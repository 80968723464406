import { Box, makeStyles, Typography } from "@material-ui/core";
import { useState } from "react";

import bg from "../../assets/searchEngine/activity/bg-activity-step-1.jpg";
import { useSearchActivitiesQuery } from "../../generated/graphql";
import theme from "../../globalTheme";
import { useSearch } from "../../hooks";
import SearchActivityModal from "../../Search/modal/SearchActivityModal";
import SearchTabs from "../../Search/SearchTabs";
import { QrReader } from "react-qr-reader";
import { t } from "@lingui/macro";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&::after": {
            // eslint-disable-next-line quotes
            content: '""',
            position: "absolute",
            zIndex: -1,
            top: "-5rem",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundImage: `url(${bg})`,
            backgroundRepeat: "no-repeat",
            backgroundPositionY: "top",
            backgroundPositionX: "center",
            backgroundSize: "cover",
        },

        minHeight: "calc(100vh - 5rem)",
    },
    main_container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "22px 1rem 2rem",
        paddingTop: 0,
        overflow: "hidden",

        [theme.breakpoints.up("md")]: {
            width: "calc(950px)",
            margin: "auto",
        },

        [theme.breakpoints.up("lg")]: {
            width: "calc(1354px)",
            margin: "auto",
        },

        [theme.breakpoints.up("xl")]: {
            width: "calc(1384px)",
            margin: "auto",
        },
    },
    qrcode_container: {
        display: "grid",
        minWidth: "500px",
        minHeight: "500px",
    },
    title: {
        color: theme.palette.common.white,
        fontWeight: 400,
        textAlign: "center",
        marginBottom: "1rem",
    },
    search: {
        gridColumn: "1 / span 12",

        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 4",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 4",
        },
    },
    lang: {
        position: "relative",
        gridColumn: "1 / span 12",

        [theme.breakpoints.up("sm")]: {
            gridColumn: "4 / span 3",
        },

        [theme.breakpoints.up("md")]: {
            gridColumn: "7 / span 2",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "7 / span 2",
        },
    },
    nbPerson: {
        position: "relative",
        gridColumn: "1 / span 12",

        [theme.breakpoints.up("sm")]: {
            gridColumn: "7 / span 3",
        },

        [theme.breakpoints.up("md")]: {
            gridColumn: "9 / span 2",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "9 / span 2",
        },
    },
    private: {
        position: "relative",
        gridColumn: "1 / span 12",

        [theme.breakpoints.up("sm")]: {
            gridColumn: "10 / span 3",
        },

        [theme.breakpoints.up("md")]: {
            gridColumn: "11 / span 2",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "11 / span 2",
        },
    },
    result: {
        gridColumn: "1 / span 12",
        display: "flex",
        justifyContent: "center",
        paddingTop: 6,

        [theme.breakpoints.up("sm")]: {
            paddingTop: 13,
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "13 / span 3",
            paddingTop: 0,
        },
    },
}));

const FirstStepQrcode = () => {
    const styles = useStyles();
    const [modalIsShown, setModalIsShown] = useState();
    const [link, setLink] = useState("");

    const videoId = "video";
    const scaleFactor = 0.25;
    const snapshots = [];

    const { filters, setFilter, resetFilters, totalCount, filtersCount } = useSearch(
        {
            searchText: null,
            communes: [],
            categories: [],
            languages: [],
            accessibleTo: [],
            payments: [],
            tags: [],
            nbPersons: 0,
            priceRange: [1, 201],
            privateActivity: false,
            parkingBus: false,
            durations: [],
        },
        useSearchActivitiesQuery,
        "SearchActivities"
    );

    const createCanvas = (video, scaleFactor) => {
        if (scaleFactor == null) {
            scaleFactor = 1;
        }
        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const render = canvas.getContext("2d");
        render.drawImage(video, 0, 0, canvas.width, canvas.height);
        return canvas;
    };

    const createPicture = () => {
        const video = document.getElementById(videoId);
        const view = document.getElementById("view");
        const canvas = createCanvas(video, scaleFactor);
        canvas.onclick = function () {
            window.open(this.toDataURL("image/jpg"));
        };
        snapshots.unshift(canvas);
        view.innerHTML = "";
        view.appendChild(snapshots[0]);
    };

    return (
        <Box className={styles.root}>
            <Box className={styles.main_container}>
                <Typography variant="h2" className={styles.title}>
                    Interface QRCode
                </Typography>
                <SearchTabs active={"qrcode"} />

                <Box className={styles.qrcode_container}>
                    <QrReader
                        onResult={(result) => {
                            if (result) {
                                setLink(result.text);
                                window.open(result.text, "_blank");
                            }
                        }}
                        style={{ width: "100%" }}
                    />
                </Box>
                <Typography variant="h2" className={styles.title}>
                    {link}
                </Typography>
                <button id="cit" onClick={() => createPicture()} className="button">
                    Take Picture
                </button>
                <div id="view"></div>
            </Box>

            <SearchActivityModal
                filtersCount={filtersCount}
                isOpen={modalIsShown}
                onClose={() => setModalIsShown(false)}
                titlePrimary={t`Tous les filtres`}
                titleSecondary="/Activités"
                filters={filters}
                setFilter={(fieldname, v) => setFilter(fieldname, v)}
                resetFilters={() => resetFilters()}
                totalCount={totalCount}
            />
        </Box>
    );
};

export default FirstStepQrcode;
