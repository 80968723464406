import { Trans, t } from "@lingui/macro";
import { Box, Typography, makeStyles } from "@material-ui/core";
import Modal from "Containers/Modal";
import theme from "globalTheme";
import { bool, func } from "prop-types";
import PlusChip from "./PlusChip";
import PlusDetailsCard from "./PlusDetailsCard";

const useStyles = makeStyles(() => ({
    cards_container: {
        display: "flex",
        gap: "1rem",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        },
    },
    legend_container: {
        ...theme.typography.h6,
        padding: 4,
        border: `0.4px solid ${theme.palette.secondary.dark}`,
        textAlign: "center",
        borderRadius: 6,
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.dark,
        marginTop: "1rem",
        fontSize: theme.typography.h5.fontSize,
    },
    title: {
        display: "flex",
        gap: "0.5rem",
        alignItems: "self-end",
        marginBottom: "1rem ",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            backgroundColor: theme.palette.common.white,
            position: "fixed",
            marginTop: "-2rem",
        },
    },
    main_content: {
        [theme.breakpoints.down("xs")]: {
            marginTop: "2rem",
        },
    },
}));
const PlusModal = (props) => {
    const styles = useStyles();
    return (
        <Modal open={props.open} onClose={() => props.onClose()} actionNeeded={false} width="44rem">
            <Box className={styles.title}>
                <Typography variant="h3">{t`Les packs`}</Typography>
                <PlusChip onlyChip height="26px" fontSize={theme.typography.h5.fontSize} />
            </Box>
            <Typography variant="h6" className={styles.main_content} style={{ color: theme.palette.primary.dark }}>
                <Trans>
                    Découvrez nos <b>packs PLUS exclusifs</b>, conçus pour vous offrir une{" "}
                    <b>expérience encore plus complète et personnalisée</b>. En complément de nos abonnements « Initial
                    » et « Essentiel », ces packs ont été spécialement créés pour répondre aux besoins de nos clients
                    les plus exigeants.
                    <br />
                    <br />
                    Ajoutez plus de personnes, plus de vins et de millésimes à votre abonnement « Initial » ou «
                    Essentiel ». Profitez également du service de dégustation des vins Féret, de l&apos;intégration
                    automatique des médailles...{" "}
                    <b>Offrez-vous une expérience personnalisée et enrichissante avec nos packs PLUS</b>.
                </Trans>
            </Typography>
            <br />
            <Typography
                variant="h6"
                style={{ color: theme.palette.primary.dark, fontWeight: 700, marginBottom: "0.5rem" }}
            >
                <Trans>Que proposent les packs PLUS ?</Trans>
            </Typography>
            <Box className={styles.cards_container}>
                <PlusDetailsCard
                    backgroundColor={theme.palette.success.light}
                    color={theme.palette.primary.dark}
                    subscriptionName={t`initial`}
                    price="150"
                    description={
                        <Typography variant="h6" style={{ lineHeight: "17px" }}>
                            <Trans>
                                <b>6 personnes</b>
                            </Trans>
                            <br />
                            <Trans>
                                <b>8 vins</b>
                            </Trans>
                            <br />
                            <Trans>
                                <b>48 millésimes</b>
                            </Trans>
                            <br />
                            <Trans>
                                <b>6 activités</b>
                            </Trans>
                            <br />
                            <Trans>
                                <b>intégration automatique des médailles</b>
                            </Trans>
                            <br />
                            <b>
                                <Trans>dégustation à la demande (160€/dégus. )</Trans>
                            </b>
                            <br />
                            <Trans>
                                accès <b>déclaration des notes</b> obtenues
                            </Trans>
                        </Typography>
                    }
                />
                <PlusDetailsCard
                    backgroundColor={theme.palette.primary.light}
                    color={theme.palette.common.white}
                    subscriptionName={t`essentiel`}
                    price="200"
                    description={
                        <Typography variant="h6" style={{ lineHeight: "17px" }}>
                            <Trans>
                                <b>6 personnes</b>
                            </Trans>
                            <br />
                            <Trans>
                                <b>8 vins</b>
                            </Trans>
                            <br />
                            <Trans>
                                <b>48 millésimes</b>
                            </Trans>
                            <br />
                            <Trans>
                                <b>6 activités</b>
                            </Trans>
                            <br />
                            <Trans>
                                <b>2 000 contacts</b>
                            </Trans>
                        </Typography>
                    }
                />
            </Box>
            <Box className={styles.legend_container}>
                <Trans>
                    Ces packs sont des « options » qui s&apos;ajoutent aux niveaux d&apos;abonnement « Initial » et «
                    Essentiel » et donc à toutes leurs fonctionnalités.
                </Trans>
            </Box>
        </Modal>
    );
};

PlusModal.propTypes = {
    open: bool,
    onClose: func,
};

export default PlusModal;
