import { t } from "@lingui/macro";
import { Box, makeStyles, Typography } from "@material-ui/core";
import Page404 from "Components/ErrorPage/Page404";
import Page500Front from "Components/ErrorPage/Page500Front";
import Helmet from "Components/Helmet/Helmet";
import LoadingWithImage from "Components/Loader/LoadingWithImage";
import FeretPublishCard from "FrontAuthor/FeretPublishCard";
import CommuneEntityCard from "FrontCommune/CommuneEntityCard";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import fioriture3 from "../assets/appellation/floriture3-big.svg";
import bg from "../assets/bg-organization.jpg";
import SocialMediaIcons from "../Components/Icons/SocialMediaIcons";
import ExpandableFormattedTextBox from "../Components/Texts/ExpandableFormattedTextBox";
import FrontContentTop from "../Front/FrontContentTop";
import FrontMainContainer from "../Front/FrontMainContainer";
import { SwiperWrapper } from "../Front/SwiperCards";
import EstateTitleSemiUnderlined from "../FrontEstate/Components/EstateTitledSemiUnderlined";
import OrganizationSummary from "../FrontOrganization/OrganizationSummary";
import FrontTitle from "../FrontRanking/FrontTitle";
import {
    useGetRandomWineEntitiesQuery,
    useGetRepresentationOrganismsFrontQuery,
    useGetRepresentationOrganismsFrontSimplifiedQuery,
} from "../generated/graphql";
import theme from "../globalTheme";
import { getTextLength, isNotEmpty, isNull, parseHtml, urlize, useWindowDimensions } from "../helpers/helpers";

const useStyles = makeStyles((e) => ({
    container_card: {
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
        },
    },
    container_content_top: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        gap: "26px",

        [theme.breakpoints.up("md")]: {
            marginTop: "-45px",
        },
    },
    container_image_logo: {
        gridColumn: "8 / span 12",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 0,

        [theme.breakpoints.up("md")]: {
            marginTop: 43,
            // height: 277,
            height: "auto",
        },

        [theme.breakpoints.up("lg")]: {
            marginTop: 60,
        },

        [theme.breakpoints.up("xl")]: {
            marginTop: 40,
        },
    },
    image_logo: {
        width: "100%",
        maxWidth: 370,
    },
    socialMedia: {
        order: 1,
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: "15px",

        [theme.breakpoints.up("lg")]: {
            order: 2,
        },

        [theme.breakpoints.up("xl")]: {
            right: 64,
            top: 42,
        },
    },
    container_estate_region: {
        backgroundColor: "transparent",
    },
    container_estate_content: {
        justifyContent: "center",
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingBottom: 38,
        background: "transparent",

        [theme.breakpoints.up("xl")]: {
            width: "calc(1314px)",
            margin: "auto",
        },
    },
    container_content_secondary: {
        gridColumn: "1 / span 12",

        [theme.breakpoints.up("md")]: {
            gridColumn: "9 / span 4",
            marginLeft: "24px",
            marginTop: "45px",
        },
    },
    container_content_primary: {
        gridColumn: "1 / span 12",

        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 8",
        },
    },
    container_infos_right: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",

        [theme.breakpoints.up("md")]: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
        },
    },
    center_responsive: {
        gridColumn: "1 / span 6",
        width: "100%",

        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 12",
        },
    },
    content_text: {
        color: theme.palette.primary.dark,

        "& *": {
            textAlign: "justify",
        },

        "& p p": {
            marginBottom: 0,
            marginTop: 0,
        },
    },
    container_slider_1: {
        [theme.breakpoints.down("600")]: {
            paddingLeft: "16px",
            paddingRight: "16px",
        },
    },
}));

const OrganizationPage = () => {
    const styles = useStyles();
    const { width } = useWindowDimensions();

    const { id } = useParams();
    const { data: randomData } = useGetRandomWineEntitiesQuery() || {};
    const locale = useSelector((state) => state?.user?.locale || "fr");

    const {
        data: { GetRepresentationOrganismsFront: representationOrganism = [] } = {},
        loading: loading,
        error,
    } = useGetRepresentationOrganismsFrontQuery({ variables: { locale, name: urlize(id) } });

    const organism = representationOrganism?.[0];
    const {
        data: { GetRepresentationOrganismsFront: representationOrganisms = [] } = {},
        error: errorRepresentationOrga,
    } = useGetRepresentationOrganismsFrontSimplifiedQuery({ variables: { locale, excludeId: organism?._id } });

    // } else {
    // }

    const breadcrumbs = [
        {
            name: t`Organismes du vignoble bordelais`,
            path: "/organismes-vignoble-bordelais",
        },
        {
            name: t`Représentations`,
            path: "/organismes-vignoble-bordelais/representation",
        },
        {
            name: organism?.name,
        },
    ];

    const displaySummary = () => {
        const members = organism.members?.some(({ firstName, lastName }) => !isNull(firstName) || !isNull(lastName));
        const address = organism.address && organism.address?.address;
        const phone = organism.phone;
        const email = organism.email;
        return !!(members || address || phone || email);
    };
    if (loading) {
        return <LoadingWithImage />;
    }
    if (!organism) {
        return <Page404 />;
    }
    if (error || errorRepresentationOrga) {
        return <Page500Front />;
    }
    return (
        <>
            <Helmet
                title={organism?.name}
                content={`${organism?.name} : explorez son histoire et ses missions, l'une des principales représentations de l'industrie viticole bordelaise. Découvrez les contacts clés de l'organisme et comment il travaille à la promotion et à la protection des vins de Bordeaux.`}
            />
            <FrontContentTop
                backgroundImage={bg}
                links={breadcrumbs}
                previousPage={{
                    name: t`Tous les organismes de représentation`,
                    path: "/organismes-vignoble-bordelais/representation",
                }}
            >
                <FrontTitle title={organism?.name} />
                <FrontMainContainer
                    styleCardContent
                    className={styles.main_container}
                    style={{
                        backgroundImage: `url(${width >= 960 && fioriture3})`,
                        backgroundPositionY: "150px",
                        backgroundPositionX: "right",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    <Box className={styles.container_card}>
                        <Box className={styles.socialMedia}>
                            <SocialMediaIcons socialMedias={organism.socialMedias} />
                        </Box>
                        <Box className={styles.container_content_top}>
                            {isNotEmpty(organism?.about) && (
                                <Box className={styles.container_content_primary}>
                                    <EstateTitleSemiUnderlined
                                        title={t`À propos`}
                                        heightAfter={32}
                                        style={{ marginTop: "0px" }}
                                        lowBar={{ width: 75 }}
                                        borderColor={theme.palette.primary.main}
                                    />
                                    <Box>
                                        <Typography component="div" variant="body2" className={styles.content_text}>
                                            {getTextLength(organism?.about) > 1690 ? (
                                                <ExpandableFormattedTextBox
                                                    value={organism.about}
                                                    truncatedValue={`${organism.about.slice(0, 1690)}...`}
                                                />
                                            ) : (
                                                parseHtml(organism.about, "body2")
                                            )}
                                        </Typography>
                                    </Box>
                                </Box>
                            )}

                            <Box className={styles.container_content_secondary}>
                                <Box className={styles.container_infos_right}>
                                    {displaySummary() && (
                                        <Box
                                            className={styles.center_responsive}
                                            style={{ gridColumn: width < 700 ? "1 / span 12" : "1 / span 6" }}
                                        >
                                            <OrganizationSummary
                                                members={organism.members}
                                                address={organism.address}
                                                phone={organism.phone}
                                                email={organism.email}
                                            />
                                        </Box>
                                    )}

                                    <Box
                                        className={styles.container_image_logo}
                                        style={{
                                            gridColumn: width < 700 ? "1 / span 12" : "8/ span 12",
                                            marginTop: width < 700 ? "24px" : "0px",
                                        }}
                                    >
                                        <img
                                            src={organism.logo?.twicpicURL}
                                            className={styles.image_logo}
                                            alt="primary logo"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </FrontMainContainer>

                <Box className={styles.container_slider_1}>
                    <SwiperWrapper
                        title={t`D’autres organismes de représentation des vins de Bordeaux`}
                        spaceBetween={0}
                        noMargin
                    >
                        {representationOrganisms.map(({ name }, i) => (
                            <FeretPublishCard
                                key={i}
                                title={name}
                                height={160}
                                titleHeight={80}
                                path="/organismes-vignoble-bordelais/representation/"
                                bgColor={theme.palette.common.white}
                                floritureBottomGray
                                bold
                            />
                        ))}
                    </SwiperWrapper>
                </Box>
            </FrontContentTop>

            <Box className={styles.container_estate_region}>
                <Box className={styles.container_estate_content}>
                    <SwiperWrapper
                        boxPaddingTop={4}
                        title={t`Découvrez des propriétés viticoles bordelaises`}
                        dark
                        noMargin
                        spaceBetween={0}
                    >
                        {randomData?.GetRandomWineEntities?.map((wineEntity, i) => {
                            return (
                                <CommuneEntityCard
                                    wineEntity={wineEntity}
                                    key={i}
                                    appellation={wineEntity?.appellation}
                                    name={wineEntity?.name}
                                    classification={wineEntity?.classification}
                                    image={wineEntity?.image?.twicpicURL}
                                    wineEntityId={wineEntity?.wineEntityId}
                                    width={320}
                                    hadRedWines={wineEntity?.hadRedWines}
                                    hadWhiteWines={wineEntity?.hadWhiteWines}
                                    hasRoseWines={wineEntity?.hasRoseWines}
                                    CardShadow="0px 3px 6px rgba(0, 0, 0, 0.08"
                                />
                            );
                        })}
                    </SwiperWrapper>
                </Box>
            </Box>
        </>
    );
};

export default OrganizationPage;
