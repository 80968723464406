import { Box, makeStyles, Typography } from "@material-ui/core";
import { bool, func, shape, string } from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import BaseButton from "../Components/Buttons/BaseButton";
import BaseCheckbox from "../Components/Inputs/BaseCheckbox";
import Loading from "../Components/Loader/Loading";
import { signIn } from "../features/user/userSlice";
import theme from "../globalTheme";
import ConnectionContainer from "../SubscriptionTunnel/Containers/ConnectionContainer";
import SignUp from "./forms/SignUp";
import { useCustomHistory } from "hooks";

const useStyles = makeStyles(() => ({
    switch_form: {
        width: "100%",
        borderRadius: 10,
        background: theme.dashboard.background,
        display: "flex",
        marginBottom: "2rem",
        [theme.breakpoints.up("xs")]: {
            flexDirection: "column",
        },
        [theme.breakpoints.up("md")]: {
            flexDirection: "row",
            gap: "1rem",
        },
    },
    option_form: () => ({
        margin: 10,
        padding: "0.5rem",
        borderRadius: 10,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flexBasis: "50%",
        justifyContent: "center",
        color: theme.palette.secondary.dark,
        cursor: "pointer",
    }),
    container_forms: {
        marginBottom: "2rem",
        "& #email-box, #password-box ": {
            gridColumn: "auto / span 6",
            height: "34px",
        },
    },
    container_choices: {
        height: "83px",
        width: "273px",
        border: `0.4px solid ${theme.palette.secondary.main}`,
        borderRadius: "10px",
        background: theme.palette.common.white,
        cursor: "pointer",
        display: "grid",
        gridTemplateColumns: "repeat(6,1fr)",
    },
    text_choice: {
        gridColumn: "2 / 7",
        display: "flex",
        alignItems: "start",
        padding: "9px 0",
    },
    radio: {
        padding: "9px",
        display: "flex",
        alignItems: "start",
        "& .MuiSvgIcon-root": {
            fill: theme.palette.secondary.main,
        },
    },
    radioChecked: {
        "& .MuiSvgIcon-root": {
            "&:nth-child(2)": {
                fill: theme.palette.primary.light,
            },
        },
    },
    itemGrid: {
        [theme.breakpoints.down("xs")]: {
            gridColumn: "1 / span 12 !important",
        },
    },
}));

const InvitationForm = (props) => {
    const currentUser = useSelector((state) => state?.user?.currentUser);

    const [selectedOption, setSelectedOption] = useState("signin");
    const [email, setEmail] = useState("");
    const styles = useStyles();
    const dispatch = useDispatch();
    const history = useCustomHistory();
    const [called, setCalled] = useState(false);
    const [signInEmail, setSigninEmail] = useState("");
    const [consentToAssociate, setConsentToAssociate] = useState(false);

    useEffect(() => {
        if (currentUser) {
            setSelectedOption("alreadyConnected");
            if (signInEmail !== "") {
                props.onSignInSubmit(signInEmail);
            }
        }
    }, [currentUser, signInEmail]);

    useEffect(() => {
        if (props.ftId) {
            setSelectedOption("signup");
        }
    }, [props.ftId]);

    const handleConnectionOnSubmit = (values) => {
        dispatch(
            signIn({
                username: values.subscriptionEmailAddress,
                password: values.subscriptionPassword,
            })
        );
        setSigninEmail(values.subscriptionEmailAddress);
        setCalled(true);
    };

    const handleSignedInContinue = () => {
        if (consentToAssociate) {
            props.onSignInSubmit(currentUser?.email);
        }
    };

    if (props.loading) {
        return (
            <Box style={{ width: "579px" }}>
                <Loading />
            </Box>
        );
    }

    const SelectedOption = () => {
        switch (selectedOption) {
            case "alreadyConnected":
                return (
                    <Box style={{ gridColumn: "1 / 13" }}>
                        <BaseCheckbox
                            style={{ marginLeft: 0, alignItems: "start", textAlign: "left" }}
                            styleCheckbox={{ marginTop: "2px" }}
                            checked={consentToAssociate}
                            value={consentToAssociate}
                            onChange={() => setConsentToAssociate(!consentToAssociate)}
                            label={
                                <Typography variant="h6">
                                    Je comprends que toutes les fiches techniques envoyées à {props.receiverEmail}{" "}
                                    seront désormais associées au compte de {currentUser?.firstName}{" "}
                                    {currentUser?.lastName} ({currentUser?.email})
                                </Typography>
                            }
                        />
                        <BaseButton
                            disabled={!consentToAssociate}
                            variant="contained"
                            color="primary"
                            fullWidth
                            boxStyle={{ gridColumn: "1 / 13", marginTop: "1rem" }}
                            onClick={handleSignedInContinue}
                        >
                            Continuer
                        </BaseButton>
                    </Box>
                );
            case "signin":
                return (
                    <ConnectionContainer
                        itemGridStyle={styles.itemGrid}
                        disabled={!consentToAssociate}
                        width="100%"
                        onSubmit={(values) => handleConnectionOnSubmit(values)}
                        called={called}
                        setCalled={setCalled}
                        onFormChange={(e) => e.fieldname === "subscriptionEmailAddress" && setEmail(e.value)}
                        matchCheckbox={{
                            display: true,
                            label: (
                                <Typography variant="h6">
                                    Je comprends que toutes les fiches techniques envoyées à {props.receiverEmail}{" "}
                                    seront désormais ajoutées au compte Féret {email}
                                </Typography>
                            ),
                            checked: consentToAssociate,
                            onChange: () => setConsentToAssociate(!consentToAssociate),
                            value: props.emailAddress?.value,
                        }}
                        forgotPasswordOnClick={() => history.push("/invitation/mdp")}
                    />
                );
            case "signup":
                return (
                    <SignUp
                        disabled={
                            props.disabled ||
                            (props.emailAddress?.value !== props.receiverEmail && !props.ftId && !consentToAssociate)
                        }
                        //TITLE
                        title={props.title}
                        //FIRSTNAME
                        firstName={props.firstName}
                        //LASTNAME
                        lastName={props.lastName}
                        //EMAIL
                        emailAddress={props.emailAddress}
                        //PHONE
                        phoneNumber={props.phoneNumber}
                        //PASSWORD
                        password={props.password}
                        //CONFIRMPASSWORD
                        confirmationPassword={props.confirmationPassword}
                        //ISPROFESSIONAL
                        isProfessional={props.isProfessional}
                        onChangeIsProfessional={() => props.onChangeIsProfessional()}
                        //CATEGORY
                        category={props.category}
                        onChangeCategory={(e) => props.onChangeCategory(e)}
                        //COMPANY
                        company={props.company}
                        //ADDRESS
                        address={props.address}
                        //EXTRAADDRESS
                        extraAddress={props.extraAddress}
                        onChangeExtraAddress={(e) => props.onChangeExtraAddress(e)}
                        //POSTALCODE
                        postalCode={props.postalCode}
                        onChangePostalCode={(e) => props.onChangePostalCode(e)}
                        //COMMUNE
                        commune={props.commune}
                        onChangeCommune={(e) => props.onChangeCommune(e)}
                        matchCheckbox={
                            !props.ftId && {
                                display: props.emailAddress?.value !== props.receiverEmail,
                                label: (
                                    <Typography variant="h6">
                                        Je comprends que toutes les fiches techniques envoyées à {props.receiverEmail}{" "}
                                        seront désormais ajoutées au compte Féret {props.emailAddress?.value}
                                    </Typography>
                                ),
                                checked: consentToAssociate,
                                onChange: () => setConsentToAssociate(!consentToAssociate),
                                value: props.emailAddress?.value,
                            }
                        }
                        onSubmit={props.onSignUpSubmit}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <>
            <Typography variant="h2" style={{ marginBottom: "1rem" }}>
                Accédez gratuitement aux fiches techniques partagées
            </Typography>
            {!props.ftId && (
                <Box className={styles.switch_form}>
                    {currentUser && (
                        <Box
                            className={styles.option_form}
                            onClick={() => setSelectedOption("alreadyConnected")}
                            style={{
                                boxShadow: selectedOption === "alreadyConnected" && "0px 3px 6px rgba(0, 0, 0, 0.16)",
                                background: selectedOption === "alreadyConnected" && theme.palette.secondary.main,
                                color: selectedOption === "alreadyConnected" && theme.palette.primary.dark,
                            }}
                        >
                            <Typography variant="h4" style={{ textAlign: "center" }}>
                                Associer au compte de {currentUser?.firstName} {currentUser?.lastName} (
                                {currentUser?.email})
                            </Typography>
                            <Typography variant="h6" style={{ textAlign: "center" }}>
                                Votre connexion actuelle
                            </Typography>
                        </Box>
                    )}
                    <Box
                        className={styles.option_form}
                        onClick={() => setSelectedOption("signin")}
                        style={{
                            boxShadow: selectedOption === "signin" && "0px 3px 6px rgba(0, 0, 0, 0.16)",
                            background: selectedOption === "signin" && theme.palette.secondary.main,
                            color: selectedOption === "signin" && theme.palette.primary.dark,
                        }}
                    >
                        <Typography variant="h4" style={{ textAlign: "center" }}>
                            Associer à un {currentUser && "autre"} compte existant
                        </Typography>
                    </Box>
                    <Box
                        className={styles.option_form}
                        onClick={() => setSelectedOption("signup")}
                        style={{
                            boxShadow: selectedOption === "signup" && "0px 3px 6px rgba(0, 0, 0, 0.16)",
                            background: selectedOption === "signup" && theme.palette.secondary.main,
                            color: selectedOption === "signup" && theme.palette.primary.dark,
                        }}
                    >
                        <Typography variant="h4" style={{ textAlign: "center" }}>
                            Associer à un nouveau compte
                        </Typography>
                    </Box>
                </Box>
            )}
            <Box className={styles.container_forms}>{SelectedOption()}</Box>
        </>
    );
};

InvitationForm.propTypes = {
    senderName: string,
    receiverEmail: string,
    disabled: bool,
    title: shape({
        value: string,
        id: string,
    }),
    firstName: shape({
        id: string,
    }),
    lastName: shape({
        id: string,
    }),
    emailAddress: shape({ id: string, value: string }),
    subscriptionNewsletter: shape({
        id: string,
    }),

    phoneNumber: shape({
        id: string,
    }),
    password: shape({
        id: string,
    }),
    confirmationPassword: shape({
        id: string,
    }),

    isProfessional: bool,
    onChangeIsProfessional: func,

    category: string,
    onChangeCategory: func,

    company: shape({
        value: string,
        id: string,
    }),

    address: shape({
        value: string,
        id: string,
    }),

    ftAllowed: shape({
        value: bool,
        onChange: func,
    }),

    extraAddress: string,
    onChangeExtraAddress: func,

    postalCode: string,
    onChangePostalCode: func,

    commune: string,
    onChangeCommune: func,
    onSignUpSubmit: func,
    onSignInSubmit: func,
    loading: bool,
    ftId: string,
};

export default InvitationForm;
