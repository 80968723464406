import React from "react";
import { makeStyles } from "@material-ui/core";
import PropTypes, { bool } from "prop-types";
import theme from "globalTheme";

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        alignItems: "center",
    },
    border: (props) => {
        return {
            borderBottom: `2px solid ${theme.palette.primary.light}`,
            borderBottomStyle: props.dashed && "dashed",
            width: "100%",
        };
    },
    content: {
        display: "flex",
        border: `1px solid ${theme.palette.primary.light}`,
        borderRadius: "50%",
        padding: "2px",
    },
}));

const DividerWithText = (props) => {
    const classes = useStyles(props);
    return (
        <div className={classes.container}>
            <div className={classes.border} />
            <span className={classes.content}>{props.children}</span>
            <div className={classes.border} />
        </div>
    );
};
DividerWithText.propTypes = {
    children: PropTypes,
    dashed: bool,
};
export default DividerWithText;
