import React from "react";
import { Typography, makeStyles, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import Popin from "../../Containers/Popin";
import theme from "../../globalTheme";
import distributionImg from "../../assets/Wine/popinDistribution.png";
import { firstLetterUppercase } from "../../helpers/helpers";
import BaseBarCode from "../../Components/BarCode/BaseBarCode";

const useStyle = makeStyles((theme) => ({
    container: {
        width: "330px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    GTINPopin__title: {
        marginBottom: theme.spacing(1),
        textAlign: "center",
    },
    GTINPopin__subtitle: {
        fontFamily: "Playfair Display",
        fontWeight: "bold",
        fontSize: "18px",
        color: "#000000",
        marginBottom: theme.spacing(2),
    },
    GTINPopin__subtitle_dark: {
        color: "#360318",
        marginBottom: theme.spacing(2),
    },
}));

const GTINPopin = (props) => {
    const styles = useStyle(props);
    return (
        <Popin
            open={props.modalOpen}
            onClose={props.handleClose}
            cross
            background={3}
            // title={<Typography variant="h3" className={styles.GTINPopin__title}>GTIN</Typography>}
        >
            <Box className={styles.container}>
                {props.values?.conditionningMode ||
                props.values?.centilisation ? (
                    <Typography
                        variant="h6"
                        className={styles.GTINPopin__subtitle}
                    >
                        {props.values?.conditionningMode
                            ? firstLetterUppercase(
                                  props.values?.conditionningMode
                              )
                            : ""}
                        {props.values?.conditionningMode &&
                        props.values?.centilisation
                            ? " · "
                            : ""}
                        {props.values?.centilisation
                            ? firstLetterUppercase(props.values?.centilisation)
                            : ""}
                        {props.values?.units ? ` x ${props.values?.units}` : ""}
                    </Typography>
                ) : (
                    ""
                )}
                <Typography
                    variant="h6"
                    className={styles.GTINPopin__subtitle_dark}
                >
                    {`${props.wine ? props.wine : ""} ${
                        props.cuvee ? props.cuvee : ""
                    } ${props?.year ? props.year : ""}`}
                </Typography>
                {props.values?.gtin ? (
                    <BaseBarCode
                        value={props.values?.gtin}
                        format="EAN13"
                        noCopy
                    />
                ) : (
                    ""
                )}
            </Box>
        </Popin>
    );
};

GTINPopin.propTypes = {
    modalOpen: PropTypes.bool,
    handleClose: PropTypes.func,
    values: PropTypes.array,
    year: PropTypes.number,
    wine: PropTypes.string,
    cuvee: PropTypes.string,
};

export default GTINPopin;
