import { element } from "prop-types";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";

export const PrivateRoute = ({ children, ...rest }) => {
    const currentUser = useSelector((state) => state?.user?.currentUser);
    return <Route {...rest} render={() => (currentUser ? children : <Redirect to="/recherche" />)} />;
};

PrivateRoute.propTypes = {
    children: element,
};
