import { Box, Divider, FormControl, Grid, InputAdornment, makeStyles, TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import BaseChip from "Components/Chips/BaseChip";
import AutocompleteSelectInput from "Components/Inputs/AutocompleteSelectInput";
import ControlledSwitch from "Components/Switch/ControlledSwitch";
import { SUBSCRIPTION_TYPES } from "config/constants";
import { debounce } from "lodash";
import { bool, func, object, string } from "prop-types";
import { useCallback, useEffect, useState } from "react";

import searchIcon from "../../assets/SubscriptionPage/searchIcon.svg";
import BaseButton from "../../Components/Buttons/BaseButton";
import AddressAppellationAutocomplete from "../../Components/Inputs/AddressAppellationAutocomplete";
import BaseCheckbox from "../../Components/Inputs/BaseCheckbox";
import SimpleInput from "../../Components/Inputs/SimpleInput";
import Loading from "../../Components/Loader/Loading";
import {
    useGetCommunesOfAppellationQuery,
    useGetCommunesOptionsQuery,
    useGetOrphanWineEntitiesLazyQuery,
} from "../../generated/graphql";
import theme from "../../globalTheme";
import { formatConcat, getFreeTrial, sanitizeAddressField, sanitizeName } from "../../helpers/helpers";
import TitleAboutProperty from "../Components/AboutProperty/TitleAboutProperty";
import EstateNotFoundPopin from "../Popins/EstateNotFoundPopin";
import SubscriptionContainer from "./SubscriptionContainer";
import { Redirect } from "Components/Redirect/Redirect";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
    formControlLabel: {
        color: "#432E35",
        marginLeft: 0,
        marginBottom: "16px",
        fontFamily: theme.typography.h5.fontFamily,
        fontSize: theme.typography.h5.fontSize,
        fontWeight: "900",
        "& .MuiTypography-body1": {
            fontWeight: "900",
        },
        marginRight: 0,
    },
    autoRoot: {
        "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
            padding: 0,
        },
    },
    option: {
        fontSize: "14px",
        color: theme.palette.primary.dark,
        "&:hover": {
            backgroundColor: theme.palette.dashboard.background,
        },
        "&.MuiListItem-root.Mui-selected": {
            backgroundColor: theme.palette.dashboard.background,
        },
        // eslint-disable-next-line quotes
        '&[data-focus="true"]': {
            backgroundColor: theme.palette.dashboard.background,
        },
    },
    paper: {
        "& ::-webkit-scrollbar": {
            width: "8px",
        },
        "& ::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            paddingRight: "8px",
        },
        "& ::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "20px",
        },
    },
    autocomplete: (props) => ({
        marginBottom: theme.spacing(3),
        "& .MuiSvgIcon-root": {
            width: "12px",
            color: theme.palette.secondary.main,
            marginRight: 0,
        },
        "& .MuiAutocomplete-clearIndicator": {
            marginRight: props.address?.simple ? "15px" : "-2px",
        },
        "& .MuiAutocomplete-endAdornment": {
            top: "calc(50% - 14px)",
            width: "10%",
        },
        "& .MuiInputAdornment-root": {
            marginRight: "10px",
        },
        backgroundColor: "white",
        "& .MuiFormLabel-root": {
            color: theme.palette.secondary.dark,
            fontSize: "14px",
            top: "-8px",
            "&.Mui-focused": {
                color: theme.palette.primary.dark,
            },
            "&.Mui-error": {
                color: theme.palette.error.main,
            },
        },
        "& .MuiOutlinedInput-root": {
            paddingLeft: "10px",
            fontSize: "14px",
            height: "34px",
            color: theme.palette.primary.dark,
            borderWidth: "0.2px",
            borderColor: theme.palette.secondary.dark,

            "&.Mui-error": {
                color: theme.palette.error.main,
            },
            "&.Mui-focused": {
                color: theme.palette.primary.dark,
                borderColor: theme.palette.primary.dark,
                borderWidth: "0.2px",
            },
            "&.Mui-disabled": {
                color: theme.palette.secondary.dark,
                backgroundColor: theme.palette.action.disabledBackground,
                borderColor: theme.palette.secondary.dark,
                borderWidth: "0.2px",
            },
            "&:hover fieldset": {
                borderColor: props.address?.error ? theme.palette.error.main : theme.palette.secondary.main,
            },
            "&.Mui-focused fieldset": {
                borderColor: props.address?.error ? theme.palette.error.main : theme.palette.secondary.main,
            },
        },
        " & .MuiSelect-select": {
            "&:focus": {
                backgroundColor: theme.palette.common.white,
            },
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: "translate(16px, 4px) scale(0.75)",
        },
        "& .MuiFormControl-marginNormal": {
            margin: 0,
        },
        "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input": {
            padding: "0px 0px 0px 10px",
        },
        "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
            paddingRight: "0px",
        },
        "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
            paddingRight: "0px",
        },
    }),
    container_form: {
        display: "grid",
        gridTemplateColumns: "repeat(12,1fr)",
        rowGap: "1rem",
    },
    container_appelation: {
        gridColumn: "1 / span 6",
        marginRight: "1rem",
    },
    container_commune: {
        gridColumn: "auto / span 6",
    },
    container_company_status: {
        gridColumn: "1 / span 2",
    },
    container_company_name: {
        gridColumn: "auto / span 4",
        marginRight: "1rem",
    },
}));

const AboutPropertyContainer = (props) => {
    const styles = useStyles(props);
    const [checked, setChecked] = useState(false);
    const freeTrial = useSelector((state) => getFreeTrial(state));

    const [commercialAddress, setCommercialAddress] = useState({
        simple: props.subscriptionState?.subscriptionAddress || null,
    });
    const [estateMainCommune, setEstateMainCommune] = useState(
        props.subscriptionState?.newSubscriptionMainCommune || null
    );
    const [estateMainAppelation, setEstateMainAppellation] = useState(
        props.subscriptionState?.subscriptionMainAppellation || ""
    );
    const [searchString, setSearchString] = useState("");
    const [estateName, setEstateName] = useState(props.subscriptionState?.subscriptionEstateName || null);
    const [commercialCommune, setCommercialCommune] = useState(props.subscriptionState?.subscriptionCommune || null);
    const [commercialPostalCode, setCommercialPostalCode] = useState(props.subscriptionState?.subscriptionPostalCode);
    const [inseeCode, setCommercialInseeCode] = useState(props.subscriptionState?.subscriptionCommuneINSEEcode);
    const [extraAddress, setExtraAddress] = useState(props.subscriptionState?.subscriptionExtraAddress || null);
    const [bordeauxVineyardError, setBordeauxVineyardError] = useState(false);
    const [companyStatus, setCompanyStatus] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [orphanWineEntityId, setOrphanWineEntityId] = useState(props.subscriptionState?.orphanWineEntityId);
    const [estateMainCommuneCodeInsee, setEstateMainCommuneCodeInsee] = useState();
    const [postalCode, setPostalCode] = useState("");

    const [search, { data: orphanData, loading: orphanLoading, called }] = useGetOrphanWineEntitiesLazyQuery({
        variables: {
            searchString,
        },
    });

    const debouncer = useCallback(debounce(search, 100), []);

    const { data: communeData } = useGetCommunesOptionsQuery({
        variables: {
            filterByRegion: "33",
        },
    });

    const {
        data: { GetCommunesOfAppellation: communesByAppellation } = {},
        loading: loadingCommunes2,
    } = useGetCommunesOfAppellationQuery({
        variables: {
            appellationLabel: estateMainAppelation,
            filterByRegion: "33",
        },
    });

    const handleSubmit = () => {
        const isAdopting = props.adoptingEstate ?? props.searchMode;
        props.subscriptionDispatch({
            type: "setManyValues",
            payload: {
                subscriptionEstateName: estateName,
                subscriptionCommuneINSEEcode: inseeCode,
                subscriptionCommune: commercialCommune,
                subscriptionMainAppellation: estateMainAppelation,
                subscriptionExtraAddress: sanitizeAddressField(extraAddress),
                subscriptionAddress: sanitizeAddressField(commercialAddress?.simple ?? commercialAddress),
                subscriptionPostalCode: commercialPostalCode,
                newSubscriptionMainCommune: estateMainCommune,
                newSubscriptionMainCommuneInseeCode: estateMainCommuneCodeInsee,
                subscriptionBillingStatut: companyStatus,
                subscriptionBillingCompanyName: companyName,
                orphanWineEntityId: isAdopting ? orphanWineEntityId : null,
            },
        });
        setCompanyName("");
        props.onOpen();
    };

    useEffect(() => {
        if (commercialPostalCode === "") setCommercialCommune(null);
    }, [commercialPostalCode]);

    useEffect(() => {
        if (props.adoptingEstate) {
            setEstateName(props.adoptingEstate.estateName);
            setEstateMainAppellation(props.adoptingEstate.mainAppellation);
            setEstateMainCommune(props.adoptingEstate.commune);
            setCommercialCommune(props.adoptingEstate.address?.commune);
            setCommercialAddress({ simple: props.adoptingEstate.address.address });
            setCommercialPostalCode(props.adoptingEstate.address.postalCode);
        }
    }, [props.adoptingEstate]);

    useEffect(() => {
        if (estateMainCommune) {
            if (estateMainAppelation) {
                const i = communesByAppellation?.findIndex((e) => estateMainCommune === e.name);
                setEstateMainCommuneCodeInsee(communesByAppellation?.[i]?.INSEEcode);
            } else {
                const communes = allCommunesRaw();
                const commune = communes?.findIndex((e) => estateMainCommune === e.simpleCommuneName);
                setEstateMainCommuneCodeInsee(commune?.communeINSEEcode);
            }
        }
    }, [estateMainCommune]);

    // ESTATE NOT FOUND VALIDATION
    const getEstateNameValidation = () => estateName?.length < 5 && "Le champ doit contenir au moins 5 caractères";

    const handleClose = () => props.onClose();

    const clearFields = () => {
        setEstateName();
        setEstateMainCommune();
        setEstateMainAppellation();
        setCommercialAddress();
        setCommercialPostalCode();
        setCommercialInseeCode();
        setCommercialCommune();
        setPostalCode("");
    };

    //ARRAY OF COMMUNES IF MAIN APPELATION IS EMPTY
    const allCommunes = () => {
        const communes = [];
        for (let i = 0; i < communeData?.GetCommunesOptions?.length; i++) {
            const commune = communeData?.GetCommunesOptions?.[i];
            if (!commune.appellations.includes(estateMainAppelation)) {
                communes.push({
                    name: commune.simpleCommuneName,
                    communeName: `${commune.simpleCommuneName} (${commune.postalCode})`,
                });
            }
        }
        return communes.sort(function (a, b) {
            return a.name.localeCompare(b.name);
        });
    };

    //ARRAY OF COMMUNES IF MAIN APPELATION IS SET
    const communesByAppellations = () =>
        communesByAppellation?.map((commune) => {
            return {
                title: commune.name,
                communeName: `${commune.name} (${commune.postalCode})`,
                ...commune,
            };
        });

    //ARRAY OF APPELLATION IF MAIN COMMUNE IS SET
    const appellationsByCommune = () => {
        const appelations = [];
        for (let i = 0; i < communeData?.GetCommunesOptions?.length; i++) {
            const commune = communeData?.GetCommunesOptions?.[i];
            if (estateMainCommune === commune.simpleCommuneName) {
                return commune.appellations;
            } else {
                commune.appellations.map((appellation) => {
                    if (!appelations.includes(appellation)) appelations.push(appellation);
                });
            }
        }
        return appelations.sort();
    };

    const allCommunesRaw = () => {
        const communes = [];
        for (let i = 0; i < communeData?.GetCommunesOptions?.length; i++) {
            const commune = communeData?.GetCommunesOptions?.[i];
            if (!commune.appellations.includes(estateMainAppelation)) {
                communes.push(commune);
            }
        }
        return communes;
    };

    if ((orphanLoading && !called) || loadingCommunes2) {
        return <Loading loading={true} />;
    }
    if (props.checkIfPreviousStepIsValid(2)) {
        return (
            <SubscriptionContainer
                maxWidth={"sm"}
                title={props.adoptingEstate?.estateName ?? "À propos de votre propriété..."}
                titleStyle={{ margin: "auto", maxWidth: "300px" }}
                titleVariant="h3"
                backLink={!freeTrial}
                onClickBack={props.onClickBack}
            >
                {props.adoptingEstate && (
                    <Typography
                        variant="h6"
                        style={{ fontFamily: "Mansalva", textAlign: "center", color: theme.palette.primary.light }}
                    >
                        Merci de vérifier les informations ci-dessous avant de passer à l’étape suivante
                    </Typography>
                )}

                {props.open && (
                    <EstateNotFoundPopin
                        modalOpen={props.open}
                        handleClose={handleClose}
                        estateName={props.subscriptionState?.subscriptionEstateName}
                        estateCommune={props.subscriptionState?.newSubscriptionMainCommune}
                        estateMainAOC={props.subscriptionState?.subscriptionMainAppellation}
                        onClickNext={props.onClickNext}
                        createSubloading={props.createSubloading}
                    />
                )}

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {/* BETA */}
                        {/* {getEstateFoundTypo()} */}
                    </Grid>
                    {!props.adoptingEstate && !freeTrial && (
                        <Grid item xs={12}>
                            <Typography variant="h6" style={{ color: theme.palette.primary.dark, textAlign: "center" }}>
                                Choisissez votre mode d’inscription, si vous ne{" "}
                                <b>trouvez pas votre propriété dans la liste</b>, ne vous inquiétez pas,{" "}
                                <b>vous pouvez l’ajouter</b> !
                            </Typography>
                            <ControlledSwitch
                                boxStyle={{ justifyContent: "end", margin: "0.5rem 0 1rem" }}
                                handleSwitchChange={() => {
                                    clearFields();
                                    props.setSearchMode(!props.searchMode);
                                }}
                                switchCheck={!props.searchMode}
                                clickedLabel
                                rightLabel="Ajouter ma propriété"
                                leftLabel="Rechercher ma propriété"
                                rightLabelExtra={
                                    <BaseChip
                                        chipOnClick={() => {
                                            clearFields();
                                            props.setSearchMode(true);
                                        }}
                                        paddingLabel="1px 4px"
                                        title={
                                            <Typography variant="subtitle1">
                                                Vous n’avez pas trouvé votre propriété ?
                                            </Typography>
                                        }
                                        style={{
                                            backgroundColor: theme.palette.wine.main,
                                        }}
                                    />
                                }
                            />
                            <Divider />
                        </Grid>
                    )}
                    <Grid item xs={12} style={{ width: "100%" }}>
                        <TitleAboutProperty
                            disabledLegend={props.adoptingEstate}
                            title={
                                props.searchMode ? "Identification de la propriété *" : "Inscription de la propriété *"
                            }
                            subtitle={
                                props.searchMode
                                    ? "Recherchez votre propriété dans le champ ci-dessous"
                                    : "Saisissez le nom de la propriété dans le champ ci-dessous"
                            }
                        />
                        {props.adoptingEstate ? null : props.searchMode ? (
                            <Autocomplete
                                required
                                clearOnBlur={true}
                                // autoSelect
                                className={styles.autocomplete}
                                classes={{ option: styles.option }}
                                // filterSelectedOptions
                                autoHighlight
                                size="small"
                                getOptionLabel={(option) => option.estateName || option}
                                renderOption={(estate) => {
                                    if (typeof estate === "string") {
                                        return estate;
                                    } else {
                                        return formatConcat(
                                            [estate.estateName, estate.mainAppellation, estate.mainCommune],
                                            " / "
                                        );
                                    }
                                }}
                                value={estateName}
                                filterOptions={(options) => options}
                                onChange={(e, estate, reason) => {
                                    if (reason === "clear") {
                                        clearFields();
                                    } else if (estate) {
                                        setEstateName(estate.estateName);
                                        setEstateMainCommune(estate.mainCommune);
                                        setEstateMainAppellation(estate.mainAppellation);
                                        setCommercialAddress({ simple: estate.businessAddress?.address });
                                        setCommercialPostalCode(estate.businessAddress?.postalCode);
                                        setCommercialInseeCode(estate.communeINSEEcode);
                                        setCommercialCommune(
                                            estate.businessAddress?.commune ?? estate.estateMainCommune
                                        );
                                        setOrphanWineEntityId(estate.wineEntityId);
                                        setPostalCode("");
                                    }
                                }}
                                includeInputInList
                                options={orphanData?.GetOrphanWineEntities || []}
                                noOptionsText={
                                    <Typography
                                        style={{
                                            fontStyle: "italic",
                                            fontSize: "12px",
                                            color: theme.palette.secondary.dark,
                                        }}
                                    >
                                        Aucune correspondance trouvée
                                    </Typography>
                                }
                                onInputChange={(event, value, reason) => {
                                    if (reason === "clear") {
                                        setEstateMainCommune("");
                                        setEstateMainAppellation("");
                                    } else {
                                        setSearchString(value);
                                        debouncer({ variables: { searchString: value } });
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        margin="normal"
                                        variant="outlined"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {params.InputProps.endAdornment}
                                                    <img
                                                        src={searchIcon}
                                                        style={{
                                                            width: "18px",
                                                        }}
                                                    />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        ) : (
                            <SimpleInput
                                style={{ marginBottom: theme.spacing(3) }}
                                value={estateName}
                                onChange={(e) => setEstateName(e?.target?.value)}
                                onBlur={() => setEstateName(sanitizeName(estateName))}
                            />
                        )}
                        <Box className={styles.container_form}>
                            <Box className={styles.container_appelation}>
                                <AutocompleteSelectInput
                                    required
                                    label="Appellation principale"
                                    value={estateMainAppelation}
                                    onChange={(e) => setEstateMainAppellation(e)}
                                    correctValue={!!estateMainAppelation}
                                    options={appellationsByCommune()}
                                ></AutocompleteSelectInput>
                            </Box>
                            <Box className={styles.container_commune}>
                                <AutocompleteSelectInput
                                    borderRadius="4px 0px 0px 4px"
                                    label="Commune"
                                    onChange={(e) => {
                                        if (e) {
                                            setPostalCode(e.communeName);
                                            setEstateMainCommune(e.name);
                                            setEstateMainCommuneCodeInsee(e?.INSEEcode);
                                        } else {
                                            setPostalCode("");
                                            setEstateMainCommune("");
                                            setEstateMainCommuneCodeInsee("");
                                        }
                                    }}
                                    value={postalCode || estateMainCommune}
                                    boxStyle={{
                                        marginRight: theme.spacing(2),
                                        width: "100%",
                                    }}
                                    options={!estateMainAppelation ? allCommunes() : communesByAppellations()}
                                    getOptionLabel={(option) => {
                                        return option.communeName || option || "";
                                    }}
                                    correctValue={!!estateMainCommune}
                                />
                            </Box>
                            {(props.subscriptionState.selectedPack === SUBSCRIPTION_TYPES.basic || freeTrial) && (
                                <>
                                    <Box className={styles.container_company_status}>
                                        <AutocompleteSelectInput
                                            borderRadius="4px 0px 0px 4px"
                                            label="Statut"
                                            enumField="LEGAL_STATUS"
                                            onChange={(e) => setCompanyStatus(e)}
                                            value={companyStatus}
                                            boxStyle={{
                                                marginRight: theme.spacing(2),
                                                width: "100%",
                                            }}
                                        />
                                    </Box>
                                    <Box className={styles.container_company_name}>
                                        <SimpleInput
                                            borderRadius="0px 4px 4px 0px"
                                            label="Raison sociale"
                                            required
                                            onChange={(e) => setCompanyName(e?.target?.value)}
                                            value={companyName}
                                            boxStyle={{ marginRight: theme.spacing(2) }}
                                            correctValue={companyName}
                                        />
                                    </Box>
                                </>
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: "1rem" }}>
                        <Divider />
                    </Grid>

                    <Grid item xs={12}>
                        <TitleAboutProperty
                            title="Adresse commerciale *"
                            subtitle="Là où vous recevez du public pour des ventes ou des activités."
                        />
                        <FormControl>
                            <Grid item xs={12}>
                                <AddressAppellationAutocomplete
                                    filterByRegion="33"
                                    postalCode={commercialPostalCode}
                                    onChangePostalCode={(e) => setCommercialPostalCode(e)}
                                    extraAddress={extraAddress}
                                    onChangeExtraAddress={(e) => setExtraAddress(e)}
                                    commune={commercialCommune}
                                    onChangeCommune={(value) => setCommercialCommune(value)}
                                    onChangeCommuneInseeCode={(value) => setCommercialInseeCode(value)}
                                    onChangeAddress={(e, d) => {
                                        setCommercialAddress(e);
                                        if (d) {
                                            setCommercialPostalCode(d?.postcode);
                                            setCommercialInseeCode(d?.citycode);
                                            setCommercialCommune(d?.city);
                                        }
                                    }}
                                    address={commercialAddress}
                                    onError={(value) => setBordeauxVineyardError(value)}
                                    error={bordeauxVineyardError}
                                />
                            </Grid>
                            {!freeTrial && (
                                <Box style={{ marginTop: theme.spacing(2) }}>
                                    <BaseCheckbox
                                        checked={checked}
                                        onChange={() => setChecked(!checked)}
                                        labelStyle={{
                                            fontSize: "14px",
                                            marginBottom: theme.spacing(2),
                                        }}
                                        label={
                                            <Typography
                                                variant="h5"
                                                style={{
                                                    color: theme.palette.primary.dark,
                                                    fontSize: "14px",
                                                    marginBottom: theme.spacing(2),
                                                }}
                                            >
                                                Je garantis être légitime à la création d&apos;un tableau de bord sur{" "}
                                                <i> Bordeaux et ses Vins </i> dédié à la présentation de la propriété,
                                                de ses vins et à la création de ses fiches techniques.
                                            </Typography>
                                        }
                                        styleCheckbox={{
                                            paddingLeft: 0,
                                            alignSelf: "flex-start",
                                        }}
                                    />
                                </Box>
                            )}
                        </FormControl>
                    </Grid>
                    <Box style={{ width: "100%" }}>
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <BaseButton
                                onClick={() => handleSubmit()}
                                type="submit"
                                color="primary"
                                variant="contained"
                                size="small"
                                disabled={
                                    (!freeTrial && !checked) ||
                                    !estateName ||
                                    !commercialCommune ||
                                    !estateMainAppelation ||
                                    !estateMainCommune ||
                                    !commercialAddress?.simple ||
                                    bordeauxVineyardError ||
                                    getEstateNameValidation() ||
                                    ((props.subscriptionState.selectedPack === SUBSCRIPTION_TYPES.basic || freeTrial) &&
                                        !companyName)
                                }
                            >
                                {props.adoptingEstate ? "Suivant" : "Inscrire cette propriété"}
                            </BaseButton>
                        </Box>
                    </Box>
                </Grid>
            </SubscriptionContainer>
        );
    } else {
        return <Redirect to={{ pathname: "/subscription/0" }} />;
    }
};

AboutPropertyContainer.propTypes = {
    subscriptionDispatch: string,
    subscriptionState: object,
    checkIfPreviousStepIsValid: func,
    estateNotFound: bool,
    onClickBack: func,
    onClickNext: func,
    switchCheck: bool,
    handleSwitchChange: func,
    fakeEstateAdopted: object,
    setSearchMode: func,
    searchMode: bool,
    createSubloading: bool,
    open: bool,
    onOpen: func,
    onClose: func,
};

export default AboutPropertyContainer;
