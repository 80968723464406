import { Box, Button, Slider, Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Add, Remove } from "@material-ui/icons";
import { Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
    formControl: {
        position: "relative",
        background: theme.palette.common.white,
        margin: 0,
        width: "100%",
        // minWidth: 120,
        // maxWidth: 300,
        border: `0.4px solid ${theme.palette.secondary.main}`,
        borderRadius: "53px",
        marginTop: "0px !important",

        "& .MuiInput-underline:before": {
            borderBottom: "none !important",
        },

        "& .MuiInput-underline:focus": {
            borderBottom: "none !important",
        },

        "& .MuiSelect-select:focus": {
            backgroundColor: "transparent",
        },

        "& label + .MuiInput-formControl": {
            marginTop: 0,
        },

        "& .MuiInput-underline.Mui-focused:after": {
            transform: "scaleX(0)",
        },

        "& .MuiSvgIcon-root": {
            fontSize: "1rem",
            marginRight: 10,
        },
    },
    outlinedSelect: {
        display: "flex",
        "&:focus": {
            background: "none",
        },
    },
    checkbox: {
        color: theme.palette.primary.light,

        "&.MuiCheckbox-colorSecondary.Mui-checked": {
            color: theme.palette.primary.light,
        },
    },
    listItemText: {
        color: theme.palette.common.black,
    },
    menuItem: {
        backgroundColor: theme.palette.common.white,
        paddingTop: 0,
        paddingBottom: 0,
        display: "flex",
        justifyContent: "space-between",
        minHeight: "auto",
        marginBottom: "0px",
        marginTop: "0.5rem",
        width: 215,

        "&:hover, &.MuiListItem-root.Mui-selected, &.MuiListItem-root.Mui-selected:hover": {
            backgroundColor: theme.palette.common.white,
        },

        "& .MuiIconButton-label": {
            width: "11px",
            height: "11px",
        },
    },
    inputLabel: {
        transform: "none !important",
        marginTop: 6,
        marginLeft: 10,
    },
    paper: {
        boxSizing: "border-box",
        minWidth: 109,
        borderRadius: 10,
        overflow: "hidden",
        margin: "0 auto",
        marginTop: 3,
        paddingBottom: 8,
        paddingTop: 8,
        overflowY: "hidden",
        overflowX: "hidden",
        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.08)",
    },
    MenuList: {
        width: "100%",
        maxHeight: 220,
        overflow: "hidden",
    },
    slide: {
        width: "100%",

        [theme.breakpoints.up("sm")]: {
            width: 187,
        },
    },
    input: {
        width: 42,
    },
    btnPerson: {
        borderRadius: "100%",
        width: 20,
        height: 20,
        padding: 0,
        minWidth: "auto",
        border: `0.5px solid ${theme.palette.wine.main}`,
        color: "#90174A",

        "&.Mui-disabled": {
            borderColor: theme.palette.secondary.dark,
        },
    },
}));

const SelectWithSlide = (props) => {
    // const [filter, setFilter] = useState(props?.filter);
    const theme = useTheme();
    const styles = useStyles();
    const [isOpen, setIsOpen] = useState(false);

    const MenuProps = {
        PaperProps: {
            className: styles.paper,
        },
        MenuListProps: {
            className: styles.MenuList,
        },
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
        },
        variant: "menu",
        getContentAnchorEl: null,
    };

    return (
        <Box>
            <FormControl
                className={styles.formControl}
                style={{
                    borderColor: props?.filter > 0 ? theme.palette.primary.dark : theme.palette.secondary.main,
                    backgroundColor:
                        props?.filter > 0 ? theme.palette.action.disabledBackground : theme.palette.common.white,
                }}
            >
                <InputLabel className={styles.inputLabel}>
                    <Typography variant="h6">{props?.label}</Typography>
                </InputLabel>
                <Select
                    variant="outlined"
                    className={styles.select}
                    multiple
                    value={[props?.filter]}
                    // onChange={handleChange}
                    input={<Input />}
                    renderValue={() => false}
                    MenuProps={MenuProps}
                    classes={{
                        root: styles.selectRoot,
                        outlined: styles.outlinedSelect,
                    }}
                    onOpen={() => setIsOpen(true)}
                    onClose={() => setIsOpen(false)}
                    open={isOpen}
                    IconComponent={() =>
                        isOpen ? (
                            <KeyboardArrowUpIcon onClick={() => setIsOpen(!isOpen)} style={{ cursor: "pointer" }} />
                        ) : (
                            <KeyboardArrowDownIcon onClick={() => setIsOpen(!isOpen)} style={{ cursor: "pointer" }} />
                        )
                    }
                >
                    <Box className={styles?.menuItem}>
                        <Box
                            style={{
                                display: "grid",
                                gridTemplateColumns: "repeat(12, 1fr)",
                                paddingRight: 14,
                                paddingLeft: 14,
                                width: "100%",
                            }}
                        >
                            <Box style={{ gridColumn: "1 / span 6", display: "flex", justifyContent: "flex-start" }}>
                                <Typography
                                    variant="h6"
                                    style={{ marginLeft: 5, marginRight: 0, color: theme.palette.primary.dark }}
                                >
                                    <Trans>Nombre de personnes</Trans>
                                </Typography>
                            </Box>

                            <Box
                                style={{
                                    gridColumn: "auto / span 6",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    className={styles.btnPerson}
                                    onClick={() => props?.setValueSlide(props?.valueSlide - 1)}
                                    disabled={props?.valueSlide <= 0}
                                >
                                    <Remove style={{ marginLeft: "2px", fill: theme.palette.wine.main, fontSize: 9 }} />
                                </Button>

                                <Typography
                                    variant="h6"
                                    style={{ margin: "0 11px", color: theme.palette.primary.dark }}
                                >
                                    {props?.valueSlide ?? 0}
                                </Typography>

                                <Button
                                    variant="outlined"
                                    className={styles.btnPerson}
                                    onClick={() => props?.setValueSlide(props?.valueSlide + 1)}
                                    disabled={props?.valueSlide >= 200}
                                >
                                    <Add style={{ marginLeft: "0px", fill: theme.palette.wine.main, fontSize: 9 }} />
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Select>
            </FormControl>
        </Box>
    );
};

SelectWithSlide.propTypes = {
    options: PropTypes.array,
    label: PropTypes.string,
    filter: PropTypes.array,
    filterName: PropTypes.string,
    filters: PropTypes.object,
    handleClick: PropTypes.func,
    winesResults: PropTypes.array,
    setSearchResult: PropTypes.func,
    valueSlide: PropTypes.any,
    setValueSlide: PropTypes.func,
    hideNbInSelect: PropTypes.bool,
};

export default SelectWithSlide;
