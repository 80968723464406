import React from "react";
import PropTypes from "prop-types";
import theme from "../../globalTheme";
import { makeStyles, Button } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    root: {
        border: "none",
        cursor: "pointer",
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        width: "100%",
        borderRadius: "53px",

        "&:hover": {
            backgroundColor: theme.palette.primary.light,
        },
    },
}));

const ModalSearchFilters = (props) => {
    const styles = useStyles();

    return (
        <Button className={styles.root} onClick={props?.onClick}>
            {props?.label}
        </Button>
    );
};

ModalSearchFilters.propTypes = {
    onClick: PropTypes.func,
    label: PropTypes.any,
};

export default ModalSearchFilters;
