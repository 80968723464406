import Loading from "Components/Loader/Loading";
import { arrayOf, bool, node, number, object, oneOf, string } from "prop-types";

export const LoadWrapper = ({ loading, children, style, className, size }) => {
    return (
        <div className={className} style={style}>
            {loading ? <Loading size={size} /> : children}
        </div>
    );
};

LoadWrapper.propTypes = {
    loading: bool,
    children: oneOf([node, arrayOf(node)]),
    style: object,
    className: string,
    size: number,
};
