import React, { useEffect } from "react";

import { makeStyles, TextField, InputAdornment, Typography, Box } from "@material-ui/core";
import { any, bool, func, number, object, string } from "prop-types";
import CheckIcon from "@material-ui/icons/Check";
import AdditionalInformationTooltip from "../Tooltips/AdditionalInformationTooltip";
import ErrorIcon from "../../assets/Error.svg";
import searchIcon from "../../assets/SubscriptionPage/searchIcon.svg";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    root: (props) => {
        const getBorderRadius = () => {
            if (props.table) {
                return "0px";
            } else if (props.borderRadius) {
                return props.borderRadius;
            } else {
                return "4px";
            }
        };
        return {
            order: props?.iconPosition === "start" ? 2 : 1,
            width: "100%",
            "&::placeholder": {
                fontStyle: "italic",
            },
            "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: props?.borderWidth ?? "1px",
                borderRadius: getBorderRadius(),
                borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
            },
            "& .MuiOutlinedInput-root": {
                borderRadius: getBorderRadius(),
                fontSize: "14px",
                color: theme.palette.primary.dark,
                borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
                backgroundColor: props?.bgTransparent ? "transparent" : "white",
                height: "34px",
                "&.Mui-disabled": {
                    color: theme.palette.secondary.dark,
                    backgroundColor: theme.palette.disabled.main,
                },
                "&:hover fieldset": {
                    borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
                },
                "&.Mui-focused fieldset": {
                    border: "1px solid",
                    borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
                },
            },
            "& .MuiFormLabel-root": {
                fontSize: "14px",
                color: props.error ? theme.palette.error.main : theme.palette.secondary.dark,
            },
        };
    },
    boxContainer: {
        position: "relative",
        width: "100%",
    },
    checkIcon: {
        width: "20px",
        fill: theme.palette.success.main,
    },
    falseIcon: {
        width: "20px",
        fill: theme.palette.error.main,
    },
    toolTipBox: {
        position: "absolute",
        right: "8px",
        top: "-8px",
        zIndex: 2,
        cursor: "default",
    },
    errorText: {
        fontSize: "12px",
        color: theme.palette.error.main,
        marginLeft: "8px",
    },
    searchIcon: {
        width: "18px",
    },
}));

const SimpleInput = (props) => {
    const styles = useStyles({ ...props });

    useEffect(() => {
        if (props.onBlur) {
            props.onBlur();
        }
    }, [props.disabled]);

    return (
        <Box className={clsx(styles.boxContainer, props.className)} style={{ ...props.boxStyle }}>
            {props.tooltipTitle && (
                <Box className={styles.toolTipBox}>
                    <AdditionalInformationTooltip color="primary" title={props.tooltipTitle} arrow />
                </Box>
            )}
            <TextField
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        if (props.onEnterPressed) {
                            props.onEnterPressed(e);
                        } else if (props.onBlur) {
                            props.onBlur(e);
                        }
                    }
                }}
                type={props.type}
                autoComplete={props.autoComplete}
                id={props.id}
                variant="outlined"
                size="small"
                label={props.label}
                placeholder={props.placeholder}
                disabled={props.disabled}
                required={props.required}
                error={props.error}
                value={props.value || ""}
                onChange={(e) => props.onChange(e)}
                onFocus={(e) => {
                    if (props.onFocus) {
                        props.onFocus(e);
                    }
                }}
                onBlur={(e) => {
                    if (props.onBlur) {
                        props.onBlur(e);
                    }
                }}
                onPaste={(e) => props.onPaste && props.onPaste(e)}
                style={{ ...props.style }}
                className={styles.root}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    type: props.type,
                    autoComplete: props.autoComplete,
                    id: props.id,
                }}
                InputProps={{
                    type: props.type,
                    classes: { input: styles.root },
                    endAdornment: !props.startAdornment && (
                        <InputAdornment position="end" style={{ order: props?.iconPosition === "start" ? 1 : 2 }}>
                            {props.search && <img className={styles.searchIcon} src={searchIcon} />}
                            {props.correctValue && <CheckIcon className={styles.checkIcon} />}
                            {props.error && <img src={ErrorIcon} className={styles.falseIcon} />}
                        </InputAdornment>
                    ),
                    startAdornment: props.startAdornment && (
                        <InputAdornment position="start">
                            {props.customIcon ?? null}

                            {props.search && <img className={styles.searchIcon} src={searchIcon} />}
                        </InputAdornment>
                    ),
                }}
            />
            {props.error && !props.table && (
                <Typography variant="h6" className={styles.errorText}>
                    {" " + props.error}
                </Typography>
            )}
        </Box>
    );
};

SimpleInput.propTypes = {
    label: string,
    placeholder: string,

    value: string,
    onChange: func,
    onBlur: func,
    onFocus: func,
    onPaste: func,
    startAdornment: bool,
    endAdornment: bool,
    disabled: bool,
    error: string,
    required: bool,

    style: object,
    boxStyle: object,
    bgTransparent: bool,
    customIcon: any,
    correctValue: bool,
    tooltipTitle: string,
    table: bool,
    autoFocus: bool,
    id: string,
    type: string,
    autoComplete: string,
    search: bool,
    borderWidth: number,
    iconPosition: string,
    onEnterPressed: func,
    className: string,
};

export default React.memo(SimpleInput);
