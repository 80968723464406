import { Badge, Box, makeStyles, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { useSearch } from "../../hooks";

import bg from "../../assets/searchEngine/wine/bg-wine-step-1.jpg";
import SelectFilterPerPage from "../../Components/Inputs/SelectInputFilter";
import SimpleInput from "../../Components/Inputs/SimpleInput";
import theme from "../../globalTheme";
import { useWindowDimensions } from "../../helpers/helpers";
import SearchResultWineCard from "../../Search/card/SearchResultWineCard";
import SearchWineModal from "../../Search/modal/SearchWineModal";
import ButtonMoreFilters from "../../Search/ui/ButtonMoreFilters";
import ButtonResetFilters from "../../Search/ui/ButtonResetFilters";
import SearchMainContainer from "../../Search/SearchMainContainer";
import SearchTabs from "../../Search/SearchTabs";
import SelectButtonOrCheckbox from "../../Search/SelectButtonOrCheckbox";
import { useSearchWinesQuery } from "../../generated/graphql";
import AutocompleteCheckboxSearch from "../../Search/AutocompleteCheckboxSearch";

import BreadCrumbs from "../../Components/Breadcrumbs/BreadCrumbs";
import { Plural, t, Trans } from "@lingui/macro";
import Page500Front from "Components/ErrorPage/Page500Front";
import { useEnumValuesState } from "hooks/useEnumValues";
import { NoMatchingResult } from "Search/ui/NoMatchingResult";
import Helmet from "Components/Helmet/Helmet";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(() => ({
    root: {
        "&::after": {
            // eslint-disable-next-line quotes
            content: '""',
            position: "absolute",
            zIndex: -1,
            top: "-5rem",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundImage: `url(${bg})`,
            backgroundRepeat: "no-repeat",
            backgroundPositionY: "top",
            backgroundPositionX: "center",
            backgroundSize: "cover",
        },

        minHeight: "calc(100vh - 5rem)",
        gridTemplateColumns: "repeat(12, 1fr)",
        display: "grid",
    },
    main_container: {
        gridColumn: "1 / span 12",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "22px 0 2rem",

        [theme.breakpoints.up("sm")]: {
            padding: "22px 1rem 2rem",
            paddingTop: 80,
        },

        [theme.breakpoints.up("md")]: {
            paddingTop: 80,
        },

        [theme.breakpoints.up("xl")]: {
            width: "calc(1314px)",
            margin: "auto",
        },
    },
    title: {
        color: theme.palette.common.white,
        fontWeight: 400,
        textAlign: "center",
        marginBottom: "1rem",
    },
    headerContainer: {
        gridColumn: "1 / span 12",
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        gap: "7px 6px",
        marginBottom: 13,

        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 4",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 3",
        },

        [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "repeat(18, 1fr)",
        },
    },
    search: {
        display: "none",

        [theme.breakpoints.up("sm")]: {
            display: "block",
            gridColumn: "1 / span 8",
            gridRow: "1 / span 1",
        },

        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 4",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 5",
        },

        [theme.breakpoints.up("xl")]: {
            gridColumn: "1 / span 5",
        },
    },
    color: {
        display: "none",
        position: "relative",

        [theme.breakpoints.up("md")]: {
            display: "block",
            gridColumn: "auto / span 2",
            gridRow: "1 / span 1",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 3",
            gridRow: "1 / span 1",
        },

        [theme.breakpoints.up("xl")]: {
            gridColumn: "auto / span 3",
        },
    },
    appellation: {
        display: "none",
        position: "relative",

        [theme.breakpoints.up("md")]: {
            display: "block",
            gridColumn: "auto / span 3",
            gridRow: "1 / span 1",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 3",
            gridRow: "1 / span 1",
        },

        [theme.breakpoints.up("xl")]: {
            gridColumn: "auto / span 3",
        },
    },
    millesime: {
        display: "none",
        position: "relative",

        [theme.breakpoints.up("md")]: {
            display: "block",
            gridColumn: "auto / span 3",
            gridRow: "1 / span 1",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 3",
            gridRow: "1 / span 1",
        },

        [theme.breakpoints.up("xl")]: {
            gridColumn: "auto / span 3",
        },
    },
    label: {
        position: "relative",
        display: "none",

        [theme.breakpoints.up("md")]: {
            display: "block",
            gridColumn: "auto / span 3",
            gridRow: "1 / span 1",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 2",
            gridRow: "1 / span 1",
        },

        [theme.breakpoints.up("xl")]: {
            gridColumn: "auto / span 2",
        },
    },
    badge: {
        display: "none",

        [theme.breakpoints.up("md")]: {
            display: "block",
            gridColumn: "auto / span 2",
            gridRow: "1 / span 1",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 2",
            gridRow: "1 / span 1",
        },

        [theme.breakpoints.up("xl")]: {
            gridColumn: "auto / span 2",
        },
    },
    btnFilters: {
        position: "relative",
        gridColumn: "1 / span 8",
        gridRow: "1 / span 1",
        width: "100%",

        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 4",
        },

        [theme.breakpoints.up("md")]: {
            gridColumn: "13 / span 4",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "13 / span 2",
        },
    },
    badgeNotif: {
        position: "absolute",
        right: 10,
        top: 4,
        border: "none",
        padding: "0 4px",

        "& .MuiBadge-badge": {
            fontWeight: 700,
            background: theme.palette.primary.dark,
            color: theme.palette.common.white,
        },
    },
    badgeSearchAdvanced: {
        top: 6,

        [theme.breakpoints.down("md")]: {
            right: -7,
        },
    },
    filterResultBox: {
        gridColumn: "1 / span 12",
        gridRow: "3 / span 1",
        display: "flex",
        alignItems: "flex-start",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        },
    },
    deleteFiltersBox: {
        display: "flex",
        justifyContent: "flex-end",
    },
    separator: {
        gridColumn: "1 / span 18",
        gridRow: "2 / span 1",
        width: "100%",
        height: 0.4,
        backgroundColor: theme.palette.secondary.main,
    },
    nbResultTypo: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        marginTop: 0,
    },

    resultType: {
        color: theme.palette.secondary.dark,
        fontWeight: 400,
    },
    perPageFilterResult: {
        gridColumn: "9 / span 12",
        gridRow: "1 / span 1",
        marginTop: 0,

        [theme.breakpoints.up("sm")]: {
            gridColumn: "15 / span 4",
            gridRow: "3 / span 1",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "15 / span 4",
            gridRow: "3 / span 1",
        },
    },
    perPageFilterNoResult: {
        gridColumn: "5 / span 13",
        gridRow: "1 / span 1",
        marginTop: 0,

        [theme.breakpoints.up("sm")]: {
            gridColumn: "15 / span 4",
            gridRow: "6 / span 1",
            marginTop: 0,
        },

        [theme.breakpoints.up("md")]: {
            gridColumn: "15 / span 4",
            gridRow: "5 / span 1",
            marginTop: 0,
            alignSelf: "center",
        },
    },
    containerCards: {
        display: "grid",
        gridTemplateColumns: "repeat(1, 1fr)",
        gap: 20,

        [theme.breakpoints.up("sm")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
        },

        [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "repeat(3, 1fr)",
        },

        [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "repeat(4, 1fr)",
        },
    },
    card: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
    },
    pagination: {
        gridColumn: "1 / span 12",
        display: "flex",
        justifyContent: "flex-end",

        "& .MuiPaginationItem-page.Mui-selected": {
            color: theme.palette.primary.light,
            border: `1px solid ${theme.palette.primary.light}`,
            height: "fit-content",
            backgroundColor: "inherit",
            fontWeight: 700,
        },
    },
    searchAdvancedBox: {
        marginRight: 40,
        position: "relative",
        gridColumn: "16 / span 3",
        gridRow: "1 / span 1",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 3,

        [theme.breakpoints.up("md")]: {
            gridColumn: "17 / span 2",
            marginRight: 0,
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "17 / span 2",
        },

        [theme.breakpoints.up("xl")]: {
            gridColumn: "17 / span 2",
        },
    },
    searchAdvancedBtn: {
        background: "transparent",
        border: "none",
        color: theme.palette.primary.dark,
        textDecoration: "underline",
        cursor: "pointer",
        padding: 0,
        display: "flex",
        justifyContent: "center",
    },
    searchCTA: {
        display: "none",

        [theme.breakpoints.up("sm")]: {
            display: "flex",
            gridColumn: "9 / span 7",
            gridRow: "1 / span 1",
        },

        [theme.breakpoints.up("md")]: {
            gridColumn: "11 / span 5",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "13 / span 4",
        },
    },
    breadcrumbs: {
        paddingLeft: "1rem",
    },
}));

const SearchResultWine = () => {
    const styles = useStyles();
    const { width } = useWindowDimensions();
    const [modalIsShown, setModalIsShown] = useState(false);
    const location = useLocation();

    const { data: appellationLabels, loading: appellationLoading } = useEnumValuesState("APPELLATION_LABEL");
    const { data: classificationOrganism, loading: classificationLoading } = useEnumValuesState(
        "CLASSIFICATION_ORGANISM"
    );
    // const labelCertifWine = useEnumValues("LABEL_CERTIFICATION_WINE", { locale });

    const [searchTextDisplay, setSearchTextDisplay] = useState("");
    const [pageSize, setPageSize] = useState(12);
    const [textFocused, setTextFocused] = useState(false);
    const [prevFilters, setPrevFilters] = useState({});
    const {
        loading,
        results,
        resetFilters,
        filters,
        setFilter,
        counts,
        totalCount,
        pageNumber,
        setPageNumber,
        similar,
        filtersCount,
        errors,
        setAllFilters,
        resetFields,
    } = useSearch(
        {
            apogee: [1850, 2100],
            searchText: null,
            colors: [],
            appellations: [],
            years: [],
            sweetness: [],
            alcoolPercentage: [],
            allergens: [],
            wineWithoutAddedSulphite: false,
            doesNotContainOGM: false,
            attack: [],
            acidity: [],
            tanins: [],
            length: [],
            levurages: [],
            plotVinification: false,
            barrelAging: "undefined",
            frenchOak: false,
            onLies: false,
            durationAgingBarrels: [],
            newBarrels: [],
            heatingBarrels: [],
            filtration: "undefined",
            bonding: "undefined",
            typeOfClosures: [],
            waxedBottle: false,
            bottlingAtTheProperty: false,
            onlineSales: false,
            reward: false,
            score: false,
            labels: [],
            priceRange: [1, 501],
            typeOfHarvest: [],
            cepages: [],
            oenologists: [],
            typesMacerations: [],
            classificationOrganisms: [],
        },
        useSearchWinesQuery,
        "searchWines",
        pageSize
    );

    useEffect(() => {
        setSearchTextDisplay(filters?.searchText);
    }, [filters?.searchText]);

    useEffect(() => {
        if (location?.state?.appellation) {
            setFilter("appellations", [location?.state?.appellation]);
        }
        if (location?.state?.profil) {
            const color = location?.state?.profil?.color;
            const sweetness = location?.state?.profil?.sweetness;
            setFilter("colors", [color]);
            setFilter("sweetness", [sweetness]);
        }
    }, [location]);
    const [displayedSimilar, setDisplayedSimilar] = useState(similar);
    const [displayedResults, setDisplayedResults] = useState(results);
    const [displayedTotalCount, setDisplayedTotalCount] = useState(totalCount);

    const trueResults = modalIsShown ? displayedResults : results;
    const trueSimilar = modalIsShown ? displayedSimilar : similar;
    const trueTotalCount = modalIsShown ? displayedTotalCount : totalCount;

    const getStringNumResults = () => {
        if (trueTotalCount > 0) {
            return <Plural value={trueTotalCount} one="# résultat trouvé" other="# résultats trouvés" />;
        } else {
            return t`Aucun résultat trouvé`;
        }
    };

    const breadcrumbs = [
        {
            name: t`Recherche`,
            path: "/recherche",
        },
        {
            name: t`Vins`,
            path: "/recherche/vins",
        },
        {
            name: t`Résultats`,
        },
    ];

    if (errors) {
        return <Page500Front />;
    }

    return (
        <>
            <Helmet title={t`Résultats vins`} />

            <Box className={styles.root}>
                <BreadCrumbs links={breadcrumbs} className={styles.breadcrumbs} />
                <Box className={styles.main_container}>
                    <Typography variant="h2" className={styles.title}>
                        <Trans>Parcourez le vignoble bordelais</Trans>
                    </Typography>
                    <SearchTabs active="wine" redirectToResult />

                    <SearchMainContainer>
                        <Box className={styles.headerContainer}>
                            <Box className={styles.search}>
                                <SimpleInput
                                    onFocus={() => setTextFocused(true)}
                                    search
                                    borderWidth={0}
                                    size="small"
                                    name="search"
                                    placeholder={t`Chercher un vin...`}
                                    variant="outlined"
                                    onBlur={(e) => {
                                        if (textFocused) {
                                            setFilter("searchText", e?.target?.value);
                                        }
                                    }}
                                    onChange={(e) => setSearchTextDisplay(e?.target?.value)}
                                    value={searchTextDisplay}
                                    boxStyle={{
                                        backgroundColor: theme.palette.common.white,
                                        borderRadius: "53px",
                                        border: "none",
                                    }}
                                    bgTransparent
                                    borderRadius={53}
                                    iconPosition="start"
                                />
                            </Box>

                            <Box className={styles.color}>
                                <Badge badgeContent={filters?.colors?.length} className={styles.badgeNotif} />
                                <SelectButtonOrCheckbox
                                    label={t`Couleur`}
                                    counts={counts?.["colors"]}
                                    options={[t`Rouge`, t`Blanc`, t`Rosé`]}
                                    filter={filters["colors"]}
                                    filterName="colors"
                                    setFilters={(colors) => setFilter("colors", colors)}
                                    hideNbInSelect
                                />
                            </Box>

                            <Box className={styles.appellation}>
                                <Badge badgeContent={filters?.appellations?.length} className={styles.badgeNotif} />
                                <AutocompleteCheckboxSearch
                                    class={styles.category_filter}
                                    label={t`Appellation`}
                                    options={appellationLabels}
                                    value={filters?.appellations}
                                    onChange={(e1, e2) => setFilter("appellations", e2)}
                                    loading={appellationLoading}
                                    sortAlphabetical
                                    popupIcon
                                    uppercase
                                />
                            </Box>

                            <Box className={styles.millesime}>
                                <Badge
                                    badgeContent={filters?.classificationOrganisms?.length}
                                    className={styles.badgeNotif}
                                />
                                <AutocompleteCheckboxSearch
                                    class={styles.category_filter}
                                    label={t`Classement`}
                                    options={classificationOrganism}
                                    value={filters?.classificationOrganisms}
                                    onChange={(e1, e2) => setFilter("classificationOrganisms", e2)}
                                    loading={classificationLoading}
                                    sortAlphabetical
                                    uppercase
                                    popupIcon
                                />
                            </Box>

                            {/* <Box className={styles.label}>
                                <Badge badgeContent={filters?.labels?.length} className={styles.badgeNotif} />
                                <AutocompleteCheckboxSearch
                                    class={styles.category_filter}
                                    label={t`Label`}
                                    options={labelCertifWine}
                                    value={filters?.labels}
                                    onChange={(e1, e2, e3) => setFilter("labels", e2)}
                                    sortAlphabetical
                                    popupIcon
                                    uppercase
                                />
                            </Box> */}

                            {width < 600 && (
                                <Box className={styles.btnFilters}>
                                    <Badge badgeContent={filtersCount} className={styles.badgeNotif} />
                                    <ButtonMoreFilters
                                        onClick={() => {
                                            setPrevFilters(filters);
                                            setDisplayedResults(results);
                                            setDisplayedTotalCount(totalCount);
                                            setDisplayedSimilar(similar);
                                            setModalIsShown(true);
                                        }}
                                    />
                                </Box>
                            )}

                            <Box className={styles.searchAdvancedBox}>
                                <Badge
                                    badgeContent={filtersCount}
                                    className={[styles.badgeNotif, styles.badgeSearchAdvanced].join(" ")}
                                />
                                <button
                                    className={styles.searchAdvancedBtn}
                                    onClick={() => {
                                        setPrevFilters(filters);
                                        setDisplayedResults(results);
                                        setDisplayedTotalCount(totalCount);
                                        setDisplayedSimilar(similar);
                                        setModalIsShown(true);
                                    }}
                                >
                                    <Typography variant="subtitle1">
                                        <Trans>Recherche avancée</Trans>
                                    </Typography>
                                </button>
                            </Box>

                            <Box className={styles.separator} />

                            <Box className={styles.filterResultBox}>
                                <Box className={styles.deleteFiltersBox}>
                                    <ButtonResetFilters
                                        onClick={() => {
                                            setSearchTextDisplay("");
                                            resetFilters();
                                        }}
                                        disabled={filtersCount === 0 && !searchTextDisplay}
                                    />
                                </Box>

                                <Box className={styles.nbResultTypo}>
                                    <Typography
                                        variant="subtitle1"
                                        className={styles.resultType}
                                        style={{ marginLeft: "5px" }}
                                    >
                                        {getStringNumResults()}
                                    </Typography>
                                </Box>
                            </Box>

                            <NoMatchingResult
                                display={!trueResults?.length || trueSimilar}
                                displaySimilar={trueResults?.length > 0 && trueSimilar}
                                resultCount={trueTotalCount}
                                similarLabel={
                                    <Trans>
                                        Chacun des vins ci-dessous présente un seul critère non respecté ou non précisé
                                        par le producteur.
                                    </Trans>
                                }
                                loading={loading}
                            />

                            {trueResults?.length > 0 && (
                                <SelectFilterPerPage
                                    searchOptions
                                    label="résultats"
                                    options={[12, 24, 48, 96]}
                                    value={pageSize}
                                    handleChange={(e) => {
                                        setPageSize(Number(e.target.value));
                                        setPageNumber(1);
                                    }}
                                    className={styles.perPageFilterResult}
                                />
                            )}
                        </Box>
                        <Box className={styles.containerCards}>
                            {trueResults?.map((wine) => (
                                <SearchResultWineCard
                                    wine={wine}
                                    sweetness={wine?.sweetness}
                                    wineId={wine?._id}
                                    key={wine?._id}
                                    name={wine?.brandName}
                                    years={wine?.years}
                                    color={wine?.color}
                                    appellation={wine?.appellation}
                                    wineEntityName={wine?.wineEntityName}
                                    reward={wine?.reward}
                                    score={wine?.score}
                                    missing={wine?.missingField}
                                    wineEntityId={wine?.wineEntityId}
                                    vintageIds={wine?.vintageIds}
                                />
                            ))}
                        </Box>

                        {trueResults?.length > 0 && (
                            <Box className={styles.pagination}>
                                <Pagination
                                    onChange={(_, n) => {
                                        setPageNumber(n);
                                        window.scrollTo({ top: 0 });
                                    }}
                                    page={pageNumber}
                                    count={trueTotalCount ? Math.floor(trueTotalCount / pageSize) + 1 : null}
                                    showFirstButton
                                    showLastButton
                                    className={styles.pagination}
                                    shape="rounded"
                                    size="small"
                                />
                            </Box>
                        )}
                    </SearchMainContainer>
                </Box>
            </Box>

            <SearchWineModal
                resetFields={resetFields}
                filtersCount={filtersCount}
                similar={similar}
                searchTextDisplay={searchTextDisplay}
                setSearchTextDisplay={(v) => setSearchTextDisplay(v)}
                isOpen={modalIsShown}
                onClose={() => setModalIsShown(false)}
                onCloseCross={() => {
                    setAllFilters(prevFilters);
                    setModalIsShown(false);
                }}
                titlePrimary={t`Tous les filtres`}
                titleSecondary={t`Vins`}
                clearFilters={() => resetFilters()}
                filters={filters}
                setFilter={(fieldname, v) => setFilter(fieldname, v)}
                totalCount={totalCount}
            />
        </>
    );
};

export default SearchResultWine;
