import React from "react";

import { makeStyles, Grid, IconButton, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import logo_feret from "../../assets/Feret_LogoMark_FullColour_50.svg";
import PropTypes from "prop-types";
import { ConnectedSwitchEstateSelect } from "../../Dashboard/Components/Navigation/SwitchEstateSelect";
import theme from "../../globalTheme";

// import bgImage from "../../assets/SubscriptionPage/background_color.webp";

const useStyles = makeStyles(() => ({
    container: {
        backgroundColor: theme.palette.dashboard.background,
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        [theme.breakpoints.up("sm")]: {
            height: "56px",
        },
        [theme.breakpoints.up("md")]: {},
        [theme.breakpoints.up("lg")]: {
            height: "64px",
        },
        [theme.breakpoints.up("xl")]: {},
    },
    getBackContainer: {
        display: "flex",
        alignItems: "center",
    },
    typo: {
        [theme.breakpoints.up("xs")]: {
            display: "none",
        },
        [theme.breakpoints.up("sm")]: {
            display: "inherit",
        },
        color: theme.palette.primary.dark,
        cursor: "pointer",
    },
    icon: {
        fontSize: "18px",
        color: theme.palette.primary.dark,
    },
    iconButton: {
        padding: theme.spacing(1),
        paddingRight: "3px",
    },
    logoContainer: {
        display: "flex",
        justifyContent: "center",
        margin: "auto 0",
        [theme.breakpoints.up("xs")]: {
            height: "32px",
        },
        [theme.breakpoints.up("md")]: {},
        [theme.breakpoints.up("lg")]: {
            height: "42px",
        },
        [theme.breakpoints.up("xl")]: {},
    },
    switchEstate: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
}));

const SubscriptionHeader = (props) => {
    const styles = useStyles(props);
    return (
        <Grid container className={styles.container} justify="space-between">
            <Grid item xs={4} className={styles.getBackContainer}>
                {props.onClickBack ? (
                    <>
                        <IconButton onClick={() => props.onClickBack()} className={styles.iconButton}>
                            <ArrowBackIcon className={styles.icon} />
                        </IconButton>
                        <Typography onClick={() => props.onClickBack()} variant="h6" className={styles.typo}>
                            Retour
                        </Typography>
                    </>
                ) : null}
            </Grid>
            <Grid item xs={4} className={styles.logoContainer}>
                <img src={logo_feret} alt="logo_feret" />
            </Grid>
            <Grid item xs={4} className={styles.switchEstate}>
                <ConnectedSwitchEstateSelect backgroundColor={theme.palette.dashboard.background} />
            </Grid>
        </Grid>
    );
};

SubscriptionHeader.propTypes = {
    onClickBack: PropTypes.func,
};

export default SubscriptionHeader;
