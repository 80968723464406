import React from "react";
import { makeStyles, Chip, Grid, Box } from "@material-ui/core";
import Tag from "./Tag";
import cross from "../../assets/whiteCross.svg";
import PropTypes, { object, string } from "prop-types";
import clsx from "clsx";

const useStyle = makeStyles((theme) => ({
    closeIcon: {
        color: "white",
    },
    chip: (props) => {
        return {
            "&.MuiChip-root": {
                height: "auto",
                borderRadius: "4px",
            },
            "&.MuiChip-outlinedPrimary": {
                padding: 0,
                backgroundColor: theme.palette.primary.light,
                "&:hover": {
                    padding: 0,
                    backgroundColor: theme.palette.primary.light,
                },
                "&:focus": {
                    padding: 0,
                    backgroundColor: theme.palette.primary.light,
                },
            },
            "&.MuiChip-outlinedSecondary": {
                padding: 0,
                backgroundColor: theme.palette.common.white,
                color: theme.palette.secondary.dark,
                "&:hover": {
                    padding: 0,
                    backgroundColor: theme.palette.common.white,
                },
                "&:focus": {
                    padding: 0,
                    backgroundColor: theme.palette.common.white,
                },
            },
            "& .MuiChip-deleteIcon": {
                marginRight: "4px",
                marginLeft: "2px",
                height: "9px",
                width: "9px",
                backgroundColor: theme.palette.primary.light,
            },
            "& .MuiChip-label": {
                whiteSpace: "break-spaces",
                padding: props.paddingLabel || "4px 8px",
            },
            fontSize: "14px",
            color: "#FFFFFF",
            border: "solid 0.2px",
            borderColor: theme.palette.secondary.dark,
            position: "relative",
            padding: 0,
            margin: 0,
            borderRadius: "2px",
            "&.Mui-disabled": {
                // backgroundColor: theme.palette.dashboard.background,
                // color: theme.palette.secondary.dark,
                // opacity: 1,
            },
        };
    },
}));

const getTags = (props) => {
    return (
        <Grid container spacing={1}>
            <Grid item>{props.notPublished && <Tag color="secondary" disabled={false} label="Non publié" />}</Grid>
        </Grid>
    );
};

const BaseChip = (props) => {
    const styles = useStyle(props);

    return (
        <Chip
            classes={props.classes}
            style={props.style}
            className={clsx(styles.chip, props.className)}
            disabled={props.disabled}
            label={props.title}
            onClick={props.chipOnClick}
            onDelete={!props.disabled && props.onDelete}
            deleteIcon={<img src={cross} alt="close_cross" className={styles.closeIcon} />}
            variant="outlined"
            color={props.color ?? "primary"}
            icon={<Box style={{ position: "absolute", right: 10, top: -10, zIndex: 2 }}>{getTags(props)}</Box>}
        ></Chip>
    );
};

BaseChip.propTypes = {
    title: PropTypes.string,
    notPublished: PropTypes.bool,
    onDelete: PropTypes.func,
    disabled: PropTypes.bool,
    chipOnClick: PropTypes.func,
    style: PropTypes.object,
    className: string,
    clickable: PropTypes.bool,
    breakSpace: PropTypes.bool,
    paddingLabel: string,
    color: string,
    classes: object,
};

export default BaseChip;
