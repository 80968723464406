import React from "react";
import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import BaseCheckbox from "../../Components/Inputs/BaseCheckbox";
import removeOrAddItems from "../../Pages/SearchEngine/helpers";

const SearchCheckbox = (props) => {
    return (
        <Box className={props.containerClassName ?? props.styles?.checkboxes}>
            {props.options?.map((item) => (
                <Box key={item} className={props.boxClassName}>
                    <BaseCheckbox
                        labelStyle={{
                            fontSize: "14px",
                        }}
                        disabled={props.disabled}
                        label={props.uppercaseFirstLetter ? item.charAt(0).toUpperCase() + item.slice(1) : item}
                        checked={props?.filters?.[props.filterName]?.includes(item)}
                        onChange={() => {
                            const newFilters = removeOrAddItems(item, props?.filters?.[props.filterName]);
                            props.setFilter(props.filterName, newFilters);
                        }}
                    />
                </Box>
            ))}
        </Box>
    );
};

SearchCheckbox.propTypes = {
    setFilter: PropTypes.func,
    filters: PropTypes.array,
    options: PropTypes.array,

    styles: PropTypes.object,
    filterName: PropTypes.string,
    boxClassName: PropTypes.string,
    containerClassName: PropTypes.string,
    uppercaseFirstLetter: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default SearchCheckbox;
