import { Grid, Link, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

import Modal from "../Containers/Modal";

const useStyles = makeStyles((theme) => ({
    container: {
        "&::-webkit-scrollbar": { width: "8px", height: "8px" },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            paddingRight: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "20px",
        },
    },
}));

const TunnelScrollingPopin = (props) => {
    const styles = useStyles(props);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Link onClick={handleOpen} style={{ cursor: "pointer" }}>
                {props.clickableElement}
            </Link>
            <Modal
                actionNeeded={false}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={styles.modal}
                open={open}
                onClose={handleClose}
            >
                <Grid container direction="column" className={styles.container}>
                    <Grid item xs={12}>
                        {props.children}
                    </Grid>
                </Grid>
            </Modal>
        </>
    );
};

TunnelScrollingPopin.propTypes = {
    bg: PropTypes.string,
    maxWidth: PropTypes.string,
    maxHeight: PropTypes.string,
    bgSide: PropTypes.string,
    clickableElement: PropTypes.any,
    style: PropTypes.object,
    children: PropTypes.any,
    backButton: PropTypes.bool,
};

export default TunnelScrollingPopin;
