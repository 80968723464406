/* eslint-disable react/jsx-indent */
/* eslint-disable no-unused-vars */
import { t, Trans } from "@lingui/macro";
import { Box, makeStyles, Tab, Tabs, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useState } from "react";

import ageImage from "../../assets/age.svg";
import densityImage from "../../assets/density.svg";
import greffe from "../../assets/greffe.png";
import soil from "../../assets/sol.svg";
import subSoil from "../../assets/sous-sol.svg";
import surfaceAreaImage from "../../assets/surfaceArea.svg";
import otherCulturesImage from "../../assets/others-cultures.svg";
import Popin from "../../Containers/Modal";
import PopinSurfacesBlocks from "../../Dashboard/Components/PopinSurfacesBlocks";
import { useVineyardFrontPopinQuery } from "../../generated/graphql";
import theme from "../../globalTheme";
import getConnectors, { commaInFr, getChips, getRound, parseHtml, useWindowDimensions } from "../../helpers/helpers";
import VineyardDetailsMaintenance from "../Components/VineyardDetailsMaintenance";

const useStyles = makeStyles((theme) => ({
    mainBox: (props) => ({
        width: "100%",
        display: "flex",
        flexDirection: "column",
        "& .MuiTabs-flexContainer": {
            justifyContent: "flex-start",
            borderBottom: "1px solid",
            borderBottomColor: theme.palette.secondary.main,
        },
        "& .MuiIconButton-root": {
            padding: 0,
        },
        "& .MuiTab-textColorPrimary.Mui-selected": {
            color: theme.palette.common.black,
            backgroundColor: "transparent",
        },
    }),
    popinSurfacesContainer: {
        "&::-webkit-scrollbar": {
            width: "8px",
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            paddingRight: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "20px",
        },
    },
    panelMainBox: {
        marginTop: theme.spacing(2),
    },
    informationsBox: {
        display: "flex",
        flexWrap: "wrap",
        marginBottom: theme.spacing(2),
        width: "100%",
    },
    detailsTitle: {
        ...theme.front.typography.h3.dark,
        alignSelf: "start",
        marginBottom: theme.spacing(2),
    },
    tabs: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        paddingBottom: "20px",
        "& .MuiTabs-indicator": {
            height: "4px",
            width: "100%",
            borderRadius: "5px 5px 0px 0px",
            backgroundColor: theme.palette.primary.light,
        },
        "& .MuiTabScrollButton-root.Mui-disabled": {
            transitionProperty: "width, opacity",
            transitionDuration: ".8s",
            animationTimingFunction: "cubic-bezier(.59,.01,.39,1)",
            width: "0px",
        },
        "& .MuiTabScrollButton-root": {
            transitionProperty: "width, opacity",
            transitionDuration: ".8s",
            animationTimingFunction: "cubic-bezier(.59,.01,.39,1)",
        },
    },
    tab: {
        textTransform: "none",
        fontSize: "18px",
        fontFamily: "Playfair Display",
        // color: theme.palette.secondary.dark,
        color: "#D0BDC3",
        padding: "10px",
        minWidth: "fit-content",
        fontWeight: "bold",
        backgroundColor: "transparent",
        borderRadius: "4px",
        [theme.breakpoints.only("xs")]: {
            fontSize: "16px",
        },
    },
    tabPanelTitle: {
        fontSize: "18px",
        fontWeight: "bold",
        color: theme.palette.secondary.dark,
        marginLeft: theme.spacing(1),
    },
    tabPanelText: {
        color: theme.palette.primary.dark,
        marginBottom: theme.spacing(1),
    },
    tabPanelTitleBox: {
        display: "flex",
        alignItems: "flex-end",
        marginBottom: theme.spacing(1),
    },
    tabPanelBlock: {
        marginBottom: theme.spacing(3),
    },
    tabPanelContainer: (props) => ({
        marginLeft: "20%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: props.justify || "space-around",
    }),
    informationTyposContainer: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        marginLeft: theme.spacing(2),
    },
    mainContainer: () => {
        return {
            // width: "50rem",
            minHeight: "340px",
            padding: "16px",
            justifyContent: "flex-start",
            [theme.breakpoints.only("xs")]: {
                margin: "8px",
            },
        };
    },
    scroll: {
        "&::-webkit-scrollbar": { width: "8px", height: "8px" },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            paddingRight: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "20px",
        },
    },

    descriptionTypo: {
        alignSelf: "start",
        marginBottom: theme.spacing(2),
    },
    surfaceTypos: {
        ...theme.front.typography.h6.dark_b,
        marginBottom: theme.spacing(2),
        fontSize: "21px",
    },
    mobileStyleTabs: {
        minHeight: "76px",
    },
    tabsAndContentMobile__wrapper: {
        "& ::-webkit-scrollbar": {
            width: "8px",
        },
        "& ::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            paddingRight: "8px",
        },
        "& ::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "20px",
        },
    },
    vineyardDetailsPopin: {
        ...theme.front.padding.popInVignoble,
    },
    vineyardDetailsPopin__typoTabs: {
        ...theme.front.typography.h5.dark_b,
        color: "innerit",
    },
    vineyardDetailsPopin__typoTitleGetBlock: {
        ...theme.front.typography.h6.dark_b,
    },
    vineyardDetailsPopin__typoValueGetBlock: {
        ...theme.front.typography.labeur.dark,
        wordBreak: "break-word",
    },
    vineyardDetailsPopin__typoExtraValue: {
        ...theme.front.typography.labeur.dark,
    },
    contentStyle: {
        width: "100%",
        overflow: "inherit",
        maxHeight: "inherit",
    },
}));

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
}
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const styles = useStyles();

    return (
        <div
            className={styles.scroll}
            style={{
                overflowY: "scroll",
                height: "25rem",
                overflowX: "hidden",
            }}
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}
TabPanel.propTypes = {
    value: PropTypes.any,
    index: PropTypes.any,
    children: PropTypes.any,
};

const getSurfaceNumber = (surface) => {
    if (!surface) {
        return;
    }
    return Object.keys(surface).length;
};

const getBlocks = (informations, styles) => {
    if (!informations) {
        return null;
    }
    return informations.map((information, index) => {
        return information?.show !== false ? (
            <Box key={index} style={{ marginRight: theme.spacing(3) }}>
                <Box style={{ display: "flex" }}>
                    <Box
                        style={{
                            display: "flex",
                            alignItems: "center",
                            // width: "100px",
                        }}
                    >
                        <img
                            src={information.image}
                            alt="informations_image"
                            style={{
                                opacity: information?.value === t`Non précisé` ? 0.5 : 1,
                            }}
                        />
                    </Box>
                    <Box className={styles.informationTyposContainer}>
                        <Typography
                            style={{
                                color: information?.value === t`Non précisé` ? theme.palette.secondary.main : "black",
                            }}
                            className={styles.vineyardDetailsPopin__typoTitleGetBlock}
                        >
                            {information.title}
                        </Typography>
                        <Typography
                            className={styles.vineyardDetailsPopin__typoValueGetBlock}
                            style={{
                                color: information?.value === t`Non précisé` ? theme.palette.secondary.dark : "#360318",
                            }}
                        >
                            {information.value}
                        </Typography>
                    </Box>
                </Box>
                {information?.extraValue && (
                    <Typography
                        style={{
                            marginBottom: theme.spacing(1),
                            color: "#360318",
                        }}
                        className={styles.vineyardDetailsPopin__typoExtraValue}
                    >
                        {information?.extraValue}
                    </Typography>
                )}
            </Box>
        ) : null;
    });
};

const VineyardDetailsPopin = (props) => {
    const styles = useStyles();
    const { width } = useWindowDimensions();
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const { data, loading, error } = useVineyardFrontPopinQuery({
        variables: { wineEntityId: props.wineEntityId, locale: props.locale },
    });
    const vineyard = data?.VineyardFrontPopin;
    const informations1 = [
        {
            title: t`Superficie vigne`,
            value: vineyard?.vineSurfaceArea
                ? commaInFr(vineyard?.vineSurfaceArea?.toFixed(2).toString(), props.locale) + t` ha`
                : t`Non précisé`,
            image: surfaceAreaImage,
            show: true,
        },
        {
            title: t`Âge moyen`,
            value: vineyard?.averageVineAge ? getRound(vineyard?.averageVineAge) + " " + t` ans` : t`Non précisé`,
            image: ageImage,
            show: true,
        },
        {
            title: t`Densité moyenne de plantation`,
            value: vineyard?.averagePlantationDensity
                ? getRound(vineyard?.averagePlantationDensity, 100) + " " + t` pieds / ha`
                : t`Non précisé`,
            image: densityImage,
            show: true,
        },
        {
            title: t`Autres cultures pratiquées`,
            value:
                vineyard?.otherCultures && vineyard?.otherCultures !== "<div/>"
                    ? parseHtml(vineyard?.otherCultures)
                    : t`Non précisé`,
            image: otherCulturesImage,
            show: vineyard?.otherCultures && vineyard?.otherCultures !== "<div/>",
        },
    ];
    const informations2 = [
        {
            title: t`Porte-greffes`,
            value:
                vineyard?.rootstocks && vineyard?.rootstocks?.length !== 0
                    ? getConnectors(vineyard?.rootstocks)
                    : t`Non précisé`,
            image: greffe,
        },
        {
            title: t`Sol`,
            value: vineyard?.soil ? getChips(vineyard?.soil, true) : t`Non précisé`,
            image: soil,
            extraValue:
                !vineyard?.subsoil && vineyard?.soilDescription && vineyard?.soil
                    ? parseHtml(vineyard?.soilDescription)
                    : "",
        },
        {
            title: t`Sous-sol`,
            value: vineyard?.subsoil ? getChips(vineyard?.subsoil, true) : t`Non précisé`,
            image: subSoil,
            extraValue:
                (!vineyard?.soil && vineyard?.soilDescription) ||
                (vineyard?.soil && vineyard?.soilDescription && vineyard?.subsoil)
                    ? parseHtml(vineyard?.soilDescription)
                    : "",
        },
    ];

    const informationsXs = [
        {
            title: t`Superficie vigne`,
            value: vineyard?.vineSurfaceArea
                ? vineyard?.vineSurfaceArea?.toFixed(2).toString().replace(/\./g, ",") + t` ha`
                : t`Non précisé`,
            image: surfaceAreaImage,
        },
        {
            title: t`Âge moyen`,
            value: vineyard?.averageVineAge ? getRound(vineyard?.averageVineAge) + t` ans` : t`Non précisé`,
            image: ageImage,
        },
        {
            title: t`Densité moyenne de plantation`,
            value: vineyard?.averagePlantationDensity
                ? getRound(vineyard?.averagePlantationDensity, 100) + t` pieds / ha`
                : t`Non précisé`,
            image: densityImage,
        },
        {
            title: t`Sol`,
            value: vineyard?.soil ? getChips(vineyard?.soil, true) : t`Non précisé`,
            image: soil,
            extraValue: !vineyard?.subsoil && vineyard?.soilDescription ? parseHtml(vineyard?.soilDescription) : "",
        },
        {
            title: t`Sous-sol`,
            value: vineyard?.subsoil ? getChips(vineyard?.subsoil, true) : t`Non précisé`,
            image: subSoil,
            extraValue:
                (!vineyard?.soil && vineyard?.soilDescription) ||
                (vineyard?.soil && vineyard?.soilDescription && vineyard?.subsoil)
                    ? parseHtml(vineyard?.soilDescription)
                    : "",
        },
        {
            title: t`Porte-greffes`,
            value:
                vineyard?.rootstocks && vineyard?.rootstocks?.length !== 0
                    ? getConnectors(vineyard?.rootstocks)
                    : t`Non précisé`,
            image: greffe,
        },
        {
            title: t`Autres cultures pratiquées`,
            // value: vineyard?.otherCultures
            //     ? parseHtml(vineyard?.otherCultures)
            //     : t`Non précisé`,
            value: vineyard?.otherCultures ? parseHtml(vineyard?.otherCultures) : t`Non précisé`,
            image: otherCulturesImage,
        },
    ];
    const tabsInformations = [
        {
            title: t`Informations générales`,
        },
        {
            title: t`Surfaces de production`,
        },
        {
            title: t`Viticulture`,
        },
    ];

    return (
        <Popin
            width="50rem"
            open={props.open}
            onClose={props.onClose}
            title={t`Détails du vignoble`}
            actionNeeded={false}
        >
            {width >= 600 ? (
                <Box className={styles.mainContainer}>
                    <Box className={styles.mainBox}>
                        <Tabs
                            className={styles.tabs}
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            TabIndicatorProps={{ children: <span /> }}
                            textColor="primary"
                            scrollButtons="on"
                            variant="scrollable"
                        >
                            <Tab
                                {...a11yProps(0)}
                                className={styles.tab}
                                label={
                                    <Typography className={styles.vineyardDetailsPopin__typoTabs}>
                                        <Trans>Informations générales</Trans>
                                    </Typography>
                                }
                            />
                            <Tab
                                {...a11yProps(1)}
                                className={styles.tab}
                                label={
                                    <Typography className={styles.vineyardDetailsPopin__typoTabs}>
                                        <Trans>Surfaces de production</Trans>
                                    </Typography>
                                }
                            />
                            <Tab
                                {...a11yProps(2)}
                                className={styles.tab}
                                label={
                                    <Typography className={styles.vineyardDetailsPopin__typoTabs}>
                                        <Trans>Viticulture</Trans>
                                    </Typography>
                                }
                            />
                        </Tabs>

                        {/* GENERALS INFORMATIONS */}
                        <TabPanel index={0} value={value}>
                            <Box className={styles?.panelMainBox}>
                                <Box
                                    style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    <Box style={{ width: "50%" }}>{getBlocks(informations1, styles)}</Box>
                                    <Box style={{ width: "50%" }}>{getBlocks(informations2, styles)}</Box>
                                </Box>
                                {props.estateInformations?.description ? (
                                    <Typography variant="h6" className={styles.descriptionTypo}>
                                        {props.estateInformations?.description}
                                    </Typography>
                                ) : (
                                    ""
                                )}
                            </Box>
                        </TabPanel>

                        {/* TAB SURFACES */}
                        <TabPanel value={value} index={1}>
                            {
                                <Box
                                    className={styles.popinSurfacesContainer}
                                    style={{
                                        maxHeight: "500px",
                                    }}
                                >
                                    {vineyard?.allHiddenSurfaces ? (
                                        <Typography
                                            style={{
                                                color: theme.palette.secondary.dark,
                                                fontWeight: "bold",
                                                border: "1px solid",
                                                borderColor: theme.palette.secondary.dark,
                                                borderRadius: "5px",
                                                padding: "10px 23px",
                                            }}
                                        >
                                            <Trans>Information non renseignée</Trans>
                                        </Typography>
                                    ) : (
                                        <PopinSurfacesBlocks
                                            locale={props.locale}
                                            hidden={vineyard?.hiddenSurfaces}
                                            vineyards={vineyard?.surfaces}
                                            surfaceImportCVI={vineyard?.surfaceImportCVI}
                                        />
                                    )}
                                </Box>
                            }
                        </TabPanel>

                        {/* TAB VITICULTURE */}
                        <TabPanel value={value} index={2} style={{ height: "25rem", overflowY: "scroll" }}>
                            <VineyardDetailsMaintenance
                                vineyard={{
                                    trimming: vineyard?.pruningMethod,
                                    grassCover: vineyard?.grassGrowing,
                                    fertilizer: vineyard?.fertilizers,
                                    underWorkType: vineyard?.worksUnderTheRow,
                                    greenProcedures: vineyard?.greenProcedures,
                                    underWorkDescription: vineyard?.soilWorkDescription,
                                    vineWorkDescription: vineyard?.vineWorkDescription,
                                    sexualConfusion: vineyard?.sexualConfusion,
                                    complementaryInformations: vineyard?.description,
                                    insectsDescription: vineyard?.insectWorkDescription,
                                    grassGrowingIsTotal: vineyard?.grassGrowingIsTotal,
                                    grassGrowingIsTemporary: vineyard?.grassGrowingIsTemporary,
                                }}
                            />
                        </TabPanel>
                    </Box>
                </Box>
            ) : (
                <Box style={{ height: "inherit" }}>
                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            height: "calc(100% - 34px)",
                        }}
                        className={styles.tabsAndContentMobile__wrapper}
                    >
                        <Tabs
                            className={styles.tabs}
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            TabIndicatorProps={{ children: <span /> }}
                            textColor="primary"
                            scrollButtons="on"
                            variant="scrollable"
                            // style={{minHeight: "74px"}}
                        >
                            {tabsInformations.map((el, key) => {
                                return (
                                    <Tab
                                        key={key}
                                        {...a11yProps(key)}
                                        className={styles.tab}
                                        label={
                                            <Typography variant="h3" style={{ fontSize: "22px" }}>
                                                {el.title}
                                            </Typography>
                                        }
                                    />
                                );
                            })}
                        </Tabs>
                        <TabPanel index={0} value={value} style={{ height: "inherit" }}>
                            <Box
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    overflowY: "scroll",
                                    height: "inherit",
                                }}
                            >
                                {getBlocks(informationsXs, styles)}
                            </Box>
                            {props.estateInformations?.description ? (
                                <Typography variant="h6">{props.estateInformations?.description}</Typography>
                            ) : (
                                ""
                            )}
                        </TabPanel>

                        <TabPanel value={value} index={1}>
                            {/* {vineyard?.allHiddenSurfaces ? (
                                ""
                            ) : vineyard?.surfaces && vineyard?.surfaces?.length === 1 ? (
                                <Typography className={styles.surfaceTypos}>
                                    <Trans>
                                        {` ${getSurfaceNumber(vineyard?.surfaces)} surface de production dont : `}
                                    </Trans>
                                </Typography>
                            ) : (
                                ""
                            )}

                            {vineyard?.allHiddenSurfaces ? (
                                ""
                            ) : vineyard?.surfaces && vineyard?.surfaces?.length > 1 ? (
                                <Typography className={styles.surfaceTypos}>
                                    <Trans>
                                        {` ${getSurfaceNumber(vineyard?.surfaces)} surfaces de production dont : `}
                                    </Trans>
                                </Typography>
                            ) : (
                                ""
                            )} */}
                            {
                                <Box className={styles.popinSurfacesContainer} style={{ maxHeight: "500px" }}>
                                    {vineyard?.allHiddenSurfaces ? (
                                        <Typography
                                            style={{
                                                color: theme.palette.secondary.dark,
                                                fontWeight: "bold",
                                                border: "1px solid",
                                                borderColor: theme.palette.secondary.dark,
                                                borderRadius: "5px",
                                                padding: "10px 23px",
                                            }}
                                        >
                                            <Trans>Information non renseignée</Trans>
                                        </Typography>
                                    ) : (
                                        <PopinSurfacesBlocks
                                            hidden={vineyard?.hiddenSurfaces}
                                            vineyards={vineyard?.surfaces}
                                            surfaceImportCVI={vineyard?.surfaceImportCVI}
                                        />
                                    )}
                                </Box>
                            }
                        </TabPanel>

                        <TabPanel value={value} index={2} style={{ height: "inherit" }}>
                            <VineyardDetailsMaintenance
                                vineyard={{
                                    trimming: vineyard?.pruningMethod,
                                    grassCover: vineyard?.grassGrowing,
                                    grassGrowingIsTemporary: vineyard?.grassGrowingIsTemporary,
                                    grassGrowingIsTotal: vineyard?.grassGrowingIsTotal,
                                    fertilizer: vineyard?.fertilizers,
                                    underWorkType: vineyard?.worksUnderTheRow,
                                    greenProcedures: vineyard?.greenProcedures,
                                    underWorkDescription: vineyard?.soilWorkDescription,
                                    vineWorkDescription: vineyard?.vineWorkDescription,
                                    sexualConfusion: vineyard?.sexualConfusion,
                                    complementaryInformations: vineyard?.description,
                                    insectsDescription: vineyard?.insectWorkDescription,
                                }}
                            />
                        </TabPanel>
                    </Box>
                </Box>
            )}
        </Popin>
    );
};

VineyardDetailsPopin.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    estateInformations: PropTypes.object,
    vineyards: PropTypes.object,
    surfaceAreaTotal: PropTypes.string,

    wineEntityId: PropTypes.string,
    locale: PropTypes.string,
};

export default VineyardDetailsPopin;
