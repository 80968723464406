import { t, Trans } from "@lingui/macro";
import { Box, Card, CardContent, makeStyles, Typography } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { formatConcat, urlFormatted } from "helpers/helpers";
import { number, object, shape, string } from "prop-types";
import { Link } from "react-router-dom";
import { useMemo } from "react";

import clairetBottle from "../assets/appellation/placeholderBottle/clairet_bottle.svg";
import liquoreuxBottle from "../assets/appellation/placeholderBottle/liquoreux_bottle.svg";
import moelleuxBottle from "../assets/appellation/placeholderBottle/moelleux_bottle.svg";
import redBottle from "../assets/appellation/placeholderBottle/red_bottle.svg";
import roseBottle from "../assets/appellation/placeholderBottle/rose_bottle.svg";
import whiteBottle from "../assets/appellation/placeholderBottle/white_bottle.svg";
import bgBlancLiquoreux from "../assets/challenges/bg-blanc-liquoreux.svg";
import bgBlancMoelleux from "../assets/challenges/bg-blanc-moelleux.svg";
import bgBlancSec from "../assets/challenges/bg-blanc-sec.svg";
import bgClairet from "../assets/challenges/bg-clairet.svg";
import bgRose from "../assets/challenges/bg-rose.svg";
import bgRouge from "../assets/challenges/bg-rouge.svg";
import reward from "../assets/challenges/reward.svg";
import theme from "../globalTheme";
import { useCustomHistory } from "hooks";

const useStyles = makeStyles(() => ({
    card: {
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.08)",
        borderRadius: "5px",
        padding: "1.625rem 0",
        position: "relative",
        width: "19rem",
        cursor: "pointer",
        textDecoration: "none",
    },
    wine: {
        position: "relative",
    },
    wine_img: {
        position: "absolute",
        top: 14,
        left: 9,
        maxHeight: "170px",
    },
    item_subtitle: {
        color: theme.palette.primary.light,
    },
    item_title: {
        color: theme.palette.primary.dark,
        marginTop: "1rem",
    },
    item_appelation: {
        color: theme.palette.primary.main,
    },
    item_wineEntity: {
        color: theme.palette.text.primary,
    },
    item_reward: {
        color: theme.palette.primary.light,
        textTransform: "uppercase",
        marginLeft: "6px",
        fontWeight: 700,
    },
    link: {
        display: "flex",
        cursor: "pointer",
        textDecoration: "none",

        "&:hover": {
            textDecoration: "underline",
        },
    },
}));

const ChallengeVintageCard = (props) => {
    const styles = useStyles();
    const { addLocale } = useCustomHistory();

    const getWineBg = (color, type) => {
        if (color === "Rouge") {
            return bgRouge;
        }
        if (color === "Rosé") {
            return bgRose;
        }
        if (color === "Blanc") {
            if (type === "Tranquille") {
                return bgBlancSec;
            }
            if (type === "Moelleux") {
                return bgBlancMoelleux;
            }
            if (type === "Liquoreux") {
                return bgBlancLiquoreux;
            }
        } else return bgClairet;
    };

    const getWineImage = (color, type) => {
        if (color === "Rouge") {
            return redBottle;
        }
        if (color === "Rosé") {
            return roseBottle;
        }
        if (color === "Blanc") {
            if (type === "Tranquille") {
                return whiteBottle;
            }
            if (type === "Moelleux") {
                return moelleuxBottle;
            }
            if (type === "Liquoreux") {
                return liquoreuxBottle;
            }
        } else return clairetBottle;
    };

    const {
        wineEntityShortId,
        wineEntityName,
        wineShortId,
        brandName,
        year,
        cuvee,
        color,
        type,
        classement,
        image,
        appellation,
        rewardName,
        rewardYear,
    } = props.vintage;

    const url = useMemo(() => {
        const wineEntityPart = urlFormatted(wineEntityShortId, wineEntityName);
        const winePart = urlFormatted(wineShortId, brandName);
        const allParts = formatConcat([`/${wineEntityPart}/vins/${winePart}`, urlFormatted(year, cuvee)], "/");
        return addLocale(allParts);
    }, [props.vintage]);

    return (
        <Card className={styles.card} component={Link} to={url}>
            <CardContent style={{ padding: "0px", display: "flex", paddingRight: "1.625rem" }}>
                <Box className={styles.wine}>
                    <img src={getWineBg(color, type)} />
                    <img src={image?.twicpicURL || getWineImage(color, type)} className={styles.wine_img} alt="wine" />
                </Box>

                <Box
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        width: "100%",
                        paddingLeft: "1rem",
                    }}
                >
                    <Typography variant="h4" className={styles.item_title}>
                        {brandName} {cuvee} {year}
                    </Typography>
                    <Box>
                        <Box style={{ marginBottom: "1rem" }}>
                            <Typography variant="h6" className={styles.item_subtitle}>
                                {classement}
                            </Typography>
                            <Typography variant="h6" className={styles.item_appelation}>
                                {appellation}
                            </Typography>
                            <Typography variant="h6" className={styles.item_wineEntity}>
                                {t`Par`} {wineEntityName}
                            </Typography>
                            {rewardName && (
                                <Box style={{ display: "flex", alignItems: "center" }}>
                                    <img src={reward} style={{ marginRight: "1px" }} alt="reward icon" />
                                    <Typography variant="body2" className={styles.item_reward}>
                                        {rewardName} {rewardYear}
                                    </Typography>
                                </Box>
                            )}
                        </Box>

                        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Link className={styles.link} to={url}>
                                <Typography
                                    variant="body2"
                                    style={{
                                        color: theme.palette.secondary.dark,
                                    }}
                                >
                                    <Trans>Découvrir</Trans>
                                </Typography>
                                <ArrowForwardIcon style={{ color: theme.palette.secondary.dark }} />
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

ChallengeVintageCard.propTypes = {
    vintage: shape({
        _id: string,
        brandName: string,
        color: string,
        rankings: string,
        classement: string,
        appellation: string,
        wineEntityName: string,
        reward: object,
        cuvee: string,
        year: string,
        type: string,
        bottleUrl: string,
        rewardName: string,
        rewardYear: number,
        image: shape({
            twicpicURL: string,
        }),
        wineId: string,
        wineEntityShortId: string,
        wineShortId: string,
    }),
};

export default ChallengeVintageCard;
