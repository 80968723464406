import { Typography } from "@material-ui/core";
import React from "react";
import Arrow from "../../../Components/Arrow/Arrow";
import theme from "../../../globalTheme";
import PropTypes, { bool } from "prop-types";

const TitleAboutProperty = (props) => {
    return (
        <>
            <Typography variant="h4" style={{ fontWeight: "700", marginBottom: props.disabledLegend && "1rem" }}>
                {props.title}
            </Typography>
            {!props.disabledLegend ? (
                <Arrow rotation="105" arrowPosition="start" style={{ marginLeft: 8, marginBottom: "0.5rem" }}>
                    <Typography
                        variant="h6"
                        style={{
                            color: theme.palette.primary.light,
                            fontSize: "14px",
                            fontFamily: "Mansalva",
                        }}
                    >
                        {props.subtitle}
                    </Typography>
                </Arrow>
            ) : null}
        </>
    );
};

TitleAboutProperty.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.any,
    disabledLegend: bool,
};

export default TitleAboutProperty;
