import { Typography } from "@material-ui/core";
import React from "react";

import translation from "../../../assets/popin/icones/translation.svg";
import Modal from "../../../Containers/Modal";


interface BetaTranslationPopinProps {
    modalOpen: boolean
    handleClose : () => void
}
const BetaTranslationPopin = (props : BetaTranslationPopinProps) => {
    return (
        <Modal
            open={props.modalOpen}
            onClose={props.handleClose}
            title='Demande de traduction'
            icon={translation}
            actionNeeded={false}
        >
            <Typography variant="h6">
                Vous aurez bientôt accès, via ce bouton, à la traduction du texte que vous aurez saisi.
            </Typography>
        </Modal>
    );
};

export default BetaTranslationPopin;