import { Box, makeStyles, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { bool, string } from "prop-types";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";

import theme from "../../../globalTheme";
import { feretAddress, feretPostalCode, PAYMENT_METHODS } from "../../../config/constants";

const useStyles = makeStyles(() => ({
    table: {
        borderCollapse: "unset",
        border: "0.4px solid #9F8D93",
        borderRadius: "10px",
    },
    cell: {
        color: theme.palette.primary.dark,
        borderBottom: "none",
    },
}));

const StyledTableRow = withStyles((theme) => ({
    root: {
        padding: "0.5rem 2rem",
        display: "flex",
        justifyContent: "space-between",

        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.background.card,
        },
        "&:nth-of-type(1)": {
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
        },
        "&:last-child": {
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
            backgroundColor: theme.palette.background.card,
        },
    },
}))(Box);

const PaymentWithReception = (props) => {
    const styles = useStyles();

    function createData(name, infos) {
        return { name, infos };
    }

    const bankTransfertRows = [
        createData("Ref virement :", "MLDANN0527"),
        createData("Montant :", props.totalPrice),
        createData("BIC : ", "CMCIFRPP"),
        createData("IBAN :", "FR76 1005 7193 7900 0201 5480 132"),
    ];

    const bankCheckRows = [
        createData("Ref. à indiquer :", "PTYESS22"),

        createData("Montant :", props.totalPrice),
        createData("Ordre :", "Éditions Féret"),
        createData(
            "Adresse postale :",
            <>
                <Typography variant="h6">Éditions Féret</Typography>
                <Typography variant="h6">{feretAddress}</Typography>
                <Typography variant="h6">{feretPostalCode}</Typography>
            </>
        ),
    ];
    const warningText = () => {
        if (props.subscriptionFlow) {
            if (props.paymentMethod === PAYMENT_METHODS.bankTransfer) {
                return "L'indication de la référence de votre virement est obligatoire.";
            } else {
                return "L'indication de la référence de votre inscription au dos du chèque est obligatoire.";
            }
        } else {
            if (props.paymentMethod === PAYMENT_METHODS.bankTransfer) {
                return "L’indication de la référence de votre virement est obligatoire";
            } else {
                return "L’indication de la référence de votre commande au dos du chèque est obligatoire.";
            }
        }
    };
    return (
        <>
            <Typography variant="h6" style={{ textAlign: "center", color: theme.palette.primary.dark }}>
                Merci de nous faire parvenir dans un délai de{" "}
                {props.paymentMethod === PAYMENT_METHODS.bankTransfer ? "3 jours*  un virement" : "7 jours* un chèque"}{" "}
                du montant de votre {props.subscriptionFlow ? "inscription" : "commande"}
            </Typography>
            <Typography variant="h6" style={{ textAlign: "center", color: theme.palette.primary.dark }}>
                Après réception de votre paiement, le délai maximal de traitement est de 3 jours.{" "}
                {props.paymentMethod === PAYMENT_METHODS.bankTransfer && "Ci-dessous nos coodonnées bancaires."}
            </Typography>
            <Box aria-label="simple table" className={styles.table}>
                {(props.paymentMethod === PAYMENT_METHODS.bankTransfer ? bankTransfertRows : bankCheckRows).map(
                    (row) => (
                        <StyledTableRow key={row.name}>
                            <Typography
                                variant="h6"
                                className={styles.cell}
                                style={{
                                    fontWeight: 700,
                                    width: props.paymentMethod === PAYMENT_METHODS.bankTransfer ? "30%" : "70%",
                                }}
                            >
                                {row.name}
                            </Typography>
                            <Typography
                                variant="h6"
                                className={styles.cell}
                                style={{
                                    flex: "1",
                                    textAlign: props.paymentMethod === PAYMENT_METHODS.bankTransfer ? "end" : "start",
                                }}
                            >
                                {row.infos}
                            </Typography>
                        </StyledTableRow>
                    )
                )}
            </Box>

            <Typography variant="subtitle1" style={{ color: theme.palette.primary.dark }}>
                *Passé ce délai, votre {props.subscriptionFlow ? "inscription" : "commande"} sera annulée.
            </Typography>
            <Typography
                variant="h6"
                style={{ display: "flex", alignIems: "center", fontWeight: "700", color: theme.palette.primary.dark }}
            >
                <WarningRoundedIcon fontSize="small" style={{ color: theme.palette.primary.main }} />
                {warningText()}
            </Typography>
        </>
    );
};

PaymentWithReception.propTypes = {
    paymentMethod: string,
    totalPrice: string,
    subscriptionFlow: bool,
};

export default PaymentWithReception;
