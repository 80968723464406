/* eslint-disable no-unused-vars */

import React, { useState } from "react";
import Loading from "../Components/Loader/Loading";
import leftPopin from "../assets/popin/action_needed_bg.svg";
import rightPopin from "../assets/SubscriptionPage/bg_popin_right.png";

import { makeStyles, Modal, Backdrop, Fade, IconButton, Box } from "@material-ui/core";
import cross from "../assets/cross.svg";
import PropTypes from "prop-types";
import ControlledSwitch from "../Components/Switch/ControlledSwitch";

const useStyle = makeStyles((theme) => ({
    contentBox: (props) => ({
        padding: "15px",
        marginTop: props.popinImage ? props.marginTop : "0px",
        [theme.breakpoints.only("xs")]: {
            padding: "16px",
            marginTop: props.popinImage ? props.marginTop : "0px",
            height: props.fullHeight ? "calc(100vh - 76px)" : null,
        },
    }),
    crossButtonContainer: (props) => ({
        display: "flex",
        justifyContent: "flex-end",
        position: props.backButtonAbsolute ? "absolute" : "relative",
        width: "100%",
        height: "auto",
    }),
    crossButton: {
        marginRight: "10px",
        marginTop: "10px",
        padding: 0,
        cursor: "pointer",
        zIndex: 99,
    },
    popinMainContainer: {
        position: "fixed",
        margin: "auto",
        left: "50%",
        right: "50%",
        display: "flex",
        justifyContent: "center",
    },
    popinContainer: (props) => ({
        position: "absolute",
        padding: props.padding ? 20 : 0,
        backgroundColor: "white",
        borderRadius: "50%",
        top: "-55px",
        zIndex: 12,
    }),
    popin: {},
    mainContainer: (props) => ({
        backgroundColor: theme.palette.common.white,
        backgroundImage: props.bgSide === "right" ? `url(${rightPopin})` : `url(${leftPopin})`,
        backgroundPosition: props.bgSide === "right" ? "bottom right" : "bottom left",
        backgroundRepeat: "no-repeat",
        backgroundSize: "80%, inherit",
        position: "relative",
        overflow: "auto",
        outline: "none",
        margin: "auto",
        borderRadius: "10px",
        [theme.breakpoints.only("xs")]: {
            margin: "8px",
            height: props.fullHeight ? "-webkit-fill-available" : null,
            overflow: "hidden",
        },
        [theme.breakpoints.only("sm")]: {
            margin: "0 16px",
        },
        [theme.breakpoints.only("md")]: {
            margin: "0 32px",
        },
        "& ::-webkit-scrollbar": {
            width: "8px",
        },
        "& ::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            paddingRight: "8px",
        },
        "& ::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "20px",
        },
    }),
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

const ClickableElementPopin = (props) => {
    const styles = useStyle(props);

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Box onClick={handleOpen} style={{ cursor: "pointer" }}>
                {props.clickableElement}
            </Box>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={styles.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
            >
                <Fade in={open}>
                    <Box className={[styles.mainContainer, props.className].join(" ")} style={props.style}>
                        <Box className={styles.crossButtonContainer}>
                            {props.backButton && (
                                <IconButton aria-label="close" className={styles.crossButton} onClick={handleClose}>
                                    <img src={cross} alt="close_cross" />
                                </IconButton>
                            )}
                        </Box>

                        <Box className={styles.popinMainContainer}>
                            {props.popinImage ? (
                                <Box className={styles.popinContainer} style={props.popinContainerStyle}>
                                    <img className={styles.popin} src={props.popinImage} alt="popin_image" />
                                </Box>
                            ) : null}
                        </Box>

                        <Box style={{ ...props.styleSwitch }}>
                            {props.priceSwitch && (
                                <ControlledSwitch
                                    switchCheck={props.switchCheck}
                                    handleSwitchChange={(event) => props.handleSwitchChange(event)}
                                />
                            )}
                        </Box>

                        <Box className={styles.contentBox} style={props.contentStyle}>
                            {props.loading ? <Loading loading={props.loading} /> : props.children}
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default ClickableElementPopin;

ClickableElementPopin.propTypes = {
    clickableElement: PropTypes.any,
    children: PropTypes.any,
    style: PropTypes.object,
    contentStyle: PropTypes.object,
    className: PropTypes.object,
    bgSide: PropTypes.string,
    marginTop: PropTypes.number,
    popinContainerStyle: PropTypes.object,

    backButton: PropTypes.bool,
    popinImage: PropTypes.string,

    modalOpen: PropTypes.bool,
    handleClose: PropTypes.func,
    handleOpen: PropTypes.func,
    onBackdropClick: PropTypes.func,

    loading: PropTypes.bool,

    priceSwitch: PropTypes.bool,
    styleSwitch: PropTypes.object,
    switchCheck: PropTypes.bool,
    handleSwitchChange: PropTypes.func,

    fullHeight: PropTypes.bool,
    backButtonAbsolute: PropTypes.bool,
};

ClickableElementPopin.defaultProps = {
    handleClose: () => {},
    handleOpen: () => {},
    onBackdropClick: () => {},
};
