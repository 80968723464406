import React from "react";
import PropTypes from "prop-types";
import theme from "../../globalTheme";
import { Typography, makeStyles } from "@material-ui/core";
import { Trans } from "@lingui/macro";

const useStyles = makeStyles(() => ({
    root: {
        background: "transparent",
        border: "none",
        padding: 0,
        cursor: "pointer",
        textDecoration: "underline",

        "&:hover": {
            textDecoration: "underline",
        },
    },
    typo: {
        color: theme.palette.primary.dark,
        fontWeight: 400,
    },
}));

const ButtonResetFilters = (props) => {
    const styles = useStyles();

    return (
        <button className={styles.root} onClick={props?.onClick} disabled={props.disabled}>
            <Typography variant="subtitle1" className={styles.typo}>
                <Trans>Réinitialiser les filtres</Trans>
            </Typography>
        </button>
    );
};

ButtonResetFilters.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

export default ButtonResetFilters;
