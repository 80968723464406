/* eslint-disable no-undef */
export const IMAGE_CROP_URL = `${process.env.REACT_APP_IMAGES_API_URL}/image/crop`;
export const IMAGE_CLIPANDCROP_URL = `${process.env.REACT_APP_IMAGES_API_URL}/image/clipandcrop`;
export const SIREN_URL = `${process.env.REACT_APP_SIREN_API_URL}/siren?siret=`;
export const ZOHO_URL = `${process.env.REACT_APP_ZOHO_API_URL}/subscription-form`;
export const SUBSCRIPTION_ACKNOWLEDGMENTS = `${process.env.REACT_APP_FRONT_URL}/subscription/merci`;
export const PARSER_CVI_URL = process.env.REACT_APP_CVI_PARSER_URL;
export const FRONT_URL = process.env.REACT_APP_FRONT_URL;
export const SUPPORT_URL = process.env.REACT_APP_SUPPORT_FERET_URL;
export const STORYLANE_SUBSCRIBER_URL = process.env.REACT_APP_STORYLANE_SUBSCRIBER_URL;
export const STORYLANE_RECEIVER_URL = process.env.REACT_APP_STORYLANE_RECEIVER_URL;
export const PRO_FERET_URL = process.env.REACT_APP_PRO_FERET_URL;
export const PRO_FERET_ABOUT_URL = `${process.env.REACT_APP_PRO_FERET_URL}/a-propos`;
export const LIBRARY_FERET_URL = process.env.REACT_APP_LIBRARY_FERET_URL;
export const DOWNLOAD_XLSX_URL = process.env.REACT_APP_DOWNLOAD_XLSX_URL;
export const REACT_APP_FERET_APP_NAME = process.env.REACT_APP_FERET_APP_NAME;
export const STAGE = process.env.REACT_APP_STAGE;
export const REACT_APP_GIT_BRANCH_NAME = STAGE !== "prod" && "Branche : " + process.env.REACT_APP_GIT_BRANCH_NAME;
export const REACT_APP_BUILD_NUMBER = process.env.REACT_APP_BUILD_NUMBER;
export const REACT_APP_BUILD_TIME = process.env.REACT_APP_BUILD_TIME;
export const REACT_APP_FULL_BUILD_ID = STAGE !== "prod" && process.env.REACT_APP_FULL_BUILD_ID;

export const ENV_NAMES = {
    prod: "prod",
    staging: "staging",
    dev: "dev",
};

const developement = process.env.REACT_APP_DEV_TYPE === "backend";
const stage = `-${STAGE}`;

export const ZOHO_STAGE = STAGE !== "prod" ? stage : "";

// BUCKETNAME
export const BUCKETNAME_CERTIFICATION_DOCUMENT = `feret-certification-documents${stage}`;
export const BUCKETNAME_BUG_REPORT_SCREENSHOT = `feret-bug-report-screenshots${stage}`;
export const BUCKETNAME_ACTIVITY_IMAGES = `feret-activity-images${stage}`;
export const BUCKETNAME_TEAM_IMAGES = `feret-team-images${stage}`;
export const BUCKETNAME_CONTACT_CSV = `feret-contact-csv${stage}`;
export const BUCKETNAME_CVI = `feret-cvi${stage}`;
export const BUCKETNAME_CELLAR_IMAGES = `feret-cellar-images${stage}`;
export const BUCKETNAME_VINTAGE_BOTTLE_LABELS = `feret-vintage-bottle-labels${stage}`;
export const BUCKETNAME_VINTAGE_BOTTLE_PICTURES = `feret-vintage-bottle-pictures${stage}`;
export const BUCKETNAME_WINE_IMAGES = `feret-wine-images${stage}`;
export const BUCKETNAME_ENTITY_IMAGES = `feret-wine-entity-images${stage}`;
export const BUCKETNAME_ENTITY_LOGOS = `feret-wine-entity-logos${stage}`;
export const BUCKETNAME_CERTIFICATION_SUGGESTION = `feret-certification-suggestions${stage}`;

// ZOHO PRODUCT CODES
export const SUB_BORDEAUX_BASIC_YEAR = `sub-bordeaux-basic-year${ZOHO_STAGE}`;
export const SUB_BORDEAUX_ESSENTIAL_MONTH = `sub-bordeaux-essential-month${ZOHO_STAGE}`;
export const SUB_BORDEAUX_ESSENTIAL_YEAR = `sub-bordeaux-essential-year${ZOHO_STAGE}`;
export const SUB_BORDEAUX_INITIAL_MONTH = `sub-bordeaux-initial-month${ZOHO_STAGE}`;
export const SUB_BORDEAUX_INITIAL_YEAR = `sub-bordeaux-initial-year${ZOHO_STAGE}`;
export const SUB_BORDEAUX_PREMIUM_MONTH = `sub-bordeaux-premium-month${ZOHO_STAGE}`;
export const SUB_BORDEAUX_PREMIUM_YEAR = `sub-bordeaux-premium-year${ZOHO_STAGE}`;
// ADDONS
export const ADD_BORDEAUX_MEDALS_INITIAL = `add-bordeaux-medals-initial${ZOHO_STAGE}`;
export const ADD_FLUX_FTP = "add-flux-ftp";
export const ADD_BORDEAUX_MEDALS = "add-bordeaux-medals";
export const ADD_BORDEAUX_PLUS = "add-packplus";
export const ADD_PROPERTY_PHOTOSHOOT = "add-property-photoshoot";
export const ADD_WINE_PHOTOSHOOT = "add-wine-photoshoot";
export const ADD_PROPERTY_WRITING_HELP = "add-property-writing-help";
export const ADD_PROPERTY_INTERVIEW = "add-property-interview";
export const ADD_TURNKEY_START = "add-turnkey-start";
export const ADD_TECHNICAL_SHEET_HELP = "add-technical-sheet-help";
export const ADD_STANDALONE = "add-standalone";
export const ADD_TASTINGREQUEST = "add-tastingrequest";
export const ADD_TASTINGVIDEO = "add-tastingvideo";
export const ADD_TRANSLATION_EN = "add-translation-en";
export const ADD_TRANSLATION_ES = "add-translation-es";
export const ADD_TRANSLATION_IT = "add-translation-it";
export const ADD_TRANSLATION_NL = "add-translation-nl";
export const ADD_TRANSLATION_DE = "add-translation-de";
export const ADD_TRANSLATION_ZH = "add-translation-zh";
export const ADD_TRANSLATION_JA = "add-translation-ja";

export const EDITIONS = [
    `${LIBRARY_FERET_URL}/produit/bordeaux-et-ses-vins-reprint-2e-edition-1868`,
    `${LIBRARY_FERET_URL}/produit/bordeaux-et-ses-vins-reprint-3e-edition-1874`,
    `${LIBRARY_FERET_URL}/produit/bordeaux-et-ses-vins-reprint-7e-edition-1898`,
    `${LIBRARY_FERET_URL}/produit/bordeaux-et-ses-vins-reprint-9e-edition-1922`,
    `${LIBRARY_FERET_URL}/produit/bordeaux-et-ses-vins-16e-edition-2001`,
    `${LIBRARY_FERET_URL}/produit/bordeaux-et-ses-vins-17e-edition-2004`,
    `${LIBRARY_FERET_URL}/produit/bordeaux-et-ses-vins-18e-edition-2007`,
    `${LIBRARY_FERET_URL}/produit/bordeaux-et-ses-vins-19e-edition-2014`,
];

export const SOCIAL_MEDIA = [
    { platform: "facebook", label: "Facebook" },
    { platform: "siteweb", label: "Site web" },
    { platform: "instagram", label: "Instagram" },
    { platform: "linkedin", label: "LinkedIn" },
    { platform: "twitter", label: "Twitter" },
    { platform: "youtube", label: "Youtube" },
];

export const LANGUAGE_LABEL = {
    fr: "français",
    en: "anglais",
    es: "espagnol",
    it: "italien",
    nl: "néerlandais",
    de: "allemand",
    ja: "japonais",
    zh: "chinois",
};

export const AVAILABLE_COUNTRIES = [
    "France",
    // "Allemagne",
    // "Grande-Bretagne",
    // "Belgique",
    // "Espagne",
    // "Italie",
    // "Luxembourg",
    // "Suisse",
];

export const SOCIAL_MEDIA_PLATFORMS = SOCIAL_MEDIA.map(({ platform }) => platform);

export const ERROR_TYPES = {
    fieldRequired: "error.field.required",
    emailFormat: "error.email.format",
    emailDuplicate: "error.email.duplicate",
    fileExtention: "error.file.extention",
};

export const CREATOR_URL = developement ? process.env.REACT_APP_CREATOR_LOCALE_URL : process.env.REACT_APP_CREATOR_URL;

export const URL_REDIRECT = process.env.REACT_APP_FRONT_URL;

export const GRAPHQL_SERVER_URL =
    process.env.REACT_APP_GRAPHQL_SERVER_URL ??
    (developement ? process.env.REACT_APP_GRAPHQL_LOCALE_URL : process.env.REACT_APP_GRAPHQL_URL);

export const FT_STATUS = {
    DRAFT: "DRAFT",
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
};

export const FTP_DIFFUSION_STATUS = {
    ERRORS: "ERRORS",
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
};
export const FLUX_STATUS = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
};
export const labelTranslation = {
    [FT_STATUS.DRAFT]: "Brouillon",
    [FT_STATUS.ACTIVE]: "Active",
    [FT_STATUS.INACTIVE]: "Inactive",
};

export const FERET_SERVICES = {
    STARTUPSUPPORT: "STARTUPSUPPORT",
    WRITING: "WRITING",
    PHOTO: "PHOTO",
    TRANSLATION: "TRANSLATION",
    DATASHEET: "DATASHEET",
    STANDALONE: "STANDALONE",
};

export const STANDALONE_STATUS = {
    PENDING: "PENDING",
    ACTIVE: "ACTIVE",
    TO_DISABLE: "TO_DISABLE",
};

export const PAYMENT_STATUS = {
    PENDING: "PENDING",
    DONE: "DONE",
};

export const TASTING_REQUEST_STATUS = {
    PAYMENT_PENDING: "PAYMENT_PENDING",
    REQUESTED: "REQUESTED", // TASTING REQUESTED
    SAMPLE_RECEIVED_FERET: "SAMPLE_RECEIVED_FERET", // SAMPLE RECEIVED BY FERET
    SHIPPED_FERET: "SHIPPED_FERET", // SAMPLE SHIPPED BY FERET
    SAMPLE_RECEIVED_JOURNALIST: "SAMPLE_RECEIVED_JOURNALIST", // SAMPLE RECEIVED BY JOURNALIST
    DONE: "DONE", // TASTING DONE
    BILLING_PENDING: "BILLING_PENDING",
    JOURNALIST_PAYED: "JOURNALIST_PAYED", //JOURNALIST WAS PAID
};

export const PAYMENT_METHODS = {
    bankTransfer: "bankTransfer",
    bankCheck: "bankCheck",
    paypal: "paypal",
    creditCard: "creditCard",
    sepaDirectDebit: "sepaDirectDebit",
    offline: "offline",
    free: "free",
};

export const SUBSCRIPTION_RECURRENCE = {
    year: "year",
    month: "month",
    eternal: "eternal",
};
export const TVA = 20;

export const SUBREGIONS = [
    "Médoc",
    "Blaye et Bourg",
    "Libournais",
    "Graves et Sauternais",
    "Bordeaux et Bordeaux supérieur",
    "Entre-deux-Mers",
];

export const SUBREGIONSTITLE = {
    medoc: "Médoc",
    "blaye-et-bourg": "Blaye et Bourg",
    libournais: "Libournais",
    "graves-et-sauternais": "Graves et Sauternais",
    "bordeaux-et-bordeaux-superieur": "Bordeaux et Bordeaux supérieur",
    "entre-deux-mers": "Entre-deux-Mers",
};
export const DEFAULT_PAGE_SIZES = [10, 25, 50, 100];

export const RE_CAPTCHA_KEY = "6LdIt8QjAAAAAL8c5Gt176tgxT9yQiOXyox3kThT";

export const CERTIFICATION_LEVELS = { WINE_ENTITY: "WINE_ENTITY", VINTAGE: "VINTAGE" };

export const SUBSCRIPTION_PAPER_TYPES = {
    online: "online",
    partial: "partial",
    full: "full",
};

export const RECURRENCE_TYPES = {
    month: "month",
    year: "year",
};

export const SUBSCRIPTION_TYPES = {
    trial: "trial",
    basic: "basic",
    initial: "initial",
    essential: "essential",
    premium: "premium",
    journalist: "journalist",
    ftReceiver: "ft-receiver",
};

export const SUBSCRIPTION_DATA_STATUS = {
    DONE: "DONE",
    PENDING: "PENDING",
    ERROR: "ERROR",
    CANCELLED: "CANCELLED",
};

export const SUBSCRIPTION_STATUS = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
};

export const SERVICE_AVAILABLE = "24 avril 2023";

export const ALL_LANGUAGES = ["fr", "en", "de", "es", "it", "nl", "ja", "zh"];
export const FR_AND_EN = ["fr", "en"];

export const FRONT_VINTAGE_TAB = {
    advices: "advices",
    viticulture: "viticulture",
    harvest: "harvest",
    winemaking: "winemaking",
    ageing: "ageing",
    production: "production",
    marketing: "marketing",
};

export const TITLE_MEMBER = {
    family: "Famille",
};

export const PROTOCOLE_TYPE = ["SFTP", "FTP"];

export const feretAddress = "1 cours Xavier Arnozan";
export const feretPostalCode = "33000 Bordeaux";

export const feretCEO = "Julien Parrou-Duboscq";
