import { useEffect, useState } from "react";

import { formatPhotonResults } from "../helpers/helpers";
import { getRequest } from "../helpers/network";

const useGeoApi = (searchString, initialValue = "", noPostalCode = false) => {
    const [formattedData, setFormattedData] = useState([]);
    const [data, setData] = useState(initialValue);
    const [loading, setLoading] = useState(false);

    const fetchData = async (url) => {
        setLoading(true);
        const data = await getRequest(url);
        const dataWithTypedText = {
            features: [
                {
                    properties: {
                        street: searchString,
                        name: searchString,
                    },
                },
            ],
        };

        if (data?.features?.length > 0) {
            dataWithTypedText.features = dataWithTypedText.features.concat(data.features);
        }

        setData(dataWithTypedText.features);
        setFormattedData(formatPhotonResults(dataWithTypedText, noPostalCode));
        setLoading(false);
    };

    useEffect(() => {
        const sanitizedString = searchString?.replace(/ /g, "+");
        let url = `https://api-adresse.data.gouv.fr/search/?q=${sanitizedString}&lat=44.818243&lon=-0.556800`;
        if (sanitizedString?.length > 4) {
            fetchData(url);
        } else {
            setLoading(false);
            setData(initialValue);
            setFormattedData([]);
        }
    }, [searchString]);

    return { loading, data, formattedData };
};

export default useGeoApi;
