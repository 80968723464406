/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import PropTypes, { func, string } from "prop-types";
import { Box, CardMedia, Hidden, makeStyles, Typography } from "@material-ui/core";
import PublicationTimelineElement from "./PublicationTimelineElement";
import BookPopin from "../../Popins/BookPopin";
import { useGetBesvInfoQuery } from "../../../generated/graphql";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
SwiperCore.use([Pagination]);
import bookPicture from "../../../assets/livre.png";
import { t, Trans } from "@lingui/macro";
import Arrow from "../../../Components/Arrow/Arrow";
import theme from "../../../globalTheme";
import EstateTitledSemiUnderlined from "../../../FrontEstate/Components/EstateTitledSemiUnderlined";
import { EDITIONS } from "config/constants";

const useStyles = makeStyles((theme) => ({
    publicationTimeline: (props) => ({
        display: "flex",
        position: "relative",
        gap: "14px",
        zIndex: 10,
    }),
    hrElement: {
        position: "absolute",
        top: "17px",
        right: "-20%",
        zIndex: -1,
        width: "30px",
        border: "none",
        height: "2px",
        backgroundColor: theme.palette.secondary.dark,
        [theme.breakpoints.down("sm")]: {
            width: "40px",
            right: "-20%",
        },
    },
    swiper: {
        "& .swiper-wrapper": {
            padding: "1rem 0",
            [theme.breakpoints.down("lg")]: {
                marginBottom: "1rem",
            },
        },
        "& .swiper-pagination-bullet": {
            background: theme.palette.primary.main,
        },
    },
    estatePage__bookPictureContainer: {
        position: "relative",
    },
    estatePage__bookPicture: {
        position: "absolute",
        top: "-64px",
        right: "-15px",
        width: "353px",
        height: "201px",
        zIndex: 1,
        [theme.breakpoints.only("xs")]: {
            width: "120px",
            height: "65px",
            top: "-60px",
            right: "5px",
            zIndex: -1,
        },
        [theme.breakpoints.down("md")]: {
            width: "220px",
            height: "125px",
            top: "-20px",
            right: "5px",
            zIndex: -1,
        },
    },
}));

const PublicationTimeline = (props) => {
    const styles = useStyles(props);
    const [publicationId, setPublicationId] = useState(null);

    const { data: queryData, loading } = useGetBesvInfoQuery({
        variables: { wineEntityId: props.wineEntityId },
    });

    const data = queryData?.GetBesvInfo;

    useEffect(() => {
        if (props.setBesvInfosAreLoading) {
            props.setBesvInfosAreLoading(loading);
        }
    }, [loading]);

    const isDisabled = () => {
        return data?.filter((info) => {
            return info.scans;
        });
    };

    const getTimelineExplanations = (styles) => (
        <Box style={{ color: theme.palette.primary.dark }}>
            <Typography className={styles.timelineExplanations} variant="h6">
                {t`Cliquez sur les bulles pour visualiser les publications dans le Féret des années correspondantes.`}
            </Typography>
            <Arrow
                icon={"primary"}
                rotation="105"
                arrowPosition="start"
                style={{ marginLeft: 8, marginBottom: "0.5rem" }}
            >
                <Typography
                    variant="h6"
                    style={{
                        fontSize: "14px",
                        fontFamily: "Mansalva",
                    }}
                >
                    {t`Les bulles rouges sont celles où la propriété est présente.`}
                </Typography>
            </Arrow>
        </Box>
    );

    const editionsInSotck = (year) => {
        if (year === 1868) {
            return EDITIONS[0];
        } else if (year === 1874) {
            return EDITIONS[1];
        } else if (year === 1898) {
            return EDITIONS[2];
        } else if (year === 1922) {
            return EDITIONS[3];
        } else if (year === 2001) {
            return EDITIONS[4];
        } else if (year === 2004) {
            return EDITIONS[5];
        } else if (year === 2007) {
            return EDITIONS[6];
        } else if (year === 2014) {
            return EDITIONS[7];
        } else {
            return "";
        }
    };
    return !isDisabled()?.length || props.disabledTimeline ? null : (
        <Box style={{ padding: "0 2rem", marginTop: "3rem" }}>
            <Box className={styles.estatePage__timelineTypoBox}>
                <EstateTitledSemiUnderlined
                    lowBar={{ space: "10px" }}
                    fontSize={18}
                    title={
                        <Box
                            style={{
                                marginRight: "12px",
                            }}
                        >
                            <Trans>
                                {props.estateName} dans <i> Bordeaux et ses Vins</i>
                            </Trans>
                        </Box>
                    }
                />
            </Box>
            <Hidden smDown>
                <Box className={styles.estatePage__bookPictureContainer}>
                    <CardMedia className={styles.estatePage__bookPicture} image={bookPicture} />
                </Box>
            </Hidden>
            {getTimelineExplanations(styles)}
            <Swiper
                pagination={{ dynamicBullets: true }}
                className={styles.swiper}
                breakpoints={{
                    0: {
                        slidesPerView: 4,
                    },
                    450: {
                        slidesPerView: 6,
                    },
                    600: {
                        slidesPerView: 8,
                    },
                    740: {
                        slidesPerView: 11,
                    },
                    960: {
                        slidesPerView: 14,
                        //   spaceBetween: 40,
                    },
                    1200: {
                        slidesPerView: 17,
                        //   spaceBetween: 50,
                    },
                    1300: {
                        slidesPerView: 20,
                    },
                    1600: {
                        slidesPerView: 23,
                    },
                    1920: {
                        slidesPerView: 20,
                    },
                }}
            >
                <Box className={styles.publicationTimeline}>
                    {data?.map((e, i) => {
                        let key = `publication-element-${i}`;
                        return (
                            <Box
                                key={key}
                                style={{
                                    position: "relative",
                                    height: "fit-content",
                                }}
                            >
                                <Box display="flex" flex={1}>
                                    <SwiperSlide style={{ background: "none" }}>
                                        {!e?.page ? (
                                            <PublicationTimelineElement
                                                pageNumber={e?.page}
                                                activeElement={i === publicationId}
                                                onClose={() => {
                                                    setPublicationId(null);
                                                }}
                                                label={e?.year}
                                                disabled={!e?.page}
                                                onClick={() => {
                                                    setPublicationId(i);
                                                }}
                                            />
                                        ) : (
                                            <BookPopin
                                                estateName={props.estateName}
                                                loading={loading}
                                                scans={e.scans?.map((e) => e.data)}
                                                linkToBuy={editionsInSotck(e?.year)}
                                                clickableElement={
                                                    <PublicationTimelineElement
                                                        label={e.year}
                                                        disabled={e?.page === null}
                                                    />
                                                }
                                            />
                                        )}
                                        {i === data?.length - 1 ? null : <hr className={styles.hrElement} />}
                                    </SwiperSlide>
                                </Box>
                            </Box>
                        );
                    })}
                </Box>
            </Swiper>
        </Box>
    );
};

PublicationTimeline.propTypes = {
    wineEntityId: PropTypes.string,
    disabledTimeline: PropTypes.bool,
    estateName: string,
    setBesvInfosAreLoading: func,
};

export default PublicationTimeline;
