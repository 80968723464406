import NotFoundPage from "Components/ErrorPage/NotFoundPage";
import LandingPage from "Pages/LandingPage";
import { NavbarContext } from "Routes/MainRouter";
import { PrivateRoute } from "Routes/MainRouter/PrivateRoute";
import StandAloneRouter from "Routes/StandAloneRouter";
import { ConnectedSubscriptionRouter } from "Routes/SubscriptionRouter";
import { any, bool, func, string } from "prop-types";
import { Route, Switch } from "react-router-dom";

export const StandaloneRouter = ({
    wineEntityId,
    welcomePopin,
    setWelcomePopin,
    openConnectionMenu,
    setOpenConnectionMenu,
    ftId,
    setFtId,
}) => (
    <Switch>
        <PrivateRoute path="/subscription">
            <ConnectedSubscriptionRouter welcomePopin={welcomePopin} openWelcomePopin={setWelcomePopin} isStandalone />
        </PrivateRoute>

        <Route path="/bienvenue">
            <LandingPage isStandalone />
        </Route>

        <Route exact path="/">
            <StandAloneRouter wineEntityId={wineEntityId} />
        </Route>

        <NavbarContext.Provider value={{ openConnectionMenu, setOpenConnectionMenu, ftId, setFtId }}>
            <StandAloneRouter wineEntityId={wineEntityId} />
        </NavbarContext.Provider>

        <Route path="*">
            <NotFoundPage />
        </Route>
    </Switch>
);

export default StandaloneRouter;

StandaloneRouter.propTypes = {
    wineEntityId: string,
    welcomePopin: bool,
    setWelcomePopin: func,
    openConnectionMenu: bool,
    setOpenConnectionMenu: func,
    ftId: any,
    setFtId: func,
};
