import { makeStyles, Card, CardMedia, Grid, CardContent, Typography, Chip, Box } from "@material-ui/core";
import { array, arrayOf, bool, number, object, string } from "prop-types";
import { Link, useParams } from "react-router-dom";
import { t, Trans } from "@lingui/macro";

import BaseButton from "../../../Components/Buttons/BaseButton";
import cardoenofio from "../../../assets/cardoenofio.png";
import calendar_icon from "../../../assets/propriétéPage/calendar_icon.svg";
import max_icon from "../../../assets/propriétéPage/max_icon.svg";
import threedots from "../../../assets/threedots.svg";

import handicap from "../../../assets/propriétéPage/handicap.svg";
import prix from "../../../assets/propriétéPage/prix.svg";
import reservation from "../../../assets/propriétéPage/reservation.svg";
import withoutReservation from "../../../assets/propriétéPage/withoutreservation.svg";

import BaseChip from "../../../Components/Chips/BaseChip";
import Tooltip from "../../../Components/Tooltips/Tooltip";

import { useCustomHistory } from "hooks";
import { getSentence } from "../../../FrontVintage/Components/vintageHelpers";
import { activityPersons, firstLetterUppercase, parseHtml } from "../../../helpers/helpers";

import theme from "../../../globalTheme";

const useStyles = makeStyles(() => ({
    cardContainer: {
        boxShadow: "0px 0px 14px rgb(0 0 0 / 14%)",
        [theme.breakpoints.up("xs")]: {
            width: "85vw",
        },
        [theme.breakpoints.up("sm")]: {
            width: "301px",
        },
        [theme.breakpoints.up("md")]: {},
        [theme.breakpoints.up("lg")]: {},
        [theme.breakpoints.up("xl")]: {},
        "& ::-webkit-scrollbar": {
            width: "8px",
        },
        "& ::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            paddingRight: "8px",
        },
        "& ::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "20px",
        },
    },
    container__secondpart: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",

        flexWrap: "wrap",
        justifyContent: "space-between",
        width: "100%",

        paddingTop: 0,
        paddingLeft: 8,
        paddingRight: 8,
        paddingBottom: 8,
        minHeight: 70,
        height: 70,
        maxHeight: 70,
        minWidth: 1,
    },
    media: {
        width: "100%",
        height: "169px",
    },
    buttonContainer: {
        marginBottom: 16,
    },
    threedots: {
        cursor: "pointer",
    },
    button: {
        width: "100%",
        marginTop: "4%",
        position: "relative",
        zIndex: 100,
    },
    mediaIcon: {
        width: 15,
        height: 15,
        alignSelf: "center",
        marginRight: theme.spacing(1),
    },
    chip: {
        ...theme.front.typography.label.dark,
        margin: theme.spacing(1),
        paddingRight: "8px",
        paddingLeft: "8px",
        right: 0,
        position: "absolute",
        color: theme.palette.common.white,
        border: "none",
    },

    container__description: {
        width: "100%",
        marginBottom: 0,
        padding: 6,
        paddingTop: 0,
        minHeight: 80,
        height: 80,
        maxHeight: 80,
        overflow: "auto",
        minWidth: 1,
    },
    container__nameAndDuration: {
        marginBottom: 16,
        padding: 6,
        paddingTop: 16,
        minHeight: 70,
        height: 70,
        maxHeight: 70,
        minWidth: 1,
        width: "100%",
    },
    activityCard__typoDuration: {
        ...theme.front.typography.h6.secondDark_b,
        wordBreak: "break-word",
        flewWrap: "nowrap",
    },
    activityCard__typoDescription: {
        ...theme.front.typography.labeur.dark,
        overflow: "hidden",
        display: "-webkit-box",
        WebkitLineClamp: 3,
        WebkitBoxOrient: "vertical",
    },
    activityCard__typoReservation: {
        ...theme.front.typography.label.darkLight,
    },
    activityCard__typoCalendrier: {
        ...theme.front.typography.label.darkLight,
    },
    activityCard__typoMaxiPerson: {
        ...theme.front.typography.label.darkLight,
    },
    activityCard__typoPrices: {
        ...theme.front.typography.label.darkLight,
    },
    emptyBox: {
        width: "22px",
        height: "22px",
        marginRight: "8px",
        boxShadow: "0px 0px 5px #0000006C",
        borderRadius: "50%",
        marginBottom: "4px",
        background: theme.palette.common.white,
    },
    languagesBox: {
        width: "22px",
        height: "22px",
        marginRight: "8px",
        boxShadow: "0px 0px 5px #0000006C",
        borderRadius: "50%",
        marginBottom: "4px",
    },
}));

const getLimitSvg = (activityChildren) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="56" viewBox="0 0 40 56">
            <g id="Groupe_5373" data-name="Groupe 5373" transform="translate(-5717.338 -2808.718)">
                <g id="Groupe_5330" data-name="Groupe 5330" transform="translate(5726.488 2816.007)">
                    <path
                        id="Tracé_2481"
                        data-name="Tracé 2481"
                        d="M-442.422,506.269a3.684,3.684,0,0,1,1.506.747,2.914,2.914,0,0,1,.019,4.316,2.892,2.892,0,0,1-4.348-.47,2.9,2.9,0,0,1,.882-4.163,9.477,9.477,0,0,1,1.058-.43Z"
                        transform="translate(457.197 -506.269)"
                        fill="#632d3e"
                    />
                    <path
                        id="Tracé_2482"
                        data-name="Tracé 2482"
                        d="M-665.164,635.913c0,1.2,0,2.333,0,3.461a3.261,3.261,0,0,1-.249,1.587.743.743,0,0,0-.029.325q0,5.173,0,10.345a1.348,1.348,0,0,1-.879,1.384,1.35,1.35,0,0,1-1.8-1.358c-.013-.487,0-.975,0-1.462v-7.71l-.078-.023c-.032.039-.084.076-.092.12a1.314,1.314,0,0,0-.006.247q0,4.386,0,8.772a1.343,1.343,0,0,1-.955,1.437,1.321,1.321,0,0,1-1.7-1.16c-.011-.128-.017-.257-.017-.385,0-3.43.071-6.862-.035-10.289-.053-1.7-.079-3.4-.16-5.125-.168.308-.349.61-.5.925-.483.989-.948,1.986-1.438,2.971a4.5,4.5,0,0,1-.431.59,1.745,1.745,0,0,0-.117.2,2.327,2.327,0,0,1-.334.6,2.937,2.937,0,0,1-1.931.953,7.888,7.888,0,0,1-.974-.087,6.979,6.979,0,0,0,.106,1.822c.224,1.133.427,2.27.637,3.406a.634.634,0,0,1-.006.128h-.921v.314q0,2.193,0,4.386a.838.838,0,0,1-.533.826.815.815,0,0,1-.9-.149.929.929,0,0,1-.286-.772q.006-2.138,0-4.276c0-.1-.012-.2-.019-.3l-.076,0c0,.094-.012.188-.012.281,0,1.444,0,2.888,0,4.331a.868.868,0,0,1-.883.949.829.829,0,0,1-.82-.71,2.815,2.815,0,0,1-.026-.412q0-2.055,0-4.11V647.6h-.8c.189-1.689.683-3.3.7-4.988a2.542,2.542,0,0,0-.374,1.2c-.019.183-.026.367-.048.549a.68.68,0,0,1-.664.642.651.651,0,0,1-.651-.71,8.9,8.9,0,0,1,.279-1.728,3.279,3.279,0,0,1,1.7-2.073c.335-.171.657-.366.979-.561a.284.284,0,0,1,.439.083,3.121,3.121,0,0,0,.274.291l.386-.478c1.11.416,2.1,1.107,3.379.615a1.434,1.434,0,0,1,.02-1.735c.682-1.341,1.317-2.707,2.046-4.021a8.239,8.239,0,0,1,2.762-3.131c.362-.226.772-.376,1.155-.57.164-.083.266-.027.381.094.171.179.363.337.62.571.137-.163.307-.352.463-.552a.32.32,0,0,1,.431-.1,6.821,6.821,0,0,1,4.309,4.571,9.78,9.78,0,0,1,.3,3.576c-.019.219-.017.442-.054.658a1.136,1.136,0,0,1-1.216.993,1.175,1.175,0,0,1-1.045-1.266c.028-.706.062-1.414.034-2.119a9.63,9.63,0,0,0-.224-1.325A.912.912,0,0,0-665.164,635.913Z"
                        transform="translate(682.482 -624.932)"
                        fill="#632d3e"
                    />
                    <path
                        id="Tracé_2483"
                        data-name="Tracé 2483"
                        d="M-643.417,739.579a1.852,1.852,0,0,1-1.851-1.855,1.859,1.859,0,0,1,1.871-1.865,1.853,1.853,0,0,1,1.848,1.885A1.846,1.846,0,0,1-643.417,739.579Z"
                        transform="translate(647.063 -724.782)"
                        fill="#632d3e"
                    />
                </g>
                <g
                    id="Rectangle_1109"
                    data-name="Rectangle 1109"
                    transform="translate(5717.338 2808.718)"
                    fill="none"
                    stroke="#632d3e"
                    strokeWidth="1"
                >
                    <rect width="40" height="56" rx="4" stroke="none" />
                    <rect x="0.5" y="0.5" width="39" height="55" rx="3.5" fill="none" />
                </g>
                <path
                    id="Rectangle_1110"
                    data-name="Rectangle 1110"
                    d="M4,0H36a4,4,0,0,1,4,4V17a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V4A4,4,0,0,1,4,0Z"
                    transform="translate(5757.338 2864.718) rotate(180)"
                    fill="#632d3e"
                />
                <text
                    id="_16_"
                    data-name="16 +"
                    transform="translate(5737.338 2861.218)"
                    fill="#fcf6f6"
                    fontSize="14"
                    fontFamily="Lato-Bold, Lato"
                    fontWeight="700"
                >
                    <tspan x="-13.531" y="0">
                        {activityChildren + " +"}
                    </tspan>
                </text>
            </g>
        </svg>
    );
};

const emptyContent = (listOfItems) => {
    return (
        <Grid
            item
            xs={12}
            style={{
                height: `calc(25px * ${listOfItems})`,
                padding: 2,
                display: "flex",
                alignItems: "flex-start",
            }}
        ></Grid>
    );
};

const getNameAndDescription = (title, duration, description, minDuration, styles) => (
    <>
        <Box className={styles.container__nameAndDuration}>
            <Typography variant="h4" display="inline">
                {title}
            </Typography>
            <Typography className={styles.activityCard__typoDuration} display="inline"></Typography>
        </Box>
        <Box className={styles.container__description}>
            <Typography className={styles.activityCard__typoDescription}>
                {description ? parseHtml(description) : null}
            </Typography>
        </Box>
    </>
);

const getHandicapInfos = (handicap) => {
    return (
        <Box>
            <Typography variant="h6">
                <Trans>Accueil des personnes atteintes de handicap : </Trans>
            </Typography>

            {handicap.map((item, i) => {
                return (
                    <Typography
                        key={i}
                        variant="h6"
                        style={{
                            color: theme.palette.common.white,
                        }}
                    >
                        • {item}
                    </Typography>
                );
            })}
        </Box>
    );
};

const getPrices = (prices) => {
    if (prices?.length === 1) {
        return (
            <Typography variant="h6" style={{ color: theme.palette.primary.light }}>
                {`${prices?.[0]?.price} €`}
            </Typography>
        );
    } else if (prices?.length > 1) {
        const arr = prices?.map((el) => el?.price);
        const sortedArr = arr.sort((a, b) => a - b);

        return (
            <Box style={{ display: "flex" }}>
                <Typography variant="h6" style={{ color: theme.palette.primary.light }}>{`${sortedArr?.[0]} € - ${
                    sortedArr?.[sortedArr?.length - 1]
                } €`}</Typography>
            </Box>
        );
    }
    return "";
};

const getActivityInformations = (props, styles) => {
    return (
        <>
            {/* MAXIMUM PERSONS */}
            {activityPersons(props.activityMaxPersons[0], props.activityMaxPersons[1]) ? (
                <Grid
                    item
                    xs={12}
                    style={{
                        padding: 2,
                        display: "flex",
                        alignItems: "flex-start",
                    }}
                >
                    <CardMedia
                        image={max_icon}
                        className={styles.mediaIcon}
                        style={{ alignSelf: "flex-start", marginTop: "3px" }}
                    />

                    <Typography className={styles.activityCard__typoMaxiPerson}>
                        {activityPersons(props.activityMaxPersons[0], props.activityMaxPersons[1])}
                    </Typography>
                </Grid>
            ) : (
                emptyContent(1)
            )}

            {/* RESERVATION */}
            {props.activityReservation ? (
                <Grid
                    item
                    xs={12}
                    style={{
                        padding: 2,
                        display: "flex",
                        alignItems: "flex-start",
                    }}
                >
                    <CardMedia
                        image={reservation}
                        className={styles.mediaIcon}
                        style={{ alignSelf: "flex-start", marginTop: "3px" }}
                    />
                    <Typography className={styles.activityCard__typoReservation}>
                        <Trans>Sur réservation</Trans>
                    </Typography>
                </Grid>
            ) : (
                <Grid
                    item
                    xs={12}
                    style={{
                        padding: 2,
                        display: "flex",
                        alignItems: "flex-start",
                    }}
                >
                    <CardMedia
                        image={withoutReservation}
                        className={styles.mediaIcon}
                        style={{ alignSelf: "flex-start", marginTop: "3px" }}
                    />
                    <Typography className={styles.activityCard__typoReservation}>
                        <Trans>Sans réservation</Trans>
                    </Typography>
                </Grid>
            )}

            {/* CALENDRIER */}
            {props.activityReservationTime && props.activityReservationTime?.length !== 0 ? (
                <Grid
                    item
                    xs={12}
                    style={{
                        padding: 2,
                        display: "flex",
                        alignItems: "flex-start",
                        height: "50px",
                    }}
                >
                    <CardMedia
                        image={calendar_icon}
                        className={styles.mediaIcon}
                        style={{
                            alignSelf: "flex-start",
                            marginTop: "3px",
                        }}
                    />
                    <Box>
                        {props.activityReservationTime
                            ?.filter((el, index) => index < 2)
                            ?.map((el, i) => {
                                return (
                                    <>
                                        <Typography key={i} className={styles.activityCard__typoCalendrier}>
                                            {getSentence(el)}
                                        </Typography>
                                    </>
                                );
                            })}

                        {props.activityReservationTime.length > 2 ? (
                            <Tooltip title={t`Voir plus`} fontSize="14px" color="primary" arrow>
                                <Box
                                    component={Link}
                                    to={props.to}
                                    style={{
                                        width: "21px",
                                        alignItems: "center",
                                        display: "flex",
                                        justifyContent: "center",
                                        color: theme.palette.common.white,
                                        cursor: "pointer",
                                        padding: "4px 0px",
                                        marginLeft: -4,
                                    }}
                                >
                                    <img src={threedots} />
                                </Box>
                            </Tooltip>
                        ) : null}
                    </Box>
                </Grid>
            ) : (
                emptyContent(2)
            )}

            {/* PRICES */}
            {((props.activityPrice && props.activityPrice?.length !== 0) || props.activityPriceFree) && (
                <Grid
                    item
                    xs={12}
                    style={{
                        padding: 2,
                        display: "flex",
                        alignItems: "flex-start",
                    }}
                >
                    <CardMedia
                        image={prix}
                        className={styles.mediaIcon}
                        style={{ alignSelf: "flex-start", marginTop: "3px" }}
                    />
                    {props.activityPriceFree ? (
                        <Typography className={styles.activityCard__typoPrices}>
                            <Trans>Activité gratuite</Trans>
                        </Typography>
                    ) : (
                        getPrices(props.activityPrice)
                    )}
                </Grid>
            )}
        </>
    );
};

const getTags = (activities, props) => {
    if (activities) {
        return (
            <Box
                style={{
                    marginRight: "14px",
                }}
            >
                {activities.length > 1 ? (
                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                        }}
                    >
                        <Box
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                                height: "30px",
                                overflow: "hidden",
                            }}
                        >
                            {activities?.map((item, i) => {
                                // if (i < 1) {
                                return (
                                    <BaseChip
                                        title={item}
                                        key={i}
                                        style={{
                                            marginRight: "4px",
                                            marginBottom: "4px",
                                        }}
                                    />
                                );
                            })}
                        </Box>
                        <Tooltip title={t`Voir plus`} fontSize="14px" color="primary" arrow>
                            <Box
                                component={Link}
                                to={props.to}
                                style={{
                                    width: "21px",
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    color: theme.palette.common.white,
                                    cursor: "pointer",
                                }}
                            >
                                <img src={threedots} />
                            </Box>
                        </Tooltip>
                    </Box>
                ) : (
                    activities?.map((item, i) => {
                        return <BaseChip title={item} key={i} style={{ marginRight: "4px" }} />;
                    })
                )}
            </Box>
        );
    }
};

const ActivityCard = (props) => {
    const styles = useStyles();
    const history = useCustomHistory();
    const { wineEntityShortId, propertyName } = useParams();

    return (
        <Grid item style={{ borderRadius: "5px", height: "670px", maxHeight: "670px" }}>
            <Card
                className={styles.cardContainer}
                style={{
                    marginTop: "3%",
                    height: "90%",
                    backgroundImage: `url(${cardoenofio})`,
                    backgroundPosition: "bottom right",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "60%",
                    borderRadius: "16px",
                }}
            >
                {props.activityType && props.activityType?.length !== 0 && (
                    <Box style={{ position: "relative" }}>
                        <Chip
                            label={firstLetterUppercase(props.activityType?.type)}
                            variant="contained"
                            className={styles.chip}
                            style={{
                                backgroundColor: props.activityType?.bgColor,
                                color: props.activityType?.textColor,
                                border:
                                    props.activityType?.bgColor === theme.palette.common.white
                                        ? `1px solid ${theme.palette.primary.dark}`
                                        : "none",
                            }}
                        />
                    </Box>
                )}

                {props.activityImage && (
                    <CardMedia
                        image={props.activityImage}
                        className={styles.media}
                        alt={`${props.name} - ${props.wineEntityName}`}
                    />
                )}

                <CardContent style={{ padding: "10px 25px", paddingBottom: 20 }}>
                    <Grid container item spacing={3}>
                        {getNameAndDescription(
                            props.name,
                            props.activityDuration,
                            props.activityDescription,
                            props.activityMinDuration,
                            styles
                        )}

                        <Box className={styles.container__secondpart}>
                            <Box
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "flex-end",
                                }}
                            >
                                {props.activityHandicap && (
                                    <Tooltip
                                        title={getHandicapInfos(props.activityHandicap)}
                                        fontSize="14px"
                                        color="primary"
                                        arrow
                                    >
                                        <img src={handicap} alt="handicap_icon" style={{ marginRight: "12px" }} />
                                    </Tooltip>
                                )}
                                {props.activityChildren && getLimitSvg(props.activityChildren)}
                            </Box>

                            {props.activityLanguages && (
                                <Box
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        flexWrap: "wrap",
                                        marginTop: theme.spacing(2),
                                    }}
                                >
                                    {props.activityLanguages.map((item, i) => {
                                        if (i <= 3) {
                                            return (
                                                <Tooltip
                                                    fontSize="14px"
                                                    color="primary"
                                                    arrow
                                                    key={i}
                                                    title={item?.language}
                                                >
                                                    {item?.icon?.twicpicURL ? (
                                                        <Box
                                                            className={styles.languagesBox}
                                                            style={{
                                                                background: `center / cover no-repeat url(${item?.icon?.twicpicURL})`,
                                                            }}
                                                        ></Box>
                                                    ) : (
                                                        <Box className={styles.emptyBox}></Box>
                                                    )}
                                                </Tooltip>
                                            );
                                        }
                                        if (i === 4) {
                                            return item?.icon?.data ? (
                                                <Box
                                                    component={Link}
                                                    to={props.to}
                                                    className={styles.languagesBox}
                                                    style={{
                                                        cursor: "pointer",
                                                        background: theme.palette.primary.main,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        // background: `center / cover no-repeat url(${item?.icon?.data})`,
                                                    }}
                                                >
                                                    <Typography
                                                        style={{
                                                            color: theme.palette.common.white,
                                                            fontSize: 14,
                                                            fontWeight: "bold",
                                                        }}
                                                    >{`+${props.activityLanguages?.length - 3}`}</Typography>
                                                </Box>
                                            ) : (
                                                <Box className={styles.emptyBox}></Box>
                                            );
                                        }
                                    })}
                                </Box>
                            )}
                        </Box>
                        <Box
                            style={{
                                minHeight: 30,
                                height: 30,
                                maxHeight: 30,
                                minWidth: 1,
                                width: "100%",
                            }}
                        >
                            {getTags(props.activityTags, props)}
                        </Box>
                        <Grid container item flexDirection="column" spacing={1} style={{ minWidth: 1, padding: 6 }}>
                            {getActivityInformations(props, styles, history, wineEntityShortId, propertyName)}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Grid container className={styles.buttonContainer}>
                <BaseButton
                    component={Link}
                    to={props.to}
                    boxStyle={{ width: "100%" }}
                    style={{
                        color: theme.palette.common.white,
                        backgroundColor: theme.palette.primary.main,
                        borderColor: theme.palette.primary.main,
                    }}
                    classes={styles.button}
                    variant="contained"
                    color="primary"
                    size="small"
                >
                    <Trans>En savoir plus</Trans>
                </BaseButton>
            </Grid>
        </Grid>
    );
};

ActivityCard.propTypes = {
    activityImage: string,
    name: string,
    activityDescription: string,
    activityDuration: string,
    activityMinDuration: string,
    activityReservationTime: arrayOf(object),
    activityMaxPersons: arrayOf(number),
    activityLanguages: arrayOf(object),
    activityType: object,
    activityTags: array,
    activityHandicap: arrayOf(string),
    activityChildren: number,
    activityReservation: bool,
    activityPrice: arrayOf(object),
    activityPriceFree: bool,
    to: string,
    wineEntityName: string,
};

export default ActivityCard;
