/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import React from "react";
import { Typography, makeStyles, Box, Hidden } from "@material-ui/core";
import PropTypes from "prop-types";
import theme from "../../globalTheme";
import Popin from "../../Containers/Popin";
import greentip from "../../assets/Wine/02_bourgeon_coton.png";
import begflo from "../../assets/Wine/09_floraison.png";
import endflo from "../../assets/Wine/11_petits_pois.png";
import begver from "../../assets/Wine/14_maturite.png";
import leaves from "../../assets/Wine/05_feuille.png";
import { firstLetterUppercase } from "../../helpers/helpers";
import { t, Trans } from "@lingui/macro";
import { getDate } from "./vintageHelpers";

const useStyle = makeStyles((theme) => ({}));

const WinePhenologicalStagesPopin = (props) => {
    const styles = useStyle(props);

    const getPhenologicalStages = () => {
        let finalArr = [];
        const getColor = (number) => {
            if (number % 2 == 0) {
                return theme.palette.dashboard.background;
            } else {
                return theme.palette.common.white;
            }
        };
        if (
            !props.phenologicalStages ||
            props.phenologicalStages?.length === 0
        ) {
            return;
        } else {
            props.phenologicalStages?.map((el, i) => {
                finalArr.push(
                    <Box
                        style={{
                            display: "flex",
                            padding: "0px 20px",
                            backgroundColor: getColor(i),
                        }}
                    >
                        <Typography
                            style={{
                                fontSize: "16px",
                                color: "#360318",
                                width: props.byGrapes ? "30%" : "40%",
                            }}
                        >
                            {firstLetterUppercase(el?.stage)}
                        </Typography>
                        {props.byGrapes && (
                            <Typography
                                style={{
                                    fontSize: "16px",
                                    color: theme.palette.primary.dark,
                                    width: "30%",
                                }}
                            >
                                {firstLetterUppercase(el?.grapeVariety)}
                            </Typography>
                        )}
                        <Typography
                            style={{
                                fontSize: "16px",
                                color:
                                    !getDate(el?.startAt) ?
                                    theme.palette.secondary.dark : theme.palette.primary.dark,
                                width: props.byGrapes ? "20%" : "30%",
                            }}
                        >
                            {getDate(el?.startAt)
                                ? getDate(el?.startAt)
                                : t`Non précisé`}
                        </Typography>
                        <Typography
                            style={{
                                fontSize: "16px",
                                color:
                                    !getDate(el?.endAt) ?
                                    theme.palette.secondary.dark : theme.palette.primary.dark,
                                width: props.byGrapes ? "20%" : "30%",
                            }}
                        >
                            {getDate(el?.endAt)
                                ? getDate(el?.endAt)
                                : t`Non précisé`}
                        </Typography>
                    </Box>
                );
            });
        }
        return finalArr;
    };

    return (
        <Popin
            title={
                <>
                    <Hidden xsDown>
                        <Box
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                flexWrap: "wrap",
                            }}
                        >
                            <img
                                src={greentip}
                                alt="phenological_stages_img"
                                style={{ height: "104px" }}
                            />
                            <img
                                src={leaves}
                                alt="phenological_stages_img"
                                style={{ height: "104px" }}
                            />
                            <img
                                src={begflo}
                                alt="phenological_stages_img"
                                style={{ height: "104px" }}
                            />
                            <img
                                src={endflo}
                                alt="phenological_stages_img"
                                style={{ height: "104px" }}
                            />
                            <img
                                src={begver}
                                alt="phenological_stages_img"
                                style={{ height: "104px" }}
                            />
                        </Box>
                        <Typography variant="h3" style={{ margin: "30px 0px" }}>
                            <Trans>Stades phénologiques</Trans>
                        </Typography>
                    </Hidden>
                    <Hidden smUp>
                        <Typography
                            variant="h3"
                            style={{ marginBottom: "30px" }}
                        >
                            <Trans>Stades phénologiques</Trans>
                        </Typography>
                    </Hidden>
                </>
            }
            background={props.background}
            open={props.open}
            cross
            onClose={props.onClose}
        >
            <Box style={{ paddingBottom: theme.spacing(5), width: "800px" }}>
                <Box
                    style={{
                        border: "1px solid",
                        borderColor: theme.palette.secondary.main,
                        borderRadius: "5px",
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: theme.palette.common.white,
                    }}
                >
                    <Box style={{ display: "flex", padding: "0px 20px" }}>
                        <Typography
                            style={{
                                fontSize: "14px",
                                color: "#915868",
                                width: props.byGrapes ? "30%" : "40%",
                            }}
                        >
                            <Trans>Stade phénologique</Trans>
                        </Typography>
                        {props.byGrapes && (
                            <Typography
                                style={{
                                    fontSize: "14px",
                                    color: "#915868",
                                    width: "30%",
                                }}
                            >
                                <Trans>Cépages</Trans>
                            </Typography>
                        )}
                        <Typography
                            style={{
                                fontSize: "14px",
                                color: "#915868",
                                width: props.byGrapes ? "20%" : "30%",
                            }}
                        >
                            <Trans>Date de début</Trans>
                        </Typography>
                        <Typography
                            style={{
                                fontSize: "14px",
                                color: "#915868",
                                width: props.byGrapes ? "20%" : "30%",
                            }}
                        >
                            <Trans>Date de fin</Trans>
                        </Typography>
                    </Box>
                    {getPhenologicalStages()}
                </Box>
            </Box>
        </Popin>
    );
};

WinePhenologicalStagesPopin.propTypes = {
    title: PropTypes.string,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    phenologicalStages: PropTypes.array,
    byGrapes: PropTypes.bool,
    background: PropTypes.number,
};

export default WinePhenologicalStagesPopin;
