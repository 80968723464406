import { useMemo, useState } from "react";
import { array, bool, func, object, string } from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Box, Button, Checkbox, ListItemText, Typography } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import removeOrAddItems from "../Pages/SearchEngine/helpers";
import theme from "../globalTheme";
import { useEnumValuesState } from "hooks/useEnumValues";
import { defaultSort, getUserLocale } from "helpers/helpers";
import { useSelector } from "react-redux";
import { ContentLoader } from "Search/ui/ContentLoader";

const useStyles = makeStyles(() => ({
    // BADGE
    formControl: {
        position: "relative",
        background: theme.palette.common.white,
        margin: 0,
        width: "100%",
        border: `0.4px solid ${theme.palette.secondary.main}`,
        borderRadius: "53px",
        marginTop: "0px !important",

        "& .MuiInput-underline:before": {
            borderBottom: "none !important",
        },

        "& .MuiInput-underline:focus": {
            borderBottom: "none !important",
        },

        "& .MuiSelect-select:focus": {
            backgroundColor: "transparent",
        },

        "& label + .MuiInput-formControl": {
            marginTop: 0,
        },

        "& .MuiInput-underline.Mui-focused:after": {
            transform: "scaleX(0)",
        },

        "& .MuiSvgIcon-root": {
            fontSize: "1rem",
            marginRight: 10,
        },
    },
    outlinedSelect: {
        display: "flex",
        "&:focus": {
            background: "none",
        },
    },

    menuItem: {
        backgroundColor: theme.palette.common.white,
        padding: 1,

        "&:hover, &.MuiListItem-root.Mui-selected, &.MuiListItem-root.Mui-selected:hover": {
            backgroundColor: theme.palette.common.white,
        },

        "& .MuiTouchRipple-root": {
            borderRadius: "53px",
        },
    },
    inputLabel: {
        transform: "none !important",
        marginTop: 6,
        marginLeft: 10,
    },
    itemButton: {
        padding: "1rem",
        border: `0.5px solid ${theme.palette.primary.light}`,
        borderRadius: "16px",
        width: 85,
        height: 27,
        backgroundColor: theme.palette.primary.light,
        boxShadow: "none",

        "&.Mui-disabled": {
            borderColor: theme.palette.primary.dark,
            backgroundColor: theme.palette.disabled.main,
            color: theme.palette.primary.dark,
            boxShadow: "none",
        },

        "&:hover": {
            boxShadow: "none",
        },
    },
    paper: {
        width: 500,
        overflowY: "hidden",
        overflowX: "hidden",
    },
    MenuList: {
        boxSizing: "border-box",
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gap: "1px 5px",
        width: "100%",
        maxHeight: 220,
        overflowY: "scroll",

        "&::-webkit-scrollbar": { width: "3px", height: "8px" },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(99,45,62,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(99,45,62,0.00)",
            paddingRight: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.primary.light,
            borderRadius: "20px",
        },

        [theme.breakpoints.up("sm")]: {
            maxHeight: 300,
        },

        [theme.breakpoints.up("md")]: {
            maxHeight: 350,
            // gridTemplateColumns: "repeat(3, 1fr)",
            gap: "9px 5px",
            width: "297px !important",
        },
    },

    // CHECKBOX
    checkbox: {
        color: theme.palette.primary.light,

        "&.MuiCheckbox-colorSecondary.Mui-checked": {
            color: theme.palette.primary.light,
        },
    },
    listItemText: {
        color: theme.palette.common.black,
    },
    menuItem_checkbox: {
        backgroundColor: theme.palette.common.white,
        paddingTop: 0,
        paddingBottom: 0,
        display: "flex",
        justifyContent: "space-between",
        minHeight: "auto",
        marginBottom: "0.5rem",
        marginTop: "0.5rem",

        "&:hover, &.MuiListItem-root.Mui-selected, &.MuiListItem-root.Mui-selected:hover": {
            backgroundColor: theme.palette.common.white,
        },

        "& .MuiIconButton-label": {
            width: "11px",
            height: "11px",
        },
    },

    paper_checkbox: {
        boxSizing: "border-box",
        minWidth: 109,
        borderRadius: 10,
        overflow: "hidden",
        boxShadow: "none",
        margin: "0 auto",
        marginTop: 3,
        paddingBottom: 8,
        paddingTop: 8,
        overflowY: "hidden",
        overflowX: "hidden",
    },
    MenuList_checkbox: {
        width: "100%",
        maxHeight: 220,
        overflowY: "scroll",

        "&::-webkit-scrollbar": { width: "3px", height: "8px" },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(99,45,62,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(99,45,62,0.00)",
            paddingRight: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.primary.light,
            borderRadius: "20px",
        },

        [theme.breakpoints.up("sm")]: {
            maxHeight: 300,
        },

        [theme.breakpoints.up("md")]: {
            maxHeight: 350,
        },
    },
}));

const SelectButtonOrCheckbox = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const locale = useSelector(getUserLocale);
    const styles = useStyles();

    const { data: enumValues, loading } = useEnumValuesState(props.enumField, { locale });

    const options = useMemo(() => {
        let optionLabels;
        if (props.enumField) {
            optionLabels = enumValues;
        } else {
            optionLabels = props.options ?? [];
        }

        const ret = optionLabels.map((name) => {
            const countFound = props.counts?.find((e) => e._id === name);
            const count = countFound?.count;
            return { name, count };
        });
        if (props.sortAlphabetical) {
            ret?.sort(defaultSort);
        }
        return ret;
    }, [enumValues, props.options, props.counts, props.sortAlphabetical]);

    const MenuProps = {
        PaperProps: {
            style: {
                width: "auto",
                borderRadius: 10,
                marginTop: 3,
                boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.08)",
            },
            className: styles.paper,
        },
        MenuListProps: {
            className: styles.MenuList,
            style: {
                padding: "14px 16px",
            },
        },
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
        },
        variant: "menu",
        getContentAnchorEl: null,
    };

    const MenuCheckboxProps = {
        PaperProps: {
            className: styles.paper_checkbox,
            style: {
                boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.08)",
            },
        },
        MenuListProps: {
            className: styles.MenuList_checkbox,
        },
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
        },
        variant: "menu",
        getContentAnchorEl: null,
    };

    const handleClickItem = (item) => {
        let newArr = [...(props?.filter ?? [])];
        if (newArr.includes(item?.name)) {
            newArr = newArr.filter((e) => e !== item?.name);
        } else {
            newArr.push(item?.name);
        }
        props?.setFilters(newArr);
    };

    return (
        <div>
            <FormControl
                className={styles.formControl}
                style={{
                    borderColor: props?.filter?.length > 0 ? theme.palette.primary.dark : theme.palette.secondary.main,
                    backgroundColor:
                        props?.filter?.length > 0
                            ? theme.palette.action.disabledBackground
                            : theme.palette.common.white,
                }}
            >
                <InputLabel className={styles.inputLabel}>
                    <Typography variant="h6">
                        {props?.label}{" "}
                        {props?.filter?.length > 0 && !props?.hideNbInSelect && `(${props?.filter?.length})`}
                    </Typography>
                </InputLabel>
                <Select
                    variant="outlined"
                    multiple
                    value={props?.filter}
                    input={<Input />}
                    renderValue={() => false}
                    MenuProps={props.itemButton ? MenuProps : MenuCheckboxProps}
                    classes={{
                        root: styles.selectRoot,
                        outlined: styles.outlinedSelect,
                    }}
                    onOpen={() => setIsOpen(true)}
                    onClose={() => setIsOpen(false)}
                    open={isOpen}
                    IconComponent={() =>
                        isOpen ? (
                            <KeyboardArrowUpIcon onClick={() => setIsOpen(!isOpen)} style={{ cursor: "pointer" }} />
                        ) : (
                            <KeyboardArrowDownIcon onClick={() => setIsOpen(!isOpen)} style={{ cursor: "pointer" }} />
                        )
                    }
                >
                    {loading && <ContentLoader />}
                    {options.map((item) =>
                        props.itemButton ? (
                            <MenuItem
                                key={item?.name}
                                value={item?.name}
                                className={styles.menuItem}
                                onClick={() => handleClickItem(item)}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={!props?.filters[props?.filterName]?.includes(item?.name)}
                                    className={styles.itemButton}
                                    disableRipple
                                >
                                    <Typography variant="body2">
                                        {item?.name} {item?.count ? "(" + item?.count + ")" : null}
                                    </Typography>
                                </Button>
                            </MenuItem>
                        ) : (
                            <MenuItem
                                key={item?.name}
                                value={item?.name}
                                className={styles.menuItem_checkbox}
                                onClick={() => props.setFilters(removeOrAddItems(item?.name, props?.filter))}
                            >
                                <Box
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Checkbox
                                        checked={props?.filter?.indexOf(item?.name) > -1}
                                        className={styles.checkbox}
                                    />
                                    {item?.flag && (
                                        <img
                                            src={item?.flag}
                                            style={{
                                                marginRight: 5,
                                                borderRadius: "100%",
                                                width: "15px",
                                                height: "15px",
                                                border: `0.2px solid ${theme.palette.secondary.main}`,
                                            }}
                                            alt={`drapeau ${item?.name}`}
                                        />
                                    )}
                                    <ListItemText
                                        primary={
                                            <Typography variant="h6" style={{ whiteSpace: "break-spaces" }}>
                                                {item?.name}
                                            </Typography>
                                        }
                                        className={styles.listItemText}
                                    />
                                </Box>
                            </MenuItem>
                        )
                    )}
                </Select>
            </FormControl>
        </div>
    );
};

SelectButtonOrCheckbox.propTypes = {
    options: array.isRequired,
    label: string.isRequired,
    filter: array,
    filterName: string,
    filters: object,
    setFilters: func,
    handleClick: func,
    fakeResultAftersearch: func,
    winesResults: array,
    setSearchResult: func,
    hideNbInSelect: bool,
    itemButton: bool,
    counts: array,
    enumField: string,
    sortAlphabetical: bool,
};

export default SelectButtonOrCheckbox;
