import townHall from "../assets/summary/town_hall.svg";
import FeretSummaryContainer from "../Front/FeretSummary/FeretSummaryContainer";
import FeretSummaryElement from "../Front/FeretSummary/FeretSummaryElement";
import { Box, makeStyles, Typography } from "@material-ui/core";
import dates from "../assets/summary/dates.svg";
import { t } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
    subtitle_primary: {
        color: theme.palette.primary.light,
    },
    subtitle_secondary: {
        color: theme.palette.primary.dark,
    },
}));

interface RankingSummaryProps {
    dates: { year: number; day: string }[];
    name: string;
    address: { address: string; postalCode: string; commune: string; country: string };
    maxWidth?: string;
    lastDateYear: number;
    lastDate: string;
    nextDateYear: number;
    nextDate: string;
}

const RankingSummary = (props: RankingSummaryProps) => {
    const styles = useStyles();

    return (
        <FeretSummaryContainer maxWidth={props.maxWidth ?? "none"}>
            <FeretSummaryElement
                icon={dates}
                title={t`Dates`}
                subtitle={
                    <Box style={{ display: "flex", flexDirection: "column" }}>
                        {props.lastDateYear && props.lastDate && (
                            <Typography variant="h6" className={styles.subtitle_primary}>
                                {props.lastDateYear} :{" "}
                                <span className={styles.subtitle_secondary}>{props.lastDate}</span>
                            </Typography>
                        )}
                        {props.nextDateYear && props.nextDate && (
                            <Typography variant="h6" className={styles.subtitle_primary}>
                                {props.nextDateYear} :{" "}
                                <span className={styles.subtitle_secondary}>{props.nextDate}</span>
                            </Typography>
                        )}
                    </Box>
                }
            />
            {props.address?.address || props.address?.postalCode ? (
                <FeretSummaryElement
                    icon={townHall}
                    title={t`Adresse`}
                    subtitle={
                        <Box>
                            <Typography variant="h6" className={styles.subtitle_secondary}>
                                {props.name}
                            </Typography>
                            <Typography variant="h6" className={styles.subtitle_secondary}>
                                {props.address?.address}
                            </Typography>
                            <Typography variant="h6" className={styles.subtitle_secondary}>
                                {props.address?.postalCode} {props.address?.commune}
                            </Typography>
                            <Typography variant="h6" className={styles.subtitle_secondary}>
                                {props.address?.country}
                            </Typography>
                        </Box>
                    }
                />
            ) : null}
        </FeretSummaryContainer>
    );
};

export default RankingSummary;
