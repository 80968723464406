import React from "react";

import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import Chip from "@material-ui/core/Chip";

const useStyle = makeStyles((theme) => ({
    medium: {
        borderRadius: "2px",
        fontSize: theme.typography.subtitle1.fontSize,
        padding: "0px",
        maxHeight: "15px",
        maxWidth: "78px",
        "& .MuiChip-label": {
            padding: theme.spacing(0.5),
        },
    },
    large: {
        borderRadius: "3px",
        padding: "0px",
        minWidth: "110px",
        minHeight: "28px",
        fontSize: "14px",
    },
    manualStyleLarge: {
        borderTopLeftRadius: (props) => props.borderTopLeftRadius,
        borderTopRightRadius: (props) => props.borderTopLeftRadius,
    },
}));

const Tag = (props) => {
    const styles = useStyle();

    const large = styles.large;
    const manualStyleLarge = styles.manualStyleLarge;

    return (
        <Chip
            style={{ ...props.style }}
            color={props.color}
            className={props.size === "large" ? `${large} ${manualStyleLarge}` : styles.medium}
            disabled={props.disabled}
            icon={props.icon}
            label={props.label}
        />
    );
};

Tag.propTypes = {
    color: PropTypes.string,
    disabled: PropTypes.bool,
    icon: PropTypes.any,
    size: PropTypes.string,
    label: PropTypes.string,
    style: PropTypes.object,
    borderTopLeftRadius: PropTypes.number,
    borderTopRightRadius: PropTypes.number,
};

export default Tag;
