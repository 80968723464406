import { Box, makeStyles } from "@material-ui/core";
import { string } from "prop-types";

const useStyles = makeStyles(() => ({
    root: (props) => ({
        width: "82px",
        height: "4px",
        backgroundColor: props.color,
        borderRadius: 4,
        marginLeft: "auto",
        marginRight: "auto",
    }),
}));
const SmallDivider = (props) => {
    const styles = useStyles(props);
    return <Box className={`${styles.root} ${props.className}`} />;
};

SmallDivider.propTypes = {
    color: string,
    className: string,
};

export default SmallDivider;
