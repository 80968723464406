import React from "react";
import { Typography, makeStyles, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import theme from "../globalTheme";
import Popin from "../Containers/Popin";
import languages from "../assets/languages.svg";
import { t } from "@lingui/macro";

const useStyle = makeStyles(() => ({
    languagesBox: {
        width: "40px",
        height: "40px",
        marginRight: "12px",
        boxShadow: "0px 0px 5px #0000006C",
        borderRadius: "50%",
        marginBottom: 24,
    },
}));

const ActivityLanguagesPopin = (props) => {
    const styles = useStyle(props);

    const getLanguages = () => {
        return props.languages?.map((el, i) => {
            return (
                <Box key={i} style={{ display: "flex", width: "50%" }}>
                    {el?.icon?.data ? (
                        <Box
                            className={styles.languagesBox}
                            style={{ background: `center / cover no-repeat url(${el?.icon?.data})` }}
                        >
                            {" "}
                        </Box>
                    ) : (
                        ""
                    )}
                    <Typography style={{ color: theme.palette.primary.dark, fontSize: "16px", marginTop: 8 }}>
                        {el?.language}
                    </Typography>
                </Box>
            );
        });
    };

    return (
        <Popin
            title={
                <Box
                    style={{
                        gap: "8px",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "22px",
                        marginRight: "15px",
                    }}
                >
                    <img src={languages} alt="climat_image" style={{ width: 50 }} />
                    <Typography variant="h3">{t`Langues parlées : ${props.languages?.length}`}</Typography>
                </Box>
            }
            background={3}
            open={props.modalOpen}
            cross
            onClose={props.handleClose}
        >
            <Box
                style={{
                    borderRadius: "10px",
                    width: "500px",
                    maxWidth: "600px",
                    display: "flex",
                    flexWrap: "wrap",
                    paddingRight: 40,
                    paddingLeft: 40,
                }}
            >
                {getLanguages()}
            </Box>
        </Popin>
    );
};

ActivityLanguagesPopin.propTypes = {
    modalOpen: PropTypes.bool,
    handleClose: PropTypes.func,
    languages: PropTypes.array,
};

export default ActivityLanguagesPopin;
