import { bool, func, object, string } from "prop-types";

import PaymentContainer from "./PaymentContainer";
import SubscriptionAccordion from "./SubscriptionAccordion";
import { useCustomHistory } from "hooks";
import { Redirect } from "Components/Redirect/Redirect";

const PaymentStepScreen = (props) => {
    const history = useCustomHistory();

    if (props.checkIfPreviousStepIsValid(4)) {
        return (
            <>
                <SubscriptionAccordion
                    setAmountToPay={props.setAmountToPay}
                    subscriptionState={props.subscriptionState}
                    allPrices={props.allPrices}
                    switchCheck={props.switchCheck}
                    onClickBack={() => {
                        props.stepperDispatch({ type: "back" });
                        history.push("/subscription/3");
                    }}
                    commune={props.commune}
                    withPlus={props.withPlus}
                />
                <PaymentContainer
                    selectPayment={props.selectPayment}
                    setSelectPayment={(e) => props.setSelectPayment(e)}
                    subscriptionState={props.subscriptionState}
                    selectPaymentMethod={props.selectPaymentMethod}
                    onClickNext={props.onClickNext}
                    isLoading={props.isLoading}
                />
            </>
        );
    }
    return <Redirect to={{ pathname: "/subscription/0" }} />;
};

PaymentStepScreen.propTypes = {
    checkIfPreviousStepIsValid: func,
    stepperDispatch: func,
    selectPaymentMethod: func,
    onClickNext: func,
    selectPayment: bool,
    setSelectPayment: func,
    subscriptionState: object,
    allPrices: object,
    switchCheck: bool,
    isLoading: bool,
    setAmountToPay: func,
    withPlus: bool,
    commune: string,
};

export default PaymentStepScreen;
