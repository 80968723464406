import { Box, Typography } from "@material-ui/core";
import { bool, func, string } from "prop-types";

import welcome from "../../assets/popin/icones/welcome.svg";
import Modal from "../../Containers/Modal";
import theme from "../../globalTheme";

const getEstateInformations = (estateName, estateCommune, estateMainAOC) => {
    const commune = estateCommune ? ` • ${estateCommune}` : "";
    const mainAOC = estateMainAOC ? ` • ${estateMainAOC}` : "";

    return (
        <Box style={{ textAlign: "center" }}>
            <Typography
                variant="h5"
                style={{ fontWeight: "bold", fontSize: "20px", color: theme.palette.primary.dark }}
            >
                {estateName + mainAOC + commune}
            </Typography>
        </Box>
    );
};

const EstateNotFoundPopin = (props) => {
    return (
        <Modal
            title={`Bienvenue ${props.estateName}`}
            open={props.modalOpen}
            onClose={props.handleClose}
            icon={welcome}
            actionNeeded={true}
            primaryCTA={{
                text: "Continuer",
                onClick: props.onClickNext,
                disabled: props.createSubloading,
            }}
        >
            {getEstateInformations(props.estateName, props.estateCommune, props.estateMainAOC)}
        </Modal>
    );
};

EstateNotFoundPopin.propTypes = {
    estateName: string,
    estateCommune: string,
    estateMainAOC: string,

    modalOpen: bool,
    handleClose: func,

    onClickNext: func,
    createSubloading: bool,
};

export default EstateNotFoundPopin;
