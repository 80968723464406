import { ApolloClient, ApolloProvider, createHttpLink, from, InMemoryCache } from "@apollo/client";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { MuiThemeProvider, StylesProvider } from "@material-ui/core";
import { setContext } from "apollo-link-context";
import { getAuthToken, onAuthStateChange } from "features/user/firebase";
import { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import ErrorPage from "./Components/ErrorPage/ErrorPage";
import { GRAPHQL_SERVER_URL } from "./config/constants";
import theme from "./globalTheme";
import { defaultLocale, dynamicActivate } from "./i18n";
import { MainRouter } from "./Routes/MainRouter";
import store from "./store";

let persistor = persistStore(store);

const httpLink = createHttpLink({
    uri: GRAPHQL_SERVER_URL,
});

const authMiddleware = setContext(async (_, { headers: initialHeaders }) => {
    const headers = { authorization: "jadeisthebest" };
    const token = await getAuthToken();
    if (token) {
        headers["firebase-token"] = token;
    }
    return { headers: { ...initialHeaders, ...headers } };
});

const apolloClientOptions = {
    link: from([authMiddleware, httpLink]),
    cache: new InMemoryCache({ addTypename: false }).restore({}),
};

let apolloClient = new ApolloClient(apolloClientOptions);

export const getApolloClient = () => {
    if (apolloClient) {
        return apolloClient;
    }
    apolloClient = new ApolloClient(apolloClientOptions);
    return apolloClient;
};

const App = () => {
    const [firebaseLoaded, setFirebaseLoaded] = useState();

    onAuthStateChange(() => setFirebaseLoaded(true));

    useEffect(() => {
        // With this method we dynamically load the catalogs
        dynamicActivate(defaultLocale);
    }, []);

    if (!firebaseLoaded) {
        return null;
    }

    return (
        <I18nProvider i18n={i18n}>
            <ErrorBoundary FallbackComponent={() => <ErrorPage />}>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <ApolloProvider client={getApolloClient()}>
                            <StylesProvider injectFirst>
                                <MuiThemeProvider theme={theme}>
                                    <BrowserRouter>
                                        <MainRouter />
                                    </BrowserRouter>
                                </MuiThemeProvider>
                            </StylesProvider>
                        </ApolloProvider>
                    </PersistGate>
                </Provider>
            </ErrorBoundary>
        </I18nProvider>
    );
};

export default App;
