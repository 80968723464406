import React, { useState } from "react";
import PropTypes from "prop-types";
import { Typography, makeStyles } from "@material-ui/core";
import theme from "../globalTheme";
import { Trans } from "@lingui/macro";
import SkillsDetails from "./SkillsDetails";

const useStyles = makeStyles(() => {
    return {
        voirPlus: {
            cursor: "pointer",
            color: "#9F8D93",
            textDecoration: "underline",
            fontSize: "16px",
        },
    };
});
const ExpandableFormattedListBox = (props) => {
    const [expanded, setExpanded] = useState(false);
    const styles = useStyles();

    return (
        <>
            <SkillsDetails data={props.data} height={!expanded && props.maxHeight ? props.maxHeight : "auto"} />
            <Typography
                className={styles.voirPlus}
                onClick={() => {
                    setExpanded(!expanded);
                }}
            >
                {expanded ? <Trans>Voir moins</Trans> : <Trans>Voir plus</Trans>}
            </Typography>
        </>
    );
};

ExpandableFormattedListBox.propTypes = {
    data: PropTypes.array,
    maxHeight: PropTypes.number,
};

export default ExpandableFormattedListBox;
