import { Box, makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import BaseButton from "Components/Buttons/BaseButton";
import FrontContentTop from "Front/FrontContentTop";
import { useWindowDimensions } from "helpers/helpers";

import arrow3 from "../../assets/arrow3.svg";
import Logo404 from "../../assets/ErrorPage/404.svg";
import bigFioTopLeft from "../../assets/ErrorPage/big_top_left.svg";
import fioBottomLeft from "../../assets/ErrorPage/bottom_left.svg";
import fioBottomRight from "../../assets/ErrorPage/bottom_right.svg";
import fioTopLeft from "../../assets/ErrorPage/top_left.svg";
import theme from "../../globalTheme";
import mobileFio from "../../assets/ErrorPage/mobile_fio.svg";
import { Trans } from "@lingui/macro";
import { useCustomHistory } from "hooks";

const useStyles = makeStyles(() => ({
    content: {
        [theme.breakpoints.up("xs")]: {
            marginBottom: "1rem",
            height: "20rem",
        },
        [theme.breakpoints.up("sm")]: {
            height: "calc(100vh - 31rem - 1rem - 50px)",
            minHeight: "20rem",
        },
        [theme.breakpoints.up("md")]: {
            height: "calc(100vh - 14rem -  1rem - 75px)",
        },
    },
    main_content: {
        display: "flex",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    container_texts: {
        [theme.breakpoints.up("xs")]: {
            width: "auto",
            margin: "0 0.5rem",
            textAlign: "center",
        },
        [theme.breakpoints.up("sm")]: {
            textAlign: "left",
            width: "392px",
            margin: 0,
        },
        [theme.breakpoints.up("md")]: {
            width: "auto",
        },
    },
    main_title: {
        color: theme.palette.primary.dark,
        [theme.breakpoints.up("xs")]: {
            textAlign: "center",
        },
        [theme.breakpoints.up("sm")]: {
            textAlign: "left",
        },
    },
    logo_404: {
        [theme.breakpoints.up("xs")]: {
            width: "80vw",
        },
        [theme.breakpoints.up("sm")]: {
            width: "445px",
        },
        [theme.breakpoints.up("md")]: {
            width: "755px",
        },
        [theme.breakpoints.up("lg")]: {
            width: "800px",
        },
        [theme.breakpoints.up("xl")]: {
            width: "1000px",
        },
    },
    container_button: {
        display: "flex",
        gap: "1rem",
        marginTop: "0.5rem",
        [theme.breakpoints.up("xs")]: {
            flexDirection: "column-reverse",
            alignItems: "center",
        },
        [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
        },
    },

    arrow: {
        display: "none",
        [theme.breakpoints.up("xl")]: {
            display: "inherit",
            width: "15px",
            transform: "rotate(115deg)",
            marginLeft: "0.5rem",
        },
    },
}));

const Page404 = () => {
    const styles = useStyles();
    const { width } = useWindowDimensions();
    const history = useCustomHistory();

    return (
        <>
            <Helmet>
                <title>404</title>
            </Helmet>
            <FrontContentTop
                backgroundColor={theme.palette.dashboard.background}
                styleOverridesClassName={{
                    backgroundImage:
                        width >= 1280
                            ? `url(${fioTopLeft}), url(${bigFioTopLeft}), url(${fioBottomLeft}), url(${fioBottomRight})`
                            : width < 600
                            ? `url(${mobileFio})`
                            : `url(${bigFioTopLeft})`,
                    backgroundSize: width < 600 ? "100%" : "auto",
                    backgroundPosition: width < 600 ? "left 30px" : "top left, top left, bottom left, bottom right",
                }}
                firstPart={styles.content}
            >
                <Box className={styles.main_content}>
                    <img src={Logo404} className={styles.logo_404} />
                    <Box className={styles.container_texts}>
                        <Typography variant="h2" className={styles.main_title}>
                            <Trans>
                                Oups cette page <span style={{ color: theme.palette.wine.main }}>n’existe pas</span> !
                            </Trans>
                        </Typography>
                        <Typography variant="h5">
                            <Trans>Nous sommes désolés, la page que vous cherchez ne semble pas disponible.</Trans>
                        </Typography>
                        <Typography variant="h5" style={{ fontFamily: "Mansalva", marginTop: "1rem" }}>
                            <Trans>À la place, voici des liens utiles. Si vous avez besoin d’aide</Trans>{" "}
                            <Link
                                to={history.addLocale("/contact")}
                                style={{
                                    color: theme.palette.wine.main,
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                }}
                            >
                                <Trans>contactez-nous</Trans>
                            </Link>
                        </Typography>
                        <Box className={styles.container_button}>
                            <img className={styles.arrow} src={arrow3} />
                            <BaseButton
                                size="small"
                                color="primary"
                                variant="outlined"
                                component={Link}
                                style={{ width: width < 960 && width >= 600 && "100%" }}
                                boxStyle={{ flex: width < 960 && 1 }}
                                to={history.addLocale("/recherche")}
                            >
                                <Trans>Moteur de recherche</Trans>
                            </BaseButton>
                            <BaseButton
                                size="small"
                                color="primary"
                                variant="contained"
                                component={Link}
                                to={history.addLocale("/figurer-dans-bordeaux-et-ses-vins")}
                            >
                                <Typography variant="h6">
                                    <Trans>
                                        Figurer dans <i>Bordeaux et ses Vins</i>
                                    </Trans>
                                </Typography>
                            </BaseButton>
                        </Box>
                    </Box>
                </Box>
            </FrontContentTop>
        </>
    );
};

Page404.propTypes = {};

export default Page404;
