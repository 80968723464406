/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { makeStyles, TextField, InputAdornment, Typography, Divider, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "../../assets/Error.svg";
import OldSelectInput from "./OldSelectInput";
import AdditionalInformationTooltip from "../Tooltips/AdditionalInformationTooltip";
import fr from "../../assets/CountryFlags/fr.svg";
import theme from "../../globalTheme";

const useStyles = makeStyles((theme) => ({
    root: (props) => ({
        width: "100%",
        // "& :-webkit-autofill" : {
        //     transitionDelay: "9999s"
        // },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
        },
        "& .MuiOutlinedInput-root": {
            backgroundColor: "white",
            fontSize: "14px",
            color: theme.palette.primary.dark,
            borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
            height: "34px",
            "&.Mui-disabled": {
                color: theme.palette.secondary.dark,
                backgroundColor: theme.palette.disabled.main,
            },
            "&:hover fieldset": {
                borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
            },
            "&.Mui-focused fieldset": {
                border: "1px solid",
                borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
            },
        },
        "& .MuiFormLabel-root": {
            fontSize: "14px",
            color: props.error ? theme.palette.error.main : theme.palette.secondary.dark,
        },
        "& .MuiInput-underline:after": {
            borderBottom: "none",
        },
        "& .MuiOutlinedInput-adornedStart": {
            paddingLeft: "0px",
        },
    }),
    toolTipBox: {
        position: "absolute",
        right: "8px",
        top: "-8px",
        zIndex: 2,
        cursor: "default",
    },
    checkIcon: (props) => ({
        width: "20px",
        fill: "#9BBA97",
        marginRight: "0px",
    }),
    falseIcon: (props) => ({
        width: "20px",
        fill: theme.palette.error.main,
        marginRight: "0px",
    }),
    mesure: (props) => ({
        color: theme.palette.secondary.dark,
        marginRight: "0px",
    }),
    divider: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        height: "32px",
        alignSelf: "center",
    },
    boxContainer: {
        position: "relative",
    },
    startAdornment: {
        color: theme.palette.secondary.dark,
        marginLeft: "6px",
    },
    flag: {
        width: "20px",
    },
    errorText: {
        fontSize: "12px",
        color: theme.palette.error.main,
        marginBottom: theme.spacing(1),
        marginTop: "0px",
        marginLeft: "8px",
    },
}));

const TelephoneInput = (props) => {
    const inputProps = {
        type: "tel",
        id: "nofillphone",
        name: "nofillphone",
        autoComplete: "off",
        maxLength: props.maxLength,
        ...props.inputProps,
    };
    const [focused, setFocused] = useState(false);
    const styles = useStyles({ ...props, focused });
    const flags = [
        {
            name: "France",
            title: <img src={fr} className={styles.flag} />,
            value: "fr",
        },
    ];

    return (
        <Box className={styles.boxContainer} style={{ ...props.boxStyle }}>
            {props.tooltipTitle && (
                <Box className={styles.toolTipBox}>
                    <AdditionalInformationTooltip color="primary" title={props.tooltipTitle} arrow />
                </Box>
            )}
            <TextField
                id="nofillphone"
                autoComplete="nofillphone"
                variant="outlined"
                placeholder={"0X XX XX XX XX"}
                onFocus={(e) => {
                    setFocused(true);
                    if (props.onFocus) {
                        props.onFocus(e);
                    }
                }}
                onBlur={(e) => {
                    setFocused(false);
                    if (props.onBlur) {
                        props.onBlur(e);
                    }
                }}
                label={props.label}
                type={props.type}
                select={props.select}
                size={props.size}
                name={props.name}
                disabled={props.disabled}
                required={props.required}
                error={props.error && !focused}
                helperText={props.helperText}
                defaultValue={props.defaultValue}
                value={
                    props.phoneValue
                        ?.replace(/[^\dA-Z]/g, "")
                        ?.replace(/(.{2})/g, "$1 ")
                        ?.trim() || ""
                }
                onChange={(e) => {
                    return props.phoneOnChange(e);
                }}
                style={{ ...props.style }}
                className={styles.root}
                inputRef={props.inputRef}
                correctValue={props.correctValue}
                inputProps={inputProps}
                InputProps={{
                    type: "tel",
                    autoComplete: "nofillphone",
                    name: "nofillphone",
                    id: "nofillphone",
                    endAdornment: (
                        <InputAdornment position="end">
                            {props.endAdornment}
                            {props.correctValue && <CheckIcon className={styles.checkIcon} />}
                            {props.error && <img src={ErrorIcon} className={styles.falseIcon} />}
                        </InputAdornment>
                    ),
                    startAdornment: (
                        <InputAdornment position="start">
                            <Box style={{ display: "flex", marginLeft: theme.spacing(1) }}>
                                <OldSelectInput
                                    disabled={props.disabled}
                                    telephone
                                    style={{ width: "50px" }}
                                    variant="standard"
                                    className={styles.startAdornment}
                                    value={"fr"}
                                    onChange={() => {}}
                                >
                                    {flags}
                                </OldSelectInput>
                                <Divider className={styles.divider} orientation="vertical" flexItem />
                            </Box>
                        </InputAdornment>
                    ),
                }}
            />
            {props.error ? (
                <Typography variant="h6" className={styles.errorText}>
                    {" "}
                    {props.error}{" "}
                </Typography>
            ) : null}
        </Box>
    );
};

TelephoneInput.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    children: PropTypes.any,
    clue: PropTypes.string,
    type: PropTypes.string,
    select: PropTypes.bool,

    phoneOnChange: PropTypes.func,
    phoneValue: PropTypes.string,
    flagOnChange: PropTypes.func,
    flagValue: PropTypes.string,
    onBlur: PropTypes.func,
    defaultValue: PropTypes.string,
    onFocus: PropTypes.func,

    disabled: PropTypes.bool,
    error: PropTypes.bool,
    required: PropTypes.bool,
    helperText: PropTypes.string,

    endAdornment: PropTypes.any,

    style: PropTypes.object,
    boxStyle: PropTypes.object,
    borderRadius: PropTypes.string,
    variant: PropTypes.oneOf(["text", "contained", "outlined"]),
    size: PropTypes.oneOf(["small", "medium"]),

    inputRef: PropTypes.any,
    inputProps: PropTypes.object,

    multiline: PropTypes.bool,
    rows: PropTypes.number,
    maxLength: PropTypes.number,
    minLength: PropTypes.number,
    rowsMax: PropTypes.number,
    undertext: PropTypes.string,
    correctValue: PropTypes.bool,
    mesure: PropTypes.oneOf(["An(s)", "ha", "Pied/ha", "%", "m²", "°C", "hl"]),
    notPublished: PropTypes.bool,

    tooltip: PropTypes.bool,
    tooltipTitle: PropTypes.string,

    flagCorrectValue: PropTypes.bool,
};

export default TelephoneInput;
