import React from "react";
import { bool, elementType, string } from "prop-types";
import { Box, Typography, makeStyles } from "@material-ui/core";
import theme from "globalTheme";
import SmallDivider from "Components/Divider/SmallDivider";

const useStyles = makeStyles(() => ({
    card: (props) => ({
        backgroundImage: `url(${props.background})`,
        height: "322px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center",
        textAlign: "center",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
    }),
    title: {
        textTransform: "uppercase",
        color: theme.palette.primary.dark,
        fontWeight: 700,
        [theme.breakpoints.down("xs")]: {
            maxWidth: "220px",
            display: "inline-block",
        },
    },
    icon_container: (props) => ({
        display: "flex",
        height: "110px",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "-7px",
        marginBottom: "1rem",
        [theme.breakpoints.down("xs")]: {
            marginBottom: "0",
            marginTop: "25px",
        },
        [theme.breakpoints.only("md")]: {
            height: !props.icon && 0,
        },
    }),
    icon: {
        [theme.breakpoints.down("xs")]: {
            height: "83px",
        },
    },
    divider: {
        marginTop: "0.5rem",
        marginBottom: "1rem",
    },
    subtitle: {
        color: theme.palette.primary.dark,
        [theme.breakpoints.down("xs")]: {
            maxWidth: "245px",
            display: "inline-block",
        },
    },
}));
const FeatureCard = (props) => {
    const styles = useStyles(props);
    return (
        <Box {...props} className={`${styles.card} ${props.className}`}>
            <Box className={styles.icon_container}>
                <img src={props.icon} className={styles.icon} />
            </Box>
            {props.title && (
                <Typography variant="h5" className={styles.title}>
                    {props.title}
                </Typography>
            )}
            {props.divider && <SmallDivider color={theme.palette.primary.light} className={styles.divider} />}
            <Typography variant="h5" className={styles.subtitle}>
                {props.subtitle}
            </Typography>
        </Box>
    );
};

FeatureCard.propTypes = {
    background: string,
    className: string,
    icon: string,
    title: string,
    subtitle: elementType,
    divider: bool,
};

export default FeatureCard;
