import { Plural, t, Trans } from "@lingui/macro";
import { Box, Divider, Hidden, Icon, makeStyles, Typography } from "@material-ui/core";
import PropTypes, { string } from "prop-types";

import accessibilityIcon from "../assets/accessibility.svg";
import calendarIcon from "../assets/calendar.svg";
import equipmentIcon from "../assets/equipment.svg";
import espacesIcon from "../assets/espaces.svg";
import exterieurIcon from "../assets/exterieur.svg";
import freeIcon from "../assets/free.svg";
import hebergementIcon from "../assets/hebergement.svg";
import interieurIcon from "../assets/interieur.svg";
import paymentIcon from "../assets/payment.svg";
import presentationIcon from "../assets/presentation.svg";
import imgSub1 from "../assets/propriétéPage/vinif.png";
import personsIcon from "../assets/salles.svg";
import sanitaryIcon from "../assets/sanitary.svg";
import tarifsIcon from "../assets/tarifs.svg";
import BaseCarousel from "../Components/Carousel/BaseCarousel";
import { getSentence } from "../FrontVintage/Components/vintageHelpers";
import theme from "../globalTheme";
import getConnectors, { firstLetterUppercase, getChips, parseHtml } from "../helpers/helpers";
import tastedWinesIcon from "../assets/activityPage/tasted_wines.svg";
import hasParkingIcon from "../assets/activityPage/has_parking_bus.svg";

const useStyles = makeStyles((theme) => ({
    subtitle__image: {
        marginRight: 6,
        height: 20,
    },
    subtitle__image__small: {
        marginRight: 6,
        height: 18,
    },
    subtitle__container: {
        display: "flex",
        alignItems: "center",
    },
    subtitle__text: {
        color: "#360318",
        fontFamily: "Lato",
        fontSize: 16,
    },
    measures__secondContainer: {
        marginTop: 12,
        display: "flex",
        width: "100%",
        flexWrap: "wrap",
    },
    flex: {
        display: "flex",
    },
    divider2: {
        gridColumn: "1 / span 12",
        backgroundColor: theme.palette.secondary.main,
        width: "100%",
        height: "0.4px",
    },
    chip: {
        position: "absolute",
        right: 0,
        margin: 10,
        top: 0,
        width: "fit-content",
        fontStyle: "14px",
        minHeight: 32,
        display: "flex",
        alignItems: "center",
        paddingRight: "16px",
        paddingLeft: "16px",
        borderRadius: "16px",
    },
    // GRID
    subtitle__box: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "1 / span 12",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "1 / span 12",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 12",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 12",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "1 / span 12",
        },
        display: "flex",
    },
    activityDetails__grid: {
        display: "grid",
        gap: "12px",
        gridTemplateColumns: "repeat(12, 1fr)",
    },
    container__carousel: {
        gridColumn: "1 / span 12",
    },
    hebergement: {
        gridColumn: "1 / span 12",
    },
    equipment__container: {
        gridColumn: "auto / span 4",
        [theme.breakpoints.down("md")]: {
            gridColumn: "auto / span 6",
        },
    },
    presentation: {
        gridColumn: "1 / span 12",
        color: "#360318",
        fontFamily: "Lato",
        fontSize: 16,
    },
    accessibility__title: {
        gridColumn: "1 / span 12",
        fontSize: 16,
        color: theme.palette.primary.dark,
    },
    accessibility__block: {
        width: "100%",
        gridColumn: "1 / span 4",
        [theme.breakpoints.only("xs")]: {
            gridColumn: "auto / span 12",
        },
        [theme.breakpoints.only("sm")]: {
            gridColumn: "auto / span 6",
        },
        [theme.breakpoints.only("md")]: {
            gridColumn: "auto / span 6",
        },
        display: "flex",
        flexWrap: "wrap",
    },
    accessibility__text: {
        color: "#360318",
        fontFamily: "Lato",
        fontSize: 16,
        width: "50%",
        [theme.breakpoints.down("xs")]: {
            width: "25%",
        },
    },
    receptionAreas__container: {
        gridColumn: "auto / span 4",
        [theme.breakpoints.down("sm")]: {
            gridColumn: "auto / span 12",
        },
        [theme.breakpoints.only("md")]: {
            gridColumn: "auto / span 8",
        },
        backgroundColor: theme.palette.dashboard.background,
        padding: 10,
        borderRadius: 10,
    },
    devis__container: {
        gridColumn: "1 / span 12",
    },
    payment__container: {
        gridColumn: "1 / span 12",
        fontSize: 16,
        color: theme.palette.primary.dark,
    },
    measures__container: {
        backgroundColor: theme.palette.dashboard.background,
        padding: 15,
        borderRadius: 10,
        gridColumn: "1 / span 12",
    },
    calendar__container: {
        gridColumn: "1 / span 12",
    },
    freeActivity: {
        gridColumn: "1 / span 12",
        fontSize: 16,
        color: theme.palette.primary.dark,
    },
    prices__description__free: {
        gridColumn: "1 / span 12",
        fontSize: 16,
        color: theme.palette.primary.dark,
    },
    prices__description: {
        [theme.breakpoints.down("xs")]: {
            gridColumn: "auto / span 12",
        },
        [theme.breakpoints.only("sm")]: {
            gridColumn: "auto / span 12",
        },
        [theme.breakpoints.only("md")]: {
            gridColumn: "auto / span 12",
        },
        [theme.breakpoints.only("lg")]: {
            gridColumn: "auto / span 9",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "auto / span 9",
        },
        fontSize: 16,
        color: theme.palette.primary.dark,
    },
    prices__tarifs: {
        background: theme.palette.background.card,
        borderRadius: "10px",
        padding: "10px 14px 10px",

        [theme.breakpoints.down("xs")]: {
            gridColumn: "1 / span 6",
        },
        [theme.breakpoints.only("sm")]: {
            gridColumn: "1 / span 6",
        },
        [theme.breakpoints.only("md")]: {
            gridColumn: "1 / span 5",
        },
        [theme.breakpoints.only("lg")]: {
            gridColumn: "1 / span 4",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "1 / span 4",
        },
    },
    prices__box: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        color: "red",
    },
    parking_tasted_wines: {
        display: "flex",
        gridColumn: "1 / span 12",
        alignItems: "center",
        gap: "0.5rem",
    },
}));

const ActivityDetails = (props) => {
    const styles = useStyles();
    const getSubtitle = (image, title) => (
        <Box className={styles.subtitle__box}>
            <img src={image} alt="img" className={styles.subtitle__image} />
            <Typography variant="h4">{title}</Typography>
        </Box>
    );

    const getPresentation = (activityType, tags, description, hasParking, tastedWines) => {
        if (description || hasParking || tastedWines || tags?.length) {
            return (
                <>
                    {getSubtitle(presentationIcon, t`Présentation`)}
                    {tags && tags?.length ? <Box className={styles.presentation}>{getChips(tags, true)}</Box> : null}
                    {hasParking && (
                        <Box className={styles.parking_tasted_wines}>
                            <Icon>
                                <img src={hasParkingIcon} />
                            </Icon>
                            <Typography variant="body2">
                                <Trans>Parking bus</Trans>
                            </Typography>
                        </Box>
                    )}
                    {tastedWines && (
                        <Box className={styles.parking_tasted_wines}>
                            <Icon>
                                <img src={tastedWinesIcon} />
                            </Icon>
                            <Typography variant="body2">
                                <Plural value={tastedWines} one="# vin dégusté" other="# vins dégustés" />
                            </Typography>
                        </Box>
                    )}
                    {description && <Typography className={styles.presentation}>{parseHtml(description)}</Typography>}
                </>
            );
        }
    };

    const getAccomodation = (accomodationPossible, bedCount, accomodationDetails) => {
        if (accomodationPossible || bedCount || accomodationDetails) {
            return (
                <>
                    <Divider orientation="horizontal" className={styles.divider2} />
                    {getSubtitle(hebergementIcon, t`Hébergement`)}
                    <Box className={styles.hebergement}>
                        {accomodationPossible && (
                            <Typography className={styles.subtitle__text}>
                                <Trans>Possibilité de logement</Trans>
                            </Typography>
                        )}
                        {bedCount && (
                            <Typography className={styles.subtitle__text}>{t`${bedCount} couchages`}</Typography>
                        )}
                        {accomodationDetails && (
                            <Typography className={styles.subtitle__text}>{parseHtml(accomodationDetails)}</Typography>
                        )}
                    </Box>
                </>
            );
        }
        return "";
    };

    const getEquipment = (equipmentProvided, availableOptions) => {
        if (equipmentProvided?.length > 0 || availableOptions?.length > 0) {
            return (
                <>
                    <Divider orientation="horizontal" className={styles.divider2} />
                    {getSubtitle(equipmentIcon, t`Équipements`)}
                    {equipmentProvided?.length > 0 && (
                        <Box className={styles.equipment__container}>
                            <Typography className={styles.subtitle__text}>
                                <Trans>Matériel mis à disposition</Trans>
                            </Typography>
                            {equipmentProvided?.map((el, i) => {
                                return <Typography key={i} className={styles.subtitle__text}>{`· ${el}`}</Typography>;
                            })}
                        </Box>
                    )}

                    {availableOptions?.length > 0 && (
                        <Box className={styles.equipment__container}>
                            <Typography className={styles.subtitle__text}>
                                <Trans>Options disponibles </Trans>
                            </Typography>
                            {availableOptions?.map((el, i) => (
                                <Typography key={i} className={styles.subtitle__text}>{`· ${el}`}</Typography>
                            ))}
                        </Box>
                    )}
                </>
            );
        }
        return "";
    };

    const getReceptionAreas = (receptionAreas) => {
        if (receptionAreas && receptionAreas?.length) {
            return (
                <>
                    <Divider orientation="horizontal" className={styles.divider2} />
                    {getSubtitle(espacesIcon, t`Salles et espaces`)}
                    {receptionAreas?.map((el, i) => (
                        <Box key={i} className={styles.receptionAreas__container}>
                            {(el?.name || el?.surfaceArea || el?.surfaceUnit) && (
                                <Typography
                                    variant="h4"
                                    style={{
                                        display: "flex",
                                        gap: 6,
                                        fontSize: 18,
                                    }}
                                    className={styles.subtitle__text}
                                >
                                    {el?.name && <b>{el?.name} </b>}
                                    {el?.surfaceArea && el?.surfaceAreaUnit && (
                                        <Typography variant="h6" style={{ fontSize: 16 }}>
                                            {`${el?.surfaceArea} ${el?.surfaceAreaUnit}`}
                                        </Typography>
                                    )}
                                </Typography>
                            )}
                            {el?.type && (
                                <Box className={styles.subtitle__container}>
                                    <img
                                        src={el?.type === "intérieur" ? interieurIcon : exterieurIcon}
                                        alt="img"
                                        className={styles.subtitle__image__small}
                                    />
                                    <Typography className={styles.subtitle__text}>
                                        {" "}
                                        {firstLetterUppercase(el?.type)}
                                    </Typography>
                                </Box>
                            )}
                            {el?.capacity && (
                                <Box className={styles.subtitle__container}>
                                    <img src={personsIcon} alt="img" className={styles.subtitle__image__small} />
                                    <Typography className={styles.subtitle__text}>
                                        <Plural value={el?.capacity} one="# personne" other="# personnes" />
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    ))}
                </>
            );
        }
        return "";
    };

    const getAccessibility = (accessibleTo) => {
        if (accessibleTo && accessibleTo?.length) {
            return (
                <>
                    <Divider orientation="horizontal" className={styles.divider2} />
                    {getSubtitle(accessibilityIcon, t`Accessibilité`)}
                    <Typography className={styles.accessibility__title}>
                        <Trans>Accueil des personnes atteintes de handicap :</Trans>
                    </Typography>
                    <Box className={styles.accessibility__block}>
                        {accessibleTo?.map((el, i) => {
                            return (
                                <Typography key={i} className={styles.accessibility__text}>
                                    {firstLetterUppercase(el?.replace("handicap ", ""))}
                                </Typography>
                            );
                        })}
                    </Box>
                </>
            );
        }
        return "";
    };

    const getCalendar = (activePeriods) => {
        if (activePeriods && activePeriods?.length) {
            return (
                <>
                    <Divider orientation="horizontal" className={styles.divider2} />
                    {getSubtitle(calendarIcon, t`Calendrier d'ouverture`)}
                    <Box className={styles.calendar__container}>
                        {activePeriods?.map((el, i) => {
                            return (
                                <Typography key={i} className={styles.subtitle__text}>
                                    {getSentence(el)}
                                </Typography>
                            );
                        })}
                    </Box>
                    {/* <Divider
                        orientation="horizontal"
                        className={styles.divider2}
                    /> */}
                </>
            );
        }
        return "";
    };

    const getTarifs = (activityType, free, priceDetails, paymentDetails, priceOnEstimation) => {
        const getPriceDetails = () => {
            if (!free && priceDetails && priceDetails.length) {
                return priceDetails?.map((el, i) => {
                    return (
                        <Box className={styles.prices__box} key={i}>
                            <Typography className={styles.subtitle__text}>
                                {firstLetterUppercase(el?.kind)} {el?.kind === "jeune" ? " (-16 ans)" : null}
                            </Typography>
                            <Typography className={styles.subtitle__text}>{el?.price.toFixed(2)} €</Typography>
                        </Box>
                    );
                });
            }
            return "";
        };

        if (props.types !== undefined) {
            if (props.types[0].typeFr === "Location de salle" && (priceOnEstimation || paymentDetails)) {
                return (
                    <>
                        <Divider orientation="horizontal" className={styles.divider2} />
                        {getSubtitle(tarifsIcon, t`Tarifs`)}
                        {(priceOnEstimation || paymentDetails) && (
                            <Box className={styles.devis__container}>
                                {priceOnEstimation && (
                                    <Typography className={styles.subtitle__text} style={{ fontWeight: "bold" }}>
                                        <Trans>Sur devis</Trans>
                                    </Typography>
                                )}
                                {paymentDetails && (
                                    <Typography className={styles.subtitle__text}>
                                        {parseHtml(paymentDetails)}
                                    </Typography>
                                )}
                            </Box>
                        )}
                    </>
                );
            } else {
                if (free || getPriceDetails() || paymentDetails) {
                    return (
                        <>
                            <Divider orientation="horizontal" className={styles.divider2} />
                            {free ? (
                                <>
                                    {getSubtitle(freeIcon, t`Activité gratuite`)}
                                    <Typography className={styles.freeActivity}>
                                        <Trans> L&apos;activité est totalement gratuite </Trans>
                                    </Typography>
                                </>
                            ) : (
                                <>
                                    {(getPriceDetails() || paymentDetails) && getSubtitle(tarifsIcon, t`Tarifs`)}
                                    {(getPriceDetails() || (paymentDetails && getPriceDetails())) && (
                                        <>
                                            {getPriceDetails() && (
                                                <Box className={styles.prices__tarifs}>{getPriceDetails()}</Box>
                                            )}
                                            {paymentDetails && (
                                                <Typography className={styles.prices__description}>
                                                    {parseHtml(paymentDetails)}
                                                </Typography>
                                            )}
                                        </>
                                    )}

                                    {paymentDetails && !getPriceDetails() && (
                                        <Typography className={styles.prices__description__free}>
                                            {parseHtml(paymentDetails)}
                                        </Typography>
                                    )}
                                </>
                            )}
                        </>
                    );
                }
                return "";
            }
        }
    };

    const getPaymentMethods = (activityType, paymentOptionsAccepted) => {
        if (paymentOptionsAccepted && paymentOptionsAccepted?.length) {
            return (
                <>
                    <Divider orientation="horizontal" className={styles.divider2} />
                    {getSubtitle(paymentIcon, t`Moyens de paiement`)}

                    <Typography className={styles.payment__container}>
                        {firstLetterUppercase(getConnectors(paymentOptionsAccepted))}
                    </Typography>
                </>
            );
        }
    };

    const getMeasures = (sanitaryMeasures) => {
        if (sanitaryMeasures && sanitaryMeasures?.length) {
            return (
                <>
                    <Divider orientation="horizontal" className={styles.divider2} />
                    <Box className={styles.measures__container}>
                        {getSubtitle(sanitaryIcon, t`Mesures sanitaires`)}

                        <Box className={styles.measures__secondContainer}>
                            {sanitaryMeasures?.map((el, i) => {
                                return (
                                    <Typography
                                        key={i}
                                        className={styles.subtitle__text}
                                        style={{
                                            width: "50%",
                                            paddingRight: 12,
                                        }}
                                    >
                                        {firstLetterUppercase(el)}
                                    </Typography>
                                );
                            })}
                        </Box>
                    </Box>
                </>
            );
        }
        return "";
    };

    const getActivityType = (types) => {
        if (types?.[0]?.type) {
            return (
                <Typography
                    style={{
                        color: types?.[0]?.textColor ? types?.[0]?.textColor : "white",
                        backgroundColor: types?.[0]?.bgColor ? types?.[0]?.bgColor : theme.palette.primary.light,
                        border: types?.[0]?.bgColor === "#FFFFFF" ? `1px solid ${theme.palette.primary.dark}` : "",
                    }}
                    className={styles.chip}
                >
                    {types?.[0]?.type}
                </Typography>
            );
        }
        return "";
    };

    return (
        <Box className={styles.activityDetails__grid}>
            {/* IMAGES */}
            <Hidden smDown>
                <Box className={styles.container__carousel}>
                    <BaseCarousel
                        alt={props.alt}
                        subImg={imgSub1}
                        images={props.images?.length ? props.images : []}
                        responsiveHeight={[310, 310, 278, 432, 432]}
                        front
                    />
                    {/* <SocialShare /> */}
                </Box>
            </Hidden>
            <Hidden lgUp smDown>
                {getActivityType(props.types)}
            </Hidden>
            {/* PRESENTATION */}
            {getPresentation(
                props.activityType,
                props.tags,
                props.description,
                props.parkingBus,
                props.numberWineTasted
            )}
            {/* HEBERGEMENT */}
            {getAccomodation(props.accomodationPossible, props.bedCount, props.accomodationDetails)}
            {/* SALLES */}
            {getReceptionAreas(props.receptionAreas)}
            {/* EQUIPEMENT */}
            {getEquipment(props.equipmentProvided, props.availableOptions)}
            {/* ACCESSIBILITY */}
            {getAccessibility(props.accessibleTo)}
            {/* CALENDAR */}
            {getCalendar(props.activePeriods)}
            {/* TARIFS */}
            {getTarifs(
                props.activityType,
                props.free,
                props.priceDetails,
                props.paymentDetails,
                props.priceOnEstimation
            )}
            {/* MOYENS */}
            {!props.free ? getPaymentMethods(props.activityType, props.paymentOptionsAccepted) : null}
            {/* MEASURES */}
            {getMeasures(props.sanitaryMeasures)}
        </Box>
    );
};

ActivityDetails.propTypes = {
    types: PropTypes.array,
    images: PropTypes.array,
    activityType: PropTypes.string,
    tags: PropTypes.string,
    description: PropTypes.string,
    accomodationPossible: PropTypes.string,
    bedCount: PropTypes.string,
    accomodationDetails: PropTypes.string,
    receptionAreas: PropTypes.string,
    equipmentProvided: PropTypes.string,
    availableOptions: PropTypes.string,
    accessibleTo: PropTypes.string,
    activePeriods: PropTypes.string,
    free: PropTypes.string,
    priceDetails: PropTypes.string,
    paymentDetails: PropTypes.string,
    priceOnEstimation: PropTypes.string,
    paymentOptionsAccepted: PropTypes.string,
    sanitaryMeasures: PropTypes.string,
    parkingBus: PropTypes.bool,
    numberWineTasted: PropTypes.number,
    alt: string,
};

export default ActivityDetails;
