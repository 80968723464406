import { ADD_BORDEAUX_PLUS, ADD_FLUX_FTP } from "config/constants";
import { getSubscriptionAddons, getSubscriptionName, hasAddon, subscriptionNameFormat } from "helpers/helpers";
import { useSelector } from "react-redux";

import { SUBSCRIPTION_TYPES } from "config/constants";

// Provide all app restrictions depending with the subscription type
export const subscriptionRestrictions = {
    [SUBSCRIPTION_TYPES.basic]: {
        maxWines: 2,
        maxContacts: 0,
        maxVintages: 0,
        maxTeamMember: 0,
        maxActivities: 0,
        maxOwnerOperator: 6,
        hasFt: false,
        hasFtp: false,
        hasReward: false,
        hasSelfScore: false,
        hasFeretScore: false,
        hasTastingRequest: false,
    },
    [SUBSCRIPTION_TYPES.initial]: {
        maxWines: 4,
        maxContacts: 0,
        maxVintages: 12,
        maxTeamMember: 2,
        maxActivities: 3,
        maxOwnerOperator: 6,
        hasFt: false,
        hasFtp: false,
        hasReward: false,
        hasSelfScore: false,
        hasFeretScore: false,
        hasTastingRequest: false,
        plus: {
            maxWines: 8,
            maxVintages: 48,
            maxTeamMember: 6,
            maxActivities: 6,
            hasReward: true,
            hasSelfScore: true,
            hasTastingRequest: true,
        },
    },
    [SUBSCRIPTION_TYPES.essential]: {
        maxWines: 4,
        maxContacts: 250,
        maxVintages: 12,
        maxActivities: 3,
        maxTeamMember: 2,
        maxOwnerOperator: 6,
        hasFt: true,
        hasFtp: false,
        hasReward: true,
        hasSelfScore: true,
        hasFeretScore: false,
        hasTastingRequest: true,
        plus: {
            maxWines: 8,
            maxContacts: 2000,
            maxVintages: 48,
            maxTeamMember: 6,
            maxActivities: 6,
            hasTastingRequest: true,
        },
    },
    [SUBSCRIPTION_TYPES.premium]: {
        maxWines: 999,
        maxVintages: 999,
        maxContacts: Infinity,
        maxActivities: 999,
        maxTeamMember: 999,
        maxOwnerOperator: 999,
        hasFt: true,
        hasFtp: true,
        hasReward: true,
        hasSelfScore: true,
        hasFeretScore: true,
        hasTastingRequest: true,
    },
};

interface UseRestrictionsReturnInterface {
    subscriptionType: string;
    subscriptionName: string;
    maxWines: number;
    maxContacts: number;
    maxVintages: number;
    maxActivities: number;
    maxTeamMember: number;
    maxOwnerOperator: number;
    isPlus: boolean;
    isBasic: boolean;
    isInitial: boolean;
    isEssential: boolean;
    isPremium: boolean;
    hasFt: boolean;
    hasFtp: boolean;
    hasReward: boolean;
    hasSelfScore: boolean;
    hasFeretScore: boolean;
    hasTastingRequest: boolean;
    isWineRestricted: boolean;
    isContactRestricted: boolean;
    isVintageRestricted: boolean;
    isActivityRestricted: boolean;
    isTeamMemberRestricted: boolean;
    isOwnerOperatorRestricted: boolean;
}

type wine = { vintages?: any[] };

type members = { displayName: string; personId: string; proFunctions: string[] };

interface UseRestrictionsArgsInterface {
    wines?: wine[];
    activities?: any[];
    contactCount?: number;
    memberMetrics: {
        members: members[];
        operatorCount: number;
        otherCount: number;
        ownerCount: number;
        ownerOperatorCount: number;
        total: number;
    };
}

export const useRestrictions = (args: UseRestrictionsArgsInterface): UseRestrictionsReturnInterface => {
    const { wines, activities, memberMetrics, contactCount = 0 } = args ?? {};
    const addonCodes = useSelector(getSubscriptionAddons);
    const subscriptionType: string = useSelector((state) => getSubscriptionName(state));

    const { plus: plusRestrictions, ...rest } = subscriptionRestrictions[subscriptionType] ?? {};

    const isPlus = hasAddon(addonCodes, ADD_BORDEAUX_PLUS);

    const restrictions = isPlus ? { ...rest, ...(plusRestrictions ?? {}) } : rest;

    const vintageCount = wines?.reduce((sum, next) => sum + (next?.vintages?.length ?? 0), 0) ?? 0;

    const isWineRestricted = Boolean(wines && wines.length >= restrictions.maxWines);
    const isVintageRestricted = Boolean(vintageCount >= restrictions.maxVintages);
    const isActivityRestricted = Boolean(activities && activities.length >= restrictions.maxActivities);

    const isTeamMemberRestricted = Boolean(memberMetrics?.otherCount >= restrictions.maxTeamMember);
    const isOwnerOperatorRestricted = Boolean(memberMetrics?.ownerOperatorCount >= restrictions.maxOwnerOperator);
    const isContactRestricted = Boolean(contactCount >= restrictions.maxContacts);

    const isBasic = subscriptionType === SUBSCRIPTION_TYPES.basic;
    const isInitial = subscriptionType === SUBSCRIPTION_TYPES.initial;
    const isEssential = subscriptionType === SUBSCRIPTION_TYPES.essential;
    const isPremium = subscriptionType === SUBSCRIPTION_TYPES.premium;

    const hasFtp = hasAddon(addonCodes, ADD_FLUX_FTP) || isPremium;

    const subscriptionName = subscriptionNameFormat[subscriptionType];

    return {
        ...restrictions,
        subscriptionType,
        subscriptionName,
        hasFtp,
        isPlus,
        isBasic,
        isInitial,
        isEssential,
        isPremium,
        isWineRestricted,
        isContactRestricted,
        isVintageRestricted,
        isActivityRestricted,
        isTeamMemberRestricted,
        isOwnerOperatorRestricted,
    };
};
