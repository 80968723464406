import { t } from "@lingui/macro";
import { Accordion, AccordionDetails, AccordionSummary, Box, makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

import expandIcon from "../../assets/arrowAccordion.svg";
import theme from "../../globalTheme";
import { allProperCase, getHectares } from "../../helpers/helpers";

const useStyles = makeStyles((theme) => ({
    summary: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        "& .MuiAccordionSummary-content": {
            margin: 0,
            display: "flex",
            justifyContent: "space-between",
        },
        "&.MuiAccordionSummary-root": {
            minHeight: 0,
            // padding: 0
        },
    },
    accordion: {
        ".MuiIconButton-root": {
            padding: 0,
        },
        "&.MuiAccordion-root.Mui-expanded": {
            margin: 0,
        },
        "& .MuiAccordionDetails-root": {
            padding: "8px",
            paddingRight: "40px",
            paddingLeft: "40px",
        },
        "&.MuiAccordion-root:before": {
            top: 0,
        },
    },
    typo: {
        fontWeight: "bold",
    },
}));

const CommunesTables = (props) => {
    const styles = useStyles();
    const getFormattedEntries = (entries) => {
        const arr = [];
        if (!entries) {
            return arr;
        }
        for (let i = 0; i < entries.length; i++) {
            const entry = entries[i]?.commune;
            const hectares = entries[i]?.superficie;
            const lieuDits = entries[i]?.lieuDits;
            const lieuDitsCopy = [...lieuDits];
            const sortedLieuDits = lieuDitsCopy.sort((a, b) => parseFloat(b.superficie) - parseFloat(a.superficie));
            arr.push(
                <Accordion
                    square
                    className={styles.accordion}
                    elevation={0}
                    style={{ width: props.width ? props.width : "300px", marginRight: theme.spacing(3) }}
                >
                    <AccordionSummary
                        expandIcon={lieuDits?.length !== 0 && <img src={expandIcon} alt="expand_icon" />}
                        className={styles.summary}
                        style={{ backgroundColor: i % 2 ? "white" : theme.palette.dashboard.background }}
                    >
                        <Typography
                            variant="h6"
                            className={styles.typo}
                            style={{ color: props.hidden ? theme.palette.secondary.dark : theme.palette.primary.main }}
                        >
                            {allProperCase(entry)}
                        </Typography>
                        <Typography
                            variant="h6"
                            className={styles.typo}
                            style={{
                                color: props.hidden ? theme.palette.secondary.dark : theme.palette.primary.main,
                                marginRight: "4px",
                            }}
                        >
                            {getHectares(hectares, props.locale)}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ backgroundColor: i % 2 ? "white" : theme.palette.dashboard.background }}>
                        <Box style={{ width: "100%" }}>
                            {sortedLieuDits.map((el2, j) => {
                                return (
                                    <Box
                                        key={j}
                                        style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                            width: "100%",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                color: theme.palette.primary.light,
                                                fontStyle: !allProperCase(el2.lieuDit) && "italic",
                                                fontSize: "12px",
                                            }}
                                        >
                                            {allProperCase(el2.lieuDit) ? allProperCase(el2.lieuDit) : t`Sans lieu-dit`}
                                        </Typography>
                                        <Typography style={{ color: theme.palette.primary.light, fontSize: "12px" }}>
                                            {getHectares(el2.superficie, props.locale)}
                                        </Typography>
                                    </Box>
                                );
                            })}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            );
        }
        return arr;
    };

    return getFormattedEntries(props.communes);
};

CommunesTables.propTypes = {
    communes: PropTypes.object,
    hidden: PropTypes.bool,
    width: PropTypes.any,
};

export default CommunesTables;
