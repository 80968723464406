function SvgFacebook() {
  return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
          <path
            d="M3 0H21C22.6545 0 24 1.3455 24 3V21C24 22.6545 22.6545 24 21 24H16.5V15.75H18.75L20.25 12H16.5V9C16.5 8.23892 17.0678 8.24331 17.8026 8.24899C17.8672 8.24949 17.9331 8.25 18 8.25H19.5V4.5H16.5C14.0145 4.5 12 6.5145 12 9V12H9V15.75H12V24H3C1.3455 24 0 22.6545 0 21V3C0 1.3455 1.3455 0 3 0Z"
            fill="#632D3E"
          />
      </svg>
  );
}

export default SvgFacebook;
