import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, makeStyles } from "@material-ui/core";

import FrontMainContainer from "../Front/FrontMainContainer";
import FrontContentTop from "../Front/FrontContentTop";
import FrontTitle from "../FrontRanking/FrontTitle";
import bg from "../assets/bg-author.jpg";
import theme from "../globalTheme";
import EstateTitleSemiUnderlined from "../FrontEstate/Components/EstateTitledSemiUnderlined";
import { formatPerson, getTextLength, isNotEmpty, parseHtml, useWindowDimensions } from "../helpers/helpers";
import AuthorSummaryProps from "../FrontAuthor/AuthorSummary";
import { SwiperWrapper } from "../Front/SwiperCards";
import fioriture1 from "../assets/rankingSummary/fioriture1.svg";
import floriture1 from "../assets/author/floriture1.svg";
import floriture2 from "../assets/author/floriture2.svg";
import floriture8 from "../assets/author/floriture8.svg";
import grape from "../assets/author/grape.svg";
import ExpandableFormattedTextBox from "../Components/Texts/ExpandableFormattedTextBox";
import ExpandableFormattedListBox from "../FrontAuthor/ExpandableFormattedListBox";
import BlockContent from "../FrontAuthor/BlockContent";
import diplome from "../assets/author/diplome.svg";
import job from "../assets/author/job.svg";
import loop from "../assets/author/loop.svg";
import SkillsDetails from "../FrontAuthor/SkillsDetails";
import { useGetArticlesByAuthorIdLazyQuery, useGetAuthorFrontQuery } from "../generated/graphql";
import FeretAuthors from "../FrontAuthor/FeretAuthors";
import { t, Trans } from "@lingui/macro";
import Page404 from "Components/ErrorPage/Page404";
import Page500Front from "Components/ErrorPage/Page500Front";
import LoadingWithImage from "Components/Loader/LoadingWithImage";
import Helmet from "Components/Helmet/Helmet";
import FeretPublishCard from "FrontAuthor/FeretPublishCard";
import FeretLastPublishCard from "FrontAuthor/FeretLastPublishCard";

const useStyles = makeStyles(() => ({
    title_subtitle: {
        fontFamily: "Lato",
        fontWeight: "normal",
        color: theme.palette.common.white,
        textAlign: "center",
        marginBottom: "25px",
        textTransform: "uppercase",

        [theme.breakpoints.up("md")]: {
            marginBottom: "32px",
        },
    },
    container_card: {
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
        },
    },
    container_content_top: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        gap: "26px",
    },
    primary_content_secondary_grid_box: {
        marginTop: "15px",
        display: "flex",
        justifyContent: "space-between",

        [theme.breakpoints.up("lg")]: {
            flexWrap: "wrap",
            justifyContent: "flex-end",
            marginTop: "0px",
        },
    },

    container_slider_1: {
        justifyContent: "center",
        paddingLeft: "16px",
        paddingRight: "16px",

        [theme.breakpoints.up("xl")]: {
            width: "calc(1314px)",
            margin: "auto",
        },
    },
    container_slider_2: {
        [theme.breakpoints.up("xl")]: {
            backgroundColor: "#C0D4BE",
            backgroundImage: `url(${floriture1}), url(${floriture2})`,
            backgroundPositionY: "75px, 90px",
            backgroundPositionX: "left, right",
            backgroundRepeat: "no-repeat",
        },
    },
    container_slider_2_content: {
        justifyContent: "center",
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingBottom: 38,
        background: "#C0D4BE",

        [theme.breakpoints.up("xl")]: {
            width: "calc(1314px)",
            margin: "auto",
        },
    },
    container_content_secondary: {
        gridColumn: "1 / span 12",

        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 12",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "9 / span 4",
        },
    },
    container_content_primary: {
        gridColumn: "1 / span 12",

        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 8",
        },
    },
    content_scollable: {
        overflowX: "hidden",
    },
    container_title_secondary: {
        marginTop: "16px",
    },
    content_text_secondary: {
        marginTop: "24px",
        marginBottom: "24px",
    },
    container_title_third: {
        marginTop: "27px",
    },
    container_infos_right: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",

        [theme.breakpoints.up("lg")]: {
            display: "flex",
            flexWrap: "wrap",
        },
    },
    center_responsive: {
        gridColumn: "1 / span 6",
        width: "100%",

        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 12",
        },
    },
    infos_title: {
        width: "100%",
        display: "flex",
        gap: "8px",
        "&:not(:last-child)": {
            marginBottom: "14px",
        },
    },
    contact_image_container: {
        imageContainer: {
            width: "48px",
            height: "48px",
        },
    },
    contact_media: {
        media: {
            width: "100%",
            [theme.breakpoints.only("xs")]: {
                width: "42px",
            },
        },
    },
    content_container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "100%",
        width: "135px",
        height: "135px",
        marginRight: "18px",
        marginBottom: "12px",
    },
    container_tabs: {
        width: "100%",
        gridColumn: "1 / span 12",
    },
    content_text: {
        gridColumn: "4 / span 9",
        color: theme.palette.primary.dark,
    },
    box_container_img_top: {
        display: "flex",
        justifyContent: "center",
        marginBottom: "5px",

        [theme.breakpoints.up("lg")]: {
            marginBottom: "14px",
        },
    },
    img_top: {
        width: 23,
        height: 48,
        marginTop: "-30px",

        [theme.breakpoints.up("lg")]: {
            width: 30,
            height: 63,
        },
    },
    frontTitle: {
        maxWidth: "none",

        [theme.breakpoints.up("xl")]: {
            paddingBottom: "0 !important",
        },
    },
    contact_container: {
        gridColumn: "1 / span 12",
        order: 1,
        marginTop: "49px",
        maxWidth: "373px",
        width: "100%",
    },
    contact_title: {
        width: "100%",
        display: "flex",
        gap: "8px",
        "&:not(:last-child)": {
            marginBottom: "28px",
        },
    },
    skill_detail: {
        color: theme.palette.primary.dark,
        paddingLeft: 16,
        marginTop: 0,
    },
    container_author_photo: ({ photo, isDescription }) => ({
        float: isDescription ? "left" : undefined,
        marginRight: 30,
        width: 120,
        height: 120,
        alignSelf: "flex-start",
        backgroundImage: `url(${photo})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        borderRadius: "100%",
        border: `4px solid ${theme.palette.primary.light}`,
        [theme.breakpoints.up("lg")]: {
            width: 155,
            height: 155,
        },
    }),
    container_details_xp: {
        gridColumn: "1 / span 12",

        [theme.breakpoints.up("sm")]: {
            gridColumn: "1 / span 6",
        },
    },
    container_main_bottom: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
    },
    main_container_slider_1: {
        gridColumn: "1 / span 12",
        overflow: "hidden",

        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 8",
        },
    },
    container_image_primary_logo: {
        gridColumn: "10 / span 3",
        display: "none",
        alignItems: "flex-start",
        justifyContent: "center",
        marginTop: 120,

        [theme.breakpoints.up("sm")]: {
            marginTop: 0,
        },

        [theme.breakpoints.up("md")]: {
            marginTop: 43,
        },

        [theme.breakpoints.up("lg")]: {
            display: "flex",
            marginTop: 34,
        },
    },
    image_primary_logo: {
        width: "100%",
        maxWidth: 157,
    },
    socialMedia: {
        order: 1,
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: "15px",

        [theme.breakpoints.up("lg")]: {
            order: 2,
        },

        [theme.breakpoints.up("xl")]: {
            right: 64,
            top: 42,
        },
    },
    about_text: {
        color: theme.palette.primary.dark,

        "& *": {
            textAlign: "justify",
        },

        "& p p": {
            marginBottom: 0,
            marginTop: 0,
        },
    },
    details: {
        "& p p": {
            marginTop: 0,
            marginBottom: 0,

            "&:last-child": {
                marginBottom: 0,
            },
        },
    },
}));

const AuthorPage = () => {
    const { width } = useWindowDimensions();
    const { name } = useParams();
    const locale = useSelector((state) => state?.user?.locale || "fr");

    const { data: { GetAuthorFront: author } = {}, loading, error } = useGetAuthorFrontQuery({
        variables: { name, locale },
    });

    const styles = useStyles({ photo: author?.photo?.data, isDescription: isNotEmpty(author?.description) });

    const [
        getArticlesByAuthorId,
        { data: { GetArticlesByAuthorId: authorArticles } = {} },
    ] = useGetArticlesByAuthorIdLazyQuery();

    useEffect(() => {
        if (author) {
            getArticlesByAuthorId({
                variables: { authorId: author?._id, locale },
            });
        }
    }, [author]);

    const breadcrumbs = [
        {
            name: t`L'encyclopédie Féret`,
            path: "/encyclopedie-feret",
        },
        {
            name: author?.personIdentity.firstName + " " + author?.personIdentity.lastName,
        },
    ];

    if (loading) {
        return <LoadingWithImage />;
    }

    if (!author) {
        return <Page404 />;
    }

    if (error) {
        return <Page500Front />;
    }

    return (
        <>
            <Helmet
                title={formatPerson(author?.personIdentity)}
                content={`Découvrez ${formatPerson(
                    author?.personIdentity
                )} à travers sa biographie, ses diplômes, ses expériences professionnelles, ses domaines d'expertise et ses publications. Un auteur de référence pour les passionnés du monde vitivinicole`}
            />
            <FrontContentTop
                backgroundImage={bg}
                links={breadcrumbs}
                previousPage={{
                    name: t`L'encyclopédie Féret`,
                    path: "/encyclopedie-feret",
                }}
            >
                <FrontTitle className={styles.frontTitle} title={formatPerson(author?.personIdentity)} />
                <Typography variant="h3" className={styles.title_subtitle}>
                    {author?.title}
                </Typography>
                <FrontMainContainer
                    styleCardContent
                    className={styles.main_container}
                    style={{
                        backgroundImage: `url(${fioriture1}), url(${floriture8})`,
                        backgroundPositionY: "bottom, center",
                        backgroundPositionX: "right, right",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    <Box className={styles.container_card}>
                        <Box className={styles.container_content_top}>
                            {[
                                author?.photo?.data,
                                isNotEmpty(author?.description),
                                isNotEmpty(author?.degree),
                                isNotEmpty(author?.professionalExperience),
                            ].some(Boolean) && (
                                <Box className={styles.container_content_primary}>
                                    <EstateTitleSemiUnderlined
                                        title={t`À propos`}
                                        heightAfter={32}
                                        style={{ marginTop: "0px" }}
                                        lowBar={{ width: 75 }}
                                        borderColor={theme.palette.primary.main}
                                    />

                                    <Box className={styles.content_scollable}>
                                        {author?.photo?.data && <Box className={styles.container_author_photo} />}
                                        {isNotEmpty(author?.description) && (
                                            <Box>
                                                <Typography
                                                    component="div"
                                                    variant="body2"
                                                    className={styles.about_text}
                                                >
                                                    {getTextLength(author?.description) > 1690 ? (
                                                        <ExpandableFormattedTextBox
                                                            value={author?.description}
                                                            truncatedValue={`${author?.description?.slice(0, 1690)}...`}
                                                        />
                                                    ) : (
                                                        parseHtml(author?.description, "body2")
                                                    )}
                                                </Typography>
                                            </Box>
                                        )}

                                        {isNotEmpty(author?.degree) && (
                                            <Box style={{ marginTop: "24px" }}>
                                                <BlockContent
                                                    icon={diplome}
                                                    title={t`Diplômes`}
                                                    data={parseHtml(author?.degree, "body2")}
                                                />
                                            </Box>
                                        )}
                                        {isNotEmpty(author?.professionalExperience) && (
                                            <Box style={{ marginTop: "24px" }}>
                                                <BlockContent
                                                    icon={job}
                                                    title={t`Expériences professionnelles`}
                                                    data={parseHtml(author?.professionalExperience, "body2")}
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            )}

                            <Box className={styles.container_content_secondary}>
                                <Box className={styles.container_infos_right}>
                                    {author?.expertise && (
                                        <Box
                                            className={styles.center_responsive}
                                            style={{
                                                gridColumn: width < 700 ? "1 / span 12" : "1 / span 6",
                                                marginBottom: width < 700 ? "16px" : "0px",
                                            }}
                                        >
                                            <AuthorSummaryProps
                                                skills={author?.expertise}
                                                typoStyle={{ lineHeight: "1.2" }}
                                            />
                                        </Box>
                                    )}

                                    {isNotEmpty(author?.expertiseDetails) && (
                                        <Box
                                            className={styles.container_details_xp}
                                            style={{
                                                gridColumn: width < 700 ? "1 / span 12" : "7 / span 6",
                                                marginLeft: width > 700 && width < 1280 ? 29 : 0,
                                            }}
                                        >
                                            <Box
                                                className={styles.contact_title}
                                                style={{
                                                    marginTop: width >= 700 && width >= 1280 ? "24px" : 0,
                                                    marginBottom: 0,
                                                }}
                                            >
                                                <Box className={styles.contact_image_container}>
                                                    <img className={styles.contact_media} src={loop} />
                                                </Box>
                                                <Box>
                                                    <Typography variant="h4">
                                                        <Trans>Détail des expertises</Trans>
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            <Box className={styles.details}>
                                                {width < 1280 ? (
                                                    getTextLength(author?.expertiseDetails) > 1690 ? (
                                                        <ExpandableFormattedListBox
                                                            data={parseHtml(author?.expertiseDetails, "body2")}
                                                            maxHeight={"55px"}
                                                        />
                                                    ) : (
                                                        <Typography
                                                            variant="body2"
                                                            style={{ color: theme.palette.primary.dark }}
                                                        >
                                                            {parseHtml(author?.expertiseDetails, "body2")}
                                                        </Typography>
                                                    )
                                                ) : (
                                                    <SkillsDetails
                                                        data={parseHtml(author?.expertiseDetails, "body2")}
                                                    />
                                                )}
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Box>

                        <Box className={styles.container_main_bottom}>
                            {author?.recentPublications?.length > 0 &&
                                author.recentPublications.some((item) => isNotEmpty(item?.publication)) && (
                                    <Box className={styles.main_container_slider_1}>
                                        <SwiperWrapper title={t`Publications d'intérêt`} spaceBetween={0} noMargin dark>
                                            {author?.recentPublications
                                                .filter((item) => isNotEmpty(item?.publication))
                                                .map((publication, i) => (
                                                    <FeretLastPublishCard
                                                        key={i}
                                                        publication={parseHtml(publication.publication, "body2")}
                                                    />
                                                ))}
                                        </SwiperWrapper>
                                    </Box>
                                )}

                            <Box className={styles.container_image_primary_logo}>
                                <img src={grape} className={styles.image_primary_logo} alt="floriture grape" />
                            </Box>
                        </Box>
                    </Box>
                </FrontMainContainer>

                {authorArticles?.length > 0 && (
                    <Box className={styles.container_slider_1}>
                        <SwiperWrapper
                            title={t`Découvrez ses publications sur bordeaux.feret.com`}
                            borderColor={theme.palette.common.white}
                            spaceBetween={0}
                            noMargin
                        >
                            {authorArticles.map((article, i) => (
                                <FeretPublishCard key={i} title={article.name} slug={article.slugFr} italic bold />
                            ))}
                        </SwiperWrapper>
                    </Box>
                )}
            </FrontContentTop>
            <Box className={styles.container_slider_2}>
                <Box className={styles.container_slider_2_content}>
                    <EstateTitleSemiUnderlined
                        style={{ marginBottom: "36px", marginTop: 0, paddingTop: "1rem" }}
                        noMargin
                        title={t`Les auteurs Féret`}
                        heightAfter={32}
                        styleBox={{ marginTop: 0 }}
                    />
                    <FeretAuthors />
                </Box>
            </Box>
        </>
    );
};

export default AuthorPage;
