import { any, bool, func, object, string } from "prop-types";
import { makeStyles, Box } from "@material-ui/core";

import SimpleInput from "./SimpleInput";
import { AutocompleteCommune } from "Components/Inputs/AutocompleteCommune";
import AddressAutocompleteInput from "./AddressAutocompleteInput";
import globalTheme from "globalTheme";

const useStyles = makeStyles(() => ({
    addressInputcontainer: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        gap: globalTheme.spacing(2),
    },
    addressInputItem: {
        gridColumn: "auto / span 4",
    },
}));

const AddressAutocomplete = (props) => {
    const styles = useStyles(props);

    return (
        <Box className={styles.addressInputcontainer} style={props.style}>
            <Box className={styles.addressInputItem}>
                <AddressAutocompleteInput
                    address={props.address}
                    onChange={props.onChangeAddress}
                    label={props.AddressLabel || "Adresse"}
                    onBlur={props.onChangeAddress}
                    disabled={props.allDisabled}
                    endAdornmentTopVal={props.endAdornmentTopValue || "10px"}
                    error={props.addressErrors}
                    correctValue={props.checkIcon && props.address}
                />
            </Box>
            <Box className={styles.addressInputItem}>
                <SimpleInput
                    value={props.extraAddress}
                    onChange={(e) => props.onChangeExtraAddress(e?.target?.value)}
                    label="Complément d'adresse"
                    disabled={props.allDisabled}
                    correctValue={props.checkIcon && props.extraAddress}
                />
            </Box>
            <Box className={styles.addressInputItem}>
                <AutocompleteCommune
                    label={"Commune"}
                    postalCode={props.postalCode}
                    commune={props.commune}
                    onChange={({ commune, postalCode, communeINSEEcode }) => {
                        if (props.onChangeCommune) props.onChangeCommune(commune);
                        if (props.onChangePostalCode) props.onChangePostalCode(postalCode);
                        if (props.onChangeCommuneINSEECode) props.onChangeCommuneINSEECode(communeINSEEcode);
                        if (props.onChange) props.onChange({ commune, postalCode, communeINSEEcode });
                    }}
                    disabled={props.allDisabled}
                    required
                />
            </Box>
        </Box>
    );
};

AddressAutocomplete.propTypes = {
    style: object,
    commune: any,
    postalCode: string,
    onChangePostalCode: func,
    allDisabled: bool,
    checkIcon: bool,
    onChangeAddress: any,
    label: string,
    address: string,
    endAdornmentTopValue: string,
    extraAddress: string,
    AddressLabel: string,
    addressErrors: any,
    onChangeCommune: func,
    onChangeExtraAddress: func,
    onChangeCommuneINSEECode: func,
    onChange: func,
};

export default AddressAutocomplete;
