import { useState } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { string, number, bool, array, object } from "prop-types";
import { Plural, t, Trans } from "@lingui/macro";

import SummaryElement from "../FrontEstate/Components/PropertySummary/SummaryElement";
import ActivityLanguagesPopin from "../FrontActivities/ActivityLanguagesPopin";
import { activityPersons, formatConcat } from "../helpers/helpers";
import durationIcon from "../assets/duration.svg";
import languagesIcon from "../assets/languages.svg";
import capacityIcon from "../assets/capacity.svg";
import startingageIcon from "../assets/startingage.svg";
import reservationIcon from "../assets/reservation.svg";
import sansReservationIcon from "../assets/sansresa.svg";
import localisationIcon from "../assets/localisation.svg";
import threedots from "../assets/threedots.svg";
import Tooltip from "../Components/Tooltips/Tooltip";
import BaseButton from "../Components/Buttons/BaseButton";
import theme from "../globalTheme";

const useStyles = makeStyles((theme) => ({
    summary__container: {
        padding: 12,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        border: "0.4px solid",
        borderColor: theme.palette.secondary.dark,
        borderRadius: "5px",
        gap: "8px",
        height: "fit-content",
        flex: 1,
    },
    block__information: {
        fontSize: "14px",
        fontFamily: "Lato",
        color: theme.palette.primary.dark,
        background: theme.palette.secondary.light,
        borderRadius: "10px",
        padding: 14,
    },
    threedots: {
        cursor: "pointer",
    },
    languagesBox: {
        width: "22px",
        height: "22px",
        marginTop: 8,
        marginRight: "8px",
        boxShadow: "0px 0px 5px #0000006C",
        borderRadius: "50%",
        marginBottom: "4px",
    },
}));

const ActivitySummary = (props) => {
    const styles = useStyles();
    const [displayPhoneNumber, setDisplayPhoneNumber] = useState(false);
    const [languagesPopinOpen, setLanguagesPopinOpen] = useState(false);

    const getLocationDuration = () => {
        if (props.minRentTime || props.maxRentTime) {
            return (
                <Box>
                    {props.minRentTime && (
                        <Typography
                            style={{
                                color: theme.palette.primary.dark,
                                fontSize: "16px",
                            }}
                        >
                            {t`Minimum :`} {props.minRentTime}{" "}
                            {props.minRentTime && props.minRentTimeUnit ? (
                                props.minRentTimeUnit
                            ) : (
                                <Plural value={props.minRentTime} one="heure" other="heures" />
                            )}{" "}
                        </Typography>
                    )}
                    {props.maxRentTime && (
                        <Typography
                            style={{
                                color: theme.palette.primary.dark,
                                fontSize: "16px",
                            }}
                        >
                            {t`Maximum :`} {props.maxRentTime}{" "}
                            {props.maxRentTime && props.maxRentTimeUnit ? (
                                props.maxRentTimeUnit
                            ) : (
                                <Plural value={props.maxRentTime} one="jour" other="jours" />
                            )}{" "}
                        </Typography>
                    )}
                </Box>
            );
        }
        return "";
    };

    const getLanguages = () => {
        if (props.languages && props.languages?.length) {
            return props.languages?.map((el, i) => {
                if (i <= 2) {
                    return (
                        <Box key={i} style={{ display: "flex", alignItems: "center" }}>
                            {el?.icon?.twicpicURL && (
                                <Box
                                    className={styles.languagesBox}
                                    style={{
                                        background: `center / cover no-repeat url(${el?.icon?.twicpicURL})`,
                                    }}
                                >
                                    {" "}
                                </Box>
                            )}
                            <Typography
                                style={{
                                    marginTop: 4,
                                    color: theme.palette.primary.dark,
                                    fontSize: "16px",
                                }}
                            >
                                {el?.language}
                            </Typography>
                        </Box>
                    );
                }
            });
        } else {
            return "";
        }
    };

    const getButton = () => {
        return (
            <BaseButton
                boxStyle={{ width: "100%", marginTop: 6 }}
                disabled={displayPhoneNumber}
                style={{
                    color: displayPhoneNumber ? theme.palette.primary.dark : "#432E35",
                    backgroundColor: displayPhoneNumber ? theme.palette.secondary.main : "white",
                    borderColor: displayPhoneNumber ? theme.palette.secondary.main : theme.palette.primary.main,
                }}
                classes={styles.button}
                variant="outlined"
                size="small"
                onClick={() => {
                    setDisplayPhoneNumber(true);
                }}
            >
                {displayPhoneNumber ? props?.contactPhoneNumber : t`Contacter la propriété`}
            </BaseButton>
        );
    };

    const getContact = () => {
        if (!props.reservationNeeded && props?.contactPhoneNumber) {
            return getButton();
        }
        return "";
    };

    const getReservation = () => {
        if (props.reservationNeeded && props?.contactPhoneNumber) {
            return getButton();
        }
        return <Box style={{ width: 1 }}></Box>;
    };

    const getLanguagesTitle = () => {
        if (props.languages?.length > 3) {
            return (
                <Box style={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="h4" style={{ fontSize: 18, marginRight: "10px" }}>
                        <Trans>Langues parlées</Trans>
                    </Typography>
                    <Tooltip fontSize="11px" title={t`Voir toutes les langues`} color="primary" arrow>
                        <img src={threedots} className={styles.threedots} onClick={() => setLanguagesPopinOpen(true)} />
                    </Tooltip>
                </Box>
            );
        } else {
            return t`Langues parlées`;
        }
    };

    const getAddress = () => {
        const { address, complementaryAddress, commune, postalCode } = props.address ?? {};
        const communePostalCode = formatConcat([commune, postalCode]);
        const completeAddress = formatConcat([address, complementaryAddress]);
        if (address || commune || postalCode) {
            return (
                <Box>
                    {address && (
                        <Typography style={{ color: theme.palette.primary.dark, fontSize: "16px" }}>
                            {completeAddress}
                        </Typography>
                    )}
                    {communePostalCode && (
                        <Typography style={{ color: theme.palette.primary.dark, fontSize: "16px" }}>
                            {communePostalCode}
                        </Typography>
                    )}
                </Box>
            );
        }
        return "";
    };

    const getLocalisation = () => {
        const atWineEntity = props.atWineEntity ? <Trans>À la propriété</Trans> : "";
        const address = getAddress();
        const contact = getContact();

        if (atWineEntity || address || contact) {
            return (
                <>
                    {atWineEntity}
                    {address}
                    {contact}
                </>
            );
        }
        return "";
    };

    return (
        <Box className={styles.summary__container}>
            {languagesPopinOpen && (
                <ActivityLanguagesPopin
                    modalOpen={languagesPopinOpen}
                    handleClose={() => setLanguagesPopinOpen(false)}
                    languages={props.languages}
                />
            )}
            {/* Mettre au pluriels si plusieurs */}
            <SummaryElement
                icon={durationIcon}
                title={t`Durée`}
                subtitle={
                    props.duration && props.durationUnit && props.activityType !== t`Location de salle`
                        ? `${props.duration} ${props.durationUnit}`
                        : ""
                }
            />
            <SummaryElement
                icon={durationIcon}
                title="Durée"
                subtitle={props.activityType === t`Location de salle` ? getLocationDuration() : ""}
            />
            <SummaryElement icon={languagesIcon} title={getLanguagesTitle()} subtitle={getLanguages()} />
            <SummaryElement
                icon={capacityIcon}
                title={t`Capacité (nb pers.)`}
                subtitle={activityPersons(props.groupSize[0], props.groupSize[1])}
            />
            {props.private && props.activityType !== t`Location de salle` ? (
                <Typography className={styles.block__information}>
                    <Trans>
                        <b>Privatisée</b> : vous serez le seul groupe sur place au cours de votre activité
                    </Trans>
                    .
                </Typography>
            ) : (
                ""
            )}
            <SummaryElement
                icon={startingageIcon}
                title={t`À partir de`}
                subtitle={
                    props.startingAge && props.activityType !== t`Location de salle` ? t`${props.startingAge} ans` : ""
                }
            />
            <SummaryElement icon={localisationIcon} title={t`Localisation`} subtitle={getLocalisation()} />
            <SummaryElement
                icon={props.reservationNeeded ? reservationIcon : sansReservationIcon}
                title={props.reservationNeeded ? t`Sur réservation` : t`Sans réservation`}
                subtitle={props.activityType !== t`Location de salle` ? getReservation() : ""}
            />
        </Box>
    );
};

ActivitySummary.propTypes = {
    activityType: string,
    duration: number,
    durationUnit: string,
    minRentTime: number,
    maxRentTime: number,
    minRentTimeUnit: string,
    maxRentTimeUnit: string,
    languages: array,
    groupSize: array,
    startingAge: number,
    address: object,
    contact: object,
    reservationNeeded: bool,
    private: bool,
    contactPhoneNumber: string,
    atWineEntity: bool,
};

export default ActivitySummary;
