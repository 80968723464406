import { useState } from "react";
import { bool, func, object, string } from "prop-types";
import Modal from "../../Containers/Modal";
import icon from "../../assets/Wine/new_ft_available.svg";
import { Box, makeStyles, Typography } from "@material-ui/core";
import theme from "../../globalTheme";
import ShowMore from "../../Dashboard/Components/TechnicalSheets/DataSheets/ShowMore";
import Lightbox from "react-image-lightbox";
import { useGetFtParameterQuery } from "../../generated/graphql";

const useStyle = makeStyles(() => ({
    portal_modal: {
        "& .ReactModal__Overlay": {
            zIndex: "2000 !important",
        },
    },
}));

const NewFtAvailable = (props) => {
    const styles = useStyle();
    const [preview, setPreview] = useState();

    const { data: { GetFtParameter: ftParameters = [] } = {}, loading: infosFtLoading } = useGetFtParameterQuery({
        variables: { ftParametersId: props.open },
        fetchPolicy: "network-only",
    });

    return (
        <>
            {preview && (
                <Box className={styles.test}>
                    <Lightbox
                        reactModalProps={{ portalClassName: styles.portal_modal }}
                        mainSrc={ftParameters?.preview?.data}
                        onCloseRequest={() => setPreview(false)}
                    />
                </Box>
            )}
            <Modal
                width="44rem"
                icon={icon}
                title={
                    <>
                        <Typography variant="h3">
                            {props.receiver
                                ? "Bienvenue, nouvelle fiche technique dans votre espace !"
                                : "Nouvelle fiche technique dans votre espace !"}
                        </Typography>
                        <Typography variant="h6" style={{ color: theme.palette.primary.light }}>
                            {ftParameters?.wine} {ftParameters?.vintage} ({ftParameters?.fullAppellation}) par{" "}
                            {ftParameters.wineEntityName} : <i>« {ftParameters?.name} »</i>
                        </Typography>
                    </>
                }
                open={props.open && !infosFtLoading}
                onClose={() => props.onClose()}
                actionNeeded={false}
            >
                <Typography variant="h6" style={{ color: theme.palette.primary.dark, marginBottom: "1rem" }}>
                    {props.receiver
                        ? "Bienvenue sur votre espace Féret, vous retrouverez ici toutes les fiches techniques reçues et toutes celles auxquelles vous vous abonnerez. Vous serez informé de toutes leurs mises à jour."
                        : "Retrouvez cette fiche technique dans votre espace Féret. Vous serez abonné à toutes ses mises à jour."}
                </Typography>
                <Typography variant="h6" style={{ fontFamily: "Mansalva", color: theme.palette.primary.dark }}>
                    N’oubliez pas, à tout moment, vous pouvez masquer ou afficher les fiches techniques de votre choix,
                    ne suivez que ce qui vous intéresse !
                </Typography>
                <Box
                    style={{
                        position: "relative",
                        width: "fit-content",
                        margin: "auto",
                    }}
                >
                    <ShowMore
                        onClick={() => setPreview(true)}
                        style={{
                            position: "absolute",
                            top: "-7px",
                            right: "-7px",
                            width: "18px",
                            height: "18px",
                            padding: "0",
                        }}
                        iconStyle={{ width: "10px", height: "10px" }}
                    />

                    <img
                        style={{ width: "93px", boxShadow: "0px 4px 76px 4px rgba(0, 0, 0, 0.08)" }}
                        src={ftParameters?.preview?.data}
                    />
                </Box>
            </Modal>
        </>
    );
};

NewFtAvailable.propTypes = {
    open: object,
    onClose: func,
    receiver: bool,
};

export default NewFtAvailable;
