import React from "react";
import { bool, func, object, string } from "prop-types";
import { Box, Icon, makeStyles, Typography } from "@material-ui/core";
import theme from "../../../globalTheme";
import wallet from "../../../assets/SubscriptionPage/code_promo.svg";
import background from "assets/code_promo_background.svg";

const useStyles = makeStyles(() => ({
    containerOutline: () => ({
        border: `1px solid ${theme.palette.secondary.dark}`,
        padding: "1rem 1.875rem",
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
        marginTop: "1rem",
        justifyContent: "start",
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right",
        backgroundSize: "contain",
    }),
    discountInput: (props) => ({
        width: "250px",
        outline: "none !important",
        outlineWidth: "0",
        "& .MuiOutlinedInput-inputAdornedEnd": {
            padding: "0 1rem",
        },
        "& .MuiOutlinedInput-adornedEnd": {
            padding: 0,
        },

        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor:
                    props.checkPromoCode === true || props.checkPromoCode === null
                        ? theme.palette.secondary.main
                        : "red",
                borderWidth: 1,
            },
            "&:hover fieldset": {
                borderColor:
                    props.checkPromoCode === true || props.checkPromoCode === null
                        ? theme.palette.secondary.main
                        : "red",
                borderWidth: 1,
            },
        },
    }),

    notchedOutline: (props) => ({
        borderColor:
            props.checkPromoCode === true || props.checkPromoCode === null ? theme.palette.secondary.main : "red",
    }),
    buttonDiscount: {
        minWidth: "inherit",
        zIndex: 10,
    },
    input: {
        color: theme.palette.primary.dark,

        "&::placeholder": {
            fontSize: theme.typography.h6.fontSize,
            color: theme.palette.secondary.dark,
        },
    },
}));
const PromoCode = (props) => {
    const styles = useStyles(props);
    return (
        <Box className={styles.containerOutline} style={props.boxStyle}>
            <Icon style={{ marginRight: "0.5rem", width: "35px", height: "30px " }}>
                <img src={wallet} style={{ width: "100%" }} />
            </Icon>
            <Typography variant="h6" style={{ fontFamily: "Mansalva" }}>
                La saisie du code promo se fait à l’étape suivante !
            </Typography>
            {/* {props.checkPromoCode ? (
                <Box style={{ display: "flex", alignItems: "center" }}>
                    <Icon style={{ marginRight: "1rem", width: "30px", height: "30px " }}>
                        <img src={wallet} style={{ width: "100%" }} />
                    </Icon>
                    <Box style={{ color: theme.palette.primary.dark }}>
                        <Typography variant="subtitle1" style={{ lineHeight: "12px" }}>
                            Vous économisez 50 € avec le code :
                        </Typography>
                        <Typography variant="h6" style={{ fontWeight: 700, display: "flex" }}>
                            {props.promoCode}
                            <DeleteIcon
                                style={{ cursor: "pointer" }}
                                fontSize="small"
                                onClick={() => {
                                    props.onDeleteCode();
                                }}
                            />
                        </Typography>
                    </Box>
                </Box>
            ) : (
                <>
                    {props.checkPromoCode === false && (
                        <Typography color="error" variant="h6">
                            Ce code promo n’est plus valide ou n’existe pas.
                        </Typography>
                    )}

                    <TextField
                        onKeyDown={(event) => {
                            if (event.key === "Enter") {
                                props.onAdd();
                            }
                        }}
                        value={props.promoCode}
                        onChange={(e) => {
                            props.onChange(e);
                        }}
                        className={styles.discountInput}
                        variant="outlined"
                        placeholder="Code promo"
                        disableUnderline={true}
                        inputProps={{
                            className: styles.input,
                        }}
                        InputProps={{
                            endAdornment: (
                                <BaseButton
                                    type="submit"
                                    disabled={!props.promoCode}
                                    onClick={(e) => {
                                        props.onAdd();
                                    }}
                                    classes={styles.buttonDiscount}
                                    color="primary"
                                    size="small"
                                    variant="contained"
                                >
                                    Ajouter
                                </BaseButton>
                            ),
                            classes: {
                                notchedOutline: styles.notchedOutline,
                            },
                        }}
                    />
                </>
            )} */}
        </Box>
    );
};

PromoCode.propTypes = {
    checkPromoCode: bool,
    onAdd: func,
    promoCode: string,
    onChange: func,
    onDeleteCode: func,
    boxStyle: object,
};

export default PromoCode;
