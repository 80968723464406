import { Box, ClickAwayListener, Icon, makeStyles, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PropTypes, { bool, func } from "prop-types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import nl from "../../assets/DataSheets/dutch.svg";
import en from "../../assets/DataSheets/english.svg";
import de from "../../assets/DataSheets/german.svg";
import it from "../../assets/DataSheets/italian.svg";
import ja from "../../assets/DataSheets/japenese.svg";
import zh from "../../assets/DataSheets/chinese.svg";
import es from "../../assets/DataSheets/spanish.svg";
import fr from "../../assets/navbarfront/fr.svg";
import { setLocale } from "../../features/user/userSlice";
import theme from "../../globalTheme";
import { ALL_LANGUAGES } from "config/constants";

const useStyles = makeStyles(() => ({
    dropdown_flag: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        position: "relative",
    },
    dropdown_content: (props) => {
        return {
            boxShadow: "rgb(0 0 0 / 20%) 0px 0px 76px -9px",
            backgroundColor: "white",
            position: !props.noFixed && "fixed",
            zIndex: 10,
            [theme.breakpoints.up("xs")]: {
                top: 45,
                bottom: "inherit",
            },
            [theme.breakpoints.up("md")]: {
                //size of the screen - navbar
                transform: !props.noFixed && "translate(0, calc(100% - calc(100vh - 65px)))",
                bottom: "0",
                top: "inherit",
            },
            borderRadius: "2px",
            display: "inline-table",
            "&:after": {
                filter: "drop-shadow(0px -3px 13px black)",
                bottom: "100%",
                left: "75%",
                border: "solid transparent",
                // eslint-disable-next-line quotes
                content: '""',
                height: "0",
                width: "0",
                position: "absolute",
                pointerEvents: "none",
                borderColor: "rgba(255, 255, 255, 0)",
                borderBottomColor: "white",
                borderWidth: "7px",
            },
        };
    },
    box_lang: {
        display: "flex",
        flexDirection: "row",
        margin: "6px",
        padding: "3px 7px",
        justifyContent: "left",
        alignItems: "center",
    },
    absolute_flags: (props) => {
        return {
            [theme.breakpoints.up("xs")]: {
                left: "-27px",
                position: "absolute",
                bottom: props.noFixed ? "-85px" : "-10px",
            },
            [theme.breakpoints.up("sm")]: {
                left: props.noFixed ? "-27px" : "-5px",
            },
            [theme.breakpoints.up("md")]: {
                left: "-5px",
                bottom: props.noFixed && "-100px",
            },
        };
    },
    current_lang: (props) => {
        return {
            color: props.scroll ? theme.palette.primary.dark : theme.palette.common.white,

            [theme.breakpoints.up("xs")]: {
                display: "none",
            },
            [theme.breakpoints.up("md")]: {
                display: "initial",
                marginLeft: "3px",
            },
        };
    },
}));

const LanguageSelector = (props) => {
    const styles = useStyles(props);
    const [expanded, setExpanded] = useState(false);
    const dispatch = useDispatch();
    const languagesAvailable = useSelector((state) => state?.user?.languagesAvailable ?? ALL_LANGUAGES);
    const locale = useSelector((state) => state?.user?.locale || "fr");
    const countries = {
        fr: {
            country: "FR",
            flag: fr,
            fullname: "Français",
        },
        en: {
            country: "EN",
            flag: en,
            fullName: "Anglais",
        },
        de: {
            country: "DE",
            flag: de,
            fullName: "Allemand",
        },
        es: {
            country: "ES",
            flag: es,
            fullName: "Anglais",
        },
        it: {
            country: "IT",
            flag: it,
            fullName: "Italien",
        },
        nl: {
            country: "NL",
            flag: nl,
            fullName: "Néerlandais",
        },
        ja: {
            country: "JA",
            flag: ja,
            fullName: "Japonais",
        },
        zh: {
            country: "ZH",
            flag: zh,
            fullName: "Mandarin",
        },
    };

    const onChangeLanguage = async (language) => {
        dispatch(setLocale(language));
    };

    const handleClickAway = () => {
        setExpanded(false);
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Box className={styles.dropdown_flag}>
                <Box onClick={() => setExpanded(!expanded)} style={{ display: "flex", alignItems: "center" }}>
                    <Icon style={{ display: "flex", width: "auto", height: "auto" }}>
                        <img src={countries[locale].flag} />
                    </Icon>
                    <Typography
                        variant="h5"
                        className={styles.current_lang}
                        style={{
                            color: props.darkTheme && theme.palette.primary.dark,
                        }}
                    >
                        {countries[locale].country}
                    </Typography>
                    <ExpandMoreIcon
                        style={{
                            color:
                                props.scroll || props.darkTheme
                                    ? theme.palette.primary.dark
                                    : theme.palette.common.white,
                        }}
                    />
                </Box>

                {expanded && (
                    <Box className={styles.absolute_flags}>
                        <Box className={styles.dropdown_content}>
                            {languagesAvailable
                                .filter((e) => !props.excludeCountries.includes(e))
                                .map((k, i) => {
                                    const el = countries[k];
                                    return (
                                        <Box
                                            className={styles.box_lang}
                                            key={i}
                                            style={{
                                                backgroundColor: k === locale && theme.palette.primary.dark,
                                                color:
                                                    k === locale
                                                        ? theme.palette.common.white
                                                        : theme.palette.primary.dark,
                                            }}
                                            onClick={() => {
                                                handleClickAway();
                                                onChangeLanguage(k);
                                            }}
                                        >
                                            <img src={el.flag} alt="flag" />

                                            <Typography style={{ marginLeft: "3px" }}>{el.country}</Typography>
                                        </Box>
                                    );
                                })}
                        </Box>
                    </Box>
                )}
            </Box>
        </ClickAwayListener>
    );
};

LanguageSelector.propTypes = {
    locale: PropTypes.string,
    excludeCountries: PropTypes.array,
    scroll: bool,
    noFixed: bool,
    darkTheme: bool,
    onChange: func,
};

LanguageSelector.defaultProps = {
    locale: "fr",
    excludeCountries: [],
    onChange: () => {},
};
export default LanguageSelector;
