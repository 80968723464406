import { object, shape, string } from "prop-types";
import DefaultLogo from "Journalist/DefaultLogo";
import { getScoringLogo } from "helpers/helpers";

export const JournalistLogo = ({ score, className, style }) => {
    const logo = getScoringLogo(score?.companyName);

    return logo ? (
        <img className={className} style={style} src={logo} alt={score?.companyName} />
    ) : (
        <DefaultLogo criticName={score?.criticName} />
    );
};

JournalistLogo.propTypes = {
    score: shape({
        companyName: string,
        criticName: string,
    }),
    className: string,
    style: object,
};
