import { string } from "prop-types";
import { Box, Card, CardContent, Typography, makeStyles } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Link } from "react-router-dom";

import { useCustomHistory } from "hooks";
import { urlize } from "helpers/helpers";
import theme from "../globalTheme";

const useStyles = makeStyles(() => ({
    card: (props) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 286,
        height: 286,
        padding: "0px",
        backgroundColor: props.bgColor ?? theme.palette.common.white,
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.08)",
        borderRadius: "100%",
        cursor: "pointer",
        textDecoration: "none",
    }),
    card_content_container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: "0",
        padding: "16px",
        maxWidth: "100%",
        maxHeight: "100%",
    },
    item_title: (props) => ({
        textAlign: "center",
        color: props.bgColor ? theme.palette.common.white : theme.palette.primary.main,
        fontWeight: 700,
    }),
    link: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        textDecoration: "none",

        "&:hover": {
            textDecoration: "underline",
            color: theme.palette.common.white,
        },
    },
    container_logo: {
        display: "flex",
        justifyContent: "center",
        maxWidth: "100%",
        height: "100%",
    },
    logo: {
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain !important",
    },
}));

const FormationCard = (props) => {
    const styles = useStyles(props);
    const history = useCustomHistory();

    const url = history.addLocale(`/organismes-vignoble-bordelais/formation/${urlize(props.title)}`);

    return (
        <Card className={styles.card} component={Link} to={url}>
            <CardContent className={styles.card_content_container}>
                <Box
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: 75,
                        marginBottom: 30,
                    }}
                >
                    <Box className={styles.container_logo}>
                        <img className={styles.logo} src={props?.logo} alt={`logo ${props?.title}`} />
                    </Box>
                </Box>

                <Box
                    style={{
                        paddingLeft: 16,
                        paddingRight: 16,
                        height: 40,
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "center",
                    }}
                >
                    <Link to={url} className={styles.link}>
                        <Typography variant="body2" className={styles.item_title}>
                            {props?.title}
                        </Typography>
                        <ArrowForwardIcon
                            style={{
                                color: props.bgColor ? theme.palette.common.white : theme.palette.primary.main,
                                marginLeft: 5,
                            }}
                        />
                    </Link>
                </Box>
            </CardContent>
        </Card>
    );
};

FormationCard.propTypes = {
    title: string,
    logo: string,
    bgColor: string,
};

export default FormationCard;
