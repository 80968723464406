import PropTypes from "prop-types";
import { Box, Card, CardContent, Typography, makeStyles, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";

import theme from "../globalTheme";
import reward from "../assets/challenges/reward.svg";
import arrowIcon from "../assets/challenges/arrow.svg";
import { urlize } from "../helpers/helpers";
import { t } from "@lingui/macro";
import { useCustomHistory } from "hooks";

const useStyles = makeStyles((e) => ({
    card: {
        borderRadius: "10px",
        width: 322,
        height: "260px",
        padding: "0px",
        backgroundColor: "#FFF",
        cursor: "pointer",
        textDecoration: "none",
    },
    item_title: {
        color: theme.palette.primary.contrastText,
        fontWeight: "bold",
        margin: "20px 14px 8px 14px",
    },
    content_container: {
        backgroundColor: theme.palette.primary.light,
        flex: 1,
        position: "relative",
    },
    item_reward: {
        color: theme.palette.primary.contrastText,
        fontWeight: "bold",
    },
    arrow: (props) => ({
        color: props.arrowColor ? props.arrowColor : theme.palette.primary.dark,
    }),
    popper: (props) => ({
        fontSize: "14px",
        backgroundColor: props.bgColor ? props.bgColor : theme.palette.primary.dark,
        borderRadius: props.borderRadius ? props.borderRadius : "12px",
        boxShadow: props.boxShadow ? props.boxShadow : "0px 3px 76px #00000014",
        padding: "8px",
    }),
    logo: {
        maxWidth: "100%",
        maxHeight: "100%",
    },
    cardImageBox: {
        height: "138px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 10,
    },
    cardContentBox: {
        padding: "0px",
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    boxLink: {
        display: "flex",
        justifyContent: "flex-end",
        position: "absolute",
        bottom: 10,
        right: 14,
    },
}));

const ChallengeEntityCard = (props) => {
    const styles = useStyles();
    const history = useCustomHistory();

    const url = history.addLocale(`/organismes-vignoble-bordelais/concours/${urlize(props.name)}`);

    return (
        <Card className={styles.card} component={Link} to={url}>
            <CardContent className={styles.cardContentBox}>
                <Box className={styles.cardImageBox}>
                    <img
                        src={props.logo?.twicpicURL}
                        style={{ margin: "2px auto" }}
                        className={styles.logo}
                        alt="logo challenge"
                    />
                </Box>

                <Box className={styles.content_container}>
                    {props.name.length > 32 ? (
                        <Tooltip
                            color="primary"
                            title={props.name}
                            arrow
                            classes={{
                                arrow: styles.arrow,
                                tooltip: styles.popper,
                            }}
                        >
                            <Typography variant="h4" className={styles.item_title}>{`${props.name.slice(
                                0,
                                31
                            )}...`}</Typography>
                        </Tooltip>
                    ) : (
                        <Typography variant="h4" className={styles.item_title}>
                            {props.name}
                        </Typography>
                    )}

                    <Box style={{ display: "flex", alignItems: "center", margin: "0px 14px " }}>
                        <img src={reward} style={{ marginRight: "1px" }} alt="reward icon" />
                        <Box style={{ display: "flex" }}>
                            <Typography variant="h6" className={styles.item_reward} style={{ marginRight: "3px" }}>
                                {props.totalMedals ? props.totalMedals : ""}{" "}
                                {props.maxYear ? t`crus bordelais médaillés en` + " " + props.maxYear : ""}
                            </Typography>
                        </Box>
                    </Box>

                    <Box className={styles.boxLink}>
                        <Link to={url}>
                            <img src={arrowIcon} alt="link icon" />
                        </Link>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

ChallengeEntityCard.propTypes = {
    name: PropTypes.string,
    logo: PropTypes.logo,
    totalMedals: PropTypes.number,
    maxYear: PropTypes.number,
};

export default ChallengeEntityCard;
