import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { useState } from "react";

import bg from "../../assets/searchEngine/all/bg-all.jpg";
import theme from "../../globalTheme";
import SearchTabs from "../../Search/SearchTabs";
import AutocompleteSearch from "./AutocompleteSearch";
import { t, Trans } from "@lingui/macro";
import Helmet from "Components/Helmet/Helmet";
import { useCustomHistory } from "hooks";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "&::after": {
            content: '""',
            position: "absolute",
            zIndex: -1,
            top: "-5rem",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundImage: `url(${bg})`,
            backgroundRepeat: "no-repeat",
            backgroundPositionY: "top",
            backgroundPositionX: "center",
            backgroundSize: "cover",
        },

        minHeight: "calc(100vh - 5rem)",
    },
    main_container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "22px 1rem 2rem",
        paddingTop: 0,
        overflow: "hidden",

        [theme.breakpoints.up("md")]: {
            width: "calc(950px)",
            margin: "auto",
        },

        [theme.breakpoints.up("lg")]: {
            width: "calc(1354px)",
            margin: "auto",
        },

        [theme.breakpoints.up("xl")]: {
            width: "calc(1384px)",
            margin: "auto",
        },
    },
    title: {
        color: theme.palette.common.white,
        fontWeight: 400,
        textAlign: "center",
        marginBottom: "1rem",
    },
    nav: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        gap: 7,
        alignItems: "center",
        background: theme.palette.common.white,
        width: "100%",
        maxWidth: 1040,
        borderRadius: "53px",
        marginTop: 28,
        marginBottom: 20,
        padding: "7px 9px",

        [theme.breakpoints.up("sm")]: {
            marginTop: 33,
        },

        [theme.breakpoints.up("md")]: {
            gap: 7,
            background: theme.palette.common.white,
            marginTop: 48,
            marginBottom: 26,
        },

        [theme.breakpoints.up("lg")]: {
            marginTop: 33,
        },

        [theme.breakpoints.up("xl")]: {
            marginTop: 76,
        },
    },
    search: {
        gridColumn: "1 / span 12",

        [theme.breakpoints.up("sm")]: {
            gridColumn: "1 / span 12",
        },

        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 8",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 8",
        },

        [theme.breakpoints.up("xl")]: {
            gridColumn: "1 / span 9",
        },
    },
    result: {
        display: "none",
        gridRow: "2 / span 1",
        gridColumn: "1 /span 12",

        [theme.breakpoints.up("sm")]: {
            gridColumn: "1 / span 12",
        },

        [theme.breakpoints.up("md")]: {
            gridColumn: "9 / span 4",
            gridRow: "1 / span 1",
            display: "flex",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "9 / span 4",
        },

        [theme.breakpoints.up("xl")]: {
            gridColumn: "10 / span 3",
        },
    },
    resultTwo: {
        display: "flex",
        width: "calc(100% + (2 * 9px))",
        [theme.breakpoints.up("sm")]: {
            maxWidth: 236,
        },

        [theme.breakpoints.up("md")]: {
            gridColumn: "9 / span 4",
            gridRow: "1 / span 1",
            display: "none",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "9 / span 4",
        },

        [theme.breakpoints.up("xl")]: {
            gridColumn: "10 / span 3",
        },
    },
    resultBtn: {
        border: "none",
        borderRadius: "53px",
        padding: "6px 9px",
        width: "100%",
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,

        "&.Mui-disabled": {
            backgroundColor: theme.palette.secondary.dark,
            color: theme.palette.secondary.main,
        },

        "&:hover": {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.common.white,
        },
    },
    autocompletePaper: {
        marginTop: 16,
        borderRadius: 10,
    },
}));

const FirstStepAll = (props) => {
    const styles = useStyles();
    const history = useCustomHistory();
    const [searchText, setSearchText] = useState();

    return (
        <>
            <Helmet
                title={t`Rechercher tout`}
                content="Découvrez le meilleur de la région bordelaise grâce à notre moteur de recherche en ligne. Trouvez des activités oenotouristiques, explorez une sélection de vins d'exception et découvrez les propriétés viticoles emblématiques de Bordeaux"
            />

            <Box className={styles.root}>
                <Box className={styles.main_container}>
                    <Typography variant="h2" className={styles.title}>
                        <Trans>Parcourez le vignoble bordelais</Trans>
                    </Typography>
                    <SearchTabs active="all" />

                    <Box className={styles.nav}>
                        <Box className={styles.search}>
                            <AutocompleteSearch
                                onChange={(e) => setSearchText(e)}
                                startAdornment
                                paperClassname={styles.autocompletePaper}
                                onEnterPressed={() =>
                                    history.push({ pathname: "/recherche/tout/resultats", search: searchText })
                                }
                            />
                        </Box>

                        <Box className={styles.result}>
                            <Button
                                className={styles.resultBtn}
                                onClick={() =>
                                    history.push({ pathname: "/recherche/tout/resultats", search: searchText })
                                }
                                disabled={!searchText || searchText === ""}
                            >
                                <Typography variant="h6">
                                    <Trans>Rechercher</Trans>
                                </Typography>
                            </Button>
                        </Box>
                    </Box>

                    <Box className={styles.resultTwo}>
                        <Button
                            className={styles.resultBtn}
                            onClick={() => history.push({ pathname: "/recherche/tout/resultats", search: searchText })}
                            disabled={!searchText || searchText === ""}
                        >
                            <Typography variant="h6">
                                <Trans>Rechercher</Trans>
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default FirstStepAll;
