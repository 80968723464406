import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Box, Card, CardContent, Typography, makeStyles } from "@material-ui/core";

import theme from "../globalTheme";
import bgWine1 from "../assets/challenges/bg-rouge.svg";
import { Trans } from "@lingui/macro";
import floriture1 from "../assets/odg/floriture1.svg";
import floriture2 from "../assets/odg/floriture2.svg";
import { urlize } from "../helpers/helpers";
import Tooltip from "Components/Tooltips/Tooltip";
import { useCustomHistory } from "hooks";

const useStyles = makeStyles(() => ({
    card: {
        cursor: "pointer",
        display: "flex",
        width: 280,
        height: 145,
        padding: "0px",
        backgroundColor: "#FFF",
        backgroundImage: `url(${floriture1}), url(${floriture2})`,
        backgroundPositionX: "left, right",
        backgroundPositionY: "bottom, top",
        backgroundRepeat: "no-repeat",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.08)",
        borderRadius: "10px",
        textDecoration: "none",
    },
    card_content_container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        margin: "1rem",
        padding: 0,
        width: "100%",
        "&:last-child": {
            paddingBottom: "0",
        },
        marginTop: "1rem",
    },
    item_title: {
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
        color: theme.palette.primary.main,
    },
    wine: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        minWidth: 123,
        height: "100%",
        backgroundImage: `url(${bgWine1})`,
        backgroundRepeat: "no-repeat",
        backgroundPositionY: "center",
    },
    link: {
        display: "flex",
        cursor: "pointer",
        textDecoration: "none",

        "&:hover": {
            textDecoration: "underline",
            textDecorationColor: theme.palette.secondary.dark,
        },
    },
}));

const LIMIT_NAME_LENGTH = 73;

const OdgWineCard = (props) => {
    const styles = useStyles(props);
    const history = useCustomHistory();

    const url = history.addLocale(`/organismes-vignoble-bordelais/ODG/${urlize(props.name)}`);

    const getCardRender = () => {
        return (
            <Card className={styles.card} component={Link} to={url}>
                <CardContent className={styles.card_content_container}>
                    <Box style={{ marginTop: "16px" }}>
                        <Typography variant="h4" className={styles.item_title}>
                            {props?.name?.length < LIMIT_NAME_LENGTH
                                ? props?.name
                                : `${props?.name?.slice(0, LIMIT_NAME_LENGTH)}...`}
                        </Typography>
                    </Box>

                    <Box style={{ display: "flex", justifyContent: "flex-end", marginRight: "28px" }}>
                        <Link className={styles.link} to={url}>
                            <Typography
                                variant="body2"
                                style={{
                                    fontSize: "16px",
                                    color: theme.palette.secondary.dark,
                                }}
                            >
                                <Trans>Découvrir</Trans>
                            </Typography>
                            <ArrowForwardIcon style={{ color: theme.palette.secondary.dark }} />
                        </Link>
                    </Box>
                </CardContent>
            </Card>
        );
    };

    if (props?.name?.length > LIMIT_NAME_LENGTH) {
        return (
            <Tooltip color="primary" title={props?.name} arrow>
                {getCardRender()}
            </Tooltip>
        );
    }

    return getCardRender();
};

OdgWineCard.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
};

export default OdgWineCard;
