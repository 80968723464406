import { useEffect, useState } from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import LanguageSelector from "Components/Accordions/LanguageSelector";
import { useTranslation, Trans } from "react-i18next";
import theme from "globalTheme";
import SelectInput from "Components/Inputs/SelectInput";
import SimpleInput from "Components/Inputs/SimpleInput";
import PasswordInput from "Components/Inputs/PasswordInput";
import ConstraintsPasswordInput from "Components/Inputs/ConstraintsPasswordInput";
import { useCustomHistory } from "hooks";
import BaseCheckbox from "Components/Inputs/BaseCheckbox";
import { getUserLocale, isEmailValid, useWindowDimensions } from "helpers/helpers";
import BaseButton from "Components/Buttons/BaseButton";
import {
    useCheckEmailAvailabilityMutation,
    useGetEnumByFieldQuery,
    useSignUpJournalistMutation,
} from "generated/graphql";
import { useDispatch, useSelector } from "react-redux";
import { signIn } from "features/user/userSlice";
import Loading from "Components/Loader/Loading";
import { login } from "features/user/firebase";
import { Link } from "react-router-dom";

const useStyles = makeStyles(() => ({
    form_container: {
        display: "grid",
        gridTemplateColumns: "repeat(12,1fr)",
        gap: "1rem",
        [theme.breakpoints.up("sm")]: {
            margin: "20px",
        },
        [theme.breakpoints.up("lg")]: {
            margin: "0",
        },
    },
    title: {
        [theme.breakpoints.up("xs")]: {
            textAlign: "center",
            gridColumn: "2 /span 10",
        },
        [theme.breakpoints.up("md")]: {
            textAlign: "left",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 12",
        },
    },
    language_selector_container: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.up("xs")]: {
            marginTop: "1rem",
            justifyContent: "center",
            gridColumn: "2 /span 10",
        },
        [theme.breakpoints.up("sm")]: {
            marginTop: 0,
        },
        [theme.breakpoints.up("md")]: {
            justifyContent: "start",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 12",
        },
    },
    title_container: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "2 / span 10",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "2 / span 2",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 2",
        },
    },
    firstName_container: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "2 / span 10",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 4 ",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 5",
        },
    },
    lastName_container: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "2 / span 10",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 4",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 5",
        },
    },
    email_container: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "2 / span 10",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "2 / span 5",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 6",
        },
    },
    displayName_container: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "2 / span 10",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 5",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 6",
        },
    },
    password_container: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "2 / span 10",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "2 / span 5",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 6",
        },
    },
    confirm_password_container: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "2 / span 10",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 5",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 6",
        },
    },
    main_areas_influence_container: {
        display: "flex",
        flexDirection: "column",
        padding: "11px 28px",
        backgroundColor: theme.palette.background.card,
        borderRadius: "6px",
        color: theme.palette.primary.dark,
        [theme.breakpoints.up("xs")]: {
            gridColumn: "2 / span 10",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 12",
        },
    },
    confidentiality_container: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "2 / span 10",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 12",
        },
    },
    login: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: theme.palette.primary.dark,
        gap: "0.5rem",
        flexWrap: "wrap",
        [theme.breakpoints.up("xs")]: {
            gridColumn: "2 / span 10",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 12",
        },
    },
}));

const Journalist = () => {
    const styles = useStyles();
    const { t } = useTranslation();
    const history = useCustomHistory();
    const [title, setTitle] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState(null);
    const [emailErrors, setEmailErrors] = useState(null);
    const [displayName, setDisplayName] = useState("");
    const [passwordValue, setPasswordValue] = useState(null);
    const [focus, setFocus] = useState(false);
    const [confirmationPasswordValue, setConfirmationPasswordValue] = useState(null);
    const [mainAreaInfluence, setMainAreaInfluence] = useState([]);
    const [lenConstraint, setLenConstraint] = useState(false);
    const [lowerCaseConstraint, setLowerCaseConstraint] = useState(false);
    const [upperCaseConstraint, setUpperCaseConstraint] = useState(false);
    const [symbolConstraint, setSymbolConstraint] = useState(false);
    const [loading, setLoading] = useState(false);
    const [confidentiality, setConfidentiality] = useState(false);
    const currentUser = useSelector((state) => state?.user?.currentUser);
    const lowerCaseRegex = /[a-z]/g;
    const upperCaseRegex = /[A-Z]/g;
    const symbolRegex = /[@$!%*?&]/g;
    const dispatch = useDispatch();
    const { width } = useWindowDimensions();
    const { data: { GetEnumByField: journalistInfluenceArea = [] } = {} } = useGetEnumByFieldQuery({
        variables: { field: "JOURNALIST_INFLUENCE_AREA" },
    });
    const [checkEmailAvailability] = useCheckEmailAvailabilityMutation();

    const [signUpJournalist, { data: signUpData, loading: signUpLoading }] = useSignUpJournalistMutation();

    const locale = useSelector(getUserLocale);

    useEffect(() => {
        setSymbolConstraint(!!passwordValue?.match(symbolRegex));
        setLenConstraint(passwordValue?.length >= 8);
        setLowerCaseConstraint(!!passwordValue?.match(lowerCaseRegex));
        setUpperCaseConstraint(!!passwordValue?.match(upperCaseRegex));
    }, [passwordValue]);

    useEffect(() => {
        if (signUpData) {
            dispatch(signIn({ username: email, password: passwordValue }));
            login(email, passwordValue);
            setLoading(false);
        }
    }, [signUpData]);

    useEffect(() => {
        if (currentUser && !loading && !signUpLoading) {
            history.replace("/dashboard/tastingrequest");
        }
    }, [currentUser, loading, signUpLoading]);

    const handleSignUpSubmit = async () => {
        try {
            const resEmail = await checkEmailAvailability({
                variables: { email: email },
            });
            const isAvailable = resEmail.data?.CheckEmailAvailability;

            if (isAvailable) {
                setLoading(true);
                await signUpJournalist({
                    variables: {
                        input: {
                            title,
                            firstName,
                            lastName,
                            displayName,
                            email,
                            password: passwordValue,
                            areas: mainAreaInfluence,
                            locale,
                        },
                    },
                });
            } else {
                setEmailErrors("Email déjà utilisé");
            }
        } catch (err) {
            setLoading(false);
            // eslint-disable-next-line no-console
            console.error(err);
        }
    };

    const getEmailErrors = () => {
        if (email && !isEmailValid(email)) {
            return "Le champ n'est pas valide";
        }
        if (emailErrors) {
            return "Email déjà utilisé";
        }
    };

    const passwordError = () => {
        if (passwordValue) {
            return !symbolConstraint || !lenConstraint || !lowerCaseConstraint || !upperCaseConstraint;
        }
        return false;
    };

    const confirmationPasswordError = () => {
        if (confirmationPasswordValue) {
            return !(confirmationPasswordValue === passwordValue);
        }
    };

    const handleAreaSelection = (area) => {
        if (mainAreaInfluence.includes(area)) {
            const updatedAreas = mainAreaInfluence.filter((item) => item !== area);
            setMainAreaInfluence(updatedAreas);
        } else {
            const updatedAreas = [...mainAreaInfluence, area];
            setMainAreaInfluence(updatedAreas);
        }
    };

    if (signUpLoading) {
        return <Loading />;
    }

    return (
        <Box className={styles.form_container}>
            <Box className={styles.language_selector_container}>
                <LanguageSelector darkTheme noFixed />
            </Box>
            <Box className={styles.title}>
                <Typography variant="h2">{t("tastingRequest.yourTastingRoom")}</Typography>
                <Typography variant="h5">{t("tastingRequest.inscription.subtitle")}</Typography>
            </Box>
            <Box className={styles.title_container}>
                <SelectInput
                    enumField="SIMPLE_PERSON_SALUTATION"
                    onChange={(e) => setTitle(e)}
                    value={title}
                    label={t("inputs.label.title")}
                    variant="outlined"
                    locale={locale}
                />
            </Box>
            {/* FIRSTNAME */}
            <Box className={styles.firstName_container}>
                <SimpleInput
                    required
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    label={t("inputs.label.firstName")}
                />
            </Box>

            {/* LASTNAME */}
            <Box className={styles.lastName_container}>
                <SimpleInput
                    required
                    label={t("inputs.label.lastName")}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
            </Box>

            <Box className={styles.email_container}>
                <SimpleInput
                    required
                    boxStyle={{ width: "100%" }}
                    variant="outlined"
                    label={t("inputs.label.email")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={(email && getEmailErrors(email)) || emailErrors}
                    clue={getEmailErrors()}
                    correctValue={email && isEmailValid(email) && !emailErrors}
                    falseValue={(email && isEmailValid(email)) || emailErrors}
                    onFocus={() => setEmailErrors(null)}
                />
            </Box>

            {/* DISPLAYNAME */}
            <Box className={styles.displayName_container}>
                <SimpleInput
                    required
                    label={t("inputs.label.displayName")}
                    value={displayName}
                    onChange={(e) => setDisplayName(e.target.value)}
                />
                <Typography variant="subtitle1" style={{ lineHeight: "12px", color: theme.palette.secondary.dark }}>
                    <Trans i18nKey="tastingRequest.inscription.displayNameInfoSubscription">
                        Please enter the name you want to be associated your name, the name of the company you work for,
                        both... (ex: John Doe MW or John Doe - WineTaste...)
                    </Trans>
                </Typography>
            </Box>
            <Box className={styles.password_container}>
                <ConstraintsPasswordInput
                    id="password"
                    name="password"
                    value={passwordValue}
                    onChange={(e) => setPasswordValue(e.target.value)}
                    error={passwordError()}
                    constraints={{
                        lenConstraint,
                        setLenConstraint,
                        upperCaseConstraint,
                        setUpperCaseConstraint,
                        lowerCaseConstraint,
                        setLowerCaseConstraint,
                        symbolConstraint,
                        setSymbolConstraint,
                    }}
                    focus={focus}
                    setFocus={setFocus}
                    style={{ width: "100%" }}
                    label={t("inputs.label.password")}
                    required
                />
            </Box>
            {/* PASSWORD CONFIRMATION */}
            <Box className={styles.confirm_password_container}>
                <PasswordInput
                    value={confirmationPasswordValue}
                    onChange={(e) => setConfirmationPasswordValue(e.target.value)}
                    error={confirmationPasswordError()}
                    correctValue={confirmationPasswordValue && confirmationPasswordValue === passwordValue}
                    helperText={confirmationPasswordError() && "Le mot de passe doit être identique"}
                    style={{ width: "100%" }}
                    label={t("inputs.label.passwordConfirmation")}
                    required
                />
            </Box>
            <Box className={styles.main_areas_influence_container}>
                <Typography variant="h6">{t("section.mainAreaInfluence")} *:</Typography>
                <Box style={{ display: "flex", flexWrap: "wrap", columnGap: "1rem" }}>
                    {journalistInfluenceArea?.map((area) => (
                        <BaseCheckbox
                            key={area}
                            boxStyle={{ width: "max-content", flexBasis: width < 600 && "100%" }}
                            labelClickable={<Typography variant="h6">{area?.valueLng[locale]}</Typography>}
                            checked={mainAreaInfluence.includes(area?.value)}
                            onChange={() => handleAreaSelection(area?.value)}
                            size="small"
                        />
                    ))}
                </Box>
            </Box>
            <BaseCheckbox
                className={styles.confidentiality_container}
                variant="outlined"
                checked={confidentiality}
                onChange={(e) => setConfidentiality(e?.target?.checked)}
                label={<Typography variant="h6">{t("common.privacyPolicy")}*</Typography>}
                style={{ width: "100%" }}
            />
            <BaseButton
                boxStyle={{
                    gridColumn: width >= 1280 ? "1 / span 12" : "2 / span 10",
                }}
                disabled={
                    !title ||
                    !firstName ||
                    !lastName ||
                    !displayName ||
                    !email ||
                    !passwordValue ||
                    mainAreaInfluence?.length === 0 ||
                    !confidentiality
                }
                fullWidth
                color="primary"
                variant="contained"
                onClick={() => handleSignUpSubmit()}
            >
                {t("common.signup")}
            </BaseButton>
            <Box className={styles.login}>
                <Typography
                    variant="h6"
                    style={{
                        color: theme.palette.primary.dark,
                    }}
                >
                    {t("common.alreadyHaveAccount")} ?{" "}
                    <Link
                        to={() => history.addLocale("bienvenue/connexion")}
                        underline="always"
                        style={{ color: theme.palette.primary.dark, cursor: "pointer", textDecoration: "underline" }}
                    >
                        {t("common.login")} !
                    </Link>
                </Typography>
            </Box>
        </Box>
    );
};

Journalist.propTypes = {};

export default Journalist;
