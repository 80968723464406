/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import Chip from "@material-ui/core/Chip";
import soils from "../../assets/Wine/soils.svg";
import theme from "../../globalTheme";
import getConnectors, { parseHtml } from "../../helpers/helpers";
import { Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
    container: {
        border: "0.4px solid",
        borderRadius: "5px",
        borderColor: theme.palette.secondary.dark,
        position: "relative",
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        paddingTop: 0,
    },
    typoContainer: {
        position: "relative",
        top: "-16px",
        left: "0px",
        display: "flex",
        alignItems: "center",
    },
    title: {
        ...theme.front.typography.h6.dark_b,
        zIndex: 2,
        backgroundColor: theme.palette.common.white,
        padding: "0px 2px",
    },
    typo: {
        color: theme.palette.primary.light,
        fontSize: "18px",
        [theme.breakpoints.only("xs")]: {
            fontSize: "12px",
        },
    },
    typoDescription: {
        fontFamily: "Lato",
        fontSize: "16px",
        color: theme.palette.primary.dark,
    },
}));

const WineHarvestDescription = (props) => {
    const styles = useStyles(props);
    return (
        <Box className={styles.container}>
            <Box className={styles.typoContainer}>
                <Typography variant="h3" className={styles.title}>
                    <Trans>À propos de la viticulture</Trans>
                </Typography>
            </Box>
            <Typography className={styles.typoDescription}>{parseHtml(props.description)}</Typography>
        </Box>
    );
};

WineHarvestDescription.propTypes = {
    description: PropTypes.string,
    title: PropTypes.string,
    wineName: PropTypes.string,
    year: PropTypes.any,
    cuvee: PropTypes.string,
};

export default WineHarvestDescription;
