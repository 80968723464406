import { makeStyles } from "@material-ui/core";

import fioContact from "assets/propriétéPage/fio_contact.svg";
import fioLeft from "assets/propriétéPage/fio-left.svg";
import fioRight from "assets/propriétéPage/fio-right.svg";
import cardBgContact from "assets/propriétéPage/fio_bottom_left.svg";

export const useStyles = makeStyles((theme) => ({
    mainContainer: {
        margin: "auto",
        padding: "16px 12px",
        minHeight: "220px",
    },
    textToday: {
        fontSize: "14px",
        fontFamily: "Lato",
        color: theme.palette.primary.light,
    },
    container_card: {
        display: "grid",
        gridTemplateColumns: "repeat(12,1fr)",
        // marginTop: "3rem",
    },
    cardHeader: {
        ...theme.front.padding.card,
    },
    propertyLocation: {
        border: "1px solid",
        borderRadius: "10px",
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.common.white,
    },
    contactPropertyFormCard__wrapper: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        [theme.breakpoints.only("xs")]: {
            display: "flex",
            flexDirection: "column",
            gap: "16px",
        },
        [theme.breakpoints.up("sm")]: {
            gap: "16px",
        },
        [theme.breakpoints.up("md")]: {},
        [theme.breakpoints.up("lg")]: {
            gap: "32px",
        },
        [theme.breakpoints.up("xl")]: {},
    },
    getPropertyLocation__wrapper: {
        [theme.breakpoints.up("xs")]: {},
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 12",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 5",
        },
    },
    getPropertyLocation__wrapper_orphan: {
        gridColumn: "auto / span 12",
    },
    getFormContact__wrapper: {
        display: "grid",
        height: "fit-content",
        [theme.breakpoints.only("xs")]: {
            display: "flex",
            flexDirection: "column",
            gap: "16px",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 12",
            gridTemplateColumns: "repeat(8, 1fr)",
            gap: "16px",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 7",
            gridTemplateColumns: "repeat(9, 1fr)",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 7",
            gridTemplateColumns: "repeat(9, 1fr)",
            gap: "32px",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "6 / span 7",
            gridTemplateColumns: "repeat(9, 1fr)",
        },
    },
    formContact_input01: {
        height: "34px",
        [theme.breakpoints.up("xs")]: {},
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 4",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 2",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 2",
        },
        [theme.breakpoints.up("xl")]: {},
    },
    formContact_input02: {
        [theme.breakpoints.up("xs")]: {},
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 4",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 3",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 3",
        },
        [theme.breakpoints.up("xl")]: {},
    },
    formContact_input03: {
        [theme.breakpoints.up("xs")]: {},
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 4",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 3",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 3",
        },
        [theme.breakpoints.up("xl")]: {},
    },
    formContact_input04: {
        [theme.breakpoints.up("xs")]: {},
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 4",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 4",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 3",
        },
        [theme.breakpoints.up("xl")]: {},
    },
    formContact_input05: {
        [theme.breakpoints.up("xs")]: {},
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 4",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 4",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 3",
        },
        [theme.breakpoints.up("xl")]: {},
    },
    formContact_input06: {
        [theme.breakpoints.up("xs")]: {},
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 4",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 3",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 3",
        },
        [theme.breakpoints.up("xl")]: {},
    },
    formContact_input07: {
        [theme.breakpoints.up("xs")]: {},
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 8",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 6",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 9",
        },
        [theme.breakpoints.up("xl")]: {},
    },
    formContact_input08: {
        [theme.breakpoints.up("xs")]: {},
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 8",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 9",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 9",
        },
        [theme.breakpoints.up("xl")]: {},
    },
    formContact_input09: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "14px",

        [theme.breakpoints.only("xs")]: {
            display: "flex",
            flexDirection: "column",
            gap: "16px",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 8",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 9",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 9",
        },
        [theme.breakpoints.up("xl")]: {
            flexDirection: "row",
            alignItems: "flex-start",
        },
    },

    contactPropertyFormCard: {
        ...theme.front.padding.card,
    },
    contactPropertyFormCard__linkArrowHorrair: {
        fontFamily: "Lato",
        fontSize: "14px",
        cursor: "pointer",
        textDecoration: "underline",
        color: theme.palette.primary.light,
    },
    textTel: {
        fontFamily: "Lato",
        color: theme.palette.primary.light,
        fontSize: "14px",
    },
    textDataHoraire: {
        fontFamily: "Lato",
        fontSize: "16px",
        color: theme.palette.primary.dark,
    },
    textLocation: {
        fontFamily: "Lato",
        fontSize: "16px",
        color: theme.palette.primary.dark,
    },

    // ORPHAN

    containerOrphanTitle: {
        border: "0.4px solid",
        borderColor: theme.palette.secondary.main,
        borderRadius: "4px",
        backgroundColor: theme.palette.background.card,
        color: theme.palette.primary.dark,
        padding: "2rem 1rem",
        textAlign: "center",
        [theme.breakpoints.up("sm")]: {
            backgroundImage: `url(${fioLeft}),url(${fioRight})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "left, right",
        },
    },
    containerAddress: {
        border: "0.4px solid",
        borderColor: theme.palette.secondary.main,
        borderRadius: "4px",
        backgroundColor: theme.palette.background.paper,
        textAlign: "center",
        color: theme.palette.common.black,
        marginBottom: "0.5rem",
        padding: "1rem",
        [theme.breakpoints.up("sm")]: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        [theme.breakpoints.up("md")]: {
            marginBottom: "0",
            border: "transparent",
            width: "50%",
            background: `url(${fioContact})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom right",
        },
    },
    AddressList: {
        display: "flex",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        },
        [theme.breakpoints.up("md")]: {
            flexDirection: "column",
            textAlign: "left",
        },
    },
    homeIcon: {
        marginRight: "1rem",
        [theme.breakpoints.down("xs")]: {
            display: "none",
        },
    },
    arrowOrphanSubtitle: {
        [theme.breakpoints.down("xs")]: {
            display: "none",
        },
    },
    OrphanSubtitle: {
        textDecoration: "underline",
        cursor: "pointer",
        fontWeight: "700",
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    },
    containerMapAddress: {
        [theme.breakpoints.up("md")]: {
            display: "flex",
            marginBottom: "1rem",
            border: "0.4px solid",
            borderColor: theme.palette.secondary.main,
            borderRadius: "4px",
        },
    },
    mapContainer: (props) => {
        return {
            width: "100%",
            [theme.breakpoints.up("md")]: {
                width: props.isOrphan || props.restriction ? "100%" : "50%",
            },
        };
    },
    container_credentials: {
        backgroundColor: theme.palette.common.white,
        borderRadius: 4,
        border: `1px solid ${theme.palette.secondary.dark}`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "1.5rem",
    },
    card: {
        borderRadius: "20px",
        boxShadow: "rgb(0 0 0 / 8%) 0px 4px 76px 4px",
        backgroundImage: `url(${cardBgContact})`,
        backgroundPosition: "bottom left",
        backgroundRepeat: "no-repeat",
        width: "100%",
        marginBottom: "3rem",
        [theme.breakpoints.up("xs")]: {
            gridColumn: "1 / span 12",
        },
        [theme.breakpoints.up("xl")]: {
            width: "calc(1314px)",
            margin: "0 auto 3rem auto",
        },
    },
    formButtonBase: {
        display: "flex",

        [theme.breakpoints.up("lg")]: {
            justifyContent: "flex-end",
        },
    },
}));
