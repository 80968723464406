import { Box, makeStyles, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { any, bool, func, node, object, oneOf, oneOfType, string } from "prop-types";

import backgroundButton from "../../assets/btn_illustre.svg";
import theme from "../../globalTheme";
import BaseTooltip from "../Tooltips/Tooltip";

const useStyle = makeStyles((theme) => ({
    button: (props) => ({
        minWidth: "150px",
        minHeight: "46px",
        borderRadius: "4px",
        fontSize: "14px",
        textTransform: "none",
        border: "1px solid",
        backgroundImage: props.background ? props.backgroundImg || `url(${backgroundButton})` : null,
        backgroundPosition: "0 0",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        borderColor: theme.palette.primary.main,
        textAlign: "center",
        "&.Mui-disabled": {
            background: theme.palette.secondary.dark,
            border: theme.palette.secondary.dark,
            color: theme.palette.secondary.main,
        },
        "&.MuiButton-outlined": {
            minWidth: props.width,
            backgroundColor: theme.palette.common.white,
            "&.Mui-disabled": {
                backgroundColor: theme.palette.common.white,
                color: `1px solid ${theme.palette.secondary.dark}`,
                border: `1px solid ${theme.palette.secondary.dark}`,
            },
        },
        "&.MuiButton-sizeSmall": {
            minHeight: "34px",
            fontSize: "14px",
        },
        "&.MuiButton-sizeLarge": {
            fontSize: "18px",
        },
        "&.MuiButton-sizeMedium": {
            minHeight: "46px",
            maxHeight: "46px",
            fontSize: "14px",
        },
    }),
    box: {
        position: "relative",
    },
}));

const getTags = (props) => {
    if (props.tooltip) {
        return (
            <Box
                style={{
                    position: "absolute",
                    right: "10px",
                    top: "-6px",
                    zIndex: 2,
                }}
            >
                {props.tooltip && (
                    <BaseTooltip color="primary" title={props.tooltipTitle} arrow>
                        <Typography
                            style={{
                                backgroundColor: "#632D3E",
                                color: "white",
                                padding: "1px 6.72px",
                                borderRadius: "80px",
                                fontSize: "9.6px",
                                fontFamily: "Playfair Display",
                                fontStyle: "italic",
                                fontWeight: "600",
                            }}
                        >
                            i
                        </Typography>
                    </BaseTooltip>
                )}
            </Box>
        );
    } else if (props.topElement) {
        return (
            <Box
                style={{
                    position: "absolute",
                    right: 25,
                    top: -9,
                    zIndex: 2,
                    backgroundColor: "white",
                }}
            >
                {props.topElement}
            </Box>
        );
    } else if (props.commingTag) {
        return (
            <Box
                style={{
                    position: "absolute",
                    right: 15,
                    top: -7,
                    zIndex: 2,
                    backgroundColor: theme.palette.secondary.main,
                    padding: "0px 4px",
                }}
            >
                <Typography
                    style={{
                        fontSize: "10px",
                        color: theme.palette.primary.dark,
                    }}
                >
                    {props.commingTag}
                </Typography>
            </Box>
        );
    }
};

const BaseButton = (props) => {
    const styles = useStyle(props);

    return (
        <BaseTooltip title={props.tooltipTitle || ""} color="primary">
            <Box style={{ ...props.boxStyle }} className={`${styles.box} ${props.className}`}>
                {getTags(props)}
                <Button className={[styles.button, props.classes].join(" ")} {...props}>
                    {props.children}
                </Button>
            </Box>
        </BaseTooltip>
    );
};

BaseButton.propTypes = {
    className: object,
    autoFocus: bool,
    classes: oneOfType([object, string]),
    variant: oneOf(["text", "contained", "outlined"]),
    color: oneOf(["primary", "secondary", "inherit", "default"]),
    children: any,
    onClick: func,
    size: oneOf(["small", "medium", "large"]),
    disabled: bool,
    background: bool,
    style: object,
    boxStyle: object,
    type: any,
    tooltip: bool,
    tooltipTitle: string,
    width: any,
    href: string,
    target: string,
    fullWidth: bool,
    component: node,
};

BaseButton.defaultProps = {
    classes: {},
};

export default BaseButton;
