// Setup:
import { bool, func, string } from "prop-types";
import theme from "../../../globalTheme";

// Components:
import { makeStyles, Card, CardMedia, Typography, Box } from "@material-ui/core";
import BaseButton from "../../../Components/Buttons/BaseButton";

// Assets:
import fioritureBigWineCard from "../../../assets/propriétéPage/fio_first_wine.svg";
import redGrape from "../../../assets/Dashboard/redGrape.svg";
import rewardImage from "../../../assets/Dashboard/reward.svg";
import whiteGrape from "../../../assets/Dashboard/whiteGrape.svg";
import scoreImage from "../../../assets/vin_score.svg";

// Methods:
import { firstLetterUppercase } from "../../../helpers/helpers";
import { useWindowDimensions } from "../../../helpers/helpers";
import { t, Trans } from "@lingui/macro";
import { Link } from "react-router-dom";

// Styles:
const useStyles = makeStyles((theme) => ({
    card: {
        // minWidth: "500px",
        position: "relative",
        padding: "20px 32px",
        paddingTop: "30px",
        paddingBottom: "5px",
        display: "flex",
        alignItems: "center",
        width: "auto",
        borderRadius: "10px",
        border: "0.4px solid",
        borderColor: theme.palette.secondary.light,
        borderBottomWidth: "10px",
        borderBottomColor: theme.palette.primary.light,
        justifyContent: "flex-start",
        [theme.breakpoints.only("xs")]: {
            // minWidth: "200px",
            padding: "8px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            alignItems: "flex-start",
        },
        [theme.breakpoints.only("sm")]: {
            alignItems: "flex-start",
            height: "300px",
            paddingTop: "20px",
            paddingBottom: "20px",
            paddingRight: "20px",
            paddingLeft: "20px",
            // minWidth: "250px"
        },
        [theme.breakpoints.only("md")]: {
            height: "auto",
            paddingTop: "20px",
            paddingBottom: "20px",
            paddingRight: "20px",
            paddingLeft: "20px",
            // minWidth: "500px"
        },
    },
    media: {
        width: "74px",
        height: "265px",
        backgroundSize: "contain",
        [theme.breakpoints.only("xs")]: {
            width: "40px",
            height: "150px",
        },
    },
    container: {
        marginLeft: theme.spacing(4),
        padding: "0px",
        [theme.breakpoints.only("xs")]: {
            minWidth: "100px",
            marginLeft: 0,
        },
    },
    color: {
        color: "#360318",
        marginRight: "4px",
    },
    aoc: {
        fontSize: "16px",
        color: "#360318",
    },
    button: {
        display: "block",
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.only("xs")]: {
            minWidth: "100px",
            width: "100%",
            margin: 0,
        },
    },
    rank: {
        color: theme.palette.primary.light,
    },
    wineRanking: {
        color: theme.palette.primary.dark,
    },
    name: {
        color: theme.palette.common.black,
        fontFamily: "Playfair Display",
    },
    marginBottom: {
        marginBottom: theme.spacing(2),
    },
    fioritureBigWine: {
        position: "absolute",
        right: 0,
        bottom: 0,
    },
    flex: {
        display: "flex",
        [theme.breakpoints.up("xs")]: {
            gap: "8px",
        },
    },
    bulletPoint: { display: "flex", alignItems: "center", marginRight: "4px" },
    hasRewards: { color: "#915868", fontSize: "14px" },
    rewardOrScoreContainer: { display: "flex", alignItems: "center", marginRight: "16px" },
    grapeImage: (props) => ({
        width: props.wineColorFr === "Blanc" ? "40px" : "50px",
        height: "40px",
        right: 0,
        position: "absolute",
        top: 0,
    }),
    rewardImage: {
        width: "30px",
        height: "40px",
        marginRight: theme.spacing(1),
    },
}));

const BigWineCard = (props) => {
    const styles = useStyles();
    const { width } = useWindowDimensions();

    const getImage = () => {
        if (props.wineColorFr === "Rouge" || props.wineColorFr === "Rosé") {
            return <img src={redGrape} alt="grape" className={styles.grapeImage} />;
        } else if (props.wineColorFr === "Blanc") {
            return <img src={whiteGrape} alt="grape" className={styles.grapeImage} />;
        }
        return null;
    };

    return (
        <Box>
            <Card
                className={styles.card}
                style={{ cursor: "pointer", textDecoration: "none" }}
                component={Link}
                to={props.to}
            >
                {props.wineColor && getImage()}
                <img src={fioritureBigWineCard} className={styles.fioritureBigWine} />
                <Box className={styles.flex} style={{ width: "100%" }}>
                    <Box className={styles.imageContainer}>
                        <CardMedia
                            className={styles.media}
                            image={props.image || ""}
                            title={`${props.name} - ${props.wineEntityName}`}
                        />
                    </Box>

                    <Box
                        className={styles.container}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            zIndex: 1,
                        }}
                    >
                        <Box>
                            {(props.name || props.wineRanking) && (
                                <Box className={styles.marginBottom}>
                                    {props.name && (
                                        <Typography variant="h4" className={styles.name}>
                                            {props.name}
                                        </Typography>
                                    )}
                                    {props.wineRanking && (
                                        <Typography variant="h5" className={styles.wineRanking}>
                                            {props.wineRanking}
                                        </Typography>
                                    )}
                                </Box>
                            )}

                            {(props.rank || props.aoc || props.wineColor) && (
                                <Box className={styles.marginBottom}>
                                    {props.rank && (
                                        <Typography variant="h6" className={styles.rank}>
                                            {firstLetterUppercase(props.rank)}
                                        </Typography>
                                    )}
                                    {(props.aoc || props.wineColor) && (
                                        <Box
                                            className={styles.flex}
                                            style={{
                                                gap: 0,
                                                alignItems: "baseline",
                                            }}
                                        >
                                            {props.wineColor && props.shouldMentionColor && (
                                                <Typography variant="h6" className={styles.color}>
                                                    {props.wineColor}
                                                </Typography>
                                            )}
                                            {props.aoc && props.wineColor && props.shouldMentionColor && (
                                                <Box className={styles.bulletPoint}>{" • "}</Box>
                                            )}
                                            {props.sweetness && props.shouldMentionSweetness && (
                                                <Typography variant="h6" className={styles.color}>
                                                    {props.wineColor}
                                                </Typography>
                                            )}
                                            {props.aoc && props.sweetness && props.shouldMentionSweetness && (
                                                <Box className={styles.bulletPoint}>{" • "}</Box>
                                            )}
                                            {props.aoc && (
                                                <Typography variant="h6" className={styles.aoc}>
                                                    {props.aoc}
                                                </Typography>
                                            )}
                                        </Box>
                                    )}
                                </Box>
                            )}
                        </Box>

                        <Box style={{ display: "flex", justifyContent: "flex-start" }}>
                            {props.hasRewards && (
                                <Box className={styles.rewardOrScoreContainer}>
                                    <img src={rewardImage} alt="reward_image" className={styles.rewardImage} />
                                    {props.rankFr === "premier vin" && (
                                        <Typography className={styles.hasRewards}>{t`Vin récompensé`}</Typography>
                                    )}
                                </Box>
                            )}

                            {props.hasScores && (
                                <Box className={styles.rewardOrScoreContainer}>
                                    <img src={scoreImage} alt="score_image" className={styles.rewardImage} />
                                    {props.rankFr === "premier vin" && (
                                        <Typography className={styles.hasRewards}>{t`Vin noté`}</Typography>
                                    )}
                                </Box>
                            )}
                        </Box>
                        {width >= 600 && (
                            <BaseButton
                                component={Link}
                                to={props.to}
                                variant="outlined"
                                color="primary"
                                size="small"
                                classes={styles.button}
                            >
                                <Typography variant="h6" style={{ color: theme.palette.primary.light }}>
                                    <Trans>Découvrir</Trans>
                                </Typography>
                            </BaseButton>
                        )}
                    </Box>
                </Box>
                {width < 600 && (
                    <BaseButton
                        component={Link}
                        to={props.to}
                        variant="outlined"
                        color="primary"
                        boxStyle={{ width: "100%" }}
                        size="small"
                        classes={styles.button}
                    >
                        <Typography variant="h6" style={{ color: theme.palette.primary.light }}>
                            <Trans>Découvrir</Trans>
                        </Typography>
                    </BaseButton>
                )}
            </Card>
        </Box>
    );
};

BigWineCard.propTypes = {
    to: string,
    wineColor: string,
    wineColorFr: string,
    rank: string,
    rankFr: string,
    name: string,
    aoc: string,
    sweetness: string,
    image: string,
    hasVintages: bool,
    hasRewards: bool,
    hasScores: bool,
    wineRanking: string,
    lastVintageId: string,
    shouldMentionSweetness: bool,
    shouldMentionColor: bool,
    isOrphan: bool,
    wineEntityName: string,
};

export default BigWineCard;
