// SETUP
import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import Lightbox from "react-image-lightbox";
import { mod, parseHtml } from "./../../helpers/helpers";

import subPropImg1 from "../../assets/propriétéPage/propriété_01-féret.png";

// COMPONENTS
import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    mobileGallery__wrapper: {
        "& .swiper-container": {
            borderRadius: "5px",
        },
        "& .swiper-pagination-bullet": {
            background: "white",
        },
    },
    mobileGallery__swiperSLide: {},
    mobileGallery__swiperSLide__img: {
        borderRadius: "2px",
    },
    mobileGallery__swiperSLideMore: {},
}));

SwiperCore.use([Pagination]);

const MobileGallery = (props) => {
    const styles = useStyles();

    const [lightBoxIsOpen, setLightBoxIsOpen] = useState(false);
    const [allImagesIndex, setAllImagesIndex] = useState(0);
    let filteredImagesUrls = [];
    let filteredImagesPaths = [];
    let captions =
        props.imagesData && props.imagesData?.length !== 0
            ? [...props.imagesData]?.map((el) => {
                  if (el?.caption) {
                      return el?.caption;
                  } else {
                      return "";
                  }
              })
            : [];

    if (props.front) {
        props.imagesData?.map((el) => {
            filteredImagesUrls?.push(el.twicpicURL);
            filteredImagesPaths?.push(el.twicpicPath);
        });
    } else {
        filteredImagesUrls = [...props.imagesData];
        filteredImagesPaths = [...props.imagesData];
    }

    const allImages = [...(filteredImagesUrls || [])];

    return (
        <Box className={styles.mobileGallery__wrapper}>
            {lightBoxIsOpen && (
                <Lightbox
                    imagePadding={100}
                    mainSrc={allImages.length && allImages[allImagesIndex]}
                    onCloseRequest={() => {
                        setLightBoxIsOpen(false);
                    }}
                    nextSrc={allImages[mod(allImagesIndex + 1, allImages?.length)]}
                    prevSrc={allImages[mod(allImagesIndex - 1, allImages?.length)]}
                    onMoveNextRequest={() => setAllImagesIndex(mod(allImagesIndex + 1, allImages?.length))}
                    onMovePrevRequest={() => setAllImagesIndex(mod(allImagesIndex - 1, allImages?.length))}
                    imageCaption={parseHtml(captions[allImagesIndex])}
                />
            )}
            <Swiper pagination={{ dynamicBullets: true }} spaceBetween={30}>
                {props?.imagesData.length !== 0 ? (
                    <>
                        {props?.imagesData?.map((el, key) => {
                            return (
                                <SwiperSlide key={key} className={styles.mobileGallery__swiperSLideMore}>
                                    <img
                                        src={el.twicpicURL}
                                        className={styles.mobileGallery__swiperSLide__img}
                                        onClick={() => {
                                            setLightBoxIsOpen(true);
                                            setAllImagesIndex(key);
                                        }}
                                    />
                                </SwiperSlide>
                            );
                        })}
                    </>
                ) : (
                    <SwiperSlide className={styles.mobileGallery__swiperSLide}>
                        <img src={subPropImg1} className={styles.mobileGallery__swiperSLide__img} />
                    </SwiperSlide>
                )}
            </Swiper>
        </Box>
    );
};

MobileGallery.propTypes = {
    imagesData: PropTypes.object,
    front: PropTypes.bool,
};

export default MobileGallery;
