/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useEffect } from "react";

import {
    makeStyles,
    TextField,
    InputAdornment,
    Typography,
    Box,
} from "@material-ui/core";
import PropTypes, { bool } from "prop-types";
import CheckIcon from "@material-ui/icons/Check";
import AdditionalInformationTooltip from "../Tooltips/AdditionalInformationTooltip";
import { getCharacters } from "../../helpers/helpers";
import LanguageBlock from "../../Dashboard/Components/TranslationComponents/LanguagesBloc";
import { debounce } from "lodash";

const useStyles = makeStyles((theme) => ({
    root: (props) => ({
        width: "100%",
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor:
                props.error && !props.focused
                    ? theme.palette.error.main
                    : theme.palette.secondary.main,
        },
        "& .MuiOutlinedInput-root": {
            fontSize: "14px",
            color: theme.palette.primary.dark,
            borderColor:
                props.error && !props.focused
                    ? theme.palette.error.main
                    : theme.palette.secondary.main,
            backgroundColor: "white",
            minHeight: props.textareaMinHeight,
            maxHeight: "auto",
            display: "flex",
            paddingBottom: "20px",
            alignItems: "flex-start",
            "&.Mui-disabled": {
                color: theme.palette.secondary.dark,
                backgroundColor: theme.palette.disabled.main,
            },
            "&:hover fieldset": {
                borderColor:
                    props.error && !props.focused
                        ? theme.palette.error.main
                        : theme.palette.secondary.main,
            },
            "&.Mui-focused fieldset": {
                border: "1px solid",
                borderColor:
                    props.error && !props.focused
                        ? theme.palette.error.main
                        : theme.palette.secondary.main,
            },
        },
        "& .MuiFormLabel-root": {
            fontSize: "14px",
            color:
                props.error && !props.focused
                    ? theme.palette.error.main
                    : theme.palette.secondary.dark,
        },
    }),
    boxContainer: {
        position: "relative",
        width: "100%",
    },
    languageBlockContainer: {
        display: "flex",
        justifyContent: "flex-end",
    },
    toolTipBox: {
        position: "absolute",
        left: -6,
        top: -10,
        zIndex: 2,
    },
    undertext: {
        fontSize: "10px",
        color: theme.palette.secondary.dark,
    },
}));

const TextareaInput = (props) => {
    const [focused, setFocused] = useState(false);
    const styles = useStyles({ ...props, focused });
    const [text, setText] = useState(props.value);
    useEffect(() => {
        setText(props.value);
    }, [props.value]);
    const debounceFn = useCallback(debounce(props.onChange, 500), []);
    const handleChange = (e1, e2, e3) => {
        setText(e1.target.value);
        debounceFn(e1.target.value, props.locale);
    };

    return (
        <>
            <Box className={styles.boxContainer} style={{ ...props.boxStyle }}>
                {/* TRANSLATION */}
                {!props.noTranslation && (
                    <Box className={styles.languageBlockContainer}>
                        <LanguageBlock translationState={1} />
                    </Box>
                )}
                {/* TOOLTIP */}
                {props.tooltipTitle && (
                    <Box className={styles.toolTipBox}>
                        <AdditionalInformationTooltip
                            color="primary"
                            title={props.tooltipTitle}
                            arrow
                        />
                    </Box>
                )}
                <TextField
                    type="textarea"
                    variant="outlined"
                    size="small"
                    multiline
                    label={props.label}
                    disabled={props.disabled}
                    required={props.required}
                    error={props.error}
                    value={text}
                    onChange={(e) => handleChange(e)}
                    onFocus={(e) => {
                        setFocused(true);
                        if (props.onFocus) {
                            props.onFocus(e);
                        }
                    }}
                    onBlur={async () => {
                        const newText = text
                            ? text.replace(/(\s*\n){2,}/g, "\n\n")
                            : null;
                        setFocused(false);
                        setText(newText);
                        await props.onChange(newText, props.locale);
                        if (props.onBlur) {
                            await props.onBlur(text);
                        }
                    }}
                    style={{ ...props.style }}
                    className={styles.root}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: props.maxLength }}
                    InputProps={{
                        classes: { input: styles.root },
                        endAdornment: (
                            <InputAdornment position="end">
                                {props.correctValue && (
                                    <CheckIcon className={styles.checkIcon} />
                                )}
                            </InputAdornment>
                        ),
                    }}
                />
                {!props.disabledLimit  &&getCharacters(
                    props.value,
                    props.minLength,
                    props.maxLength,
                    props.error,
                    focused
                )}
            </Box>
            {props.undertext ? (
                <Typography variant="h6" className={styles.undertext}>
                    {" "}
                    {props.undertext}{" "}
                </Typography>
            ) : (
                ""
            )}
        </>
    );
};

TextareaInput.propTypes = {
    locale: PropTypes.string,
    label: PropTypes.string,
    noTranslation: PropTypes.bool,

    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,

    disabled: PropTypes.bool,
    error: PropTypes.string,
    required: PropTypes.bool,

    style: PropTypes.object,
    boxStyle: PropTypes.object,

    correctValue: PropTypes.bool,
    tooltipTitle: PropTypes.string,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    undertext: PropTypes.string,
    disabledLimit : bool
};

export default React.memo(TextareaInput);
