import { Box,  Card, CardContent,  makeStyles, Modal, Typography } from "@material-ui/core";
import React, { Ref } from "react";

import cross from "../../assets/cross.svg";
import noActionNeededBg from "../../assets/popin/no_action_needed_bg.svg";
import actionNeededBg from "../../assets/popin/action_needed_bg.svg";
import theme from "../../globalTheme";
import ModalResetFilters from "../ui/ModalResetFilters";
import ModalSearchFilters from "../ui/ModalSearchFilters";
import { Plural } from "@lingui/macro";

interface ButtonProps {
    // eslint-disable-next-line no-unused-vars
    onClick?: (e?: any) => Promise<void> | void;
    disabled?: boolean;
    text: string;
}
interface PopinProps {
    open: boolean;
    onClose: () => void;
    title: React.ReactNode;
    actionNeeded?: boolean;
    customPrimary?: React.ReactNode;
    children: React.ReactNode;
    primaryCTA?: ButtonProps;
    secondaryCTA?: ButtonProps | null;
    tertiaryCTA?: ButtonProps;
    width?: string;
    legend?: React.ReactNode;
    table?: boolean;
    disableEscapeKeyDown?: boolean;
    extraTextButton?: string;
    childrenBoxStyle?: any;
    styles?: object;
    titlePrimary: string;
    titleSecondary: string;
    switch: any;
    searchResult: object[];
    CTAPrimaryClick: () => void;
    CTAPrimaryDisabled: Boolean;
    boxContentClassname: any;
    contentBoxPaddingLeft: string;
    totalCount: number;
    onCloseCross: () => void;
    headerRef: Ref<HTMLDivElement>;
    footerRef: Ref<HTMLDivElement>;
}

const getBackground = (background?: boolean) => {
    if (background) {
        return `no-repeat bottom left/15rem ${theme.palette.common.white} url(${actionNeededBg})`;
    } else if (background === false) {
        return `no-repeat bottom left/10rem ${theme.palette.common.white} url(${noActionNeededBg})`;
    } else return `${theme.palette.common.white}`;
};

const useStyle = makeStyles((theme) => ({
    modal: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
    },
    cardContainer: {
        maxHeight: "90vh",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        margin: "0 auto",
        width: "100%",
        overflow: "hidden",
    },
    card: (props: PopinProps) => ({
        width: props.width,
        position: "relative",
        background: getBackground(props.actionNeeded),
        borderRadius: "10px",
        maxWidth: "70rem",
        height: "100%",
        maxHeight: "90vh",
    }),
    cross: {
        position: "absolute",
        zIndex: 2,
        top: 0,
        right: 0,
        margin: "1rem",
        cursor: "pointer",
    },
    cardContent: {
        padding: "0",
    },
    childrenContainer: {
        //TO NOT DELETE SCROLL STYLE
        "&::-webkit-scrollbar": {
            width: "8px",
            height: "8px",
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            paddingRight: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "20px",
        },
    },
    grid: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        padding: "26px 9px",

        [theme.breakpoints.up("sm")]: {
            padding: "26px 30px",
        },
    },
    top: {
        gridColumn: "1 / span 12",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        marginBottom: 0,

        [theme.breakpoints.up("sm")]: {
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
        },
        [theme.breakpoints.down("sm")]: {
            textAlign: "left",
            alignItems: "start",
        },
    },
    title: {
        gridColumn: "1 / span 12",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 8,
        flexWrap: "wrap",
        flexDirection: "row",

        [theme.breakpoints.up("sm")]: {
            justifyContent: "flex-start",
        },
        [theme.breakpoints.down("sm")]: {
            textAlign: "left",
            alignItems: "start",
        },
    },
    titlePrimary: {
        color: theme.palette.common.black,
        textAlign: "center",

        [theme.breakpoints.up("sm")]: {
            textAlign: "left",
        },
    },
    titleSecondary: {
        color: theme.palette.secondary.main,
        marginLeft: 6,
        textAlign: "center",

        [theme.breakpoints.up("sm")]: {
            textAlign: "left",
        },
    },
    switchBox: {
        display: "flex",
        alignItems: "center",
    },
    cta: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        padding: "0px 18px",
        // paddingRight: 30,

        [theme.breakpoints.up("sm")]: {
            padding: "0px 46px 0 30px",
        },
    },
    resetBox: {
        gridColumn: "1 / span 12",
        display: "flex",
        justifyContent: "center",
        marginTop: 0,

        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 6",
            justifyContent: "flex-start",
            marginBottom: 0,
        },
    },
    searchBox: {
        gridColumn: "1 / span 12",
        justifyContent: "flex-end",
        border: "none",
        borderRadius: "20px",
        padding: "6px 0px",
        width: "100%",
        height: 35,

        [theme.breakpoints.up("md")]: {
            gridColumn: "7 / span 12",
            marginLeft: 0,
            marginTop: 0,
        },
    },
    contentRoot: (props: PopinProps) => ({
        padding: "24px 9px",
        paddingLeft: "9px",
        paddingTop: 0,

        [theme.breakpoints.up("sm")]: {
            padding: "26px 30px",
            paddingLeft: props?.contentBoxPaddingLeft ?? "30px",
            paddingTop: 0,
        },
    }),
    containerBoxContent: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        width: "calc(100% - 10px)",
        gridColumn: "1 / span 12",
        overflowY: "scroll",
        overflowX: "hidden",
        paddingRight: "10px",

        [theme.breakpoints.up("sm")]: {
            justifyContent: "flex-start",
            flexDirection: "row",
            width: "calc(100% - 20px)",
            paddingRight: "30px",
        },

        "&::-webkit-scrollbar": { width: "8px", height: "8px" },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(99,45,62,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(99,45,62,0.00)",
            paddingRight: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.primary.main,
            borderRadius: "20px",
        },
    },
}));

export const SearchModal = (props: PopinProps) => {
    const styles = useStyle(props);

    return (
        <Modal
            className={styles.modal}
            open={props.open}
            disableEscapeKeyDown={props.actionNeeded || props.disableEscapeKeyDown}
            style={props?.styles ?? {}}
        >
            <Box
                className={styles.cardContainer}
                maxWidth={props.width}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <Card className={styles.card} variant="outlined">
                    <img
                        src={cross}
                        onClick={() => {
                            props?.onCloseCross();
                        }}
                        className={styles.cross}
                    />

                    <CardContent className={styles.cardContent}>
                        <Box className={styles.childrenContainer} style={props.childrenBoxStyle}>
                            <div ref={props.headerRef} className={styles.grid}>
                                <Box className={styles.top}>
                                    <Box className={styles.title}>
                                        <Typography variant="h3" className={styles.titlePrimary}>
                                            {props?.titlePrimary}
                                        </Typography>
                                        <Typography variant="h3" className={styles.titleSecondary}>
                                            {props?.titleSecondary}
                                        </Typography>
                                    </Box>

                                    {props?.switch && <Box className={styles.switchBox}>{props?.switch}</Box>}
                                </Box>
                            </div>

                            <Box className={styles.contentRoot}>
                                <Box className={[styles.containerBoxContent, props?.boxContentClassname]?.join(" ")}>
                                    {props.children}
                                </Box>
                            </Box>

                            <div ref={props.footerRef} className={styles.cta}>
                                {/* CTA */}
                                <Box className={styles.resetBox}>
                                    <ModalResetFilters
                                        onClick={props?.CTAPrimaryClick}
                                        disabled={!!props?.CTAPrimaryDisabled}
                                    />
                                </Box>

                                <Box className={styles.searchBox}>
                                    <ModalSearchFilters
                                        onClick={() => props?.onClose()}
                                        label={
                                            <Plural
                                                value={props?.totalCount ?? 0}
                                                one="Rechercher (# résultat)"
                                                other="Rechercher (# résultats)"
                                                _0="Rechercher (# résultat)"
                                            />
                                        }
                                    />
                                </Box>
                            </div>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </Modal>
    );
};
