import { bool } from "prop-types";
import { useParams, Redirect } from "react-router-dom";
import { useEffect } from "react";

import { useFrontQrcodeRedirectQuery } from "../../generated/graphql";
import Loading from "Components/Loader/Loading";

export const RedirectPage = ({ nutri }) => {
    const { vintageId, wineId } = useParams();

    const { loading, data } = useFrontQrcodeRedirectQuery({
        variables: { vintageId },
    });

    useEffect(() => {
        if (data?.redirect) {
            window.location.replace(data?.redirect);
        }
    }, [data]);

    if (loading) return <Loading />;

    if (data?.redirect) return null;

    return <Redirect to={`/qr/${wineId}/${vintageId}/${nutri ? "nutri" : ""}`} />;
};

RedirectPage.propTypes = {
    nutri: bool,
};
