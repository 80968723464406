import { Typography, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import theme from "../../globalTheme";
import climat from "../../assets/Wine/climatologie.svg";
import Popin from "../../Containers/Popin";
import { parseHtml } from "../../helpers/helpers";
import { t } from "@lingui/macro";

const ClimatologyPopin = (props) => {
    const getClimatology = () => {
        if (props.globalClimatology) {
            return <Typography>{parseHtml(props.globalClimatology)}</Typography>;
        } else if (props.detailedClimatology) {
            return props.detailedClimatology?.map((el) => {
                if (el?.text) {
                    return (
                        <Box style={{ marginBottom: theme.spacing(2) }}>
                            <Box
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <img src={el?.logo?.data} alt="climat_image" style={{ marginRight: "14px" }} />
                                <Typography
                                    variant="h5"
                                    style={{
                                        color: theme.palette.primary.light,
                                    }}
                                >
                                    {el?.season === "Ete" ? " Été" : el?.season}
                                </Typography>
                            </Box>
                            <Typography variant="h6" style={{ fontSize: "16px", color: theme.palette.primary.dark }}>
                                {parseHtml(el?.text)}
                            </Typography>
                        </Box>
                    );
                }
            });
        }
    };

    return (
        <Popin
            title={
                <Box
                    style={{
                        gap: "8px",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "22px",
                        marginRight: "15px",
                    }}
                >
                    <img src={climat} alt="climat_image" style={{ width: 50 }} />
                    <Typography variant="h3">{t`Climatologie du millésime`}</Typography>
                </Box>
            }
            background={4}
            open={props.modalOpen}
            cross
            onClose={props.handleClose}
        >
            <Box style={{ borderRadius: "10px", maxWidth: "600px", color: theme.palette.primary.dark }}>
                {getClimatology()}
            </Box>
        </Popin>
    );
};

ClimatologyPopin.propTypes = {
    modalOpen: PropTypes.bool,
    handleClose: PropTypes.func,
    globalClimatology: PropTypes.string,
    detailedClimatology: PropTypes.array,
};

export default ClimatologyPopin;
