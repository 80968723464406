import { Plural, t, Trans } from "@lingui/macro";
import { Badge, Box, makeStyles, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import Page500Front from "Components/ErrorPage/Page500Front";
import { useEffect, useState } from "react";

import bg from "../../assets/searchEngine/activity/bg-activity-step-1.jpg";
import noResultImg from "../../assets/searchEngine/no-result.svg";
import Arrow from "../../Components/Arrow/Arrow";
import BreadCrumbs from "../../Components/Breadcrumbs/BreadCrumbs";
import SelectFilterPerPage from "../../Components/Inputs/SelectInputFilter";
import SimpleInput from "../../Components/Inputs/SimpleInput";
import BigSwitch from "../../Components/Switch/BigSwitch";
import { useSearchActivitiesQuery } from "../../generated/graphql";
import theme from "../../globalTheme";
import { useWindowDimensions } from "../../helpers/helpers";
import { useSearch } from "../../hooks";
import SearchResultActivityCard from "../../Search/card/SearchResultActivityCard";
import SearchActivityModal from "../../Search/modal/SearchActivityModal";
import SearchMainContainer from "../../Search/SearchMainContainer";
import SearchTabs from "../../Search/SearchTabs";
import SelectButtonOrCheckbox from "../../Search/SelectButtonOrCheckbox";
import SelectWithCount from "../../Search/SelectWithCount";
import ButtonMoreFilters from "../../Search/ui/ButtonMoreFilters";
import ButtonResetFilters from "../../Search/ui/ButtonResetFilters";

const useStyles = makeStyles(() => ({
    root: {
        "&::after": {
            // eslint-disable-next-line quotes
            content: '""',
            position: "absolute",
            zIndex: -1,
            top: "-5rem",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundImage: `url(${bg})`,
            backgroundRepeat: "no-repeat",
            backgroundPositionY: "top",
            backgroundPositionX: "center",
            backgroundSize: "cover",
        },

        gridTemplateColumns: "repeat(12, 1fr)",
        display: "grid",
        minHeight: "calc(100vh - 5rem)",
    },
    main_container: {
        gridColumn: "1 / span 12",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "22px 0 2rem",

        [theme.breakpoints.up("sm")]: {
            padding: "22px 1rem 2rem",
            paddingTop: 80,
        },

        [theme.breakpoints.up("md")]: {
            paddingTop: 80,
        },

        [theme.breakpoints.up("xl")]: {
            width: "calc(1314px)",
            margin: "auto",
        },
    },
    title: {
        color: theme.palette.common.white,
        fontWeight: 400,
        textAlign: "center",
        marginBottom: "1rem",
    },
    headerContainer: {
        gridColumn: "1 / span 12",
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        gap: "7px 6px",
        marginBottom: 13,

        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 4",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 3",
        },

        [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "repeat(18, 1fr)",
        },
    },
    search: {
        display: "none",

        [theme.breakpoints.up("sm")]: {
            display: "block",
            gridColumn: "1 / span 8",
            gridRow: "1 / span 1",
        },

        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 3",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 5",
        },
    },
    category: {
        display: "none",
        position: "relative",

        [theme.breakpoints.up("md")]: {
            display: "block",
            gridColumn: "auto / span 2",
            gridRow: "1 / span 1",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 2",
            gridRow: "1 / span 1",
        },
    },
    lang: {
        display: "none",
        position: "relative",

        [theme.breakpoints.up("md")]: {
            display: "block",
            gridColumn: "auto / span 2",
            gridRow: "1 / span 1",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 2",
            gridRow: "1 / span 1",
        },
    },
    nbPerson: {
        display: "none",
        position: "relative",

        [theme.breakpoints.up("md")]: {
            display: "block",
            gridColumn: "auto / span 3",
            gridRow: "1 / span 1",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 3",
            gridRow: "1 / span 1",
        },
    },
    private: {
        display: "none",
        position: "relative",

        [theme.breakpoints.up("md")]: {
            display: "block",
            gridColumn: "auto / span 2",
            gridRow: "1 / span 1",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 2",
            gridRow: "1 / span 1",
        },
    },
    privateBox: {
        display: "flex",
        flexDirection: "row-reverse",
        alignItems: "center",
        justifyContent: "space-between",
        border: `0.4px solid ${theme.palette.secondary.main}`,
        borderRadius: "53px",
        height: "32px",
        paddingLeft: 6,
        paddingRight: 10,
        cursor: "pointer",
        "-webkit-tap-highlight-color": "transparent",

        [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
            justifyContent: "flex-start",
        },

        [theme.breakpoints.up("lg")]: {
            paddingRight: 5,
        },
    },
    btnFilters: {
        position: "relative",
        gridColumn: "1 / span 6",
        gridRow: "1 / span 1",
        width: "100%",

        [theme.breakpoints.up("sm")]: {
            gridColumn: "11 / span 5",
        },

        [theme.breakpoints.up("md")]: {
            gridColumn: "12 / span 4",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "14 / span 2",
        },
    },
    badgeNotif: {
        position: "absolute",
        right: 10,
        top: 4,
        border: "none",
        padding: "0 4px",

        "& .MuiBadge-badge": {
            fontWeight: 700,
            background: theme.palette.primary.dark,
            color: theme.palette.common.white,
        },
    },
    badgeSearchAdvanced: {
        top: 6,

        [theme.breakpoints.down("md")]: {
            right: -7,
        },
    },
    result: {
        display: "none",
        justifyContent: "center",

        [theme.breakpoints.up("sm")]: {
            display: "flex",
            gridColumn: "15 / span 4",
            gridRow: "1 / span 1",
        },

        [theme.breakpoints.up("lg")]: {
            display: "flex",
            gridColumn: "15 / span 4",
        },
    },
    filterResultBox: {
        gridColumn: "1 / span 18",
        gridRow: "3 / span 1",
        display: "flex",
        alignItems: "flex-start",
    },
    deleteFiltersBox: {
        display: "flex",
        justifyContent: "flex-end",
    },
    separator: {
        gridColumn: "1 / span 18",
        gridRow: "2 / span 1",
        width: "100%",
        height: 0.4,
        backgroundColor: theme.palette.secondary.main,
    },
    nbResultTypo: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        marginTop: 0,
    },
    resultType: {
        color: theme.palette.secondary.dark,
        fontWeight: 400,
    },
    noResultImageBox: {
        gridColumn: "1 / span 18",
        position: "relative",
        display: "flex",
        alignItems: "flex-start",

        [theme.breakpoints.up("sm")]: {
            minHeight: 130,
            alignItems: "flex-end",
        },

        [theme.breakpoints.up("md")]: {
            minHeight: 170,
            alignItems: "flex-end",
            marginBottom: 24,
        },
    },
    noResultImg: {
        display: "none",

        [theme.breakpoints.up("sm")]: {
            display: "block",
            position: "absolute",
            width: 96,
            top: 0,
        },

        [theme.breakpoints.up("md")]: {
            width: 130,
            left: 77,
        },

        [theme.breakpoints.up("xl")]: {
            width: 145,
            left: 77,
        },
    },
    noResultTypoBox: {
        background: "#FCF6F6",
        padding: "13px 16px",
        width: "100%",

        [theme.breakpoints.up("sm")]: {
            padding: "17px 16px 26px 96px",
        },

        [theme.breakpoints.up("md")]: {
            padding: "22px 16px 22px",
            paddingLeft: "calc(96px + 130px)",
            marginBottom: 10,
        },

        [theme.breakpoints.up("xl")]: {
            padding: "22px 16px 22px",
            paddingLeft: "calc(96px + 145px)",
            marginBottom: 0,
        },
    },
    noResultTitle: {
        color: theme.palette.secondary.dark,
        width: "100%",
        textAlign: "left",

        [theme.breakpoints.up("sm")]: {
            maxWidth: 536,
        },

        [theme.breakpoints.up("md")]: {
            maxWidth: "90%",
        },

        [theme.breakpoints.up("lg")]: {
            maxWidth: "70%",
        },

        [theme.breakpoints.up("xl")]: {
            maxWidth: "70%",
        },
    },
    noResultContentBox: {
        gridColumn: "1 / span 18",
        // gridRow: "4 / span 1",
        display: "flex",
        flexDirection: "column",
        marginTop: 19,

        [theme.breakpoints.up("sm")]: {
            gridColumn: "1 / span 18",
            gridRow: "5 / span 1",
        },

        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 13",
            gridRow: "5 / span 1",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 10",
            gridRow: "5 / span 1",
        },
    },
    noResultContent: {
        color: theme.palette.common.black,
        fontWeight: 700,
    },
    perPageFilterResult: {
        gridColumn: "auto / span 12",
        gridRow: "1 / span 1",
        marginTop: 0,

        [theme.breakpoints.up("sm")]: {
            gridColumn: "15 / span 4",
            gridRow: "3 / span 1",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "15 / span 4",
            gridRow: "3 / span 1",
        },
    },
    containerCards: {
        display: "grid",
        gridTemplateColumns: "repeat(1, 1fr)",
        gap: 20,

        [theme.breakpoints.up("sm")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
        },

        [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "repeat(3, 1fr)",
        },

        [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "repeat(4, 1fr)",
        },
    },
    card: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
    },
    pagination: {
        gridColumn: "1 / span 12",
        display: "flex",
        justifyContent: "flex-end",

        "& .MuiPaginationItem-page.Mui-selected": {
            color: theme.palette.primary.light,
            border: `1px solid ${theme.palette.primary.light}`,
            height: "fit-content",
            backgroundColor: "inherit",
            fontWeight: 700,
        },
    },

    searchAdvancedBox: {
        marginRight: 40,
        position: "relative",
        gridColumn: "16 / span 3",
        gridRow: "1 / span 1",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 3,

        [theme.breakpoints.up("md")]: {
            gridColumn: "16 / span 2",
            marginRight: 0,
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "17 / span 2",
        },
    },
    searchAdvancedBtn: {
        background: "transparent",
        border: "none",
        color: theme.palette.primary.dark,
        textDecoration: "underline",
        cursor: "pointer",
        padding: 0,
        display: "flex",
        justifyContent: "center",
    },
    breadcrumbs: {
        paddingLeft: "1rem",
    },
}));

const SearchResultQrcode = () => {
    const styles = useStyles();
    const { width } = useWindowDimensions();
    const [modalIsShown, setModalIsShown] = useState(false);

    const [pageSize, setPageSize] = useState(12);
    const [searchTextDisplay, setSearchTextDisplay] = useState("");

    const {
        results,
        filters,
        setFilter,
        resetFilters,
        totalCount,
        filtersCount,
        pageNumber,
        setPageNumber,
        similar,
        errors,
    } = useSearch(
        {
            searchText: null,
            communes: [],
            categories: [],
            languages: [],
            accessibleTo: [],
            payments: [],
            tags: [],
            nbPersons: 0,
            priceRange: [1, 201],
            privateActivity: false,
            parkingBus: false,
            durations: [],
        },
        useSearchActivitiesQuery,
        "SearchActivities",
        pageSize
    );

    useEffect(() => {
        setSearchTextDisplay(filters?.searchText);
    }, [filters?.searchText]);

    const [displayedSimilar, setDisplayedSimilar] = useState(similar);

    const [displayedResults, setDisplayedResults] = useState(results);
    const [displayedTotalCount, setDisplayedTotalCount] = useState(totalCount);

    const trueResults = modalIsShown ? displayedResults : results;
    const trueSimilar = modalIsShown ? displayedSimilar : similar;
    const trueTotalCount = modalIsShown ? displayedTotalCount : totalCount;
    const [textFocused, setTextFocused] = useState(false);

    const getStringNumResults = () => {
        const c = trueTotalCount;
        if (trueTotalCount > 0) {
            return <Plural value={c} one="# résultat trouvé" other="# résultats trouvés" />;
        } else {
            return t`Aucun résultat trouvé`;
        }
    };

    const breadcrumbs = [
        {
            name: t`Recherche`,
            path: "/recherche",
        },
        {
            name: t`Activités`,
            path: "/recherche/activites",
        },
        {
            name: t`Résultats`,
        },
    ];

    if (errors) {
        return <Page500Front />;
    }
    return (
        <>
            <Box className={styles.root}>
                <BreadCrumbs links={breadcrumbs} className={styles.breadcrumbs} />
                <Box className={styles.main_container}>
                    <Typography variant="h2" className={styles.title}>
                        <Trans>Parcourez le vignoble bordelais</Trans>
                    </Typography>
                    <SearchTabs active="activity" redirectToResult />
                    <SearchMainContainer>
                        <Box className={styles.headerContainer}>
                            <Box className={styles.search}>
                                <SimpleInput
                                    onFocus={() => {
                                        setTextFocused(true);
                                    }}
                                    onChange={(e) => {
                                        setSearchTextDisplay(e?.target?.value);
                                    }}
                                    value={searchTextDisplay}
                                    search
                                    borderWidth={0}
                                    size="small"
                                    name={"search"}
                                    placeholder={
                                        width >= 960 && width < 1280
                                            ? "Chercher une activité..."
                                            : t`Chercher une activité, une ville...`
                                    }
                                    variant="outlined"
                                    onBlur={(e) => {
                                        if (textFocused) {
                                            setFilter("searchText", e?.target?.value);
                                        }
                                    }}
                                    boxStyle={{
                                        backgroundColor: theme.palette.common.white,
                                        borderRadius: "53px",
                                        border: "none",
                                    }}
                                    bgTransparent
                                    borderRadius={53}
                                    iconPosition="start"
                                />
                            </Box>

                            <Box className={styles.category}>
                                <Badge badgeContent={filters?.categories?.length} className={styles.badgeNotif} />
                                <SelectButtonOrCheckbox
                                    enumField={"ACTIVITY_TYPE"}
                                    setFilters={(categories) => setFilter("categories", categories)}
                                    hideNbInSelect
                                    label={t`Catégorie`}
                                    filter={filters?.categories}
                                    filterName="categories"
                                />
                            </Box>

                            <Box className={styles.lang}>
                                <Badge badgeContent={filters?.languages?.length} className={styles.badgeNotif} />
                                <SelectButtonOrCheckbox
                                    enumField={"LANGUAGES"}
                                    setFilters={(languages) => setFilter("languages", languages)}
                                    hideNbInSelect
                                    label={t`Langue`}
                                    filter={filters?.languages}
                                    filterName="languages"
                                />
                            </Box>

                            <Box className={styles.nbPerson}>
                                {/* <Badge badgeContent={filters?.nbPersons > 0 ? 1 : 0} className={styles.badgeNotif} /> */}
                                {/* <SelectInputSlide
                                    label={
                                        filters?.nbPersons > 0
                                            ? `${filters?.nbPersons === 200 ? "+ " : ""} ${filters?.nbPersons} ${
                                                  filters?.nbPersons > 1 ? "personnes" : "personne"
                                              }`
                                            : "Nb de personnes"
                                    }
                                    filter={filters?.nbPersons}
                                    filterName="nbPersons"
                                    valueSlide={filters?.nbPersons}
                                    setValueSlide={(v) => setFilter("nbPersons", v)}
                                /> */}

                                <SelectWithCount
                                    label={
                                        filters?.nbPersons > 0
                                            ? `${filters?.nbPersons === 200 ? "+ " : ""} ${filters?.nbPersons} ${
                                                  filters?.nbPersons > 1 ? "personnes" : "personne"
                                              }`
                                            : "Nb de personnes"
                                    }
                                    filter={filters?.nbPersons}
                                    filterName="nbPersons"
                                    valueSlide={filters?.nbPersons}
                                    setValueSlide={(v) => setFilter("nbPersons", v)}
                                />
                            </Box>

                            <Box className={styles.private}>
                                <Badge
                                    badgeContent={filters?.privateActivity ? 1 : 0}
                                    className={styles.badgeNotif}
                                    style={{ top: 4 }}
                                />
                                <Box
                                    className={styles.privateBox}
                                    onClick={() => setFilter("privateActivity", !filters?.privateActivity)}
                                    style={{
                                        borderColor: filters?.privateActivity
                                            ? theme.palette.primary.dark
                                            : theme.palette.secondary.main,
                                        backgroundColor: filters?.privateActivity
                                            ? theme.palette.background.card
                                            : theme.palette.common.white,
                                    }}
                                >
                                    <BigSwitch
                                        checked={filters?.privateActivity}
                                        onChange={() => setFilter("privateActivity", !filters?.privateActivity)}
                                    />
                                    <Typography
                                        variant="h6"
                                        style={{ marginLeft: 7, color: theme.palette.primary.dark }}
                                    >
                                        <Trans>Privatisée</Trans>
                                    </Typography>
                                </Box>
                            </Box>

                            {width < 600 ? (
                                <>
                                    <Box className={styles.btnFilters}>
                                        <Badge badgeContent={filtersCount} className={styles.badgeNotif} />
                                        <ButtonMoreFilters
                                            onClick={() => {
                                                setDisplayedResults(results);
                                                setDisplayedTotalCount(totalCount);
                                                setDisplayedSimilar(similar);

                                                setModalIsShown(true);
                                            }}
                                        />
                                    </Box>
                                    {trueResults?.length && !trueSimilar ? (
                                        <SelectFilterPerPage
                                            searchOptions
                                            label="résultats"
                                            options={[12, 24, 48, 96]}
                                            value={pageSize}
                                            handleChange={(e) => {
                                                setPageSize(Number(e.target.value));
                                            }}
                                            className={styles.perPageFilterResult}
                                        />
                                    ) : null}
                                </>
                            ) : null}

                            {width >= 600 ? (
                                <Box className={styles.searchAdvancedBox}>
                                    <Badge
                                        badgeContent={filtersCount}
                                        className={[styles.badgeNotif, styles.badgeSearchAdvanced].join(" ")}
                                    />
                                    <button
                                        className={styles.searchAdvancedBtn}
                                        onClick={() => {
                                            setDisplayedResults(results);
                                            setDisplayedTotalCount(totalCount);
                                            setDisplayedSimilar(similar);

                                            setModalIsShown(true);
                                        }}
                                    >
                                        <Typography variant="subtitle1">
                                            <Trans>Recherche avancée</Trans>
                                        </Typography>
                                    </button>
                                </Box>
                            ) : null}

                            <Box className={styles.separator} />

                            <Box className={styles.filterResultBox}>
                                <Box className={styles.deleteFiltersBox}>
                                    <ButtonResetFilters
                                        onClick={() => {
                                            resetFilters();
                                        }}
                                        disabled={filtersCount === 0}
                                    />
                                </Box>

                                <Box className={styles.nbResultTypo}>
                                    <Typography
                                        variant="subtitle1"
                                        className={styles.resultType}
                                        style={{ marginLeft: "5px" }}
                                    >
                                        {getStringNumResults()}
                                    </Typography>
                                </Box>
                                {width >= 600 && trueResults?.length && !trueSimilar ? (
                                    <Box
                                        style={{
                                            display: "flex",
                                            flexDirection: "row-reverse",
                                            marginLeft: "auto",
                                        }}
                                    >
                                        <SelectFilterPerPage
                                            searchOptions
                                            label="résultats"
                                            options={[12, 24, 48, 96]}
                                            value={pageSize}
                                            handleChange={(e) => {
                                                setPageSize(Number(e.target.value));
                                            }}
                                            className={styles.perPageFilterResult}
                                        />
                                    </Box>
                                ) : null}
                            </Box>

                            {(!trueResults?.length || trueSimilar) && (
                                <>
                                    <Box className={styles.noResultImageBox}>
                                        <img
                                            src={noResultImg}
                                            className={styles.noResultImg}
                                            alt="image aucun résultat"
                                        />
                                        <Box className={styles.noResultTypoBox}>
                                            <Typography variant="h3" className={styles.noResultTitle}>
                                                <Trans>
                                                    Aucun résultat respectant tous vos critères de recherche n’a été
                                                    trouvé.
                                                </Trans>
                                            </Typography>
                                        </Box>
                                    </Box>

                                    {trueResults?.length && trueSimilar ? (
                                        <Box className={styles.noResultContentBox}>
                                            <Typography variant="h4" className={styles.noResultContent}>
                                                <Plural
                                                    value={trueTotalCount}
                                                    one="Découvrez le résultat qui correspond au plus près à votre recherche"
                                                    other="Découvrez les résultats qui correspondent au plus près à votre recherche"
                                                />
                                            </Typography>
                                            {trueTotalCount > 1 ? (
                                                width > 600 ? (
                                                    <Arrow
                                                        rotation="105"
                                                        arrowPosition="start"
                                                        style={{ height: "100%", marginLeft: "0" }}
                                                        icon="primary"
                                                        textBoxStyle={{
                                                            marginLeft: 0,
                                                            marginRight: "8px",
                                                            marginTop: "2px",
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="h6"
                                                            style={{
                                                                fontFamily: "Mansalva",
                                                                color: theme.palette.primary.dark,
                                                            }}
                                                        >
                                                            <Trans>
                                                                Chacune des activités ci-dessous présente un seul
                                                                critère non respecté ou non précisé par le producteur.
                                                            </Trans>
                                                        </Typography>
                                                    </Arrow>
                                                ) : (
                                                    <Typography
                                                        variant="h6"
                                                        style={{
                                                            fontFamily: "Mansalva",
                                                            color: theme.palette.primary.dark,
                                                        }}
                                                    >
                                                        <Trans>
                                                            Chacune des activités ci-dessous présente un seul critère
                                                            non respecté ou non précisé par le producteur.
                                                        </Trans>
                                                    </Typography>
                                                )
                                            ) : null}
                                        </Box>
                                    ) : null}
                                </>
                            )}
                        </Box>
                        {trueSimilar ? (
                            <Box
                                style={{
                                    display: "flex",
                                    marginLeft: "auto",
                                    flexDirection: "row-reverse",
                                    marginBottom: 1,
                                }}
                            >
                                <Box>
                                    {trueResults?.length ? (
                                        <SelectFilterPerPage
                                            searchOptions
                                            label="résultats"
                                            options={[12, 24, 48, 96]}
                                            value={pageSize}
                                            handleChange={(e) => {
                                                setPageSize(Number(e.target.value));
                                            }}
                                            className={styles.perPageFilterResult}
                                        />
                                    ) : null}
                                </Box>
                            </Box>
                        ) : null}
                        <Box className={styles.containerCards}>
                            {trueResults?.map((activity, i) => (
                                <SearchResultActivityCard key={i} activity={activity} />
                            ))}
                        </Box>
                        {trueResults?.length > 0 ? (
                            <Box className={styles.pagination}>
                                <Pagination
                                    onChange={(_, n) => {
                                        setPageNumber(n);
                                    }}
                                    page={pageNumber}
                                    count={trueTotalCount ? Math.floor(trueTotalCount / pageSize) + 1 : null}
                                    showFirstButton
                                    showLastButton
                                    className={styles.pagination}
                                    shape="rounded"
                                    size="small"
                                />
                            </Box>
                        ) : null}
                    </SearchMainContainer>
                </Box>
            </Box>

            <SearchActivityModal
                filtersCount={filtersCount}
                similar={similar}
                searchTextDisplay={searchTextDisplay}
                isOpen={modalIsShown}
                onClose={() => setModalIsShown(false)}
                titlePrimary={t`Tous les filtres`}
                titleSecondary={t`Activités`}
                filters={filters}
                setFilter={(fieldname, v) => setFilter(fieldname, v)}
                resetFilters={() => resetFilters()}
                totalCount={totalCount}
                // setFilters={setFilters}
                // nbFilters={nbFilters}
                // setNbFilters={setNbFilters}
                // searchText={searchText}
                // setSearchText={setSearchText}
            />
        </>
    );
};

export default SearchResultQrcode;
