import { bool, oneOf, shape, string } from "prop-types";
import { Box, Card, CardContent, makeStyles, Typography } from "@material-ui/core";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import BaseButton from "../../Components/Buttons/BaseButton";
import { useCreateNewSubscriptionDataMutation } from "generated/graphql";
import { FRONT_URL, RECURRENCE_TYPES, SUBSCRIPTION_TYPES, SUBSCRIPTION_DATA_STATUS } from "../../config/constants";
import { getUserId, isOffline } from "helpers/helpers";
import { buildSubscritionDataInput } from "Routes/SubscriptionRouter/helper";
import Loading from "Components/Loader/Loading";
import { signOut } from "features/user/userSlice";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        justifyContent: "center",
        marginTop: "1rem",
    },
    card: {
        width: "673px",
        borderRadius: "20px",
    },
    cardHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    cardContent: {
        padding: "1.75rem 4rem",
    },
    title: {
        color: theme.palette.primary.dark,
    },
    icon: {
        fontSize: theme.typography.h3.fontSize,
        color: theme.palette.primary.dark,
        marginRight: "1rem",
    },
    legend: {
        textAlign: "center",
        marginBottom: "2.5rem",
        marginTop: "8px",
    },
    automaticLogout: {
        textAlign: "center",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "22px",
        marginBottom: "2rem",
    },
}));

const BasicRegistration = ({ subscriptionState, subscriptionData, loading, recurrence }) => {
    const styles = useStyles();
    const [newSubscriptionDataMutation, { data }] = useCreateNewSubscriptionDataMutation();
    const userId = useSelector(getUserId);
    const dispatch = useDispatch();

    const handleSubscriptionTunnelMutation = async () => {
        if (userId) {
            const input = buildSubscritionDataInput(userId, subscriptionState, recurrence);
            newSubscriptionDataMutation({ variables: { input } });
        }
    };

    useEffect(() => {
        if (!loading && !subscriptionData) {
            handleSubscriptionTunnelMutation();
        }
    }, [loading, subscriptionData]);

    const subscriptionCreated = useMemo(() => subscriptionData || (!loading && !subscriptionData && data), [
        loading,
        subscriptionData,
        data,
    ]);

    useEffect(() => {
        if (subscriptionCreated) {
            const handle = setTimeout(() => {
                dispatch(signOut());
            }, 20000);
            return () => clearTimeout(handle);
        }
    }, [subscriptionCreated]);

    if (loading) {
        return <Loading />;
    }

    const subscriptionType = subscriptionData?.subscriptionType ?? subscriptionState?.selectedPack;
    const paymentMethod = subscriptionData?.paymentMethod ?? subscriptionState?.paymentMethod;

    return (
        <Box className={styles.container}>
            <Card className={styles.card}>
                <CardContent className={styles.cardContent}>
                    <Box className={styles.cardHeader}>
                        <CheckCircleOutlineRoundedIcon className={styles.icon} />
                        <Typography className={styles.title} variant="h3">
                            Votre inscription est enregistrée
                        </Typography>
                    </Box>
                    <Typography variant="h5" className={styles.legend}>
                        {isOffline(paymentMethod) && subscriptionType !== SUBSCRIPTION_TYPES.basic ? (
                            <>
                                Nous avons enregistré votre inscription et votre demande de paiement
                                &quot;hors-ligne&quot;.
                                <br />
                                Nos experts reviendront rapidement vers vous afin de vous informer des modalités selon
                                le mode de paiement choisi.
                            </>
                        ) : (
                            "Vous recevrez un e-mail dès qu'elle sera validée, vous pourrez alors accéder à votre tableau de bord et gérer vos informations."
                        )}
                    </Typography>
                    <Typography className={styles.automaticLogout}>
                        Vous allez automatiquement être deconnecté dans quelques instants.
                    </Typography>
                    <BaseButton
                        onClick={() => {
                            if (typeof window !== "undefined") {
                                window.location.href = `${FRONT_URL}/recherche`;
                            }
                        }}
                        boxStyle={{ textAlign: "center" }}
                        variant="contained"
                        color="primary"
                        size="small"
                    >
                        Accéder à bordeaux.feret.com
                    </BaseButton>
                </CardContent>
            </Card>
        </Box>
    );
};

const addressType = {
    address: string,
    complementaryAddress: string,
    commune: string,
    postalCode: string,
    country: string,
};

const billingInformationType = {
    siret: string,
    organizationStatus: string,
    organizationName: string,
    tvaNumber: string,
    title: string,
    firstName: string,
    lastName: string,
    email: string,
    telephoneNumber: string,
};

const subscriptionDataType = shape({
    userId: string,
    wineEntityName: string,
    mainCommune: string,
    mainCommuneInseeCode: string,
    address: addressType,
    billingAddress: addressType,
    billingInformation: billingInformationType,
    email: string,
    telephone: string,
    orphanWineEntityId: string,
    isOwner: string,
    isOperator: string,
    status: oneOf(Object.values(SUBSCRIPTION_DATA_STATUS)),
    organizationName: string,
    mainAppellation: string,
    siret: string,
    organizationStatus: string,
    subscriptionName: oneOf(Object.values(SUBSCRIPTION_TYPES)),
    paymentMethod: string,
    addons: string,
    recurrence: oneOf(Object.values(RECURRENCE_TYPES)),
});

BasicRegistration.propTypes = {
    subscriptionState: subscriptionDataType,
    subscriptionData: subscriptionDataType,
    recurrence: oneOf(Object.values(RECURRENCE_TYPES)),
    loading: bool,
};

export default BasicRegistration;
