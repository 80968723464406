import { makeStyles } from "@material-ui/core";
import globalTheme from "globalTheme";

export const useStyles = makeStyles(() => ({
    form_card: {
        backgroundColor: globalTheme.palette.background.card,
        borderRadius: "20px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    grid_content: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        columnGap: "1rem",
    },
    section_title: {
        color: globalTheme.palette.primary.main,
        marginBottom: "1rem",
        [globalTheme.breakpoints.up("xs")]: {
            gridColumn: "1 / span 12",
        },
        [globalTheme.breakpoints.up("sm")]: {
            gridColumn: "1 / span 12",
        },
        [globalTheme.breakpoints.up("md")]: {
            gridColumn: "1 / span 12",
        },
        [globalTheme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 12",
        },
        [globalTheme.breakpoints.up("xl")]: {
            gridColumn: "1 / span 12",
        },
    },
    title_container: {
        marginBottom: "1rem",
        [globalTheme.breakpoints.up("xs")]: {
            gridColumn: "1 / span 12",
        },
        [globalTheme.breakpoints.up("sm")]: {
            gridColumn: "1 / span 2",
        },
        [globalTheme.breakpoints.up("md")]: {
            gridColumn: "1 / span 2",
        },
        [globalTheme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 2",
        },
        [globalTheme.breakpoints.up("xl")]: {
            gridColumn: "1 / span 3",
        },
    },
    firstName_container: {
        marginBottom: "1rem",
        [globalTheme.breakpoints.up("xs")]: {
            gridColumn: "1 / span 12",
        },
        [globalTheme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 5",
        },
        [globalTheme.breakpoints.up("md")]: {
            gridColumn: "auto / span 5",
        },
        [globalTheme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 5",
        },
        [globalTheme.breakpoints.up("xl")]: {
            gridColumn: "auto / span 4",
        },
    },
    lastName_container: {
        marginBottom: "1rem",
        [globalTheme.breakpoints.up("xs")]: {
            gridColumn: "1 / span 12",
        },
        [globalTheme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 5",
        },
        [globalTheme.breakpoints.up("md")]: {
            gridColumn: "auto / span 5",
        },
        [globalTheme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 5",
        },
        [globalTheme.breakpoints.up("xl")]: {
            gridColumn: "auto / span 5",
        },
    },
    company_container: {
        marginBottom: "1rem",
        [globalTheme.breakpoints.up("xs")]: {
            gridColumn: "1 / span 12",
        },
        [globalTheme.breakpoints.up("sm")]: {
            gridColumn: "1 / span 12",
        },
        [globalTheme.breakpoints.up("md")]: {
            gridColumn: "1 / span 12",
        },
        [globalTheme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 12",
        },
        [globalTheme.breakpoints.up("xl")]: {
            gridColumn: "1 / span 12",
        },
    },
    email_container: {
        [globalTheme.breakpoints.up("xs")]: {
            gridColumn: "1 / span 12",
            marginBottom: "1rem",
        },
        [globalTheme.breakpoints.up("sm")]: {
            gridColumn: "1 / span 6",
            marginBottom: "0",
        },
        [globalTheme.breakpoints.up("md")]: {
            gridColumn: "1 / span 6",
        },
        [globalTheme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 6",
        },
        [globalTheme.breakpoints.up("xl")]: {
            gridColumn: "1 / span 6",
        },
    },
    phone_container: {
        [globalTheme.breakpoints.up("xs")]: {
            gridColumn: "1 / span 12",
        },
        [globalTheme.breakpoints.up("sm")]: {
            gridColumn: "auto/ span 6",
        },
        [globalTheme.breakpoints.up("md")]: {
            gridColumn: "auto/ span 6",
        },
        [globalTheme.breakpoints.up("lg")]: {
            gridColumn: "auto/ span 6",
        },
        [globalTheme.breakpoints.up("xl")]: {
            gridColumn: "auto/ span 6",
        },
    },
    asterisk_info: {
        color: globalTheme.palette.primary.main,
        fontFamily: "Mansalva",
        marginBottom: "1rem",
        [globalTheme.breakpoints.up("xs")]: {
            gridColumn: "1 / span 12",
        },
        [globalTheme.breakpoints.up("sm")]: {
            gridColumn: "1 / span 12",
        },
        [globalTheme.breakpoints.up("md")]: {
            gridColumn: "1 / span 12",
        },
        [globalTheme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 12",
        },
        [globalTheme.breakpoints.up("xl")]: {
            gridColumn: "1 / span 12",
        },
    },
    subject_container: {
        marginBottom: "1rem",
        [globalTheme.breakpoints.up("xs")]: {
            gridColumn: "1 / span 12",
        },
        [globalTheme.breakpoints.up("sm")]: {
            gridColumn: "1 / span 12",
        },
        [globalTheme.breakpoints.up("md")]: {
            gridColumn: "1 / span 12",
        },
        [globalTheme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 12",
        },
        [globalTheme.breakpoints.up("xl")]: {
            gridColumn: "1 / span 12",
        },
    },
    message_container: {
        [globalTheme.breakpoints.up("xs")]: {
            gridColumn: "1 / span 12",
        },
        [globalTheme.breakpoints.up("sm")]: {
            gridColumn: "1 / span 12",
        },
        [globalTheme.breakpoints.up("md")]: {
            gridColumn: "1 / span 12",
        },
        [globalTheme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 12",
        },
        [globalTheme.breakpoints.up("xl")]: {
            gridColumn: "1 / span 12",
        },
    },
    newsletter_container: {
        marginBottom: "1rem",
        [globalTheme.breakpoints.up("xs")]: {
            gridColumn: "1 / span 12",
        },
        [globalTheme.breakpoints.up("sm")]: {
            gridColumn: "1 / span 12",
        },
        [globalTheme.breakpoints.up("md")]: {
            gridColumn: "1 / span 12",
        },
        [globalTheme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 12",
        },
        [globalTheme.breakpoints.up("xl")]: {
            gridColumn: "1 / span 12",
        },
    },
    responsivePadding: {
        [globalTheme.breakpoints.up("xs")]: {
            padding: "1.25rem",
        },
        [globalTheme.breakpoints.up("sm")]: {
            padding: "1.25rem",
        },
        [globalTheme.breakpoints.up("md")]: {
            padding: "1.25rem",
        },
        [globalTheme.breakpoints.up("lg")]: {
            padding: "1.25rem 1rem",
        },
        [globalTheme.breakpoints.up("xl")]: {
            padding: "2.25rem",
        },
    },
}));
