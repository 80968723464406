import React from "react";
import { Box, Typography, Link, makeStyles } from "@material-ui/core";
import BaseButton from "../../Components/Buttons/BaseButton";
import Modal from "../../Containers/Modal";
import PropTypes from "prop-types";
import theme from "../../globalTheme";
import cga from "../../assets/popin/icones/cga.svg";
import { feretAddress, feretPostalCode } from "config/constants";
const useStyle = makeStyles(() => ({
    mainBox: {
        // overflowY: "scroll",
        // height: "65vh",
        width: "100%",

        "&::-webkit-scrollbar": { width: "8px", height: "8px" },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            paddingRight: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "20px",
        },
    },
    title: {
        textAlign: "center",
        marginBottom: theme.spacing(4),
    },

    secondText: {
        textAlign: "center",
        marginBottom: theme.spacing(3),
        fontSize: "18px",
        color: theme.palette.primary.dark,
        fontWeight: "bold",
    },
    buttonBox: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        flexWrap: "wrap",
        marginBottom: theme.spacing(2),
    },
    text: {
        marginBottom: theme.spacing(2),
        fontSize: "12px",
        color: theme.palette.primary.dark,
    },
    firstTryText: { fontSize: "12px", color: theme.palette.primary.dark },
}));

const CGAUPopin = (props) => {
    const styles = useStyle(props);

    return (
        <Modal
            width="50rem"
            open={props.modalOpen}
            onClose={props.handleClose}
            actionNeeded={false}
            title="Conditions générales d'abonnement et d'utilisation"
            icon={cga}
        >
            <Box className={styles.mainBox}>
                <Typography variant="h6" className={styles.secondText}>
                    Éditions Féret
                </Typography>
                <Typography variant="h6" className={styles.firstTryText}>
                    La <strong>SOCIÉTÉ DES ÉDITIONS FÉRET</strong>, (ci-après « l&apos;Éditeur » ou « FÉRET »), société
                    à responsabilité limitée immatriculée au Registre du Commerce et des Sociétés de Bordeaux sous le
                    numéro 388 807 174 ayant son siège social {feretAddress} - {feretPostalCode}, est un éditeur du vin
                    bordelais depuis 1812, ainsi qu&apos;un éditeur de solutions numériques en ligne.
                    <br />
                    <br />
                    Elle édite les sites web feret.com, bordeaux.feret.com et pro.feret.com dédiés aux professionnels du
                    vin (ci-après la &quot;Solution&quot;).
                    <br /> <br />
                    Les présentes Conditions Générales d&apos;Abonnement et d&apos;Utilisation s&apos;appliquent à tout{" "}
                    <strong>Utilisateur</strong> de la <strong>Solution</strong> sans restriction ni réserve. Elles sont
                    acceptées lors de toute souscription à un <strong>Abonnement</strong>.
                    <br /> <br />
                    Elles sont accessibles à tout moment sur la <strong>Solution</strong> et sur le site Internet de
                    l&apos;Éditeur, accessible à l&apos;adresse URL https://feret.com
                    <br /> <br />
                    La création ou l&apos;accès à un abonné, payant ou non, par quelque moyen que ce soit, induit
                    l&apos;acceptation pleine et totale des présentes conditions l&apos;utilisation.
                    <br /> <br />
                    La version en vigueur des Conditions Générales d&apos;Abonnement et d&apos;Utilisation, seule
                    opposable, est celle actuellement disponible en ligne, à l&apos;exclusion de toute autre version ou
                    document contradictoire.
                </Typography>
                <br />
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    1 – DÉFINITIONS
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Pour la bonne compréhension des présentes, les termes ci-dessous, commençant par une majuscule,
                    qu&apos;ils soient au singulier ou au pluriel, auront la signification suivante :{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    - « <strong>Abonné</strong> » ou « <strong>Client</strong> » désigne toute personne physique ou
                    morale abonnée à la Solution
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    - « <strong>Abonnement</strong> » désigne la souscription aux services proposés dans la Solution,
                    effectuée par le Client pour une durée définie au Contrat d&apos;abonnement.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    - « <strong>Conditions Générales d&apos;Abonnement et d&apos;Utilisation</strong> » désigne les
                    conditions générales d&apos;abonnement et d&apos;utilisation de la <strong>Solution</strong>,
                    régissant la souscription aux <strong>Abonnements</strong> proposés par{" "}
                    <strong>l&apos;Éditeur</strong> et les règles d&apos;utilisation de l&apos;Interface pour les{" "}
                    <strong>Abonnés</strong>.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    - « <strong>Contrat</strong> » désigne le Contrat d&apos;abonnement entre
                    <strong>l&apos;Éditeur</strong> et <strong>l&apos;Abonné</strong> dans le cadre de
                    l&apos;utilisation de la <strong>Solution</strong>.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    - « <strong>Éditeur</strong> », ou « <strong>FÉRET</strong> » désigne la{" "}
                    <strong>SOCIÉTÉ DES ÉDITIONS FÉRET</strong> visée en tête des présentes en tant que créateur et
                    fournisseur de la <strong>Solution</strong>.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    - « <strong>Fiche technique</strong> » désigne le document réunissant toutes les caractéristiques
                    techniques et organoleptiques, avec ou sans informations sur la structure productrice, les activités
                    œnotouristiques proposées et les membres de l&apos;équipe de l&apos;Abonné.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    - « <strong>Solution</strong> » désigne l&apos;Interface en ligne https://bordeaux.feret.com et les
                    services associés.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    - « <strong>Parties</strong> » désigne les parties du <strong>Contrat</strong> d&apos;abonnement, à
                    savoir <strong>l&apos;Éditeur</strong> et <strong>l&apos;Abonné</strong>, lesquelles sont dénommées
                    individuellement « <strong>Partie</strong> ».{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    - « <strong>Site</strong> » désigne le site Internet Bordeaux et ses Vins édité par{" "}
                    <strong>FÉRET</strong>, accessible à l&apos;adresse URL https://bordeaux.feret.com/.
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    - « <strong>Espace Féret</strong> » désigne l&apos;interface de gestion des informations mise à
                    disposition des <strong>Abonnés</strong> au sein de la <strong>Solution</strong>. <br />
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    - « <strong>Utilisateur</strong> » désigne tout utilisateur de la solution de manière générale,
                    autorisé par <strong>l&apos;Abonné</strong> ou par FÉRET.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    - « <strong>Guide Papier</strong> », ou « <strong>BESV</strong> », ou « <strong>Guide</strong> »
                    désigne l’ouvrage <strong>Bordeaux et ses vins</strong>, au format papier, qui perpétue l’histoire
                    de la Place de Bordeaux depuis 1850.
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    2 - COMPTE CLIENT
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    2.1 - Création du compte client
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Toute souscription à un <strong>Abonnement</strong> nécessite la création d&apos;un compte{" "}
                    <strong>Client</strong> sur la <strong>Solution</strong> accessible à l&apos;adresse
                    https://pro.feret.com.
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Les données d&apos;identification du compte client sont strictement personnelles. Les actions menées
                    dans la <strong>Solution</strong> sous la connexion engagent la responsabilité du signataire de
                    ladite connexion.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Une personne physique ou morale peut cumuler plusieurs <strong>Abonnements</strong> pour plusieurs
                    structures différentes.
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    3 - ABONNEMENTS EN LIGNE
                </Typography>
                <Typography variant="h6" className={styles.firstTryText}>
                    Le <strong>Client</strong> a la possibilité de souscrire à un <strong>Abonnement</strong> via la{" "}
                    <strong>Solution</strong>. <br />
                    <br />
                    Quatre niveaux <strong>d&apos;Abonnements</strong> sont proposés par <strong>FÉRET</strong> :
                    <ul>
                        <li>
                            <strong>Basique</strong> : gratuit
                        </li>
                        <li>
                            <strong>Initial</strong> : gamme de services intermédiaire
                        </li>
                        <li>
                            <strong>Essentiel</strong> : gamme de services limitée
                        </li>
                        <li>
                            <strong>Premium</strong> : gamme de services étendue
                        </li>
                    </ul>
                    Des options et services sont dispnibles en complément, toutes les informations sont dispnibles sur
                    la page des tarifs.
                    <br />
                    <br />
                    Un <strong>Abonnement</strong> donne accès à une interface de gestion des informations (ci-après l’«{" "}
                    <strong>Espace Féret</strong> ») et à une gamme de services mis à disposition par{" "}
                    <strong>FÉRET</strong>. <br />
                    <br />
                    Le contenu des services d&apos;abonnement et les interfaces de gestion peuvent différer selon le
                    type d&apos;acteur défini lors de l&apos;inscription par <strong>l&apos;Abonné</strong> : Propriété,
                    Maison de négoce, Cave coopérative ou Autre professionnel du vin par exemple.
                    <br />
                    <br />
                    Le client gère les informations dans son <strong>Espace Féret</strong>.
                </Typography>
                <br />
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    4 – OPTIONS
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    4.1. - Édition papier du guide Bordeaux et ses vins
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    La souscription à l&apos;édition du <strong>Guide Papier</strong> Bordeaux et ses vins est payante
                    une fois et renouvelable environ tous les cinq (5) ans.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    La souscription peut se faire depuis l’<strong>Espace Féret</strong>, dès lors que{" "}
                    <strong>Féret</strong> aura ouvert cette possibilité, quelques mois avant la parution du{" "}
                    <strong>Guide Papier</strong>.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Le contenu de la parution papier sera tiré des informations disponibles sur l’
                    <strong>Espace Féret</strong> de <strong>l&apos;Abonné</strong>, ainsi que d&apos;informations
                    collectées et retraitées par <strong>Féret</strong>.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Afin d&apos;effectuer toutes les modifications utiles avant impression d&apos;une nouvelle édition
                    du <strong>Guide Papier</strong>, <strong>l&apos;Abonné</strong> sera averti au moins trois (3) mois
                    avant le lancement de la mise en page afin de mettre à jour son <strong>Espace Féret</strong>.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Toute modification ultérieure au délai indiqué à <strong>l&apos;Abonné</strong> ne pourra être prise
                    en compte dans la nouvelle édition du <strong>Guide</strong>.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Lorsque la parution de <strong>l&apos;Abonné</strong> sera finalisée, celui-ci devra valider le bon
                    à tirer de ladite parution (ci-après le « BAT »), qui lui sera proposé par <strong>Féret</strong>.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    En l&apos;absence de validation du BAT par l&apos;Abonné dans un délai de deux (2) semaines après
                    envoi, celui-ci sera considéré comme tacitement accepté.
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    4.2. - Service d&apos;aide à la rédaction
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Le service d&apos;aide à la rédaction peut être souscrit en option à n&apos;importe quel moment de
                    la durée de validité de <strong>l&apos;Abonnement</strong>.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Ce service s&apos;appuie sur les documents fournis par <strong>l&apos;Abonné</strong>, et,
                    éventuellement, sur les archives de <strong>FÉRET</strong> ou d&apos;autres sources historiques
                    fiables.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Le contenu rédactionnel sera soumis à <strong>l&apos;Abonné</strong> avant publication pour
                    validation, et, le cas échéant, pour modification avant validation.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Le contenu rédactionnel de <strong>l&apos;Abonné</strong> est exclusivement réservé à
                    l&apos;enrichissement de l&apos;histoire de la propriété sur feret.com et sur le{" "}
                    <strong>Guide Papier</strong> Bordeaux et ses Vins.
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    4.3. - Entretien avec le Propriétaire
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Un service d’entretien peut être pris en option au service d&apos;aide à la rédaction par{" "}
                    <strong>l&apos;Abonné</strong>.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Le contenu rédactionnel sera soumis à <strong>l&apos;Abonné</strong> avant publication pour
                    validation, et, le cas échéant, pour modifications avant validation.{" "}
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    4.4. - Service de traduction
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Deux types de traductions sont disponibles dans la <strong>Solution</strong> :{" "}
                </Typography>
                <Typography variant="h6" className={styles.text} style={{ textDecoration: "underline" }}>
                    - <strong>La traduction intégrée.</strong>
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Cette traduction est basée sur un système intégré aux serveurs <strong>Féret</strong>, basé sur la
                    notion d&apos;apprentissage profond, et enrichie par un glossaire spécialisé conçu par des experts
                    du monde du vin.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Cette traduction est disponible en anglais uniquement.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Chaque enregistrement de l’<strong>Espace Féret</strong> déclenchera une traduction du contenu
                    saisi. Cette traduction concerne tous les champs de texte renseignés en saisie libre par{" "}
                    <strong>l&apos;Abonné</strong>.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text} style={{ textDecoration: "underline" }}>
                    - <strong>La traduction manuelle.</strong>{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    La traduction est effectuée par une agence partenaire expérimentée dans le domaine des vins et
                    spiritueux. Le tarif de la traduction dépend du nombre de caractères à traduire et de la langue
                    cible. Il sera communiqué à <strong>l&apos;Abonné</strong> lors de la demande en ligne dudit service
                    de traduction.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    <strong>L&apos;Abonné</strong> peut demander la traduction de son contenu à tout moment. Il n’est
                    pas possible de disposer d’un contenu partiellement traduit.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Le contenu rédactionnel traduit sera soumis à <strong>l&apos;Abonné</strong> et ajouté à son{" "}
                    <strong>Espace Féret</strong> avant publication pour validation. Cette traduction concerne tous les
                    champs de texte renseignés en saisie libre par <strong>l&apos;Abonné</strong>.
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Pour les Abonnés Basique, Initial, Essentiel et Premium, la traduction intégrée est incluse dans le
                    tarif de l&apos;Abonnement.
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    4.5. - Service de reportage photographique
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Une formule de reportage photographique est proposée à <strong>l&apos;Abonné</strong> qui pourra en
                    faire la demande directement depuis son <strong>Espace Féret</strong>.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    La prestation est réalisée par un prestataire expérimenté, sélectionné par <strong>FÉRET</strong>{" "}
                    pour son professionnalisme et son expérience de photographe du monde du vin.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Les photographies livrées peuvent être réutilisées par <strong>l&apos;Abonné</strong> sans mention
                    du copyright du photographe pour sa propre promotion uniquement. Les photos porteront un filigrane
                    discret &quot;<strong>Féret</strong>&quot; inamovible afin de permettre une traçabilité de leur
                    usage.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    En revanche, <strong>l&apos;Abonné</strong> n&apos;acquiert pas le droit de redistribuer les
                    photographies à des organismes tiers qui se les approprieraient, sans autorisation express.
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    4.6. - Packshot bouteilles
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    <strong>L&apos;Abonné</strong> peut, depuis son <strong>Espace Féret</strong>, et à tout moment,
                    faire une demande de service packshot bouteilles, réalisé au sein du studio photo{" "}
                    <strong>FÉRET</strong>.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    <strong>L&apos;Abonné</strong> devra alors faire parvenir la ou les bouteilles à photographier,
                    ainsi qu&apos;un exemplaire de l&apos;étiquette à <strong>FÉRET</strong> pour que{" "}
                    <strong>FÉRET</strong> puisse réaliser les photographies desdites bouteilles.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Le prix du service dépend du nombre de bouteilles à photographier et sera communiqué à{" "}
                    <strong>l&apos;Abonné</strong> lorsqu&apos;il en fera la demande en ligne, dans la{" "}
                    <strong>Solution</strong>. L&apos;adresse à laquelle expédier les bouteilles sera également indiquée
                    dans la <strong>Solution</strong>.
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    4.7. - Service studio fiche technique
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Un service de fiches techniques sur mesure est proposé à <strong>l&apos;Abonné</strong>.
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Après tous les éléments fournis, comprenant les éléments graphiques, les contraintes et les envies
                    de <strong>l&apos;Abonné</strong>. <br />
                    Féret réalise un modèle de fiche technique unique à l&apos;image de la propriété. Il sera
                    réutilisable à l&apos;infini par <strong>l&apos;Abonné</strong> émetteur de la demande.
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    5 - ACCÈS AU SERVICE
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Chaque niveau <strong>d&apos;Abonnement</strong> (Basique, Initial, Essentiel ou Premium) inclut le
                    droit pour un utilisateur à gérer l’<strong>Espace Féret</strong> en s&apos;y connectant avec une
                    adresse de messagerie électronique.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    <strong>L&apos;Abonné</strong> qui a souscrit à un <strong>Abonnement</strong> avec son adresse de
                    messagerie électronique est considéré comme administrateur du compte. Il est toutefois possible de
                    changer l’adresse e-mail à tout instant.
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    6 - PRIX
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Le tarif des <strong>Abonnements</strong>, indiqué en Euros (€), hors taxes (H.T.) et toutes taxes
                    comprises (T.T.C.), est celui en vigueur au jour de la souscription à{" "}
                    <strong>l&apos;Abonnement</strong>.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    <strong>FÉRET</strong> se réserve la possibilité de modifier ses conditions tarifaires à tout
                    moment.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Toute souscription à un <strong>Abonnement</strong> fait l&apos;objet d&apos;un paiement par avance,
                    et d&apos;une facturation correspondante.
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    7 - MODALITÉS DE PAIEMENT
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Les <strong>Abonnements</strong> sont payables annuellement ou mensuellement par avance, par
                    l&apos;un des moyens de paiement suivants :{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    <ul>
                        <li>Virement</li>
                        <li>Carte bancaire</li>
                        <li>Chèque</li>
                        <li>Prélèvement</li>
                        <li>Mandat SEPA</li>
                    </ul>
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Les paiements effectués en vertu du <strong>Contrat d&apos;abonnement</strong> ne seront considérés
                    comme définitifs qu&apos;après encaissement effectif par <strong>FÉRET</strong> des sommes dues.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Le paiement des options doit être effectué par <strong>l&apos;Abonné</strong> avant la réalisation
                    de la prestation correspondant à l&apos;option choisie.
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    8 - DURÉE
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    <strong>L&apos;Abonnement</strong> entrera en vigueur à la date de création de{" "}
                    <strong>l’Abonnement</strong>, et pour une durée incompressible de douze (12) mois.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    À l&apos;issue de cette période, <strong>l&apos;Abonnement</strong> se prolongera pour une durée
                    équivalente et dans les mêmes termes par tacite reconduction, sauf dénonciation de l&apos;une ou
                    l&apos;autre des <strong>Parties</strong> avec un préavis d&apos;au moins 60 jours avant
                    l&apos;arrivée du terme.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    A tout instant, il est possible pour <strong>l&apos;Abonné</strong> de choisir{" "}
                    <strong>l&apos;Abonnement</strong> de niveau supérieur. Le cas échéant, il devra s’acquitter de la
                    différence de prix, au prorata temporis de <strong>l&apos;Abonnement</strong> en cours. La date
                    anniversaire de <strong>l&apos;Abonnement</strong> ne change pas.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    A tout instant, il est possible pour <strong>l&apos;Abonné</strong> de choisir{" "}
                    <strong>l&apos;Abonnement</strong> de niveau inférieur. Le cas échéant,{" "}
                    <strong>l&apos;Abonnement</strong> perdurera jusqu’à la date anniversaire, et repartira sur une
                    durée de 12 mois pour ce nouveau niveau d’Abonnement choisi. La date anniversaire de{" "}
                    <strong>l&apos;Abonnement</strong> ne change pas.
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    9 - DONNÉES CLIENT PRÉSENTÉES SUR L&apos;INTERFACE ET LE SITE
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    9.1. - Données pré-enregistrées par <strong>FÉRET</strong>
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Certaines informations contenues sur l’<strong>Espace Féret</strong> de{" "}
                    <strong>l&apos;Abonné</strong> sont pré-renseignées par <strong>FÉRET</strong>.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    <strong>L&apos;Abonné</strong> a la possibilité de modifier ou de supprimer les informations
                    présentes sur son <strong>Espace Féret</strong>.
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Ceci étant, et pour des raisons d’exactitude, certaines informations ne peuvent être modifiées
                    qu&apos;avec l&apos;accord préalable et la validation de <strong>FÉRET</strong>.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Pour ce faire, <strong>l&apos;Abonné</strong> devra faire une demande de modification des données
                    pré-saisies non modifiables à <strong>FÉRET</strong>.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Les modifications seront alors effectuées après accord de <strong>FÉRET</strong>, et si cela est
                    jugé légitime.
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    9.2. - Autres champs d&apos;information
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    <strong>L&apos;Abonné</strong> peut présenter sa propriété, sa maison de négoce, sa cave coopérative
                    ou tout autre activité relative au milieu viticole, publier son histoire et importer des médias.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Les surfaces de production de <strong>l&apos;Abonné</strong> peuvent être renseignées manuellement
                    ou via l&apos;import du Casier Viticole Informatisé (ci-après le « CVI »).
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    <strong>L&apos;Abonné</strong> peut également gérer la visibilité sur le Site (visible ou masqué) de
                    ses surfaces et/ou du détail de ses surfaces ainsi que des médailles et notes obtenues pour chacun
                    de ses vins.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Il en est de même pour les surfaces de production extraites d’un CVI concernant un exploitant mais
                    plusieurs propriétés : L’Abonné devra masquer les surfaces de production qui concernent une autre
                    propriété que celle gérée dans l’<strong>Espace Féret</strong>.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Les données importées via le CVI ne sont pas modifiables librement par{" "}
                    <strong>l&apos;Abonné</strong>, qui devra en faire la demande à <strong>FÉRET</strong>, le cas
                    échéant.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Les données importées par le CVI, comme toutes les autres informations détenues par Féret, restent
                    strictement confidentielles et ne seront divulguées à aucun tiers.
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    9.3. - Présentation des vins et de leurs millésimes
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Le nombre de vins présentés par un <strong>Abonné</strong> dépend de{" "}
                    <strong>l&apos;Abonnement</strong> choisi.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    <strong>L&apos;Abonné</strong>, à partir du niveau Essentiel, a la possibilité de renseigner une
                    présentation extrêmement détaillée de ses vins millésimés.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Certaines informations sur les vins sont conditionnées aux caractéristiques de la propriété
                    productrice desdits vins de manière automatique (appellation, terroir...) afin de faciliter la
                    saisie pour <strong>l&apos;Abonné</strong>, et de limiter les erreurs ou incohérences.{" "}
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    9.4. - Présentation de l&apos;équipe
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    <strong>L&apos;Abonné</strong> peut ajouter le nombre de membres d&apos;équipe que lui permet son
                    niveau <strong>d&apos;Abonnement</strong>.
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    <strong>L&apos;Abonné</strong>, quel que soit son niveau <strong>d&apos;abonnement</strong>, a
                    l&apos;obligation de renseigner au moins un (1) exploitant et un (1) propriétaire, et de tenir à
                    jour ces informations en cas de changement.{" "}
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    9.5. – Présentation des activités œnotouristiques
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    <strong>L&apos;Abonné</strong> aura la possibilité de présenter ses activités œnotouristiques ; Le
                    nombre d&apos;activités œnotouristiques présentées dépend du niveau{" "}
                    <strong>d&apos;Abonnement</strong>.{" "}
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    9.6. – Usage des notes et médailles
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Dans le cas où un vin a obtenu une médaille lors d’un concours reconnu par la DGCCRF, cette médaille
                    sera automatiquement attachée au millésime en question. <strong>L&apos;Abonné</strong> pourra
                    décider d’afficher cette médaille ou non dans la <strong>Solution</strong>, et de la faire figurer
                    ou pas sur ses fiches techniques. En revanche, il ne pourra pas ajouter ou modifier une médaille.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Il en va de même pour les notes obtenues d’un journaliste ou d’un guide, à la différence près que{" "}
                    <strong>l&apos;Abonné</strong> devra certifier disposer des droits d’usage de la note obtenue avant
                    de pouvoir l’utiliser dans la <strong>Solution</strong>, et même avant de prendre connaissance de
                    ladite note:{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    En cas de non-respect de cette clause par <strong>l&apos;Abonné</strong>, <strong>FÉRET</strong> ne
                    saurait être tenue pour responsable, et <strong>FÉRET</strong> pourrait décider de mettre fin à{" "}
                    <strong>l&apos;Abonnement</strong> sans délai, et sans aucune forme de compensation ni
                    indemnisation.
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    9.7. - Usage des données par FÉRET
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    <strong>L&apos;Abonné</strong> accorde à <strong>FÉRET</strong> un droit d&apos;accès à titre
                    gratuit à ses données afin d&apos;en assurer la gestion, la sécurisation et la sauvegarde. Aucun
                    usage commercial n&apos;est accordé à <strong>Féret</strong> sur les données de{" "}
                    <strong>l&apos;Abonné.</strong>{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    De même, <strong>l&apos;Abonné</strong> autorise <strong>FÉRET</strong> à utiliser les données pour
                    des usages anonymes à des fins statistiques.
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    9.8 - Mini-site avec URL personnalisée
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    <strong>L&apos;Abonné</strong> « Initial », « Essentiel » ou « Premium » peut disposer, dans le
                    cadre de son abonnement, à une URL personnalisée du format &quot;<strong>monchateau</strong>
                    .feret.com&quot; où &quot;<strong>monchateau</strong>&quot; sera le nom de la structure vinicole
                    associée à la souscription dudit <strong>Abonné</strong>, ou toute autre adresse. Cette URL
                    redirigera vers la page publique du <strong>Site</strong> dudit <strong>Abonné</strong>, excluant
                    l&apos;accès aux autres exploitations et au moteur de recherche, permettant de simuler un site web
                    dédié à <strong>l&apos;Abonné</strong>.
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    10 - FICHES TECHNIQUES
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    10.1. - Création et contenu des Fiches techniques
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Si <strong>l&apos;Abonné</strong> a renseigné et publié sur le <strong>Site</strong> au moins un vin
                    et un millésime, il peut générer une Fiche technique seulement avec un Abonnement « Essentiel » ou «
                    Premium »
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Le degré de personnalisation des <strong>Fiches techniques</strong> de{" "}
                    <strong>l&apos;Abonné</strong> dépend de son niveau <strong>d&apos;Abonnement</strong>.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Des modèles conçus par <strong>FÉRET</strong> sont à la disposition des Abonnés. Le nombre de
                    modèles disponibles dépend du niveau <strong>d&apos;Abonnement</strong> de{" "}
                    <strong>l&apos;Abonné</strong>.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    De même, le niveau <strong>d&apos;Abonnement</strong> définit le degré de personnalisation possible
                    par <strong>l&apos;Abonné</strong>
                    des modèles mis à disposition par <strong>FÉRET</strong>.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Le logo <strong>FÉRET</strong> est automatiquement présent dans les{" "}
                    <strong>Fiches techniques</strong>. Le logo peut être supprimé selon le niveau{" "}
                    <strong>d&apos;Abonnement</strong> de <strong>l&apos;Abonné</strong>.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Pour afficher les notes obtenues par les vins dans les <strong>Fiches techniques</strong>,{" "}
                    <strong>l&apos;Abonné</strong> devra avoir certifié disposer d’un <strong>abonnement</strong> chez
                    le journaliste ou guide émetteur de la note lui octroyant des droits de réutilisation commerciale
                    nécessaires.{" "}
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    10.2. - Partage des fiches techniques
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Les <strong>Fiches techniques</strong> peuvent être partagées par <strong>l&apos;Abonné</strong>.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Une fois générée, la Fiche technique est libre d&apos;utilisation par <strong>l&apos;Abonné</strong>{" "}
                    sans limitation de durée.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    <strong>L&apos;Abonné</strong> peut télécharger sa Fiche technique au format PDF et l’utiliser comme
                    bon lui semble. Il peut aussi la partager grâce au système de diffusion intégré à la{" "}
                    <strong>Solution</strong>.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Le destinataire du partage recevra alors une invitation à créer un compte Féret particulier ou
                    professionnel, gratuit, pour consulter les <strong>Fiches techniques</strong> reçues.
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Les informations de contacts renseignées par <strong>l&apos;Abonné</strong> reste son exclusive
                    propriété, et <strong>l&apos;Abonné</strong> ne concède à Féret que le droit de sauvegarder,
                    sécuriser ces données, en complément bien sûr de les mettre à disposition de{" "}
                    <strong>l&apos;Abonné</strong> pour son usage propre.
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    11 - NOTES, MÉDAILLES ET CLASSEMENTS
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    11.1. - Notes et médailles
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Les vins renseignés par les <strong>Abonnés</strong> peuvent obtenir des médailles et/ou des notes.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Ces données seront visibles dans leur <strong>Espace Féret</strong> selon les conditions définies
                    aux présentes Conditions Générales <strong>d’Abonnement</strong> et d’Utilisation.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    <strong>L&apos;Abonné</strong> n&apos;a pas la possibilité de modifier les médailles et les notes
                    affichées, afin de permettre à <strong>FÉRET</strong> de certifier ces informations.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Les médailles sont renseignées par <strong>FÉRET</strong> qui les aura obtenues via des partenariats
                    avec les sources émettrices. Les médailles renseignées par <strong>FÉRET</strong> seront soumises à
                    validation par <strong>l&apos;Abonné</strong> afin d&apos;en renforcer la fiabilité, et décider de
                    leur affichage ou non.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    La liste des journalistes ou comités de dégustation émetteurs des notes peut être modifiée,
                    augmentée ou réduite à la discrétion de <strong>FÉRET</strong>, sans aucun droit à indemnité au
                    profit de <strong>l&apos;Abonné</strong>, notamment en cas de suppressions sur la liste.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    <strong>FÉRET</strong> fait ses meilleurs efforts pour garantir les droits des journalistes et les
                    conditions tenant à l’affichage et à la diffusion des notes qu’ils émettent.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Elle se dégage ainsi de toute responsabilité en cas d’affichage ou de diffusion par{" "}
                    <strong>l&apos;Abonné</strong> de notes qui ne respecterait pas le processus décrit aux présentes
                    Conditions Générales <strong>d&apos;Abonnement</strong> et d’Utilisation, et qui aurait pour
                    conséquence de contrevenir aux droits des journalistes et guides.
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Il est proposé à <strong>l&apos;Abonné</strong> de renseigner un score et une note de dégustation
                    qui ne serait pas dans la liste des informations directement collectées par Féret. Des vérifications
                    auront lieu par Féret et les déclarations erronées seront supprimées sans préavis. Pour cette
                    fonctionnalité encore,
                    <strong>l&apos;Abonné</strong> doit s&apos;assurer de disposer des droits d&apos;usage des notes
                    déclarées.
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    11.3. – Classements
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    <strong>FÉRET</strong> renseigne le classement des vins classés de manière automatisée. Les
                    classements sont affichés sur les entités titrées (vin, millésime, propriété). Les classements
                    émanent de sources officielles, collectées par <strong>FÉRET</strong>, et ne sont pas modifiables
                    depuis l’<strong>Espace Féret</strong>.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Dans le cas où <strong>l&apos;Abonné</strong> souhaiterait apporter une modification, il devra en
                    faire la demande auprès de <strong>FÉRET</strong>. Les modifications seront alors effectuées après
                    accord de <strong>FÉRET</strong>, si cela est jugé légitime.
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    12 - BADGE FÉRET
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    <strong>FÉRET</strong> certifie certaines données publiées par <strong>l&apos;Abonné</strong> dans
                    son <strong>Espace Féret</strong>, et donc accessibles sur le <strong>Site</strong>, à l&apos;aide
                    d&apos;autres justificatifs requis notamment concernant l&apos;obtention d&apos;une certification ou
                    d&apos;un label
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    <strong>FÉRET</strong> se réserve le droit de retirer une certification si elle considère que{" "}
                    <strong>l&apos;Abonné</strong> a diffusé des informations erronées.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Toutes les propriétés certifiées auront un <strong>Badge</strong> en attestant, qui sera visible et
                    identifiable par les visiteurs sur le <strong>Site</strong> et sur les{" "}
                    <strong>Fiches techniques</strong> des <strong>Abonnés</strong> certifiés.{" "}
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    13 - FORMULAIRE DE CONTACT
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Depuis son Espace Féret, <strong>l&apos;Abonné</strong> peut renseigner différents moyens de
                    contact.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Sur le Site, les visiteurs trouveront sur chaque page propriété un formulaire de contact pour
                    joindre la propriété affichée.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Tous les formulaires e-mail renseignés et envoyés par les visiteurs du Site seront transférés par{" "}
                    <strong>FÉRET</strong> aux <strong>Abonnés</strong> concernés avec la demande et les coordonnées de
                    l&apos;expéditeur.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    <strong>L&apos;Abonné</strong> est alors libre de donner suite ou non à la demande reçue, sans avoir
                    communiqué ses coordonnées.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    <strong>FÉRET</strong> s&apos;engage à la confidentialité la plus stricte dans le cadre des messages
                    reçus et transférés aux Abonnés.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    <strong>FÉRET</strong> s&apos;engage à prendre toutes les précautions nécessaires et utiles pour
                    maintenir la confidentialité des informations confidentielles dont elle pourrait avoir connaissance
                    à cette occasion, et s&apos;interdit en conséquence de divulguer, pour quelque cause que ce soit,
                    directement ou indirectement, quelque information confidentielle, à moins que lesdites informations
                    ne soient tombées dans le domaine public ou que leur divulgation soit rendue nécessaire en vertu de
                    la loi ou d&apos;une injonction judiciaire ou administrative.
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    14 - GARANTIES DE L&apos;ABONNÉ
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    <strong>L&apos;Abonné</strong> est le seul responsable des contenus qu&apos;il diffuse sur sa page.
                    Il s&apos;engage à diffuser des informations réelles et exactes, dans le respect des règles
                    élémentaires de la langue française, et à ne pas diffuser d&apos;informations erronées sur sa page.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    À ce titre, il garantit que toutes les informations qu&apos;il publie (données, fichiers, vidéos,
                    photographies) sont libres de droits.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    De même, <strong>l&apos;Abonné</strong> garantit qu&apos;il possède tous les droits nécessaires pour
                    reproduire, publier et diffuser les informations qu&apos;il renseigne dans son{" "}
                    <strong>Espace Féret</strong>.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    <strong>L&apos;Abonné</strong> garantit{" "}
                    <strong>
                        <strong>FÉRET</strong>
                    </strong>{" "}
                    contre tous recours ou revendications que pourraient exercer des tiers vis-à-vis du contenu
                    qu&apos;il publie.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    De manière plus générale, <strong>l&apos;Abonné</strong> s&apos;engage à respecter les lois et les
                    règlements en vigueur, dans le cadre de l&apos;utilisation de la <strong>Solution</strong>, et à ce
                    que ses agissements ou ceux de ses préposés et/ou <strong>Utilisateurs</strong> qu&apos;il autorise
                    à utiliser la <strong>Solution</strong>, ne soient pas contraires à l&apos;ordre public ni aux
                    bonnes mœurs, et ne portent pas atteinte aux droits des tiers.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Il est expressément interdit à <strong>l&apos;Abonné</strong> de diffuser des messages
                    d&apos;incitation à la haine, agressifs, diffamatoires, de dénigrement commercial, ou tout autre
                    message portant atteinte à autrui.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Dans pareil cas, <strong>FÉRET</strong> se réserve le droit de supprimer le contenu litigieux et
                    éventuellement de suspendre ou supprimer <strong>l&apos;Abonnement</strong> sans aucune
                    indemnisation ni remboursement.
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    15 - OBLIGATIONS DE L&apos;ABONNÉ
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    En souscrivant à un <strong>Abonnement</strong> auprès de <strong>FÉRET</strong>,{" "}
                    <strong>l&apos;Abonné</strong> <strong>s&apos;engage</strong> à respecter les présentes Conditions
                    Générales d&apos;Abonnement et d&apos;Utilisation.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    En cas de fraude ou de fausses déclarations, tout comme en cas de non-respect grave ou renouvelé des
                    règles d&apos;utilisation de la <strong>Solution</strong>, <strong>FÉRET</strong> se réserve le
                    droit, selon la gravité des faits constatés et reprochés à <strong>l&apos;Abonné</strong>, de
                    suspendre ou de résilier <strong>l&apos;Abonnement</strong> à titre de sanction, sans indemnités, et
                    après en avoir préalablement avisé <strong>l&apos;Abonné</strong>.
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    16 - RÉSILIATION
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    <strong>L&apos;Abonné</strong> pourra résilier son <strong>Abonnement</strong> par lettre
                    recommandée avec accusé de réception adressée à ÉDITIONS FÉRET, {feretAddress} - {feretPostalCode},
                    avec un préavis de deux (2) mois avant l&apos;arrivée du terme de <strong>l&apos;Abonnement</strong>
                    .{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Toute résiliation, pour quelque cause que ce soit, transformera <strong>l&apos;abonnement</strong>{" "}
                    payant en <strong>abonnement</strong> &apos;Basique&apos; gratuit. <strong>L&apos;Abonné</strong>{" "}
                    peut, en complément et de manière explicite, demander la suppression définitive des données le
                    concernant, et de fait sa disparition de Bordeaux et ses Vins, dans la <strong>Solution</strong>{" "}
                    comme dans le <strong>Guide Papier</strong>.
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    17 - SÉCURITÉ
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Dans la mesure où les atteintes à la sécurité sont inhérentes à un réseau ouvert sur Internet,{" "}
                    <strong>FÉRET</strong> ne saurait offrir une garantie absolue de sécurité sur la{" "}
                    <strong>Solution</strong>.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Aussi, <strong>l&apos;Abonné</strong> devra prendre toutes les précautions utiles afin de protéger
                    son matériel en vue de se prémunir d&apos;attaques virales via le réseau Internet, et de maintenir à
                    jour ses outils de protection.{" "}
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    18 - RESPONSABILITÉ
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    FÉRET ne sera pas responsable des pertes et dommages directs ou indirects résultant d&apos;une
                    interruption ou d&apos;un dysfonctionnement de la <strong>Solution</strong>, notamment en cas de
                    défaillance, ou de toute difficulté affectant son bon fonctionnement, en cas de panne de serveur,
                    d&apos;attaque virale ou de piratage informatique ayant eu pour conséquence une indisponibilité
                    partielle ou définitive de la <strong>Solution</strong>.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    FÉRET ne saurait être tenue responsable en cas d&apos;utilisation illicite ou non conforme de la{" "}
                    <strong>Solution</strong> par un Abonné et/ou Utilisateur.{" "}
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    19 - PROPRIÉTÉ INTELLECTUELLE
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Les éléments reproduits ou utilisés dans la <strong>Solution</strong>, tels que les marques, logos,
                    textes, photographies, images, vidéogrammes, sons, et toutes les applications utilisées en vue de
                    faire fonctionner la <strong>Solution</strong>, sont protégés par les textes en vigueur en matière
                    de propriété intellectuelle.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Sauf mention contraire, l&apos;ensemble des éléments susvisés sont la propriété pleine et entière de{" "}
                    <strong>FÉRET</strong> et réservés pour toute forme d&apos;utilisation.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Toute reproduction, représentation, adaptation ou utilisation de la <strong>Solution</strong>, en
                    tout ou partie, et sous quelque forme que ce soit, sans autorisation préalable et expresse de{" "}
                    <strong>FÉRET</strong> est strictement interdite.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    En cas de violation de la présente clause, <strong>l&apos;Abonné</strong> et/ou{" "}
                    <strong>l&apos;Utilisateur</strong> s&apos;expose, outre la suspension ou la résiliation de{" "}
                    <strong>l&apos;Abonnement</strong>, à des poursuites judiciaires.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    L&apos;absence d&apos;action de la part de <strong>FÉRET</strong> ne saurait valoir acceptation des
                    utilisations non autorisées qu&apos;elle aurait pu constater, ni renonciation aux poursuites et
                    sanctions judiciaires ou extra-judiciaires à l&apos;encontre de <strong>l&apos;Abonné</strong> et/ou
                    de <strong>l&apos;Utilisateur</strong>.{" "}
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    20 - DONNÉES PERSONNELLES
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    <strong>FÉRET</strong> met en œuvre des traitements de données à caractère personnel dans le cadre
                    de la fourniture de ses services en ligne.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    À ce titre, <strong>FÉRET</strong> s&apos;engage à respecter la réglementation en vigueur applicable
                    à la protection des données à caractère personnel, et notamment, la Loi n°78-17 du 6 janvier 1978
                    dite Loi Informatique et Libertés dans sa version modifiée en vigueur, et le Règlement (UE) 2016/679
                    du 27 avril 2016 sur la protection des données personnelles.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Les données à caractère personnel communiquées par les{" "}
                    <strong>Clients/Abonnés/Utilisateurs/</strong>tiers à FÉRET seront utilisées dans le cadre de la
                    souscription et de la gestion des <strong>Abonnements</strong> et des demandes de contact, ainsi
                    qu&apos;à des fins statistiques. Ces données pourront également faire l&apos;objet d&apos;un
                    traitement à des fins de prospection commerciale.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    En tout état de cause, <strong>FÉRET</strong> s&apos;engage à limiter la collecte des données aux
                    informations adéquates, pertinentes et strictement nécessaires à la réalisation des finalités
                    ci-avant définies.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Les données à caractère personnel seront conservées pendant le temps nécessaire à
                    l&apos;accomplissement des objectifs poursuivis. En aucun cas elles ne seront communiquées à des
                    personnes non autorisées ou vendues à des tiers.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    La sécurité et la confidentialité des données à caractère personnel est assurée par{" "}
                    <strong>FÉRET</strong> qui s&apos;engage à notifier toute violation de données à caractère personnel
                    à la CNIL et aux personnes concernées en cas de risque élevé pour leurs droits et libertés.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Tout <strong>Client/Abonné/Utilisateur</strong>/tiers personne physique justifiant de son identité,
                    peut exercer son droit d&apos;accès, d&apos;interrogation, de limitation, de modification, de
                    rectification, de portabilité et son droit à l&apos;oubli et/ou de suppression relativement à
                    l&apos;ensemble des données le concernant. Le <strong>Client/Abonné/Utilisateur</strong>/tiers
                    dispose également d&apos;un droit d&apos;opposition au traitement des données à caractère personnel,
                    ainsi que d&apos;un droit d&apos;opposition à ce que ces données soient utilisées à des fins de
                    prospection commerciale.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Pour exercer les droits susvisés, le <strong>Client/Abonné/Utilisateur</strong>/tiers doit adresser
                    sa demande, accompagnée d&apos;une copie de sa pièce d&apos;identité par courrier électronique à
                    l&apos;adresse suivante rgpd@feret.com, ou par courrier postal à ÉDITIONS FÉRET, {feretAddress} -{" "}
                    {feretPostalCode}.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Le <strong>Client/Abonné/Utilisateur</strong>/tiers pourra introduire une réclamation auprès de
                    l&apos;autorité de contrôle dans l&apos;hypothèse où l&apos;un de ses droits ne serait pas respecté.
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    21 - NULLITÉ PARTIELLE
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Si une ou plusieurs stipulations des présentes Conditions Générales d&apos;Abonnement et
                    d&apos;Utilisation devaient être considérées comme non valides ou déclarées comme telles en
                    application de la Loi ou d&apos;une décision de justice passée en force de chose jugée, cette
                    nullité n&apos;entraînerait pas la nullité des autres dispositions qui demeureront en vigueur.{" "}
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    22 - LOI APPLICABLE – RÈGLEMENT DES LITIGES
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Les présentes Conditions Générales d&apos;Abonnement et d&apos;Utilisation sont régies par la Loi
                    française.
                </Typography>{" "}
                <Typography variant="h6" className={styles.text}>
                    Elles sont rédigées en français. Dans le cas où elles seraient traduites en une ou plusieurs langues
                    étrangères, seul le texte français ferait foi.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    En cas de litige relatif à la validité, l&apos;interprétation, l&apos;exécution ou la non-exécution
                    du <strong>Contrat</strong>, les <strong>Parties</strong> tenteront prioritairement de régler leur
                    conflit de manière amiable.{" "}
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    À défaut de règlement amiable, le litige sera soumis à la compétence exclusive des tribunaux de
                    Bordeaux.
                </Typography>
                <Typography
                    variant="h6"
                    style={{
                        marginBottom: theme.spacing(2),
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                        fontWeight: "bold",
                    }}
                >
                    23 - MODIFICATIONS – MISES À JOUR
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    La version actuellement en ligne des présentes Conditions Générales d&apos;Abonnement et
                    d&apos;Utilisation est la seule version opposable en vigueur.
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    L&apos;Éditeur se réserve le droit de modifier unilatéralement le contenu des présentes Conditions
                    Générales d&apos;Abonnement et d&apos;Utilisation.
                </Typography>
                <Typography variant="h6" className={styles.text}>
                    Toute nouvelle version des Conditions Générales d&apos;Abonnement et d&apos;Utilisation remplacera
                    la version précédente dès sa mise en ligne.
                </Typography>
            </Box>
        </Modal>
    );
};

export default CGAUPopin;

CGAUPopin.propTypes = {
    modalOpen: PropTypes.bool,
    handleClose: PropTypes.func,

    cancelOnClick: PropTypes.func,
    continueOnClick: PropTypes.func,
    errorLinkOnClick: PropTypes.func,
};
