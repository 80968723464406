
import React from "react";
import PropTypes from "prop-types";
import { Switch, makeStyles, Box, Typography } from "@material-ui/core";
import theme from "../../globalTheme";

const useStyles = makeStyles(() => ({
    mainContainer: {
        display: "flex", alignItems: "center"
    },
    root: {
        width: "28px",
        height: "13px",
        padding: 0,
        display: "flex",
    },
   
    switchBase: {
        top: "-1px",
        left: "-1px",
        padding: 2,
        "&$checked": {
          transform: "translateX(14px)",
          color: theme.palette.common.white,
          "& + $track": {
            opacity: 1,
            backgroundColor: "#6AC561",
            borderColor: "#6AC561",
          },
        },
      },
      thumb: {
        width: "11px",
        height: "11px",
        boxShadow: "none",
      },
      track: {
        height: "-webkit-fill-available",
        border: "1px solid #C4C4C4",
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: "#C4C4C4",
      },
      checked: {},
      disabled : {
            color: `${theme.palette.common.white} !important`,
            "&$checked": {
                transform: "translateX(14px)",
                color: theme.palette.common.white,
                "& + $track": {
                  opacity: 0.5,
                  backgroundColor: "#6AC561",
                  borderColor: "#6AC561",
                },
              },
      }
}));

const ControlledSwitchLabelInside = (props) => {
    const styles = useStyles();

    return (
        <Box
            style={props.boxStyle}
            className={styles.mainContainer}
        >
            <Typography
                variant="h6"
                style={{
                    fontSize: props.labelStyle?.fontSize ? props.labelStyle.fontSize : "14px",
                    fontWeight: props.labelStyle?.fontWeight ?? "normal",
                    width: props.labelWidth,
                    color: props.disabled ? theme.palette.secondary.main : theme.palette.primary.dark
                }}
            >
                {props.label}
            </Typography>
            <Box style={{ display: "flex", alignItems: "center", margin: props.margin || "0 1rem" }}>
                {props.switchCheck && <Typography
                    variant="h6"
                    style={{
                        fontSize: "10px",
                        color: props.disabled ? theme.palette.secondary.main : theme.palette.primary.main,
                        position: "absolute",
                        left: 17,
                        zIndex: 10,
                    }}>
                    {props.leftLabel}
                </Typography>}
                <Switch
                    className={styles.root}
                    disableRipple
                    disabled={props.disabled}
                    focusVisibleClassName={styles.focusVisible}
                    color="secondary"
                    classes={{
                        root: styles.root,
                        switchBase: styles.switchBase,
                        thumb: styles.thumb,
                        track: styles.track,
                        checked: styles.checked,
                        disabled: styles.disabled
                    }}
                    // style={{ ...props.style }}
                    name="checkedSwitch"
                    checked={!!props.switchCheck}
                    onChange={(event, e2) => props.handleSwitchChange(event, e2)}
                // value={props.switchCheck}
                />
                {!props.switchCheck && <Typography
                    variant="h6"
                    style={{
                        fontSize: "10px",
                        color: props.disabled ? theme.palette.secondary.main : theme.palette.secondary.dark,
                        position: "absolute",
                        left: 30,
                        zIndex: 10,
                    }}>
                    {props.rightLabel}
                </Typography>}
            </Box>
        </Box>
    );
};

ControlledSwitchLabelInside.propTypes = {
    switchCheck: PropTypes.bool,
    disabled: PropTypes.bool,
    handleSwitchChange: PropTypes.func,
    placement: PropTypes.oneOf(["start", "end"]),
    label: PropTypes.string,
    style: PropTypes.object,
    leftLabel: PropTypes.string,
    rightLabel: PropTypes.string,

    labelWidth: PropTypes.string,
    boxStyle: PropTypes.object,
    labelStyle: PropTypes.object,
    margin: PropTypes.string
};

export default ControlledSwitchLabelInside;