/* eslint-disable no-unused-vars */
/*eslint-disable no-irregular-whitespace */

import React, { useState } from "react";
import {
    Box, Typography, makeStyles, FormControl, FormControlLabel, Radio, RadioGroup,
} from "@material-ui/core";
import BaseButton from "../../../Components/Buttons/BaseButton";
import Popin from "../../../Containers/Popin";
import PropTypes from "prop-types";
import theme  from "../../../globalTheme";
import popinImg from "../../../assets/Dashboard/translationAssets/translation1.svg";
import image1 from "../../../assets/SubscriptionPage/montre.svg";
import image2 from "../../../assets/SubscriptionPage/sablier.svg";
import cbImg from "../../../assets/SubscriptionPage/cb.svg";
import paypalImg from "../../../assets/SubscriptionPage/paypal.svg";
import OldSelectInput from "../../../Components/Inputs/OldSelectInput";
import anglais from "../../../assets/propriétéPage/anglais.svg";
import france from "../../../assets/propriétéPage/france.svg";
import ControlledSwitch from "../../../Components/Switch/ControlledSwitch";
import AddElementButton from "../../../Components/Buttons/AddElementButton";

const useStyle = makeStyles(() => ({
    radioContainer: {
        width: "100%",
        "& .MuiRadio-root": {
            color: theme.palette.secondary.main,
        },
        "& .Mui-checked": {
            color: theme.palette.primary.light,
        },
        "& .MuiSvgIcon-root": {
            width: "15px",
            fontSize: "12px"
        },
    },
    formControlLabel: {
        color: theme.palette.primary.dark,
        fontFamily: theme.typography.h5.fontFamily,
        fontSize: "10px",
        "& .MuiTypography-body1": {
            fontSize: "14px",
        },
        "&.MuiFormControlLabel-root": {
            marginLeft: "0px",
        },
    },
    deadlineTypo: {
        textAlign: "center",
        padding: "10px",
        backgroundColor: "#F8F5F5",
        borderRadius: "10px",
        color: theme.palette.primary.dark,
        display: "flex",
        justifyContent: "center",
        marginBottom: theme.spacing(2)
    },
    subtitles: {
        fontWeight: "900",
        marginRight: "2px",
        color: theme.palette.primary.dark,
    },
    subtitleBox: {
        display: "flex",
        marginBottom: "20px",

    },
    subtitleImg: {
        width: "18px",
        height: "18px",
        marginRight: "4px"
    },
    buttonsBox: {
        marginBottom: "38px",
        display: "flex",
        flexDirection: "rows",
        // width: "200px"

    }
}));

const getButton = (buttonLabel, buttonImage, onClick, tooltipTitle) => {
    return (
        <BaseButton
            style={{
                height: "47px",
                width: "250px",
                border: "0.2px solid",
                borderColor: theme.palette.secondary.main,
                borderRadius: "4px",
                display: "flex",
            }}
            boxStyle={{
                marginRight: "37px"
            }}
            variant="outlined"
            onClick={onClick}
            tooltip={tooltipTitle}
            tooltipTitle={tooltipTitle}
        >
            {buttonImage && <img style={{ marginRight: theme.spacing(1) }} src={buttonImage} alt="button_img" />}
            <Typography
                variant="h5"
                style={{
                    color: theme.palette.primary.dark,
                }}>
                {buttonLabel}
            </Typography>
        </BaseButton>
    );
};


const AskForTranslationPopin = (props) => {
    const styles = useStyle(props);

    const getTitle = (image, typo) => {
        return <Box style={{ display: "flex", alignItems: "center" }}>
            <img src={image} alt="flag" style={{ marginRight: "8px", width: "15px" }} />
            <Typography style={{ fontSize: "14px" }}>{typo}</Typography>
        </Box>;
    };

    const [state, setState] = useState({ firstStep: true, secondStep: false, thridStep: false });
    const [HTPrice, setHTPrice] = useState(true);

    return (
        <Popin
            cross
            background={2}
            icon={popinImg}
            open={props.modalOpen}
            onClose={props.handleClose}
            backLinkOnClick={() => 
                state?.secondStep && setState({
                firstStep: true,
                secondStep: false,
                thirdStep: false
            })}
        >
            <Box style={{ width: (state.firstStep && 730) || (state.secondStep && 720) || (state.thirdStep && 600) }}>
                {state.firstStep &&
                    <Box style={{ marginRight: 36, marginLeft: 36 }}>
                        <Typography style={{ zIndex: 2, position: "relative", textAlign: "center", color: theme.palette.primary.dark, fontSize: "26px", marginBottom: theme.spacing(4) }} variant="h3">
                            Demande de traduction manuelle
                        </Typography>
                        <Typography style={{ fontSize: "18px", color: theme.palette.primary.dark }}>
                            La traduction manuelle experte est réalisée par notre partenaire professionnel spécialisé dans les traductions de contenus du monde vitivinicole. En tant que bêta testeur <b>vous bénéficiez de 10 % de remise</b> sur ce service. Cette réduction sera appliquée au paiement.
                        </Typography><br />
                        <Box style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                            <ControlledSwitch
                                leftLabel="Prix TTC"
                                rightLabel="Prix HT"
                                switchCheck={HTPrice}
                                handleSwitchChange={(e) => setHTPrice(!HTPrice)}
                            />
                        </Box>
                        <Box style={{ display: "flex", width: "100%", justifyContent: "space-between", marginBottom: theme.spacing(2) }}>
                            <OldSelectInput
                                boxStyle={{ width: "50%" }}
                                variant="outlined"
                                name="surfaceColor"
                                label="Langue de la traduction"
                            >
                                {[{ title: getTitle(anglais, "Anglais"), value: "eng" }]}
                            </OldSelectInput>
                            <Box>
                                <Typography style={{ fontWeight: "bold", fontSize: "18px" }}>
                                    XX €* HT
                                </Typography>
                            </Box>
                        </Box>
                        {/* <AddElementButton
                        onClick={() => { }}
                    >
                        Ajouter une langue de traduction
                    </AddElementButton> */}

                        <Typography style={{ fontSize: "18px", color: theme.palette.primary.dark, marginTop: theme.spacing(2) }}>
                            En cliquant sur « Suivant », nous enregistrons votre tableau de bord et figeons le contenu concerné par votre demande de traduction.
                        </Typography><br />
                        <Typography variant="h6" style={{ marginBottom: theme.spacing(2), fontSize: "14px", color: theme.palette.primary.dark }}>
                            * Ce tarif est calculé selon le nombre de caractères que vous avez saisi.
                        </Typography>
                        <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", marginBottom: theme.spacing(2) }}>
                            <BaseButton
                                variant="outlined"
                                color="primary"
                                style={{ minHeight: "34px" }}
                                onClick={props.handleClose}
                            >
                                Annuler et quitter
                            </BaseButton>
                            <BaseButton
                                variant="contained"
                                color="primary"
                                style={{ minHeight: "34px" }}
                                onClick={() => { setState({ firstStep: false, secondStep: true, thirdStep: false }); }}
                            >
                                Suivant
                            </BaseButton>
                        </Box>
                    </Box>}

                {
                    state.secondStep &&
                    <Box style={{ marginRight: 36, marginLeft: 36 }}>
                        <Typography style={{ textAlign: "center", zIndex: 20, position: "relative" }} variant="h4">
                            Demande de traduction manuelle
                        </Typography>
                        <Typography style={{ zIndex: 20, position: "relative", textAlign: "center", color: theme.palette.primary.dark, fontSize: "26px", marginBottom: theme.spacing(4) }} variant="h3">
                            Récapitulatif avant paiement
                        </Typography>
                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", border: "1px solid", borderColor: theme.palette.secondary.main, borderRadius: "10px", padding: "8px 16px", marginBottom: theme.spacing(2) }}>
                            <Typography style={{ fontSize: "18px", color: theme.palette.primary.dark }}>Traduction de 300 caractères FR</Typography>
                            <Typography style={{ fontSize: "18px", color: theme.palette.primary.dark }}>XX €</Typography>
                        </Box>

                        <Box style={{ border: "1px solid", borderColor: theme.palette.secondary.main, borderRadius: "10px", padding: "16px", marginBottom: theme.spacing(2) }}>
                            <Box style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                <Typography variant="h4" style={{ fontWeight: "bold", fontSize: "18px" }}>Total hors taxes</Typography>
                                <Typography style={{ fontSize: "18px", color: theme.palette.primary.dark }}>XX €</Typography>
                            </Box>
                            <Box style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                <Typography style={{ fontSize: "14px", color: theme.palette.primary.dark }}>TVA</Typography>
                                <Typography style={{ fontSize: "14px", color: theme.palette.primary.dark }}>XX €</Typography>
                            </Box>
                            {/* BETA */}
                            <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: theme.spacing(1) }}>
                                <Typography variant="h5" >Réduction bêta testeur de 10 %:</Typography>
                                <Typography variant="h5" >{"XX €"}</Typography>
                            </Box>
                            <Box style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                <Typography variant="h4" style={{ fontWeight: "bold", fontSize: "18px" }}>Total à payer</Typography>
                                <Typography style={{ fontWeight: "bold", fontSize: "18px", color: theme.palette.primary.dark }}>XXX €</Typography>
                            </Box>
                        </Box>
                        <Box style={{ border: "1px solid", borderColor: theme.palette.secondary.main, borderRadius: "10px", padding: "16px", marginBottom: theme.spacing(3) }}>

                            <Typography variant="h4" style={{ marginBottom: theme.spacing(2), fontSize: "18px", }}>Finalisez votre demande</Typography>
                            <Typography style={{ color: theme.palette.primary.dark, fontStyle: "italic" }}>En cliquant sur &quot;Envoyer&quot;, votre demande nous sera transmise. Nous vous recontacterons dans un très court délai afin de procéder au paiement de la prestation et de déclencher sa réalisation</Typography>
                            {/* BETA */}
                            {/* 
                        <Box container style={{ display: "flex", justifyContent: "center", height: "100%", width: "100%" }}>
                            <form noValidate style={{ width: "100%" }}>
                                <Box className={styles.subtitleBox}>
                                    <img src={image1} alt="subtitle_img" className={styles.subtitleImg} />
                                    <Typography variant="h6" className={styles.subtitles}>
                                        Validation instantanée
                                    </Typography>
                                </Box>

                                <Box className={styles.buttonsBox}>
                                    {getButton("Carte bancaire", cbImg, () => alert("carte bancaire clicked"), "Carte bancaire")}
                                    {getButton("Paypal", paypalImg, () => alert("paypal clicked"), "Paypal")}
                                </Box>

                                <Box className={styles.subtitleBox} >
                                    <img src={image2} alt="subtitle_img" className={styles.subtitleImg} />
                                    <Typography variant="h6" className={styles.subtitles}>
                                        Validation après réception du paiement
                                    </Typography>
                                </Box>

                                <Box className={styles.buttonsBox}>
                                    {getButton("Virement bancaire", null, () => alert("virement clicked"), "Virement bancaire")}
                                    {getButton("Chèque", null, () => alert("cheque clicked"), "Chèque")}
                                </Box>
                            </form>
                        </Box> */}
                        </Box>
                        <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", marginBottom: theme.spacing(2) }}>
                            <BaseButton
                                variant="outlined"
                                color="primary"
                                style={{ minHeight: "34px" }}
                                onClick={props.handleClose}
                            >Annuler et quitter</BaseButton>
                            <BaseButton
                                variant="contained"
                                color="primary"
                                style={{ minHeight: "34px" }}
                                onClick={() => {
                                    setState({ firstStep: false, secondStep: false, thirdStep: true });
                                }}
                            >Envoyer</BaseButton>
                        </Box>
                    </Box>
                }
                {
                    state.thirdStep &&
                    <Box style={{ marginRight: 36, marginLeft: 36 }}>
                        <Typography style={{ zIndex: 20, position: "relative", textAlign: "center", color: theme.palette.primary.dark, fontSize: "26px", marginBottom: theme.spacing(2) }} variant="h3">
                            Merci pour votre demande !
                        </Typography>
                        <Typography variant="h5" style={{ marginBottom: theme.spacing(2), textAlign: "center" }}> Nous vous contacterons très bientôt.</Typography>
                        {/* BETA */}
                        {/* <Typography style={{ fontSize: "18px" }}>Dès validation de votre paiement vous recevrez par mail votre facture et la traduction sera disponible dans votre tableau de bord dans un délai de 5 jours ouvrés.</Typography><br />
                    <Typography style={{ fontSize: "14px", }}>En attendant la traduction manuelle experte, souhaitez-vous conserver la traduction technique programmée de votre contenu ?</Typography>
                    <FormControl className={styles.radioContainer} style={{ marginBottom: theme.spacing(3) }}>
                        <RadioGroup
                            style={{ flexDirection: "row" }}
                        // aria-label="circulation"
                        // name="wineCirculation"
                        // value={scratch[`cellars_${index}_wineCirculation`]}
                        // onChange={(e) => handleChange(`cellars_${index}_wineCirculation`, e)}
                        >
                            <FormControlLabel
                                value="Oui"
                                control={
                                    <Radio
                                        style={{ paddingRight: "4px" }} 
                                        />
                                }
                                className={styles.formControlLabel}
                                label="Oui"

                            />
                            <FormControlLabel
                                value="Non"
                                control={
                                    <Radio
                                        style={{ paddingRight: "3px" }} />
                                }
                                className={styles.formControlLabel}
                                label="Non, supprimer dès à présent la traduction automatique"
                            />
                        </RadioGroup>
                    </FormControl>
                    <br />
                    <Box style={{ width: "100%", display: "flex", justifyContent: "center", marginBottom: theme.spacing(2) }}>
                        <BaseButton
                            variant="contained"
                            color="primary"
                            style={{ minHeight: "34px" }}
                            onClick={props.handleClose}

                        >Valider</BaseButton>
                    </Box> */}
                    </Box>
                }
            </Box>
        </Popin >
    );
};

export default AskForTranslationPopin;

AskForTranslationPopin.propTypes = {
    modalOpen: PropTypes.bool,
    handleClose: PropTypes.func,

};