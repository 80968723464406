import { getUserLocale } from "helpers/helpers";
import { useEffect, useReducer, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const useSearch = (filterSetup, useSearchQuery, queryName, pageSize) => {
    const { search } = useLocation();
    const [filtersCount, setFiltersCount] = useState();
    const locale = useSelector((state) => getUserLocale(state));

    let init = { ...filterSetup };
    let initialFilters = { ...filterSetup };

    if (search && search !== "") {
        const s = decodeURI(search.substring(1));

        const toAdd = JSON.parse(s);

        init = { ...init, ...toAdd };
    }

    const filterKeys = Object.keys(filterSetup);
    const [pageNumber, setPageNumber] = useState(1);
    const filtersReducer = (state, action) => {
        let newState;
        switch (action.type) {
            case "push":
                newState = { ...state, [action.field]: [...state[action.field], action.payload] };
                break;
            case "reset":
                newState = { ...state, ...initialFilters };
                break;
            case "resetFields":
                newState = { ...state };

                for (let i = 0; i < action.fields.length; i++) {
                    const field = action.fields[i];
                    newState[field] = initialFilters[field];
                }
                break;
            case "set":
                newState = { ...state, [action.field]: action.payload };
                break;
            case "setAll":
                newState = { ...state, ...action.payload };
                break;

            default:
                throw new Error();
        }
        return newState;
    };
    const [filters, dispatch] = useReducer(filtersReducer, init);

    const { data, loading, errors } = useSearchQuery({
        variables: {
            searchInput: filters,
            pageSize,
            pageNumber,
            locale,
        },
    });
    const totalCount = data?.[queryName]?.totalCount?.[0]?.totalRecords;

    useEffect(() => {
        setFiltersCount(getFiltersCount());
    }, [filters]);

    const setFilter = (fieldname, value) => {
        if (pageNumber !== 1) {
            setPageNumber(1);
        }
        return dispatch({ type: "set", field: fieldname, payload: value });
    };

    const setAllFilters = (o) => {
        return dispatch({ type: "setAll", payload: o });
    };

    const resetFields = (fields) => {
        return dispatch({ type: "resetFields", fields });
    };

    const counts = {};
    for (let i = 0; i < filterKeys.length; i++) {
        const filterKey = filterKeys[i];
        counts[filterKey] = data?.[queryName]?.[filterKey];
    }

    const getUrlParams = (searchText) => {
        const ret = {};
        const entries = Object.entries(initialFilters);
        for (let [filterKey, filterValue] of entries) {
            if (Array.isArray(filterValue)) {
                if (filters[filterKey]?.length > 0) {
                    ret[filterKey] = filters[filterKey];
                }
            } else {
                if (filters[filterKey] !== filterValue) {
                    ret[filterKey] = filters[filterKey];
                }
            }
        }
        if (searchText) {
            ret["searchText"] = searchText;
        }
        return ret;
    };

    const resetFilters = () => {
        return dispatch({ type: "reset" });
    };

    const getFiltersCount = () => {
        let ret = 0;
        const keys = Object.keys(filters);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const v = filters[key];
            if (key === "searchText") {
                continue;
            }
            if (Array.isArray(v)) {
                if (key === "apogee" && v?.length === 2) {
                    if (v[0] !== 1850 || v[1] !== 2100) {
                        ret += 1;
                    }
                } else if (key === "surface" && v?.length === 2) {
                    if (v[0] !== 0 || v[1] !== 100) {
                        ret += 1;
                    }
                } else if (key === "priceRange" && v?.length === 2) {
                    if (v[0] !== filterSetup?.["priceRange"]?.[0] || v[1] !== filterSetup?.["priceRange"]?.[1]) {
                        ret += 1;
                    }
                } else if (v?.length > 0) {
                    ret += 1;
                }
            } else {
                if (v !== initialFilters[key]) {
                    ret += 1;
                }
            }
        }
        return ret;
    };

    return {
        filters,
        dispatch,
        setFilter,
        counts,
        results: data?.[queryName]?.data,
        totalCount,
        setPageNumber,
        getUrlParams,
        resetFilters,
        similar: data?.[queryName]?.similar,
        filtersCount,
        loading,
        pageNumber,
        errors,
        resetFields,
        setAllFilters,
    };
};
export default useSearch;
