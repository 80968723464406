import moment from "moment";
import { dateDep } from "../helpers/helpers";

const isEmptyRow = (row) => {
    if (!row) {
        return false;
    }
    const values = Object.values(row).filter((e) => e);
    return values.length === 0;
};
export const onChangeConditions = [
    "elementsLimit",
    "dependantPercentageLimit",
    "compositeKeys",
    "totalPercentage",
    "array",
    "object",
    "matchingHours",
    "datesComparison",
    "required",
    "address",
    "maxTime",
];
export const isError = (k, val, condition, scratch, row, isOnChange) => {
    switch (k) {
        case "custom":
            return condition(val, scratch);
        case "gtin":
            if (val) {
                const v = val?.toString();
                if (v?.length === 13) {
                    let sum = 0;
                    for (let i = 0; i < v?.length - 1; i++) {
                        let ch = parseInt(v[i]);
                        if (i % 2 === 1) {
                            ch *= 3;
                        }
                        sum += ch;
                    }
                    let checkDigit = 10 - (sum % 10);
                    if (checkDigit === 10) {
                        checkDigit = 0;
                    }
                    if (!(checkDigit === parseInt(v[v?.length - 1]))) {
                        return "GTIN invalide";
                    }
                }
            }
            break;
        case "required":
            if (isEmptyRow(row) && !isOnChange) {
                return;
            }
            if (val === "" || (val !== 0 && !val)) {
                return "obligatoire";
            }
            break;
        case "regex":
            if (val && val != "" && !condition.expression.test(val)) {
                return "format invalide";
            }
            break;
        case "matchingHours": {
            let matchingArr = [];
            if (condition.uniform) {
                let allEmpty = true;
                for (let i = 0; i < val?.length; i++) {
                    const row = val[i];
                    if (row.open && (row.openMorning || row.closeMorning || row.openAfternoon || row.closeAfternoon)) {
                        allEmpty = false;
                    }
                }
                if (allEmpty) {
                    return false;
                }
            }
            for (let i = 0; i < val?.length; i++) {
                const row = val[i];
                let orderedArr = [row?.openMorning, row?.closeMorning, row?.openAfternoon, row?.closeAfternoon];
                orderedArr = orderedArr
                    .filter((e) => e)
                    .map((e) => {
                        if (moment(e).format("HH:mm") === "00:00") {
                            return moment(moment(new Date().setHours(23, 59, 59)).format("HH:mm"), "HH:mm:ss");
                        } else {
                            return moment(moment(e).format("HH:mm"), "HH:mm:ss");
                        }
                    });

                if (row?.open && !row?.nonStop) {
                    if (orderedArr?.length !== 4) {
                        matchingArr?.push({
                            e: "emptyRow",
                            rowIndex: i + 1,
                            fieldname: "openingHours",
                            text: "Il manque des horaires",
                        });
                    }
                    if (orderedArr?.length === 4) {
                        if (
                            orderedArr[0] >= orderedArr[1] ||
                            orderedArr[1] >= orderedArr[2] ||
                            orderedArr[2] >= orderedArr[3]
                        ) {
                            matchingArr?.push({
                                e: "matchingHours",
                                rowIndex: i + 1,
                                fieldname: "openingHours",
                                text: "Les horaires ne sont pas dans le bon ordre",
                            });
                        }
                    }
                } else if (row?.open && row?.nonStop) {
                    if (
                        (!row?.openMorning && !row?.closeMorning) ||
                        (row?.openMorning && !row?.closeMorning) ||
                        (!row?.openMorning && row?.closeMorning)
                    ) {
                        matchingArr?.push({
                            e: "emptyRow",
                            rowIndex: i + 1,
                            fieldname: "openingHours",
                            text: "la ligne est vide",
                        });
                    }
                    if (orderedArr.length === 2) {
                        if (orderedArr[0] > orderedArr[1]) {
                            matchingArr?.push({
                                e: "matchingHours",
                                rowIndex: i + 1,
                                fieldname: "openingHours",
                                text: "Les horaires ne sont pas dans le bon ordre",
                            });
                        }
                    }
                }
            }
            return matchingArr && matchingArr.length !== 0 ? matchingArr : false;
        }
        case "date":
            var d1 = new Date(val);
            var d2 = new Date();
            var d3 = new Date(condition?.minLimitDate);

            if (condition?.noDateAfterActualDate) {
                if (val && d1.getTime() > d2.getTime()) {
                    return "La date ne peut pas être supérieure à la date d'aujourd'hui";
                }
            }
            if (condition?.minLimitDate) {
                if (val && d1.getTime() < d3.getTime()) {
                    return "date invalide";
                }
            }
            if (
                val &&
                val !== "Invalid Date" &&
                moment(val).format("DD/MM/YYYY") != "" &&
                !condition.expression.test(moment(val).format("DD/MM/YYYY"))
            ) {
                return "date invalide";
            }
            break;
        case "numberLimit":
            if (val && (val < condition.min || val > condition.max)) {
                return `min. : ${condition.min}, max. : ${condition.max}`;
            }
            break;
        case "elementsLimit":
            if (val?.length > condition.limit) {
                return `${condition.limit} éléments maximum`;
            }
            break;
        case "dependantPercentageLimit": {
            let arr;
            if (condition?.accessors?.length !== 0) {
                condition?.accessors?.map((el) => {
                    if (scratch[el] + val > 100) {
                        return (arr = "total max. : " + 100);
                    } else {
                        arr = false;
                    }
                });
            } else {
                arr = false;
            }
            return arr;
        }
        case "datesComparison": {
            let val2 = null;
            if (condition?.dateType === "start") {
                val2 = row?.endAt;
            } else if (condition?.dateType === "end") {
                val2 = row?.startAt;
            } else {
                return;
            }
            if (dateDep(val, val2, condition?.dateType)) {
                return "date error";
            } else {
                return;
            }
        }
        case "requiredDependant":
            if (scratch[condition.accessor]) {
                if (val === "" || (val !== 0 && !val)) {
                    return "obligatoire";
                }
            } else {
                return;
            }
            break;
        case "dependToOtherValue":
            if (row[condition.accessor]) {
                if (val === "" || (val !== 0 && !val)) {
                    return "obligatoire";
                }
            } else {
                return;
            }
            break;
        case "dependant":
            if (row[condition?.accessor] && !val) {
                return `Cette donnée est dépendante de ${row[condition?.accessor]}`;
            } else {
                return false;
            }
        case "charStringLength":
            if (val && (val?.length < condition.min || val?.length > condition.max)) {
                return `min. caractères : ${condition.min}, max. caractères : ${condition.max} `;
            }
            break;

        case "htmlStringLength":
            if (
                val &&
                typeof val === "string" &&
                val?.replace(/<[^>]+>/g, "")?.length &&
                (val?.replace(/<[^>]+>/g, "")?.length < condition.min ||
                    val?.replace(/<[^>]+>/g, "")?.length > condition.max)
            ) {
                return `min. caractères : ${condition.min}, max. caractères : ${condition.max} `;
            }
            break;
        case "preciseLength":
            // WHEN SPACE DOESN'T COUNT
            if (condition?.noSpace) {
                const valWithoutSpaces = val?.replace(/\s/g, "");
                if (valWithoutSpaces?.length !== 0) {
                    if (valWithoutSpaces?.length !== condition?.number) {
                        return `${condition?.number} caractères requis`;
                    } else {
                        return false;
                    }
                }
                // WHEN SPACES COUNT
            } else if (condition?.onlyNumber) {
                const onlyNumberCheck = /^\d+$/.test(val);
                if (!onlyNumberCheck) {
                    return `${condition?.number} caractères requis`;
                } else {
                    if (val && val?.length !== condition?.number) {
                        return `${condition?.number} caractères requis`;
                    } else {
                        return false;
                    }
                }
            } else {
                if (val && val?.toString()?.length !== condition?.number) {
                    return `${condition?.number} caractères requis`;
                } else {
                    return false;
                }
            }
            break;
        case "telephone":
            if (val && val[0] !== "0") {
                return "Le numéro doit contenir 10 chiffres et commencer par 0";
            } else if (val && val?.length !== 14) {
                return "Le numéro doit contenir 10 chiffres et commencer par 0";
            }
            break;
        case "minRange":
            if (val && val[0] && val[0] < condition.min) {
                return `minimum : ${condition.min}`;
            }
            break;
        case "object": {
            const keys = Object.keys(condition);
            const result = [];
            if (keys.length === 0) {
                return true;
            }
            for (let key of keys) {
                const fieldConditionKeys = Object.keys(condition);

                for (let i = 0; i < fieldConditionKeys.length; i++) {
                    const fieldConditionKey = fieldConditionKeys[i];
                    const restrictions = condition[fieldConditionKey];

                    const restrictionKeys = Object.keys(restrictions);
                    for (let k = 0; k < restrictionKeys.length; k++) {
                        const element = restrictionKeys[k];

                        if (key === fieldConditionKey) {
                            const error = isError(element, val[key], restrictions[element], scratch, val);
                            if (error) {
                                result.push({
                                    fieldname: fieldConditionKey,
                                    label: restrictions[element].label,
                                    error,
                                });
                            }
                        }
                    }
                }
            }
            return result.length ? result : false;
            /*
            const keys = Object.keys(condition);

            if (keys.length === 0) {
                return true;
            }

            const result = Object.entries(condition)
                .flatMap(([key, restrictions]) => {
                    Object.values(restrictions).map((element) => {
                        const error = isError(element, val[key], restrictions[element], scratch, val);
                        if (error) {
                            return {
                                fieldname: key,
                                label: restrictions[element].label,
                                error,
                            };
                        }
                        return null;
                    });
                })
                .filter(Boolean);

            return result.length ? result : false;
            */
        }
        case "array":
            // eslint-disable-next-line no-case-declarations
            const fieldnames = Object.keys(condition);
            // eslint-disable-next-line no-case-declarations
            const ret = [];
            if (fieldnames.length === 0) {
                return true;
            }
            for (let i = 0; i < val?.length; i++) {
                const row = val[i];
                const aaa = Object.values(row);
                const allNull = aaa.every((e) => !e || e === "m²");

                if (allNull) {
                    continue;
                }
                for (let j = 0; j < fieldnames.length; j++) {
                    const fieldname = fieldnames[j];
                    const fieldCondition = condition[fieldname];
                    const fieldConditionKeys = Object.keys(fieldCondition);
                    // if (j === 0) {
                    //     continue;
                    // }

                    for (let k = 0; k < fieldConditionKeys.length; k++) {
                        const fieldConditionKey = fieldConditionKeys[k];
                        const label = fieldCondition[fieldConditionKey].label;
                        const fieldValue = row[fieldname];

                        if (!isOnChange || (isOnChange && onChangeConditions.includes(fieldConditionKey))) {
                            const e = isError(
                                fieldConditionKey,
                                fieldValue,
                                fieldCondition[fieldConditionKey],
                                scratch,
                                val[i]
                            );
                            if (e) {
                                ret.push({
                                    rowIndex: i,
                                    fieldname,
                                    e,
                                    label: label,
                                });
                            }
                        }
                    }
                }
            }
            return ret.length ? ret : false;
        case "address": {
            // eslint-disable-next-line no-case-declarations
            const addressFieldNames = Object.keys(condition);
            // eslint-disable-next-line no-case-declarations
            if (addressFieldNames.length === 0) {
                return true;
            }

            let retAddress = {};
            const address = val?.address;
            const cp = val?.postalCode;
            const commune = val?.communeINSEEcode;
            const extra = val?.complementaryAddress;

            if (!cp && !address && !commune && !extra) {
                return;
            }

            if ((!address && (cp || commune)) || (extra && !address)) {
                retAddress.address = {
                    fieldname: "address",
                    e: "renseignez l'adresse",
                    label: "Addresse",
                };
            }

            if ((!cp && (address || commune)) || (extra && !cp)) {
                retAddress.postalCode = {
                    fieldname: "postalCode",
                    e: "renseignez le code postal",
                    label: "Code postal",
                };
            }

            if (cp && cp?.length > 0 && cp?.length < 5) {
                retAddress.postalCode = {
                    fieldname: "postalCode",
                    e: "5 caractères requis",
                    label: "Code postale",
                };
            }

            if ((cp?.length === 5 && !commune) || (extra && !address)) {
                retAddress.commune = {
                    fieldname: "communeINSEECode",
                    e: "renseignez la commune",
                    label: "Commune",
                };
            }
            if (Object.keys(retAddress).length === 0) {
                return false;
            } else {
                return retAddress;
            }
        }
        case "compositeKeys":
            // eslint-disable-next-line no-case-declarations
            const values = new Set();
            // eslint-disable-next-line no-case-declarations
            const rett = [];
            for (let i = 0; i < val?.length; i++) {
                const row = val[i];

                let hash = "";
                for (let j = 0; j < condition?.fields?.length; j++) {
                    const singleCondition = row[condition?.fields[j]];
                    if (singleCondition !== null && singleCondition !== undefined) {
                        hash = hash + singleCondition;
                    }
                }
                if (hash !== "") {
                    if (values.has(hash)) {
                        for (let k = 0; k < condition?.fields?.length; k++) {
                            rett.push({
                                rowIndex: i,
                                labels: condition?.labels,
                                e: "ligne double",
                                fieldname: condition?.fields[k],
                            });
                        }
                    } else {
                        values.add(hash);
                    }
                }
            }
            return rett.length ? rett : false;
        case "totalPercentage": {
            let percentageSum = null;
            let finalArr = [];

            for (let i = 0; i < val?.length; i++) {
                if (val[i]?.percentage && typeof val[i]?.percentage == "number") {
                    percentageSum = percentageSum + val[i]?.percentage;
                }
            }
            if (percentageSum && percentageSum >= 101) {
                for (let j = 0; j < val?.length; j++) {
                    finalArr?.push({
                        rowIndex: j,
                        fieldname: condition.name[0],
                        e: "Total max : 100 %",
                        label: condition.label,
                    });
                }
                return finalArr;
            } else {
                return false;
            }
        }
        case "cellsSumLimit": {
            let cellsSum = null;
            let cellsSumFinalArr = [];
            for (let i = 0; i < val?.length; i++) {
                if (val[i]?.[condition?.cellName] && typeof val[i]?.[condition?.cellName] == "number") {
                    cellsSum = cellsSum + val[i]?.[condition?.cellName];
                }
            }
            if (cellsSum && cellsSum > scratch[condition?.limitInputName]) {
                for (let j = 0; j < val?.length; j++) {
                    if (val[j]?.surfaceArea) {
                        cellsSumFinalArr?.push({
                            rowIndex: j,
                            fieldname: condition?.cellName,
                            e: "cellsSumLimit",
                            phrase: condition?.phrase,
                        });
                    }
                }
                return cellsSumFinalArr;
            } else {
                return false;
            }
        }
        // CAS PRECIS : SURFACE TOTAL + PETITE QUE SUPERFICIE VIGNE
        case "maxLimitSurfaceArea":
            if (scratch[condition?.fieldname]) {
                if (val && val > scratch[condition?.fieldname]) {
                    return `ne peut pas dépasser la surface totale : ${scratch[condition?.fieldname]} ha`;
                }
            }
            break;

        case "maxSurfaceArea":
            if (scratch[condition?.fieldname]) {
                if (val && val > scratch[condition?.fieldname]) {
                    return `ne peut pas dépasser : ${scratch[condition?.fieldname]} ha`;
                }
            }
            break;
        // CAS PRECIS : SUPERFICIE VIGNE + GRANDE QUE SURFACE TOTALE
        case "minLimitSurfaceArea":
            if (scratch[condition?.fieldname]) {
                if (val && val < scratch[condition?.fieldname]) {
                    return `ne peut pas être inférieur à la superficie vigne : ${scratch[condition?.fieldname]} ha`;
                }
            }
            break;
        case "noZero":
            if (val === 0) {
                return "ne peut pas être 0";
            }
            break;
        case "onChange":
            return false;
        default:
            throw ("unexpected condition key", k);
    }
    return false;
};
