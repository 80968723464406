/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment-timezone";
import { makeStyles, Typography, Box } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PropTypes from "prop-types";
import { t, Trans } from "@lingui/macro";

import "react-big-calendar/lib/css/react-big-calendar.css";

import manual from "assets/Wine/manual.svg";
import mecanical from "assets/Wine/mecanical.svg";
import tries from "assets/Wine/tries.svg";
import cagettes from "assets/Wine/cagettes.svg";

import { useFrontVintageDetailedHarvestQuery } from "generated/graphql";
import Loading from "Components/Loader/Loading";
import { useWindowDimensions } from "helpers/helpers";
import BaseCheckbox from "Components/Inputs/BaseCheckbox";
import { useSelector } from "react-redux";

const localizer = momentLocalizer(moment);

const useStyles = makeStyles((theme) => ({
    calendar: {
        "& .rbc-toolbar .rbc-toolbar-label": {
            fontSize: "30px",
            fontFamily: "Playfair display",
            textTransform: "capitalize",
            [theme.breakpoints.up("sm")]: {
                textAlign: "start",
            },
            [theme.breakpoints.up("md")]: {
                textAlign: "center",
            },
        },
        "& .rbc-toolbar": {
            gap: "16px",
            [theme.breakpoints.up("sm")]: {
                flexDirection: "column",
                alignItems: "center",
            },
            [theme.breakpoints.up("md")]: {
                flexDirection: "row",
            },
        },
        "& .rbc-timeslot-group": {
            minHeight: "200px",
        },
        "& .rbc-time-content": {
            display: "none",
        },
        "& .rbc-time-view": {
            flex: "none",
        },
        "& .rbc-month-view": {
            height: "800px",
        },
        "& .rbc-show-more": {
            color: "black",
            fontStyle: "italic",
            marginTop: "10px",
        },
        "& .rbc-today": {
            backgroundColor: "#fff7fa",
        },
        "& .rbc-date-cell": {
            fontFamily: "Playfair display",
            fontSize: "18px",
            fontWeight: "bold",
            textAlign: "start",
        },
        "& .rbc-header": {
            color: theme.palette.secondary.dark,
            fontSize: "12px",
            fontWeight: "normal",
        },
        "& .rbc-label": {
            padding: 0,
        },
        "& .rbc-toolbar button": {
            cursor: "pointer",
        },
    },
    leftPartContainer: (props) => {
        if (props.width > 1920) {
            return {
                backgroundColor: "white",

                padding: "25px",
                width: "20%",
                border: "1px solid",
                borderColor: "#ededed",
                borderRight: "none",
                borderRadius: "10px",
            };
        } else {
            return {
                backgroundColor: "white",

                padding: "25px",
                display: "flex",
                gap: "16px",
                [theme.breakpoints.only("xs")]: {
                    flexWrap: "wrap",
                    padding: 0,
                },
                [theme.breakpoints.up("xl")]: {
                    flexDirection: "column",
                },
            };
        }
    },
    calendarTypos: {
        fontSize: "20px",
        fontFamily: "Playfair display",
        [theme.breakpoints.only("xs")]: {
            fontSize: "14px",
        },
    },
    column: {
        display: "flex",
        flexDirection: "column",
    },
    flexCenter: {
        display: "flex",
        alignItems: "center",
    },
    icon1: {
        width: "20px",
        height: "20px",
        marginRight: "4px",
    },
    margin1: {
        marginBottom: "16px",
    },
    titleTypo: {
        color: "white",
        fontSize: "14px !important",
    },
    typoXs14: {
        [theme.breakpoints.only("xs")]: {
            fontSize: "14px",
        },
    },
    boxCalendar: (props, width) => ({
        width: width > 1920 ? "80%" : "100%",
        minHeight: "850px",
        backgroundColor: "white",

        height: "850px",
        [theme.breakpoints.only("xs")]: {
            border: "1px solid #9F8D93",
            borderRadius: "5px",
            padding: "8px 0 0 0",
        },
    }),
}));

const getDates = (el) => {
    let startAt = null;
    let endAt = null;

    let startMonth = "";
    let endMonth = "";

    startMonth = el?.startAt?.month - 1;

    if (el?.endAt?.month === 1) {
        endMonth = 0;
    } else {
        endMonth = el?.endAt?.month - 1;
    }

    if (el?.startAt?.vaguePeriod) {
        if (el?.startAt?.vaguePeriod === "début") {
            startAt = new Date(el?.startAt?.year, startMonth, 1);
        } else if (el?.startAt?.vaguePeriod === "mi") {
            startAt = new Date(el?.startAt?.year, startMonth, 10);
        } else if (el?.startAt?.vaguePeriod === "fin") {
            startAt = new Date(el?.startAt?.year, startMonth, 20);
        }
    }

    if (el?.startAt?.day) {
        startAt = new Date(el?.startAt?.year, startMonth, el?.startAt?.day);
    }

    if (el?.endAt?.vaguePeriod) {
        if (el?.endAt?.vaguePeriod === "début") {
            endAt = new Date(el?.endAt?.year, endMonth, 10 + 1);
        } else if (el?.endAt?.vaguePeriod === "mi") {
            endAt = new Date(el?.endAt?.year, endMonth, 20 + 1);
        } else if (el?.endAt?.vaguePeriod === "fin") {
            endAt = new Date(el?.endAt?.year, endMonth + 1, 0 + 1);
        }
    }

    if (el?.endAt?.day) {
        endAt = new Date(el?.endAt?.year, endMonth, el?.endAt?.day + 1);
    }
    return { startAt, endAt };
};

const WineCalendar = (props) => {
    const { width } = useWindowDimensions();
    const styles = useStyles({ ...props, width });
    const locale = useSelector((state) => state?.user?.locale || "fr");

    const { data, loading } = useFrontVintageDetailedHarvestQuery({
        variables: {
            vintageId: props.vintageId,
            locale: props.locale,
        },
    });

    // EVENTS
    const harvest = data?.FrontVintageDetailedHarvest;

    const [grapeVarietyFilterValues, setGrapeVarietyFilterValues] = useState();
    const [grapeVarietyColors, setGrapeVarietyColors] = useState();
    const [harvestTypeFilterValues, setHarvestTypeFilterValues] = useState();
    const [date, setDate] = useState();

    useEffect(() => {
        if (harvest) {
            const colors = ["#90174A", "#632D3E", "#9C44AC", "#4A90E2", "#9BBA97", "#F48D00", "#B40D2B"];

            const defaultYear = harvest?.map((el) => el?.startAt?.year) || [];
            const defaultMonth = harvest?.map((el) => el?.startAt?.month - 1) || [];
            const initGrapeVarietyFilterValues = {};
            const initGrapeVarietyColors = {};

            const allGrapesNames = harvest?.map((el) => el?.grapeVariety) || [];

            const filteredGrapesNames = [...new Set(allGrapesNames)];
            filteredGrapesNames.map((el, i) => {
                initGrapeVarietyColors[el] = colors[i];
                return (initGrapeVarietyFilterValues[el] = true);
            });
            setGrapeVarietyColors(initGrapeVarietyColors);
            setGrapeVarietyFilterValues(initGrapeVarietyFilterValues);

            const initHarvestTypeFilterValues = {};
            const allMethods =
                harvest?.map((el) => {
                    return el?.method;
                }) || [];
            const filteredMethods = [...new Set(allMethods)];
            filteredMethods.map((el) => {
                return (initHarvestTypeFilterValues[el] = true);
            });
            setHarvestTypeFilterValues(initHarvestTypeFilterValues);

            if (defaultMonth?.length !== 0 && defaultYear !== 0) {
                setDate(new Date(defaultYear?.[0], defaultMonth?.[0], 1));
            } else {
                setDate(new Date());
            }
        }
    }, [harvest]);

    const getEvents = () => {
        const ret = [];
        for (let i = 0; i < harvest.length; i++) {
            const el = harvest[i];
            if (grapeVarietyFilterValues?.[el?.grapeVariety] && harvestTypeFilterValues?.[el?.method]) {
                const { startAt, endAt } = getDates(el);
                ret.push({
                    name: el?.grapeVariety,
                    title: (
                        <Box className={styles.flexCenter}>
                            <img src={el?.icon?.data} className={styles.icon1} />
                            {width > 960 ? (
                                <Typography className={styles.titleTypo}>{el?.grapeVariety} </Typography>
                            ) : (
                                ""
                            )}
                        </Box>
                    ),
                    allDay: true,
                    start: startAt,
                    end: endAt,
                    hexColor: grapeVarietyColors[el.grapeVariety],
                    harvest: el?.method,
                });
            }
        }
        return ret;
    };

    const eventStyleGetter = function (event) {
        var style = {
            backgroundColor: event.hexColor,
            borderRadius: "0px",
            opacity: 0.8,
            color: "black",
            fontFamily: "Lato",
            fontSize: "14px",
        };
        return {
            style: style,
        };
    };

    const getHarvestImage = (method) => {
        const i = data?.FrontVintageDetailedHarvest?.findIndex((e) => e.method === method);
        if (i >= 0) {
            const methodFr = data?.FrontVintageDetailedHarvest?.[i].methodFr;
            if (methodFr === "manuelles") {
                return manual;
            } else if (methodFr === "manuelles en cagettes") {
                return cagettes;
            } else if (methodFr === "mécaniques") {
                return mecanical;
            } else if (methodFr === "manuelles par tries") {
                return tries;
            } else {
                return "";
            }
        }
    };

    const getMethodCheckboxes = () => {
        return (
            <Box className={styles.column}>
                {harvestTypeFilterValues &&
                    Object.keys(harvestTypeFilterValues).map((method) => {
                        return (
                            <BaseCheckbox
                                style={{
                                    alignItems: "start",
                                    marginBottom: "1rem",
                                    marginRight: 0,
                                }}
                                styleCheckbox={{ marginTop: "8px" }}
                                // outlineStyle={{fill: "red"}}
                                // backgroundStyle={{color: "red"}}
                                checked={harvestTypeFilterValues[method]}
                                onChange={(e) => {
                                    setHarvestTypeFilterValues({
                                        ...harvestTypeFilterValues,
                                        [e.target.name]: e.target.checked,
                                    });
                                }}
                                name={method}
                                label={
                                    <Box
                                        style={{
                                            display: "flex",
                                            alignItems: "start",
                                        }}
                                    >
                                        <Box
                                            style={{
                                                minWidth: "25px",
                                                marginRight: "8px",
                                                marginTop: "8px",
                                            }}
                                        >
                                            <img
                                                style={{
                                                    height: "25px",
                                                    width: "25px",
                                                }}
                                                src={getHarvestImage(method)}
                                            />
                                        </Box>
                                        <Typography>{method}</Typography>
                                    </Box>
                                }
                            />
                        );
                    })}
            </Box>
        );
    };
    const getGrapesCheckboxes = () => {
        return (
            <Box className={styles.column}>
                {grapeVarietyFilterValues &&
                    Object.keys(grapeVarietyFilterValues).map((grapeVariety) => {
                        return (
                            <FormControlLabel
                                style={{ marginBottom: "1rem" }}
                                control={
                                    <BaseCheckbox
                                        outlineStyle={{
                                            fill: grapeVarietyColors[grapeVariety],
                                            color: grapeVarietyColors[grapeVariety],
                                        }}
                                        backgroundStyle={{
                                            color: grapeVarietyColors[grapeVariety],
                                        }}
                                        checked={grapeVarietyFilterValues[grapeVariety]}
                                        onChange={(e) => {
                                            setGrapeVarietyFilterValues({
                                                ...grapeVarietyFilterValues,
                                                [e.target.name]: e.target.checked,
                                            });
                                        }}
                                        name={grapeVariety}
                                    />
                                }
                                label={grapeVariety}
                            />
                        );
                    })}
            </Box>
        );
    };

    if (loading || !harvest) {
        return <Loading />;
    }

    if (harvest && harvest?.length !== 0) {
        let noGrapeVariety = false;
        if (grapeVarietyFilterValues && Object.keys(grapeVarietyFilterValues).every((e) => e === "null")) {
            noGrapeVariety = true;
        }
        return (
            <Box
                style={{
                    display: "flex",
                    flexDirection: width >= 1920 ? "row" : "column",
                    paddingBottom: "16px",
                }}
            >
                <Box className={styles.leftPartContainer}>
                    {!noGrapeVariety && (
                        <Box className={styles.margin1} style={{ marginRight: width > 1920 ? 0 : "16px" }}>
                            <Typography variant="h4" className={[styles.margin1, styles.typoXs14].join(" ")}>
                                <Trans> Cépages </Trans>
                            </Typography>
                            {getGrapesCheckboxes()}
                        </Box>
                    )}
                    <Box>
                        <Typography variant="h4" className={[styles.margin1, styles.typoXs14].join(" ")}>
                            <Trans> Type de vendange </Trans>
                        </Typography>
                        <Box className={styles.column}>{getMethodCheckboxes()}</Box>
                    </Box>
                </Box>
                <Box className={styles.boxCalendar}>
                    <Calendar
                        events={getEvents()}
                        localizer={localizer}
                        startAccessor="start"
                        endAccessor="end"
                        date={date}
                        onNavigate={(date) => {
                            setDate(date);
                        }}
                        views={["month", "day"]}
                        className={styles.calendar}
                        culture={props.locale}
                        messages={{
                            next: <Typography className={styles.calendarTypos}> {">"} </Typography>,
                            previous: <Typography className={styles.calendarTypos}> {"<"} </Typography>,
                            today: <Typography className={styles.calendarTypos}> {t`Aujourd'hui`} </Typography>,
                            month: <Typography className={styles.calendarTypos}> {t`Mois`} </Typography>,
                            week: <Typography className={styles.calendarTypos}> {t`Semaine`} </Typography>,
                            day: <Typography className={styles.calendarTypos}> {t`Jour`} </Typography>,
                            showMore: function showMore(total) {
                                return "+" + total + " " + t`voir plus`;
                            },
                        }}
                        eventPropGetter={eventStyleGetter}
                        formats={{
                            dayRangeHeaderFormat: ({ start, end }, culture, localizer) => {
                                let s = localizer.format(start, "DD", culture);
                                let e = localizer.format(end, "DD MMMM YYYY", culture);
                                return `${s} - ${e}`;
                            },
                            dayHeaderFormat: (date) => {
                                const dayDate = new Date(date).toLocaleDateString(locale, {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                });
                                return dayDate;
                            },
                            monthHeaderFormat: (date) => {
                                const monthDate = new Date(date).toLocaleDateString(locale, {
                                    year: "numeric",
                                    month: "long",
                                });
                                return monthDate;
                            },
                        }}
                    />
                </Box>
            </Box>
        );
    } else {
        return (
            <Typography style={{ fontSize: "14px", padding: "16px" }}>
                <Trans>Information non renseignée</Trans>
            </Typography>
        );
    }
};

WineCalendar.propTypes = {
    vintageId: PropTypes.string,
    locale: PropTypes.string,
};

export default WineCalendar;
