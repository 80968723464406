import { addonWithSuffix, getPlanCode } from "helpers/helpers";

export const buildSubscritionDataInput = (userId, subscriptionState, recurrence) => {
    const subscriptionType = subscriptionState.selectedPack;
    const planCode = getPlanCode(subscriptionType, recurrence);
    const addonCodes = subscriptionState?.addonCodes.map((addon) => addonWithSuffix(addon, subscriptionType));

    return {
        userId,
        wineEntityName: subscriptionState["subscriptionEstateName"],
        mainCommune: subscriptionState["newSubscriptionMainCommune"],
        mainCommuneInseeCode: subscriptionState["newSubscriptionMainCommuneInseeCode"],
        address: {
            address: subscriptionState["subscriptionAddress"]?.simple || subscriptionState["subscriptionAddress"],
            complementaryAddress: subscriptionState["subscriptionExtraAddress"],
            commune: subscriptionState["subscriptionCommune"],
            postalCode: subscriptionState["subscriptionPostalCode"],
            communeINSEEcode: subscriptionState["subscriptionCommuneINSEEcode"],
        },
        billingAddress: {
            address: subscriptionState["subscriptionBillingAddress"],
            complementaryAddress: subscriptionState["subscriptionBillingExtraAddress"],
            communeINSEEcode: subscriptionState["subscriptionBillingCommuneINSEEcode"],
            commune: subscriptionState["subscriptionBillingCommune"],
            postalCode: subscriptionState["subscriptionBillingZipCode"],
            country: subscriptionState["subscriptionBillingCountry"],
        },
        billingInformation: {
            siret: subscriptionState["subscriptionBillingSiret"],
            organizationStatus: subscriptionState["subscriptionBillingStatut"],
            organizationName: subscriptionState["subscriptionBillingCompanyName"],
            tvaNumber: subscriptionState["subscriptionBillingTVA"],
            title: subscriptionState["subscriptionContactTitle"],
            firstName: subscriptionState["subscriptionContactFirstName"],
            lastName: subscriptionState["subscriptionContactLastName"],
            email: subscriptionState["subscriptionContactEmailAddress"],
            telephoneNumber: subscriptionState["subscriptionContactPhoneNumber"],
        },
        email: subscriptionState["subscriptionContactEmailAddress"],
        telephone: subscriptionState["subscriptionContactPhoneNumber"],

        isOwner: subscriptionState["subscriptionPropertyOwner"] === "true",
        isOperator: subscriptionState["subscriptionPropertyOperator"] === "true",

        mainAppellation: subscriptionState["subscriptionMainAppellation"],
        siret: subscriptionState["subscriptionBillingSiret"],
        organizationName: subscriptionState["subscriptionBillingCompanyName"],
        organizationStatus: subscriptionState["subscriptionBillingStatut"],
        orphanWineEntityId: subscriptionState.orphanWineEntityId,
        paymentMethod: subscriptionState.paymentMethod,
        subscriptionType,
        addonCodes,
        recurrence,
        planCode,
    };
};
