import React, { useState } from "react";
import { any, bool, func, string } from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useGeoApi } from "../../hooks";
import { makeStyles, TextField, Box, Typography, InputAdornment } from "@material-ui/core";
import theme from "../../globalTheme";
import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "../../assets/Error.svg";

const useStyles = makeStyles(() => ({
    checkIcon: {
        width: "20px",
        fill: "#9BBA97",
        marginRight: "16px",
    },
    falseIcon: {
        width: "20px",
        fill: theme.palette.error.main,
        marginRight: "16px",
    },
    autocomplete: (props) => ({
        "& .MuiSvgIcon-root": {
            fontSize: "20px",
            color: theme.palette.secondary.main,
        },
        "& .MuiAutocomplete-endAdornment": {
            top: `calc(50% - ${props.endAdornmentTopVal || "10px"})`,
        },
        "& .MuiAutocomplete-clearIndicator": {
            // width: "15px",
            display: props.unit && "none",
            marginRight: props.correctValue || props.error ? "25px" : "0px",
            color: theme.palette.secondary.dark,
        },
        backgroundColor: "white",
        "& .MuiFormLabel-root": {
            color: theme.palette.secondary.dark,
            fontSize: "14px",
            top: "-8px",
            "&.Mui-focused": {
                color: theme.palette.primary.dark,
            },
            "&.Mui-error": {
                color: theme.palette.error.main,
            },
        },
        "& .MuiOutlinedInput-root": {
            paddingLeft: "10px",
            fontSize: "14px",
            height: "34px",
            color: theme.palette.primary.dark,
            borderWidth: "0.2px",
            borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.dark,

            "&.Mui-error": {
                color: theme.palette.error.main,
            },
            "&.Mui-focused": {
                color: theme.palette.primary.dark,
                borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
                borderWidth: "0.2px",
            },
            "&.Mui-disabled": {
                color: theme.palette.secondary.dark,
                backgroundColor: theme.palette.action.disabledBackground,
                borderColor: theme.palette.secondary.dark,
                borderWidth: "0.2px",
            },
            "&:hover fieldset": {
                borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
            },
            "&.Mui-focused fieldset": {
                borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
            },
        },
        "& .MuiOutlinedInput-input": {
            padding: "0px",
        },
        " & .MuiSelect-select": {
            "&:focus": {
                backgroundColor: theme.palette.common.white,
            },
        },
        "& .MuiOutlinedInput-adornedStart": {
            padding: "0px",
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: "translate(16px, 4px) scale(0.75)",
        },
        "& .MuiFormControl-marginNormal": {
            margin: 0,
        },
        "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input": {
            padding: 0,
        },
        "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
            paddingRight: "16px",
        },
        "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
            paddingRight: "16px",
        },
    }),
    option: {
        fontSize: "14px",
        color: theme.palette.primary.dark,
        "&:hover": {
            backgroundColor: theme.palette.dashboard.background,
        },
        "&.MuiListItem-root.Mui-selected": {
            backgroundColor: theme.palette.dashboard.background,
        },
    },
    optionText: {
        fontStyle: "italic",
        fontSize: "12px",
        color: theme.palette.secondary.dark,
    },
}));

const AddressAutocompleteInput = (props) => {
    const styles = useStyles(props);
    const [searchString, setSearchString] = useState(props.address || "");
    const { data, formattedData } = useGeoApi(searchString, "", true);

    return (
        <Box>
            <Autocomplete
                className={styles.autocomplete}
                classes={{ option: styles.option }}
                noOptionsText={
                    <Typography className={styles.optionText}>
                        Il n&apos;y a plus d&apos;options disponibles.
                    </Typography>
                }
                disabled={props.disabled}
                loading={props.loading}
                options={formattedData}
                filterOptions={(x) => x}
                value={props.address || ""}
                inputValue={searchString}
                onInputChange={(event, value) => setSearchString(value)}
                onChange={(event, value) => props.onChange(value, data?.[value?.index]?.properties)}
                getOptionSelected={(option, value) => option && value && option.name === value.name}
                getOptionLabel={(option) => option.simple || option}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        required
                        label={props.label || "Adresse"}
                        margin="normal"
                        error={props.error}
                        variant="outlined"
                        onBlur={(e) => props.onChange(e.target?.value)}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <InputAdornment position="end">
                                    {params.InputProps.endAdornment}
                                    {props.correctValue && <CheckIcon className={styles.checkIcon} />}
                                    {props.error && <img src={ErrorIcon} className={styles.falseIcon} />}
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
                renderOption={(e) =>
                    e.index === 0 ? (
                        <Typography
                            style={{
                                fontWeight: "bold",
                                fontSize: "14px",
                            }}
                        >
                            {e.name}
                        </Typography>
                    ) : (
                        e.name
                    )
                }
            />
            <Typography variant="h6" style={{ fontSize: "12px", color: theme.palette.error.main }}>
                {props.error}
            </Typography>
        </Box>
    );
};

AddressAutocompleteInput.propTypes = {
    correctValue: bool,
    error: any,
    width: string,
    onChange: func,
    address: any,
    disabled: bool,
    loading: bool,
    label: string,
};

export default AddressAutocompleteInput;
