import PropTypes from "prop-types";
import { Box, makeStyles } from "@material-ui/core";
import { useParams } from "react-router-dom";

import { useGetArticleSummaryQuery } from "../generated/graphql";
import SwiperCards, { SwiperWrapper } from "../Front/SwiperCards";
import { getUserLocale, urlize } from "../helpers/helpers";
import theme from "../globalTheme";
import { t } from "@lingui/macro";
import { useSelector } from "react-redux";
import FeretVisitCard from "FrontPost/FeretVisitCard";

const useStyles = makeStyles((e) => ({
    container_slider_2_content: (props) => ({
        justifyContent: "center",
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingBottom: 38,

        [theme.breakpoints.up("xl")]: {
            width: props.titleCenter ? "100%" : "calc(1314px)",
            margin: "auto",
        },
    }),
}));

const FeretVisitEncyclopedia = (props) => {
    const styles = useStyles(props);
    const { title } = useParams();
    const locale = useSelector(getUserLocale);

    const { data: { GetArticleSummary: articleSummary } = {} } = useGetArticleSummaryQuery({
        variables: { name: urlize(title), locale },
    });

    return (
        <Box className={styles.container_slider_2}>
            <Box className={[styles.container_slider_2_content, props.boxClassName].join(" ")}>
                <SwiperWrapper
                    title={t`Visitez l’encyclopédie Féret`}
                    titleCenter={props.titleCenter}
                    hideLowBar={props.hideLowBar}
                    titleStyle={props.titleStyle}
                    noPadding={props.noPadding}
                    spaceBetween={0}
                    noMargin
                    dark
                >
                    {articleSummary?.map((article, i) => {
                        const key = `feret_visit_encyclopedia-${i}`;
                        return <FeretVisitCard key={key} article={article} />;
                    })}
                </SwiperWrapper>
            </Box>
        </Box>
    );
};

export default FeretVisitEncyclopedia;
FeretVisitEncyclopedia.propTypes = {
    titleCenter: PropTypes.bool,
    hideLowBar: PropTypes.bool,
    titleStyle: PropTypes.object,
    noPadding: PropTypes.bool,
    boxClassName: PropTypes.object,
};
