import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Typography, makeStyles } from "@material-ui/core";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import moment from "moment-timezone";

// Images
import bg from "../assets/bg-challenge.jpg";
import contacts from "../assets/summary/contacts.svg";
import chiffres from "../assets/rankingSummary/chiffres.svg";
import fioriture3 from "../assets/rankingSummary/fioriture3.svg";
import fioriture1 from "../assets/rankingSummary/fioriture1.svg";
import fioritureMultiple from "../assets/fleuritures/challenge/fleuritures-multiple.svg";

import FrontMainContainer from "../Front/FrontMainContainer";
import FrontContentTop from "../Front/FrontContentTop";
import FrontTitle from "../FrontRanking/FrontTitle";
import theme from "../globalTheme";
import EstateTitleSemiUnderlined from "../FrontEstate/Components/EstateTitledSemiUnderlined";
import SocialMediaIcons from "../Components/Icons/SocialMediaIcons";
import { formatConcat, getTextLength, isNotEmpty, parseHtml, urlize } from "../helpers/helpers";
import ChallengeSummary from "../FrontChallenge/ChallengeSummary";
import { SwiperWrapper } from "../Front/SwiperCards";
import { useWindowDimensions } from "../helpers/helpers";
import ChallengeCarousel from "../FrontChallenge/ChallengeCarousel";
import AddressBlock from "../FrontChallenge/AddressBlock";
import ExpandableFormattedTextBox from "../Components/Texts/ExpandableFormattedTextBox";
import {
    useGetCompetitionsFrontQuery,
    useGetVintagesOfCompetitionQuery,
    useGetRandomCompetitionSampleQuery,
} from "../generated/graphql";
import { t, Trans } from "@lingui/macro";
import Page404 from "Components/ErrorPage/Page404";
import Page500Front from "Components/ErrorPage/Page500Front";
import LoadingWithImage from "Components/Loader/LoadingWithImage";
import Helmet from "Components/Helmet/Helmet";
import ChallengeEntityCard from "FrontChallenge/ChallengeEntityCard";
import ChallengeVintageCard from "FrontChallenge/ChallengeVintageCard";

const useStyles = makeStyles(() => ({
    container_card: {
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
        },
    },
    container_content_top: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        gap: "26px",

        [theme.breakpoints.up("md")]: {
            marginTop: "-45px",
        },
    },
    image_container: {
        gridColumn: "1 / span 12",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "371px",
        margin: "0 auto",
        marginTop: "16px",

        [theme.breakpoints.up("sm")]: {
            gridColumn: "8 / span 12",
        },

        [theme.breakpoints.up("md")]: {
            marginTop: "39px",
        },
    },
    image_badges: {
        width: "100%",
    },
    socialMedia: {
        order: 1,
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: "15px",

        [theme.breakpoints.up("lg")]: {
            order: 2,
        },

        [theme.breakpoints.up("xl")]: {
            right: 64,
            top: 42,
        },
    },
    container_other_rankings: {
        justifyContent: "center",
        paddingLeft: "16px",
        paddingRight: "16px",

        [theme.breakpoints.up("xl")]: {
            width: "calc(1314px)",
            margin: "auto",
        },
    },
    container_content_secondary: {
        gridColumn: "1 / span 12",

        [theme.breakpoints.up("md")]: {
            gridColumn: "9 / span 4",
            marginLeft: "24px",
            backgroundImage: `url(${fioritureMultiple})`,
            backgroundPositionY: "80%",
            backgroundPositionX: "right",
            backgroundRepeat: "no-repeat",
            marginTop: "45px",
        },
    },
    container_content_primary: {
        gridColumn: "1 / span 12",

        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 8",
        },
    },
    container_title_secondary: {
        marginTop: "16px",
    },
    content_text_secondary: {
        marginTop: "24px",
        marginBottom: "24px",
        color: theme.palette.primary.dark,
        textAlign: "justify",
    },
    container_infos_right: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",

        [theme.breakpoints.up("md")]: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
        },
    },
    center_responsive: {
        gridColumn: "1 / span 12",
        width: "100%",

        [theme.breakpoints.up("sm")]: {
            gridColumn: "1 / span 6",
        },

        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 12",
        },
    },
    contact_container: {
        gridColumn: "1 / span 12",
        order: 1,
        marginTop: "49px",
        maxWidth: "373px",
        width: "100%",
    },
    contact_title: {
        width: "100%",
        display: "flex",
        gap: "8px",
        "&:not(:last-child)": {
            marginBottom: "28px",
        },
    },
    infos_title: {
        width: "100%",
        display: "flex",
        gap: "8px",
        "&:not(:last-child)": {
            marginBottom: "14px",
        },
    },
    contact_image_container: {
        imageContainer: {
            width: "48px",
            height: "48px",
        },
    },
    contact_media: {
        media: {
            width: "100%",
            [theme.breakpoints.only("xs")]: {
                width: "42px",
            },
        },
    },
    medalsBox: {
        display: "flex",
        flexWrap: "wrap",
        columnGap: 16,
        rowGap: 12,

        [theme.breakpoints.down("md")]: {
            columnGap: 12,
        },

        [theme.breakpoints.down("lg")]: {
            columnGap: 8,
        },
    },
    content_container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "100%",
        width: "125px",
        height: "125px",

        [theme.breakpoints.up("sm")]: {
            width: "135px",
            height: "135px",
        },

        [theme.breakpoints.up("md")]: {
            width: "105px",
            height: "105px",
        },

        [theme.breakpoints.up("lg")]: {
            width: "135px",
            height: "135px",
        },
    },
    carousel_item_number: {
        fontFamily: "Playfair Display, Helvetica, Arial, sans-serif",
    },
    content_text: {
        color: theme.palette.primary.dark,
        textAlign: "justify",

        "& *": {
            textAlign: "justify",
        },
    },
}));

const ChallengePage = () => {
    const { width } = useWindowDimensions();
    const styles = useStyles(width);
    const { challenge } = useParams();
    const locale = useSelector((state) => state?.user?.locale || "fr");
    const [competition, setCompetition] = useState(null);

    const {
        data: { GetCompetitionsFront: competitions } = {},
        loading,
        error: errorGetCompetitions,
    } = useGetCompetitionsFrontQuery({
        variables: { locale, name: urlize(challenge) },
    });

    const { data: { GetVintagesOfCompetition: vintages } = {}, error: errorGetVintages } =
        useGetVintagesOfCompetitionQuery({
            variables: {
                competitionId: competition?.competitionId,
            },
        });

    const { data: { GetRandomCompetitionSample: otherChallenges } = {}, error: errorGetRandomCompetition } =
        useGetRandomCompetitionSampleQuery({
            variables: { excludeId: competition?._id },
        });

    useEffect(() => {
        if (competitions?.length > 0) {
            const members = competitions[0].members.map((member) => {
                const { role, title, lastName, firstName } = member;
                return {
                    ...member,
                    title: role?.[locale],
                    name: formatConcat([title, firstName, lastName]),
                };
            });
            setCompetition({ ...competitions[0], members });
        }
    }, [competitions]);

    useEffect(() => {
        moment.locale(locale);
    }, [locale]);

    const setCarouselvisibleSlidesChiffreDatas = () => {
        if (width < 400) return 2;
        else if (width < 500) return 3;
        else if (width < 700) return 4;
        return 5;
    };

    const breadcrumbs = [
        {
            name: t`Organismes du vignoble bordelais`,
            path: "/organismes-vignoble-bordelais",
        },
        {
            name: t`Concours`,
            path: "/organismes-vignoble-bordelais/concours",
        },
        {
            name: competition?.name,
        },
    ];

    const intl = new Intl.NumberFormat("fr-FR", { maximumSignificantDigits: 3 });

    if (loading) {
        return <LoadingWithImage />;
    }

    if (!competition) {
        return <Page404 />;
    }

    if (errorGetCompetitions || errorGetVintages || errorGetRandomCompetition) {
        return <Page500Front />;
    }

    return (
        <>
            <Helmet
                title={competition?.name}
                content={`${competition?.name} : découvrez les résultats de ce prestigieux concours sur Bordeaux et ses Vins. Découvrez les vins primés selon des critères rigoureux de sélection. Apprenez-en plus sur l'histoire et les modalités de ce concours renommé dans le monde du vin.`}
            />

            <FrontContentTop
                backgroundImage={bg}
                links={breadcrumbs}
                previousPage={{
                    name: t`Tous les concours`,
                    path: "/organismes-vignoble-bordelais/concours",
                }}
            >
                <FrontTitle className={styles.frontTitle} title={competition?.name} />
                <FrontMainContainer
                    styleCardContent
                    className={styles.main_container}
                    style={{
                        backgroundImage: `url(${fioriture1}), url(${fioriture3})`,
                        backgroundPositionY: "bottom, center",
                        backgroundPositionX: "right, right",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    <Box className={styles.container_card}>
                        <Box className={styles.socialMedia}>
                            <SocialMediaIcons socialMedias={competition?.socialMedias} />
                        </Box>
                        <Box className={styles.container_content_top}>
                            {[
                                isNotEmpty(competition?.presentation),
                                competition?.medals?.length > 0 || competition?.medalPercentage,
                                isNotEmpty(competition?.selectionCriteria),
                                isNotEmpty(competition?.moreInformations),
                                competition?.members?.length > 0,
                            ].some(Boolean) && (
                                <Box className={styles.container_content_primary}>
                                    <EstateTitleSemiUnderlined
                                        title={t`Présentation du concours`}
                                        heightAfter={32}
                                        style={{ marginTop: "0px" }}
                                        lowBar={{ width: 75 }}
                                        borderColor={theme.palette.primary.main}
                                    />

                                    <Box>
                                        <Typography component="div" variant="body2" className={styles.content_text}>
                                            {getTextLength(competition?.presentation) > 1690 ? (
                                                <ExpandableFormattedTextBox
                                                    value={competition?.presentation}
                                                    truncatedValue={`${competition?.presentation?.slice(0, 1690)}...`}
                                                />
                                            ) : (
                                                parseHtml(competition?.presentation, "body2")
                                            )}
                                        </Typography>

                                        {(competition?.medals?.length > 0 || competition?.medalPercentage) && (
                                            <Box style={{ marginTop: "21px" }}>
                                                <Box className={styles.contact_title}>
                                                    <Box className={styles.contact_image_container}>
                                                        <img className={styles.contact_media} src={chiffres} />
                                                    </Box>
                                                    <Box>
                                                        <Typography variant="h4" className={styles.title}>
                                                            <Trans>Chiffres clés</Trans>
                                                        </Typography>
                                                    </Box>
                                                </Box>

                                                {width < 960 ? (
                                                    <ChallengeCarousel
                                                        medals={competition?.medals}
                                                        visibleSlides={setCarouselvisibleSlidesChiffreDatas()}
                                                        type="keyFigures"
                                                        height={110}
                                                        width={110}
                                                        medalPercentage={competition?.medalPercentage}
                                                        medalTotal={competition?.totalMedals}
                                                        medalMaxYear={competition?.maxYear}
                                                    />
                                                ) : (
                                                    <Box className={styles.medalsBox}>
                                                        {/* MEDAL PERCENTAGE */}
                                                        {competition?.medalPercentage && (
                                                            <Box
                                                                className={styles.content_container}
                                                                style={{
                                                                    boxSizing: "borderBox",
                                                                    backgroundColor: "#FFFFFF",
                                                                    border: "3px solid rgba(145,88,104)",
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant="h3"
                                                                    className={styles.carousel_item_number}
                                                                    style={{ color: "#360318" }}
                                                                >
                                                                    {intl.format(competition?.medalPercentage)} %
                                                                </Typography>
                                                                <Typography
                                                                    variant="body2"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        maxWidth: "85%",
                                                                        color: "#360318",
                                                                    }}
                                                                >
                                                                    <Trans>de médaillés</Trans>
                                                                </Typography>
                                                            </Box>
                                                        )}

                                                        {/* TOTAL MEDAL */}
                                                        {competition?.totalMedals && competition?.maxYear && (
                                                            <Box
                                                                className={styles.content_container}
                                                                style={{
                                                                    boxSizing: "borderBox",
                                                                    backgroundColor: theme.palette.primary.light,
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant="h3"
                                                                    className={styles.carousel_item_number}
                                                                    style={{ color: theme.palette.common.white }}
                                                                >
                                                                    {competition?.totalMedals}
                                                                </Typography>
                                                                <Typography
                                                                    variant="body2"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        maxWidth: "85%",
                                                                        color: theme.palette.common.white,
                                                                    }}
                                                                >
                                                                    <Trans>
                                                                        crus médaillés en {competition?.maxYear}
                                                                    </Trans>
                                                                </Typography>
                                                            </Box>
                                                        )}

                                                        {/* ALL MEDALS */}
                                                        {competition?.medals?.length > 0 &&
                                                            competition?.medals?.map((el, i) => (
                                                                <Box
                                                                    key={i}
                                                                    className={styles.content_container}
                                                                    style={{
                                                                        boxSizing: "borderBox",
                                                                        backgroundColor: el.bgColor ?? "transparent",
                                                                        border: "none",
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        variant="h3"
                                                                        className={styles.carousel_item_number}
                                                                        style={{
                                                                            color: el.fontColor,
                                                                        }}
                                                                    >
                                                                        {el.number}
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="body2"
                                                                        style={{
                                                                            textAlign: "center",
                                                                            maxWidth: "85%",
                                                                            color: el.fontColor,
                                                                        }}
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                fontWeight: "bold",
                                                                                textTransform: "uppercase",
                                                                            }}
                                                                        >
                                                                            {el.name}{" "}
                                                                        </span>
                                                                        {el.year ? t`en ${el.year}` : t`de médaillés`}
                                                                    </Typography>
                                                                </Box>
                                                            ))}
                                                    </Box>
                                                )}
                                            </Box>
                                        )}

                                        {isNotEmpty(competition?.selectionCriteria) && (
                                            <>
                                                <Box className={styles.container_title_secondary}>
                                                    <EstateTitleSemiUnderlined
                                                        title={t`Les critères de sélection`}
                                                        heightAfter={32}
                                                        style={{ marginTop: "0px" }}
                                                        lowBar={{ width: 75 }}
                                                        borderColor={theme.palette.primary.main}
                                                    />
                                                </Box>

                                                <Typography
                                                    component="div"
                                                    variant="body2"
                                                    className={styles.content_text_secondary}
                                                >
                                                    {getTextLength(competition?.selectionCriteria) > 1690 ? (
                                                        <ExpandableFormattedTextBox
                                                            value={competition?.selectionCriteria}
                                                            truncatedValue={`${competition?.selectionCriteria?.slice(
                                                                0,
                                                                1690
                                                            )}...`}
                                                        />
                                                    ) : (
                                                        parseHtml(competition?.selectionCriteria, "body2")
                                                    )}
                                                </Typography>
                                            </>
                                        )}

                                        {isNotEmpty(competition?.moreInformations) && (
                                            <>
                                                <Box className={styles.infos_title}>
                                                    <Box className={styles.contact_image_container}>
                                                        <ControlPointIcon
                                                            style={{
                                                                color: theme.palette.primary.light,
                                                                fontSize: "21px",
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <Typography variant="h4">
                                                            <Trans>Informations complémentaires</Trans>
                                                        </Typography>
                                                    </Box>
                                                </Box>

                                                <Typography variant="body2" className={styles.content_text_secondary}>
                                                    {parseHtml(competition?.moreInformations, "body2")}
                                                </Typography>
                                            </>
                                        )}

                                        {competition?.members?.length > 0 && (
                                            <Box style={{ display: width >= 960 ? "none" : "block" }}>
                                                <Box className={styles.contact_title}>
                                                    <Box className={styles.contact_image_container}>
                                                        <img className={styles.contact_media} src={contacts} />
                                                    </Box>
                                                    <Box>
                                                        <Typography variant="h4">
                                                            <Trans>Contacts</Trans>
                                                        </Typography>
                                                    </Box>
                                                </Box>

                                                {width < 960 && (
                                                    <Box style={{ display: "flex", flexDirection: "column" }}>
                                                        {competition?.members?.map((e, i) => (
                                                            <AddressBlock
                                                                key={i}
                                                                data={e}
                                                                boxStyle={{ height: "auto", width: "auto" }}
                                                            />
                                                        ))}
                                                    </Box>
                                                )}
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            )}

                            <Box className={styles.container_content_secondary}>
                                <Box className={styles.container_infos_right}>
                                    {[
                                        competition?.lastDate,
                                        competition?.nextDateYear,
                                        competition?.nextDate,
                                        competition?.nextDateUnknow,
                                    ].some(Boolean) && (
                                        <Box className={styles.center_responsive}>
                                            <ChallengeSummary
                                                lastDateYear={
                                                    competition?.lastDate && moment(competition?.lastDate).year()
                                                }
                                                lastDate={
                                                    competition?.lastDate &&
                                                    new Date(competition?.lastDate).toLocaleDateString(locale, {
                                                        year: "numeric",
                                                        month: "long",
                                                        day: "numeric",
                                                    })
                                                }
                                                nextDateYear={
                                                    competition?.nextDateYear ?? moment(competition?.nextDate).year()
                                                }
                                                nextDate={
                                                    competition?.isNextDateKnow
                                                        ? new Date(competition?.nextDate).toLocaleDateString(locale, {
                                                              year: "numeric",
                                                              month: "long",
                                                              day: "numeric",
                                                          })
                                                        : competition?.nextDateUnknow
                                                }
                                                name={competition?.name}
                                                address={competition?.address}
                                            />
                                        </Box>
                                    )}
                                    {competition?.logo?.twicpicURL && (
                                        <Box className={styles.image_container}>
                                            <img
                                                src={competition?.logo?.twicpicURL}
                                                className={styles.image_badges}
                                                alt="badge"
                                            />
                                        </Box>
                                    )}

                                    {competition?.members?.length > 0 && (
                                        <Box
                                            className={styles.contact_container}
                                            style={{ display: width < 960 ? "none" : "block" }}
                                        >
                                            <Box className={styles.contact_title}>
                                                <Box className={styles.contact_image_container}>
                                                    <img className={styles.contact_media} src={contacts} />
                                                </Box>
                                                <Box>
                                                    <Typography variant="h4">
                                                        <Trans>Contacts</Trans>
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            <Box style={{ display: "flex", flexDirection: "column" }}>
                                                {competition?.members?.map((e, i) => (
                                                    <AddressBlock
                                                        key={i}
                                                        data={e}
                                                        boxStyle={{ height: "auto" }}
                                                        titleStyle={{ marginTop: width < 960 ? 21 : 0 }}
                                                    />
                                                ))}
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </FrontMainContainer>

                {vintages?.length > 0 && (
                    <Box className={styles.container_other_rankings}>
                        <SwiperWrapper title={t`Découvrez des vins médaillés par ce concours`}>
                            {vintages?.map((vintage, i) => {
                                const key = `challenge_rewards-${i}`;
                                return <ChallengeVintageCard key={key} vintage={vintage} />;
                            })}
                        </SwiperWrapper>
                    </Box>
                )}
            </FrontContentTop>

            {otherChallenges?.length > 0 && (
                <Box className={styles.container_other_rankings}>
                    <SwiperWrapper title={t`D'autres concours des vins bordelais`} dark>
                        {otherChallenges?.map((e, i) => {
                            const key = `challenge_others-${i}`;
                            return (
                                <ChallengeEntityCard
                                    key={key}
                                    name={e.name}
                                    logo={e.logo}
                                    totalMedals={e.totalMedals}
                                    maxYear={e.maxYear}
                                />
                            );
                        })}
                    </SwiperWrapper>
                </Box>
            )}
        </>
    );
};

export default ChallengePage;
