import FrontContentTop from "Front/FrontContentTop";
import FrontMainContainer from "Front/FrontMainContainer";
import FrontTitle from "Front/FrontTitle";
import theme from "../globalTheme";
import SectionCGU from "Components/cgu/SectionCGU";
import { Link } from "react-router-dom";
import { t, Trans } from "@lingui/macro";
import Helmet from "Components/Helmet/Helmet";
import { useCustomHistory } from "hooks";
import { feretAddress, feretCEO, feretPostalCode } from "config/constants";

const LegalNoticePage = () => {
    const { addLocale } = useCustomHistory();

    const datas = [
        {
            title: t`Éditeur du site`,
            body: [
                <>
                    <Trans>
                        <strong>Éditions Féret</strong>, SARL au capital de 50000€, immatriculée au RCS de Bordeaux sous
                        le numéro B 388 807 174, et dont le siège social est {feretAddress} {feretPostalCode}
                    </Trans>
                </>,
                t`Email : contact@feret.com`,
                t`Tel : 05 56 13 79 95`,
                t`Directeur de la publication : ${feretCEO}`,
            ],
        },
        {
            title: t`Hébergeur`,
            body: [
                [
                    t`IONOS 7, place de la gare, BP 70109 57201 Sarreguines Cedex`,
                    t`AWS Europe, 5 rue Plaetis, Luxembourg`,
                ],
                <>
                    <Trans>
                        Images et illustrations : voir nos{" "}
                        <Link style={{ color: theme.palette.primary.dark }} to={addLocale("/confidentialite-CGU")}>
                            CGU
                        </Link>
                    </Trans>
                </>,
            ],
        },
    ];

    return (
        <>
            <Helmet title={t`Mentions légales`} />

            <FrontContentTop backgroundColor={theme.palette.primary.dark}>
                <FrontTitle typoVariant="h2" title={t`Mentions légales`} />

                <FrontMainContainer styleCardContent style={{ backgroundImage: "transparent" }} hideFloritureTopImg>
                    {datas?.map((data, index) => (
                        <SectionCGU
                            key={`${index + 1}-${data.title.replaceAll(" ", "_")}`}
                            number={null}
                            title={data.title}
                            body={data.body}
                            titleStyle={{ marginBottom: 25 }}
                        />
                    ))}
                </FrontMainContainer>
            </FrontContentTop>
        </>
    );
};

LegalNoticePage.propTypes = {};

export default LegalNoticePage;
