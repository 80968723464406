import { useState, useRef } from "react";
import PropTypes from "prop-types";

import { Box, SvgIcon, Hidden, Avatar, makeStyles, ClickAwayListener } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    primaryBox: {
        display: "flex",
        flexDirection: "column",
    },
    primaryBox__wrapper: (props) => ({
        display: "flex",
        alignItems: "center",

        width: "fit-content",
        maxHeight: "50px",
        borderRadius: "10px",

        backgroundColor: props.backgroundColor ? props.backgroundColor : theme.palette.common.white,
        color: props.color ? props.color : theme.palette.common.black,

        padding: "3px 6px",
        cursor: "pointer",
    }),
    permanentElement__wrapper: {
        display: "flex",
    },
    primaryIcon__wrapper: {
        display: "flex",
        alignItems: "center",
        padding: "8px 8px",
    },
    avatar: (props) => ({
        backgroundColor: !props.avatarImage && theme.palette.primary.dark,
        width: 30,
        height: 30,
    }),
    secondaryElement__wrapper: {},
    statusIndicator__wrapper: (props) => ({
        display: "flex",
        alignItems: "center",
        padding: "6px 6px",
        color: theme.palette.secondary.dark,
        transition: "transform .2s cubic-bezier(0.4, 0, 0.2, 1)",
        transform: props.boxOpen ? "rotate(180deg)" : "rotate(0deg)",
    }),
    openCard: {
        position: "relative",
    },
    openCard__wrapper: (props) => ({
        zIndex: 5,

        position: "absolute",
        left: props.openCardJustify === "left" ? 0 : null,
        right: props.openCardJustify === "right" ? 0 : null,

        width: "-webkit-fill-available",

        borderRadius: "10px",

        backgroundColor: props.backgroundColor ? props.backgroundColor : theme.palette.common.white,
        color: props.color ? props.color : theme.palette.common.black,

        padding: "8px 8px",
        marginTop: props.openCardMarginTop ? `${props.openCardMarginTop}px` : "32px",

        display: "none",
        opacity: 0,
        transform: "translateY(-32px)",

        [theme.breakpoints.down("md")]: {
            width: "fit-content",
        },
    }),

    "@global": {
        "@keyframes aniOpenCard": {
            "0%": {
                transform: "translateY(-32px)",
                opacity: 0,
            },
            "100%": {
                transform: "translateY(-26px)",
                opacity: 1,
            },
        },
        "@keyframes aniCloseCard": {
            "0%": {
                transform: "translateY(-26px)",
                opacity: 1,
            },
            "100%": {
                transform: "translateY(-32px)",
                opacity: 0,
            },
        },
    },
}));

const AccordionSplit = (props) => {
    const [boxOpen, setBoxOpen] = useState(false);

    const cardHidden = useRef(null);

    const styles = useStyles({ ...props, boxOpen });

    const boxHandleChange = () => {
        setBoxOpen(!boxOpen);
        if (boxOpen) {
            cardHidden.current.style.setProperty("display", "none");
            cardHidden.current.style.animation = "aniCloseCard .2s ease-in forwards";
        } else {
            cardHidden.current.style.setProperty("display", "block");
            cardHidden.current.style.animation = "aniOpenCard .2s ease-out forwards";
        }
    };

    const setBoxClose = () => {
        setBoxOpen(false);
        cardHidden.current.style.setProperty("display", "none");
        cardHidden.current.style.animation = "aniCloseCard .2s ease-in forwards";
    };

    return (
        <ClickAwayListener
            onClickAway={() => {
                setBoxClose();
            }}
        >
            <Box style={{ width: "fit-content" }}>
                <Box className={styles.primaryBox}>
                    <Box
                        className={styles.primaryBox__wrapper}
                        onClick={boxHandleChange}
                        boxShadow={props.elevationPrimaryCard}
                    >
                        <Box className={styles.permanentElement__wrapper}>
                            {props.primaryIcon ? (
                                <Box className={styles.primaryIcon__wrapper}>
                                    <Avatar className={styles.avatar} image={props.primaryIcon} />
                                </Box>
                            ) : null}
                            {props.primaryElement}
                        </Box>
                        <Hidden mdDown>
                            <Box className={styles.secondaryElement__wrapper}>{props.secondaryElement}</Box>
                        </Hidden>
                        {props.expandIcon ? (
                            <Box className={styles.statusIndicator__wrapper}>
                                <SvgIcon style={{ fontSize: "20px" }}>
                                    <path d="M0 0h24v24H0z" fill="none" />
                                    <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
                                </SvgIcon>
                            </Box>
                        ) : null}
                    </Box>
                </Box>
                <Box className={styles.openCard}>
                    <Box ref={cardHidden} className={styles.openCard__wrapper} boxShadow={props.elevationSecondaryCard}>
                        {props.openCardContent}
                    </Box>
                </Box>
            </Box>
        </ClickAwayListener>
    );
};

AccordionSplit.propTypes = {
    // Colors:
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    // Contents:
    expandIcon: PropTypes.bool,
    openCardContent: PropTypes.any,
    primaryIcon: PropTypes.any,
    primaryElement: PropTypes.any,
    secondaryElement: PropTypes.any,
    // Layout
    openCardJustify: PropTypes.string,
    openCardMarginTop: PropTypes.number,
    // Shadow
    elevationPrimaryCard: PropTypes.string,
    elevationSecondaryCard: PropTypes.number,
};

export default AccordionSplit;
