import React from "react";
import PropTypes from "prop-types";
import TunnelScrollingPopin from "../../Containers/TunnelScrollingPopin";
import hugeImageEstate from "../../assets/SubscriptionPage/preview_estate_page.png";
import { SUBSCRIPTION_TYPES } from "config/constants";

const EstateZoomPopin = (props) => (
    <TunnelScrollingPopin maxWidth="95%" maxHeight="90%" clickableElement={props.clickableElement} backButton>
        {/* GRATUIT */}
        {props.subscriptionStateSelectedPack === SUBSCRIPTION_TYPES.basic && (
            <img src={hugeImageEstate} alt="estate_page_example" style={{ width: "100%" }} />
        )}
        {/* ESSENTIEL */}
        {props.subscriptionStateSelectedPack === SUBSCRIPTION_TYPES.essential && (
            <img src={hugeImageEstate} alt="estate_page_example" style={{ width: "100%" }} />
        )}
        {/* PREMIUM */}
        {props.subscriptionStateSelectedPack === SUBSCRIPTION_TYPES.premium && (
            <img src={hugeImageEstate} alt="estate_page_example" style={{ width: "100%" }} />
        )}
    </TunnelScrollingPopin>
);

EstateZoomPopin.propTypes = {
    clickableElement: PropTypes.any,
    subscriptionStateSelectedPack: PropTypes.number,
};

export default EstateZoomPopin;
