import { Box, makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { object } from "prop-types";
import { Helmet } from "react-helmet";
import { Trans, t } from "@lingui/macro";
import LanguageSelector from "Components/Accordions/LanguageSelector";
import { isNotNull, urlFormatted } from "helpers/helpers";

import logo from "assets/feret_logomark_50.svg";
import theme from "globalTheme";
import { useCustomHistory } from "hooks";

const useStyles = makeStyles(() => ({
    container: {
        zIndex: 15,
        position: "absolute",
        top: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "#FCF6F6",
        color: theme.palette.primary.dark,
        padding: "2em 0",
    },
    content: {
        display: "block",
        width: "70%",
        margin: "0 auto",
        textAlign: "center",
        [theme.breakpoints.only("xs")]: {
            width: "100%",
        },
        [theme.breakpoints.up("sm")]: {
            width: "50%",
        },
        [theme.breakpoints.up("md")]: {
            width: "40%",
        },
        [theme.breakpoints.up("lg")]: {
            width: "40%",
        },
        [theme.breakpoints.up("xl")]: {
            width: "30%",
        },
    },
    line: {
        display: "flex",
        padding: "0 4em",
        [theme.breakpoints.only("xs")]: {
            padding: "0 1em",
        },
        [theme.breakpoints.only("sm")]: {
            padding: "0 2em",
        },
    },
    body4: {
        fontFamily: "Mansalva",
        color: theme.palette.primary.dark,
        textAlign: "left",
        marginBottom: "0.5em",
        padding: "0 4em",
        [theme.breakpoints.only("xs")]: {
            padding: "0 1em",
        },
        [theme.breakpoints.only("sm")]: {
            padding: "0 2em",
        },
    },
    flag: {
        position: "absolute",
        right: "40px",
        top: "20px",
        [theme.breakpoints.only("xs")]: {
            right: "10px",
            top: "10px",
        },
        [theme.breakpoints.only("sm")]: {
            right: "20px",
        },
    },
}));

const Separator = () => (
    <Box style={{ width: "100%", height: "1px", backgroundColor: "#D0BDC3", margin: "0.25em auto" }} />
);

export const QrcodePage = (props) => {
    const styles = useStyles();
    const { addLocale } = useCustomHistory();

    const {
        name,
        year,
        cuvee,
        wineEntityName,
        label,
        energy,
        energyType,
        fats,
        saturatedFattyAcids,
        glucides,
        sugars,
        protein,
        salt,
        ingredients,
        wineShortId,
        wineEntityShortId,
    } = props.vintage ?? {};

    const listIngredients = () => {
        if (ingredients.length > 0 && ingredients[0].ingredient) {
            let list = "";
            ingredients.map((e) => {
                if (e.isAllergen == true) {
                    list += "<b>" + e.ingredient + "</b>, ";
                } else {
                    list += e.ingredient + ", ";
                }
            });
            list = list.slice(0, list.length - 2);
            return list;
        } else {
            return t`non renseigné`;
        }
    };

    const checkVar = (data, type) => {
        if (isNotNull(data)) {
            return `${data} ${type}`;
        } else {
            return t`non renseigné`;
        }
    };

    return (
        <>
            <Helmet>
                <title>{`Infos nutritionnelles ${name} ${year} - Bordeaux et ses Vins`}</title>
            </Helmet>
            <Box className={styles.container}>
                <Box className={styles.flag}>
                    <LanguageSelector scroll />
                </Box>

                <Box className={styles.content}>
                    <Typography variant="h2">{name}</Typography>
                    <Typography variant="h4">{year}</Typography>

                    <Box style={{ width: "40%", height: "1px", backgroundColor: "#D0BDC3", margin: "1em auto" }} />

                    <Typography variant="h5" style={{ fontWeight: "bold" }}>
                        <Trans>INFORMATIONS NUTRITIONNELLES</Trans>
                    </Typography>
                    <Typography variant="body2">{label}</Typography>

                    <Box style={{ backgroundColor: "white", padding: "0.5em 0", margin: "1em 0" }}>
                        <Typography variant="body2" style={{ marginBottom: "0.5em" }}>
                            <Trans>Ingrédients :</Trans>
                        </Typography>
                        <Typography variant="body2">
                            <div dangerouslySetInnerHTML={{ __html: listIngredients() }} />
                        </Typography>
                    </Box>

                    <Typography variant="h6" className={styles.body4}>
                        <Trans>Pour 100 ml</Trans>
                    </Typography>

                    <Box className={styles.line}>
                        <Typography variant="body2" style={{ flex: "1 1", fontWeight: "bold", textAlign: "left" }}>
                            <Trans>Énergie</Trans>
                        </Typography>
                        <Typography variant="body2" style={{ flex: "1 1", textAlign: "right" }}>
                            {energyType == "kJ"
                                ? `${checkVar(((energy || 0) / 4.184).toFixed(2), "kCal")} | ${checkVar(
                                      (energy || 0).toFixed(2),
                                      "kJ"
                                  )}`
                                : `${checkVar((energy || 0).toFixed(2), "kCal")} | ${checkVar(
                                      ((energy || 0) * 4.184).toFixed(2),
                                      "kJ"
                                  )}`}
                        </Typography>
                    </Box>

                    <Separator />

                    <Box className={styles.line}>
                        <Typography variant="body2" style={{ flex: "1 1", fontWeight: "bold", textAlign: "left" }}>
                            <Trans>Matières grasses</Trans>
                        </Typography>
                        <Typography variant="body2" style={{ flex: "1 1", textAlign: "right", fontWeight: "bold" }}>
                            {checkVar(fats, "g")}
                        </Typography>
                    </Box>
                    <Box className={styles.line}>
                        <Typography variant="body2" style={{ flex: "1 1", textAlign: "left" }}>
                            <Trans>dont acides gras saturés</Trans>
                        </Typography>
                        <Typography variant="body2" style={{ flex: "1 1", textAlign: "right" }}>
                            {checkVar(saturatedFattyAcids, "g")}
                        </Typography>
                    </Box>

                    <Separator />

                    <Box className={styles.line}>
                        <Typography variant="body2" style={{ flex: "1 1", fontWeight: "bold", textAlign: "left" }}>
                            <Trans>Glucides</Trans>
                        </Typography>
                        <Typography variant="body2" style={{ flex: "1 1", textAlign: "right", fontWeight: "bold" }}>
                            {checkVar(glucides, "g")}
                        </Typography>
                    </Box>
                    <Box className={styles.line}>
                        <Typography variant="body2" style={{ flex: "1 1", textAlign: "left" }}>
                            <Trans>dont sucres</Trans>
                        </Typography>
                        <Typography variant="body2" style={{ flex: "1 1", textAlign: "right" }}>
                            {checkVar(sugars, "g")}
                        </Typography>
                    </Box>

                    <Separator />

                    <Box className={styles.line}>
                        <Typography variant="body2" style={{ flex: "1 1", fontWeight: "bold", textAlign: "left" }}>
                            <Trans>Protéines</Trans>
                        </Typography>
                        <Typography variant="body2" style={{ flex: "1 1", textAlign: "right" }}>
                            {checkVar(protein, "g")}
                        </Typography>
                    </Box>

                    <Separator />

                    <Box className={styles.line}>
                        <Typography variant="body2" style={{ flex: "1 1", fontWeight: "bold", textAlign: "left" }}>
                            <Trans>Sel</Trans>
                        </Typography>
                        <Typography variant="body2" style={{ flex: "1 1", textAlign: "right" }}>
                            {checkVar(salt, "g")}
                        </Typography>
                    </Box>

                    <Separator />

                    <Box style={{ backgroundColor: "white", margin: "1em 0", padding: "0.5em 0", fontFamily: "Lato" }}>
                        <Link
                            to={addLocale(
                                `/${urlFormatted(wineEntityShortId, wineEntityName)}/vins/${urlFormatted(
                                    wineShortId,
                                    name
                                )}/${urlFormatted(year, cuvee)}`
                            )}
                            style={{ color: theme.palette.primary.dark }}
                        >
                            <Trans>Voir le millésime sur bordeaux.feret.com</Trans>
                        </Link>
                    </Box>

                    <Box>
                        <img src={logo} alt="Logo Feret" style={{ width: "20%" }} />
                    </Box>
                </Box>
            </Box>
        </>
    );
};

QrcodePage.propTypes = {
    vintage: object,
};
