import { t, Trans } from "@lingui/macro";
import { Box, makeStyles, Typography } from "@material-ui/core";
import PropTypes, { bool } from "prop-types";
import React, { useState } from "react";

import appellationIcon from "../../../assets/icons/propertiesInfosIcons/appellation.svg";
import certificationIcon from "../../../assets/icons/propertiesInfosIcons/certifications.svg";
import commerceIcon from "../../../assets/icons/propertiesInfosIcons/commerce.svg";
import communeIcon from "../../../assets/icons/propertiesInfosIcons/commune.svg";
import membreIcon from "../../../assets/icons/propertiesInfosIcons/membre-de.svg";
import regionIcon from "../../../assets/icons/propertiesInfosIcons/region.svg";
import vignobleIcon from "../../../assets/icons/propertiesInfosIcons/vignoble.svg";
import ownerIcon from "../../../assets/icons/propertiesInfosIcons/owner.svg";
import operatorIcon from "../../../assets/icons/propertiesInfosIcons/operator.svg";
import zoom from "../../../assets/zoom.svg";
import Tooltip from "../../../Components/Tooltips/Tooltip";
import LabelsFrontPopin from "../../../FrontEstate/Popins/LabelsFrontPopin";
import VineyardDetailsPopin from "../../../FrontEstate/Popins/VineyardDetailsPopin";
import getConnectors, { commaInFr, firstLetterUppercase, urlize } from "../../../helpers/helpers";
import SummaryElement from "./SummaryElement";

const useStyles = makeStyles((theme) => ({
    propertySummary: {
        ...theme.front.padding.propertySummary,
        display: "flex",
        flexDirection: "column",
        width: "inherit",
        backgroundColor: "white",
        border: "0.4px solid",
        borderColor: theme.palette.secondary.dark,
        borderRadius: "5px",
        gap: "8px",
        height: "fit-content",
    },
    zoom: {
        cursor: "pointer",
    },
    certificationTypo: {
        ...theme.front.typography.labeur.dark,
        display: "flex",
        flexDirection: "column",
        color: theme.palette.primary.dark,
    },
    title: {
        fontWeight: 700,
        marginRight: "10px",
    },
    vineSurfaceTypo: {
        color: theme.palette.primary.dark,
        [theme.breakpoints.only("xs")]: {
            fontSize: "12px",
        },
    },
    vineSurfaceTypoLink: {
        ...theme.front.typography.label.darkLight,
        cursor: "pointer",
        marginRight: "2px",
        marginLeft: "2px",
    },
    arrowBox: {
        paddingBottom: 0,
    },
}));

const PropertySummary = (props) => {
    const styles = useStyles();
    const [vineyardPopinOpen, setVineyardPopinOpen] = useState(false);
    const [labelsPopinOpen, setLabelsPopinOpen] = useState(false);

    const {
        mainAOCName,
        commune,
        region,
        sousRegion,
        vineSurfaceArea,
        certificationsArray,
        membersOf,
        marketing,
        estateName,
        wineEntityId,
        totalSurfaceArea,
        owners,
        operators,
    } = props;

    const associations = membersOf?.map((item) => item);

    const marketingValues = marketing?.map((item) => {
        return item;
    });

    const getCertificationsTitle = () => {
        return (
            <Box style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h4" className={styles.title}>
                    <Trans>Certifications</Trans>
                </Typography>
                <Tooltip fontSize="11px" title={t`Voir plus de détails`} color="primary" arrow>
                    <img src={zoom} className={styles.zoom} onClick={() => setLabelsPopinOpen(true)} />
                </Tooltip>
            </Box>
        );
    };

    const getVineyardTitle = () => {
        return (
            <Box style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h4" className={styles.title}>
                    <Trans>Vignoble</Trans>
                </Typography>
                <Tooltip fontSize="11px" title={t`Voir plus de détails`} color="primary" arrow>
                    <img src={zoom} className={styles.zoom} onClick={() => setVineyardPopinOpen(true)} />
                </Tooltip>
            </Box>
        );
    };

    const certifications = () => {
        let certifs = certificationsArray?.map((item, i) => (
            <Box key={i} className={styles.certificationTypo}>
                {item.name} {item?.isInConversion ? ": " + t`en conversion` : ""}
            </Box>
        ));
        return certifs;
    };

    const getOwners = () => {
        const ownerDisplayNames = owners.map(({ displayName }) => displayName);
        return getConnectors(ownerDisplayNames);
    };

    const getOperators = () => {
        let arr = [];
        for (let i = 0; i < operators.length; i++) {
            const element = operators[i];
            arr.push(element.displayName);
        }
        return getConnectors(arr);
    };

    const getAssociations = () => {
        return associations?.map((item, i) => (
            <Box key={i} className={styles.certificationTypo}>
                {`· ${item}`}
            </Box>
        ));
    };

    const wineSurfaceArea = () => {
        const surface = vineSurfaceArea?.toFixed(2).toString();
        return commaInFr(surface, props.locale);
    };
    const vineyardGroup = () => {
        return (
            <>
                {vineSurfaceArea && (
                    <Typography className={styles.certificationTypo}>
                        <Trans>Vignes : {wineSurfaceArea()} ha</Trans>
                    </Typography>
                )}
            </>
        );
    };

    return (
        <Box className={styles.propertySummary}>
            {region && <SummaryElement icon={regionIcon} title={t`Région`} subtitle={region} />}
            {sousRegion && <SummaryElement icon={props.subregionImage} title={t`Sous-région`} subtitle={sousRegion} />}
            {vineyardPopinOpen ? (
                <VineyardDetailsPopin
                    locale={props.locale}
                    wineEntityId={props.wineEntityId}
                    totalSurfaceArea={totalSurfaceArea}
                    open={vineyardPopinOpen}
                    onClose={() => setVineyardPopinOpen(false)}
                />
            ) : (
                ""
            )}
            {labelsPopinOpen ? (
                <LabelsFrontPopin
                    wineEntityId={wineEntityId}
                    estateName={estateName}
                    open={labelsPopinOpen}
                    onClose={() => setLabelsPopinOpen(false)}
                />
            ) : (
                ""
            )}
            {mainAOCName && <SummaryElement icon={appellationIcon} title={t`Appellation`} subtitle={mainAOCName} />}
            {commune && (
                <SummaryElement
                    icon={communeIcon}
                    title={t`Commune`}
                    subtitle={commune}
                    // linkTo={!props.standalone && `/commune/${urlize(commune)}`}
                />
            )}

            {owners && owners?.length !== 0 && (
                <SummaryElement icon={ownerIcon} title={t`Propriétaire`} subtitle={getOwners()} />
            )}

            {operators && operators?.length !== 0 && (
                <SummaryElement icon={operatorIcon} title={t`Exploitant`} subtitle={getOperators()} />
            )}
            {(vineSurfaceArea !== null || props.hasVineyardInformation) && (
                <SummaryElement icon={vignobleIcon} title={getVineyardTitle()} subtitle={vineyardGroup()} />
            )}
            {certificationsArray && certificationsArray?.length !== 0 && (
                <SummaryElement icon={certificationIcon} title={getCertificationsTitle()} subtitle={certifications()} />
            )}
            {membersOf && membersOf?.length !== 0 && (
                <SummaryElement icon={membreIcon} title={t`Membre de`} subtitle={getAssociations()} />
            )}
            {marketingValues && marketingValues?.length !== 0 && (
                <SummaryElement
                    icon={commerceIcon}
                    title={t`Commercialisation`}
                    subtitle={firstLetterUppercase(getConnectors(marketingValues))}
                />
            )}
        </Box>
    );
};

PropertySummary.propTypes = {
    mainAOCName: PropTypes.any,
    appellations: PropTypes.any,
    commune: PropTypes.any,
    region: PropTypes.any,
    sousRegion: PropTypes.any,
    vineSurfaceArea: PropTypes.any,
    certificationsArray: PropTypes.any,
    membersOf: PropTypes.any,
    marketing: PropTypes.any,
    vineyards: PropTypes.object,
    labels: PropTypes.array,
    estateName: PropTypes.string,
    totalSurfaceArea: PropTypes.number,
    hasVineyardInformation: PropTypes.bool,
    subregionImage: PropTypes.any,
    wineEntityId: PropTypes.string,
    locale: PropTypes.string,
    owners: PropTypes.array,
    operators: PropTypes.array,
    standalone: bool,
};

export default PropertySummary;
