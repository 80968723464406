import { node, any } from "prop-types";

export const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
};

TabPanel.propTypes = {
    children: node,
    index: any.isRequired,
    value: any.isRequired,
};
