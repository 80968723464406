import { Trans, t } from "@lingui/macro";
import { Box, makeStyles } from "@material-ui/core";
import Page500Front from "Components/ErrorPage/Page500Front";
import Loading from "Components/Loader/Loading";
import { SwiperWrapper } from "Front/SwiperCards";
import EstateTitleSemiUnderlined from "FrontEstate/Components/EstateTitledSemiUnderlined";
import HomeEstateCard from "Search/card/HomeEstateCard";
import estate from "assets/propriétéPage/estate.svg";
import { useGetCommonalitiesWineEntitiesQuery } from "generated/graphql";
import theme from "globalTheme";
import { useWindowDimensions } from "helpers/helpers";
import { string } from "prop-types";
import SubtitleCommonality from "../../Front/SubtitleCommonality";
import MoreCommonalityButton from "Front/MoreCommunalityButton";
const useStyles = makeStyles(() => ({
    container: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "1 / span 12",
        },
        [theme.breakpoints.up("sm")]: {
            padding: "0 16px",
        },
        [theme.breakpoints.up("xl")]: {
            width: "calc(1314px)",
            margin: "auto",
        },
    },
    main_container: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        gap: "1rem",
        backgroundColor: theme.palette.background.card,
        [theme.breakpoints.only("xs")]: {
            padding: "14px",
        },
    },
}));
const EstateCommunalities = (props) => {
    const styles = useStyles();
    const { width } = useWindowDimensions();
    const {
        data: { GetCommonalitiesWineEntities: commonalitiesWineEntities = [] } = {},
        loading: commonalitiesWineEntitiesLoading,
        error: commonalitiesWineEntitiesError,
    } = useGetCommonalitiesWineEntitiesQuery({
        variables: {
            wineEntityId: props.wineEntityId,
        },
    });

    if (commonalitiesWineEntitiesLoading) {
        return <Loading />;
    }
    if (commonalitiesWineEntitiesError) {
        return <Page500Front />;
    }
    return (
        <Box className={styles.main_container}>
            <Box className={styles.container}>
                <EstateTitleSemiUnderlined title={t`(Re)découvrez des propriétés de la région`} />
                {commonalitiesWineEntities?.byCommune?.result?.length > 0 && (
                    <>
                        <SubtitleCommonality
                            img={estate}
                            title={
                                <Trans>
                                    de la même {width >= 600 ? <br /> : ""} <b>commune</b>
                                </Trans>
                            }
                        />
                        <SwiperWrapper spaceBetween={0} slidesPerView="auto" loading={commonalitiesWineEntitiesLoading}>
                            {commonalitiesWineEntities?.byCommune?.result?.map((wineEntity, i) => {
                                return (
                                    <>
                                        <HomeEstateCard
                                            margin="1rem 0 0"
                                            arrayLoading={commonalitiesWineEntitiesLoading}
                                            key={i}
                                            estate={wineEntity}
                                            name={wineEntity?.wineEntityName}
                                            wineEntityId={wineEntity?.wineEntityId}
                                            appellation={wineEntity?.appellation}
                                            width="281px"
                                            height="158px"
                                        />
                                        {i === commonalitiesWineEntities?.byCommune?.result?.length - 1 &&
                                            commonalitiesWineEntities?.byCommune?.total > 10 && (
                                                <MoreCommonalityButton
                                                    pathname="/recherche/proprietes/resultats"
                                                    filter="communes"
                                                    value={wineEntity.INSEEcode}
                                                />
                                            )}
                                    </>
                                );
                            })}
                        </SwiperWrapper>
                    </>
                )}
                {commonalitiesWineEntities?.byAppellation?.result?.length > 0 && (
                    <>
                        <SubtitleCommonality
                            img={estate}
                            title={
                                <Trans>
                                    de la même {width >= 600 ? <br /> : ""} <b>appellation</b>
                                </Trans>
                            }
                        />
                        <SwiperWrapper spaceBetween={0} slidesPerView="auto" loading={commonalitiesWineEntitiesLoading}>
                            {commonalitiesWineEntities?.byAppellation?.result?.map((wineEntity, i) => {
                                return (
                                    <>
                                        <HomeEstateCard
                                            margin="1rem 0 0"
                                            arrayLoading={commonalitiesWineEntitiesLoading}
                                            key={i}
                                            estate={wineEntity}
                                            name={wineEntity?.wineEntityName}
                                            wineEntityId={wineEntity?.wineEntityId}
                                            appellation={wineEntity?.appellation}
                                            width="281px"
                                            height="158px"
                                        />
                                        {i === commonalitiesWineEntities?.byAppellation?.result?.length - 1 &&
                                            commonalitiesWineEntities?.byAppellation?.total > 10 && (
                                                <MoreCommonalityButton
                                                    pathname="/recherche/proprietes/resultats"
                                                    filter="appellation"
                                                    value={wineEntity.appellation}
                                                />
                                            )}
                                    </>
                                );
                            })}
                        </SwiperWrapper>
                    </>
                )}
                {commonalitiesWineEntities?.bySubregion?.result?.length > 0 && (
                    <>
                        <SubtitleCommonality
                            img={estate}
                            title={
                                <Trans>
                                    de la même {width >= 600 ? <br /> : ""} <b>sous-région</b>
                                </Trans>
                            }
                        />
                        <SwiperWrapper spaceBetween={0} slidesPerView="auto" loading={commonalitiesWineEntitiesLoading}>
                            {commonalitiesWineEntities?.bySubregion?.result?.map((wineEntity, i) => {
                                return (
                                    <>
                                        <HomeEstateCard
                                            margin="1rem 0 0"
                                            arrayLoading={commonalitiesWineEntitiesLoading}
                                            key={i}
                                            estate={wineEntity}
                                            name={wineEntity?.wineEntityName}
                                            wineEntityId={wineEntity?.wineEntityId}
                                            appellation={wineEntity?.appellation}
                                            width="281px"
                                            height="158px"
                                        />
                                        {i === commonalitiesWineEntities?.bySubregion?.result?.length - 1 &&
                                            commonalitiesWineEntities?.bySubregion?.total > 10 && (
                                                <MoreCommonalityButton
                                                    pathname="/recherche/proprietes/resultats"
                                                    filter="subregion"
                                                    value={wineEntity.subregion}
                                                />
                                            )}
                                    </>
                                );
                            })}
                        </SwiperWrapper>
                    </>
                )}
            </Box>
        </Box>
    );
};

EstateCommunalities.propTypes = {
    wineEntityId: string,
};

export default EstateCommunalities;
