import { Box, Breadcrumbs, makeStyles, Typography } from "@material-ui/core";
import KeyboardBackspaceRoundedIcon from "@material-ui/icons/KeyboardBackspaceRounded";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { array, object, string } from "prop-types";

import theme from "../../globalTheme";
import { useWindowDimensions } from "../../helpers/helpers";
import { useCustomHistory } from "hooks";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    breadcrumbs_container: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "1 / span 12",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "1 / span 12",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 12",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 12",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "2 / span 11",
        },
    },
    breadcrumbs_link: {
        color: theme.palette.common.white,
        textDecoration: "none",
        ...theme.typography.body2,
        "&:hover": {
            textDecoration: "underline",
        },
    },
}));

const BreadCrumbs = (props) => {
    const history = useCustomHistory();
    const styles = useStyles();
    const { width } = useWindowDimensions();

    const formatLinkName = (name) => (name === "Entre-deux-mers" ? "Entre-deux-Mers" : name);

    if (width >= 960) {
        return (
            <Breadcrumbs
                className={`${styles.breadcrumbs_container} ${props.className}`}
                separator={<NavigateNextIcon style={{ color: theme.palette.secondary.main, fontSize: "16px" }} />}
                aria-label="breadcrumb"
            >
                {props.links?.map((link, i) =>
                    i === props.links?.length - 1 ? (
                        <Typography key={i} style={{ color: theme.palette.common.white }} variant="body2">
                            {formatLinkName(link?.name)}
                        </Typography>
                    ) : (
                        <Link key={i} className={styles.breadcrumbs_link} to={history.addLocale(link.path)}>
                            {formatLinkName(link?.name)}
                        </Link>
                    )
                )}
            </Breadcrumbs>
        );
    }

    if (props.previousPage?.name) {
        return (
            <Box
                className={`${styles.breadcrumbs_container} ${props.className}`}
                style={{ display: "flex", alignItems: "center" }}
            >
                <KeyboardBackspaceRoundedIcon
                    style={{ color: theme.palette.common.white, fontSize: "20px", marginRight: "4px" }}
                />
                <Link className={styles.breadcrumbs_link} to={history.addLocale(props.previousPage?.path)}>
                    {props.previousPage?.name}
                </Link>
            </Box>
        );
    }

    return null;
};

BreadCrumbs.propTypes = {
    links: array,
    className: string,
    previousPage: object,
};

export default BreadCrumbs;
