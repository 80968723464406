import { Trans } from "@lingui/macro";
import { Accordion, AccordionDetails, AccordionSummary, Typography, makeStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import { array, bool, func } from "prop-types";
import { useState } from "react";
import { Link } from "react-router-dom";
import Loading from "../../Components/Loader/Loading";
import theme from "../../globalTheme";

const useStyles = makeStyles(() => ({
    accordionRoot: {
        background: "transparent",
        color: theme.palette.primary.dark,
        padding: "0",
        "&.MuiPaper-elevation1": {
            boxShadow: "unset",
        },
        "&.Mui-expanded": {
            margin: 0,
            backgroundColor: theme.palette.background.card,
        },
        "&:before": {
            content: "unset",
        },
        "&.MuiPaper-root": {
            "&.Mui-expanded": {
                backgroundColor: theme.palette.background.card,
            },
        },
    },
    mainAccordion: {
        "&.MuiPaper-elevation1": {
            "&:first-child": {
                paddingTop: "0.75rem",
            },
            "&:last-child": {
                paddingBottom: "0.75rem",
            },
            padding: "0 0 0 0.75rem",
        },
    },
    accordionSummary: {
        minHeight: "inherit",
        justifyContent: "space-between",
        padding: "0",

        "&.Mui-expanded": {
            minHeight: "inherit",
        },
    },
    nestedAccordionMenuItem: {},
    contentSummary: {
        margin: "0 !important",
        flexGrow: "inherit !important",
    },
    nestedMenuItems: {
        display: "flex",
        flexDirection: "column",
        padding: 0,
    },
    iconExpand: {
        padding: "0 0.75rem",
    },
    submenu: {
        borderLeft: `1px solid ${theme.palette.secondary.main}`,
        paddingLeft: "5px",
        marginLeft: "1rem",
        cursor: "pointer",
    },
    linkViewMore: {
        color: theme.palette.primary.dark,
        cursor: "pointer",
        textDecorationLine: "none",
        paddingLeft: 5,
    },
    removeDefaultStyle: {
        color: "inherit",
        textDecoration: "none",
    },
}));

const NestedAccordions = (props) => {
    const [expandedItemMenu, setExpandedItemMenu] = useState(false);
    const [expandedSubMenu, setExpandedSubMenu] = useState(false);
    const styles = useStyles();

    const handleChangeExpandedItemMenu = (panel, subMenu) => (event, isExpanded) => {
        if (subMenu) setExpandedItemMenu(isExpanded ? panel : false);
    };

    const handleChangeExpandedSubMenu = (panel, subMenu) => (event, isExpanded) => {
        if (subMenu) setExpandedSubMenu(isExpanded ? panel : false);
    };

    if (props.loadingMenuItems) {
        return <Loading />;
    }

    return props.datas?.map((data, i) => {
        if (data.loading) {
            return <Loading />;
        }

        if (!data.mainTitle) {
            return null;
        }

        const accordionKey = `main-accordion-${i}`;
        return (
            //first accordion
            <Accordion
                key={accordionKey}
                TransitionProps={{ unmountOnExit: true }}
                className={`${styles.accordionRoot} ${styles.mainAccordion}`}
                expanded={expandedItemMenu === `panel-${i}`}
                onChange={handleChangeExpandedItemMenu(`panel-${i}`, data?.menuItems)}
            >
                <AccordionSummary
                    classes={{ content: styles.contentSummary }}
                    className={styles.accordionSummary}
                    IconButtonProps={{
                        edge: "false",
                        classes: { root: styles.iconExpand },
                    }}
                    expandIcon={data?.menuItems && <ExpandMoreIcon style={{ color: theme.palette.primary.dark }} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    {data?.to ? (
                        <Typography
                            variant="h5"
                            style={{
                                margin: "6px 0",
                                fontWeight: expandedItemMenu === `panel-${i}` && 700,
                                textDecoration: "none",
                                color: "inherit",
                            }}
                            onClick={() => props.onClose()}
                            component={Link}
                            to={data?.to}
                        >
                            {data.mainTitle}
                        </Typography>
                    ) : (
                        <Typography
                            variant="h5"
                            style={{
                                margin: "6px 0",
                                fontWeight: expandedItemMenu === `panel-${i}` && 700,
                            }}
                        >
                            {data.mainTitle}
                        </Typography>
                    )}
                </AccordionSummary>
                <AccordionDetails className={styles.nestedMenuItems}>
                    {/* second accordion */}

                    {data?.viewMore && (
                        <Typography
                            variant="h5"
                            className={clsx(styles.linkViewMore, styles.removeDefaultStyle)}
                            style={{
                                display: "flex",
                                marginBottom: 3,
                                marginLeft: 3,
                            }}
                            onClick={() => props.onClose()}
                            component={Link}
                            to={data?.viewMore}
                        >
                            <Trans>Tout voir</Trans>
                        </Typography>
                    )}
                    {data?.menuItems?.map((menuItem, j) => {
                        let menuItemKey = `menuitem-accordion-${j}`;
                        return (
                            <Accordion
                                className={`${styles.accordionRoot} ${styles.nestedAccordionMenuItem}`}
                                key={menuItemKey}
                                TransitionProps={{ unmountOnExit: true }}
                                expanded={expandedSubMenu === `panel-${i}-${j}`}
                                onChange={handleChangeExpandedSubMenu(`panel-${i}-${j}`, menuItem.subMenus)}
                            >
                                <AccordionSummary
                                    style={{
                                        borderTopLeftRadius: "4px",
                                        borderBottomLeftRadius: "4px",
                                        backgroundColor:
                                            expandedSubMenu === `panel-${i}-${j}` && theme.palette.secondary.main,
                                    }}
                                    classes={{ content: styles.contentSummary }}
                                    className={styles.accordionSummary}
                                    IconButtonProps={{
                                        edge: "false",
                                        classes: { root: styles.iconExpand },
                                    }}
                                    expandIcon={
                                        menuItem?.subMenus?.length > 0 && (
                                            <ExpandMoreIcon style={{ color: theme.palette.primary.dark }} />
                                        )
                                    }
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    {menuItem.to ? (
                                        <Typography
                                            variant="h5"
                                            style={{
                                                margin: "5px 0px 5px 11px",
                                            }}
                                            onClick={() => props.onClose()}
                                            component={Link}
                                            to={menuItem.to}
                                            className={styles.removeDefaultStyle}
                                        >
                                            {menuItem.title}
                                        </Typography>
                                    ) : (
                                        <Typography
                                            variant="h5"
                                            style={{
                                                margin: "5px 0px 5px 11px",
                                                fontWeight: expandedSubMenu === `panel-${i}-${j}` && 700,
                                            }}
                                        >
                                            {menuItem.title}
                                        </Typography>
                                    )}
                                </AccordionSummary>
                                {menuItem?.subMenus?.length > 0 && (
                                    <AccordionDetails className={styles.nestedMenuItems}>
                                        {menuItem?.subMenus?.map((subMenu, k) => {
                                            let subMenuKey = `menuitem-accordion-${k}`;
                                            return (
                                                <Typography
                                                    variant="h6"
                                                    className={clsx(styles.submenu, styles.removeDefaultStyle)}
                                                    key={subMenuKey}
                                                    onClick={() => props.onClose()}
                                                    component={Link}
                                                    to={subMenu.to}
                                                >
                                                    {subMenu.title}
                                                </Typography>
                                            );
                                        })}
                                    </AccordionDetails>
                                )}
                            </Accordion>
                        );
                    })}
                </AccordionDetails>
            </Accordion>
        );
    });
};

NestedAccordions.propTypes = {
    datas: array,
    onClose: func,
    loadingMenuItems: bool,
};

export default NestedAccordions;
