import PropTypes, { string } from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import BrowsingPreferenceModal from "Front/browsingPreference/BrowsingPreferenceModal";
import { SubscriptionRouter } from "./SubscriptionRouter";

export const ConnectedSubscriptionRouter = (props) => {
    const settingsLocale = useSelector((state) => state?.user?.locale || "fr");
    const [warningBrowserLanguage, setWarningBrowserLanguage] = useState(settingsLocale);
    const history = useHistory();

    useEffect(() => {
        if (settingsLocale !== "fr") {
            setWarningBrowserLanguage(true);
        } else {
            setWarningBrowserLanguage(false);
        }
    }, [settingsLocale]);

    return (
        <>
            <BrowsingPreferenceModal
                open={warningBrowserLanguage}
                registrationMode
                noEnlish
                onClickBack={() => history.goBack()}
                onClose={() => setWarningBrowserLanguage(false)}
            />
            <SubscriptionRouter
                welcomePopin={props.welcomePopin}
                openWelcomePopin={props.openWelcomePopin}
                isStandalone={props.isStandalone}
            />
        </>
    );
};

export default ConnectedSubscriptionRouter;

ConnectedSubscriptionRouter.propTypes = {
    welcomePopin: PropTypes.bool,
    openWelcomePopin: PropTypes.func,
    isStandalone: string,
};
