import { bool, number, object, string } from "prop-types";
import { Box, makeStyles } from "@material-ui/core";
import { useParams } from "react-router-dom";

import { useGetJournalistSamplesQuery } from "../generated/graphql";
import { SwiperWrapper } from "../Front/SwiperCards";
import theme from "../globalTheme";
import { t } from "@lingui/macro";
import JournalistCard from "./JournalistCard";
import Loading from "Components/Loader/Loading";

const useStyles = makeStyles(() => ({
    container_slider_2_content: (props) => ({
        justifyContent: "center",
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingBottom: 38,
        [theme.breakpoints.up("xl")]: {
            width: props.titleCenter ? "100%" : 1314,
            margin: "auto",
        },
        [theme.breakpoints.down("xs")]: {
            "& .swiper-slide": {
                display: "flex",
                width: "100%",
            },
        },
    }),
}));

const FeretJournalists = (props) => {
    const styles = useStyles(props);
    const { name } = useParams();

    const { data, loading } = useGetJournalistSamplesQuery({ variables: { exclude: name, limit: props.limit } });
    const journalists = data?.GetJournalistSamples;

    if (loading) {
        return <Loading />;
    }

    return (
        <Box className={styles.container_slider_2}>
            <Box className={[styles.container_slider_2_content, props.boxClassName].join(" ")}>
                <SwiperWrapper
                    spaceBetween={14}
                    wrapperPaddingBottom="1rem"
                    title={props.title || t`Découvrez les journalistes de la salle de dégustation`}
                    titleCenter={props.titleCenter}
                    hideLowBar={props.hideLowBar}
                    titleStyle={props.titleStyle}
                    noPadding={props.noPadding}
                    boxPaddingTop={20}
                    noMargin
                    dark
                >
                    {journalists?.map((journalist, i) => {
                        const key = `feret_journalists-${i}`;
                        return <JournalistCard key={key} journalist={journalist} />;
                    })}
                </SwiperWrapper>
            </Box>
        </Box>
    );
};

FeretJournalists.propTypes = {
    title: string,
    titleCenter: bool,
    hideLowBar: bool,
    titleStyle: object,
    noPadding: bool,
    boxClassName: object,
    limit: number,
};

export default FeretJournalists;
