import { Suspense, createContext, lazy, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { dynamicActivate } from "i18n";
import { Helmet } from "react-helmet";
import { useLingui } from "@lingui/react";
import { useTranslation } from "react-i18next";

import NotFoundPage from "Components/ErrorPage/NotFoundPage";
import { ALL_LANGUAGES, FR_AND_EN, SUBSCRIPTION_STATUS, SUBSCRIPTION_TYPES } from "config/constants";
import { setLocale } from "features/user/userSlice";
import { MainRoutes } from "Routes/MainRouter/MainRoutes";
import { useCustomHistory } from "hooks";
import Loading from "Components/Loader/Loading";
import { lazyRetry } from "Routes/lazyRetry";
import { InactiveAccountModal } from "Routes/MainRouter/InactiveAccountModal";
import { RestrictedLanguageRoute } from "Routes/RestrictedLanguageRoute";

const DashboardRouter = lazy(() => lazyRetry(() => import("Routes/DashboardRouter"), "DashboardRouter"));
const DashboardRouterNoEntity = lazy(() =>
    lazyRetry(() => import("Routes/DashboardRouterNoEntity"), "DashboardRouterNoEntity")
);
const StandaloneRouter = lazy(() => lazyRetry(() => import("Routes/MainRouter/StandaloneRouter"), "StandaloneRouter"));
const DashboardRouterJournalist = lazy(() =>
    lazyRetry(() => import("Routes/DashboardRouterJournalist"), "DashboardRouterJournalist")
);

export const NavbarContext = createContext();

const DashboardRouterWrapper = (props) => {
    const currentUser = useSelector((state) => state.user.currentUser);

    if (!currentUser) {
        return <Redirect to="/recherche" />;
    }

    if (!currentUser?.status) {
        return <Redirect to="/subscription" />;
    }

    if (currentUser?.status === SUBSCRIPTION_STATUS.INACTIVE) {
        return (
            <RestrictedLanguageRoute languages={FR_AND_EN}>
                <InactiveAccountModal />
            </RestrictedLanguageRoute>
        );
    }

    if (currentUser?.wineEntityId) return <DashboardRouter {...props} />;
    if (currentUser.subscriptionName === SUBSCRIPTION_TYPES.journalist) return <DashboardRouterJournalist />;
    return <DashboardRouterNoEntity />;
};

// Skip lang preffix for /dashbord url
export const SKIP_ROUTES = ["qr"];
export const WITHOUT_PREFFIX = ["dashboard"];

export const MainRouter = () => {
    const [welcomePopin, setWelcomePopin] = useState(false);
    const [openConnectionMenu, setOpenConnectionMenu] = useState(false);
    const [ftId, setFtId] = useState(null);
    const location = useLocation();
    const history = useCustomHistory();
    const locale = useSelector((state) => state?.user?.locale ?? "fr");
    const dispatch = useDispatch();

    const isFresh = useRef(true);
    const unblock = useRef();

    const { i18n } = useLingui();
    const { i18n: i18next } = useTranslation();

    const getLocationDetails = (loc) => {
        const parts = loc.pathname.split("/");
        let lang = parts[1];
        let pathname = loc.pathname;
        if (ALL_LANGUAGES.includes(lang)) {
            pathname = "/" + parts.slice(2).join("/");
        }
        return { lang, pathname };
    };

    // Add lang prefix if needed on location change
    useEffect(() => {
        unblock.current = history.block((loc) => {
            const { lang } = getLocationDetails(loc);
            unblock.current();
            if ([...SKIP_ROUTES, ...WITHOUT_PREFFIX, ...ALL_LANGUAGES].includes(lang)) return true;
            history.push(loc.pathname);
            return false;
        });
    }, [location]);

    // Add or modify lang prefix on locale change
    useEffect(() => {
        const { lang, pathname } = getLocationDetails(location);

        if (SKIP_ROUTES.includes(lang)) return;

        if (isFresh.current && lang !== locale && ALL_LANGUAGES.includes(lang)) {
            dispatch(setLocale(lang));
            dynamicActivate(lang);
        } else if (isFresh.current && i18n.locale !== locale) {
            dynamicActivate(locale);
        } else if (!isFresh.current || lang !== locale) {
            if (!WITHOUT_PREFFIX.includes(lang)) {
                history.push(pathname);
            }
            i18next.changeLanguage(locale);
            dynamicActivate(locale);
        }
        isFresh.current = false;
    }, [locale]);

    const isStandalone = window.location.hostname === "alice";
    const defaultWineEntity = "6230539224061800096fc00e";

    if (isStandalone) {
        return (
            <Suspense fallback={<Loading />}>
                <StandaloneRouter
                    wineEntityId={defaultWineEntity}
                    welcomePopin={welcomePopin}
                    setWelcomePopin={setWelcomePopin}
                    openConnectionMenu={openConnectionMenu}
                    setOpenConnectionMenu={setOpenConnectionMenu}
                    ftId={ftId}
                    setFtId={setFtId}
                />
            </Suspense>
        );
    }

    return (
        <Suspense fallback={<Loading />}>
            {/* <Helmet>
                {ALL_LANGUAGES.map((lang) => (
                    <link
                        key={lang}
                        rel={lang === "fr" ? "canonical" : "alternate"}
                        href={`https://bordeaux.feret.com/${lang}`}
                        hrefLang={lang}
                    />
                ))}
            </Helmet> */}
            <Switch>
                {/* Dashboard */}
                <Route path="/dashboard">
                    <Helmet>
                        <title>Espace personnel</title>
                    </Helmet>
                    <DashboardRouterWrapper welcomePopin={welcomePopin} setWelcomePopin={setWelcomePopin} />
                </Route>

                {/* External URLs */}
                <Route exact path='/:lang/external/historical-research' component={() => {
                    window.location.href = "https://librairie.feret.com/recherche-historique/";
                    return null;
                }}/>

                <Route exact path='/:lang/external/feret-bookshop' component={() => {
                    window.location.href = "https://librairie.feret.com";
                    return null;
                }}/>

                <Route exact path='/:lang/external/lettre-feret' component={() => {
                    window.location.href = "https://lalettre.feret.com";
                    return null;
                }}/>

                <Route path="/:lang">
                    <MainRoutes
                        welcomePopin={welcomePopin}
                        setWelcomePopin={setWelcomePopin}
                        openConnectionMenu={openConnectionMenu}
                        setOpenConnectionMenu={setOpenConnectionMenu}
                        ftId={ftId}
                        setFtId={setFtId}
                    />
                </Route>

                <Redirect from="/" to={`/${locale}`} />

                {/* Not found page */}
                <Route path="*">
                    <NotFoundPage />
                </Route>
            </Switch>
        </Suspense>
    );
};
