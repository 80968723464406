import { getApolloClient } from "../../App";
import { AddConnectionLogDocument } from "../../generated/graphql";

const firebase = require("firebase/app");
require("firebase/auth");

firebase.initializeApp({
    apiKey: "AIzaSyBacPKJnwG_tqnIfFQCvJHic0j2HV9rzXQ",
    authDomain: "feret-online.firebaseapp.com",
    databaseURL: "https://feret-online.firebaseio.com",
    projectId: "feret-online",
    storageBucket: "feret-online.appspot.com",
    messagingSenderId: "556991333195",
    appId: "1:556991333195:web:75be7dd1ff267d14cb5ece",
    measurementId: "G-2MY97VRG51",
});

firebase.auth().languageCode = "fr";
firebase.auth().onIdTokenChanged(async (user) => {
    if (user) {
        const token = await user.getIdToken();
        localStorage.setItem("token", token);
    }
});

export const onAuthStateChange = (callback) => {
    return firebase.auth().onAuthStateChanged((user) => {
        if (user) {
            callback({ loggedIn: true, email: user.email });
        } else {
            callback({ loggedIn: false });
        }
    });
};

export const resetPassword = async (email) => {
    return firebase.auth().sendPasswordResetEmail(email);
};

export const getAuthToken = () => firebase.auth().currentUser?.getIdToken();

export const connectionLog = async (login, connectionSucceed) => {
    const apolloClient = getApolloClient();
    let userAgent, userAgentData, device, browser;

    userAgent = navigator?.userAgent;
    userAgentData = navigator?.userAgentData;

    if (userAgent.match(/chrome|chromium|crios/i)) {
        browser = "chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
        browser = "firefox";
    } else if (userAgent.match(/safari/i)) {
        browser = "safari";
    } else if (userAgent.match(/opr\//i)) {
        browser = "opera";
    } else if (userAgent.match(/edg/i)) {
        browser = "edge";
    } else {
        browser = null;
    }

    if (userAgent.match(/Android/i)) {
        device = "Android";
    } else if (userAgent.match(/webOS/i)) {
        device = "webOs";
    } else if (userAgent.match(/iPhone/i)) {
        device = "iPhone";
    } else if (userAgent.match(/iPad/i)) {
        device = "iPad";
    } else if (userAgent.match(/Mac/i)) {
        device = "Mac";
    } else if (userAgent.match(/Linux/i)) {
        device = "Linux";
    } else if (userAgent.match(/Windows Phone/i)) {
        device = "Windows Phone";
    } else {
        device = userAgentData?.platform ?? null;
    }

    await apolloClient.mutate({
        mutation: AddConnectionLogDocument,
        variables: {
            input: {
                login,
                device,
                browser,
                connectionSucceed,
                useragent: userAgent,
            },
        },
    });
};

export const login = async (username, password) => {
    let res;
    try {
        res = await firebase.auth().signInWithEmailAndPassword(username, password);
        await connectionLog(username, true);
    } catch (e) {
        await connectionLog(username, false);
        throw e;
    }
    return res;
};

export const updateEmail = async (email) => {
    return firebase.auth().currentUser.updateEmail(email);
};

export const logout = () => {
    return firebase.auth().signOut();
};

export const loginFacebook = async () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    try {
        const res = await firebase.auth().signInWithPopup(provider);
        return res;
    } catch (e) {
        return e;
    }
};

export const loginGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
        const res = await firebase.auth().signInWithPopup(provider);
        return res;
    } catch (e) {
        return e;
    }
};
