import { bool, func, object, oneOf, string } from "prop-types";
import { Switch, makeStyles } from "@material-ui/core";
import theme from "../../globalTheme";

const useStyles = makeStyles(() => ({
    root: {
        width: "28px",
        height: "13px",
        padding: 0,
        display: "flex",
    },

    switchBase: {
        top: "-1px",
        left: "-1px",
        padding: 2,
        "&$checked": {
            transform: "translateX(14px)",
            color: theme.palette.common.white,
            "& + $track": {
                opacity: 1,
                backgroundColor: "#6AC561",
                borderColor: "#6AC561",
            },
        },
    },
    thumb: {
        width: "11px",
        height: "11px",
        boxShadow: "none",
    },
    track: {
        height: "-webkit-fill-available",
        border: "1px solid #C4C4C4",
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: "#C4C4C4",
    },
    checked: {},
    disabled: {
        color: `${theme.palette.common.white} !important`,
        "&$checked": {
            transform: "translateX(14px)",
            color: theme.palette.common.white,
            "& + $track": {
                opacity: 0.5,
                backgroundColor: "#6AC561",
                borderColor: "#6AC561",
            },
        },
    },
}));

const BigSwitch = (props) => {
    const styles = useStyles();

    return (
        <Switch
            className={styles.root}
            disableRipple
            focusVisibleClassName={styles.focusVisible}
            color="secondary"
            classes={{
                root: styles.root,
                switchBase: styles.switchBase,
                thumb: styles.thumb,
                track: styles.track,
                checked: styles.checked,
                disabled: styles.disabled,
            }}
            name="checkedBigSwitch"
            onChange={props.onChange}
            disabled={props.disabled}
            checked={props.checked}
        />
    );
};

BigSwitch.propTypes = {
    checked: bool,
    disabled: bool,
    onChange: func,
    placement: oneOf(["start", "end"]),
    label: string,
    style: object,
    leftLabel: string,
    rightLabel: string,

    labelWidth: string,
    boxStyle: object,
    labelStyle: object,
    margin: string,
    defaultChecked: bool,
};

export default BigSwitch;
