import { useMemo } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { any, array, bool, func, object, oneOfType, string } from "prop-types";
import { Typography, Box } from "@material-ui/core";
import { ExpandMore, ArrowDropDown } from "@material-ui/icons";

import cross from "../../assets/whiteCross.svg";
import theme from "../../globalTheme";
import { firstLetterUppercase } from "../../helpers/helpers";
import { useEnumValues } from "hooks/useEnumValues";

const useStyles = makeStyles((theme) => ({
    root: (props) => ({
        width: "100%",
        "& .MuiAutocomplete-endAdornment": {
            top: props.popupIcon ? "calc(50% - 8px)" : "calc(50% - 14px)",
        },
        "& .MuiSvgIcon-root": {
            fill: theme.palette.secondary.dark,
            width: "18px",
        },
        // "&.MuiAutocomplete-endAdornment": {
        // top: "calc(50% - 12px)"
        // },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
            paddingLeft: props?.inputPaddingLeft ?? "8px",
        },
        "& .MuiOutlinedInput-root": {
            fontSize: "14px",
            color: theme.palette.primary.dark,
            borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
            backgroundColor: "white",
            minHeight: "34px",
            "&.Mui-disabled": {
                color: theme.palette.secondary.dark,
                backgroundColor: theme.palette.disabled.main,
            },
            "&:hover fieldset": {
                borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
            },
            "&.Mui-focused fieldset": {
                border: "1px solid",
                borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
            },
        },
        "& .MuiFormLabel-root": {
            fontSize: "14px",
            color: props.error ? theme.palette.error.main : props?.labelColor ?? theme.palette.secondary.dark,
            paddingLeft: props?.labelPaddingLeft ?? 0,
            paddingTop: props?.labelPaddingTop ?? 0,
        },
        "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
            padding: 0,
            paddingTop: "4px",
            paddingBottom: "4px",
            paddingRight: 30,
            paddingLeft: "4px",
        },
        "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input": () => ({
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: props?.placeholderPaddingLeft ?? "4px",
        }),
        "& .MuiAutocomplete-tag": {
            backgroundColor: theme.palette.primary.light,
            borderRadius: "4px",
            height: "22px",
        },
        "& .MuiChip-label": {
            color: theme.palette.common.white,
            fontSize: "12px",
        },
        "& .MuiChip-deleteIcon": {
            color: "white",
            width: "10px",
            height: "10px",
        },
        "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
            fontSize: "12px",
            color: theme.palette.secondary.dark,
            fontStyle: "italic",
        },
    }),
    noOptionsText: {
        fontStyle: "italic",
        fontSize: "12px",
        color: theme.palette.secondary.dark,
    },
    errorText: {
        fontSize: "12px",
        color: theme.palette.error.main,
        marginBottom: theme.spacing(1),
        marginTop: "0px",
    },
    option: {
        fontSize: "14px",
        color: theme.palette.primary.dark,
        "&:hover": {
            backgroundColor: theme.palette.dashboard.background,
        },
        "&.MuiListItem-root.Mui-selected": {
            backgroundColor: theme.palette.dashboard.background,
        },
    },
    paper: {
        "& ::-webkit-scrollbar": {
            width: "8px",
        },
        "& ::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            paddingRight: "8px",
        },
        "& ::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "20px",
        },
    },
}));

export default function AutocompleteChipsInput(props) {
    const classes = useStyles(props);

    const enumValues = useEnumValues(props.enumField, { locale: props.locale ?? "fr" });
    const options = useMemo(() => {
        let ret;
        if (props.enumField) {
            ret = enumValues;
        } else {
            ret = props.options;
        }
        if (props.sortAlphabetical) {
            ret = [...ret].sort();
        }
        return ret;
    });

    const placeholderHandle = () => {
        if (props.tagsLimit && props.value !== null) {
            if (props.value?.length < props.options?.length && props.value?.length < props.tagsLimit) {
                return props.placeholder ?? "Choisir...";
            }
        } else if (props.value === null) {
            return props.placeholder ?? "Choisir...";
        } else {
            if (props.value?.length < props.options?.length) {
                return props.placeholder ?? "Choisir...";
            } else if (props.value === undefined || props.value?.length === 0) {
                return props.placeholder ?? "Choisir...";
            }
        }
    };

    return (
        <Box className={[props.class, classes.root].join(" ")} style={props.style}>
            <Autocomplete
                popupIcon={
                    props.popupIcon ? (
                        <ExpandMore style={{ fill: theme.palette.primary.dark, fontSize: "1rem" }} />
                    ) : (
                        <ArrowDropDown />
                    )
                }
                getOptionSelected={props.getOptionSelected ? props.getOptionSelected : undefined}
                renderOption={props.renderOption}
                renderTags={props.renderTags}
                openText="Ouvrir"
                closeText="Fermer"
                id="tags-outlined"
                multiple
                disableListWrap
                disableClearable
                filterSelectedOptions
                getOptionDisabled={(options) => (props.value?.length >= props?.tagsLimit ? true : false)}
                openOnFocus={false}
                ListboxProps={{ style: { maxHeight: "180px" } }}
                options={options || []}
                onChange={(event, newValue, e3) => {
                    props.onChange(event, newValue);
                }}
                getOptionLabel={(option) => {
                    if (props.getOptionLabel) {
                        return props.getOptionLabel(option);
                    } else {
                        if (props.uppercase) {
                            return firstLetterUppercase(option);
                        } else {
                            return option;
                        }
                    }
                }}
                value={props.value || []}
                classes={{ option: classes.option, paper: classes.paper }}
                noOptionsText={
                    <Typography className={classes.noOptionsText}>
                        Il n&apos;y a plus d&apos;options disponibles.
                    </Typography>
                }
                ChipProps={{
                    deleteIcon: <img src={cross} alt="close_cross" className={classes.closeIcon} />,
                }}
                disabled={props.disabled}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label={props.label}
                        placeholder={placeholderHandle()}
                        error={props.error}
                        disabled={props.disabled}
                        InputLabelProps={{ shrink: true }}
                    />
                )}
                aria-required={props.required}
            />
            {props.error ? (
                <Typography variant="h6" className={classes.errorText}>
                    {" "}
                    {props.error}{" "}
                </Typography>
            ) : null}
        </Box>
    );
}

AutocompleteChipsInput.propTypes = {
    label: string,
    options: array,
    onChange: func,
    getOptionLabel: func,
    value: any,
    style: object,
    class: oneOfType([object, string]),
    uppercase: bool,
    error: bool,
    disabled: bool,
    tagsLimit: any,
    renderTags: func,
    renderOption: func,
    labelColor: string,
    placeholderPaddingLeft: string,
    inputPaddingLeft: string,
    labelPaddingLeft: string,
    labelPaddingTop: string,
    enumField: string,
    popupIcon: bool,
    sortAlphabetical: bool,
    getOptionSelected: func,
    placeholder: string,
    locale: string,
    required: bool,
};
AutocompleteChipsInput.defaultProps = {
    onChange: () => {},
};
