import { useState, useRef, useEffect } from "react";
import { makeStyles, Modal, Backdrop, Typography, Box, Link } from "@material-ui/core";
import { any, bool, func, node, number, object, string } from "prop-types";

import cross from "assets/cross.svg";
import whiteCross from "assets/whiteCross.svg";
import popinBg1 from "assets/popin/popinbg1.jpg";
import popinBg2 from "assets/popin/popinbg2.jpg";
import popinBg3 from "assets/popin/popinbg3.jpg";
import popinBg4 from "assets/popin/popinbg4.jpg";
import popinBg5 from "assets/popin/popinbg5.jpg";
import popinBg6 from "assets/popin/popinbg6.svg";

const getBackground = (background) => {
    if (background === 1) return `no-repeat bottom right/30% url(${popinBg4})`;
    if (background === 2) return `no-repeat bottom left/30% url(${popinBg2})`;
    if (background === 3) return `no-repeat bottom right/30% url(${popinBg1})`;
    if (background === 4) return `no-repeat bottom left/30% url(${popinBg5})`;
    if (background === 5)
        return `url(${popinBg3}) top left/112px no-repeat, url(${popinBg1}) right bottom/300px no-repeat`;
    if (background === 6) return `#fff no-repeat bottom right/30% url(${popinBg6})`;
    return "";
};

const useStyle = makeStyles((theme) => ({
    modal: (props) => ({
        padding: props.icon ? "30px 10px 10px 10px" : "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }),
    container: (props) => ({
        width: props.containerWidth ? props.containerWidth : "",
        backgroundColor: props.backgroundColor ? props.backgroundColor : theme.palette.common.white,
        minWidth: 100,
        minHeight: props?.minHeight ?? 200,
        height: "fit-content",
        maxWidth: "100%",
        maxHeight: "100%",
        borderRadius: 10,
        padding: props.noPadding ? "0px" : "30px 30px",
        position: "relative",
        background: getBackground(props.background),
        outline: "none",
        overflow: !props.icon && "hidden",
        "&::-webkit-scrollbar": { width: "8px", height: "8px" },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            paddingRight: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "20px",
        },
    }),
    cross_dark: {
        position: "absolute",
        top: 10,
        right: 10,
        zIndex: 1000,
        cursor: "pointer",
    },
    container__top_icon: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        height: "20px",
        maxHeight: "20px",
        backgroundColor: "white",
    },
    top_icon: (props) => ({
        position: "absolute",
        height: "100px",
        top: "-30px",
        borderRadius: "50%",
        backgroundColor: "transparent",
        padding: props.iconPadding ? props.iconPadding : "0px",
    }),
    container_content: {
        overflow: "auto",
        "&::-webkit-scrollbar": { width: "8px", height: "8px" },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            paddingRight: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "20px",
        },
    },
    title: {
        ...theme.front.typography.h3.dark,
        alignSelf: "start",
        marginBottom: theme.spacing(2),
    },
    container__backLink: {
        ...theme.margins.default.popin,
        fontStyle: "italic",
        position: "absolute",
        top: 10,
        left: 10,
        zIndex: 1000,
        cursor: "pointer",
    },
    container__title: (props) => ({
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing(2),
    }),
}));

const Popin = (props) => {
    const styles = useStyle(props);
    const [boxWidth, setBoxWidth] = useState();
    const [boxHeight, setBoxHeight] = useState();
    const [titleBoxHeight, setTitleBoxHeight] = useState();
    const boxRef = useRef();
    const titleBoxRef = useRef();

    const getBoxSize = () => {
        const newWidth = boxRef?.current?.clientWidth;
        setBoxWidth(newWidth);
        const newHeight = boxRef?.current?.clientHeight;
        setBoxHeight(newHeight);
    };

    const getTitleBoxSize = () => {
        const newHeight = titleBoxRef?.current?.clientHeight;
        setTitleBoxHeight(newHeight);
    };

    useEffect(() => {
        getBoxSize();
        getTitleBoxSize();
    });

    if (!props.open) {
        return null;
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={props.open}
            onClose={props.onClose}
            className={styles.modal}
        >
            <Box
                className={styles.container}
                ref={boxRef}
                style={{
                    width: props.width,
                    overflow: props.overlowContentPopin,
                }}
            >
                {props.icon && (
                    <Box className={styles.container__top_icon}>
                        <Box className={styles.top_icon}>
                            <img src={props.icon} style={{ height: "100px" }} />
                        </Box>
                    </Box>
                )}

                {props.backLinkOnClick && (
                    <Link className={styles.container__backLink} underline="always" onClick={props.backLinkOnClick}>
                        Retour
                    </Link>
                )}

                {props.title && (
                    <Box className={styles.container__title} ref={titleBoxRef} style={props.styleHeader}>
                        {props.imgTitle && <img src={props.imgTitle} style={props.styleImgTitle} />}
                        <Typography
                            variant={props.typographyVariant ?? "p"}
                            className={styles.title}
                            style={props.titleStyle}
                        >
                            {props.title}
                        </Typography>
                    </Box>
                )}

                {/* CROSS */}
                {props.cross && (
                    <img
                        src={props.whiteCross ? whiteCross : cross}
                        className={styles.cross_dark}
                        onClick={props.onClose}
                    />
                )}

                {/* CONTENT */}
                <Box
                    className={[styles.container_content, props.classStyleContent].join(" ")}
                    style={{
                        maxWidth: props.maxWidth ? props.maxWidth : boxWidth,
                        maxHeight: props.maxHeight || props.icon ? boxHeight - 70 : boxHeight - 60 - titleBoxHeight,
                    }}
                >
                    {props.open ? props.children : null}
                </Box>
            </Box>
        </Modal>
    );
};

export default Popin;

Popin.propTypes = {
    title: string,
    titleStyle: object,

    open: bool,
    onClose: func,

    icon: string,
    cross: bool,
    whiteCross: bool,
    backLinkOnClick: any,

    background: number,
    children: node,
    classStyleContent: object,

    overlowContentPopin: string,
    containerWidth: string,
    maxWidth: string,

    imgTitle: string,
    styleImgTitle: object,
    styleHeader: object,
    maxHeight: string,
    minHeight: string,
    width: string,

    typographyVariant: string,
};
