import { array, string } from "prop-types";
import { Box, makeStyles, Typography, Link as MUILink } from "@material-ui/core";
import theme from "../../globalTheme";
import fb from "../../assets/footer/fb.svg";
import insta from "../../assets/footer/instagram.svg";
import scrollTop from "../../assets/footer/scrollTop.svg";
import linkedin from "../../assets/footer/linkedIn.svg";
import { getCurrentYear, useWindowDimensions } from "../../helpers/helpers";
import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";

const useStyles = makeStyles(() => ({
    footer: {
        [theme.breakpoints.up("xs")]: {
            height: "43.25rem",
            display: "flex",
            flexDirection: "column",
        },
        [theme.breakpoints.up("sm")]: {
            height: "31rem",
        },
        [theme.breakpoints.up("md")]: {
            height: "14rem",
        },
    },
    footer_content: {
        backgroundColor: theme.palette.primary.main,
        display: "grid",
        gridTemplateColumns: "repeat(12,1fr)",
        color: theme.palette.common.white,
        [theme.breakpoints.up("sm")]: {
            padding: "14px",
            columnGap: "1rem",
        },
        [theme.breakpoints.up("md")]: {
            padding: "20px 34px",
            columnGap: "1rem",
        },
        [theme.breakpoints.up("lg")]: {
            gap: "1rem",
            padding: "20px 40px",
        },

        [theme.breakpoints.up("xl")]: {
            gridTemplateColumns: "repeat(18,1fr)",
        },
        "& .MuiTypography-h6": {
            lineHeight: 2,
        },
    },

    copyright_content: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.dark,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        flex: 1,
    },
    dot: {
        display: "inline-flex",
        width: 3,
        height: 3,
        borderRadius: "50%",
        backgroundColor: theme.palette.primary.light,
        margin: "0 0.5rem",
    },
    logo_content: {
        [theme.breakpoints.up("xs")]: {
            gridRow: 2,
            gridColumn: "1/span 12",
            display: "flex",
            justifyContent: "center",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "1 /span 4",
            gridRow: 1,
            display: "inherit",
            justifyContent: "inherit",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 2",
            gridRow: "1 / span 1",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 2",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "4 / span 2",
        },
    },
    logo_frt: {
        [theme.breakpoints.up("xs")]: {
            width: "50%",
            height: "auto",
        },
        [theme.breakpoints.up("sm")]: {
            width: "100%",
            height: "auto",
        },
        [theme.breakpoints.up("md")]: {
            width: "100%",
            height: "auto",
        },
        [theme.breakpoints.up("lg")]: {
            width: "100%",
            height: "auto",
        },
        [theme.breakpoints.up("xl")]: {
            width: "100%",
            height: "auto",
        },
    },

    first_block: {
        [theme.breakpoints.only("xs")]: {
            gridRow: 4,
            gridColumn: "1 / span 12",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "1rem",
        },
        [theme.breakpoints.up("sm")]: {
            gridRow: 3,
            gridColumn: "1 / span 4",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "4 / span 3",
            gridRow: "1 / span 2",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "4 / span 2",
            gridRow: "1 / span 1",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "6 / span 3",
        },
    },
    second_block: {
        [theme.breakpoints.only("xs")]: {
            gridRow: 5,
            gridColumn: "1 / span 12",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "1rem",
        },
        [theme.breakpoints.up("sm")]: {
            gridRow: 3,
            gridColumn: "5 / span 4",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "7 / span 3",
            gridRow: "1 / span 2",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "6 / span 2",
            gridRow: "1 / span 1",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "9 / span 3",
        },
    },
    third_block: {
        [theme.breakpoints.only("xs")]: {
            gridRow: 6,
            gridColumn: "1 / span 12",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "1rem",
        },
        [theme.breakpoints.up("sm")]: {
            gridRow: 3,
            gridColumn: "9 / span 4",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "10 / span 3",
            gridRow: "1 / span 2",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "8 / span 2",
            gridRow: "1 / span 1",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "12 / span 2",
        },
    },
    container_scroll: {
        display: "flex",
        [theme.breakpoints.up("xs")]: {
            gridRow: 1,
            gridColumn: "1 / span 12",
            justifyContent: "center",
        },
        [theme.breakpoints.up("sm")]: {
            justifyContent: "start",
            gridRow: 1,
            gridColumn: "8 / span 5",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 2",
            gridRow: "2 / span 1",
            alignItems: "self-start",
            marginTop: "0.5rem",
        },
        [theme.breakpoints.up("lg")]: {
            gridRow: "1 / span 1",
            gridColumn: "11 / span 2",
            alignItems: "start",
            marginTop: "0",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "14 / span 2",
        },
    },
    button_scroll: {
        cursor: "pointer",
        width: "fit-content",
        display: "flex",
        alignItems: "center",

        [theme.breakpoints.up("md")]: {
            marginBottom: "1rem",
        },
    },
    social_media: {
        gap: "1rem",
        display: "flex",
        marginTop: "1rem",
        [theme.breakpoints.up("xs")]: {
            justifyContent: "center",
        },
        [theme.breakpoints.up("sm")]: {
            justifyContent: "flex-start",
        },
    },
    link: {
        color: theme.palette.common.white,
        cursor: "pointer",
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline",
        },
    },
    separator: {
        height: "0.5px",
        borderBottomColor: "transparent",
        borderTopColor: theme.palette.secondary.main,
        gridColumn: "1 / span 12",
        [theme.breakpoints.up("xs")]: {
            margin: "14px -14px",
            gridRow: 3,
        },
        [theme.breakpoints.up("sm")]: {
            gridRow: "2",
        },
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
    },
    responsive_copyright_content: {
        display: "flex",
        flexDirection: "column",
    },
}));

const StandaloneFooter = (props) => {
    const styles = useStyles();
    const { width } = useWindowDimensions();

    const dot = () => <span className={styles.dot} />;

    return (
        <Box className={styles.footer}>
            <Box className={styles.footer_content}>
                <Box className={styles.logo_content}>
                    <img src={props.logo} className={styles.logo_frt} />
                </Box>

                <Box className={styles.first_block}>
                    <Typography variant="h4">{props.wineEntityName}</Typography>
                    <Typography variant="h6">
                        <Link className={styles.link} to="/#propriete">
                            Propriété
                        </Link>
                    </Typography>
                    <Typography variant="h6">
                        <Link className={styles.link} to="/#equipe">
                            Équipe
                        </Link>
                    </Typography>
                    <Typography variant="h6">
                        <Link className={styles.link} to="/#contact">
                            <Trans>Contactez-nous</Trans>
                        </Link>
                    </Typography>
                    <Box className={styles.social_media}>
                        {props.facebookLink ? (
                            <MUILink
                                style={{ cursor: "pointer", display: "flex" }}
                                target="_blank"
                                href={props.facebookLink}
                            >
                                <img src={fb} />
                            </MUILink>
                        ) : null}
                        {props.instagramLink ? (
                            <MUILink
                                style={{ cursor: "pointer", display: "flex" }}
                                target="_blank"
                                href={props.instagramLink}
                            >
                                <img src={insta} />
                            </MUILink>
                        ) : null}
                        {props.linkedinLink ? (
                            <MUILink
                                style={{ cursor: "pointer", display: "flex" }}
                                target="_blank"
                                href={props.linkedinLink}
                            >
                                <img src={linkedin} />
                            </MUILink>
                        ) : null}
                    </Box>
                </Box>

                {props.wines?.length ? (
                    <Box className={styles.second_block}>
                        <Typography variant="h4">
                            <Link className={styles.link}>Les vins</Link>
                        </Typography>
                        {props.wines?.map((wine, i) => {
                            let key = `wine-${i}`;
                            return (
                                <Typography key={key} variant="h6">
                                    <Link
                                        className={styles.link}
                                        to={wine?.lastVintageId ? `/vins/${wine?.lastVintageId}` : "/#vins"}
                                    >
                                        {wine.brandName}
                                    </Link>
                                </Typography>
                            );
                        })}
                    </Box>
                ) : null}
                {props.activities?.length ? (
                    <Box className={styles.third_block}>
                        <Typography variant="h4">
                            <Link className={styles.link}>Les activités</Link>
                        </Typography>
                        {props.activities?.map((wine, i) => {
                            let key = `wine-${i}`;
                            return (
                                <Typography key={key} variant="h6">
                                    <Link className={styles.link} to={`/activites/${wine?.activityId}`}>
                                        {wine.name}
                                    </Link>
                                </Typography>
                            );
                        })}
                    </Box>
                ) : null}

                <Box className={styles.container_scroll}>
                    <Box
                        className={styles.button_scroll}
                        onClick={() => {
                            window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: "smooth",
                            });
                        }}
                    >
                        <img src={scrollTop} />
                        <Typography variant="h6" style={{ fontFamily: "Mansalva", marginLeft: "0.5rem" }}>
                            {width >= 960 && width < 1280 ? "Retour en haut" : "Retour en haut de la page"}
                        </Typography>
                    </Box>
                </Box>

                <hr className={styles.separator} />
            </Box>
            {width >= 600 ? (
                <Box className={styles.copyright_content}>
                    <Typography variant="h6">
                        {" "}
                        &copy; Éditions Féret {getCurrentYear()}, <i>Bordeaux et ses Vins</i>
                    </Typography>
                    {dot()}
                    <Typography variant="h6">Mentions légales</Typography>
                    {dot()}
                    <Typography variant="h6">Confidentialité et CGU</Typography>
                    {dot()}
                    <Typography variant="h6">Plan du site</Typography>
                </Box>
            ) : (
                <Box className={styles.responsive_copyright_content}>
                    <Typography variant="h6" style={{ textAlign: "center" }}>
                        {" "}
                        &copy; Éditions Féret {getCurrentYear()}, <i>Bordeaux et ses Vins</i>
                    </Typography>
                    <Typography
                        variant="h6"
                        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                        Mentions légales {dot()} Confidentialité et CGU
                    </Typography>

                    <Typography variant="h6" style={{ textAlign: "center", marginBottom: "1rem" }}>
                        Plan du site
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

StandaloneFooter.propTypes = {
    logo: string,
    wineEntityName: string,
    wines: array,
    activities: array,
    facebookLink: string,
    instagramLink: string,
    linkedinLink: string,
};

export default StandaloneFooter;
