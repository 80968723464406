import { IconButton, makeStyles } from "@material-ui/core";
import PropTypes, { func, object } from "prop-types";
import React from "react";

import showmore from "../../../../assets/DataSheets/showmore.svg";
import theme from "../../../../globalTheme";

const useStyle = makeStyles(() => ({
    showMore: {
        backgroundColor: theme.palette.secondary.main,
        "&:hover": {
            backgroundColor: theme.palette.secondary.dark,
        },
    },
}));
const ShowMore = (props) => {
    const styles = useStyle();
    return (
        <IconButton
            onClick={() => {
                props.onClick();
            }}
            className={styles.showMore}
            style={{ ...props.style }}
        >
            <img src={showmore} alt="showMore" style={props.iconStyle} />
        </IconButton>
    );
};

ShowMore.propTypes = {
    style: object,
    onClick: func,
    iconStyle: object,
};

export default ShowMore;
