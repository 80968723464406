import { Box, makeStyles } from "@material-ui/core";

import download2 from "../assets/summary/download.svg";
import FeretSummaryContainer from "../Front/FeretSummary/FeretSummaryContainer";
import FeretSummaryElement from "../Front/FeretSummary/FeretSummaryElement";
import { Maybe } from "../generated/graphql";
import classement from "../assets/summary/classement.svg";
import dates from "../assets/summary/dates.svg";
import organisation from "../assets/summary/organisation.svg";
import { t, Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
    subtitle: {
        marginRight: "0.25rem",
        color: theme.palette.primary.light,
    },
    subtitle_date: {
        color: theme.palette.primary.dark,
    },
    btn: {
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
    },
}));

interface RankingSummaryProps {
    lastRanking?: Maybe<string>;
    nextRanking?: Maybe<string>;
    organisation?: Maybe<string>;
    classement: string;
    maxWidth?: string;
    setTabActive: () => void;
}

const RankingSummary = (props: RankingSummaryProps) => {
    const styles = useStyles();

    return (
        <FeretSummaryContainer maxWidth={props.maxWidth ?? "none"}>
            {(props.lastRanking || props.nextRanking) && (
                <FeretSummaryElement
                    icon={dates}
                    title={t`Dates`}
                    subtitle={
                        props.lastRanking ? (
                            <>
                                <Box className={styles.subtitle}>
                                    <Trans>Dernier classement</Trans> :{" "}
                                    <span className={styles.subtitle_date}>{props.lastRanking}</span>
                                </Box>
                            </>
                        ) : null
                    }
                    subtitle2={
                        props.nextRanking ? (
                            <>
                                <Box className={styles.subtitle}>
                                    <Trans>Prochain classement</Trans> :{" "}
                                    <span className={styles.subtitle_date}>{props.nextRanking}</span>
                                </Box>
                            </>
                        ) : null
                    }
                />
            )}
            {props.organisation && (
                <FeretSummaryElement icon={organisation} title={t`Organisation`} subtitle={props.organisation} />
            )}
            {props.classement && (
                <FeretSummaryElement
                    icon={classement}
                    title={t`Classement`}
                    subtitle={
                        <>
                            <Trans>Accéder au classement</Trans>
                            <Box style={{ marginLeft: "0.25rem" }}>
                                <a href={props.classement} target="_blank" rel="noreferrer">
                                    <img style={{ width: "21px", height: "19px" }} src={download2} />
                                </a>
                            </Box>
                        </>
                    }
                />
            )}
        </FeretSummaryContainer>
    );
};

export default RankingSummary;
