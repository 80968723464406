import { useState } from "react";
import { any, number } from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { AppBar, Typography } from "@material-ui/core";
import theme from "../globalTheme";
import PropTypes from "prop-types";
import { t } from "@lingui/macro";
import { useCustomHistory } from "hooks";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        backgroundColor: "transparent",
        boxShadow: "none",

        "& .MuiTabs-flexContainer": {
            margin: "0 auto",
            display: "block",
            textAlign: "center",

            [theme.breakpoints.up("sm")]: {
                justifyContent: "space-around",
            },
        },

        "& .MuiTabs-indicator": {
            backgroundColor: theme.palette.common.white,
            height: 4,
            borderRadius: "0px 0px 5px 5px",
        },
    },
    typo: {
        color: theme.palette.common.white,
    },
});

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
}

const setIndexActiveTab = (activeTab) => {
    switch (activeTab) {
        case "estate":
            return 0;
        case "wine":
            return 1;
        case "activity":
            return 2;
        // case "qrcode":
        //     return 3;
        case "all":
            return 3;

        default:
            break;
    }
    return activeTab === "estate" ? 1 : 0;
};

const SearchTabs = (props) => {
    const styles = useStyles();
    const history = useCustomHistory();
    const [value, setValue] = useState(setIndexActiveTab(props?.active));
    const tabs = [
        { tabName: "Propriétés", label: t`Propriétés` },
        { tabName: "Vins", label: t`Vins` },
        { tabName: "Activités", label: t`Activités` },
        // { tabName: "Qrcode", label: t`Qrcode` },
        { tabName: "Tout voir", label: t`Tout voir` },
    ];

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const setUrlEnd = (tab) => {
        switch (tab) {
            case "Propriétés":
                return "proprietes";

            case "Vins":
                return "vins";

            case "Activités":
                return "activites";

            case "Qrcode":
                return "qrcode";

            case "Tout voir":
                return "tout";

            default:
                break;
        }
    };

    return (
        <AppBar
            position="static"
            style={{
                background: "transparent",
                boxShadow: "none",
                maxWidth: props?.appBarMaxWidth,
                zIndex: "unset",
            }}
        >
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                TabIndicatorProps={{ children: <span /> }}
                textColor="primary"
                scrollButtons="on"
                variant={"scrollable"}
                className={styles.root}
                aria-label="scrollable auto tabs example"
            >
                {tabs.map((tab, i) => (
                    <Tab
                        key={i}
                        {...a11yProps(0)}
                        label={
                            <Typography
                                variant="h5"
                                className={styles.typo}
                                style={{
                                    fontWeight: value === i ? 700 : 400,
                                    backgroundColor: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                }}
                            >
                                {tab.label}
                            </Typography>
                        }
                        style={{ padding: "6px 12px" }}
                        onClick={() =>
                            history.push(
                                props.redirectToResult
                                    ? `/recherche/${setUrlEnd(tab.tabName)}/resultats`
                                    : `/recherche/${setUrlEnd(tab.tabName)}`
                            )
                        }
                    />
                ))}
            </Tabs>
        </AppBar>
    );
};

SearchTabs.propTypes = {
    active: number,
    appBarMaxWidth: any,
    redirectToResult: PropTypes.bool,
};

export default SearchTabs;
