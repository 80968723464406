/* eslint-disable quotes */
/* eslint-disable react/jsx-indent */
import React from "react";
import PropTypes, { object } from "prop-types";
import TitleSemiUnderlined from "../../Components/TitleSemiUnderlined/TitleSemiUnderlined";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    typo: (props) => ({
        // ...theme.front.typography.h3.dark,
        color: theme.palette.info,
        position: "relative",
        marginBottom: props.lowBar?.marginBottom || theme.spacing(2),
        marginTop: "1rem",
        "&:after": {
            display: props.lowBar?.display ?? "block",
            content: '""',
            position: "absolute",
            width: props.lowBar?.width || 50,
            bottom: props.lowBar?.bottom || theme.spacing(-1),
            left: 0,
            borderBottom: props.lowBar?.borderBottom || "4px solid",
            borderBottomColor: props.borderColor ? props.borderColor : theme.palette.primary.light,
        },
    }),
}));

const EstateTitleSemiUnderlined = (props) => {
    const styles = useStyles(props);

    return (
        <TitleSemiUnderlined style={props.style} className={styles.typo} box={props.styleBox}>
            {props.title}
        </TitleSemiUnderlined>
    );
};

EstateTitleSemiUnderlined.propTypes = {
    title: PropTypes.string.isRequired,
    style: PropTypes.object,
    borderColor: PropTypes.string,
    lowBar: PropTypes.object,
    fontSize: PropTypes.number,
    sizeBig: PropTypes.bool,
    heightAfter: PropTypes.number,
    styleBox: object,
};

export default EstateTitleSemiUnderlined;
