import PropTypes, { shape } from "prop-types";
import { Card, CardContent, Typography, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

import theme from "../globalTheme";
import book from "../assets/post/book.svg";
import BaseButton from "../Components/Buttons/BaseButton";
import { useCustomHistory } from "hooks";

const useStyles = makeStyles((e) => ({
    card_content: {
        padding: "0px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        flexWrap: "wrap",
        alignItems: "center",
        width: "80%",
        height: "inherit",
        margin: "0 auto",
    },
    card_title: {
        color: theme.palette.primary.main,
        fontFamily: "Playfair Display, Helvetica, Arial, sans-serif",
        fontWeight: "bold",
        fontStyle: "italic",
        textAlign: "center",
        marginTop: "calc(48px - 16px)",
        display: "flex",
        alignItems: "center",
        height: "66px",
    },
    card_author: {
        color: theme.palette.primary.main,
        fontWeight: 400,
        textAlign: "center",
        marginBottom: "35px",
    },
    button: {
        border: "none",
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
}));

const FeretVisitCard = (props) => {
    const styles = useStyles();
    const { addLocale } = useCustomHistory();
    const url = addLocale(`/encyclopedie-feret/article/${props.article?.slugFr}`);

    return (
        <BaseButton classes={styles.button} component={Link} to={url}>
            <Card
                style={{
                    borderRadius: "10px",
                    width: 260,
                    height: 195,
                    padding: "16px 16px 20px",
                    backgroundColor: theme.palette.common.white,
                    backgroundImage: `url(${book})`,
                    backgroundPositionX: "center",
                    backgroundPositionY: "center",
                    backgroundRepeat: "no-repeat",
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.08)",
                }}
            >
                <CardContent className={styles.card_content}>
                    <Typography variant="h4" className={styles.card_title}>
                        {props.article?.name}
                    </Typography>
                    <Typography variant="h5" className={styles.card_author}>
                        {props.article?.author}
                    </Typography>
                </CardContent>
            </Card>
        </BaseButton>
    );
};

FeretVisitCard.propTypes = {
    article: shape({
        id: PropTypes.string,
        name: PropTypes.string,
        author: PropTypes.string,
    }),
};

export default FeretVisitCard;
