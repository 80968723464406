import React from "react";
import PropTypes, { string } from "prop-types";
import { Box } from "@material-ui/core";
import MainTitle from "./MainTitle";
import theme from "../globalTheme";
const TitleStandalone = (props) => {
    return (
        <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", gridColumn: "1 / span 12" }}>
            <Box style={{ width: "10rem" }}>
                <img src={props.logo} style={{ width: "100%" }} />
            </Box>

            <MainTitle wineEntityId={props.wineEntityId} color={theme.palette.primary.dark} />
        </Box>
    );
};

TitleStandalone.propTypes = {
    wineEntityId: string,
    logo: string,
};

export default TitleStandalone;
