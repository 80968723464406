import React, { useEffect } from "react";

import { makeStyles, TextField, InputAdornment, Typography, Box } from "@material-ui/core";
import PropTypes, { bool, object } from "prop-types";
import CheckIcon from "@material-ui/icons/Check";
import AdditionalInformationTooltip from "../Tooltips/AdditionalInformationTooltip";
import ErrorIcon from "../../assets/Error.svg";
import searchIcon from "../../assets/SubscriptionPage/searchIcon.svg";

const useStyles = makeStyles((theme) => ({
    root: (props) => {
        const getBorderRadius = () => {
            if (props.table) {
                return "0px";
            } else if (props.borderRadius) {
                return props.borderRadius;
            } else {
                return "4px";
            }
        };
        return {
            width: "100%",
            order: props?.iconPosition === "start" ? 2 : 1,
            "&::placeholder": {
                fontStyle: "italic",
            },
            "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: props?.borderWidth ?? "1px",
                borderRadius: getBorderRadius(),
                borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
            },
            "& .MuiOutlinedInput-root": {
                fontSize: "14px",
                color: theme.palette.primary.dark,
                borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
                backgroundColor: props?.bgTransparent ? "transparent" : "white",
                height: "34px",
                "&.Mui-disabled": {
                    color: theme.palette.secondary.dark,
                    backgroundColor: theme.palette.disabled.main,
                },
                "&:hover fieldset": {
                    borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
                },
                "&.Mui-focused fieldset": {
                    border: "1px solid",
                    borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
                },
            },
            "& .MuiFormLabel-root": {
                fontSize: "14px",
                color: props.error ? theme.palette.error.main : theme.palette.secondary.dark,
            },
        };
    },
    boxContainer: {
        position: "relative",
        width: "100%",
    },
    checkIcon: {
        width: "20px",
        fill: theme.palette.success.main,
    },
    falseIcon: {
        width: "20px",
        fill: theme.palette.error.main,
    },
    toolTipBox: {
        position: "absolute",
        right: "8px",
        top: "-8px",
        zIndex: 2,
        cursor: "default",
    },
    errorText: {
        fontSize: "12px",
        color: theme.palette.error.main,
        marginLeft: "8px",
    },
    searchIcon: {
        width: "18px",
    },
}));

const SimpleInputUncontrolled = (props) => {
    const styles = useStyles({ ...props });

    useEffect(() => {
        if (props.onBlur) {
            props.onBlur();
        }
    }, [props.disabled]);
    return (
        <Box className={styles.boxContainer} style={{ ...props.boxStyle }}>
            {props.tooltipTitle && (
                <Box className={styles.toolTipBox}>
                    <AdditionalInformationTooltip color="primary" title={props.tooltipTitle} arrow />
                </Box>
            )}
            <TextField
                defaultValue={props.defaultValue}
                type={props.type}
                autoComplete={props.autoComplete}
                id={props.id}
                variant="outlined"
                size="small"
                label={props.label}
                placeholder={props.placeholder}
                disabled={props.disabled}
                required={props.required}
                error={props.error}
                onFocus={(e) => {
                    if (props.onFocus) {
                        props.onFocus(e);
                    }
                }}
                onBlur={(e) => {
                    if (props.onBlur) {
                        props.onBlur(e);
                    }
                }}
                style={{ ...props.style }}
                className={styles.root}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    type: props.type,
                    autoComplete: props.autoComplete,
                    id: props.id,
                }}
                InputProps={{
                    type: props.type,
                    classes: { input: styles.root },
                    endAdornment: (
                        <InputAdornment
                            position="end"
                            style={{
                                order: props?.iconPosition === "start" ? 1 : 2,
                                marginLeft: props?.inModal ? 20 : 8,
                            }}
                        >
                            {props.search && <img className={styles.searchIcon} src={searchIcon} />}
                            {props.correctValue && <CheckIcon className={styles.checkIcon} />}
                            {props.error && <img src={ErrorIcon} className={styles.falseIcon} />}
                        </InputAdornment>
                    ),
                }}
                {...props.textFieldProps}
            />
            {props.error && (
                <Typography variant="h6" className={styles.errorText}>
                    {" " + props.error}
                </Typography>
            )}
        </Box>
    );
};

SimpleInputUncontrolled.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,

    value: PropTypes.string,
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,

    disabled: PropTypes.bool,
    error: PropTypes.string,
    required: PropTypes.bool,

    style: PropTypes.object,
    boxStyle: PropTypes.object,
    bgTransparent: PropTypes.bool,

    correctValue: PropTypes.bool,
    tooltipTitle: PropTypes.string,
    table: PropTypes.bool,
    autoFocus: PropTypes.bool,
    id: PropTypes.string,
    type: PropTypes.string,
    autoComplete: PropTypes.string,
    search: bool,
    borderWidth: PropTypes.number,
    iconPosition: PropTypes.string,
    inModal: PropTypes.bool,
    textFieldProps: PropTypes.object,
};
SimpleInputUncontrolled.defaultValue = {
    textFieldProps: {},
};
export default React.memo(SimpleInputUncontrolled);
