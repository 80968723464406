import React, { useState } from "react";
import { makeStyles, Box, Typography, Link } from "@material-ui/core";

import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import WineGreenOperationsPopin from "./WineGreenOperationsPopin";
import Arrow from "../../Components/Arrow/Arrow";

import green from "../../assets/Wine/green.svg";
import PropTypes from "prop-types";
import theme from "../../globalTheme";
import { firstLetterUppercase } from "../../helpers/helpers";
import { getSentence } from "./vintageHelpers";
import { Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
    container: (props) => ({
        border: "0.4px solid",
        borderRadius: "5px",
        borderColor: theme.palette.secondary.dark,
        position: "relative",
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        paddingTop: 0,
        height: "calc(100% - 32px)",
        "& .MuiStepConnector-lineVertical": {
            borderLeftColor: theme.palette.success.main,
            position: "absolute",
            minHeight: "62px",
            top: -31,
        },
        "& .MuiStepLabel-labelContainer": {
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(1),
        },
        "& .MuiStepConnector-vertical": {
            position: "relative",
            marginLeft: "9px",
            padding: 0,
        },
        "& .MuiStepper-root": {
            padding: 0,
        },
        "& .MuiTimelineConnector-root": {
            width: "1px",
        },
        "& .MuiTimelineItem-missingOppositeContent:before": {
            flex: props.displayALign === "left" ? 0 : 1,
            padding: props.displayALign === "left" ? 0 : "6px 16px",
        },
        [theme.breakpoints.only("xs")]: {
            height: "100%",
            padding: 0,
        },
    }),
    typoContainer: {
        position: "relative",
        top: "-16px",
        left: "0px",
        display: "flex",
        alignItems: "center",
    },
    title: {
        ...theme.front.typography.h6.dark_b,
        zIndex: 2,
        backgroundColor: theme.palette.common.white,
        padding: "0px 2px",
    },
    stepLabelText: {
        border: "1px solid",
        borderColor: theme.palette.secondary.main,
        borderRadius: "4px",
        padding: theme.spacing(1),
    },
    stepLabelText__TypoPrim: {
        ...theme.front.typography.labeur.clear,
    },
    stepLabelText__TypoSecond: {
        ...theme.front.typography.labeur.dark,
    },
    linkTypoWineGreen: {
        cursor: "pointer",
        marginLeft: "3px",
        [theme.breakpoints.only("xs")]: {
            fontSize: "12px",
        },
    },
}));

const WineGreenOperations = (props) => {
    const styles = useStyles(props);
    const [open, setOpen] = useState(false);

    return (
        <Box className={styles.container}>
            <Box className={styles.typoContainer}>
                <img
                    src={green}
                    alt="title_icon"
                    style={{ backgroundColor: theme.palette.common.white, paddingRight: "4px" }}
                />
                <Typography variant="h3" className={styles.title}>
                    <Trans>Opérations en vert</Trans>
                </Typography>
            </Box>
            <Timeline align={props.displayALign} style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}>
                {props?.greenOperations?.map((el, i) => {
                    if (i < 6) {
                        return (
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot
                                        variant="outlined"
                                        style={{
                                            borderColor: theme.palette.success.main,
                                            borderWidth: "3px",
                                        }}
                                    />
                                    <TimelineConnector style={{ backgroundColor: theme.palette.success.main }} />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Box className={styles.stepLabelText}>
                                        {el?.operation && (
                                            <Typography
                                                variant="h6"
                                                className={styles.stepLabelText__TypoPrim}
                                                style={{ color: theme.palette.primary.light }}
                                            >
                                                {firstLetterUppercase(el.operation)}
                                            </Typography>
                                        )}
                                        <Typography
                                            className={styles.stepLabelText__TypoSecond}
                                            style={{ color: theme.palette.primary.dark }}
                                        >
                                            {getSentence(el)}
                                        </Typography>
                                    </Box>
                                </TimelineContent>
                            </TimelineItem>
                        );
                    }
                })}
            </Timeline>
            {props.greenOperations?.length > 5 && (
                <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Arrow
                        rotation="105"
                        arrowPosition="start"
                        style={{
                            margin: 0,
                            marginLeft: theme.spacing(1),
                            color: theme.palette.primary.light,
                        }}
                    >
                        <Link
                            onClick={() => setOpen(true)}
                            variant="h6"
                            color="primary"
                            underline="always"
                            className={styles.linkTypoWineGreen}
                            style={{ color: theme.palette.primary.light, fontSize: "14px" }}
                        >
                            <Trans>Voir toutes les opérations en vert</Trans>
                        </Link>
                    </Arrow>
                </Box>
            )}

            {open && (
                <WineGreenOperationsPopin
                    open={open}
                    onClose={() => setOpen(false)}
                    cross
                    greenOperations={props?.greenOperations}
                />
            )}
        </Box>
    );
};

WineGreenOperations.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    greenOperations: PropTypes.array,
    displayALign: PropTypes.string,
};

export default WineGreenOperations;
