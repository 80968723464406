import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import PropTypes, { string } from "prop-types";
import { CarouselProvider, Slider, Slide, DotGroup, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import img2 from "../../assets/ChateauGiscours/slide2.jpg";
import { useWindowDimensions } from "../../helpers/helpers";
import Loading from "Components/Loader/Loading";

const useStyle = makeStyles((theme) => ({
    carouselDotGroup: (props) => ({
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        "& > button": {
            display: props.images?.length < 2 ? "none" : "flex",
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "50px",
            width: "9px",
            height: "9px",
            padding: 0,
            border: "none",
            marginRight: theme.spacing(1),
        },
        "& > button:disabled": {
            backgroundColor: theme.palette.primary.light,
            borderRadius: "50px",
            width: "9px",
            height: "9px",
            padding: 0,
        },
    }),
    carousel: {
        display: "flex",
        flexDirection: "column",
        overflowY: "hidden",
        position: "relative",
        // [theme.breakpoints.only("xs")]: {
        //     height: "auto",
        // },
    },
    carouselSlider: (props) => ({
        position: "block",
        [theme.breakpoints.up("xs")]: {
            height: props.responsiveHeight?.[0] ? props.responsiveHeight?.[0] : 310,
            "& .slideInner___2mfX9": {
                height: props.responsiveHeight?.[0] ? props.responsiveHeight?.[0] : 310,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: theme.palette.dashboard.background,
                borderRadius: 10,
            },
            "& .horizontalSlider___281Ls": {
                height: props.responsiveHeight?.[0] ? props.responsiveHeight?.[0] : 310,
            },
        },
        [theme.breakpoints.up("sm")]: {
            height: props.responsiveHeight?.[1] ? props.responsiveHeight?.[1] : 310,
            "& .slideInner___2mfX9": {
                height: props.responsiveHeight?.[1] ? props.responsiveHeight?.[1] : 310,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: theme.palette.dashboard.background,
                borderRadius: 10,
            },
            "& .horizontalSlider___281Ls": {
                height: props.responsiveHeight?.[1] ? props.responsiveHeight?.[1] : 310,
            },
        },
        [theme.breakpoints.only("md")]: {
            height: props.responsiveHeight?.[2] ? props.responsiveHeight?.[2] : 350,
            "& .slideInner___2mfX9": {
                height: props.responsiveHeight?.[2] ? props.responsiveHeight?.[2] : 350,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: theme.palette.dashboard.background,
                borderRadius: 10,
            },
            "& .horizontalSlider___281Ls": {
                height: props.responsiveHeight?.[2] ? props.responsiveHeight?.[2] : 350,
            },
        },
        [theme.breakpoints.only("lg")]: {
            height: props.responsiveHeight?.[3] ? props.responsiveHeight?.[3] : 350,
            "& .slideInner___2mfX9": {
                height: props.responsiveHeight?.[3] ? props.responsiveHeight?.[3] : 350,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: theme.palette.dashboard.background,
                borderRadius: 10,
            },
            "& .horizontalSlider___281Ls": {
                height: props.responsiveHeight?.[3] ? props.responsiveHeight?.[3] : 350,
            },
        },
        [theme.breakpoints.only("xl")]: {
            height: props.responsiveHeight?.[4] ? props.responsiveHeight?.[4] : 350,
            "& .slideInner___2mfX9": {
                height: props.responsiveHeight?.[4] ? props.responsiveHeight?.[4] : 350,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: theme.palette.dashboard.background,
                borderRadius: 10,
            },
            "& .horizontalSlider___281Ls": {
                height: props.responsiveHeight?.[4] ? props.responsiveHeight?.[4] : 350,
            },
        },
    }),
    carouselImg: {
        width: "100%",
        height: "100%",
        borderRadius: 10,
        objectFit: "cover",
        objectPosition: "50% 0%",
    },
    container: {
        position: "relative",
    },
    buttonBack: (props) => ({
        display: props.images?.length < 2 ? "none" : "flex",
        left: 0,
        position: "absolute",
        top: "40%",
        background: "none",
        border: "none",
        outline: "none",
    }),
    buttonNext: (props) => ({
        display: props.images?.length < 2 ? "none" : "flex",
        right: 0,
        position: "absolute",
        top: "40%",
        background: "none",
        border: "none",
        outline: "none",
    }),
    arrowIcon: {
        color: "white",
        width: "20px",
    },
}));

const BaseCarousel = (props) => {
    const styles = useStyle(props);
    const { width } = useWindowDimensions();

    return (
        <CarouselProvider
            totalSlides={props.images?.length}
            naturalSlideWidth={100}
            naturalSlideHeight={width >= 600 ? 125 : 0}
            className={styles.carousel}
            infinite={true}
            dragEnabled={false}
        >
            {props.images?.length === 0 ? (
                <img src={props.subImg ?? img2} className={styles.carouselImg} alt={props.alt} />
            ) : (
                <Slider className={styles.carouselSlider}>
                    {props.images?.map((el, i) => (
                        <Slide key={i} index={i}>
                            <img
                                src={props.front ? el?.twicpicURL : el}
                                className={styles.carouselImg}
                                alt={props.alt}
                            />
                        </Slide>
                    ))}
                </Slider>
            )}
            <ButtonBack className={styles.buttonBack}>
                <ArrowBackIosIcon className={styles.arrowIcon} />
            </ButtonBack>
            <ButtonNext className={styles.buttonNext}>
                <ArrowForwardIosIcon className={styles.arrowIcon} />
            </ButtonNext>
            {props.images?.length > 1 && <DotGroup className={styles.carouselDotGroup} />}
        </CarouselProvider>
    );
};

BaseCarousel.propTypes = {
    images: PropTypes.array,
    imageStyle: PropTypes.object,
    front: PropTypes.bool,
    subImg: PropTypes.any,
    responsiveHeight: PropTypes.array,
    alt: string,
};

export default BaseCarousel;
