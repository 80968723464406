import { Box, makeStyles, Typography } from "@material-ui/core";
import { string } from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";

import { t } from "@lingui/macro";
import Helmet from "Components/Helmet/Helmet";
import { FR_AND_EN, SUBSCRIPTION_TYPES, SUBSCRIPTION_STATUS, URL_REDIRECT } from "config/constants";
import BrowsingPreferenceModal from "Front/browsingPreference/BrowsingPreferenceModal";
import { useCustomHistory } from "hooks";
import Journalist from "Journalist/Journalist";
import { useTranslation } from "react-i18next";
import { RestrictedLanguageRoute } from "Routes/RestrictedLanguageRoute";
import Loading from "../Components/Loader/Loading";
import { signIn } from "../features/user/userSlice";
import theme from "../globalTheme";
import ConnectionContainer from "../SubscriptionTunnel/Containers/ConnectionContainer";
import { ConnectedRegistrationContainer } from "../SubscriptionTunnel/Containers/RegistrationContainer";
import ForgotPassword from "./ForgotPassword";

const useStyles = makeStyles(() => ({
    grid_container: {
        display: "grid",
        gridTemplateColumns: "repeat(12,1fr)",
        gap: "1rem",
        [theme.breakpoints.up("sm")]: {
            margin: "20px",
        },
        [theme.breakpoints.up("lg")]: {
            margin: "0",
        },
    },
    title: {
        [theme.breakpoints.up("xs")]: {
            textAlign: "center",
            gridColumn: "2 /span 10",
        },
        [theme.breakpoints.up("md")]: {
            textAlign: "left",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 12",
        },
    },
}));

const ConnectionAndSubscription = (props) => {
    const history = useCustomHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const settingsLocale = useSelector((state) => state?.user?.locale || "fr");
    const [called, setCalled] = useState(false);
    const [warningBrowserLanguage, setWarningBrowserLanguage] = useState(settingsLocale);
    const { url } = useRouteMatch();
    const adoptWineEntity = useSelector((state) => state?.settings?.adoptWineEntity);
    const styles = useStyles();
    const { t: ti18next } = useTranslation();

    const user = useSelector((state) => state.user);
    const { currentUser, loading } = user ?? {};
    const connexionUrl = location.pathname === `${url}/connexion`;
    const journalistUrl = location.pathname === `${url}/inscription/journaliste`;

    useEffect(() => {
        if (currentUser) {
            if (currentUser?.subscriptionName === SUBSCRIPTION_TYPES.journalist) {
                history.replace("/dashboard/tastingrequest");
            } else if (currentUser?.status === SUBSCRIPTION_STATUS.ACTIVE) {
                history.replace("/");
            } else {
                history.replace("/subscription");
            }
        }
    }, [currentUser]);

    useEffect(() => {
        if (props.isStandalone) {
            window.location.href = `${URL_REDIRECT}${location.pathname}`;
        }
    }, [location]);

    useEffect(() => {
        if ((journalistUrl || connexionUrl) && !FR_AND_EN.includes(settingsLocale)) {
            setWarningBrowserLanguage(true);
        } else if (
            (location.pathname === url || location.pathname == `${url}/inscription`) &&
            settingsLocale !== "fr"
        ) {
            setWarningBrowserLanguage(true);
        } else {
            setWarningBrowserLanguage(false);
        }
    }, [settingsLocale, location]);

    const handleConnectionOnSubmit = async (values) => {
        dispatch(
            signIn({
                username: values.subscriptionEmailAddress,
                password: values.subscriptionPassword,
            })
        );
        setCalled(true);
    };

    if (loading || props.isStandalone) {
        return (
            <Box style={{ width: "579px" }}>
                <Loading />
            </Box>
        );
    }

    return (
        <>
            <BrowsingPreferenceModal
                open={warningBrowserLanguage}
                registrationMode={!journalistUrl && !connexionUrl}
                onClose={() => setWarningBrowserLanguage(false)}
                onClickBack={() => history.goBack()}
                noEnlish
            />
            <Switch>
                <RestrictedLanguageRoute exact path={`${url}`} languages={["fr"]}>
                    <Box className={styles.grid_container}>
                        <ConnectedRegistrationContainer
                            adoptWineEntity={adoptWineEntity}
                            textSignIn={!adoptWineEntity}
                        />
                    </Box>
                </RestrictedLanguageRoute>
                <RestrictedLanguageRoute path={`${url}/connexion`} languages={FR_AND_EN}>
                    <Box className={styles.grid_container}>
                        <Typography variant="h2" className={styles.title}>
                            {ti18next("connexion.loginTitle")}
                        </Typography>
                        <ConnectionContainer
                            forgotPasswordOnClick={() => history.push("/bienvenue/mdp")}
                            onSubmit={(formValues) => handleConnectionOnSubmit(formValues)}
                            setCalled={setCalled}
                            called={called}
                            homeConnexion
                            textSignUp={settingsLocale === "fr"}
                            width="auto"
                            contactUs={settingsLocale === "en"}
                        />
                    </Box>
                </RestrictedLanguageRoute>
                <RestrictedLanguageRoute path={`${url}/inscription/journaliste`} languages={FR_AND_EN}>
                    <Journalist />
                </RestrictedLanguageRoute>

                <Route path={`${url}/inscription`}>
                    <Box className={styles.grid_container}>
                        <ConnectedRegistrationContainer textSignIn />
                    </Box>
                </Route>
                <Route path={`${url}/mdp`}>
                    <Helmet title={t`Réinitialiser le mot de passe`} />

                    <Box className={styles.grid_container}>
                        <Box className={styles.title}>
                            <Typography variant="h2">Mot de passe oublié</Typography>
                            <Typography variant="h5">
                                Saisissez l&apos;adresse email associée à votre compte. Nous vous enverrons un lien par
                                email pour réinitialiser votre mot de passe.
                            </Typography>{" "}
                        </Box>
                        <ForgotPassword onClick={() => history.push("/bienvenue/inscription")} />
                    </Box>
                </Route>
            </Switch>
        </>
    );
};

export default ConnectionAndSubscription;

ConnectionAndSubscription.propTypes = {
    isStandalone: string,
};
