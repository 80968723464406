import { Box, Icon, Link, makeStyles, Typography } from "@material-ui/core";
import waiting from "assets/navbarfront/basic_waiting.svg";
import basket from "assets/navbarfront/basket.svg";
import { PAYMENT_METHODS, PAYMENT_STATUS, SUBSCRIPTION_TYPES } from "config/constants";
import theme from "globalTheme";
import { getPaymentMethod, getPaymentStatus, getSubscriptionName, subscriptionNameFormat } from "helpers/helpers";
import React from "react";
import { useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
    legend_container: {
        width: "fit-content",
        display: "flex",
        flexDirection: "row",
        alignItems: "start",
        marginBottom: "0.5rem",
        gap: "0.5rem",
    },
    icon_container: {
        width: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    icon: {
        width: "100%",
        height: "100%",
    },
    typo_waiting: {
        color: theme.palette.secondary.dark,
    },
    typo_proceeding: {
        color: theme.palette.primary.main,
    },
}));
const SubscriptionInProgress = () => {
    const subscriptionName = useSelector((state) => getSubscriptionName(state));
    const paymentMethod = useSelector((state) => getPaymentMethod(state));
    const paymentStatus = useSelector((state) => getPaymentStatus(state));
    const styles = useStyles();

    if (paymentStatus !== PAYMENT_STATUS.DONE) {
        if (subscriptionName === SUBSCRIPTION_TYPES.basic) {
            return (
                <Box className={styles.legend_container}>
                    <Icon className={styles.icon_container}>
                        <img className={styles.icon} src={waiting} />
                    </Icon>
                    <Typography variant="h5" className={styles.typo_waiting}>
                        Abonnement« {subscriptionNameFormat[subscriptionName]} » en cours de validation...
                    </Typography>
                </Box>
            );
        } else if (paymentMethod === PAYMENT_METHODS.offline) {
            return (
                <Box className={styles.legend_container}>
                    <Icon className={styles.icon_container}>
                        <img className={styles.icon} src={waiting} />
                    </Icon>
                    <Typography variant="h5" className={styles.typo_waiting}>
                        Abonnement« {subscriptionNameFormat[subscriptionName]} » en attente de paiement...
                    </Typography>
                </Box>
            );
        } else {
            return (
                <Link className={styles.legend_container} href="/subscription">
                    <Icon className={styles.icon_container}>
                        <img className={styles.icon} src={basket} />
                    </Icon>
                    <Typography variant="h5" className={styles.typo_proceeding}>
                        Continuer ma souscription
                    </Typography>
                </Link>
            );
        }
    }
    return (
        <Box className={styles.legend_container}>
            <Icon className={styles.icon_container}>
                <img className={styles.icon} src={waiting} />
            </Icon>
            <Typography variant="h5" className={styles.typo_waiting}>
                Abonnement en cours de validation...
            </Typography>
        </Box>
    );
};

SubscriptionInProgress.propTypes = {};

export default SubscriptionInProgress;
