import { createMuiTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import "./globalStyle.css";

const breakpoints = createBreakpoints({
    values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
    },
});

const themeObject = {
    breakpoints,
    palette: {
        primary: {
            main: "#632D3E",
            light: "#915868",
            dark: "#360318",
            contrastText: "#ffffff",
        },
        secondary: {
            main: "#D0BDC3",
            light: "#FFEFF6",
            dark: "#9F8D93",
            contrastText: "#432e35",
            transparent: "rgb(145 88 104 / 30%)",
            disabled: "#C1ABB2",
        },
        text: {
            primary: "#000000",
            secondary: "#360318",
            disabled: "#9F8D93",
        },
        error: {
            main: "#ff0000",
            dark: "#DB0316",
        },
        warning: {
            main: "#F48D00",
            dark: "#E07D21",
        },
        info: {
            main: "#857fbb",
        },
        success: {
            light: "#C0D4BE",
            main: "#9BBA97",
            dark: "#6AC561",
        },
        common: {
            white: "#FFFFFF",
            black: "#000000",
        },
        background: {
            paper: "#FFFFFF",
            card: "#FCF6F6",
        },
        disabled: {
            main: "#FCF6F6",
        },
        action: {
            disabledBackground: "#FCF6F6",
        },
        dashboard: {
            background: "#FCF6F6",
        },
        switch: {
            neutral: "#C4C4C4",
            disabled: "#E5E5E5",
        },
        wine: {
            main: "#90174A",
            secondary: "#FF9EA2",
            light: "#C0D4BE",
            white: "#E9EBB7",
        },
        magenta: {
            light: "#C58E9E",
        },
    },

    action: {
        disabledBackground: "#FCF6F6",
    },
    typography: {
        fontFamily: "Lato, Helvetica, Arial, sans-serif",
        h1: {
            fontFamily: "Playfair Display, Helvetica, Arial, sans-serif",
            transition: "fontSize .6s",
            fontSize: "4.688rem",
            [breakpoints.up("xs")]: {
                fontSize: "2.188rem",
            },
            [breakpoints.up("md")]: {
                fontSize: "3.125rem",
            },
            [breakpoints.up("xl")]: {
                fontSize: "4.688rem",
            },
        },
        h2: {
            fontFamily: "Playfair Display, Helvetica, Arial, sans-serif",
            transition: "fontSize .6s",
            [breakpoints.up("xs")]: {
                fontSize: "1.875rem",
            },
            [breakpoints.up("md")]: {
                fontSize: "2.188rem",
            },
            [breakpoints.up("xl")]: {
                fontSize: "3.125rem",
            },
        },
        h3: {
            fontFamily: "Playfair Display, Helvetica, Arial, sans-serif",
            transition: "fontSize .6s",
            [breakpoints.up("xs")]: {
                fontSize: "1.563rem",
            },
            [breakpoints.up("md")]: {
                fontSize: "1.563rem",
            },
            [breakpoints.up("xl")]: {
                fontSize: "1.813rem",
            },
        },
        h4: {
            fontFamily: "Playfair Display, Helvetica, Arial, sans-serif",
            fontWeight: 700,
            fontSize: "1.125rem",
            transition: "fontSize .6s",
            [breakpoints.up("xs")]: {
                fontSize: "1rem",
            },
            [breakpoints.up("md")]: {
                fontSize: "1rem",
            },
            [breakpoints.up("xl")]: {
                fontSize: "1.125rem",
            },
        },
        h5: {
            fontFamily: "Lato, Helvetica, Arial, sans-serif",
            transition: "fontSize .6s",
            fontSize: "1.125rem",
            [breakpoints.up("xs")]: {
                fontSize: "1rem",
            },
            [breakpoints.up("md")]: {
                fontSize: "1rem",
            },
            [breakpoints.up("xl")]: {
                fontSize: "1.125rem",
            },
        },
        h6: {
            fontFamily: "Lato, Helvetica, Arial, sans-serif",
            transition: "fontSize .6s",
            fontSize: "0.875rem",
            [breakpoints.up("xs")]: {
                fontSize: "0.875rem",
            },
        },
        body1: {
            fontFamily: "Lato, Helvetica, Arial, sans-serif",
            transition: "fontSize .6s",
            [breakpoints.up("xs")]: {
                fontSize: "1rem",
            },
            [breakpoints.up("md")]: {
                fontSize: "1rem",
            },
            [breakpoints.up("xl")]: {
                fontSize: "1.125rem",
            },
        },
        body2: {
            fontFamily: "Lato, Helvetica, Arial, sans-serif",
            transition: "fontSize .6s",
            fontSize: "1rem",
            [breakpoints.up("xs")]: {
                fontSize: "0.875rem",
            },
            [breakpoints.up("md")]: {
                fontSize: "0.875rem",
            },
            [breakpoints.up("xl")]: {
                fontSize: "1rem",
            },
        },
        subtitle1: {
            fontFamily: "Lato, Helvetica, Arial, sans-serif",
            fontSize: "0.625rem",
        },
        button: {
            textTransform: "none",
            fontFamily: "Lato, Helvetica, Arial, sans-serif",
            transition: "fontSize .6s",
            [breakpoints.up("xs")]: {
                fontSize: "0.875rem",
            },
            [breakpoints.up("md")]: {
                fontSize: "0.875rem",
            },
            [breakpoints.up("xl")]: {
                fontSize: "1rem",
            },
        },
    },
    paddings: {
        default: {
            popin: {
                transition: "padding .6s ease-in-out",
                [breakpoints.up("xs")]: {
                    padding: "10px 10px",
                },
                [breakpoints.up("sm")]: {
                    padding: "20px 20px",
                },
                [breakpoints.up("md")]: {
                    padding: "20px 20px",
                },
                [breakpoints.up("lg")]: {
                    padding: "20px 20px",
                },
                [breakpoints.up("xl")]: {
                    padding: "20px 20px",
                },
            },
            card: {
                transition: "padding .6s ease-in-out",
                [breakpoints.up("xs")]: {
                    padding: "10px 10px",
                },
                [breakpoints.up("sm")]: {
                    padding: "20px 20px",
                },
                [breakpoints.up("md")]: {
                    padding: "30px 20px",
                },
                [breakpoints.up("lg")]: {
                    padding: "30px 20px",
                },
                [breakpoints.up("xl")]: {
                    padding: "32px 40px",
                },
            },
            tunnel: {
                transition: "padding .6s ease-out",
                [breakpoints.up("xs")]: {
                    padding: "16px 16px 0 16px",
                },
                [breakpoints.up("sm")]: {
                    padding: "16px 16px 0 16px",
                },
                [breakpoints.up("md")]: {
                    padding: "70px 16px 0 16px",
                },
                [breakpoints.up("lg")]: {
                    padding: "70px 16px 0 16px",
                },
                [breakpoints.up("xl")]: {
                    padding: "105px 48px 0 48px",
                },
            },
        },
        extra: {
            comparativeTable: {
                transition: "padding .6s ease-in-out",
                [breakpoints.up("xs")]: {
                    padding: "10px 10px",
                },
                [breakpoints.up("sm")]: {
                    padding: "20px 20px",
                },
                [breakpoints.up("md")]: {
                    padding: "30px 20px",
                },
                [breakpoints.up("lg")]: {
                    padding: "30px 58px",
                },
                [breakpoints.up("xl")]: {
                    padding: "32px 116px",
                },
            },
        },
    },
    margins: {
        default: {
            popin: {
                transition: "margin .6s ease-in-out",
                [breakpoints.up("xs")]: {
                    margin: "10px 10px",
                },
                [breakpoints.up("sm")]: {
                    margin: "20px 20px",
                },
                [breakpoints.up("md")]: {
                    margin: "20px 20px",
                },
                [breakpoints.up("lg")]: {
                    margin: "20px 20px",
                },
                [breakpoints.up("xl")]: {
                    margin: "20px 20px",
                },
            },
        },
    },

    dashboard: {
        background: "#FCF6F6",
    },
    front: {
        typography: {
            h1: {
                light: {
                    color: "#FFFFFF",
                    fontFamily: "Playfair Display, Helvetica, Arial, sans-serif",
                    fontSize: "50px",
                },
                dark: {
                    color: "#000000",
                    fontFamily: "Playfair Display, Helvetica, Arial, sans-serif",
                    fontSize: "50px",
                },
            },
            h2: {
                light: {
                    color: "#FFFFFF",
                    fontFamily: "Lato, Helvetica, Arial, sans-serif",
                    fontSize: "30px",
                },
                dark: {
                    fontSize: "30px",
                    fontFamily: "Lato, Helvetica, Arial, sans-serif",
                    color: "#000000",
                },
            },
            h3: {
                light: {
                    color: "#FFFFFF",
                    fontFamily: "Playfair Display, Helvetica, Arial, sans-serif",
                    fontSize: "29px",
                },
                dark: {
                    color: "#000000",
                    fontFamily: "Playfair Display, Helvetica, Arial, sans-serif",
                    fontSize: "29px",
                },
                dark_i: {
                    color: "#000000",
                    fontFamily: "Playfair Display, Helvetica, Arial, sans-serif",
                    fontSize: "29px",
                    fontStyle: "italic",
                },
                dark_b: {
                    color: "#000000",
                    fontFamily: "Playfair Display, Helvetica, Arial, sans-serif",
                    fontSize: "29px",
                    fontWeight: "bold",
                },
            },
            h4: {
                light: {
                    color: "#FFFFFF",
                    fontFamily: "Lato, Helvetica, Arial, sans-serif",
                    fontSize: "24px",
                },
                dark: {
                    color: "#000000",
                    fontFamily: "Lato, Helvetica, Arial, sans-serif",
                    fontSize: "24px",
                },
            },
            h5: {
                light_b: {
                    color: "#FFFFFF",
                    fontSize: "21px",
                    fontFamily: "Playfair Display, Helvetica, Arial, sans-serif",
                    fontWeight: "bold",
                },
                dark: {
                    color: "#000000",
                    fontSize: "21px",
                    fontFamily: "Playfair Display, Helvetica, Arial, sans-serif",
                },
                dark_b: {
                    color: "#000000",
                    fontSize: "21px",
                    fontFamily: "Playfair Display, Helvetica, Arial, sans-serif",
                    fontWeight: "bold",
                },
            },
            h6: {
                light_b: {
                    color: "#FFFFFF",
                    fontSize: "18px",
                    fontFamily: "Playfair Display, Helvetica, Arial, sans-serif",
                    fontWeight: "bold",
                },
                dark_b: {
                    color: "#000000",
                    fontSize: "18px",
                    fontFamily: "Playfair Display, Helvetica, Arial, sans-serif",
                    fontWeight: "bold",
                },
                secondDark_b: {
                    color: "#9F8D93",
                    fontSize: "18px",
                    fontFamily: "Playfair Display, Helvetica, Arial, sans-serif",
                    fontWeight: "bold",
                },
            },
            labeur: {
                light: {
                    fontSize: "16px",
                    fontFamily: "Lato, Helvetica, Arial, sans-serif",
                    color: "#FFFFFF",
                },
                dark: {
                    fontSize: "16px",
                    fontFamily: "Lato, Helvetica, Arial, sans-serif",
                    color: "#000000",
                },
                dark_b: {
                    color: "#000000",
                    fontFamily: "Lato, Helvetica, Arial, sans-serif",
                    fontSize: "16px",
                    fontWeight: "bold",
                },
                clear: {
                    color: "#9F8D93",
                    fontFamily: "Lato, Helvetica, Arial, sans-serif",
                    fontSize: "16px",
                },
            },
            label: {
                light: {
                    color: "#FFFFFF",
                    fontFamily: "Lato, Helvetica, Arial, sans-serif",
                    fontSize: "14px",
                },
                dark: {
                    color: "#000000",
                    fontFamily: "Lato, Helvetica, Arial, sans-serif",
                    fontSize: "14px",
                },
                clear_i: {
                    color: "#9F8D93",
                    fontFamily: "Lato, Helvetica, Arial, sans-serif",
                    fontSize: "14px",
                    fontStyle: "italic",
                },
                gray: {
                    color: "#FCF6F6",
                    fontFamily: "Lato, Helvetica, Arial, sans-serif",
                    fontSize: "14px",
                },
                darkLight: {
                    color: "#915868",
                    fontFamily: "Lato, Helvetica, Arial, sans-serif",
                    fontSize: "14px",
                },
            },
            arrow: {
                color: "#432E35",
                fontFamily: "Mansalva, Arial, sans-serif",
                fontSize: "14px",
            },
            inputLabel: {
                gray: {
                    coor: "#9F8D93",
                    fontFamily: "Lato, Helvetica, Arial, sans-serif",
                    fontSize: "10px",
                },
            },
        },
        padding: {
            card: {
                [breakpoints.up("xs")]: {
                    padding: "14px",
                },
                [breakpoints.up("sm")]: {
                    padding: "40px 14px",
                },
                [breakpoints.up("md")]: {
                    padding: "40px 34px",
                },
                [breakpoints.up("lg")]: {
                    padding: "34px 40px",
                },
                [breakpoints.up("xl")]: {
                    padding: "40px 90px",
                },
            },
            estateCard: {
                [breakpoints.up("xs")]: {
                    padding: "14px",
                },
                [breakpoints.up("sm")]: {
                    padding: "40px 14px",
                },
                [breakpoints.up("md")]: {
                    padding: "40px 34px",
                },
                [breakpoints.up("lg")]: {
                    padding: "34px 40px",
                },
                [breakpoints.up("xl")]: {
                    padding: "80px 90px",
                },
            },
            propertySummary: {
                [breakpoints.up("xs")]: {
                    padding: "8px",
                },
                [breakpoints.up("sm")]: {
                    padding: "16px",
                },
                [breakpoints.up("md")]: {
                    padding: "24px",
                },
                [breakpoints.up("lg")]: {
                    padding: "24px",
                },
                [breakpoints.up("xl")]: {
                    padding: "24px",
                },
            },
            timeline: {
                [breakpoints.up("xs")]: {
                    padding: "16px",
                },
                [breakpoints.up("sm")]: {
                    padding: "16px",
                },
                [breakpoints.up("md")]: {
                    padding: "16px",
                },
                [breakpoints.up("lg")]: {
                    padding: "32px",
                },
                [breakpoints.up("xl")]: {
                    padding: "32px",
                },
            },
            popInVignoble: {
                [breakpoints.up("xs")]: {
                    padding: "8px",
                },
                [breakpoints.up("sm")]: {
                    padding: "16px",
                },
                [breakpoints.up("md")]: {
                    padding: "16px",
                },
                [breakpoints.up("lg")]: {
                    padding: "32px 64px",
                },
                [breakpoints.up("xl")]: {
                    padding: "32px 120px",
                },
            },
            cardMembersPaper: {
                [breakpoints.up("xs")]: {
                    padding: "16px",
                },
                [breakpoints.up("sm")]: {
                    padding: "16px",
                },
                [breakpoints.up("md")]: {
                    padding: "32px",
                },
                [breakpoints.up("lg")]: {
                    padding: "32px",
                },
                [breakpoints.up("xl")]: {
                    padding: "32px 88px 32px 88px",
                },
            },
        },
        margin: {},
        components: {
            card: {
                padding: "88px",
                [breakpoints.up("xs")]: {},
            },
        },
    },
} as const;
// export const theme = createMuiTheme(themeObject);

type CustomTheme = {
    [Key in keyof typeof themeObject]: typeof themeObject[Key];
};
declare module "@material-ui/core/styles/createMuiTheme" {
    interface Theme extends CustomTheme {}
    interface ThemeOptions extends CustomTheme {}
}

export default createMuiTheme(themeObject);
