import { shape, string } from "prop-types";
import { Box, Card, CardContent, Typography, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import theme from "../globalTheme";
import { useCustomHistory } from "hooks";
import { getScoringLogo } from "helpers/helpers";
import DefaultLogo from "Journalist/DefaultLogo";

const useStyles = makeStyles(() => ({
    card: {
        borderRadius: "10px",
        width: 322,
        height: "260px",
        padding: "0px",
        backgroundColor: theme.palette.common.white,
        cursor: "pointer",
        textDecoration: "none",
        boxShadow: "0px 4px 16px 0px #00000014",
    },
    item_title: {
        color: theme.palette.primary.dark,
        fontWeight: "bold",
        margin: "30px 14px 8px 14px",
    },
    content_container: {
        backgroundColor: theme.palette.success.light,
        flex: 1,
        position: "relative",
        textAlign: "center",
    },
    logo: {
        maxWidth: "100%",
        maxHeight: "100%",
    },
    cardImageBox: {
        height: "138px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 10,
    },
    cardContentBox: {
        padding: "0px",
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    boxLink: {
        display: "flex",
        justifyContent: "flex-end",
        position: "absolute",
        bottom: 10,
        right: 14,
    },
}));

const JournalistCard = ({ journalist }) => {
    const styles = useStyles();
    const history = useCustomHistory();

    const url = history.addLocale(`/salle-de-degustation/${journalist.slug}`);
    const logoUrl = journalist?.logo?.twicpicURL ?? getScoringLogo(journalist.company);
    return (
        <Card className={styles.card} component={Link} to={url}>
            <CardContent className={styles.cardContentBox}>
                <Box className={styles.cardImageBox}>
                    {logoUrl ? (
                        <img
                            src={logoUrl}
                            style={{ margin: "2px auto" }}
                            className={styles.logo}
                            alt="logo challenge"
                        />
                    ) : (
                        <DefaultLogo criticName={journalist?.title} />
                    )}
                </Box>

                <Box className={styles.content_container}>
                    <Typography variant="h4" className={styles.item_title}>
                        {journalist.title}
                    </Typography>

                    <Box className={styles.boxLink}>
                        <Link to={url}>
                            <ArrowForwardIcon
                                style={{
                                    color: theme.palette.primary.main,
                                    marginLeft: 5,
                                }}
                            />
                        </Link>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

JournalistCard.propTypes = {
    journalist: shape({
        slug: string,
        title: string,
        logo: shape({ twicpicURL: string }),
    }),
};

export default JournalistCard;
