import FeretSummaryContainer from "../Front/FeretSummary/FeretSummaryContainer";
import FeretSummaryElement from "../Front/FeretSummary/FeretSummaryElement";
import { Box, makeStyles, Typography } from "@material-ui/core";
import theme from "../globalTheme";
import school from "../assets/formation/summary/school.svg";
import what from "../assets/formation/summary/what.svg";
import contact from "../assets/formation/summary/contact.svg";
import download2 from "../assets/summary/download.svg";
import { t, Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
    subtitle_primary: {
        color: theme.palette.primary.light,
        lineHeight: "16px",
    },
    subtitle_secondary: {
        color: theme.palette.primary.dark,
    },
}));

interface FormationSummaryProps {
    formation: string;
    howToRegister: string;
    contact: { address: string; postalCode: number; commune: string; phone: string; email: string };
    maxWidth?: string;
}

const FormationSummary = (props: FormationSummaryProps) => {
    const styles = useStyles();

    return (
        <FeretSummaryContainer maxWidth={props.maxWidth ?? "none"}>
            <FeretSummaryElement icon={school} title={t`Rythme de formation`} subtitle={props.formation} />
            <FeretSummaryElement
                icon={what}
                title={t`Comment s’inscrire ?`}
                subtitle={
                    <>
                        <Trans>En ligne, accéder</Trans>{" "}
                        <Box style={{ marginLeft: "0.25rem" }}>
                            <a href={props.howToRegister} target="_blank" rel="noreferrer">
                                <img style={{ width: "18px", height: "18px" }} src={download2} />
                            </a>
                        </Box>
                    </>
                }
            />
            <FeretSummaryElement
                icon={contact}
                title={t`Contact`}
                subtitle={
                    <Box>
                        <Typography variant="h6" className={styles.subtitle_secondary}>
                            {props.contact.address}
                        </Typography>
                        <Typography variant="h6" className={styles.subtitle_secondary}>
                            {props.contact.postalCode} {props.contact.commune}
                        </Typography>
                        <Typography variant="h6" className={styles.subtitle_secondary}>
                            {props.contact.email}
                        </Typography>
                        <Typography variant="h6" className={styles.subtitle_secondary}>
                            {props.contact.phone}
                        </Typography>
                    </Box>
                }
            />
        </FeretSummaryContainer>
    );
};

export default FormationSummary;
