import { t, Trans } from "@lingui/macro";
import { Box, makeStyles, Tab, Tabs, Typography } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes, { string } from "prop-types";
import React, { useEffect, useState } from "react";

import bottle from "../../assets/propriétéPage/cellarPopin/bottle.svg";
import circulation from "../../assets/propriétéPage/cellarPopin/circulation.svg";
import conditionnement from "../../assets/propriétéPage/cellarPopin/conditionnement.svg";
import containerImg from "../../assets/propriétéPage/cellarPopin/container.svg";
import elevage from "../../assets/propriétéPage/cellarPopin/elevage.svg";
import storageImg from "../../assets/propriétéPage/cellarPopin/storage.svg";
import contenant from "../../assets/propriétéPage/cellarPopin/tank.svg";
import thermoregulationImg from "../../assets/propriétéPage/cellarPopin/thermoregulation.svg";
import imgSub1 from "../../assets/propriétéPage/vinif.png";
import BaseCarousel from "../../Components/Carousel/BaseCarousel";
import Popin from "../../Containers/Popin";
import { useCellarFrontPopinQuery } from "../../generated/graphql";
import getConnectors, {
    applySeparator,
    commaInFr,
    firstLetterUppercase,
    parseHtml,
    useWindowDimensions,
} from "../../helpers/helpers";
import theme from "../../globalTheme";
import { sumByKey } from "utils/price";

const useStyle = makeStyles((theme) => ({
    cellarAreaText: {
        ...theme.front.typography.labeur.dark,
        color: theme.palette.secondary.dark,
    },
    titleTextsBox: {
        width: "100%",
        display: "flex",
        alignItems: "baseline",
        justifyContent: "flex-start",
    },
    titleText: {
        ...theme.front.typography.h3.dark,
        marginRight: theme.spacing(1),
    },
    addressAndDescriptionTexts: {
        ...theme.front.typography.labeur.dark,
        textAlign: "left",
        width: "100%",
        marginBottom: theme.spacing(1),
    },
    tabs: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        paddingBottom: "20px",
        "& .MuiTabs-indicator": {
            height: "4px",
            width: "100%",
            borderRadius: "5px 5px 0px 0px",
            backgroundColor: theme.palette.primary.light,
        },
        "& .MuiTabs-scrollable": {
            borderBottom: "1px solid",
            borderBottomColor: theme.palette.secondary.main,
        },
        "& .MuiTabScrollButton-root.Mui-disabled": {
            transitionProperty: "width, opacity",
            transitionDuration: ".8s",
            animationTimingFunction: "cubic-bezier(.59,.01,.39,1)",
            width: "0px",
        },
        "& .MuiTabScrollButton-root": {
            transitionProperty: "width, opacity",
            transitionDuration: ".8s",
            animationTimingFunction: "cubic-bezier(.59,.01,.39,1)",
        },
        "& .MuiTab-textColorPrimary.Mui-selected": {
            color: "#000000",
        },
    },
    tab: {
        textTransform: "none",
        fontSize: "21px",
        fontFamily: "Playfair Display",
        padding: "5px 15px",
        minWidth: "fit-content",
        fontWeight: "bold",
        color: "#D0BDC3",
    },
    tabPanelTitle: {
        ...theme.front.typography.h6.dark_b,
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    tabPanelTitleBox: {
        display: "flex",
        alignItems: "flex-end",
        marginBottom: theme.spacing(1),
    },
    tabPanelBlock: {
        marginBottom: theme.spacing(3),
    },
    contentBox: {
        paddingRight: "25px",
        paddingLeft: "25px",
        paddingBottom: "25px",
    },
    arrow: (props) => ({
        color: props.arrowColor ? props.arrowColor : theme.palette.primary.dark,
    }),
    popper: (props) => ({
        fontSize: "14px",
        fontFamily: "Lato",
        backgroundColor: props.bgColor ? props.bgColor : theme.palette.primary.dark,
        borderRadius: props.borderRadius ? props.borderRadius : "12px",
        boxShadow: props.boxShadow ? props.boxShadow : "0px 3px 76px #00000014",
        padding: "10px",
    }),
    vatThermoTypo: {
        ...theme.front.typography.labeur.light,
        backgroundColor: theme.palette.primary.light,
        borderRadius: "4px",
        width: "auto",
        padding: "0px 5px",
        marginRight: "4px",
        marginBottom: "4px",
    },
    cellarDetailsPopin__typocirculation: {
        ...theme.front.typography.labeur.dark,
    },
    cellarDetailsPopin__typoBottlingDestail: {
        ...theme.front.typography.labeur.dark,
    },
    cellarDetailsPopin__typoStorageInfos: {
        ...theme.front.typography.labeur.dark,
    },
}));

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

TabPanel.propTypes = {
    value: PropTypes.any,
    index: PropTypes.any,
    children: PropTypes.any,
};

const CellarDetailsPopin = (props) => {
    const styles = useStyle(props);
    const { width } = useWindowDimensions();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const { data, loading } = useCellarFrontPopinQuery({
        variables: {
            cellarId: props.cellarId,
            wineEntityId: props.wineEntityId,
            locale: props.locale,
        },
    });

    const getTabs = () => {
        const arr = [];
        if (cellar?.isVathouse) {
            arr?.push("cuvier");
        }
        if (cellar?.isAgeing) {
            arr?.push("elevage");
        }
        if (cellar?.isConditioning) {
            arr?.push("conditionning");
        }

        if (cellar?.isStorage) {
            arr?.push("storage");
        }
        if (data) {
            return arr;
        }
    };

    const [value, setValue] = useState(null);
    useEffect(() => {
        if (!value && data) {
            const t = getTabs();
            setValue(t?.[0]);
        }
    }, [data]);

    const cellar = data?.CellarFrontPopin;

    const getThermoregulationChips = () => {
        if (cellar?.vatThermoregulation && cellar?.vatThermoregulation?.length !== 0) {
            return (
                <Box style={{ display: "flex", flexWrap: "wrap" }}>
                    {cellar?.vatThermoregulation?.map((el, i) => {
                        let key = `cellar-vat-thermoregulation-${i}`;
                        return (
                            <Typography key={key} className={styles.vatThermoTypo}>
                                {el}
                            </Typography>
                        );
                    })}
                </Box>
            );
        } else {
            return (
                <Typography variant="h5" style={{ color: theme.palette.secondary.dark }}>
                    <Trans>Non précisé</Trans>
                </Typography>
            );
        }
    };

    const getCirculationtext = () => {
        if (cellar?.vatCirculation === "circulation par pompes") {
            return t`Par pompes`;
        } else {
            return t`Gravitaire`;
        }
    };

    const getAgeingContainer = () => {
        return cellar?.ageingContainers?.map((el, i) => {
            let key = `cellar-ageing-container-${i}`;
            return (
                <Box key={key} style={{ display: "flex", gap: "4px" }}>
                    {el?.kind ? (
                        <Box style={{ display: "flex", gap: "4px" }}>
                            <Typography style={{ color: theme.palette.primary.dark, fontSize: "16px" }}>
                                {firstLetterUppercase(el?.kind)}
                            </Typography>
                            <Typography style={{ color: theme.palette.primary.dark, fontWeight: "600" }}>
                                {" "}
                                ·{" "}
                            </Typography>
                        </Box>
                    ) : null}
                    {el?.material ? (
                        <Box style={{ display: "flex", gap: "4px" }}>
                            <Typography style={{ color: theme.palette.primary.dark, fontSize: "16px" }}>
                                {el?.material}
                            </Typography>
                            <Typography style={{ color: theme.palette.primary.dark, fontWeight: "600" }}>
                                {" "}
                                ·{" "}
                            </Typography>
                        </Box>
                    ) : null}
                    {el?.form ? (
                        <Box style={{ display: "flex", gap: "4px" }}>
                            <Typography style={{ color: theme.palette.primary.dark, fontSize: "16px" }}>
                                {el?.form}
                            </Typography>
                            <Typography style={{ color: theme.palette.primary.dark, fontWeight: "600" }}>
                                {" "}
                                ·{" "}
                            </Typography>
                        </Box>
                    ) : null}
                    {el?.quantity ? (
                        <Typography style={{ color: theme.palette.primary.dark, fontSize: "16px" }}>
                            {el?.quantity}
                        </Typography>
                    ) : null}
                    {el?.capacity ? (
                        <Typography style={{ color: theme.palette.primary.dark, fontSize: "16px" }}>
                            {" "}
                            x {commaInFr(applySeparator(el?.capacity?.toLocaleString(props.locale)), props.locale)}{" "}
                            <Trans>hl</Trans>
                        </Typography>
                    ) : null}
                </Box>
            );
        });
    };

    const getVatsContainer = () => {
        return cellar?.vatContainers?.map((el, i) => {
            let key = `cellar-vat-container-${i}`;
            return (
                <Box key={key} style={{ display: "flex", gap: "4px", flexWrap: "wrap" }}>
                    {el?.kind ? (
                        <Box style={{ display: "flex", gap: "4px" }}>
                            <Typography style={{ color: theme.palette.primary.dark, fontSize: "16px" }}>
                                {firstLetterUppercase(el?.kind)}
                            </Typography>
                            <Typography style={{ color: theme.palette.primary.dark, fontWeight: "600" }}>
                                {" "}
                                ·{" "}
                            </Typography>
                        </Box>
                    ) : null}
                    {el?.material ? (
                        <Box style={{ display: "flex", gap: "4px" }}>
                            <Typography style={{ color: theme.palette.primary.dark, fontSize: "16px" }}>
                                {el?.material}
                            </Typography>
                            <Typography style={{ color: theme.palette.primary.dark, fontWeight: "600" }}>
                                {" "}
                                ·{" "}
                            </Typography>
                        </Box>
                    ) : null}
                    {el?.form ? (
                        <Box style={{ display: "flex", gap: "4px" }}>
                            <Typography style={{ color: theme.palette.primary.dark, fontSize: "16px" }}>
                                {el?.form}
                            </Typography>
                            <Typography style={{ color: theme.palette.primary.dark, fontWeight: "600" }}>
                                {" "}
                                ·{" "}
                            </Typography>
                        </Box>
                    ) : null}
                    {el?.quantity ? (
                        <Typography style={{ color: theme.palette.primary.dark, fontSize: "16px" }}>
                            {el?.quantity}
                        </Typography>
                    ) : null}
                    {el?.capacity ? (
                        <Typography style={{ color: theme.palette.primary.dark, fontSize: "16px" }}>
                            {" "}
                            x {commaInFr(applySeparator(el?.capacity?.toLocaleString(props.locale)), props.locale)}{" "}
                            {t`hl`}
                        </Typography>
                    ) : null}
                </Box>
            );
        });
    };

    const getTotaltHl = (items) => {
        if (!items) return 0;
        return items?.reduce((current, next) => current + (next?.quantity ?? 0) * (next?.capacity ?? 0), 0);
    };

    const getAgeingContainerTitle = () => {
        const containerNumber = sumByKey(cellar?.ageingContainers, "quantity");
        const totalHl = getTotaltHl(cellar?.ageingContainers);

        if (containerNumber && containerNumber > 0 && totalHl && totalHl > 0) {
            return (
                <Typography variant="h5" style={{ fontSize: "18px" }} className={styles.tabPanelTitle}>
                    {applySeparator(containerNumber)} {containerNumber > 1 ? t`contenants` : t`contenant`} :{" "}
                    {commaInFr(applySeparator(totalHl), props.locale)} {t`hl`}
                </Typography>
            );
        }
        return "";
    };

    const getVatsContainerTitle = () => {
        const containerNumber = sumByKey(cellar?.vatContainers, "quantity");
        const totalHl = getTotaltHl(cellar?.vatContainers);

        if (containerNumber && containerNumber > 0 && totalHl && totalHl > 0) {
            return (
                <Typography variant="h5" style={{ fontSize: "18px" }} className={styles.tabPanelTitle}>
                    {applySeparator(containerNumber)} {containerNumber > 1 ? t`contenants` : t`contenant`} :{" "}
                    {commaInFr(applySeparator(totalHl), props.locale)} {t`hl`}
                </Typography>
            );
        }
        return "";
    };

    const getBottlingDetails = () => {
        let chains = false;
        if (cellar?.numberOfConditioningChains && cellar?.numberOfConditioningChains > 1) {
            chains = cellar?.numberOfConditioningChains + " " + t`chaînes d'embouteillage`;
        } else if (cellar?.numberOfConditioningChains && cellar?.numberOfConditioningChains === 1) {
            chains = cellar?.numberOfConditioningChains + " " + t`Chaînes d'embouteillage`;
        }

        return (
            <Typography
                className={styles.cellarDetailsPopin__typoBottlingDestail}
                style={{ color: theme.palette.primary.dark }}
            >
                {chains ? applySeparator(chains) : ""}
                {chains && cellar?.conditioningCapacity ? " · " : ""}
                {cellar?.conditioningCapacity && cellar?.conditioningCapacity !== 0
                    ? applySeparator(cellar?.conditioningCapacity) + " " + t`btl/heure`
                    : ""}
            </Typography>
        );
    };

    const getStorageInfos = () => {
        if (!cellar?.storageCapacity) {
            return (
                <Typography variant="h5" style={{ color: theme.palette.primary.dark }}>
                    <Trans>Non précisé</Trans>
                </Typography>
            );
        }

        if (cellar?.storageCapacityUnit === "btl") {
            return (
                <Typography
                    className={styles.cellarDetailsPopin__typoStorageInfos}
                    style={{ color: theme.palette.primary.dark }}
                >
                    {commaInFr(applySeparator((cellar?.storageCapacity * 0.0075)?.toFixed(2)), props.locale)} {t`hl`} ·{" "}
                    {commaInFr(applySeparator(Math.ceil(cellar?.storageCapacity / 6)), props.locale)} {t`caisses`} ·{" "}
                    {commaInFr(applySeparator(cellar?.storageCapacity), props.locale)} {t`bouteilles`}
                </Typography>
            );
        } else if (cellar?.storageCapacityUnit === "hl") {
            return (
                <Typography
                    className={styles.cellarDetailsPopin__typoStorageInfos}
                    style={{ color: theme.palette.primary.dark }}
                >
                    {applySeparator(cellar?.storageCapacity)} {t`hl`} ·{" "}
                    {Math.ceil(cellar?.storageCapacity / 0.0075 / 6)} {t`caisses`} ·{" "}
                    {Math.ceil(cellar?.storageCapacity / 0.0075)} {t`bouteilles`}
                </Typography>
            );
        }
    };

    const getWidth = () => {
        if (width >= 650) {
            return "600px";
        } else if (width > 400 && width < 650) {
            return "100%";
        } else if (width < 400) {
            return "100%";
        }
    };

    if (loading) {
        return null;
    }

    return (
        <Popin
            open={props.open}
            onClose={props.onClose}
            cross
            background={1}
            noPadding
            whiteCross
            overlowContentPopin={"auto"}
            maxWidth="unset"
        >
            <Box style={{ width: getWidth() }}>
                {/* CAROUSEL */}
                <BaseCarousel
                    alt={props.alt}
                    subImg={imgSub1}
                    images={cellar?.images?.length ? cellar?.images : []}
                    imageStyle={{
                        height: width >= 600 ? "350px" : 250,
                        width: "100%",
                    }}
                    responsiveHeight={[250, 281, 337, 337, 337]}
                    front
                />

                <Box className={styles.contentBox}>
                    {/* TITRE & m² */}
                    <Box className={styles.titleTextsBox} style={{ marginBottom: "4px" }}>
                        {cellar?.name && <Typography className={styles.titleText}>{cellar?.name}</Typography>}
                        {cellar?.surfaceArea && (
                            <Typography className={styles.cellarAreaText}>{cellar?.surfaceArea + t` m²`}</Typography>
                        )}
                    </Box>

                    {/* CELLAR TYPE */}
                    <Box style={{ display: "flex", marginBottom: "16px" }}>
                        {cellar?.isVathouse && (
                            <Tooltip
                                color="primary"
                                title={t`Vinification`}
                                arrow
                                classes={{
                                    arrow: styles.arrow,
                                    tooltip: styles.popper,
                                }}
                            >
                                <img src={contenant} alt="cellar_type_img" style={{ marginRight: "12px" }} />
                            </Tooltip>
                        )}
                        {cellar?.isAgeing && (
                            <Tooltip
                                color="primary"
                                title={t`Élevage`}
                                arrow
                                classes={{
                                    arrow: styles.arrow,
                                    tooltip: styles.popper,
                                }}
                            >
                                <img src={elevage} alt="cellar_type_img" style={{ marginRight: "12px" }} />
                            </Tooltip>
                        )}
                        {cellar?.isConditioning && (
                            <Tooltip
                                color="primary"
                                title={t`Embouteillage et conditionnement`}
                                arrow
                                classes={{
                                    arrow: styles.arrow,
                                    tooltip: styles.popper,
                                }}
                            >
                                <img src={conditionnement} alt="cellar_type_img" style={{ marginRight: "12px" }} />
                            </Tooltip>
                        )}
                        {cellar?.isStorage && (
                            <Tooltip
                                color="primary"
                                title={t`Stockage`}
                                arrow
                                classes={{
                                    arrow: styles.arrow,
                                    tooltip: styles.popper,
                                }}
                            >
                                <img src={bottle} alt="cellar_type_img" style={{ marginRight: "12px" }} />
                            </Tooltip>
                        )}
                    </Box>

                    {/* MAIN INFOS */}
                    <Typography
                        className={styles.addressAndDescriptionTexts}
                        style={{ color: theme.palette.primary.dark }}
                    >
                        {cellar?.address?.address && cellar?.address?.address + ","}{" "}
                        {cellar?.address?.complementaryAddress && cellar?.address?.complementaryAddress + ","}{" "}
                        {cellar?.address?.postalCode} {cellar?.address?.commune}
                    </Typography>
                    <Typography
                        className={styles.addressAndDescriptionTexts}
                        style={{ color: theme.palette.primary.dark }}
                    >
                        {cellar?.description ? parseHtml(cellar?.description) : null}
                    </Typography>
                    <Typography
                        className={styles.addressAndDescriptionTexts}
                        style={{ color: theme.palette.primary.dark }}
                    >
                        {cellar?.temperature ? t`Chai climatisé` + " : " + cellar?.temperature + " " + t`°C` : ""}
                    </Typography>

                    {/* 3 TABS */}
                    {(cellar?.isVathouse || cellar?.isAgeing || cellar?.isConditioning || cellar?.isStorage) && (
                        <Tabs
                            className={styles.tabs}
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            TabIndicatorProps={{ children: <span /> }}
                            textColor="primary"
                            scrollButtons="on"
                            variant="scrollable"
                        >
                            {cellar?.isVathouse && (
                                <Tab
                                    {...a11yProps("cuvier")}
                                    className={styles.tab}
                                    value="cuvier"
                                    label={t`Vinification`}
                                />
                            )}

                            {cellar?.isAgeing && (
                                <Tab
                                    {...a11yProps("elevage")}
                                    className={styles.tab}
                                    value="elevage"
                                    label={t`Élevage`}
                                />
                            )}

                            {cellar?.isConditioning && (
                                <Tab
                                    {...a11yProps("conditionning")}
                                    value="conditionning"
                                    className={styles.tab}
                                    label={t`Embouteillage et conditionnement`}
                                />
                            )}
                            {cellar?.isStorage && (
                                <Tab
                                    {...a11yProps("storage")}
                                    value="storage"
                                    className={styles.tab}
                                    label={t`Stockage`}
                                />
                            )}
                        </Tabs>
                    )}

                    {/* VATHOUSE */}
                    {cellar?.isVathouse && (
                        <TabPanel index="cuvier" value={value} className={styles.panelMainBox}>
                            {(cellar?.vatContainers && cellar?.vatContainers?.length !== 0) ||
                            (cellar?.vatThermoregulation && cellar?.vatThermoregulation?.length !== 0) ||
                            cellar?.vatCirculation ? (
                                <>
                                    <Box
                                        style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                            flexWrap: "wrap",
                                            gap: "16px",
                                        }}
                                    >
                                        {/* THERMOREGULATION */}
                                        <Box className={styles.tabPanelBlock} style={{ maxWidth: "460px" }}>
                                            <Box
                                                className={styles.tabPanelTitleBox}
                                                style={{
                                                    alignItems: "flex-start",
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        opacity:
                                                            cellar?.vatThermoregulation &&
                                                            cellar?.vatThermoregulation?.length !== 0
                                                                ? 1
                                                                : 0.5,
                                                    }}
                                                    src={thermoregulationImg}
                                                    alt="thermoregulation_image"
                                                />
                                                <Typography
                                                    style={{
                                                        color:
                                                            cellar?.vatThermoregulation &&
                                                            cellar?.vatThermoregulation?.length !== 0
                                                                ? theme.palette.common.dark
                                                                : theme.palette.secondary.dark,
                                                    }}
                                                    className={styles.tabPanelTitle}
                                                >
                                                    <Trans>Thermorégulation des cuves</Trans>
                                                </Typography>
                                            </Box>
                                            {getThermoregulationChips()}
                                        </Box>
                                        {/* PRESS TYPE */}
                                        <Box className={styles.tabPanelBlock}>
                                            <Box
                                                className={styles.tabPanelTitleBox}
                                                style={{
                                                    alignItems: "flex-start",
                                                }}
                                            >
                                                <img src={circulation} alt="press_image" />
                                                <Typography className={styles.tabPanelTitle}>
                                                    <Trans>Circulation</Trans>
                                                </Typography>
                                            </Box>
                                            <Typography
                                                className={styles.cellarDetailsPopin__typocirculation}
                                                style={{ color: theme.palette.primary.dark }}
                                            >
                                                {cellar?.vatCirculation ? getCirculationtext() : t`Non précisé`}
                                            </Typography>
                                        </Box>
                                    </Box>

                                    {cellar?.vatContainers && cellar?.vatContainers?.length !== 0 && (
                                        <>
                                            <Box className={styles.tabPanelTitleBox}>
                                                <img src={containerImg} alt="container_image" />
                                                {getVatsContainerTitle()}
                                            </Box>
                                            {getVatsContainer()}
                                        </>
                                    )}
                                </>
                            ) : (
                                <Typography variant="h5" style={{ color: theme.palette.primary.dark }}>
                                    <Trans>Non précisé</Trans>
                                </Typography>
                            )}
                        </TabPanel>
                    )}

                    {/* ELEVAGE */}
                    {cellar?.isAgeing && (
                        <TabPanel index="elevage" value={value} className={styles.panelMainBox}>
                            {cellar.ageingContainers && cellar.ageingContainers?.length !== 0 ? (
                                <>
                                    <Box
                                        style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                            flexWrap: "wrap",
                                            gap: "16px",
                                        }}
                                    ></Box>

                                    {cellar.ageingContainers && cellar.ageingContainers?.length !== 0 && (
                                        <>
                                            <Box className={styles.tabPanelTitleBox}>
                                                <img
                                                    src={elevage}
                                                    style={{
                                                        width: "23px",
                                                    }}
                                                    alt="container_image"
                                                />
                                                {getAgeingContainerTitle()}
                                            </Box>
                                            {getAgeingContainer()}
                                        </>
                                    )}
                                </>
                            ) : (
                                <Typography variant="h5" style={{ color: theme.palette.primary.dark }}>
                                    <Trans>Non précisé</Trans>
                                </Typography>
                            )}
                        </TabPanel>
                    )}

                    {/* BOTTLING */}
                    {cellar?.isConditioning && (
                        <TabPanel index="conditionning" value={value}>
                            {cellar?.conditioningActivities && cellar?.conditioningActivities?.length !== 0 ? (
                                <Box className={styles?.panelMainBox}>
                                    <Box className={styles.tabPanelTitleBox} style={{ alignItems: "flex-start" }}>
                                        <img
                                            src={conditionnement}
                                            style={{ width: "25px" }}
                                            alt="conditionning_image"
                                        />
                                        <Typography className={styles.tabPanelTitle}>
                                            {getConnectors(cellar?.conditioningActivities, true)}
                                        </Typography>
                                    </Box>
                                    {getBottlingDetails()}
                                </Box>
                            ) : (
                                <Typography variant="h5" style={{ color: theme.palette.primary.dark }}>
                                    <Trans>Non précisé</Trans>
                                </Typography>
                            )}
                        </TabPanel>
                    )}

                    {/* STOCKAGE */}
                    {cellar?.isStorage && (
                        <TabPanel index="storage" value={value}>
                            {cellar?.storageCapacity ? (
                                <Box className={styles?.panelMainBox}>
                                    <Box className={styles.tabPanelTitleBox}>
                                        <img src={storageImg} alt="container_image" />
                                        <Typography className={styles.tabPanelTitle}>
                                            <Trans>Capacité de stockage</Trans>
                                        </Typography>
                                    </Box>
                                    {getStorageInfos()}
                                </Box>
                            ) : (
                                <Typography variant="h5" style={{ color: theme.palette.primary.dark }}>
                                    <Trans>Non précisé</Trans>
                                </Typography>
                            )}
                        </TabPanel>
                    )}
                </Box>
            </Box>
        </Popin>
    );
};

CellarDetailsPopin.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    cellarId: PropTypes.string,
    wineEntityId: PropTypes.string,
    fullHeight: PropTypes.bool,
    locale: PropTypes.string,
    alt: string,
};

export default CellarDetailsPopin;
