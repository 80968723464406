import { bool, number, object, oneOfType, string } from "prop-types";
import { Box, makeStyles, Typography } from "@material-ui/core";
import theme from "../../globalTheme";
import MoonLoader from "react-spinners/MoonLoader";

const useStyle = makeStyles(() => ({
    boxContainer: (props) => {
        let size = {};
        if (props.size) {
            size.width = props.size;
            size.height = props.size;
        }
        return {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            "& *": { ...size, top: 0, left: 0 },
        };
    },
}));

const Loading = (props) => {
    const styles = useStyle(props);
    return (
        <Box className={styles.boxContainer} style={props.boxStyle}>
            <MoonLoader loading={props.loading} color={props.color ?? theme.palette.primary.light} size={props.size} />
            {props.message && (
                <Typography style={{ fontStyle: "italic", textAlign: "center", marginTop: "20px", width: "100%" }}>
                    {props.message}
                </Typography>
            )}
        </Box>
    );
};

Loading.defaultProps = {
    size: 60,
};

Loading.propTypes = {
    loading: oneOfType([bool, string]),
    message: string,
    size: number,
    boxStyle: object,
    color: string,
};

export default Loading;
