import React from "react";
import PropTypes from "prop-types";
import { Box, Card, CardContent, Typography, makeStyles } from "@material-ui/core";
import theme from "../globalTheme";

const useStyles = makeStyles((theme) => ({
    card: {
        width: 310,
        height: 184,
        backgroundColor: theme.palette.background.card,
        boxShadow: "none",
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
        padding: "1rem",
        paddingNottom: 24,
    },
    card_content_container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "0",
        padding: "1rem",
    },
    item_title: {
        width: "90%",
        marginLeft: "auto",
        marginRight: "auto",
    },
    link: {
        display: "flex",
        cursor: "pointer",

        "&:hover": {
            textDecoration: "underline",
        },
    },
    boxBontent: {
        color: theme.palette.common.black,
    },
}));

const FeretLastPublishCard = (props) => {
    const styles = useStyles(props);

    return (
        <Card className={styles.card}>
            <CardContent className={styles.card_content_container}>
                <Box className={styles.boxContent}>
                    <Typography variant="body2" className={styles.item_title}>
                        {props.publication}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
};

FeretLastPublishCard.propTypes = {
    publication: PropTypes.string,
};

export default FeretLastPublishCard;
