import { Box, Typography, makeStyles } from "@material-ui/core";
import theme from "../globalTheme";
import PropTypes, { object } from "prop-types";

const useStyles = makeStyles(() => ({
    contact_block: (props) => ({
        background: theme.action.disabledBackground,
        borderRadius: "4px",
        padding: "16px 23px 16px 20px",
        width: "100%",
        marginBottom: "16px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignSelf: "stretch",

        [theme.breakpoints.up("md")]: {
            width: props?.width ?? "auto",
        },
    }),
    contact_title: {
        color: theme.palette.text.primary,
        fontWeight: "bold",
    },
    content: {
        fontWeight: "400",
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.primary.dark,
    },
}));

const AddressBlock = (props) => {
    const styles = useStyles(props);

    const { title, name, address, email, telephone } = props?.data ?? {};

    return (
        <Box className={styles.contact_block} style={props.boxStyle}>
            {title && (
                <Typography variant="body2" className={styles.contact_title} style={props.titleStyle}>
                    {title}
                </Typography>
            )}

            {name && (
                <Typography variant="body2" className={styles.content}>
                    {name}
                </Typography>
            )}

            {address && (
                <Typography variant="body2" className={styles.content}>
                    {address}
                </Typography>
            )}

            {email && (
                <Typography variant="body2" className={styles.content}>
                    {email}
                </Typography>
            )}

            {telephone && (
                <Typography variant="body2" className={styles.content}>
                    {telephone}
                </Typography>
            )}
        </Box>
    );
};

AddressBlock.propTypes = {
    data: PropTypes.object,
    width: PropTypes.number,
    boxStyle: object,
    titleStyle: object,
};

export default AddressBlock;
