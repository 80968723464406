import { t, Trans } from "@lingui/macro";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Page404 from "Components/ErrorPage/Page404";
import Helmet from "Components/Helmet/Helmet";
import Loading from "Components/Loader/Loading";
import { SwiperWrapper } from "Front/SwiperCards";
import ChallengeVintageCard from "FrontChallenge/ChallengeVintageCard";
import FeretJournalists from "FrontTasting/FeretJournalists";
import { useGetJournalistQuery } from "generated/graphql";
import { useCustomHistory } from "hooks";
import { useRestrictions } from "hooks/useRestrictions";
import DefaultLogo from "Journalist/DefaultLogo";
import bg from "../assets/journalist-bg.jpeg";
import SocialMediaIcons from "../Components/Icons/SocialMediaIcons";
import FrontContentTop from "../Front/FrontContentTop";
import FrontMainContainer from "../Front/FrontMainContainer";
import FrontTitle from "../Front/FrontTitle";
import EstateTitleSemiUnderlined from "../FrontEstate/Components/EstateTitledSemiUnderlined";
import theme from "../globalTheme";
import { getScoringLogo, useWindowDimensions } from "../helpers/helpers";

const useStyles = makeStyles(() => ({
    container_card: {
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
        },
    },
    subtitle: {
        textAlign: "center",
        color: theme.palette.common.white,
        marginBottom: "2em",
        marginTop: "-1em",
    },
    container_content_top: {
        display: "grid",
        gridTemplateColumns: "1fr",
    },
    socialMedia: {
        order: 1,
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: "15px",

        [theme.breakpoints.up("xl")]: {
            right: 64,
            top: 42,
        },
        [theme.breakpoints.down("md")]: {
            marginBottom: 0,
        },
    },
    grid_item_text: {
        display: "grid",
        gridTemplateColumns: "1fr",
        gap: "16px 24px",
        alignItems: "center",
        textAlign: "center",

        [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "repeat(12, 1fr)",
        },
    },
    container_ask_tasting: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        background: theme.palette.background.card,
        justifyContent: "center",
        alignItems: "center",
        marginTop: "2em",
        borderRadius: "6px",
        [theme.breakpoints.up("xs")]: {
            gridColumn: "auto / span 12",
            padding: "1rem",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 8",
            padding: "2em 3em",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 10",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "auto / span 9",
        },
    },
    container_ask_tasting_right: {
        textAlign: "right",
        gridColumn: "auto / span 4",

        [theme.breakpoints.down("xs")]: {
            gridColumn: "auto / span 12",
            textAlign: "center",
        },
    },
    container_ask_tasting_left: {
        gridColumn: "auto / span 8",

        [theme.breakpoints.down("xs")]: {
            gridColumn: "auto / span 12",
            textAlign: "center",
            marginBottom: "1em",
            display: "flex",
            flexDirection: "column",
        },
    },
    btnAsk: {
        border: `1px solid ${theme.palette.primary.dark}`,
        color: theme.palette.primary.dark,
        borderRadius: 4,
        padding: "6px 16px",
        backgroundColor: theme.palette.common.white,

        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.common.white,
        },
    },
    container_title_description: {
        order: 2,
        [theme.breakpoints.up("lg")]: {
            order: 2,
        },
    },
    container_infos_left: {
        textAlign: "left",
        gridColumn: "auto / span 12",
    },
    areas_container: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "auto / span 12",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 8",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 10",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "auto / span 9",
        },
    },
    container_infos_right: {
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        [theme.breakpoints.up("xs")]: {
            gridColumn: "auto / span 12",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 4",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 2",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "auto / span 3",
        },
    },
    typoAbout: {
        color: theme.palette.primary.dark,
    },
    container_zones: {
        marginTop: "1rem",
        display: "flex",
        flexWrap: "wrap",
        gap: "1rem",
        textAlign: "center",
    },
    zoneBasic: {
        color: theme.palette.secondary.main,
        padding: "4px 1.5em",
    },
    active: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        padding: "4px 1.5em",
        borderRadius: 4,
    },
    winesTasted: {
        marginTop: "3em",
        [theme.breakpoints.down("xs")]: {
            padding: "0 16px",
        },
    },
    container_author_photo: ({ photo }) => {
        const photoUrl = photo ? `url(${encodeURI(photo)})` : "";
        return {
            float: "left",
            marginRight: 30,
            width: 120,
            height: 120,
            alignSelf: "flex-start",
            backgroundImage: photoUrl,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            borderRadius: "100%",
            border: `4px solid ${theme.palette.primary.light}`,
            [theme.breakpoints.up("lg")]: {
                width: 155,
                height: 155,
            },
        };
    },
}));

const JournalistPage = () => {
    const locale = useSelector((state) => state?.user?.locale || "fr");
    const currentUser = useSelector((state) => state.user.currentUser);
    const { hasTastingRequest } = useRestrictions();
    const { width } = useWindowDimensions();
    const { name } = useParams();
    const history = useCustomHistory();
    const { data, loading } = useGetJournalistQuery({ variables: { slug: name, locale } });

    const journalist = data?.GetJournalist;

    const styles = useStyles({ photo: journalist?.photo?.twicpicURL ?? null });
    const logoUrl = journalist?.logo?.twicpicURL ?? getScoringLogo(journalist?.company);

    const breadcrumbs = [
        {
            name: t`Salle de dégustation Féret`,
            path: "/salle-de-degustation",
        },
        {
            name: journalist?.title,
        },
    ];

    if (loading) {
        return <Loading />;
    }

    if (!journalist) {
        return <Page404 />;
    }

    return (
        <>
            <Helmet title={journalist.title} content="" />
            <FrontContentTop
                backgroundImage={journalist?.background?.twicpicURL ?? bg}
                links={breadcrumbs}
                previousPage={{
                    name: t`La salle de dégustation Féret`,
                    path: "/salle-de-degustation",
                }}
            >
                <FrontTitle title={journalist.title} />
                <Typography variant="h5" className={styles.subtitle}>
                    <Trans>{journalist.company}</Trans>
                </Typography>

                <FrontMainContainer styleCardContent>
                    <Box className={styles.container_card}>
                        <Box className={styles.container_content_top}>
                            <Box className={styles.container_title_description}>
                                <EstateTitleSemiUnderlined
                                    title={t`À propos`}
                                    heightAfter={32}
                                    style={{ marginTop: "0px", color: theme.palette.primary.dark }}
                                />
                            </Box>
                            <Box className={styles.socialMedia}>
                                <SocialMediaIcons socialMedias={journalist.socialMedias} />
                            </Box>
                        </Box>

                        <Box className={styles.grid_item_text}>
                            <Box className={styles.container_infos_left}>
                                <Box className={styles.container_author_photo} />
                                <Typography variant="h5" className={styles.typoAbout}>
                                    <div dangerouslySetInnerHTML={{ __html: journalist.about?.[locale] }} />
                                </Typography>
                            </Box>

                            <Box className={styles.areas_container}>
                                {journalist.areas?.length > 0 && (
                                    <>
                                        <Typography
                                            variant="h5"
                                            className={styles.typoAbout}
                                            style={{ fontWeight: "bold", textAlign: "left" }}
                                        >
                                            <Trans>Principales zones d&apos;influence</Trans>
                                        </Typography>

                                        <Box className={styles.container_zones}>
                                            {journalist.areas.map((zone) => (
                                                <Box key={zone}>
                                                    <Typography variant="body2" className={styles.active}>
                                                        <Trans>{zone}</Trans>
                                                    </Typography>
                                                </Box>
                                            ))}
                                        </Box>
                                    </>
                                )}
                            </Box>
                            <Box className={styles.container_infos_right}>
                                {logoUrl ? (
                                    <img style={{ width: "100%" }} src={logoUrl} alt="Journalist Logo" />
                                ) : (
                                    <DefaultLogo criticName={journalist?.title} />
                                )}
                            </Box>

                            {currentUser && hasTastingRequest && locale === "fr" && (
                                <Box className={styles.container_ask_tasting}>
                                    <Box className={styles.container_ask_tasting_left}>
                                        <Typography
                                            variant="h5"
                                            className={styles.typoAbout}
                                            style={{ fontWeight: "bold", textAlign: width >= 960 ? "left" : "center" }}
                                        >
                                            <Trans>Vos vins dégustés par {journalist.title}</Trans>
                                        </Typography>
                                    </Box>
                                    <Box className={styles.container_ask_tasting_right}>
                                        <Button
                                            className={styles.btnAsk}
                                            onClick={() => {
                                                history.push({
                                                    pathname: "/dashboard/services",
                                                    state: {
                                                        tastingRequest: true,
                                                    },
                                                });
                                            }}
                                        >
                                            <Typography variant="body2">
                                                <Trans>Faire déguster mes vins</Trans>
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </FrontMainContainer>
                {journalist.vintages?.length > 0 && (
                    <Box style={{ marginTop: "3em" }} className={styles.winesTasted}>
                        <SwiperWrapper title={t`Découvrez des vins dégustés par ${journalist.title}`}>
                            {journalist.vintages?.map((vintage, i) => {
                                if (!vintage) return null;
                                const { wine, wineEntity, vintage: subVintage } = vintage;
                                if (!wine) return null;
                                const { shortId: wineShortId, brandName, appellation, color, type } = wine;
                                if (!wineEntity) return null;
                                const { name: wineEntityName, shortId: wineEntityShortId } = wineEntity;
                                if (!subVintage) return null;
                                const { year, cuvee } = subVintage;
                                return (
                                    <ChallengeVintageCard
                                        key={`challenge_rewards-${i}`}
                                        vintage={{
                                            wineEntityName,
                                            wineEntityShortId,
                                            wineShortId,
                                            brandName,
                                            appellation,
                                            color,
                                            type,
                                            year,
                                            cuvee,
                                        }}
                                    />
                                );
                            })}
                        </SwiperWrapper>
                    </Box>
                )}
            </FrontContentTop>

            <FeretJournalists
                limit={6}
                title={t`D’autres journalistes de la salle de dégustation Féret`}
                titleStyle={{ color: theme.palette.primary.dark, fontWeight: 400, marginTop: 0 }}
                boxClassName={styles.slider}
                noPadding
            />
        </>
    );
};

export default JournalistPage;
