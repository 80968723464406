import { Box, makeStyles, Typography } from "@material-ui/core";
import { array, bool, func, number, object, string } from "prop-types";
import { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

import AutocompleteChipsInput from "../../Components/Inputs/AutocompleteChipsInput";
import BaseCheckbox from "../../Components/Inputs/BaseCheckbox";
import SimpleInputUncontrolled from "../../Components/Inputs/SimpleInputUncontrolled";
import RangeSliderCustomSearch from "../RangeSliderCustomSearch";
import ControlledSwitch from "../../Components/Switch/ControlledSwitch";
import theme from "../../globalTheme";
import { getUserLocale, useWindowDimensions } from "../../helpers/helpers";
import { SearchModal } from "../modal/SearchModal";
import SwitchThreeState from "../SwitchThreeState";
import ModalSectionTitle from "../ui/ModalSectionTitle";
import SearchCheckbox from "./SearchCheckbox";
import BigSwitch from "../../Components/Switch/BigSwitch";
import { t, Trans } from "@lingui/macro";
import inComingLogo from "assets/search/in_coming.svg";
import BaseRadioLabel from "Components/Radio/BaseRadioLabel";
import smallInComing from "assets/search/small_in_coming.svg";
import BaseTooltip from "Components/Tooltips/Tooltip";

const useStyles = makeStyles(() => ({
    containerBoxContent: ({ contentHeight }) => {
        return {
            paddingLeft: "10px",
            maxHeight: contentHeight,
        };
    },
    filterSearch: {
        gridColumn: "1 / span 12",
    },
    filterSelect: {
        gridColumn: "1 / span 12",
        marginTop: 16,

        "& .MuiOutlinedInput-root": {
            borderRadius: 53,
        },
    },
    onlineSales: {
        gridColumn: "1 / span 12",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginLeft: 16,
    },
    durationAgingBarrels: {
        display: "flex",
        flexDirection: "column",
        gridColumn: "1 /span 12",
        marginTop: 15,
    },
    switches: {
        gridColumn: "1 / span 12",
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        marginTop: 10,
    },
    switchFull: (props) => ({
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",

        "&:nth-child(1)": {
            gridColumn: props?.width > 300 ? "1 / span 5" : "1 / span 12",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "1 / span 5",
                marginLeft: 20,
            },
        },

        "&:nth-child(2)": {
            gridColumn: props?.width > 300 ? "6 / span 5" : "1 / span 12",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "auto / span 5",
            },
        },

        "&:nth-child(3)": {
            gridColumn: props?.width > 300 ? "10 / span 4" : "1 / span 12",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "auto / span 3",
            },
        },
    }),
    switch: (props) => ({
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",

        "&:nth-child(1)": {
            gridColumn: props?.width > 300 ? "1 / span 4" : "1 / span 12",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "1 / span 4",
                marginLeft: 20,
            },
        },

        "&:nth-child(2)": {
            gridColumn: props?.width > 300 ? "5 / span 5" : "1 / span 12",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "auto / span 5",
            },
        },

        "&:nth-child(3)": {
            gridColumn: props?.width > 300 ? "10 / span 4" : "1 / span 12",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "auto / span 3",
            },
        },
    }),
    switchThreeStateBig: (props) => ({
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",

        "&:nth-child(1)": {
            gridColumn: props?.width > 400 ? "1 / span 5" : "1 / span 12",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "1 / span 5",
                marginLeft: 20,
            },
        },

        "&:nth-child(2)": {
            gridColumn: props?.width > 400 ? "5 / span 7" : "1 / span 12",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "auto / span 7",
            },
        },

        "&:nth-child(3)": {
            gridColumn: props?.width > 400 ? "10 / span 4" : "1 / span 12",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "auto / span 3",
            },
        },
    }),
    switchThreeStateFull: (props) => ({
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",

        "&:nth-child(1)": {
            gridColumn: props?.width > 400 ? "1 / span 5" : "1 / span 12",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "1 / span 5",
                marginLeft: 20,
            },
        },

        "&:nth-child(2)": {
            gridColumn: props?.width > 400 ? "6 / span 5" : "1 / span 12",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "auto / span 5",
            },
        },

        "&:nth-child(3)": {
            gridColumn: props?.width > 400 ? "10 / span 4" : "1 / span 12",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "auto / span 3",
            },
        },
    }),
    switchThreeState: (props) => ({
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",

        "&:nth-child(1)": {
            gridColumn: props?.width > 400 ? "1 / span 4" : "1 / span 12",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "1 / span 4",
                marginLeft: 20,
            },
        },

        "&:nth-child(2)": {
            gridColumn: props?.width > 400 ? "5 / span 5" : "1 / span 12",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "auto / span 5",
            },
        },

        "&:nth-child(3)": {
            gridColumn: props?.width > 400 ? "10 / span 4" : "1 / span 12",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "auto / span 3",
            },
        },
    }),
    switchThreeStateCustom: (props) => ({
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",

        "&:nth-child(1)": {
            gridColumn: props?.width > 400 ? "1 / span 5" : "1 / span 12",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "1 / span 5",
                marginLeft: 20,
            },
        },

        "&:nth-child(2)": {
            gridColumn: props?.width > 400 ? "6 / span 6" : "1 / span 12",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "6 / span 3",
            },
        },

        "&:nth-child(3)": {
            gridColumn: props?.width > 400 ? "1 / span 4" : "1 / span 12",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "10 / span 3",
                marginLeft: 0,
            },
        },
    }),
    checkboxBox: {
        display: "flex",
        flexDirection: "column",
        gridColumn: "1 /span 12",
        marginTop: 15,
    },
    threeStateBox: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        width: "100%",
        gridColumn: "1 / span 12",
    },
    threeStateTypo: {
        marginLeft: 7,
        color: theme.palette.primary.dark,
    },
    threeStateFirst: {
        display: "flex",
        alignItems: "center",
        gridColumn: "1 /span 12",

        [theme.breakpoints.up("sm")]: {
            gridColumn: "1 /span 4",
            marginLeft: 20,
        },
    },
    threeStateFirstFull: {
        display: "flex",
        alignItems: "center",
        gridColumn: "1 /span 12",

        [theme.breakpoints.up("sm")]: {
            marginLeft: 20,
        },
    },
    threeStateSecond: {
        display: "flex",
        alignItems: "center",
        gridColumn: "1 /span 12",

        [theme.breakpoints.up("sm")]: {
            gridColumn: "5 /span 5",
        },
    },
    threeStateSecondFull: {
        display: "flex",
        alignItems: "center",
        gridColumn: "1 /span 12",

        [theme.breakpoints.up("sm")]: {
            gridColumn: "5 /span 8",
        },
    },
    threeStateThird: {
        display: "flex",
        alignItems: "center",
        gridColumn: "1 /span 12",

        [theme.breakpoints.up("sm")]: {
            gridColumn: "10 /span 3",
        },
    },
    checkboxBase: (props) => ({
        display: "flex",
        alignItems: "center",

        "&:nth-child(1)": {
            gridColumn: props?.width > 300 ? "1 / span 4" : "1 / span 12",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "1 / span 4",
                marginLeft: 20,
            },
        },

        "&:nth-child(2)": {
            gridColumn: props?.width > 300 ? "5 / span 4" : "1 / span 12",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "auto / span 5",
            },
        },

        "&:nth-child(3)": {
            gridColumn: props?.width > 300 ? "10 / span 4" : "1 / span 12",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "auto / span 3",
            },
        },
    }),
    checkboxOneLine: () => ({
        display: "flex",
        alignItems: "center",
        gridColumn: "1 / span 12",

        "&:nth-child(1)": {
            [theme.breakpoints.up("sm")]: {
                gridColumn: "1 / span 4",
                marginLeft: 20,
            },
        },

        "&:nth-child(2)": {
            [theme.breakpoints.up("sm")]: {
                gridColumn: "auto / span 5",
            },
        },

        "&:nth-child(3)": {
            [theme.breakpoints.up("sm")]: {
                gridColumn: "auto / span 3",
            },
        },
    }),
    checkboxTwoLine: (props) => ({
        display: "flex",
        alignItems: "center",

        "&:nth-child(1)": {
            gridColumn: props?.width > 300 && props?.width < 600 ? "1 / span 4" : "1 / span 12",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "1 / span 4",
                marginLeft: 20,
            },
        },

        "&:nth-child(2)": {
            gridColumn:
                props?.width > 300 && props?.width < 450
                    ? "5 / span 5"
                    : props?.width >= 450 && props?.width < 600
                    ? "5 / span 4"
                    : "1 / span 12",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "auto / span 5",
            },
        },

        "&:nth-child(3)": {
            gridColumn:
                props?.width > 300 && props?.width < 450
                    ? "1 / span 4"
                    : props?.width >= 450 && props?.width < 600
                    ? " 10 / span 3"
                    : "1 / span 12",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "auto / span 3",
            },
        },

        "&:nth-child(4)": {
            gridColumn:
                props?.width > 300 && props?.width < 450
                    ? "5 / span 4"
                    : props?.width >= 450 && props?.width < 600
                    ? " 1 / span 6"
                    : "1 / span 12",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "1 / span 4",
                marginLeft: 20,
            },
        },
    }),
    listItemText: {
        color: theme.palette.common.black,
    },
    checkboxes: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        width: "100%",
        gridColumn: "1 / span 12",
    },
    subRegionTitle: {
        color: theme.palette.primary.dark,
    },
    switchTypo: {
        marginLeft: 7,
        color: theme.palette.primary.dark,
        cursor: "pointer",
    },
    switchTypoDisabled: {
        marginLeft: 7,
        color: theme.palette.secondary.dark,
        cursor: "not-allowed",
    },
    container_vintage_search: {
        display: "grid",
        gridTemplateColumns: "repeat(12,1fr)",
        gridColumn: "1 / span 12",

        position: "relative",
    },
    in_coming_filter: {
        position: "absolute",
        height: "100%",
        width: "100%",
        opacity: "0.6",
        background: theme.palette.common.white,
        top: "0",
        left: "0",
        zIndex: "11",
    },
    logo_in_coming: {
        marginTop: "10px",
        [theme.breakpoints.down("sm")]: {
            width: "64px",
        },
    },
}));

const SearchWineModal = (props) => {
    const { width } = useWindowDimensions();
    const headerRef = useRef();
    const footerRef = useRef();
    const locale = useSelector(getUserLocale);
    const [searchingByVintage, setSearchingByVintage] = useState(false);

    const contentHeight = useMemo(() => {
        const headerDimensions = headerRef.current?.getBoundingClientRect();
        const footerDimensions = footerRef.current?.getBoundingClientRect();
        const headerHeight = headerDimensions?.height;
        const footerHeight = footerDimensions?.height;

        let contentHeight = "80%";
        if (headerHeight && footerHeight) {
            const CONTENT_PADDING_BOTTOM_x2 = 52;
            const substract = [headerHeight, footerHeight, CONTENT_PADDING_BOTTOM_x2]
                .map((item) => item + "px")
                .join(" - ");
            contentHeight = `calc(90vh - ${substract})`;
        }

        return contentHeight;
    }, [headerRef, footerRef, width, locale, searchingByVintage]);

    const styles = useStyles({
        ...props,
        width,
        contentHeight,
    });
    const inComing = true;

    const extraContent = (position, top, right) => {
        return (
            <BaseTooltip title={t`Bientôt : des filtres avancés pour rechercher un millésime`}>
                <Box
                    style={{
                        position: position,
                        right: right,
                        borderRadius: "12px",
                        padding: "2px 4px",
                        top: top,
                        border: "1px solid rgb(99, 45, 62)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <img src={smallInComing} style={{ width: "14px", height: "14px" }} />
                    <Typography varinat="h6" style={{ color: theme.palette.primary.main, lineHeight: 1 }}>
                        <Trans>à venir</Trans>
                    </Typography>
                </Box>
            </BaseTooltip>
        );
    };

    return (
        <SearchModal
            totalCount={props.similar ? 0 : props.totalCount}
            width={width > 600 ? "45rem" : "100%"}
            open={props?.isOpen}
            onClose={() => {
                props.nextStep && props.nextStep();
                props.onClose();
            }}
            onCloseCross={() => props.onCloseCross()}
            titlePrimary={props?.titlePrimary}
            titleSecondary={`/ ${props?.titleSecondary}`}
            switch={
                width >= 600 ? (
                    <Box style={{ position: "relative" }}>
                        {extraContent("absolute", -20, 20)}
                        <ControlledSwitch
                            leftLabel={t`Rechercher un vin`}
                            rightLabel={t`Rechercher un millésime`}
                            switchCheck={searchingByVintage}
                            handleSwitchChange={() => setSearchingByVintage(!searchingByVintage)}
                            switchPosition={{ top: "0px", left: "0px" }}
                            fontTypo="Mansalva"
                            clickedLabel
                        />
                    </Box>
                ) : (
                    <Box>
                        <BaseRadioLabel
                            size="small"
                            variant="h6"
                            checked={!searchingByVintage}
                            onChange={() => {
                                setSearchingByVintage(false);
                            }}
                            label={t`Rechercher un vin`}
                        />
                        <BaseRadioLabel
                            size="small"
                            variant="h6"
                            label={t`Rechercher un millésime`}
                            checked={searchingByVintage}
                            onChange={() => {
                                setSearchingByVintage(true);
                            }}
                            extraContent={extraContent("inherit", 0, 0)}
                        />
                    </Box>
                )
            }
            CTAPrimaryClick={() => props.clearFilters()}
            CTAPrimaryDisabled={props.filtersCount === 0}
            boxContentClassname={styles.containerBoxContent}
            contentBoxPaddingLeft="20px"
            headerRef={headerRef}
            footerRef={footerRef}
        >
            {/* IDENTITY */}
            <ModalSectionTitle
                title={t`Identité`}
                styles={{
                    marginTop: "0px",
                }}
            />

            <Box className={styles.filterSearch}>
                <SimpleInputUncontrolled
                    search
                    size="small"
                    name="search"
                    placeholder={t`Chercher un vin...`}
                    variant="outlined"
                    defaultValue={props.filters?.["searchText"]}
                    onBlur={(e) => {
                        if (e?.target?.value !== undefined) {
                            return props.setFilter("searchText", e?.target?.value);
                        }
                    }}
                    boxStyle={{
                        backgroundColor: theme.palette.common.white,
                        borderRadius: "53px",
                        border: "none",
                    }}
                    bgTransparent
                    borderRadius={53}
                    iconPosition="start"
                    inModal
                />
            </Box>
            <AutocompleteChipsInput
                placeholder={t`Choisir...`}
                class={styles.filterSelect}
                label={t`Appellation`}
                enumField="APPELLATION_LABEL"
                value={props?.filters?.appellations}
                onChange={(e1, e2) => props?.setFilter("appellations", e2)}
                labelColor={theme.palette.primary.dark}
                labelPaddingLeft="16px"
                labelPaddingTop="1px"
                placeholderPaddingLeft="20px"
                inputPaddingLeft="20px"
                sortAlphabetical
                uppercase
                popupIcon
            />

            <AutocompleteChipsInput
                placeholder={t`Choisir...`}
                class={styles.filterSelect}
                label={t`Classement`}
                enumField="CLASSIFICATION_ORGANISM"
                value={props?.filters?.classificationOrganisms}
                onChange={(e1, e2) => props?.setFilter("classificationOrganisms", e2)}
                labelColor={theme.palette.primary.dark}
                labelPaddingLeft="16px"
                labelPaddingTop="1px"
                placeholderPaddingLeft="20px"
                inputPaddingLeft="20px"
                sortAlphabetical
                popupIcon
            />

            <Box className={styles.checkboxBox}>
                <Typography variant="h6" style={{ color: theme.palette.primary.dark, fontWeight: 700 }}>
                    <Trans>Couleur</Trans>
                </Typography>
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <SearchCheckbox
                        setFilter={(filtername, v) => props.setFilter(filtername, v)}
                        options={[t`Rouge`, t`Blanc`, t`Rosé`]}
                        styles={styles}
                        filterName="colors"
                        boxClassName={styles.checkboxBase}
                        filters={props.filters}
                    />
                </Box>
            </Box>

            <Box className={styles.checkboxBox}>
                <Typography variant="h6" style={{ color: theme.palette.primary.dark, fontWeight: 700 }}>
                    {t`Sucrosité`}
                </Typography>
                <Box style={{ display: "flex", justifyContent: "space-between", marginBottom: 8 }}>
                    <SearchCheckbox
                        setFilter={(filtername, v) => props.setFilter(filtername, v)}
                        options={[t`Sec`, t`Demi-sec`, t`Moelleux`, t`Liquoreux`]}
                        styles={styles}
                        filterName="sweetness"
                        boxClassName={styles.checkboxTwoLine}
                        filters={props.filters}
                    />
                </Box>
            </Box>
            {searchingByVintage && (
                <Box className={styles.container_vintage_search}>
                    <Box
                        style={{
                            position: "absolute",
                            zIndex: "12",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            top: "10%",
                        }}
                    >
                        <Box
                            style={{
                                width: "410px",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.27)",
                                background: theme.palette.common.white,
                                borderRadius: "10px",
                                textAlign: "center",
                                color: theme.palette.primary.dark,
                            }}
                        >
                            <img src={inComingLogo} className={styles.logo_in_coming} />
                            <Typography variant="h4" style={{ margin: width < 600 ? "0 26px" : "0 48px" }}>
                                <Trans>Recherche par millésime à venir</Trans>
                            </Typography>
                            <Typography
                                variant="h6"
                                style={{ margin: width < 600 ? "0 26px 17px 26px" : "0 48px 23px 48px" }}
                            >
                                <Trans>
                                    Notre équipe est déjà sur le coup pour votre offrir ce mode de recherche au plus
                                    vite !
                                </Trans>
                            </Typography>
                        </Box>
                    </Box>
                    <Box className={styles.in_coming_filter} />
                    <AutocompleteChipsInput
                        placeholder={t`Choisir...`}
                        class={styles.filterSelect}
                        label={t`Millésime`}
                        options={Array.from({ length: 2022 + 1 - 1920 }, (v, k) => (k + 1920).toString())}
                        value={props?.filters?.years}
                        onChange={(e1, e2) => props?.setFilter("years", e2)}
                        labelColor={theme.palette.primary.dark}
                        labelPaddingLeft="16px"
                        labelPaddingTop="1px"
                        placeholderPaddingLeft="20px"
                        inputPaddingLeft="20px"
                        uppercase
                        popupIcon
                        disabled={inComing}
                    />
                    <Box className={styles.checkboxBox}>
                        <Typography variant="h6" style={{ color: theme.palette.primary.dark, fontWeight: 700 }}>
                            <Trans>Alc./vol.</Trans>
                        </Typography>
                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                            <SearchCheckbox
                                setFilter={(filtername, v) => props.setFilter(filtername, v)}
                                options={[t`< 12 %`, t`> 12 % < 14 %`, t`> 14 %`]}
                                styles={styles}
                                filterName="alcoolPercentage"
                                boxClassName={styles.checkboxTwoLine}
                                filters={props.filters}
                                disabled={inComing}
                            />
                        </Box>
                    </Box>
                    <AutocompleteChipsInput
                        placeholder={t`Choisir...`}
                        class={styles.filterSelect}
                        label={t`Label`}
                        sortAlphabetical
                        enumField="LABEL_CERTIFICATION_WINE"
                        value={props?.filters?.labels}
                        onChange={(e1, e2) => props?.setFilter("labels", e2)}
                        labelColor={theme.palette.primary.dark}
                        labelPaddingLeft="16px"
                        labelPaddingTop="1px"
                        placeholderPaddingLeft="20px"
                        inputPaddingLeft="20px"
                        locale={locale}
                        uppercase
                        popupIcon
                        disabled={inComing}
                    />
                    <AutocompleteChipsInput
                        placeholder={t`Choisir...`}
                        class={styles.filterSelect}
                        label={t`Ne contient pas...`}
                        enumField="ALLERGENS"
                        uppercase
                        value={props?.filters?.allergens}
                        onChange={(e1, e2) => props?.setFilter("allergens", e2)}
                        labelColor={theme.palette.primary.dark}
                        labelPaddingLeft="16px"
                        labelPaddingTop="1px"
                        placeholderPaddingLeft="20px"
                        inputPaddingLeft="20px"
                        locale={locale}
                        popupIcon
                        disabled={inComing}
                    />
                    <AutocompleteChipsInput
                        placeholder={t`Choisir...`}
                        class={styles.filterSelect}
                        label={t`Cépages`}
                        enumField="GRAPE_VARIETY"
                        value={props?.filters?.cepages}
                        onChange={(e1, e2) => props?.setFilter("cepages", e2)}
                        labelColor={theme.palette.primary.dark}
                        labelPaddingLeft="16px"
                        labelPaddingTop="1px"
                        placeholderPaddingLeft="20px"
                        inputPaddingLeft="20px"
                        locale={locale}
                        sortAlphabetical
                        uppercase
                        popupIcon
                        disabled={inComing}
                    />
                    <AutocompleteChipsInput
                        placeholder={t`Choisir...`}
                        class={styles.filterSelect}
                        label={t`Œnologue consultant`}
                        enumField="ENOLOGIST"
                        value={props?.filters?.oenologists}
                        onChange={(e1, e2) => props?.setFilter("oenologists", e2)}
                        labelColor={theme.palette.primary.dark}
                        labelPaddingLeft="16px"
                        labelPaddingTop="1px"
                        placeholderPaddingLeft="20px"
                        inputPaddingLeft="20px"
                        locale={locale}
                        sortAlphabetical
                        // uppercase
                        popupIcon
                        disabled={inComing}
                    />
                    <Box className={styles.switches}>
                        <Box className={styles.switchFull} style={{ gridColumn: width < 500 && "1 / span 12" }}>
                            <BaseCheckbox
                                labelStyle={{
                                    fontSize: "14px",
                                }}
                                disabled={inComing}
                                label={t`Vin sans sulfite ajouté`}
                                checked={props?.filters?.wineWithoutAddedSulphite}
                                onChange={() =>
                                    props?.setFilter(
                                        "wineWithoutAddedSulphite",
                                        !props?.filters?.wineWithoutAddedSulphite
                                    )
                                }
                            />
                        </Box>

                        <Box className={styles.switchFull} style={{ gridColumn: width < 500 && "1 / span 12" }}>
                            <BaseCheckbox
                                labelStyle={{
                                    fontSize: "14px",
                                }}
                                disabled={inComing}
                                label={t`Ne contient pas d’OGM`}
                                checked={props?.filters?.doesNotContainOGM}
                                onChange={() =>
                                    props?.setFilter("doesNotContainOGM", !props?.filters?.doesNotContainOGM)
                                }
                            />
                        </Box>
                    </Box>
                    {/* DEGUSTATION */}
                    <ModalSectionTitle
                        title={t`Dégustation`}
                        styles={{
                            marginTop: "27px",
                            marginBottom: "16px",
                        }}
                    />
                    <Box
                        className={styles.filterSelect}
                        style={{ justifyContent: "center", alignItems: "center", marginTop: 0 }}
                    >
                        <RangeSliderCustomSearch
                            value={props?.filters?.apogee}
                            initialMinimumValue={1}
                            onChange={(e, v) => props?.setFilter("apogee", v)}
                            labelEndTypeSingular="an"
                            labelMiddleType={t`à`}
                            labelEndType=""
                            labelAll={t`tout`}
                            labelType={t`Apogée`}
                            max={2100}
                            min={1850}
                            defaultValue={[1850, 2100]}
                            colorThumb={theme.palette.primary.dark}
                            trackColor={theme.palette.primary.main}
                            railColor={theme.palette.secondary.main}
                            labelStyle={{
                                fontSize: "14px",
                                color: theme.palette.primary.dark,
                            }}
                            typoMarginLeft="24px"
                            alignSelf="center"
                            width="96%"
                            resetValues
                            noShowMore
                            disabled={inComing}
                        />
                    </Box>
                    <AutocompleteChipsInput
                        placeholder={t`Choisir...`}
                        class={styles.filterSelect}
                        label={t`Attaque`}
                        enumField="PALATE_ATTACK"
                        value={props?.filters?.attack}
                        onChange={(e1, e2) => props?.setFilter("attack", e2)}
                        labelColor={theme.palette.primary.dark}
                        labelPaddingLeft="16px"
                        labelPaddingTop="1px"
                        placeholderPaddingLeft="20px"
                        inputPaddingLeft="20px"
                        locale={locale}
                        uppercase
                        popupIcon
                        disabled={inComing}
                    />
                    <AutocompleteChipsInput
                        placeholder={t`Choisir...`}
                        class={styles.filterSelect}
                        label={t`Acidité`}
                        enumField="PALATE_ACIDITY"
                        value={props?.filters?.acidity}
                        onChange={(e1, e2) => props?.setFilter("acidity", e2)}
                        labelColor={theme.palette.primary.dark}
                        labelPaddingLeft="16px"
                        labelPaddingTop="1px"
                        placeholderPaddingLeft="20px"
                        inputPaddingLeft="20px"
                        locale={locale}
                        uppercase
                        popupIcon
                        disabled={inComing}
                    />
                    <AutocompleteChipsInput
                        placeholder={t`Choisir...`}
                        class={styles.filterSelect}
                        label={t`Tanins`}
                        enumField="PALATE_TANNIN"
                        uppercase
                        value={props?.filters?.tanins}
                        onChange={(e1, e2) => props?.setFilter("tanins", e2)}
                        labelColor={theme.palette.primary.dark}
                        labelPaddingLeft="16px"
                        labelPaddingTop="1px"
                        placeholderPaddingLeft="20px"
                        inputPaddingLeft="20px"
                        locale={locale}
                        popupIcon
                        disabled={inComing}
                    />
                    <AutocompleteChipsInput
                        placeholder={t`Choisir...`}
                        class={styles.filterSelect}
                        label={t`Longueur`}
                        enumField={"PALATE_LENGTH"}
                        uppercase
                        value={props?.filters?.length}
                        onChange={(e1, e2) => props?.setFilter("length", e2)}
                        labelColor={theme.palette.primary.dark}
                        labelPaddingLeft="16px"
                        labelPaddingTop="1px"
                        placeholderPaddingLeft="20px"
                        inputPaddingLeft="20px"
                        locale={locale}
                        popupIcon
                        disabled={inComing}
                    />
                    {/* VITICULTURE */}
                    <ModalSectionTitle
                        title={!searchingByVintage ? t`Viticulture` : t`Viticulture et vinification`}
                        styles={{
                            marginTop: "27px",
                        }}
                    />
                    <AutocompleteChipsInput
                        placeholder={t`Choisir...`}
                        class={styles.filterSelect}
                        label={t`Type de vendanges`}
                        enumField="HARVEST_METHOD"
                        value={props?.filters?.typeOfHarvest}
                        onChange={(e1, e2) => props?.setFilter("typeOfHarvest", e2)}
                        labelColor={theme.palette.primary.dark}
                        labelPaddingLeft="16px"
                        labelPaddingTop="1px"
                        placeholderPaddingLeft="20px"
                        inputPaddingLeft="20px"
                        locale={locale}
                        uppercase
                        popupIcon
                        disabled={inComing}
                    />
                    <AutocompleteChipsInput
                        placeholder={t`Choisir...`}
                        class={styles.filterSelect}
                        label={t`Type de macérations`}
                        enumField="MACERATION_TYPE"
                        value={props?.filters?.typesMacerations}
                        onChange={(e1, e2) => props?.setFilter("typesMacerations", e2)}
                        labelColor={theme.palette.primary.dark}
                        labelPaddingLeft="16px"
                        labelPaddingTop="1px"
                        placeholderPaddingLeft="20px"
                        inputPaddingLeft="20px"
                        locale={locale}
                        popupIcon
                        uppercase
                        disabled={inComing}
                    />
                    <Box className={styles.checkboxBox}>
                        <Typography variant="h6" style={{ color: theme.palette.primary.dark, fontWeight: 700 }}>
                            <Trans>Levures</Trans>
                        </Typography>
                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                            <SearchCheckbox
                                setFilter={(filtername, v) => {
                                    return props.setFilter(filtername, v);
                                }}
                                options={[t`ajoutées`, t`indigènes`]}
                                styles={styles}
                                filterName="levurages"
                                boxClassName={styles.checkboxBase}
                                filters={props.filters}
                                uppercaseFirstLetter
                                disabled={inComing}
                            />
                        </Box>
                    </Box>
                    <Box className={styles.switch} style={{ gridColumn: "1 / span 12", marginLeft: 20, marginTop: 15 }}>
                        <BigSwitch
                            checked={props.filters.plotVinification}
                            onChange={() => props.setFilter("plotVinification", !props.filters.plotVinification)}
                            border="none"
                            disabled={inComing}
                        />
                        <Typography
                            variant="h6"
                            className={styles.switchTypo}
                            onClick={() => {
                                if (!inComing) props.setFilter("plotVinification", !props.filters.plotVinification);
                            }}
                        >
                            <Trans>Vinification parcellaire</Trans>
                        </Typography>
                    </Box>
                    {/* ELEVAGE */}
                    <ModalSectionTitle
                        title={t`Élevage`}
                        styles={{
                            marginTop: "27px",
                        }}
                    />
                    <Box className={styles.switches}>
                        <SwitchThreeState
                            boxClassName={styles.switchThreeStateCustom}
                            style={{
                                gridColumn: !searchingByVintage || width <= 500 ? "1 / span 12" : "1 / span 5",
                            }}
                            choices={["false", "undefined", "true"]}
                            currentState={props.filters.barrelAging}
                            onChange={(e) => {
                                if (e !== "false") {
                                    props.resetFields([
                                        "frenchOak",
                                        "durationAgingBarrels",
                                        "newBarrels",
                                        "heatingBarrels",
                                    ]);
                                }
                                props.setFilter("barrelAging", e);
                            }}
                            legend={
                                <Typography variant="h6" className={styles.threeStateTypo}>
                                    <Trans>Élevage en barriques</Trans>
                                </Typography>
                            }
                            disabled={inComing}
                        />

                        <Box
                            className={styles.switch}
                            style={{ gridColumn: width > 500 ? "auto / span 4" : "1 / span 12" }}
                        >
                            <BigSwitch
                                // disabled={props?.filters?.barrelAging === "false"}
                                checked={props?.filters?.frenchOak}
                                onChange={() => props?.setFilter("frenchOak", !props?.filters?.frenchOak)}
                                border="none"
                                disabled={inComing}
                            />
                            <Typography
                                variant="h6"
                                className={
                                    props?.filters?.barrelAging !== "false"
                                        ? styles.switchTypo
                                        : styles.switchTypoDisabled
                                }
                                onClick={() => {
                                    // if (props?.filters?.barrelAging !== "false") {
                                    if (!inComing) {
                                        props?.setFilter("frenchOak", !props?.filters?.frenchOak);
                                    }
                                }}
                            >
                                <Trans>Chêne français</Trans>
                            </Typography>
                        </Box>

                        <Box
                            className={styles.switch}
                            style={{ gridColumn: width > 500 ? "10 / span 3" : "1 / span 12" }}
                        >
                            <BigSwitch
                                checked={props?.filters?.onLies}
                                onChange={() => props?.setFilter("onLies", !props?.filters?.onLies)}
                                border="none"
                                disabled={inComing}
                            />
                            <Typography
                                variant="h6"
                                className={styles.switchTypo}
                                onClick={() => {
                                    if (!inComing) props?.setFilter("onLies", !props?.filters?.onLies);
                                }}
                            >
                                <Trans>Sur lies</Trans>
                            </Typography>
                        </Box>
                    </Box>
                    <Box className={styles.checkboxBox}>
                        <Typography variant="h6" style={{ color: theme.palette.primary.dark, fontWeight: 700 }}>
                            <Trans>Durée d’élevage en barriques</Trans>
                        </Typography>
                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                            <SearchCheckbox
                                setFilter={(filtername, v) => props.setFilter(filtername, v)}
                                options={[t`< 12 mois`, t`12 mois à 18 mois`, t`> 18 mois`]}
                                styles={styles}
                                filterName="durationAgingBarrels"
                                boxClassName={styles.checkboxOneLine}
                                filters={props.filters}
                                // disabled={props.filters?.barrelAging === "false"}
                                disabled={inComing}
                            />
                        </Box>
                    </Box>
                    <Box className={styles.checkboxBox}>
                        <Typography variant="h6" style={{ color: theme.palette.primary.dark, fontWeight: 700 }}>
                            <Trans>Barriques neuves</Trans>
                        </Typography>
                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                            <SearchCheckbox
                                setFilter={(filtername, v) => props.setFilter(filtername, v)}
                                options={[t`< 30 % `, t`30 % à 80 % `, t`> 80 % `]}
                                styles={styles}
                                filterName={"newBarrels"}
                                boxClassName={styles.checkboxTwoLine}
                                filters={props.filters}
                                // disabled={props.filters?.barrelAging === "false"}
                                disabled={inComing}
                            />
                        </Box>
                    </Box>
                    <AutocompleteChipsInput
                        placeholder={t`Choisir...`}
                        class={styles.filterSelect}
                        label={t`Chauffe des barriques`}
                        enumField="BARRELS_TOASTING"
                        uppercase
                        value={props?.filters?.heatingBarrels}
                        onChange={(e1, e2) => props?.setFilter("heatingBarrels", e2)}
                        labelColor={theme.palette.primary.dark}
                        labelPaddingLeft="16px"
                        labelPaddingTop="1px"
                        placeholderPaddingLeft="20px"
                        inputPaddingLeft="20px"
                        locale={locale}
                        // disabled={props.filters?.barrelAging === "false"}
                        popupIcon
                        disabled={inComing}
                    />
                    {/* PRODUCTION */}
                    <ModalSectionTitle
                        title={t`Production`}
                        styles={{
                            marginTop: "27px",
                        }}
                    />
                    <Box className={styles.switches}>
                        <SwitchThreeState
                            boxClassName={styles.switchThreeState}
                            choices={["false", "undefined", "true"]}
                            currentState={props.filters.bonding}
                            onChange={(e) => props.setFilter("bonding", e)}
                            legend={
                                <Typography variant="h6" className={styles.threeStateTypo}>
                                    <Trans>Collage</Trans>
                                </Typography>
                            }
                            disabled={inComing}
                        />

                        <SwitchThreeState
                            boxClassName={styles.switchThreeState}
                            choices={["false", "undefined", "true"]}
                            currentState={props.filters.filtration}
                            onChange={(e) => props.setFilter("filtration", e)}
                            legend={
                                <Typography variant="h6" className={styles.threeStateTypo}>
                                    <Trans>Filtration</Trans>
                                </Typography>
                            }
                            disabled={inComing}
                        />
                    </Box>
                    <AutocompleteChipsInput
                        placeholder={t`Choisir...`}
                        class={styles.filterSelect}
                        label={t`Type de bouchage`}
                        enumField="WINE_CORKING_MODE"
                        uppercase
                        value={props?.filters?.typeOfClosures}
                        onChange={(e1, e2) => props?.setFilter("typeOfClosures", e2)}
                        labelColor={theme.palette.primary.dark}
                        labelPaddingLeft="16px"
                        labelPaddingTop="1px"
                        placeholderPaddingLeft="20px"
                        inputPaddingLeft="20px"
                        locale={locale}
                        popupIcon
                        disabled={inComing}
                    />
                    <Box
                        className={styles.switches}
                        style={{
                            display: searchingByVintage ? "grid" : "flex",
                            marginTop: searchingByVintage ? 10 : 0,
                        }}
                    >
                        <Box
                            className={styles.switchFull}
                            style={{ gridColumn: width < 500 ? "1 / span 12" : "1 / span 4" }}
                        >
                            <BigSwitch
                                checked={props?.filters?.waxedBottle}
                                onChange={() => props?.setFilter("waxedBottle", !props?.filters?.waxedBottle)}
                                border="none"
                                disabled={inComing}
                            />
                            <Typography
                                variant="h6"
                                className={styles.switchTypo}
                                onClick={() => {
                                    if (!inComing) props?.setFilter("waxedBottle", !props?.filters?.waxedBottle);
                                }}
                            >
                                <Trans>Bouteille cirée</Trans>
                            </Typography>
                        </Box>

                        <Box
                            className={styles.switchFull}
                            style={{ gridColumn: width < 500 ? "1 / span 12" : "auto / span 6" }}
                        >
                            <BigSwitch
                                checked={props?.filters?.bottlingAtTheProperty}
                                onChange={() =>
                                    props?.setFilter("bottlingAtTheProperty", !props?.filters?.bottlingAtTheProperty)
                                }
                                border="none"
                                disabled={inComing}
                            />
                            <Typography
                                variant="h6"
                                className={styles.switchTypo}
                                onClick={() => {
                                    if (!inComing)
                                        props?.setFilter(
                                            "bottlingAtTheProperty",
                                            !props?.filters?.bottlingAtTheProperty
                                        );
                                }}
                            >
                                <Trans>Mise en bouteille à la propriété</Trans>
                            </Typography>
                        </Box>

                        {/* <Box
                    className={styles.switchFull}
                    style={{ gridColumn: searchingByVintage && width < 500 ? "1 / span 12" : "5 / span 6" }}
                >
                    <SwitchThreeState
                        boxClassName={styles.switchThreeState}
                        choices={["false", "undefined", "true"]}
                        currentState={props.filters.bottlingAtTheProperty}
                        onChange={(e) => {
                            props.setFilter("bottlingAtTheProperty", e);
                        }}
                        legend={
                            <Typography variant="h6" className={styles.threeStateTypo}>
                                <Trans>Mise en bouteille à la propriété</Trans>
                            </Typography>
                        }
                        style={{ marginLeft: 0 }}
                    />
                </Box> */}
                    </Box>
                    {/* DISTRIBUTION */}
                    <ModalSectionTitle
                        title={t`Distribution`}
                        styles={{
                            marginTop: "27px",
                        }}
                    />
                    <Box className={styles.switches}>
                        <Box className={styles.switchFull} style={{ gridColumn: "1 /span 12" }}>
                            <BigSwitch
                                checked={props?.filters?.onlineSales}
                                onChange={() => props?.setFilter("onlineSales", !props?.filters?.onlineSales)}
                                border="none"
                                disabled={inComing}
                            />
                            <Typography
                                variant="h6"
                                className={styles.switchTypo}
                                onClick={() => {
                                    if (!inComing) props?.setFilter("onlineSales", !props?.filters?.onlineSales);
                                }}
                            >
                                <Trans>Vente en ligne</Trans>
                            </Typography>
                        </Box>
                    </Box>
                    <Box className={styles.filterSelect} style={{ justifyContent: "center", alignItems: "center" }}>
                        <RangeSliderCustomSearch
                            value={props?.filters?.priceRange}
                            initialMinimumValue={1}
                            onChange={(e, v) => props?.setFilter("priceRange", v)}
                            labelEndTypeSingular="an"
                            labelMiddleType={t`à`}
                            labelEndType=""
                            labelAll={t`tous les prix`}
                            labelType={t`Prix de vente`}
                            max={501}
                            min={1}
                            defaultValue={[1, 501]}
                            colorThumb={theme.palette.primary.dark}
                            trackColor={theme.palette.primary.main}
                            railColor={theme.palette.secondary.main}
                            labelStyle={{
                                fontSize: "14px",
                                color: theme.palette.primary.dark,
                            }}
                            isPrice
                            resetValues
                            typoMarginLeft="24px"
                            width="96%"
                            alignSelf="center"
                            disabled={inComing}
                        />
                    </Box>
                    {/* NOTE ET MEDAILLE */}
                    <ModalSectionTitle
                        title={t`Notes et médailles`}
                        styles={{
                            marginTop: "27px",
                        }}
                    />
                    <Box className={styles.switches}>
                        <Box className={styles.switch}>
                            <BigSwitch
                                checked={props?.filters?.score}
                                onChange={() => props?.setFilter("score", !props?.filters?.score)}
                                border="none"
                                disabled={inComing}
                            />
                            <Typography
                                variant="h6"
                                className={styles.switchTypo}
                                onClick={() => {
                                    if (!inComing) props?.setFilter("score", !props?.filters?.score);
                                }}
                            >
                                <Trans>Vin noté</Trans>
                            </Typography>
                        </Box>

                        <Box className={styles.switch}>
                            <BigSwitch
                                checked={props?.filters?.reward}
                                onChange={() => props?.setFilter("reward", !props?.filters?.reward)}
                                border="none"
                                disabled={inComing}
                            />
                            <Typography
                                variant="h6"
                                className={styles.switchTypo}
                                onClick={() => {
                                    if (!inComing) props?.setFilter("reward", !props?.filters?.reward);
                                }}
                            >
                                <Trans>Vin médaillé</Trans>
                            </Typography>
                        </Box>
                    </Box>{" "}
                </Box>
            )}
        </SearchModal>
    );
};

SearchWineModal.propTypes = {
    totalCount: number,
    isOpen: bool,
    onClose: func,
    titlePrimary: string,
    titleSecondary: string,
    foodAndWinePairingsOptions: array,
    filters: object,
    setFilter: func,
    resetFields: func,
    filtersCount: number,
    clearFilters: func,
    similar: bool,
    nextStep: func,
    onCloseCross: func,
};

export default SearchWineModal;
