/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import React, { useState } from "react";
import { makeStyles, Box, Typography, Link } from "@material-ui/core";
import Arrow from "../../Components/Arrow/Arrow";
import pheno from "../../assets/Wine/pheno.svg";
import WinePhenologicalStagesPopin from "./WinePhenologicalStagesPopin";
import PropTypes from "prop-types";
import theme from "../../globalTheme";
import {
    firstLetterUppercase,
    useWindowDimensions,
} from "../../helpers/helpers";
import { getSentence } from "./vintageHelpers";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import { Trans } from "@lingui/macro";

SwiperCore.use([Pagination]);

const useStyles = makeStyles((theme) => ({
    container: {
        border: "0.4px solid",
        borderRadius: "5px",
        borderColor: theme.palette.secondary.dark,
        position: "relative",
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        paddingTop: 0,
        height: "calc(100% - 32px)",
        [theme.breakpoints.only("xs")]: {
            height: "100%",
            padding: 0,
        },
        [theme.breakpoints.only("sm")]: {
            marginBottom: theme.spacing(2),
        },
        "& .swiper-container": {
            borderRadius: "5px",
        },
        "& .swiper-pagination-bullet": {
            background: "#915868",
        },
    },
    typoContainer: {
        position: "relative",
        top: "-16px",
        left: "0px",
        display: "flex",
        alignItems: "center",
    },
    title: {
        ...theme.front.typography.h6.dark_b,
        zIndex: 2,
        backgroundColor: theme.palette.common.white,
        padding: "0px 2px",
    },
    typo: {
        color: theme.palette.primary.light,
        fontSize: "18px",
        [theme.breakpoints.only("xs")]: {
            fontSize: "12px",
        },
    },
    stagesContainer: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        justifyContent: "space-around",
    },
    stageContainer: {
        width: "162px",
        marginBottom: theme.spacing(2),
        margin: "0px 20px",
    },
    imgContainer: {
        height: "130px",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
    },
    typoTitle: {
        fontFamily: "Lato",
        fontSize: "14px",
        color: "#915868",
        textAlign: "center",
    },
    typoTextContent: {
        fontFamily: "Lato",
        fontSize: "16px",
        color: "#360318",
    },
    winePhenologicalStagesSwipers: {},
}));

const WinePhenologicalStages = (props) => {
    const styles = useStyles(props);
    const [open, setOpen] = useState(false);
    const { width } = useWindowDimensions();

    return (
        <Box className={styles.container}>
            <Box className={styles.typoContainer}>
                <img
                    src={pheno}
                    alt="title_icon"
                    style={{ backgroundColor: "white", paddingRight: "4px" }}
                />
                <Typography
                    variant="h3"
                    className={styles.title}
                    style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        color: "#000000",
                    }}
                >
                    <Trans>Stades phénologiques</Trans>
                </Typography>
            </Box>
            {width >= 960 ? (
                <>
                    <Box className={styles.stagesContainer}>
                        {props.phenologicalStages?.map((el, i) => {
                            if (i < 4) {
                                return (
                                    <Box
                                        className={styles.stageContainer}
                                        key={i}
                                    >
                                        <Box className={styles.imgContainer}>
                                            <img
                                                src={el?.image?.data}
                                                alt="stages_img"
                                                style={{ height: "100%" }}
                                            />
                                        </Box>
                                        {el?.stage && (
                                            <Typography
                                                className={styles.typoTitle}
                                                style={{
                                                    fontSize: "14px",
                                                    color: "#915868",
                                                }}
                                            >
                                                {firstLetterUppercase(
                                                    el?.stage
                                                )}
                                            </Typography>
                                        )}
                                        <Typography
                                            style={{
                                                textAlign: "center",
                                                fontSize: "16px",
                                                color: theme.palette.primary.dark,
                                            }}
                                            className={styles.typoTextContent}
                                        >
                                            {getSentence(el)}
                                        </Typography>
                                    </Box>
                                );
                            }
                        })}
                    </Box>
                    {(props.phenologicalStages &&
                        props.phenologicalStages?.length) > 4 ? (
                            <Arrow
                            rotation="105"
                            arrowPosition="start"
                            style={{
                                margin: 0,
                                marginLeft: theme.spacing(1),
                                color: "#915868",
                            }}
                        >
                                <Link
                                variant="h6"
                                color="primary"
                                underline="always"
                                onClick={() => setOpen(true)}
                                style={{
                                    cursor: "pointer",
                                    marginLeft: "3px",
                                    color: theme.palette.primary.light

                                }}
                            >
                                    <Trans>
                                        Voir tous les stades phénologiques
                                    </Trans>
                                </Link>
                            </Arrow>
                    ) : (
                        ""
                    )}
                    {open ? (
                        <WinePhenologicalStagesPopin
                            background={3}
                            onClose={() => setOpen(false)}
                            open={open}
                            phenologicalStages={props.phenologicalStages}
                        />
                    ) : (
                        ""
                    )}
                </>
            ) : (
                <Swiper pagination={{ dynamicBullets: true }} spaceBetween={30}>
                    {props.phenologicalStages?.map((el, i) => {
                        return (
                            <SwiperSlide
                                key={i}
                                className={styles.winePhenologicalStagesSwipers}
                            >
                                <Box className={styles.stageContainer}>
                                    <Box className={styles.imgContainer}>
                                        <img
                                            src={el?.image?.data}
                                            alt="stages_img"
                                            style={{ height: "100%" }}
                                        />
                                    </Box>
                                    {el?.stage && (
                                        <Typography
                                            className={styles.typoTitle}
                                            style={{
                                                fontSize: "14px",
                                                color: "#915868",
                                            }}
                                        >
                                            {firstLetterUppercase(el?.stage)}
                                        </Typography>
                                    )}
                                    <Typography
                                        style={{
                                            textAlign: "center",
                                            fontSize: "16px",
                                            color: "#360318",
                                        }}
                                        className={styles.typoTextContent}
                                    >
                                        {getSentence(el)}
                                    </Typography>
                                </Box>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            )}
        </Box>
    );
};

WinePhenologicalStages.propTypes = {
    phenologicalStages: PropTypes.array,
};

export default WinePhenologicalStages;
