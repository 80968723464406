import { useState } from "react";
import PropTypes, { bool, func } from "prop-types";
import { Grid, Box, Typography, Link } from "@material-ui/core";
import { useForm } from "react-hook-form";
import BaseButton from "../../Components/Buttons/BaseButton";
import SubscriptionContainer from "./SubscriptionContainer";
import theme from "../../globalTheme";
import BaseCheckbox from "../../Components/Inputs/BaseCheckbox";
import CGAUPopin from "../../Dashboard/Popins/CGAUPopin";
import Loading from "../../Components/Loader/Loading";
import Payment from "../../Dashboard/Components/Payment/Payment";
import { PAYMENT_METHODS } from "config/constants";
import { useCustomHistory } from "hooks";

const PaymentContainer = (props) => {
    const [CGAUPopinOpen, setCGAUPopinOpen] = useState(false);
    const history = useCustomHistory();
    const [checked, setChecked] = useState(false);
    const paymentOnSubmit = () => {
        props.onClickNext();
    };

    const { handleSubmit } = useForm({
        defaultValues: props.subscriptionState,
        mode: "onChange",
        reValidateMode: "onChange",
    });

    return (
        <SubscriptionContainer
            title="Choisissez votre mode de paiement"
            titleVariant="h3"
            onClickNext={props.onClickNext}
            style={{ margin: 0, marginTop: theme.spacing(4) }}
        >
            <Grid container style={{ display: "flex", justifyContent: "center", height: "100%", width: "100%" }}>
                {CGAUPopinOpen && <CGAUPopin modalOpen={CGAUPopinOpen} handleClose={() => setCGAUPopinOpen(false)} />}
                {props.isLoading ? (
                    <Box display="flex" justifyContent="center" alignItems="flex-end">
                        <Loading loading={props.isLoading} />
                    </Box>
                ) : (
                    <form noValidate onSubmit={handleSubmit(paymentOnSubmit)} style={{ width: "100%" }}>
                        <Payment
                            disableMailto
                            noDiscount
                            paymentMethod={props.selectPayment}
                            disableTitle
                            disabledRecapPrice
                            disabledTitle
                            onClick={(e) => {
                                props.setSelectPayment(e);
                            }}
                            onClose={() => {}}
                        />

                        <BaseCheckbox
                            onlyCheckbox
                            checked={checked}
                            onChange={() => setChecked(!checked)}
                            labelStyle={{ fontSize: "14px", color: "#432E35", pointerEvents: "auto" }}
                            disabled={false}
                            style={{ paddingLeft: 0 }}
                            labelClickable={
                                <Typography style={{ color: "#432E35", fontSize: "12px", marginTop: "2px" }}>
                                    J&apos;ai pris connaissance et j&apos;accepte les&nbsp;
                                </Typography>
                            }
                            labelNotClickable={
                                <Link
                                    onClick={() => setCGAUPopinOpen(true)}
                                    underline="always"
                                    style={{ color: "#432E35", fontSize: "12px", cursor: "pointer", marginLeft: 0 }}
                                >
                                    conditions générales d&apos;abonnement et d&apos;utilisation
                                </Link>
                            }
                            noMargin
                        />
                        <Box justifyContent="flex-end" display="flex" paddingTop="10px">
                            <BaseButton
                                type="submit"
                                disabled={!checked || !props.selectPayment}
                                color="primary"
                                variant="contained"
                                size="small"
                                onClick={() => {
                                    if (props.selectPayment === PAYMENT_METHODS.offline) {
                                        history.push("/subscription/registered");
                                    }
                                }}
                            >
                                Accéder au paiement
                            </BaseButton>
                        </Box>
                    </form>
                )}
            </Grid>
        </SubscriptionContainer>
    );
};

PaymentContainer.propTypes = {
    onClickNext: PropTypes.func,
    selectPaymentMethod: PropTypes.func,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    subscriptionState: PropTypes.object,
    selectPayment: bool,
    setSelectPayment: func,
};

export default PaymentContainer;
