import { Box, makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import theme from "../globalTheme";

const useStyles = makeStyles((e) => ({
    container_title: {
        width: "100%",
        display: "flex",
        gap: "8px",
        "&:not(:last-child)": {
            marginBottom: "28px"
        }
    },
    image_container: {
        imageContainer: {
            width: "48px",
            height: "48px",
        },
    },
    image: {
        media: {
            width: "100%",
            [theme.breakpoints.only("xs")]: {
                width: "42px",
            },
        },
    },
    typo: {
        color: theme.palette.primary.dark
    }
}));

const BlockContent = (props) => {
    const styles = useStyles();

    return (
        <>
            <Box className={styles.container_title} style={{ marginBottom: "5px" }}>
                <Box className={styles.image_container}>
                    <img className={styles.image} src={props.icon} />
                </Box>
                <Box>
                    <Typography variant="h4">{props.title}</Typography>
                </Box>
            </Box>

            <Box>
                <Box>
                    <Typography variant="body2" className={styles.typo}>{props.data}</Typography>
                </Box>
            </Box>
        </>
    );
};


BlockContent.propTypes = {
    icon: PropTypes.string,
    data: PropTypes.string,
    title: PropTypes.string,
};

export default BlockContent;