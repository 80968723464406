import { makeStyles, Box } from "@material-ui/core";
import React from "react";
import theme from "../globalTheme";
import cardBg from "../assets/frontPage/pinkBg.jpg";
import tourismBg from "../assets/landingPage/tourismbg.svg";

interface FrontContentBottomProps {
    estate?: any;
    children: React.ReactNode;
    activityBackground?: boolean;
    contactBackground?: boolean;
    style?: object;
    boxStyle?: object;
}

const useStyles = makeStyles(() => {
    return {
        activity: {
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${tourismBg})`,
            backgroundSize: "100%",
            width: "100%",
            backgroundPositionY: "bottom",
            backgroundPositionX: "right",
            zIndex: -1,

            display: "grid",
            gap: "24px",
            gridTemplateColumns: "repeat(12, 1fr)",
            [theme.breakpoints.up("sm")]: {
                margin: "0 auto",
            },
            [theme.breakpoints.only("lg")]: {
                overflow: "hidden",
            },
        },
        contact: {
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${cardBg})`,
            backgroundSize: "100%",
            width: "100%",
            backgroundPositionY: "bottom",
            backgroundPositionX: "right",
            zIndex: -1,

            display: "grid",
            gap: "24px",
            gridTemplateColumns: "repeat(12, 1fr)",
            [theme.breakpoints.up("sm")]: {
                margin: "0 auto",
            },
            [theme.breakpoints.only("lg")]: {
                overflow: "hidden",
            },
        },
        content: {
            backgroundRepeat: "no-repeat",
            width: "100%",
            backgroundPositionX: "right",
            zIndex: -1,
            display: "grid",
            gap: "24px",
            gridTemplateColumns: "repeat(12, 1fr)",
            [theme.breakpoints.up("xs")]: {
                backgroundSize: "auto 45%, auto 55%",
                backgroundPositionY: "bottom, top",
                backgroundImage: `url(${cardBg})`,
            },
            [theme.breakpoints.up("sm")]: {
                backgroundImage: `url(${cardBg}),url(${tourismBg})`,
                backgroundSize: "auto 45%, auto 55%",
                backgroundPositionY: "bottom, top",
            },
            [theme.breakpoints.up("md")]: {
                backgroundSize: "auto 45%, auto 55%",
                backgroundPositionY: "bottom, top",
            },
            [theme.breakpoints.up("lg")]: {
                backgroundSize: "auto 45%, auto 55%",
                backgroundPositionY: "bottom, top",
                overflow: "hidden",
            },
            [theme.breakpoints.up("xl")]: {
                backgroundSize: "100% 30%, 100% auto",
                backgroundPositionY: "bottom, center",
            },
        },
        second_part: () => {
            return {
                [theme.breakpoints.up("xs")]: {
                    gridColumn: "1 / span 12",
                },
                [theme.breakpoints.up("sm")]: {
                    padding: "0 16px",
                },
                [theme.breakpoints.up("xl")]: {
                    width: "calc(1314px)",
                    margin: "auto",
                },
            };
        },
    };
});
const FrontContentBottom = (props: FrontContentBottomProps) => {
    const styles = useStyles();

    const background = () => {
        if (props.activityBackground) {
            if (props.contactBackground) {
                return styles.content;
            } else {
                return styles.activity;
            }
        } else {
            return styles.contact;
        }
    };
    return (
        <Box className={`${background()}`} style={props.boxStyle}>
            <Box className={styles.second_part} style={props.style}>
                {props.children}
            </Box>
        </Box>
    );
};
export default FrontContentBottom;
