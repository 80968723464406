import { Box, Hidden, makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { SUBSCRIPTION_TYPES } from "config/constants";
import PropTypes from "prop-types";
import { useState } from "react";
import { getPricesWithOption } from "SubscriptionTunnel/Containers/WebSubscriptionContainer";

import BaseChip from "Components/Chips/BaseChip";
import { getFreeTrial } from "helpers/helpers";
import { useSelector } from "react-redux";
import theme from "../../../globalTheme";
import { getPackType } from "../../constants";
import ComparisonTablePopinContainer from "../../Containers/ComparisonTablePopinContainer";
import ExtraStatusCard from "../ExtraStatusCard";
import Pricing from "../Pricing";
import BaseSubscriptionStatusCards from "./BaseSubscriptionStatusCards";

const useStyles = makeStyles((theme) => ({
    media: {
        width: "50px",
        height: "50px",
    },
    text: {
        color: theme.palette.common.black,
        textAlign: "left",
    },
    link: {
        cursor: "pointer",
        color: theme.palette.primary.dark,
        fontFamily: theme.typography.h6.fontFamily,
    },
    getSubscription__container: {},
    getSubscription__wrapper: {
        display: "flex",
    },
    getSubscription__icon: {
        padding: "0 8px",
    },
    getSubscription__typo__wrapper: {
        paddingLeft: 0,
    },
    getNotSelectPack__wrapper: {
        display: "flex",
        paddingLeft: 0,
        paddingTop: theme.spacing(3),
    },
    free_trial_summary: {
        display: "flex",
        flexDirection: "column",
        color: theme.palette.primary.main,
    },
    title_free_trial: {
        display: "flex",
        flexDirection: "row",
        gap: "4px",
        alignItems: "baseline",
    },
    chip: {
        borderRadius: "4px !important",
        "& .MuiChip-label": {
            padding: "2px 4px",
            fontWeight: 700,
        },
    },
}));

const SubscriptionTypeSummary = (props) => {
    const styles = useStyles();

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const subscriptionType = getPackType(props.subscriptionStateSelectedPack);
    const freeTrial = useSelector((state) => getFreeTrial(state));

    return (
        <>
            {open && (
                <ComparisonTablePopinContainer
                    collapse
                    modalOpen={open}
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                    subscriptionStateSelectedPack={props.subscriptionStateSelectedPack}
                    subscriptionDispatch={props.subscriptionDispatch}
                    switchCheck={props.switchCheck}
                    handleSwitchChange={(event) => props.handleSwitchChange(event)}
                />
            )}

            <BaseSubscriptionStatusCards
                cardTitle="Abonnement"
                disabledCard={subscriptionType?.disabled}
                // extraContent={
                //     subscriptionType?.labelComparisonTable === "« Premium »" &&
                //     props.switchCheck && (
                //         <Tag
                //             style={{
                //                 backgroundColor: theme.palette.primary.light,
                //                 color: theme.palette.common.white,
                //                 padding: "8px 3px",
                //                 minHeight: "auto",
                //                 height: "auto",
                //                 width: "fit-content",
                //                 maxWidth: "inherit",
                //             }}
                //             label={"OFFRE DE LANCEMENT - 50  %"}
                //         />
                //     )
                // }
            >
                <Box className={styles.getSubscription__container}>
                    <Box className={styles.getSubscription__wrapper}>
                        {/* What to display when selected or not selected pack */}
                        {props.subscriptionStateSelectedPack !== null ? (
                            <>
                                <Hidden only={["xs", "sm", "lg"]}>
                                    <Box className={styles.getSubscription__icon}>
                                        <img className={styles.media} src={subscriptionType?.picture} component="img" />
                                    </Box>
                                </Hidden>
                                <Box className={styles.getSubscription__typo__wrapper}>
                                    <Typography
                                        className={styles.text}
                                        variant="h5"
                                        align="center"
                                        style={{ fontWeight: "700" }}
                                    >
                                        « {subscriptionType?.type} »
                                    </Typography>
                                    {/* {getSelectedPackTypo(subscriptionType, styles)} */}
                                    {props.withPlus && <ExtraStatusCard />}
                                    {props.subscriptionStateSelectedPack !== null ? (
                                        freeTrial ? (
                                            <Box className={styles.free_trial_summary}>
                                                <Box className={styles.title_free_trial}>
                                                    <Typography style={{ fontFamily: "Mansalva" }} variant="h6">
                                                        30 jours
                                                    </Typography>
                                                    <BaseChip title="essai gratuit" className={styles.chip} />
                                                </Box>
                                                <Typography
                                                    variant="subtitle1"
                                                    style={{ textTransform: "uppercase", fontWeight: "700" }}
                                                >
                                                    sans engagement
                                                </Typography>
                                            </Box>
                                        ) : (
                                            <Pricing
                                                noHeight
                                                priceColor={theme.palette.primary.main}
                                                title={subscriptionType?.labelComparisonTable}
                                                // discountPrice={
                                                //     allPrices?.premium?.discount && props.switchCheck ? allPrices?.premium?.discount : null
                                                // }
                                                // beta={ props.subscriptionStateSelectedPack === SUBSCRIPTION_TYPES.premium}
                                                style={{ justifyContent: "flex-start" }}
                                                price={getPricesWithOption(
                                                    subscriptionType,
                                                    props.switchCheck,
                                                    props.withPlus,
                                                    subscriptionType?.id === SUBSCRIPTION_TYPES.initial ? 150 : 200
                                                )}
                                                period={
                                                    subscriptionType?.id === SUBSCRIPTION_TYPES.basic
                                                        ? subscriptionType?.period
                                                        : props.switchCheck
                                                        ? "/an"
                                                        : "/mois"
                                                }
                                                small
                                                textColor="dark"
                                            />
                                        )
                                    ) : null}
                                </Box>
                            </>
                        ) : (
                            <Box className={styles.getNotSelectPack__wrapper}>
                                <Typography
                                    className={styles.text}
                                    variant="h5"
                                    align="center"
                                    style={{ fontWeight: "bold" }}
                                >
                                    {subscriptionType?.type}
                                </Typography>
                                <Pricing
                                    price={subscriptionType?.price}
                                    period={subscriptionType?.period}
                                    small
                                    textColor="dark"
                                />
                            </Box>
                        )}
                    </Box>
                </Box>
            </BaseSubscriptionStatusCards>
        </>
    );
};

SubscriptionTypeSummary.propTypes = {
    disabledCard: PropTypes.bool,
    webSubDisplayModifyLink: PropTypes.bool,

    subscriptionStateSelectedPack: PropTypes.number,
    subscriptionDispatch: PropTypes.func,

    switchCheck: PropTypes.bool,
    handleSwitchChange: PropTypes.func,

    withPlus: PropTypes.bool,
};

export default SubscriptionTypeSummary;
