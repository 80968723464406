import { Backdrop, Box, Icon, makeStyles, Typography } from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import LanguageSelector from "Components/Accordions/LanguageSelector";
import { any, array, bool, elementType, func, object } from "prop-types";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { useCustomHistory } from "hooks";
import { Link } from "react-router-dom";
import account from "../../assets/navbarfront/account.svg";
import inputSearchIcon from "../../assets/navbarfront/inputLoupe.svg";
import theme from "../../globalTheme";
import { darkColorSvg, getPaymentStatus, menuCTA } from "../../helpers/helpers";
import AutocompleteSearch from "../../Pages/SearchEngine/AutocompleteSearch";
import NestedAccordions from "./NestedAccordions";

const useStyles = makeStyles((theme) => ({
    menu_container: {
        zIndex: "1000",
        boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.25)",
        position: "absolute",
        width: "100vw",
        left: 0,
        background: theme.palette.common.white,
        top: "0",
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
    },

    topbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0.5rem 2rem",
        [theme.breakpoints.down("md")]: {
            padding: "0.5rem 1rem",
        },
        borderBottom: `0.4px solid ${theme.palette.secondary.main}`,
    },
    logo: (props) => {
        return {
            [theme.breakpoints.only("xs")]: {
                display: props.standalone && "none",
            },
            display: "flex",
            width: "auto",
            height: "auto",
            marginLeft: "1rem",
            marginRight: 0,
        };
    },

    contentAccordion: {
        padding: "0.5rem 2rem",
        [theme.breakpoints.down("md")]: {
            padding: "0.5rem 1rem",
        },
    },
    cta: {
        padding: "6px",
        textAlign: "center",
        color: theme.palette.common.white,
        background: theme.palette.primary.dark,
        fontFamily: "Mansalva",
        cursor: "pointer",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: "5px",
    },
    containerAccordion: {
        [theme.breakpoints.down("md")]: {
            margin: "0 0 0.5rem 0",
            overflowY: "scroll",
            overflowX: "hidden",
            maxHeight: "65vh",
            "&::-webkit-scrollbar": { width: "4px" },
            "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                paddingRight: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.primary.dark,
                borderRadius: "20px",
            },
        },
        margin: "0 2rem 0.5rem 2rem",
    },
    containerLang: {
        display: "flex",
        width: "5rem",
        height: "36px",
        position: "relative",
    },
    account: {
        position: "relative",
        marginRight: "1rem",
    },
    connectedDot: {
        width: "4px",
        height: "4px",
        backgroundColor: theme.palette.success.dark,
        top: "0px",
        right: "2px",
        display: "flex",
        position: "absolute",
        borderRadius: "50%",
    },
    autocompleteContainer: {
        width: "100%",
        borderRadius: "50px",
    },
    autocompleteInput: {
        fontStyle: "italic",
        fontSize: "14px",
        color: theme.palette.primary.dark,
    },
    autocompletePaper: {
        marginTop: 16,
        borderRadius: 10,
    },
}));

// eslint-disable-next-line react/display-name
const Menu = React.forwardRef((props, ref) => {
    const [searchMenu, setSearchMenu] = useState("");
    const currentUser = useSelector((state) => state.user.currentUser);
    const style = useStyles(props);
    const history = useCustomHistory();
    const paymentStatus = useSelector((state) => getPaymentStatus(state));

    return (
        <Backdrop open={props.open} style={{ zIndex: "1" }}>
            <Box className={style.menu_container} {...props} ref={ref}>
                <Box>
                    <Box className={style.topbar}>
                        <CloseRoundedIcon
                            onClick={() => props.onClose()}
                            style={{ fontSize: "2rem", cursor: "pointer", fill: theme.palette.primary.dark }}
                        />

                        <Icon
                            className={style.logo}
                            style={{ filter: props.open && darkColorSvg }}
                            onClick={() => {
                                props.onClickLogo();
                                props.onClose();
                            }}
                            onDragStart={ (e) => { e.preventDefault(); }}
                        >
                            {props.logo}
                        </Icon>
                        <Box style={{ display: "flex", alignItems: "center" }}>
                            {(!props.standalone || (props.standalone && props.currentUser)) && (
                                <Icon className={style.account} onClick={() => props.accountOnClick()}>
                                    {props.currentUser ? <span className={style.connectedDot}></span> : null}
                                    <img
                                        src={account}
                                        style={{
                                            cursor: "pointer",
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            filter: darkColorSvg,
                                        }}
                                    />
                                </Icon>
                            )}
                            <LanguageSelector scroll />
                        </Box>
                    </Box>
                    {props.search && (
                        <Box className={style.contentAccordion}>
                            <Box className={style.search}>
                                <AutocompleteSearch
                                    onChange={(e) => setSearchMenu(e)}
                                    startAdornment
                                    inputStyle={{
                                        backgroundColor: theme.palette.common.white,
                                        borderRadius: 50,
                                        border: `0.4px solid ${theme.palette.secondary.main}`,
                                        height: 32,
                                        width: "100%",
                                    }}
                                    paperClassname={style.autocompletePaper}
                                    placeholder="Rechercher un vin, une propriété..."
                                    onEnterPressed={() =>
                                        searchMenu &&
                                        history.push({ pathname: "/recherche/tout/resultats", search: searchMenu })
                                    }
                                    autocompleteContainerClassName={style.autocompleteContainer}
                                    icon={inputSearchIcon}
                                    inputClassName={style.autocompleteInput}
                                    iconLink="/recherche"
                                />
                            </Box>
                        </Box>
                    )}
                    <Box className={style.containerAccordion}>
                        <NestedAccordions
                            datas={props.datas}
                            onClose={() => props.onClose()}
                            loadingMenuItems={props.loadingMenuItems}
                        />
                    </Box>
                    {props.buttonBESV && (
                        <Link
                            style={{ textDecoration: "none" }}
                            to={menuCTA(currentUser, paymentStatus).href}
                            onClick={() => props.onClose()}
                        >
                            <Typography className={style.cta} variant="h6">
                                {menuCTA(currentUser, paymentStatus).content}
                            </Typography>
                        </Link>
                    )}
                </Box>
            </Box>
        </Backdrop>
    );
});

Menu.propTypes = {
    onClose: func,
    ref: any,
    open: bool,
    datas: array,
    accountOnClick: func,
    currentUser: object,
    buttonBESV: bool,
    search: bool,
    logo: elementType,
    standalone: bool,
    loadingMenuItems: bool,
    onClickLogo: func,
};

export default Menu;
