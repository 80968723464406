import { makeStyles, Box, Typography } from "@material-ui/core";
import SvgIcon from "@material-ui/core/SvgIcon";
import { PropTypes } from "prop-types";

import theme from "../../globalTheme";
import { useWindowDimensions } from "../../helpers/helpers";

const useStyles = makeStyles((theme) => ({
    container__global: {
        zIndex: 100000,
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,

        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background: "#F7F4F4",
        textAlign: "center",
        backgroundColor: "white",
    },
    icon: {
        fontSize: "274px",
        "& path": {
            fill: theme.palette.primary.light,
        },
    },
}));

const SmallScreen = (props) => {
    const styles = useStyles(theme);

    const { width } = useWindowDimensions();
    return (
        <Box style={{ textAlign: "center" }} className={styles.container__global}>
            <SvgIcon className={styles.icon}>
                <path d="M17 6H18V7.29289L19.1464 6.14645L19.8536 6.85355L18.7071 8L20 8V9L18 9H17V8V6Z" />
                <path d="M7 15V16V18H6L6 16.7071L4.85355 17.8536L4.14645 17.1464L5.29289 16L4 16V15H6H7Z" />
                <path d="M16 8V7H6C5.44772 7 5 7.44772 5 8V14H6V8H16ZM18 10V16H8V17H18C18.5523 17 19 16.5523 19 16V10H18Z" />
            </SvgIcon>
            <Box>
                <Typography variant="h2" style={{ padding: "16px", fontSize: "18px" }}>
                    {props.text}
                </Typography>
                <Typography variant="body2" style={{ padding: "16px" }}>
                    La résolution minimale est de 600px.
                </Typography>
                <Typography variant="body2">Vous êtes actuellement à {width}px</Typography>
            </Box>
        </Box>
    );
};

export default SmallScreen;

SmallScreen.propTypes = {
    text: PropTypes.string,
};
