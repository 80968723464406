import { useEffect, useState } from "react";
import { Box, Button, Typography, makeStyles } from "@material-ui/core";
import theme from "../globalTheme";
import imgMillesimePageBg from "../assets/millesimePage/millesime_background.webp";
import Fuse from "fuse.js";

import designationBarsac from "assets/millesimePage/notes/notes_barsac.json";
import designationBordeaux from "assets/millesimePage/notes/notes_bordeaux.json";
import designationGraves from "assets/millesimePage/notes/notes_graves.json";
import designationHautMedoc from "assets/millesimePage/notes/notes_haut-medoc.json";
import designationMoulis from "assets/millesimePage/notes/notes_moulis.json";
import designationPauillac from "assets/millesimePage/notes/notes_pauillac.json";
import designationPessacLeognan from "assets/millesimePage/notes/notes_pessac-leognan.json";
import designationSaintEmilion from "assets/millesimePage/notes/notes_saint-emilion.json";
import designationSaintEstephe from "assets/millesimePage/notes/notes_saint-estephe.json";
import designationSauternes from "assets/millesimePage/notes/notes_sauternes.json";
import designationStJulien from "assets/millesimePage/notes/notes_st-julien.json";
import designationMedoc from "assets/millesimePage/notes/notes_medoc.json";
import designationListrac from "assets/millesimePage/notes/notes_listrac.json";
import designationMargaux from "assets/millesimePage/notes/notes_margaux.json";
import designationStEmilionGrandCru from "assets/millesimePage/notes/notes_saint-emilion-grand-cru.json";
import designationPomerol from "assets/millesimePage/notes/notes_pomerol.json";
import designationFronsac from "assets/millesimePage/notes/notes_fronsac.json";

import imgArrow from "assets/millesimePage/millesimeArrow.svg";
import { Link } from "react-router-dom";
import { useCustomHistory } from "hooks";
import { t, Trans } from "@lingui/macro";

const useStyles = makeStyles((e) => ({
    pageContainer: {
        backgroundImage: `url(${imgMillesimePageBg})`,
        backgroundSize: "cover",  
        maxHeight: "80vh",
        minHeight: "80vh",
        overflowY: "auto",
        margin: "-75px 0 0  0",
        padding: "75px 0 75px 0"
    },
    pageWrapper: {
        padding: "60px 15px",
        maxWidth: "1300px",
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        gap: "2em",
    },
    titleWrapper: {
        width: "100%",
        textAlign: "center",
        color: "#fff",
    },
    rankingsWrapper: {
        background: "#fff",
        borderRadius: "20px",
        padding: "15px", 
        display: "flex",
        flexDirection: "column", 
        gap: "0.5em",
        [theme.breakpoints.up("md")]: {
            padding: "30px", 
            gap: "1em"
        },
    },
    ranking: {
        background: "#DDD2D5",
        color: "#632D3E",
        padding: "15px",
        borderRadius: "15px",
        [theme.breakpoints.up("md")]: {
            padding: "30px", 
        },
        fontWeight: "700"
    },
    collapsibleHead: {
        listStyle: "none",
        userSelect: "none",
        cursor: "pointer",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    collapsibleContent: {
        margin: "1em 0 0 0"
    },
    designationsWrapper: {
        background: "#fff",
        borderRadius: "20px",
        padding: "15px", 
        display: "flex",
        flexDirection: "column", 
        gap: "1em",
        [theme.breakpoints.up("md")]: {
            padding: "30px", 
        },
    },
    designations: {
        display: "flex",
        flexDirection: "column",
        gap: "1em",
    },
    designation: {
        borderRadius: "15px",
    },
    collapsibleHeadClone: {
        background: theme.palette.primary.dark,
        color: theme.palette.secondary.light,
        listStyle: "none",
        outline: "none",
        padding: "15px",
        borderRadius: "15px",
        userSelect: "none",
        cursor: "pointer",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignContent: "center",
        alignItems: "center",
        [theme.breakpoints.up("md")]: {
            padding: "30px", 
        },
        "&::-webkit-details-marker": {
            display: "none"
        }
    },
    collapsibleContentClone: {
        margin: "1em 0 0 0",
        display: "flex",
        flexDirection: "column",
        gap: "1em"
    },
    estate: {
        display: "flex",
        flexDirection: "column",
        gap: "0.75em",
        [theme.breakpoints.up("md")]: {
            gap: "0.25em",
        },
        padding: "30px",
        borderRadius: "10px",
        backgroundColor: theme.palette.secondary.main
    },
    estateHeader: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.up("md")]: {
            flexDirection: "row",
            justifyContent: "space-between",
        },
        userSelect: "none",
        fontFamily: "PlayFair Display",
        fontSize: "20px"
    },
    estateBody: {
        display: "flex",
        flexDirection: "column",
        gap: "0.5em",
    },
    estateInfos: {
        display: "flex",
        flexDirection: "row",
        gap: "0.5em",
        color: theme.palette.primary.dark,
    },
    estateInfo: {
        fontWeight: "700",
        '&:not(:first-of-type)::before': {
            content: '" "',
            display: "inline-block",
            width: "0.5em",
            height: "0.5em",
            lineHeight: "1.5em",
            verticalAlign: "middle",
            margin: "0 0.5em 0 0",
            textAlign: "center",
            borderRadius: "50%",
            backgroundColor: theme.palette.primary.main,
        }
    },
    estateComments: {   
        display: "flex",
        flexDirection: "column",
        gap: "0.5em",
        color: theme.palette.primary.main
    },
    estateQuote: {
        borderLeft: theme.palette.primary.main + " solid 1px",
        padding: "0 0 0 0.5em",
        lineHeight: "20px",
        textAlign: "justify"
    }
}));

const designations = [
    { title: "Médoc", estates : designationMedoc },
    { title: "Haut-Médoc", estates : designationHautMedoc },
    { title: "Saint-Estèphe", estates: designationSaintEstephe }, 
    { title: "Pauillac", estates: designationPauillac },
    { title: "Saint-Julien", estates: designationStJulien }, 
    { title: "Listrac", estates: designationListrac }, 
    { title: "Moulis", estates : designationMoulis },
    { title: "Margaux", estates : designationMargaux },
    { title: "Pessac-Léognan", estates: designationPessacLeognan },
    { title: "Graves", estates : designationGraves },
    { title: "Sauternes", estates: designationSauternes }, 
    { title: "Barsac", estates : designationBarsac },
    { title: "Saint-Émilion Grand Cru", estates: designationStEmilionGrandCru },
    { title: "Saint-Émilion", estates: designationSaintEmilion },
    { title: "Lussac", estates: [] },
    { title: "Pomerol", estates: designationPomerol },
    { title: "Lalande de Pomerol", estates: [] },
    { title: "Fronsac", estates : designationFronsac },
    { title: "Bordeaux", estates : designationBordeaux },
];

const MillesimesPage = (props) => {
    const styles = useStyles(props, theme); 
    const history = useCustomHistory(); 
    const [searchTerm, setSearchTerm] = useState("");
    const [visibleDesignations, setVisibleDesignations] = useState([]);
    const [showMore, setShowMore] = useState(false);
    const resultsPerPage = 10;

    useEffect(() => {
        if (searchTerm.trim() === "") {
            const filteredDesignations = designations.filter(designation => designation.estates.length > 0);
            setVisibleDesignations(filteredDesignations.filter(designation => designation.estates.length > 1).slice(0, resultsPerPage));
            setShowMore(filteredDesignations.some(designation => designation.estates.length > resultsPerPage));
        } else {
            const results = designations.map(designation => {
                const fuse = new Fuse(designation.estates, {
                    keys: [
                        "title"
                    ],
                    includeScore: false,
                    ignoreLocation: true,
                    threshold: 0.2,
                });
                const filteredEstates = fuse.search(searchTerm).map(result => result.item);
                return { ...designation, estates: filteredEstates };
            });
    
            const filteredDesignations = results.filter(designation => designation.estates.length > 0);
            setVisibleDesignations(filteredDesignations.slice(0, resultsPerPage));
            setShowMore(filteredDesignations.some(designation => designation.estates.length > resultsPerPage));
        }
    }, [searchTerm]);

    useEffect(() => {
        const filteredDesignations = designations.filter(designation => designation.estates.length > 0);
        setVisibleDesignations(filteredDesignations.slice(0, resultsPerPage));
        setShowMore(designations.length > resultsPerPage);
    }, []);

    const handleShowMore = () => {
        const lastIndex = visibleDesignations.length;
        const nextResults = designations
            .filter(designation => designation.estates.length > 0) // Filtrer les appellations sans estates
            .slice(lastIndex, lastIndex + resultsPerPage);
        setVisibleDesignations(prevVisibleDesignations => [...prevVisibleDesignations, ...nextResults]);
        setShowMore(designations.filter(designation => designation.estates.length > 0).length > lastIndex + resultsPerPage);
    };

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };

    return (
        <>
            <Box className={ styles.pageContainer }>
                <Box className={styles.pageWrapper}>
                    <Box className={ styles.titleWrapper}>
                        <Trans><Typography variant="h2">Découvrez la note du millésime par Féret</Typography></Trans>
                    </Box>

                    <Box style={{ width: "100%", display:"flex", flexDirection: "row", alignContent: "center", justifyContent: "center" }}>
                        <input
                            type="text"
                            placeholder={ t`Rechercher une note...` }
                            value={searchTerm}
                            onChange={handleChange}
                            style={{ fontFamily: "Playfair Display", backgroundColor: "#E8D4DD", border: "none", outline: "none", borderRadius: "30px", height: "100%", padding: "1em 1.5em", maxWidth: "300px", fontStyle: "normal", fontSize: "16px", "&::placeholder": { color: "#9F8D93" } }}
                        />
                    </Box>

                    <Box className={styles.designations}>
                        {visibleDesignations.map((designation, index) => (
                            <Box className={styles.designation} key={index}>
                                <details>
                                    <summary className={styles.collapsibleHeadClone}>
                                        <Typography variant="h3" style={{ width: "fit-content" }}>{designation.title}</Typography>
                                        <img src={ imgArrow } style={ {  width: "15px", [theme.breakpoints.up("md")]: {  width: "40px" } }} alt="" />
                                    </summary>
                                    <Box className={styles.collapsibleContentClone}>
                                        {designation.estates.map((estate, keyEstate) => (
                                            <Box className={styles.estate} key={keyEstate}>
                                                <Box className={styles.estateHeader}>
                                                    <Typography variant="h3">{estate.title}</Typography>
                                                    <Typography style={{ flexShrink: 0 }} variant="h3">{estate.ranking} / 20</Typography>
                                                </Box>
                                                <Box className={styles.estateBody}>
                                                    <Box className={styles.estateInfos}>
                                                        {estate.classification && (
                                                            <Typography className={styles.estateInfo}>{estate.classification}</Typography>
                                                        )}
                                                        {estate.vintage && (
                                                            <Typography className={styles.estateInfo}>{estate.vintage}</Typography>
                                                        )}
                                                        {estate.color && (
                                                            <Typography className={styles.estateInfo}>{estate.color}</Typography>
                                                        )}
                                                    </Box>
                                                    <Box className={styles.estateComments}>
                                                        {estate.comments.map((comment, keyComment) => (
                                                            <Box className={styles.estateQuote} key={keyComment}>
                                                                {comment.quote}
                                                                <br />
                                                                { comment.quote_it && (
                                                                    <>
                                                                        <i>{ comment.quote_it }</i>
                                                                        <br />
                                                                    </>
                                                                )}
                                                                <Link to={ history.addLocale("/la-lettre-feret#tasters") } style={{ color: theme.palette.primary.main, textDecoration: "none" }}><b>{comment.taster}</b></Link>
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                    <Box>
                                                        {estate.tasting_location && estate.tasting_date && (
                                                            <Typography variant="caption" style={{ color: "#360318" }}>{estate.tasting_location.trim()}, {estate.tasting_date.toLowerCase()}</Typography>
                                                        )}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        ))}
                                    </Box>
                                </details>
                            </Box>
                        ))}
                        {showMore && (
                            <Box>
                                <Button color="primary" variant="contained" size="large" onClick={handleShowMore}>Voir plus</Button>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default MillesimesPage;
