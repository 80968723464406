import PropTypes, { any, bool, elementType, object, string } from "prop-types";
import { Box, makeStyles, Tabs, Tab } from "@material-ui/core";
import { Route, Switch, useRouteMatch } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
    box: (props) => ({
        width: "100%",
        display: "flex",
        flexDirection: "column",
        "& .MuiTabs-flexContainer": {
            justifyContent: props.justify ? props.justify : "flex-start",
            borderBottom: "1px solid",
            borderBottomColor: theme.palette.secondary.main,
            width: "100%",
        },
        "& .MuiIconButton-root": {
            padding: "0",
        },
        "& .MuiTab-textColorPrimary.Mui-selected": {
            color: theme.palette.common.black,
        },
    }),
    tabs: (props) => ({
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: props.justify ? props.justify : "flex-start",
        width: "100%",
        paddingBottom: props.tabsPaddingBottom || "40px",
        "& .MuiTabs-indicator": {
            height: "5px",
            width: "100%",
            borderRadius: "5px 5px 0px 0px",
            backgroundColor: theme.palette.primary.light,
        },
    }),
    tab: (props) => ({
        "& .MuiTab-wrapper": {
            maxWidth: !props.noTabMaxWidth && "166px",
            paddingBottom: "6px",
        },
        textTransform: "none",
        fontSize: "20px",
        fontFamily: "Playfair Display",
        color: theme.palette.secondary.dark,
        padding: "0px",
        margin: "0px 15px",
        minWidth: "fit-content",
        backgroundColor: "white",
        borderRadius: "4px",
        "&:selected": {
            backgroundColor: "white",
        },
    }),
    tabPanelTitle: {
        fontSize: "18px",
        fontWeight: "bold",
        color: theme.palette.secondary.dark,
        marginLeft: theme.spacing(1),
    },
    tabPanelText: {
        color: theme.palette.primary.dark,
        marginBottom: theme.spacing(1),
    },
    tabPanelTitleBox: {
        display: "flex",
        alignItems: "flex-end",
        marginBottom: theme.spacing(1),
    },
    tabPanelBlock: {
        marginBottom: theme.spacing(3),
    },
    tabPanelContainer: (props) => ({
        marginLeft: "20%",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: props.justify || "space-around",
    }),
    arrow_button: {
        width: "3rem",
        "&:hover": {
            backgroundColor: "transparent",
        },
        color: theme.palette.primary.dark,
        "&:disabled": {
            color: theme.palette.secondary.dark,
        },
        "& .MuiSvgIcon-root": {
            width: "20px",
            height: "20px",
        },
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        "aria-controls": `wrapped-tabpanel-${index}`,
    };
}

TabPanel.propTypes = {
    children: PropTypes.any,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const OrgnizationsTabs = (props) => {
    const styles = useStyle(props);
    const { path } = useRouteMatch();

    return (
        <Box className={styles.box} style={props.boxStyle}>
            <Tabs
                TabScrollButtonProps={props.TabScrollButtonProps}
                classes={props.classes}
                className={styles.tabs}
                value={props.currentTab}
                indicatorColor="primary"
                TabIndicatorProps={{ children: <span /> }}
                textColor="primary"
                onChange={props.updateNavigation}
                style={props.styleTabs}
                variant={props.variant || "standard"}
                scrollButtons={props.scrollButtons || "auto"}
            >
                {props.tabs?.map((el, i) => (
                    <Tab
                        onClick={el.onClick}
                        classes={props.tabClasses}
                        key={el?.value}
                        style={props.tabStyle}
                        className={styles.tab}
                        value={el.value}
                        label={el.label}
                        wrapped
                        {...a11yProps(el.value)}
                    />
                ))}
            </Tabs>

            <Switch>
                {props.tabs?.map((el, i) => (
                    <Route key={i} path={path.replace(":organization", el.value)}>
                        <TabPanel
                            value={el.value}
                            style={props.tabPanelStyle}
                            className={styles.tabPanelContainer}
                            index={el.value}
                        >
                            {el.content}
                        </TabPanel>
                    </Route>
                ))}
            </Switch>
        </Box>
    );
};

OrgnizationsTabs.propTypes = {
    tabs: PropTypes.array,
    tabStyle: PropTypes.object,
    tabPanelStyle: PropTypes.object,
    justify: PropTypes.string,
    noTabMaxWidth: PropTypes.bool,
    styleTabs: object,
    style: PropTypes.object,
    boxStyle: object,
    variant: string,
    scrollButtons: string,
    classes: object,
    TabScrollButtonProps: any,
    ScrollButtonComponent: elementType,
    tabClasses: object,
    router: bool,
    currentTab: PropTypes.any,
    updateNavigation: PropTypes.any,
};

export default OrgnizationsTabs;
