import { useEffect, useRef, useState } from "react";

import { makeStyles, TextField, InputAdornment, Typography, Divider, Box } from "@material-ui/core";
import PropTypes, { bool, func, node } from "prop-types";
import CheckIcon from "@material-ui/icons/Check";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AdditionalInformationTooltip from "../Tooltips/AdditionalInformationTooltip";
import ErrorIcon from "../../assets/Error.svg";
import { getSortedValues } from "../../helpers/helpers";
import searchIcon from "../../assets/SubscriptionPage/searchIcon.svg";
import { useEnumValues } from "hooks/useEnumValues";

const useStyles = makeStyles((theme) => ({
    option: {
        fontSize: "14px",
        color: theme.palette.primary.dark,
        "&:hover": {
            backgroundColor: theme.palette.dashboard.background,
        },
        "&.MuiListItem-root.Mui-selected": {
            backgroundColor: theme.palette.dashboard.background,
        },
        // eslint-disable-next-line quotes
        '&[data-focus="true"]': {
            backgroundColor: theme.palette.dashboard.background,
        },
    },
    paper: {
        overflow: "hidden",
        "& ::-webkit-scrollbar": {
            width: "8px",
        },
        "& ::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            paddingRight: "8px",
        },
        "& ::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "20px",
        },
    },
    autoRoot: {
        "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
            padding: 0,
        },
    },
    customMenuPopover: {
        "& .MuiMenu-paper": {
            maxHeight: "200px",
        },
        backgroundColor: "blue",

        "& ::-webkit-scrollbar": {
            width: "8px",
        },
        "& ::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            paddingRight: "8px",
        },
        "& ::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "20px",
        },
    },
    textFieldRoot: {
        "& > div.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
            paddingRight: "9px",
            "& button": {
                order: 3, // order 3 means the search icon will appear after the clear icon which has an order of 2
            },
            "& > div.MuiAutocomplete-endAdornment": {
                position: "relative", // default was absolute. we make it relative so that it is now within the flow of the other two elements
                order: 2,
            },
        },
    },
    menuItems: {
        fontSize: "14px",
        color: theme.palette.primary.dark,
        "&:hover": {
            backgroundColor: theme.palette.dashboard.background,
        },
        "&.MuiListItem-root.Mui-selected": {
            backgroundColor: theme.palette.dashboard.background,
        },
    },
    listSubheader: {
        fontSize: "14px",
        color: theme.palette.primary.light,
    },
    root: (props) => {
        const getBorderRadius = () => {
            if (props.table) {
                return "0px";
            } else if (props.borderRadius) {
                return props.borderRadius;
            } else {
                return "4px";
            }
        };
        return {
            "&.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'], .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
                paddingRight: "10px !important",
            },
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
                borderRadius: getBorderRadius(),
            },
            "& .MuiSvgIcon-root": {
                width: "15px",
                color: theme.palette.secondary.main,
            },
            "& .MuiAutocomplete-clearIndicator": {
                marginRight: props.error && "0px",
                color: theme.palette.secondary.dark,
            },
            "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input": {
                padding: "0px",
                paddingLeft: props.noClear ? "8px" : "16px",
            },
            "& .MuiOutlinedInput-root": {
                fontSize: "14px",
                color: theme.palette.primary.dark,
                borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
                backgroundColor: "white",
                height: "34px",
                "&.Mui-disabled": {
                    color: theme.palette.secondary.dark,
                    backgroundColor: theme.palette.disabled.main,
                },
                "&:hover fieldset": {
                    borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
                },
                "&.Mui-focused fieldset": {
                    border: "1px solid",
                    borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
                },
            },
            "& .MuiFormLabel-root": {
                fontSize: "14px",
                color: props.error ? theme.palette.error.main : theme.palette.secondary.dark,
            },
            "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-endAdornment": {
                right: props.correctValue || props.error || props.search ? "32px" : "9px",
                top: 5,
                backgroundColor: props.disabled ? theme.palette.dashboard.background : "white",
            },
            "& .MuiInputAdornment-positionEnd": {
                width: "20px",
            },
        };
    },
    boxContainer: {
        position: "relative",
    },
    checkIcon: {
        width: "20px",
        fill: theme.palette.success.main,
    },
    falseIcon: {
        width: "20px",
        fill: theme.palette.error.main,
    },
    toolTipBox: {
        position: "absolute",
        right: "8px",
        top: "-8px",
        zIndex: 2,
        cursor: "default",
    },
    errorText: {
        fontSize: "12px",
        color: theme.palette.error.main,
        marginLeft: "8px",
    },
}));

const AutocompleteSelectInput = (props) => {
    const styles = useStyles(props);
    const ref = useRef();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (props.autoFocus) {
            ref.current?.click();
            setOpen(true);
        }
    }, [props.autoFocus]);

    const [textfieldValue, setTextfieldValue] = useState(props.value);
    const enumValues = useEnumValues(props.enumField);
    const [focused, setFocused] = useState(false);
    let filteredEnumsValues;

    const getExcludedValues = (allEnums) => {
        if (allEnums) {
            let arr = allEnums;
            if (props.excluded) {
                arr = allEnums?.filter((el, i) => props.value === el || !props.excluded?.includes(el));
            }
            if (props.sorted) {
                arr = getSortedValues(arr, true);
            }
            filteredEnumsValues = arr;
        }
    };

    getExcludedValues(props.filteredEnums || enumValues);

    return (
        <Box className={styles.boxContainer} style={{ ...props.boxStyle }}>
            {props.tooltipTitle && (
                <Box className={styles.toolTipBox}>
                    <AdditionalInformationTooltip color="primary" title={props.tooltipTitle} arrow />
                </Box>
            )}
            <Autocomplete
                options={props.options || filteredEnumsValues || []}
                className={styles.root}
                classes={{
                    option: styles.option,
                    paper: styles.paper,
                    popper: styles.popper,
                    root: styles.autoRoot,
                }}
                ListboxProps={{
                    style: { maxHeight: "180px", overflowX: "hidden" },
                }}
                filterOptions={props.filterOptions}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                getOptionLabel={props.getOptionLabel ? props.getOptionLabel : (option) => option}
                getOptionSelected={props.getOptionSelected}
                value={props.value || ""}
                onChange={(e1, e2) => {
                    return props.onChange(e2);
                }}
                onFocus={() => {
                    if (!focused) {
                        setFocused(true);
                    }
                    if (props.onFocus) {
                        props.onFocus();
                    }
                }}
                onBlur={(e) => {
                    setFocused(false);
                    if (props.onBlur) {
                        props.onBlur(e);
                    }
                }}
                disableClearable={props.noClear}
                disabled={props.disabled}
                noOptionsText={
                    props.optionsText ? (
                        props.optionsText
                    ) : (
                        <Typography variant="h6" className={styles.noOptionsText}>
                            Il n&apos; y a plus d&apos; options disponibles.
                        </Typography>
                    )
                }
                ref={ref}
                renderOption={props.renderOption}
                renderInput={(params) => {
                    return (
                        <TextField
                            size="small"
                            // error={props.error}
                            required={props.required}
                            onBlur={() => {
                                setTextfieldValue("");
                            }}
                            placeholder={props.placeholder}
                            {...params}
                            value={textfieldValue}
                            onChange={(e, e2) => {
                                setTextfieldValue(e?.target?.target);
                            }}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {params.InputProps.endAdornment}
                                        {props.search && <img style={{ width: "18px" }} src={searchIcon} />}
                                        {props.correctValue && <CheckIcon className={styles.checkIcon} />}
                                        {props.error && <img src={ErrorIcon} className={styles.falseIcon} />}
                                        {props.mesure && (
                                            <Box style={{ display: "flex" }}>
                                                <Divider className={styles.divider} orientation="vertical" flexItem />
                                                <Typography variant="h6" className={styles.mesure}>
                                                    {props.mesure}
                                                </Typography>
                                            </Box>
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                            label={props.label}
                            variant="outlined"
                        />
                    );
                }}
            />
            {props.error ? (
                <Typography variant="h6" className={styles.errorText}>
                    {" "}
                    {props.error}{" "}
                </Typography>
            ) : (
                ""
            )}
        </Box>
    );
};

AutocompleteSelectInput.propTypes = {
    onChange: PropTypes.func,
    onBlur: PropTypes.func,

    disabled: PropTypes.bool,
    error: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,

    style: PropTypes.object,
    boxStyle: PropTypes.object,

    value: PropTypes.string,
    onFocus: PropTypes.func,

    correctValue: PropTypes.bool,
    mesure: PropTypes.oneOf(["An(s)", "ha", "Pied/Ha", "%", "m²"]),

    enumField: PropTypes.string,
    filteredEnums: PropTypes.any,
    excluded: PropTypes.any,
    sorted: PropTypes.bool,

    tooltipTitle: PropTypes.string,
    noClear: PropTypes.bool,
    onClear: PropTypes.any,
    borderRadius: PropTypes.string,
    autoFocus: PropTypes.bool,
    options: PropTypes.array,
    renderOption: PropTypes.func,
    search: bool,
    optionsText: node,

    getOptionLabel: func,
    filterOptions: func,
    getOptionSelected: func,
};

AutocompleteSelectInput.defaultProps = {
    onChange: () => {},
    onBlur: () => {},
};

export default AutocompleteSelectInput;
