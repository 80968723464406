import { useSelector } from "react-redux";
import { useState } from "react";

import { GetExternalServiceRequestsDocument, useCreateServiceRequestMutation } from "generated/graphql";
import { getEntityId } from "helpers/helpers";
import { toast } from "react-toastify";

/**
 * __useCreateService__
 *
 * First call with the parameters that fit your needs (depending on `FERET_SERVICES`).
 * Returns an object with the following properties:
 * - `pending`: indicate if the mutation is running or not
 * - `paymentMethod`: The payment method
 * - `setPaymentMethod`: Function that mutate the payment method
 * - `createService`: the GraphQL mutation that creates the service
 *
 * @example
 * const {
 *   pending,
 *   paymentMethod,
 *   setPaymentMethod,
 *   createService
 * } = useCreateService()
 */
export const useCreateService = () => {
    const wineEntityId = useSelector((state) => getEntityId(state));
    const [pending, setPending] = useState(false);
    const [error, setError] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState("");

    const [createServiceRequest] = useCreateServiceRequestMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: GetExternalServiceRequestsDocument,
                variables: { wineEntityId },
            },
        ],
    });

    const createService = async (inputParams) => {
        setPending(true);
        try {
            const input = { wineEntityId, ...inputParams };
            if (paymentMethod) input.paymentMethod = paymentMethod;
            const res = await createServiceRequest({ variables: { input } });
            const url = res?.data?.CreateServiceRequest;
            if (url) window.open(res?.data?.CreateServiceRequest, "_blank");
        } catch (e) {
            setError(e);
            toast.error(
                "Une erreur empêche l'enregistrement du service demandé, veuillez réessayer plus tard ou contactez nous: support@feret.com"
            );
        }
        setPending(false);
    };

    return { pending, error, paymentMethod, setPaymentMethod, createService };
};
