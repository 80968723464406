import { t, Trans } from "@lingui/macro";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { array, bool, func, number, object, string } from "prop-types";
import { useSelector } from "react-redux";

import AutocompleteChipsInput from "Components/Inputs/AutocompleteChipsInput";
import SimpleInputUncontrolled from "Components/Inputs/SimpleInputUncontrolled";
import ControlledSwitch from "Components/Switch/ControlledSwitch";
import theme from "globalTheme";
import { getUserLocale, useWindowDimensions } from "helpers/helpers";
import { useEnumValues } from "hooks/useEnumValues";
import { SearchModal } from "Search/modal/SearchModal";
import RangeSliderCustomSearch from "Search/RangeSliderCustomSearch";
import AutocompleteCommune from "../AutocompleteCommune";
import ModalSectionTitle from "../ui/ModalSectionTitle";
import SearchCheckbox from "./SearchCheckbox";

const useStyles = makeStyles(() => ({
    containerBoxContent: {
        maxHeight: "calc(90vh - 150px - 95px)",

        [theme.breakpoints.up("sm")]: {
            maxHeight: "calc(90vh - 118px - 95px)",
        },

        [theme.breakpoints.up("md")]: {
            maxHeight: "calc(90vh - 78px - 95px)",
        },
    },
    filterSearch: {
        gridColumn: "1 / span 12",
    },
    filterSelect: {
        gridColumn: "1 / span 12",
        marginTop: 16,

        "& .MuiOutlinedInput-root": {
            borderRadius: 53,
        },
    },
    badgeBox: {
        gridColumn: "1 / span 12",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        alignItems: "center",
        marginLeft: 16,
        marginTop: 13,
        marginBottom: 27,
    },
    color: {
        display: "flex",
        flexDirection: "column",
        gridColumn: "1 /span 12",

        [theme.breakpoints.up("sm")]: {
            gridColumn: "1 / span 6",
        },
    },
    sweetness: {
        display: "flex",
        flexDirection: "column",
        gridColumn: "1 /span 12",
        marginTop: 7,

        [theme.breakpoints.up("sm")]: {
            gridColumn: "7 / span 6",
            marginTop: 0,
        },
    },
}));

const SearchEstateModal = (props) => {
    const styles = useStyles(props);
    const { width } = useWindowDimensions();

    const appellationLabels = useEnumValues("APPELLATION_LABEL");
    const classificationOrganisms = useEnumValues("CLASSIFICATION_ORGANISM");

    const locale = useSelector(getUserLocale);

    return (
        <SearchModal
            totalCount={props.similar ? 0 : props.totalCount}
            width={width > 600 ? "38rem" : "100%"}
            open={props?.isOpen}
            onClose={() => {
                props.nextStep && props.nextStep();
                props.onClose();
            }}
            onCloseCross={() => props.onCloseCross()}
            titlePrimary={props?.titlePrimary}
            titleSecondary={`/ ${props?.titleSecondary}`}
            searchResult={props?.searchResult}
            CTAPrimaryClick={() => {
                props.clearFilters();
                props.setLocalisationChecked(false);
            }}
            CTAPrimaryDisabled={props.filtersCount <= 0}
            boxContentClassname={styles.containerBoxContent}
        >
            {/* ESTATE */}
            <ModalSectionTitle title={t`La propriété`} />

            <Box className={styles.filterSearch}>
                <SimpleInputUncontrolled
                    search
                    size="small"
                    name="search"
                    placeholder={t`Chercher une propriété...`}
                    variant="outlined"
                    onBlur={(e) => {
                        if (e?.target?.value !== undefined) {
                            props.setFilter("searchText", e?.target?.value);
                        }
                    }}
                    boxStyle={{
                        backgroundColor: theme.palette.common.white,
                        borderRadius: "53px",
                        border: "none",
                    }}
                    bgTransparent
                    borderRadius={53}
                    iconPosition="start"
                    inModal
                    defaultValue={props.filters?.["searchText"]}
                />
            </Box>

            <AutocompleteChipsInput
                placeholder={t`Choisir...`}
                class={styles.filterSelect}
                label={t`Label`}
                enumField="LABEL_CERTIFICATION_ESTATE"
                value={props?.filters?.labels}
                onChange={(e1, e2) => props?.setFilter("labels", e2)}
                labelColor={theme.palette.primary.dark}
                labelPaddingLeft="16px"
                labelPaddingTop="1px"
                placeholderPaddingLeft="20px"
                inputPaddingLeft="20px"
                locale={locale}
                uppercase
                popupIcon
            />

            <AutocompleteChipsInput
                placeholder={t`Choisir...`}
                class={styles.filterSelect}
                label={t`Appellation`}
                options={appellationLabels}
                uppercase
                value={props?.filters?.appellations}
                onChange={(e1, e2) => props?.setFilter("appellations", e2)}
                labelColor={theme.palette.primary.dark}
                labelPaddingLeft="16px"
                labelPaddingTop="1px"
                placeholderPaddingLeft="20px"
                inputPaddingLeft="20px"
                popupIcon
                sortAlphabetical
            />

            <AutocompleteChipsInput
                placeholder={t`Choisir...`}
                class={styles.filterSelect}
                label={t`Classement`}
                options={classificationOrganisms}
                uppercase
                value={props?.filters?.classifications}
                onChange={(e1, e2) => props?.setFilter("classifications", e2)}
                labelColor={theme.palette.primary.dark}
                labelPaddingLeft="16px"
                labelPaddingTop="1px"
                placeholderPaddingLeft="20px"
                inputPaddingLeft="20px"
                popupIcon
            />

            <Box className={styles.filterSelect} style={{ justifyContent: "center", alignItems: "center" }}>
                <RangeSliderCustomSearch
                    value={props?.filters?.surface}
                    initialMinimumValue={1}
                    onChange={(e, v) => props?.setFilter("surface", v)}
                    labelEndTypeSingular="an"
                    labelMiddleType={t`à`}
                    labelEndType=""
                    labelAll={t`toutes les surfaces`}
                    labelType={t`Surface (ha)`}
                    max={400}
                    min={0}
                    defaultValue={[0, 400]}
                    colorThumb={theme.palette.primary.dark}
                    trackColor={theme.palette.primary.main}
                    railColor={theme.palette.secondary.main}
                    labelStyle={{
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                    }}
                    resetValues
                    typoMarginLeft="24px"
                    width="92.25%"
                    alignSelf="center"
                />
            </Box>

            <Box
                className={styles.badgeBox}
                style={{ flexDirection: width > 500 ? "row" : "column", alignItems: "flex-start" }}
            >
                {/* <Box style={{ display: "flex", alignItems: "center", marginRight: width > 500 ? 50 : 0 }}>
                    <BigSwitch
                        checked={props?.filters?.badge}
                        onChange={() => props?.setFilter("badge", !props?.filters?.badge)}
                    />
                    <Typography
                        variant="h6"
                        style={{ marginLeft: 7, color: theme.palette.primary.dark, cursor: "pointer" }}
                        onClick={() => props?.setFilter("badge", !props?.filters?.badge)}
                    >
                        <Trans>Propriétés certifiées par le Badge Féret</Trans>
                    </Typography>
                </Box> */}

                <Box style={{ display: "flex", alignItems: "center" }}>
                    <SearchCheckbox
                        setFilter={(filtername, v) => props.setFilter(filtername, v)}
                        options={[t`Vente en ligne`]}
                        styles={styles}
                        filterName="tourism"
                        filters={props.filters}
                    />
                </Box>
            </Box>

            {/* PRODUCTION */}
            <ModalSectionTitle title={t`Production`} />

            <Box className={styles.color}>
                <Typography variant="h6" style={{ color: theme.palette.primary.dark }}>
                    <Trans>Couleur des vins produits</Trans>
                </Typography>
                <SearchCheckbox
                    setFilter={(filtername, v) => props.setFilter(filtername, v)}
                    options={[t`Rouge`, t`Blanc`, t`Rosé`]}
                    styles={styles}
                    filterName="colors"
                    filters={props.filters}
                />
            </Box>

            <Box className={styles.sweetness}>
                <Typography variant="h6" style={{ color: theme.palette.primary.dark }}>
                    <Trans>Sucrosité des vins produits</Trans>
                </Typography>
                <SearchCheckbox
                    setFilter={(filtername, v) => props.setFilter(filtername, v)}
                    options={[t`Sec`, t`Demi-sec`, t`Moelleux`, t`Liquoreux`]}
                    styles={styles}
                    filterName="sweetness"
                    filters={props.filters}
                />
            </Box>

            <AutocompleteChipsInput
                placeholder={t`Choisir...`}
                class={styles.filterSelect}
                style={{ marginBottom: 27 }}
                label={t`Appellation des vins produits`}
                options={appellationLabels}
                uppercase
                value={props?.filters?.appellationsWineProducts}
                onChange={(e1, e2) => props?.setFilter("appellationsWineProducts", e2)}
                labelColor={theme.palette.primary.dark}
                labelPaddingLeft="16px"
                labelPaddingTop="1px"
                placeholderPaddingLeft="20px"
                inputPaddingLeft="20px"
                popupIcon
                sortAlphabetical
            />

            {/* LOCALISATION */}
            <ModalSectionTitle title={t`Localisation`} />

            <Box style={{ display: "flex", alignItems: "center", gridColumn: "1 /span 12" }}>
                <ControlledSwitch
                    leftLabel={t`Par sous-région`}
                    rightLabel={t`Par commune`}
                    switchCheck={props.localisationChecked}
                    handleSwitchChange={() => {
                        const newLocalisationChecked = !props.localisationChecked;
                        props.setFilter(newLocalisationChecked ? "subRegions" : "communes", []);
                        props.setLocalisationChecked(!props.localisationChecked);
                    }}
                    switchPosition={{ top: "0px", left: "0px" }}
                />
            </Box>

            {!props.localisationChecked && (
                <AutocompleteChipsInput
                    placeholder={t`Choisir...`}
                    class={styles.filterSelect}
                    style={{ marginBottom: 27 }}
                    label={t`Sous-région`}
                    options={[
                        "Blaye et Bourg",
                        "Bordeaux et Bordeaux supérieur",
                        "Entre-deux-mers",
                        "Graves et Sauternais",
                        "Libournais",
                        "Médoc",
                    ]}
                    uppercase
                    value={props?.filters?.subRegions}
                    onChange={(e1, e2) => props?.setFilter("subRegions", e2)}
                    labelColor={theme.palette.primary.dark}
                    labelPaddingLeft="16px"
                    labelPaddingTop="1px"
                    placeholderPaddingLeft="20px"
                    inputPaddingLeft="20px"
                    popupIcon
                />
            )}

            {props.localisationChecked && (
                <AutocompleteCommune
                    placeholder={t`Choisir...`}
                    value={props.filters?.communes}
                    setFilter={(e) => props?.setFilter("communes", e)}
                    label={t`Commune`}
                />
                // <AutocompleteChipsInput placeholder={t`Choisir...`}
                //     class={styles.filterSelect}
                //     style={{ marginBottom: 27 }}
                //     label="Commune"
                //     options={communesKeys}
                //     uppercase
                //     getOptionSelected={(e1, e2) => {
                //         return e1.INSEEcode === e2;
                //     }}
                //     value={props.filters?.communes}
                //     onChange={(e1, e2) => {
                //         props?.setFilter("communes", e2);
                //     }}
                //     labelColor={theme.palette.primary.dark}
                //     labelPaddingLeft="16px"
                //     labelPaddingTop="1px"
                //     placeholderPaddingLeft="20px"
                //     inputPaddingLeft="20px"
                //     popupIcon
                //     getOptionLabel={(e1, e2) => {
                //         return communes[e1].displayName + " (" + communes[e1].postalCode + ")";
                //     }}
                // />
            )}

            {/* TOURISM */}
            <ModalSectionTitle title={t`Tourisme`} />

            <Box style={{ gridColumn: "1 /span 12" }}>
                <SearchCheckbox
                    setFilter={(filtername, v) => props.setFilter(filtername, v)}
                    options={[t`Actuellement ouvert`, t`Activités œnotouristiques proposées`]}
                    styles={styles}
                    filterName="tourism"
                    filters={props.filters}
                />
            </Box>
        </SearchModal>
    );
};

SearchEstateModal.propTypes = {
    isOpen: bool,
    similar: bool,
    onClose: func,
    titlePrimary: string,
    titleSecondary: string,
    filters: object,
    setFilter: func,
    searchResult: array,
    filtersCount: number,
    totalCount: number,
    clearFilters: func,
    nextStep: func,
    onCloseCross: func,
    localisationChecked: bool,
    setLocalisationChecked: func,
};

export default SearchEstateModal;
