import { Box, Typography, makeStyles, IconButton } from "@material-ui/core";
import PropTypes from "prop-types";
import theme from "../../globalTheme";
import ControlPointIcon from "@material-ui/icons/ControlPoint";

const useStyle = makeStyles(() => ({
    container: (props) => {
        return {
            alignItems: "center",
            display: "flex",
            marginTop: "6px",
            cursor: !props.disabled && "pointer",
        };
    },
    icon: (props) => ({
        color: props.disabled ? theme.palette.secondary.main : theme.palette.primary.light,
        fontSize: "20px",
    }),
    iconButton: {
        padding: "0px",
    },
    typo: (props) => ({
        color: props.disabled ? theme.palette.secondary.main : theme.palette.primary.light,
        cursor: !props.disabled && "pointer",
        fontWeight: "bold",
        marginLeft: "4px",
    }),
}));

const AddElementButton = (props) => {
    const styles = useStyle(props);
    return (
        <Box
            style={props.style}
            className={[styles.container, props.classBox].join(" ")}
            onClick={props.disabled ? null : props.onClick}
        >
            <ControlPointIcon className={styles.icon} style={props.buttonStyle} />
            <Typography style={props.typoStyle} variant="h6" className={styles.typo}>
                {props.children}
            </Typography>
        </Box>
    );
};

export default AddElementButton;

AddElementButton.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.any,
    style: PropTypes.object,
    typoStyle: PropTypes.object,
    disabled: PropTypes.bool,
    buttonStyle: PropTypes.bool,
    classBox: PropTypes.object,
};
