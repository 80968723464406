import { useState } from "react";

const useSubscriptionScratch = (initialState) => {
    const [subscription, setSubscription] = useState(initialState);

    const setSubscriptionValue = (field, value) => {
        const subscriptionCopy = { ...subscription };
        subscriptionCopy[field] = value;
        setSubscription(() => subscriptionCopy);
    };

    return [subscription, setSubscriptionValue, setSubscription];
};

export default useSubscriptionScratch;