import { Box, createStyles, makeStyles, Typography } from "@material-ui/core";
import React from "react";

interface FeretSummaryElementProps {
    icon: string,
    title: string,
    subtitle?: any,
    subtitle2?: any,
    subtitle3?: any,
    style?: object,
    typoStyle?: object 
}
const useStyles = makeStyles((theme) => createStyles({
    mainBox: {
        width: "100%",
        display: "flex",
        gap: "8px",
        "&:not(:last-child)": {
            marginBottom: "1rem"
        }
    },
    typoContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
    },
    title: {
        margin: "0",
    },
    subtitle: {
        display: "flex",
        alignItems: "center",
        color: theme.palette.primary.dark,
    },
    imageContainer: {
        width: "48px",
        height: "48px",
    },
    media: {
        width: "100%",
        [theme.breakpoints.only("xs")]: {
            width: "42px",
        },
    },
}));

const FeretSummaryElement = (props : FeretSummaryElementProps) => {
    const styles = useStyles();

    if (!props.subtitle || props.subtitle.length === 0) {
        return null;
    } else {
        return (
            <Box className={styles.mainBox} style={props.style}>
                <Box className={styles.imageContainer}>
                    <img className={styles.media} src={props.icon} />
                </Box>
                <Box className={styles.typoContainer}>
                    <Typography variant="h4" className={styles.title}>
                        {props.title}
                    </Typography>
                    <Typography variant="h6" style={ props.typoStyle ?? { lineHeight:"1" }} className={styles.subtitle}>
                        {props.subtitle}
                    </Typography>
                    {props.subtitle2 &&  
                    <Typography style={{lineHeight:"1"}} variant="h6" className={styles.subtitle}>
                        {props.subtitle2}
                    </Typography> }
                    {props.subtitle3 &&  
                    <Typography style={{lineHeight:"1"}} variant="h6" className={styles.subtitle}>
                        {props.subtitle3}
                    </Typography> }
                </Box>
            </Box>
        );
    }
};

export default FeretSummaryElement;
