import { i18n } from "@lingui/core";
import { en, cs, fr, de, es, it, nl } from "make-plural/plurals";

export const locales = {
    fr: "Français",
    en: "Anglais",
    de: "Deutsch",
    es: "Español",
    it: "Italiano",
    nl: "Nederlands",
};
export const defaultLocale = "fr";

i18n.loadLocaleData({
    fr: { plurals: fr },
    en: { plurals: en },
    de: { plurals: de },
    es: { plurals: es },
    it: { plurals: it },
    nl: { plurals: nl },
    cs: { plurals: cs },
});

/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 */
export async function dynamicActivate(locale) {
    const { messages } = await import(`./locales/${locale}/messages`);
    i18n.load(locale, messages);
    i18n.activate(locale);
}
