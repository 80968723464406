import { useCallback } from "react";
import PropTypes from "prop-types";
import { Autocomplete } from "@material-ui/lab";
import { debounce } from "lodash";
import { useAutocompleteSearchLazyQuery } from "../../generated/graphql";
import { InputAdornment, makeStyles, TextField, Typography } from "@material-ui/core";
import theme from "../../globalTheme";
import searchIcon from "../../assets/SubscriptionPage/searchIcon.svg";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { t } from "@lingui/macro";
import { urlFormatted } from "helpers/helpers";
import { useCustomHistory } from "hooks";

const useStyles = makeStyles(() => ({
    searchNoResultTypo: {
        fontStyle: "italic",
        fontSize: "12px",
        color: theme.palette.secondary.dark,
    },
    searchResultTypo: {
        color: theme.palette.primary.dark,
        fontWeight: 400,
        marginBottom: 0,
    },
    groupLabel: {
        ...theme.typography.subtitle1,
        color: theme.palette.secondary.dark,
        textTransform: "capitalize",
        margin: "4px 0px",
    },
    groupUl: {
        paddingLeft: 16,

        "& .MuiAutocomplete-option": {
            paddingLeft: 0,
        },
    },
    option: {
        padding: 0,
        paddingLeft: 0,
        marginBottom: 4,
        minHeight: "auto",

        '&[aria-selected="true"]': {
            backgroundColor: theme.palette.grey.A200,
            borderColor: "transparent",
        },

        "&:hover": {
            backgroundColor: theme.palette.background.card,
        },
    },
    searchIcon: (props) => ({
        width: "18px",
        marginLeft: props.border ? 16 : 9,
        cursor: props.iconLink ? "pointer" : "default",
    }),
    textField: (props) => ({
        border: props.border ? `0.4px solid ${theme.palette.secondary.main}` : "none",
        "& input::placeholder": {
            color: theme.palette.secondary.dark,
            fontStyle: "italic",
        },
    }),
}));

const AutocompleteSearch = (props) => {
    const styles = useStyles(props);
    const history = useCustomHistory();

    const [search, { data }] = useAutocompleteSearchLazyQuery();
    const debouncer = useCallback(debounce(search, 100), []);

    return (
        <form style={{ display: "contents" }}>
            <Autocomplete
                required
                // className={styles.autocomplete}
                // classes={{ option: styles.option }}
                size="small"
                getOptionLabel={(option) => option?.title}
                renderOption={(e, { inputValue }) => {
                    const matches = match(e.title, inputValue, { insideWords: true, findAllOccurrences: true });
                    const parts = parse(e.title, matches);

                    return (
                        <>
                            <Typography variant="h6" className={styles.searchResultTypo}>
                                {parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                        {part.text}
                                    </span>
                                ))}

                                <span style={{ fontStyle: "italic" }}>
                                    {e?.description ? " (" + e?.description + ")" : ""}
                                </span>
                            </Typography>
                        </>
                    );
                }}
                freeSolo
                onKeyDown={(event) => {
                    if (props.onEnterPressed && event.key === "Enter") {
                        event.preventDefault();
                        props.onEnterPressed(event);
                    }
                }}
                groupBy={(e) => {
                    if (e.kind === "wine") return t`Vin`;
                    if (e.kind === "activity") return t`Activité`;
                    if (e.kind === "wineEntity") return t`Propriété`;
                }}
                // filterOptions={(options) => options}
                onChange={(_, selected) => {
                    const {
                        wineEntityShortId,
                        wineEntityName,
                        wineShortId,
                        activityShortId,
                        description,
                        title,
                        kind,
                    } = selected;
                    if (kind === "wine") {
                        history.push(
                            `/${urlFormatted(wineEntityShortId, wineEntityName)}/vins/${urlFormatted(
                                wineShortId,
                                title
                            )}`
                        );
                    }
                    if (kind === "activity") {
                        history.push(
                            `/${urlFormatted(wineEntityShortId, description)}/activites/${urlFormatted(
                                activityShortId,
                                title
                            )}`
                        );
                    } else if (kind === "wineEntity") {
                        history.push(`/${urlFormatted(wineEntityShortId, title)}`);
                    }
                }}
                options={data?.AutocompleteSearch ?? []}
                noOptionsText={
                    <Typography className={styles.searchNoResultTypo}>Aucune correspondance trouvée</Typography>
                }
                // // inputValue={searchText}
                onHighlightChange={(events, value, reason) => {
                    if (reason === "keyboard") {
                        props.onChange(value?.title);
                    }
                }}
                onInputChange={(event, value, reason) => {
                    props.onChange(value);
                    // setSearchText(value);
                    debouncer({
                        variables: { searchText: value },
                    });
                }}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                disableUnderline: true,
                                startAdornment: props.startAdornment && (
                                    <InputAdornment position="start">
                                        <img
                                            className={styles.searchIcon}
                                            src={props.icon ?? searchIcon}
                                            onClick={
                                                props.iconLink
                                                    ? () => {
                                                          history.push(props?.iconLink);
                                                      }
                                                    : () => {}
                                            }
                                        />
                                    </InputAdornment>
                                ),
                            }}
                            classes={{ root: props.inputClassName ?? styles.textField }}
                            placeholder={props?.placeholder}
                            style={props.inputStyle}
                        />
                    );
                }}
                classes={{
                    root: props.autocompleteContainerClassName,
                    paper: props?.paperClassname,
                    listbox: styles.listbox,
                    groupLabel: styles.groupLabel,
                    option: styles.option,
                    groupUl: styles.groupUl,
                }}
                disableClearable
                debug
            />
        </form>
    );
};

AutocompleteSearch.propTypes = {
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    startAdornment: PropTypes.bool,
    border: PropTypes.bool,
    inputStyle: PropTypes.object,
    paperClassname: PropTypes.string,
    placeholder: PropTypes.string,
    onEnterPressed: PropTypes.func,
    autocompleteContainerClassName: PropTypes.object,
    inputClassName: PropTypes.string,
    icon: PropTypes.any,
    onChangeTest: PropTypes.func,
    iconLink: PropTypes.string,
};

export default AutocompleteSearch;
