import { Trans } from "@lingui/macro";
import { Box, Link, makeStyles, Typography } from "@material-ui/core";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import cn from "clsx";
import { DisplayPackaging } from "FrontVintage/Components/DisplayPackaging";
import PropTypes from "prop-types";
import { useState } from "react";
import ReactApexCharts from "react-apexcharts";

import arrow from "../../assets/Wine/arrow.png";
import Arrow from "../../Components/Arrow/Arrow";
import Loading from "../../Components/Loader/Loading";
import { useFrontVintageMarketingQuery } from "../../generated/graphql";
import theme from "../../globalTheme";
import getConnectors, { useWindowDimensions } from "../../helpers/helpers";
import PackagingsPopin from "./PackagingsPopin";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
        gap: "32px",
        [theme.breakpoints.only("xs")]: {
            gap: "16px",
        },
    },
    priceBlock: {
        width: "50%",
        [theme.breakpoints.down("md")]: {
            width: "60%",
        },
        [theme.breakpoints.down("sm")]: {
            width: "80%",
        },
    },
    blockContainer: {
        backgroundColor: "white",
        border: "0.4px solid",
        borderRadius: "5px",
        borderColor: theme.palette.secondary.dark,
        position: "relative",
        padding: theme.spacing(2),
        paddingTop: 0,
        [theme.breakpoints.down("xs")]: {
            minWidth: "0px",
            width: "100%",
        },
    },
    typoContainer: {
        position: "relative",
        top: "-12px",
        left: "0px",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.only("xs")]: {
            top: -10,
        },
    },
    title: {
        ...theme.front.typography.h6.dark_b,
        zIndex: 2,
        backgroundColor: theme.palette.common.white,
        padding: "0px 2px",
    },
    marketingTypo: {
        color: theme.palette.primary.light,
        fontSize: "18px",
        marginRight: "4px",
        [theme.breakpoints.only("xs")]: {
            fontSize: "12px",
        },
    },
    marketingContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    marketingIcon: {
        width: "13px",
        height: "13px",
        marginRight: "4px",
    },
    marketingTypo2: {
        color: theme.palette.primary.light,
        fontSize: "18px",
        [theme.breakpoints.only("xs")]: {
            fontSize: "12px",
        },
    },
    flexCenter: {
        display: "flex",
        alignItems: "center",
    },
    pricesContainer: {
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        top: "-12px",
        left: "10px",
        [theme.breakpoints.only("xs")]: {
            top: -9,
        },
    },
    priceTypo: {
        fontFamily: "Mansalva",
    },
    exporation__box__typoDetails: {
        color: theme.palette.primary.light,
        fontSize: "18px",
        textAlign: "center",
        [theme.breakpoints.only("xs")]: {
            fontSize: "12px",
            padding: 0,
        },
    },
    exporation__box__typoPrim: {
        textAlign: "center",
        [theme.breakpoints.only("xs")]: {
            padding: 0,
        },
    },
    linkPrice: {
        [theme.breakpoints.only("xs")]: {
            fontSize: "12px",
        },
    },
    blockRow: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.up("xs")]: {
            justifyContent: "left",
        },
        [theme.breakpoints.up("sm")]: {
            height: "80%",
            justifyContent: "center",
        },
    },
}));

const DISPLAY_PRODUCT_LIMIT = 3;

const WineFrontDistribution = (props) => {
    const styles = useStyles(props);
    const { width } = useWindowDimensions();
    const [packagingsPopinOpen, setPackagingsPopinOpen] = useState(false);
    const { data, loading } = useFrontVintageMarketingQuery({
        variables: {
            vintageId: props.vintageId,
            wineId: props.wineId,
            locale: props.locale,
        },
    });
    const distribution = data?.FrontVintageMarketing;

    if (loading && props.vintageId) {
        return (
            <Box display="flex" justifyContent="center" alignItems="flex-end" width="100%">
                <Loading loading={loading} />
            </Box>
        );
    }

    const firstHalfPieChartPercentage = parseInt(distribution?.exportPercentage);
    const secondHalfPieChartPercentage = 100 - distribution?.exportPercentage;

    const colors = [
        theme.palette.primary.light,
        theme.palette.secondary.main,
        theme.palette.secondary.light,
        theme.palette.secondary.dark,
        theme.palette.primary.main,
        theme.palette.primary.dark,
    ];

    let marketingSeries = [];
    if (distribution?.directPercentage && distribution?.indirectPercentage) {
        marketingSeries = [distribution?.directPercentage, distribution?.indirectPercentage];
    } else if (distribution?.directPercentage && !distribution?.indirectPercentage) {
        marketingSeries = [distribution?.directPercentage, 100 - distribution?.directPercentage];
    } else if (!distribution?.directPercentage && distribution?.indirectPercentage) {
        marketingSeries = [100 - distribution?.indirectPercentage, distribution?.indirectPercentage];
    }

    const marketingChartOptions = {
        series: marketingSeries,
        options: {
            tooltip: {
                enabled: false,
            },
            colors: colors,
            legend: {
                show: false,
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: false,
            },
            plotOptions: {
                pie: {
                    expandOnClick: false,
                },
            },
        },
    };

    const exportationChartOptions = {
        series: [firstHalfPieChartPercentage, secondHalfPieChartPercentage],
        options: {
            tooltip: {
                enabled: false,
            },
            colors: [theme.palette.secondary.dark, theme.palette.common.white],
            legend: {
                show: false,
            },
            dataLabels: {
                enabled: false,
            },
            plotOptions: {
                pie: {
                    expandOnClick: false,
                },
            },
            stroke: {
                colors: [theme.palette.secondary.dark],
            },
            chart: {
                animations: {
                    enabled: false,

                    animateGradually: {
                        enabled: false,
                    },
                    dynamicAnimation: {
                        enabled: false,
                    },
                },
            },
        },
    };

    if (!distribution) {
        if (!props.vintageId) {
            return null;
        }
        return (
            <Typography style={{ fontSize: "16px", padding: "16px" }}>
                <Trans>Information non renseignée</Trans>
            </Typography>
        );
    }
    return (
        <>
            {packagingsPopinOpen && (
                <PackagingsPopin
                    modalOpen={packagingsPopinOpen}
                    handleClose={() => setPackagingsPopinOpen(false)}
                    packagings={distribution?.packagings}
                    wine={props.wine}
                    cuvee={props.cuvee}
                    year={props.year}
                />
            )}

            {/* MARKETING */}
            <>
                {(distribution?.indirectPercentage || distribution?.directPercentage) && (
                    <Box className={styles.blockContainer}>
                        <Box className={styles.typoContainer}>
                            <Typography variant="h3" className={styles.title}>
                                <Trans> Répartition </Trans>
                            </Typography>
                        </Box>
                        <Box className={styles.blockRow}>
                            {distribution?.indirectPercentage !== 100 && distribution?.directPercentage !== 100 && (
                                <ReactApexCharts
                                    options={marketingChartOptions.options}
                                    series={marketingChartOptions.series}
                                    type="pie"
                                    width={width >= 600 ? 180 : 100}
                                />
                            )}

                            {(distribution?.indirectPercentage || distribution?.directPercentage) && (
                                <Box
                                    className={styles.marketingContainer}
                                    style={{
                                        paddingRight: width >= 600 ? "30px" : 0,
                                    }}
                                >
                                    {distribution?.directPercentage > 0 && (
                                        <Box className={styles.flexCenter}>
                                            <Brightness1Icon
                                                style={{
                                                    color: theme.palette?.primary.light,
                                                }}
                                                className={styles.marketingIcon}
                                            />
                                            <Typography
                                                className={styles.marketingTypo}
                                                style={{
                                                    fontFamily: "Lato",
                                                    fontSize: "16px",
                                                    color: "#360318",
                                                }}
                                            >
                                                <Trans>Directe :</Trans>
                                            </Typography>
                                            <Typography
                                                className={styles.marketingTypo2}
                                                style={{
                                                    fontFamily: "Lato",
                                                    fontSize: "16px",
                                                    color: "#360318",
                                                }}
                                            >
                                                {" "}
                                                {distribution?.directPercentage > 0 && distribution?.directPercentage} %
                                            </Typography>
                                        </Box>
                                    )}
                                    {distribution?.indirectPercentage > 0 && (
                                        <Box className={styles.flexCenter}>
                                            <Brightness1Icon
                                                style={{
                                                    color: theme.palette?.secondary.main,
                                                }}
                                                className={styles.marketingIcon}
                                            />
                                            <Typography
                                                className={styles.marketingTypo}
                                                style={{
                                                    fontFamily: "Lato",
                                                    fontSize: "16px",
                                                    color: "#360318",
                                                }}
                                            >
                                                <Trans>Indirecte :</Trans>
                                            </Typography>
                                            <Typography
                                                className={styles.marketingTypo2}
                                                style={{
                                                    fontFamily: "Lato",
                                                    fontSize: "16px",
                                                    color: "#360318",
                                                }}
                                            >
                                                {" "}
                                                {distribution?.indirectPercentage} %
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                            )}
                        </Box>
                    </Box>
                )}

                {/* EXPORATION */}
                {(distribution?.exportCountries?.length > 0 || distribution?.exportPercentage) && (
                    <Box className={styles.blockContainer}>
                        <Box className={styles.typoContainer}>
                            <Typography variant="h3" className={styles.title}>
                                <Trans>Exportation</Trans>
                            </Typography>
                        </Box>
                        <Box className={styles.blockRow}>
                            {distribution?.exportPercentage && (
                                <Box className={styles.exportationChart}>
                                    {distribution?.exportPercentage !== 100 && (
                                        <ReactApexCharts
                                            options={exportationChartOptions.options}
                                            series={exportationChartOptions.series}
                                            type="pie"
                                            width={width >= 600 ? 180 : 100}
                                        />
                                    )}
                                </Box>
                            )}

                            <Box style={{ maxWidth: "400px" }}>
                                {distribution?.exportPercentage && (
                                    <Typography variant="h2" className={styles.exporation__box__typoPrim}>
                                        {`${distribution?.exportPercentage} %`}
                                    </Typography>
                                )}
                                {distribution?.exportCountries?.length > 0 && (
                                    <Typography
                                        className={styles.exporation__box__typoDetails}
                                        style={{
                                            fontFamily: "Lato",
                                            fontSize: "16px",
                                            color: "#360318",
                                        }}
                                    >
                                        {getConnectors(distribution?.exportCountries)}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    </Box>
                )}
            </>
            <>
                {/* PRICE */}
                {distribution?.packagings && distribution?.packagings?.length > 0 && (
                    <Box className={cn(styles.blockContainer, styles.priceBlock)}>
                        <Box className={styles.pricesContainer}>
                            <Typography variant="h3" className={styles.title}>
                                <Trans> Produits et tarifs</Trans>
                            </Typography>
                            <Arrow
                                rotation="250"
                                arrowPosition="end"
                                mirror
                                icon={"Woody Brown"}
                                style={{
                                    position: "relative",
                                    margin: 0,
                                    height: "30px",
                                    padding: 0,
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <Typography variant="h6" className={styles.priceTypo} style={{ color: "#432E35" }}>
                                    <Trans> Prix direct propriété </Trans>
                                </Typography>
                            </Arrow>
                        </Box>
                        <Box
                            style={{
                                margin: "auto",
                                width: "100%",
                                marginTop: theme.spacing(4),
                            }}
                        >
                            <DisplayPackaging
                                packagings={distribution?.packagings}
                                limit={DISPLAY_PRODUCT_LIMIT}
                                wine={props.wine}
                                cuvee={props.cuvee}
                                year={props.year}
                            />
                            {distribution?.packagings?.length > DISPLAY_PRODUCT_LIMIT && (
                                <Box
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <img
                                        src={arrow}
                                        alt="img"
                                        style={{
                                            marginRight: theme.spacing(2),
                                        }}
                                    />
                                    <Link
                                        underline="always"
                                        className={styles.linkPrice}
                                        onClick={() => setPackagingsPopinOpen(true)}
                                        style={{
                                            color: "#915868",
                                            cursor: "pointer",
                                            fontFamily: "Lato",
                                            fontSize: "14px",
                                        }}
                                    >
                                        <Trans>Voir tous les produits et tarifs</Trans>
                                    </Link>
                                </Box>
                            )}
                        </Box>
                    </Box>
                )}
            </>
        </>
    );
};

WineFrontDistribution.propTypes = {
    distribution: PropTypes.array,
    vintageId: PropTypes.string,
    wineId: PropTypes.string,
    wineName: PropTypes.string,
    wine: PropTypes.string,
    cuvee: PropTypes.string,
    year: PropTypes.number,
    aoc: PropTypes.string,
    locale: PropTypes.string,
};

export default WineFrontDistribution;
