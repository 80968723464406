import { Box, makeStyles, Typography } from "@material-ui/core";
import theme from "../globalTheme";
import FrontContentTop from "../Front/FrontContentTop";
import FrontTitle from "../FrontRanking/FrontTitle";
import FrontMainContainer from "../Front/FrontMainContainer";
import { useWindowDimensions } from "../helpers/helpers";
import { RankingList } from "../FrontRanking/RankingList";
import floriture1 from "../assets/encyclopedia/floriture-1.svg";
import floriture2 from "../assets/encyclopedia/floriture-2.svg";
import floriture3 from "../assets/encyclopedia/floriture-3.svg";
import floriture4 from "../assets/encyclopedia/floriture-4.svg";
import floriture5 from "../assets/encyclopedia/floriture-5.svg";
import floriture6 from "../assets/encyclopedia/floriture-6.svg";
import { Link } from "react-router-dom";
import { t, Trans } from "@lingui/macro";
import Helmet from "Components/Helmet/Helmet";
import { useCustomHistory } from "hooks";

const useStyles = makeStyles((e) => ({
    typoDescription: {
        color: theme.palette.text.primary,
        textAlign: "justify",
    },
    boxSlider: {
        background: theme.palette.background.card,
        marginTop: 16,
        padding: 8,
    },

    slider: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
    },
    postLink: {
        color: theme.palette.primary.main,
    },
}));

const ClassificationsPage = () => {
    const styles = useStyles();
    const { width } = useWindowDimensions();
    const { addLocale } = useCustomHistory();

    const getBackgroundImage = () => {
        if (width >= 1920) {
            return {
                backgroundImage: `url(${floriture3}), url(${floriture4}), url(${floriture5}), url(${floriture6})`,
                backgroundPositionY: "71px, top, bottom, bottom",
                backgroundPositionX: "left, right, left, right",
            };
        } else if (width < 1920 && width >= 960) {
            return {
                backgroundImage: `url(${floriture4})`,
                backgroundPositionY: "top",
                backgroundPositionX: "right",
            };
        } else {
            return {
                backgroundImage: "none",
            };
        }
    };

    return (
        <>
            <Helmet
                title={t`Classements des vins`}
                content="Découvrez les célèbres classements des vins de Bordeaux, une tradition qui remonte au 19ème siècle. Classement de 1855, classement de Saint-Émilion, classement des crus bourgeois... Explorez l'histoire et les critères de ces classements prestigieux sur Bordeaux et ses Vins."
            />

            <FrontContentTop
                backgroundColor={theme.palette.primary.light}
                boxStyles={{
                    ...getBackgroundImage(),
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "auto",
                    minHeight: "calc(100vh - 75px)",
                    alignItems: "flex-start",
                    paddingBottom: 20,
                }}
            >
                <FrontTitle title={t`Les classements`} />

                <FrontMainContainer
                    styleCardContent
                    className={styles.main_container}
                    style={{
                        backgroundImage: `url(${floriture1}), url(${floriture2})`,
                        backgroundPositionY: "top, bottom",
                        backgroundPositionX: "left, right",
                        backgroundRepeat: "no-repeat",
                    }}
                    hideFloritureTopImg
                >
                    <Box style={{ backgroundColor: theme.palette.common.white }}>
                        <Typography variant="body2" className={styles.typoDescription}>
                            <Trans>
                                Il existe (ou il a existé, pour être précis) six classements des vins de Bordeaux, qui
                                ont été réalisés à des époques différentes. Ils concernent des zones d’appellations
                                spécifiques, et le lecteur trouvera dans les chapitres concernés leur présentation.
                            </Trans>
                        </Typography>
                        <br />
                        <Typography variant="body2" className={styles.typoDescription}>
                            <Trans>
                                Pour une meilleure compréhension de chacun d’entre eux, nous évoquerons ceux-ci par
                                ordre chronologique, afin que l’amateur non averti puisse les restituer dans leur
                                contexte.
                            </Trans>
                        </Typography>
                        <br />
                        <Typography variant="body2" className={styles.typoDescription}>
                            <Trans>
                                Quelle que soit l’époque de leur établissement, les classements sont largement basés sur
                                la notion de terroir. Notion prise dans son acceptation la plus large, outre la qualité
                                des sols, elle tient compte aussi de l’histoire, des moyens et techniques mis en œuvre,
                                de la compétence de l’homme, de la qualité du vignoble, du climat… L’ensemble fait que
                                certains crus ont acquis ou acquièrent une notoriété telle, qu’ils sont considérés comme
                                étant les meilleurs de leur appellation ou de leur aire régionale de production à un
                                moment donné...
                            </Trans>
                        </Typography>
                    </Box>

                    <Link
                        to={addLocale("/encyclopedie-feret/article/les-classements-des-vins-de-bordeaux")}
                        style={{
                            display: "inline-flex",
                            color: theme.palette.primary.main,
                            textDecoration: "underline",
                        }}
                    >
                        <Typography variant="body2" className={styles.postLink}>
                            <Trans>Voir l’article complet sur les classements</Trans>
                        </Typography>
                    </Link>

                    <Box className={styles.boxSlider}>
                        <RankingList
                            title={t`Les cinq classements du vignoble bordelais`}
                            titleCenter
                            hideLowBar
                            titleStyle={{ color: theme.palette.primary.dark, fontWeight: 700, marginTop: 0 }}
                            boxClassName={styles.slider}
                            noPadding
                        />
                    </Box>
                </FrontMainContainer>
            </FrontContentTop>
        </>
    );
};

export default ClassificationsPage;
