import { useState } from "react";
import { number, object, oneOfType, string } from "prop-types";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { redirectOutsideUrl } from "helpers/helpers";
import theme from "globalTheme";
import Modal from "Containers/Modal";
import { converDateMonthYear } from "helpers/helpers";
import { Trans, t } from "@lingui/macro";
import BaseTooltip from "Components/Tooltips/Tooltip";
import { parseHtml } from "helpers/helpers";
import { JournalistLogo } from "Journalist/JournalistLogo";

const useStyles = makeStyles(() => ({
    card: {
        color: theme.palette.primary.dark,
        cursor: "pointer",
        position: "relative",
        display: "flex",
        alignItems: "center",
        borderRadius: 4,
        [theme.breakpoints.up("xs")]: {
            width: "100%",
            height: "43px",
            padding: "0px 28px",
            gap: "1rem",
            background: `linear-gradient(90deg, ${theme.palette.background.card} 0%, rgba(252, 246, 246, 0) 100%)`,
        },
        [theme.breakpoints.up("sm")]: {
            width: "113px",
            height: "76px",
            flexDirection: "column",
            justifyContent: "center",
            background: `linear-gradient(180deg, ${theme.palette.background.card} 0%, rgba(252, 246, 246, 0) 100%)`,
            gap: "4.5px",
        },
        [theme.breakpoints.up("md")]: {
            gap: 0,
            flexDirection: "row",
            width: "197px",
            height: "43px",
            justifyContent: "space-between",
            padding: "0px 5px",
            background: `linear-gradient(90deg, ${theme.palette.background.card} 0%, rgba(252, 246, 246, 0) 100%)`,
        },
        [theme.breakpoints.up("lg")]: {
            width: "274px",
            height: "57px",
            padding: "0px 17px",
        },
        [theme.breakpoints.up("xl")]: {
            flexDirection: "column",
            background: `linear-gradient(180deg, ${theme.palette.background.card} 0%, rgba(252, 246, 246, 0) 100%)`,
            width: "152px",
            height: "95px",
            gap: "14px",
            justifyContent: "center",
            padding: "10px",
        },
    },
    score_badge: {
        backgroundColor: theme.palette.primary.light,
        padding: "5px 0",
        borderRadius: 34,
        color: theme.palette.common.white,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.up("xs")]: {
            width: 62,
        },
        [theme.breakpoints.up("lg")]: {
            width: 70,
        },
    },
    img: {
        [theme.breakpoints.up("xs")]: {
            width: 108,
        },
        [theme.breakpoints.up("lg")]: {
            width: 136,
        },
        [theme.breakpoints.up("xl")]: {
            width: 153,
        },
    },
    detail_container: {
        cursor: "pointer",
        marginTop: "1rem",
        border: `1px solid ${theme.palette.primary.dark}`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "fit-content",
        borderRadius: 4,
        padding: 6,
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.dark,
    },
}));

const ScoreCard = ({ score, vintage }) => {
    const styles = useStyles();
    const [open, setOpen] = useState();

    return (
        <>
            <Modal width="435px" open={open} onClose={() => setOpen(false)} actionNeeded={false} title={vintage}>
                <JournalistLogo className={styles.img} score={score} />
                <Typography variant="body2" style={{ fontWeight: "700", color: theme.palette.primary.dark }}>
                    {score?.score} / {score?.scoreMax}
                </Typography>
                <Typography variant="body2" style={{ fontWeight: "700", color: theme.palette.primary.dark }}>
                    <Trans>Date dégustation :</Trans>{" "}
                    <span style={{ fontWeight: "400" }}>{converDateMonthYear(score?.tastingDate)}</span>
                </Typography>
                {score?.criticName && (
                    <Typography variant="body2" style={{ fontWeight: "700", color: theme.palette.primary.dark }}>
                        <Trans>Dégustateur :</Trans> <span style={{ fontWeight: "400" }}>{score?.criticName}</span>
                    </Typography>
                )}
                {score.tastingComment?.length > 0 && (
                    <Typography variant="body2" style={{ color: theme.palette.primary.dark, marginTop: "1rem" }}>
                        {parseHtml(score.tastingComment)}
                    </Typography>
                )}
                {score?.scoreUrl && (
                    <Box className={styles.detail_container} onClick={() => redirectOutsideUrl(score?.scoreUrl)}>
                        <Typography variant="h6">
                            <Trans>Voir cette note sur</Trans>
                        </Typography>
                        <JournalistLogo className={styles.img} score={score} />
                    </Box>
                )}
            </Modal>
            <BaseTooltip title={t`Voir plus de détails`}>
                <Box className={styles.card} onClick={() => setOpen(true)}>
                    <Typography variant="body2" className={styles.score_badge}>
                        {score?.score}
                    </Typography>
                    <JournalistLogo className={styles.img} score={score} />
                </Box>
            </BaseTooltip>
        </>
    );
};

ScoreCard.propTypes = {
    score: object,
    vintage: oneOfType([number, string])
};

export default ScoreCard;
