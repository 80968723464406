import { Box, FormControlLabel, Grid, makeStyles, Radio, RadioGroup, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { useState } from "react";
import { useSelector } from "react-redux";

import Arrow from "../../Components/Arrow/Arrow";
import BaseButton from "../../Components/Buttons/BaseButton";
import SiretInput from "../../Components/Inputs/SiretInput";
import Loading from "../../Components/Loader/Loading";
import theme from "../../globalTheme";
import { formatConcat, sanitizeAddressField, sanitizeUpperCase } from "../../helpers/helpers";
import { isError } from "../../helpers/validators";
import SubscriptionContainer from "./SubscriptionContainer";
import { Redirect } from "Components/Redirect/Redirect";

const useStyles = makeStyles(() => ({
    loadingContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
    },
    billingTitle: {
        fontFamily: "Lato",
        fontSize: "18px",
        fontWeight: "700",
        marginBottom: "1rem",
    },
    billingBox: {
        background: theme.palette.background.card,
        padding: "12px 30px 15px ",
        borderRadius: 4,
        display: "flex",
        justifyContent: "space-between",
        columnGap: 33,

        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            width: "100%",
        },
    },
    typoBold: {
        fontWeight: 700,
        margin: 0,
    },
    firstContainer: { marginTop: "40px" },
    secondContainer: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
    },
    radioContainer: {
        marginBottom: theme.spacing(1),
        "& .MuiRadio-root": {
            color: theme.palette.secondary.main,
        },
        "& .Mui-checked": {
            color: theme.palette.primary.light,
        },
        "& .MuiSvgIcon-root": {
            width: "20px",
        },
    },
    radioLabel: {
        ...theme.typography.h6,
    },
}));

const BillingContainer = (props) => {
    const styles = useStyles(props);
    const [loading, setLoading] = useState(false);
    const currentUser = useSelector((state) => state.user?.currentUser);

    // ADDRESSE INPUTS
    const [siret, setSiret] = useState(props.subscriptionState?.subscriptionBillingSiret || null);
    const [statut, setStatut] = useState(props.subscriptionState?.subscriptionBillingStatut || null);
    const [companyName, setCompanyName] = useState(props.subscriptionState?.subscriptionBillingCompanyName || null);
    const [tva, setTva] = useState(props.subscriptionState?.subscriptionBillingTVA || null);
    const [country, setCountry] = useState(props.subscriptionState?.subscriptionBillingCountry || null);
    const [address, setAddress] = useState(props.subscriptionState?.subscriptionBillingAddress || null);
    const [extraAddress, setExtraAddress] = useState(props.subscriptionState?.subscriptionBillingExtraAddress || null);
    const [zipCode, setZipCode] = useState(props.subscriptionState?.subscriptionBillingZipCode || null);
    const [commune, setCommune] = useState(props.subscriptionState?.subscriptionBillingCommune || null);
    const [communeINSEEcode, setCommuneINSEEcode] = useState(
        props.subscriptionState?.subscriptionBillingCommuneINSEEcode || null
    );
    const [propertyOwner, setPropertyOwner] = useState(props.subscriptionState?.subscriptionPropertyOwner || null);
    const [propertyOperator, setPropertyOperator] = useState(
        props.subscriptionState?.subscriptionPropertyOperator || null
    );

    const submitBillingInformations = () => {
        // DISPATCH ADDRESS INPUTS
        props.subscriptionDispatch({
            type: "setManyValues",
            payload: {
                subscriptionBillingSiret: siret,
                subscriptionBillingStatut: statut,
                subscriptionBillingCompanyName: sanitizeUpperCase(companyName),
                subscriptionBillingTVA: tva,
                subscriptionBillingCountry: country,
                subscriptionBillingAddress: sanitizeAddressField(address),
                subscriptionBillingExtraAddress: sanitizeAddressField(extraAddress),
                subscriptionBillingZipCode: zipCode,
                subscriptionBillingCommune: commune,
                subscriptionBillingCommuneINSEEcode: communeINSEEcode,
                subscriptionPropertyOwner: propertyOwner,
                subscriptionPropertyOperator: propertyOperator,
            },
        });
        props.onClickNext();
    };

    // VALIDATIONS
    const siretErrors = isError("preciseLength", siret, {
        noSpace: true,
        number: 14,
    });
    const zipCodeErrors = isError("preciseLength", zipCode, {
        onlyNumber: true,
        number: 5,
    });

    if (props.checkIfPreviousStepIsValid(3)) {
        return (
            <SubscriptionContainer
                title="Coordonnées de facturation"
                titleVariant="h3"
                backLink
                onClickBack={props.onClickBack}
                // style={{ margin: 0 }}
            >
                <Grid container spacing={2} className={styles.firstContainer}>
                    <Grid item sm={12} className={styles.secondContainer}>
                        {loading ? (
                            <Grid container spacing={4}>
                                <Box className={styles.loadingContainer}>
                                    <Loading />
                                </Box>
                            </Grid>
                        ) : (
                            <Grid container spacing={4} style={{ marginBottom: "8px" }}>
                                {/* SIRET - test 38880717400064 */}
                                <SiretInput
                                    variant="outlined"
                                    label="SIRET"
                                    numberMode
                                    maxLength={14}
                                    value={siret}
                                    onChange={(e) => setSiret(e)}
                                    setLoading={setLoading}
                                    statut={{
                                        value: statut,
                                        onChange: (e) => setStatut(e),
                                        correctValue: statut,
                                    }}
                                    companyName={{
                                        value: companyName,
                                        onChange: (e) => setCompanyName(e),
                                        correctValue: companyName,
                                    }}
                                    tva={{
                                        value: tva,
                                        onChange: (e) => setTva(e),
                                        correctValue: tva,
                                    }}
                                    country={{
                                        value: country,
                                        onChange: (e) => setCountry(e),
                                        correctValue: country,
                                    }}
                                    address={{
                                        value: address,
                                        onChange: (e) => setAddress(e),
                                        correctValue: address,
                                    }}
                                    extraAddress={{
                                        value: extraAddress,
                                        onChange: (e) => setExtraAddress(e),
                                        correctValue: extraAddress,
                                    }}
                                    zipCode={{
                                        value: zipCode,
                                        onChange: (e) => setZipCode(e),
                                        correctValue: zipCode,
                                        errors: zipCodeErrors,
                                    }}
                                    onChangeCommune={(e) => setCommune(e)}
                                    commune={commune}
                                    onChangeCommuneINSEECode={setCommuneINSEEcode}
                                    correctValue={siret?.length === 14}
                                    falseValue={siret && siretErrors}
                                    error={siret && siretErrors}
                                    clue="Le champ doit contenir 14 chiffres"
                                />

                                {/* PROPERTY OWNER */}
                                <Grid
                                    item
                                    xs={5}
                                    sm={5}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    className={styles.radioContainer}
                                    style={{
                                        padding: 0,
                                        paddingLeft: theme.spacing(2),
                                    }}
                                >
                                    <RadioGroup
                                        style={{ flexDirection: "row" }}
                                        aria-label="property-owner"
                                        name="property-owner"
                                        value={propertyOwner}
                                        onChange={(e) => setPropertyOwner(e.target.value)}
                                    >
                                        <FormControlLabel
                                            value="true"
                                            label="Oui"
                                            classes={{
                                                label: styles.radioLabel,
                                            }}
                                            control={<Radio />}
                                        />
                                        <FormControlLabel
                                            value="false"
                                            label="Non"
                                            classes={{
                                                label: styles.radioLabel,
                                            }}
                                            control={<Radio />}
                                        />
                                    </RadioGroup>
                                    <Arrow
                                        icon={"primary"}
                                        rotation="105"
                                        arrowPosition="start"
                                        style={{
                                            marginLeft: 8,
                                            marginBottom: "0.5rem",
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            style={{
                                                fontSize: "14px",
                                                fontFamily: "Mansalva",
                                            }}
                                        >
                                            Il s’agit du propriétaire de la propriété
                                        </Typography>
                                    </Arrow>
                                </Grid>

                                {/* PROPERTY OPERATOR*/}
                                <Grid
                                    item
                                    xs={5}
                                    sm={5}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    className={styles.radioContainer}
                                    style={{
                                        padding: 0,
                                        paddingLeft: theme.spacing(2),
                                    }}
                                >
                                    <RadioGroup
                                        style={{ flexDirection: "row" }}
                                        aria-label="property-operator"
                                        name="property-operator"
                                        value={propertyOperator}
                                        onChange={(e) => setPropertyOperator(e.target.value)}
                                    >
                                        <FormControlLabel
                                            value="true"
                                            label="Oui"
                                            classes={{
                                                label: styles.radioLabel,
                                            }}
                                            control={<Radio />}
                                        />
                                        <FormControlLabel
                                            value="false"
                                            label="Non"
                                            classes={{
                                                label: styles.radioLabel,
                                            }}
                                            control={<Radio />}
                                        />
                                    </RadioGroup>
                                    <Arrow
                                        icon={"primary"}
                                        rotation="105"
                                        arrowPosition="start"
                                        style={{
                                            marginLeft: 8,
                                            marginBottom: "0.5rem",
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            style={{
                                                fontSize: "14px",
                                                fontFamily: "Mansalva",
                                            }}
                                        >
                                            Il s’agit de l’exploitant de la propriété
                                        </Typography>
                                    </Arrow>
                                </Grid>
                            </Grid>
                        )}
                        <Grid container spacing={2}>
                            {/* PARTIE FACTURATION */}
                            <Grid item sm={12} xs={12} style={{ paddingBottom: 0 }}>
                                <Typography variant="h4" color="primary" className={styles.billingTitle}>
                                    Contact de facturation
                                </Typography>
                            </Grid>

                            <Box className={styles.billingBox}>
                                <Typography variant="h6" className={styles.typoBold}>
                                    {formatConcat([currentUser?.title, currentUser?.firstName, currentUser?.lastName])}
                                </Typography>

                                <Typography variant="h6">{currentUser?.email}</Typography>

                                <Typography variant="h6">{currentUser?.telephone}</Typography>
                            </Box>
                        </Grid>

                        <Box justifyContent="flex-end" display="flex" paddingTop="3%">
                            <BaseButton
                                type="submit"
                                onClick={() => submitBillingInformations()}
                                disabled={
                                    !siret ||
                                    !companyName ||
                                    !country ||
                                    !address ||
                                    (siret && siretErrors) ||
                                    zipCodeErrors ||
                                    !zipCode ||
                                    !commune ||
                                    !communeINSEEcode ||
                                    propertyOperator === null ||
                                    propertyOwner === null
                                }
                                color="primary"
                                variant="contained"
                                size="small"
                            >
                                Suivant
                            </BaseButton>
                        </Box>
                    </Grid>
                </Grid>
                {/* <DevTool control={control} /> */}
            </SubscriptionContainer>
        );
    } else {
        return <Redirect to={{ pathname: "/subscription/0" }} />;
    }
};

BillingContainer.propTypes = {
    subscriptionDispatch: PropTypes.object,
    subscriptionState: PropTypes.object,
    checkIfPreviousStepIsValid: PropTypes.func,
    registrationInformations: PropTypes.object,
    onClickBack: PropTypes.func,
    onClickNext: PropTypes.func,
};

export default BillingContainer;
