import React from "react";
import PropTypes, { object } from "prop-types";
import { Box, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    box: {
        position: "relative",
    },
}));

const TitleSemiUnderlined = (props) => {
    const styles = useStyles(props);

    return (
        <Box className={styles.box} style={props.box}>
            <Typography className={props.className} variant="h3" style={props.style}>
                {props.children}
            </Typography>
        </Box>
    );
};

TitleSemiUnderlined.propTypes = {
    children: PropTypes.string,
    className: PropTypes.any,
    style: PropTypes.object,
    box: object,
};

export default TitleSemiUnderlined;
