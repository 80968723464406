import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

export const ContentLoader = () => (
    <Box style={{ display: "flex", flexDirection: "column", gap: "8px", padding: "0 8px" }}>
        <Skeleton animation="wave" variant="rect" />
        <Skeleton animation="wave" variant="rect" />
        <Skeleton animation="wave" variant="rect" />
    </Box>
);
