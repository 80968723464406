function SvgYoutube() {
  return (
      <svg
        width="24"
        height="16"
        viewBox="0 0 24 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
          <path
            d="M23.5055 2.50362C23.229 1.52463 22.4183 0.752898 21.3903 0.489286C19.5122 0 11.9997 0 11.9997 0C11.9997 0 4.48756 0 2.60948 0.470736C1.60122 0.734069 0.770802 1.52477 0.494239 2.50362C0 4.29172 0 8 0 8C0 8 0 11.727 0.494239 13.4964C0.771095 14.4752 1.58145 15.247 2.60962 15.5106C4.50733 16 12 16 12 16C12 16 19.5122 16 21.3903 15.5293C22.4185 15.2658 23.229 14.4941 23.5058 13.5152C23.9999 11.727 23.9999 8.01883 23.9999 8.01883C23.9999 8.01883 24.0197 4.29172 23.5055 2.50362V2.50362ZM9.60793 11.4258V4.57416L15.8549 8L9.60793 11.4258Z"
            fill="#632D3E"
          />
      </svg>
  );
}

export default SvgYoutube;

