function SvgWeb() {
    return (
        <svg width="21" height="25" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.5899 20.07H13.4799C12.5893 20.3275 11.667 20.4588 10.7399 20.46C8.54397 20.4582 6.41323 19.7136 4.69412 18.3473C2.975 16.981 1.76861 15.0733 1.27109 12.9345C0.773572 10.7957 1.01418 8.55142 1.95379 6.56667C2.8934 4.58192 4.47676 2.97336 6.44644 2.00254C8.41611 1.03171 10.6563 0.755714 12.8027 1.21941C14.9491 1.68311 16.8755 2.85924 18.2688 4.55657C19.6621 6.2539 20.4402 8.37262 20.4767 10.5682C20.5132 12.7639 19.806 14.9073 18.4699 16.65"
                stroke="#632D3E"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.4102 19.5C12.6607 20.1287 11.7184 20.4816 10.7402 20.5C7.41023 20.5 4.74023 16.13 4.74023 10.75C4.74023 5.37001 7.41023 1.01001 10.7402 1.01001C14.0702 1.01001 16.7402 5.33001 16.7402 10.75C16.7564 11.9542 16.6288 13.156 16.3602 14.33"
                stroke="#632D3E"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M10.7402 1.01001V20.5" stroke="#632D3E" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20.48 10.75H1" stroke="#632D3E" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.3299 15.76H2.37988" stroke="#632D3E" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.1002 15.76H17.4302" stroke="#632D3E" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.0999 5.73999H2.37988" stroke="#632D3E" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M13.8599 13.11L13.8699 21.12L15.9099 19.68L17.7199 23.65L19.0199 23.09L17.2799 19.09L19.6899 18.61L13.8599 13.11Z"
                stroke="#632D3E"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default SvgWeb;
