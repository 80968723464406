import { makeStyles } from "@material-ui/core";
import theme from "../../../globalTheme";

const useStyles = makeStyles(() => ({
    swiper__wrapper: {
        width: "100%",
        "& .swiper-slide": {
            [theme.breakpoints.only("xs")]: {
                width: "100%",
            },
            [theme.breakpoints.up("sm")]: {
                width: "fit-content",
            },
        },
        "& .swiper-wrapper": {
            marginBottom: "32px",
        },
        "& .swiper-pagination-bullet": {
            background: "#632d3e",
        },
    },
}));

export default useStyles;
