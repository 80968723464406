import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";

import { Maybe } from "../generated/graphql";

interface FrontTitleProps {
    title?: Maybe<string>;
    style?: any;
    className?: object;
}

const useStyles = makeStyles((theme) => {
    return {
        title_container: (props) => ({
            padding: "42px 0 32px",
            margin: "0",
            textAlign: "center",
            lineHeight: 1.5,
            color: theme.palette.common.white,

            [theme.breakpoints.up("md")]: {
                padding: "55px 0 65px",
            },

            [theme.breakpoints.up("md")]: {
                padding: "55px 0 15px",
                margin: "0 auto",
            },
        }),
    };
});

const FrontTitle = (props: FrontTitleProps) => {
    const styles = useStyles(props);

    return (
        <Grid item>
            {props.className ? (
                <Typography
                    variant={"h1"}
                    className={[styles.title_container, props.className].join(" ")}
                    style={props.style}
                >
                    {props.title}
                </Typography>
            ) : (
                <Typography variant={"h1"} className={styles.title_container} style={props.style}>
                    {props.title}
                </Typography>
            )}
        </Grid>
    );
};

export default FrontTitle;
