import { useState } from "react";
import { string } from "prop-types";
import { Box, makeStyles, Typography } from "@material-ui/core";
import theme from "../globalTheme";
import { t } from "@lingui/macro";
import { useCustomHistory } from "hooks";

const useStyles = makeStyles(() => ({
    root: {
        width: 170,
        height: 177,
        border: `1px solid ${theme.palette.common.white}`,
        borderRadius: "5px",
        boxSizing: "border-box",
        backgroundColor: "transparent",
        padding: 0,
    },
    container_content: {
        backgroundColor: "rgba(217, 217, 217, 0.2)",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",

        "&:hover": {
            backgroundColor: theme.palette.common.white,

            "& h5": {
                color: theme.palette.primary.light,
            },
        },
    },
    title: {
        color: theme.palette.common.white,
        fontWeight: 400,
    },
    container_image: {
        height: 103,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "1rem",
    },
}));

const LinkCard = (props) => {
    const styles = useStyles();
    const history = useCustomHistory();
    const [hovering, setHovering] = useState(false);

    const generateLink = () => {
        switch (props?.title) {
            case t`Une propriété`:
                return "/recherche/proprietes/resultats";
            case t`Un vin`:
                return "/recherche/vins/resultats";
            case t`Une activité`:
                return "/recherche/activites/resultats";
            case "QR code":
                return "/recherche/qrcode";
            default:
                break;
        }
    };

    return (
        <button
            className={styles.root}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
            onClick={() => history.push(generateLink())}
        >
            <Box className={styles.container_content}>
                <Box className={styles.container_image}>
                    {props.image(hovering ? theme.palette.primary.light : theme.palette.common.white)}
                </Box>
                <Typography variant="h5" className={styles.title}>
                    {props?.title}
                </Typography>
            </Box>
        </button>
    );
};

LinkCard.propTypes = {
    title: string,
    image: string,
};

export default LinkCard;
