import { Box, Button, Card, CardContent, makeStyles, Modal as MuiModal, Typography } from "@material-ui/core";
import cn from "clsx";
import React from "react";

import cross from "../assets/cross.svg";
import actionNeededBg from "../assets/popin/action_needed_bg.svg";
import noActionNeededBg from "../assets/popin/no_action_needed_bg.svg";
import globalTheme from "../globalTheme";
import { Trans } from "@lingui/macro";

interface ButtonProps {
    // eslint-disable-next-line no-unused-vars
    onClick?: (e?: any) => Promise<void> | void;
    disabled?: boolean;
    text: string | React.ReactNode;
    restriction?: React.ReactNode;
}
interface PopinProps {
    open: boolean;
    onClose: () => void;
    title: React.ReactNode;
    icon: string;
    actionNeeded?: boolean;
    customPrimary?: React.ReactNode;
    children: React.ReactNode;
    primaryCTA?: ButtonProps;
    secondaryCTA?: ButtonProps | null;
    tertiaryCTA?: ButtonProps;
    width?: string;
    legend?: React.ReactNode;
    legendTextColor?: string;
    table?: boolean;
    disableEscapeKeyDown?: boolean;
    extraTextButton?: string;
    childrenBoxStyle?: any;
    childrenContainerClassName?: string;
    styles?: object;
    buttonContainer?: object;
    comeBack?: any;
    onComeBack?: () => void;
    cardHeaderMargin?: string;
    cardHeaderColor?: string;
    contentCard?: string;
    noFioritures?: boolean;
    backgroundColor?: string;
    cardStyle?: object;
    extraFixText?: any;
    validationError?: React.ReactNode;
}

const getBackground = (background?: boolean, noFioritures?: boolean) => {
    if (noFioritures) {
        return `${globalTheme.palette.common.white}`;
    }
    if (background) {
        return `no-repeat bottom left/15rem ${globalTheme.palette.common.white} url(${actionNeededBg})`;
    } else if (background === false) {
        return `no-repeat bottom left/10rem ${globalTheme.palette.common.white} url(${noActionNeededBg})`;
    } else return `${globalTheme.palette.common.white}`;
};

const getNumberOfButtons = (primaryCTA?: ButtonProps, secondaryCTA?: ButtonProps, tertiaryCTA?: ButtonProps) => {
    const buttonsArray = [primaryCTA, tertiaryCTA, secondaryCTA];
    return buttonsArray.filter((button?: ButtonProps) => button).length;
};

const useStyle = makeStyles(() => ({
    modal: (props: PopinProps) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        "&>div": {
            backgroundColor: props.backgroundColor ?? "inherit",
        },
    }),
    cardContainer: {
        maxHeight: "100vh",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        outline: "none",
        width: "100%",
        margin: "0 auto",
    },
    card: (props: PopinProps) => ({
        width: props.width,
        position: "relative",
        background: getBackground(props.actionNeeded, props.noFioritures),
        borderRadius: "10px",
        maxWidth: "70rem",
        height: "100%",
        maxHeight: "90vh",
        display: "flex",
        flexDirection: "column",
        overflow: props.table ? "inherit" : "",
        [globalTheme.breakpoints.down("sm")]: {
            "@media (orientation: landscape)": {
                maxHeight: "70vh",
                minHeight: "90vh",
                overflowY: "scroll",
            },
        },
    }),
    cardHeader: (props) => ({
        textAlign: "center",
        margin: props.cardHeaderMargin || props.title ? "2.5rem 2.5rem 1.5rem 2.5rem" : "1rem",
        color: props?.cardHeaderColor,
    }),
    icon: {
        position: "absolute",
        width: "100%",
        textAlign: "center",
        top: "-45px",
        left: "0",
        right: "0",
        zIndex: 1,
        [globalTheme.breakpoints.down("sm")]: {
            "@media (orientation: landscape)": {
                display: "none",
            },
        },
    },
    cross: {
        position: "absolute",
        zIndex: 2,
        top: 0,
        right: 0,
        margin: "1rem",
        cursor: "pointer",
    },
    cardContent: (props: PopinProps) => ({
        overflowY: "scroll",
        overflowX: "hidden",
        padding: `0rem 2.5rem ${props.actionNeeded || props.actionNeeded === undefined ? "1.5rem" : "2rem"} 2.5rem`,
        "&:last-child": {
            paddingBottom: "0",
        },
        //TO NOT DELETE SCROLL STYLE
        "&::-webkit-scrollbar": {
            width: "8px",
            height: "8px",
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            paddingRight: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: globalTheme.palette.secondary.main,
            borderRadius: "20px",
        },
    }),
    background: {
        position: "absolute",
        bottom: "0",
    },
    buttonContainer: (props: PopinProps) => ({
        display: "flex",

        flexWrap: "wrap",
        gap: "0.5rem",
        justifyContent:
            getNumberOfButtons(props.primaryCTA, props.secondaryCTA!, props.tertiaryCTA) > 2
                ? "space-between"
                : "right",
        [globalTheme.breakpoints.down("sm")]: {
            flexDirection: "column",
            justifyContent: "center",
        },
        [globalTheme.breakpoints.down("sm")]: {
            "@media (orientation: landscape)": {
                flexDirection: "row",
                justifyContent: "end",
            },
        },
    }),
    footer: (props: PopinProps) => ({
        margin: `0rem 2.5rem ${props.actionNeeded || props.actionNeeded === undefined ? "1.5rem" : "2rem"} 2.5rem`,
        display: "flex",
        flexDirection: "column",
        textAlign: "right",
    }),
    button: {
        marginBottom: "0.5rem",
        "&.MuiButton-outlinedPrimary": {
            backgroundColor: globalTheme.palette.common.white,
        },
        "&.MuiButton-contained.Mui-disabled": {
            color: globalTheme.palette.secondary.main,
            backgroundColor: "#C1ABB2",
        },
    },
    legendText: (props) => ({
        color: props.legendTextColor || globalTheme.palette.secondary.dark,
        fontSize: "10px",
        textAlign: "end",
        marginBottom: "0.5rem",
    }),
    childrenContainer: (props) => ({
        padding: props.title ? "1rem 0" : "0 0 1rem 0",
        //TO NOT DELETE SCROLL STYLE
        "&::-webkit-scrollbar": {
            width: "8px",
            height: "8px",
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            paddingRight: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: globalTheme.palette.secondary.main,
            borderRadius: "20px",
        },
    }),
}));

const Modal = (props: PopinProps) => {
    const styles = useStyle(props);

    const handleClose = (_: any, reason: string) => {
        if (props.actionNeeded && reason === "backdropClick") {
            return;
        } else {
            props.onClose();
        }
    };

    return (
        <MuiModal
            className={styles.modal}
            open={props.open}
            onClose={handleClose}
            disableEscapeKeyDown={props.actionNeeded || props.disableEscapeKeyDown}
            style={props.styles}
            aria-labelledby="modal"
        >
            <Box
                className={styles.cardContainer}
                maxWidth={props.width}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                {props.actionNeeded !== undefined && (
                    <div className={styles.icon}>
                        <img src={props.icon} />
                    </div>
                )}
                <Card className={styles.card} style={props.cardStyle} variant="outlined">
                    {props.actionNeeded === false && (
                        <img src={cross} onClick={props.onClose} className={styles.cross} />
                    )}
                    {props.comeBack && (
                        <Typography
                            variant="h6"
                            onClick={() => {
                                if (props.onComeBack) props.onComeBack();
                            }}
                            style={{
                                zIndex: 100,
                                cursor: "pointer",
                                display: "inline",
                                marginLeft: "2rem",
                                position: "absolute",
                                top: "18px",
                                color: globalTheme.palette.primary.dark,
                            }}
                        >
                            <Trans>Retour</Trans>
                        </Typography>
                    )}

                    <Typography variant="h3" className={styles.cardHeader} id="title">
                        {props.title}
                    </Typography>
                    <CardContent className={cn(props.contentCard, styles.cardContent)}>
                        <Box
                            className={cn(props.childrenContainerClassName, styles.childrenContainer)}
                            style={props.childrenBoxStyle}
                            id="content"
                        >
                            {props.children}
                        </Box>

                        {props.legend && <Typography className={styles.legendText}>{props.legend}</Typography>}

                        {props.extraTextButton && (
                            <Typography
                                variant="subtitle1"
                                style={{ textAlign: "right", color: globalTheme.palette.primary.light }}
                            >
                                {props.extraTextButton}
                            </Typography>
                        )}
                    </CardContent>
                    {props.actionNeeded && (
                        <Box className={styles.footer}>
                            <Box className={styles.buttonContainer} style={props.buttonContainer} id="footer">
                                {props.extraFixText}
                                {props.tertiaryCTA && props.tertiaryCTA.text !== "" && (
                                    <Button
                                        className={styles.button}
                                        color="primary"
                                        variant="outlined"
                                        disabled={props.tertiaryCTA.disabled}
                                        onClick={props.tertiaryCTA.onClick}
                                    >
                                        {props.tertiaryCTA.text}
                                    </Button>
                                )}

                                {props.secondaryCTA && props.secondaryCTA.text !== "" && (
                                    <Button
                                        className={styles.button}
                                        color="primary"
                                        variant="outlined"
                                        disabled={props.secondaryCTA.disabled}
                                        onClick={props.secondaryCTA.onClick}
                                    >
                                        {props.secondaryCTA.text}
                                        {props.secondaryCTA?.restriction}
                                    </Button>
                                )}

                                {props.primaryCTA && props.primaryCTA.text !== "" && (
                                    <Button
                                        className={styles.button}
                                        color="primary"
                                        variant="contained"
                                        disabled={props.primaryCTA.disabled}
                                        onClick={props.primaryCTA.onClick}
                                    >
                                        {props.primaryCTA.text}
                                    </Button>
                                )}
                                {props.customPrimary}
                            </Box>
                            {props.validationError}
                        </Box>
                    )}
                </Card>
            </Box>
        </MuiModal>
    );
};

export default Modal;
