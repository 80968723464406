import { Box, makeStyles } from "@material-ui/core";
import { arrayOf, node, shape, string } from "prop-types";

import BaseTooltip from "../Tooltips/Tooltip";
import { PlatformIcons } from "./PlatformIcons";

const useStyles = makeStyles((theme) => ({
    mainBox: {
        display: "flex",
    },
    icon: {
        color: theme.palette.info.main,
        cursor: "pointer",
        marginLeft: "12px",
    },
    img: {
        height: "22px",
        color: theme.palette.primary.main,
    },
}));

const TooltipWrapper = ({ children, title, ...rest }) =>
    title ? (
        <BaseTooltip title={title} {...rest}>
            {children}
        </BaseTooltip>
    ) : (
        children
    );

TooltipWrapper.propTypes = {
    children: node,
    title: string,
};

const SocialMediaIcons = (props) => {
    const styles = useStyles();

    if (props.socialMedias?.length === 0) {
        return null;
    }

    const handleIconClick = (url) => {
        if (url?.indexOf("http://") == 0 || url?.indexOf("https://") == 0) {
            return url;
        }
        return `https://${url}`;
    };

    return (
        <Box className={styles.mainBox}>
            {props.socialMedias
                ?.filter(({ url }) => Boolean(url))
                ?.map(({ platform, url, name, icon }, i) => (
                    <TooltipWrapper key={i} title={name}>
                        <Box
                            component="a"
                            className={styles.icon}
                            href={handleIconClick(url.trim())}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {(icon?.data || platform) && <PlatformIcons platform={platform} />}
                        </Box>
                    </TooltipWrapper>
                ))}
        </Box>
    );
};

SocialMediaIcons.propTypes = {
    socialMedias: arrayOf(
        shape({
            platform: string,
            url: string,
            icon: shape({ data: string }),
        })
    ),
};

SocialMediaIcons.defaultProps = {
    socialMedias: [],
};

export default SocialMediaIcons;
