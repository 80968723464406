import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { SKIP_ROUTES, WITHOUT_PREFFIX } from "Routes/MainRouter/MainRouter";
import { getUserLocale } from "helpers/helpers";
import { ALL_LANGUAGES } from "config/constants";

export const useCustomHistory = () => {
    const locale = useSelector(getUserLocale);
    const history = useHistory();

    const addLocale = (str) => {
        if (!str) return `/${locale}`;
        const suffix = str[0] === "/" ? str : "/" + str;
        const lang = suffix.split("/")[1];
        if (ALL_LANGUAGES.includes(lang)) return suffix;
        return `/${locale}` + suffix;
    };

    const preffixPath = (arg) => {
        const isSkipped = (str) => [...WITHOUT_PREFFIX, ...SKIP_ROUTES].some((item) => str.indexOf(item) >= 0);

        if (typeof arg === "string") {
            if (isSkipped(arg)) return arg;
            return addLocale(arg);
        }
        if (!arg?.pathname) return arg;

        if (isSkipped(arg.pathname)) return arg;
        const pathname = addLocale(arg.pathname);
        return { ...arg, pathname };
    };

    const push = (...args) => {
        const formattedArg = preffixPath(args[0]);
        return history.push(formattedArg, ...args.slice(1));
    };

    const replace = (...args) => {
        const formattedArg = preffixPath(args[0]);
        return history.replace(formattedArg, ...args.slice(1));
    };

    return { ...history, push, replace, addLocale, preffixPath };
};
