import FeretSummaryContainer from "../Front/FeretSummary/FeretSummaryContainer";
import FeretSummaryElement from "../Front/FeretSummary/FeretSummaryElement";

import appellation from "../assets/author/expertise.svg";
import { t } from "@lingui/macro";

interface AuthorSummaryProps {
    skills: string[];
    maxWidth?: string;
    typoStyle?: object;
}

const AuthorSummary = (props: AuthorSummaryProps) => (
    <FeretSummaryContainer maxWidth={props.maxWidth ?? "none"}>
        <FeretSummaryElement
            icon={appellation}
            title={t`Expertises`}
            subtitle={props.skills}
            typoStyle={props?.typoStyle}
        />
    </FeretSummaryContainer>
);

export default AuthorSummary;
