import { any, array, bool, func, number, object, string } from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useEffect, useRef, useState } from "react";
import ListItem from "@material-ui/core/ListItem";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";

import ArrowViewMore from "../../assets/navbarfront/arrow_view_more.svg";
import { useWindowDimensions } from "../../helpers/helpers";
import { Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
    link: (args) => {
        return {
            ...theme.typography.h5,
            color: args.scroll ? theme.palette.primary.dark : theme.palette.common.white,
            cursor: "pointer",
            position: "sticky",
            backgroundColor: "unset",
            padding: 0,
            width: "fit-content",
            fontWeight: args.open ? "700" : "normal",
            "&:hover": {
                color: args.scroll ? theme.palette.primary.dark : theme.palette.common.white,
                backgroundColor: "unset",
            },
            "&:before": {
                content: '""',
                position: "absolute",
                display: "block",
                width: "50px",
                height: "5px",
                bottom: "-6px",
                left: 0,
                borderBottomRightRadius: "20px",
                borderTopRightRadius: "20px",
                backgroundColor: args.scroll ? theme.palette.primary.dark : theme.palette.common.white,
                transform: args.open ? "scaleX(1)" : "scaleX(0)",
                transformOrigin: "top left",
                transition: "transform 0.3s ease",
            },
            "&:hover:before, &.Mui-selected:before": {
                transform: "scaleX(1)",
            },
            "&.Mui-selected, &:hover": {
                fontWeight: "700",
                backgroundColor: "unset !important",
            },
            [theme.breakpoints.down("sm")]: {
                display: "none",
            },
            [theme.breakpoints.up("md")]: {
                display: "inherit",
            },
        };
    },
    paper: {
        borderRadius: 0,
        borderBottomLeftRadius: "20px",
        borderBottomRightRadius: "20px",
    },
    containerGrid: {
        minHeight: "12rem",
        gridTemplateColumns: "repeat(12, auto)",
        display: "grid",
    },
    mainPart: (props) => {
        const getPapeGridTemplate = (index) => {
            if (index === 1) {
                return "repeat(10, 1fr)";
            } else if (index === 2) {
                return "repeat(13, 1fr)";
            } else if (index === 3) {
                return "repeat(8, 1fr)";
            }
        };

        return {
            gridTemplateColumns: getPapeGridTemplate(props.index),
            display: "grid",
            gridColumn: "1 / span 9",
            gap: "1rem",
        };
    },

    title: {
        position: "relative",
        maxWidth: "70%",
        [theme.breakpoints.only("md")]: {
            marginTop: "4rem",
            marginLeft: "1rem",
        },
        "&:before": {
            borderBottomRightRadius: "20px",
            borderTopRightRadius: "20px",
            content: '""',
            position: "absolute",
            display: "block",
            width: "97px",
            height: "3px",
            bottom: "-6px",
            left: 0,
            backgroundColor: theme.palette.common.white,
            transformOrigin: "top left",
            transition: "transform 0.3s ease",
        },
    },

    links: {
        color: "inherit",
        textDecoration: "none",
        lineHeight: "2.2",
        cursor: "pointer",
        display: "block",
        "&:hover": {
            fontWeight: 800,
            textDecorationLine: "underline",
        },
    },
    linkViewMore: {
        cursor: "pointer",
        textDecorationLine: "none",
        display: "flex",
        color: theme.palette.primary.light,
        lineHeight: "2.2",
        marginTop: 3,
        "&:hover": {
            fontWeight: 800,
            textDecorationLine: "underline",
        },
    },
    linksIcon: {
        width: 25,
        marginLeft: 12,
        marginTop: 3,
    },
    submenu: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        testDecoration: "none",
        textDecorationLine: "none",
        color: "inherit",
        "&:hover": {
            textDecorationLine: "underline",
        },
    },
}));

export default function NavbarMenuList(props) {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const [selectedMenuIndex, setSelectedMenuIndex] = useState(undefined);
    const prevOpen = useRef(open);
    const [itemRef, setItemRef] = useState(false);
    const styles = useStyles({ ...props, open });
    const { width } = useWindowDimensions();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getPapeWidth = (index) => {
        if (index === 1) {
            return "630px";
        } else if (index === 2) {
            return "830px";
        } else if (index === 3) {
            return "575px";
        }
    };

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    if (!props.data?.mainTitle) return null;

    return (
        <div
            onMouseLeave={handleClose}
            style={{
                display: width >= 960 ? "flex" : "none",
                position: "relative",
                height: "100%",
                alignItems: "center",
                flex: width >= 1280 && 1,
                width: props.width,
                justifyContent: "center",
            }}
            className={props.className}
        >
            <div style={{ height: "100%", width: "100%" }}>
                <Box
                    style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}
                    ref={anchorRef}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onMouseOver={handleOpen}
                >
                    {props.data.to ? (
                        <ListItem className={styles.link} variant="h5" component={Link} to={props.data.to}>
                            {props.data.mainTitle}
                        </ListItem>
                    ) : (
                        <ListItem className={styles.link} variant="h5">
                            {props.data.mainTitle}
                        </ListItem>
                    )}
                </Box>
                {props.menuItems?.length > 0 && (
                    <Popper
                        {...props}
                        style={{ position: "fixed" }}
                        container={<Box id="test" />}
                        open={open}
                        anchorEl={anchorRef.current}
                        placement={props.placement}
                        disablePortal
                        transition
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow {...TransitionProps}>
                                <Paper className={styles.paper} onMouseLeave={handleClose}>
                                    {/* <ClickAwayListener> */}
                                    <Box className={styles.containerGrid}>
                                        <Box className={styles.mainPart} style={{ width: getPapeWidth(props.index) }}>
                                            <Box className={props.classNameContainerTitle}>
                                                <Typography variant="h3" className={styles.title}>
                                                    {props.titlePopper}
                                                </Typography>
                                            </Box>
                                            <Box className={props.classNameContainerLinks}>
                                                {props.menuItems?.map((menuItem, i) => {
                                                    const key = `menuItem-${i}`;
                                                    return (
                                                        <Box key={key}>
                                                            <Typography
                                                                style={{
                                                                    fontWeight:
                                                                        selectedMenuIndex === i && itemRef && "900",
                                                                    textDecorationLine:
                                                                        selectedMenuIndex === i &&
                                                                        itemRef &&
                                                                        "underline",
                                                                }}
                                                                onMouseEnter={() => {
                                                                    setItemRef(true);
                                                                    setSelectedMenuIndex(i);
                                                                }}
                                                                className={styles.links}
                                                                variant="h5"
                                                                onMouseLeave={() => setItemRef(false)}
                                                                onClick={() => handleClose()}
                                                                component={Link}
                                                                to={menuItem.to}
                                                            >
                                                                {menuItem.title}
                                                            </Typography>
                                                        </Box>
                                                    );
                                                })}

                                                {props.viewMore && (
                                                    <Box>
                                                        <Typography
                                                            variant="h5"
                                                            component={Link}
                                                            to={props.viewMore}
                                                            className={styles.linkViewMore}
                                                        >
                                                            <Trans>Tout voir</Trans>
                                                            <img
                                                                src={ArrowViewMore}
                                                                className={styles.linksIcon}
                                                                alt="icone flèche Tout voir"
                                                            />
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </Box>
                                        </Box>
                                        {selectedMenuIndex !== undefined &&
                                            itemRef &&
                                            props.menuItems[selectedMenuIndex]?.subMenus?.length > 0 && (
                                                <Box
                                                    className={props.classNameContainerSubMenus}
                                                    onMouseEnter={() => setItemRef(true)}
                                                    onMouseLeave={() => setItemRef(false)}
                                                >
                                                    {props.menuItems[selectedMenuIndex]?.subMenus?.map((subMenu, i) => {
                                                        let key = `subMenu-${i}`;

                                                        return (
                                                            <Typography
                                                                className={styles.submenu}
                                                                variant="h6"
                                                                key={key}
                                                                onClick={() => handleClose()}
                                                                component={Link}
                                                                to={subMenu.to}
                                                            >
                                                                {subMenu.title}
                                                            </Typography>
                                                        );
                                                    })}
                                                </Box>
                                            )}
                                    </Box>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                )}
            </div>
        </div>
    );
}

NavbarMenuList.propTypes = {
    selected: bool,
    scroll: bool,
    onClick: func,
    menuItems: array,
    subMenuItems: array,
    iconType: any,
    className: object,
    titlePopper: string,
    width: string,
    viewMore: string,
    classNameContainerTitle: string,
    classNameContainerLinks: string,
    classNameContainerSubMenus: string,
    placement: string,
    index: number,
    indexMap: number,
    data: object,
};
