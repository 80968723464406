/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import React, { useState } from "react";
import { makeStyles, Box, Typography, Link } from "@material-ui/core";
import Arrow from "../../Components/Arrow/Arrow";
import pheno from "../../assets/Wine/pheno.svg";
import WinePhenologicalStagesPopin from "./WinePhenologicalStagesPopin";
import PropTypes from "prop-types";
import theme from "../../globalTheme";
import { firstLetterUppercase } from "../../helpers/helpers";
import { getSentence } from "./vintageHelpers";
import { Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        border: "0.4px solid",
        borderRadius: "5px",
        borderColor: theme.palette.secondary.dark,
        position: "relative",
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        paddingTop: 0,
        height: "calc(100% - 32px)",
        [theme.breakpoints.only("xs")]: {
            height: "100%",
            padding: 0,
        },
    },
    typoContainer: {
        position: "relative",
        top: "-16px",
        left: "0px",
        display: "flex",
        alignItems: "center",
    },
    title: {
        color: theme.palette.common.black,
        zIndex: 2,
        backgroundColor: theme.palette.common.white,
        padding: "0px 2px",
    },
    typo: {
        color: theme.palette.primary.light,
        fontSize: "18px",
    },
    stagesContainer: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        justifyContent: "space-around",
    },
    stageContainer: {
        width: "162px",
        marginBottom: theme.spacing(2),
        margin: "0px 20px",
    },
    imgContainer: {
        height: "130px",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
    },
    typoTitle: {
        fontSize: "16px",
        color: theme.palette.secondary.dark,
        textAlign: "center",
    },
}));

const WinePhenologicalStages = (props) => {
    const styles = useStyles(props);
    const [open, setOpen] = useState(false);

    return (
        <Box className={styles.container}>
            <Box className={styles.typoContainer}>
                <img
                    src={pheno}
                    alt="title_icon"
                    style={{ backgroundColor: "white", paddingRight: "4px" }}
                />
                <Typography
                    variant="h3"
                    className={styles.title}
                    style={{
                        fontWeight: "bold",
                        fontSize: "18px",
                        color: "#000000",
                    }}
                >
                    <Trans>Stades phénologiques</Trans>
                </Typography>
            </Box>
            <Box className={styles.stagesContainer}>
                {props.phenologicalStagesByGrapes?.map((el, i) => {
                    if (i < 4) {
                        return (
                            <Box className={styles.stageContainer}>
                                <Box className={styles.imgContainer}>
                                    <img
                                        src={el?.image?.data}
                                        style={{ height: "100%" }}
                                        alt="stages_img"
                                    />
                                </Box>
                                {el?.stage && (
                                    <Typography
                                        className={styles.typoTitle}
                                        style={{
                                            fontSize: "14px",
                                            color: "#915868",
                                        }}
                                    >
                                        {firstLetterUppercase(el?.stage)}
                                    </Typography>
                                )}
                                <Typography
                                    style={{
                                        textAlign: "center",
                                        color: theme.palette.primary.dark,
                                        fontSize: "16px",
                                    }}
                                >
                                    {getSentence(el)}
                                </Typography>
                            </Box>
                        );
                    }
                })}
            </Box>
            <Arrow
                rotation="105"
                arrowPosition="start"
                style={{ margin: 0, marginLeft: theme.spacing(1) }}
            >
                <Link
                    variant="h6"
                    color="primary"
                    underline="always"
                    onClick={() => setOpen(true)}
                    style={{
                        cursor: "pointer",
                        marginLeft: "3px",
                        color: theme.palette.primary.light
                    }}
                >
                    <Trans>Voir les stades phénologiques détaillés par cépage</Trans>
                </Link>
            </Arrow>
            {open ? (
                <WinePhenologicalStagesPopin
                    background={3}
                    onClose={() => setOpen(false)}
                    open={open}
                    byGrapes
                    phenologicalStages={props.phenologicalStagesByGrapes}
                />
            ) : (
                ""
            )}
        </Box>
    );
};

WinePhenologicalStages.propTypes = {
    phenologicalStagesByGrapes: PropTypes.array,
};

export default WinePhenologicalStages;
