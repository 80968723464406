// Setup:
import React from "react";
import PropTypes, { string } from "prop-types";

// Components:
import BaseButton from "../../Components/Buttons/BaseButton";
import cross from "../../assets/cross.svg";
import ControlledSwitch from "../../Components/Switch/ControlledSwitch";
import EstateZoomPopin from "../Popins/EstateZoomPopin";
import PaperZoomPopin from "../Popins/PaperZoomPopin";
import theme from "../../globalTheme";

import { Box, Grid, Hidden, IconButton, Link, makeStyles, Typography } from "@material-ui/core";

// Styles:
const useStyles = makeStyles((theme) => ({
    link: {
        color: theme.palette.primary.dark,
        cursor: "pointer",
        textDecoration: "underline",
    },
    closeButton: {
        color: theme.palette.primary.dark,
        fontWeight: "bold",
        fontSize: "18px",
        padding: "0px",
        height: "0px",
    },
    container: {
        display: "flex",
        flexDirection: "column",
        borderRadius: "20px",
        boxShadow: "0px 3px 76px #00000014",
        backgroundColor: "white",
        transition: "padding .6s ease-out",
        padding: "32px",
        marginBottom: "4rem",
    },
    discountBadge: {
        borderRadius: "4px",
        padding: "2px 4px",
        fontSize: "9px",
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.main,
        [theme.breakpoints.down("sm")]: {
            marginTop: "1rem",
        },
    },
    containerDiscount: {
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
        },
    },
    button_next: {
        [theme.breakpoints.down("sm")]: {
            width: "86px",
            minWidth: "auto",
        },
    },
}));

// Methods:
const getButtonLabel = (onClickNext, buttonLabel, disabled, type) => (
    <Box justifyContent="flex-end" display="flex" marginTop="32px">
        <BaseButton
            type={type}
            disabled={disabled}
            color="primary"
            variant="contained"
            size="small"
            onClick={onClickNext}
        >
            {buttonLabel}
        </BaseButton>
    </Box>
);

const getBackLink = (onClickBack, styles, title) => (
    <Link variant="h6" className={styles.link} component="a" onClick={onClickBack}>
        {title || "Retour"}
    </Link>
);

const getCrossButton = () => (
    <IconButton aria-label="close">
        <img src={cross} alt="close_cross" />
    </IconButton>
);

const getTypo = (titleVariant, title) => (
    <Typography align="center" variant={titleVariant} style={{ marginBottom: title ? "16px" : "0" }}>
        {title}
    </Typography>
);

const getZoomPopinButton = (selectType, selectPack) => {
    if (selectType === "estate") {
        return (
            <EstateZoomPopin
                clickableElement={
                    <BaseButton color="primary" variant="outlined" size="small" style={{ marginRight: "16px" }}>
                        Aperçu
                    </BaseButton>
                }
                subscriptionStateSelectedPack={selectPack}
            />
        );
    } else if (selectType === "paper") {
        return (
            <PaperZoomPopin
                clickableElement={
                    <BaseButton color="primary" variant="outlined" size="small" style={{ marginRight: "16px" }}>
                        Aperçu
                    </BaseButton>
                }
                subscriptionStateSelectedPack={selectPack}
            />
        );
    }
};

const SubscriptionContainer = (props) => {
    const styles = useStyles();
    return (
        <Box
            className={styles.container}
            style={{
                ...props.style,
            }}
        >
            <Box>
                <Grid
                    container
                    style={{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "space-between" }}
                >
                    <Grid item xs={12} sm={2} md={2}>
                        {props.backLink ? getBackLink(props.onClickBack, styles, props.titleButtonBack) : null}
                    </Grid>

                    <Grid item xs={12} sm={8} md={8} style={props.titleStyle}>
                        {getTypo(props.titleVariant, props.title)}
                    </Grid>
                    {props.buttonNext && (
                        <Grid item xs={2} sm={2} md={2}>
                            <Box justifyContent="flex-end" display="flex">
                                <BaseButton
                                    classes={styles.button_next}
                                    type={props.type}
                                    disabled={props.disabled}
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    onClick={props.onClickNext}
                                >
                                    {props.buttonLabel}
                                </BaseButton>
                            </Box>
                        </Grid>
                    )}
                    <Grid
                        item
                        xs={12}
                        sm={props.buttonNext ? 12 : 2}
                        md={props.buttonNext ? 12 : 2}
                        className={styles.containerDiscount}
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-start",
                        }}
                    >
                        {props.priceSwitch && (
                            <ControlledSwitch
                                switchCheck={props.switchCheck}
                                handleSwitchChange={() => props.handleSwitchChange(!props.switchCheck)}
                                rightLabel="Facturation annuelle"
                                leftLabel="Facturation mensuelle"
                                rightLabelExtra={
                                    <Typography
                                        className={styles.discountBadge}
                                        style={{ opacity: props.switchCheck ? "1" : "0.5" }}
                                    >
                                        Économisez jusqu&apos;à 14 %
                                    </Typography>
                                }
                            />
                        )}
                    </Grid>
                </Grid>

                <Box>{props.backButton && getCrossButton()}</Box>
            </Box>
            {props.children}
            <Box style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
                <Hidden mdUp>
                    {props.subscriptionStateSelectedPack
                        ? getZoomPopinButton(props.typeSubscriptionPopin, props.subscriptionStateSelectedPack)
                        : null}
                </Hidden>
                {props.buttonLabel
                    ? getButtonLabel(props.onClickNext, props.buttonLabel, props.disabled, props.type)
                    : null}
            </Box>
        </Box>
    );
};

SubscriptionContainer.propTypes = {
    title: PropTypes.string,
    titleVariant: PropTypes.oneOf(["h2", "h3"]),
    buttonLabel: PropTypes.string,
    backLink: PropTypes.bool,
    backButton: PropTypes.bool,
    onClickNext: PropTypes.func,
    onClickBack: PropTypes.func,
    children: PropTypes.any,
    maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
    style: PropTypes.object,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    titleStyle: PropTypes.object,
    priceSwitch: PropTypes.bool,
    switchCheck: PropTypes.bool,
    handleSwitchChange: PropTypes.func,
    subscriptionStateSelectedPack: PropTypes.number,
    typeSubscriptionPopin: PropTypes.string,
    titleButtonBack: string,
    buttonNext: string,
};

export default SubscriptionContainer;
