import { Trans, t } from "@lingui/macro";
import { Box, Card, CardContent, Typography, makeStyles } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import { useCustomHistory } from "hooks";
import { sortBy } from "lodash";
import PropTypes, { object } from "prop-types";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loading from "../../Components/Loader/Loading";
import Tooltip from "../../Components/Tooltips/Tooltip";
import flagArabe from "../../assets/searchEngine/activity/flag/arabe.svg";
import flagEngland from "../../assets/searchEngine/activity/flag/england.svg";
import flagFrance from "../../assets/searchEngine/activity/flag/france.svg";
import flagGermany from "../../assets/searchEngine/activity/flag/germany.svg";
import flagCantonais from "../../assets/searchEngine/activity/flag/hk.svg";
import flagItaly from "../../assets/searchEngine/activity/flag/italy.svg";
import flagJapon from "../../assets/searchEngine/activity/flag/japon.svg";
import flagKorea from "../../assets/searchEngine/activity/flag/korea.svg";
import flagMandarin from "../../assets/searchEngine/activity/flag/mandarin.svg";
import flagNetherland from "../../assets/searchEngine/activity/flag/netherland.svg";
import flagPortugal from "../../assets/searchEngine/activity/flag/portugal.svg";
import flagRussia from "../../assets/searchEngine/activity/flag/russia.svg";
import flagSpain from "../../assets/searchEngine/activity/flag/spain.svg";
import flagTurkey from "../../assets/searchEngine/activity/flag/turkey.svg";
import flagVietnam from "../../assets/searchEngine/activity/flag/vietnam.svg";
import close from "../../assets/searchEngine/activity/icon/close.svg";
import handicap from "../../assets/searchEngine/activity/icon/handicap.svg";
import money from "../../assets/searchEngine/activity/icon/money.svg";
import people from "../../assets/searchEngine/activity/icon/people.svg";
import activityDefault from "../../assets/searchEngine/activity/thumbs/activity-default.svg";
import { useGetActivityCardInformationQuery, useGetEnumByFieldQuery } from "../../generated/graphql";
import theme from "../../globalTheme";
import {
    activityPersons,
    formatAndAddUnit,
    getUserLocale,
    urlFormatted,
    useWindowDimensions,
} from "../../helpers/helpers";

const useStyles = makeStyles(() => ({
    root: (props) => ({
        textDecoration: "none",
        background: theme.palette.common.white,
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.08)",
        borderRadius: 10,
        marginBottom: props?.marginBottom ?? "1rem",
        cursor: "pointer",
    }),
    cardContent: {
        padding: "0",
        paddingBottom: "15px !important",
        height: 365,
    },
    contentTop: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.palette.common.white,
        borderRadius: 10,
    },
    missing: {
        position: "absolute",
        top: 3,
        right: 7,
        background: theme.palette.primary.light,
        borderRadius: 16,
        padding: "5px 16px",
    },
    missingTypo: {
        color: theme.palette.common.white,
    },
    status: {
        boxSizing: "border-box",
        position: "absolute",
        bottom: 0,
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        width: "100%",
        padding: "2px 16px",
        height: 25,
    },
    name: {
        color: theme.palette.primary.dark,
        height: 48,
    },
    category: {
        color: theme.palette.common.white,
        fontWeight: 700,
        lineHeight: 1.5,
    },
    statusIcons: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusIconContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.primary.light,
        width: 20,
        height: 20,
        marginLeft: 4,
        border: "none",
        borderRadius: 4,
    },
    duration: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        marginLeft: 4,
        height: 20,
        borderRadius: 4,
        paddingRight: 21,
        paddingLeft: 21,
        lineHeight: "18px",
    },
    ContentPrimary: {
        display: "flex",
        flexDirection: "column",
        margin: "6px 16px",
    },
    title: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        color: theme.palette.primary.dark,
        marginBottom: 8,
        minHeight: 49,
    },
    estateContent: {
        color: theme.palette.primary.dark,
        lineHeight: "17px",
    },
    communeContent: {
        color: theme.palette.primary.dark,
        lineHeight: "17px",
    },
    priceContent: {
        color: theme.palette.primary.dark,
    },
    poepleContent: {
        color: theme.palette.primary.dark,
    },
    contentBottom: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        padding: "0 16px",
    },
    iconWine: {
        width: "7.5px !important",
        height: "15px !important",
        marginRight: 1,
    },
    link: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        textDecoration: "none",
    },
    viewMore: {
        color: theme.palette.secondary.dark,

        "&:hover": {
            textDecoration: "underline",
        },
    },
    viewMoreArrow: {
        color: theme.palette.secondary.dark,
        marginLeft: 5,
        fontSize: "16px",
    },
}));

const SearchResultActivityCard = ({ activity, cardWidthFixed, customWidth, customHeight }) => {
    const styles = useStyles();
    const { width } = useWindowDimensions();
    const { data, loading } = useGetActivityCardInformationQuery({
        variables: {
            activityId: activity?._id,
        },
    });

    const locale = useSelector(getUserLocale);

    const { data: { GetEnumByField: timeUnitPluralEnum = [] } = {} } = useGetEnumByFieldQuery({
        variables: { field: "TIME_UNIT_PLURAL" },
    });

    const { data: { GetEnumByField: timeUnitEnum = [] } = {} } = useGetEnumByFieldQuery({
        variables: { field: "TIME_UNIT" },
    });

    const getFlag = (lang) => {
        switch (lang) {
            case "Français":
                return flagFrance;
            case "Espagnol":
                return flagSpain;
            case "Italien":
                return flagItaly;
            case "Anglais":
                return flagEngland;
            case "Néerlandais":
                return flagNetherland;
            case "Allemand":
                return flagGermany;
            case "Japonais":
                return flagJapon;
            case "Cantonais":
                return flagCantonais;
            case "Mandarin":
                return flagMandarin;
            case "Arabe":
                return flagArabe;
            case "Coréen":
                return flagKorea;
            case "Portugais":
                return flagPortugal;
            case "Russe":
                return flagRussia;
            case "Turc":
                return flagTurkey;
            case "Vietnamien":
                return flagVietnam;
            default:
                break;
        }
    };

    const formatDuration = () => {
        const isPlural = activity?.duration > 1;
        const enums = isPlural ? timeUnitPluralEnum : timeUnitEnum;
        return formatAndAddUnit(activity?.duration, activity?.durationUnit, enums, { locale, isPlural });
    };

    const getLimitName = () => {
        if (cardWidthFixed) {
            return cardWidthFixed;
        } else {
            if (width > 1280) {
                return 54;
            }

            if (width >= 960 && width < 1280) {
                return 70;
            }

            if (width >= 600 && width < 960) {
                return 70;
            }

            if (width >= 500 && width < 600) {
                return 105;
            }

            if (width < 500) {
                return 125;
            }
        }
    };

    const getActivityImage = () => {
        if (loading) {
            return <Loading loading={true} />;
        }
        if (data?.GetActivityCardInformation?.img?.twicpicURL) {
            return (
                <img
                    src={data?.GetActivityCardInformation?.img?.twicpicURL}
                    style={{ width: "100%", maxHeight: 158 }}
                    alt="activity photo"
                />
            );
        }
        return <img src={activityDefault} style={{ width: "100%", maxHeight: 158 }} alt="activity photo" />;
    };
    const { addLocale } = useCustomHistory();
    const prices = sortBy(activity?.prices);
    const min = activity?.groupSize?.[0] ?? null;
    const max = activity?.groupSize?.[1] ?? null;

    const redirectToActivity = addLocale(
        `/${urlFormatted(activity?.wineEntityShortId, activity?.wineEntityName)}/activites/${urlFormatted(
            activity?.activityShortId,
            activity?.name
        )}`
    );

    const ViewMore = () => (
        <Link className={styles.link} to={redirectToActivity}>
            <Typography variant="subtitle1" className={styles.viewMore}>
                <Trans>Découvrir</Trans>
            </Typography>
            <ArrowForwardIcon className={styles.viewMoreArrow} />
        </Link>
    );

    return (
        <Card
            component={Link}
            className={styles.root}
            style={{ width: customWidth ?? "auto", height: customHeight ?? "auto" }}
            to={redirectToActivity}
        >
            <CardContent className={styles.cardContent}>
                <Box className={styles.contentTop}>
                    {getActivityImage()}

                    {activity?.missing && (
                        <Box className={styles.missing}>
                            <Typography variant="h6" className={styles.missingTypo}>
                                <Trans>Manquant</Trans> :{" "}
                                <span style={{ position: "relative" }}>
                                    {activity?.missing}
                                    <span
                                        style={{
                                            position: "absolute",
                                            top: "50%",
                                            left: 0,
                                            right: 0,
                                            height: "1.5px",
                                            marginTop: "1px",
                                            backgroundColor: theme.palette.common.white,
                                        }}
                                    />
                                </span>
                            </Typography>
                        </Box>
                    )}

                    <Box className={styles.status}>
                        <Typography variant="h6" className={styles.category}>
                            {activity?.category?.toUpperCase()}
                        </Typography>

                        <Box className={styles.statusIcons}>
                            {activity?.duration && (
                                <Typography variant="h6" className={styles.duration}>
                                    {formatDuration(activity?.duration)}
                                </Typography>
                            )}

                            {activity?.private && (
                                <Tooltip color="primary" title={t`Activité privatisée`} arrow>
                                    <Box className={styles.statusIconContainer} onClick={() => {}}>
                                        <img
                                            src={close}
                                            style={{ width: 10, height: 14 }}
                                            alt="icon personnes atteintes de handicap"
                                        />
                                    </Box>
                                </Tooltip>
                            )}

                            {activity?.handicap && (
                                <Tooltip
                                    color="primary"
                                    title={
                                        <>
                                            <Trans>Accueil des personnes atteintes de handicap :</Trans>
                                            <ul style={{ paddingLeft: 24 }}>
                                                <li>
                                                    <Trans>handicap moteur</Trans>
                                                </li>
                                                <li>
                                                    <Trans>handicap visuel</Trans>
                                                </li>
                                                <li>
                                                    <Trans>handicap mental</Trans>
                                                </li>
                                            </ul>
                                        </>
                                    }
                                    arrow
                                >
                                    <Box className={styles.statusIconContainer} onClick={() => {}}>
                                        <img src={handicap} style={{ width: 10, height: 14 }} alt="icon privée" />
                                    </Box>
                                </Tooltip>
                            )}
                        </Box>
                    </Box>
                </Box>

                <Box className={styles.ContentPrimary}>
                    <Box className={styles.title}>
                        {activity.name?.length >= getLimitName() ? (
                            <Tooltip color="primary" title={activity?.name} arrow>
                                <Typography
                                    variant="h4"
                                    className={styles.name}
                                    style={{
                                        display: "inline-flex",
                                        cursor: "pointer",
                                        marginTop: 0,
                                        marginBottom: 0,
                                        color: theme.palette.primary.dark,
                                    }}
                                >
                                    {activity.name.slice(0, getLimitName())} ...
                                </Typography>
                            </Tooltip>
                        ) : (
                            <Typography variant="h4" className={styles.name}>
                                {activity?.name}
                            </Typography>
                        )}
                    </Box>

                    <Box
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "center",
                            marginBottom: 17,
                            height: 34,
                        }}
                    >
                        {activity?.wineEntityName && (
                            <Typography variant="h6" className={styles.estateContent}>
                                {activity?.wineEntityName}
                            </Typography>
                        )}

                        {activity?.wineEntityName && activity?.commune && (
                            <Brightness1Icon
                                style={{
                                    color: theme.palette.primary.light,
                                    fontSize: "6px",
                                    padding: "0 5px",
                                }}
                            />
                        )}

                        {activity?.commune && (
                            <Typography variant="h6" className={styles.communeContent}>
                                {activity?.commune}
                            </Typography>
                        )}
                    </Box>

                    <Box style={{ display: "flex", alignItems: "center", minHeight: 18 }}>
                        {prices?.length > 0 && (
                            <>
                                <img src={money} style={{ width: 18, marginRight: 6 }} alt="icone prix" />
                                <Typography variant="h6" className={styles.priceContent}>
                                    {prices[0] === prices[prices?.length - 1]
                                        ? prices[0]
                                        : prices[0] + " - " + prices[prices?.length - 1]}
                                    €
                                </Typography>
                            </>
                        )}
                    </Box>

                    <Box style={{ display: "flex", alignItems: "center", minHeight: 18 }}>
                        {activityPersons(min, max) && (
                            <>
                                <img src={people} style={{ width: 18, marginRight: 6 }} alt="icone personnes" />
                                <Typography variant="h6" style={{ color: theme.palette.primary.dark }}>
                                    {activityPersons(min, max)}
                                </Typography>
                            </>
                        )}
                    </Box>
                </Box>

                <Box className={styles.contentBottom}>
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            flexWrap: "wrap",
                            gap: 4,
                            height: 21,
                        }}
                    >
                        {activity?.languages &&
                            activity?.languages?.map((lang, i) => (
                                <Tooltip key={lang} color="primary" title={activity.labelLanguages?.[i]} arrow>
                                    <img
                                        src={getFlag(lang)}
                                        style={{
                                            width: 21,
                                            height: 21,
                                            borderRadius: "100%",
                                            border: `0.2px solid ${theme.palette.secondary.main}`,
                                        }}
                                        alt={`drapeau ${lang}`}
                                    />
                                </Tooltip>
                            ))}
                    </Box>

                    {activity?.languages?.length <= 7 && (
                        <Box
                            style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end", height: 42 }}
                        >
                            {ViewMore()}
                        </Box>
                    )}
                </Box>

                {activity?.languages?.length > 7 && (
                    <Box style={{ display: "flex", justifyContent: "flex-end", height: 36, padding: "0px 16px" }}>
                        {ViewMore()}
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};

SearchResultActivityCard.propTypes = {
    className: PropTypes.object,
    customWidth: PropTypes.number,
    customHeight: PropTypes.number,
    marginBottom: PropTypes.int,
    cardWidthFixed: PropTypes.number,
    activity: object,
};

export default SearchResultActivityCard;
