import { Box, makeStyles } from "@material-ui/core";
import theme from "../globalTheme";
import { array, bool, func, node, object, string } from "prop-types";
import { useEffect, useState } from "react";

const useStyles = makeStyles(() => ({
    slideContainer: (props) => {
        return {
            width: "29px",
            height: "13px",
            borderRadius: "50px",
            border: `1px solid ${props.disabled ? theme.palette.switch.disabled : theme.palette.switch.neutral}`,
            position: "relative",
        };
    },
    slider: (args) => {
        const leftPosition = args.choices[0];
        const rightPosition = args.choices[2];
        const slides = () => {
            if (args.currentState === leftPosition) {
                return 0;
            } else if (args.currentState === rightPosition) {
                return "calc(100% - 13.5px)";
            } else {
                return "calc(50% - 7px)";
            }
        };

        return {
            width: "11px",
            height: "11px",
            borderRadius: "50%",
            border: `1px solid ${theme.palette.common.white}`,
            background: "white",
            position: "absolute",
            transition: "0.25s",
            left: slides(),
        };
    },
}));

const SwitchThreeState = (props) => {
    const [currentState, setCurrentState] = useState(props.currentState);
    const [prevState, setPrevState] = useState(props.choices[0]);
    const styles = useStyles({ ...props, currentState });

    useEffect(() => {
        if (props.currentState === props.choices[1]) {
            setCurrentState(props.choices[1]);
        }
    }, [props.currentState]);

    const handleSlideClick = () => {
        switch (currentState) {
            case props.choices[0]: {
                setCurrentState(props.choices[1]);
                setPrevState(props.choices[0]);
                props.onChange(props.choices[1]);

                break;
            }
            case props.choices[1]: {
                setCurrentState(prevState === props.choices[0] ? props.choices[2] : props.choices[0]);
                setPrevState(props.choices[1]);
                props.onChange(prevState === props.choices[0] ? props.choices[2] : props.choices[0]);

                break;
            }
            case props.choices[2]: {
                setCurrentState(props.choices[1]);
                setPrevState(props.choices[2]);
                props.onChange(props.choices[1]);
                break;
            }
        }
    };
    const getBgSlideContainer = () => {
        switch (currentState) {
            case props.choices[2]:
                return theme.palette.success.dark;

            case props.choices[0]:
                return theme.palette.error.dark;

            default:
                return theme.palette.switch.neutral;
        }
    };

    return (
        <Box
            className={props.boxClassName}
            onClick={() => {
                if (!props.disabled) handleSlideClick();
            }}
            style={{
                ...props?.style,
                cursor: "pointer",
            }}
        >
            <div
                className={styles.slideContainer}
                onClick={() => {
                    if (!props.disabled) handleSlideClick();
                }}
                style={{ background: props.disabled ? theme.palette.switch.disabled : getBgSlideContainer() }}
            >
                <div className={[styles.slider, currentState].join(" ")} />
            </div>
            {props.legend}
        </Box>
    );
};

SwitchThreeState.propTypes = {
    choices: array,
    currentState: string,
    onChange: func,
    legend: node,
    boxClassName: object,
    style: object,
    disabled: bool,
};

export default SwitchThreeState;
