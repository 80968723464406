import { useEffect, useState } from "react";
import Fade from "@material-ui/core/Fade";
import {
    makeStyles,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Link,
    Grid,
    Box,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { bool, func, object, string } from "prop-types";
import { getIdentityType, getPackType } from "../constants";
import PurchaseDetailsBlock from "../Components/Summary/PurchaseDetailsBlock";
import BillingInformationsBlock from "../Components/Summary/BillingInformationsBlock";
import ShortSummaryBlock from "../Components/Summary/ShortSummaryBlock";
import { getPricesWithOption } from "./WebSubscriptionContainer";
import PromoCode from "Dashboard/Components/Payment/PromoCode";
import { SUBSCRIPTION_TYPES } from "config/constants";

const useStyles = makeStyles((theme) => ({
    accordion: () => ({
        width: "auto",
        display: "flex",
        flexDirection: "column",
        transition: "padding .6s ease-out",
        padding: "32px",
        borderRadius: "20px",
        boxShadow: "0px 3px 76px #00000014",
        backgroundColor: "white",
        "&.MuiAccordion-rounded": {
            borderRadius: "20px",
        },
        "&.Mui-expanded": {
            margin: "0",
        },
        "&.MuiAccordion-root:before": {
            height: "0px",
        },
        "& .MuiAccordionSummary-content": {
            margin: "0px",
            marginTop: "10px",
            display: "block",
            textAlign: "center",
        },
    }),
    accordionSummary: {
        width: "100%",
        padding: 0,
        display: "flex",
        alignItems: "flex-start",
        position: "relative",
    },
    summaryToHide: {
        marginTop: theme.spacing(0),
        // ...theme.paddings.default.card,
    },
    link: {
        color: theme.palette.primary.dark,
        cursor: "pointer",
        textDecoration: "underline",
        fontFamily: theme.typography.h6.fontFamily,
    },
    expandIcon: {
        color: theme.palette.common.black,
        fontSize: "24px",
    },
    AccordionDetails: {
        width: "auto",
        display: "flex",
        flexDirection: "column",
        padding: 0,
    },
    summaryToHide__wrapper: {
        gap: "1rem",

        [theme.breakpoints.up("sm")]: {
            flexWrap: "wrap",
        },
        [theme.breakpoints.up("md")]: {
            flexWrap: "nowrap",
        },
    },
    content_short_summary: {
        display: "grid",
        gridTemplateColumns: "repeat(12,1fr)",
    },
    summary_block: {
        gridColumn: "3 / span 5",
        padding: "20px 0",

        [theme.breakpoints.up("md")]: {
            gridColumn: "5 / span 3",
        },
    },
    summary_block_web: {
        gridColumn: "auto / span 3",
        padding: "20px 0",
    },
}));

const getTitle = (title, onClickBack, styles, showSummary) => {
    return (
        <Grid container justify="space-between" xs={12} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
            <Grid item style={{ display: "flex" }}>
                <Link variant="h6" className={styles.link} component="a" onClick={onClickBack}>
                    Retour
                </Link>
            </Grid>
            <Grid item style={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="h3">{title}</Typography>
            </Grid>
            <Grid item style={{ display: "flex" }}>
                <ExpandMoreIcon className={styles.expandIcon} style={{ transform: !showSummary && "rotate(180deg)" }} />
            </Grid>
        </Grid>
    );
};

const SubscriptionAccordion = (props) => {
    const styles = useStyles(props);
    const [showSummary, setShowSummary] = useState(true);

    const role = getIdentityType(props.subscriptionState?.selectedRole);
    const webSub = getPackType(props.subscriptionState?.selectedPack);

    const price = () =>
        getPricesWithOption(
            webSub,
            props.switchCheck,
            props.withPlus,
            props.subscriptionState.selectedPack === SUBSCRIPTION_TYPES.essential ? 200 : 150
        ).slice(0, -5);

    const discount = () => {
        if (props.switchCheck && webSub?.discount) {
            return price() - 1000;
        } else {
            return price();
        }
    };

    useEffect(() => {
        props.setAmountToPay(discount());
    }, [props.switchCheck]);

    return (
        <Accordion
            className={styles.accordion}
            elevation={0}
            TransitionProps={{
                timeout: {
                    enter: 400,
                    exit: 400,
                },
                unmountOnExit: true,
            }}
            onChange={() => setShowSummary(!showSummary)}
        >
            <AccordionSummary className={styles.accordionSummary}>
                {/* TITLE AND BACK */}
                {getTitle("Récapitulatif avant paiement", props.onClickBack, styles, showSummary)}
                {/* SUMMARY */}
                {showSummary && (
                    <Box className={styles.summaryToHide}>
                        {/* SUMMARY CARDS */}
                        <Box className={styles.content_short_summary}>
                            <ShortSummaryBlock
                                boxStyle={styles.summary_block}
                                mode="light"
                                type="role"
                                value={role}
                                title="Vous souscrivez pour"
                                subscriptionState={props.subscriptionState}
                                marginLeft="0"
                            />
                            <ShortSummaryBlock
                                boxStyle={styles.summary_block_web}
                                mode="light"
                                type="web"
                                value={webSub}
                                title="Abonnement"
                                switchCheck={props.switchCheck}
                                withPlus={props.withPlus}
                            />
                        </Box>
                    </Box>
                )}
            </AccordionSummary>

            <Fade in={!showSummary} timeout={{ enter: 900, exit: 900 }} style={{ marginTop: "1rem" }}>
                <AccordionDetails className={styles.AccordionDetails} style={{ paddingTop: 0 }}>
                    <Grid container justify="space-around" xs={12} className={styles.summaryToHide}>
                        {/* SUMMARY CARDS */}
                        <Box
                            className={styles.summaryToHide__wrapper}
                            style={{ display: "flex", width: "100%", justifyContent: "space-between" }}
                        >
                            <ShortSummaryBlock
                                mode="full"
                                type="role"
                                value={role}
                                title="Vous souscrivez pour"
                                subscriptionState={props.subscriptionState}
                                marginLeft="0"
                            />
                            <ShortSummaryBlock
                                mode="full"
                                type="web"
                                value={webSub}
                                title="Abonnement"
                                switchCheck={props.switchCheck}
                                // discountPrice={webSub?.discount}
                                withPlus={props.withPlus}
                            />
                        </Box>
                    </Grid>
                    {/* PURCHASE RECAP CARD */}
                    <PurchaseDetailsBlock
                        priceAfterDiscount={discount()}
                        webSub={webSub}
                        switchCheck={props.switchCheck}
                        withPlus={props.withPlus}
                    />
                    <PromoCode />
                    {/* BILLING INFORMATIONS CARD */}
                    <BillingInformationsBlock subscriptionState={props.subscriptionState} commune={props.commune} />
                </AccordionDetails>
            </Fade>
        </Accordion>
    );
};

SubscriptionAccordion.propTypes = {
    subscriptionState: object,
    commune: string,
    setAmountToPay: func,
    onClickBack: func,
    switchCheck: bool,
    withPlus: bool,
};

export default SubscriptionAccordion;
