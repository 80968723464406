import { useState } from "react";
import { object } from "prop-types";
import { Box, Typography } from "@material-ui/core";

import SelectInput from "../Inputs/SelectInput";
import SimpleInput from "../Inputs/SimpleInput";
import theme from "../../globalTheme";
import TelephoneInput from "../Inputs/TelephoneInput";
import TextareaInput from "../Inputs/TextareaInput";
import BaseCheckbox from "../Inputs/BaseCheckbox";
import BaseButton from "../Buttons/BaseButton";
import { ReCaptchaForm } from "Components/Forms/ReCaptchaForm";
import { useStyles } from "./styles";
import { useSendContactMailMutation } from "generated/graphql";
import MessageSendImg from "../../assets/message-send.svg";
import { t, Trans } from "@lingui/macro";
import Loading from "Components/Loader/Loading";

const ContactForm = (props) => {
    const styles = useStyles();
    const [title, setTitle] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [company, setCompany] = useState("");
    const [email, setEmail] = useState("");
    const [phoneFlag, setPhoneFlag] = useState("fr");
    const [telephone, setPhone] = useState("");
    const [object, setObject] = useState("");
    const [message, setMessage] = useState("");
    const [newsletter, setNewsletter] = useState(false);
    const [messageSent, setMessageSent] = useState(false);

    const phoneNumberErrors = telephone && (telephone?.length !== 14 || telephone[0] !== "0");

    const [sendMail, { loading }] = useSendContactMailMutation();

    // EMAIL CONSTRAINTS
    // eslint-disable-next-line no-useless-escape
    const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const emailError = !email?.match(emailRegex);

    const sendMessage = async () => {
        await sendMail({
            variables: {
                title,
                firstName,
                lastName,
                company,
                emailFrom: email,
                telephone,
                object,
                message,
                newsletter,
            },
        });
        setMessageSent(true);
    };

    return (
        <Box className={`${props.className} ${styles.form_card}`}>
            {loading ? (
                <Loading />
            ) : (
                <>
                    {messageSent ? (
                        // TODO: replace it with proper screen
                        <Box style={{ padding: "1.25rem" }}>
                            <img src={MessageSendImg} alt="icone message envoyé" style={{ marginBottom: 18 }} />

                            <Typography
                                variant="h3"
                                style={{ color: theme.palette.primary.dark, fontWeight: 700, marginBottom: 10 }}
                            >
                                <Trans>Message envoyé !</Trans>
                            </Typography>

                            <Typography
                                variant="h3"
                                style={{
                                    fontFamily: "Mansalva",
                                    color: theme.palette.primary.light,
                                    fontWeight: 400,
                                    textAlign: "center",
                                }}
                            >
                                <Trans>à très vite !</Trans>
                            </Typography>
                        </Box>
                    ) : (
                        <ReCaptchaForm
                            submitButton={(challengeCompleted) => (
                                <BaseButton
                                    disabled={
                                        !firstName ||
                                        !lastName ||
                                        ((!email || emailError) && (phoneNumberErrors || !telephone)) ||
                                        !object ||
                                        !message ||
                                        !challengeCompleted
                                    }
                                    boxStyle={{ gridColumn: "1/span 12" }}
                                    size="small"
                                    color="primary"
                                    fullWidth
                                    variant="contained"
                                    type="submit"
                                >
                                    <Trans>Envoyez votre message</Trans>
                                </BaseButton>
                            )}
                            onSubmit={() => sendMessage()}
                            className={styles.responsivePadding}
                            style={{ recaptcha: { marginBottom: "1rem" } }}
                        >
                            <Box className={styles.grid_content}>
                                <Typography variant="h4" className={styles.section_title}>
                                    <Trans>Vous êtes</Trans>
                                </Typography>
                                {/* TITLE */}
                                <Box className={styles.title_container}>
                                    <SelectInput
                                        enumField="SIMPLE_PERSON_SALUTATION"
                                        onChange={(e) => setTitle(e)}
                                        value={title}
                                        label={t`Titre`}
                                        variant="outlined"
                                    />
                                </Box>
                                {/* FIRSTNAME */}
                                <Box className={styles.firstName_container}>
                                    <SimpleInput
                                        required
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        label={t`Prénom`}
                                    />
                                </Box>
                                {/* LASTNAME */}
                                <Box className={styles.lastName_container}>
                                    <SimpleInput
                                        required
                                        label={t`Nom`}
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                </Box>
                                {/* COMPANY */}
                                <Box className={styles.company_container}>
                                    <SimpleInput
                                        label={t`Société`}
                                        value={company}
                                        onChange={(e) => setCompany(e.target.value)}
                                    />
                                </Box>
                                <Typography variant="h4" className={styles.section_title}>
                                    <Trans>Comment vous contacter ?</Trans>
                                </Typography>
                                <Box className={styles.email_container}>
                                    <SimpleInput
                                        id="username"
                                        name="username"
                                        type="email"
                                        autoComplete={"username"}
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        error={email && emailError ? "Le champs n'est pas valide" : false}
                                        correctValue={email && !emailError}
                                        style={{ width: "100%" }}
                                        disabled={false}
                                        label={t`Adresse e-mail**`}
                                    />
                                </Box>
                                {/* PHONE */}
                                <Box className={styles.phone_container}>
                                    <TelephoneInput
                                        type="tel"
                                        label={t`Téléphone**`}
                                        flagValue={phoneFlag}
                                        flagOnChange={(e) => setPhoneFlag(e)}
                                        phoneValue={telephone}
                                        phoneOnChange={(e) => setPhone(e.target.value)}
                                        error={
                                            phoneNumberErrors
                                                ? "Le numéro doit contenir 10 chiffres et commencer par 0"
                                                : false
                                        }
                                        correctValue={!phoneNumberErrors && telephone}
                                        maxLength={14}
                                    />
                                </Box>

                                <Typography variant="h6" className={styles.asterisk_info}>
                                    <Trans>
                                        <sup style={{ fontFamily: "Lato" }}>(**)</sup> : renseignez au moins un numéro
                                        de téléphone ou une adresse e-mail
                                    </Trans>
                                </Typography>
                                <Typography variant="h4" className={styles.section_title}>
                                    <Trans>Quelle est votre demande ?</Trans>
                                </Typography>
                                <Box className={styles.subject_container}>
                                    <SelectInput
                                        noClear
                                        required
                                        value={object}
                                        onChange={(e) => setObject(e)}
                                        label={t`Sujet de votre message`}
                                    >
                                        {[
                                            t`Poser une question`,
                                            t`Figurer dans Bordeaux et ses vins`,
                                            t`Faire une remarque`,
                                            t`Rejoindre l'équipe`,
                                            t`Autre`,
                                        ]}
                                    </SelectInput>
                                </Box>
                                <Box className={styles.message_container}>
                                    <TextareaInput
                                        disabledLimit
                                        noTranslation
                                        label={t`Votre message`}
                                        value={message}
                                        required
                                        onChange={(e) => setMessage(e)}
                                        textareaMinHeight={170}
                                        boxStyle={{ width: "100%" }}
                                    />
                                </Box>
                                {/* <reCAPTCHA sitekey /> */}
                                <Box className={styles.newsletter_container}>
                                    <BaseCheckbox
                                        style={{ marginLeft: 0 }}
                                        checked={newsletter}
                                        onChange={() => setNewsletter(!newsletter)}
                                        label={
                                            <Typography variant="body2" style={{ color: theme.palette.primary.main }}>
                                                <Trans>
                                                    Je souhaite recevoir la newsletter de <i>Bordeaux et ses Vins</i>
                                                </Trans>
                                            </Typography>
                                        }
                                    />
                                </Box>
                            </Box>
                        </ReCaptchaForm>
                    )}
                </>
            )}
        </Box>
    );
};

ContactForm.propTypes = {
    className: object,
};

export default ContactForm;
