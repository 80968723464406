import { Box, makeStyles, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import fioRight from "../assets/odg/fio_odg.svg";
import fioLeft from "../assets/odg/fio_odg_top.svg";
import FrontContentTop from "../Front/FrontContentTop";
import FrontMainContainer from "../Front/FrontMainContainer";
import OdfTab from "../FrontOrganization/OdfTab";
import OdgTab from "../FrontOrganization/OdgTab";
import FrontTitle from "../FrontRanking/FrontTitle";
import theme from "../globalTheme";
import { useWindowDimensions } from "../helpers/helpers";
import OdrTab from "../FrontOrganization/OdrTab";
import OdcTab from "../FrontOrganization/OdcTab";
import { setNextRoute } from "../features/settings/settingsSlice";
import floriture3 from "../assets/encyclopedia/floriture-3.svg";
import floriture4 from "../assets/encyclopedia/floriture-4.svg";
import floriture5 from "../assets/encyclopedia/floriture-5.svg";
import floriture6 from "../assets/encyclopedia/floriture-6.svg";
import OrgnizationsTabs from "../FrontOrganization/OrganizationsTabs";
import { t, Trans } from "@lingui/macro";
import Helmet from "Components/Helmet/Helmet";
import { useCustomHistory } from "hooks";

const useStyles = makeStyles(() => ({
    container_tab_list: {
        borderBottom: "0 !important",
    },
    indicator: {
        borderRadius: "5px !important",
    },
    tabs: {
        gridColumn: "1 / span 12",
        alignItems: "center",
        backgroundColor: theme.palette.background.card,
        borderRadius: "4px",
        padding: "12px 0",
        flexFlow: "row",
        "& .MuiTabScrollButton-root": {
            color: theme.palette.primary.dark,
            "& .MuiSvgIcon-root": {
                width: "40px",
                height: "40px",
            },
        },
        "& .MuiTabScrollButton-root.Mui-disabled": {
            color: theme.palette.secondary.dark,
        },
    },
    tab: {
        backgroundColor: theme.palette.background.card,
        flex: 0,
        textTransform: "uppercase",
        maxWidth: "inherit",
        "& .MuiTypography-root": {
            fontFamily: "Lato",
            fontWeight: "400",
            color: theme.palette.primary.dark,
        },
    },
    tab_selected: {
        backgroundColor: theme.palette.background.card,
        "& .MuiTypography-root": {
            fontWeight: "700",
        },
    },
    main_container: {
        marginBottom: "3rem",
    },
    container_card: {
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
        },
    },
}));

const OrganizationsPage = () => {
    const styles = useStyles();
    const { organization } = useParams();
    const { width } = useWindowDimensions();
    const history = useCustomHistory();
    const dispatch = useDispatch();

    const tabs = [
        {
            value: "ODG",
            label: (
                <Typography variant="h4">
                    <Trans>Défense et gestion</Trans>
                </Typography>
            ),
            content: <OdgTab />,
            onClick: () => history.replace("/organismes-vignoble-bordelais/ODG"),
        },
        {
            value: "formation",
            label: (
                <Typography variant="h4">
                    <Trans>Formation</Trans>
                </Typography>
            ),
            content: <OdfTab />,
            onClick: () => history.replace("/organismes-vignoble-bordelais/formation"),
        },
        {
            value: "representation",
            label: (
                <Typography variant="h4">
                    <Trans>Représentation</Trans>
                </Typography>
            ),
            content: <OdrTab />,
            onClick: () => history.replace("/organismes-vignoble-bordelais/representation"),
        },
        {
            value: "concours",
            label: (
                <Typography variant="h4">
                    <Trans>Concours</Trans>
                </Typography>
            ),
            content: <OdcTab />,
            onClick: () => history.replace("/organismes-vignoble-bordelais/concours"),
        },
    ];

    const getBackgroundImage = () => {
        if (width >= 1920) {
            return {
                backgroundImage: `url(${floriture3}), url(${floriture4}), url(${floriture5}), url(${floriture6})`,
                backgroundPositionY: "71px, top, bottom, bottom",
                backgroundPositionX: "left, right, left, right",
            };
        } else if (width < 1920 && width >= 960) {
            return {
                backgroundImage: `url(${floriture4})`,
                backgroundPositionY: "top",
                backgroundPositionX: "right",
            };
        } else {
            return {
                backgroundImage: "none",
            };
        }
    };

    const updateNavigation = (event, newValue) => {
        dispatch(setNextRoute(`/organismes-vignoble-bordelais/${newValue}`));
    };

    const helmetTitle = {
        ODG: t`ODG`,
        representation: t`Organisme de représentation`,
        formation: t`Formations vitivinicoles`,
        concours: t`Concours des vins`,
    };

    const helmetContent = {
        ODG:
            "Découvrez les ODG du vignoble bordelais, des organismes de défense et de gestion qui garantissent l'authenticité et la qualité des vins de Bordeaux. Apprenez-en plus sur leur rôle et leur histoire sur Bordeaux et ses Vins.",
        representation:
            "Découvrez les organismes de représentation du vignoble bordelais, qui défendent les intérêts des vignerons et négociants de la région. Syndicats, associations, chambres consulaires... apprenez-en plus sur leur rôle et leur impact sur notre site.",
        formation:
            "Découvrez les formations proposées par les organismes de formation du vignoble bordelais pour approfondir vos connaissances sur le vin. Diplômes universitaires, écoles spécialisées, programmes de formation continue... trouvez la formation bordelaise qui vous convient sur notre site.",
        concours:
            "Découvrez les concours viticoles organisés dans le vignoble bordelais pour récompenser les meilleurs vins de la région. Concours généraux, concours spécialisés, labels de qualité... trouvez toutes les informations sur les concours viticoles sur Bordeaux et ses Vins.",
    };

    return (
        <Box>
            <Helmet title={helmetTitle[organization]} content={helmetContent[organization]} />

            <FrontContentTop
                backgroundColor={theme.palette.primary.main}
                boxStyles={{
                    ...getBackgroundImage(),
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: width >= 1920 ? "top left, top right, center left, center right" : "top right",
                    backgroundSize: "auto",
                }}
            >
                <FrontTitle title={t`Les organismes`} />

                <FrontMainContainer
                    styleCardContent
                    className={styles.main_container}
                    style={{
                        backgroundImage: `url(${fioLeft}), url(${fioRight})`,
                        backgroundPositionY: "top, bottom",
                        backgroundPositionX: "left, right",
                        backgroundRepeat: "no-repeat",
                    }}
                    hideFloritureTopImg
                >
                    <Box className={styles.container_card}>
                        <Box className={styles.container_tab}>
                            <OrgnizationsTabs
                                updateNavigation={updateNavigation}
                                currentTab={organization}
                                variant="scrollable"
                                scrollButtons="on"
                                noTabMaxWidth="fit-content"
                                classes={{
                                    root: styles.tabs,
                                    flexContainer: styles.container_tab_list,
                                    indicator: styles.indicator,
                                }}
                                tabClasses={{
                                    root: styles.tab,
                                    selected: styles.tab_selected,
                                }}
                                boxStyle={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(12, 1fr)",
                                }}
                                justify="space-between"
                                tabPanelStyle={{
                                    marginLeft: 0,
                                    gridColumn: "1 / span 13",
                                }}
                                tabs={tabs}
                            />
                        </Box>
                    </Box>
                </FrontMainContainer>
            </FrontContentTop>
        </Box>
    );
};

OrganizationsPage.propTypes = {};

export default OrganizationsPage;
