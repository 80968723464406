export const communesKeys = [
    "33001",
    "33002",
    "33003",
    "33004",
    "33006",
    "33007",
    "33008",
    "33010",
    "33012",
    "33013",
    "33014",
    "33015",
    "33016",
    "33017",
    "33018",
    "33020",
    "33021",
    "33022",
    "33023",
    "33024",
    "33025",
    "33027",
    "33028",
    "33030",
    "33031",
    "33032",
    "33033",
    "33034",
    "33035",
    "33036",
    "33037",
    "33038",
    "33039",
    "33040",
    "33043",
    "33044",
    "33045",
    "33046",
    "33047",
    "33048",
    "33049",
    "33050",
    "33052",
    "33053",
    "33054",
    "33055",
    "33056",
    "33057",
    "33058",
    "33059",
    "33060",
    "33061",
    "33062",
    "33063",
    "33064",
    "33065",
    "33066",
    "33067",
    "33069",
    "33071",
    "33072",
    "33073",
    "33074",
    "33075",
    "33076",
    "33077",
    "33078",
    "33079",
    "33080",
    "33081",
    "33082",
    "33083",
    "33084",
    "33085",
    "33086",
    "33087",
    "33088",
    "33089",
    "33090",
    "33091",
    "33092",
    "33093",
    "33094",
    "33096",
    "33098",
    "33099",
    "33100",
    "33101",
    "33102",
    "33103",
    "33104",
    "33105",
    "33106",
    "33107",
    "33108",
    "33109",
    "33111",
    "33112",
    "33113",
    "33114",
    "33116",
    "33117",
    "33118",
    "33119",
    "33120",
    "33121",
    "33122",
    "33123",
    "33124",
    "33125",
    "33126",
    "33127",
    "33128",
    "33129",
    "33130",
    "33131",
    "33132",
    "33133",
    "33134",
    "33135",
    "33136",
    "33137",
    "33138",
    "33139",
    "33140",
    "33141",
    "33142",
    "33143",
    "33144",
    "33145",
    "33146",
    "33147",
    "33148",
    "33149",
    "33150",
    "33151",
    "33152",
    "33153",
    "33154",
    "33156",
    "33157",
    "33158",
    "33159",
    "33160",
    "33161",
    "33162",
    "33163",
    "33164",
    "33165",
    "33166",
    "33167",
    "33168",
    "33169",
    "33170",
    "33171",
    "24189",
    "33172",
    "33173",
    "33174",
    "33175",
    "33176",
    "33177",
    "33178",
    "33179",
    "33180",
    "33181",
    "33182",
    "33183",
    "33184",
    "33185",
    "33186",
    "33187",
    "33189",
    "33191",
    "33192",
    "33193",
    "33194",
    "33195",
    "33196",
    "33197",
    "33198",
    "33200",
    "33201",
    "33204",
    "33205",
    "33206",
    "33207",
    "33208",
    "33209",
    "33210",
    "33211",
    "33212",
    "33213",
    "33215",
    "33216",
    "33218",
    "33219",
    "33220",
    "33221",
    "33222",
    "33223",
    "33224",
    "33225",
    "33226",
    "33227",
    "33228",
    "33230",
    "33231",
    "33233",
    "33234",
    "33235",
    "33237",
    "33238",
    "33240",
    "33241",
    "33242",
    "33243",
    "33244",
    "33245",
    "33246",
    "33247",
    "33248",
    "33249",
    "33250",
    "33252",
    "33253",
    "33254",
    "33256",
    "33257",
    "33258",
    "33259",
    "33261",
    "33262",
    "33263",
    "33264",
    "33266",
    "33267",
    "33268",
    "33269",
    "33270",
    "33271",
    "33272",
    "33273",
    "33274",
    "33275",
    "33276",
    "33277",
    "33278",
    "33279",
    "33280",
    "33281",
    "33282",
    "33283",
    "33285",
    "33287",
    "33288",
    "33289",
    "33290",
    "33291",
    "33292",
    "33293",
    "33294",
    "33295",
    "33296",
    "33297",
    "33298",
    "33299",
    "33300",
    "33301",
    "33302",
    "33303",
    "33304",
    "33305",
    "33306",
    "33307",
    "33308",
    "33309",
    "33311",
    "33312",
    "33314",
    "33315",
    "33316",
    "33317",
    "33318",
    "33319",
    "33320",
    "33321",
    "33322",
    "33323",
    "33324",
    "33325",
    "33326",
    "33327",
    "33328",
    "33329",
    "33330",
    "33331",
    "33332",
    "24335",
    "33334",
    "33335",
    "33336",
    "33337",
    "33338",
    "33339",
    "33341",
    "33342",
    "33343",
    "33344",
    "33345",
    "33346",
    "33347",
    "33348",
    "33349",
    "33350",
    "33351",
    "33352",
    "33353",
    "33354",
    "33355",
    "33356",
    "33357",
    "33358",
    "33359",
    "33360",
    "33361",
    "33362",
    "33363",
    "33364",
    "33365",
    "33366",
    "33367",
    "33369",
    "33370",
    "33372",
    "33373",
    "33374",
    "33375",
    "33376",
    "33377",
    "33378",
    "33379",
    "33380",
    "33381",
    "33382",
    "33383",
    "33384",
    "33385",
    "33386",
    "33387",
    "33388",
    "33389",
    "33390",
    "33391",
    "33392",
    "33393",
    "33394",
    "33395",
    "33396",
    "33397",
    "33398",
    "33399",
    "33400",
    "33401",
    "33402",
    "33403",
    "33404",
    "33405",
    "33406",
    "33407",
    "33408",
    "33409",
    "33411",
    "33412",
    "33413",
    "33414",
    "33415",
    "33416",
    "33417",
    "33418",
    "33419",
    "33420",
    "33421",
    "33422",
    "33423",
    "33424",
    "33425",
    "33426",
    "33427",
    "33428",
    "33431",
    "33432",
    "33433",
    "33434",
    "33435",
    "33437",
    "33438",
    "33439",
    "33440",
    "33441",
    "33442",
    "33443",
    "33444",
    "33445",
    "33446",
    "33447",
    "33448",
    "33449",
    "33451",
    "33452",
    "33453",
    "33454",
    "33456",
    "33457",
    "33458",
    "33459",
    "33460",
    "33461",
    "33462",
    "33463",
    "33464",
    "33465",
    "33466",
    "33467",
    "33468",
    "33470",
    "33471",
    "33472",
    "33473",
    "33474",
    "33475",
    "33476",
    "33477",
    "33478",
    "33479",
    "33480",
    "33481",
    "33482",
    "33483",
    "33485",
    "33486",
    "33487",
    "33488",
    "33489",
    "33490",
    "33491",
    "33492",
    "33493",
    "33494",
    "33496",
    "33499",
    "33500",
    "33501",
    "33502",
    "33504",
    "33505",
    "33506",
    "33507",
    "33508",
    "33509",
    "33510",
    "33511",
    "33512",
    "33513",
    "33514",
    "33515",
    "33516",
    "33517",
    "33518",
    "33519",
    "33520",
    "33521",
    "33522",
    "33523",
    "33524",
    "33525",
    "33526",
    "33530",
    "33531",
    "33532",
    "33533",
    "33534",
    "33535",
    "33537",
    "33538",
    "33539",
    "33540",
    "33541",
    "33542",
    "33543",
    "33544",
    "33545",
    "33546",
    "33547",
    "33548",
    "33549",
    "33550",
    "33551",
    "33552",
    "33553",
    "33554",
];

export const communes = {
    33001: { displayName: "Abzac", INSEEcode: "33001", postalCode: "33230" },
    33002: { displayName: "Aillas", INSEEcode: "33002", postalCode: "33124" },
    33003: { displayName: "Ambarès-et-Lagrave", INSEEcode: "33003", postalCode: "33565" },
    33004: { displayName: "Ambès", INSEEcode: "33004", postalCode: "33810" },
    33006: { displayName: "Anglade", INSEEcode: "33006", postalCode: "33390" },
    33007: { displayName: "Arbanats", INSEEcode: "33007", postalCode: "33640" },
    33008: { displayName: "Porte-de-Benauge", INSEEcode: "33008", postalCode: "33760" },
    33010: { displayName: "Arcins", INSEEcode: "33010", postalCode: "33460" },
    33012: { displayName: "Arsac", INSEEcode: "33012", postalCode: "33460" },
    33013: { displayName: "Artigues-près-Bordeaux", INSEEcode: "33013", postalCode: "33370" },
    33014: { displayName: "Les Artigues-de-Lussac", INSEEcode: "33014", postalCode: "33570" },
    33015: { displayName: "Arveyres", INSEEcode: "33015", postalCode: "33500" },
    33016: { displayName: "Asques", INSEEcode: "33016", postalCode: "33240" },
    33017: { displayName: "Aubiac", INSEEcode: "33017", postalCode: "33430" },
    33018: { displayName: "Aubie-et-Espessas", INSEEcode: "33018", postalCode: "33240" },
    33020: { displayName: "Auriolles", INSEEcode: "33020", postalCode: "33790" },
    33021: { displayName: "Auros", INSEEcode: "33021", postalCode: "33124" },
    33022: { displayName: "Avensan", INSEEcode: "33022", postalCode: "33480" },
    33023: { displayName: "Ayguemorte-les-Graves", INSEEcode: "33023", postalCode: "33640" },
    33024: { displayName: "Bagas", INSEEcode: "33024", postalCode: "33190" },
    33025: { displayName: "Baigneaux", INSEEcode: "33025", postalCode: "33760" },
    33027: { displayName: "Barie", INSEEcode: "33027", postalCode: "33190" },
    33028: { displayName: "Baron", INSEEcode: "33028", postalCode: "33750" },
    33030: { displayName: "Barsac", INSEEcode: "33030", postalCode: "33720" },
    33031: { displayName: "Bassanne", INSEEcode: "33031", postalCode: "33190" },
    33032: { displayName: "Bassens", INSEEcode: "33032", postalCode: "33565" },
    33033: { displayName: "Baurech", INSEEcode: "33033", postalCode: "33880" },
    33034: { displayName: "Bayas", INSEEcode: "33034", postalCode: "33230" },
    33035: { displayName: "Bayon-sur-Gironde", INSEEcode: "33035", postalCode: "33710" },
    33036: { displayName: "Bazas", INSEEcode: "33036", postalCode: "33430" },
    33037: { displayName: "Beautiran", INSEEcode: "33037", postalCode: "33640" },
    33038: { displayName: "Bégadan", INSEEcode: "33038", postalCode: "33340" },
    33039: { displayName: "Bègles", INSEEcode: "33039", postalCode: "33321" },
    33040: { displayName: "Béguey", INSEEcode: "33040", postalCode: "33410" },
    33043: { displayName: "Bellebat", INSEEcode: "33043", postalCode: "33760" },
    33044: { displayName: "Bellefond", INSEEcode: "33044", postalCode: "33760" },
    33045: { displayName: "Belvès-de-Castillon", INSEEcode: "33045", postalCode: "33350" },
    33046: { displayName: "Bernos-Beaulac", INSEEcode: "33046", postalCode: "33430" },
    33047: { displayName: "Berson", INSEEcode: "33047", postalCode: "33390" },
    33048: { displayName: "Berthez", INSEEcode: "33048", postalCode: "33124" },
    33049: { displayName: "Beychac-et-Caillau", INSEEcode: "33049", postalCode: "33750" },
    33050: { displayName: "Bieujac", INSEEcode: "33050", postalCode: "33210" },
    33052: { displayName: "Les Billaux", INSEEcode: "33052", postalCode: "33500" },
    33053: { displayName: "Birac", INSEEcode: "33053", postalCode: "33430" },
    33054: { displayName: "Blaignac", INSEEcode: "33054", postalCode: "33190" },
    33055: { displayName: "Blaignan", INSEEcode: "33055", postalCode: "33340" },
    33056: { displayName: "Blanquefort", INSEEcode: "33056", postalCode: "33295" },
    33057: { displayName: "Blasimon", INSEEcode: "33057", postalCode: "33540" },
    33058: { displayName: "Blaye", INSEEcode: "33058", postalCode: "33395" },
    33059: { displayName: "Blésignac", INSEEcode: "33059", postalCode: "33670" },
    33060: { displayName: "Bommes", INSEEcode: "33060", postalCode: "33210" },
    33061: { displayName: "Bonnetan", INSEEcode: "33061", postalCode: "33370" },
    33062: { displayName: "Bonzac", INSEEcode: "33062", postalCode: "33910" },
    33063: { displayName: "Bordeaux", INSEEcode: "33063", postalCode: "33083" },
    33064: { displayName: "Bossugan", INSEEcode: "33064", postalCode: "33350" },
    33065: { displayName: "Bouliac", INSEEcode: "33065", postalCode: "33272" },
    33066: { displayName: "Bourdelles", INSEEcode: "33066", postalCode: "33190" },
    33067: { displayName: "Bourg", INSEEcode: "33067", postalCode: "33710" },
    33069: { displayName: "Le Bouscat", INSEEcode: "33069", postalCode: "33491" },
    33071: { displayName: "Branne", INSEEcode: "33071", postalCode: "33420" },
    33072: { displayName: "Brannens", INSEEcode: "33072", postalCode: "33124" },
    33073: { displayName: "Braud-et-Saint-Louis", INSEEcode: "33073", postalCode: "33820" },
    33074: { displayName: "Brouqueyran", INSEEcode: "33074", postalCode: "33124" },
    33075: { displayName: "Bruges", INSEEcode: "33075", postalCode: "33523" },
    33076: { displayName: "Budos", INSEEcode: "33076", postalCode: "33720" },
    33077: { displayName: "Cabanac-et-Villagrains", INSEEcode: "33077", postalCode: "33650" },
    33078: { displayName: "Cabara", INSEEcode: "33078", postalCode: "33420" },
    33079: { displayName: "Cadarsac", INSEEcode: "33079", postalCode: "33750" },
    33080: { displayName: "Cadaujac", INSEEcode: "33080", postalCode: "33882" },
    33081: { displayName: "Cadillac", INSEEcode: "33081", postalCode: "33410" },
    33082: { displayName: "Cadillac-en-Fronsadais", INSEEcode: "33082", postalCode: "33240" },
    33083: { displayName: "Camarsac", INSEEcode: "33083", postalCode: "33750" },
    33084: { displayName: "Cambes", INSEEcode: "33084", postalCode: "33880" },
    33085: { displayName: "Camblanes-et-Meynac", INSEEcode: "33085", postalCode: "33360" },
    33086: { displayName: "Camiac-et-Saint-Denis", INSEEcode: "33086", postalCode: "33420" },
    33087: { displayName: "Camiran", INSEEcode: "33087", postalCode: "33190" },
    33088: { displayName: "Camps-sur-l'Isle", INSEEcode: "33088", postalCode: "33660" },
    33089: { displayName: "Campugnan", INSEEcode: "33089", postalCode: "33390" },
    33090: { displayName: "Canéjan", INSEEcode: "33090", postalCode: "33612" },
    33091: { displayName: "Cantenac", INSEEcode: "33091", postalCode: "33460" },
    33092: { displayName: "Cantois", INSEEcode: "33092", postalCode: "33760" },
    33093: { displayName: "Capian", INSEEcode: "33093", postalCode: "33550" },
    33094: { displayName: "Caplong", INSEEcode: "33094", postalCode: "33220" },
    33096: { displayName: "Carbon-Blanc", INSEEcode: "33096", postalCode: "33564" },
    33098: { displayName: "Cardan", INSEEcode: "33098", postalCode: "33410" },
    33099: { displayName: "Carignan-de-Bordeaux", INSEEcode: "33099", postalCode: "33360" },
    33100: { displayName: "Cars", INSEEcode: "33100", postalCode: "33390" },
    33101: { displayName: "Cartelègue", INSEEcode: "33101", postalCode: "33390" },
    33102: { displayName: "Casseuil", INSEEcode: "33102", postalCode: "33190" },
    33103: { displayName: "Castelmoron-d'Albret", INSEEcode: "33103", postalCode: "33540" },
    33104: { displayName: "Castelnau-de-Médoc", INSEEcode: "33104", postalCode: "33480" },
    33105: { displayName: "Castelviel", INSEEcode: "33105", postalCode: "33540" },
    33106: { displayName: "Castets-en-Dorthe", INSEEcode: "33106", postalCode: "33210" },
    33107: { displayName: "Castillon-de-Castets", INSEEcode: "33107", postalCode: "33210" },
    33108: { displayName: "Castillon-la-Bataille", INSEEcode: "33108", postalCode: "33350" },
    33109: { displayName: "Castres-Gironde", INSEEcode: "33109", postalCode: "33640" },
    33111: { displayName: "Caudrot", INSEEcode: "33111", postalCode: "33490" },
    33112: { displayName: "Caumont", INSEEcode: "33112", postalCode: "33540" },
    33113: { displayName: "Cauvignac", INSEEcode: "33113", postalCode: "33690" },
    33114: { displayName: "Cavignac", INSEEcode: "33114", postalCode: "33620" },
    33116: { displayName: "Cazats", INSEEcode: "33116", postalCode: "33430" },
    33117: { displayName: "Cazaugitat", INSEEcode: "33117", postalCode: "33790" },
    33118: { displayName: "Cénac", INSEEcode: "33118", postalCode: "33360" },
    33119: { displayName: "Cenon", INSEEcode: "33119", postalCode: "33155" },
    33120: { displayName: "Cérons", INSEEcode: "33120", postalCode: "33720" },
    33121: { displayName: "Cessac", INSEEcode: "33121", postalCode: "33760" },
    33122: { displayName: "Cestas", INSEEcode: "33122", postalCode: "33612" },
    33123: { displayName: "Cézac", INSEEcode: "33123", postalCode: "33620" },
    33124: { displayName: "Chamadelle", INSEEcode: "33124", postalCode: "33230" },
    33125: { displayName: "Cissac-Médoc", INSEEcode: "33125", postalCode: "33250" },
    33126: { displayName: "Civrac-de-Blaye", INSEEcode: "33126", postalCode: "33920" },
    33127: { displayName: "Civrac-sur-Dordogne", INSEEcode: "33127", postalCode: "33350" },
    33128: { displayName: "Civrac-en-Médoc", INSEEcode: "33128", postalCode: "33340" },
    33129: { displayName: "Cleyrac", INSEEcode: "33129", postalCode: "33540" },
    33130: { displayName: "Coimères", INSEEcode: "33130", postalCode: "33210" },
    33131: { displayName: "Coirac", INSEEcode: "33131", postalCode: "33540" },
    33132: { displayName: "Comps", INSEEcode: "33132", postalCode: "33710" },
    33133: { displayName: "Coubeyrac", INSEEcode: "33133", postalCode: "33890" },
    33134: { displayName: "Couquèques", INSEEcode: "33134", postalCode: "33340" },
    33135: { displayName: "Courpiac", INSEEcode: "33135", postalCode: "33760" },
    33136: { displayName: "Cours-de-Monségur", INSEEcode: "33136", postalCode: "33580" },
    33137: { displayName: "Cours-les-Bains", INSEEcode: "33137", postalCode: "33690" },
    33138: { displayName: "Coutras", INSEEcode: "33138", postalCode: "33230" },
    33139: { displayName: "Coutures", INSEEcode: "33139", postalCode: "33580" },
    33140: { displayName: "Créon", INSEEcode: "33140", postalCode: "33670" },
    33141: { displayName: "Croignon", INSEEcode: "33141", postalCode: "33750" },
    33142: { displayName: "Cubnezais", INSEEcode: "33142", postalCode: "33620" },
    33143: { displayName: "Cubzac-les-Ponts", INSEEcode: "33143", postalCode: "33240" },
    33144: { displayName: "Cudos", INSEEcode: "33144", postalCode: "33430" },
    33145: { displayName: "Cursan", INSEEcode: "33145", postalCode: "33670" },
    33146: { displayName: "Cussac-Fort-Médoc", INSEEcode: "33146", postalCode: "33460" },
    33147: { displayName: "Daignac", INSEEcode: "33147", postalCode: "33420" },
    33148: { displayName: "Dardenac", INSEEcode: "33148", postalCode: "33420" },
    33149: { displayName: "Daubèze", INSEEcode: "33149", postalCode: "33540" },
    33150: { displayName: "Dieulivol", INSEEcode: "33150", postalCode: "33580" },
    33151: { displayName: "Donnezac", INSEEcode: "33151", postalCode: "33860" },
    33152: { displayName: "Donzac", INSEEcode: "33152", postalCode: "33410" },
    33153: { displayName: "Doulezon", INSEEcode: "33153", postalCode: "33350" },
    33154: { displayName: "Les Églisottes-et-Chalaures", INSEEcode: "33154", postalCode: "33230" },
    33156: { displayName: "Escoussans", INSEEcode: "33156", postalCode: "33760" },
    33157: { displayName: "Espiet", INSEEcode: "33157", postalCode: "33420" },
    33158: { displayName: "Les Esseintes", INSEEcode: "33158", postalCode: "33190" },
    33159: { displayName: "Étauliers", INSEEcode: "33159", postalCode: "33820" },
    33160: { displayName: "Eynesse", INSEEcode: "33160", postalCode: "33220" },
    33161: { displayName: "Eyrans", INSEEcode: "33161", postalCode: "33390" },
    33162: { displayName: "Eysines", INSEEcode: "33162", postalCode: "33326" },
    33163: { displayName: "Faleyras", INSEEcode: "33163", postalCode: "33760" },
    33164: { displayName: "Fargues", INSEEcode: "33164", postalCode: "33210" },
    33165: { displayName: "Fargues-Saint-Hilaire", INSEEcode: "33165", postalCode: "33370" },
    33166: { displayName: "Le Fieu", INSEEcode: "33166", postalCode: "33230" },
    33167: { displayName: "Floirac", INSEEcode: "33167", postalCode: "33274" },
    33168: { displayName: "Flaujagues", INSEEcode: "33168", postalCode: "33350" },
    33169: { displayName: "Floudès", INSEEcode: "33169", postalCode: "33190" },
    33170: { displayName: "Fontet", INSEEcode: "33170", postalCode: "33192" },
    33171: { displayName: "Fossès-et-Baleyssac", INSEEcode: "33171", postalCode: "33190" },
    24189: { displayName: "Fougueyrolles", INSEEcode: "24189", postalCode: "33220" },
    33172: { displayName: "Fours", INSEEcode: "33172", postalCode: "33390" },
    33173: { displayName: "Francs", INSEEcode: "33173", postalCode: "33570" },
    33174: { displayName: "Fronsac", INSEEcode: "33174", postalCode: "33506" },
    33175: { displayName: "Frontenac", INSEEcode: "33175", postalCode: "33760" },
    33176: { displayName: "Gabarnac", INSEEcode: "33176", postalCode: "33410" },
    33177: { displayName: "Gaillan-en-Médoc", INSEEcode: "33177", postalCode: "33341" },
    33178: { displayName: "Gajac", INSEEcode: "33178", postalCode: "33430" },
    33179: { displayName: "Galgon", INSEEcode: "33179", postalCode: "33133" },
    33180: { displayName: "Gans", INSEEcode: "33180", postalCode: "33430" },
    33181: { displayName: "Gardegan-et-Tourtirac", INSEEcode: "33181", postalCode: "33350" },
    33182: { displayName: "Gauriac", INSEEcode: "33182", postalCode: "33710" },
    33183: { displayName: "Gauriaguet", INSEEcode: "33183", postalCode: "33240" },
    33184: { displayName: "Générac", INSEEcode: "33184", postalCode: "33920" },
    33185: { displayName: "Génissac", INSEEcode: "33185", postalCode: "33420" },
    33186: { displayName: "Gensac", INSEEcode: "33186", postalCode: "33890" },
    33187: { displayName: "Gironde-sur-Dropt", INSEEcode: "33187", postalCode: "33190" },
    33189: { displayName: "Gornac", INSEEcode: "33189", postalCode: "33540" },
    33191: { displayName: "Gours", INSEEcode: "33191", postalCode: "33660" },
    33192: { displayName: "Gradignan", INSEEcode: "33192", postalCode: "33174" },
    33193: { displayName: "Grayan-et-l'Hôpital", INSEEcode: "33193", postalCode: "33590" },
    33194: { displayName: "Grézillac", INSEEcode: "33194", postalCode: "33420" },
    33195: { displayName: "Grignols", INSEEcode: "33195", postalCode: "33690" },
    33196: { displayName: "Guillac", INSEEcode: "33196", postalCode: "33420" },
    33197: { displayName: "Guillos", INSEEcode: "33197", postalCode: "33720" },
    33198: { displayName: "Guîtres", INSEEcode: "33198", postalCode: "33230" },
    33200: { displayName: "Le Haillan", INSEEcode: "33200", postalCode: "33186" },
    33201: { displayName: "Haux", INSEEcode: "33201", postalCode: "33550" },
    33204: { displayName: "Hure", INSEEcode: "33204", postalCode: "33190" },
    33205: { displayName: "Illats", INSEEcode: "33205", postalCode: "33720" },
    33206: { displayName: "Isle-Saint-Georges", INSEEcode: "33206", postalCode: "33640" },
    33207: { displayName: "Izon", INSEEcode: "33207", postalCode: "33450" },
    33208: { displayName: "Jau-Dignac-et-Loirac", INSEEcode: "33208", postalCode: "33590" },
    33209: { displayName: "Jugazan", INSEEcode: "33209", postalCode: "33420" },
    33210: { displayName: "Juillac", INSEEcode: "33210", postalCode: "33890" },
    33211: { displayName: "Labarde", INSEEcode: "33211", postalCode: "33460" },
    33212: { displayName: "Labescau", INSEEcode: "33212", postalCode: "33690" },
    33213: { displayName: "La Brède", INSEEcode: "33213", postalCode: "33652" },
    33215: { displayName: "Ladaux", INSEEcode: "33215", postalCode: "33760" },
    33216: { displayName: "Lados", INSEEcode: "33216", postalCode: "33124" },
    33218: { displayName: "Lagorce", INSEEcode: "33218", postalCode: "33230" },
    33219: { displayName: "La Lande-de-Fronsac", INSEEcode: "33219", postalCode: "33240" },
    33220: { displayName: "Lamarque", INSEEcode: "33220", postalCode: "33460" },
    33221: { displayName: "Lamothe-Landerron", INSEEcode: "33221", postalCode: "33190" },
    33222: { displayName: "Lalande-de-Pomerol", INSEEcode: "33222", postalCode: "33500" },
    33223: { displayName: "Landerrouat", INSEEcode: "33223", postalCode: "33790" },
    33224: { displayName: "Landerrouet-sur-Ségur", INSEEcode: "33224", postalCode: "33540" },
    33225: { displayName: "Landiras", INSEEcode: "33225", postalCode: "33720" },
    33226: { displayName: "Langoiran", INSEEcode: "33226", postalCode: "33550" },
    33227: { displayName: "Langon", INSEEcode: "33227", postalCode: "33213" },
    33228: { displayName: "Lansac", INSEEcode: "33228", postalCode: "33710" },
    33230: { displayName: "Lapouyade", INSEEcode: "33230", postalCode: "33620" },
    33231: { displayName: "Laroque", INSEEcode: "33231", postalCode: "33410" },
    33233: { displayName: "Laruscade", INSEEcode: "33233", postalCode: "33620" },
    33234: { displayName: "Latresne", INSEEcode: "33234", postalCode: "33360" },
    33235: { displayName: "Lavazan", INSEEcode: "33235", postalCode: "33690" },
    33237: { displayName: "Léogeats", INSEEcode: "33237", postalCode: "33210" },
    33238: { displayName: "Léognan", INSEEcode: "33238", postalCode: "33850" },
    33240: { displayName: "Lesparre-Médoc", INSEEcode: "33240", postalCode: "33341" },
    33241: { displayName: "Lestiac-sur-Garonne", INSEEcode: "33241", postalCode: "33550" },
    33242: { displayName: "Les Lèves-et-Thoumeyragues", INSEEcode: "33242", postalCode: "33220" },
    33243: { displayName: "Libourne", INSEEcode: "33243", postalCode: "33503" },
    33244: { displayName: "Lignan-de-Bazas", INSEEcode: "33244", postalCode: "33430" },
    33245: { displayName: "Lignan-de-Bordeaux", INSEEcode: "33245", postalCode: "33360" },
    33246: { displayName: "Ligueux", INSEEcode: "33246", postalCode: "33220" },
    33247: { displayName: "Listrac-de-Durèze", INSEEcode: "33247", postalCode: "33790" },
    33248: { displayName: "Listrac-Médoc", INSEEcode: "33248", postalCode: "33480" },
    33249: { displayName: "Lormont", INSEEcode: "33249", postalCode: "33305" },
    33250: { displayName: "Loubens", INSEEcode: "33250", postalCode: "33190" },
    33252: { displayName: "Loupes", INSEEcode: "33252", postalCode: "33370" },
    33253: { displayName: "Loupiac", INSEEcode: "33253", postalCode: "33410" },
    33254: { displayName: "Loupiac-de-la-Réole", INSEEcode: "33254", postalCode: "33190" },
    33256: { displayName: "Ludon-Médoc", INSEEcode: "33256", postalCode: "33294" },
    33257: { displayName: "Lugaignac", INSEEcode: "33257", postalCode: "33420" },
    33258: { displayName: "Lugasson", INSEEcode: "33258", postalCode: "33760" },
    33259: { displayName: "Lugon-et-l'Île-du-Carnay", INSEEcode: "33259", postalCode: "33240" },
    33261: { displayName: "Lussac", INSEEcode: "33261", postalCode: "33570" },
    33262: { displayName: "Macau", INSEEcode: "33262", postalCode: "33460" },
    33263: { displayName: "Madirac", INSEEcode: "33263", postalCode: "33670" },
    33264: { displayName: "Maransin", INSEEcode: "33264", postalCode: "33230" },
    33266: { displayName: "Marcenais", INSEEcode: "33266", postalCode: "33620" },
    33267: { displayName: "Marcillac", INSEEcode: "33267", postalCode: "33860" },
    33268: { displayName: "Margaux-Cantenac", INSEEcode: "33268", postalCode: "33460" },
    33269: { displayName: "Margueron", INSEEcode: "33269", postalCode: "33220" },
    33270: { displayName: "Marimbault", INSEEcode: "33270", postalCode: "33430" },
    33271: { displayName: "Marions", INSEEcode: "33271", postalCode: "33690" },
    33272: { displayName: "Marsas", INSEEcode: "33272", postalCode: "33620" },
    33273: { displayName: "Martignas-sur-Jalle", INSEEcode: "33273", postalCode: "33127" },
    33274: { displayName: "Martillac", INSEEcode: "33274", postalCode: "33652" },
    33275: { displayName: "Martres", INSEEcode: "33275", postalCode: "33760" },
    33276: { displayName: "Masseilles", INSEEcode: "33276", postalCode: "33690" },
    33277: { displayName: "Massugas", INSEEcode: "33277", postalCode: "33790" },
    33278: { displayName: "Mauriac", INSEEcode: "33278", postalCode: "33540" },
    33279: { displayName: "Mazères", INSEEcode: "33279", postalCode: "33212" },
    33280: { displayName: "Mazion", INSEEcode: "33280", postalCode: "33394" },
    33281: { displayName: "Mérignac", INSEEcode: "33281", postalCode: "33691" },
    33282: { displayName: "Mérignas", INSEEcode: "33282", postalCode: "33350" },
    33283: { displayName: "Mesterrieux", INSEEcode: "33283", postalCode: "33540" },
    33285: { displayName: "Mombrier", INSEEcode: "33285", postalCode: "33710" },
    33287: { displayName: "Mongauzy", INSEEcode: "33287", postalCode: "33190" },
    33288: { displayName: "Monprimblanc", INSEEcode: "33288", postalCode: "33410" },
    33289: { displayName: "Monségur", INSEEcode: "33289", postalCode: "33580" },
    33290: { displayName: "Montagne", INSEEcode: "33290", postalCode: "33570" },
    33291: { displayName: "Montagoudin", INSEEcode: "33291", postalCode: "33190" },
    33292: { displayName: "Montignac", INSEEcode: "33292", postalCode: "33760" },
    33293: { displayName: "Montussan", INSEEcode: "33293", postalCode: "33450" },
    33294: { displayName: "Morizès", INSEEcode: "33294", postalCode: "33190" },
    33295: { displayName: "Mouillac", INSEEcode: "33295", postalCode: "33240" },
    33296: { displayName: "Mouliets-et-Villemartin", INSEEcode: "33296", postalCode: "33350" },
    33297: { displayName: "Moulis-en-Médoc", INSEEcode: "33297", postalCode: "33480" },
    33298: { displayName: "Moulon", INSEEcode: "33298", postalCode: "33420" },
    33299: { displayName: "Mourens", INSEEcode: "33299", postalCode: "33410" },
    33300: { displayName: "Naujac-sur-Mer", INSEEcode: "33300", postalCode: "33990" },
    33301: { displayName: "Naujan-et-Postiac", INSEEcode: "33301", postalCode: "33420" },
    33302: { displayName: "Néac", INSEEcode: "33302", postalCode: "33500" },
    33303: { displayName: "Nérigean", INSEEcode: "33303", postalCode: "33750" },
    33304: { displayName: "Neuffons", INSEEcode: "33304", postalCode: "33580" },
    33305: { displayName: "Le Nizan", INSEEcode: "33305", postalCode: "33430" },
    33306: { displayName: "Noaillac", INSEEcode: "33306", postalCode: "33190" },
    33307: { displayName: "Noaillan", INSEEcode: "33307", postalCode: "33730" },
    33308: { displayName: "Omet", INSEEcode: "33308", postalCode: "33410" },
    33309: { displayName: "Ordonnac", INSEEcode: "33309", postalCode: "33340" },
    33311: { displayName: "Paillet", INSEEcode: "33311", postalCode: "33550" },
    33312: { displayName: "Parempuyre", INSEEcode: "33312", postalCode: "33290" },
    33314: { displayName: "Pauillac", INSEEcode: "33314", postalCode: "33250" },
    33315: { displayName: "Les Peintures", INSEEcode: "33315", postalCode: "33230" },
    33316: { displayName: "Pellegrue", INSEEcode: "33316", postalCode: "33790" },
    33317: { displayName: "Périssac", INSEEcode: "33317", postalCode: "33240" },
    33318: { displayName: "Pessac", INSEEcode: "33318", postalCode: "33615" },
    33319: { displayName: "Pessac-sur-Dordogne", INSEEcode: "33319", postalCode: "33890" },
    33320: { displayName: "Petit-Palais-et-Cornemps", INSEEcode: "33320", postalCode: "33570" },
    33321: { displayName: "Peujard", INSEEcode: "33321", postalCode: "33240" },
    33322: { displayName: "Le Pian-Médoc", INSEEcode: "33322", postalCode: "33294" },
    33323: { displayName: "Le Pian-sur-Garonne", INSEEcode: "33323", postalCode: "33490" },
    33324: { displayName: "Pineuilh", INSEEcode: "33324", postalCode: "33220" },
    33325: { displayName: "Plassac", INSEEcode: "33325", postalCode: "33390" },
    33326: { displayName: "Pleine-Selve", INSEEcode: "33326", postalCode: "33820" },
    33327: { displayName: "Podensac", INSEEcode: "33327", postalCode: "33720" },
    33328: { displayName: "Pomerol", INSEEcode: "33328", postalCode: "33500" },
    33329: { displayName: "Pompéjac", INSEEcode: "33329", postalCode: "33730" },
    33330: { displayName: "Pompignac", INSEEcode: "33330", postalCode: "33370" },
    33331: { displayName: "Pondaurat", INSEEcode: "33331", postalCode: "33190" },
    33332: { displayName: "Porchères", INSEEcode: "33332", postalCode: "33660" },
    24335: { displayName: "Port-Sainte-Foy-et-Ponchapt", INSEEcode: "24335", postalCode: "33220" },

    33334: { displayName: "Portets", INSEEcode: "33334", postalCode: "33640" },

    33335: { displayName: "Le Pout", INSEEcode: "33335", postalCode: "33670" },
    33336: { displayName: "Préchac", INSEEcode: "33336", postalCode: "33730" },
    33337: { displayName: "Preignac", INSEEcode: "33337", postalCode: "33210" },

    33338: { displayName: "PRIGNAC EN MEDOC", INSEEcode: "33338", postalCode: "33340" },
    33339: { displayName: "Prignac-et-Marcamps", INSEEcode: "33339", postalCode: "33710" },
    33341: { displayName: "Pugnac", INSEEcode: "33341", postalCode: "33710" },
    33342: { displayName: "Puisseguin", INSEEcode: "33342", postalCode: "33570" },
    33343: { displayName: "Pujols-sur-Ciron", INSEEcode: "33343", postalCode: "33212" },
    33344: { displayName: "Pujols", INSEEcode: "33344", postalCode: "33350" },
    33345: { displayName: "Le Puy", INSEEcode: "33345", postalCode: "33580" },
    33346: { displayName: "Puybarban", INSEEcode: "33346", postalCode: "33190" },
    33347: { displayName: "Puynormand", INSEEcode: "33347", postalCode: "33660" },
    33348: { displayName: "Queyrac", INSEEcode: "33348", postalCode: "33340" },
    33349: { displayName: "Quinsac", INSEEcode: "33349", postalCode: "33360" },
    33350: { displayName: "Rauzan", INSEEcode: "33350", postalCode: "33420" },
    33351: { displayName: "Reignac", INSEEcode: "33351", postalCode: "33860" },
    33352: { displayName: "La Réole", INSEEcode: "33352", postalCode: "33192" },
    33353: { displayName: "Rimons", INSEEcode: "33353", postalCode: "33580" },
    33354: { displayName: "Riocaud", INSEEcode: "33354", postalCode: "33220" },
    33355: { displayName: "Rions", INSEEcode: "33355", postalCode: "33410" },
    33356: { displayName: "La Rivière", INSEEcode: "33356", postalCode: "33126" },
    33357: { displayName: "Roaillan", INSEEcode: "33357", postalCode: "33212" },
    33358: { displayName: "Romagne", INSEEcode: "33358", postalCode: "33760" },
    33359: { displayName: "Roquebrune", INSEEcode: "33359", postalCode: "33580" },
    33360: { displayName: "La Roquille", INSEEcode: "33360", postalCode: "33220" },
    33361: { displayName: "Ruch", INSEEcode: "33361", postalCode: "33350" },
    33362: { displayName: "Sablons", INSEEcode: "33362", postalCode: "33910" },
    33363: { displayName: "Sadirac", INSEEcode: "33363", postalCode: "33670" },
    33364: { displayName: "Saillans", INSEEcode: "33364", postalCode: "33141" },
    33365: { displayName: "Saint-Aignan", INSEEcode: "33365", postalCode: "33126" },
    33366: { displayName: "Saint-André-de-Cubzac", INSEEcode: "33366", postalCode: "33240" },
    33367: { displayName: "Saint-André-du-Bois", INSEEcode: "33367", postalCode: "33490" },
    33369: { displayName: "Saint-André-et-Appelles", INSEEcode: "33369", postalCode: "33220" },
    33370: { displayName: "Saint-Androny", INSEEcode: "33370", postalCode: "33390" },
    33372: { displayName: "Saint-Antoine-du-Queyret", INSEEcode: "33372", postalCode: "33790" },
    33373: { displayName: "Saint-Antoine-sur-l'Isle", INSEEcode: "33373", postalCode: "33660" },
    33374: { displayName: "Saint-Aubin-de-Blaye", INSEEcode: "33374", postalCode: "33820" },
    33375: { displayName: "Saint-Aubin-de-Branne", INSEEcode: "33375", postalCode: "33420" },
    33376: { displayName: "Saint-Aubin-de-Médoc", INSEEcode: "33376", postalCode: "33166" },
    33377: { displayName: "Saint-Avit-de-Soulège", INSEEcode: "33377", postalCode: "33220" },
    33378: { displayName: "Saint-Avit-Saint-Nazaire", INSEEcode: "33378", postalCode: "33220" },
    33379: { displayName: "Saint-Brice", INSEEcode: "33379", postalCode: "33540" },
    33380: { displayName: "Saint-Caprais-de-Blaye", INSEEcode: "33380", postalCode: "33820" },
    33381: { displayName: "Saint-Caprais-de-Bordeaux", INSEEcode: "33381", postalCode: "33880" },
    33382: { displayName: "Saint-Christoly-de-Blaye", INSEEcode: "33382", postalCode: "33920" },
    33383: { displayName: "Saint-Christoly-Médoc", INSEEcode: "33383", postalCode: "33340" },
    33384: { displayName: "Saint-Christophe-des-Bardes", INSEEcode: "33384", postalCode: "33330" },
    33385: { displayName: "Saint-Christophe-de-Double", INSEEcode: "33385", postalCode: "33230" },
    33386: { displayName: "Saint-Cibard", INSEEcode: "33386", postalCode: "33570" },
    33387: { displayName: "Saint-Ciers-d'Abzac", INSEEcode: "33387", postalCode: "33910" },
    33388: { displayName: "Saint-Ciers-de-Canesse", INSEEcode: "33388", postalCode: "33710" },
    33389: { displayName: "Saint-Ciers-sur-Gironde", INSEEcode: "33389", postalCode: "33820" },
    33390: { displayName: "Sainte-Colombe", INSEEcode: "33390", postalCode: "33350" },
    33391: { displayName: "Saint-Côme", INSEEcode: "33391", postalCode: "33430" },
    33392: { displayName: "Sainte-Croix-du-Mont", INSEEcode: "33392", postalCode: "33410" },
    33393: { displayName: "Saint-Denis-de-Pile", INSEEcode: "33393", postalCode: "33910" },
    33394: { displayName: "Saint-Émilion", INSEEcode: "33394", postalCode: "33330" },
    33395: { displayName: "Saint-Estèphe", INSEEcode: "33395", postalCode: "33180" },
    33396: { displayName: "Saint-Étienne-de-Lisse", INSEEcode: "33396", postalCode: "33330" },
    33397: { displayName: "Sainte-Eulalie", INSEEcode: "33397", postalCode: "33560" },
    33398: { displayName: "Saint-Exupéry", INSEEcode: "33398", postalCode: "33190" },
    33399: { displayName: "Saint-Félix-de-Foncaude", INSEEcode: "33399", postalCode: "33540" },
    33400: { displayName: "Saint-Ferme", INSEEcode: "33400", postalCode: "33580" },
    33401: { displayName: "Sainte-Florence", INSEEcode: "33401", postalCode: "33350" },
    33402: { displayName: "Sainte-Foy-la-Grande", INSEEcode: "33402", postalCode: "33220" },
    33403: { displayName: "Sainte-Foy-la-Longue", INSEEcode: "33403", postalCode: "33490" },
    33404: { displayName: "Sainte-Gemme", INSEEcode: "33404", postalCode: "33580" },
    33405: { displayName: "Saint-Genès-de-Blaye", INSEEcode: "33405", postalCode: "33390" },
    33406: { displayName: "Saint-Genès-de-Castillon", INSEEcode: "33406", postalCode: "33350" },
    33407: { displayName: "Saint-Genès-de-Fronsac", INSEEcode: "33407", postalCode: "33240" },
    33408: { displayName: "Saint-Genès-de-Lombaud", INSEEcode: "33408", postalCode: "33670" },
    33409: { displayName: "Saint-Genis-du-Bois", INSEEcode: "33409", postalCode: "33760" },
    33411: { displayName: "Saint-Germain-de-Grave", INSEEcode: "33411", postalCode: "33490" },
    33412: { displayName: "Saint-Germain-d'Esteuil", INSEEcode: "33412", postalCode: "33341" },
    33413: { displayName: "Saint-Germain-du-Puch", INSEEcode: "33413", postalCode: "33750" },
    33414: { displayName: "Saint-Germain-de-la-Rivière", INSEEcode: "33414", postalCode: "33240" },
    33415: { displayName: "Saint-Gervais", INSEEcode: "33415", postalCode: "33240" },
    33416: { displayName: "Saint-Girons-d'Aiguevives", INSEEcode: "33416", postalCode: "33920" },
    33417: { displayName: "Sainte-Hélène", INSEEcode: "33417", postalCode: "33480" },
    33418: { displayName: "Saint-Hilaire-de-la-Noaille", INSEEcode: "33418", postalCode: "33190" },
    33419: { displayName: "Saint-Hilaire-du-Bois", INSEEcode: "33419", postalCode: "33540" },
    33420: { displayName: "Saint-Hippolyte", INSEEcode: "33420", postalCode: "33330" },
    33421: { displayName: "Saint-Jean-de-Blaignac", INSEEcode: "33421", postalCode: "33420" },
    33422: { displayName: "Saint-Jean-d'Illac", INSEEcode: "33422", postalCode: "33127" },
    33423: { displayName: "Saint-Julien-Beychevelle", INSEEcode: "33423", postalCode: "33250" },
    33424: { displayName: "Saint-Laurent-Médoc", INSEEcode: "33424", postalCode: "33112" },
    33425: { displayName: "Saint-Laurent-d'Arce", INSEEcode: "33425", postalCode: "33240" },
    33426: { displayName: "Saint-Laurent-des-Combes", INSEEcode: "33426", postalCode: "33330" },
    33427: { displayName: "Saint-Laurent-du-Bois", INSEEcode: "33427", postalCode: "33540" },
    33428: { displayName: "Saint-Laurent-du-Plan", INSEEcode: "33428", postalCode: "33190" },
    33431: { displayName: "Saint-Léon", INSEEcode: "33431", postalCode: "33670" },
    33432: { displayName: "Saint-Loubert", INSEEcode: "33432", postalCode: "33212" },
    33433: { displayName: "Saint-Loubès", INSEEcode: "33433", postalCode: "33451" },
    33434: { displayName: "Saint-Louis-de-Montferrand", INSEEcode: "33434", postalCode: "33561" },
    33435: { displayName: "Saint-Macaire", INSEEcode: "33435", postalCode: "33490" },
    33437: { displayName: "Saint-Magne-de-Castillon", INSEEcode: "33437", postalCode: "33350" },
    33438: { displayName: "Saint-Maixant", INSEEcode: "33438", postalCode: "33212" },
    33439: { displayName: "Saint-Mariens", INSEEcode: "33439", postalCode: "33620" },
    33440: { displayName: "Saint-Martial", INSEEcode: "33440", postalCode: "33490" },
    33441: { displayName: "Saint-Martin-Lacaussade", INSEEcode: "33441", postalCode: "33391" },
    33442: { displayName: "Saint-Martin-de-Laye", INSEEcode: "33442", postalCode: "33910" },
    33443: { displayName: "Saint-Martin-de-Lerm", INSEEcode: "33443", postalCode: "33540" },
    33444: { displayName: "Saint-Martin-de-Sescas", INSEEcode: "33444", postalCode: "33490" },
    33445: { displayName: "Saint-Martin-du-Bois", INSEEcode: "33445", postalCode: "33910" },
    33446: { displayName: "Saint-Martin-du-Puy", INSEEcode: "33446", postalCode: "33540" },
    33447: { displayName: "Saint-Médard-de-Guizières", INSEEcode: "33447", postalCode: "33230" },
    33448: { displayName: "Saint-Médard-d'Eyrans", INSEEcode: "33448", postalCode: "33652" },
    33449: { displayName: "Saint-Médard-en-Jalles", INSEEcode: "33449", postalCode: "33165" },
    33451: { displayName: "Saint-Michel-de-Fronsac", INSEEcode: "33451", postalCode: "33505" },
    33452: { displayName: "Saint-Michel-de-Rieufret", INSEEcode: "33452", postalCode: "33720" },
    33453: { displayName: "Saint-Michel-de-Lapujade", INSEEcode: "33453", postalCode: "33190" },
    33454: { displayName: "Saint-Morillon", INSEEcode: "33454", postalCode: "33650" },
    33456: { displayName: "Saint-Palais", INSEEcode: "33456", postalCode: "33820" },
    33457: { displayName: "Saint-Pardon-de-Conques", INSEEcode: "33457", postalCode: "33210" },
    33458: { displayName: "Saint-Paul", INSEEcode: "33458", postalCode: "33390" },
    33459: { displayName: "Saint-Pey-d'Armens", INSEEcode: "33459", postalCode: "33330" },
    33460: { displayName: "Saint-Pey-de-Castets", INSEEcode: "33460", postalCode: "33350" },
    33461: { displayName: "Saint-Philippe-d'Aiguille", INSEEcode: "33461", postalCode: "33350" },
    33462: { displayName: "Saint-Philippe-du-Seignal", INSEEcode: "33462", postalCode: "33220" },
    33463: { displayName: "Saint-Pierre-d'Aurillac", INSEEcode: "33463", postalCode: "33490" },
    33464: { displayName: "Saint-Pierre-de-Bat", INSEEcode: "33464", postalCode: "33760" },
    33465: { displayName: "Saint-Pierre-de-Mons", INSEEcode: "33465", postalCode: "33210" },
    33466: { displayName: "Saint-Quentin-de-Baron", INSEEcode: "33466", postalCode: "33750" },
    33467: { displayName: "Saint-Quentin-de-Caplong", INSEEcode: "33467", postalCode: "33220" },
    33468: { displayName: "Sainte-Radegonde", INSEEcode: "33468", postalCode: "33350" },
    33470: { displayName: "Saint-Romain-la-Virvée", INSEEcode: "33470", postalCode: "33240" },
    33471: { displayName: "Saint-Sauveur", INSEEcode: "33471", postalCode: "33250" },
    33472: { displayName: "Saint-Sauveur-de-Puynormand", INSEEcode: "33472", postalCode: "33660" },
    33473: { displayName: "Saint-Savin", INSEEcode: "33473", postalCode: "33920" },
    33474: { displayName: "Saint-Selve", INSEEcode: "33474", postalCode: "33652" },
    33475: { displayName: "Saint-Seurin-de-Bourg", INSEEcode: "33475", postalCode: "33710" },
    33476: { displayName: "Saint-Seurin-de-Cadourne", INSEEcode: "33476", postalCode: "33180" },
    33477: { displayName: "Saint-Seurin-de-Cursac", INSEEcode: "33477", postalCode: "33394" },
    33478: { displayName: "Saint-Seurin-sur-l'Isle", INSEEcode: "33478", postalCode: "33660" },
    33479: { displayName: "Saint-Sève", INSEEcode: "33479", postalCode: "33190" },
    33480: { displayName: "Saint-Sulpice-de-Faleyrens", INSEEcode: "33480", postalCode: "33330" },
    33481: { displayName: "Saint-Sulpice-de-Guilleragues", INSEEcode: "33481", postalCode: "33580" },
    33482: { displayName: "Saint-Sulpice-de-Pommiers", INSEEcode: "33482", postalCode: "33540" },
    33483: { displayName: "Saint-Sulpice-et-Cameyrac", INSEEcode: "33483", postalCode: "33451" },
    33485: { displayName: "Sainte-Terre", INSEEcode: "33485", postalCode: "33350" },
    33486: { displayName: "Saint-Trojan", INSEEcode: "33486", postalCode: "33710" },
    33487: { displayName: "Saint-Vincent-de-Paul", INSEEcode: "33487", postalCode: "33440" },
    33488: { displayName: "Saint-Vincent-de-Pertignas", INSEEcode: "33488", postalCode: "33420" },
    33489: { displayName: "Saint-Vivien-de-Blaye", INSEEcode: "33489", postalCode: "33920" },
    33490: { displayName: "Saint-Vivien-de-Médoc", INSEEcode: "33490", postalCode: "33590" },
    33491: { displayName: "Saint-Vivien-de-Monségur", INSEEcode: "33491", postalCode: "33580" },
    33492: { displayName: "Saint-Yzan-de-Soudiac", INSEEcode: "33492", postalCode: "33920" },
    33493: { displayName: "Saint-Yzans-de-Médoc", INSEEcode: "33493", postalCode: "33340" },
    33494: { displayName: "Salaunes", INSEEcode: "33494", postalCode: "33160" },
    33496: { displayName: "Sallebœuf", INSEEcode: "33496", postalCode: "33370" },
    33499: { displayName: "Les Salles-de-Castillon", INSEEcode: "33499", postalCode: "33350" },
    33500: { displayName: "Samonac", INSEEcode: "33500", postalCode: "33710" },
    33501: { displayName: "Saucats", INSEEcode: "33501", postalCode: "33650" },
    33502: { displayName: "Saugon", INSEEcode: "33502", postalCode: "33920" },
    33504: { displayName: "Sauternes", INSEEcode: "33504", postalCode: "33210" },
    33505: { displayName: "La Sauve", INSEEcode: "33505", postalCode: "33670" },
    33506: { displayName: "Sauveterre-de-Guyenne", INSEEcode: "33506", postalCode: "33540" },
    33507: { displayName: "Sauviac", INSEEcode: "33507", postalCode: "33430" },
    33508: { displayName: "Savignac", INSEEcode: "33508", postalCode: "33124" },
    33509: { displayName: "Savignac-de-l'Isle", INSEEcode: "33509", postalCode: "33910" },
    33510: { displayName: "Semens", INSEEcode: "33510", postalCode: "33490" },
    33511: { displayName: "Sendets", INSEEcode: "33511", postalCode: "33690" },
    33512: { displayName: "Sigalens", INSEEcode: "33512", postalCode: "33690" },
    33513: { displayName: "Sillas", INSEEcode: "33513", postalCode: "33690" },
    33514: { displayName: "Soulac-sur-Mer", INSEEcode: "33514", postalCode: "33780" },
    33515: { displayName: "Soulignac", INSEEcode: "33515", postalCode: "33760" },
    33516: { displayName: "Soussac", INSEEcode: "33516", postalCode: "33790" },
    33517: { displayName: "Soussans", INSEEcode: "33517", postalCode: "33460" },
    33518: { displayName: "Tabanac", INSEEcode: "33518", postalCode: "33550" },
    33519: { displayName: "Le Taillan-Médoc", INSEEcode: "33519", postalCode: "33327" },
    33520: { displayName: "Taillecavat", INSEEcode: "33520", postalCode: "33580" },
    33521: { displayName: "Talais", INSEEcode: "33521", postalCode: "33590" },
    33522: { displayName: "Talence", INSEEcode: "33522", postalCode: "33404" },
    33523: { displayName: "Targon", INSEEcode: "33523", postalCode: "33760" },
    33524: { displayName: "Tarnès", INSEEcode: "33524", postalCode: "33240" },
    33525: { displayName: "Tauriac", INSEEcode: "33525", postalCode: "33710" },
    33526: { displayName: "Tayac", INSEEcode: "33526", postalCode: "33570" },
    33530: { displayName: "Teuillac", INSEEcode: "33530", postalCode: "33710" },
    33531: { displayName: "Tizac-de-Curton", INSEEcode: "33531", postalCode: "33420" },
    33532: { displayName: "Tizac-de-Lapouyade", INSEEcode: "33532", postalCode: "33620" },
    33533: { displayName: "Toulenne", INSEEcode: "33533", postalCode: "33213" },
    33534: { displayName: "Le Tourne", INSEEcode: "33534", postalCode: "33550" },
    33535: { displayName: "Tresses", INSEEcode: "33535", postalCode: "33370" },
    33537: { displayName: "Uzeste", INSEEcode: "33537", postalCode: "33730" },
    33538: { displayName: "Valeyrac", INSEEcode: "33538", postalCode: "33340" },
    33539: { displayName: "Vayres", INSEEcode: "33539", postalCode: "33870" },
    33540: { displayName: "Vendays-Montalivet", INSEEcode: "33540", postalCode: "33930" },
    33541: { displayName: "Vensac", INSEEcode: "33541", postalCode: "33590" },
    33542: { displayName: "Vérac", INSEEcode: "33542", postalCode: "33240" },
    33543: { displayName: "Verdelais", INSEEcode: "33543", postalCode: "33490" },
    33544: { displayName: "Le Verdon-sur-Mer", INSEEcode: "33544", postalCode: "33123" },
    33545: { displayName: "Vertheuil", INSEEcode: "33545", postalCode: "33180" },
    33546: { displayName: "Vignonet", INSEEcode: "33546", postalCode: "33330" },
    33547: { displayName: "Villandraut", INSEEcode: "33547", postalCode: "33730" },
    33548: { displayName: "Villegouge", INSEEcode: "33548", postalCode: "33141" },
    33549: { displayName: "Villenave-de-Rions", INSEEcode: "33549", postalCode: "33550" },
    33550: { displayName: "Villenave-d'Ornon", INSEEcode: "33550", postalCode: "33885" },
    33551: { displayName: "Villeneuve", INSEEcode: "33551", postalCode: "33710" },
    33552: { displayName: "Virelade", INSEEcode: "33552", postalCode: "33720" },
    33553: { displayName: "Virsac", INSEEcode: "33553", postalCode: "33240" },
    33554: { displayName: "Yvrac", INSEEcode: "33554", postalCode: "33370" },
};
