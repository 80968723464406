import PropTypes from "prop-types";
import { Typography, makeStyles, Box } from "@material-ui/core";
import theme from "../globalTheme";
import moment from "moment";

const useStyles = makeStyles((e) => ({
    typo: {
        color: theme.palette.primary.light,
        minWidth: "20%",
        whiteSpace: "nowrap",
        lineHeight: 1.3,

        [theme.breakpoints.down("xs")]: {
            width: "30%",
        },
    },
    hours: {
        color: theme.palette.primary.dark,
        marginLeft: "2rem",
        minWidth: "200px",
        lineHeight: 1.3,

        [theme.breakpoints.down("xs")]: {
            minWidth: "inherit",
            marginLeft: "1rem",
        },
    },
}));

const Day = (props) => {
    const styles = useStyles(props);

    const formattedTime = (data) => {
        return moment(data)?.format("HH[h]mm");
    };

    const getOpeningTime = (time) => {
        if (
            time?.openMorning &&
            time?.closeMorning &&
            time?.openAfternoon &&
            time?.closeAfternoon
        ) {
            return `${formattedTime(time?.openMorning)} - ${formattedTime(
                time?.closeMorning
            )} / ${formattedTime(time?.openAfternoon)} - ${formattedTime(
                time?.closeAfternoon
            )}`;
        } else if (
            time?.openMorning &&
            time?.closeMorning &&
            !time?.openAfternoon &&
            !time?.closeAfternoon
        ) {
            return `${formattedTime(time?.openMorning)} - ${formattedTime(
                time?.closeMorning
            )}`;
        } else if (
            !time?.openMorning &&
            !time?.closeMorning &&
            time?.openAfternoon &&
            time?.closeAfternoon
        ) {
            return `${formattedTime(time?.openAfternoon)} - ${formattedTime(
                time?.closeAfternoon
            )}`;
        } else if (
            time?.openMorning &&
            !time?.closeMorning &&
            !time?.openAfternoon &&
            time?.closeAfternoon
        ) {
            return `${formattedTime(time?.openMorning)} - ${formattedTime(
                time?.closeAfternoon
            )}`;
        } else if (!time?.open) {
            return "Fermé";
        } else {
            return "";
        }
    };
    return (
        <Box
            style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-start",
            }}
        >
            <Typography variant="body2" className={styles.typo}>
                {props?.data?.name}
            </Typography>
            <Typography variant="body2" className={styles.hours}>
                {props?.data?.open ? getOpeningTime(props?.data) : "Fermé"}
            </Typography>
        </Box>
    );
};

Day.propTypes = {
    data: PropTypes.string,
};

export default Day;