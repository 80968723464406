import  { any } from "prop-types";
import frame4 from "assets/appearsInBESV/frame-4.svg";
import frame3 from "assets/appearsInBESV/frame-3.svg";
import frame2 from "assets/appearsInBESV/frame-2.svg";
import frame1 from "assets/appearsInBESV/frame-1.svg";
import icon1 from "assets/appearsInBESV/logo-1.svg";
import icon2 from "assets/appearsInBESV/logo-2.svg";
import icon3 from "assets/appearsInBESV/logo-3.svg";
import { Box, Link, Typography, makeStyles } from "@material-ui/core";
import FeatureCard from "./FeatureCard";
import theme from "globalTheme";
import { Trans } from "@lingui/macro";
import spanish from "assets/DataSheets/spanish.svg";
import french from "assets/DataSheets/french.svg";
import english from "assets/DataSheets/english.svg";
import dutch from "assets/DataSheets/dutch.svg";
import italian from "assets/DataSheets/italian.svg";
import japenese from "assets/DataSheets/japenese.svg";
import chinese from "assets/DataSheets/chinese.svg";
import german from "assets/DataSheets/german.svg";
import { SwiperWrapper } from "Front/SwiperCards";
import { useWindowDimensions } from "helpers/helpers";

const useStyles = makeStyles(() => ({
    features_container: {
        gridTemplateColumns: "repeat(12, 1fr)",
        display: "grid",
        gap: "1rem",
    },
    feature_card_1: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "auto / span 12",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "1 / span 6",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 4",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 3",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "1 / span 3",
        },
    },
    feature_card_2: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "auto / span 12",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 6",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 4",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 3",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "auto / span 3",
        },
    },
    feature_card_3: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "auto / span 12",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 6",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 4",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 3",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "auto / span 3",
        },
    },
    feature_card_4: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "auto / span 12",
        },

        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 6",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "4 / span 6",
        },
        [theme.breakpoints.only("md")]: {
            background: "none",
            height: "fit-content",
            marginBottom: "4rem",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 3",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "auto / span 3",
        },
    },
    feature_card_4_content: {
        textAlign: "left",
        marginLeft: "1.1rem",
        marginTop: "-1rem",
        [theme.breakpoints.only("md")]: {
            textAlign: "center",
        },
    },
    feature_card_4_flags_content: {
        display: "flex",
        gap: "4px",
        margin: "6px 0",
        [theme.breakpoints.only("md")]: {
            justifyContent: "center",
        },
    },
}));

const KeyFeatures = (props) => {
    const styles = useStyles();
    const { width } = useWindowDimensions();

    const handleClick = () => {
        props.showMoreRef.current.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <Box
            className={styles.features_container}
            component={width < 600 && SwiperWrapper}
            spaceBetween={0}
            slidesPerView={1}
            noPadding
        >
            <FeatureCard
                background={frame1}
                className={styles.feature_card_1}
                icon={icon1}
                title={
                    <Trans>
                        Profitez d’une vitrine
                        <br /> digitale spécialisée
                    </Trans>
                }
                subtitle={
                    <Trans>
                        <b>
                            Offrez à votre propriété une visibilité sans précédent grâce à <i>Bordeaux et ses Vins !</i>
                        </b>
                    </Trans>
                }
                divider
            />
            <FeatureCard
                background={frame2}
                className={styles.feature_card_2}
                icon={icon2}
                title={
                    <Trans>
                        présentez vos vins et
                        <br /> Faites-les déguster
                    </Trans>
                }
                subtitle={
                    <Trans>
                        Vos <b>vins dégustés</b> par des <b>journalistes</b> de <b>renommée internationale !</b>
                    </Trans>
                }
                divider
            />
            <FeatureCard
                background={frame3}
                className={styles.feature_card_3}
                icon={icon3}
                title={
                    <Trans>
                        Automatisez vos
                        <br /> fiches techniques
                    </Trans>
                }
                subtitle={
                    <Trans>
                        <b>
                            Concevez facilement et en 5 minutes, automatisez, diffusez et suivez vos fiches techniques !
                        </b>
                    </Trans>
                }
                divider
            />
            <FeatureCard
                background={frame4}
                className={styles.feature_card_4}
                subtitle={
                    <Box className={styles.feature_card_4_content}>
                        <Trans>
                            <b>Et bien plus encore...</b>
                        </Trans>
                        <Typography variant="h5" style={{ fontFamily: "Mansalva", marginTop: "0.5rem" }}>
                            <Trans>Faites tomber les frontières, traduction en 8 langues incluse !</Trans>
                        </Typography>
                        <Box className={styles.feature_card_4_flags_content}>
                            <img src={french} />
                            <img src={spanish} />
                            <img src={italian} />
                            <img src={dutch} />
                            <img src={chinese} />
                            <img src={japenese} />
                            <img src={english} />
                            <img src={german} />
                        </Box>
                        <Link
                            variant="h5"
                            style={{ textDecoration: "underline", cursor: "pointer" }}
                            onClick={handleClick}
                        >
                            <Trans>voir toutes les fonctionnalités</Trans>
                        </Link>
                    </Box>
                }
            />
        </Box>
    );
};

KeyFeatures.propTypes = {
    showMoreRef: any,
};

export default KeyFeatures;
