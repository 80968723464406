import { t } from "@lingui/macro";
import { any, string } from "prop-types";
import React from "react";
import { Helmet as HelmetReact } from "react-helmet";

const Helmet = ({ title, content, link }) => {
    return (
        <HelmetReact>
            <title>{(title ? `${title} - ` : "") + t`Bordeaux et ses Vins`}</title>
            {content && <meta name="description" content={content} />} {link}
        </HelmetReact>
    );
};

Helmet.propTypes = {
    title: string,
    content: string,
    link: any,
};

export default Helmet;
