import { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import magentaArrow from "assets/base_arrow.svg";
import secondaryArrow from "assets/secondary_arrow.svg";
import PropTypes, { object, string } from "prop-types";
import arrowComplexeImg from "../../assets/arrow-complex.svg";
import arrowImg from "../../assets/arrow.svg";
import arrow2 from "../../assets/arrow2.svg";
import arrow3 from "../../assets/arrow3.svg";
import whiteArrowImg from "../../assets/fleche_blanche.svg";
import redArrow from "../../assets/red-arrow.svg";
// import theme  from "../../globalTheme";

const getRotation = (rotation) => {
    if (rotation) {
        return `rotate( ${rotation}deg )`;
    }
    return "rotate( 0deg )";
};

const getMirror = (mirror) => {
    if (mirror) {
        return "scaleX( -1 )";
    }
    return "scaleX( 1 )";
};

const useStyle = makeStyles((theme) => ({
    root: {
        display: "flex",
        "& .MuiCardContent-root:last-child": {
            padding: 0,
        },
    },
    content: (props) => ({
        width: "20px",
        height: "20px",
        display: props.display ?? "flex",
        padding: 0,
        alignSelf: props.arrowAlignSelf,
    }),
    media: ({ rotation, mirror, mediaColor }) => ({
        objectFit: "none",
        transform: getRotation(rotation) + " " + getMirror(mirror),
        color: mediaColor ?? "#915868",
    }),

    ArrowAndText: (props) => {
        return {
            position: "relative",
            marginLeft: props.marginLeft ? theme.spacing(4) : theme.spacing(0),
            display: "flex",
            [theme.breakpoints.up("xs")]: {
                alignItems: null,
            },
        };
    },
}));

//------------------------------------
const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
};
const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
};
//------------------------------------

const Arrow = (props) => {
    const styles = useStyle(props);
    const { width } = useWindowDimensions();

    const checkColorArrow = (color) => {
        if (color === "white") {
            return whiteArrowImg;
        }
        if (color === "Woody Brown") {
            return arrow2;
        }
        if (color === "primary") {
            return arrow3;
        }
        if (color === "complex") {
            return arrowComplexeImg;
        }
        if (color === "red") {
            return redArrow;
        }
        if (color === "magenta") {
            return magentaArrow;
        }
        if (color === "secondary") {
            return secondaryArrow;
        }
        return arrowImg;
    };

    const imageComponent = (
        <CardContent className={styles.content} style={props.arrowStyle}>
            <CardMedia
                className={styles.media}
                image={checkColorArrow(props.icon)}
                component="img"
                style={props.mediaStyle}
            />
        </CardContent>
    );

    const checkResponsiveScreen = () => {
        if (width <= 0) {
            return (
                <Box className={[styles.root, styles.ArrowAndText, props.styleBox].join(" ")} style={props.style}>
                    {props.children}
                </Box>
            );
        } else {
            return (
                <Box className={[styles.root, styles.ArrowAndText, props.styleBox].join(" ")} style={props.style}>
                    {props.arrowPosition === "start" && imageComponent}
                    <Box
                        style={
                            props.textBoxStyle || {
                                marginLeft: "8px",
                                marginRight: "8px",
                                marginTop: "2px",
                            }
                        }
                    >
                        {props.children}
                    </Box>
                    {props.arrowPosition === "end" && imageComponent}
                </Box>
            );
        }
    };

    return (
        <Box style={props.containerBox} className={props.className}>
            {checkResponsiveScreen()}
        </Box>
    );
};

Arrow.propTypes = {
    icon: PropTypes.string,
    horizontal: PropTypes.string,
    vertical: PropTypes.string,
    rotation: PropTypes.string,
    arrowPosition: PropTypes.string,
    children: PropTypes.any,
    style: PropTypes.object,
    textBoxStyle: PropTypes.any,
    mirror: PropTypes.bool,
    arrowAlignSelf: PropTypes.string,
    marginLeft: PropTypes.bool,
    styleBox: PropTypes.object,
    marginTopTypography: PropTypes.number,
    arrowStyle: PropTypes.object,
    mediaColor: PropTypes.string,
    containerBox: object,
    mediaStyle: object,
    display: string,
    className: object,
};

export default Arrow;
