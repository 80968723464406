import { t } from "@lingui/macro";
import { Box, makeStyles, Typography } from "@material-ui/core";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";

import Popin from "../../../Containers/Popin";
import theme from "../../../globalTheme";
import { useWindowDimensions } from "../../../helpers/helpers";

const useStyle = makeStyles((theme) => ({
    container: {
        width: "25rem",
        [theme.breakpoints.down("sm")]: {
            minWidth: "400px",
            padding: "0px",
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            minWidth: "100%",
            padding: "0px",
        },
    },
    typo: {
        color: "#9F8D93",
        minWidth: "20%",
        fontSize: "16px",
        fontFamily: "Lato",
        whiteSpace: "nowrap",
        [theme.breakpoints.down("xs")]: {
            width: "30%",
        },
    },
    hours: {
        fontFamily: "Lato",
        fontSize: "16px",
        color: "#360318",
        marginLeft: "2rem",
        minWidth: "240px",
        [theme.breakpoints.down("xs")]: {
            minWidth: "inherit",
            marginLeft: "2rem",
            width: "100%",
        },
    },
}));

const WeeklyHoursPopin = (props) => {
    const styles = useStyle(props);
    const { width, height } = useWindowDimensions();

    const formattedTime = (data) => {
        return moment(data)?.format("HH[h]mm");
    };

    const getOpeningTime = (time) => {
        if (time?.openMorning && time?.closeMorning && time?.openAfternoon && time?.closeAfternoon) {
            return `${formattedTime(time?.openMorning)} - ${formattedTime(time?.closeMorning)} / ${formattedTime(
                time?.openAfternoon
            )} - ${formattedTime(time?.closeAfternoon)}`;
        } else if (time?.openMorning && time?.closeMorning && !time?.openAfternoon && !time?.closeAfternoon) {
            return `${formattedTime(time?.openMorning)} - ${formattedTime(time?.closeMorning)}`;
        } else if (!time?.openMorning && !time?.closeMorning && time?.openAfternoon && time?.closeAfternoon) {
            return `${formattedTime(time?.openAfternoon)} - ${formattedTime(time?.closeAfternoon)}`;
        } else if (time?.openMorning && !time?.closeMorning && !time?.openAfternoon && time?.closeAfternoon) {
            return `${formattedTime(time?.openMorning)} - ${formattedTime(time?.closeAfternoon)}`;
        } else if (!time?.open) {
            return t`Fermé`;
        } else {
            return t`Pas d'information sur ce jour`;
        }
    };
    const getName = (name) => {
        for (let i = 0; i < props.comparedDays.length; i++) {
            const day = props.comparedDays[i];
            if (day.titleFr === name) {
                return day.title;
            }
        }
    };
    return (
        <Popin
            title={
                <Typography
                    variant="h3"
                    style={{
                        fontSize: "29px",
                        marginBottom: theme.spacing(4),
                        textAlign: "center",
                    }}
                >{t`Horaires d'ouverture`}</Typography>
            }
            open={props.modalOpen}
            onClose={props.handleClose}
            cross
            background={5}
        >
            <Box className={styles.container}>
                {props?.weeklyHours?.map((el, i) => {
                    return (
                        <Box
                            key={i}
                            style={{
                                display: "flex",
                                width: "100%",
                                marginBottom: "1rem",
                                justifyContent: width > 600 ? "center" : "",
                            }}
                        >
                            <Typography className={styles.typo}>{getName(el.name)} :</Typography>
                            <Typography className={styles.hours} variant="h5">
                                {getOpeningTime(el)}
                            </Typography>
                        </Box>
                    );
                })}
            </Box>
        </Popin>
    );
};

WeeklyHoursPopin.propTypes = {
    modalOpen: PropTypes.bool,
    handleClose: PropTypes.func,
    weeklyHours: PropTypes.array,
    comparedDays: PropTypes.array,
};

export default WeeklyHoursPopin;
