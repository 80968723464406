import React from "react";
import PropTypes from "prop-types";
import { Box, makeStyles } from "@material-ui/core";
import theme from "../../globalTheme";
// import subscriptionBG from "../../assets/SubscriptionPage/background.png";
// import subscriptionBG from "../../assets/SubscriptionPage/background_color.webp";

const useStyles = makeStyles(() => ({
    containerBackground: {
        height: "100%",
        [theme.breakpoints.up("sm")]: {
            paddingTop: "112px",
        },
        [theme.breakpoints.up("md")]: {
            paddingTop: "160px",
        },
        [theme.breakpoints.up("lg")]: {
            paddingTop: "192px",
        },
        [theme.breakpoints.up("xl")]: {},
    },
}));

const SubscriptionBackground = (props) => {
    const styles = useStyles();
    return (
        <Box className={styles.containerBackground} display="flex" flexDirection="column">
            {props.children}
        </Box>
    );
};

SubscriptionBackground.propTypes = {
    children: PropTypes.any,
};

export default SubscriptionBackground;
