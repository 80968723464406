import React from "react";
import PropTypes from "prop-types";
import ReactApexCharts from "react-apexcharts";
import theme from "../../globalTheme";

import {
    makeStyles
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    chart: {
        // [theme.breakpoints.only("xs")]: {
        //     padding: "43px 37px 0 10px"
        // },
        // [theme.breakpoints.only("md")]: {
        //     padding: "32px"
        // },
        "& .apexcharts-toolbar": {
            display: "none"
        },
        "& .apexcharts-svg": {
            overflow:"visible",
        },
        "& .apexcharts-text": {
            display:"none"
        },
        "& .apexcharts-datalabel" : {
            fill: theme.palette.secondary.dark,
            fontSize: theme.typography.body1.fontSize,
            fontFamily: `${theme.typography.body1.fontFamily} !important` ,

        }
    }
}));

const RadarChart = (props) => {
    const styles = useStyles(props);
    return (
        <ReactApexCharts className={styles.chart} options={props.options} series={props.series} type="radar" height={props.height || 180} />
    );
};

RadarChart.propTypes = {
    options: PropTypes.object,
    series: PropTypes.array,
    height: PropTypes.number
};

export default RadarChart;