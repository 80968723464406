import PropTypes from "prop-types";
import FeretSummaryContainer from "../Front/FeretSummary/FeretSummaryContainer";
import { Typography, makeStyles, Box } from "@material-ui/core";
import theme from "../globalTheme";
import Day from "./Day";


const useStyles = makeStyles((e) => ({
    title: {
        fontWeight: "bold",
        color: theme.palette.common.black,
        marginBottom: "7px",
    },
    container: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("sm")]: {
            minWidth: "400px",
            padding: "0px",
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            minWidth: "100%",
            padding: "0px",
        },
    },
}));

const Horaire = (props) => {
    const styles = useStyles(props);

    return (
        <FeretSummaryContainer maxWidth={props.maxWidth ?? "none"}>
            <Typography variant="h6" className={styles.title}>{props.title}</Typography>

            <Box className={styles.container}>
                {props?.horaires.map((day, i) => (
                    <Day key={day} data={day} />
                ))}
            </Box>
            
        </FeretSummaryContainer>
    );
};

Horaire.propTypes = {
    maxWidth: PropTypes.number,
    horaires: PropTypes.array,
    title: PropTypes.string
    
};

export default Horaire;