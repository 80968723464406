import { Plural, t } from "@lingui/macro";
import { Box, makeStyles, Paper, Typography } from "@material-ui/core";
import { merge } from "lodash";
import PropTypes, { any, bool, object, shape, string } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import fleuritures03 from "assets/fleuritures/estate/team/f08 card équipe.png";
import fioriture from "assets/propriétéPage/fioriture8.svg";
import Arrow from "Components/Arrow/Arrow";
import Page500Front from "Components/ErrorPage/Page500Front";
import Helmet from "Components/Helmet/Helmet";
import SocialMediaIcons from "Components/Icons/SocialMediaIcons";
import LoadingWithImage from "Components/Loader/LoadingWithImage";
import Gallery from "Components/Media/Gallery";
import LgGallery from "Components/Media/LgGallery ";
import MobileGallery from "Components/Media/MobileGallery";
import SmallGallery from "Components/Media/SmallGallery";
import CellarsSwipeCompo from "Components/Swipe/CellarsSwipeCompo";
import ExpandableFormattedTextBox from "Components/Texts/ExpandableFormattedTextBox";
import { SUBSCRIPTION_TYPES } from "config/constants";
import Quote from "Dashboard/Components/Quote";
import { setAdoptWineEntity } from "features/settings/settingsSlice";
import { signOut } from "features/user/userSlice";
import FrontContentBottom from "Front/FrontContentBottom";
import FrontContentTop from "Front/FrontContentTop";
import FrontMainContainer from "Front/FrontMainContainer";
import ActivityCardsContainer from "FrontEstate/Components/Cards/ActivityCardsContainer";
import ContactPropertyFormCard from "FrontEstate/Components/Cards/ContactPropertyFormCard/ContactPropertyFormCard";
import WineCardsContainer from "FrontEstate/Components/Cards/WineCardsContainer";
import EstateTitledSemiUnderlined from "FrontEstate/Components/EstateTitledSemiUnderlined";
import PersonsDetailsBlock from "FrontEstate/Components/PersonsDetails/PersonsDetailsBlock";
import PropertySummary from "FrontEstate/Components/PropertySummary/PropertySummary";
import ScrolledPropertySummary from "FrontEstate/Components/PropertySummary/ScrolledPropertySummary";
import PublicationTimeline from "FrontEstate/Components/PublicationTimeline/PublicationTimeline";
import MainTitle from "FrontEstate/MainTitle";
import TitleStandalone from "FrontEstate/TitleStandalone";
import { useEstateFrontScreenQuery } from "generated/graphql";
import theme from "globalTheme";
import { getTextLength, parseHtml, useWindowDimensions } from "helpers/helpers";

import { useCustomHistory } from "hooks";
import { useState } from "react";
import EstateCommunalities from "./EstateCommonalities";

const useStyles = makeStyles(() => {
    return {
        teamDescriptionTypoBox: {
            transition: "max-width .6s",
            zIndex: 10,
            [theme.breakpoints.up("xs")]: {
                maxWidth: "100%",
                display: "none",
            },
            [theme.breakpoints.up("md")]: {
                maxWidth: "57%",
                display: "none",
            },
            [theme.breakpoints.up("lg")]: {
                maxWidth: "64%",
                display: "inherit",
                marginTop: "1rem",
                marginLeft: "2rem",
            },
            [theme.breakpoints.up("xl")]: {
                maxWidth: "66%",
            },
        },
        teamDescriptionTypoBoxResponsive: {
            transition: "max-width .6s",
            // zIndex: 10,
            [theme.breakpoints.up("xs")]: {
                maxWidth: "100%",
            },
            [theme.breakpoints.up("md")]: {
                gridColumn: "1 / span 12",
                marginBottom: "4rem",
            },
            [theme.breakpoints.up("lg")]: {
                display: "none",
            },
        },
        teamDescriptionTypo: {
            ...theme.front.typography.labeur.light,
            [theme.breakpoints.up("xs")]: {
                color: theme.palette.primary.dark,
                marginTop: "32px",
            },
            [theme.breakpoints.up("sm")]: {
                marginTop: "24px",
            },
            [theme.breakpoints.up("md")]: {
                marginTop: "0",
                marginBottom: "2rem",
            },
            [theme.breakpoints.up("lg")]: {
                color: theme.palette.common.white,
                marginBottom: "0",
            },
        },
        propertyNameTitle: {
            color: theme.palette.common.white,
            [theme.breakpoints.up("xs")]: {
                fontSize: "24px",
            },
            [theme.breakpoints.up("sm")]: {
                fontSize: "35px",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "50px",
            },
            [theme.breakpoints.up("lg")]: {
                fontSize: "74px",
            },
        },
        estatePage__socialMedia: {
            display: "flex",
            justifyContent: "flex-end",
            [theme.breakpoints.up("xl")]: {
                position: "absolute",
                right: 0,
                top: "-30px",
            },
        },
        estatePage__gallery: {
            zIndex: 2,
            overflow: "hidden",
        },
        estatePage__subContent: {
            display: "grid",
            gap: "16px",
            marginTop: "1rem",
            gridTemplateColumns: "repeat(12, 1fr)",
            [theme.breakpoints.down("sm")]: {
                display: "flex",
                flexDirection: "column",
            },
        },
        estatePage__subContentPrim: {
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            [theme.breakpoints.up("md")]: {
                gridColumn: "auto / span 7",
            },
            [theme.breakpoints.up("xl")]: {
                gridColumn: "auto / span 8",
            },
        },
        estatePage__cellarTitle: {
            marginBottom: "16px",
            width: "100%",
        },
        estatePage__cellarTypo: {
            ...theme.front.typography.h5.dark_b,
        },
        estatePage__subContentSecond: {
            display: "flex",
            width: "100%",
            [theme.breakpoints.up("md")]: {
                gridColumn: "auto / span 5",
            },
            [theme.breakpoints.up("xl")]: {
                gridColumn: "auto / span 4",
            },
        },
        estatePage__membersBox: {
            margin: "3rem 0",
            position: "relative",
            zIndex: 10,
        },
        estatePage__teamPaper: (props, estate) => {
            const image = props.estate?.teamImage?.twicpicURL;
            const url = image ? `,url("${image}")` : "";

            return {
                zIndex: "-1",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                width: "100%",
                height: 0,
                paddingTop: "33.25%",
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))${url}`,
                backgroundPosition: "center",
                display: "flex",
                flexDirection: "column",
                borderRadius: props.estate?.members < 1 ? "20px" : "20px 20px 0px 0px",
                boxShadow: "0px 3px 76px #00000014",
                position: "relative",
                [theme.breakpoints.up("xs")]: {
                    justifyContent: "flex-start",
                },
                [theme.breakpoints.up("sm")]: {
                    justifyContent: "flex-start",
                },
                [theme.breakpoints.up("md")]: {
                    minHeight: estate?.teamDescription ? null : null,
                },
                [theme.breakpoints.up("lg")]: {
                    minHeight: estate?.teamDescription ? null : null,
                },
                [theme.breakpoints.up("xl")]: {
                    minHeight: estate?.teamDescription ? null : null,
                },
            };
        },
        estatePage__membersPaper: () => {
            const extendsStyle = merge(theme.front.padding.cardMembersPaper, {
                [theme.breakpoints.up("xs")]: {
                    gap: "16px",
                    display: "flex",
                    flexDirection: "column",
                },
                [theme.breakpoints.up("sm")]: {
                    display: "flex",
                    gap: "16px",
                    flexDirection: "column",
                },
                [theme.breakpoints.up("md")]: {
                    display: "grid",
                    gridTemplateColumns: "repeat(12, 1fr)",
                    gap: "32px",
                },
            });
            return {
                ...extendsStyle,
                background: `url("${fleuritures03}") bottom left no-repeat`,
                borderRadius: "0px 0px 20px 20px",
                boxShadow: "0px 3px 76px #00000014",
            };
        },

        estatePage__teamHistoryMainBox: {
            width: "89%",
            position: "absolute",
            zIndex: "2",
            [theme.breakpoints.up("xs")]: {
                bottom: 0,
            },
            [theme.breakpoints.up("lg")]: {
                bottom: "inherit",
                top: "2rem",
            },
        },
        estatePage__teamHistoryMainBoxWithoutMembers: {
            zIndex: "2",
            marginBottom: "6rem",
            marginLeft: "2rem",
        },

        // ORPHAN
        containerOrphanTitle: {
            background: theme.palette.primary.main,
            color: theme.palette.common.white,
            padding: "2rem 1rem",
            margin: "2rem 0",
            textAlign: "center",
            [theme.breakpoints.up("sm")]: {
                textAlign: "center",
                marginLeft: "-16px",
                marginRight: "-16px",
            },
            [theme.breakpoints.up("md")]: {
                background: "none",
                padding: 0,
                margin: "2rem 0",
            },
        },
        titleEstateOrphan: {
            [theme.breakpoints.up("md")]: {
                marginBottom: "1rem",
            },
        },
        arrowOrphanSubtitle: {
            [theme.breakpoints.down("xs")]: {
                display: "none",
            },
        },
        OrphanSubtitle: {
            textDecoration: "underline",
            cursor: "pointer",
            fontWeight: "700",
            [theme.breakpoints.up("sm")]: {
                display: "none",
            },
        },
        activityBox: {
            width: "100%",

            [theme.breakpoints.up("xl")]: {
                width: "1314px",
            },
        },
    };
});

const getPersonsTitle = (estateName, teamDescription, members, styles) =>
    (estateName || teamDescription) && (
        <Box
            className={
                teamDescription && teamDescription !== "<div/>"
                    ? styles.estatePage__teamHistoryMainBox
                    : styles.estatePage__teamHistoryMainBoxWithoutMembers
            }
        >
            {estateName && (
                <Box className={styles.estateTileMember}>
                    <EstateTitledSemiUnderlined
                        style={{
                            margin: teamDescription && teamDescription && "0 2rem 2rem 2rem",
                            color: theme.palette.common.white,
                        }}
                        title={t`Ils sont ${estateName}`}
                        borderColor={theme.palette.common.white}
                        heightAfter={32}
                    />
                </Box>
            )}
            {teamDescription && teamDescription !== 0 && (
                <Box xs={12} className={styles.teamDescriptionTypoBox}>
                    <Typography className={styles.teamDescriptionTypo}>{parseHtml(teamDescription)}</Typography>
                </Box>
            )}
        </Box>
    );

const GenericEstateScreen = (props) => {
    const { locale: pageLocale, wineEntityShortId } = useParams();
    const settingsLocale = useSelector((state) => state?.user?.locale || "fr");
    const locale = pageLocale || settingsLocale;
    const history = useCustomHistory();
    const dispatch = useDispatch();
    const [activitiesAreLoading, setActivitiesAreLoading] = useState(false);
    const [besvInfosAreLoading, setBesvInfosAreLoading] = useState(false);
    const [winesAreLoading, setWinesAreLoading] = useState(false);
    const [loadingContact, setContactAreLoading] = useState(false);
    const { loading, error, data } = useEstateFrontScreenQuery({
        variables: { wineEntityShortId, locale },
    });

    const estate = data?.EstateFrontScreen;
    const wineEntityId = estate?.wineEntityId;
    const isOrphan = estate?.isOrphan;
    const styles = useStyles({ ...props, estate });
    const { width } = useWindowDimensions();
    const hasWines = estate?.firstWines?.length + estate?.secondWines?.length + estate?.otherWines?.length > 0;
    const restriction = estate?.subscriptionName == SUBSCRIPTION_TYPES.basic;

    if (loading) {
        return <LoadingWithImage />;
    } else if (!estate) {
        return <Redirect to="/" />;
    } else if (error) {
        return <Page500Front />;
    }

    const getGallery = () => {
        if (width >= 1280) {
            return <Gallery imageUrls={estate?.images} front />;
        } else if (width < 1280 && width >= 960) {
            return <SmallGallery imageUrls={estate?.images} front />;
        } else {
            return <MobileGallery imagesData={estate?.images} front />;
        }
    };

    const getGalleryWithFloriture = () => {
        if (width >= 1280) {
            return <LgGallery imageUrls={estate?.images} front />;
        } else if (width < 1280) {
            return <MobileGallery imagesData={estate?.images} front />;
        }
    };

    const noTeamDescription =
        estate?.teamDescription === "<div/>" ||
        estate?.teamDescription === null ||
        estate?.teamDescription === undefined;

    const getQuoteOusideMainBoard = () => {
        const getWidth = () => {
            if (width >= 1280 && estate?.cellars?.length === 1) {
                return "80%";
            } else {
                return "100%";
            }
        };
        return estate?.citation && estate?.cellars?.length <= 1 ? (
            <Box
                id="containerQuote"
                style={{
                    width: getWidth(),
                    margin: width > 1280 ? "0 3rem" : "0",
                    minWidth: 250,
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <Quote
                    small
                    oneSideOf
                    quoteAuthor={estate.citationAuthor?.title}
                    quoteAuthorFunctions={estate?.citationAuthor?.functions || []}
                >
                    {parseHtml(estate?.citation)}
                </Quote>
            </Box>
        ) : null;
    };

    const regainControl = async () => {
        try {
            await dispatch(signOut());
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        } finally {
            const { name, mainAppellation, commune, address } = estate;
            dispatch(setAdoptWineEntity({ wineEntityId, estateName: name, mainAppellation, commune, address }));
            history.push("/bienvenue");
        }
    };

    return (
        <>
            <ToastContainer
                autoClose={3500}
                draggable
                pauseOnHover
                pauseOnFocusLoss
                closeOnClick
                position={"top-right"}
            />
            <Helmet
                title={estate?.name}
                content={`Découvrez la propriété viticole ${estate?.name} et son terroir d'exception. Apprenez-en davantage sur ses vins et leurs millésimes grâce à ses fiches techniques détaillées. Plongez dans l'histoire de la propriété dès maintenant sur Bordeaux et ses Vins`}
            />

            <FrontContentTop backgroundImage={estate?.bgImage?.twicpicURL} cropBackground boxStyles={props.boxStyles}>
                {props.frontTitle && <MainTitle wineEntityId={wineEntityId} />}
                {/* Content Page Container */}
                {isOrphan ? (
                    <>
                        <ScrolledPropertySummary estate={estate} locale={locale} isOrphan={isOrphan} />
                        <Box className={styles.containerOrphanTitle}>
                            <Typography variant="h3" className={styles.titleEstateOrphan}>
                                {estate?.name} est votre propriété ?
                            </Typography>
                            <Typography
                                className={styles.OrphanSubtitle}
                                variant="h5"
                                onClick={async () => {
                                    await regainControl();
                                }}
                            >
                                Prenez gratuitement le contrôle sur sa visibilité !
                            </Typography>
                            <Box className={styles.arrowOrphanSubtitle}>
                                <Arrow
                                    arrowPosition="start"
                                    icon="white"
                                    style={{
                                        cursor: "pointer",
                                        justifyContent: "center",
                                    }}
                                    mediaStyle={{ marginRight: 0 }}
                                >
                                    <Typography
                                        variant="h6"
                                        style={{
                                            textDecoration: "underline",
                                            color: theme.palette.common.white,
                                            fontWeight: 700,
                                        }}
                                        onClick={() => regainControl()}
                                    >
                                        Prenez gratuitement le contrôle sur sa visibilité !
                                    </Typography>
                                </Arrow>
                            </Box>
                        </Box>
                    </>
                ) : (
                    <>
                        <FrontMainContainer
                            ref={props.estateRef}
                            styleCardContent
                            hideFloritureTopImg={props.hideFloritureTopImg}
                            className={styles.front_main_container}
                        >
                            {/* Social medias */}
                            {props.socialMedias && (
                                <Box className={styles.estatePage__socialMedia}>
                                    <SocialMediaIcons socialMedias={estate?.socialMedias} />
                                </Box>
                            )}
                            {props.titleStandalone && (
                                <TitleStandalone wineEntityId={props.wineEntityId || wineEntityId} logo={props.logo} />
                            )}
                            {/* Gallery */}
                            <Box className={styles.estatePage__gallery}>
                                {!estate.images.length || estate.images.length > 1
                                    ? getGallery()
                                    : getGalleryWithFloriture()}
                                {/* <SocialShare /> */}
                            </Box>

                            {!restriction && (
                                <>
                                    <Box className={styles.estatePage__subContent}>
                                        <Box className={styles.estatePage__subContentPrim}>
                                            {/* Estate Hisory */}
                                            {estate?.description && (
                                                <Box className={styles.estatePage__titleDescription}>
                                                    <EstateTitledSemiUnderlined
                                                        title={t`La propriété`}
                                                        heightAfter={32}
                                                    />

                                                    <ExpandableFormattedTextBox
                                                        value={estate?.description}
                                                        truncatedValue={estate?.truncatedDescription}
                                                    />
                                                </Box>
                                            )}

                                            {/* Cellars Title */}

                                            {width >= 960 && estate?.cellars?.length !== 0 ? (
                                                <Box
                                                    style={{
                                                        display: estate?.cellars?.length === 1 && "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "justify-content",
                                                    }}
                                                >
                                                    <Box>
                                                        <Typography
                                                            variant="h3"
                                                            style={{
                                                                fontWeight: "bold",
                                                                fontSize: "21px",
                                                                marginBottom: "1rem",
                                                            }}
                                                        >
                                                            <Plural
                                                                value={estate?.cellars?.length}
                                                                one="Le chai"
                                                                other="Les chais"
                                                            />
                                                        </Typography>
                                                        <Box
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                flexWrap: "wrap",
                                                                gap: 16,
                                                            }}
                                                        >
                                                            <Box
                                                                style={{ flex: 1 }}
                                                                className={styles.estatePage__cellarTitle}
                                                            >
                                                                <CellarsSwipeCompo
                                                                    wineEntityName={estate?.name}
                                                                    cellarsData={estate?.cellars}
                                                                    wineEntityId={props.wineEntityId || wineEntityId}
                                                                    locale={locale}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    {width >= 1280 && (
                                                        <Box
                                                            style={{
                                                                display: "flex",
                                                                marginLeft: "1rem",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            {getQuoteOusideMainBoard()}
                                                        </Box>
                                                    )}
                                                </Box>
                                            ) : (
                                                <Box
                                                    style={{
                                                        display: width >= 960 ? "flex" : "none",
                                                        marginTop: "2rem",
                                                    }}
                                                >
                                                    {getQuoteOusideMainBoard()}
                                                </Box>
                                            )}
                                        </Box>

                                        {/* Property Summary Container */}
                                        <Box className={styles.estatePage__subContentSecond}>
                                            <PropertySummary
                                                standalone={props.standalone}
                                                mainAOCName={estate?.mainAppellation}
                                                commune={estate?.commune}
                                                region={estate?.region}
                                                sousRegion={estate?.subregion}
                                                subregionImage={estate?.subregionImage?.data}
                                                vineSurfaceArea={estate?.vineSurfaceArea}
                                                hasVineyardInformation={estate?.hasVineyardInformation}
                                                certificationsArray={estate?.certifications}
                                                membersOf={estate?.promotionBodies}
                                                marketing={estate?.commercialisationTypes}
                                                estateName={estate?.name}
                                                wineEntityId={props.wineEntityId || wineEntityId}
                                                totalSurfaceArea={estate?.estateSurfaceArea}
                                                owners={estate?.owners}
                                                operators={estate?.operators}
                                                locale={locale}
                                            />
                                        </Box>

                                        {width < 960 && estate?.cellars?.length !== 0 && (
                                            <Box
                                                style={{ marginTop: theme.spacing(2) }}
                                                className={styles.estatePage__cellarTitle}
                                            >
                                                <Typography variant="h4" className={styles.estatePage__cellarTypo}>
                                                    {estate?.cellars?.length === 1 ? "Le chai" : "Les chais"}
                                                </Typography>
                                            </Box>
                                        )}
                                        {width < 960 ? (
                                            <CellarsSwipeCompo
                                                wineEntityName={estate?.wineEntityName}
                                                cellarsData={estate?.cellars}
                                                wineEntityId={props.wineEntityId || wineEntityId}
                                                locale={locale}
                                            />
                                        ) : null}
                                    </Box>
                                    {((width < 1280 && estate?.cellars?.length === 1 && estate?.citation) ||
                                        (width < 960 && estate?.cellars?.length === 0 && estate?.citation)) && (
                                        <Box>
                                            <Quote
                                                small
                                                oneSideOf
                                                quoteAuthor={estate.citationAuthor?.title}
                                                quoteAuthorFunctions={estate?.citationAuthor?.functions || []}
                                            >
                                                {parseHtml(estate?.citation)}
                                            </Quote>
                                        </Box>
                                    )}
                                </>
                            )}
                        </FrontMainContainer>
                        {restriction && (
                            <ScrolledPropertySummary estate={estate} locale={locale} style={{ marginTop: "3rem" }} />
                        )}

                        {!restriction && estate?.citation && estate?.cellars?.length > 1 && (
                            <Quote
                                small
                                oneSideOf
                                quoteAuthor={estate.citationAuthor?.title}
                                quoteAuthorFunctions={estate?.citationAuthor?.functions || []}
                            >
                                {parseHtml(estate?.citation)}
                            </Quote>
                        )}
                        {/* Members Title and Team Members */}
                        {((!restriction && estate?.members && estate?.members?.length !== 0) ||
                            estate?.teamDescription) && (
                            // || estate?.teamImage?.data
                            <Box ref={props.teamRef} className={styles.estatePage__membersBox}>
                                <Paper
                                    className={styles.estatePage__teamPaper}
                                    style={{
                                        position: "relative",
                                        justifyContent: noTeamDescription && "center",
                                    }}
                                >
                                    <img
                                        src={fioriture}
                                        style={{
                                            position: "absolute",
                                            bottom: "0px",
                                            right: 32,
                                            zIndex: 1,
                                            display: width <= 600 && "none",
                                        }}
                                    />
                                    {getPersonsTitle(
                                        estate?.name,
                                        estate?.teamDescription,
                                        estate?.members && estate?.members?.length !== 0,
                                        styles
                                    )}
                                </Paper>
                                {estate?.members && estate?.members?.length !== 0 ? (
                                    <Paper
                                        className={styles.estatePage__membersPaper}
                                        style={{ backgroundColor: theme.palette.common.white }}
                                    >
                                        {estate?.teamDescription && estate?.teamDescription !== 0 && (
                                            <Box xs={12} className={styles.teamDescriptionTypoBoxResponsive}>
                                                <Typography className={styles.teamDescriptionTypo}>
                                                    {getTextLength(estate?.teamDescription) > 600 ? (
                                                        <ExpandableFormattedTextBox
                                                            styleTypo={{ color: theme.palette.primary.dark }}
                                                            value={estate?.teamDescription}
                                                            truncatedValue={`${estate?.teamDescription?.slice(
                                                                0,
                                                                600
                                                            )}...`}
                                                        />
                                                    ) : (
                                                        parseHtml(estate?.teamDescription)
                                                    )}
                                                </Typography>
                                            </Box>
                                        )}
                                        <PersonsDetailsBlock members={estate?.members} />
                                    </Paper>
                                ) : (
                                    ""
                                )}
                            </Box>
                        )}
                    </>
                )}

                {/* Wines Paper orphan */}
                {isOrphan && (
                    <Box>
                        <WineCardsContainer
                            loadingContact={loadingContact}
                            setWinesAreLoading={setWinesAreLoading}
                            loadingActivity={activitiesAreLoading}
                            loadingEstate={loading}
                            loadingBesv={besvInfosAreLoading}
                            wineEntityName={estate?.name}
                            title={props.wineSectionTitle}
                            showMoreWines={props.showMoreWines}
                            wineEntityId={props.wineEntityId || wineEntityId}
                            whiteVersion
                            limited
                            locale={locale}
                        />
                    </Box>
                )}

                {/* Timeline */}
                {!restriction && (
                    <PublicationTimeline
                        setBesvInfosAreLoading={setBesvInfosAreLoading}
                        wineEntityId={props.wineEntityId || wineEntityId}
                        disabledTimeline={isOrphan}
                        estateName={estate.name}
                    />
                )}

                {/* Wines Paper not orphan */}
                {!isOrphan && hasWines && (
                    <Box style={{ marginTop: "3rem" }}>
                        <WineCardsContainer
                            loadingContact={loadingContact}
                            setWinesAreLoading={setWinesAreLoading}
                            loadingActivity={activitiesAreLoading}
                            loadingEstate={loading}
                            loadingBesv={besvInfosAreLoading}
                            wineEntityName={estate?.name}
                            title={props.wineSectionTitle}
                            showMoreWines={props.showMoreWines}
                            wineEntityId={props.wineEntityId || wineEntityId}
                            whiteVersion
                            limited
                            locale={locale}
                        />
                    </Box>
                )}
            </FrontContentTop>

            {/* Wines Paper not orphan */}

            <FrontContentBottom
                activityBackground={estate?.activities?.length !== 0 && !isOrphan && !restriction}
                contactBackground
            >
                {/* Activity title */}
                {!isOrphan && estate?.activities?.length !== 0 && (
                    <Box style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                        <ActivityCardsContainer
                            loadingContact={loadingContact}
                            loadingWine={winesAreLoading}
                            loadingBesv={besvInfosAreLoading}
                            loadingEstate={loading}
                            setActivitiesAreLoading={setActivitiesAreLoading}
                            wineEntityName={estate?.name}
                            wineEntityId={props.wineEntityId || wineEntityId}
                            locale={locale}
                            styleBox={{ margin: width < 600 && "0 14px" }}
                            title={
                                props.activitiesSectionTitle ||
                                t`Les activités proposées par ${estate?.name ? estate?.name : "cette propriété"}`
                            }
                            boxStyle={{ padding: "0px" }}
                            boxClassName={styles.activityBox}
                        />
                    </Box>
                )}
                {/* Contact Paper */}
                <Box ref={props.contactRef}>
                    <ContactPropertyFormCard
                        setContactAreLoading={setContactAreLoading}
                        standalone={props.standalone}
                        restriction={restriction}
                        contactSectionTitle={props.contactSectionTitle}
                        regainControl={regainControl}
                        isOrphan={isOrphan}
                        size={"large"}
                        estateName={estate?.name}
                        address={estate?.address}
                        phoneNumber={estate?.telephoneNumber}
                        fax={estate?.fax}
                        logo={estate?.logos?.[0]?.twicpicPath || null}
                        identityLinked={true}
                        coordinates={estate?.coordinates}
                        weeklyHours={
                            estate?.displayOpeningHours
                                ? estate?.openingHours && estate?.openingHours?.length
                                    ? estate?.openingHours
                                    : null
                                : null
                        }
                        wineEntityId={props.wineEntityId || wineEntityId}
                        classNameCard={styles.bottomCard}
                    />
                </Box>
            </FrontContentBottom>
            {!props.standalone && <EstateCommunalities wineEntityId={wineEntityId} />}
        </>
    );
};

GenericEstateScreen.propTypes = {
    estate: object,
    propertyTitle: string,
    propertySubtitle: string,
    facebookOnClick: PropTypes.func,
    twitterOnClick: PropTypes.func,
    tripAdvisorOnClick: PropTypes.func,
    websiteOnClick: PropTypes.func,
    mainPicture: PropTypes.array,
    tileData: PropTypes.array,
    cols: PropTypes.number,
    rows: PropTypes.number,
    propertyNameTitle: string,
    expandableText: string,
    imagesArray: PropTypes.array,
    carouselImages: PropTypes.array,
    propertySummaryArray: PropTypes.array,
    personsArray: PropTypes.array,
    publicationData: PropTypes.arrayOf(shape({ disabled: PropTypes.bool, label: string })),
    property: PropTypes.any,

    wineEntityId: string,
    standalone: bool,
    socialMedias: bool,
    frontTitle: bool,
    titleStandalone: bool,
    boxStyles: object,
    hideFloritureTopImg: bool,
    wineSectionTitle: string,
    showMoreWines: string,
    activitiesSectionTitle: string,
    contactSectionTitle: string,
    estateRef: any,
    teamRef: any,
    logo: string,
    contactRef: any,
};

export default GenericEstateScreen;
