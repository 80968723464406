import React from "react";
import PropTypes from "prop-types";
import { Box, CardMedia, Divider, makeStyles, Typography } from "@material-ui/core";
import theme from "../globalTheme";
import badgeImage from "../assets/Dashboard/badge_feret.svg";
import { useMainTitleQuery } from "../generated/graphql";
import getConnectors from "../helpers/helpers";
import FrontTitle from "../Front/FrontTitle";
import { t } from "@lingui/macro";

const useStyles = makeStyles(() => {
    return {
        estatePage__mainTitle: (props) => {
            return {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                color: props.color || theme.palette.common.white,
                [theme.breakpoints.only("xs")]: {
                    height: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                },
            };
        },
        estatePage__typoBadge: {
            display: "flex",
        },

        estatePage__media: {
            backgroundSize: "contain",
            [theme.breakpoints.up("xs")]: {
                height: "30px",
                width: "30px",
                marginTop: theme.spacing(0),
                marginLeft: theme.spacing(0),
            },
            [theme.breakpoints.up("md")]: {
                height: "40px",
                width: "40px",
                marginTop: theme.spacing(1),
                marginLeft: theme.spacing(1),
            },
            [theme.breakpoints.up("lg")]: {
                height: "40px",
                width: "40px",
                marginTop: 0,
                marginLeft: theme.spacing(2),
            },
            [theme.breakpoints.up("xl")]: {
                height: "40px",
                width: "40px",
                marginTop: theme.spacing(2),
                marginLeft: theme.spacing(2),
            },
        },
        estatePage__rank: {
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            maxWidth: "100%",
        },
        estatePage__divider: (props) => {
            return {
                backgroundColor: props.color || "white",
                [theme.breakpoints.up("xs")]: {
                    width: "0px",
                },
                [theme.breakpoints.up("sm")]: {
                    width: "60px",
                },
                [theme.breakpoints.up("md")]: {
                    width: "160px",
                },
                [theme.breakpoints.up("lg")]: {
                    width: "183px",
                },
                [theme.breakpoints.up("xl")]: {
                    width: "183px",
                },
            };
        },
        estatePage__rankTypo: (props) => {
            return {
                ...theme.front.typography.h2.light,
                color: props.color || "white",
                fontSize: "30px",
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(1),
                textAlign: "center",
                [theme.breakpoints.only("xs")]: {
                    fontSize: "20px",
                },
            };
        },
        estatePage__subtitle: (props) => {
            return {
                ...theme.typography.h4.light,
                color: props.color || "white",
                fontSize: "24px",
                fontWeight: 300,
                zIndex: 1,
                [theme.breakpoints.up("xs")]: {
                    fontSize: "12px",
                    marginTop: theme.spacing(0),
                },
                [theme.breakpoints.up("sm")]: {
                    fontSize: "22px",
                    marginTop: theme.spacing(1),
                },
                [theme.breakpoints.up("md")]: {
                    fontSize: "24px",
                    marginTop: theme.spacing(2),
                },
            };
        },
    };
});
const MainTitle = (props) => {
    const styles = useStyles(props);
    const { data } = useMainTitleQuery({
        variables: {
            wineEntityId: props.wineEntityId,
        },
    });

    const estateName = data?.MainTitle?.name;
    const estateRank = data?.MainTitle?.classifications;
    const mainAppellation = data?.MainTitle?.mainAppellation;
    const estateSurfaceArea = data?.MainTitle?.estateSurfaceArea;
    const getClassifications = (classifications) => {
        const arr = classifications.map((el) => el?.name);
        return getConnectors(arr);
    };

    return (
        <FrontTitle
            title={
                <Box className={styles.estatePage__mainTitle}>
                    {estateName && (
                        <Box className={styles.estatePage__typoBadge}>
                            {estateName && <Typography variant="h1">{estateName} </Typography>}

                            <CardMedia className={styles.estatePage__media} image={badgeImage} title="badge_feret" />
                        </Box>
                    )}
                    {estateRank && estateRank?.length ? (
                        <Box className={styles.estatePage__rank}>
                            <Divider orientation="horizontal" className={styles.estatePage__divider} />
                            <Typography className={styles.estatePage__rankTypo}>
                                {getClassifications(estateRank)}
                            </Typography>
                            <Divider orientation="horizontal" className={styles.estatePage__divider} />
                        </Box>
                    ) : (
                        ""
                    )}
                    {mainAppellation && (
                        <Typography variant="h6" className={styles.estatePage__subtitle}>
                            {`${mainAppellation ? mainAppellation.toUpperCase() : ""}  ${
                                estateSurfaceArea ? `· ${estateSurfaceArea} ${t`ha`}` : ""
                            }`}
                        </Typography>
                    )}
                </Box>
            }
        />
    );
};

MainTitle.propTypes = {
    wineEntityId: PropTypes.string,
};

export default MainTitle;
