import { Box, makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import ReactPinField from "react-pin-field";

import Loading from "../Components/Loader/Loading";
import theme from "../globalTheme";

const useStyles = makeStyles(() => {
    return {
        ReactCodeInput: (args) => {
            return {
                [theme.breakpoints.up("xs")]: {
                    width: 34,
                    height: 48,
                    marginRight: "6px",

                    ...theme.typography.h3,
                    textTransform: "uppercase",
                },
                [theme.breakpoints.up("sm")]: {
                    width: "70px",
                    height: "98px",
                    marginRight: "11px",

                    ...theme.typography.h1,
                    textTransform: "uppercase",
                },
                borderRadius: "8px",
                boxShadow: "none",
                borderWidth: 1,
                borderStyle: "solid",
                textAlign: "center",
                borderColor:
                    args.invalidCode && args.invalidCode !== undefined ? "#DB0316" : theme.palette.secondary.main,
            };
        },
        container_code_input: {
            [theme.breakpoints.down("xs")]: {
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
            },
            [theme.breakpoints.up("sm")]: {
                width: "fit-content",
            },
        },
    };
});

const CodeInput = (props) => {
    const [invalidCode, setInvalidCode] = useState(false);
    const styles = useStyles({ invalidCode });
    const [completedOnce, setCompletedOnce] = useState(false);

    useEffect(() => {
        if (completedOnce && !props.loading) {
            setInvalidCode(!props.isCodeValid);
        }
        if (!props.isCodeValid) {
            setCompletedOnce(false);
        }
    }, [props.isCodeValid, completedOnce, props.loading]);

    const handleComplete = (code) => {
        setCompletedOnce(code);
        props.nextStep(code);
    };
    return (
        <>
            <Typography variant="h2" style={{ marginBottom: "2.75rem" }}>
                Vous avez reçu une invitation pour consulter des fiches techniques
            </Typography>
            {props.loading || props.isCodeValid ? (
                <Box>
                    <Loading />
                </Box>
            ) : (
                <Box className={styles.container_code_input}>
                    <ReactPinField
                        length={6}
                        className={styles.ReactCodeInput}
                        onChange={() => setInvalidCode(false)}
                        onComplete={(code) => handleComplete(code)}
                    />
                    <Typography variant="h5" style={{ marginTop: "1rem" }}>
                        Veuillez saisir le code à usage unique reçu par mail pour continuer
                    </Typography>
                    <Box
                        style={{
                            marginTop: "1rem",
                            display: "flex",
                            alignItems: "start",
                            flexDirection: "column",
                            justifyContent: "end",
                        }}
                    >
                        {invalidCode && (
                            <Typography variant="h6" style={{ color: "#DB0316", width: "100%" }}>
                                Ce code est incorrect ou a déjà été utilisé.
                            </Typography>
                        )}
                    </Box>
                </Box>
            )}
        </>
    );
};

CodeInput.propTypes = {
    nextStep: PropTypes.func,
    isCodeValid: PropTypes.bool,
    loading: PropTypes.bool,
};

export default CodeInput;
