import { Typography, makeStyles, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import Popin from "../../Containers/Popin";
import distributionImg from "../../assets/Wine/popinDistribution.png";
import { Trans } from "@lingui/macro";
import { DisplayPackaging } from "FrontVintage/Components/DisplayPackaging";
import globalTheme from "globalTheme";

const useStyle = makeStyles((theme) => ({
    packagingsPopin: {
        borderRadius: "10px",
        width: "100%",
        [theme.breakpoints.only("xs")]: {
            width: "350px",
        },
    },
    packagingsPopin__header: {
        alignItems: "center",
        display: "flex",
        marginBottom: theme.spacing(1),
    },
    packagingsPopin__imgTitle: {
        marginRight: "4px",
    },
    packagingsPopin__title: {},
    packagingsPopin__element: {
        alignItems: "center",
        color: theme.palette.primary.light,
        display: "flex",
        marginBottom: "10px",
    },
    packagingsPopin__imgFirstTypo: {
        alignItems: "center",
        display: "flex",
    },
    packagingsPopin__img: {
        height: "40px",
        marginRight: theme.spacing(2),
    },
    packagingsPopin__firstTypo: {
        width: "300px",
        color: "#360318",
        fontSize: "16px",
        fontWeight: "500",
        [theme.breakpoints.only("xs")]: {
            width: "200px",
        },
        [theme.breakpoints.up("md")]: {
            width: "300px",
        },
    },
    packagingsPopin__secondTypo: {
        fontWeight: "bold",
        whiteSpace: "nowrap",
        fontSize: "16px",
        color: "#360318",
    },
    customModal: {
        maxWidth: "580px",
        [theme.breakpoints.only("xs")]: {
            marginLeft: "8px",
        },
    },
}));

const PackagingsPopin = (props) => {
    const styles = useStyle(props);

    return (
        <Popin
            title={
                <Box className={styles.packagingsPopin__header}>
                    <img src={distributionImg} alt="climat_image" className={styles.packagingsPopin__imgTitle} />
                    <Typography variant="h3" className={styles.packagingsPopin__title}>
                        <Trans>Produits et tarifs</Trans>
                    </Typography>
                </Box>
            }
            open={props.modalOpen}
            onClose={props.handleClose}
            background={3}
            overlowContentPopin="visible"
            cross
        >
            <Box className={styles.packagingsPopin}>
                <DisplayPackaging
                    packagings={props.packagings}
                    color={globalTheme.palette.primary.light}
                    wine={props.wine}
                    cuvee={props.cuvee}
                    year={props.year}
                />
            </Box>
        </Popin>
    );
};

PackagingsPopin.propTypes = {
    modalOpen: PropTypes.bool,
    handleClose: PropTypes.func,
    packagings: PropTypes.array,
    year: PropTypes.number,
    wine: PropTypes.string,
    cuvee: PropTypes.string,
};

export default PackagingsPopin;
