import i18n from "i18n-next";
import objectPath from "object-path";

const labels = {
    // ESTATE
    GeneralInformationScreen: {
        address: { label: "Adresse" },
        complementaryAddress: { label: "Complément d'adresse" },
        postalCode: { label: "CP", labelError: "Code postal" },
        commune: { label: "Commune" },
        fax: { label: "Fax" },
        telephone: { label: "Téléphone" },
        email: { label: "Adresse e-mail" },
        website: { label: "Site internet" },
        socialMedia: {
            name: { label: "Nom du profil" },
            platform: { label: "Réseau" },
            url: { label: "URL du profil" },
        },
        openingHours: {
            label: "Tableau horaires",
            openMorning: { label: "Ouverture matin" },
            closeMorning: { label: "Fermeture matin" },
            openAfternoon: { label: "Ouverture après-midi" },
            closeAfternoon: { label: "Fermeture après-midi" },
        },
    },
    EstateScreen: {
        totalSurfaceArea: {
            label: "Surf. totale",
            labelError: "Surface totale",
        },
        description: { label: "Présentation de la propriété" },
        citation: { label: "La propriété et ses valeurs en une citation" },
        author: { label: "Auteur" },
        promotionBodies: { label: "Organisme de promotion" },
        commercialisationTypes: { label: "Type de distribution" },
    },
    CellarTabScreen: {
        surfaceArea: { label: "Surface" },
        airConditioning: { label: "Chai climatisé" },
        temperature: { label: "Temp.", labelError: "Température" },
        vatContainers: {
            kind: { label: "Type" },
            material: { label: "Matériau" },
            form: { label: "Forme" },
            capacity: { label: "Capacité" },
            quantity: { label: "Quantité" },
        },
        vatThermoregulation: { label: "Thermorégulation des cuves" },
        conditioningActivities: { label: "Activités" },
        numberOfConditioningChains: {
            label: "Nb de chaînes",
            labelError: "Nombre de chaînes",
        },
        conditioningCapacity: { label: "Capacité" },
        storageCapacity: { label: "Capacité" },
        storageCapacityUnit: { label: "" },
        description: { label: "Autres informations sur le chai" },
        // address: "20 rue des oiseaux",
        // commune: "Saint-Emilion",
        // complementaryAddress: null,
        // postalCode: "33330",
    },
    ProprieteVignobleScreen: {
        soil: { label: "Sols" },
        subsoil: { label: "Sous-sols" },
        soilDescription: {
            label: "Autres informations sur le sol et le sous-sol",
        },
        grassGrowing: { label: "Enherbement" },
        fertilizers: { label: "Engrais" },
        worksUnderTheRow: { label: "Travail sous le rang" },
        soilWorkDescription: { label: "Détail du travail du sol" },
        pruningMethod: { label: "Mode de taille" },
        greenProcedures: { label: "Opérations en vert" },
        vineWorkDescription: { label: "Détail du travail de la vigne" },
        sexualConfusion: { label: "Pratique de confusion sexuelle" },
        insectWorkDescription: {
            label: "Détail de la lutte contre les insectes ravageurs",
        },
        description: { label: "Autres informations sur le vignoble" },
        otherCultures: { label: "Autres cultures pratiquées" },
    },
    SurfaceManualScreen: {
        totalSurfaceArea: { label: "Superficie vigne" },
        averageDensity: { label: "Densité moyenne" },
        averageAge: { label: "Âge moyen" },
        surfaces: {
            appellation: { label: "Appellation" },
            grapeColor: { label: "Couleur" },
            surfaceArea: { label: "Superficie" },
        },
    },

    // EQUIPE
    TeamGeneral: {
        teamDescription: { label: "Description détaillée de l'équipe" },
    },
    TeamMemberBoc: {
        email: { label: "Adresse e-mail" },
        telephone: { label: "Téléphone" },
        dateOfBirth: { label: "Date de naissance" },
        vip: { label: "Membre VIP" },
        description: { labelError: "Description du membre" },
        // feminineProFunction: ,
        // title: ,
        // firstName: ,
        // kind: ,
        // lastName: ,
        // organizationName: ,
        // organizationStatus: ,
        // proFunctions: ,
        // visibility:,
    },

    // WINE
    WinePresentationScreen: {
        soils: { label: "Sols" },
        subsoils: { label: "Sous-sol" },
        description: { label: "Description du vin" },
        advice: {
            serviceTemperature: { label: "Temp.", labelError: "Température" },
            decantingDuration: { label: "Carafage" },
        },
        wineMaking: {
            alcoholicFermentationDuration: {
                label: "Durée",
                labelError: "Durée de fermentation alcoolique",
            },
            alcoholicFermentationTemperature: {
                label: "Temp.",
                labelError: "Température de fermentation alcoolique",
            },
        },
        maturation: {
            barrelMaturationDurationValue: {
                label: "Durée",
                labelError: "Durée d'élevage en barriques",
            },
            newBarrelPercentage: {
                label: "Neuves",
                labelError: "Pourcentage de barriques neuves",
            },
        },
        production: {
            bottlesVolume: { label: "Production moyenne" },
            yield: { label: "Rendement" },
            production: { label: "Prod./récolte" },
        },
        marketing: {
            directPercentage: { label: "Directe" },
            exportCountries: { label: "Pays d'export" },
            exportPercentage: { label: "Export" },
            indirectPercentage: { label: "Indirecte" },
        },
        // color: ,
        // label: ,
        // name: ,
        // rank: ,
        // sweetness: ,
        // type: ,
    },

    // VINTAGES
    VintageIdentityScreen: {
        wineAssembly: {
            grapeVarietyName: { label: "Cépage" },
            percentage: { label: "Pourcentage" },
        },
        alcoholicStrength: { label: "Alc./vol." },
        residualSugar: { label: "Sucres résiduels" },
        totalAcidity: { label: "Acidité totale" },
        volatileAcidity: { label: "Acidité volatile" },
        totalSulfur: { label: "SO2 total" },
        ph: { label: "pH" },
        ipt: { label: "IPT" },
        allergens: { label: "Allergènes" },
        nonOGM: { label: "Ne contient pas d'OGM" },
        noSulphites: { label: "Vin sans sulfite ajouté" },
        consultingEnologist: { label: "Œnologue" },
        globalClimatology: { label: "Climatologie du millésime" },
        fallClimatology: { label: "Climatologie du millésime en automne" },
        springClimatology: { label: "Climatologie du millésime au printemps" },
        summerClimatology: { label: "Climatologie du millésime en été" },
        winterClimatology: { label: "Climatologie du millésime en hiver" },
        vintageDetails: { labelError: "Description du vin" },
    },
    VintageViticultureScreen: {
        greenOperations: {
            operation: { label: "Opération en vert" },
            startAt: { label: "Date de début" },
            endAt: { label: "Date de fin" },
        },
        simplePhenologicalStages: {
            stage: { label: "Stade phénologique" },
            startAt: { label: "Date de début" },
            endAt: { label: "Date de fin" },
        },
        detailedPhenologicalStages: {
            stage: { label: "Stade phénologique" },
            grapeVariety: { label: "Cépage" },
            startAt: { label: "Date de début" },
            endAt: { label: "Date de fin" },
        },
        harvest: {
            method: { label: "Type de vendange" },
            startAt: { label: "Date de début" },
            endAt: { label: "Date de fin" },
        },
        detailedHarvest: {
            method: { label: "Type de vendange" },
            grapeVariety: { label: "Cépage" },
            startAt: { label: "Date de début" },
            endAt: { label: "Date de fin" },
        },
        numberOfSorts: { label: "Nombre de tries" },
        viticultureDetails: { label: "Autres informations sur la viticulture" },
    },
    VintageWineMakingScreen: {
        alcoholicFermentationDuration: {
            label: "Durée",
            labelError: "Durée de fermentation alcoolique",
        },
        alcoholicFermentationDurationUnit: {
            label: "",
            labelError: "Unité de durée de fermentation alcoolique",
        },
        alcoholicFermentationTemperature: {
            label: "Temp.",
            labelError: "Température de fermentation alcoolique",
        },
        destemming: { label: "Éraflage" },
        extractionMode: { label: "Mode d'extraction" },
        macerations: {
            type: { label: "Macération" },
            duration: { label: "Durée", labelError: "Durée de macération" },
            durationUnit: {
                label: "",
                labelError: "Unité de durée de macération",
            },
            temperature: {
                label: "Température",
                labelError: "Température de macération",
            },
        },
        malolacticBacteria: { label: "Bactéries" },
        malolacticFermentation: { label: "Fermentation malolactique" },
        malolacticFermentationDuration: {
            label: "Durée",
            labelError: "Durée de fermentation malolactique",
        },
        malolacticFermentationDurationUnit: {
            label: "",
            labelError: "Unité de durée de fermentation malolactique",
        },
        malolacticFermentationTemperature: {
            label: "Temp.",
            labelError: "Température de fermentation malolactique",
        },
        parcelBasedVinification: { label: "Vinification parcellaire" },
        pressType: { label: "Type de presse" },
        sortingMethod: { label: "Méthode de tri" },
        treading: { label: "Foulage" },
        vatting: { label: "Mode d'encuvage" },
        vinificationCoopName: { label: "Nom de la cave coopérative" },
        vinificationDetails: {
            label: "Autres informations sur la vinification",
        },
        vinificationPlace: { label: "Lieu de vinification" },
        yeasting: { label: "Levures" },
    },
    VintageAgeingScreen: {
        barrelMaturationDurationValue: {
            label: "Durée",
            labelError: "Durée d'élevage en barriques",
        },
        newBarrelPercentage: {
            label: "Neuves",
            labelError: "Pourcentage de barriques neuves",
        },
        frenchOak: { label: "Chêne français" },
        toasting: { label: "Chauffe des barriques" },

        barrelMakers: {
            barrelMaker: { label: "Tonnelier" },
            percentage: { label: "Pourcentage" },
        },

        otherMaturations: {
            containerType: { label: "Contenant" },
            durationUnit: {
                label: "Unité",
                labelErrors: "Unité de durée de autre élevage",
            },
            durationValue: {
                label: "Durée",
                labelErrors: "Durée de autre élevage",
            },
            material: { label: "Matériau" },
        },

        lees: { label: "Élevage sur lies" },
        batonnage: { label: "Avec bâtonnage" },

        description: { label: "Autres informations sur l'élevage" },
    },
    VintageProductionScreen: {
        finingTypes: { label: "Type de collage" },
        filtrationTypes: { label: "Filtration" },
        corkingMode: { label: "Bouchage" },
        waxedBottle: { label: "Bouteille cirée" },
        bottlingDate: {
            label: "Date",
            labelError: "Date de mise en bouteille",
        },

        estateBottling: { label: "Mise en bouteille à la propriété" },
        address: { label: "Adresse" },
        complementaryAddress: { label: "Complément d'adresse" },
        postalCode: { label: "CP", labelError: "Code postal" },
        commune: { label: "Commune" },

        bottler: { label: "Embouteilleur" },
        bottlerPostalCode: { label: "CP", labelError: "Code postal" },
        bottlerCommune: { label: "Commune" },

        bottlesVolume: { label: "Volume produit" },
        yield: { label: "Rendement" },
        production: { label: "Prod./récolte" },
    },
    VintageMarketingScreen: {
        directPercentage: { label: "Directe" },
        exportCountries: { label: "Pays d'export" },
        exportPercentage: { label: "Export" },
        gtin: { label: "GTIN" },
        // ownWebsiteSelling: ,
        packagings: {
            centilisation: { label: "Centilisation" },
            conditionningMode: { label: "Conditionnement" },
            price: { label: "Prix TTC" },
            units: { label: "Qté", labelError: "Quantité" },
            gtin: { label: "GTIN (13 chiffres)", labelError: "GTIN" },
        },
        productPageUrl: { label: "Url de la boutique" },
        indirectPercentage: { label: "Indirecte" },
    },
    VintageAdviceScreen: {
        serviceTemperature: { label: "Temp.", labelError: "Température" },
        treatmentBeforeService: { label: "Avant service" },
        decantingDuration: { label: "Carafage" },
        apogee: { label: "Apogée" },

        appearanceBrilliance: { label: "Brillance" },
        appearanceColor: { label: "Couleur" },
        appearanceIntensity: {
            label: "Intensité",
            labelError: "Intensité de l'apparence",
        },
        appearanceLimpidity: { label: "Limpidité" },
        appearanceTears: { label: "Larmes" },

        noseAromas: { label: "Arômes du nez" },
        noseIntensity: {
            label: "Intensité aromatique",
            labelError: "Intensité aromatique du nez",
        },

        palateAcidity: { label: "Acidité" },
        palateAttack: { label: "Attaque" },
        palateIntensity: {
            label: "Intensité aromatique",
            labelError: "Intensité aromatique du palais",
        },
        palateLength: { label: "Longueur" },
        palateTannins: { label: "Tanins" },

        aromaAnimal: { label: "Arôme animal" },
        aromaBalsamic: { label: "Arôme balsamique" },
        aromaConfectionery: { label: "Arôme confiserie" },
        aromaEmpyreumatic: { label: "Arôme empyreumatique" },
        aromaFermented: { label: "Arôme fermentaire" },
        aromaFloral: { label: "Arôme floral" },
        aromaFruity: { label: "Arôme fruité" },
        aromaMineral: { label: "Arôme minéral" },
        aromaOaky: { label: "Arôme boisé" },
        aromaSpicy: { label: "Arôme épicé" },
        aromaVegetal: { label: "Arôme végétal" },

        description: { label: "Votre commentaire de dégustation" },
        videoUrl: { label: "Url de la vidéo de dégustation" },
    },
    ActivityTabScreen: {
        accessibleTo: { label: "Adapté pour" },
        startingAge: { label: "À partir de" },
        tags: { label: "Mots-clés précisant l'activité" },
        activePeriods: {
            startAt: { label: "Début de période" },
            endAt: { label: "Fin de période" },
        },
        vagueActivePeriods: {
            startAt: { label: "Début" },
            endAt: { label: "Fin" },
        },
        reservationNeeded: { label: "sur réservation" },
        priceDetails: {
            kind: { label: "Tarif" },
            price: { label: "Prix" },
        },
        duration: { label: "Durée de l'activité" },
        durationUnit: { label: "Unité de durée de l'activité" },
        contactPerson: { label: "Contact associé" },
        contactPhoneNumber: { label: "Téléphone" },
        mesures: { label: "Mesures sanitaires" },
        materials: { label: "Matériel mis à disposition" },
        options: { label: "Options disponibles" },
        free: { label: "Activité complétement gratuite" },
        devis: { label: "Tarifs sur devis" },
        payment: { label: "Moyens de paiement acceptés" },
        paymentDetails: { label: "Détails sur les tarifs" },
        hostingDetails: { label: "Détails sur l'hébergement" },
        hosting: { label: "hébergement possible" },
        beds: { label: "Nb de lit" },
        espaces: {
            name: { label: "Nom" },
            surface: { label: "Superficie" },
            capacity: { label: "Capacité" },
            type: { label: "Type" },
            surfaceAreaUnit: { label: "Unité" },
        },
        receptionAreas: {
            name: { label: "Nom" },
            surfaceArea: { label: "Superficie" },
            capacity: { label: "Capacité" },
            type: { label: "Type" },
            surfaceAreaUnit: { label: "Unité" },
        },
        locationMin: { label: "Location min." },
        locationMax: { label: "Location max." },
        private: { label: "Activité privatisée" },
        atWineEntity: { label: "Se situe à la propriété" },
    },
    GetServicesOfferByJournalist: {
        delay: {
            label: i18n.t("inputs.label.delay"),
        },
        demoVideoUrl: {
            label: "URL",
        },
    },
};

export const getLabelsErrors = (query) => {
    return labels[query] || {};
};

export const getLabel = (query, path) => {
    return objectPath.get(labels[query], path)?.label;
};
