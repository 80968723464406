import { Trans, t } from "@lingui/macro";
import { Box, Link, Typography } from "@material-ui/core";
import parse from "html-react-parser";
import moment from "moment";
import { useEffect, useState } from "react";

import {
    ADD_FLUX_FTP,
    ADD_TASTINGREQUEST,
    ADD_TASTINGVIDEO,
    CREATOR_URL,
    ENV_NAMES,
    FR_AND_EN,
    PAYMENT_METHODS,
    PAYMENT_STATUS,
    SUBSCRIPTION_RECURRENCE,
    SUBSCRIPTION_TYPES,
    SUB_BORDEAUX_ESSENTIAL_MONTH,
    SUB_BORDEAUX_ESSENTIAL_YEAR,
    SUB_BORDEAUX_INITIAL_MONTH,
    SUB_BORDEAUX_INITIAL_YEAR,
    SUB_BORDEAUX_PREMIUM_MONTH,
    SUB_BORDEAUX_PREMIUM_YEAR,
    TVA,
    ZOHO_STAGE,
} from "config/constants";
import BaseTooltip from "../Components/Tooltips/Tooltip";
import caviste from "../assets/diffusion/caviste.svg";
import ce from "../assets/diffusion/ce.svg";
import chr from "../assets/diffusion/chr.svg";
import commerce from "../assets/diffusion/commerce.svg";
import courtier from "../assets/diffusion/courtier.svg";
import ecommercant from "../assets/diffusion/ecommercant.svg";
import exportateur from "../assets/diffusion/exportateur.svg";
import grande_distribution from "../assets/diffusion/grande_distribution.svg";
import grossiste from "../assets/diffusion/grossiste.svg";
import importateur from "../assets/diffusion/importateur.svg";
import negociant from "../assets/diffusion/negociant.svg";
import particulier from "../assets/diffusion/particulier.svg";
import theme from "../globalTheme";
import { useSelector } from "react-redux";
import { omit } from "lodash";

const getConnectors = (datas, capitalize, lastConnector = ", ") => {
    if (!datas) {
        return null;
    }
    const values = [datas.slice(0, -1).join(", "), datas.slice(-1)?.[0]];
    const finalSentence = formatConcat(values, lastConnector);
    if (capitalize) {
        return firstLetterUppercase(finalSentence);
    }
    return finalSentence;
};
export default getConnectors;

export const getChips = (list, uppercase, fontSize, dots, nullIfNull) => {
    if (list && list?.length !== 0) {
        return (
            <Box style={{ display: "flex", flexWrap: "wrap" }}>
                {list?.map((el, i) => {
                    return (
                        <>
                            <Typography
                                key={i}
                                style={{
                                    fontFamily: "Lato",
                                    color: "white",
                                    backgroundColor: theme.palette.primary.light,
                                    borderRadius: "4px",
                                    width: "auto",
                                    padding: "0px 5px",
                                    fontSize: fontSize ? fontSize : "16px",
                                    marginRight: "4px",
                                    marginBottom: "5px",
                                }}
                            >
                                {uppercase ? el?.charAt(0)?.toUpperCase() + el?.slice(1) : el}
                            </Typography>
                            {dots && i === list?.length - 1 ? "..." : ""}
                        </>
                    );
                })}
            </Box>
        );
    } else {
        if (nullIfNull) {
            return null;
        }
        return (
            <Typography
                style={{
                    color: theme.palette.primary.dark,
                    fontSize: theme.front.typography.labeur.dark.fontSize,
                }}
            >
                <Trans>Non précisé</Trans>
            </Typography>
        );
    }
};

export const getSortedValues = (values, noTitle) => {
    if (values) {
        return [...values].sort((e1, e2) => {
            const a1 = noTitle ? e1 : e1.title;
            const a2 = noTitle ? e2 : e2.title;

            if (a1 == a2) {
                return 0;
            }
            if (a1 < a2) {
                return -1;
            }
            if (a1 > a2) {
                return 1;
            }
        });
    }
};

export const dateDep = (v1, v2, dateType) => {
    const getFullDateFromVaguePeriod = (vaguePeriod) => {
        if (vaguePeriod === "début") {
            return [0, 10];
        } else if (vaguePeriod === "mi") {
            return [11, 20];
        } else if (vaguePeriod === "fin") {
            return [21, 31];
        }
    };

    if (!v1 || !v2 || !dateType) {
        return;
    }

    // Check year and month
    if (dateType === "start") {
        if (v1?.year > v2?.year) {
            return true;
        }
        if (v1?.year === v2?.year && v1?.month > v2?.month) {
            return true;
        }
    } else if (dateType === "end") {
        if (v1?.year < v2?.year) {
            return true;
        }
        if (v1?.year === v2?.year && v1?.month < v2?.month) {
            return true;
        }
    }

    // Both val have full date
    if (dateType === "start" && v1?.day && v2?.day) {
        if (v1?.year === v2?.year && v1?.month === v2?.month && v1?.day > v2?.day) {
            return true;
        }
    } else if (dateType === "end" && v1?.day && v2?.day) {
        if (v1?.year === v2?.year && v1?.month === v2?.month && v1?.day < v2?.day) {
            return true;
        }
    }

    // Both vals day have vague period
    if (v1?.vaguePeriod && v2?.vaguePeriod) {
        const vague1 = v1?.vaguePeriod;
        const vague2 = v2?.vaguePeriod;
        if (v1?.year === v2?.year && v1.month === v2.month) {
            if (dateType === "start") {
                if (vague1 === "mi" && vague2 === "début") {
                    return true;
                }
                if (vague1 === "Fin" && (vague2 === "début" || vague2 === "mi")) {
                    return true;
                }
            } else if (dateType === "end") {
                if (vague1 === "début" && (vague2 === "mi" || vague2 === "fin")) {
                    return true;
                }
                if (vague1 === "mi" && vague2 === "fin") {
                    return true;
                }
            }
        }
    }

    // Val1 day only have vague period
    if (v1?.vaguePeriod && !v2?.vaguePeriod) {
        const vague1Period = v1?.vaguePeriod;
        const vague1 = getFullDateFromVaguePeriod(v1?.vaguePeriod);
        const vague2 = v2?.day;
        if (v1?.year === v2?.year && v1.month === v2.month) {
            if (dateType === "start") {
                if ((vague1Period === "mi" || vague1Period === "fin") && vague2 < vague1[0]) {
                    return true;
                }
            } else if (dateType === "end") {
                if ((vague1Period === "début" || vague1Period === "mi") && vague2 > vague1[1]) {
                    return true;
                }
            }
        }
    }

    // Val2 day only have vague period
    if (!v1?.vaguePeriod && v2?.vaguePeriod) {
        const vague1 = v1?.day;
        const vague2Period = v2?.vaguePeriod;
        const vague2 = getFullDateFromVaguePeriod(v2?.vaguePeriod);
        if (v1?.year === v2?.year && v1.month === v2.month) {
            if (dateType === "start") {
                if ((vague2Period === "début" || vague2Period === "mi") && vague1 > vague2[1]) {
                    return true;
                }
            } else if (dateType === "end") {
                if ((vague2Period === "mi" || vague2Period === "début") && vague1 < vague2[0]) {
                    return true;
                }
            }
        }
    }
};
export const redirectOutsideUrl = (url) => {
    if (url) {
        if (url?.indexOf("http://") == 0 || url?.indexOf("https://") == 0) {
            window.open(url);
        } else {
            window.open(`https://${url}`);
        }
    }
};

export const useWindowDimensions = () => {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.outerWidth,
                height: window.outerHeight,
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
};

export const getRound = (data, factor = 1) => {
    return Math.round(data / factor) * factor;
};

export const getFilteredOptions = (options, values) => {
    if (!values?.length || !options?.length) {
        return options;
    }
    const hasNone = values.filter((el) => el === "aucun" || el === "aucune");
    const valueNotNone = values.filter((el) => el !== "aucun" && el !== "aucune");
    const hasOther = options.filter((el) => el !== "aucun" && el !== "aucune");

    if (hasNone?.length !== 0) {
        return hasNone;
    }
    if (valueNotNone?.length !== 0) {
        return hasOther;
    }
};

export const getImageDimensions = (url, rejectTimeout) =>
    new Promise((resolve, reject) => {
        let timer = null;

        const img = new Image();

        img.addEventListener("load", () => {
            if (timer) {
                clearTimeout(timer);
            }

            resolve(img);
        });

        img.addEventListener("error", (event) => {
            if (timer) {
                clearTimeout(timer);
            }
            reject(`${event.type}: ${event.toString()}`);
        });

        img.src = url;

        if (rejectTimeout) {
            timer = setTimeout(() => reject("Timeout exception"), rejectTimeout);
        }
    });

export function mod(n, m) {
    return ((n % m) + m) % m;
}

export const formatToMegabytes = (bytes) => {
    return bytes / 1000000;
};

export const lowerCaseAndFirstLetterUppercase = (text) => {
    if (!text) {
        return;
    }
    return text?.charAt(0)?.toUpperCase() + text?.toLowerCase()?.slice(1);
};

export function firstLetterUppercase(string) {
    if (string && typeof string !== "string") {
        return "";
    }
    return string?.charAt(0)?.toUpperCase() + string?.slice(1).toLowerCase() || "";
}

export const allProperCase = (text) => {
    if (!text) {
        return;
    }
    const splittedTexts = text.split(" ");
    const splittedTextsWithUpperCase = splittedTexts.map((el) => {
        return el.charAt(0).toUpperCase() + el.toLowerCase().slice(1);
    });

    return splittedTextsWithUpperCase.join(" ");
};

export const applySeparator = (value, separator = " ") => {
    if (!value) return "";
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};

// GET HECTARES
export const getHectares = (value, locale) => {
    if (!value) return;
    const hectares = value / 10000;
    if (locale && locale === "fr") {
        return (Math.round(hectares * 100) / 100 + t` ha`).replace(/\./g, ",");
    }
    return Math.round(hectares * 100) / 100 + t` ha`;
};

export const commaInFr = (value, locale) => {
    if (locale && locale === "fr") return value.replace(/\./g, ",");
    return value;
};

export const getPluralOrSingular = (number, singular, plural) => {
    if (number <= 1) {
        return singular;
    } else if (number > 1) {
        return plural;
    }
};

export const getCountPluralOrSingular = (number, plural, singular, printZero = true) => {
    if ((printZero && number === 0) || number === 1) {
        return number + " " + singular;
    } else if (number > 1) {
        return number + " " + plural;
    }
    return null;
};

export const getCharacters = (value, minValue, maxValue, error, focused) => {
    if (!value?.length) {
        return (
            <Typography
                variant="h6"
                style={{
                    color: theme.palette.secondary.dark,
                    fontSize: "10px",
                    position: "absolute",
                    right: 8,
                    bottom: 2,
                    marginBottom: 0,
                }}
            >
                {minValue ? <Trans>{minValue} caractères min.</Trans> : <Trans>0 caractères / {maxValue} max.</Trans>}
            </Typography>
        );
    } else if (value?.length === 1) {
        return (
            <Typography
                className="textChar"
                variant="h6"
                style={{
                    color: !focused ? theme.palette.error.main : theme.palette.warning.main,
                    fontSize: "10px",
                    position: "absolute",
                    right: 8,
                    bottom: 2,
                    marginBottom: 0,
                }}
            >
                {minValue ? <Trans>1 caractère / {minValue} min.</Trans> : <Trans>1 caractère / {maxValue} max.</Trans>}
            </Typography>
        );
    } else if (minValue && value?.length > 1 && value?.length < minValue) {
        return (
            <Typography
                className="textChar"
                variant="h6"
                style={{
                    color: !focused ? theme.palette.error.main : theme.palette.warning.main,
                    fontSize: "10px",
                    position: "absolute",
                    right: 8,
                    bottom: 2,
                }}
            >
                <Trans>
                    {value?.length} caractères / {minValue} min.
                </Trans>
            </Typography>
        );
    } else if (value?.length > 1 && value?.length < maxValue) {
        return (
            <Typography
                className="textChar"
                variant="h6"
                style={{
                    color: theme.palette.secondary.dark,
                    fontSize: "10px",
                    position: "absolute",
                    right: 8,
                    bottom: 2,
                }}
            >
                <Trans>
                    {value?.length} caractères / {maxValue} max.
                </Trans>
            </Typography>
        );
    } else if (value?.length >= maxValue) {
        return (
            <Typography
                variant="h6"
                style={{
                    color: focused ? theme.palette.warning.main : theme.palette.error.main,
                    fontSize: "10px",
                    position: "absolute",
                    right: 8,
                    bottom: 2,
                }}
            >
                <Trans>
                    {value?.length} caractères / {maxValue} max.
                </Trans>
            </Typography>
        );
    }
};

export function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export const calculateTTC = (price, tva = TVA) => {
    const tvaCost = (price / 100) * tva;
    return price + tvaCost;
};

export const calculateTVA = (ht, tva = TVA) => {
    return (ht / 100) * tva;
};

export const formatPrice = (price, suffix) => {
    return `${price
        .toString()
        .replace(".", ",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")} € ${suffix ? suffix.toUpperCase() : ""}`;
};

export const isNull = (value) => {
    if (Array.isArray(value)) {
        return value.length === 0;
    }
    return [undefined, null, ""].includes(value);
};

export const not = (value) => !value;

export const isNotNull = (value) => !isNull(value);

export const isEmptyObject = (obj) => {
    if (!obj) {
        return true;
    }
    if (typeof obj !== "object") {
        return false;
    }
    if (Object.keys(obj).length === 0) return true;
    return Object.values(obj).every((value) => (typeof value === "object" ? isEmptyObject(value) : isNull(value)));
};

export const removeEmptyRows = (obj) => {
    const result = {};
    const entries = Object.entries(obj);
    for (let [key, value] of entries) {
        if (Array.isArray(value)) {
            result[key] = [];
            for (const row of value) {
                if (!isEmptyObject(row)) {
                    result[key].push(row);
                }
            }
        } else {
            result[key] = value;
        }
    }
    return result;
};

export const formatPhotonResults = (res) => {
    const features = res?.features;

    if (!features) {
        return [];
    }

    return features.map((feature, i) => {
        let simple = "";
        const { housenumber, street, city, postcode, name } = feature.properties;
        if (housenumber && street) {
            simple = [housenumber, street].join(" ");
        } else {
            simple = name;
        }
        const fullName = formatConcat([simple, city, postcode]);
        return { name: fullName, index: i, simple };
    });
};

export const getEntityId = (state) => state.user.currentUser?.wineEntityId;
export const getEntityName = (state) => state.user.currentUser?.wineEntityName;
export const getShortEntityId = (state) => state.user.currentUser?.shortWineEntityId;
export const getCurrentUser = (state) => state.user.currentUser;
export const getSubscriptionName = (state) => state.user.currentUser?.subscriptionName;
export const getSubscriptionAddons = (state) => state.user.currentUser?.addonCodes;
export const getSubscriptionRecurrence = (state) => state.user.currentUser?.subscriptionRecurrence;
export const getPaymentMethod = (state) => state.user.currentUser?.paymentMethod;
export const getPaymentStatus = (state) => state.user.currentUser?.paymentStatus;
export const getUserId = (state) => state.user?.currentUser?.userId;
export const getUserLocale = (state) => state?.user?.locale || "fr";
export const getFreeTrial = (state) => state.user.currentUser?.freeTrial;

export const sanitizeFirstLetter = (brandName, doStopwordsStep = true, doAccentsStep = true) => {
    if (brandName) {
        let low = brandName.toLowerCase();
        if (doAccentsStep) {
            low = low.replace("chateau", "château");
            low = low.replace("cuvee", "cuvée");
        }

        const separators =
            low.match(/[ '-]+/g)?.map((e) => {
                if (e.includes("-") || e.includes("'")) {
                    return e.trim();
                } else {
                    return e.replace(/\s+/g, " ");
                }
            }) || [];

        const lows = low.split(/[ '-]+/g);

        let ret = "";
        for (let i = 0; i < lows.length; i++) {
            const l = lows[i];
            if (doStopwordsStep && i !== 0) {
                ret += l;
            } else {
                ret += l.charAt(0).toUpperCase() + l.slice(1);
            }
            if (i < lows.length - 1) {
                ret += separators[i];
            }
        }
        return ret;
    }
    return brandName?.trim();
};
export const sanitizeName = (brandName, doStopwordsStep = true, doAccentsStep = true) => {
    const stopwords = [
        "de",
        "des",
        "du",
        "et",
        "la",
        "le",
        "les",
        "l",
        "d",
        "sur",
        "au",
        "aux",
        "par",
        "entre",
        "ma",
        "mon",
        "mes",
    ];
    if (brandName) {
        let low = brandName.toLowerCase();
        if (doAccentsStep) {
            low = low.replace("chateau", "château");
            low = low.replace("cuvee", "cuvée");
        }

        const separators =
            low.match(/[ '-]+/g)?.map((e) => {
                if (e.includes("-") || e.includes("'")) {
                    return e.trim();
                } else {
                    return e.replace(/\s+/g, " ");
                }
            }) || [];

        const lows = low.split(/[ '-]+/g);

        let ret = "";
        for (let i = 0; i < lows.length; i++) {
            const l = lows[i];
            if (doStopwordsStep && stopwords.includes(l)) {
                ret += l;
            } else {
                ret += l.charAt(0).toUpperCase() + l.slice(1);
            }
            if (i < lows.length - 1) {
                ret += separators[i];
            }
        }
        return ret;
    }
    return brandName?.trim();
};
export const sanitizeProperCase = (brandName, doStopwordsStep = true, doAccentsStep = true) => {
    const stopwords = [
        "de",
        "des",
        "du",
        "et",
        "la",
        "le",
        "les",
        "l",
        "d",
        "sur",
        "au",
        "aux",
        "par",
        "entre",
        "ma",
        "mon",
        "mes",
    ];
    if (brandName) {
        let low = brandName.toLowerCase();
        if (doAccentsStep) {
            low = low.replace("chateau", "château");
            low = low.replace("cuvee", "cuvée");
        }

        const separators =
            low.match(/[ '-]+/g)?.map((e) => {
                if (e.includes("-") || e.includes("'")) {
                    return e.trim();
                } else {
                    return e.replace(/\s+/g, " ");
                }
            }) || [];

        const lows = low.split(/[ '-]+/g);

        let ret = "";
        for (let i = 0; i < lows.length; i++) {
            const l = lows[i];
            if (doStopwordsStep && stopwords.includes(l) && i !== 0) {
                ret += l;
            } else {
                ret += l.charAt(0).toUpperCase() + l.slice(1);
            }
            if (i < lows.length - 1) {
                ret += separators[i];
            }
        }
        return ret;
    }
    return brandName?.trim();
};

export const sanitizeUpperCase = (brandName) => {
    if (brandName) {
        let low = brandName.toLowerCase();

        const separators =
            low.match(/[ '-]+/g)?.map((e) => {
                if (e.includes("-") || e.includes("'")) {
                    return e.trim();
                } else {
                    return e.replace(/\s+/g, " ");
                }
            }) || [];

        const lows = low.split(/[ '-]+/g);

        let ret = "";
        for (let i = 0; i < lows.length; i++) {
            const l = lows[i];
            ret += l.toUpperCase();

            if (i < lows.length - 1) {
                ret += separators[i];
            }
        }
        return ret;
    }
    return brandName?.trim();
};

export const sanitizeAddress = (address) => ({
    ...address,
    address: sanitizeAddressField(address?.address),
    complementaryAddress: sanitizeAddressField(address?.complementaryAddress),
});

export const sanitizeAddressField = (field) => {
    let ret = sanitizeProperCase(field)?.replace("Lieu-Dit", "Lieu-dit");
    const bp = ret?.match(/Bp\d+/g);
    const replaceBp = bp?.map((e) => e.toUpperCase());
    for (let i = 0; i < bp?.length; i++) {
        const b = bp[i];
        ret = ret.replace(b, replaceBp[i]);
    }
    return ret;
};

export const parseHtml = (content) => {
    if (content) {
        return parse(content);
    }
    return null;
};

export const getHtmlLength = (str) => {
    let ret;
    if (str === null || str === "" || !str) {
        return 0;
    } else {
        ret = str.toString();
    }

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return ret.replace(/(<([^>]+)>)/gi, "").length;
};

export const formatPerson = (person) => {
    const { title = "", firstName = "", lastName = "" } = person ?? {};

    const result = [title, firstName, lastName].filter((value) => value !== "").join(" ");
    return result === "" ? null : result;
};

export const getErrorExtentions = (error) => error?.graphQLErrors?.[0]?.extensions ?? {};

export const getIds = (data) => {
    if (!Array.isArray(data)) return null;
    const dataIds = data.map((d) => d?._id).filter((d) => d !== undefined);
    return dataIds;
};

export const isEllipsisActive = (e) => {
    return e?.offsetWidth < e?.scrollWidth;
};

export const truncateContentLink = (title, col, i, isBold, href) => {
    if (!title) return "";
    const id = document.getElementById(`${col}-${i}`);
    return (
        <BaseTooltip placement="bottom-start" title={isEllipsisActive(id) ? title : ""}>
            <Link
                href={href}
                target="_blank"
                underline="hover"
                id={`${col}-${i}`}
                variant="h6"
                style={{
                    display: "block",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    fontWeight: isBold ? 700 : "",
                }}
            >
                {title}
            </Link>
        </BaseTooltip>
    );
};

export const truncateContent = (title, col, i, isBold, color = "inherit") => {
    if (!title) return "";
    const id = document.getElementById(`${col}-${i}`);
    return (
        <BaseTooltip placement="bottom-start" title={isEllipsisActive(id) ? title : ""}>
            <Typography
                id={`${col}-${i}`}
                variant="h6"
                style={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    fontWeight: isBold ? 700 : "",
                    color,
                }}
            >
                {title}
            </Typography>
        </BaseTooltip>
    );
};

export const convertDate = (date) => {
    if (!date) return;
    const newDate = parseInt(date);
    return new Date(newDate).toLocaleString("fr-FR").slice(0, -3);
};

export const converDateMonthYear = (date) => {
    return moment(date).format("DD/MM/YYYY");
};
export const convertDateMonthYearHourMinutes = (date) => {
    return moment(date).format("DD/MM/YYYY HH:mm");
};

export const sortByKey = (a, b, key) => {
    const aLower = a?.values?.[key]?.toLowerCase();
    const bLower = b?.values?.[key]?.toLowerCase();
    if (aLower === bLower) {
        return 0;
    } else if (aLower === null || aLower === undefined) {
        return 1;
    } else if (bLower === null || bLower === undefined) {
        return -1;
    } else if (aLower < bLower) {
        return -1;
    }
    return 1;
};

export const defaultSort = (a, b) => {
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }
    return 0;
};

export const categoryIcon = {
    Caviste: caviste,
    Négociant: negociant,
    Exportateur: exportateur,
    Importateur: importateur,
    CHR: chr,
    Commerce: commerce,
    "E-commerçant": ecommercant,
    Courtier: courtier,
    Particulier: particulier,
    Grossiste: grossiste,
    "Grande distribution": grande_distribution,
    "C.E.": ce,
};

export const isEmailValid = (email) => {
    // eslint-disable-next-line no-useless-escape
    const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return !!email.match(emailRegex);
};

export const subscriptionNameFormat = {
    [SUBSCRIPTION_TYPES.trial]: "Essai gratuit",
    [SUBSCRIPTION_TYPES.basic]: "Basique",
    [SUBSCRIPTION_TYPES.initial]: "Initial",
    [SUBSCRIPTION_TYPES.essential]: "Essentiel",
    [SUBSCRIPTION_TYPES.premium]: "Premium",
};

export const filterNull = (values) => {
    if (!Array.isArray(values)) return null;
    return values.filter((value) => !isNull(value));
};

export const formatConcat = (values, separator = " ") => filterNull(values)?.join(separator) ?? "";

export const recursiveConcat = (values, separators = [" "]) => {
    return values
        .map((value) => {
            if (Array.isArray(value)) return recursiveConcat(value, separators.slice(1));
            return value;
        })
        .filter((value) => !isNull(value))
        .join(separators.length > 0 ? separators[0] : " ");
};

export const formatAddress = (fullAddress) => {
    const { address, postalCode, commune, country } = fullAddress ?? {};
    return recursiveConcat(
        [
            [address, postalCode],
            [commune, country],
        ],
        [" ", ", "]
    );
};

export const stringifyUrlParams = (params) =>
    Object.entries(params)
        .map(([key, value]) => (!isNull(value) ? `${key}=${encodeURI(value)}` : null))
        .filter(Boolean)
        .join("&");

export const darkColorSvg =
    "brightness(0) saturate(100%) invert(18%) sepia(66%) saturate(533%) hue-rotate(290deg) brightness(93%) contrast(89%)";

export const getPercentage = (value, percentage) => (value * percentage) / 100;

export const getCurrentDate = () => {
    const event = new Date();
    const options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
    return event.toLocaleDateString(undefined, options);
};

export const descSort = (property) => {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        var result = a[property] < b[property] ? 1 : a[property] > b[property] ? -1 : 0;
        return result * sortOrder;
    };
};

export const clearStringAccent = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const urlize = (str) => {
    if (!str || typeof str !== "string") return str;
    return str
        .toLowerCase()
        .trim()
        .replace(/\s|'|,|\//g, "-")
        .replace(/-+/g, "-")
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
};

export const getTextLength = (text) => text && text?.replace(/(<([^>]+)>)/gi, "").split("")?.length;

export const isNotEmpty = (content) =>
    content && content.replace(/(<([^>]+)>)/gi, "") !== "" && !isNull(parseHtml(content));

export const compare = (a, b) => {
    let aName = "",
        bName = "";
    if (a?.original?.name) {
        aName = a.original.name;
    }
    if (b?.original?.name) {
        bName = b.original.name;
    }
    return aName.toLowerCase().localeCompare(bName.toLowerCase());
};

export const openCreator = (wineEntityId, creatorParams) => {
    const args = [
        "toolbar=no",
        "location=no",
        "directories=no",
        "status=no",
        "menubar=no",
        "scrollbars=yes",
        "copyhistory=yes",
        "fullscreen=yes",
        "width=4000",
        "height=4000",
    ];
    const { ftParametersId: ftId, token } = creatorParams;
    window.open(`${CREATOR_URL}/?${stringifyUrlParams({ wineEntityId, ftId, token })}`, null, args.join(","));
};

export const getNamesByWineAndVintage = (wine, vintage) => (ft) => {
    if (ft?.wineId === wine?.wineId && Number(ft?.vintage) === Number(vintage?.year) && ft?.cuvee === vintage?.cuvee) {
        return ft.name;
    }
    return null;
};

export const activityPersons = (min, max) => {
    if (!min && !max) {
        return null;
    } else if (min && !max) {
        return t`À partir de` + " " + min;
    } else if (max && !min) {
        return t`Max.` + " " + max;
    } else if (min !== max) {
        return `${min} - ${max}`;
    } else if (min === max) {
        return `${min} `;
    }
};

export const getCurrentYear = () => {
    return moment().year();
};

export const formatAndAddUnit = (initialValue, labelFr, enums, { locale = "fr", isPlural = false }) => {
    let value = null;
    let unit = labelFr + (isPlural ? "s" : "");
    if (initialValue) {
        value = new Intl.NumberFormat(locale).format(initialValue);
        const unitEnum = enums.find((enumValue) => enumValue?.valueLng?.fr === unit);
        unit = unitEnum?.valueLng?.[locale];
        if (unit) value += ` ${unit}`;
    }
    return value;
};

export const isOffline = (paymentMethod) =>
    !paymentMethod ||
    [PAYMENT_METHODS.bankCheck, PAYMENT_METHODS.bankTransfer, PAYMENT_METHODS.offline].includes(paymentMethod);

export const getPlanCode = (subscriptionType, recurrence) => {
    const planCodeMatch = {
        [SUBSCRIPTION_TYPES.initial]: {
            [SUBSCRIPTION_RECURRENCE.month]: SUB_BORDEAUX_INITIAL_MONTH,
            [SUBSCRIPTION_RECURRENCE.year]: SUB_BORDEAUX_INITIAL_YEAR,
        },
        [SUBSCRIPTION_TYPES.essential]: {
            [SUBSCRIPTION_RECURRENCE.month]: SUB_BORDEAUX_ESSENTIAL_MONTH,
            [SUBSCRIPTION_RECURRENCE.year]: SUB_BORDEAUX_ESSENTIAL_YEAR,
        },
        [SUBSCRIPTION_TYPES.premium]: {
            [SUBSCRIPTION_RECURRENCE.month]: SUB_BORDEAUX_PREMIUM_MONTH,
            [SUBSCRIPTION_RECURRENCE.year]: SUB_BORDEAUX_PREMIUM_YEAR,
        },
    };
    return planCodeMatch[subscriptionType]?.[recurrence];
};

export const isRequested = (service) => ["PAID"].includes(service?.status);

export const round = (number, precision = 2) => {
    const multiplicator = Math.pow(10, precision);
    return Math.round(number * multiplicator) / multiplicator;
};

export const getSubscriptionTranslation = (type) => {
    if (type === SUBSCRIPTION_TYPES.basic) {
        return t`basique`;
    } else if (type === SUBSCRIPTION_TYPES.essential) {
        return t`essentiel`;
    } else if (type === SUBSCRIPTION_TYPES.initial) {
        return t`initial`;
    }
    return t`premium`;
};

export const isBasicOrInitial = (subscriptionName) => {
    return subscriptionName === SUBSCRIPTION_TYPES.basic || subscriptionName === SUBSCRIPTION_TYPES.initial;
};

export const isInitialOrEssential = (subscriptionName) => {
    return subscriptionName === SUBSCRIPTION_TYPES.initial || subscriptionName === SUBSCRIPTION_TYPES.essential;
};

export const addonWithSuffix = (addon, subscriptionType, withSub = true) => {
    const withoutSubAddons = [ADD_FLUX_FTP, ADD_TASTINGVIDEO, ADD_TASTINGREQUEST];
    let result = addon;
    if (withSub && !withoutSubAddons.includes(addon)) {
        result += `-${subscriptionType}`;
    }
    return result + ZOHO_STAGE;
};

export const addonWithoutSuffix = (addon) => {
    const regexConstruct = [...Object.values(ENV_NAMES), ...Object.values(SUBSCRIPTION_TYPES)].map((e) => `-${e}`);
    const regex = new RegExp(regexConstruct.join("|"), "g");
    return addon.replaceAll(regex, "");
};

export const hasAddon = (susbcriptionAddons, addonCode) =>
    susbcriptionAddons &&
    Array.isArray(susbcriptionAddons) &&
    susbcriptionAddons.map(addonWithoutSuffix).includes(addonCode);

export const urlFormatted = (shortId, path) => {
    return formatConcat([shortId, urlize(path)], "-");
};

export const getDateParams = () => {
    const date = new Date();
    const year = date.getFullYear();
    const day = date.getDate();
    const month = date.getMonth();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const milliseconds = date.getMilliseconds();
    const weekDay = date.getDay();
    return {
        year,
        day,
        month,
        hours,
        minutes,
        seconds,
        milliseconds,
        weekDay,
    };
};

export const renameImg = (content, path) => {
    for (let i = 0; i < content.length; i++) {
        if (content[i]?.filename && content[i]?.originalData) {
            const { seconds, milliseconds } = getDateParams();
            const fileExtention = content[i].filename.split(".").slice(-1)[0];
            content[i].filename = `${path}${i + 1}-${seconds}-${milliseconds}.${fileExtention}`;
        }
    }
    return content;
};

export const renameDocument = (content, path) => {
    for (let i = 0; i < content.length; i++) {
        if (content[i]?.name) {
            const { seconds, milliseconds } = getDateParams();
            const fileExtention = content[i].name.split(".").slice(-1)[0];
            let blob = content[0].slice(0, content[0].size, content[0].type);
            let newFile = new File([blob], `${path}${i + 1}-${seconds}-${milliseconds}.${fileExtention}`, {
                type: content[0].type,
            });
            content[i] = newFile;
        }
    }
    return content;
};

export const getByKey = (array, key) => {
    if (!Array.isArray(array)) return null;
    return array.map((item) => item[key]);
};

export const getStrByKey = (array, key) => getConnectors(getByKey(array, key));

export const makeHttp = (url) => (url?.search("https?://") === 0 ? url : `http://${url}`);

export const getScoringLogo = (companyName) => {
    const availableLogos = [
        "andreas-larsson",
        "anthocyanes",
        "b-my-bordeaux",
        "bernard-burtschy",
        "bettane-&-desseauve",
        "bloomberg",
        "cellar-tracker",
        "decanter",
        "Decanter",
        "falstaff",
        "gault&millau",
        "great-bordeaux-wines",
        "jacques-dupont",
        "james-suckling",
        "jancis-robinson",
        "JancisRobinson",
        "jeannie-cho-lee",
        "jeb-dunnuck",
        "jm-quarin",
        "la-revue-vinicole-internationale",
        "le-figaro",
        "Logo_JamesSuckling",
        "Logo_RobertParker",
        "Logo_Vinous",
        "Logo_WineSpectator",
        "markus-del-monego-mw",
        "matthew-jukes",
        "rene-gabriel",
        "robert-parker-wine-advocate",
        "rvf",
        "terre-de-vins",
        "the-wine-cellar-iInsider",
        "the-wine-cellar-insider",
        "the-wine-independent",
        "tim-atkin",
        "vertdevin",
        "vinous",
        "wine-decider",
        "wine-doctor",
        "wine-enthusiast",
        "wine-searcher",
        "wine-spectator",
    ];
    const companySlug = urlize(companyName);
    if (!availableLogos.includes(companySlug)) return null;
    return `https://feret.twic.pics/feret-scoring-logos/${companySlug}.png`;
};

export const getAllMemberRoles = (member, locale = "fr") => {
    if (!member) {
        return null;
    }
    const ret = member.role?.map((e) => e.valueLng?.[locale]) || [];

    const roleComplement = member.roleComplement?.raw?.[locale];
    if (roleComplement) {
        ret.push(roleComplement);
    }
    return ret;
};

export const hasOwnerFunction = (proFunctions) => proFunctions?.includes("Propriétaire");

export const operatorValues = ["Exploitant", "Exploitante"];

export const hasOperatorFunction = (proFunctions) =>
    proFunctions?.some((proFunction) => operatorValues.includes(proFunction));

export const isOwner = (proFunctions) => hasOwnerFunction(proFunctions);

export const isOperator = (proFunctions) => hasOperatorFunction(proFunctions);

export const isMemberOwner = (member) => isOwner(getAllMemberRoles(member));

export const isMemberOperator = (member) => isOperator(getAllMemberRoles(member));

export const isMemberOwnerOperator = (member) =>
    getAllMemberRoles(member)?.some((role) => [...operatorValues, "Propriétaire"].includes(role));

export const isMemberOther = (member) =>
    getAllMemberRoles(member)?.every(
        (proFunction) => proFunction !== "Propriétaire" && !operatorValues.includes(proFunction)
    );

export const isValidDate = (date) => moment(date).isValid();
export const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

export const menuCTA = (currentUser, paymentStatus, shortFreetrial) => {
    const locale = useSelector((state) => state?.user?.locale || "fr");
    const { width } = useWindowDimensions();
    const freeTrialText = () => {
        if (shortFreetrial) {
            if (width >= 1920) {
                return t`30 jours d'essai gratuit !`;
            }
            return t`30j d'essai gratuit`;
        }
        return t`30 jours d'essai gratuit sans engagement !`;
    };
    if (currentUser?.wineEntityId) {
        return {
            href: "/dashboard",
            content: <Trans>Gérer mes informations</Trans>,
        };
    } else if (currentUser?.subscriptionName === SUBSCRIPTION_TYPES.journalist)
        return {
            href: "/dashboard/tastingrequest",
            content: <Trans>Ma salle de dégustation</Trans>,
        };
    else if (currentUser?.subscriptionName === SUBSCRIPTION_TYPES.ftReceiver)
        return {
            href: "/dashboard/sheets/inbox",
            content: <Trans>Fiches techniques reçues</Trans>,
        };
    else if (paymentStatus === PAYMENT_STATUS.PENDING) {
        return {
            href: "/subscription",
            content: <Trans>Continuer ma souscription</Trans>,
        };
    } else
        return {
            href: "/figurer-dans-bordeaux-et-ses-vins",
            content: (
                <>
                    <Trans>
                        Figurer dans <i>Bordeaux et ses Vins</i>
                    </Trans>
                    {FR_AND_EN.includes(locale) && (
                        <span style={{ color: theme.palette.magenta.light }}>{freeTrialText()}</span>
                    )}
                </>
            ),
        };
};

export const convertUTCDate = (date) => {
    const dateObj = moment.utc(date);
    return dateObj.format("DD/MM/YYYY");
};

export const deepSanitizeObject = (obj, options = {}) => {
    const { considerFalse = false } = options;
    const discriminant = considerFalse ? isNotNull : Boolean;

    // Is value is not an object return null
    if (isNull(obj) || typeof obj !== "object") return null;
    if (Array.isArray(obj)) {
        if (obj.filter(discriminant).length > 0) return obj.map(deepSanitizeObject).filter(discriminant);
        return null;
    }
    if (Object.keys(obj).length === 0) return null;
    const entries = Object.entries(obj)
        .map(([key, value]) => {
            if (typeof value === "object") return [key, deepSanitizeObject(value)];
            return [key, value];
        })
        .filter(([, value]) => discriminant(value));
    return Object.fromEntries(entries);
};

export const isObjectDeepEmpty = (obj, options = {}) => {
    const { handlePrimitive = false } = options;
    // Do not handle primitive by default (return false for primitive values)
    if (handlePrimitive && typeof obj !== "object") return !isNull(obj);
    if (!obj || typeof obj !== "object") return true;
    const result = deepSanitizeObject(obj, options);
    return Object.keys(result).length === 0;
};

export const download = (dl) => {
    const a = document.createElement("a");
    a.setAttribute("href", dl.url);
    a.setAttribute("download", dl.fileName);
    a.setAttribute("target", "_blank");
    a.click();
};

export const downloadText = (filename, text) => {
    var a = document.createElement("a");
    a.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(text));
    a.setAttribute("download", filename);
    a.click();
};

export const filterKey = (obj, allowedKeys) => {
    if (!allowedKeys?.length) return obj;
    const entries = Object.entries(obj);
    const filtred = entries.filter(([key]) => allowedKeys.includes(key));
    return Object.fromEntries(filtred);
};

export const deepOmit = (obj, path) => {
    if (!obj) return obj;
    const parts = path.split(".");
    const firstKey = parts[0];
    const rest = parts.slice(1).join(".");

    if (parts.length === 1) {
        return Array.isArray(obj) ? obj.map((item) => omit(item, path)) : omit(obj, path);
    }

    if (typeof obj === "object") {
        if (Array.isArray(obj)) {
            return obj.map((item) => ({ ...item, [firstKey]: deepOmit(item?.[firstKey], rest) }));
        }
        const entries = Object.entries(obj).map(([key, value]) => {
            if (typeof value === "object" && key === firstKey) return [key, deepOmit(value, rest)];
            return [key, value];
        });
        return Object.fromEntries(entries);
    }
    return obj;
};

export const getDeepValues = (obj) => {
    if (!obj || typeof obj !== "object") return null;
    return Object.values(obj)
        .flatMap((value) => {
            if (typeof value === "object") return getDeepValues(value);
            return value;
        })
        .filter(Boolean);
};
