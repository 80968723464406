import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, makeStyles } from "@material-ui/core";
import theme from "../../globalTheme";

const useStyles = makeStyles(() => ({
    root: {
        gridColumn: "1 / span 12",
        marginBottom: 10,
    },
    line: {
        position: "absolute",
        zIndex: 1,
        top: "50%",
        width: "100%",
        height: 0.4,
        background: theme.palette.secondary.main,
    },
    title: {
        color: theme.palette.primary.dark,
        fontWeight: 700,
        backgroundColor: theme.palette.common.white,
        display: "inline-flex",
        position: "relative",
        zIndex: 10,
        paddingRight: 10,
    },
}));

const ModalSectionTitle = (props) => {
    const styles = useStyles();

    return (
        <Box className={styles.root} style={props?.styles}>
            <Box style={{ position: "relative" }}>
                <Box className={styles.line} />
                <Typography variant="h4" className={styles.title}>
                    {props?.title}
                </Typography>
            </Box>
        </Box>
    );
};

ModalSectionTitle.propTypes = {
    title: PropTypes.string,
    styles: PropTypes.object,
};

export default ModalSectionTitle;
