import { Box, Typography, makeStyles } from "@material-ui/core";
import Modal from "../../Containers/Modal";
import PropTypes from "prop-types";
import theme from "../../globalTheme";
import SectionCGU from "Components/cgu/SectionCGU";
import { feretAddress, feretPostalCode } from "config/constants";

const useStyle = makeStyles(() => ({
    mainBox: {
        width: "100%",

        "&::-webkit-scrollbar": { width: "8px", height: "8px" },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            paddingRight: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "20px",
        },
    },
    title: {
        textAlign: "center",
        marginBottom: theme.spacing(4),
    },
}));

const CGUPopin = (props) => {
    const styles = useStyle(props);

    const datas = [
        {
            title: "Principes",
            body: [
                <>
                    Les présentes conditions générales d&apos;utilisation ont pour objet l&apos;encadrement juridique de
                    l&apos;utilisation du site <i>Bordeaux et ses Vins</i> et de ses services.
                </>,
                `Le site Internet bordeaux.feret.com est un service de la société EDITIONS FERET, située ${feretAddress} ${feretPostalCode}, France`,
                [
                    "adresse URL du site : bordeaux.feret.com",
                    "e-mail : contact@feret.com",
                    "téléphone : 05 56 13 79 95",
                ],
                "Les conditions générales d'utilisation doivent être acceptées par tout Utilisateur, et son accès au site vaut acceptation de ces conditions.",
            ],
        },
        {
            title: "Evolution et durée des CGU",
            body: [
                "Les présentes conditions générales d'utilisation sont conclues pour une durée indéterminée. Le contrat produit ses effets à l'égard de l'Utilisateur à compter du début de l’utilisation du service.",
                <>
                    Le site <i>Bordeaux et ses Vins</i> se réserve le droit de modifier les clauses de ces conditions
                    générales d’utilisation à tout moment et sans justification.
                </>,
            ],
        },
        {
            title: "Accès au site",
            body: [
                <>
                    Tout Utilisateur ayant accès à internet peut accéder gratuitement et depuis n&apos;importe où au
                    site <i>Bordeaux et ses Vins</i>. Les frais supportés par l’Utilisateur pour y accéder (connexion
                    internet, matériel informatique, etc.) ne sont pas à la charge de l’Éditeur.
                </>,
                "Le site et ses différents services peuvent être interrompus ou suspendus par l’Éditeur, notamment à l’occasion d'une maintenance, sans obligation de préavis ou de justification.",
                "L'Utilisateur du site a accès au service suivant : accès et abonnement aux fiches techniques de vins, présentées par les producteurs.",
                "Le site comprend un espace membre réservé aux utilisateurs inscrits. Ces utilisateurs pourront y accéder en utilisant leurs identifiants de connexion.",
                "Les services réservés aux membres sont les suivants : Accès aux fiches techniques des vins.",
            ],
        },
        {
            title: "Responsabilités",
            body: [
                "La responsabilité de l'Éditeur ne peut être engagée en cas de défaillance, panne, difficulté ou interruption de fonctionnement, empêchant l'accès au site ou à une de ses fonctionnalités.",
                "Le matériel de connexion au site utilisée est sous l'entière responsabilité de l’Utilisateur qui doit prendre toutes les mesures appropriées pour protéger le matériel et les données notamment d'attaques virales par Internet. L'utilisateur est par ailleurs le seul responsable des sites et données qu'il consulte.",
                "L'Éditeur ne pourra être tenu responsable en cas de poursuites judiciaires à l'encontre de l'Utilisateur :",
                [
                    "du fait de l'usage du site ou de tout service accessible via Internet ;",
                    "du fait du non-respect par l'Utilisateur des présentes conditions génératles.",
                ],
                "L'Éditeur n'est pas responsable des dommages causés à l’Utilisateur, à des tiers et ou à l'équipement de l'Utilisateur du fait de sa connexion ou de son utilisation du site et l'Utilisateur renonce à toute action contre l'Éditeur de ce fait.",
                "Si l’Éditeur venait à faire l'objet d'une procédure amiable ou judiciaire à raison de l'utilisation du site par l'Utilisateur, il pourra se retourner contre lui pour obtenir indemnisation de tous les préjudices, sommes, condamnations et frais qui pourraient découler de cette procédure.",
            ],
        },
        {
            title: "Propriété intellectuelle",
            body: [
                "Tous les documents techniques, produits, photographies, textes, logos, dessins, vidéos, etc., sont soumis à des droits d'auteur et sont protégés par le Code de la propriété intellectuelle. Ils demeurent la propriété exclusive de EDITIONS FERET, seul titulaire des droits de propriété intellectuelle sur ces documents.",
                "Nos clients s'engagent à ne faire aucun usage de ces documents, susceptible de porter atteinte aux droits de propriété industrielle ou intellectuelle du fournisseur et s'engagent à ne les divulguer à aucun tiers, en dehors d'une autorisation expresse et préalable donnée par l'Editeur.",
            ],
        },
        {
            title: "Actualisation des informations et badge Fére",
            body: [
                "Féret entreprend une vaste opération de recensement des propriétés et vins de Bordeaux. Seules les propriétés arborant le Badge Féret présentent des données actualisées et certifiées.",
            ],
        },
        {
            title: "Protection des données personnelles",
            body: [
                "Données collectées",
                "Les données à caractère personnel qui sont collectées sur ce site sont les suivantes :",
                [
                    "ouverture de compte : lors de la création du compte de l'utilisateur : nom ; prénom ; adresse électronique ; n° de téléphone ;",
                    "connexion : lors de la connexion de l'utilisateur au site web, celui-ci enregistre, notamment, ses nom, prénom, données de connexion, d'utilisation et de localisation;",
                    "profil : l'utilisation des services prévus sur le site web permet de renseigner un profil, pouvant comprendre une adresse et un numéro de téléphone ;",
                    "communication : lorsque le site web est utilisé pour communiquer avec d'autres membres, les données concernant les communications de l'utilisateur font l'objet d'une conservation temporaire ;",
                    "cookies : les cookies sont utilisés dans le cadre de l'utilisation du site. L'utilisateur a la possibilité de désactiver les cookies à partir des paramètres de son navigateur.",
                ],
            ],
        },
        {
            title: "Utilisation des données personnelles",
            body: [
                "Les données personnelles collectées auprès des utilisateurs ont pour objectif la mise à disposition des services du site web, leur amélioration et le maintien d'un environnement sécurisé. Plus précisément, les utilisations sont les suivantes :",
                [
                    "accès et utilisation du site web par l’utilisateur ;",
                    "gestion du fonctionnement et optimisation du site web ;",
                    "organisation des conditions d'utilisation des Services de paiement ;",
                    "vérification, identification et authentification des données transmises par l'utilisateur ;",
                    "mise en œuvre d'une assistance utilisateurs ;",
                    "personnalisation des services en affichant des publicités en fonction de l’historique de navigation de l'utilisateur, selon ses préférences ;",
                    "prévention et détection des fraudes, malwares (malicious softwares ou logiciels malveillants) et gestion des incidents de sécurité ;",
                    "gestion des éventuels litiges avec les utilisateurs ;",
                    "envoi d'informations commerciales et publicitaires, en fonction des préférences de l'utilisateur.",
                ],
            ],
        },
        {
            title: "Partage des données personnelles avec des tiers",
            body: [
                "Les données personnelles peuvent être partagées avec des sociétés tierces, dans les cas suivants :",
                [
                    "lorsque l'Utilisateur utilise les services de paiement, pour la mise en œuvre de ces services, le site web est en relation avec des sociétés bancaires et financières tierces avec lesquelles elle a passé des contrats ;",
                    "lorsque l'Utilisateur publie, dans les zones de commentaires libres du site web, des informations accessibles au public :",
                    "lorsque l'Utilisateur autorise le site web d'un tiers à accéder à ses données ;",
                    "lorsque le site web recourt aux services de prestataires pour fournir l'assistance utilisateurs, la publicité et les services de paiement. Ces prestataires disposent d'un accès limité aux données de l'utilisateur, dans le cadre de l'exécution de ces prestations, et ont une obligation contractuelle de les utiliser en conformité avec les dispositions de la réglementation applicable en matière protection des données à caractère personnel ;",
                    "si la loi l'exige, le site web peut effectuer la transmission de données pour donner suite aux réclamations présentées contre le site web et se conformer aux procédures administratives et judiciaires ;",
                    "si le site web est impliqué dans une opération de fusion, acquisition, cession d'actifs ou procédure de redressement judiciaire, elle pourra être amenée à céder ou partager tout ou partie de ses actifs, y compris les données à caractère personnel. Dans ce cas, les utilisateurs seraient informés, avant que les données à caractère personnel ne soient transférées à une tierce partie.",
                ],
            ],
        },
        {
            title: "Sécurité et confidentialité",
            body: [
                "Le site web met en œuvre des mesures organisationnelles, techniques, logicielles et physiques en matière de sécurité du numérique pour protéger les données personnelles contre les altérations, destructions et accès non autorisés. Toutefois, il est à signaler qu'internet n'est pas un environnement complètement sécurisé et le site web ne peut pas garantir la sécurité de la transmission ou du stockage des informations sur Internet.",
            ],
        },
        {
            title: "Mise en œuvre des droits des utilisateurs",
            body: [
                "En application de la réglementation applicable aux données à caractère personnel, les utilisateurs disposent des droits suivants, qu'ils peuvent exercer en faisant leur demande à l'adresse suivante : rgpd@feret.com.",
                [
                    "le droit d’accès: ils peuvent exercer leur droit d'accès, pour connaître les données personnelles les concernant. Dans ce cas, avant la mise en œuvre de ce droit, le site web peut demander une preuve de l'identité de l'utilisateur afin d'en vérifier l'exactitude.",
                    "le droit de rectification : si les données à caractère personnel détenues par le site web sont inexactes, ils peuvent demander la mise à jour des informations.",
                    "le droit de suppression des données : les utilisateurs peuvent demander la suppression de leurs données à caractère personnel, conformément aux lois applicables en matière de protection des données.",
                    "le droit à la limitation du traitement : les utilisateurs peuvent de demander au site web de limiter le traitement des données personnelles conformément aux hypothèses prévues par le RGPD.",
                    "le droit de s'opposer au traitement des données : les utilisateurs peuvent s’opposer à ce que ses données soient traitées conformément aux hypothèses prévues par le RGPD.",
                    "le droit à la portabilité : ils peuvent réclamer que le site web leur remette les données personnelles qui lui sont fournies pour les transmettre à un nouveau site web.",
                ],
            ],
        },
        {
            title: "Evolution de la présente clause",
            body: [
                "Le site web se réserve le droit d'apporter toute modification à la présente clause relative à la protection des données à caractère personnel à tout moment. Si une modification est apportée à la présente clause de protection des données à caractère personnel, le site web s'engage à publier la nouvelle version sur son site. Le site web informera également les utilisateurs de la modification par messagerie électronique, dans un délai minimum de 15 jours avant la date d'effet. Si l'utilisateur n'est pas d'accord avec les termes de la nouvelle rédaction de la clause de protection des données à caractère personnel, il a la possibilité de supprimer son compte",
            ],
        },
        {
            title: "Cookies",
            body: [
                <>
                    Le site <i>Bordeaux et ses Vins</i> peut collecter automatiquement des informations standards.
                    Toutes les informations collectées indirectement ne seront utilisées que pour suivre le volume, le
                    type et la configuration du trafic utilisant ce site, pour en développer la conception et
                    l&apos;agencement et à d&apos;autres fins administratives et de planification et plus généralement
                    pour améliorer le service que nous vous offrons.
                </>,
            ],
        },
        {
            title: "Loi applicable",
            body: [
                "Les présentes conditions générales d'utilisation sont soumises à l'application du droit français. Si les parties n'arrivent pas à résoudre un litige à l'amiable, le litige sera soumis à la compétence des tribunaux français.",
            ],
        },
    ];

    return (
        <Modal
            width="50rem"
            open={props.modalOpen}
            onClose={props.handleClose}
            actionNeeded={false}
            title="Confidentialité et conditions générales d’utilisation"
        >
            <Box className={styles.mainBox}>
                <Box className={styles.topContent}>
                    <Typography variant="h6" className={styles.title_subtitle}>
                        <strong>Conditions générales d&apos;utilisation</strong> <br />
                        <strong>du site</strong> <i>Bordeaux et ses Vins</i>
                    </Typography>

                    <br />

                    <Typography variant="h6" className={styles.typo}>
                        Préambule
                        <br />
                        Les présentes conditions générales d&apos;utilisation sont conclues entre :
                    </Typography>

                    <ul className={styles.listBox}>
                        <li>
                            <Typography variant="h6" className={styles.listItem}>
                                le gérant du site internet ci-après désigné « l’Éditeur »,
                            </Typography>
                        </li>
                        <li className={styles.listItem}>
                            <Typography variant="h6" className={styles.listItem}>
                                toute personne souhaitant accéder au site et à ses services, ci-après appelé «
                                l’Utilisateur ».
                            </Typography>
                        </li>
                    </ul>
                </Box>

                {datas?.map((data, index) => (
                    <SectionCGU
                        key={`${index + 1}-${data.title.replaceAll(" ", "_")}`}
                        number={index + 1}
                        title={data.title}
                        body={data.body}
                        variantTitle="h5"
                        variantContent="h6"
                        titleStyle={{ fontWeight: "bold" }}
                    />
                ))}
            </Box>
        </Modal>
    );
};

export default CGUPopin;

CGUPopin.propTypes = {
    modalOpen: PropTypes.bool,
    handleClose: PropTypes.func,
    cancelOnClick: PropTypes.func,
    continueOnClick: PropTypes.func,
    errorLinkOnClick: PropTypes.func,
};
