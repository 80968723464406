/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
// Setup:
import React from "react";
import PropTypes from "prop-types";

// Components:
import { makeStyles, Box, Typography } from "@material-ui/core";
import WineSoils from "../Components/WineSoils";
import WineHarvestDescription from "../Components/WineHarvestDescription";
import WineGreenOperations from "../Components/WineGreenOperations";
import WinePhenologicalStages from "../Components/WinePhenologicalStages";
import WinePhenologicalStagesByGrapes from "../Components/WinePhenologicalStagesByGrapes";
import theme from "../../globalTheme";
import { styles } from "@material-ui/pickers/views/Calendar/Calendar";
import { useFrontVintageViticultureQuery } from "../../generated/graphql";
import { useWindowDimensions } from "../../helpers/helpers";
import Loading from "../../Components/Loader/Loading";
import WinePhenologicalStagesMobile from "../Components/WinePhenologicalStagesMobile";
import { t, Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    wineViticulture__wrapper: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        gap: "16px",
        [theme.breakpoints.only("xs")]: {
            display: "flex",
            flexWrap: "wrap",
            gap: "32px",
        },
    },
    wineViticulture__InGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        gap: "16px",
        gridColumn: "auto / span 12",
        [theme.breakpoints.only("xs")]: {
            display: "flex",
            flexWrap: "wrap",
            gap: "32px",
            width: "100%",
        },
    },
    wineViticulture__soil: {
        backgroundColor: theme.palette.common.white,
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 12",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 6",
        },
    },
    wineViticulture__greenOperations: {
        backgroundColor: theme.palette.common.white,
        [theme.breakpoints.only("xs")]: {
            width: "100%",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 12",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 6",
        },
    },
    wineViticulture__subSoil: {
        backgroundColor: theme.palette.common.white,
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 12",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 6",
        },
    },
    wineViticulture__simplePhenologicalStages: {
        backgroundColor: theme.palette.common.white,
        [theme.breakpoints.only("xs")]: {
            width: "inherit",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 12",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 6",
        },
    },
    wineViticulture__detailedPhenologicalStages: {
        backgroundColor: theme.palette.common.white,
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 12",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 12",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 6",
        },
    },
    wineViticulture__viticultureDetails: {
        backgroundColor: theme.palette.common.white,
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 12",
        },
    },
}));

const WineViticultureContainer = (props) => {
    const styles = useStyles(props);
    const { width } = useWindowDimensions();

    const { data, loading, error } = useFrontVintageViticultureQuery({
        variables: { vintageId: props.vintageId, locale: props.locale },
    });

    const viticulture = data?.FrontVintageViticulture;

    if (loading) {
        return (
            <Box display={"flex"} justifyContent={"center"} alignItems={"flex-end"}>
                <Loading loading={loading} />
            </Box>
        );
    }

    if (
        viticulture?.greenOperations?.length > 0 ||
        viticulture?.simplePhenologicalStages?.length > 0 ||
        viticulture?.detailedPhenologicalStages?.length > 0 ||
        viticulture?.soil?.length > 0 ||
        viticulture?.subsoil?.length > 0 ||
        viticulture?.viticultureDetails
    ) {
        return (
            <>
                <Box className={styles.wineViticulture__wrapper}>
                    {(viticulture?.soil?.length > 0 || viticulture?.subsoil?.length > 0) && (
                        <Box className={styles.wineViticulture__InGrid}>
                            {viticulture?.soil?.length > 0 ? (
                                <Box className={styles.wineViticulture__soil}>
                                    <WineSoils soilsType={viticulture?.soil} title={t`Sol`} />
                                </Box>
                            ) : (
                                ""
                            )}
                            {viticulture?.subsoil?.length > 0 ? (
                                <Box className={styles.wineViticulture__subSoil}>
                                    <WineSoils soilsType={viticulture?.subsoil} title={t`Sous-sol`} subsoils />
                                </Box>
                            ) : (
                                ""
                            )}
                        </Box>
                    )}
                    {(viticulture?.greenOperations?.length > 0 ||
                        viticulture?.simplePhenologicalStages?.length > 0 ||
                        viticulture?.detailedPhenologicalStages?.length > 0) && (
                        <Box className={styles.wineViticulture__InGrid}>
                            {/* GREEN OPERATIONS */}
                            {viticulture?.greenOperations?.length > 0 && (
                                <Box className={styles.wineViticulture__greenOperations}>
                                    <WineGreenOperations
                                        greenOperations={viticulture?.greenOperations}
                                        displayALign={width < 960 ? "left" : "alternate"}
                                    />
                                </Box>
                            )}
                            {/* SIMPLE PHENOLOGICAL STAGES */}
                            {viticulture?.simplePhenologicalStages?.length > 0 && (
                                <Box className={styles.wineViticulture__simplePhenologicalStages}>
                                    {width >= 600 ? (
                                        <WinePhenologicalStages
                                            phenologicalStages={viticulture?.simplePhenologicalStages}
                                        />
                                    ) : (
                                        <WinePhenologicalStagesMobile
                                            phenologicalStages={viticulture?.simplePhenologicalStages}
                                        />
                                    )}
                                </Box>
                            )}
                            {/* DETAILED PHENOLOGICAL STAGES */}
                            {viticulture?.detailedPhenologicalStages?.length > 0 && (
                                <Box className={styles.wineViticulture__detailedPhenologicalStages}>
                                    <WinePhenologicalStagesByGrapes
                                        phenologicalStagesByGrapes={viticulture?.detailedPhenologicalStages}
                                    />
                                </Box>
                            )}
                        </Box>
                    )}
                    {viticulture?.viticultureDetails && (
                        <Box className={styles.wineViticulture__InGrid}>
                            {viticulture?.viticultureDetails && viticulture?.showDefaultViticultureDetails && (
                                <Box className={styles.wineViticulture__viticultureDetails}>
                                    <WineHarvestDescription
                                        description={viticulture?.viticultureDetails}
                                        year={props.year}
                                        wineName={props.wineName}
                                        cuvee={props.cuvee}
                                    />
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
            </>
        );
    } else {
        return (
            <Box className={styles.mainContainer}>
                {/* En att texte substitution display AOC */}
                <Typography style={{ fontSize: "16px" }}></Typography>
            </Box>
        );
    }
};

WineViticultureContainer.propTypes = {
    vintageId: PropTypes.string,
    aoc: PropTypes.string,
    wineName: PropTypes.string,
    year: PropTypes.any,
    cuvee: PropTypes.any,
};

export default WineViticultureContainer;
