import React, { useState } from "react";
import { PropTypes } from "prop-types";
import Lightbox from "react-image-lightbox";
import { mod, parseHtml } from "../../helpers/helpers";
import theme from "../../globalTheme";

import { Box, makeStyles, Typography } from "@material-ui/core";

import subPropImg1 from "../../assets/propriétéPage/propriété_01-féret.png";
import subPropImg2 from "../../assets/propriétéPage/propriété_02_féret.png";

const useStyles = makeStyles(() => ({
    smallGallery__wrapper: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        gap: "16px",
    },
    smallGallery__mainImg: (props) => ({
        overflow: "hidden",
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 12",
            height: "auto",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: props.imageUrls.length > 2 ? "auto / span 8" : "auto / span 6",
            height: "auto",
        },
    }),
    smallGallery__mainImg__image: {
        borderRadius: "2px",
        objectFit: "cover",
        height: "100%",
        cursor: "pointer",
        width: "100%",
    },
    smallGallery__secondaryImg: (props) => ({
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 12",
            flexDirection: "row",
            gap: "16px",
            flexWrap: "nowrap",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: props.imageUrls.length > 2 ? "auto / span 4" : "auto / span 6",
            flexDirection: "column",
            gap: 0,
            flexWrap: "wrap",
        },
    }),
    smallGallery__secondaryImg__wrapper: {
        position: "relative",
        [theme.breakpoints.up("sm")]: {
            width: "50%",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 12",
            gridRow: "auto",
            width: "auto",
        },
    },
    smallGallery__secondaryImg__wrapper__image: {
        borderRadius: "2px",
        height: "100%",
        width: "100%",
        cursor: "pointer",
    },
    moreImgSmallGallery__wrapper: {
        position: "absolute",
        width: "calc(100% - 1px)",
        height: "calc(100% - 1px)",
        zIndex: 10,
        backgroundColor: "rgba(0,0,0,0.5)",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "2px",
    },
}));

const SmallGallery = (props) => {
    const styles = useStyles(props);

    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [allImagesIndex, setAllImagesIndex] = useState(0);
    let filteredImagesUrls = [];

    let captions =
        props.imageUrls && props.imageUrls?.length !== 0
            ? [...props.imageUrls]?.map((el) => {
                if (el?.caption) {
                    return el?.caption;
                } else {
                    return "";
                }
            })
            : [];
    if (props.front) {
        props.imageUrls?.map((el) => {
            filteredImagesUrls?.push(el.twicpicURL);
        });
    } else {
        filteredImagesUrls = [...props.imageUrls];
    }

    const allImages = [...(filteredImagesUrls || [])];

    if (props.mainImageUrl) {
        allImages.unshift(props.mainImageUrl);
    }

    return (
        <Box className={styles.smallGallery__wrapper}>
            {lightboxIsOpen && (
                <Lightbox
                    imagePadding={100}
                    mainSrc={allImages.length && allImages[allImagesIndex]}
                    onCloseRequest={() => {
                        setLightboxIsOpen(false);
                    }}
                    nextSrc={allImages[mod(allImagesIndex + 1, allImages?.length)]}
                    prevSrc={allImages[mod(allImagesIndex - 1, allImages?.length)]}
                    onMoveNextRequest={() => setAllImagesIndex(mod(allImagesIndex + 1, allImages?.length))}
                    onMovePrevRequest={() => setAllImagesIndex(mod(allImagesIndex - 1, allImages?.length))}
                    imageCaption={parseHtml(captions[allImagesIndex])}
                />
            )}
            {/* MAIN IMG */}
            <Box className={styles.smallGallery__mainImg}>
                <img
                    src={allImages[0] || subPropImg1}
                    className={styles.smallGallery__mainImg__image}
                    onClick={() => {
                        setLightboxIsOpen(true);
                        setAllImagesIndex(0);
                    }}
                />
            </Box>

            {/* SECONDARY IMG */}
            <Box className={styles.smallGallery__secondaryImg}>
                {props.imageUrls.length <= 1 ? (
                    <Box className={styles.smallGallery__secondaryImg__wrapper}>
                        <img src={subPropImg2} className={styles.smallGallery__secondaryImg__wrapper__image} />
                    </Box>
                ) : null}
                {props.imageUrls.map((image, i) => {
                    if (i === 0) {
                        return null;
                    } else if (i === 1) {
                        return (
                            <Box className={styles.smallGallery__secondaryImg__wrapper}>
                                <img
                                    src={image?.twicpicURL}
                                    className={styles.smallGallery__secondaryImg__wrapper__image}
                                    onClick={() => {
                                        setLightboxIsOpen(true);
                                        setAllImagesIndex(i);
                                    }}
                                />
                            </Box>
                        );
                    } else if (i === 2) {
                        if (props.imageUrls.length > 2) {
                            return (
                                <Box className={styles.smallGallery__secondaryImg__wrapper}>
                                    {props.imageUrls?.length > 3 && (
                                        <Box
                                            onClick={() => {
                                                setLightboxIsOpen(true);
                                                setAllImagesIndex(i);
                                            }}
                                            className={styles.moreImgSmallGallery__wrapper}
                                        >
                                            <Typography
                                                variant="h6"
                                                style={{
                                                    fontSize: "38px",
                                                    color: "white",
                                                    textAlign: "center",
                                                    fontWieght: "bold",
                                                }}
                                            >
                                                +{props.imageUrls?.length - 3}
                                            </Typography>
                                        </Box>
                                    )}
                                    <img
                                        src={image?.twicpicURL}
                                        className={styles.smallGallery__secondaryImg__wrapper__image}
                                        onClick={() => {
                                            setLightboxIsOpen(true);
                                            setAllImagesIndex(i);
                                        }}
                                    />
                                </Box>
                            );
                        } else {
                            return (
                                <Box className={styles.smallGallery__secondaryImg__wrapper}>
                                    <Box
                                        onClick={() => {
                                            setLightboxIsOpen(true);
                                            setAllImagesIndex(i);
                                        }}
                                        className={styles.smallGallery__secondaryImg__wrapper__more}
                                    >
                                        <Typography
                                            variant="h6"
                                            style={styles.smallGallery__secondaryImg__wrapper__more__type}
                                        >
                                            +{filteredImagesUrls?.length - 3}
                                        </Typography>
                                    </Box>
                                    <img
                                        src={image?.twicpicURL}
                                        className={styles.smallGallery__secondaryImg__wrapper__image}
                                        onClick={() => {
                                            setLightboxIsOpen(true);
                                            setAllImagesIndex(i);
                                        }}
                                    />
                                </Box>
                            );
                        }
                    } else if (i > 2) {
                        return null;
                    }
                })}
            </Box>
        </Box>
    );
};
SmallGallery.propTypes = {
    mainImageUrl: PropTypes.string,
    imageUrls: PropTypes.arrayOf(PropTypes.string),
    front: PropTypes.bool,
};

export default SmallGallery;
