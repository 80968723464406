// Settings:
import { makeStyles } from "@material-ui/styles";
import theme from "../../globalTheme";
import { useLocation } from "react-router-dom";

// Components:
import { Box, Typography } from "@material-ui/core";
import Logo404 from "../../assets/ErrorPage/404.svg";
import bigFioTopLeft from "../../assets/ErrorPage/big_top_left.svg";
import fioBottomLeft from "../../assets/ErrorPage/bottom_left.svg";
import fioBottomRight from "../../assets/ErrorPage/bottom_right.svg";
import mobileFio from "../../assets/ErrorPage/mobile_fio.svg";
import fioTopLeft from "../../assets/ErrorPage/top_left.svg";

// Styles:
const useStyles = makeStyles(() => ({
    containerError__wrapper: {
        minHeight: "46rem",
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        height: "100%",
        justifyContent: "center",
        overflow: "hidden",
        position: "relative",
        width: "100%",
        borderRadius: "10px",
        backgroundColor: theme.palette.common.white,
        backgroundRepeat: "no-repeat",
        [theme.breakpoints.up("xs")]: {
            backgroundImage: `url(${mobileFio})`,
            backgroundSize: "100%",
            backgroundPosition: "left 30px",
        },
        [theme.breakpoints.up("sm")]: {
            backgroundImage: `url(${bigFioTopLeft})`,
            backgroundSize: "auto",

            backgroundPosition: "top -70px left -365px",
        },
        [theme.breakpoints.up("md")]: {
            backgroundPosition: "right -300px top -70px",
        },
        [theme.breakpoints.up("lg")]: {
            backgroundImage: `url(${fioTopLeft}), url(${bigFioTopLeft}), url(${fioBottomLeft}), url(${fioBottomRight})`,
            backgroundPosition:
                "top -65px left -55px, top -50px right 33px, bottom -45px left -70px, bottom -40px right -15px ",
        },
        [theme.breakpoints.up("xl")]: {
            backgroundPosition: "-65px -50px, -165px -40px, -80px bottom, bottom right",
        },
    },
    main_content: {
        display: "flex",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    container_texts: {
        [theme.breakpoints.up("xs")]: {
            width: "auto",
            margin: "0 0.5rem",
            textAlign: "center",
        },
        [theme.breakpoints.up("sm")]: {
            textAlign: "left",
            width: "392px",
            margin: 0,
        },
        [theme.breakpoints.up("md")]: {
            width: "auto",
        },
    },
    main_title: {
        color: theme.palette.primary.dark,
        [theme.breakpoints.up("xs")]: {
            textAlign: "center",
        },
        [theme.breakpoints.up("sm")]: {
            textAlign: "left",
        },
    },
    logo_404: {
        [theme.breakpoints.up("xs")]: {
            width: "80vw",
        },
        [theme.breakpoints.up("sm")]: {
            width: "445px",
        },
        [theme.breakpoints.up("md")]: {
            width: "85%",
        },
        [theme.breakpoints.up("lg")]: {
            width: "800px",
        },
        [theme.breakpoints.up("xl")]: {
            width: "1000px",
        },
    },
}));

const NotFoundPage = (props) => {
    const styles = useStyles();
    const location = useLocation();

    return (
        <Box className={styles.containerError__wrapper}>
            <Box className={styles.main_content}>
                <img src={Logo404} className={styles.logo_404} />
                <Box className={styles.container_texts}>
                    <Typography variant="h2" className={styles.main_title}>
                        Oups cette page <span style={{ color: theme.palette.wine.main }}>n’existe pas</span> !
                    </Typography>
                    <Typography variant="h5">
                        Nous sommes désolés, la page que vous cherchez ne semble pas disponible.
                    </Typography>
                    <Typography variant="h5" style={{ fontFamily: "Mansalva", marginTop: "1rem" }}>
                        Si vous avez besoin d&apos;aide contactez-nous{" "}
                        <a
                            href={`mailto:support@feret.com?subject=Erreur 404 : ${location?.pathname}`}
                            style={{ cursor: "pointer", color: theme.palette.wine.main }}
                        >
                            support@feret.com
                        </a>
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default NotFoundPage;
