import { t } from "@lingui/macro";
import { Box, makeStyles, Typography } from "@material-ui/core";
import Page404 from "Components/ErrorPage/Page404";
import Page500Front from "Components/ErrorPage/Page500Front";
import Helmet from "Components/Helmet/Helmet";
import LoadingWithImage from "Components/Loader/LoadingWithImage";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import bg from "../assets/ranking-bg.jpg";
import fioriture1 from "../assets/rankingSummary/fioriture1.svg";
import fioriture3 from "../assets/rankingSummary/fioriture3.svg";
import SocialMediaIcons from "../Components/Icons/SocialMediaIcons";
import ExpandableFormattedTextBox from "../Components/Texts/ExpandableFormattedTextBox";
import FrontContentTop from "../Front/FrontContentTop";
import FrontMainContainer from "../Front/FrontMainContainer";
import FrontTitle from "../Front/FrontTitle";
import EstateTitleSemiUnderlined from "../FrontEstate/Components/EstateTitledSemiUnderlined";
import { RankingList } from "../FrontRanking/RankingList";
import RankingsTabs from "../FrontRanking/RankingsTabs";
import RankingSummary from "../FrontRanking/RankingSummary";
import { useGetClassificationOrganismFrontQuery } from "../generated/graphql";
import theme from "../globalTheme";
import {
    formatAddress,
    formatPerson,
    getTextLength,
    isEmptyObject,
    isNotEmpty,
    isNull,
    parseHtml,
    useWindowDimensions,
} from "../helpers/helpers";

const useStyles = makeStyles(() => ({
    container_card: {
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
        },
    },
    container_content_top: {
        display: "grid",
        gridTemplateColumns: "1fr",

        [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "83.33333% 16.66666%",
        },
    },
    image_container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "1.1rem",
        marginBottom: "1.1rem",
        width: "50%",

        [theme.breakpoints.down("sm")]: {
            marginLeft: "auto",
            marginRight: "auto",
        },

        [theme.breakpoints.up("sm")]: {
            marginLeft: "1rem",
            width: "50%",
        },
        [theme.breakpoints.up("lg")]: {
            marginLeft: "0px",
            maxWidth: "373px",
            width: "100%",
        },
    },
    image_badge: {
        width: "100%",
        height: "auto",
        [theme.breakpoints.up("xs")]: {
            maxWidth: "none",
        },
        [theme.breakpoints.up("sm")]: {
            maxWidth: "197px",
        },
    },
    socialMedia: {
        order: 1,
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: "15px",

        [theme.breakpoints.up("lg")]: {
            order: 2,
        },

        [theme.breakpoints.up("xl")]: {
            right: 64,
            top: 42,
        },
    },
    grid_item_text: {
        display: "grid",
        gridTemplateColumns: "1fr",
        gap: "16px 24px",
        alignItems: "flex-start",

        [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "67% 33%",
        },
    },
    container_title_description: {
        order: 2,

        [theme.breakpoints.up("lg")]: {
            order: 1,
        },
    },
    container_infos_right: {
        display: "grid",
        gridTemplateColumns: "1fr",
        justifyContent: "flex-start",

        [theme.breakpoints.up("sm")]: {
            gridTemplateColumns: "67% 33%",
        },

        [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
        },

        [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "1fr",
            justifyContent: "flex-start",
        },
    },
    center_responsive: {
        [theme.breakpoints.down("sm")]: {
            marginLeft: 0,
            marginRight: "auto",
        },
    },
    history_text: {
        color: theme.palette.primary.dark,

        "& *": {
            textAlign: "justify",
        },

        "& p p": {
            marginBottom: 0,
            marginTop: 0,
        },
    },
}));

const RankingPage = () => {
    const styles = useStyles();
    const { width } = useWindowDimensions();
    const locale = useSelector((state) => state?.user?.locale || "fr");
    const [tabActive, setTabActive] = useState(0);

    const { name } = useParams();

    const {
        data: { GetClassificationOrganismFront: classificationOrganim } = {},
        loading,
        error: error,
    } = useGetClassificationOrganismFrontQuery({
        variables: { name, locale },
    });

    const [currentOrganism, setCurrentOrganism] = useState(null);

    useEffect(() => {
        if (classificationOrganim) {
            const { organism, president, director, organisation } = classificationOrganim;
            const contacts = [
                { title: organisation, value: organism },
                { title: t`Président`, value: president },
                { title: t`Directeur`, value: director },
            ]
                .map(({ title, value }) =>
                    isNull(value) || isEmptyObject(value)
                        ? null
                        : {
                              ...value,
                              title,
                              name: formatPerson(value),
                              address: formatAddress(value?.address),
                          }
                )
                .filter(Boolean);
            const newOrganism = {
                ...classificationOrganim,
                contacts,
            };
            setCurrentOrganism(newOrganism);
        }
    }, [classificationOrganim]);

    const breadcrumbs = [
        {
            name: t`Classements de Bordeaux`,
            path: "/les-classements-de-bordeaux",
        },
        {
            name: currentOrganism?.name,
        },
    ];

    if (error) {
        return <Page500Front />;
    }

    if (loading) {
        return <LoadingWithImage />;
    }

    if (!currentOrganism) {
        return <Page404 />;
    }

    return (
        <>
            <Helmet
                title={currentOrganism.name}
                content={`Explorez le ${currentOrganism.name}, l'un des plus célèbres classements de vins au monde, et découvrez les propriétés viticoles distinguées ainsi que les caractéristiques de leurs vins. Découvrez l'histoire et les secrets de ce classement légendaire à travers Bordeaux et ses Vins.`}
            />

            <FrontContentTop
                backgroundImage={bg}
                links={breadcrumbs}
                previousPage={{
                    name: t`Tous les classements`,
                    path: "/les-classements-de-bordeaux",
                }}
            >
                <FrontTitle title={currentOrganism.name} />
                <FrontMainContainer
                    styleCardContent
                    style={{
                        backgroundImage: `url(${fioriture1}), url(${fioriture3})`,
                        backgroundPositionY: "bottom, center",
                        backgroundPositionX: "right, right",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    <Box className={styles.container_card}>
                        <Box className={styles.container_content_top}>
                            <Box className={styles.container_title_description}>
                                {isNotEmpty(currentOrganism?.history) && (
                                    <EstateTitleSemiUnderlined
                                        title={t`Histoire du classement`}
                                        heightAfter={32}
                                        style={{ marginTop: "0px" }}
                                    />
                                )}
                            </Box>
                            <Box className={styles.socialMedia}>
                                <SocialMediaIcons socialMedias={currentOrganism?.socialMedias} />
                            </Box>
                        </Box>

                        <Box className={styles.grid_item_text}>
                            <Box>
                                {isNotEmpty(currentOrganism?.history) && (
                                    <Typography component="div" variant="body2" className={styles.history_text}>
                                        {getTextLength(currentOrganism?.history) > 1690 ? (
                                            <ExpandableFormattedTextBox
                                                value={currentOrganism?.history}
                                                truncatedValue={`${currentOrganism?.history?.slice(0, 1690)}...`}
                                            />
                                        ) : (
                                            parseHtml(currentOrganism?.history, "body2")
                                        )}
                                    </Typography>
                                )}
                            </Box>

                            <Box className={styles.container_infos_right}>
                                {[
                                    currentOrganism.lastClassification,
                                    currentOrganism.nextClassification,
                                    currentOrganism.organisation,
                                    currentOrganism.classificationLink,
                                ].some(Boolean) && (
                                    <Box className={styles.center_responsive}>
                                        <RankingSummary
                                            lastRanking={currentOrganism.lastClassification}
                                            nextRanking={currentOrganism.nextClassification}
                                            organisation={currentOrganism.organisation}
                                            maxWidth="373px"
                                            classement={currentOrganism.classificationLink}
                                            setTabActive={() => {
                                                setTabActive(1);
                                                window.location.hash = "tabs";
                                            }}
                                        />
                                    </Box>
                                )}

                                {currentOrganism?.logo?.data && (
                                    <Box
                                        className={styles.image_container}
                                        style={{
                                            display: width >= 500 ? "flex" : "block",
                                        }}
                                    >
                                        <img
                                            src={currentOrganism.logo.twicpicURL}
                                            className={styles.image_badge}
                                            alt="badge"
                                        />
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Box>

                    <Box style={{ marginBottom: "16px" }} />
                    <Box id="tabs">
                        <RankingsTabs organism={currentOrganism} active={tabActive} setActive={setTabActive} />
                    </Box>
                </FrontMainContainer>
            </FrontContentTop>
            <RankingList />
        </>
    );
};

export default RankingPage;
