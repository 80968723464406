import { Box, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

import theme from "../../globalTheme";
import { allProperCase, getHectares } from "../../helpers/helpers";

const GrapesTables = (props) => {
    const getFormattedEntries = (entries) => {
        const arr = [];
        if (!entries) {
            return arr;
        }

        // const sortable = Object.fromEntries(
        //     Object.entries(entries).sort(([,a],[,b]) => a-b).reverse()
        // );
        for (let i = 0; i < entries.length; i++) {
            const entry = entries[i]?.cepage;
            const hectares = entries[i]?.superficie;
        
            arr.push(
                <Box
                    style={{
                        width: props.width ? props.width : "300px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: "5px",
                        backgroundColor:
                            i % 2
                                ? "white"
                                : theme.palette.dashboard.background,
                    }}
                >
                    <Typography
                        variant="h6"
                        style={{
                            color: props.hidden ? theme.palette.secondary.dark : theme.palette.primary.main,
                            fontWeight: "bold",
                            padding: "0 5%",
                        }}
                    >
                        {allProperCase(entry)}
                    </Typography>
                    <Typography
                        variant="h6"
                        style={{
                            color: props.hidden ? theme.palette.secondary.dark : theme.palette.primary.main,
                            fontWeight: "bold",
                            padding: "0 5%",
                        }}
                    >
                        {getHectares(hectares, props.locale)}
                    </Typography>
                </Box>
            );
        }
        return arr;
    };

    return getFormattedEntries(props.grapes);
};

GrapesTables.propTypes = {
    grapes: PropTypes.object,
    hidden: PropTypes.bool,
    locale: PropTypes.string
};

export default GrapesTables;