import PropTypes, { object } from "prop-types";
import { Box, Card, CardContent, Typography, makeStyles } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import theme from "../../globalTheme";
import cart from "../../assets/search/cart.svg";
import people from "../../assets/search/people.svg";
import wineBlanc from "../../assets/search/wine-blanc.svg";
import wineRose from "../../assets/search/wine-rose.svg";
import wineRouge from "../../assets/search/wine-rouge.svg";
import badge from "../../assets/search/badge.svg";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import Tooltip from "../../Components/Tooltips/Tooltip";
import dfltImg from "../../assets/searchEngine/estate/thumbs/estate-3.jpg";

import { useGetWineEntityCardInformationQuery } from "../../generated/graphql";
import Loading from "../../Components/Loader/Loading";
import { urlFormatted, useWindowDimensions } from "../../helpers/helpers";
import { t, Trans } from "@lingui/macro";
import { useCustomHistory } from "hooks";
import { Link } from "react-router-dom";

const useStyles = makeStyles((e) => ({
    root: (props) => ({
        background: theme.palette.common.white,
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.08)",
        borderRadius: 10,
        marginBottom: props?.marginBottom ?? "1rem",
        cursor: "pointer",
        minHeight: 297,
        textDecoration: "none",
        [theme.breakpoints.up("md")]: {
            height: "auto !important",
        },

        [theme.breakpoints.up("lg")]: {
            height: "297px !important",
        },
    }),
    cardContent: {
        padding: "0",
    },
    contentTop: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.palette.common.white,
        width: "281",
        height: "188",
        borderRadius: 10,
    },
    status: {
        boxSizing: "border-box",
        position: "absolute",
        bottom: 0,
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        width: "100%",
        padding: "4px 8px",
    },
    missing: {
        position: "absolute",
        top: 3,
        right: 7,
        background: theme.palette.primary.light,
        borderRadius: 16,
        padding: "5px 16px",
        dislay: "flex",
        flexDirection: "row",
    },
    missingTypo: {
        color: theme.palette.common.white,
    },
    missingTypoStrike: {
        color: theme.palette.common.white,
        textDecoration: "line-through",
    },
    isOpen: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    isOpenText: {
        color: theme.palette.common.white,
        fontWeight: 400,
        marginLeft: 4,
    },
    statusIcons: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusIconContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.primary.light,
        width: 20,
        height: 20,
        marginLeft: 4,
        border: "none",
        borderRadius: 2,
    },
    ContentPrimary: {
        display: "flex",
        flexDirection: "column",
        margin: "6px 16px",
        // height: 120,
    },
    title: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        minHeight: 50,
        height: 50,
        color: theme.palette.primary.dark,
    },
    classificationContent: {
        color: theme.palette.primary.light,
        fontWeight: 400,
        height: 22,
    },
    appellationContent: {
        color: theme.palette.primary.dark,
        fontWeight: 400,
    },
    contentBottom: {
        display: "flex",
        justifyContent: "space-between",
        padding: "0 16px",
    },
    iconWine: {
        // width: "7.5px !important",
        height: "15px !important",
        marginRight: 1,
    },
    link: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
    },
    viewMore: {
        color: theme.palette.secondary.dark,

        "&:hover": {
            textDecoration: "underline",
        },
    },
    viewMoreArrow: {
        color: theme.palette.secondary.dark,
        marginLeft: 5,
        fontSize: "16px",
    },
}));

const SearchResultEstateCard = (props) => {
    const styles = useStyles();
    const { width } = useWindowDimensions();
    const history = useCustomHistory();
    const { data, loading } = useGetWineEntityCardInformationQuery({
        variables: {
            wineEntityId: props.wineEntityId,
        },
    });

    if (loading) {
        return <Loading />;
    }

    const cardInformation = data?.GetWineEntityCardInformation;

    const getLimitName = () => {
        if (props?.cardWidthFixed) {
            return props?.cardWidthFixed;
        } else {
            if (width > 1280) {
                return 54;
            }

            if (width >= 960 && width < 1280) {
                return 70;
            }

            if (width >= 600 && width < 960) {
                return 70;
            }

            if (width >= 500 && width < 600) {
                return 105;
            }

            if (width < 500) {
                return 125;
            }
        }
    };

    const getLimitNameClassification = () => {
        if (props?.cardWidthFixed) {
            return props?.cardWidthFixed;
        }
        if (width > 1280) {
            return 35;
        }

        if (width >= 960 && width < 1280) {
            return 70;
        }

        if (width >= 600 && width < 960) {
            return 70;
        }

        if (width >= 500 && width < 600) {
            return 105;
        }

        // width < 500
        return 125;
    };

    return (
        <Card
            component={Link}
            className={styles.root}
            style={{ width: props.width ?? "auto", height: props.height ?? "auto" }}
            to={history.addLocale(`/${urlFormatted(props.estate?.wineEntityShortId, props.estate?.wineEntityName)}`)}
        >
            <CardContent className={styles.cardContent}>
                <Box className={styles.contentTop}>
                    <img
                        src={cardInformation?.img?.twicpicURL ? cardInformation?.img?.twicpicURL : dfltImg}
                        style={{ width: "100%" }}
                        alt="estate photo"
                    />

                    {props?.missing && (
                        <Box className={styles.missing}>
                            <Typography display="inline" variant="h6" className={styles.missingTypo}>
                                <Trans>Manquant</Trans> :{" "}
                            </Typography>
                            <Typography display="inline" variant="h6" className={styles.missingTypoStrike}>
                                {props?.missing}
                            </Typography>
                        </Box>
                    )}

                    {(cardInformation?.hasProductPageUrl ||
                        cardInformation?.hasEnotourismActivity ||
                        cardInformation?.isOpenNow) && (
                        <Box className={styles.status}>
                            {cardInformation?.isOpenNow && (
                                <Box className={styles.isOpen}>
                                    <Brightness1Icon
                                        style={{
                                            color:
                                                cardInformation?.isOpenNow === "open"
                                                    ? theme.palette.success.dark
                                                    : theme.palette.error.dark,
                                            fontSize: "5px",
                                        }}
                                    />
                                    <Typography variant="h6" className={styles.isOpenText}>
                                        {cardInformation?.isOpenNow === "open"
                                            ? t`Ouvert actuellement`
                                            : t`Fermé actuellement`}
                                    </Typography>
                                </Box>
                            )}

                            <Box className={styles.statusIcons} style={{ marginLeft: "auto" }}>
                                {cardInformation?.hasProductPageUrl && (
                                    <Tooltip color="primary" title={t`Vente en ligne`} arrow>
                                        <button className={styles.statusIconContainer} onClick={() => {}}>
                                            <img
                                                src={cart}
                                                styles={{ width: 10, height: 10 }}
                                                alt="icon vente en ligne"
                                            />
                                        </button>
                                    </Tooltip>
                                )}
                                {cardInformation?.hasEnotourismActivity && (
                                    <Tooltip color="primary" title={t`Activités œnotouristiques`} arrow>
                                        <button className={styles.statusIconContainer} onClick={() => {}}>
                                            <img
                                                src={people}
                                                styles={{ width: 12, height: 12 }}
                                                alt={t`Activités œnotouristiques`}
                                            />
                                        </button>
                                    </Tooltip>
                                )}
                            </Box>
                        </Box>
                    )}
                </Box>

                <Box className={styles.ContentPrimary}>
                    <Box className={styles.title}>
                        {props.name.length >= getLimitName() ? (
                            <>
                                <Tooltip color="primary" title={props?.name} arrow>
                                    <Typography
                                        variant="h4"
                                        className={styles.name}
                                        style={{
                                            display: "inline-flex",
                                            cursor: "pointer",
                                            marginTop: 0,
                                            marginBottom: 0,
                                            color: theme.palette.primary.dark,
                                        }}
                                    >
                                        {props.name.slice(0, getLimitName())}...
                                    </Typography>
                                </Tooltip>

                                <img
                                    src={badge}
                                    alt="badge Feret"
                                    style={{ width: "13px", height: "13px", display: "inline" }}
                                />
                            </>
                        ) : (
                            <Typography variant="h4" className={styles.name}>
                                {props?.name}

                                <img
                                    src={badge}
                                    alt="badge Feret"
                                    style={{ width: "13px", height: "13px", display: "inline" }}
                                />
                            </Typography>
                        )}
                    </Box>

                    {cardInformation?.classifications?.length > 0 ? (
                        cardInformation?.classifications[0].length >= getLimitNameClassification() ? (
                            <Typography variant="h6" className={styles.classificationContent}>
                                <Tooltip color="primary" title={cardInformation?.classifications[0]} arrow>
                                    <Typography
                                        variant="h6"
                                        className={styles.classificationContent}
                                        style={{
                                            display: "inline-flex",
                                            cursor: "pointer",
                                            marginTop: 0,
                                            marginBottom: 0,
                                        }}
                                    >
                                        {cardInformation?.classifications[0].slice(0, getLimitNameClassification())}
                                        ...
                                    </Typography>
                                </Tooltip>
                            </Typography>
                        ) : (
                            <Typography variant="h6" className={styles.classificationContent}>
                                {cardInformation?.classifications[0]}
                            </Typography>
                        )
                    ) : (
                        <Box style={{ minHeight: 22, height: 22 }}></Box>
                    )}

                    <Box style={{ display: "flex", alignItems: "center" }}>
                        {props?.appellation && (
                            <Typography variant="h6" className={styles.appellationContent}>
                                {props?.appellation}
                            </Typography>
                        )}
                        {cardInformation?.surfaceArea > 0 && (
                            <Typography variant="h6" className={styles.appellationContent}>
                                <Brightness1Icon
                                    style={{
                                        color: theme.palette.primary.light,
                                        fontSize: "6px",
                                        padding: "0 5px",
                                    }}
                                />
                                <Trans>{cardInformation?.surfaceArea} ha</Trans>
                            </Typography>
                        )}
                    </Box>
                </Box>

                <Box className={styles.contentBottom}>
                    <Box style={{ display: "flex", justifyContent: "center" }}>
                        {cardInformation?.wineColors?.includes("Rouge") && (
                            <img src={wineRouge} className={styles.iconWine} alt="vin rouge" />
                        )}
                        {cardInformation?.wineColors?.includes("Rosé") && (
                            <img src={wineRose} className={styles.iconWine} alt="vin rosé" />
                        )}
                        {cardInformation?.wineColors?.includes("Blanc") && (
                            <img src={wineBlanc} className={styles.iconWine} alt="vin blanc" />
                        )}
                    </Box>

                    <Box className={styles.link}>
                        <Typography variant="subtitle1" className={styles.viewMore}>
                            <Trans>Découvrir</Trans>
                        </Typography>
                        <ArrowForwardIcon className={styles.viewMoreArrow} />
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

SearchResultEstateCard.propTypes = {
    estate: object,
    wineEntityId: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
    classification: PropTypes.string,
    appellation: PropTypes.string,
    badge: PropTypes.string,
    ha: PropTypes.number,
    wines: PropTypes.object,
    isOpen: PropTypes.bool,
    onlineSales: PropTypes.bool,
    className: PropTypes.object,
    width: PropTypes.number,
    height: PropTypes.number,
    marginBottom: PropTypes.number,
    missing: PropTypes.string,
    cardWidthFixed: PropTypes.number,
};

export default SearchResultEstateCard;
