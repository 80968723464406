import { t } from "@lingui/macro";
import { Box, Link, makeStyles } from "@material-ui/core";
import { useCustomHistory } from "hooks";
import { any, array, bool, func, number, string } from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import cardBgWine from "../../../assets/propriétéPage/fio_bg_3.svg";
import Arrow from "../../../Components/Arrow/Arrow";
import Loading from "../../../Components/Loader/Loading";
import EstateTitledSemiUnderlined from "../../../FrontEstate/Components/EstateTitledSemiUnderlined";
import { useFrontWineListQuery } from "../../../generated/graphql";
import theme from "../../../globalTheme";
import getConnectors, { isNull, urlFormatted } from "../../../helpers/helpers";
import BigWineCard from "./BigWineCard";
import MediumWineCard from "./MediumWineCard";

const useStyles = makeStyles(() => ({
    container: (props) => ({
        boxShadow: "0px 4px 76px 4px rgba(0, 0, 0, 0.08)",

        backgroundColor: props.whiteVersion ? "white" : "transparent",
        [theme.breakpoints.down("xs")]: {
            padding: "14px",
            gridGap: "16px",
            backgroundImage: "none",
        },
        [theme.breakpoints.up("sm")]: {
            padding: "40px 14px",
            gridGap: "32px",
        },
        [theme.breakpoints.up("md")]: {
            padding: "40px 34px",
            gridGap: "32px",
        },
        [theme.breakpoints.up("lg")]: {
            padding: "34px 40px",
            gridGap: "32px",
        },
        [theme.breakpoints.up("xl")]: {
            padding: "40px 90px",
        },
        border: "1px solid",
        borderColor: props.whiteVersion ? "white" : theme.palette.secondary.main,
        borderRadius: 20,
        backgroundImage: `url(${cardBgWine})`,
        backgroundPosition: "top right",
        backgroundRepeat: "no-repeat",
        gap: 24,
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        width: "auto",
    }),
    link: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "auto / span 12",
        },
    },
    container__title: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "auto / span 12",
        },
    },
    bigBlock: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "auto / span 12",
            gridRow: "auto/ span 3",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 12",
            gridRow: "auto/ span 2",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 6",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 6",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "auto / span 6",
        },
    },
    firstGridChildren: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "auto / span 12",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 12",
        },
        [theme.breakpoints.only("md")]: {
            gridColumn: "auto / span 6",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 6",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "auto / span 6",
        },
    },
}));

const WineCardsContainer = (props) => {
    const styles = useStyles(props);
    const [extended, setExtended] = useState(false);
    const location = useLocation();
    const winesListRef = useRef(null);
    const winesList = winesListRef?.current;
    const { wineEntityShortId, propertyName } = useParams();
    const history = useCustomHistory();
    const { data, loading, error } = useFrontWineListQuery({
        variables: {
            wineEntityId: props.wineEntityId,
            locale: props.locale,
            omitWineId: props.omitWineId,
        },
    });

    useEffect(() => {
        if (props.setWinesAreLoading) {
            props.setWinesAreLoading(loading);
        }
    }, [loading]);

    useEffect(() => {
        if (
            location.hash === "#vins" &&
            !loading &&
            !props.loadingEstate &&
            !props.loadingActivity &&
            !props.loadingBesv &&
            !props.loadingContact
        ) {
            if (winesList) {
                const rect = winesList.getBoundingClientRect();
                const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

                window.scroll({
                    top: rect.top + rect.height / 2 - viewHeight / 2,
                    behavior: "smooth",
                });
            }
        }
    }, [
        loading,
        props.loadingEstate,
        props.loadingActivity,
        props.loadingBesv,
        props.loadingContact,
        location.hash,
        winesList,
    ]);

    const getClassifications = (classifications) => {
        const arr = classifications.map((el, i) => el?.name);
        return getConnectors(arr);
    };

    const getWineUrl = (el) => {
        return history.addLocale(
            `/${urlFormatted(wineEntityShortId, propertyName)}/vins/${urlFormatted(el.shortWineId, el?.name)}`
        );
    };

    const firstWines = data?.FrontWineList?.firstWines?.map((el, i) => {
        if (props.limited && i >= 2 && !extended) {
            return null;
        }
        return (
            <Box className={styles.bigBlock} key={i}>
                <BigWineCard
                    wineEntityName={props.wineEntityName}
                    wineId
                    isOrphan={props.orphanEstate}
                    lastVintageId={el.lastVintageId}
                    name={el?.name}
                    rank={el?.rank}
                    wineColor={el?.color}
                    rankFr={el?.rankFr}
                    wineColorFr={el?.colorFr}
                    sweetness={el?.sweetness}
                    hasVintages={el?.hasVintages}
                    hasScores={el?.scored}
                    aoc={el?.appellation}
                    hasRewards={el?.rewarded}
                    wineRanking={
                        el?.classifications && el?.classifications?.length
                            ? getClassifications(el?.classifications)
                            : null
                    }
                    image={el?.bottleImage?.twicpicURL}
                    to={getWineUrl(el)}
                    shouldMentionSweetness={el?.shouldMentionSweetness}
                    shouldMentionColor={el?.shouldMentionColor}
                />
            </Box>
        );
    });

    const allOtherWines = data?.FrontWineList?.secondWines?.concat(data?.FrontWineList?.otherWines);

    const getMediumWineCard = (el, i) => {
        return (
            <Box className={styles.firstGridChildren}>
                {props.orphanEstate ? (
                    <BigWineCard
                        wineEntityName={props.wineEntityName}
                        wineId
                        isOrphan={props.orphanEstate}
                        key={i}
                        name={el?.name}
                        rank={el?.rank}
                        wineColor={el?.color}
                        rankFr={el?.rankFr}
                        wineColorFr={el?.colorFr}
                        sweetness={el?.sweetness}
                        hasVintages={el?.hasVintages}
                        hasScores={el?.scored}
                        aoc={el?.appellation}
                        wineRanking={el?.classifications?.length > 0 ? getClassifications(el?.classifications) : null}
                        hasRewards={el?.rewarded}
                        image={el?.bottleImage?.twicpicURL}
                        lastVintageId={el?.lastVintageId}
                        to={getWineUrl(el)}
                        shouldMentionSweetness={el?.shouldMentionSweetness}
                        shouldMentionColor={el?.shouldMentionColor}
                    />
                ) : (
                    <MediumWineCard
                        wineEntityName={props.wineEntityName}
                        key={i}
                        name={el?.name}
                        rank={el?.rank}
                        wineColor={el?.color}
                        rankFr={el?.rankFr}
                        wineColorFr={el?.colorFr}
                        sweetness={el?.sweetness}
                        hasVintages={el?.hasVintages}
                        hasScores={el?.scored}
                        aoc={el?.appellation}
                        wineRanking={el?.classifications?.length > 0 ? getClassifications(el?.classifications) : null}
                        to={getWineUrl(el)}
                        hasRewards={el?.rewarded}
                        image={el?.bottleImage?.twicpicURL}
                        lastVintageId={el?.lastVintageId}
                        shouldMentionSweetness={el?.shouldMentionSweetness}
                        shouldMentionColor={el?.shouldMentionColor}
                    />
                )}
            </Box>
        );
    };

    const otherWines = allOtherWines?.map((el, i) => {
        if (props.limited && !extended) {
            if (firstWines?.length === 1) {
                if (i <= 1) {
                    return getMediumWineCard(el, i);
                } else {
                    return "";
                }
            } else if (!firstWines || firstWines?.length === 0) {
                if (i <= 3) {
                    return getMediumWineCard(el, i);
                } else {
                    return "";
                }
            }
        } else {
            return getMediumWineCard(el, i);
        }
    });

    const getTitle = () => {
        return (
            <Box className={styles.container__title}>
                <EstateTitledSemiUnderlined title={props.title} heightAfter={32} />
            </Box>
        );
    };

    const arrow = () => {
        return (
            <Box className={styles.link}>
                <Arrow rotation="110" arrowPosition="start" style={{ padding: 0 }} icon="primary">
                    <Link
                        variant="h6"
                        underline="always"
                        style={{
                            cursor: "pointer",
                            marginLeft: "3px",
                            color: theme.palette.primary.dark,
                            fontFamily: "Mansalva",
                        }}
                        onClick={() => setExtended(!extended)}
                    >
                        {extended ? t`Réduire` : props.showMoreWines}
                    </Link>
                </Arrow>
            </Box>
        );
    };

    const getArrow = () => {
        if (props.limited) {
            if (firstWines?.length > 2) {
                return arrow();
            }
            if (firstWines?.length === 1 && allOtherWines?.length >= 3) {
                return arrow();
            }
            if (firstWines?.length === 2 && allOtherWines?.length >= 1) {
                return arrow();
            }
            if ((!firstWines || firstWines?.length === 0) && allOtherWines?.length >= 5) {
                return arrow();
            }
        }
    };

    if (loading) {
        return <Loading loading={loading} />;
    }

    if (
        isNull(data?.FrontWineList?.firstWines) &&
        isNull(data?.FrontWineList?.secondWines) &&
        isNull(data?.FrontWineList?.otherWines)
    )
        return null;

    return (
        <Box className={styles.container} style={props.styleBox} ref={winesListRef} id="vins">
            {props.title ? getTitle() : ""}
            {firstWines}
            {otherWines}
            {getArrow()}
        </Box>
    );
};

export default WineCardsContainer;

WineCardsContainer.propTypes = {
    locale: string,
    wineEntityId: string,
    firstWines: array,
    secondWines: array,
    otherWines: array,
    limited: number,
    title: string,
    whiteVersion: bool,
    omitWineId: string,
    styleBox: any,
    orphanEstate: bool,
    showMoreWines: string,
    wineEntityName: string,
    loadingEstate: bool,
    loadingActivity: bool,
    loadingBesv: bool,
    loadingContact: bool,
    setWinesAreLoading: func,
};
