import { Box, makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import theme from "../../globalTheme";
import logo from "../../assets/footer/footer_logo.svg";
import fb from "../../assets/footer/fb.svg";
import insta from "../../assets/footer/instagram.svg";
import scrollTop from "../../assets/footer/scrollTop.svg";
import linkedin from "../../assets/footer/linkedIn.svg";
import BaseButton from "../../Components/Buttons/BaseButton";
import { getCurrentYear, useWindowDimensions } from "../../helpers/helpers";
import { t, Trans } from "@lingui/macro";
import {
    feretAddress,
    feretPostalCode,
    LIBRARY_FERET_URL,
    PRO_FERET_ABOUT_URL,
    PRO_FERET_URL,
    SUBSCRIPTION_TYPES,
} from "config/constants";
import { useCustomHistory } from "hooks";

const useStyles = makeStyles(() => ({
    footer: {
        [theme.breakpoints.up("xs")]: {
            // height: "43.25rem",
            display: "flex",
            flexDirection: "column",
        },
        [theme.breakpoints.up("sm")]: {
            // height: "31rem",
        },
        [theme.breakpoints.up("md")]: {
            height: "14rem",
        },
    },
    footer_content: {
        backgroundColor: theme.palette.primary.main,
        display: "grid",
        gridTemplateColumns: "repeat(12,1fr)",
        color: theme.palette.common.white,
        [theme.breakpoints.up("md")]: {
            padding: "20px 16px",
            gridTemplateColumns: "repeat(13,1fr)",
        },
        [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "repeat(12,1fr)",
        },
        [theme.breakpoints.up("xl")]: {
            padding: "20px 0 px",
        },
        "& .MuiTypography-h6": {
            lineHeight: 2,
        },
    },

    copyright_content: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.dark,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        flex: 1,
    },
    dot: {
        display: "inline-flex",
        width: 3,
        height: 3,
        borderRadius: "50%",
        backgroundColor: theme.palette.primary.light,
        margin: "0 0.5rem",
    },
    logo_content: {
        [theme.breakpoints.up("xs")]: {
            gridRow: "4",
            gridColumn: "1 /span 13",
            margin: "2rem 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "column",
        },
        [theme.breakpoints.up("sm")]: {
            display: "grid",
            gridTemplateColumns: "repeat(12, 1fr)",
            margin: "2rem",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "auto",
            gridRow: "1",
            margin: "0",
            display: "flex",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 3",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "start",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "3 / span 2",
        },
    },
    logo_frt: {
        [theme.breakpoints.up("xs")]: {
            width: "93px",
        },
        [theme.breakpoints.up("sm")]: {
            width: "183px",
            gridColumn: "1 / span 6",
        },
        [theme.breakpoints.up("md")]: {
            width: "183px",
            gridColumn: "unset",
        },
        [theme.breakpoints.up("lg")]: {
            width: "203px",
        },
        [theme.breakpoints.up("xl")]: {
            width: "auto",
        },
    },
    besv_button: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "unset",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 6",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "unset",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "unset",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "unset",
        },
    },
    first_block: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "1 / span 13",
            gridRow: "2",
            margin: "1rem",
            textAlign: "center",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "1 / span 6",
            margin: "2rem",
            textAlign: "left",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "3 / span 4",
            gridRow: "1",
            margin: "unset",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 3",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "5 / span 2",
        },
    },
    second_block: {
        [theme.breakpoints.up("xs")]: {
            margin: "1rem",
            gridRow: "3",
            gridColumn: "1 / span 13",
            textAlign: "center",
        },
        [theme.breakpoints.up("sm")]: {
            margin: "2rem 0",
            gridRow: "2",
            gridColumn: "9 / span 5",
            textAlign: "left",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 3",
            gridRow: "1",
            margin: "unset",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 3",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "7 / span 2",
        },
    },
    third_block: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "1 / span 12",
            gridRow: "1",
            margin: "1rem",
            display: "flex",
            flexDirection: "column-reverse",
            alignItems: "center",
        },
        [theme.breakpoints.up("sm")]: {
            display: "grid",
            gridTemplateColumns: "repeat(12, 1fr)",

            flexDirection: "inherit",
        },
        [theme.breakpoints.up("md")]: {
            display: "flex",
            flexDirection: "column",
            gridRow: "1",
            margin: "unset",
            gridColumn: "auto / span 4",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 3",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "9 / span 2",
        },
    },
    social_media: {
        gap: "1rem",
        display: "flex",
        marginTop: "1rem",
        [theme.breakpoints.up("xs")]: {
            justifyContent: "center",
        },
        [theme.breakpoints.up("sm")]: {
            justifyContent: "flex-start",
        },
    },
    button_scroll: {
        [theme.breakpoints.up("xs")]: {
            marginTop: 0,
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "9 /span 5",
            alignItems: "baseline",
            height: "fit-content",
        },
        [theme.breakpoints.up("md")]: {
            marginTop: "1rem",
            gridColumn: "unset",
            width: "100%",
        },
        cursor: "pointer",
        width: "fit-content",
        display: "flex",
        alignItems: "center",
    },
    link: {
        color: theme.palette.common.white,
        cursor: "pointer",
        textDecoration: "none",
        "&:active": {
            textDecoration: "none",
        },
    },
    separator: {
        width: "-webkit-fill-available",
        height: "0.5px",
        borderBottomColor: "transparent",
        borderTopColor: theme.palette.secondary.main,
        gridColumn: "1 / span 13",
        gridRow: "2",
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
    },
    responsive_copyright_content: {
        display: "flex",
        flexDirection: "column",
    },
}));

const FrontFooter = () => {
    const styles = useStyles();
    const { width } = useWindowDimensions();
    const history = useCustomHistory();
    const currentUser = useSelector((state) => state.user.currentUser);

    const dot = () => <span className={styles.dot} />;

    return (
        <Box className={styles.footer}>
            <Box className={styles.footer_content}>
                <Box className={styles.logo_content}>
                    <img src={logo} className={styles.logo_frt} />
                    <BaseButton
                        component={Link}
                        className={styles.besv_button}
                        to={history.addLocale("/figurer-dans-bordeaux-et-ses-vins")}
                        variant="outlined"
                        fullWidth
                        style={{
                            marginTop: "1rem",
                            borderRadius: "4px",
                            color: theme.palette.common.white,
                            backgroundColor: theme.palette.primary.dark,
                            height: width >= 1280 ? "29px" : "fit-content",
                            minHeight: "inherit",
                        }}
                    >
                        <Typography variant="h6">
                            {currentUser?.subscriptionName === SUBSCRIPTION_TYPES.journalist ? (
                                <Trans>Ma salle de dégustation</Trans>
                            ) : (
                                <Trans>
                                    Figurer dans <i style={{ whiteSpace: "nowrap" }}>Bordeaux et ses Vins</i>
                                </Trans>
                            )}
                        </Typography>
                    </BaseButton>
                </Box>

                <Box className={styles.first_block}>
                    <Typography variant="h4">
                        <Trans>À propos de nous</Trans>
                    </Typography>
                    <Typography variant="h6">
                        <a className={styles.link} target="_blank" rel="noreferrer" href={PRO_FERET_ABOUT_URL}>
                            <Trans>Le guide Féret</Trans>
                        </a>
                    </Typography>
                    <Typography variant="h6">
                        <a className={styles.link} target="_blank" rel="noreferrer" href={PRO_FERET_URL}>
                            <Trans>
                                La solution <i>Bordeaux et ses Vins</i>
                            </Trans>
                        </a>
                    </Typography>
                    <Typography variant="h6">
                        <a className={styles.link} target="_blank" rel="noreferrer" href={LIBRARY_FERET_URL}>
                            <Trans>La librairie Féret</Trans>
                        </a>
                    </Typography>
                </Box>

                <Box className={styles.second_block}>
                    <Typography variant="h4">
                        <a className={styles.link}>
                            <Trans>Restons en contact</Trans>
                        </a>
                    </Typography>
                    <Typography variant="h6">
                        {feretAddress}, <br />
                        {feretPostalCode}
                    </Typography>
                    <Typography variant="h6" style={{ margin: "1rem 0" }}>
                        <Link className={styles.link} to={history.addLocale("/contact")}>
                            <Trans>Contactez-nous</Trans>
                        </Link>
                    </Typography>
                    <Box className={styles.social_media}>
                        <a
                            style={{ cursor: "pointer", display: "flex" }}
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.facebook.com/Feret1812/"
                        >
                            <img src={fb} />
                        </a>
                        <a
                            style={{ cursor: "pointer", display: "flex" }}
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.instagram.com/editions_feret/"
                        >
                            <img src={insta} />
                        </a>
                        <a
                            style={{ cursor: "pointer", display: "flex" }}
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.linkedin.com/company/feret"
                        >
                            <img src={linkedin} />
                        </a>
                    </Box>
                </Box>

                <Box className={styles.third_block}>
                    <Box
                        className={styles.button_scroll}
                        style={{ marginTop: 0 }}
                        onClick={() => {
                            window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: "smooth",
                            });
                        }}
                    >
                        <img src={scrollTop} />
                        <Typography variant="h6" style={{ fontFamily: "Mansalva", marginLeft: "0.5rem" }}>
                            <Trans>Retour en haut de la page</Trans>
                        </Typography>
                    </Box>
                </Box>
                <hr className={styles.separator} />
            </Box>
            {width >= 600 ? (
                <Box className={styles.copyright_content}>
                    <Typography variant="h6">
                        {" "}
                        &copy; Éditions Féret {getCurrentYear()}, <i>Bordeaux et ses Vins</i>
                    </Typography>
                    {dot()}
                    <Typography
                        component={Link}
                        variant="h6"
                        to={history.addLocale("/mentions-legales")}
                        style={{ cursor: "pointer", color: theme.palette.primary.dark, textDecoration: "none" }}
                    >
                        <Trans>Mentions légales</Trans>
                    </Typography>
                    {dot()}

                    <Typography
                        variant="h6"
                        component={Link}
                        className={styles.link}
                        to={history.addLocale("/confidentialite-CGU")}
                        style={{ cursor: "pointer", color: theme.palette.primary.dark, textDecoration: "none" }}
                    >
                        <Trans>Confidentialité et CGU</Trans>
                    </Typography>
                    {/* {dot()} */}
                    {/* <Typography variant="h6">
                        <Trans>Plan du site</Trans>
                    </Typography> */}
                </Box>
            ) : (
                <Box className={styles.responsive_copyright_content}>
                    <Typography variant="h6" style={{ textAlign: "center" }}>
                        {" "}
                        &copy; Éditions Féret {getCurrentYear()}, <i>Bordeaux et ses Vins</i>
                    </Typography>
                    <Typography
                        variant="h6"
                        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                        <Link
                            className={styles.link}
                            to={history.addLocale("/mentions-legales")}
                        >{t`Mentions légales`}</Link>{" "}
                        {dot()}{" "}
                        <Link
                            className={styles.link}
                            to={history.addLocale("/confidentialite-CGU")}
                        >{t`Confidentialité et CGU`}</Link>
                    </Typography>

                    {/* <Typography variant="h6" style={{ textAlign: "center", marginBottom: "1rem" }}>
                        <Trans>Plan du site</Trans>
                    </Typography> */}
                </Box>
            )}
        </Box>
    );
};

export default FrontFooter;
