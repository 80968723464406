import { Plural, t, Trans } from "@lingui/macro";
import { Badge, Box, makeStyles, Typography } from "@material-ui/core";
import Helmet from "Components/Helmet/Helmet";
import { getUserLocale } from "helpers/helpers";
import { useEnumValuesState } from "hooks/useEnumValues";
import { useState } from "react";
import { useSelector } from "react-redux";

import bg from "../../assets/searchEngine/estate/bg-estate-step-1.jpg";
import SimpleInput from "../../Components/Inputs/SimpleInput";
import { useSearchWineEntitiesQuery } from "../../generated/graphql";
import theme from "../../globalTheme";
import { useCustomHistory, useSearch } from "../../hooks";
import AutocompleteCheckboxSearch from "../../Search/AutocompleteCheckboxSearch";
import SearchEstateModal from "../../Search/modal/SearchEstateModal";
import SearchTabs from "../../Search/SearchTabs";
import ButtonSearch from "../../Search/ui/ButtonSearch";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&::after": {
            // eslint-disable-next-line quotes
            content: '""',
            position: "absolute",
            zIndex: -1,
            top: "-5rem",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundImage: `url(${bg})`,
            backgroundRepeat: "no-repeat",
            backgroundPositionY: "top",
            backgroundPositionX: "center",
            backgroundSize: "cover",
        },

        minHeight: "calc(100vh - 5rem)",
    },
    main_container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "22px 1rem 2rem",
        paddingTop: 0,
        overflow: "hidden",

        [theme.breakpoints.up("md")]: {
            width: "calc(950px)",
            margin: "auto",
        },

        [theme.breakpoints.up("lg")]: {
            width: "calc(1354px)",
            margin: "auto",
        },

        [theme.breakpoints.up("xl")]: {
            width: "calc(1384px)",
            margin: "auto",
        },
    },
    title: {
        color: theme.palette.common.white,
        fontWeight: 400,
        textAlign: "center",
        marginBottom: "1rem",
    },
    nav: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        gap: 6,
        alignItems: "center",
        background: "transparent",
        width: "100%",
        maxWidth: 1100,
        borderRadius: "53px",
        marginTop: 25,

        [theme.breakpoints.up("md")]: {
            marginTop: 23,
            background: theme.palette.common.white,
            height: 34,
            padding: "7px 9px",
        },

        [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "repeat(15, 1fr)",
            gap: 7,
            marginTop: 48,
        },
    },
    search: {
        gridColumn: "1 / span 12",

        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 4",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 4",
        },
    },
    label: {
        position: "relative",
        gridColumn: "1 / span 12",

        [theme.breakpoints.up("sm")]: {
            gridColumn: "1 / span 4",
        },

        [theme.breakpoints.up("md")]: {
            gridColumn: "5 / span 2",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "5 / span 2",
        },
    },
    appellation: {
        position: "relative",
        gridColumn: "1 / span 12",

        [theme.breakpoints.up("sm")]: {
            gridColumn: "5 / span 4",
        },

        [theme.breakpoints.up("md")]: {
            gridColumn: "7 / span 3",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "7 / span 3",
        },
    },
    classement: {
        position: "relative",
        gridColumn: "1 / span 12",

        [theme.breakpoints.up("sm")]: {
            gridColumn: "9 / span 4",
        },

        [theme.breakpoints.up("md")]: {
            gridColumn: "10 / span 3",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "10 / span 3",
        },
    },
    badgeNotif: {
        position: "absolute",
        right: 10,
        top: 4,
        border: "none",
        padding: "0 4px",
        background: theme.palette.primary.dark,

        "& .MuiBadge-badge": {
            fontWeight: 700,
        },
    },
    result: {
        gridColumn: "1 / span 12",
        display: "flex",
        justifyContent: "center",
        paddingTop: 6,

        [theme.breakpoints.up("sm")]: {
            paddingTop: 13,
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "13 / span 3",
            paddingTop: 0,
        },
    },
    resultBtn: {
        [theme.breakpoints.up("sm")]: {
            maxWidth: 236,
        },

        [theme.breakpoints.up("lg")]: {
            maxWidth: "none",
        },
    },
    searchAdvancedBox: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        gap: 12,
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        maxWidth: 1100,
    },
    searchAdvancedBtn: {
        background: "transparent",
        border: "none",
        color: theme.palette.common.white,
        textDecoration: "underline",
        cursor: "pointer",
        padding: 0,

        gridColumn: "1 / span 12",
        display: "flex",
        justifyContent: "center",

        [theme.breakpoints.up("sm")]: {
            marginTop: 0,
        },

        [theme.breakpoints.up("md")]: {
            gridColumn: "5 / span 4",
            marginTop: 48,
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "11 / span 3",
            marginTop: 0,
            marginRight: 40,
        },
    },
}));

const FirstStepEstate = () => {
    const styles = useStyles();
    const history = useCustomHistory();
    const locale = useSelector(getUserLocale);

    const { data: appellationLabel, loading: loadingLabel } = useEnumValuesState("APPELLATION_LABEL");
    const { data: classificationOrganism, loading: loadingClassification } = useEnumValuesState(
        "CLASSIFICATION_ORGANISM"
    );
    const { data: labelCertifEstate, loading: loadingCertif } = useEnumValuesState("LABEL_CERTIFICATION_ESTATE", {
        locale,
    });

    const [modalIsShown, setModalIsShown] = useState();
    const [searchText, setSearchText] = useState("");
    const [prevFilters, setPrevFilters] = useState({});

    const {
        getUrlParams,
        results,
        filtersCount,
        filters,
        setFilter,
        totalCount,
        resetFilters,
        setAllFilters,
    } = useSearch(
        {
            searchText: "",
            labels: [],
            appellations: [],
            appellationsWineProducts: [],
            classifications: [],
            badge: false,
            tourism: [],
            sweetness: [],
            colors: [],
            subRegions: [],
            communes: [],
            surface: [0, 100],
        },
        useSearchWineEntitiesQuery,
        "SearchWineEntities"
    );

    const handleNextStep = (e) => {
        history.push({
            pathname: "/recherche/proprietes/resultats",
            search: JSON.stringify(getUrlParams(e?.target?.value)),
        });
    };

    return (
        <>
            <Helmet
                title={t`Rechercher des propriétés`}
                content="Découvrez les plus belles propriétés viticoles de Bordeaux grâce à notre moteur de recherche en ligne. Explorez leurs histoires, leurs terroirs et leurs vins sur Bordeaux et ses Vins."
            />

            <Box className={styles.root}>
                <Box className={styles.main_container}>
                    <Typography variant="h2" className={styles.title}>
                        <Trans>Parcourez le vignoble bordelais</Trans>
                    </Typography>
                    <SearchTabs active="estate" />
                    <Box className={styles.nav}>
                        <Box className={styles.search}>
                            <SimpleInput
                                search
                                borderWidth={0.4}
                                size="small"
                                name="search"
                                placeholder={t`Chercher une propriété, un vin...`}
                                variant="outlined"
                                onEnterPressed={(e) => handleNextStep(e)}
                                onBlur={(e) => setFilter("searchText", e?.target?.value)}
                                onChange={(e) => setSearchText(e?.target?.value)}
                                boxStyle={{
                                    backgroundColor: theme.palette.common.white,
                                    borderRadius: "53px",
                                    border: "none",
                                }}
                                bgTransparent
                                borderRadius={53}
                                iconPosition="start"
                                value={searchText}
                            />
                        </Box>

                        <Box className={styles.label}>
                            <Badge
                                badgeContent={filters?.labels?.length}
                                color="primary"
                                className={styles.badgeNotif}
                            />
                            <AutocompleteCheckboxSearch
                                class={styles.category_filter}
                                label={t`Label`}
                                options={labelCertifEstate}
                                value={filters?.labels}
                                onChange={(e1, e2) => setFilter("labels", e2)}
                                loading={loadingCertif}
                                sortAlphabetical
                                uppercase
                                popupIcon
                            />
                        </Box>

                        <Box className={styles.appellation}>
                            <Badge
                                badgeContent={filters?.appellations?.length}
                                color="primary"
                                className={styles.badgeNotif}
                            />
                            <AutocompleteCheckboxSearch
                                class={styles.category_filter}
                                label={t`Appellation`}
                                options={appellationLabel}
                                value={filters?.appellations}
                                onChange={(e1, e2) => setFilter("appellations", e2)}
                                loading={loadingLabel}
                                sortAlphabetical
                                uppercase
                                popupIcon
                            />
                        </Box>

                        <Box className={styles.classement}>
                            <Badge
                                badgeContent={filters?.classifications?.length}
                                color="primary"
                                className={styles.badgeNotif}
                            />

                            <AutocompleteCheckboxSearch
                                class={styles.category_filter}
                                label={t`Classement`}
                                options={classificationOrganism}
                                value={filters?.classifications}
                                onChange={(e1, e2) => setFilter("classifications", e2)}
                                loading={loadingClassification}
                                sortAlphabetical
                                uppercase
                                popupIcon
                            />
                        </Box>

                        {/* <Box className={styles.hasBadge}>
                        <Badge badgeContent={filters?.badge ? 1 : 0} color="primary" className={styles.badgeNotif} />
                        <Box
                            className={styles.hasBadgeBox}
                            onClick={() => setFilter("badge", !filters?.badge)}
                            style={{
                                borderColor: filters?.badge ? theme.palette.primary.dark : theme.palette.secondary.main,
                                backgroundColor: filters?.badge
                                    ? theme.palette.background.card
                                    : theme.palette.common.white,
                            }}
                        >
                            <BigSwitch checked={filters?.badge} onChange={() => setFilter("badge", !filters?.badge)} />
                            <Typography variant="h6" style={{ marginLeft: 7, color: theme.palette.primary.dark }}>
                                <Trans>Badge Féret</Trans>
                            </Typography>
                        </Box>
                    </Box> */}

                        <Box className={styles.result}>
                            <ButtonSearch
                                onClick={() => handleNextStep()}
                                className={styles.resultBtn}
                                // disabled={filtersCount === 0 && !searchText}
                            >
                                <Plural
                                    value={totalCount ?? 0}
                                    one={"Rechercher (# résultat)"}
                                    other={"Rechercher (# résultats)"}
                                    _0={"Rechercher (# résultat)"}
                                />
                            </ButtonSearch>
                        </Box>
                    </Box>

                    <Box className={styles.searchAdvancedBox}>
                        <button
                            className={styles.searchAdvancedBtn}
                            onClick={() => {
                                setPrevFilters(filters);
                                setModalIsShown(true);
                            }}
                        >
                            <Typography variant="subtitle1">
                                <Trans>Recherche avancée</Trans>
                            </Typography>
                        </button>
                    </Box>
                </Box>
                <SearchEstateModal
                    filtersCount={filtersCount}
                    clearFilters={() => resetFilters()}
                    isOpen={modalIsShown}
                    onClose={() => setModalIsShown(false)}
                    onCloseCross={() => {
                        setAllFilters(prevFilters);
                        setModalIsShown(false);
                    }}
                    titlePrimary={t`Tous les filtres`}
                    titleSecondary={t`Propriété`}
                    searchResult={results}
                    filters={filters}
                    setFilter={(fieldname, v) => setFilter(fieldname, v)}
                    totalCount={totalCount}
                    nextStep={() => handleNextStep()}
                />
            </Box>
        </>
    );
};

export default FirstStepEstate;
