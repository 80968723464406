import React from "react";

import { makeStyles, Box, Typography } from "@material-ui/core";
import PropTypes, { bool, string } from "prop-types";
import Arrow from "../../Components/Arrow/Arrow";
import theme from "../../globalTheme";

const useStyles = makeStyles(() => ({
    priceBox: (props) => ({
        display: "flex",
        justifyContent: "center",
    }),
    price: (props) => ({
        fontWeight: "900",
        fontSize: props.small ? "22px" : "32px",
        color: theme.palette.primary.dark,
        fontFamily: "Lato",
    }),
    period: (props) => ({
        color: props.textColor ? theme.palette.primary.dark : theme.palette.primary.main,
        marginLeft: "5px",
        alignSelf: "flex-end",
    }),
}));

const Pricing = (props) => {
    const styles = useStyles(props);
    return (
        <Box>
            <Box className={styles.priceBox} style={props.style}>
                <Typography
                    variant="h3"
                    className={[styles.price, props.classNamePrice].join(" ")}
                    style={{ textDecorationLine: props.beta && "line-through", color: props.priceColor }}
                >
                    {props.discountPrice && props.title === "« Premium »" ? props.discountPrice : props.price}
                </Typography>
                {props.period && (
                    <Typography variant="h6" className={styles.period}>
                        {props.period}
                    </Typography>
                )}{" "}
            </Box>
            {props.discountPrice && props.title === "« Premium »" ? (
                <Typography
                    variant="h6"
                    style={{
                        textAlign: "right",
                        textDecorationLine: "line-through",
                        color: theme.palette.primary.main,
                    }}
                >
                    {props.price} /an
                </Typography>
            ) : null}

            {props.totalMonthly ? (
                props.totalMonthly === " " ? (
                    <Typography
                        style={{
                            color: theme.palette.primary.dark,
                            fontSize: "14px",
                        }}
                    >
                        &nbsp;
                    </Typography>
                ) : (
                    <Typography
                        style={{
                            color: theme.palette.primary.dark,
                            fontSize: "14px",
                        }}
                    >
                        {`(${props.totalMonthly} /an)`}
                    </Typography>
                )
            ) : null}
            {props.beta && (
                <Arrow rotation={"105"} arrowPosition="start">
                    <Typography style={{ fontFamily: "mansalva" }}>
                        En version bêta, vous ne payez rien ! Les prix sont inscrits à titre indicatif
                    </Typography>
                </Arrow>
            )}
        </Box>
    );
};

Pricing.propTypes = {
    beta: PropTypes.bool,
    classNamePrice: PropTypes.object,
    period: PropTypes.any,
    price: PropTypes.any,
    small: PropTypes.bool,
    style: PropTypes.object,
    textColor: PropTypes.string,
    totalMonthly: PropTypes.any,
    discountPrice: string,
    title: string,
    priceColor: string,
    noHeight: bool,
};

export default Pricing;
