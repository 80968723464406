import { Trans, t } from "@lingui/macro";
import { Box, makeStyles } from "@material-ui/core";
import Page500Front from "Components/ErrorPage/Page500Front";
import Loading from "Components/Loader/Loading";
import EstateTitleSemiUnderlined from "FrontEstate/Components/EstateTitledSemiUnderlined";
import HomeActivityCard from "Search/card/HomeActivityCard";
import { useGetCommonalitiesActivitiesQuery } from "generated/graphql";
import { string } from "prop-types";
import theme from "globalTheme";
import { useWindowDimensions } from "helpers/helpers";
import SubtitleCommonality from "Front/SubtitleCommonality";
import { SwiperWrapper } from "Front/SwiperCards";
import activities from "assets/activityPage/activities.svg";
import MoreCommonalityButton from "Front/MoreCommunalityButton";
const useStyles = makeStyles(() => ({
    container: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "1 / span 12",
        },
        [theme.breakpoints.up("sm")]: {
            padding: "0 16px",
        },
        [theme.breakpoints.up("xl")]: {
            width: "calc(1314px)",
            margin: "auto",
        },
        "& .swiper-slide": {
            [theme.breakpoints.only("xs")]: {
                width: "100%",
                padding: "7px 0 !important",
            },
        },
    },

    main_container: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        gap: "1rem",
        backgroundColor: theme.palette.background.card,
        [theme.breakpoints.only("xs")]: {
            padding: "14px",
        },
    },
}));

const ActivityCommonalities = (props) => {
    const styles = useStyles();
    const { width } = useWindowDimensions();
    const {
        data: { GetCommonalitiesActivities: commonalitiesActivities = [] } = {},
        loading: commonalitiesActivitiesLoading,
        error: commonalitiesActivitiesError,
    } = useGetCommonalitiesActivitiesQuery({
        variables: {
            activityId: props.activityId,
        },
    });

    if (commonalitiesActivitiesLoading) {
        return <Loading />;
    }
    if (commonalitiesActivitiesError) {
        return <Page500Front />;
    }
    return (
        <Box className={styles.main_container}>
            <Box className={styles.container}>
                <EstateTitleSemiUnderlined title={t`Des activités pour mieux (re)découvrir la région`} />
                {commonalitiesActivities?.byCommune?.result?.length > 0 && (
                    <>
                        <SubtitleCommonality
                            img={activities}
                            title={
                                <Trans>
                                    de la même {width >= 600 ? <br /> : ""} <b>commune</b>
                                </Trans>
                            }
                        />
                        <SwiperWrapper
                            spaceBetween={width >= 600 ? 0 : 16}
                            slidesPerView="auto"
                            loading={commonalitiesActivitiesLoading}
                            wrapperPaddingBottom="0"
                        >
                            {commonalitiesActivities?.byCommune?.result?.map((activity, i) => {
                                return (
                                    <>
                                        <HomeActivityCard activity={activity} />
                                        {i === commonalitiesActivities?.byCommune?.result?.length - 1 &&
                                            commonalitiesActivities?.byCommune?.total > 10 && (
                                                <MoreCommonalityButton
                                                    pathname="/recherche/activites/resultats"
                                                    filter="commune"
                                                    value={activity.INSEEcode}
                                                />
                                            )}
                                    </>
                                );
                            })}
                        </SwiperWrapper>
                    </>
                )}
                {commonalitiesActivities?.byType?.result?.length > 0 && (
                    <>
                        <SubtitleCommonality
                            img={activities}
                            title={
                                <Trans>
                                    du même {width >= 600 ? <br /> : ""} <b>genre</b>
                                </Trans>
                            }
                        />
                        <SwiperWrapper
                            spaceBetween={width >= 600 ? 0 : 16}
                            slidesPerView="auto"
                            loading={commonalitiesActivitiesLoading}
                            wrapperPaddingBottom="0"
                        >
                            {commonalitiesActivities?.byType?.result?.map((activity, i) => {
                                return (
                                    <>
                                        <HomeActivityCard activity={activity} />
                                        {i === commonalitiesActivities?.byType?.result?.length - 1 &&
                                            commonalitiesActivities?.byType?.total > 10 && (
                                                <MoreCommonalityButton
                                                    pathname="/recherche/activites/resultats"
                                                    filter="categorie"
                                                    value={activity.typeFr}
                                                />
                                            )}
                                    </>
                                );
                            })}
                        </SwiperWrapper>
                    </>
                )}
            </Box>
        </Box>
    );
};

ActivityCommonalities.propTypes = {
    activityId: string,
};

export default ActivityCommonalities;
