import React from "react";

const removeOrAddItems = (item, filter) => {
    const ret = [...filter];
    if (ret.includes(item)) {
        const indexEl = ret.indexOf(item);

        if (indexEl !== -1) {
            ret.splice(indexEl, 1);
        }

        return ret;
    } else {
        ret.push(item);
        return ret;
    }
};
export default removeOrAddItems;
