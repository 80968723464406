import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import PropTypes, { arrayOf } from "prop-types";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepButton from "@material-ui/core/StepButton";
import MobileStepper from "@material-ui/core/MobileStepper";
import theme from "../../globalTheme";
import { useWindowDimensions } from "../../helpers/helpers";

const useStyles = makeStyles((theme) => ({
    stepper: (props) => {
        return {
            padding: props.labelColor ? theme.spacing(4) : theme.spacing(4),
            backgroundColor: props.backgroundColor || theme.palette.primary.main,
            maxHeight: "60px",
            "& .MuiStepLabel-label": {
                color: props.labelColor || theme.palette.common.white,
                fontSize: props.labelFontSize || 14,
                marginTop: 2,
            },
            "& .MuiStepLabel-iconContainer": {
                display: "block",
            },
            "& .MuiStepConnector-alternativeLabel": {
                opacity: "0.5",
                height: "2px",
                left: "calc(-55% + 20px)",
                right: "calc(45% + 20px)",
                top: "-16px",
                "& .MuiStepConnector-line": {
                    borderColor: props.backgroundColor ? theme.palette.secondary.dark : "white",
                    opacity: props.backgroundColor ? "1" : "0.5",
                    borderTopWidth: "2px",
                },
                "&.MuiStepConnector-active > span": {
                    borderColor: props.backgroundColor ? theme.palette.primary.main : "white",
                    opacity: "1",
                    borderTopWidth: "2px",
                },
                "&.MuiStepConnector-completed > span": {
                    borderColor: props.backgroundColor ? theme.palette.primary.main : "white",
                    opacity: "1",
                    borderTopWidth: "2px",
                },
            },
        };
    },
    step: {
        display: "flex",
        alignItems: "start",
    },
    mobileStepperStyle: (props) => {
        const dots = document.querySelectorAll(".MuiMobileStepper-dot");
        props.completedSteps.map((el, i) => {
            if (el) {
                [...dots].map((dot, y) => {
                    if (y === i) {
                        dot.style.backgroundColor = "white";
                    }
                });
            } else {
                [...dots].map((dot, y) => {
                    if (y === i) {
                        dot.style.backgroundColor = "";
                    }
                });
            }
        });

        return {
            backgroundColor: theme.palette.primary.main,
            display: "flex",
            justifyContent: "center",
            minHeight: "24px",
            "& .MuiMobileStepper-dotActive": {
                backgroundColor: "white",
                "&::after": {
                    content: "' '",
                    display: "block",
                    width: "20px",
                    height: "20px",
                    border: "2px solid white",
                    borderRadius: "20px",
                    transform: "translate(-25%, -25%)",
                },
            },
            "& .MuiMobileStepper-dots": {
                width: "100%",
                justifyContent: "space-around",
            },
            "& .MuiMobileStepper-dot": {
                width: "12px",
                height: "12px",
            },
        };
    },
    mobileStepper_dotCheck: (props) => {
        return {
            backgroundColor: "green",
        };
    },
}));

const getStepIcon = (props, active, completed) => {
    if (active) {
        return props.activeIcon;
    }
    if (completed) {
        return props.completedIcon;
    }
    return props.disabledIcon;
};

const SimpleStepper = (props) => {
    const styles = useStyles(props);
    const { width, height } = useWindowDimensions();

    const checkResponsiveCompoenent = (props) => {
        if (width >= 960) {
            return (
                <Stepper
                    activeStep={props.activeStep}
                    alternativeLabel
                    className={styles.stepper}
                    style={{ ...props.style }}
                >
                    {props.stepLabels?.map((label, i) => (
                        <Step
                            key={label}
                            className={styles.step}
                            disabled={props.disabledSteps[i]}
                            active={props.activeStep === i}
                            completed={props.completedSteps[i]}
                        >
                            <StepButton
                                onClick={() => props.onClick(label, i)}
                                style={{ paddingTop: theme.spacing(0), paddingBottom: theme.spacing(0) }}
                            >
                                <StepLabel
                                    style={{ margin: 0 }}
                                    StepIconComponent={() =>
                                        getStepIcon(props, props.activeStep === i, props.completedSteps[i])
                                    }
                                >
                                    {label}
                                </StepLabel>
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
            );
        } else if (width < 960) {
            return (
                <MobileStepper
                    variant="dots"
                    steps={props.stepLabels.length}
                    position="static"
                    className={styles.mobileStepperStyle}
                    activeStep={props.activeStep}
                />
            );
        }
    };

    return <>{checkResponsiveCompoenent(props)}</>;
};

SimpleStepper.propTypes = {
    backgroundColor: PropTypes.string,
    labelColor: PropTypes.string,
    disabledSteps: arrayOf(PropTypes.bool),
    stepLabels: PropTypes.arrayOf(PropTypes.string),
    onClick: PropTypes.func,
    completedSteps: arrayOf(PropTypes.bool),
    activeStep: PropTypes.number,
    activeIcon: PropTypes.any,
    disabledIcon: PropTypes.any,
    completedIcon: PropTypes.any,
    style: PropTypes.object,
};

export default SimpleStepper;
