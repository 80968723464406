import { Card, CardContent, makeStyles } from "@material-ui/core";
import React from "react";
import theme  from "../../globalTheme";

const useStyles = makeStyles(() => {
    return {
        summary_container: {
            width: "30%",
            [theme.breakpoints.between("sm","md")]: {
                width: "80%",
             },
            [theme.breakpoints.down("xs")]: {
                width: "100%",
            },
        },
    };
});

interface FeretSummaryContainerProps {
    children: React.ReactNode,
    maxWidth?: string
}

const FeretSummaryContainer = (props : FeretSummaryContainerProps) => {
    const styles = useStyles();
    return (
        <Card variant="outlined" style={{ maxWidth: props.maxWidth ?? "none", backgroundColor: theme.palette.common.white}}>
            <CardContent>
                {props.children}
            </CardContent>
        </Card>
    );
};


export default FeretSummaryContainer;
