import { createSlice } from "@reduxjs/toolkit";

export const settingsSlice = createSlice({
    name: "settings",
    initialState: {
        formDirty: false,
        formLoading: false,
        nextRoute: null,
        saveForm: false,
        enums: {},
        adoptWineEntity: null,
    },
    reducers: {
        setNotificationOpened(state, action) {
            state.notificationOpened = action.payload;
        },
        setDirty(state, action) {
            state.formDirty = action.payload;
        },
        setNextRoute(state, action) {
            state.nextRoute = action.payload;
        },
        setSaveForm(state, action) {
            state.saveForm = action.payload;
        },
        setLoadingForm(state, action) {
            state.formLoading = action.payload;
        },
        setAdoptWineEntity(state, action) {
            state.adoptWineEntity = action.payload;
        },
    },
});

export const { setDirty, setNextRoute, setSaveForm, setLoadingForm, setAdoptWineEntity } = settingsSlice.actions;
export default settingsSlice.reducer;
