import { t } from "@lingui/macro";
import { Box, makeStyles, Typography } from "@material-ui/core";
import Helmet from "Components/Helmet/Helmet";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useLocation, useParams, useRouteMatch } from "react-router-dom";

import { useCustomHistory } from "hooks";
import logo from "../assets/landingPage/feret_logo.svg";
import vines from "../assets/landingPage/langing_page_bg.png";
import { signIn } from "../features/user/userSlice";
import {
    useCreateWebFtDiffusionMutation,
    useGetFtRequestByCodeLazyQuery,
    useSignInInviteMutation,
    useSignUpInviteMutation,
} from "../generated/graphql";
import theme from "../globalTheme";
import ForgotPassword from "../Landing/ForgotPassword";
import CodeInput from "./CodeInput";
import InvitationForm from "./InvitationForm";
import { toast, ToastContainer } from "react-toastify";

const useStyles = makeStyles(() => ({
    invitationPage__wrapper: {
        width: "100vw",
        minHeight: "100vh",
        display: "flex",
        [theme.breakpoints.up("xs")]: {
            flexDirection: "column",
            background: `border-box white url(${vines}) no-repeat`,
            backgroundSize: "inherit",
        },
        [theme.breakpoints.up("lg")]: {
            flexDirection: "row",
            backgroundSize: "cover",
            justifyContent: "space-between",
        },
        [theme.breakpoints.up("xl")]: {
            flexDirection: "row",
            backgroundSize: "contain",
        },
    },
    logoContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.up("xs")]: {
            width: "100%",
            height: "200px",
        },
        [theme.breakpoints.up("sm")]: {
            width: "100%",
            height: "300px",
        },
        [theme.breakpoints.up("lg")]: {
            width: "40%",
            height: "auto",
        },
        [theme.breakpoints.up("xl")]: {
            width: "35%",
        },
    },
    logo: {
        [theme.breakpoints.up("xs")]: {
            width: "260px",
        },
        [theme.breakpoints.up("sm")]: {
            width: "350px",
        },
        [theme.breakpoints.up("md")]: {
            width: "450px",
        },
    },
    invitationPage__CoAndSub: {
        backgroundColor: "white",
        [theme.breakpoints.up("xs")]: {
            display: "grid",
            gridTemplateColumns: "repeat(12,1fr)",
            alignItems: "none",
            height: "initial",
            width: "auto",
            padding: "1rem",
            flex: 1,
        },
        [theme.breakpoints.up("sm")]: {
            width: "100%",
            padding: "0",
            alignItems: "center",
            flex: 1,
        },
        [theme.breakpoints.up("md")]: {
            flex: 1,
        },
        [theme.breakpoints.up("lg")]: {
            flex: "initial",
            width: "50%",
            paddingRight: "calc(8px * 8)",
            paddingLeft: "calc(8px * 8)",
        },
    },
    text_container: (args) => {
        return {
            [theme.breakpoints.up("xs")]: {
                width: "auto",
                gridColumn: "1 / span 12",
                textAlign: "center",
            },
            [theme.breakpoints.up("sm")]: {
                width: "auto",
                gridColumn: "2 / span 10",
                textAlign: "left",
                marginTop: "3rem",
            },
            [theme.breakpoints.up("md")]: {
                width: "auto",
                gridColumn: "2 / span 10",
                marginTop: "3rem",
            },
            [theme.breakpoints.up("lg")]: {
                width: "auto",
                gridColumn: "1 / span 12",
            },
            [theme.breakpoints.up("xl")]: {
                width: "auto",
                gridColumn: "2 / span 10",
            },
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
        };
    },

    ReactCodeInput: {
        display: "flex !important",
        justifyContent: "flex-start",
        gap: "1rem",
    },
}));

const Invitation = () => {
    const [step, setStep] = useState(0);

    const history = useCustomHistory();

    const [errors, setErrors] = useState(null);
    const [titleValue, setTitleValue] = useState(null);
    const [firstNameValue, setFirstNameValue] = useState(null);
    const [lastNameValue, setLastNameValue] = useState(null);
    const [emailAddressValue, setEmailAddressValue] = useState("");
    const [passwordValue, setPasswordValue] = useState(null);
    const [confirmationPasswordValue, setConfirmationPasswordValue] = useState(null);
    const [phoneNumberValue, setPhoneNumberValue] = useState(null);
    const [isProfessional, setIsProfessional] = useState(false);
    const [category, setCategory] = useState(null);
    const [company, setCompany] = useState(null);
    const [address, setAddress] = useState(null);
    const [extraAddress, setExtraAddress] = useState(null);
    const [postalCode, setPostalCode] = useState(null);
    const [commune, setCommune] = useState(null);
    const [ftId, setFtId] = useState(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();
    const params = useParams();
    const [
        getRequestByCode,
        { data: { GetFtRequestByCode: request = null } = {}, loading: loadingCode },
    ] = useGetFtRequestByCodeLazyQuery({
        fetchPolicy: "network-only",
    });
    const [signUpInvite, { data: signUpData, loading: signUpLoading, error: signUpError }] = useSignUpInviteMutation();
    const [signInInvite, { data: signInData, loading: signInLoading, error: signInError }] = useSignInInviteMutation();
    const [createDiffusion] = useCreateWebFtDiffusionMutation();

    const currentUser = useSelector((state) => state?.user?.currentUser);

    useEffect(() => {
        if (params?.ftId) {
            setFtId(params.ftId);
            setStep(1);
        }
    }, [location]);

    // Correct code and FtRequest found
    useEffect(() => {
        if (request) {
            setStep(step + 1);
            setEmailAddressValue(request.email);
        }
    }, [request]);

    // Sign up succeed, sign the user in
    useEffect(() => {
        if (signUpData) {
            if (ftId) {
                createDiffusion({ variables: { ftId, userId: signUpData?.SignUpInvite?.user?._id } });
            }
            dispatch(signIn({ username: emailAddressValue, password: passwordValue }));
        }
    }, [signUpData]);

    // After singup, load until redirect
    useEffect(() => {
        if (signUpLoading || signInLoading) {
            setLoading(true);
        }
    }, [signUpLoading, signInLoading]);

    useEffect(() => {
        if (signUpError || signInError) {
            setLoading(false);
        }
    }, [signUpError, signInError]);

    // When user signed in (current user is defined), redirect to FT dashboard
    useEffect(() => {
        if (currentUser && (signUpData || signInData)) {
            history.push({
                pathname: "/dashboard/sheets/inbox",
                state: {
                    datasheetId: ftId,
                },
            });
        }
    }, [currentUser, signInData]);

    const nextStep = (code) => {
        getRequestByCode({ variables: { code } });
    };

    // PASSWORD CONSTRAINTS
    const lowerCaseRegex = /[a-z]/g;
    const upperCaseRegex = /[A-Z]/g;
    const symbolRegex = /[@$!%*?&]/g;
    const [focus, setFocus] = useState(false);
    const [lenConstraint, setLenConstraint] = useState(false);
    const [lowerCaseConstraint, setLowerCaseConstraint] = useState(false);
    const [upperCaseConstraint, setUpperCaseConstraint] = useState(false);
    const [symbolConstraint, setSymbolConstraint] = useState(false);

    useEffect(() => {
        setSymbolConstraint(!!passwordValue?.match(symbolRegex));
        setLenConstraint(passwordValue?.length >= 8);
        setLowerCaseConstraint(!!passwordValue?.match(lowerCaseRegex));
        setUpperCaseConstraint(!!passwordValue?.match(upperCaseRegex));
    }, [passwordValue]);

    const passwordError = () => {
        if (passwordValue) {
            if (!symbolConstraint || !lenConstraint || !lowerCaseConstraint || !upperCaseConstraint) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    const confirmationPasswordError = () => {
        if (confirmationPasswordValue) {
            return !(confirmationPasswordValue === passwordValue);
        }
    };

    const getEmailErrors = () => {
        if (emailAddressValue && emailError) {
            return "Le champ n'est pas valide";
        }
        if (errors?.emailAddressValue?.message) {
            return "Email déjà utilisé";
        }
    };

    const handleSignUpSubmit = async () => {
        try {
            await signUpInvite({
                variables: {
                    input: {
                        requestEmail: request?.email,
                        title: titleValue,
                        firstName: firstNameValue,
                        lastName: lastNameValue,
                        telephone: phoneNumberValue,
                        email: emailAddressValue,
                        password: passwordValue,
                        category,
                        company,
                        address,
                        extraAddress,
                        postalCode,
                        commune,
                    },
                },
            });
        } catch (e) {
            const code =
                e.graphQLErrors?.[0]?.extensions?.exception?.errorInfo?.code ?? e.graphQLErrors?.[0]?.extensions?.code;

            if (["auth/email-already-exists", "error.http.409"].includes(code) || e.message === "Conflict") {
                const errorMsg = "Cette adresse e-mail est déjà associée à un compte.";
                toast.error(errorMsg);
            } else {
                toast.error("Une erreur est survenue, veuillez réessayer ultérieurement.");
            }
        }
    };

    const handleSignInSubmit = async (email) => {
        try {
            await signInInvite({
                variables: {
                    input: {
                        requestEmail: request.email,
                        email,
                    },
                },
            });
        } catch (e) {
            const errorMsg = "Connexion impossible avec ces identifiants.";
            toast.error(errorMsg);
        }
    };

    const firstNameErrors = firstNameValue?.length < 1 || firstNameValue?.length > 30;
    const lastNameErrors = lastNameValue && (lastNameValue?.length < 2 || lastNameValue?.length > 30);
    const phoneNumberErrors = phoneNumberValue && (phoneNumberValue?.length !== 14 || phoneNumberValue[0] !== "0");
    const companyValueErrors = company?.length < 1;

    // EMAIL CONSTRAINTS
    // eslint-disable-next-line no-useless-escape
    const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const emailError = !emailAddressValue?.match(emailRegex);

    if (step === 0) {
        return <CodeInput isCodeValid={!!request} nextStep={(code) => nextStep(code)} loading={loadingCode} />;
    }
    if (step === 1) {
        return (
            <InvitationForm
                ftId={ftId}
                senderName={request?.estateName}
                receiverEmail={request?.email}
                disabled={
                    !passwordValue ||
                    passwordError() ||
                    firstNameErrors ||
                    lastNameErrors ||
                    phoneNumberErrors ||
                    !confirmationPasswordValue ||
                    confirmationPasswordError() ||
                    !firstNameValue ||
                    !lastNameValue ||
                    !emailAddressValue ||
                    emailError ||
                    !phoneNumberValue
                }
                //TITLE
                title={{
                    value: titleValue,
                    onChange: (e) => setTitleValue(e),
                }}
                //FIRSTNAME
                firstName={{
                    value: firstNameValue,
                    onChange: (e) => setFirstNameValue(e.target.value),
                    correctValue: firstNameValue && !firstNameErrors,
                    error: firstNameErrors,
                }}
                //LASTNAME
                lastName={{
                    value: lastNameValue,
                    onChange: (e) => setLastNameValue(e.target.value),
                    correctValue: lastNameValue && !lastNameErrors,
                    error: lastNameErrors,
                }}
                //EMAIL
                emailAddress={{
                    value: emailAddressValue,
                    onChange: (e) => setEmailAddressValue(e.target.value),
                    error: emailAddressValue && (emailError || errors?.emailAddressValue?.message),
                    clue: getEmailErrors(),
                    correctValue: emailAddressValue && !emailError && !errors?.emailAddressValue?.message,
                    falseValue: emailError || errors?.emailAddressValue?.message,
                    onFocus: () =>
                        setErrors({
                            emailAddressValue: {
                                message: "",
                            },
                        }),
                }}
                //PHONE
                phoneNumber={{
                    value: phoneNumberValue,
                    onChange: (e) => setPhoneNumberValue(e),
                    error: phoneNumberErrors,
                    correctValue: phoneNumberValue && !phoneNumberErrors,
                }}
                //PASSWORD
                password={{
                    value: passwordValue,
                    onChange: (e) => setPasswordValue(e.target.value),
                    error: passwordError(),
                    focus,
                    setFocus,
                    constraints: {
                        lenConstraint,
                        setLenConstraint,
                        upperCaseConstraint,
                        setUpperCaseConstraint,
                        lowerCaseConstraint,
                        setLowerCaseConstraint,
                        symbolConstraint,
                        setSymbolConstraint,
                    },
                }}
                //CONFIRMPASSWORD
                confirmationPassword={{
                    value: confirmationPasswordValue,
                    onChange: (e) => setConfirmationPasswordValue(e.target.value),
                    error: confirmationPasswordError(),
                    correctValue: confirmationPasswordValue && confirmationPasswordValue === passwordValue,
                    helperText: confirmationPasswordError() && "Le mot de passe doit être identique",
                }}
                //ISPROFESSIONAL
                isProfessional={isProfessional}
                onChangeIsProfessional={() => {
                    setIsProfessional(!isProfessional);
                }}
                //CATEGORY
                category={category}
                onChangeCategory={(e) => setCategory(e)}
                //COMPANY
                company={{
                    value: company,
                    onChange: (e) => setCompany(e.target.value),
                    correctValue: company && !companyValueErrors,
                    error: companyValueErrors,
                }}
                //ADDRESS
                address={{
                    value: address,
                    onChange: (e) => setAddress(e),
                    correctValue: address,
                }}
                //EXTRAADDRESS
                extraAddress={extraAddress}
                onChangeExtraAddress={(e) => setExtraAddress(e)}
                //POSTALCODE
                postalCode={postalCode}
                onChangePostalCode={(e) => setPostalCode(e)}
                //COMMUNE
                commune={commune}
                onChangeCommune={(e) => setCommune(e)}
                onSignUpSubmit={handleSignUpSubmit}
                onSignInSubmit={handleSignInSubmit}
                loading={loading}
            />
        );
    }
    return null;
};

export const InvitationRoutes = () => {
    const styles = useStyles();
    const { url } = useRouteMatch();

    return (
        <>
            <Helmet title={t`Invitation`} />
            <ToastContainer
                autoClose={3500}
                draggable
                pauseOnHover
                pauseOnFocusLoss
                closeOnClick
                position="top-right"
            />
            <Box className={styles.invitationPage__wrapper}>
                <Box className={styles.logoContainer}>
                    <img src={logo} className={styles.logo} />
                </Box>
                <Box className={styles.invitationPage__CoAndSub} style={{ postion: "relative" }}>
                    <Box className={styles.text_container}>
                        <Switch>
                            <Route path={`${url}/mdp`}>
                                <Box>
                                    <Typography
                                        variant="h2"
                                        style={{
                                            textAlign: "left",
                                            marginBottom: theme.spacing(1),
                                            zIndex: 5,
                                            position: "relative",
                                        }}
                                    >
                                        Mot de passe oublié
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        style={{
                                            textAlign: "left",
                                            width: "80%",
                                            margin: "24px 0px 40px",
                                        }}
                                    >
                                        Saisissez l&apos;adresse email associée à votre compte. Nous vous enverrons un
                                        lien par email pour réinitialiser votre mot de passe.
                                    </Typography>
                                    <ForgotPassword onClick={() => history.push("/invitation")} />
                                </Box>
                            </Route>
                            <Route path={`${url}/:ftId`}>
                                <Invitation />
                            </Route>

                            <Route path={url}>
                                <Invitation />
                            </Route>
                        </Switch>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default InvitationRoutes;
