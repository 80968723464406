import React from "react";
import PropTypes from "prop-types";
import Slider from "@material-ui/core/Slider";
import { makeStyles, Box, Typography } from "@material-ui/core";
import theme from "../../globalTheme";

const useStyles = makeStyles(() => ({
    mainBox: (props) => ({
        display: "flex",
        flexDirection: "column",
        paddingRight: "8px",
        paddingLeft: "8px",
        "& .MuiSlider-thumbColorPrimary": {
            color: theme.palette.primary.dark,
        },
        "& .MuiSlider-rail": {
            color: theme.palette.secondary.dark,
        },
        "& .MuiSlider-track": {
            color: theme.palette.secondary.dark,
            opacity: 0,
        },
        "& .MuiSlider-markLabel": {
            color: props.colorEnum ? theme.palette.primary.light : theme.palette.secondary.dark,
            top: 20,
            fontSize: "10px",
        },
        '& .MuiSlider-markLabel[data-index="0"]': {
            left: "2% !important",
        },
        "& .MuiSlider-thumb.Mui-disabled": {
            width: "10px",
            height: "10px",
            marginTop: -4,
        },
        [theme.breakpoints.only("xs")]: {
            '& .MuiSlider-markLabel[data-index="0"]': {
                top: -20,
            },
            '& .MuiSlider-markLabel[data-index="2"]': {
                top: -20,
            },
            '& .MuiSlider-markLabel[data-index="4"]': {
                top: -20,
            },
            '& .MuiSlider-markLabel[data-index="6"]': {
                top: -20,
            },
            '& .MuiSlider-markLabel[data-index="8"]': {
                top: -20,
            },
            '& .MuiSlider-markLabel[data-index="10"]': {
                top: -20,
            },
        },
    }),
    labelsBox: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        justifyContent: "space-between",
    },
    bellowText: {
        fontSize: "10px",
        color: theme.palette.secondary.dark,
    },
    label: {
        ...theme.front.typography.labeur.dark,
    },
    slider: {
        padding: "8px 0px",
    },
}));

const BaseSlider = (props) => {
    const styles = useStyles(props);
    const getColor = () => {
        if (props.value <= 10) {
            return "#d0bdc3";
        } else if (props.value > 10 && props.value <= 20) {
            return "#c3acb3";
        } else if (props.value > 20 && props.value <= 30) {
            return "#b99fa7";
        } else if (props.value > 30 && props.value <= 40) {
            return "#ae919a";
        } else if (props.value > 40 && props.value <= 50) {
            return "#9d7a85";
        } else if (props.value > 50 && props.value <= 60) {
            return "#8d6471";
        } else if (props.value > 60 && props.value <= 70) {
            return "#7e515f";
        } else if (props.value > 70 && props.value <= 80) {
            return "#724150";
        } else if (props.value > 80 && props.value <= 90) {
            return "#6e3b4b";
        } else if (props.value > 90 && props.value <= 100) {
            return "#632d3e";
        }
    };

    const styledMarks = props.marks?.map((e) => {
        return {
            label: (
                <Typography
                    key={e.value}
                    style={{
                        fontSize: e.value === props.value ? "15.25px" : "14px",
                        color: e.value === props.value ? theme.palette.primary.dark : theme.palette.primary.light,
                    }}
                >
                    {e.label}
                </Typography>
            ),
            value: e.value,
        };
    });

    return (
        <Box className={[styles.mainBox, props.sliderClassname].join(" ")}>
            <Typography variant="h6" className={styles.label}>
                {props.label}
            </Typography>
            <Slider
                value={props.value}
                defaultValue={props.defaultValue}
                disabled
                onChange={props.handleChange}
                onChangeCommitted={props.onChangeCommitted}
                color={props.color}
                marks={styledMarks}
                max={props.max}
                min={props.min}
                name={props.name}
                orientation={props.orientation}
                scale={props.scale}
                step={props.step}
                track={props.track}
                className={styles.slider}
                aria-labelledby="continuous-slider"
                style={{ color: getColor() }}
            />
            <Box className={styles.labelsBox}>
                <Typography variant="h6" className={styles.bellowText}>
                    {props.bellowLeftText}
                </Typography>
                <Typography variant="h6" className={styles.bellowText}>
                    {props.bellowRightText}
                </Typography>
            </Box>
        </Box>
    );
};

BaseSlider.propTypes = {
    label: PropTypes.string,
    bellowLeftText: PropTypes.string,
    bellowRightText: PropTypes.string,
    value: PropTypes.number,
    handleChange: PropTypes.func,
    defaultValue: PropTypes.number,
    disabled: PropTypes.bool,
    marks: PropTypes.bool,
    max: PropTypes.number,
    min: PropTypes.number,
    name: PropTypes.string,
    onChangeCommitted: PropTypes.func,
    orientation: PropTypes.oneOf(["horizontal", "vertical"]),
    scale: PropTypes.func,
    step: PropTypes.number,
    track: PropTypes.oneOf(["normal", "false", "inverted"]),
    color: PropTypes.oneOf(["primary", "secondary"]),
    colorEnum: PropTypes.bool,
    sliderClassname: PropTypes.string,
};

export default BaseSlider;
