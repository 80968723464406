import { Typography, Box, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import conversion from "../../assets/conversion.png";
import { useFrontVintageLabelsPopinQuery } from "../../generated/graphql";
import { useSelector } from "react-redux";
import Popin from "../../Containers/Popin";
import { t, Trans } from "@lingui/macro";
import Loading from "Components/Loader/Loading";
import theme from "globalTheme";

const useStyles = makeStyles(() => ({
    mainBox: () => ({
        width: "100%",
        display: "flex",
        flexDirection: "column",
        "& .MuiTabs-flexContainer": {
            justifyContent: "space-between",
            borderBottom: "1px solid",
            borderBottomColor: theme.palette.secondary.main,
        },
        "& .MuiIconButton-root": {
            padding: 0,
        },
        "& .MuiTab-textColorPrimary.Mui-selected": {
            color: theme.palette.common.black,
            backgroundColor: "white !important",
        },
    }),
    detailsTitle: {
        alignSelf: "start",
        marginBottom: theme.spacing(6),
        fontSize: "50px",
    },
    mainContainer: {
        marginTop: "20px",
        width: "650px",
        [theme.breakpoints.down("sm")]: {
            width: "auto",
        },
    },
    labelBox: {
        border: "1px solid",
        borderColor: theme.palette.secondary.dark,
        borderRadius: "10px",
        paddingTop: "40px",
        paddingBottom: "25px",
        paddingRight: "25px",
        paddingLeft: "25px",
        marginBottom: "30px",
        position: "relative",
        backgroundColor: "white",
    },
    titleBox: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "white",
        position: "absolute",
        top: -20,
        left: 20,
        paddingRight: "10px",
        paddingLeft: "5px",
    },
    image: {
        height: "40px",
        marginRight: theme.spacing(1),
    },
    blocsBox: {
        display: "flex",
        justifyContent: "start",
        alignItems: "flex-start",
        flexWrap: "wrap",
        [theme.breakpoints.down("xs")]: {
            display: "block",
        },
    },
    blocBox: {
        marginRight: theme.spacing(2),
        display: "flex",
        alignItems: "flex-start",
        flex: 1,
        [theme.breakpoints.down("xs")]: {
            marginBottom: theme.spacing(2),
        },
    },
    conversionImage: {
        width: "60px",
        marginRight: theme.spacing(2),
    },
    blocTitle: {
        fontSize: "14px",
    },
    blocSubtitle: {
        fontSize: "16px",
        fontFamily: "Playfair Display",
        fontWeight: "bold",
    },
    titlesBox: {
        marginRight: theme.spacing(2),
        flex: 1,
        [theme.breakpoints.down("xs")]: {
            marginBottom: theme.spacing(2),
        },
    },
    classStyleContent: (loading) => {
        return {
            overflow: loading && "inherit",
        };
    },
}));

const LabelsFrontPopin = (props) => {
    const locale = useSelector((state) => state?.user?.locale || "fr");

    const { data, loading } = useFrontVintageLabelsPopinQuery({
        variables: {
            wineEntityId: props.wineEntityId,
            locale,
        },
    });
    const styles = useStyles(loading);

    const labels = data?.FrontVintageLabelsPopin;

    const getStateImage = (isInConversion) => {
        if (isInConversion === true) {
            return (
                <Box className={styles.blocBox}>
                    <img src={conversion} alt="label_image" className={styles.conversionImage} />
                    <Box>
                        <Typography variant="h6" className={styles.blocTitle}>
                            {t`État`}
                        </Typography>
                        <Typography variant="h6" className={styles.blocSubtitle}>
                            <Trans>En conversion</Trans>
                        </Typography>
                    </Box>
                </Box>
            );
        }
        return null;
    };

    const getLabels = () => {
        if (labels && labels?.length > 0) {
            return labels?.map((el, i) => {
                if (
                    el?.certificationBody ||
                    el?.expiryDate ||
                    el?.isInConversion === true ||
                    el?.isInConversion === false ||
                    el?.logo?.data ||
                    el?.object
                ) {
                    return (
                        <Box key={i} className={styles.labelBox}>
                            <Box className={styles.titleBox}>
                                <img src={el?.logo?.data} alt="label_image" className={styles.image} />
                                <Typography variant="h4" style={{ fontWeight: "normal" }}>
                                    {el?.object}
                                </Typography>
                            </Box>
                            <Box className={styles.blocsBox}>
                                {getStateImage(el?.isInConversion)}
                                <Box className={styles.titlesBox}>
                                    <Typography variant="h6" className={styles.blocTitle}>
                                        {t`Organisme de certification`}
                                    </Typography>
                                    <Typography variant="h6" className={styles.blocSubtitle}>
                                        {el?.certificationBody}
                                    </Typography>
                                </Box>
                                <Box className={styles.titlesBox} style={{ marginBottom: 0 }}>
                                    <Typography variant="h6" className={styles.blocTitle}>
                                        {t`Date de fin de validité`}
                                    </Typography>
                                    <Typography variant="h6" className={styles.blocSubtitle}>
                                        {new Date(el?.expiryDate).toLocaleDateString(locale, {
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric",
                                        })}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    );
                }
                return null;
            });
        } else {
            return <Typography>Cette propriété n&apos;a pas encore déclaré de labels</Typography>;
        }
    };

    return (
        <Popin
            background={2}
            open={props.open}
            cross
            onClose={props.onClose}
            title={t`Labels et certifications`}
            classStyleContent={styles.classStyleContent}
        >
            <Box className={styles.mainContainer}>{loading ? <Loading /> : getLabels()}</Box>
        </Popin>
    );
};

LabelsFrontPopin.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    labels: PropTypes.object,
    estateName: PropTypes.string,
    wineEntityId: PropTypes.string,
};

export default LabelsFrontPopin;
