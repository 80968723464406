import axios from "axios";
import { getApolloClient } from "../App";
import { getAuthToken } from "../features/user/firebase";
import { CreateUploadUrlDocument } from "../generated/graphql";

import { IMAGE_CROP_URL, IMAGE_CLIPANDCROP_URL, ZOHO_URL, SIREN_URL } from "../config/constants";

const resources = {};

const makeRequestCreator = () => {
    let cancel;

    return async (url) => {
        if (cancel) {
            // Cancel the previous request before making a new request
            cancel.cancel();
        }
        // Create a new CancelToken
        cancel = axios.CancelToken.source();
        try {
            if (resources[url]) {
                // Return result if it exists
                return resources[url];
            }
            const res = await axios.get(url, { cancelToken: cancel.token });
            const result = res.data;
            // Store response
            resources[url] = result;

            return result;
        } catch (error) {
            if (axios.isCancel(error)) {
                // Handle if request was cancelled
                // console.log("Request canceled", error.message);
            } else {
                // Handle usual errors
                // console.log("Something went wrong: ", error.message);
            }
        }
    };
};
export const getRequest = makeRequestCreator();
export async function dataUrlToFile(dataUrl, fileName) {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    return new File([blob], fileName, { type: "image/*" });
}
const dataURItoBlob = (dataURI) => {
    var binary = atob(dataURI?.split(",")[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/png" });
};

export const uploadBase64 = async (base64, filename, entityId, bucketName) => {
    const document = dataURItoBlob(base64);
    document.name = filename;
    return uploadDocument(document, entityId, bucketName);
};

export const uploadDocument = async (document, prefix, bucketName) => {
    const apolloClient = getApolloClient();
    let sanitizedFilename = prefix + "/" + document.name;
    const signedUrl = await apolloClient.mutate({
        mutation: CreateUploadUrlDocument,
        variables: { fileName: sanitizedFilename, bucketName },
    });
    const uploadUrl = signedUrl?.data?.CreateUploadURL.url;

    // const file = await dataUrlToFile(document.originalData, sanitizedFilename);

    await axios.put(uploadUrl, document);
};

const getContentType = (sanitizedFilename) => {
    const suffixTypes = {
        jpg: "jpeg",
        jpeg: "jpeg",
        png: "png",
        webp: "webp",
        gif: "gif",
    };
    const fileSuffix = sanitizedFilename.split(".").slice(-1)[0];
    if (Object.keys(suffixTypes).includes(fileSuffix)) {
        return "image/" + suffixTypes[fileSuffix];
    } else {
        return "image/*";
    }
};

const uploadOriginalImage = async (image, fileName, bucketName) => {
    const apolloClient = getApolloClient();
    const createdUploadUrl = await apolloClient.mutate({
        mutation: CreateUploadUrlDocument,
        variables: { fileName, bucketName },
    });
    const uploadUrl = createdUploadUrl?.data?.CreateUploadURL?.url;
    const file = await dataUrlToFile(image.originalData, fileName);

    await axios.put(uploadUrl, file, {
        headers: {
            "content-type": getContentType(fileName),
        },
    });
};

const manageImage = async (image, sourceFilename, destFilename, bucketName, option) => {
    const authToken = await getAuthToken();
    let selectedUrl;
    switch (option) {
        case "crop":
            selectedUrl = IMAGE_CROP_URL;
            break;
        case "clipandcrop":
            selectedUrl = IMAGE_CLIPANDCROP_URL;
            break;
        default:
            break;
    }
    try {
        await axios.post(
            selectedUrl,
            {
                bucketName: bucketName,
                sourceFilename: sourceFilename,
                cropData: image.cropData,
                destFilename: destFilename,
            },
            {
                headers: {
                    authToken,
                },
            }
        );
    } catch (e) {
        await axios.post(
            IMAGE_CROP_URL,
            {
                bucketName: bucketName,
                sourceFilename: sourceFilename,
                cropData: image.cropData,
                destFilename: destFilename,
            },
            {
                headers: {
                    authToken,
                },
            }
        );
    }
};

const dataImage = (image, newFilename, bucketName) => {
    return {
        fileName: newFilename,
        bucketName: bucketName,
        mainImage: image.mainImage,
        backgroundImage: image.backgroundImage,
        caption: image.caption,
        hidden: image.hidden,
    };
};

export const uploadAndCropImage = async (image, entityId, bucketName) => {
    if (image.cropData) {
        const fileNameParts = image?.filename?.split(".");
        let sanitizedFilename = fileNameParts?.slice(0, -1)?.join(".");
        sanitizedFilename += ".original." + fileNameParts?.[fileNameParts?.length - 1].toLowerCase();

        await uploadOriginalImage(image, sanitizedFilename, bucketName);
        await manageImage(image, sanitizedFilename, image.filename, bucketName, "crop");

        return dataImage(image, image.filename, bucketName);
    } else {
        return dataImage(image, image.fileName, bucketName);
    }
};

export const uploadAndClipAndCropImage = async (image, entityId, bucketName) => {
    if (image.cropData) {
        const fileNameParts = image?.filename?.split(".");
        let baseFilename = fileNameParts?.slice(0, -1)?.join(".");
        let sanitizedFilename = baseFilename + ".original." + fileNameParts?.[fileNameParts?.length - 1].toLowerCase();
        let newFilename = baseFilename + ".clip.png";

        await uploadOriginalImage(image, sanitizedFilename, bucketName);
        await manageImage(image, sanitizedFilename, newFilename, bucketName, "clipandcrop");

        return dataImage(image, newFilename, bucketName);
    } else {
        return dataImage(image, image.fileName, bucketName);
    }
};

export const getDetailsFromSiret = async (siret) => {
    const authToken = await getAuthToken();

    try {
        const result = await axios.get(`${SIREN_URL}${siret}`, {
            headers: {
                authToken,
            },
        });

        return result.data?.message;
    } catch (e) {
        return null;
    }
};

export const getSubscriptionFormPage = async (data) => {
    const authToken = await getAuthToken();

    try {
        const result = await axios.post(ZOHO_URL, data, {
            headers: {
                authToken,
            },
        });

        return result.data;
    } catch (e) {
        return null;
    }
};
