import Popin from "../../Containers/Popin";
import { bool, func, any } from "prop-types";
import { t } from "@lingui/macro";
import { makeStyles, Box, Typography } from "@material-ui/core";
import labelCertifImg from "../../assets/labels-certif-popin.svg";

const useStyles = makeStyles((theme) => ({
    container: {
        overflowY: "scroll",
        paddingRight: "5px",
        maxHeight: "350px",
        width: "98%",

        [theme.breakpoints.up("sm")]: {
            maxHeight: "340px",
            width: "87.5%",
        },

        "&::-webkit-scrollbar": { width: "8px", height: "8px" },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(99,45,62,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(99,45,62,0.00)",
            paddingRight: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.primary.main,
            borderRadius: "20px",
        },
    },
    label_container: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        alignItems: "center",
        marginBottom: "30px",
    },
    label_image: {
        gridColumn: "1 / 3",
        width: "53px",
        height: "53px",
        marginRight: "28px",
    },
    box_content: {
        gridColumn: "3 / 12",
    },
    content: {
        fontWeight: "bold",
        color: theme.palette.primary.dark,
    },
    subtitle: {
        color: theme.palette.primary.dark,
    },
}));

const LabelAndCertifPopin = (props) => {
    const styles = useStyles();

    return (
        <Popin
            background={6}
            open={props.open}
            cross
            onClose={props.onClose}
            title={`${t`Labels et certifications`} : ${props?.certificationList.length}`}
            imgTitle={labelCertifImg}
            styleImgTitle={{ marginRight: "13px" }}
            titleStyle={{ marginBottom: "0px", alignSelf: "auto" }}
            styleHeader={{ marginBottom: "41px" }}
            containerWidth="555px"
            typographyVariant="h3"
        >
            <Box className={styles.container}>
                {props?.certificationList.map((el, i) => (
                    <Box key={i} className={styles.label_container}>
                        <img src={el?.logo?.twicpicURL} className={styles.label_image} />

                        <Box className={styles.box_content}>
                            <Typography variant="body2" className={styles.content}>
                                {el?.object}
                            </Typography>
                            <Typography variant="body2" className={styles.subtitle}>
                                {el?.certificationBody}
                            </Typography>
                        </Box>
                    </Box>
                ))}
            </Box>
        </Popin>
    );
};

LabelAndCertifPopin.propTypes = {
    open: bool,
    onClose: func,
    certificationList: any,
};

export default LabelAndCertifPopin;
