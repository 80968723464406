import React, { useEffect, useState } from "react";
import { bool, func, object } from "prop-types";
import { Backdrop, Box, Icon, makeStyles, Typography } from "@material-ui/core";
import arrow from "../../assets/navbarfront/arrow_search.svg";
import fioriture from "../../assets/navbarfront/fioriture_account.svg";
import ConnectionContainer from "../../SubscriptionTunnel/Containers/ConnectionContainer";
import theme from "../../globalTheme";
import BaseButton from "../../Components/Buttons/BaseButton";
import { useWindowDimensions } from "../../helpers/helpers";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../Components/Loader/Loading";
import { signIn } from "../../features/user/userSlice";
import BrowsingPreferenceModal from "Front/browsingPreference/BrowsingPreferenceModal";
import { useCustomHistory } from "hooks";

const useStyles = makeStyles((theme) => ({
    container_account: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.background.card,
        position: "absolute",
        top: "0",
        right: "0",
        width: "436px",
        height: "100vh",
        backgroundImage: `url(${fioriture})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom left",
        [theme.breakpoints.down("sm")]: {
            width: "100vw",
            top: "0",
            left: "0",
            backgroundImage: "unset",
        },
    },
    container_title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        display: "flex",
        padding: "2rem 1rem",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        [theme.breakpoints.down("sm")]: {
            padding: "1rem 0.5rem",
        },
    },
    backIcon: {
        position: "absolute",
        left: "1rem",
        cursor: "pointer",
    },
    form_account: {
        padding: "2rem",
        [theme.breakpoints.down("md")]: {
            overflowY: "scroll",
            overflowX: "hidden",
            "&::-webkit-scrollbar": { width: "4px" },
            "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                paddingRight: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.primary.dark,
                borderRadius: "20px",
            },
        },
    },
    step_text: {
        display: "flex",
        alignItems: "start",
        margin: "0 2rem",
    },
    register: {
        [theme.breakpoints.down("sm")]: {
            display: "flex",
            justifyContent: "center",
        },
    },
    choose_subscription: {
        [theme.breakpoints.down("sm")]: {
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            background: theme.palette.common.white,
            padding: "1rem 1rem 2rem 1rem",
            borderRadius: "6px",
            marginTop: "1rem",
        },
    },
    consult_ft: {
        [theme.breakpoints.down("sm")]: {
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            background: theme.palette.background.card,
            padding: "0 1rem 2rem 1rem",
            borderRadius: "6px",
            marginTop: "1rem",
            border: `0.4px solid ${theme.palette.secondary.dark}`,
        },
    },
    text_connected: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        width: "100%",
        "&:first-child": {
            marginBottom: 24,
        },
    },
    icons_connected: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "20px",
        marginRight: "0.5rem",
    },
}));

const DownloadFt = React.forwardRef((props, ref) => {
    const [called, setCalled] = useState(false);
    const styles = useStyles();
    const history = useCustomHistory();
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();
    const settingsLocale = useSelector((state) => state?.user?.locale || "fr");
    const [warningBrowserLanguage, setWarningBrowserLanguage] = useState(settingsLocale);

    const user = useSelector((state) => state.user);
    const { currentUser, loading } = user ?? {};

    useEffect(() => {
        if (settingsLocale !== "fr") {
            setWarningBrowserLanguage(true);
        } else {
            setWarningBrowserLanguage(false);
        }
    }, [settingsLocale]);

    useEffect(() => {
        if (currentUser && called) {
            props.onClose();
        }
    }, [currentUser]);

    const handleConnectionOnSubmit = async (values) => {
        dispatch(
            signIn({
                username: values.subscriptionEmailAddress,
                password: values.subscriptionPassword,
            })
        );
        setCalled(true);
    };

    return (
        <>
            <BrowsingPreferenceModal
                open={props.open && warningBrowserLanguage}
                registrationMode
                noEnlish
                onClickBack={() => {
                    setWarningBrowserLanguage(false);
                    props.onClose();
                }}
                onClose={() => {
                    setWarningBrowserLanguage(false);
                    props.onClose();
                }}
            />
            <Backdrop open={props.open} style={{ zIndex: "1" }}>
                <Box className={styles.container_account} ref={ref}>
                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            <Box className={styles.container_title}>
                                <Icon className={styles.backIcon} onClick={() => props.onClose()}>
                                    <img src={arrow} />
                                </Icon>
                                <Typography variant="h4">Connexion</Typography>
                            </Box>
                            <Box className={styles.form_account} style={{ padding: width < 600 && "12px 7px" }}>
                                <Typography variant="h5" style={{ fontWeight: 700, marginBottom: "1rem" }}>
                                    Pour ajouter des fiches techniques à votre espace Féret,{" "}
                                    <span style={{ color: theme.palette.primary.main }}>connectez-vous</span>
                                </Typography>
                                <ConnectionContainer
                                    marginTop="10px"
                                    width={width < 960 ? "100%" : undefined}
                                    forgotPasswordOnClick={() => history.push("/bienvenue/mdp")}
                                    onSubmit={(formValues) => handleConnectionOnSubmit(formValues)}
                                    setCalled={setCalled}
                                    called={called}
                                    subtitle1
                                />
                                <hr style={{ marginTop: "3rem" }} />
                                <Box
                                    className={styles.register}
                                    style={{
                                        flexDirection: width <= 300 && "column",
                                        alignItems: width <= 300 && "center",
                                    }}
                                >
                                    <Typography variant="h5" style={{ marginRight: "4px", fontWeight: 700 }}>
                                        Vous n’avez pas de compte ?{" "}
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        style={{ color: theme.palette.primary.main, fontWeight: 700 }}
                                    >
                                        Inscrivez-vous gratuitement !
                                    </Typography>
                                </Box>
                                <BaseButton
                                    onClick={() => {
                                        history.push({
                                            pathname: `/invitation/${props.ftId}`,
                                            state: {
                                                datasheet: props.ftId,
                                            },
                                        });
                                    }}
                                    size="small"
                                    fullWidth
                                    color="primary"
                                    variant="outlined"
                                    style={{ marginTop: "1rem" }}
                                >
                                    S&apos;inscrire
                                </BaseButton>
                            </Box>
                        </>
                    )}
                </Box>
            </Backdrop>
        </>
    );
});

DownloadFt.displayName = "Account";

DownloadFt.propTypes = {
    onClose: func,
    open: bool,
    currentUser: object,
    ftId: object,
    onOpen: func,
};

export default DownloadFt;
