import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Scrollbar } from "swiper";
import PropTypes, { string } from "prop-types";
import FrontCellarCard from "../Media/FrontCellarCard";
import imgSub1 from "../../assets/propriétéPage/vinif.png";
import theme from "../../globalTheme";
import { useWindowDimensions } from "../../helpers/helpers";

import "swiper/swiper-bundle.min.css";
import "./cellarSwipe.css";

const useStyles = makeStyles(() => ({
    swiper__wrapper: {
        width: "100%",
        overflow: "hidden",
        "& .swiper-scrollbar": {
            display: "none",
        },
        "& .swiper-slide": {
            [theme.breakpoints.only("xs")]: {
                width: "100%",
            },
            [theme.breakpoints.up("sm")]: {
                width: "400px",
            },
            [theme.breakpoints.only("md")]: {
                height: "16rem",
                display: "flex",
                alignItems: "baseline",
            },
        },
        "& .swiper-pagination-bullet": {
            background: "#632d3e",
        },
    },
    swiper__up__wrapper: {
        "& .swiper-slide": {
            height: "16rem",
            display: "flex",
            alignItems: "baseline",
        },
        "& .swiper-pagination-bullet": {
            background: "#632d3e",
        },
        "& .swiper-scrollbar": {
            display: "none",
        },
    },
}));

SwiperCore.use([Pagination]);
SwiperCore.use([Scrollbar]);

const CellarsSwipeCompo = (props) => {
    const styles = useStyles();
    const { width } = useWindowDimensions();

    return (
        <Box className={width <= 960 ? styles.swiper__wrapper : styles.swiper__up__wrapper}>
            {width < 1280 ? (
                <Swiper
                    pagination={width > 1280 ? false : true}
                    slidesPerView={"auto"}
                    spaceBetween={16}
                    scrollbar={{ hide: true }}
                >
                    {props?.cellarsData?.map((el, key) => {
                        return (
                            <SwiperSlide key={key} style={{ width: width >= 600 && "fit-content" }}>
                                <FrontCellarCard
                                    wineEntityName={props.wineEntityName}
                                    locale={props.locale}
                                    cellarId={el?.cellarId}
                                    wineEntityId={props.wineEntityId}
                                    style={{ marginRight: theme.spacing(3) }}
                                    picture={el?.images?.[0]?.twicpicURL || imgSub1}
                                    cellarName={el?.name}
                                    surfaceAreaValue={el?.surfaceArea}
                                    isConditioning={el?.isConditioning}
                                    isStorage={el?.isStorage}
                                    isVathouse={el?.isVathouse}
                                    isAgeing={el?.isAgeing}
                                />
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            ) : (
                <Box style={{ display: "flex", gap: "32px", flexWrap: "wrap" }}>
                    {props?.cellarsData?.map((el, key) => {
                        return (
                            <FrontCellarCard
                                wineEntityName={props.wineEntityName}
                                locale={props.locale}
                                key={key}
                                cellarId={el?.cellarId}
                                wineEntityId={props.wineEntityId}
                                style={{ marginRight: theme.spacing(3) }}
                                picture={el?.images?.[0]?.twicpicURL || imgSub1}
                                cellarName={el?.name}
                                surfaceAreaValue={el?.surfaceArea}
                                isConditioning={el?.isConditioning}
                                isStorage={el?.isStorage}
                                isVathouse={el?.isVathouse}
                                isAgeing={el?.isAgeing}
                            />
                        );
                    })}
                </Box>
            )}
        </Box>
    );
};

export default CellarsSwipeCompo;

CellarsSwipeCompo.propTypes = {
    cellarsData: PropTypes.object,
    wineEntityId: PropTypes.number,
    locale: PropTypes.string,
    wineEntityName: string,
};
