import React from "react";
import PropTypes from "prop-types";
import { Box, makeStyles } from "@material-ui/core";
import theme from "../globalTheme";

const useStyles = makeStyles(() => {
    return {
        root: (props) => ({
            boxSizing: "border-box",
            position: "relative",
            borderRadius: 20,
            backgroundColor: theme.palette.common.white,
            padding: "7px 10px",
            width: "calc(100%)",
            maxWidth: 1243,
            marginTop: 28,

            [theme.breakpoints.up("sm")]: {
                marginTop: 50,
                margin: "0 auto",
            },

            [theme.breakpoints.up("md")]: {
                padding: !props?.searchOnly ? "7px 24px" : "7px",
            },

            [theme.breakpoints.up("lg")]: {
                marginTop: 33,
                width: !props?.searchOnly ? "calc(100% - 20px)" : "100%",
            },

            [theme.breakpoints.up("xl")]: {
                marginTop: 76,
                padding: !props?.searchOnly ? "7px 32px" : "7px",
            },
        }),
        cardContent: {
            position: "relative",
            zIndex: 2,
            padding: 0,
            paddingBottom: "0px !important",
            width: "100%",
        },
    };
});

const SearchMainContainer = (props) => {
    const styles = useStyles(props);

    return (
        <>
            <Box className={styles.root} style={props?.style}>
                <Box className={styles.cardContent}>{props.children}</Box>
            </Box>
        </>
    );
};

SearchMainContainer.propTypes = {
    children: PropTypes.any,
    searchOnly: PropTypes.bool,
    style: PropTypes.object,
};

export default SearchMainContainer;
