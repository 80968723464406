import { t } from "@lingui/macro";
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

import theme from "../../globalTheme";
import SurfaceBlock from "./SurfaceBlock";

const PopinSurfacesBlocks = (props) => {

    if (!props.vineyards || props.vineyards?.length === 0) {
        return <Typography style={{ color: theme.palette.secondary.dark, fontWeight: "bold", border: "1px solid", borderColor: theme.palette.secondary.dark, borderRadius: "5px", padding: "10px 23px" }}>
            {t`Information non renseignée`}
        </Typography>;
    } else {
        return props.vineyards?.map((vineyard, i) => {
            if (
                props.hidden[i]?.ageMoyenDesVignes
                && props.hidden[i]?.communes
                && props.hidden[i]?.densiteDePlantation
                && props.hidden[i]?.encepagements
                && props.hidden[i]?.rootstocks
                && props.hidden[i]?.vinesCount
            ) {
                return "";
            } else {
                return (
                    <SurfaceBlock
                    locale={props.locale}
                        hidden={props.hidden[i]}
                        key={i}
                        vineyard={vineyard}
                        surfaceImportCVI={props.surfaceImportCVI}
                    />
                );
            }

        });
    }
};

PopinSurfacesBlocks.propTypes = {
    vineyards: PropTypes.object,
    surfaceImportCVI: PropTypes.bool,
    locale : PropTypes.string
};

export default PopinSurfacesBlocks;
