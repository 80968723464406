import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, makeStyles } from "@material-ui/core";

import FrontMainContainer from "../Front/FrontMainContainer";
import FrontContentTop from "../Front/FrontContentTop";
import FrontTitle from "../FrontRanking/FrontTitle";
import bg from "../assets/bg-formation.jpg";
import theme from "../globalTheme";
import EstateTitleSemiUnderlined from "../FrontEstate/Components/EstateTitledSemiUnderlined";
import SocialMediaIcons from "../Components/Icons/SocialMediaIcons";
import { getTextLength, parseHtml, useWindowDimensions } from "../helpers/helpers";
import FormationSummary from "../FrontFormation/FormationSummary";
import { SwiperWrapper } from "../Front/SwiperCards";
import fioriture3 from "../assets/appellation/floriture3-big.svg";
import fioriture1 from "../assets/formation/floriture1.svg";
import courses from "../assets/formation/courses.svg";
import logoBg from "../assets/formation/logo-bg.svg";
import ExpandableFormattedTextBox from "../Components/Texts/ExpandableFormattedTextBox";
import { useGetFormationOrganismsFrontQuery, useGetFormationOrganismsSampleQuery } from "../generated/graphql";
import { t, Trans } from "@lingui/macro";
import Page404 from "Components/ErrorPage/Page404";
import Page500Front from "Components/ErrorPage/Page500Front";
import LoadingWithImage from "Components/Loader/LoadingWithImage";
import Helmet from "Components/Helmet/Helmet";
import FormationCard from "FrontFormation/FormationCard";

const useStyles = makeStyles((e) => ({
    title_subtitle: {
        fontFamily: "Lato",
        fontWeight: "normal",
        color: theme.palette.common.white,
        textTransform: "uppercase",
        textAlign: "center",
        marginBottom: "5px",

        [theme.breakpoints.up("xl")]: {
            marginBottom: "45px",
        },
    },
    container_card: {
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
        },
    },
    container_content_top: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        gap: "26px",

        [theme.breakpoints.up("md")]: {
            marginTop: "-45px",
        },
    },
    container_image_logo: {
        gridColumn: "8 / span 12",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 0,
    },
    socialMedia: {
        order: 1,
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: "15px",

        [theme.breakpoints.up("lg")]: {
            order: 2,
        },

        [theme.breakpoints.up("xl")]: {
            right: 64,
            top: 42,
        },
    },
    container_content_secondary: {
        gridColumn: "1 / span 12",

        [theme.breakpoints.up("sm")]: {
            background: `url(${logoBg})`,
            backgroundRepeat: "no-repeat",
            backgroundPositionY: "85%",
            backgroundPositionX: "50%",
        },

        [theme.breakpoints.up("md")]: {
            backgroundPositionY: "80px",
            backgroundPositionX: "90%",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "9 / span 4",
            marginTop: "45px",
            backgroundPositionY: "280px",
            backgroundPositionX: "50%",
        },

        [theme.breakpoints.up("xl")]: {
            backgroundPositionY: "370px",
        },
    },
    container_content_primary: {
        gridColumn: "1 / span 12",

        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 8",
        },
    },
    container_infos_right: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",

        [theme.breakpoints.up("lg")]: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
        },
    },
    center_responsive: {
        gridColumn: "1 / span 6",
        width: "100%",

        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 12",
        },
    },
    content_text: {
        color: theme.palette.primary.dark,
        textAlign: "justify",
    },
    container_slider_1: {
        [theme.breakpoints.down("600")]: {
            paddingLeft: "16px",
            paddingRight: "16px",
        },
    },
    container_content_secondary_title: {
        marginTop: "30px",
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(1),
    },
    icon_content_secondary_title: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 24,
        height: 19,
    },
    formation_text: {
        color: theme.palette.primary.dark,

        "& *": {
            textAlign: "justify",
        },

        "& p p": {
            marginBottom: 0,
            marginTop: 0,
        },
    },
    image_logo: {
        maxWidth: "100%",
    },
}));

const FormationPage = () => {
    const styles = useStyles();
    const locale = useSelector((state) => state?.user?.locale || "fr");
    const { width } = useWindowDimensions();
    const [formationOrganism, setFormationOrganism] = useState(null);
    const { name } = useParams();
    const [region, setRegion] = useState();

    const {
        data: { GetFormationOrganismsFront: formationOrganisms } = {},
        loading: loading,
        error: errorGetFormationOrganisms,
    } = useGetFormationOrganismsFrontQuery({
        variables: {
            name: name,
            locale,
        },
    });

    const {
        data: { GetFormationOrganismsSample: formationOrganismList } = {},
        error,
    } = useGetFormationOrganismsSampleQuery({
        variables: {
            name: name,
            locale,
        },
    });

    useEffect(() => {
        if (formationOrganisms?.length > 0) {
            const currentFormationOrganism = formationOrganisms[0];
            setFormationOrganism(currentFormationOrganism);
            setRegion(currentFormationOrganism.address?.postalCode?.slice(0, 2));
        }
    }, [formationOrganisms, name]);

    const breadcrumbs = [
        {
            name: t`Organismes du vignoble bordelais`,
            path: "/organismes-vignoble-bordelais",
        },
        {
            name: t`Formations`,
            path: "/organismes-vignoble-bordelais/formation",
        },
        {
            name: formationOrganism?.name,
        },
    ];

    if (loading) {
        return <LoadingWithImage />;
    }

    if (!formationOrganism) {
        return <Page404 />;
    }

    if (errorGetFormationOrganisms || error) {
        return <Page500Front />;
    }

    return (
        <>
            <Helmet
                title={formationOrganism?.name}
                content={`${formationOrganism?.name} : l'un des organismes de formation de référence du vignoble bordelais. Découvrez ses formations de qualité proposées dans le domaine viticole. Accédez à un programme complet dispensé par des professionnels expérimentés pour devenir un expert en la matière.`}
            />

            <FrontContentTop
                backgroundImage={bg}
                links={breadcrumbs}
                previousPage={{
                    name: t`Toutes les formations`,
                    path: "/organismes-vignoble-bordelais/formation",
                }}
            >
                <FrontTitle className={styles.frontTitle} title={formationOrganism?.name} />
                <Typography variant="body2" className={styles.title_subtitle}>
                    {formationOrganism?.address?.commune} {region && `(${region})`}
                </Typography>

                <FrontMainContainer
                    styleCardContent
                    className={styles.main_container}
                    style={{
                        backgroundImage: width > 600 ? `url(${fioriture1}), url(${fioriture3})` : `url(${fioriture1})`,
                        backgroundPositionY: width < 1280 ? "bottom, 60%" : "bottom, 30%",
                        backgroundPositionX: "right, right",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    <Box className={styles.container_card}>
                        <Box className={styles.socialMedia}>
                            <SocialMediaIcons socialMedias={formationOrganism?.socialMedias} />
                        </Box>
                        <Box className={styles.container_content_top}>
                            <Box className={styles.container_content_primary}>
                                <EstateTitleSemiUnderlined
                                    title={t`À propos`}
                                    heightAfter={32}
                                    style={{ marginTop: "0px" }}
                                    lowBar={{ width: 75 }}
                                    borderColor={theme.palette.primary.main}
                                />

                                <Box>
                                    <Typography variant="body2" className={styles.content_text}>
                                        {getTextLength(formationOrganism?.about) > 1690 ? (
                                            <ExpandableFormattedTextBox
                                                value={formationOrganism?.about}
                                                truncatedValue={`${formationOrganism?.about?.slice(0, 1690)}...`}
                                            />
                                        ) : (
                                            parseHtml(formationOrganism?.about, "body2")
                                        )}
                                    </Typography>
                                </Box>

                                <Box style={{ position: "relative", zIndex: 1 }}>
                                    <Box className={styles.container_content_secondary_title}>
                                        <img
                                            src={courses}
                                            className={styles.icon_content_secondary_title}
                                            alt="icon courses"
                                        />

                                        <Typography
                                            variant="h4"
                                            style={{
                                                color: theme.palette.text.primary,
                                                marginLeft: theme.spacing(1),
                                            }}
                                            className={styles.tabPanelTitle}
                                        >
                                            <Trans>Formations proposées relatives au monde du vin</Trans>
                                        </Typography>
                                    </Box>

                                    <Typography variant="body2" className={styles.formation_text}>
                                        {getTextLength(formationOrganism?.formations) > 1690 ? (
                                            <ExpandableFormattedTextBox
                                                value={formationOrganism?.formations}
                                                truncatedValue={`${formationOrganism?.formations?.slice(0, 1690)}...`}
                                            />
                                        ) : (
                                            parseHtml(formationOrganism?.formations, "body2")
                                        )}
                                    </Typography>
                                </Box>
                            </Box>

                            <Box className={styles.container_content_secondary}>
                                <Box className={styles.container_infos_right}>
                                    <Box
                                        className={styles.center_responsive}
                                        style={{ gridColumn: width < 700 ? "1 / span 12" : "1 / span 6" }}
                                    >
                                        <FormationSummary
                                            formation={formationOrganism?.rhythm}
                                            howToRegister={formationOrganism?.link}
                                            contact={{
                                                ...formationOrganism?.address,
                                                phone: formationOrganism?.phone,
                                                email: formationOrganism?.email,
                                            }}
                                        />
                                    </Box>

                                    <Box
                                        className={styles.container_image_logo}
                                        style={{
                                            gridColumn: width < 700 ? "1 / span 12" : "8/ span 12",
                                            marginTop: "24px",
                                        }}
                                    >
                                        <img
                                            src={formationOrganism?.logo?.twicpicURL}
                                            className={styles.image_logo}
                                            alt="primary logo"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </FrontMainContainer>

                <Box className={styles.container_slider_1}>
                    <SwiperWrapper
                        title={t`D’autres formations relatives au monde du vin`}
                        spaceBetween={0}
                        noMargin
                    >
                        {formationOrganismList?.map((e, i) => (
                            <FormationCard key={i} title={e?.name} logo={e?.logo?.twicpicURL} />
                        ))}
                    </SwiperWrapper>
                </Box>
            </FrontContentTop>
        </>
    );
};

export default FormationPage;
