import { Box, Card, CardContent, Typography, makeStyles } from "@material-ui/core";
import PropTypes, { bool, number, object, string } from "prop-types";
import badge from "../../assets/search/badge.svg";
import dfltImg from "../../assets/searchEngine/estate/thumbs/estate-3.jpg";
import theme from "../../globalTheme";

import clsx from "clsx";
import { useCustomHistory } from "hooks";
import { Link } from "react-router-dom";
import Loading from "../../Components/Loader/Loading";
import { useGetWineEntityCardInformationQuery } from "../../generated/graphql";
import { urlFormatted } from "../../helpers/helpers";

const useStyles = makeStyles(() => ({
    root: (props) => ({
        background: theme.palette.common.white,
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.08)",
        borderRadius: 10,
        marginBottom: props?.marginBottom ?? "1rem",
        cursor: "pointer",

        "&:hover .highlight-text": {
            height: "100%",
            background: "rgba(54, 3, 24, 0.9)",
        },
        "&:hover .highlight-subtext": {
            display: "block",
        },
        "&:hover .highlight-name": {
            fontWeight: "700",
        },

        [theme.breakpoints.up("xs")]: {
            marginBottom: 0,
        },
    }),
    cardContent: {
        padding: "0",
        height: "100%",
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
        "&:last-child": {
            paddingBottom: "0",
        },
    },
    status: {
        boxSizing: "border-box",
        position: "absolute",
        bottom: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background:
            "linear-gradient(180deg, rgba(54, 3, 24, 0) 0%, rgba(54, 3, 24, 0.7) 48.51%, rgba(54, 3, 24, 0.7) 100%);",
        width: "100%",
        height: "70px",
        padding: "0em 8px",
    },
    name: {
        color: theme.palette.common.white,
        fontFamily: "lato",
        fontWeight: "400",
        maxWidth: "85%",
        textAlign: "center",
    },
    subname: {
        display: "none",
        color: theme.palette.common.white,
        fontWeight: 300,
    },
    imgEstate: {
        width: "100% !important",
        height: "100% !important",

        [theme.breakpoints.up("sm")]: {
            borderRadius: "16px",
        },
    },
}));

const HomeEstateCard = (props) => {
    const styles = useStyles();
    const history = useCustomHistory();
    const { data, loading } = useGetWineEntityCardInformationQuery({
        variables: {
            wineEntityId: props.wineEntityId,
        },
    });

    if (props.arrayLoading && loading) {
        return <Loading />;
    }

    const cardInformation = data?.GetWineEntityCardInformation;

    return (
        <Card
            className={styles.root}
            style={{ width: props.width ?? "auto", height: props.height ?? "auto", margin: props.margin ?? "auto" }}
        >
            <Link
                style={{ textDecoration: "none" }}
                to={history.addLocale(
                    `/${urlFormatted(props.estate?.wineEntityShortId, props.estate?.wineEntityName)}`
                )}
            >
                <CardContent className={styles.cardContent}>
                    <img
                        src={cardInformation?.img?.twicpicURL ? cardInformation?.img?.twicpicURL : dfltImg}
                        className={styles.imgEstate}
                        alt="estate photo"
                    />

                    <Box className={clsx(styles.status, "highlight-text")}>
                        <Typography variant="h4" className={clsx(styles.name, "highlight-name")}>
                            {props?.name}

                            <img
                                src={badge}
                                alt="badge Feret"
                                style={{ width: "13px", height: "13px", display: "inline" }}
                            />
                        </Typography>
                        <Typography variant="body1" className={clsx(styles.subname, "highlight-subtext")}>
                            {props?.appellation}
                        </Typography>
                    </Box>
                </CardContent>
            </Link>
        </Card>
    );
};

HomeEstateCard.propTypes = {
    estate: object,
    wineEntityId: string,
    name: string,
    badge: string,
    className: object,
    width: string,
    height: string,
    margin: string,
    appellation: string,
    arrayLoading: bool,
};

export default HomeEstateCard;
