import { Trans } from "@lingui/macro";
import PropTypes from "prop-types";

// Components:
import { Typography, makeStyles, Box } from "@material-ui/core";
import Popin from "../../Containers/Popin";
import RadarChart from "../../Components/Radar/RadarChart";

// Methods:
import { getChips } from "../../helpers/helpers";

// Assets:
import palais from "../../assets/Wine/advices/palais.svg";

// Styles:
const useStyle = makeStyles((theme) => ({
    frontAromasPopin: {
        borderRadius: "10px",
    },
    frontAromasPopin__header: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(1),
    },
    frontAromasPopin__titleImg: {
        marginRight: "4px",
    },
    frontAromasPopin__body: {
        display: "flex",
        [theme.breakpoints.down("md")]: {
            justifyContent: "left",
            overflowY: "scroll",
            width: "430px",
        },
        [theme.breakpoints.up("xs")]: {
            flexWrap: "wrap",
        },
        [theme.breakpoints.up("md")]: {
            flexWrap: "nowrap",
        },
    },
    frontAromasPopin__radar: {
        width: "50%",
        paddingTop: "25px",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
        [theme.breakpoints.down("xs")]: {
            margin: "auto",
        },
    },
    frontAromasPopin__list: {
        alignItems: "flex-start",
        display: "flex",
        flexWrap: "wrap",
        marginLeft: "40px",
        width: "500px",
        [theme.breakpoints.down("md")]: {
            justifyContent: "center",
            width: "100%",
            "&::after": {
                content: '""',
                height: 0,
                marginRight: "20px",
                width: "40%",
            },
        },
        [theme.breakpoints.only("sm")]: {
            marginLeft: 0,
            justifyContent: "center",
        },
        [theme.breakpoints.only("xs")]: {
            justifyContent: "end",
            margin: 0,
        },
    },
    frontAromasPopin__item: {
        marginRight: "20px",
        width: "40%",
    },
    frontAromasPopin__firstTypo: {
        color: "#915868",
        fontFamily: "Lato",
        fontSize: "14px",
    },
    frontAromasPopin__secondTypo: {
        color: "black",
        fontSize: "16px",
    },
}));

const FrontAromasPopin = (props) => {
    const styles = useStyle(props);

    return (
        <Popin
            title={
                <Box className={styles.frontAromasPopin__header}>
                    <img src={palais} alt="climat_image" className={styles.frontAromasPopin__titleImg} />
                    <Typography variant="h3" className={styles.frontAromasPopin__title}>
                        <Trans>Arômes gustatifs</Trans>
                    </Typography>
                </Box>
            }
            open={props.modalOpen}
            onClose={props.handleClose}
            cross
            background={3}
        >
            <Box className={styles.frontAromasPopin}>
                <Box className={styles.frontAromasPopin__body}>
                    <Box className={styles.frontAromasPopin__radar}>
                        <RadarChart options={props.chartOptions} series={props.chartSeries} height={250} />
                    </Box>
                    <Box className={styles.frontAromasPopin__list}>
                        {props.advices?.aromaVegetal && (
                            <Box className={styles.frontAromasPopin__item}>
                                <Typography className={styles.frontAromasPopin__firstTypo}>
                                    <Trans>Arômes végétaux</Trans>
                                </Typography>
                                <Typography className={styles.frontAromasPopin__secondTypo}>
                                    {getChips(props.advices?.aromaVegetal, true)}
                                </Typography>
                            </Box>
                        )}

                        {props.advices?.aromaFermented && (
                            <Box className={styles.frontAromasPopin__item}>
                                <Typography className={styles.frontAromasPopin__firstTypo}>
                                    <Trans>Arômes fermentaires</Trans>
                                </Typography>
                                <Typography className={styles.frontAromasPopin__secondTypo}>
                                    {getChips(props.advices?.aromaFermented, true)}
                                </Typography>
                            </Box>
                        )}

                        {props.advices?.aromaFruity && (
                            <Box className={styles.frontAromasPopin__item}>
                                <Typography className={styles.frontAromasPopin__firstTypo}>
                                    <Trans>Arômes fruités</Trans>
                                </Typography>
                                <Typography className={styles.frontAromasPopin__secondTypo}>
                                    {getChips(props.advices?.aromaFruity, true)}
                                </Typography>
                            </Box>
                        )}

                        {props.advices?.aromaFloral && (
                            <Box className={styles.frontAromasPopin__item}>
                                <Typography className={styles.frontAromasPopin__firstTypo}>
                                    <Trans>Arômes floraux</Trans>
                                </Typography>
                                <Typography className={styles.frontAromasPopin__secondTypo}>
                                    {getChips(props.advices?.aromaFloral, true)}
                                </Typography>
                            </Box>
                        )}

                        {props.advices?.aromaConfectionery && (
                            <Box className={styles.frontAromasPopin__item}>
                                <Typography className={styles.frontAromasPopin__firstTypo}>
                                    <Trans>Arômes de confiserie</Trans>
                                </Typography>
                                <Typography className={styles.frontAromasPopin__secondTypo}>
                                    {getChips(props.advices?.aromaConfectionery, true)}
                                </Typography>
                            </Box>
                        )}

                        {props.advices?.aromaEmpyreumatic && (
                            <Box className={styles.frontAromasPopin__item}>
                                <Typography className={styles.frontAromasPopin__firstTypo}>
                                    <Trans>Arômes empyreumatiques</Trans>
                                </Typography>
                                <Typography className={styles.frontAromasPopin__secondTypo}>
                                    {getChips(props.advices?.aromaEmpyreumatic, true)}
                                </Typography>
                            </Box>
                        )}

                        {props.advices?.aromaMineral && (
                            <Box className={styles.frontAromasPopin__item}>
                                <Typography className={styles.frontAromasPopin__firstTypo}>
                                    <Trans>Arômes minéraux</Trans>
                                </Typography>
                                <Typography className={styles.frontAromasPopin__secondTypo}>
                                    {getChips(props.advices?.aromaMineral, true)}
                                </Typography>
                            </Box>
                        )}

                        {props.advices?.aromaOaky && (
                            <Box className={styles.frontAromasPopin__item}>
                                <Typography className={styles.frontAromasPopin__firstTypo}>
                                    <Trans>Arômes boisés</Trans>
                                </Typography>
                                <Typography className={styles.frontAromasPopin__secondTypo}>
                                    {getChips(props.advices?.aromaOaky, true)}
                                </Typography>
                            </Box>
                        )}

                        {props.advices?.aromaSpicy && (
                            <Box className={styles.frontAromasPopin__item}>
                                <Typography className={styles.frontAromasPopin__firstTypo}>
                                    <Trans>Arômes épicés</Trans>
                                </Typography>
                                <Typography className={styles.frontAromasPopin__secondTypo}>
                                    {getChips(props.advices?.aromaSpicy, true)}
                                </Typography>
                            </Box>
                        )}

                        {props.advices?.aromaAnimal && (
                            <Box className={styles.frontAromasPopin__item}>
                                <Typography className={styles.frontAromasPopin__firstTypo}>
                                    <Trans>Arômes épicés</Trans>
                                </Typography>
                                <Typography className={styles.frontAromasPopin__secondTypo}>
                                    {getChips(props.advices?.aromaAnimal, true)}
                                </Typography>
                            </Box>
                        )}

                        {props.advices?.aromaBalsamic && (
                            <Box className={styles.frontAromasPopin__item}>
                                <Typography className={styles.frontAromasPopin__firstTypo}>
                                    <Trans>Arômes épicés</Trans>
                                </Typography>
                                <Typography className={styles.frontAromasPopin__secondTypo}>
                                    {getChips(props.advices?.aromaBalsamic, true)}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        </Popin>
    );
};

FrontAromasPopin.propTypes = {
    modalOpen: PropTypes.bool,
    handleClose: PropTypes.func,
    advices: PropTypes.object,
    chartSeries: PropTypes.object,
    chartOptions: PropTypes.object,
};

export default FrontAromasPopin;
