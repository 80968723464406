import { Route, Switch, useRouteMatch } from "react-router-dom";
import Page404 from "Components/ErrorPage/Page404";
import FrontFooter from "Front/footer/FrontFooter";
import FrontNavbar from "Front/FrontNavbar";

import FirstStepActivity from "./FirstStepActivity";
import FirstStepQrcode from "./FirstStepQrcode";
import FirstStepAll from "./FirstStepAll";
import FirstStepEstate from "./FirstStepEstate";
import FirstStepWine from "./FirstStepWine";
import SearchEngineHome from "./SearchEngineHome";
import SearchResultActivity from "./SearchResultActivity";
import SearchResultAll from "./SearchResultAll";
import SearchResultEstate from "./SearchResultEstate";
import SearchResultWine from "./SearchResultWine";
import SearchResultQrcode from "./SearchResultQrcode";
import { ALL_LANGUAGES } from "config/constants";
import ScrollToTop from "Containers/ScrollToTop";
import { SEOLinks } from "Routes/FrontRoutes";
import { RestrictedLanguageRoute } from "Routes/RestrictedLanguageRoute";

const SearchRouter = () => {
    const { url } = useRouteMatch();

    return (
        <>
            <FrontNavbar />
            <ScrollToTop />
            <Switch>
                {/*-------------------- WINE --------------------*/}
                <Route exact path={`${url}/vins/resultats`}>
                    <SearchResultWine />
                </Route>
                <Route exact path={`${url}/vins`}>
                    <SEOLinks languages={ALL_LANGUAGES} />
                    <FirstStepWine />
                </Route>
                {/*-------------------- ESTATE --------------------*/}
                <Route path={`${url}/proprietes/resultats`}>
                    <SearchResultEstate />
                </Route>
                <Route path={`${url}/proprietes`}>
                    <SEOLinks languages={ALL_LANGUAGES} />
                    <FirstStepEstate />
                </Route>
                {/*-------------------- ACTIVITY --------------------*/}
                <Route path={`${url}/activites/resultats`}>
                    <SearchResultActivity />
                </Route>
                <Route path={`${url}/activites`}>
                    <SEOLinks languages={ALL_LANGUAGES} />
                    <FirstStepActivity />
                </Route>
                {/*-------------------- ALL --------------------*/}
                <Route path={`${url}/tout/resultats`}>
                    <SearchResultAll />
                </Route>
                <Route path={`${url}/tout`}>
                    <SEOLinks languages={ALL_LANGUAGES} />
                    <FirstStepAll />
                </Route>
                {/*-------------------- QRCODE --------------------*/}
                <Route path={`${url}/qrcode/resultats`}>
                    <SearchResultQrcode />
                </Route>
                <Route path={`${url}/qrcode`}>
                    <FirstStepQrcode />
                </Route>
                {/*-------------------- SEARCH & ERROR --------------------*/}
                <RestrictedLanguageRoute languages={ALL_LANGUAGES} path={url} exact>
                    <SEOLinks languages={ALL_LANGUAGES} />
                    <SearchEngineHome />
                </RestrictedLanguageRoute>
                <Route path="*">
                    <Page404 />
                </Route>
            </Switch>
            <FrontFooter />
        </>
    );
};

SearchRouter.propTypes = {};

export default SearchRouter;
