import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Typography, makeStyles, AccordionSummary, Accordion, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import FrontMainContainer from "../Front/FrontMainContainer";
import FrontContentTop from "../Front/FrontContentTop";
import FrontTitle from "../FrontRanking/FrontTitle";
import bg from "../assets/bg-appellation.jpg";
import theme from "../globalTheme";
import EstateTitleSemiUnderlined from "../FrontEstate/Components/EstateTitledSemiUnderlined";
import {
    firstLetterUppercase,
    getTextLength,
    isNotEmpty,
    parseHtml,
    urlize,
    useWindowDimensions,
} from "../helpers/helpers";
import AppellationSummary from "../FrontAppellation/AppellationSummary";
import { SwiperWrapper } from "../Front/SwiperCards";
import fioriture3 from "../assets/rankingSummary/fioriture3.svg";
import fioriture3Big from "../assets/appellation/floriture3-big.svg";
import fioriture1 from "../assets/rankingSummary/fioriture1.svg";
import AppellationTabs from "../FrontAppellation/tabs/AppellationTabs";

import floriture1 from "../assets/appellation/floriture-1.svg";
import floriture2 from "../assets/appellation/floriture-2.svg";
import CmsAccordion from "../Front/cms/CmsAccordion";

import ExpandableFormattedTextBox from "../Components/Texts/ExpandableFormattedTextBox";
import {
    useGetAppellationDescriptionsFrontQuery,
    useGetAppellationDescriptionsSampleQuery,
} from "../generated/graphql";
import { WineImages } from "../FrontAppellation/WineImages";
import location from "../assets/appellation/location.svg";
import { Plural, t } from "@lingui/macro";
import LoadingWithImage from "Components/Loader/LoadingWithImage";
import Helmet from "Components/Helmet/Helmet";
import AppellationRegionCard from "FrontAppellation/AppellationRegionCard";

const useStyles = makeStyles(() => ({
    container_card: {
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
        },
    },
    container_content_top: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        gap: "26px",
    },
    container_image_primary_logo: {
        gridColumn: "8 / span 12",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 0,

        [theme.breakpoints.up("md")]: {
            marginTop: 43,
        },

        [theme.breakpoints.up("lg")]: {
            marginTop: 60,
        },

        [theme.breakpoints.up("xl")]: {
            marginTop: 40,
        },
    },
    image_primary_logo: {
        width: "100%",
        maxWidth: 370,
    },
    container_other_rankings: {
        justifyContent: "center",
        paddingLeft: "16px",
        paddingRight: "16px",

        [theme.breakpoints.up("xl")]: {
            width: "calc(1314px)",
            margin: "auto",
        },
    },
    container_appellation_region: {
        [theme.breakpoints.up("xl")]: {
            backgroundColor: theme.palette.common.white,
            backgroundImage: `url(${floriture1}), url(${floriture2})`,
            backgroundPositionY: "48px, top",
            backgroundPositionX: "left, right",
            backgroundRepeat: "no-repeat",
        },
    },
    container_appelation_content: {
        justifyContent: "center",
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingBottom: 38,
        background: theme.palette.common.white,

        [theme.breakpoints.up("xl")]: {
            width: "calc(1314px)",
            margin: "auto",
        },
    },
    container_content_secondary: {
        gridColumn: "1 / span 12",

        [theme.breakpoints.up("md")]: {
            gridColumn: "9 / span 4",
            marginLeft: "24px",
            marginTop: "45px",
        },
    },
    container_content_primary: {
        gridColumn: "1 / span 12",

        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 8",
        },
    },
    container_title_secondary: {
        marginTop: "16px",
    },
    container_title_third: {
        marginTop: "27px",
    },
    container_infos_right: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",

        [theme.breakpoints.up("md")]: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
        },
    },
    center_responsive: {
        gridColumn: "1 / span 6",
        width: "100%",

        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 12",
        },
    },
    container_tabs: {
        width: "100%",
        gridColumn: "1 / span 12",
    },
    content_text: {
        color: theme.palette.primary.dark,
        "& *": {
            textAlign: "justify",
        },
        "& p p": {
            marginBottom: 0,
            marginTop: 0,
        },
    },
    box_container_img_top: {
        display: "flex",
        justifyContent: "center",
        marginBottom: "5px",

        [theme.breakpoints.up("lg")]: {
            marginBottom: "14px",
        },
    },
    img_top: {
        width: 23,
        height: 48,
        marginTop: "-30px",

        [theme.breakpoints.up("lg")]: {
            width: 30,
            height: 63,
        },
    },
    root: {
        width: "100%",
        padding: "0px",
    },
    accordion: {
        padding: "0px",
        background: "transparent",
        border: "none",
        boxShadow: "none",

        "& .Mui-expanded": {
            marginTop: "0px",
        },
        "&:before": {
            height: 0,
        },
    },
    accordion_container_items: {
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap",
    },
    accordion_container_item_single: (props) => ({
        background: theme.palette.primary.light,
        color: theme.palette.common.white,
        marginBottom: props?.itemMarginBottom ?? "6px",
        marginRight: props?.itemMarginRight ?? "5px",
        borderRadius: "4px",
        padding: props?.itemPadding ?? "4px",
    }),
    accordion_title: {
        // ...theme.front.typography.h6.dark_b,
        color: theme.palette.common.black,
        marginRight: theme.spacing(1),
    },
    accordion_content_title: {
        fontFamily: theme.typography.fontFamily,
        // ...theme.front.typography.label.darkLight,
    },
    accordion_summary: {
        justifyContent: "flex-start",
        padding: "0px",

        "&.Mui-expanded": {
            minHeight: 48,
        },

        "& .MuiAccordionSummary-content": {
            flexGrow: 0,
            marginTop: 0,
            marginBottom: 0,
        },

        "& .MuiIconButton-root": {
            padding: 0,
            marginTop: 3,
            color: theme.palette.primary.dark,
        },
    },
    accordion_details: {
        flexWrap: "wrap",
        padding: "0px",
    },
    icon: (props) => ({
        marginRight: "5px",
        marginTop: "3px",
        width: props?.iconStyle?.width ?? "20px",
        height: props?.iconStyle?.height ?? "20px",
    }),
    accordion_description: {
        color: theme.palette.primary.dark,
    },
    AppellationWineCardContainer: {
        width: "19rem",
    },
}));

const customStyles = {
    FrontTitle: {
        paddingTop: "30px",
        paddingBottom: "30px",
        maxWidth: "none",
    },
};

const AppellationPage = () => {
    const styles = useStyles();
    const { width } = useWindowDimensions();
    const { appellation, region } = useParams();
    const locale = useSelector((state) => state?.user?.locale || "fr");
    const [geographyList, setGeographyList] = useState();
    const [index, setIndex] = useState(null);

    const {
        data: { GetAppellationDescriptionsFront: appellations } = {},
        loading,
    } = useGetAppellationDescriptionsFrontQuery({ variables: { locale, name: appellation } });

    const {
        data: { GetAppellationDescriptionsSample: appellationSample } = {},
    } = useGetAppellationDescriptionsSampleQuery();

    // const { data: { GetAppellationVintageIds: vintageIds } = {} } = useGetAppellationVintageIdsQuery({
    //     variables: { label: appellation },
    // });

    const appellationItem = appellations?.[0];

    useEffect(() => {
        if (appellationItem) {
            setGeographyList([
                {
                    title: t`Aire géographique`,
                    number: appellationItem?.geoArea?.length,
                    description: t`La récolte des raisins, la vinification, l’élaboration et l’élevage des vins sont assurés sur le territoire des communes ou parties de communes suivantes ;`,
                    content: appellationItem?.geoArea,
                },
                {
                    title: t`Aire de proximité immédiate`,
                    number: appellationItem?.proximityArea?.length,
                    description: t`Définie par dérogation pour la vinification, l’élaboration et l’élevage des vins, l'aire de proximité immédiate est constituée par le territoire des communes suivantes ;`,
                    content: appellationItem?.proximityArea,
                },
            ]);
        }
    }, [appellationItem]);

    const winesProduced = firstLetterUppercase(appellationItem?.winesProduced?.toLocaleLowerCase());

    if (loading || !appellationItem) {
        return <LoadingWithImage />;
    }

    const setMainContainerBgFloritureUrl = () => {
        if (width >= 1280) return `url(${fioriture1}), url(${fioriture3}`;
        if (width < 1280 && width >= 960) return `url(${fioriture1}), url(${fioriture3Big}`;
        return `url(${fioriture3}`;
    };

    const setMainContainerBgFloritureWith = () => {
        if (width < 700) return "1050px";
        else if (width < 960) return "1000px";
        else if (width >= 960 && width < 1280) return "bottom, 330px";
        return "bottom, 400px";
    };

    const redPrimaryVarieties = appellationItem?.grapeVarietiesRed
        .filter(({ primary }) => primary)
        .map(({ varietyDisplayName }) => ({ name: varietyDisplayName, type: "rouge" }));

    const redSecondaryVarieties = appellationItem?.grapeVarietiesRed
        .filter(({ primary }) => !primary)
        .map(({ varietyDisplayName }) => ({ name: varietyDisplayName, type: "rouge" }));

    const whitePrimaryVarieties = appellationItem?.grapeVarietiesWhite
        .filter(({ primary }) => primary)
        .map(({ varietyDisplayName }) => ({ name: varietyDisplayName, type: "blanc" }));

    const whiteSecondaryVarieties = appellationItem?.grapeVarietiesWhite
        .filter(({ primary }) => !primary)
        .map(({ varietyDisplayName }) => ({ name: varietyDisplayName, type: "blanc" }));

    const primaryContent = [...redPrimaryVarieties, ...whitePrimaryVarieties];
    const secondaryContent = [...redSecondaryVarieties, ...whiteSecondaryVarieties];

    const cepagesList = [
        {
            id: 1,
            title: t`Les cépages principaux`,
            number: primaryContent.length,
            content: primaryContent,
        },
        {
            id: 2,
            title: t`Les cépages accessoires`,
            number: secondaryContent.length,
            content: secondaryContent,
        },
    ];

    const changeOpen = (id) => {
        setIndex(index === id ? null : id);
    };

    const breadcrumbs = [
        {
            name: t`Appellations`,
            path: "/appellations",
        },
        {
            name: appellationItem.subregion,
            path: `/appellations/${urlize(appellationItem.subregion)}`,
        },
        {
            name: appellationItem.name,
        },
    ];

    const geographyListItemsTotal = () => {
        return geographyList
            ?.map((el) => el.number)
            ?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    };

    const checkArrayContent = (array) => array && array.map(({ content }) => content).flat().length > 0;

    const formatRegionName = (region) => {
        if (region === "Entre-deux-Mers") return region.slice(0, 1).toUpperCase() + region.slice(1);
        return region
            .split("-")
            .map((el) => (el !== "et" ? el.slice(0, 1).toUpperCase() + el.slice(1) : el))
            .join(" ");
    };

    return (
        <>
            <Helmet
                title={appellationItem.name}
                content={`Découvrez l'histoire de l'appellation ${appellationItem.name}, les caractéristiques de cette AOC, la superficie, les cépages autorisés ainsi que les zones géographiques de production ses principales caractéristiques et le profil des vins qui y sont produits.`}
            />

            <FrontContentTop
                backgroundImage={bg}
                links={breadcrumbs}
                previousPage={{
                    name: formatRegionName(region),
                    path: `/appellations/${region}`,
                }}
            >
                <FrontTitle style={customStyles.FrontTitle} title={appellationItem.name} />
                <Box className={styles.box_container_img_top}>
                    <WineImages height={64} wines={appellationItem.wines} isWhite />
                </Box>
                <FrontMainContainer
                    styleCardContent
                    className={styles.main_container}
                    style={{
                        // backgroundImage: `url(${fioriture1}), url(${
                        //     width < 1280 ? width >= 960 && fioriture3Big : fioriture3
                        // })`,
                        backgroundImage: setMainContainerBgFloritureUrl(),
                        backgroundPositionY: setMainContainerBgFloritureWith(),
                        backgroundPositionX: "right, right",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    <Box className={styles.container_card}>
                        <Box className={styles.container_content_top}>
                            <Box className={styles.container_content_primary}>
                                <EstateTitleSemiUnderlined
                                    title={t`L'appellation`}
                                    heightAfter={32}
                                    style={{ marginTop: "0px" }}
                                    lowBar={{ width: 75 }}
                                    borderColor={theme.palette.primary.main}
                                />

                                <Box>
                                    {isNotEmpty(appellationItem?.description) && (
                                        <Typography variant="body2" className={styles.content_text}>
                                            {getTextLength(appellationItem.description) > 1960 ? (
                                                <ExpandableFormattedTextBox
                                                    value={appellationItem.description}
                                                    truncatedValue={`${appellationItem.description.slice(0, 1690)}...`}
                                                />
                                            ) : (
                                                parseHtml(appellationItem.description, "body2")
                                            )}
                                        </Typography>
                                    )}

                                    {checkArrayContent(cepagesList) && (
                                        <Box className={styles.container_title_secondary}>
                                            <EstateTitleSemiUnderlined
                                                title={t`Les cépages`}
                                                heightAfter={32}
                                                style={{ marginTop: "0px" }}
                                                lowBar={{ width: 75 }}
                                                borderColor={theme.palette.primary.main}
                                            />

                                            <Box style={{ marginTop: "16px" }}>
                                                <CmsAccordion
                                                    type="cepage"
                                                    datas={cepagesList.filter(({ number }) => number > 0)}
                                                    iconStyle={{ width: 21, height: 34 }}
                                                />
                                            </Box>
                                        </Box>
                                    )}

                                    {geographyListItemsTotal() > 0 ? (
                                        <Box className={styles.container_title_third}>
                                            <EstateTitleSemiUnderlined
                                                title={t`Aires et zones géographiques`}
                                                heightAfter={32}
                                                style={{ marginTop: "0px" }}
                                                lowBar={{ width: 75 }}
                                                borderColor={theme.palette.primary.main}
                                                panelNotActive
                                            />

                                            {checkArrayContent(geographyList) && (
                                                <Box style={{ marginTop: "16px" }}>
                                                    {geographyList?.length > 0 &&
                                                        geographyList
                                                            .filter((el) => el.number > 0)
                                                            .map((item, i) => (
                                                                <Accordion
                                                                    key={i}
                                                                    className={styles.accordion}
                                                                    expanded={index === i}
                                                                    onChange={() => changeOpen(i)}
                                                                >
                                                                    <AccordionSummary
                                                                        expandIcon={<ExpandMoreIcon />}
                                                                        aria-controls="panel1a-content"
                                                                        id="panel1a-header"
                                                                        className={styles.accordion_summary}
                                                                    >
                                                                        <Box
                                                                            style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={location}
                                                                                className={styles.icon}
                                                                                style={{
                                                                                    opacity:
                                                                                        item.title ===
                                                                                        "Les cépages accessoires"
                                                                                            ? 0.5
                                                                                            : 1,
                                                                                }}
                                                                            />
                                                                            <Typography
                                                                                variant="h4"
                                                                                className={styles.accordion_title}
                                                                            >
                                                                                {item.title}:{" "}
                                                                                <Plural
                                                                                    value={item.number}
                                                                                    one={"# commune"}
                                                                                    other={"# communes"}
                                                                                />
                                                                            </Typography>
                                                                        </Box>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails
                                                                        className={styles.accordion_details}
                                                                    >
                                                                        <Box style={{ marginBottom: "8px" }}>
                                                                            <Typography
                                                                                variant="body2"
                                                                                className={styles.accordion_description}
                                                                            >
                                                                                {item.description}
                                                                            </Typography>
                                                                        </Box>
                                                                        <Box
                                                                            className={styles.accordion_container_items}
                                                                        >
                                                                            {item.content?.map((area, i) => (
                                                                                <Box
                                                                                    key={i}
                                                                                    className={
                                                                                        styles.accordion_container_item_single
                                                                                    }
                                                                                >
                                                                                    <Typography
                                                                                        variant="body2"
                                                                                        className={
                                                                                            styles.accordion_content_title
                                                                                        }
                                                                                    >
                                                                                        {area}
                                                                                    </Typography>
                                                                                </Box>
                                                                            ))}
                                                                        </Box>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            ))}
                                                </Box>
                                            )}
                                        </Box>
                                    ) : null}
                                </Box>
                            </Box>

                            <Box className={styles.container_content_secondary}>
                                <Box className={styles.container_infos_right}>
                                    {(appellationItem.surface ||
                                        appellationItem.surfacePercentage ||
                                        appellationItem?.odg?.name ||
                                        appellationItem?.specificationLink) && (
                                        <Box
                                            className={styles.center_responsive}
                                            style={{ gridColumn: width < 700 ? "1 / span 12" : "1 / span 6" }}
                                        >
                                            <AppellationSummary
                                                products={winesProduced}
                                                surface={{
                                                    ha: appellationItem.surface,
                                                    percentage: appellationItem.surfacePercentage,
                                                }}
                                                odg={appellationItem?.odg?.name}
                                                specificationLink={appellationItem?.specificationLink}
                                            />
                                        </Box>
                                    )}

                                    {appellationItem?.logo?.data && (
                                        <Box
                                            className={styles.container_image_primary_logo}
                                            style={{ gridColumn: width < 700 ? "1 / span 12" : "8/ span 12" }}
                                        >
                                            <img
                                                src={appellationItem?.logo?.data}
                                                className={styles.image_primary_logo}
                                                alt="Appellation logo"
                                            />
                                        </Box>
                                    )}
                                </Box>
                            </Box>

                            {appellationItem?.wines?.length > 0 && (
                                <Box className={styles.container_tabs}>
                                    <AppellationTabs
                                        wineInformations={appellationItem?.wineInformations}
                                        wines={appellationItem?.wines}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Box>
                </FrontMainContainer>
                {/* {vintageIds?.length > 0 && (
                    <Box className={styles.container_other_rankings}>
                        <FrontCardWrapper title={t`Découvrez des vins de cette appellation`}>
                            {vintageIds.map((vintageId) => (
                                <div key={vintageId} className={styles.AppellationWineCardContainer}>
                                    <AppellationWineCard vintageId={vintageId} />
                                </div>
                            ))}
                        </FrontCardWrapper>
                    </Box>
                )} */}
            </FrontContentTop>

            {appellationSample?.length > 0 && (
                <Box className={styles.container_appellation_region}>
                    <Box className={styles.container_appelation_content}>
                        <SwiperWrapper
                            boxPaddingTop={4}
                            title={t`D'autres appellations de la région bordelaise`}
                            noMargin
                            dark
                        >
                            {appellationSample.map((e, i) => {
                                const key = `appellation_region-${i}`;
                                return (
                                    <AppellationRegionCard
                                        key={key}
                                        wines={e.wines}
                                        name={e.name}
                                        logo={e.logo}
                                        subregion={e.subregion}
                                    />
                                );
                            })}
                        </SwiperWrapper>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default AppellationPage;
