import React from "react";
import { any, bool, func, string } from "prop-types";
import { Box, makeStyles, Typography } from "@material-ui/core";
import BaseRadio from "./BaseRadio";
import theme from "globalTheme";

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        flexDirection: "row",
        gap: "0.3rem",
        color: theme.palette.primary.dark,
        position: "relative",
    },
    label: (props) => {
        return {
            fontFamily: "Mansalva",
            color: props.checked ? theme.palette.primary.dark : theme.palette.secondary.dark,
        };
    },
}));
const BaseRadioLabel = (props) => {
    const styles = useStyles(props);
    return (
        <Box className={`${props.classNameBox} ${styles.container}`}>
            <BaseRadio size={props.size} checked={props.checked} onChange={() => props.onChange()} />
            <Typography
                className={`${props.classNameLabel} ${styles.label}`}
                variant={props.variant}
                onClick={() => props.onChange()}
            >
                {props.label}
            </Typography>
            {props.extraContent}
        </Box>
    );
};

BaseRadioLabel.propTypes = {
    size: string,
    label: string,
    variant: string,
    classNameBox: string,
    classNameLabel: string,
    checked: bool,
    onChange: func,
    extraContent: any,
};

export default BaseRadioLabel;
