import { calculateTTC, formatPrice } from "helpers/helpers";

import propriete from "assets/icons/custom/propriete.svg";
import cave from "assets/icons/custom/CaveCoop40x40.svg";
import negoce from "assets/icons/custom/Negoce40x40.svg";
import autre from "assets/icons/custom/AutreProVin40x40.svg";
import { Box } from "@material-ui/core";
// PACK IMAGES
import basic from "assets/icons/custom/verre_basique40x40.svg";
import essential from "assets/icons/custom/bouteilles_essentiel40x40.svg";
import premium from "assets/icons/custom/premium.svg";

import basic2 from "assets/icons/custom/basic_logo.svg";
import essential2 from "assets/icons/custom/essential_logo.svg";
import initial from "assets/icons/custom/initial.svg";
import premium2 from "assets/icons/custom/premium_logo.svg";

import { t } from "@lingui/macro";
import { SUBSCRIPTION_TYPES } from "config/constants";

export const colors = {
    disabled: "#dbc3ca",
    enabled: "#360318",
};

const ESSENTIAL_PRICE = { year: 870, month: 85 };

// PRICES
export const allPrices = {
    free: "Gratuit",
    initial: {
        htNumber: 495,
        htNumberPack: 645,
        htMonthly: 45,
        htTotalMonthly: 540,
        ht: formatPrice(495, "HT"),
        htPack: formatPrice(645, "HT"),
        ttc: formatPrice(calculateTTC(495, 20), "ttc"),
        monthly: formatPrice(45, "HT"),
        monthlyPack: formatPrice(57.5, "HT"),
        totalMonthly: formatPrice(540, "HT"),
        packPlusPrice: 150,
        mensualPackPlusPrice: 12.5,
    },
    essential: {
        htNumber: ESSENTIAL_PRICE.year,
        htMonthly: ESSENTIAL_PRICE.month,
        htTotalMonthly: 12 * ESSENTIAL_PRICE.month,
        ht: formatPrice(ESSENTIAL_PRICE.year, "HT"),
        ttc: formatPrice(calculateTTC(ESSENTIAL_PRICE.year, 20), "ttc"),
        monthly: formatPrice(ESSENTIAL_PRICE.month, "HT"),
        totalMonthly: formatPrice(12 * ESSENTIAL_PRICE.month, "HT"),
        ftpAnnualy: 240,
        ftpMonthly: 20,
    },
    premium: {
        htNumber: 2000,
        htMonthly: 195,
        htTotalMonthly: 2340,
        ht: formatPrice(2000, "HT"),
        ttc: formatPrice(calculateTTC(2000, 20), "ttc"),
        monthly: formatPrice(195, "HT"),
        totalMonthly: formatPrice(2340, "HT"),
        discount: formatPrice(1000, "HT"),
    },
    partial: {
        htNumber: 500,
        ht: formatPrice(500, "HT"),
        ttc: formatPrice(calculateTTC(500, 20), "ttc"),
        htDiscount: formatPrice(500, "HT"),
        ttcDiscount: formatPrice(calculateTTC(500, 20), "ttc"),
    },
    complete: {
        htNumber: 700,
        ht: formatPrice(700, "HT"),
        ttc: formatPrice(calculateTTC(700, 20), "ttc"),
        htDiscount: formatPrice(700, "HT"),
        ttcDiscount: formatPrice(calculateTTC(700, 20), "ttc"),
    },
};

// User's roles
const PROFESSIONAL_TYPES = {
    ESTATE: "estate",
    CAVE: "cave",
    NEGOCE: "negociant",
    AUTRE: "other",
};
export const months = [
    { title: t`janvier`, value: 1, titleFr: "janvier" },
    { title: t`février`, value: 2, titleFr: "février" },
    { title: t`mars`, value: 3, titleFr: "mars" },
    { title: t`avril`, value: 4, titleFr: "avril" },
    { title: t`mai`, value: 5, titleFr: "mai" },
    { title: t`juin`, value: 6, titleFr: "juin" },
    { title: t`juillet`, value: 7, titleFr: "juillet" },
    { title: t`août`, value: 8, titleFr: "août" },
    { title: t`septembre`, value: 9, titleFr: "septembre" },
    { title: t`octobre`, value: 10, titleFr: "octobre" },
    { title: t`novembre`, value: 11, titleFr: "novembre" },
    { title: t`décembre`, value: 12, titleFr: "décembre" },
];
export const getIdentityType = (identityType) => {
    if (identityType === PROFESSIONAL_TYPES.ESTATE) {
        return { title: t`Une propriété`, picture: propriete };
    }
    if (identityType === PROFESSIONAL_TYPES.CAVE) {
        return { title: "Une cave coopérative", picture: cave };
    }
    if (identityType === PROFESSIONAL_TYPES.NEGOCE) {
        return { title: "Une maison de négoce", picture: negoce };
    }
    if (identityType === PROFESSIONAL_TYPES.AUTRE) {
        return { title: "Un autre professionnel du vin", picture: autre };
    }
    return null;
};

// Web's subscriptions
export const PACKS = {
    cursor: "pointer",
    basic: {
        id: SUBSCRIPTION_TYPES.basic,
        type: "Basique",
        picture: basic,
        price: "Gratuit",
        htPrice: 0,
        htMonthly: 0,
        period: "",
        disabled: false,
        label: "Abonnement « Basique »",
        labelComparisonTable: "« Basique »",
        icon: basic,
        logo: basic2,
        tooltip: "En choisissant cet abonnement, vous ne bénéficiez pas d’un tarif réduit sur la souscription papier.",
        displayTag: false,
        detailsStar: false,
        zohoId: "WebBasic",
        paperDiscount: false,
        paperSubscription: "Tarif classique",
        description: "Offrez-vous gratuitement une simple présence sur Bordeaux et ses Vins en ligne.",
        publication: "Mention limitée à une ligne dans la XXe édition papier.",
        include: ["Présentation propriété limitée", "Propriétaire, exploitant"],
        exclude: ["Aucun millésime", "Aucune activité"],
    },
    initial: {
        id: SUBSCRIPTION_TYPES.initial,
        label: "Abonnement « Initial »",
        labelComparisonTable: "« Initial »",
        icon: initial,
        logo: initial,
        period: "/ an",
        type: "Initial",
        picture: initial,
        price: allPrices?.initial.ht,
        htPrice: allPrices?.initial.htNumber,
        htMonthly: allPrices?.initial.htMonthly,
        disabled: false,
        tooltip: "",
        displayTag: false,
        tagLabel: "Recommandé",
        detailsStar: false,
        zohoId: "WebInitial",
        paperDiscount: true,
        paperSubscription: "- 20 %",
        ht: formatPrice(495, "HT"),
        ttc: formatPrice(calculateTTC(495, 20), "ttc"),
        monthly: formatPrice(45, "HT"),
        totalMonthly: formatPrice(540, "HT"),
        description: "Présentez de façon intermédiaire votre propriété et ses vins au monde entier.",
        checkbox: {
            title: "Avec pack",
            description: "Plus de membres, de vins, de millésimes d’activités et d’options... ",
        },
        publication: "Parution complète dans la XXe édition papier.",
        include: [
            "Présentation propriété complète",
            "Propriétaire, exploitant + 2 membres",
            "4 vins",
            "12 millésimes",
            "3 activités",
        ],
    },
    essential: {
        id: SUBSCRIPTION_TYPES.essential,
        label: "Abonnement « Essentiel »",
        labelComparisonTable: "« Essentiel »",
        icon: essential,
        logo: essential2,
        period: "/ an",
        type: "Essentiel",
        picture: essential,
        price: allPrices?.essential.ht,
        htPrice: allPrices?.essential.htNumber,
        htMonthly: allPrices?.essential.htMonthly,
        disabled: false,
        tooltip: "",
        displayTag: false,
        tagLabel: "Recommandé",
        detailsStar: false,
        zohoId: "WebEssentiel",
        paperDiscount: true,
        paperSubscription: "- 20 %",
        ht: formatPrice(870, "HT"),
        ttc: formatPrice(calculateTTC(870, 20), "ttc"),
        monthly: formatPrice(85, "HT"),
        totalMonthly: formatPrice(1020, "HT"),
        description:
            "En plus d'une présence intermédiaire de votre propriété et ses vins sur Bordeaux et ses Vins en ligne, accédez à la version allégée du gestionnaire de fiches techniques Féret : création, gestion et diffusion",
        publication: "Parution complète dans la XXe édition papier.",
        checkbox: {
            title: "Avec pack",
            description: "Plus de membres, de vins, de millésimes d’activités et d’options... ",
        },
        include: [
            "Présentation propriété complète",
            "Propriétaire, exploitant + 6 membres",
            "8 vins",
            "48 millésimes",
            "6 activités",
            "Éditeur limité fiches techniques",
            "2000 contacts",
            "3 modèles",
        ],
    },
    premium: {
        id: SUBSCRIPTION_TYPES.premium,
        label: "Abonnement « Premium »",
        labelComparisonTable: "« Premium »",
        icon: premium,
        logo: premium2,
        period: "/ an",
        type: "Premium",
        picture: premium,
        price: allPrices?.premium?.ht,
        htPrice: allPrices?.premium.htNumber,
        htMonthly: allPrices?.premium.htMonthly,
        tagLabel: "OFFRE DE LANCEMENT - 50 %",
        disabled: false,
        tooltip: "",
        displayTag: false,
        detailsStar: true,
        zohoId: "WebPremium",
        paperDiscount: true,
        paperSubscription: "- 50 %",
        ht: formatPrice(1800, "HT"),
        ttc: formatPrice(1920, "ttc"),
        monthly: formatPrice(195, "HT"),
        totalMonthly: formatPrice(2340, "HT"),
        description:
            "Accédez à la solution complète pour présenter intégralement votre propriété et ses vins en 8 langues et créez, gérez, diffusez vos fiches techniques sans limite",
        publication: "Souscription complète papier comprise pour la XXème édition.",
        include: [
            "Présentation propriété complète",
            "Toute l’équipe",
            "Vins illimités",
            "Millésimes illimités",
            "Activités illimitées",
            "Éditeur complet fiches techniques",
            "Contacts illimités",
            "Tous les modèles",
        ],
    },
};

export const getPackType = (subType) => {
    return PACKS[subType];
};

// Paper's subscriptions
export const PAPER = {
    line: {
        id: "line",
        type: "Ligne",
        tooltip: false,
        displayTag: false,
        label: "Souscription « Ligne »",
        description: (
            <Box>
                Votre propriété sera simplement citée dans la XX<sup>e</sup> édition de <i>Bordeaux et ses Vins</i>.
                Vous n&apos;aurez pas la visibilité que peuvent offrir les deux autres souscriptions.
            </Box>
        ),
        price: "Gratuit",
        disabled: false,
        zohoId: "SouscriptionPapierLigne",
        zohoIdDiscount: "SouscriptionPapierLigne",
    },
    partial: {
        id: "partial",
        type: "Partielle",
        tooltip: false,
        displayTag: false,
        label: "Souscription « Partielle »",
        description: (
            <Box>
                La souscription « Partielle » vous offre une présence dans <i>Bordeaux et ses Vins</i> et la mise en
                avant des principaux attributs de la propriété mais ne vous permet pas de raconter son histoire.
            </Box>
        ),
        price: allPrices?.partial?.ht,
        discountPrice: allPrices?.partial?.htDiscount,
        disabled: true,
        zohoId: "SouscriptionPapierPartielle",
        zohoIdDiscount: "SouscriptionPapierPartiellePromo",
    },
    complete: {
        id: "complete",
        type: "Complète",
        tooltip: false,
        displayTag: false,
        label: "Souscription « Complète »",
        description: (
            <Box>
                En choisissant cette souscription, vous assurez à votre propriété une présence complète dans la XX
                <sup>e</sup> édition de <i>Bordeaux et ses Vins</i>. L&apos;encyclopédie la plus exhaustive sur la Place
                de Bordeaux depuis plus de 200 ans honorera votre propriété parmi toutes les institutions bordelaises.
            </Box>
        ),
        price: allPrices?.complete?.ht,
        discountPrice: allPrices?.complete?.htDiscount,
        disabled: true,
        zohoId: "SouscriptionPapierComplete",
        zohoIdDiscount: "SouscriptionPapierCompletePromo",
    },
};

export const getPaperType = (paperSubType) => {
    return PAPER[paperSubType] || { type: formatPrice(500, "ht"), disabled: true };
};
