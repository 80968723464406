import React from "react";
import PropTypes from "prop-types";
import theme from "../../globalTheme";
import { Typography, makeStyles } from "@material-ui/core";
import { Trans } from "@lingui/macro";

const useStyles = makeStyles(() => ({
    root: (props) => ({
        border: "none",
        cursor: "pointer",
        backgroundColor: "transparent",
        paddingLeft: 0,
        paddingRight: 0,
        padding: 0,

        "&:hover": {
            backgroundColor: "transparent",
        },
    }),
}));

const ModalResetFilters = (props) => {
    const styles = useStyles();

    return (
        <button className={styles.root} onClick={props?.onClick} disabled={props?.disabled}>
            <Typography
                variant="h6"
                style={{
                    fontWeight: 700,
                    color: props?.disabled ? theme.palette.secondary.dark : theme.palette.primary.light,
                }}
            >
                <Trans>Réinitialiser les filtres</Trans>
            </Typography>
        </button>
    );
};

ModalResetFilters.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

export default ModalResetFilters;
