import { Box, makeStyles, Typography } from "@material-ui/core";
import theme from "globalTheme";
import { number, object, oneOf, string } from "prop-types";

const useStyles = makeStyles((e) => ({
    root: {
        marginBottom: "2rem",
    },
    title: {
        color: theme.palette.primary.dark,
        marginBottom: 4,
    },
    boxContent: {},
    typo: {
        color: theme.palette.primary.dark,
        marginBottom: "1rem",
    },
}));

const SectionCGU = (props) => {
    const styles = useStyles();

    return (
        <>
            <Box className={styles.root}>
                <Typography
                    variant={props?.variantTitle ?? "h4"}
                    className={styles.contentTitle}
                    style={props?.titleStyle}
                >
                    {props?.number ? `Article ${props?.number} - ${props.title}` : props.title}
                </Typography>

                <Box className={styles.boxContent}>
                    {props?.body?.map((el, i) => {
                        if (Array.isArray(el)) {
                            return (
                                <ul className={styles.list}>
                                    {el?.map((item, index) => (
                                        <li key={index} className={styles.listItem}>
                                            <Typography variant={props?.variantContent ?? "h5"}>{item}</Typography>
                                        </li>
                                    ))}
                                </ul>
                            );
                        } else {
                            return (
                                <Typography key={i} variant={props?.variantContent ?? "h5"} className={styles.typo}>
                                    {el}
                                </Typography>
                            );
                        }
                    })}
                </Box>
            </Box>
        </>
    );
};

const variantsType = [
    "body1",
    "body2",
    "button",
    "caption",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "inherit",
    "overline",
    "subtitle1",
    "subtitle2",
];

SectionCGU.propTypes = {
    number: number,
    title: string,
    body: object,
    titleStyle: object,
    variantTitle: oneOf(variantsType),
    variantContent: oneOf(variantsType),
};

export default SectionCGU;
