import { useState } from "react";
import { bool, string } from "prop-types";
import { Chip, makeStyles } from "@material-ui/core";
import { Trans, t } from "@lingui/macro";
import theme from "globalTheme";
import BaseTooltip from "Components/Tooltips/Tooltip";
import PlusModal from "./PlusModal";
const useStyles = makeStyles(() => ({
    chip: (props) => ({
        width: "fit-content",
        height: props.height ?? "17px",
        borderRadius: 4,
        backgroundColor: theme.palette.wine.main,
        color: theme.palette.common.white,
        "&:hover": {
            backgroundColor: theme.palette.wine.main,
        },
    }),
    clickable: {
        backgroundColor: theme.palette.wine.main,
        "&:focus": {
            backgroundColor: theme.palette.wine.main,
        },
    },
    label: (props) => ({
        fontFamily: "Lato",
        textTransform: "uppercase",
        padding: "2px",
        fontSize: props.fontSize ?? 12,
        fontWeight: 900,
    }),
}));
const PlusChip = (props) => {
    const styles = useStyles(props);
    const [openDetails, setOpenDetails] = useState();

    return (
        <>
            <PlusModal open={openDetails} onClose={() => setOpenDetails(false)} />
            <BaseTooltip
                title={
                    !props.onlyChip && (
                        <Trans>
                            Voir les <b>packs PLUS</b>
                        </Trans>
                    )
                }
            >
                <Chip
                    {...props}
                    label={t`plus`}
                    classes={{
                        root: styles.chip,
                        label: styles.label,
                        clickable: styles.clickable,
                        clickableColorPrimary: styles.clickable,
                    }}
                    clickable={!props.onlyChip}
                    onClick={() => {
                        if (!props.onlyChip) setOpenDetails(true);
                    }}
                />
            </BaseTooltip>
        </>
    );
};

PlusChip.propTypes = {
    onlyChip: bool,
    height: string,
    fontSize: string,
};

export default PlusChip;
