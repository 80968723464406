import { Plural, t, Trans } from "@lingui/macro";
import { Badge, Box, makeStyles, Typography } from "@material-ui/core";
import Helmet from "Components/Helmet/Helmet";
import { getUserLocale } from "helpers/helpers";
import { useEnumValuesState } from "hooks/useEnumValues";
import { useState } from "react";
import { useSelector } from "react-redux";

import bg from "../../assets/searchEngine/wine/bg-wine-step-1.jpg";
import SimpleInput from "../../Components/Inputs/SimpleInput";
import { useSearchWinesQuery } from "../../generated/graphql";
import theme from "../../globalTheme";
import { useCustomHistory, useSearch } from "../../hooks";
import AutocompleteCheckboxSearch from "../../Search/AutocompleteCheckboxSearch";
import SearchWineModal from "../../Search/modal/SearchWineModal";
import SearchTabs from "../../Search/SearchTabs";
import SelectButtonOrCheckbox from "../../Search/SelectButtonOrCheckbox";
import ButtonSearch from "../../Search/ui/ButtonSearch";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&::after": {
            // eslint-disable-next-line quotes
            content: '""',
            position: "absolute",
            zIndex: -1,
            top: "-5rem",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundImage: `url(${bg})`,
            backgroundRepeat: "no-repeat",
            backgroundPositionY: "top",
            backgroundPositionX: "center",
            backgroundSize: "cover",
        },

        minHeight: "calc(100vh - 5rem)",
    },
    main_container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "22px 1rem 2rem",
        paddingTop: 0,
        overflow: "hidden",

        [theme.breakpoints.up("md")]: {
            width: "calc(950px)",
            margin: "auto",
        },

        [theme.breakpoints.up("lg")]: {
            width: "calc(1354px)",
            margin: "auto",
        },

        [theme.breakpoints.up("xl")]: {
            width: "calc(1384px)",
            margin: "auto",
        },
    },
    title: {
        color: theme.palette.common.white,
        fontWeight: 400,
        textAlign: "center",
        marginBottom: "1rem",
    },
    nav: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        gap: 12,
        alignItems: "center",
        background: "transparent",
        width: "100%",
        maxWidth: 1100,
        borderRadius: "53px",
        marginTop: 25,
        [theme.breakpoints.up("sm")]: {
            marginTop: 33,
        },

        [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "repeat(14, 1fr)",
            gap: 4,
            marginTop: 23,
            background: theme.palette.common.white,
            height: 34,
            padding: "7px 9px",
        },

        [theme.breakpoints.up("lg")]: {
            gap: 4,
            marginTop: 48,
        },
    },
    search: {
        gridColumn: "1 / span 12",

        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 4",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 3",
        },
    },
    color: {
        position: "relative",
        gridColumn: "1 / span 12",

        [theme.breakpoints.up("sm")]: {
            gridColumn: "1 / span 4",
        },

        [theme.breakpoints.up("md")]: {
            gridColumn: "5 / span 3",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "4 / span 2",
        },
    },
    appellation: {
        position: "relative",
        gridColumn: "1 / span 12",

        [theme.breakpoints.up("sm")]: {
            gridColumn: "5 / span 4",
        },

        [theme.breakpoints.up("md")]: {
            gridColumn: "8 / span 3",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "6 / span 3",
        },
    },
    vintage: {
        position: "relative",
        gridColumn: "1 / span 12",

        [theme.breakpoints.up("sm")]: {
            gridColumn: "9 / span 4",
        },

        [theme.breakpoints.up("md")]: {
            gridColumn: "11 / span 4",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "9 / span 3",
        },
    },
    badgeNotif: {
        position: "absolute",
        right: 10,
        top: 4,
        border: "none",
        padding: "0 4px",
        background: theme.palette.primary.dark,

        "& .MuiBadge-badge": {
            fontWeight: 700,
        },
    },
    result: {
        gridColumn: "1 / span 12",
        display: "flex",
        justifyContent: "center",
        paddingTop: 6,
        marginTop: 7,

        [theme.breakpoints.up("sm")]: {
            paddingTop: 8,
        },

        [theme.breakpoints.up("md")]: {
            paddingTop: 8,
            marginTop: 7,
            gridColumn: "1 / span 14",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "12 / span 3",
            paddingTop: 0,
            marginTop: 0,
        },
    },
    resultBtn: {
        [theme.breakpoints.up("sm")]: {
            maxWidth: 236,
        },

        [theme.breakpoints.up("lg")]: {
            maxWidth: "none",
        },
    },
    searchAdvancedBox: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        gap: 12,
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        maxWidth: 1100,
    },
    searchAdvancedBtn: {
        background: "transparent",
        border: "none",
        color: theme.palette.common.white,
        textDecoration: "underline",
        cursor: "pointer",
        padding: 0,

        gridColumn: "1 / span 12",
        display: "flex",
        justifyContent: "center",

        [theme.breakpoints.up("sm")]: {
            marginTop: 0,
        },

        [theme.breakpoints.up("md")]: {
            gridColumn: "5 / span 4",
            marginTop: 48,
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "11 / span 3",
            marginTop: 0,
            marginRight: 40,
        },
    },
}));

const FirstStepWine = () => {
    const styles = useStyles();
    const history = useCustomHistory();
    const locale = useSelector(getUserLocale);

    const labelCertifWine = useEnumValuesState("LABEL_CERTIFICATION_WINE", { locale });
    const { data: appellationLabels, loading: appellationLoading } = useEnumValuesState("APPELLATION_LABEL");
    const { data: classificationOrganism, loading: classificationLoading } = useEnumValuesState(
        "CLASSIFICATION_ORGANISM"
    );

    const [prevFilters, setPrevFilters] = useState({});

    const {
        filters,
        setFilter,
        counts,
        totalRecords,
        totalCount,
        getUrlParams,
        resetFilters,
        filtersCount,
        resetFields,
        setAllFilters,
    } = useSearch(
        {
            apogee: [1850, 2100],
            searchText: null,
            colors: [],
            appellations: [],
            years: [],
            sweetness: [],
            alcoolPercentage: [],
            allergens: [],
            wineWithoutAddedSulphite: false,
            doesNotContainOGM: false,
            attack: [],
            acidity: [],
            tanins: [],
            length: [],
            levurages: [],
            plotVinification: null,
            barrelAging: "undefined",
            frenchOak: null,
            onLies: null,
            durationAgingBarrels: [],
            newBarrels: [],
            heatingBarrels: [],
            filtration: "undefined",
            bonding: "undefined",
            typeOfClosures: [],
            waxedBottle: null,
            bottlingAtTheProperty: false,
            onlineSales: null,
            reward: null,
            score: null,
            labels: null,
            priceRange: [1, 501],
            typeOfHarvest: [],
            cepages: [],
            oenologists: [],
            typesMacerations: [],
            classificationOrganisms: [],
        },
        useSearchWinesQuery,
        "searchWines"
    );
    const [modalIsShown, setModalIsShown] = useState(false);
    const [searchText, setSearchText] = useState("");

    const handleNextStep = (e) => {
        history.push({
            pathname: "/recherche/vins/resultats",
            search: JSON.stringify(getUrlParams(e?.target?.value)),
        });
    };

    return (
        <>
            <Helmet
                title={t`Rechercher des vins`}
                content="Trouvez les vins de Bordeaux d'exception que vous cherchez grâce à notre moteur de recherche en ligne. Profitez de fiches techniques détaillées pour explorer les cépages, terroirs et millésimes. Laissez notre expertise bicentenaire vous guider dans la découverte des meilleurs vins de la région."
            />

            <Box className={styles.root}>
                <Box className={styles.main_container}>
                    <Typography variant="h2" className={styles.title}>
                        <Trans>Parcourez le vignoble bordelais</Trans>
                    </Typography>
                    <SearchTabs active={"wine"} />
                    <Box className={styles.nav}>
                        <Box className={styles.search}>
                            <SimpleInput
                                value={searchText}
                                onChange={(e) => setSearchText(e?.target?.value)}
                                onEnterPressed={(e) => handleNextStep(e)}
                                onBlur={(e) => setFilter("searchText", e?.target?.value)}
                                search
                                borderWidth={0.4}
                                size="small"
                                name="search"
                                placeholder={t`Chercher un vin...`}
                                variant="outlined"
                                boxStyle={{
                                    backgroundColor: theme.palette.common.white,
                                    borderRadius: "53px",
                                    border: "none",
                                }}
                                bgTransparent
                                borderRadius={53}
                                iconPosition="start"
                            />
                        </Box>

                        <Box className={styles.color}>
                            <Badge
                                badgeContent={filters?.colors?.length}
                                color="primary"
                                className={styles.badgeNotif}
                            />
                            <SelectButtonOrCheckbox
                                label={t`Couleur`}
                                counts={counts?.colors}
                                enumField="WINE_COLOR"
                                filter={filters.colors}
                                filterName="colors"
                                setFilters={(colors) => setFilter("colors", colors)}
                                hideNbInSelect
                            />
                        </Box>

                        <Box className={styles.appellation}>
                            <Badge
                                badgeContent={filters?.appellations?.length}
                                color="primary"
                                className={styles.badgeNotif}
                            />
                            {/* <SelectButtonOrCheckbox
                            counts={counts?.["appellations"]}
                            enumField={"APPELLATION_LABEL"}
                            label={t`Appellation`}
                            filter={filters["appellations"]}
                            filterName="appellations"
                            setFilters={(appellations) => setFilter("appellations", appellations)}
                            hideNbInSelect
                        /> */}
                            <AutocompleteCheckboxSearch
                                class={styles.category_filter}
                                label={t`Appellation`}
                                options={appellationLabels}
                                value={filters?.appellations}
                                onChange={(e1, e2) => setFilter("appellations", e2)}
                                loading={appellationLoading}
                                sortAlphabetical
                                uppercase
                                popupIcon
                            />
                        </Box>

                        <Box className={styles.vintage}>
                            <Badge
                                badgeContent={filters?.classificationOrganisms?.length}
                                color="primary"
                                className={styles.badgeNotif}
                            />
                            {/* <AutocompleteButtonSearch
                            counts={counts["years"]}
                            class={styles.category_filter}
                            label={t`Millésime`}
                            placeholder={t`Millésime`}
                            options={Array.from({ length: 2022 + 1 - 1920 }, (v, k) => (k + 1920).toString())}
                            uppercase
                            value={filters?.years}
                            onChange={(e1, e2, e3) => {
                                setFilter("years", e2);
                            }}
                            popupIcon
                            hideNbInSelect
                        /> */}
                            <AutocompleteCheckboxSearch
                                class={styles.category_filter}
                                label={t`Classement`}
                                options={classificationOrganism}
                                value={filters?.classificationOrganisms}
                                onChange={(e1, e2) => setFilter("classificationOrganisms", e2)}
                                loading={classificationLoading}
                                sortAlphabetical
                                uppercase
                                popupIcon
                            />
                        </Box>

                        {/* <Box className={styles.label}>
                        <Badge badgeContent={filters?.labels?.length} color="primary" className={styles.badgeNotif} />
                        <AutocompleteCheckboxSearch
                            sortAlphabetical
                            class={styles.category_filter}
                            label={t`Label`}
                            placeholder={t`Label`}
                            options={labelCertifWine}
                            uppercase
                            value={filters?.labels}
                            onChange={(e1, e2, e3) => {
                                setFilter("labels", e2);
                            }}
                            popupIcon
                        />
                    </Box> */}

                        <Box className={styles.result}>
                            <ButtonSearch onClick={() => handleNextStep()} className={styles.resultBtn}>
                                <Plural
                                    value={totalCount ?? 0}
                                    one="Rechercher (# résultat)"
                                    other="Rechercher (# résultats)"
                                    _0="Rechercher (# résultat)"
                                />
                            </ButtonSearch>
                        </Box>
                    </Box>

                    {/* <ButtonMoreFilters onClick={() => setModalIsShown(true)} /> */}
                    <Box className={styles.searchAdvancedBox}>
                        <button
                            className={styles.searchAdvancedBtn}
                            onClick={() => {
                                setPrevFilters(filters);
                                setModalIsShown(true);
                            }}
                        >
                            <Typography variant="subtitle1">
                                <Trans>Recherche avancée</Trans>
                            </Typography>
                        </button>
                    </Box>
                </Box>
                <SearchWineModal
                    resetFields={resetFields}
                    filtersCount={filtersCount}
                    isOpen={modalIsShown}
                    onClose={() => setModalIsShown(false)}
                    onCloseCross={() => {
                        setAllFilters(prevFilters);
                        setModalIsShown(false);
                    }}
                    titlePrimary={t`Tous les filtres`}
                    titleSecondary={t`Vins`}
                    clearFilters={() => resetFilters()}
                    filters={filters}
                    setFilter={(fieldname, v) => setFilter(fieldname, v)}
                    totalCount={totalCount}
                    nextStep={() => handleNextStep()}
                />
            </Box>
        </>
    );
};

export default FirstStepWine;
