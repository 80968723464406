import { bool, object, string } from "prop-types";
import { Box, Card, makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

import BaseButton from "../Buttons/BaseButton";
import { firstLetterUppercase, getSubscriptionTranslation, useWindowDimensions } from "../../helpers/helpers";
import theme from "../../globalTheme";
import Tag from "../Chips/Tag";
import { t, Trans } from "@lingui/macro";
import { SUBSCRIPTION_TYPES } from "config/constants";
import { useCustomHistory } from "hooks";

const useStyle = makeStyles((theme) => ({
    card: (props) => {
        return {
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            boxShadow: "unset",
            borderRadius: "10px",
            backgroundColor: props.backgroundColor,
            border: `1px solid ${props.borderColor ?? "transparent"}`,
            padding: "1rem 0.5rem",
            [theme.breakpoints.up("lg")]: {
                width: "240px",
                margin: "auto",
            },
            [theme.breakpoints.up("xl")]: {
                width: "245px",
                margin: "auto",
            },
        };
    },
    discount: {
        border: `5px solid ${theme.palette.wine.main} !important`,
        position: "relative",
        overflow: "visible",
    },
    content: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
    },
    content_img: {
        height: "40px",
        marginTop: "8px",
        [theme.breakpoints.up("xl")]: {
            height: "78px",
            width: "60px",
            marginTop: "16px",
        },
    },
    text: (props) => {
        return {
            flex: "1",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",

            color:
                props.subscription.type === SUBSCRIPTION_TYPES.basic ||
                props.subscription.type === SUBSCRIPTION_TYPES.initial
                    ? theme.palette.primary.dark
                    : theme.palette.common.white,
        };
    },
    monthly_text: {
        display: "flex",
        alignItems: "baseline",
        [theme.breakpoints.up("sm")]: {
            justifyContent: "center",
        },
        [theme.breakpoints.up("xl")]: {
            justifyContent: "left",
        },
    },
    container_button: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "70px",
        marginTop: 8,

        "&.Mui-disabled": {
            background: theme.palette.secondary.dark,
            border: theme.palette.secondary.dark,
            color: theme.palette.secondary.main,
        },
        [theme.breakpoints.up("xl")]: {
            justifyContent: "end",
            marginTop: 0,
        },
    },
    typo_price: {
        fontFamily: "Lato",
        display: "flex",
        alignItems: "flex-end",
        fontWeight: "700",
    },
}));

const SubscriptionCard = (props) => {
    const styles = useStyle(props);
    const { width } = useWindowDimensions();
    const facturation = props.switch ? "monthly" : "annual";
    const hadDiscount = props.subscription[facturation].discount;
    const { addLocale } = useCustomHistory();

    const price = () => {
        if (props.subscription.type !== SUBSCRIPTION_TYPES.basic) {
            if (props.switch) {
                return (
                    <>
                        <Box className={styles.monthly_text}>
                            <Typography variant="h3" className={styles.typo_price}>
                                {props.subscription.monthly.ht} €{" "}
                                <Trans>
                                    HT
                                    <Typography variant="h5">/mois</Typography>
                                </Trans>{" "}
                            </Typography>
                            {/* {hadDiscount} */}
                        </Box>
                    </>
                );
            } else {
                return (
                    <>
                        <Box style={{ display: "flex", alignItems: "baseline", flexDirection: "column" }}>
                            <Typography variant="h3" className={styles.typo_price}>
                                {parseInt(
                                    props.subscription.annual.ht - props.subscription.annual.discount
                                ).toLocaleString("fr-FR")}{" "}
                                €{" "}
                                <Trans>
                                    HT<Typography variant="h5">/an</Typography>
                                </Trans>
                            </Typography>

                            {/* {hadDiscount && (
                                <>
                                    <Typography
                                        variant="h6"
                                        style={{ textAlign: "end", width: "100%", textDecoration: "line-through" }}
                                    >
                                        {parseInt(props.subscription.annual.ht).toLocaleString("fr-FR")} €{" "}
                                        <Trans>HT/an</Trans>
                                    </Typography>
                                </>
                            )} */}
                        </Box>
                    </>
                );
            }
        } else {
            return (
                <>
                    <Typography variant="h3" style={{ fontFamily: "Lato" }}>
                        {props.subscription.monthly.ht}
                    </Typography>
                </>
            );
        }
    };
    return (
        <Card className={`${styles.card} ${hadDiscount && styles.discount}`}>
            {hadDiscount ? (
                <Box
                    style={{
                        position: "absolute",
                        top: "-15px",
                        right: "0",
                        left: "0",
                    }}
                >
                    <Tag
                        label={props.subscription[facturation].discountText}
                        size="large"
                        style={{
                            ...theme.typography.body2,
                            backgroundColor: theme.palette.wine.main,
                            color: theme.palette.common.white,
                            fontStyle: "uppercase",
                            padding: "3px 0px",
                            height: "auto",
                            width: "fit-content",
                        }}
                    />
                </Box>
            ) : null}
            <Box className={styles.content}>
                <Box className={styles.content_img}>
                    <img src={props.icon} style={{ width: "100%", height: "100%" }} />
                </Box>
                <Box className={styles.text}>
                    <Typography variant="h3" style={{ fontFamily: "Lato", fontWeight: 300 }}>
                        <Trans>ABONNEMENT</Trans>
                    </Typography>
                    <Typography variant="h3" style={{ fontWeight: 700 }}>
                        « {firstLetterUppercase(getSubscriptionTranslation(props.subscription.type))} »{" "}
                    </Typography>
                    <Typography variant="h3" style={{ fontFamily: "Lato", fontWeight: "700" }}>
                        {price()}
                    </Typography>
                </Box>
            </Box>
            <Box className={styles.container_button}>
                {width >= 1920 && hadDiscount && (
                    <Typography
                        variant="subtitle1"
                        style={{
                            fontFamily: "Mansalva",
                            color:
                                props.subscription.type === SUBSCRIPTION_TYPES.basic
                                    ? theme.palette.primary.dark
                                    : theme.palette.common.white,
                        }}
                    >
                        offre valable la première année pour une facturation annuelle
                    </Typography>
                )}
                <BaseButton
                    disabled={false}
                    component={Link}
                    to={addLocale("/bienvenue")}
                    style={{ width: "100%" }}
                    size="small"
                    variant="outlined"
                    color="primary"
                >
                    Choisir
                </BaseButton>
                {width <= 1280 && hadDiscount && (
                    <Typography
                        variant="subtitle1"
                        style={{
                            fontFamily: "Mansalva",
                            color:
                                props.subscription.type === SUBSCRIPTION_TYPES.basic
                                    ? theme.palette.primary.dark
                                    : theme.palette.common.white,
                        }}
                    >
                        offre valable la première année pour une facturation annuelle
                    </Typography>
                )}
            </Box>
        </Card>
    );
};

SubscriptionCard.propTypes = {
    icon: string,
    backgroundColor: string,
    borderColor: string,
    subscription: object,
    switch: bool,
};

export default SubscriptionCard;
