import { Box, makeStyles, Typography } from "@material-ui/core";
import PropTypes, { bool, func, number, string } from "prop-types";
import { getPricesWithOption } from "SubscriptionTunnel/Containers/WebSubscriptionContainer";

import theme from "../../../globalTheme";
import { calculateTTC, calculateTVA, numberWithSpaces } from "../../../helpers/helpers";
import { SUBSCRIPTION_TYPES } from "config/constants";

const useStyles = makeStyles(() => ({
    mainContainer: { width: "100%", display: "flex", flexDirection: "column", marginRight: "20px" },
    secondContainer: { display: "flex", justifyContent: "space-between" },
    hugeTypo: { textAlign: "left", fontWeight: "bold", fontSize: "18px", display: "flex", alignItems: "flex-end" },
    alignRight: { textAlign: "right" },
    alignleft: { textAlign: "left" },
    totalBlock: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "20px",
    },
    totalPriceTypo: { textAlign: "right", fontWeight: "900", fontSize: "22px" },
    sentenceTypo: { textAlign: "left", fontSize: "10px" },
    borderCard: {
        border: `1px solid ${theme.palette.secondary.dark}`,
        borderRadius: "10px",
        padding: "20px 20px",
        display: "flex",
        justifyContent: "flex-end",
        [theme.breakpoints.up("sm")]: {
            marginTop: "16px",
        },
        [theme.breakpoints.up("md")]: {
            marginTop: "1rem",
        },
    },
}));

const PurchaseDetailsBlock = (props) => {
    const styles = useStyles(props);

    if (!props.webSub) {
        return null;
    }

    return (
        <Box className={styles.borderCard}>
            <Box className={styles.mainContainer}>
                {/* TOTAL WITHOUT DISCOUNT */}
                <Box className={styles.secondContainer}>
                    <Typography variant="h4" className={styles.hugeTypo}>
                        Total hors taxes :
                    </Typography>
                    <Typography variant="h5" className={styles.alignRight}>
                        {getPricesWithOption(props.webSub, props.switchCheck, props.withPlus, props.webSub?.id === SUBSCRIPTION_TYPES.essential ? 200 : 150).slice(0, -2)}
                    </Typography>
                </Box>
                {/* DISCOUNT */}
                {props.switchCheck && props.discount && (
                    <Box className={styles.secondContainer}>
                        <Typography variant="h6" style={{ color: theme.palette.primary.light }}>
                            Offre de lancement première année :
                        </Typography>
                        <Typography variant="h6" style={{ color: theme.palette.primary.light }}>
                            - {props.discount.substring(0, props.webSub.price.length - 2)}
                        </Typography>
                    </Box>
                )}
                {/* TVA */}
                <Box className={styles.secondContainer}>
                    <Typography variant="h6" className={styles.alignLeft} style={{ color: "#432E35" }}>
                        TVA (20%) :
                    </Typography>
                    <Typography variant="h6" className={styles.alignRight}>
                        {calculateTVA(props.priceAfterDiscount, 20)} €{/* {formatPrice(TVAOnPrice)} */}
                    </Typography>
                </Box>
                {/* TOTAL */}
                <Box className={styles.totalBlock}>
                    <Typography variant="h4" className={styles.hugeTypo}>
                        Total à payer :
                    </Typography>
                    <Typography variant="h6" className={styles.totalPriceTypo}>
                        {numberWithSpaces(calculateTTC(Number(props.priceAfterDiscount), 20))} €
                        {/* {formatPrice(totalPriceWithTVA, "ttc")} */}
                    </Typography>
                </Box>
                {/* PAYMENT SENTENCE */}
                {/* {totalPriceWithoutCalculatingTVA > 0 &&
                    <Typography variant="h6" className={styles.sentenceTypo}>
                        À payer aujourd’hui pour un an d’abonnement web et la présence dans le livre Bordeaux et ses vins.
                    </Typography>} */}
            </Box>
        </Box>
    );
};

PurchaseDetailsBlock.propTypes = {
    webSub: PropTypes.object,
    discount: string,
    switchCheck: PropTypes.bool,
    priceAfterDiscount: number,
    withPlus: bool,
};

export default PurchaseDetailsBlock;
