import { Trans } from "@lingui/macro";
import { Box, Link, Typography, makeStyles } from "@material-ui/core";
import theme from "globalTheme";
import { useWindowDimensions } from "helpers/helpers";
import { useCustomHistory } from "hooks";

const useStyles = makeStyles(() => ({
    banner_container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        [theme.breakpoints.up("xs")]: {
            margin: "1rem 0",
        },
        [theme.breakpoints.up("lg")]: {
            width: "calc(100% - 300px - 8px)",
            margin: "0 0 1rem auto",
        },
        [theme.breakpoints.up("xl")]: {
            width: "calc(100% - 284px - 8px)",
            marginRight: "8px",
            marginBottom: "0",
        },
    },
    banner: {
        backgroundColor: theme.palette.wine.main,
        color: theme.palette.common.white,
        borderRadius: 4,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        cursor: "pointer",
        "&:hover": {
            textDecoration: "none",
        },
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
        },
    },
    text: {
        padding: 5,
        fontFamily: "Lato",
        fontWeight: "bold",
    },
    no_engagement: {
        fontFamily: "Mansalva",
        color: theme.palette.primary.dark,
        textAlign: "center",
    },
}));
const FreeTrialBanner = () => {
    const styles = useStyles();
    const history = useCustomHistory();
    const { width } = useWindowDimensions();

    return (
        <Box className={styles.banner_container}>
            <Box
                onClick={() => {
                    history.push({
                        pathname: "/bienvenue/inscription",
                        state: {
                            freeTrial: true,
                        },
                    });
                }}
                className={styles.banner}
            >
                <Typography variant="h3" className={styles.text}>
                    {width >= 600 ? (
                        <Trans>Profitez de 30 jours d’essai gratuit pour découvrir notre solution !</Trans>
                    ) : (
                        <Trans>30 jours d’essai gratuit pour découvrir notre solution !</Trans>
                    )}
                </Typography>
            </Box>
            <Typography variant="h5" className={styles.no_engagement}>
                <Trans>Aucun engagement, aucune obligation, pas de carte bleue requise.</Trans>
            </Typography>
        </Box>
    );
};

FreeTrialBanner.propTypes = {};

export default FreeTrialBanner;
