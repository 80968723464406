import PropTypes, { object } from "prop-types";
import { Trans } from "@lingui/macro";

import { Box, Button, Card, CardActions, CardContent, CardMedia, makeStyles, Typography } from "@material-ui/core";
import subPropImg1 from "../assets/propriétéPage/propriété_01-féret.png";
import learnMore from "../assets/CommunePage/learn_more.svg";
import wineRouge from "../assets/organization/wines/wine-rouge.svg";
import wineBlanc from "../assets/organization/wines/wine-blanc.svg";
import wineRose from "../assets/organization/wines/wine-rose.svg";

import theme from "../globalTheme";
import { urlFormatted } from "helpers/helpers";
import { useCustomHistory } from "hooks";
import { Link } from "react-router-dom";

const useStyles = makeStyles((e) => ({
    wine_img: {
        width: 8,
        height: 15,
    },
}));

const CommuneEntityCard = (props) => {
    const styles = useStyles(props);
    const history = useCustomHistory();

    return (
        <Card
            style={{
                borderRadius: "10px",
                width: props.width ?? 394,
                height: 344,
                boxShadow:
                    props?.CardShadow ??
                    "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
                cursor: "pointer",
            }}
        >
            <Link
                style={{ textDecoration: "none" }}
                to={history.addLocale(`/${urlFormatted(props.wineEntity?.wineEntityShortId, props.wineEntity?.name)}`)}
            >
                <CardMedia image={props.image ?? subPropImg1} style={{ width: "100%", height: "199px" }} />
                <CardContent>
                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Box
                            style={{
                                display: "flex",
                                color: theme.palette.primary.dark,
                                height: 45,
                            }}
                        >
                            <Typography variant="h4">{props.name}</Typography>
                        </Box>
                        <Box
                            style={{
                                display: "flex",
                                color: theme.palette.primary.light,
                            }}
                        >
                            <Typography variant={"body2"}>{props.classification}</Typography>
                        </Box>
                        <Box
                            style={{
                                display: "flex",
                                color: theme.palette.primary.dark,
                            }}
                        >
                            <Typography variant="h6">{props.appellation}</Typography>
                        </Box>
                    </Box>
                </CardContent>

                <Box
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingLeft: "16px",
                        paddingRight: "16px",
                    }}
                >
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "5px",
                        }}
                    >
                        {props?.hasRedWines && <CardMedia image={wineRouge} className={styles.wine_img} />}
                        {props?.hasWhiteWines && <CardMedia image={wineBlanc} className={styles.wine_img} />}
                        {props?.hasRoseWines && <CardMedia image={wineRose} className={styles.wine_img} />}
                    </Box>

                    <CardActions
                        style={{
                            display: "flex",
                            justifyContent: "end",
                        }}
                    >
                        <Button
                            component={Link}
                            size="small"
                            to={history.addLocale(
                                `/${urlFormatted(props.wineEntity?.wineEntityShortId, props.wineEntity?.name)}`
                            )}
                        >
                            <Typography
                                variant="h6"
                                style={{
                                    color: theme.palette.secondary.dark,
                                    marginRight: "0.5rem",
                                }}
                            >
                                <Trans>Découvrir</Trans>
                            </Typography>
                            <img src={learnMore} />
                        </Button>
                    </CardActions>
                </Box>
            </Link>
        </Card>
    );
};

CommuneEntityCard.propTypes = {
    wineEntity: object,
    name: PropTypes.string,
    classification: PropTypes.string,
    appellation: PropTypes.string,
    wineEntityId: PropTypes.string,
    image: PropTypes.string,
    width: PropTypes.string,
    wines: PropTypes.object,
    CardShadow: PropTypes.string,
    hasRedWines: PropTypes.bool,
    hasWhiteWines: PropTypes.bool,
    hasRoseWines: PropTypes.bool,
};

export default CommuneEntityCard;
