import { Trans } from "@lingui/macro";
import { Box, Card, CardContent, makeStyles, Typography } from "@material-ui/core";
import Tooltip from "Components/Tooltips/Tooltip";
import { string } from "prop-types";
import { Link } from "react-router-dom";

import learnMore from "../assets/CommunePage/learn_more.svg";
import theme from "../globalTheme";
import { urlize } from "../helpers/helpers";
import { useCustomHistory } from "hooks";

const useStyles = makeStyles((e) => ({
    card: {
        borderRadius: "10px",
        width: "280px",
        height: "16.45rem",
        backgroundColor: theme.palette.common.white,
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.08)",
        cursor: "pointer",
        textDecoration: "none",
    },
    container_card: {
        height: "100%",
        justifyContent: "space-between",
        display: "flex",
        flexDirection: "column",
    },
    item_title: {
        color: theme.palette.primary.main,
        fontWeight: "bold",
        textAlign: "center",
        marginBottom: 9,
        width: "100%",
    },
    link: {
        display: "flex",
        justifyContent: "end",
        marginTop: "1rem",
        alignSelf: "end",
        textDecoration: "none",

        "&:hover": {
            textDecoration: "underline",
            textDecorationColor: theme.palette.secondary.dark,
        },
    },
}));

const imageStyle = {
    height: "80%",
    margin: "auto 0",
};

const OdrCard = ({ name, logo, id }) => {
    const styles = useStyles();
    const history = useCustomHistory();
    const link = history.addLocale(`/organismes-vignoble-bordelais/representation/${urlize(name)}`);

    const limitName = 80;

    const getCardRender = () => {
        return (
            <Card className={styles.card} component={Link} to={link}>
                <CardContent style={{ padding: "0.5rem 1rem 1rem 1rem", height: "-webkit-fill-available" }}>
                    <Box className={styles.container_card}>
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "column",
                                flex: "1",
                            }}
                        >
                            <Box
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "6.5rem",
                                    margin: "auto",
                                }}
                            >
                                <img src={logo} style={{ width: "100%", height: "100%" }} />
                            </Box>
                            <Box
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                }}
                            >
                                <Box
                                    style={{
                                        display: "flex",
                                        marginTop: "0.5rem",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography variant="h4" className={styles.item_title}>
                                        {name?.length < limitName ? name : `${name?.slice(0, limitName)}...`}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <Box
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                    padding: "0px",
                                }}
                            >
                                <Link to={link} className={styles.link}>
                                    <Typography
                                        variant="body2"
                                        style={{
                                            color: theme.palette.secondary.dark,
                                            marginRight: "0.5rem",
                                            padding: "0px",
                                            marginLeft: "-2px",
                                        }}
                                    >
                                        <Trans>Accéder</Trans>
                                    </Typography>
                                    <img src={learnMore} style={imageStyle} />
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        );
    };

    return (
        <>
            {name?.length > limitName ? (
                <>
                    <Tooltip color="primary" title={name} arrow>
                        {getCardRender()}
                    </Tooltip>
                </>
            ) : (
                getCardRender()
            )}
        </>
    );
};

OdrCard.propTypes = {
    name: string,
    id: string,
    logo: string,
};

export default OdrCard;
