import { useCustomHistory } from "hooks";
import { object, oneOfType, string } from "prop-types";
import { Redirect as ReactRedirect } from "react-router-dom";

export const Redirect = (props) => {
    const history = useCustomHistory();
    const to = history.preffixPath(props.to);

    return <ReactRedirect {...props} to={to} />;
};

Redirect.propTypes = {
    to: oneOfType([string, object]),
};
