import { string } from "prop-types";
import { makeStyles, Box } from "@material-ui/core";
import logoVines from "../assets/landingPage/langing_page_bg.png";
import logo from "../assets/landingPage/feret_logo.svg";
import ConnectionAndSubscription from "../Landing/ConnectionAndSubscription";

import theme from "../globalTheme";

const useStyles = makeStyles(() => ({
    landingPage__wrapper: {
        width: "100vw",
        display: "flex",
        justifyContent: "space-evenly",

        [theme.breakpoints.up("xs")]: {
            flexDirection: "column",
            background: `border-box white url(${logoVines}) no-repeat`,
            backgroundSize: "inherit",
        },
        [theme.breakpoints.up("sm")]: {
            height: "100vh",
        },
        [theme.breakpoints.up("lg")]: {
            flexDirection: "row",
            height: "auto",
            backgroundSize: "cover",
        },
        [theme.breakpoints.up("xl")]: {
            backgroundPositionX: "-53px",
        },
    },
    logoContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        [theme.breakpoints.down("md")]: {
            height: "314px",
        },
        [theme.breakpoints.down("xs")]: {
            height: "200px",
        },
    },
    logo: {
        [theme.breakpoints.only("xs")]: {
            maxWidth: "260px",
            width: "100%",
        },
    },
    landingPage__CoAndSub: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        backgroundColor: "white",
        [theme.breakpoints.up("xs")]: {
            alignItems: "unset",
            height: "100%",
            flex: "1",
            justifyContent: "start",
        },
        [theme.breakpoints.up("sm")]: {
            height: "100vh",
            alignItems: "center",
        },
        [theme.breakpoints.up("lg")]: {
            justifyContent: "center",
            flex: "inherit",
            maxWidth: "50%",
            paddingRight: "calc(8px * 8)",
            paddingLeft: "calc(8px * 8)",
        },
        [theme.breakpoints.up("xl")]: {
            maxWidth: "40%",
        },
    },
}));

const LandingPage = (props) => {
    const styles = useStyles(props, theme);

    return (
        <Box className={styles.landingPage__wrapper}>
            <Box className={styles.logoContainer}>
                <img src={logo} className={styles.logo} />
            </Box>
            <Box className={styles.landingPage__CoAndSub}>
                <ConnectionAndSubscription isStandalone={props.isStandalone} />
            </Box>
        </Box>
    );
};

LandingPage.propTypes = {
    isStandalone: string,
};

export default LandingPage;
