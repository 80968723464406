import React from "react";
import { any, string } from "prop-types";
import { Box, Typography } from "@material-ui/core";

const SubtitleCommonality = ({ title, img }) => {
    return (
        <Box
            style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "end",
                gap: "0.5rem",
                marginTop: "2rem",
            }}
        >
            <img src={img} />
            <Typography variant="h5">{title}</Typography>
        </Box>
    );
};

SubtitleCommonality.propTypes = {
    title: any,
    img: string,
};

export default SubtitleCommonality;
