import { Trans } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { formatConcat } from "helpers/helpers";

const months = [
    { title: <Trans>janvier</Trans>, value: 1, titleFr: "janvier" },
    { title: <Trans>février</Trans>, value: 2, titleFr: "février" },
    { title: <Trans>mars</Trans>, value: 3, titleFr: "mars" },
    { title: <Trans>avril</Trans>, value: 4, titleFr: "avril" },
    { title: <Trans>mai</Trans>, value: 5, titleFr: "mai" },
    { title: <Trans>juin</Trans>, value: 6, titleFr: "juin" },
    { title: <Trans>juillet</Trans>, value: 7, titleFr: "juillet" },
    { title: <Trans>août</Trans>, value: 8, titleFr: "août" },
    { title: <Trans>septembre</Trans>, value: 9, titleFr: "septembre" },
    { title: <Trans>octobre</Trans>, value: 10, titleFr: "octobre" },
    { title: <Trans>novembre</Trans>, value: 11, titleFr: "novembre" },
    { title: <Trans>décembre</Trans>, value: 12, titleFr: "décembre" },
];

const dateFormat = (str) => String(str).padStart(2, "0");

export const getDate = (date, noYear) => {
    if (!date) return "";
    const monthFound = months.find((month) => month.value === date?.month);
    const monthString = monthFound ? i18n._(monthFound.titleFr) : null;
    if (date?.vaguePeriod) {
        const vaguePeriod = i18n._(date?.vaguePeriod);
        return `${vaguePeriod} ${monthString} ${noYear ? "" : date?.year}`;
    } else if (date?.day) {
        return `${dateFormat(date?.day)}/${dateFormat(date?.month)}/${date?.year}`;
    }
    return "";
};

export const getSentence = (el) => {
    const start = getDate(el?.startAt, "noYear");
    const end = getDate(el?.endAt, "noYear");

    if (start === end) {
        return `${start}`;
    }
    return formatConcat([start, end], " - ");
};
