import { array, bool, node, number, object, string } from "prop-types";
import { Box } from "@material-ui/core";

import FrontSwiperContainer from "./FrontSwiperContainer";
import theme from "../globalTheme";
import SearchResultEstateCard from "../Search/card/SearchResultEstateCard";
import SearchResultActivityCard from "../Search/card/SearchResultActivityCard";
import SearchResultWineCard from "../Search/card/SearchResultWineCard";
import Loading from "Components/Loader/Loading";

const SwiperCards = (props) => {
    const cardList = () => {
        if (props.page === "search_estate") {
            return props.datas?.map((estate, i) => (
                <SearchResultEstateCard
                    estate={estate}
                    wineEntityId={estate?.wineEntityId}
                    key={i}
                    marginBottom={0}
                    width={281}
                    height={297}
                    appellation={estate?.appellation}
                    badge={estate?.badge}
                    image={estate?.image}
                    label={estate?.label}
                    onlineSales={estate?.onlineSales}
                    isOpen={estate?.isOpen}
                    wines={estate?.wines}
                    name={estate?.wineEntityName}
                    classification={estate?.classification}
                    ha={estate?.ha}
                    cardWidthFixed={23}
                />
            ));
        } else if (props.page === "search_wine") {
            return props.datas?.map((wine, i) => (
                <SearchResultWineCard
                    wine={wine}
                    wineEntityId={wine?.wineEntityId}
                    sweetness={wine?.sweetness}
                    key={i}
                    wineId={wine?._id}
                    width={256}
                    name={wine?.brandName}
                    years={wine?.years}
                    color={wine?.color}
                    appellation={wine?.appellation}
                    classification={wine?.classification}
                    estate={wine?.estate}
                    reward={wine?.reward}
                    score={wine?.score}
                    label={wine?.label}
                    wineEntityName={wine?.wineEntityName}
                    cardWidthFixed={25}
                />
            ));
        } else if (props.page === "search_activity") {
            return props.datas?.map((activity, i) => (
                <SearchResultActivityCard
                    key={i}
                    marginBottom={0}
                    customWidth={281}
                    activity={activity}
                    cardWidthFixed={30}
                />
            ));
        } else {
            return "";
        }
    };

    if (!props.datas?.length) return null;

    return (
        <Box style={{ background: props.bgColor ?? "transparent", paddingTop: props.boxPaddingTop ?? 0 }}>
            <FrontSwiperContainer
                titleStyle={{
                    color: props.dark ? theme.palette.text.primary : theme.palette.primary.contrastText,
                    marginBottom: "36px",
                    ...props.titleStyle,
                }}
                titleCenter={props.titleCenter}
                title={props.title}
                containerStyle={{ alignItems: "flex-end", marginBottom: "58px" }}
                padding={props.noPadding ? 0 : "7px"}
                width={props.width}
                noMargin={props.noMargin}
                lowBar={{
                    display: props.hideLowBar ? "none" : "block",
                    width: props.page === "ranking" ? 50 : 75,
                    borderColor: props.page === "commune" ? theme.palette.common.white : theme.palette.primary.main,
                }}
                itemsAlign="flex-start"
                spaceBetween={props.spaceBetween ?? 32}
                lastSlide={props?.lastSlide ?? null}
                wrapperPaddingBottom={props?.wrapperPaddingBottom ?? 32}
            >
                {cardList()}
            </FrontSwiperContainer>
        </Box>
    );
};

export const SwiperWrapper = (props) => {
    if (props.loading) return <Loading />;

    const themeColor = props.dark ? theme.palette.text.primary : theme.palette.primary.contrastText;
    const themeBorderColor = props.dark ? theme.palette.primary.main : theme.palette.common.white;

    return (
        <Box style={{ background: props.bgColor ?? "transparent", paddingTop: props.boxPaddingTop ?? 0 }}>
            <FrontSwiperContainer
                autoHeight={props.autoHeight}
                slidesPerView={props.slidesPerView}
                titleStyle={{
                    color: themeColor,
                    marginBottom: "36px",
                    ...props.titleStyle,
                }}
                centerButtons={props.centerButtons}
                titleCenter={props.titleCenter}
                title={props.title}
                containerStyle={{ alignItems: "flex-end", marginBottom: "58px" }}
                padding={props.noPadding ? 0 : "7px"}
                width={props.width}
                noMargin={props.noMargin}
                lowBar={{
                    display: props.hideLowBar ? "none" : "block",
                    width: props.page === "ranking" ? 50 : 75,
                    borderColor: themeBorderColor,
                }}
                itemsAlign="flex-start"
                spaceBetween={props.spaceBetween ?? 32}
                lastSlide={props?.lastSlide ?? null}
                wrapperPaddingBottom={props?.wrapperPaddingBottom ?? 32}
            >
                {props.children}
            </FrontSwiperContainer>
        </Box>
    );
};

const sharedPropTypes = {
    commune: string,
    title: string,
    titleStyle: object,
    dark: bool,
    bgColor: string,
    boxPaddingTop: number,
    noMargin: bool,
    spaceBetween: number,
    background: string,
    cardWidth: number,
    CardShadow: string,
    lastSlide: node,
    wrapperPaddingBottom: number,
    titleCenter: bool,
    hideLowBar: bool,
    noPadding: bool,
    width: number,
    centerButtons: bool,
    autoHeight: bool,
};

SwiperWrapper.propTypes = {
    ...sharedPropTypes,
    children: node,
    borderColor: string,
    loading: bool,
    slidesPerView: string,
};

SwiperCards.propTypes = { ...sharedPropTypes, page: string, datas: array };

export default SwiperCards;
