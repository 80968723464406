import { t } from "@lingui/macro";
import { any, string } from "prop-types";

import GenericEstatePage from "./GenericEstatePage";

export const FrontEstatePage = (props) => {
    return (
        <GenericEstatePage
            socialMedias
            frontTitle
            wineSectionTitle={t`Les vins de la propriété`}
            showMoreWines={t`Découvrir tous les vins`}
            estateRef={props.estateRef}
        />
    );
};

FrontEstatePage.propTypes = {
    estateRef: any,
};

const StandaloneEstatePage = (props) => {
    return (
        <GenericEstatePage
            standalone
            logo={props.logo}
            estateRef={props.estateRef}
            wineEntityId={props.wineEntityId}
            hideFloritureTopImg
            titleStandalone
            boxStyles={{ paddingTop: "5rem" }}
            wineSectionTitle={t`Nos vins`}
            showMoreWines={t`Découvrir tous nos vins`}
            activitiesSectionTitle={t`Nos activités`}
            contactSectionTitle={t`Contactez-nous !`}
            contactRef={props.contactRef}
        />
    );
};

StandaloneEstatePage.propTypes = {
    estateRef: any,
    teamRef: any,
    wineEntityId: string,
    logo: string,
    contactRef: any,
};

export default StandaloneEstatePage;
