/* eslint-disable react/prop-types */
import { Box, InputAdornment, makeStyles, TextField, Typography } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { AutocompleteCommune } from "Components/Inputs/AutocompleteCommune";
import { isNull } from "lodash";
import { bool, func, object, string } from "prop-types";
import { useEffect, useState } from "react";

import { useGetCommunesOptionsQuery } from "../../generated/graphql";
import theme from "../../globalTheme";
import { useGeoApi } from "../../hooks";
import SimpleInput from "./SimpleInput";

const useStyles = makeStyles((theme) => ({
    autocomplete: (props) => ({
        gridColumn: "1 / 7",
        "& .MuiSvgIcon-root": {
            width: "15px",
            color: theme.palette.secondary.main,
            marginRight: 0,
        },
        "& .MuiAutocomplete-clearIndicator": {
            marginRight: props.address?.simple ? "15px" : "-2px",
        },
        "& .MuiAutocomplete-endAdornment": {
            top: "calc(50% - 14px)",
        },
        "& .MuiInputAdornment-root": {
            marginRight: "10px",
        },
        backgroundColor: "white",
        "& .MuiFormLabel-root": {
            color: theme.palette.secondary.dark,
            fontSize: "14px",
            top: "-8px",
            "&.Mui-focused": {
                color: theme.palette.primary.dark,
            },
            "&.Mui-error": {
                color: theme.palette.error.main,
            },
        },
        "& .MuiOutlinedInput-root": {
            paddingLeft: "10px",
            fontSize: "14px",
            height: "34px",
            color: theme.palette.primary.dark,
            borderWidth: "0.2px",
            borderColor: theme.palette.secondary.dark,

            "&.Mui-error": {
                color: theme.palette.error.main,
            },
            "&.Mui-focused": {
                color: theme.palette.primary.dark,
                borderColor: theme.palette.primary.dark,
                borderWidth: "0.2px",
            },
            "&.Mui-disabled": {
                color: theme.palette.secondary.dark,
                backgroundColor: theme.palette.action.disabledBackground,
                borderColor: theme.palette.secondary.dark,
                borderWidth: "0.2px",
            },
            "&:hover fieldset": {
                borderColor: props.address?.error ? theme.palette.error.main : theme.palette.secondary.main,
            },
            "&.Mui-focused fieldset": {
                borderColor: props.address?.error ? theme.palette.error.main : theme.palette.secondary.main,
            },
        },
        "& .MuiOutlinedInput-input": {
            padding: "0px",
        },
        " & .MuiSelect-select": {
            "&:focus": {
                backgroundColor: theme.palette.common.white,
            },
        },
        "& .MuiOutlinedInput-adornedStart": {
            padding: "0px",
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: "translate(16px, 4px) scale(0.75)",
        },
        "& .MuiFormControl-marginNormal": {
            margin: 0,
        },
        "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input": {
            padding: 0,
        },
        "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
            paddingRight: "16px",
        },
        "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
            paddingRight: "16px",
        },
    }),
    option: {
        fontSize: "14px",
        color: theme.palette.primary.dark,
        "&:hover": {
            backgroundColor: theme.palette.dashboard.background,
        },
        "&.MuiListItem-root.Mui-selected": {
            backgroundColor: theme.palette.dashboard.background,
        },
    },
    checkIcon: {
        width: "20px",
        fill: "#9BBA97",
        marginRight: "5px",
    },
    containerGridComponent: {
        gridTemplateColumns: "repeat(12, 1fr)",
        display: "grid",
        gap: "16px",
    },
    inputFlexAdditionalAddress: {
        gridColumn: "7 / 13",
    },
    inputFlexCommune: {
        gridColumn: "1 / 7",
    },
    optionText: {
        fontStyle: "italic",
        fontSize: "12px",
        color: theme.palette.secondary.dark,
    },
}));

const AddressAppellationAutocomplete = (props) => {
    const [searchString, setSearchString] = useState();

    const { data, loading: geoApiLoading, formattedData } = useGeoApi(searchString, "", true);

    const styles = useStyles(props);

    const {
        data: { GetCommunesOptions: communeOptions = [] } = {},
        loading: loadingcommuneOptions,
    } = useGetCommunesOptionsQuery({
        variables: { postalCode: props.postalCode, filterByRegion: props.filterByRegion },
    });

    useEffect(() => {
        const isAddressError = communeOptions.length === 0 && !isNull(props.postalCode) && !loadingcommuneOptions;
        if (props.onError) {
            props.onError(isAddressError);
        }
    }, [communeOptions]);

    return (
        <Box className={styles.containerGridComponent}>
            <Autocomplete
                className={styles.autocomplete}
                classes={{ option: styles.option }}
                noOptionsText={
                    <Typography className={styles.optionText}>
                        Il n&apos;y a plus d&apos;options disponibles.
                    </Typography>
                }
                loading={geoApiLoading}
                options={formattedData}
                filterOptions={(x) => x}
                value={props.address ? props.address : ""}
                inputValue={searchString}
                onChange={(_, e2) => props.onChangeAddress(e2, data?.[e2?.index]?.properties)}
                getOptionSelected={(option, value) => option && value && option.name === value.name}
                getOptionLabel={(option) => option.simple || ""}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        required
                        label={props.AddressLabel || "Adresse"}
                        margin="normal"
                        variant="outlined"
                        value={searchString}
                        onChange={(e) => setSearchString(e.target.value)}
                        onBlur={(e) => props.onChangeAddress({ simple: e?.target?.value })}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <InputAdornment position="end">
                                    {params.InputProps.endAdornment}
                                    {props.address?.simple && <CheckIcon className={styles.checkIcon} />}
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
                renderOption={(e) => {
                    if (e.index === 0) {
                        return (
                            <Typography
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                }}
                            >
                                {e.name}
                            </Typography>
                        );
                    }
                    return e.name;
                }}
            />
            <Box className={styles.inputFlexAdditionalAddress}>
                <SimpleInput
                    boxStyle={{ marginRight: theme.spacing(2) }}
                    value={props.extraAddress}
                    onChange={(e) => {
                        props.onChangeExtraAddress(e?.target?.value);
                    }}
                    label="Complément d'adresse"
                    variant="outlined"
                    correctValue={props.extraAddress}
                />
            </Box>
            <Box className={styles.inputFlexCommune}>
                <AutocompleteCommune
                    commune={props.commune}
                    label="Commune"
                    postalCode={props.postalCode}
                    onChange={({ commune, postalCode, communeINSEEcode }) => {
                        props.onChangeCommune(commune);
                        props.onChangePostalCode(postalCode);
                        props.onChangeCommuneInseeCode(communeINSEEcode);
                    }}
                    error={props.error}
                    region={props.filterByRegion}
                    required
                />
            </Box>
            {props.error && (
                <Typography
                    style={{
                        width: "100%",
                        fontSize: "12px",
                        fontStyle: "italic",
                        color: theme.palette.error.main,
                        gridColumn: "1 /12",
                    }}
                >
                    L&apos;adresse saisie ne se trouve pas dans une commune du vignoble bordelais, essayez une autre
                    adresse.
                </Typography>
            )}
        </Box>
    );
};

AddressAppellationAutocomplete.propTypes = {
    commune: object,
    onChangeCommune: func,
    postalCode: string,
    onChangePostalCode: func,
    onChangeCommuneInseeCode: func,
    onError: func,
    error: bool,
    filterByRegion: string,
};

export default AddressAppellationAutocomplete;
