/* eslint-disable no-unused-vars */
/*eslint-disable no-irregular-whitespace */
import React from "react";
import { Box, Typography, makeStyles, Link } from "@material-ui/core";
import BaseButton from "../../../Components/Buttons/BaseButton";
import Popin from "../../../Containers/Popin";
import PropTypes from "prop-types";
import theme  from "../../../globalTheme";
import popinImg from "../../../assets/Dashboard/translationAssets/translation1.svg";
import Arrow from "../../../Components/Arrow/Arrow";

const useStyle = makeStyles(() => ({

}));


const AboutTranslationPopin = (props) => {
    const styles = useStyle(props);
    return (
        <Popin
            icon={popinImg}
            cross
            open={props.modalOpen}
            onClose={props.handleClose}
            background={2}

        >
            <Box style={{ marginRight: 56, marginLeft: 56, width: "780px" }}>
                <Typography style={{ zIndex: 1000, position: "relative", fontSize: "26px", textAlign: "center", color: theme.palette.primary.dark, marginBottom: theme.spacing(4) }} variant="h3">
                    Traduction intégrée
                </Typography>
                <Typography style={{ fontSize: "18px" }}>Nous avons fait le choix d’intégrer dans nos serveurs une architecture de traduction de très haut niveau, basée sur les réseaux neuronaux et enrichie par un glossaire spécialisé conçu par des experts du monde du vin. </Typography><br />
                <Typography style={{ fontSize: "18px" }} >Les moteurs de traduction sont « entrainés » grâce à des modèles mathématiques permettant de traiter des phrases dans leur ensemble en fonction du concept ou de l’idée qu’elles présentent et non mot par mot.</Typography><br />
                <Typography style={{ marginBottom: theme.spacing(5), fontSize: "14px" }}>
                    Si vous n’êtes pas satisfait de cette traduction vous pouvez nous contacter à
                    <Link underline="always" style={{ marginRight: "4px", marginLeft: "4px", color: theme.palette.primary.main, cursor: "pointer" }}>
                        traduction@feret.com
                    </Link>
                    ou faire une
                    <Link 
                    underline="always" 
                    style={{ fontWeight: "bold", color: theme.palette.primary.dark, marginLeft: "4px", cursor: "pointer" }}
                    onClick={() => {
                        props.handleClose();
                        props.setManualPopinOpen(true);
                    }}
                    >
                        demande de traduction manuelle
                    </Link>
                </Typography>
            </Box>
        </Popin>
    );
};

export default AboutTranslationPopin;

AboutTranslationPopin.propTypes = {
    modalOpen: PropTypes.bool,
    handleClose: PropTypes.func,
    setManualPopinOpen: PropTypes.func,

};