import { makeStyles, Typography, Box } from "@material-ui/core";
import PropTypes, { any, string } from "prop-types";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CheckIcon from "@material-ui/icons/Check";
import FormHelperText from "@material-ui/core/FormHelperText";
import StopIcon from "@material-ui/icons/Stop";
import theme from "../../globalTheme";

const useStyles = makeStyles((theme) => ({
    checkbox: (props) => ({
        pointerEvents: "auto",
        paddingTop: "0",
        paddingBottom: "0",
        paddingRight: "2px",
        paddingLeft: 0,
        color: theme.palette.primary.light,
        width: "21px",
        height: "21px",
        "&.Mui-checked": {
            color: theme.palette.primary.light,
        },
        "& .MuiSvgIcon-root": {
            ...props.styleCheckboxIcon,
            fontSize: "12px",
        },
        "& .MuiIconButton-label": {
            zIndex: 10,
        },
    }),
    outlineIcon: (props) => ({
        width: "21px",
        height: "21px",
        zIndex: 10,
        position: "absolute",
        fill: props.disabled ? theme.palette.secondary.main : theme.palette.primary.light,
        color: props.disabled ? theme.palette.secondary.light : theme.palette.primary.light,
    }),
    outlineIconEmpty: (props) => ({
        top: 0,
        left: 0,
        width: "21px",
        height: "21px",
        zIndex: 10,
        position: "absolute",
        fill: props.disabled ? theme.palette.secondary.main : theme.palette.primary.light,
        color: props.disabled ? theme.palette.secondary.light : theme.palette.primary.light,
    }),

    checkIcon: (props) => ({
        width: "21px",
        height: "21px",
        zIndex: 10,
        position: "relative",
        color: props.disabled ? theme.palette.action.disabledBackground : theme.palette.common.white,
    }),
    stopIcon: (props) => ({
        zIndex: 5,
        width: "27px",
        height: "27px",
        color: props.disabled
            ? theme.palette.secondary.main
            : props.checked
            ? theme.palette.primary.light
            : theme.palette.common.white,
        position: "absolute",
    }),
    stopIconEmpty: (props) => ({
        top: -3,
        left: -3,
        zIndex: 5,
        width: "27px",
        height: "27px",
        color: props.disabled
            ? theme.palette.secondary.main
            : props.checked
            ? theme.palette.primary.light
            : theme.palette.common.white,
        position: "absolute",
    }),

    typo: (props) => ({
        color: props.disabled ? theme.palette.secondary.dark : theme.palette.primary.dark,
        marginLeft: props.noMargin ? "0px" : "5px",
    }),
    helperText: (props) => ({
        fontSize: "10px",
        "&.MuiFormHelperText-root.Mui-error": {
            color: props.disabled ? theme.palette.secondary.main : theme.palette.error.main,
        },
    }),
    formControl: {
        "&.MuiFormControlLabel-root": {
            margin: 0,
        },
    },
    container: {
        display: "flex",
    },
}));

const BaseCheckbox = (props) => {
    const styles = useStyles(props);
    return (
        <>
            <Box className={`${styles.container} ${props.className}`} style={props.boxStyle}>
                {props.extraContent}
                <FormControlLabel
                    onBlur={props.onBlur}
                    className={styles.formControl}
                    style={{ ...props.style }}
                    control={
                        <Checkbox
                            inputRef={props.inputRef}
                            className={styles.checkbox}
                            style={props.styleCheckbox}
                            checkedIcon={
                                <>
                                    <CheckBoxOutlineBlankIcon
                                        className={styles.outlineIcon}
                                        style={props.outlineStyle}
                                    />
                                    <StopIcon className={styles.stopIcon} style={props.backgroundStyle} />
                                    <CheckIcon className={styles.checkIcon} />
                                </>
                            }
                            icon={
                                props.disabled ? (
                                    <>
                                        <CheckBoxOutlineBlankIcon className={styles.outlineIcon} />
                                        <StopIcon className={styles.stopIcon} />
                                    </>
                                ) : (
                                    <div style={{ position: "relative", width: "21px", height: "21px" }}>
                                        <CheckBoxOutlineBlankIcon
                                            className={styles.outlineIconEmpty}
                                            style={props.outlineStyle}
                                        />
                                        <StopIcon className={styles.stopIconEmpty} />
                                    </div>
                                )
                            }
                            checked={props.checked}
                            disabled={props.disabled}
                            required={props.required}
                            value={props.value}
                            onChange={props.onChange}
                            name={props.name}
                        />
                    }
                    label={
                        <Typography
                            variant="h6"
                            style={props.labelStyle || { fontSize: "10px" }}
                            className={styles.typo}
                        >
                            {props.label || props.labelClickable}
                        </Typography>
                    }
                />
                {props.labelNotClickable && (
                    <Typography
                        variant="h6"
                        style={
                            props.labelStyle || {
                                fontSize: "10px",
                                pointerEvents: props.onlyCheckbox && "auto",
                            }
                        }
                        className={styles.typo}
                    >
                        {props.labelNotClickable}
                    </Typography>
                )}
            </Box>
            {props.clue && (props.value || props.error) ? (
                <Typography
                    variant="h6"
                    style={{
                        fontSize: "12px",
                        color: props.error ? theme.palette.error.main : "black",
                        marginBottom: theme.spacing(1),
                    }}
                >
                    {props.clue}
                </Typography>
            ) : null}
            {props.helperText ? (
                <FormHelperText style={{ ...props.helperStyle }} className={styles.helperText} error={props.error}>
                    {props.helperText}
                </FormHelperText>
            ) : null}
        </>
    );
};

BaseCheckbox.propTypes = {
    checked: PropTypes.bool,
    label: PropTypes.any,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    value: PropTypes.any,
    onChange: PropTypes.func,
    error: PropTypes.any,
    helperText: PropTypes.string,
    inputRef: PropTypes.any,
    onlyCheckbox: PropTypes.bool,
    labelNotClickable: PropTypes.bool,
    labelStyle: PropTypes.object,
    style: PropTypes.object,
    styleCheckbox: PropTypes.object,
    styleCheckboxIcon: PropTypes.object,
    helperStyle: PropTypes.object,
    clue: PropTypes.any,
    boxStyle: PropTypes.any,
    labelClickable: PropTypes.any,
    noMargin: PropTypes.any,
    onBlur: PropTypes.func,
    name: PropTypes.string,
    outlineStyle: PropTypes.object,
    backgroundStyle: PropTypes.object,
    className: string,
    extraContent: any,
};

BaseCheckbox.defaultProps = {
    onBlur: () => {},
};

export default BaseCheckbox;
