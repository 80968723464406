import { makeStyles } from "@material-ui/core";

import theme from "../../globalTheme";
import flori01 from "../../assets/fleuritures/winePage/Degustation/floriDegustationWine01.png";
import flori02 from "../../assets/fleuritures/winePage/Degustation/floriDegustationWine02.png";

export const useStyles = makeStyles(() => {
    return {
        mainInfosBlock: {
            marginBottom: "2rem",
            textAlign: "center",
        },
        winePage_GridColumn_Layout: {
            [theme.breakpoints.only("xs")]: {
                paddingBottom: 0,
                gridColumn: "1 / span 12",
            },
            [theme.breakpoints.up("sm")]: {
                gridColumn: "1 / span 12",
            },
            [theme.breakpoints.up("xl")]: {
                gridColumn: "2 / span 10",
            },
        },
        tabs: {
            display: "flex",
            flexDirection: "row",
            paddingBottom: "60px",
            "& .MuiTabs-flexContainer": {
                justifyContent: "space-around",
            },
            "& .MuiTabs-scrollable": {
                borderBottom: "1px solid",
                borderBottomColor: theme.palette.secondary.main,
            },
            "& .MuiTabs-indicator": {
                height: "4px",
                width: "100%",
                borderRadius: "5px 5px 0px 0px",
                backgroundColor: theme.palette.primary.dark,
            },
            "& .MuiTabScrollButton-root.Mui-disabled": {
                transitionProperty: "width, opacity",
                transitionDuration: ".8s",
                animationTimingFunction: "cubic-bezier(.59,.01,.39,1)",
                width: "0px",
            },
            "& .MuiTabScrollButton-root": {
                transitionProperty: "width, opacity",
                transitionDuration: ".8s",
                animationTimingFunction: "cubic-bezier(.59,.01,.39,1)",
            },
            "& .MuiButtonBase-root": {
                color: theme.palette.secondary.main,
            },
            "& .Mui-selected ": {
                color: theme.palette.text.primary,
            },
        },
        tab: {
            ...theme.front.typography.h5.dark_b,
            textTransform: "none",
            padding: "10px",
            minWidth: "fit-content",
            fontWeight: "bold",
            backgroundColor: "white",
            borderRadius: "4px",
            maxWidth: "initial",
            flex: 1,
        },
        tabPanelContainer: {
            height: "100%",
            background: `url(${flori02}) top -10px right /600px no-repeat, url(${flori01}) left 0 bottom 0px/500px no-repeat`,
        },
        partOneContainer: (props) => {
            return {
                position: "relative",
                display: "grid",
                gridTemplateColumns: "repeat(12, 1fr)",
                gap: "16px",
                minHeight: props.vintage?.bottleLabel ? 530 : 360,
                [theme.breakpoints.only("xs")]: {
                    display: "flex",
                    flexDirection: "column",
                },
            };
        },
        bottleLabel: {
            maxWidth: "247px",
            height: "138px",
            backgroundColor: theme.palette.dashboard.background,
            position: "relative",
            zIndex: 2000,
            marginTop: "37%",
            display: "flex",
            justifyContent: "center",
            [theme.breakpoints.only("xs")]: {
                display: "none",
            },
            [theme.breakpoints.only("sm")]: {
                display: "none",
            },
        },
        bottleLabelSmall: {
            backgroundColor: theme.palette.dashboard.background,
            position: "relative",
            zIndex: 2000,
            display: "flex",
            justifyContent: "center",
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            [theme.breakpoints.only("xs")]: {
                maxWidth: "203px",
                height: "114px",
            },
            [theme.breakpoints.only("sm")]: {
                maxWidth: "203px",
                height: "114px",
            },
            [theme.breakpoints.up("md")]: {
                display: "none",
            },
        },
        descriptionTypoTitle: {
            ...theme.front.typography.h5.dark_b,
        },
        summaryContainer: {
            padding: "24px",
            backgroundColor: "white",
            position: "relative",
            zIndex: 2,
            height: "fit-content",
            border: "1px solid",
            borderColor: theme.palette.secondary.dark,
            borderRadius: "5px",
            width: "auto",
            [theme.breakpoints.only("xs")]: {
                margin: "0 8px",
                padding: "8px",
            },
            [theme.breakpoints.up("sm")]: {
                gridColumn: "auto / span 12",
            },
            [theme.breakpoints.up("md")]: {
                gridColumn: "4 / span 9",
            },
            [theme.breakpoints.up("lg")]: {
                gridColumn: "8 / span 5",
                gridRow: "2 / span 4",
            },
            [theme.breakpoints.up("xl")]: {
                gridColumn: "9 / span 5",
                gridRow: "1 / span 5",
            },
        },
        mainTabsContainer: {
            [theme.breakpoints.up("xs")]: {
                padding: "8px",
                paddingTop: 0,
            },
            [theme.breakpoints.up("sm")]: {
                padding: "0",
                paddingTop: 0,
            },
            [theme.breakpoints.up("md")]: {
                padding: "32px",
                paddingTop: 0,
            },
            [theme.breakpoints.up("lg")]: {
                padding: "32px",
                paddingTop: 0,
            },
            [theme.breakpoints.up("xl")]: {
                padding: "88px",
                paddingTop: 0,
            },
        },
        tabsContainer: {
            [theme.breakpoints.up("xs")]: {
                padding: "0px 16px",
            },
            [theme.breakpoints.up("sm")]: {
                padding: "0px 16px",
            },
            [theme.breakpoints.up("md")]: {
                padding: "0px 32px",
            },
            [theme.breakpoints.up("lg")]: {
                padding: "0px 32px",
            },
            [theme.breakpoints.up("xl")]: {
                padding: "0px 88px",
            },
        },
        mainInfosBlockSubtitle: {
            color: theme.palette.common.white,
            fontFamily: "Lato",
            fontWeight: "400",
        },
        description__wrapper: {
            position: "block",
            zIndex: 2,
            marginBottom: "40px",
            [theme.breakpoints.up("xs")]: {
                margin: "0 8px",
            },
            [theme.breakpoints.up("sm")]: {
                gridColumn: "auto / span 12",
                margin: "0",
            },
            [theme.breakpoints.up("md")]: {
                gridColumn: "4 / span 9",
                margin: "0",
            },
            [theme.breakpoints.up("lg")]: {
                gridColumn: "3 / span 5 ",
                margin: "0",
            },
            [theme.breakpoints.up("xl")]: {
                gridColumn: "3 / span 6",
                margin: "0",
            },
        },
        label__wrapper: {
            [theme.breakpoints.only("xs")]: {
                margin: "0 8px",
            },
            [theme.breakpoints.up("sm")]: {
                gridColumn: "auto / span 12",
            },
            [theme.breakpoints.up("md")]: {
                gridColumn: "4 / span 9",
            },
            [theme.breakpoints.up("lg")]: {
                gridColumn: "3 / span 5",
            },
            [theme.breakpoints.up("xl")]: {
                gridColumn: "3 / span 6",
            },
        },
        fioriture: {
            left: 0,
            bottom: 0,
            position: "absolute",
            zIndex: 1,
            [theme.breakpoints.only("xs")]: {},
            [theme.breakpoints.only("sm")]: {},
            [theme.breakpoints.only("md")]: {
                left: 10,
                width: "45%",
            },
            [theme.breakpoints.only("lg")]: {
                left: 40,
                width: "60%",
            },
            [theme.breakpoints.only("xl")]: {
                left: 200,
                width: "52%",
            },
        },
        typographyTitle__labelCertif: {
            fontSize: "21px",
            marginBottom: theme.spacing(2),
            [theme.breakpoints.only("xs")]: {
                fontSize: "14px",
            },
        },
        typographyTitle__labelCertif_2: {
            fontSize: "25px",
            marginBottom: theme.spacing(2),
            [theme.breakpoints.only("xs")]: {
                fontSize: "14px",
            },
        },
        typoDescritpion__CertifOrLabel: {
            [theme.breakpoints.only("xs")]: {
                fontSize: "12px",
            },
        },
        vintageSelectorOnly__box: {
            backgroundColor: theme.palette.dashboard.background,
            borderRadius: "10px",
            padding: "20px 24px",
            [theme.breakpoints.only("xs")]: {},
        },
        classifications_divider: {
            backgroundColor: "white",
            [theme.breakpoints.up("xs")]: {
                width: "0px",
            },
            [theme.breakpoints.up("sm")]: {
                width: "60px",
            },
            [theme.breakpoints.up("md")]: {
                width: "160px",
            },
            [theme.breakpoints.up("lg")]: {
                width: "183px",
            },
            [theme.breakpoints.up("xl")]: {
                width: "183px",
            },
        },
        link_open_modal: {
            ...theme.front.typography.h3.light,
            fontSize: "28px",
            fontFamily: theme.typography.fontFamily,
        },
        medal_mask: {
            position: "absolute",
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.55)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
        },
    };
});
