import React from "react";
import PropTypes from "prop-types";

import { makeStyles, Typography, Box, CardMedia, IconButton } from "@material-ui/core";
import mailIcon from "../../../assets/propriétéPage/Icon-mail.svg";
import noAvatar from "../../../assets/noAvatar.jpg";
import theme from "../../../globalTheme";
import { parseHtml, useWindowDimensions } from "../../../helpers/helpers";

const useStyles = makeStyles((theme) => ({
    button: {
        margin: "auto  0px 5px 0px",
    },

    teamMemberZoom: {
        border: "0.4px solid ",
        borderColor: theme.palette.secondary.dark,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "5px",
        [theme.breakpoints.up("xs")]: {
            width: "auto",
            height: "100%",
        },
        [theme.breakpoints.down("sm")]: {
            flexDirection: "row",
            justifyContent: "left",
            backgroundColor: theme.palette.common.white,
        },
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            justifyContent: "left",
        },
        [theme.breakpoints.up("md")]: {
            height: "fit-content",
            gridColumn: "auto / span 6",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 5",
        },
    },
    temMemberZoom__mediaContainer: {
        position: "relative",
        [theme.breakpoints.up("xs")]: {
            width: "100%",
            height: "110px",
            display: "flex",
            alignItems: "center",
            padding: "1rem 0 0 1rem",
        },
        [theme.breakpoints.up("sm")]: {
            height: "100%",
            width: "auto",
            padding: "1rem 0 1rem 1rem",
        },
        [theme.breakpoints.up("md")]: {
            width: "100%",
            height: "140px",
            padding: "inherit",
        },
    },
    teamMemberZoom__media: (props) => ({
        width: "257px",
        height: "257px",
        borderRadius: "50%",
        border: "4px solid",
        borderColor: props.vip ? theme.palette.primary.light : theme.palette.common.white,
        position: "absolute",
        top: "-140px",
        left: "50%",
        marginLeft: "-128px",
        [theme.breakpoints.only("xs")]: {
            width: "200px",
            height: "200px",
            top: "-100px",
            left: "50%",
            marginLeft: "-103px",
        },
        [theme.breakpoints.only("md")]: {
            width: "250px",
            height: "250px",
            top: "-120px",
            left: "50%",
            marginLeft: "-130px",
        },
        [theme.breakpoints.only("sm")]: {
            width: "250px",
            height: "250px",
            top: "-120px",
            left: "50%",
            marginLeft: "-130px",
        },
        [theme.breakpoints.down("sm")]: {
            width: "168px",
            height: "168px",
            position: "relative",
            margin: 0,
            top: "inherit",
            left: "inherit",
            display: "inline-flex",
        },
        [theme.breakpoints.down("xs")]: {
            width: "77px",
            height: "77px",
        },
        [theme.breakpoints.up("md")]: {
            position: "absolute",
            top: "-120px",
            left: "50%",
            width: "200px",
            height: "200px",
            marginLeft: "-100px",
        },
    }),
    teamMemberZoom__textContainer: {
        textAlign: "left",
        [theme.breakpoints.up("md")]: {
            textAlign: "center",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "1rem 0",
        },
        [theme.breakpoints.down("xs")]: {
            padding: "0 0 1rem 0",
        },
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "8px",
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            paddingRight: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "20px",
        },
    },
    teamMemberZoom__name: {
        ...theme.front.typography.h6.dark_b,
    },
    teamMemberZoom__work: {
        ...theme.front.typography.labeur.dark_b,
        color: theme.palette.primary.dark,
    },
    teamMemberZoom__description: {
        ...theme.front.typography.labeur.dark,
        color: theme.palette.primary.dark,
    },
}));

const getIcon = (onClick, styles) => (
    <IconButton variant="text" color="secondary" size="medium" onClick={onClick} className={styles.button}>
        <img src={mailIcon} alt="mail_icon" />
    </IconButton>
);

const TeamMemberZoom = (props) => {
    const styles = useStyles(props);
    const { width } = useWindowDimensions();

    return (
        <Box className={styles.teamMemberZoom}>
            <Box className={styles.temMemberZoom__mediaContainer}>
                <CardMedia
                    className={styles.teamMemberZoom__media}
                    image={props.profilePic || ""}
                    title={props.fullName}
                />
                <Box style={{ display: width >= 600 && "none" }}>
                    <Typography className={styles.teamMemberZoom__name} color="primary">
                        {props.fullName}
                    </Typography>
                    <Typography className={styles.teamMemberZoom__work}>{props.works}</Typography>
                </Box>
            </Box>
            <Box className={styles.teamMemberZoom__textContainer}>
                <Typography
                    className={styles.teamMemberZoom__name}
                    color="primary"
                    style={{ display: width < 600 && "none" }}
                >
                    {props.fullName}
                </Typography>
                <Typography className={styles.teamMemberZoom__work} style={{ display: width < 600 && "none" }}>
                    {props.works}
                </Typography>
                <Typography
                    className={styles.teamMemberZoom__description}
                    style={{
                        marginTop: theme.spacing(2),
                        marginBottom: theme.spacing(2),
                    }}
                >
                    {parseHtml(props.description)}
                </Typography>
            </Box>
            {/* <Box>
                {getIcon(props.onClick, styles)}
            </Box> */}
        </Box>
    );
};

TeamMemberZoom.propTypes = {
    label: PropTypes.string,
    profilePic: PropTypes.string,
    onClick: PropTypes.func,
    fullName: PropTypes.string,
    works: PropTypes.any,
    description: PropTypes.string,
    vip: PropTypes.bool,
};

export default TeamMemberZoom;
