import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useFrontVintageQrcodeQuery } from "../../generated/graphql";
import { QrcodePage } from "./QrcodePage";
import LoadingWithImage from "Components/Loader/LoadingWithImage";
import Page500Front from "Components/ErrorPage/Page500Front";

export const ConnectedQrcodeWinePage = () => {
    let { vintageId, locale: pageLocale } = useParams();
    const settingsLocale = useSelector((state) => state?.user?.locale);
    const locale = pageLocale || settingsLocale;

    const { loading, data, error } = useFrontVintageQrcodeQuery({
        variables: { vintageId, locale },
    });

    if (loading) {
        return <LoadingWithImage />;
    }

    if (error) {
        return <Page500Front />;
    }

    return <QrcodePage vintage={data?.FrontVintageQrcode} />;
};
