import React from "react";

import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";

import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles({
  imageIcon: props => ({
    height: props.iconHeight || "100%",
    width:  props.iconWidth||"100%",
  }),
  iconRoot: {
    textAlign: "center",
  },
});
const IconSVG = (props) => {
  const classes = useStyles(props);
  return (
      <Icon style={props.style}
      className={classes.iconRoot}>
          <img className={classes.imageIcon} src={props.path} />
      </Icon>
  );
};

IconSVG.propTypes = {
  path: PropTypes.string,
  style: PropTypes.any,
  iconHeight: PropTypes.number,
  iconWidth: PropTypes.number,

};

export default IconSVG;
