import "react-toastify/dist/ReactToastify.css";

import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Link, makeStyles, Typography } from "@material-ui/core";
import PropTypes, { any, bool, string } from "prop-types";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import * as Yup from "yup";

import BaseButton from "../../Components/Buttons/BaseButton";
import BaseCheckbox from "../../Components/Inputs/BaseCheckbox";
import PasswordInput from "../../Components/Inputs/PasswordInput";
import UncontrolledSimpleInput from "../../Components/Inputs/UncontrolledSimpleInput";
import ToastOptions from "../../Components/Toasts/ToastOptions";
import theme from "../../globalTheme";
import Helmet from "Components/Helmet/Helmet";
import { t } from "@lingui/macro";
import { useCustomHistory } from "hooks";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
    containerGrid: {
        display: "grid",
        gridTemplateColumns: "repeat( 12, 1fr )",
        gridGap: "10px 24px",
    },
    form_container: {
        [theme.breakpoints.up("xs")]: {
            textAlign: "center",
            gridColumn: "2 /span 10",
        },
        [theme.breakpoints.up("md")]: {
            textAlign: "left",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 12",
        },
    },
    itemGrid: {
        gridColumn: "auto / span 12",
        height: "34px",
    },
    subscribe: {
        cursor: "pointer",
        marginLeft: "4px",
        color: theme.palette.primary.dark,
        alignSelf: "center",
        fontFamily: theme.typography.h6.fontFamily,
    },
    submit_button: {
        gridColumn: "2 / span 10",
    },
}));

const ConnectionContainer = (props) => {
    const errorCode = useSelector((state) => state.user.error?.code);
    const toastOptions = ToastOptions();
    const { t: ti18next } = useTranslation();
    const history = useCustomHistory();
    const datas = {
        subscriptionEmailAddress: "",
        subscriptionPassword: "",
    };

    useEffect(() => {
        if (errorCode && props.called) {
            toast.error(
                toastOptions?.toastErrorComponent("Connexion impossible avec ces identifiants"),
                toastOptions?.passwordOptions
            );
        }
    }, [errorCode, props.called]);

    const registrationValidationSchema = Yup.object().shape({
        subscriptionPassword: Yup.string().required("Le mot de passe est requis").min(6, "Minimum 6 caractères"),
        subscriptionEmailAddress: Yup.string()
            .email("Veuillez entrer une adresse e-mail valide")
            .required("L'email est requis"),
    });

    const { register, handleSubmit, errors, formState } = useForm({
        resolver: yupResolver(registrationValidationSchema),
        defaultValues: datas,
        mode: "onChange",
        reValidateMode: "onChange",
    });

    const styles = useStyles();

    return (
        <>
            <Helmet title={t`Connexion`} />
            <form
                className={styles.form_container}
                noValidate
                onSubmit={handleSubmit(props.onSubmit)}
                onChange={(e) => {
                    if (props.onFormChange) {
                        return props.onFormChange({ fieldname: e.target?.name, value: e.target?.value });
                    }
                }}
            >
                <Box className={styles.containerGrid}>
                    <Box
                        id="email-box"
                        className={`${styles.itemGrid} ${props.itemGridStyle}`}
                        style={{ marginBottom: "24px" }}
                    >
                        <UncontrolledSimpleInput
                            inputRef={register}
                            name="subscriptionEmailAddress"
                            error={errors.subscriptionEmailAddress?.error?.message}
                            label={ti18next("inputs.label.email")}
                            placeholder="Entrez votre adresse e-mail"
                            required
                            onFocus={() => props.setCalled(false)}
                        />
                    </Box>
                    <Box
                        id="password-box"
                        className={`${styles.itemGrid} ${props.itemGridStyle}`}
                        style={{ marginBottom: "12px" }}
                    >
                        <PasswordInput
                            inputRef={register}
                            name="subscriptionPassword"
                            error={errors.subscriptionPassword?.error?.message}
                            label={ti18next("inputs.label.password")}
                            placeholder="Entrez votre mot de passe"
                            required
                            onFocus={() => props.setCalled(false)}
                        />
                    </Box>
                </Box>

                {props.matchCheckbox && props.matchCheckbox?.value && (
                    <BaseCheckbox
                        style={{ marginLeft: 0, alignItems: "start", textAlign: "left" }}
                        styleCheckbox={{ marginTop: "2px" }}
                        checked={props.matchCheckbox?.checked}
                        value={props.matchCheckbox?.value}
                        onChange={props.matchCheckbox?.onChange}
                        label={props.matchCheckbox?.label}
                    />
                )}

                <Box className={styles.submit_button}>
                    {props.homeConnexion ? (
                        <BaseButton
                            type="submit"
                            disabled={!formState.isValid}
                            color="primary"
                            variant="contained"
                            fullWidth
                        >
                            {ti18next("connexion.button")}
                        </BaseButton>
                    ) : (
                        <BaseButton
                            type="submit"
                            variant="contained"
                            disabled={!formState.isValid || props.disabled}
                            color="primary"
                            fullWidth
                        >
                            {ti18next("common.login")}
                        </BaseButton>
                    )}
                    <ToastContainer
                        autoClose={3500}
                        draggable
                        pauseOnHover
                        pauseOnFocusLoss
                        closeOnClick
                        position={"top-right"}
                    />
                </Box>
            </form>
            <Box className={styles.submit_button}>
                {props.textSignUp ? (
                    <Typography
                        variant="h6"
                        style={{
                            color: theme.palette.primary.dark,
                            textAlign: "center",
                        }}
                    >
                        Vous n&apos;avez pas de compte ?{" "}
                        <Link
                            onClick={() => {
                                history.push("/bienvenue/inscription");
                            }}
                            underline="always"
                            className={styles.subscribe}
                        >
                            Inscrivez-vous
                        </Link>
                    </Typography>
                ) : null}

                <Typography
                    variant={props.subtitle1 ? "subtitle1" : "h6"}
                    style={{
                        color: theme.palette.primary.dark,
                        textAlign: "center",
                    }}
                >
                    {props.contactUs ? (
                        <>
                            {ti18next("connexion.troubleLogin")}
                            <Link
                                variant={props.subtitle1 ? "subtitle1" : "h6"}
                                href="mailto:support@feret.com"
                                underline="always"
                                className={styles.subscribe}
                            >
                                {ti18next("common.contactUs")}
                            </Link>
                        </>
                    ) : (
                        <>
                            Vous avez oublié votre mot de passe ?{" "}
                            <Link
                                variant={props.subtitle1 ? "subtitle1" : "h6"}
                                onClick={() => props.forgotPasswordOnClick()}
                                underline="always"
                                className={styles.subscribe}
                            >
                                Réinitialisez-le
                            </Link>
                        </>
                    )}
                </Typography>
            </Box>
        </>
    );
};

ConnectionContainer.propTypes = {
    contactUs: bool,
    forgotPasswordOnClick: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    called: PropTypes.bool,
    setCalled: PropTypes.func,
    width: PropTypes.string,
    textSignUp: PropTypes.bool,
    onFormChange: PropTypes.func,
    matchCheckbox: PropTypes.shape({
        value: PropTypes.string,
        onChange: PropTypes.func,
        label: PropTypes.any,
        checked: PropTypes.bool,
    }),
    homeConnexion: bool,
    marginTop: string,
    disabled: bool,
    subtitle1: bool,
    itemGridStyle: any,
};

export default ConnectionContainer;
