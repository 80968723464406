import PropTypes from "prop-types";
import { Typography, Box, makeStyles } from "@material-ui/core";
import theme from "../../globalTheme";
import BaseButton from "../../Components/Buttons/BaseButton";
import SubscriptionContainer from "./SubscriptionContainer";
import Tag from "../../Components/Chips/Tag";
import bordeaux_region from "../../assets/SubscriptionPage/region_bordelaise.svg";
import { getIdentityType, colors } from "../constants";

import Cave from "../../assets/SubscriptionPage/cave-coop";
import Estate from "../../assets/SubscriptionPage/propriete";
import Negociant from "../../assets/SubscriptionPage/maison_negoce";
import Other from "../../assets/SubscriptionPage/autre_pro_vin";

const useStyles = makeStyles(() => ({
    roleButton: () => ({
        width: "100%",
        minHeight: "80px",
    }),
    container: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "center",
    },
    typo: {
        color: theme.palette.primary.dark,
        alignSelf: "center",
        fontWeight: "400",
    },
    iconContainer: {
        margin: "0% 2%",
        height: "40px",
    },
    subscription__items__wrapper: {
        display: "flex",
        flexWrap: "wrap",
        [theme.breakpoints.up("sm")]: {
            gap: "16px",
        },
        [theme.breakpoints.up("md")]: {
            gap: "32px",
        },
    },
    subscription__item__wrapper: {
        [theme.breakpoints.up("sm")]: {
            width: "100%",
        },
        [theme.breakpoints.up("md")]: {
            width: "calc(50% - 16px)",
            marginBottom: 0,
        },
    },
}));

const choices = [
    {
        id: "estate",
        label: getIdentityType("estate").title,
        icon: <Estate color={colors.enabled} />,
        disabled: false,
    },
    {
        id: "cave",
        label: getIdentityType("cave").title,
        icon: <Cave color={colors.disabled} />,
        disabled: true,
    },
    {
        id: "negociant",
        label: getIdentityType("negociant").title,
        icon: <Negociant color={colors.disabled} />,
        disabled: true,
    },
    {
        id: "other",
        label: getIdentityType("other").title,
        icon: <Other color={colors.disabled} />,
        disabled: true,
    },
];

const getChoice = (item, subscriptionStateSelectedRole, subscriptionDispatch, styles, key, onClickNext) => (
    <Box className={styles.subscription__item__wrapper} id={key}>
        <BaseButton
            className={styles.roleButton}
            variant="outlined"
            disabled={item.disabled}
            style={{
                backgroundColor: item.disabled
                    ? "#FDF6F6"
                    : subscriptionStateSelectedRole === item.id
                    ? "#FCF6F6"
                    : "white",
                width: "100%",
                minHeight: "80px",
                border: subscriptionStateSelectedRole === item.id ? "0.6px solid" : "0.4px solid",
                borderColor:
                    subscriptionStateSelectedRole === item.id
                        ? theme.palette.primary.dark
                        : theme.palette.secondary.main,
                borderRadius: "10px",
            }}
            onClick={() => {
                subscriptionDispatch({
                    type: "setValue",
                    payload: { fieldName: "selectedRole", fieldValue: item.id },
                });
                onClickNext();
            }}
        >
            <Box className={styles.container}>
                {item.disabled && (
                    <Box
                        style={{
                            position: "absolute",
                            right: "12px",
                            top: "-6px",
                            backgroundColor: "#D5BAC3",
                            color: "#360318",
                            padding: "0 8px",
                            fontSize: "12px",
                            borderRadius: "4px",
                        }}
                    >
                        À venir
                    </Box>
                )}
                <Box className={styles.iconContainer}>{item.icon}</Box>
                <Typography variant="h5" className={styles.typo} style={{ color: item.disabled && "#dbc3ca" }}>
                    {item.label}
                </Typography>
            </Box>
        </BaseButton>
    </Box>
);

const getTag = (
    <Tag
        style={{
            position: "absolute",
            top: "0px",
            left: "30px",
            boxShadow: " 0px 3px 10px #00000029",
            borderTopRightRadius: "0",
            borderTopLeftRadius: "0",
        }}
        borderTopLeftRadius={0}
        borderTopRightRadius={0}
        color="secondary"
        size="large"
        icon={<img height={25} width={25} src={bordeaux_region} />}
        label="Région bordelaise"
    />
);

const RoleIdentificationContainer = (props) => {
    const styles = useStyles(props);

    return (
        <SubscriptionContainer
            backLink={false}
            title="Vous souscrivez pour..."
            titleVariant="h3"
            // buttonLabel="Suivant"
            disabled={props.subscriptionStateSelectedRole !== null ? false : true}
            style={{ position: "relative", margin: 0, ...props.style }}
            // onClickNext={props.onClickNext}
        >
            {getTag}
            {props.popin && (
                <Box
                    style={{ backgroundColor: theme.palette.secondary.light, padding: "10px 8%", marginBottom: "43px" }}
                >
                    <Typography variant="h6" style={{ color: theme.palette.primary.main }}>
                        Si vous modifiez ce choix vous allez automatiquement retourner à l’étape abonnement. Pour vous
                        proposer une solution sur-mesure, nous adaptons nos formules à votre profession.
                    </Typography>
                </Box>
            )}
            <Box className={styles.subscription__items__wrapper}>
                {choices.map((item, key) =>
                    getChoice(
                        item,
                        props.subscriptionStateSelectedRole,
                        props.subscriptionDispatch,
                        styles,
                        key,
                        props.onClickNext
                    )
                )}
            </Box>
        </SubscriptionContainer>
    );
};

RoleIdentificationContainer.propTypes = {
    subscriptionStateSelectedRole: PropTypes.number,
    subscriptionDispatch: PropTypes.func,
    onClickNext: PropTypes.func,
    // When it's not in Card mode, diplays different texts
    popin: PropTypes.bool,
    style: PropTypes.object,
};

export default RoleIdentificationContainer;
