import { Box, Card, CardContent, Typography, makeStyles } from "@material-ui/core";
import { bool, number, object, string } from "prop-types";
import bgBlanc from "../../assets/searchEngine/wine/bg-home-blanc.svg";
import bgLiq from "../../assets/searchEngine/wine/bg-home-liquoreux.svg";
import bgMoe from "../../assets/searchEngine/wine/bg-home-moelleux.svg";
import bgRose from "../../assets/searchEngine/wine/bg-home-rose.svg";
import bgRouge from "../../assets/searchEngine/wine/bg-home-rouge.svg";
import bottleBlanc from "../../assets/searchEngine/wine/bottle-blanc.png";
import bottleRose from "../../assets/searchEngine/wine/bottle-rose.png";
import bottleRouge from "../../assets/searchEngine/wine/bottle-rouge.png";
import theme from "../../globalTheme";

import Brightness1Icon from "@material-ui/icons/Brightness1";
import { useCustomHistory } from "hooks";
import { useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import Loading from "../../Components/Loader/Loading";
import Tooltip from "../../Components/Tooltips/Tooltip";
import { useGetWineCardInformationQuery } from "../../generated/graphql";
import { urlFormatted, useWindowDimensions } from "../../helpers/helpers";

const useStyles = makeStyles(() => ({
    root: (props) => {
        const getBg = () => {
            if (props?.color === "Rouge") return bgRouge;
            else if (props?.color === "Rosé") return bgRose;
            if (props?.sweetness === "Liquoreux") return bgLiq;
            if (props?.sweetness === "Moelleux") return bgMoe;
            return bgBlanc;
        };

        return {
            cursor: "pointer",
            background: "transparent",
            backgroundImage: `url(${getBg()})`,
            backgroundRepeat: "no-repeat",
            backgroundPositionX: "center",
            backgroundPositionY: "40%",
            boxShadow: "none",
            borderRadius: 5,
            "&:hover": {
                backgroundColor: theme.palette.common.white,
            },
        };
    },
    contentPrimary: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
    },
    cardContent: {
        position: "relative",
        padding: "1em",
        width: "100%",
        "&:last-child": {
            paddingBottom: "1em",
        },
    },
    containerImage: {
        minHeight: 150,
    },
    content: {
        marginTop: "15px",
        padding: "0",
        paddingLeft: 0,
    },
    name: {
        color: theme.palette.primary.dark,
        display: "inline-flex",
        cursor: "pointer",
        marginTop: 0,
        marginBottom: 0,
        fontFamily: "lato",
        fontWeight: 400,
    },
    appellation: {
        color: theme.palette.primary.main,
        fontWeight: 400,
    },
    brightness1Icon: {
        width: 4,
        height: 4,
        color: theme.palette.primary.light,
        margin: "2px 3px",
        marginTop: 0,
    },
}));

const HomeWineCard = (props) => {
    const styles = useStyles(props);
    const { width } = useWindowDimensions();
    const cardContentRef = useRef();
    const history = useCustomHistory();
    const { data, loading } = useGetWineCardInformationQuery({
        variables: {
            wineId: props.wineId,
        },
    });

    const getImageBottle = () => {
        if (loading) {
            return <Loading loading={true} />;
        }
        if (data?.GetWineCardInformation?.img?.twicpicURL) {
            return data?.GetWineCardInformation?.img?.twicpicURL;
        }
        switch (props?.color) {
            case "Rouge":
                return bottleRouge;
            case "Rosé":
                return bottleRose;
            case "Blanc":
                return bottleBlanc;
            default:
                break;
        }
    };

    const getLimitName = () => {
        if (props?.cardWidthFixed) {
            return props?.cardWidthFixed;
        } else {
            if (width > 1280) {
                return 35;
            }

            if (width >= 960 && width < 1280) {
                return 45;
            }

            if (width >= 600 && width < 960) {
                return 35;
            }

            if (width >= 500 && width < 600) {
                return 80;
            }

            if (width < 500) {
                return 50;
            }
        }
    };

    const cardLink = useMemo(() => {
        return history.addLocale(
            `/${urlFormatted(props.wine?.wineEntityShortId, props.wine?.wineEntityName)}/vins/${urlFormatted(
                props.wine?.wineShortId,
                props.wine?.brandName
            )}`
        );
    }, [data, props.wineId]);

    if (props.arrayLoading && loading) {
        return <Loading />;
    }

    return (
        <Card
            component={Link}
            className={styles.root}
            style={{
                width: props.width ?? "auto",
                height: props.height ?? "auto",
                textDecoration: "none",
                display: "flex",
            }}
            to={cardLink}
        >
            <CardContent ref={cardContentRef} className={styles.cardContent}>
                <Box className={styles.contentPrimary}>
                    <Box className={styles.containerImage}>
                        <img
                            src={getImageBottle()}
                            alt={`vin ${props?.color}`}
                            style={{ height: 150, margin: "auto" }}
                        />
                    </Box>
                    <Box className={styles.content}>
                        {props.name?.length >= getLimitName() ? (
                            <>
                                <Tooltip color="primary" title={props?.name} arrow>
                                    <Typography variant="h4" className={styles.name}>
                                        {props.name?.slice(0, getLimitName())} ...
                                    </Typography>
                                </Tooltip>
                            </>
                        ) : (
                            <Typography variant="h4" className={styles.name}>
                                {props?.name}
                            </Typography>
                        )}
                        <Typography variant="h6" className={styles.appellation}>
                            {(props?.color === "Blanc") | (props?.color === "Rosé") ? props?.color : null}
                            {(props?.color === "Blanc") | (props?.color === "Rosé") ? (
                                <Brightness1Icon className={styles.brightness1Icon} />
                            ) : null}
                            {props?.appellation}
                        </Typography>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

HomeWineCard.propTypes = {
    wineId: string,
    wineEntityId: string,
    name: string,
    color: string,
    appellation: string,
    wineEntityName: string,
    className: object,
    width: number,
    height: number,
    sweetness: string,
    cardWidthFixed: number,
    wine: object,
    arrayLoading: bool,
};

export default HomeWineCard;
