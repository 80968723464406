import PropTypes, { object } from "prop-types";
import { Box, Card, CardContent, Typography, makeStyles } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import theme from "../../globalTheme";
import reward from "../../assets/searchEngine/wine/reward.svg";
import score from "../../assets/searchEngine/wine/score.svg";
import bottleBlanc from "../../assets/searchEngine/wine/bottle-blanc.png";
import bottleRouge from "../../assets/searchEngine/wine/bottle-rouge.png";
import bottleRose from "../../assets/searchEngine/wine/bottle-rose.png";
import bgRouge from "../../assets/searchEngine/wine/bg-rouge.svg";
import bgBlanc from "../../assets/searchEngine/wine/bg-blanc.svg";
import bgRose from "../../assets/searchEngine/wine/bg-rose.svg";
import bgLiq from "../../assets/searchEngine/wine/bg-liquoreux.svg";
import bgMoe from "../../assets/searchEngine/wine/bg-moelleux.svg";

import Brightness1Icon from "@material-ui/icons/Brightness1";
import Tooltip from "../../Components/Tooltips/Tooltip";
import { useGetWineCardInformationQuery } from "../../generated/graphql";
import Loading from "../../Components/Loader/Loading";
import { urlFormatted, useWindowDimensions } from "../../helpers/helpers";
import { t, Trans } from "@lingui/macro";
import { useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import { useCustomHistory } from "hooks";

const useStyles = makeStyles((e) => ({
    root: (props) => {
        const getBg = () => {
            if (props?.color === "Rouge") return bgRouge;
            else if (props?.color === "Rosé") return bgRose;
            if (props?.sweetness === "Liquoreux") return bgLiq;
            if (props?.sweetness === "Moelleux") return bgMoe;
            return bgBlanc;
        };

        return {
            textDecoration: "none",
            cursor: "pointer",
            backgroundColor: theme.palette.common.white,
            backgroundImage: `url(${getBg()})`,
            backgroundRepeat: "no-repeat",
            backgroundPositionX: "left",
            backgroundPositionY: "95%",
            boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.08)",
            borderRadius: 5,
            marginBottom: "1rem",
            border: `0.4px solid ${theme.palette.secondary.main}`,
        };
    },
    cardContent: {
        position: "relative",
        padding: "0",
        paddingLeft: 10,
        // minHeight: 260,
    },
    missing: {
        background: theme.palette.primary.light,
        borderRadius: 16,
        padding: "5px 16px",
    },
    missingTypo: {
        color: theme.palette.common.white,
    },
    contentTop: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginTop: "8px",
    },
    BoxRewardAndScore: {
        height: 41,
    },
    contentTopIcon: (props) => ({
        display: "flex",
        justifyContent: "flex-end",
        gap: 8,
        border: props?.score || props?.reward ? `0.4px solid ${theme.palette.secondary.main}` : "none",
        borderTop: "none",
        borderRight: "none",
        borderRadius: "0px 0px 0px 4px",
        padding: "5px 9px",
    }),
    ContentPrimary: {
        display: "flex",
    },
    containerImage: {
        minHeight: 150,
        marginRight: 19,
    },
    content: {
        marginTop: "15px",
        padding: "0 16px",
        paddingLeft: 0,
    },
    name: {
        color: theme.palette.primary.dark,
        height: 48,
        // overflow: "hidden",
    },
    appellation: {
        color: theme.palette.primary.main,
        fontWeight: 400,
        height: 44,
    },
    classification: {
        color: theme.palette.primary.dark,
        fontWeight: 400,
        height: 12,
    },
    estate: {
        color: theme.palette.secondary.dark,
        fontWeight: 400,
    },
    estateButton: {
        marginLeft: "4px",
        color: theme.palette.secondary.dark,
        textDecoration: "underline",
    },
    brightness1Icon: {
        width: 4,
        height: 4,
        color: theme.palette.primary.light,
        margin: "2px 3px",
        marginTop: 0,
    },
    tag: {
        borderRadius: 4,
        padding: "0px 2px",
        cursor: "pointer",
    },
    vintages: {
        display: "flex",
        flexWrap: "wrap",
        gap: 2,
        marginTop: 7,
    },
    vintageLabel: {
        color: theme.palette.primary.light,
        fontWeight: 400,
        lineHeight: 1.6,
    },
    viewMore: {
        color: theme.palette.secondary.dark,

        "&:hover": {
            textDecoration: "underline",
        },
    },
}));

const SearchResultWineCard = (props) => {
    const styles = useStyles(props);
    const { width } = useWindowDimensions();
    const cardContentRef = useRef();
    const { addLocale } = useCustomHistory();

    const { data, loading } = useGetWineCardInformationQuery({
        variables: {
            wineId: props.wineId,
        },
    });

    const getImageBottle = () => {
        if (loading) {
            return <Loading loading={true} />;
        }
        if (data?.GetWineCardInformation?.img?.twicpicURL) {
            return data?.GetWineCardInformation?.img?.twicpicURL;
        }
        switch (props?.color) {
            case "Rouge":
                return bottleRouge;
            case "Rosé":
                return bottleRose;
            case "Blanc":
                return bottleBlanc;
            default:
                break;
        }
    };

    const getLimitName = () => {
        if (props?.cardWidthFixed) {
            return props?.cardWidthFixed;
        } else {
            if (width > 1280) {
                return 35;
            }

            if (width >= 960 && width < 1280) {
                return 45;
            }

            if (width >= 600 && width < 960) {
                return 35;
            }

            if (width >= 500 && width < 600) {
                return 80;
            }

            if (width < 500) {
                return 50;
            }
        }
    };

    const cardLink = useMemo(() => {
        return addLocale(
            `/${urlFormatted(props.wine?.wineEntityShortId, props.wine?.wineEntityName)}/vins/${urlFormatted(
                props.wine?.wineShortId,
                props.wine?.brandName
            )}`
        );
    }, [data, props.wineId]);

    return (
        <Card
            component={Link}
            className={styles.root}
            style={{ width: props.width ?? "auto", height: props.height ?? "auto" }}
            to={cardLink}
        >
            <CardContent ref={cardContentRef} className={styles.cardContent}>
                <Box className={styles.contentTop}>
                    {props?.missing && (
                        <Box className={styles.missing} style={{ marginRight: props?.score || props?.reward ? 4 : 8 }}>
                            <Typography variant="h6" className={styles.missingTypo}>
                                <Trans>Manquant</Trans> :{" "}
                                <span style={{ textDecoration: "line-through" }}>{props?.missing}</span>
                            </Typography>
                        </Box>
                    )}

                    <Box className={styles.BoxRewardAndScore}>
                        <Box className={styles.contentTopIcon}>
                            {data?.GetWineCardInformation?.rewarded && (
                                <Tooltip color="primary" title={t`Vin médaillé`} arrow>
                                    <Box>
                                        <img src={reward} styles={{ width: 10, height: 10 }} alt={t`Vin médaillé`} />
                                    </Box>
                                </Tooltip>
                            )}

                            {data?.GetWineCardInformation?.scored && (
                                <Tooltip color="primary" title={t`Vin noté`} arrow>
                                    <Box>
                                        <img src={score} styles={{ width: 10, height: 10 }} alt={t`Vin noté`} />
                                    </Box>
                                </Tooltip>
                            )}
                        </Box>
                    </Box>
                </Box>

                <Box className={styles.ContentPrimary}>
                    <Box className={styles.containerImage}>
                        <img src={getImageBottle()} alt={`vin ${props?.color}`} style={{ height: 179 }} />
                    </Box>

                    <Box className={styles.content}>
                        {props.name?.length >= getLimitName() ? (
                            <>
                                <Tooltip color="primary" title={props?.name} arrow>
                                    <Typography
                                        variant="h4"
                                        className={styles.name}
                                        style={{
                                            display: "inline-flex",
                                            cursor: "pointer",
                                            marginTop: 0,
                                            marginBottom: 0,
                                        }}
                                    >
                                        {props.name?.slice(0, getLimitName())} ...
                                    </Typography>
                                </Tooltip>
                            </>
                        ) : (
                            <Typography variant="h4" className={styles.name}>
                                {props?.name}
                            </Typography>
                        )}

                        <Typography variant="h6" className={styles.appellation}>
                            {(props?.color === "Blanc") | (props?.color === "Rosé") ? props?.color : null}
                            {(props?.color === "Blanc") | (props?.color === "Rosé") ? (
                                <Brightness1Icon className={styles.brightness1Icon} />
                            ) : null}
                            {props?.appellation}
                        </Typography>

                        <Typography variant="subtitle1" className={styles.classification}>
                            {props?.classification}
                        </Typography>

                        <Box style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="subtitle1" className={styles.estate}>
                                <Trans>par</Trans>
                            </Typography>
                            <Link
                                className={styles.estateButton}
                                to={addLocale(
                                    `/${urlFormatted(props.wine?.wineEntityShortId, props.wine?.wineEntityName)}`
                                )}
                            >
                                <Typography variant="subtitle1" className={styles.estate}>
                                    {props?.wineEntityName}
                                </Typography>
                            </Link>
                        </Box>

                        {/* <Box className={styles.vintages}>
                            {data?.GetWineCardInformation?.vintageCardInformation?.length > 0 &&
                                data?.GetWineCardInformation?.vintageCardInformation?.map((v, i) => (
                                    <Box
                                        key={v.year + v.cuvee}
                                        className={styles.tag}
                                        style={{ backgroundColor: getColor() }}
                                        onClick={() => {
                                        }}
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            className={styles.vintageLabel}
                                            style={{
                                                color:
                                                    props?.color === "Rouge"
                                                        ? theme.palette.common.white
                                                        : theme.palette.primary.dark,
                                            }}
                                        >
                                            {v.cuvee ? v.year + " - " + v.cuvee : v.year}
                                        </Typography>
                                    </Box>
                                ))}
                        </Box> */}
                    </Box>
                </Box>
            </CardContent>
            <Box style={{ display: "flex", justifyContent: "flex-end", margin: "0 14px 14px 7px" }}>
                <Box style={{ display: "flex", cursor: "pointer" }}>
                    <Typography variant="subtitle1" className={styles.viewMore}>
                        <Trans>Découvrir</Trans>
                    </Typography>
                    <ArrowForwardIcon style={{ color: theme.palette.secondary.dark, width: 17, height: 17 }} />
                </Box>
            </Box>
        </Card>
    );
};

SearchResultWineCard.propTypes = {
    wineId: PropTypes.string,
    wineEntityId: PropTypes.string,
    vintageIds: PropTypes.string,
    name: PropTypes.string,
    years: PropTypes.array,
    color: PropTypes.string,
    appellation: PropTypes.string,
    classification: PropTypes.string,
    wineEntityName: PropTypes.string,
    reward: PropTypes?.bool,
    score: PropTypes?.bool,
    className: PropTypes.object,
    width: PropTypes.number,
    height: PropTypes.number,
    missing: PropTypes.string,
    sweetness: PropTypes.string,
    cardWidthFixed: PropTypes.number,
    wine: object,
};

export default SearchResultWineCard;
