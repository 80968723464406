import { useState } from "react";
import { Box, Card, CardActionArea, makeStyles, Typography } from "@material-ui/core";
import BaseChip from "Components/Chips/BaseChip";
import BaseRadio from "Components/Radio/BaseRadio";
import PropTypes, { array, string } from "prop-types";
import Arrow from "../../../Components/Arrow/Arrow";
import BaseTooltip from "../../../Components/Tooltips/Tooltip";
import theme from "../../../globalTheme";
import PlusModal from "Components/FrontBESV/PlusModal";

const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: "10px",
        width: "100%",
        boxShadow: "none",
    },
    container: (props) => {
        return {
            maxWidth: "100%",
            padding: "1rem 1.5rem",
            border: "0.4px solid",
            borderRadius: "10px",
            position: "relative",
            borderColor: props.selected ? "transparent" : theme.palette.secondary.main,
            color: props.selected ? theme.palette.primary.dark : theme.palette.common.black,
            backgroundColor: props.selected ? theme.palette.secondary.transparent : theme.palette.common.white,
            "&:hover": {
                backgroundColor: !props.selected
                    ? theme.palette.action.disabledBackground
                    : theme.palette.secondary.transparent,
            },
        };
    },
    header_card: {
        display: "grid",
        gridTemplateColumns: "repeat(12,1fr)",
    },
    title_container: {
        display: "flex",
        alignItems: "start",
        gap: "1rem",
        gridColumn: "1 / span 9",
    },
    priceBox: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    price: {
        color: theme.palette.primary.dark,
    },
    typography: {
        fontWeight: "bold",
    },
    chips_container: {
        gap: "0.2rem",
        display: "flex",
        flexWrap: "wrap",
        [theme.breakpoints.up("md")]: {
            marginLeft: "2.25rem",
        },
    },
    checkboxRewardBox: {
        border: `0.4px solid ${theme.palette.secondary.dark}`,
        borderRadius: 6,
        padding: "7px 6px 3px 6px",
        width: "fit-content",
        [theme.breakpoints.up("md")]: {
            marginLeft: "2.25rem",
        },
    },
    price_container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        gridColumn: "auto/span 3",
    },
    firstPrice: {
        color: theme.palette.primary.dark,
    },
    mansalva_font: {
        fontFamily: "Mansalva",
        color: theme.palette.primary.dark,
    },
}));

const PackDescription = (props) => {
    const styles = useStyles(props);
    const [openDetails, setOpenDetails] = useState();

    const getPrice = () => {
        return (
            <Box className={styles.price_container}>
                <Box className={styles.priceBox}>
                    {props.discountPriceChildren && <Arrow rotation="113" arrowPosition={"end"} icon="primary" />}

                    <Typography
                        variant="h3"
                        className={styles.firstPrice}
                        style={{
                            fontWeight: "900",
                            fontFamily: "Lato",
                            whiteSpace: "nowrap",
                        }}
                    >
                        {props.discountPriceChildren || props.priceChildren}
                    </Typography>
                    <Typography
                        variant="h6"
                        style={{ marginLeft: "5px", alignSelf: "flex-end" }}
                        className={styles.price}
                    >
                        {props.periodChildren}
                    </Typography>
                </Box>
                {props.discountPriceChildren && (
                    <Box className={styles.priceBox}>
                        <Typography
                            variant="body2"
                            className={styles.firstPrice}
                            style={{
                                textDecorationLine: "line-through",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {props.priceChildren}
                        </Typography>
                        <Typography
                            variant="h6"
                            style={{ marginLeft: "5px", alignSelf: "flex-end" }}
                            className={styles.price}
                        >
                            {props.periodChildren}
                        </Typography>
                    </Box>
                )}
                {props.detailPrice && (
                    <Typography style={{ color: theme.palette.primary.dark, fontSize: "14px" }}>
                        {props.detailPrice}
                    </Typography>
                )}
            </Box>
        );
    };
    return (
        <Card className={styles.card}>
            <CardActionArea
                className={styles.container}
                disabled={props.disabled}
                onClick={(e1, e2) => {
                    props.onChangeSelected(e2);
                }}
            >
                <Box className={styles.header_card}>
                    <Box className={styles.title_container}>
                        <BaseTooltip title={props.tooltip}>
                            <Box style={{ zIndex: 10 }}>
                                <BaseRadio
                                    size="small"
                                    checked={props.selected}
                                    className={styles.radio}
                                    color="theme.palette.primary.dark"
                                    onChange={() => {}}
                                />
                            </Box>
                        </BaseTooltip>

                        <Box>
                            <img src={props.logoSubscriptionType} style={{ height: "35px" }} />
                        </Box>
                        <Box>
                            <Typography variant="h6" className={styles.mansalva_font} style={{ fontWeight: "600" }}>
                                {props.publication}
                            </Typography>
                            <Typography color="theme.palette.primary.dark" variant="h3" className={styles.typography}>
                                {props.titleChildren}
                            </Typography>
                            <Typography variant="h6" className={styles.mansalva_font}>
                                {props.description}
                            </Typography>
                        </Box>
                    </Box>
                    {getPrice()}
                </Box>
                <Box className={styles.chips_container}>
                    {props.include?.map((title, i) => (
                        <BaseChip key={`feature-included-${i}`} title={title} />
                    ))}
                    {props.exclude?.map((title, i) => (
                        <BaseChip key={`feature-excluded-${i}`} title={title} color="secondary" />
                    ))}
                </Box>
            </CardActionArea>
            <PlusModal open={openDetails} onClose={() => setOpenDetails(false)} />
        </Card>
    );
};

PackDescription.propTypes = {
    logoSubscriptionType: PropTypes.string,
    titleChildren: PropTypes.string,
    priceChildren: PropTypes.string,
    periodChildren: PropTypes.string,
    checkbox: string,
    include: array,
    exclude: array,
    description: string,
    tooltip: PropTypes.bool,
    selected: PropTypes.bool,
    onChangeSelected: PropTypes.func,
    disabled: PropTypes.bool,
    detailPrice: PropTypes.any,
    discountPriceChildren: string,
};

export default PackDescription;
