import { any, array, bool, object, string } from "prop-types";

import { t } from "@lingui/macro";
import { Box, Hidden, makeStyles, Typography } from "@material-ui/core";
import fioriture1 from "assets/CommunePage/fioriture1.svg";
import fioriture2 from "assets/CommunePage/fioriture2.svg";
import imgSub1 from "assets/propriétéPage/vinif.png";
import BaseCarousel from "Components/Carousel/BaseCarousel";
import Page500Front from "Components/ErrorPage/Page500Front";
import Helmet from "Components/Helmet/Helmet";
import SocialMediaIcons from "Components/Icons/SocialMediaIcons";
import LoadingWithImage from "Components/Loader/LoadingWithImage";
import { SUBSCRIPTION_TYPES } from "config/constants";
import FrontContentBottom from "Front/FrontContentBottom";
import FrontContentTop from "Front/FrontContentTop";
import FrontMainContainer from "Front/FrontMainContainer";
import ActivityDetails from "FrontActivities/ActivityDetails";
import ActivitySummary from "FrontActivities/ActivitySummary";
import ActivityCardsContainer from "FrontEstate/Components/Cards/ActivityCardsContainer";
import ContactPropertyFormCard from "FrontEstate/Components/Cards/ContactPropertyFormCard/ContactPropertyFormCard";
import WineCardsContainer from "FrontEstate/Components/Cards/WineCardsContainer";
import EstateTitledSemiUnderlined from "FrontEstate/Components/EstateTitledSemiUnderlined";
import MainTitle from "FrontEstate/MainTitle";
import TitleStandalone from "FrontEstate/TitleStandalone";
import { useListActivitiesQuery } from "generated/graphql";
import theme from "globalTheme";
import { useWindowDimensions } from "helpers/helpers";
import { Redirect } from "react-router-dom";
import ActivityCommonalities from "./ActivityCommonalities";

const useStyles = makeStyles((theme) => ({
    card_content: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        columnGap: "1rem",
    },
    activityPage__socialMedia: {
        display: "flex",
        justifyContent: "flex-end",
        [theme.breakpoints.up("xl")]: {
            position: "absolute",
            right: "-30px",
            top: 0,
        },
        alignItems: "center",
    },
    activityPage__chip: {
        fontStyle: "14px",
        minHeight: 32,
        display: "flex",
        alignItems: "center",
        paddingRight: "16px",
        paddingLeft: "16px",
        borderRadius: "16px",
        width: "fit-content",
    },
    title__box: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    flex: {
        display: "flex",
    },
    back__container: {
        zIndex: 2000,
        boxShadow: "0px 3px 16px #00000014",
        position: "fixed",
        top: "45%",
        background: theme.palette.common.white,
        width: 180,
        borderRadius: "0px 10px 10px 0px",
        minWidth: 60,
        padding: "8px 16px",
    },
    back__typo: {
        fontSize: 16,
        color: theme.palette.primary.dark,
        fontFamily: "Lato",
    },
    // GRID
    block__left: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "auto / span 12",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 12",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 7",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 8",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "auto / span 8",
        },
    },
    block__right: {
        zIndex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        [theme.breakpoints.up("xs")]: {
            gridColumn: "auto / span 12",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 12",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 5",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 4",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "auto / span 4",
        },
    },
    block__left__scrollable: {
        position: "relative",

        [theme.breakpoints.up("xs")]: {
            gridColumn: "auto / span 12",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 12",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 7",
            height: 500,
            maxHeight: 500,
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 8",
            height: 615,
            maxHeight: 615,
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "auto / span 8",
            height: 650,
            maxHeight: 650,
        },
        backgroundColor: "transparent",
        borderRadius: 4,
        overflowX: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "8px",
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            paddingRight: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "20px",
        },
    },
    block__summary: {
        position: "relative",
        [theme.breakpoints.up("xs")]: {
            gridColumn: "auto / span 12",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 12",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 5",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 4",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "auto / span 4",
        },
    },
}));

const ActivityPage = (props) => {
    const activityId = props.activityId;
    const styles = useStyles({ ...props }, theme);
    const activityType = props.activity?.types?.[0]?.type;
    const { width } = useWindowDimensions();
    const { data: activities, loading: loadingActivities, error } = useListActivitiesQuery({
        variables: {
            wineEntityId: props.wineEntityId,
            omit: activityId ? [activityId] : null,
            locale: props.locale,
        },
    });
    const restriction = activities?.ListActivities?.subscriptionName === SUBSCRIPTION_TYPES.basic;
    const getActivityName = (name) => {
        if (name) {
            return (
                <EstateTitledSemiUnderlined style={{ marginTop: 0 }} title={props.activity?.name} heightAfter={32} />
            );
        } else {
            return "";
        }
    };
    if (props.loading || loadingActivities) {
        return <LoadingWithImage />;
    }

    if (!activities.ListActivities || restriction) {
        return <Redirect to="/" />;
    }
    if (error) {
        return <Page500Front />;
    }

    const getActivityType = (types, absolute) => {
        if (types?.[0]?.type) {
            return (
                <Typography
                    style={{
                        color: types?.[0]?.textColor ? types?.[0]?.textColor : theme.palette.common.white,
                        backgroundColor: types?.[0]?.bgColor ? types?.[0]?.bgColor : theme.palette.primary.light,
                        border:
                            types?.[0]?.bgColor === theme.palette.common.white
                                ? `1px solid ${theme.palette.primary.dark}`
                                : "",
                        zIndex: 1000,
                        position: absolute && "absolute",
                        right: absolute && 0,
                        margin: absolute && 10,
                        top: absolute && 0,
                    }}
                    className={styles.activityPage__chip}
                >
                    {types?.[0]?.type}
                </Typography>
            );
        } else {
            return "";
        }
    };

    return (
        <>
            <Helmet
                title={props.activity?.name}
                content={`Partez à la découverte d'une activité oenotouristique unique à la propriété ${props.wineEntityName}, et profitez d'une expérience immersive pour découvrir tous les secrets. Réservez dès maintenant votre visite pour une expérience inoubliable en plein cœur du vignoble bordelais.`}
            />
            <FrontContentTop
                backgroundImage={props.activity?.bgImage?.twicpicURL}
                links={props.breadcrumbs}
                previousPage={props.previousPage}
            >
                {/* MAIN TITLE */}
                {props.frontTitle ? <MainTitle wineEntityId={props.wineEntityId} /> : null}

                <FrontMainContainer
                    hideFloritureTopImg={props.hideFloritureTopImg}
                    style={{
                        backgroundImage: `url(${fioriture1}), url(${fioriture2})`,
                        backgroundPositionY: "bottom",
                        backgroundPositionX: "right, left",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "20rem, auto",
                    }}
                    styleCardContent
                    classNameCardContent={styles.card_content}
                >
                    {/* SOCIAL MEDIAS HIDDEN MD & UP*/}
                    {props.socialMedias ? (
                        <Hidden mdUp>
                            <Box className={styles.block__right}>
                                <Box className={styles.activityPage__socialMedia}>
                                    <SocialMediaIcons socialMedias={props.activity.socialMedias} />
                                </Box>
                            </Box>
                        </Hidden>
                    ) : null}
                    {props.titleStandalone && <TitleStandalone wineEntityId={props.wineEntityId} logo={props.logo} />}
                    {/* ACTIVITY TITLE & TYPE*/}
                    <Box className={styles.block__left}>
                        <Box className={styles.title__box}>
                            {/* ACTIVITY NAME */}
                            {getActivityName(props.activity?.name)}
                            {/* ACTIVITY TYPE HIDDEN SM & DOWN*/}
                            <Hidden mdDown>{getActivityType(props.activity?.types)}</Hidden>
                        </Box>
                    </Box>
                    {/* SOCIAL MEDIAS HIDDEN SM & DOWN*/}
                    {props.socialMedias && (
                        <Hidden smDown>
                            <Box className={styles.block__right}>
                                <Box className={styles.activityPage__socialMedia}>
                                    <SocialMediaIcons socialMedias={props.activity?.socialMedias} />
                                </Box>
                            </Box>
                        </Hidden>
                    )}
                    {/* ACTIVITY DETAILS HIDDEN SM & DOWN */}
                    <Hidden smDown>
                        <Box className={styles.block__left__scrollable}>
                            <ActivityDetails
                                alt={`${props.activity?.name} - ${props.wineEntityName}`}
                                types={props.activity?.types}
                                images={props.activity?.images}
                                activityType={activityType}
                                tags={props.activity?.tags}
                                description={props.activity?.description}
                                accomodationPossible={props.activity?.accomodationPossible}
                                bedCount={props.activity?.bedCount}
                                accomodationDetails={props.activity?.accomodationDetails?.[props.locale]}
                                receptionAreas={props.activity?.receptionAreas}
                                equipmentProvided={props.activity?.equipmentProvided}
                                availableOptions={props.activity?.availableOptions}
                                accessibleTo={props.activity?.accessibleTo}
                                activePeriods={props.activity?.activePeriods}
                                free={props.activity?.free}
                                priceDetails={props.activity?.priceDetails}
                                paymentDetails={props.activity?.paymentDetails?.[props.locale]}
                                priceOnEstimation={props.activity?.priceOnEstimation}
                                paymentOptionsAccepted={props.activity?.paymentOptionsAccepted}
                                sanitaryMeasures={props.activity?.sanitaryMeasures}
                                parkingBus={props.activity?.parkingBus}
                                numberWineTasted={props.activity?.numberWineTasted}
                            />
                        </Box>
                    </Hidden>
                    <Box className={styles.block__summary}>
                        {/* TYPES HIDDEN MD & UP*/}
                        <Hidden mdUp>{getActivityType(props.activity?.types, true)}</Hidden>
                        {/* IMAGES HIDDEN MD & UP*/}
                        <Hidden mdUp>
                            <BaseCarousel
                                subImg={imgSub1}
                                images={props.activity?.images?.length ? props.activity?.images : []}
                                imageStyle={{ width: "100%" }}
                                responsiveHeight={[302, 502, 278, 432, 432]}
                                front
                                alt={`${props.activity?.name} - ${props.wineEntityName}`}
                            />
                        </Hidden>
                        {/* <Hidden mdUp>
                            <Box style={{ marginBottom: "0.5rem" }}>
                                <SocialShare />
                            </Box>
                        </Hidden> */}

                        {/* ACTIVITY SUMMARY*/}
                        <ActivitySummary
                            activityType={activityType}
                            duration={props.activity?.duration}
                            durationUnit={props.activity?.durationUnit}
                            minRentTime={props.activity?.minRentTime}
                            maxRentTime={props.activity?.maxRentTime}
                            minRentTimeUnit={props.activity?.minRentTimeUnit}
                            maxRentTimeUnit={props.activity?.maxRentTimeUnit}
                            languages={props.activity?.languages}
                            groupSize={props.activity?.groupSize}
                            startingAge={props.activity?.startingAge}
                            address={props.activity?.address}
                            reservationNeeded={props.activity?.reservationNeeded}
                            atWineEntity={props.activity?.atWineEntity}
                            contactEmail={props.activity?.contactEmail}
                            contactPerson={props.activity?.contactPerson}
                            contactPhoneNumber={props.activity?.contactPhoneNumber}
                            private={props.activity?.private}
                            reservation={false}
                        />
                    </Box>

                    {/* ACTIVITY DETAILS HIDDEN MD & UP*/}
                    <Hidden mdUp>
                        <Box className={styles.block__left}>
                            <ActivityDetails
                                types={props.activity?.types}
                                images={props.activity?.images}
                                activityType={activityType}
                                tags={props.activity?.tags}
                                description={props.activity?.description}
                                accomodationPossible={props.activity?.accomodationPossible}
                                bedCount={props.activity?.bedCount}
                                accomodationDetails={props.activity?.accomodationDetails?.[props.locale]}
                                receptionAreas={props.activity?.receptionAreas}
                                equipmentProvided={props.activity?.equipmentProvided}
                                availableOptions={props.activity?.availableOptions}
                                accessibleTo={props.activity?.accessibleTo}
                                activePeriods={props.activity?.activePeriods}
                                free={props.activity?.free}
                                priceDetails={props.activity?.priceDetails}
                                paymentDetails={props.activity?.paymentDetails?.[props.locale]}
                                priceOnEstimation={props.activity?.priceOnEstimation}
                                paymentOptionsAccepted={props.activity?.paymentOptionsAccepted}
                                sanitaryMeasures={props.activity?.sanitaryMeasures}
                                parkingBus={props.activity?.parkingBus}
                                numberWineTasted={props.activity?.numberWineTasted}
                            />
                        </Box>
                    </Hidden>
                </FrontMainContainer>
            </FrontContentTop>
            <FrontContentBottom activityBackground={activities?.ListActivities?.length >= 1}>
                {activities?.ListActivities?.length >= 1 && (
                    /* ACTIVITIES CARD */
                    <ActivityCardsContainer
                        wineEntityName={props.wineEntityName}
                        title={
                            props.activitiesSectionTitle ||
                            t`Les autres activités proposées par ${props.wineEntityName}`
                        }
                        wineEntityId={props.wineEntityId}
                        omit={activityId ? [activityId] : null}
                        locale={props.locale}
                        boxClassName={styles.container_activity}
                        styleBox={{ margin: width < 600 && "0 14px" }}
                    />
                )}
            </FrontContentBottom>

            <FrontContentBottom contactBackground>
                {/* WINES CARD */}
                <WineCardsContainer
                    whiteVersion
                    title={props.wineSectionTitle}
                    wineEntityId={props.wineEntityId}
                    showMoreWines={props.showMoreWines}
                    limited
                    locale={props.locale}
                    wineEntityName={props.wineEntityName}
                />
                {/* CONTACT CARD */}
                <ContactPropertyFormCard
                    restriction={restriction}
                    standalone={props.standalone}
                    contactSectionTitle={props.contactSectionTitle}
                    style={{ marginTop: "3rem" }}
                    size="large"
                    isOrphan={props.activity.isOrphan}
                    estateName={props.wineEntityName}
                    wineEntityId={props.wineEntityId}
                    address={props.activity?.address}
                    phoneNumber={props.activity?.telephoneNumber}
                    fax={props.activity?.fax}
                    logo={props.activity?.logos?.[0]?.data || null}
                    identityLinked
                    coordinates={props.activity?.coordinates}
                    weeklyHours={
                        props.activity?.displayOpeningHours
                            ? props.activity?.openingHours && props.activity?.openingHours?.length
                                ? props.activity?.openingHours
                                : null
                            : null
                    }
                />
            </FrontContentBottom>
            <ActivityCommonalities activityId={activityId} />
        </>
    );
};

ActivityPage.propTypes = {
    wineSectionTitle: string,
    showMoreWines: string,
    contactSectionTitle: string,
    frontTitle: bool,
    breadcrumbs: array,
    previousPage: object,
    activity: any,
    wineEntityId: string,
    wineEntityName: string,
    locale: string,
    loading: bool,
    hideFloritureTopImg: bool,
    socialMedias: bool,
    titleStandalone: bool,
    activitiesSectionTitle: string,
    logo: string,
    standalone: bool,
    activityId: string,
};

export default ActivityPage;
