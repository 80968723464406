import React from "react";
import PropTypes, { func } from "prop-types";
import { Box, makeStyles, Typography } from "@material-ui/core";
import downloadFt from "../../assets/Wine/download_ft.svg";
import theme from "../../globalTheme";
import left from "../../assets/Wine/fioriture_left.svg";
import right from "../../assets/Wine/fioriture_right.svg";
import { Trans } from "@lingui/macro";

const useStyle = makeStyles(() => ({
    container: {
        gridColumn: "auto / span 6",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.dashboard.background,
        padding: "15px 0",
        borderRadius: "2px",
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.08)",
        cursor: "pointer",
        background: `url(${left}), url(${right})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left, right",
        backgroundSize: "contain",
    },
}));

const DownloadFT = (props) => {
    const styles = useStyle();
    return (
        <Box className={styles.container} onClick={() => props.onClick()}>
            <img src={downloadFt} style={{ marginRight: "1rem" }} />
            <Typography variant="h4" style={{ fontFamily: "Mansalva", fontWeight: "normal" }}>
                <Trans>Découvrir la fiche technique</Trans>
            </Typography>
        </Box>
    );
};

DownloadFT.propTypes = {
    onClick: func,
};

export default DownloadFT;
