import { Plural, t, Trans } from "@lingui/macro";
import { Box, makeStyles, Typography } from "@material-ui/core";
import Page500Front from "Components/ErrorPage/Page500Front";
import Helmet from "Components/Helmet/Helmet";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import bg from "../../assets/searchEngine/all/bg-all.jpg";
import floriture from "../../assets/searchEngine/all/floriture-1.svg";
import floriture2 from "../../assets/searchEngine/all/floriture-2.svg";
import floriture3 from "../../assets/searchEngine/all/floriture-3.svg";
import noResultImg from "../../assets/searchEngine/no-result.svg";
import BreadCrumbs from "../../Components/Breadcrumbs/BreadCrumbs";
import SimpleInput from "../../Components/Inputs/SimpleInput";
import SwiperCards from "../../Front/SwiperCards";
import { useSearchAllQuery } from "../../generated/graphql";
import theme from "../../globalTheme";
import SearchMainContainer from "../../Search/SearchMainContainer";
import SearchTabs from "../../Search/SearchTabs";
import ButtonViewAllResults from "../../Search/ui/ButtonViewAllResults";

const useStyles = makeStyles(() => ({
    root: {
        "&::after": {
            // eslint-disable-next-line quotes
            content: '""',
            position: "absolute",
            zIndex: -1,
            top: "-5rem",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundImage: `url(${bg})`,
            backgroundRepeat: "no-repeat",
            backgroundPositionY: "top",
            backgroundPositionX: "center",
            backgroundSize: "cover",
        },

        gridTemplateColumns: "repeat(12, 1fr)",
        display: "grid",
        minHeight: "calc(100vh - 5rem)",
    },
    main_container: {
        gridColumn: "1 / span 12",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "22px 0 2rem",
        [theme.breakpoints.up("sm")]: {
            padding: "22px 1rem 2rem",
            paddingTop: 80,
        },
        [theme.breakpoints.up("md")]: {
            paddingTop: 80,
        },
        [theme.breakpoints.up("xl")]: {
            width: "calc(1314px)",
            margin: "auto",
        },
    },
    title: {
        color: theme.palette.common.white,
        fontWeight: 400,
        textAlign: "center",
        marginBottom: "1rem",
    },
    headerContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        gap: 5,
        marginBottom: 9,
    },
    search: {
        gridColumn: "1 / span 12",

        [theme.breakpoints.up("sm")]: {
            gridColumn: "1 / span 12",
            gridRow: "1 / span 1",
        },

        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 12",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 12",
        },
    },
    searchCTA: {
        display: "flex",
        gridColumn: "11 / span 2",
        gridRow: "1 / span 1",

        [theme.breakpoints.up("sm")]: {
            gridColumn: "9 / span 4",
        },

        [theme.breakpoints.up("md")]: {
            gridColumn: "10 / span 3",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "11 / span 2",
        },
    },
    linkDeleteFilters: {
        background: "transparent",
        border: "none",
        padding: 0,
        cursor: "pointer",
        textDecoration: "underline",

        "&:hover": {
            textDecoration: "underline",
        },
    },
    resultType: {
        color: theme.palette.secondary.dark,
        fontWeight: 400,
    },
    card: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
    },
    titleResultItem: {
        color: theme.palette.primary.dark,
        fontWeight: 700,
        marginBottom: 16,
        backgroundColor: theme.palette.common.white,
        display: "inline-flex",
        position: "relative",
        zIndex: 10,
        paddingRight: 10,
    },
    separator: {
        position: "absolute",
        zIndex: 1,
        top: "30%",
        width: "100%",
        height: 0.4,
        background: theme.palette.secondary.main,
    },

    noResultImageBox: {
        gridColumn: "1 / span 18",
        gridRow: "3 / span 1",
        position: "relative",
        display: "flex",
        alignItems: "flex-start",

        [theme.breakpoints.up("sm")]: {
            gridRow: "4 / span 1",
            minHeight: 130,
            alignItems: "flex-end",
        },

        [theme.breakpoints.up("md")]: {
            minHeight: 170,
            alignItems: "flex-end",
            marginBottom: 24,
        },
    },
    noResultImg: {
        display: "none",

        [theme.breakpoints.up("sm")]: {
            display: "block",
            position: "absolute",
            width: 96,
            top: 0,
        },

        [theme.breakpoints.up("md")]: {
            width: 130,
            left: 77,
        },

        [theme.breakpoints.up("xl")]: {
            width: 145,
            left: 77,
        },
    },
    noResultTypoBox: {
        background: "#FCF6F6",
        padding: "13px 16px",
        width: "100%",

        [theme.breakpoints.up("sm")]: {
            padding: "17px 16px 26px 96px",
        },

        [theme.breakpoints.up("md")]: {
            padding: "22px 16px 22px",
            paddingLeft: "calc(96px + 130px)",
            marginBottom: 10,
        },

        [theme.breakpoints.up("xl")]: {
            padding: "22px 16px 22px",
            paddingLeft: "calc(96px + 145px)",
            marginBottom: 0,
        },
    },
    noResultTitleBig: {
        color: theme.palette.primary.dark,
        width: "100%",
        textAlign: "left",
    },
    noResultTitle: {
        color: theme.palette.secondary.dark,
        width: "100%",
        textAlign: "left",

        [theme.breakpoints.up("sm")]: {
            maxWidth: 536,
        },

        [theme.breakpoints.up("md")]: {
            maxWidth: "90%",
        },

        [theme.breakpoints.up("lg")]: {
            maxWidth: "70%",
        },

        [theme.breakpoints.up("xl")]: {
            maxWidth: "70%",
        },
    },
    breadcrumbs: {
        paddingLeft: "1rem",
    },
}));

const SearchResultAll = () => {
    const styles = useStyles();
    const { search = "" } = useLocation();

    const [searchText, setSearchText] = useState("");
    const [searchTextQuery, setSearchTextQuery] = useState("");

    useEffect(() => {
        if (search) {
            const s = decodeURI(search.substring(1));
            setSearchText(s);
            setSearchTextQuery(s);
        }
    }, [search]);

    const { data, error } = useSearchAllQuery({
        variables: {
            searchText: searchTextQuery,
        },
    });

    // Default search query, use as fall back
    const { data: noResultData, loading: noResultLoading } = useSearchAllQuery({
        variables: {
            searchText: "vin",
        },
    });
    const noResult = noResultData?.SearchAll;

    const allResults = data?.SearchAll;

    const breadcrumbs = [
        {
            name: t`Recherche`,
            path: "/recherche",
        },
        {
            name: t`Tout`,
            path: "/recherche/tout",
        },
        {
            name: t`Résultats`,
        },
    ];

    if (error) {
        return <Page500Front />;
    }

    return (
        <>
            <Helmet title={t`Résultats tout`} />
            <Box className={styles.root}>
                <BreadCrumbs links={breadcrumbs} className={styles.breadcrumbs} />
                <Box className={styles.main_container}>
                    <Typography variant="h2" className={styles.title}>
                        <Trans>Parcourez le vignoble bordelais</Trans>
                    </Typography>
                    <SearchTabs active="all" redirectToResult />

                    <SearchMainContainer>
                        <Box className={styles.headerContainer}>
                            <Box className={styles.search}>
                                <SimpleInput
                                    borderWidth={0.4}
                                    size="small"
                                    name="search"
                                    placeholder={t`Chercher une propriété, un vin...`}
                                    variant="outlined"
                                    value={searchTextQuery}
                                    onChange={(e) => setSearchTextQuery(e?.target?.value)}
                                    onBlur={(e) => setSearchTextQuery(e?.target?.value)}
                                    boxStyle={{
                                        backgroundColor: theme.palette.common.white,
                                        borderRadius: "53px",
                                        border: "none",
                                    }}
                                    borderRadius={53}
                                    startAdornment
                                    bgTransparent
                                    search
                                />
                            </Box>
                        </Box>

                        <Box
                            style={{
                                width: "100%",
                                height: 0.4,
                                background: theme.palette.secondary.main,
                                marginBottom: 10,
                            }}
                        />

                        {/* NO RESULT */}
                        {searchTextQuery && allResults?.totalCount === 0 && (
                            <Box style={{ paddingBottom: 31 }}>
                                <Typography variant="h3" className={styles.noResultTitleBig}>
                                    <Trans>Aucun résultat pour « {searchTextQuery} »</Trans>
                                </Typography>
                            </Box>
                        )}

                        {!noResultLoading && (
                            <>
                                {searchTextQuery && allResults?.totalCount > 0 && (
                                    <Box>
                                        <Typography variant="h3" style={{ color: theme.palette.primary.dark }}>
                                            <Trans>Résultats pour « {searchTextQuery} »</Trans>
                                        </Typography>
                                        <Typography
                                            variant="subtitle1"
                                            className={styles.resultType}
                                            style={{ marginLeft: "5px", marginTop: 5, marginBottom: 18 }}
                                        >
                                            <Plural
                                                value={data?.SearchAll?.totalCount}
                                                one="# résultat trouvé"
                                                other="# résultats trouvés"
                                            />
                                            ;
                                        </Typography>
                                    </Box>
                                )}

                                {/* estates results */}
                                <Box>
                                    <Box style={{ position: "relative" }}>
                                        <Box className={styles.separator} />
                                        <Typography variant="h4" className={styles.titleResultItem}>
                                            {allResults?.wineEntityData?.totalCount.length > 0
                                                ? t`Propriétés (${
                                                      allResults?.wineEntityData?.totalCount?.[0]?.totalRecords ?? 0
                                                  })`
                                                : t`Aucune propriété ne correspond à votre recherche`}
                                        </Typography>
                                    </Box>

                                    <Box>
                                        {allResults?.wineEntityData?.totalCount.length === 0 && (
                                            <Box className={styles.noResultImageBox}>
                                                <img
                                                    src={noResultImg}
                                                    className={styles.noResultImg}
                                                    alt="image aucun résultat"
                                                />
                                                <Box className={styles.noResultTypoBox}>
                                                    <Typography variant="h3" className={styles.noResultTitle}>
                                                        <Trans>
                                                            Aucune propriété correspondant à «{searchTextQuery}» n’a été
                                                            trouvée, modifiez votre saisie ou découvrez une sélection
                                                            ci-dessous.
                                                        </Trans>
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        )}
                                        <SwiperCards
                                            page="search_estate"
                                            datas={
                                                allResults?.wineEntityData?.totalCount.length > 0
                                                    ? allResults?.wineEntityData?.data
                                                    : noResult?.wineEntityData?.data
                                            }
                                            spaceBetween={0}
                                            width={281}
                                            wrapperPaddingBottom={19}
                                            lastSlide={
                                                <ButtonViewAllResults
                                                    seeAllResults={
                                                        <Typography
                                                            variant="h3"
                                                            style={{
                                                                color: theme.palette.common.white,
                                                                maxWidth: 212,
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            <Trans>
                                                                Voir tous les résultats pour <br />
                                                                <b>les propriétés</b>
                                                            </Trans>
                                                        </Typography>
                                                    }
                                                    url="/recherche/proprietes/resultats"
                                                    searchText={searchText}
                                                    label="propriétés"
                                                    style={{
                                                        backgroundColor: theme.palette.primary.light,
                                                        backgroundImage: `url(${floriture})`,
                                                        backgroundPosition: "bottom left",
                                                        width: 281,
                                                        height: 297,
                                                        borderRadius: "10px",
                                                    }}
                                                    noResult={allResults?.wineEntityData?.totalCount.length === 0}
                                                />
                                            }
                                        />
                                    </Box>
                                </Box>

                                {/* wines results */}
                                <Box>
                                    <Box style={{ position: "relative" }}>
                                        <Box className={styles.separator} />
                                        <Typography variant="h4" className={styles.titleResultItem}>
                                            {allResults?.vintageData?.totalCount?.length > 0
                                                ? t`Vins (${
                                                      allResults?.vintageData?.totalCount?.[0]?.totalRecords ?? 0
                                                  })`
                                                : t`Aucun vin ne correspond à votre recherche`}
                                        </Typography>
                                    </Box>

                                    <Box>
                                        {allResults?.vintageData?.totalCount?.length === 0 && (
                                            <Box className={styles.noResultImageBox}>
                                                <img
                                                    src={noResultImg}
                                                    className={styles.noResultImg}
                                                    alt="image aucun résultat"
                                                />
                                                <Box className={styles.noResultTypoBox}>
                                                    <Typography variant="h3" className={styles.noResultTitle}>
                                                        <Trans>
                                                            Aucun vin correspondant à «{searchTextQuery}» n’a été
                                                            trouvé, modifiez votre saisie ou découvrez une sélection
                                                            ci-dessous.
                                                        </Trans>
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        )}
                                        <SwiperCards
                                            page="search_wine"
                                            datas={
                                                allResults?.vintageData?.totalCount?.length > 0
                                                    ? allResults?.vintageData?.data
                                                    : noResult?.vintageData?.data
                                            }
                                            wrapperPaddingBottom={19}
                                            lastSlide={
                                                <ButtonViewAllResults
                                                    seeAllResults={
                                                        <Typography
                                                            variant="h3"
                                                            style={{
                                                                color: theme.palette.common.white,
                                                                maxWidth: 212,
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            <Trans>
                                                                Voir tous les résultats pour <br />
                                                                <b>les vins</b>
                                                            </Trans>
                                                        </Typography>
                                                    }
                                                    url="/recherche/vins/resultats"
                                                    searchText={searchText}
                                                    label="vins"
                                                    style={{
                                                        backgroundColor: "#C0D4BE",
                                                        backgroundImage: `url(${floriture2})`,
                                                        backgroundPosition: "top right",
                                                        width: 256,
                                                        height: 290,
                                                        borderRadius: "5px",
                                                    }}
                                                    noResult={allResults?.vintageData?.totalCount.length === 0}
                                                />
                                            }
                                        />
                                    </Box>
                                </Box>

                                {/* activities results */}
                                <Box>
                                    <Box style={{ position: "relative" }}>
                                        <Box className={styles.separator} />
                                        <Typography variant="h4" className={styles.titleResultItem}>
                                            {allResults?.activityData?.totalCount.length > 0
                                                ? t`Activités (${
                                                      allResults?.activityData?.totalCount?.[0]?.totalRecords ?? 0
                                                  })`
                                                : t`Aucune activité ne correspond à votre recherche`}
                                        </Typography>
                                    </Box>

                                    <Box>
                                        {allResults?.activityData?.totalCount.length === 0 && (
                                            <Box className={styles.noResultImageBox}>
                                                <img
                                                    src={noResultImg}
                                                    className={styles.noResultImg}
                                                    alt="image aucun résultat"
                                                />
                                                <Box className={styles.noResultTypoBox}>
                                                    <Typography variant="h3" className={styles.noResultTitle}>
                                                        <Trans>
                                                            Aucune activité correspondant à «{searchTextQuery}» n’a été
                                                            trouvée, modifiez votre saisie ou découvrez une sélection
                                                            ci-dessous.
                                                        </Trans>
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        )}
                                        <SwiperCards
                                            page="search_activity"
                                            datas={
                                                allResults?.activityData?.totalCount.length > 0
                                                    ? allResults?.activityData?.data
                                                    : noResult?.activityData?.data
                                            }
                                            spaceBetween={0}
                                            wrapperPaddingBottom={19}
                                            lastSlide={
                                                <ButtonViewAllResults
                                                    seeAllResults={
                                                        <Typography
                                                            variant="h3"
                                                            style={{
                                                                color: theme.palette.common.white,
                                                                maxWidth: 212,
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            <Trans>
                                                                Voir tous les résultats pour <br />
                                                                <b>les activités</b>
                                                            </Trans>
                                                        </Typography>
                                                    }
                                                    url="/recherche/activites/resultats"
                                                    searchText={searchText}
                                                    label="activités"
                                                    style={{
                                                        backgroundColor: theme.palette.primary.dark,
                                                        backgroundImage: `url(${floriture3})`,
                                                        backgroundPosition: "center right",
                                                        width: 281,
                                                        height: 380,
                                                        borderRadius: "10px",
                                                    }}
                                                    noResult={allResults?.activityData?.totalCount.length === 0}
                                                />
                                            }
                                        />
                                    </Box>
                                </Box>
                            </>
                        )}
                    </SearchMainContainer>
                </Box>
            </Box>
        </>
    );
};

export default SearchResultAll;
