import { t } from "@lingui/macro";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { string } from "prop-types";
import { useEffect, useRef } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

import fioriture from "assets/navbarfront/fioriture_menu.svg";
import Page404 from "Components/ErrorPage/Page404";
import ScrollToTop from "Containers/ScrollToTop";
import StandaloneFooter from "Front/footer/StandaloneFooter";
import Navbar from "Front/navbar/Navbar";
import theme from "globalTheme";
import { urlFormatted, useWindowDimensions } from "helpers/helpers";
import { StandaloneActivityPage } from "Pages/ActivityPage/ActivityPage";
import StandaloneEstatePage from "Pages/EstatePage/EstatePage";
import { StandaloneConnectedWinePage } from "Pages/WinePage";
import { useCustomHistory } from "hooks";

const useStyles = makeStyles(() => ({
    navbar_container: {
        [theme.breakpoints.up("md")]: {
            display: "grid !important",
            gridTemplateColumns: "repeat(12,1fr)",
            gap: "24px",
            padding: "0 16px",
            width: "auto !important",
        },
    },
    containerRight_standalone: {
        [theme.breakpoints.up("xs")]: {},
        [theme.breakpoints.up("sm")]: {},
        [theme.breakpoints.up("md")]: {
            width: "fit-content",

            margin: 0,
            gridColumn: "auto / span 1",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "12 / span 1",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "11 / span 1",
        },
    },
    logo_standalone: {
        [theme.breakpoints.up("xs")]: {},
        [theme.breakpoints.up("sm")]: {},
        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 3",
            marginLeft: 0,
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 3",
            marginLeft: 0,
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "2 / span 2",
        },
    },
    containerTitle: {
        gridColumn: "1 / span 4",
        backgroundImage: `url(${fioriture})`,
        backgroundRepeat: "no-repeat",
        color: theme.palette.common.white,
        borderBottomLeftRadius: "20px",
        width: "200px",
        [theme.breakpoints.up("md")]: {
            backgroundSize: "200px",
        },
        [theme.breakpoints.up("lg")]: {
            backgroundSize: "200px",
            padding: "4rem 0 2rem 2rem",
        },
        [theme.breakpoints.up("xl")]: {
            padding: "4rem 0 2rem 1rem",
        },
    },
    containerLinks: {
        gridColumn: "auto / span 8",
        display: "flex",
        flexDirection: "column",
        width: "max-content",
        minWidth: "230px",
        color: theme.palette.primary.dark,
        padding: "4rem 0rem 2rem 1rem",
        [theme.breakpoints.up("md")]: {
            padding: "4rem 0rem 2rem 1rem",
        },
        [theme.breakpoints.up("lg")]: {
            padding: "4rem 0 2rem 0rem",
        },
    },
    containerSubMenus: {
        padding: "2rem 2rem 2rem 1rem",
        borderBottomRightRadius: "20px",
        gridColumn: "auto / span 3",
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        textTransform: "uppercase",
        gap: "1rem",
        display: "grid",
        gridTemplateRows: "repeat(4, 1fr)",
        gridAutoFlow: "column",
    },
    container_menu_items: {
        display: "flex",
        alignItems: "center",
        padding: "0 0.5rem 0.5rem 0",
    },

    wine_text: {
        "& > i": {
            fontWeight: "300",
        },
        "&:hover": {
            fontWeight: "700",
            "& > i": {
                fontWeight: "700",
            },
        },
    },
    slideContents: {
        overflow: "hidden",
        [theme.breakpoints.up("xs")]: {
            width: "100%",
        },
        [theme.breakpoints.up("md")]: {
            overflow: "inherit",
            width: "auto",
            gridColumn: "auto / span 8",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 7",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "auto / span 6",
        },
        height: "100%",
    },
}));

const StandAloneRouter = (props) => {
    const styles = useStyles();
    const { width } = useWindowDimensions();
    const location = useLocation();
    const history = useCustomHistory();
    const estateRef = useRef(null);
    const teamRef = useRef(null);
    const winesListRef = useRef(null);
    const activitiesListRef = useRef(null);
    const contactRef = useRef(null);

    const queryGlobal = {
        wineEntityName: "Cru Geoffroi",
        wineEntityId: props.wineEntityId,
        shortId: "123ABC",
        facebookLink: "test",
        instagramLink: "test",
        linkedinLink: "https://fr.linkedin.com/company/feret",
        teamDescription: true,
        estateDescription: true,
    };

    const queryWines = [
        {
            wineId: "6230628bc454f3000938ec92",
            shortId: "456DEF",
            brandName: "Cru Geoffroi",
            appellationLabel: "Bordeaux",
            type: "Tranquille",
            color: "Rouge",
            sweetness: "Sec",
            fullAppellation: "Bordeaux",
            rank: "second vin",
            bottleImage: {
                data:
                    "https://feret-resource-images.s3.eu-central-1.amazonaws.com/sidebarBottles/rouge.svg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA225HDR6G6RRWZZQL%2F20221117%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20221117T090248Z&X-Amz-Expires=604800&X-Amz-Security-Token=IQoJb3JpZ2luX2VjECEaDGV1LWNlbnRyYWwtMSJHMEUCIQDJGkwyID1bcrzc%2FjSUAhc0oR%2Fg7Db8z20k7eDdqsvM3AIgcVEQXjwlQRD2aRIvXSFotwkAO056qIJUZgtloFYey7gqtAMIKhACGgw3NDQ5OTA0MTI2ODUiDNCUkcQpZA15%2BxNakiqRA5A8a3AGe3mZvNOFkJbI9uc%2BtJ55NXa0zyxNVn%2BM4BVCAEuAt1i9b6QoUFbhjeg2aIi7WymC13x8fPF8B29SZzgfbEh2ULHhzv754EAWwKtz4k9MU1ULZXw%2BvYlrEC0RKNKt2kOBH75UdzfNgxamtiAHfbfxGR4RCsQNfsF4W5UtsPlXFRROJtvCmPC5k9sAWUfyUUkHXGrLlqVHcwPNHjefYpZtat8z7wP%2Fp1x9IttH7IyU%2B64ddXyz4KBn6aycynEozQ9EuWL8Kd65oPMULcye6qApkRNGLmTZLG4XCprQzdw%2Br5YkKzcIcY1%2FPPOOGQgwnVsf%2FZlAsB0bjWuzJgg3jGBeMvdQ5sNulAeflREPbzOa6qlLV97MqmuHKbqrR%2Bo6wm6MqkxbbHxSLLqNBWsckUf01wI2qPX6aiqeBXfOnRX%2BN0%2BgHx0IL9qC2COIa5rqd9sUgm83PSGlvvWd0upm0yaNBm635GN14xooOr4BuZrUJcf71atAihR9Zh1w9Qhd6dBiVDo4EpfdHYaY%2FTm%2FMJDv15sGOp4BK20AbRoHTVEsg2oVKxMj%2FfUUtMfNSQ3ztzOBtu0LXr3PP4JRODAzYCE%2FgUJFy7kRsAlSI3Xvues%2FrLv1U6Z1LIkHYQAGQbfrOqdcRSvOpPuyrS9dR%2B%2Bnb%2BGeaQbPEcHxYtxCEJQI0nKL6SMcLpcSUw2r2HoqsV3nvambBnCMYOsVo4a84xlhsopIu%2FzAMzKqW9MVJHWzluUbooluqsU%3D&X-Amz-Signature=c425dabebd24d9a655a7d928440894c93ddf8dcad113252d8f539b688275ac1e&X-Amz-SignedHeaders=host&response-cache-control=no-cache",
            },
            lastVintageId: "6230b08c5ee1e10009bca8f6",
            vintages: [
                {
                    year: 1988,
                    vintageId: "6230b08c5ee1e10009bca8f6",
                    cuvee: "",
                },
                {
                    year: 1997,
                    vintageId: "6241dcd1d1c21d000981aab7",
                    cuvee: "police du style",
                },
                {
                    year: 1999,
                    vintageId: "6230a7debc0e200009b549bc",
                    cuvee: "",
                },
                {
                    year: 2001,
                    vintageId: "62e3961882c9990009cd241a",
                    cuvee: "work",
                },
                {
                    year: 2002,
                    vintageId: "6230a422cc3c1f0009e2740d",
                    cuvee: "",
                },
                {
                    year: 2002,
                    vintageId: "634420bbe522950009017180",
                    cuvee: "cuvée MORTY",
                },
                {
                    year: 2008,
                    vintageId: "62b9c51eea7b257b650438b9",
                    cuvee: "",
                },
            ],
        },
        {
            wineId: "623062d579477f0009706a12",
            brandName: "Chanté du Cru Geoffroi",
            appellationLabel: "Bordeaux",
            type: "Tranquille",
            color: "Rosé",
            sweetness: "Sec",
            fullAppellation: "Bordeaux",
            rank: "premier vin",
            bottleImage: {
                data:
                    "https://feret-resource-images.s3.eu-central-1.amazonaws.com/sidebarBottles/rose.svg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA225HDR6G6RRWZZQL%2F20221117%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20221117T090248Z&X-Amz-Expires=604800&X-Amz-Security-Token=IQoJb3JpZ2luX2VjECEaDGV1LWNlbnRyYWwtMSJHMEUCIQDJGkwyID1bcrzc%2FjSUAhc0oR%2Fg7Db8z20k7eDdqsvM3AIgcVEQXjwlQRD2aRIvXSFotwkAO056qIJUZgtloFYey7gqtAMIKhACGgw3NDQ5OTA0MTI2ODUiDNCUkcQpZA15%2BxNakiqRA5A8a3AGe3mZvNOFkJbI9uc%2BtJ55NXa0zyxNVn%2BM4BVCAEuAt1i9b6QoUFbhjeg2aIi7WymC13x8fPF8B29SZzgfbEh2ULHhzv754EAWwKtz4k9MU1ULZXw%2BvYlrEC0RKNKt2kOBH75UdzfNgxamtiAHfbfxGR4RCsQNfsF4W5UtsPlXFRROJtvCmPC5k9sAWUfyUUkHXGrLlqVHcwPNHjefYpZtat8z7wP%2Fp1x9IttH7IyU%2B64ddXyz4KBn6aycynEozQ9EuWL8Kd65oPMULcye6qApkRNGLmTZLG4XCprQzdw%2Br5YkKzcIcY1%2FPPOOGQgwnVsf%2FZlAsB0bjWuzJgg3jGBeMvdQ5sNulAeflREPbzOa6qlLV97MqmuHKbqrR%2Bo6wm6MqkxbbHxSLLqNBWsckUf01wI2qPX6aiqeBXfOnRX%2BN0%2BgHx0IL9qC2COIa5rqd9sUgm83PSGlvvWd0upm0yaNBm635GN14xooOr4BuZrUJcf71atAihR9Zh1w9Qhd6dBiVDo4EpfdHYaY%2FTm%2FMJDv15sGOp4BK20AbRoHTVEsg2oVKxMj%2FfUUtMfNSQ3ztzOBtu0LXr3PP4JRODAzYCE%2FgUJFy7kRsAlSI3Xvues%2FrLv1U6Z1LIkHYQAGQbfrOqdcRSvOpPuyrS9dR%2B%2Bnb%2BGeaQbPEcHxYtxCEJQI0nKL6SMcLpcSUw2r2HoqsV3nvambBnCMYOsVo4a84xlhsopIu%2FzAMzKqW9MVJHWzluUbooluqsU%3D&X-Amz-Signature=d124ee99413b222b8caef2debcade48a6d59eaa36f8ee0661436a2058d1d08ac&X-Amz-SignedHeaders=host&response-cache-control=no-cache",
            },
            lastVintageId: "6233685c8892d60009a0d369",
            vintages: [
                {
                    year: 1955,
                    vintageId: "6233685c8892d60009a0d369",
                    cuvee: "",
                },
                {
                    year: 2000,
                    vintageId: "62beb1c70375925722fc751e",
                    cuvee: "",
                },
                {
                    year: 2000,
                    vintageId: "62beb2044337165f643049e5",
                    cuvee: "bis",
                },
                {
                    year: 2000,
                    vintageId: "62beb23edf460060b761be13",
                    cuvee: "bisbb",
                },
                {
                    year: 2000,
                    vintageId: "62beb301397b33636ed1d775",
                    cuvee: "5",
                },
            ],
        },
        {
            wineId: "623062eb79477f0009706c65",
            brandName: "Christelle du Cru Geoffroi",
            appellationLabel: "Entre-deux-Mers",
            type: "Tranquille",
            color: "Blanc",
            sweetness: "Sec",
            fullAppellation: "Entre-deux-Mers",
            rank: "premier vin",
            bottleImage: {
                data:
                    "https://feret-resource-images.s3.eu-central-1.amazonaws.com/sidebarBottles/blanc_sec.svg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA225HDR6G6RRWZZQL%2F20221117%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20221117T090248Z&X-Amz-Expires=604800&X-Amz-Security-Token=IQoJb3JpZ2luX2VjECEaDGV1LWNlbnRyYWwtMSJHMEUCIQDJGkwyID1bcrzc%2FjSUAhc0oR%2Fg7Db8z20k7eDdqsvM3AIgcVEQXjwlQRD2aRIvXSFotwkAO056qIJUZgtloFYey7gqtAMIKhACGgw3NDQ5OTA0MTI2ODUiDNCUkcQpZA15%2BxNakiqRA5A8a3AGe3mZvNOFkJbI9uc%2BtJ55NXa0zyxNVn%2BM4BVCAEuAt1i9b6QoUFbhjeg2aIi7WymC13x8fPF8B29SZzgfbEh2ULHhzv754EAWwKtz4k9MU1ULZXw%2BvYlrEC0RKNKt2kOBH75UdzfNgxamtiAHfbfxGR4RCsQNfsF4W5UtsPlXFRROJtvCmPC5k9sAWUfyUUkHXGrLlqVHcwPNHjefYpZtat8z7wP%2Fp1x9IttH7IyU%2B64ddXyz4KBn6aycynEozQ9EuWL8Kd65oPMULcye6qApkRNGLmTZLG4XCprQzdw%2Br5YkKzcIcY1%2FPPOOGQgwnVsf%2FZlAsB0bjWuzJgg3jGBeMvdQ5sNulAeflREPbzOa6qlLV97MqmuHKbqrR%2Bo6wm6MqkxbbHxSLLqNBWsckUf01wI2qPX6aiqeBXfOnRX%2BN0%2BgHx0IL9qC2COIa5rqd9sUgm83PSGlvvWd0upm0yaNBm635GN14xooOr4BuZrUJcf71atAihR9Zh1w9Qhd6dBiVDo4EpfdHYaY%2FTm%2FMJDv15sGOp4BK20AbRoHTVEsg2oVKxMj%2FfUUtMfNSQ3ztzOBtu0LXr3PP4JRODAzYCE%2FgUJFy7kRsAlSI3Xvues%2FrLv1U6Z1LIkHYQAGQbfrOqdcRSvOpPuyrS9dR%2B%2Bnb%2BGeaQbPEcHxYtxCEJQI0nKL6SMcLpcSUw2r2HoqsV3nvambBnCMYOsVo4a84xlhsopIu%2FzAMzKqW9MVJHWzluUbooluqsU%3D&X-Amz-Signature=1de16c154e5cb8dda1f20a1d336df20511ce15485d9537711d1be6714daac337&X-Amz-SignedHeaders=host&response-cache-control=no-cache",
            },
            vintages: [],
        },
        {
            wineId: "623449e73b8a6d00090800a9",
            brandName: "Claire du Cru Geoffroi",
            appellationLabel: "Graves supérieures",
            type: "Tranquille",
            color: "Blanc",
            sweetness: "Moelleux",
            fullAppellation: "Graves supérieures",
            rank: "premier vin",
            bottleImage: {
                data:
                    "https://feret-resource-images.s3.eu-central-1.amazonaws.com/sidebarBottles/moelleux.svg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA225HDR6G6RRWZZQL%2F20221117%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20221117T090248Z&X-Amz-Expires=604800&X-Amz-Security-Token=IQoJb3JpZ2luX2VjECEaDGV1LWNlbnRyYWwtMSJHMEUCIQDJGkwyID1bcrzc%2FjSUAhc0oR%2Fg7Db8z20k7eDdqsvM3AIgcVEQXjwlQRD2aRIvXSFotwkAO056qIJUZgtloFYey7gqtAMIKhACGgw3NDQ5OTA0MTI2ODUiDNCUkcQpZA15%2BxNakiqRA5A8a3AGe3mZvNOFkJbI9uc%2BtJ55NXa0zyxNVn%2BM4BVCAEuAt1i9b6QoUFbhjeg2aIi7WymC13x8fPF8B29SZzgfbEh2ULHhzv754EAWwKtz4k9MU1ULZXw%2BvYlrEC0RKNKt2kOBH75UdzfNgxamtiAHfbfxGR4RCsQNfsF4W5UtsPlXFRROJtvCmPC5k9sAWUfyUUkHXGrLlqVHcwPNHjefYpZtat8z7wP%2Fp1x9IttH7IyU%2B64ddXyz4KBn6aycynEozQ9EuWL8Kd65oPMULcye6qApkRNGLmTZLG4XCprQzdw%2Br5YkKzcIcY1%2FPPOOGQgwnVsf%2FZlAsB0bjWuzJgg3jGBeMvdQ5sNulAeflREPbzOa6qlLV97MqmuHKbqrR%2Bo6wm6MqkxbbHxSLLqNBWsckUf01wI2qPX6aiqeBXfOnRX%2BN0%2BgHx0IL9qC2COIa5rqd9sUgm83PSGlvvWd0upm0yaNBm635GN14xooOr4BuZrUJcf71atAihR9Zh1w9Qhd6dBiVDo4EpfdHYaY%2FTm%2FMJDv15sGOp4BK20AbRoHTVEsg2oVKxMj%2FfUUtMfNSQ3ztzOBtu0LXr3PP4JRODAzYCE%2FgUJFy7kRsAlSI3Xvues%2FrLv1U6Z1LIkHYQAGQbfrOqdcRSvOpPuyrS9dR%2B%2Bnb%2BGeaQbPEcHxYtxCEJQI0nKL6SMcLpcSUw2r2HoqsV3nvambBnCMYOsVo4a84xlhsopIu%2FzAMzKqW9MVJHWzluUbooluqsU%3D&X-Amz-Signature=62b4ba64f48fb147a8a9fcca08f57bcd9014a48f81f9788980488d111ea4171f&X-Amz-SignedHeaders=host&response-cache-control=no-cache",
            },
            lastVintageId: "62ff5544c0224400095f6c1a",
            vintages: [
                {
                    year: 1996,
                    vintageId: "62ff5544c0224400095f6c1a",
                    cuvee: "",
                },
            ],
        },
        {
            wineId: "624457c666922600093e6427",
            brandName: "Célestin du Cru Geoffroi",
            appellationLabel: "Entre-deux-Mers",
            type: "Tranquille",
            color: "Blanc",
            sweetness: "Sec",
            fullAppellation: "Entre-deux-Mers",
            rank: "premier vin",
            bottleImage: {
                data:
                    "https://feret-resource-images.s3.eu-central-1.amazonaws.com/sidebarBottles/blanc_sec.svg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA225HDR6G6RRWZZQL%2F20221117%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20221117T090248Z&X-Amz-Expires=604800&X-Amz-Security-Token=IQoJb3JpZ2luX2VjECEaDGV1LWNlbnRyYWwtMSJHMEUCIQDJGkwyID1bcrzc%2FjSUAhc0oR%2Fg7Db8z20k7eDdqsvM3AIgcVEQXjwlQRD2aRIvXSFotwkAO056qIJUZgtloFYey7gqtAMIKhACGgw3NDQ5OTA0MTI2ODUiDNCUkcQpZA15%2BxNakiqRA5A8a3AGe3mZvNOFkJbI9uc%2BtJ55NXa0zyxNVn%2BM4BVCAEuAt1i9b6QoUFbhjeg2aIi7WymC13x8fPF8B29SZzgfbEh2ULHhzv754EAWwKtz4k9MU1ULZXw%2BvYlrEC0RKNKt2kOBH75UdzfNgxamtiAHfbfxGR4RCsQNfsF4W5UtsPlXFRROJtvCmPC5k9sAWUfyUUkHXGrLlqVHcwPNHjefYpZtat8z7wP%2Fp1x9IttH7IyU%2B64ddXyz4KBn6aycynEozQ9EuWL8Kd65oPMULcye6qApkRNGLmTZLG4XCprQzdw%2Br5YkKzcIcY1%2FPPOOGQgwnVsf%2FZlAsB0bjWuzJgg3jGBeMvdQ5sNulAeflREPbzOa6qlLV97MqmuHKbqrR%2Bo6wm6MqkxbbHxSLLqNBWsckUf01wI2qPX6aiqeBXfOnRX%2BN0%2BgHx0IL9qC2COIa5rqd9sUgm83PSGlvvWd0upm0yaNBm635GN14xooOr4BuZrUJcf71atAihR9Zh1w9Qhd6dBiVDo4EpfdHYaY%2FTm%2FMJDv15sGOp4BK20AbRoHTVEsg2oVKxMj%2FfUUtMfNSQ3ztzOBtu0LXr3PP4JRODAzYCE%2FgUJFy7kRsAlSI3Xvues%2FrLv1U6Z1LIkHYQAGQbfrOqdcRSvOpPuyrS9dR%2B%2Bnb%2BGeaQbPEcHxYtxCEJQI0nKL6SMcLpcSUw2r2HoqsV3nvambBnCMYOsVo4a84xlhsopIu%2FzAMzKqW9MVJHWzluUbooluqsU%3D&X-Amz-Signature=1de16c154e5cb8dda1f20a1d336df20511ce15485d9537711d1be6714daac337&X-Amz-SignedHeaders=host&response-cache-control=no-cache",
            },
            vintages: [],
        },
        {
            wineId: "633ff8c05b143c0009285556",
            brandName: "Mon Petit Vin",
            appellationLabel: "Bordeaux supérieur",
            type: "Tranquille",
            color: "Rouge",
            sweetness: "Sec",
            fullAppellation: "Bordeaux supérieur - Rouge",
            rank: "premier vin",
            bottleImage: {
                data:
                    "https://feret-resource-images.s3.eu-central-1.amazonaws.com/sidebarBottles/rouge.svg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA225HDR6G6RRWZZQL%2F20221117%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20221117T090248Z&X-Amz-Expires=604800&X-Amz-Security-Token=IQoJb3JpZ2luX2VjECEaDGV1LWNlbnRyYWwtMSJHMEUCIQDJGkwyID1bcrzc%2FjSUAhc0oR%2Fg7Db8z20k7eDdqsvM3AIgcVEQXjwlQRD2aRIvXSFotwkAO056qIJUZgtloFYey7gqtAMIKhACGgw3NDQ5OTA0MTI2ODUiDNCUkcQpZA15%2BxNakiqRA5A8a3AGe3mZvNOFkJbI9uc%2BtJ55NXa0zyxNVn%2BM4BVCAEuAt1i9b6QoUFbhjeg2aIi7WymC13x8fPF8B29SZzgfbEh2ULHhzv754EAWwKtz4k9MU1ULZXw%2BvYlrEC0RKNKt2kOBH75UdzfNgxamtiAHfbfxGR4RCsQNfsF4W5UtsPlXFRROJtvCmPC5k9sAWUfyUUkHXGrLlqVHcwPNHjefYpZtat8z7wP%2Fp1x9IttH7IyU%2B64ddXyz4KBn6aycynEozQ9EuWL8Kd65oPMULcye6qApkRNGLmTZLG4XCprQzdw%2Br5YkKzcIcY1%2FPPOOGQgwnVsf%2FZlAsB0bjWuzJgg3jGBeMvdQ5sNulAeflREPbzOa6qlLV97MqmuHKbqrR%2Bo6wm6MqkxbbHxSLLqNBWsckUf01wI2qPX6aiqeBXfOnRX%2BN0%2BgHx0IL9qC2COIa5rqd9sUgm83PSGlvvWd0upm0yaNBm635GN14xooOr4BuZrUJcf71atAihR9Zh1w9Qhd6dBiVDo4EpfdHYaY%2FTm%2FMJDv15sGOp4BK20AbRoHTVEsg2oVKxMj%2FfUUtMfNSQ3ztzOBtu0LXr3PP4JRODAzYCE%2FgUJFy7kRsAlSI3Xvues%2FrLv1U6Z1LIkHYQAGQbfrOqdcRSvOpPuyrS9dR%2B%2Bnb%2BGeaQbPEcHxYtxCEJQI0nKL6SMcLpcSUw2r2HoqsV3nvambBnCMYOsVo4a84xlhsopIu%2FzAMzKqW9MVJHWzluUbooluqsU%3D&X-Amz-Signature=c425dabebd24d9a655a7d928440894c93ddf8dcad113252d8f539b688275ac1e&X-Amz-SignedHeaders=host&response-cache-control=no-cache",
            },
            lastVintageId: "633ff8d25b143c0009285c97",
            vintages: [
                {
                    year: 2012,
                    vintageId: "633ff8d25b143c0009285c97",
                    cuvee: "",
                },
            ],
        },
    ];
    const queryActivities = [
        {
            activityId: "623df1f00ca326000997a5eb",
            name: "Réception d'amis",
        },
        {
            activityId: "633ef91febfc660009895711",
            name: "Visite et dégustation",
        },
        {
            activityId: "633ef93febfc660009895880",
            name: "Course d'orientation",
        },
    ];

    useEffect(() => {
        const refList = [
            { hash: "#vin", ref: winesListRef },
            { hash: "#activites", ref: activitiesListRef },
            { hash: "#contact", ref: contactRef },
            { hash: "#equipe", ref: teamRef },
            { hash: "#propriete", ref: estateRef },
        ];
        const ref = refList.find((item) => item.hash === location?.hash)?.ref;

        if (ref.current) {
            const top = ref.current.getBoundingClientRect().top;
            const navbarHeight = width >= 960 ? 75 : 50;
            const offsetPosition = top + window.pageYOffset - navbarHeight - 48;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
            });
        }
    }, [
        location,
        winesListRef.current,
        activitiesListRef.current,
        contactRef.current,
        teamRef.current,
        estateRef.current,
    ]);

    useEffect(() => {
        if (location?.hash === "#contact" && contactRef && contactRef?.current) {
            var element = contactRef?.current?.getBoundingClientRect().top;
            var navbarHeight = width >= 960 ? 75 : 50;

            var offsetPosition = element + window.pageYOffset - navbarHeight - 48;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
            });
        }
    }, [location]);

    const winesMenuItems = queryWines.map((wine) => ({
        title: (
            <Box className={styles.container_menu_items}>
                <img src={wine?.bottleImage?.data} style={{ marginRight: "0.5rem" }} />
                <Typography variant="h5" className={styles.wine_text}>
                    {wine?.brandName} - <i className={styles.appellationLabel}>{wine?.appellationLabel}</i>
                </Typography>
            </Box>
        ),
        onClick: () => {
            history.push(wine?.vintages?.length ? `/vins/${wine?.lastVintageId}` : "/#vins");
        },

        subMenus:
            wine?.vintages?.length > 0 &&
            wine?.vintages?.map((vintage) => {
                return {
                    title: vintage?.year,
                    onClick: () => {
                        history.push(`/vins/${vintage?.vintageId}`);
                    },
                };
            }),
    }));

    const activitiesMenuItems = queryActivities?.map((activity) => ({
        title: activity.name,
        onClick: () => {
            history.push(`/activites/${activity?.activityId}`);
        },
    }));

    const placement = () => {
        if (width >= 960 && width < 1280) {
            return "bottom";
        } else {
            return "bottom-start";
        }
    };
    const datas = [
        {
            mainTitle: queryGlobal.estateDescription && t`Propriété`,
            width: "105px",
            onClick: () => {
                history.push(`${urlFormatted(queryGlobal.shortId, queryGlobal.wineEntityName)}#propriete`);
            },
        },
        {
            mainTitle: queryGlobal.teamDescription && t`Équipe`,
            width: "105px",
            onClick: () => {
                history.push(`${urlFormatted(queryGlobal.shortId, queryGlobal.wineEntityName)}#equipe`);
            },
        },
        {
            mainTitle: queryWines?.length && t`Vins`,
            onClick: () => {},
            sectionTitle: t`Vins`,
            menuItems: winesMenuItems,
            width: "105px",
        },
        {
            mainTitle: queryActivities?.length && t`Activités`,
            sectionTitle: t`Activités`,
            onClick: () => {},
            menuItems: activitiesMenuItems,
            width: "105px",
        },
    ];
    return (
        <>
            <Navbar
                datas={datas}
                logo={
                    <Typography variant="h3" style={{ flex: 1 }}>
                        {queryGlobal.wineEntityName}
                    </Typography>
                }
                classNameNavbar={styles.navbar_container}
                classNameContainerTitle={styles.containerTitle}
                classNameContainerLinks={styles.containerLinks}
                classNameContainerSubMenus={styles.containerSubMenus}
                classNameSlideContent={styles.slideContents}
                classNameLogo={styles.logo_standalone}
                classNameContainerRight={styles.containerRight_standalone}
                placement={placement()}
                modifiers={{
                    offset: {
                        enabled: true,
                        offset: "-50,0",
                    },
                }}
                onClickLogo={() => {
                    history.push(`${urlFormatted(queryGlobal.shortId, queryGlobal.wineEntityName)}#propriete`);
                }}
                standalone
            />
            <ScrollToTop />

            <Switch>
                <Route path="/activites/:activityId">
                    <StandaloneActivityPage
                        logo="https://cdn-icons-png.flaticon.com/512/4470/4470107.png"
                        activitiesListRef={activitiesListRef}
                        wineEntityName={queryGlobal.wineEntityName}
                        wineEntityId={queryGlobal.wineEntityId}
                    />
                </Route>
                <Route path="/vins/:vintageId">
                    <StandaloneConnectedWinePage
                        logo="https://cdn-icons-png.flaticon.com/512/4470/4470107.png"
                        wineEntityName={queryGlobal.wineEntityName}
                        wineEntityId={queryGlobal.wineEntityId}
                        winesListRef={winesListRef}
                    />
                </Route>

                <Route exact path={urlFormatted(queryGlobal.shortId, queryGlobal.wineEntityName)}>
                    <StandaloneEstatePage
                        logo="https://cdn-icons-png.flaticon.com/512/4470/4470107.png"
                        estateRef={estateRef}
                        teamRef={teamRef}
                        winesListRef={winesListRef}
                        wineEntityId={queryGlobal.wineEntityId}
                        activitiesListRef={activitiesListRef}
                        contactRef={contactRef}
                    />
                </Route>
                <Route path="*">
                    <Page404 />
                </Route>
            </Switch>
            <StandaloneFooter
                wineEntityName={queryGlobal.wineEntityName}
                wines={queryWines}
                activities={queryActivities}
                logo="https://cdn-icons-png.flaticon.com/512/4470/4470107.png"
                facebookLink={queryGlobal.facebookLink}
                linkedinLink={queryGlobal.linkedinLink}
                instagramLink={queryGlobal.instagramLink}
            />
        </>
    );
};

StandAloneRouter.propTypes = {
    wineEntityId: string,
};

export default StandAloneRouter;
