import React, {
    useState,
    // useEffect
} from "react";

import { Grid, Typography, IconButton, makeStyles, Box, Hidden } from "@material-ui/core";
import theme from "../../../globalTheme";
import PropTypes from "prop-types";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TeamMemberZoom from "../../../Dashboard/Components/TeamMembers/TeamMemberZoom";
import PersonDetails from "./PersonDetails";
import getConnectors from "../../../helpers/helpers";
import { useWindowDimensions } from "../../../helpers/helpers";
import SwipeMembers from "../SwipeMembers/SwipeMembers";
import { t } from "@lingui/macro";
import { formatConcat } from "../../../helpers/helpers";

// import getConnectors from "../../helper/helper";

const useStyles = makeStyles(() => ({
    containerMembers: {
        display: "grid",
        gridTemplateColumns: "repeat(8, 1fr)",
        height: "fit-content",
        [theme.breakpoints.up("xs")]: {},
        [theme.breakpoints.up("sm")]: {},
        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 6",
            gap: "16px",
            overflowY: "auto",
            // height: "373px",
        },
        [theme.breakpoints.up("lg")]: {
            gap: "32px",
            gridColumn: "auto / span 7",
            height: "fit-content",
        },
        [theme.breakpoints.up("xl")]: {},
        "&::-webkit-scrollbar": {
            width: "8px",
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            // -webkit-box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            paddingRight: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#D0BDC3",
            borderRadius: "20px",
        },
    },
    boxScrollHorizontal: {
        width: "auto",
        // overflow: "auto",
    },
    showMoreBtn: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "1 / span 8",
        },
    },
}));

const PersonsDetailsBlock = ({ members }) => {
    const styles = useStyles();

    const [teamMemberSelected, setTeamMemberSelected] = useState(0);
    const { width } = useWindowDimensions();

    const teamMemberArray = [];
    const zoomMemberArray = [];
    const [showItems, setShowItems] = useState(4);

    const [showMoreClicked, setShowMoreClicked] = useState(false);

    const handleSelected = (item) => {
        return item === teamMemberSelected;
    };

    // Team members
    members?.map((teamMember, i) => {
        let fonctions = teamMember?.roles.concat(teamMember?.fonctionsComplement, teamMember?.roleComplement);
        teamMemberArray.push(
            <PersonDetails
                key={i}
                name={`${teamMember?.displayName}`}
                description={teamMember?.description}
                works={formatConcat(fonctions, ", ")}
                email={teamMember?.email}
                picture={teamMember?.image?.twicpicURL}
                onClick={() => handleClick(i)}
                vip={teamMember?.vip}
                selected={handleSelected(i)}
            />
        );
        zoomMemberArray.push(
            <TeamMemberZoom
                key={i}
                fullName={teamMember?.displayName}
                works={formatConcat(fonctions, ", ")}
                description={teamMember?.description}
                profilePic={teamMember?.image?.twicpicURL}
                vip={teamMember?.vip}
            />
        );
    });

    // Get selected Member to zoom in
    const handleClick = (i) => {
        setTeamMemberSelected(i);
    };

    const [showItemsSwipe, setShowItemsSwipe] = useState(teamMemberArray.length);

    // Display only a certain number of members - here 8 maximum
    const items = teamMemberArray?.slice(0, width >= 960 ? showItems : showItemsSwipe).map((person) => person);
    const handleShowMore = () => {
        setShowMoreClicked(!showMoreClicked);
        showItems == 4 ? setShowItems(teamMemberArray.length) : setShowItems(4);
    };

    return (
        <>
            <Hidden smDown>
                <Box className={styles.containerMembers}>
                    {/* Members list */}
                    <>{items}</>
                    {/* Show More */}
                    {teamMemberArray.length > 4 && (
                        <Grid container item direction="column" className={styles.showMoreBtn}>
                            <Grid item style={{ display: "flex", justifyContent: "center" }}>
                                <Typography variant="h6" style={{ fontSize: 14 }}>
                                    {showMoreClicked ? t`Voir moins` : t`Voir toute l'équipe`}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    margin: 0,
                                }}
                            >
                                <IconButton onClick={handleShowMore} style={{ padding: 0 }}>
                                    <ExpandMoreIcon
                                        color="primary"
                                        style={{
                                            fontSize: 30,
                                            transform: showMoreClicked ? "rotate(180deg) " : "rotate(0deg)",
                                        }}
                                    />
                                </IconButton>
                            </Grid>
                        </Grid>
                    )}
                </Box>
            </Hidden>
            {/* Zoom on a member */}
            {width >= 960 &&
                zoomMemberArray.map((el, i) => {
                    if (i === teamMemberSelected) {
                        return el;
                    }
                })}

            {width < 960 ? (
                <Box className={styles.boxScrollHorizontal}>
                    <SwipeMembers cellarsData={items} slidesPerView={1} />
                </Box>
            ) : null}
        </>
    );
};

PersonsDetailsBlock.propTypes = {
    members: PropTypes.any,
};

export default PersonsDetailsBlock;
