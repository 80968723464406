import { Box, Button, Typography, makeStyles } from "@material-ui/core";
import theme from "../globalTheme";
import imgLetterFeretBg from "../assets/feretLetter/letter_ferret_fioritures.png";
import imgLetterFeretTxt from "../assets/feretLetter/letter_feret_logo.png";
import imgFeretBubble from "../assets/feretLetter/feret_bubble.png";
import imgMarieLefevere from "../assets/feretLetter/editions/two/feret_marie_lefevere.webp";
import imgInnovin from "../assets/feretLetter/editions/two/Innovin.webp";

import imgBenedicteMartre from "../assets/feretLetter/feret_benedicte_cover.webp";
import imgWineFields from "../assets/feretLetter/feret_banner_wine_fields.png";
import imgTasters from "../assets/feretLetter/pictureTasters.png";
import imgWave from "../assets/feretLetter/wave.svg";

import imgTasterBernard from "../assets/feretLetter/taster_bernard.png";
import imgTasterEtienne from "../assets/feretLetter/taster_etienne.png";
import imgTasterGianni from "../assets/feretLetter/taster_gianni.png";
import imgTasterHenry from "../assets/feretLetter/taster_henry.jpg";

import imgNewsLafitte from "../assets/feretLetter/editions/two/Smith_Haut_Lafitte.webp";
import imgNewsHeinz from "../assets/feretLetter/editions/two/Axel_Heinz_Lascombes.webp";
import imgNewsFigeac from "../assets/feretLetter/editions/two/La_Rose_Figeac.webp";
import imgNewsMehari from "../assets/feretLetter/editions/two/LaGrande_Mehari.webp";

import fioOne from "../assets/feretLetter/fioritures/fio1.webp";
import fioTwo from "../assets/feretLetter/fioritures/fio2.webp";
import fioThree from "../assets/feretLetter/fioritures/fio3.webp";
import fioFour from "../assets/feretLetter/fioritures/fio4.webp";
import fioFive from "../assets/feretLetter/fioritures/fio5.webp";

import imgLogo from "../assets/feretLetter/feretInline.svg";
import imgLogoInnovin from "../assets/feretLetter/editions/two/innovin.png";

import { Link, useLocation } from "react-router-dom";
import classNames from "clsx";
import { useEffect, useRef } from "react";
import { useCustomHistory } from "hooks";
import { displayName } from "react-quill";

const useStyles = makeStyles((e) => ({
    feretLetterHeader: {
        fontFamily: "Playfair Display",
        backgroundImage: `url(${imgLetterFeretBg})`,
        backgroundSize: "cover",
        minHeight: "250px",
        width: "100%"
    },
    feretBodyContainer: {
        overflow: "hidden",
        position: "relative",
        display: "flex",
        flexDirection: "column",
    },
    feretLetterBody: {
        position: "relative",
        padding: "25px 15px",
        maxWidth: "1000px",
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        gap: "2em",
        overflow: "visible",
    },
    feretLetterBodyWithWrapper: {
        position: "relative",
        overflow: "hidden",
    },
    feretLetterBodyWithBgWrapper: {
        backgroundColor: "#EDE5E5"
    },
    feretLetterBodyWithBg: {
        padding: "25px 15px",
        maxWidth: "1000px",
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        gap: "2em",
        backgroundColor: "#EDE5E5"
    },
    feretLetterSubHeader: {
        display: "flex",
        flexDirection: "column-reverse",
        justifyItems: "center",
        alignItems: "center",
        gap: "2em",
        margin: "0 0 20px 0",

        [theme.breakpoints.up("md")]: {
            flexDirection: "row"
        }
    },
    feretLetterSubHeaderContent: {
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "50%",
            flexShrink: "0",
        }
    },
    feretLetterSubHeaderContentTitle: {
        textAlign: "center",
        lineHeight: "42px",
        color: theme.palette.primary.main,
        [theme.breakpoints.up("md")]: {
            textAlign: "left",
            width: "100%",
        }
    },
    feretLetterSubHeaderImg: {
        width: "100%",
        margin: "0 auto",
        [theme.breakpoints.up("md")]: {
            width: "50%",
            minHeight: "100%"
        }

    },
    feretLetterTextHeaderContent: {
        fontSize: "20px",
        lineHeight: "28px", 
        textAlign: "justify",
        color: theme.palette.primary.main,
        [theme.breakpoints.up("md")]: {
            fontSize: "20px",
            lineHeight: "32px", 
        }
    },
    feretLetterTextContent: {
        fontSize: "18px",
        lineHeight: "28px", 
        textAlign: "justify",
        [theme.breakpoints.up("md")]: {
            fontSize: "20px",
            lineHeight: "32px", 
        },
        "& br": {
            display: "block",
            lineHeight: "30px"
        }
    },
    feretLetterTextTitleDescription: {
        fontSize: "18px",
        lineHeight: "28px", 
        textAlign: "justify", 
        margin: "1em 0 0 0",
    },
    separator: {
        width: "100%",
        height: "1px",
        backgroundColor: "#E2E2E2",
        border: "none",
        margin: "40px 0",
        [theme.breakpoints.up("md")]: {
            margin: "80px 0"
        }
    },
    imgWineFields: {
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "75%",
            margin: "0 auto"
        }
    },
    testimonials: {
        padding: "0 0 4em 0"
    },
    testimonialWrapper: {

    },
    testimonialHeader: {
        position: "relative",
        height: "200px",
    },
    testimonialHeaderRight: {
        position: "relative",
        textAlign: "left",
        height: "200px",

        [theme.breakpoints.up("md")]: {
            textAlign: "right",
        }
    },
    testimonialHeaderTitle: {
        position: "absolute",
        left: "0",
        top: "50%",
        color: theme.palette.primary.main,
        transform: "translate(0,-50%)",
        [theme.breakpoints.up("md")]: {
        }
    },
    testimonialHeaderTitleRight: {
        position: "absolute",
        width: "100%",
        right: "0",
        top: "50%",
        color: theme.palette.primary.main,
        transform: "translate(0,-50%)",
        [theme.breakpoints.up("md")]: {
        }
    },
    testimonialHeaderImg: {
        position: "absolute",
        left: "0",
        top: "50%",
        zIndex: "-1",
        width: "100px",
        transform: "translate(40%,-40%) scale(1.75)",
        opacity: "0.4",
        
        [theme.breakpoints.up("lg")]: {
            transform: "translate(0,-40%) scale(1.75)",
        }
    },
    testimonialHeaderImgRight: {
        position: "absolute",
        right: "0",
        top: "50%",
        zIndex: "-1",
        width: "100px",
        transform: "translate(-40%,-40%) scale(-1.75, 1.75) ",
        opacity: "0.4",
        
        [theme.breakpoints.up("lg")]: {
            transform: "translate(0,-40%) scale(-1.75, 1.75)",
        }
    },
    tasterList: {
        display: "flex",
        flexDirection: "column",
        gap: "75px"
    },
    tasterWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        textAlign: "justify",
        [theme.breakpoints.up("md")]: {
            flexDirection: "row",
            alignItems: "center",
            textAlign: "left",
        },
        gap: "30px"
    }, 
    tasterImg: {
        borderRadius: "50%",
        width: "150px",
        height: "150px",
        [theme.breakpoints.up("md")]: {
            width: "180px",
            height: "180px",
        },
        objectFit: "cover"
    },
    millesimeCTA: {
        padding: "40px 0", 
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center",
        background: "#EEE5E5",
    },
    millesimeBtn: {
        backgroundColor: "#915868",
        color: "#fff",
        padding: "10px 20px",
        fontFamily: "Playfair Display",
        textDecoration: "none",
        width: "fit-content",
        margin: "0 auto",
        borderRadius: "4px",
    },
    millesimeLink: {
        textDecoration: "none",
        fontFamily: "Playfair Display",
        width: "fit-content",
        color: "#000",
    },
    boxComment: { 
        position: "relative", 
        overflow: "hidden",
        padding: "0.5em", 
        border: "solid #632D3E 1px", 
        background: "FCF6F6", 
        textAlign: "justify",
        [theme.breakpoints.up("md")]: { 
            padding: "10px 100px 10px 10px" 
        } 
    },
    fioOne: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "block",
            position: "absolute", 
            right: "0", 
            top: "60px", 
            zIndex: "-1", 
            width: "400px",
        }
    },
    firstParagraph: {
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "60%"
        }
    },
    millesimeBtnTypo: { 
        fontFamily: "Playfair Display", 
        fontWeight: "700", 
        fontSize: "18px",
        [theme.breakpoints.up("md")]: {
            fontSize: "22px",
        }
    },
    waveBox: { width:"100%", height: "60px", [theme.breakpoints.up("md")]: { height: "100px" }, position: "relative" },
    subscriberNewsHeader: {
        margin: "0 0 2em 0",
    },
    subscriberNewsTitle: {
        color: theme.palette.primary.main, 
        fontWeight: "700"
    },
    subscriberNewsSubTitle: {
        color: theme.palette.primary.main, 
        fontWeight: "600",
        fontSize: "1.25em",
        lineHeight: "2em"
    },
    subscriberNewsBody: {
        display: "flex",
        width: "100%",
        flexDirection: "column-reverse",
        [theme.breakpoints.up("md")]: {
            flexDirection: "row",
            gap: "40px"
        }
    },
    subscriberNewsBodyImgWrapper: {
        width: "100%",
        margin: "0 0 1em 0",
        [theme.breakpoints.up("md")]: {
            width: "40%",
            margin: "0"
        }
    },
    subscriberNewsBodyImg: {
        width: "100%",
        height: "100%",
        objectFit: "cover"
    },
    subscriberNewsBodyImgFigeac: {
        display: "block",
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "60%",
            margin: "2em auto 0 auto"
        }
    },
    subscriberNewsBodyContent: {
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "60%",
        }
    },
    subscriberSeparator: {
        width: "100%",
        height: "1px",
        backgroundColor: "#E2E2E2",
        border: "none",
        margin: "20px 0",
        [theme.breakpoints.up("md")]: {
            margin: "30px 0"
        }
    },
    "@global": {
        "a": {
            color: "#000"
        }
    }
}));

const FeretLetterPage = (props) => {
    const styles = useStyles(props, theme);

    const history = useCustomHistory();

    const redirection = () => {
        window.open("https://501d5642.sibforms.com/serve/MUIFAOiZvjoYMrT1PePLF1M2OpvyXtmN1QqULV3fpd-T86rBrGyo0vhnMrA4xjFUM3ui5rQ-GxgdqnQKzLRHHWVMsijnIIzmOK9JY3nnqg3TeRYR45EP0-_g1p9D39vlGaRRJxXRu5IG29lb70vuNQ-GB0mOO3e6EJTrSY9cHqfCsAdRONAle-P-gmw4Jhvwryl05Jp-otNj5uUj", "_blank");
    };

    const download = () => {
        window.open("https://feret.com/wp-content/uploads/2024/06/La-Lettre-Feret-n%C2%B02.pdf", "_blank");
    };

    const hashRefs = useRef({});
    const location = useLocation();

    const scrollToHash = () => {
        const hash = location.hash.slice(1);
        const element = hashRefs.current[hash];
        
        if (hash && element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
            scrollToHash(); 
        }, 500);
    }, []);

    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                gap={4}
                className={ styles.feretLetterHeader }
            >  
                <img src={ imgLetterFeretTxt } style={{ width: "300px", margin: "0 auto" }} onDragStart={ (e) => { e.preventDefault(); } } />
            </Box>

            <Box className={ styles.feretBodyContainer }>

                <Box className={ styles.feretLetterBody}>
                    <Typography style={{ margin: "1.5em 0 0 0", fontFamily: "dsds" }}>#1-juillet 2024</Typography>
                    <Typography variant="h1">L&apos;actualité trimestrielle du vignoble bordelais vue par Féret.</Typography>
                </Box>

                <Box ref={ref => hashRefs.current["editorial"] = ref} className={ styles.feretLetterBody} style={{ padding: "0 0 25px 0", margin: "0 auto" }}>
                    <hr className={ styles.separator} style={{ margin: "40px 0 0 0" }} />
                </Box>

                {/* Éditorial de la Lettre Feret */}
                <Box className={ styles.feretLetterBody }>
                
                    <Box style={{ display: "flex", flexDirection: "column", gap: "0.5em" }}>
                        <Typography variant="h2" className={ styles.feretLetterSubHeaderContentTitle }>Éditorial</Typography>
                        <Typography variant="h3" className={ styles.feretLetterSubHeaderContentTitle }><i>Une caisse de résonance</i></Typography>
                    </Box>

                    <img src={ fioOne} className={ styles.fioOne } />

                    <Typography variant="body1" className={ classNames(styles.feretLetterTextContent, styles.firstParagraph) }>
                        Cette deuxième édition de la Lettre Féret se veut être un petit condensé d’informations du vignoble girondin que nous espérons aussi utile que plaisant.
                        <br/>
                        Dans la continuité de la précédente lettre, nous vous invitons à partir à la rencontre d’une personnalité du vignoble. Cette-fois-ci, il s’agit de Marie Lefévère, la propriétaire des châteaux Sansonnet, Villemaurine, Soutard-Cadet et Moulin du Cadet.  
                    </Typography>

                    <Typography variant="body1" className={ styles.feretLetterTextContent }>
                        La présente Lettre a été augmentée de deux nouvelles rubriques. L’une concerne les innovations de la filière et l’autre, les actualités du vignoble. Deux espaces pour dire avec notre partenaire <a href="https://innovin.fr/" target="_blank" rel="noreferrer">INNOV&apos;IN</a> combien Bordeaux se renouvelle et enfin pour nous faire l’écho des nouveautés et initiatives des vigneronnes et des vignerons du Bordelais. 
                    </Typography>

                    <Typography variant="h3" style={{ color: theme.palette.primary.main }}>Prescripteur majeur</Typography>

                    <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                        Il fallait enfin que nous vous remerciions pour vos nombreux retours positifs et le relai sur vos réseaux ou sites de la <Link to={ history.addLocale("/note-du-millesime") } className={ styles.millesimeLink }>notation des primeurs 2023</Link>. Les dégustateurs émérites Bernard Grandchamp, ancien directeur de domaines viticoles et dégustateur pour le Guide Hachette des Vins, Étienne Khemtémourian, formateur, vinificateur et ancien collaborateur à la RVF et Gianni Degl’Innocenti, sommelier professionnel et conférencier, ont pour l’occasion dégusté près de 500 vins. Si ce chiffre reste impressionnant, nous nous engageons d’ores et déjà à faire mieux l’année prochaine. Une contribution notable qui doit à terme nous positionner comme un prescripteur important. Nous restons en effet persuadés que Féret aura désormais toute sa place à jouer dans ce moment fort, sinon clé, de l’année.  
                    </Typography>

                    <Typography variant="h3" style={{ color: theme.palette.primary.main }}>Des salons au cœur de Bordeaux</Typography>
                    <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                        Saviez-vous que pour préparer votre rentrée, Féret peut mettre à disposition des salons dans l’écrin exceptionnel de l’hôtel Fenwick - 1 cours Xavier Arnozan à Bordeaux - pour faire goûter vos vins ? N’hésitez pas à vous adresser à nous pour plus d’informations.
                        <br/>
                        Par ailleurs, Féret peut fournir à tout un chacun, et sur simple demande, une mine d’archives vitivinicoles. Un outil précieux, il nous semble, pour bâtir un beau storytelling, étayer votre communication, sur des bases historiques fiables.  
                    </Typography>


                    <Typography variant="body1" className={ styles.feretLetterTextContent }>
                        Fidèlement vôtre, 
                        <br/>
                        <br/>
                        Henry Clemens
                        <br/>
                        Directeur de la publication
                    </Typography>

                    <Box style={{ padding: "0.5em", border: "solid #632D3E 1px", textAlign: "justify"  }}>
                        <Typography style={{  color: "#632D3E", fontWeight: "700", fontSize: "18px" }}>
                            Fondé en 1813 par Jean-Baptiste Féret à Bordeaux, la maison Féret est l&apos;une des plus anciennes maisons d&apos;édition spécialisées dans le domaine du vin en France dont Bordeaux et ses vins constitua longtemps le véritable bateau amiral. Depuis sa création, elle s&apos;est engagée à promouvoir et à documenter l&apos;histoire et la culture viticoles, en publiant une variété d&apos;ouvrages de référence, d’encyclopédies, de guides et de revues. Féret est réputée pour sa rigueur éditoriale et son engagement envers la qualité, ce qui en fait une source fiable d&apos;informations bicentenaires pour les professionnels, les passionnés de vin, les chercheurs ou les collectionneurs. Au fil des décennies, Féret a su établir sa réputation en tant qu&apos;autorité incontestée dans le monde du vin bordelais, contribuant ainsi à renforcer l&apos;image et la notoriété de la région viticole. 
                        </Typography>
                    </Box>

                </Box>

                {/* <Box className={ styles.waveBox }>
                    <img src={imgWave} style={{ height: "100%", width: "100%", position: "absolute", left: "0", right: "0", bottom:"0" }} />
                </Box> */}

                {/* <Box ref={ref => hashRefs.current["tasters"] = ref} className={ styles.feretLetterBodyWithBgWrapper }>

                    <Box className={ styles.feretLetterBodyWithBg }>

                        <Box>
                            <Typography variant="h3" style={{ textAlign: "center", padding: "40px 0", fontSize: "28px" }}>Présentation de nos dégustateurs lors des primeurs 2023</Typography>
                        </Box>

                        <Box className={ styles.tasterList }>

                            <Box className={ styles.tasterWrapper }>
                                <Box>
                                    <img src={ imgTasterBernard } className={ styles.tasterImg } onDragStart={ (e) => { e.preventDefault(); } } />
                                </Box>

                                <Box>
                                    <Typography paragraph variant="h3" style={{ fontFamily: "Mansalva", color: theme.palette.primary.main }}>Bernard Grandchamp</Typography>
                                    <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>Bernard Grandchamp, ancien directeur de domaines viticoles et expert viticole à Bordeaux et ailleurs, dégustateur pour le Guide Hachette des Vins et divers concours internationaux, expert judiciaire près la cour d&apos;appel de Bordeaux de 1996 à 2006.</Typography>
                                </Box>
                            </Box>

                            <Box className={ styles.tasterWrapper }>
                                <Box>
                                    <img src={ imgTasterEtienne } className={ styles.tasterImg } onDragStart={ (e) => { e.preventDefault(); } } />
                                </Box>

                                <Box>
                                    <Typography paragraph variant="h3" style={{ fontFamily: "Mansalva", color: theme.palette.primary.main }}>Étienne Khemtémourian</Typography>
                                    <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>Étienne Khemtémourian, bloggeur et formateur à la dégustation, vinificateur à Montagne Saint-Émilion, grand connaisseur de Bordeaux, Bourgogne et Champagne, qui a collaboré autrefois à la RVF et au Gault & Millau.</Typography>
                                </Box>
                            </Box>

                            <Box className={ styles.tasterWrapper }>
                                <Box>
                                    <img src={ imgTasterGianni } className={ styles.tasterImg } onDragStart={ (e) => { e.preventDefault(); } } />
                                </Box>

                                <Box>
                                    <Typography paragraph variant="h3" style={{ fontFamily: "Mansalva", color: theme.palette.primary.main }}>Gianni Degl&apos;Innocenti</Typography>
                                    <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>Gianni Degl&apos;Innocenti : sommelier et dégustateur officiel de l&apos;<i>Associazione Italiana Sommelier</i> depuis 2004, rédacteur en chef et responsable des formations de troisième niveau. Également collectionneur et grand connaisseur des terroirs les plus prestigieux d&apos;Italie et de France, maître-dégustateur de l&apos;ONAF <i>(Organizzazione Nazionale Assaggiatori Formaggi).</i></Typography>
                                </Box>
                            </Box>

                            <Box className={ styles.tasterWrapper }>
                                <Box>
                                    <img src={ imgTasterHenry } className={ styles.tasterImg } onDragStart={ (e) => { e.preventDefault(); } } />
                                </Box>

                                <Box>
                                    <Typography paragraph variant="h3" style={{ fontFamily: "Mansalva", color: theme.palette.primary.main }}>Henry Clemens</Typography>
                                    <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>Après un long parcours en tant que formateur, enseignant et rédacteur vin, il intègre le milieu de la presse. Rédacteur régulier pour les revues spécialisées <i>Sommeliers International</i>, <i>Tanin</i>, <i>Terre de Vins</i> ou encore la revue culturelle <i>JunkPage</i>, dont il fut un temps rédacteur en chef, il a rejoint la maison d&apos;édition Féret en 2024 au poste de Directeur des Opérations.</Typography>
                                </Box>
                            </Box>

                        </Box>

                    </Box>

                </Box>

                <Box style={{ position: "relative", width:"100%" }}>
                    <Box className={ styles.millesimeCTA }>
                        <Link to={ history.addLocale("/note-du-millesime") } className={ styles.millesimeLink }><Button color="primary" variant="contained" size="large" className={styles.millesimeBtn }><Typography className={ styles.millesimeBtnTypo }>Consulter les notes du millésime 2023.</Typography></Button></Link>
                    </Box>    
                
                    <img src={imgWave} style={{ height: "100%", width: "100%", transform: "rotate(180deg) translate(0, -100%)", position: "absolute", left: "0", right: "0", bottom:"0" }}/>
                </Box> */}

                {/* <Box className={ styles.feretLetterBody }>
                    <Box style={{ margin: "0 auto", maxWidth: "550px", textAlign: "center", display: "flex", flexDirection: "column", alignContent: "center", justifyContent: "center", gap: "10px" }}>
                        <img src={ imgTasters } style={{ width: "100%", margin: "0 auto", maxWidth: "950px" }} />
                        <Typography style={{ fontSize: "14px" }}><i>Étienne Khemtémourian, Gianni Degl&apos;Innocenti, Bernard Grandchamp, dégustateurs Féret, Lucille Rioual et Claudia Campanella, accompagnatrices et interprètes pour nos dégustateurs.</i></Typography>
                    </Box>
                </Box> */}

                {/* <hr ref={ref => hashRefs.current["edito"] = ref} className={ styles.separator } /> */}

                {/* Note du millesime */}
                {/* <Box className={ styles.feretLetterBody } style={{ marginBottom: "40px" }}>

                    <Box className={ styles.feretLetterSubHeader } >
                        <Box className={ styles.feretLetterSubHeaderContent }>

                            <Typography variant="h2" className={ styles.feretLetterSubHeaderContentTitle } style={{ margin: "0 0 1em 0", display: "inline-block", flexDirection: "row", alignContent: "center", gap: "0.5em" }}>Edito <img width="125" src={imgLogo} alt="" style={{ display: "inline-block" }}/></Typography>
                            <Typography variant="body1" className={ styles.feretLetterTextContent }>
                                Féret, c&apos;est une première, revêt l&apos;habit d&apos;un prescripteur passionné et légitime de plus de 200 ans d&apos;expertise avec la présentation de notes de dégustations primeurs. Pour cet événement annuel majeur, un collège d&apos;experts Féret reconnus (un jury trois étoiles !) livre les notations pour la campagne 2023.
                            </Typography>
                        </Box>

                        <img src={ imgWineDegustation } className={ styles.feretLetterSubHeaderImg } onDragStart={ (e) => { e.preventDefault(); } } />
                    </Box>

                    <Typography variant="h3" style={{ color: theme.palette.primary.main }}>Un millésime riche et multiple</Typography>

                    <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                        Il est bien entendu impossible de résumer le millésime 2023 à quelques caractéristiques ou séquences météorologiques. Bordeaux reste multiple, riche et démontre cette année encore sa forte capacité d&apos;adaptation. On parlera cette année une nouvelle fois d&apos;un millésime révélateur de lieux et de grands terroirs. 
                    </Typography>

                    <Typography variant="h4" className={ styles.feretLetterTextHeaderContent}>Un scénario à suspense</Typography>

                    <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                        Pour la campagne 2023, la fin de printemps est très humide, ce qui fait dire à Benjamin Massié de Derenoncourt Consultants, qu&apos;il s&apos;agit d&apos;un millésime qui a demandé une grande capacité d&apos;adaptation, aussi bien pour les bio que pour les conventionnels avec des cadences de traitements assez similaires.
                        <br/>
                        Le mois de juillet est marqué par un changement radical de climat. Cependant, les nuits restent chaudes avec une pression continue du mildiou. On a vite basculé à partir du 18 août dans une séquence caniculaire, et ce jusqu&apos;au 25 août. C&apos;est un millésime qui peut être vu comme un des plus chauds de la dernière décennie. Comparé à 2022, ce dernier laisse en revanche beaucoup plus parler les terroirs et peut, à certains égards, être comparé à 2014. 
                        <br/>
                        Phénomène plutôt rare, l&apos;été indien a permis la concomitance des maturités phénoliques et technologiques. Les arômes sont préservés en fin de cycle ce qui en fait un millésime très intéressant avec des équilibres aromatiques, de l&apos;énergie et de la fraîcheur
                    </Typography>

                    <Typography variant="h4" className={ styles.feretLetterTextHeaderContent}>Rendements et fraîcheur au rendez-vous</Typography>

                    <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                        On constate bien entendu une forte variété sur l&apos;ensemble du territoire, avec les impacts liés au mildiou ou au gel. On peut parler d&apos;un millésime de cabernets et plus particulièrement de cabernet sauvignon sur la rive gauche. Les rendements sont très beaux sur de nombreux secteurs.
                        <br/>
                        Les belles sorties de fruits ont souvent nécessité de soulager les vignes afin de pouvoir aller chercher les bonnes maturités. Il y a eu sur les sols plus filtrants quelques problèmes de stress hydrique. Sur le calcaire, on retrouve de belles acidités, de la fraîcheur, de l&apos;énergie et des jus souvent très mûrs.
                        <br/>                        
                        Il n&apos;est pas exagéré de dire qu&apos;en 2023, tout s&apos;est joué à la vigne avec, en particulier, le besoin de porter une attention particulière aux sols dès le mois d&apos;août. Avec toute une réflexion autour des couverts végétaux qu&apos;il fallait mener dès le mois de juin. En cuve, les équilibres sont intéressants avec une belle extractibilité des tanins et des arômes.
                        <br/>
                        Ce qui fait dire à beaucoup d&apos;œnologues qu&apos;il s&apos;agit en réalité d&apos;un millésime qui demande à s&apos;effacer. En fonction des terroirs, les jus ne devront pas être élevés de la même manière. Il faudra distinguer les lots issus de sables ou de graves aux bouquets aromatiques très fins et pas très opulents (d&apos;où une proportion accrue d&apos;amphores) ou encore les vins de sols argileux ou calcaires pour ne pas matraquer les jus. 
                    </Typography>

                    <Typography variant="h4" className={ styles.feretLetterTextHeaderContent}>Un millésime sans recette préétablie</Typography>

                    <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                        On repérera, bien souvent, dans ce millésime d&apos;« esthètes » les acteurs et actrices qui ont respecté la matière, à l&apos;extraction comme à l&apos;élevage.
                        <br/>
                        Des équilibres somme toute assez fragiles demanderont en effet parfois un peu de retenue. À l&apos;image des 2014 ou des 2016, le 2023 se présente comme une éponge à terroir. On peut encore une fois parler d&apos;un millésime de vignerons dans la mesure où 2023 a demandé une grande capacité d&apos;adaptation, de la flexibilité, la remise en cause de pratiques et une cuvaison particulièrement adaptée.
                    </Typography>

                    <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                        Les blancs offrent des équilibres parfaits. Les vins sont élégants et digestes avec des teneurs en alcool modérées. Les sauvignons blancs ont été ramassés précocement la dernière semaine d&apos;août. Ils affichent des notes suggérant les fruits jaunes, les fruits exotiques et sont souvent dénués de tout caractère végétal. Les sémillons plus tardifs ont échappé aux pluies de mi-septembre. Ils apportent du gras à l&apos;ensemble. Cela donne des vins aromatiques, complexes et joliment explosifs. 
                    </Typography>

                    <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                        Les rouges se présentent sous des atours plus fins qu&apos;opulents. Il faudra se montrer très attentif à ne pas trop extraire pour conserver le soyeux et la rondeur des débuts de fermentation. Finalement donc des vins tout en finesse, plus classiques mais d&apos;une jolie profondeur. Ils sont globalement construits sur une belle trame de tanins fins, procurée par une fin d&apos;été favorable et un beau début d&apos;automne. Les vins jouissent globalement de concentrations et de niveaux d&apos;alcool très modérés. Les grands terroirs sont au rendez-vous ! On se laissera surprendre par la richesse et la diversité de leurs profils.
                    </Typography>

                    <Box className={ styles.boxComment }>
                        <Typography style={{  color: "#632D3E", fontSize: "18px" }}>
                            La campagne 2023 fut intense et soutenue, dans la mesure où les sorties plus précoces se sont effectuées à un rythme très rapproché chez bon nombre de têtes d&apos;affiche et autres « stars », suscitant un peu d&apos;excitation. Cette campagne des primeurs a été plus ramassée et dense que 2022 qui s&apos;étira quant à elle sur deux mois. Comme rappelé par M. Bernard, du groupe Millésima, le contexte économique particulier d&apos;inflation et de ralentissement économique « génère une incertitude qui incite inéluctablement les consommateurs et les investisseurs à être plus prudents ». Sous entendant que les prix seront globalement revus à la baisse, aux vues du niveau des stocks chez les importateurs, les distributeurs et les cavistes ou encore de la baisse des ventes sur les principaux marchés exports. Ce qui, au regard de la qualité et du volume du millésime, devrait susciter un regain d&apos;attractivité et redonner le sourire aux consommateurs et aux producteurs.
                        </Typography>
                        <img src={ fioThree} style={{ position: "absolute", top: "-50px", right: "-10px", width: "250px", zIndex: "-1", opacity: "0.5" }} />
                    </Box>

                    <img src={ fioTwo } style={{ position: "absolute", left: "-300px", transform: "scale(-1.5, 1.5)", top: "10%", zIndex: "-1", width: "400px", opacity: "0.5" }}/>
                    <img src={ fioTwo } style={{ position: "absolute", right: "-300px", transform: "scale(1.5)", top: "30%", zIndex: "-1", width: "400px", opacity: "0.5" }}/>
                    <img src={ fioTwo } style={{ position: "absolute", left: "-300px", transform: "scale(-1.5, 1.5)", top: "50%", zIndex: "-1", width: "400px", opacity: "0.5" }}/>
                    <img src={ fioTwo } style={{ position: "absolute", right: "-300px", transform: "scale(1.5)", top: "70%", zIndex: "-1", width: "400px", opacity: "0.5" }}/>
                </Box> */}

                <hr ref={ ref => hashRefs.current["coup-de-projecteur"] = ref } className={ styles.separator } />

                {/* Coup de projecteur sur : Marie Lefévère */}
                <Box className={ styles.feretLetterBodyWithWrapper }>

                    <img src={ fioFour } style={{ position: "absolute", top: "20px", transform: "scale(1.5)", left: "0px", width: "250px", zIndex: "-1", opacity: "0.5" }} />
                    
                    <Box className={ styles.feretLetterBody }>

                        <img src={ fioTwo } style={{ position: "absolute", right: "-300px", transform: "scale(1.5)", top: "12.5%", zIndex: "-1", width: "400px", opacity: "0.5" }}/>
                        <img src={ fioTwo } style={{ position: "absolute", left: "-300px", transform: "scale(-1.5, 1.5)", top: "20%", zIndex: "-1", width: "400px", opacity: "0.5" }}/>
                        <img src={ fioTwo } style={{ position: "absolute", right: "-300px", transform: "scale(1.5)", top: "32.5%", zIndex: "-1", width: "400px", opacity: "0.5" }}/>
                        <img src={ fioTwo } style={{ position: "absolute", left: "-300px", transform: "scale(-1.5, 1.5)", top: "47.5%", zIndex: "-1", width: "400px", opacity: "0.5" }}/>
                        <img src={ fioTwo } style={{ position: "absolute", right: "-300px", transform: "scale(1.5)", top: "57.5%", zIndex: "-1", width: "400px", opacity: "0.5" }}/>
                        <img src={ fioTwo } style={{ position: "absolute", left: "-300px", transform: "scale(-1.5, 1.5)", top: "70.5%", zIndex: "-1", width: "400px", opacity: "0.5" }}/>
                        <img src={ fioTwo } style={{ position: "absolute", right: "-300px", transform: "scale(1.5)", top: "77.5%", zIndex: "-1", width: "400px", opacity: "0.5" }}/>
                        <img src={ fioTwo } style={{ position: "absolute", left: "-300px", transform: "scale(-1.5, 1.5)", top: "91.5%", zIndex: "-1", width: "400px", opacity: "0.5" }}/>
                        <img src={ fioFive } style={{ position: "absolute", right: "-300px", transform: "scale(-1, 1)", bottom: "0", zIndex: "-1", width: "400px", opacity: "0.5" }}/>

                        <Box className={ styles.feretLetterSubHeader } style={{ padding: "70px 0 0 0" }}>
                            <Box className={ styles.feretLetterSubHeaderContent } justifyContent="center" style={{ gap: "1em" }}>
                                <Typography variant="h2" className={ styles.feretLetterSubHeaderContentTitle }>Coup de projecteur sur : <br/><i>Marie Lefévère</i></Typography>
                                <Typography variant="body1" className={ styles.feretLetterTextTitleDescription }>Féret part à la rencontre de Marie Lefévère, propriétaire des châteaux, <a href="https://bordeaux.feret.com/fr/OHY1MF-chateau-sansonnet">Sansonnet</a>, <a href="https://bordeaux.feret.com/fr/4DYQ65-chateau-villemaurine">Villemaurine</a>, Soutard-Cadet et <a href="https://bordeaux.feret.com/fr/4LFKXF-chateau-moulin-du-cadet">Moulin du Cadet</a>.</Typography>
                                <Typography variant="body1" className={ styles.feretLetterTextTitleDescription }><i>« Il faut remettre le mot convivialité au cœur de Bordeaux. » <br/>Marie Lefévère</i></Typography>
                            </Box>

                            <img src={imgMarieLefevere} className={ styles.feretLetterSubHeaderImg } onDragStart={ (e) => { e.preventDefault(); } } style={{ height: "400px", width: "auto" }} />
                        </Box>

                        <Box className={styles.testimonials}>

                            {/* Left */}
                            <Box className={styles.testimonialWrapper}>
                                <Box className={styles.testimonialHeader} >
                                    <Typography variant="h3" className={ styles.testimonialHeaderTitle }>Qui êtes-vous ?</Typography>
                                    <img className={ styles.testimonialHeaderImg }  alt="" src={ imgFeretBubble } />
                                </Box>

                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    Elle paraît simple cette question mais je ne suis pas certaine qu&apos;elle le soit (Rires). Je suis une fille du coin. J&apos;ai grandi aux alentours de Saint-Émilion et je suis arrière-petite fille, petite fille et fille de viticulteur. 
                                </Typography>
    
                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    Je me suis un peu égarée en entreprenant des études de pharmacie mais après avoir exercé quelques années, je suis revenue à ma première passion. Je suis scientifique de nature et les études m&apos;ont plu ainsi que le côté très humain du métier mais j&apos;ai trouvé ça plus monotone, moins tributaire de la saisonnalité et changeant que le métier de vigneronne. Et j&apos;étais enfermée dans quelques mètres carrés, loin des vignes et de la nature que j&apos;aime. 
                                </Typography>

                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    J&apos;ai ainsi pu constater qu&apos;on se rend compte de ce que l&apos;on aime en le quittant. Voilà pourquoi j&apos;ai convaincu mon mari – qui est assez éloigné de cette culture du vin – d&apos;investir dans le vignoble. 
                                </Typography>
    
                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    J&apos;ajoute que j&apos;adore les échanges et les rencontres que procure le vin. C&apos;est dans mon ADN.
                                </Typography>

                            </Box>

                            {/* Right */}
                            <Box className={styles.testimonialWrapper}>
                                <Box className={styles.testimonialHeaderRight} >
                                    <Typography variant="h3" className={ styles.testimonialHeaderTitleRight } >Parlez-nous de vos propriétés</Typography>
                                    <img className={ styles.testimonialHeaderImgRight }  alt="" src={ imgFeretBubble } />
                                </Box>

                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    J&apos;ai envie de commencer par vous parler de Soutard-Cadet, une propriété non classée de moins de trois hectares. Un endroit vraiment magique et bénéficiant d&apos;un terroir incroyable qui offre de très beaux vins. 
                                </Typography>
    
                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    <a href="https://bordeaux.feret.com/fr/OHY1MF-chateau-sansonnet">Château Sansonnet</a> est notre première propriété, celle qui nous a permis de nous jeter dans le grand bain. Comme l&apos;aînée d&apos;une fratrie, elle est celle dans laquelle nous avons mis tous nos espoirs, nos principes. On a essayé de comprendre son terroir, son vignoble pour en tirer le meilleur. Il s&apos;agit sans conteste de notre propriété de cœur. 
                                </Typography>
    
                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    <a href="https://bordeaux.feret.com/fr/4LFKXF-chateau-moulin-du-cadet">Moulin du Cadet</a> est une jolie petite propriété, dont le vignoble exposé au nord fait des vins frais et croquants. Elle permet d&apos;augmenter notre production à Saint-Émilion et d&apos;initier un effet de gamme avec des vins plus abordables et d&apos;une plus grande buvabilité. 
                                </Typography>
    
                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    Nous avons fait l&apos;acquisition du  <a href="https://bordeaux.feret.com/fr/4DYQ65-chateau-villemaurine">Château Villemaurine</a> en 2021. Après avoir hésité au vu de la somme de travail que cela représentait, je me suis vite convaincue que je ne pouvais pas passer à côté de ce cru exceptionnel. Un achat de bon sens. Nous nous attelons désormais à révéler son terroir magnifique qui a encore beaucoup à donner.
                                </Typography>

                            </Box>

                            {/* Left */}
                            <Box className={styles.testimonialWrapper}>
                                <Box className={styles.testimonialHeader} >
                                    <Typography variant="h3" className={ styles.testimonialHeaderTitle }>Est-ce que, selon vous, ces châteaux possèdent une identité commune ?</Typography>
                                    <img className={ styles.testimonialHeaderImg }  alt="" src={ imgFeretBubble } />
                                </Box>

                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    Les personnes qui dégustent régulièrement nos vins nous disent fréquemment qu&apos;il y a une touche. Notre maître-mot reste l&apos;équilibre. 
                                </Typography>
    
                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    À Sansonnet nous avons des vins pleins, offrant une belle matière. C&apos;est pourquoi nous sommes très vigilants aux acidités, au pH, à la fraîcheur indispensable au parfait équilibre des vins structurés. 
                                </Typography>
    
                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    À Villemaurine, les vins sont très fins et élégants, on est donc ici attentifs à l&apos;élevage. En plus de sa durée, nous restons exigeants quant à la qualité des bois utilisés. 
                                </Typography>
   
                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    Par conséquent, je dirais que nos vins sont globalement caractérisés par des équilibres entre la matière, le volume, la fraîcheur et le toucher de tanin construit aussi lors des élevages. 
                                </Typography>

                            </Box>

                            {/* Right */}
                            <Box className={styles.testimonialWrapper}>
                                <Box className={styles.testimonialHeaderRight} >
                                    <Typography variant="h3" className={ styles.testimonialHeaderTitleRight }>Comment se réinventer lorsque l&apos;on est à Bordeaux ?  </Typography>
                                    <img className={ styles.testimonialHeaderImgRight }  alt="" src={ imgFeretBubble } />
                                </Box>

                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    Bordeaux se réinvente déjà beaucoup et depuis longtemps. Depuis quinze ans que je suis là, les efforts qui ont été faits sont incroyables sur le plan de l&apos;agroécologie, de l&apos;œnotourisme et de l&apos;accueil. On peut désormais aisément visiter un grand nombre de propriété, petites ou grandes.
                                </Typography>
                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    Bordeaux, qui adapte aussi ses vins aux plus jeunes, ne doit pas lâcher et doit continuer sur cette voie pour contrecarrer la vieille image parfaitement obsolète de vins chers, austères parfois mal faits. Cette image reste encore forte en dépit des efforts colossaux fournis par les associations ou encore les syndicats pour promouvoir une région viticole majeure en France. 
                                </Typography>
                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    Je reste convaincue que nous devons remettre de la convivialité dans les vins de Bordeaux, quelle que soit la gamme des vins proposés. Il faut également que Bordeaux retrouve de la cohérence dans les propositions qu&apos;il fait aux consommateurs. 
                                </Typography>

                            </Box>

                            {/* Left */}
                            <Box className={styles.testimonialWrapper}>
                                <Box className={styles.testimonialHeader} >
                                    <Typography variant="h3" className={ styles.testimonialHeaderTitle }>Parlez-moi du millésime</Typography>
                                    <img className={ styles.testimonialHeaderImg }  alt="" src={ imgFeretBubble } />
                                </Box>

                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    2023 est indéniablement un joli millésime, charmant et tout à fait gourmand. C&apos;est un millésime dont je me suis très vite dit, et bien avant que la campagne des primeurs commence, qu&apos;il allait plaire avec une très belle qualité de tanin, une jolie matière, de la sucrosité, du fruit et de faibles taux d&apos;alcool. On n&apos;est certes pas sur LE très grand millésime mais 2023 est indéniablement un millésime plaisant qui devrait faire consensus et être rassembleur. 
                                </Typography>
                            </Box>

                            {/* Right */}
                            <Box className={styles.testimonialWrapper}>
                                <Box className={styles.testimonialHeaderRight} >
                                    <Typography variant="h3" className={ styles.testimonialHeaderTitleRight }>Votre millésime de cœur ?</Typography>
                                    <img className={ styles.testimonialHeaderImgRight }  alt="" src={ imgFeretBubble } />
                                </Box>

                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    À Sansonnet, je retiendrai le 2016 qui a marqué le début d&apos;une longue série de beaux millésimes même si 2012 est incroyable. Il porte les fruits de tout ce que nous avons mis en œuvre à la vigne, par exemple sur le rééquilibrage des sols, et au chai avec, entre autres, le travail effectué sur les types de barriques, sur les méthodes de tri. Il a été l&apos;aboutissement de ce travail sur la recherche des équilibres. Je suis plutôt très critique mais là je dois avouer qu&apos;il fut un réel accomplissement de toutes ces années de travail. Nous l&apos;avons dégusté récemment et il s&apos;est présenté sans aspérité, tout en finesse et élégance. Même si le millésime nous a bien aidés, bien entendu.
                                </Typography>

                            </Box>

                            {/* Left */}
                            <Box className={styles.testimonialWrapper}>
                                <Box className={styles.testimonialHeader} >
                                    <Typography variant="h3" className={ styles.testimonialHeaderTitle }>Avez-vous une actualité dont vous aimeriez nous parler ?</Typography>
                                    <img className={ styles.testimonialHeaderImg }  alt="" src={ imgFeretBubble } />
                                </Box>

                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    Oui. Nous allons très prochainement développer l&apos;activité œnotouristique au château Sansonnet, avant l&apos;ouverture de l&apos;hôtel l&apos;année prochaine, à Villemaurine. Cela fait suite aux vastes travaux de réaménagement de Sansonnet, qui ont duré deux ans. Avec un nouveau réceptif et un nouveau parcours opérationnels dès juin, nous souhaitons ouvrir nos portes et recevoir, en toute convivialité, les personnes qui dégustent nos vins. Cette activité importante nous permet d&apos;échanger directement avec les consommateurs.
                                </Typography>

                            </Box>

                            {/* Right */}
                            <Box className={styles.testimonialWrapper}>
                                <Box className={styles.testimonialHeaderRight} >
                                    <Typography variant="h3" className={ styles.testimonialHeaderTitleRight }>Votre père, également viticulteur, vous a-t-il transmis un adage ?</Typography>
                                    <img className={ styles.testimonialHeaderImgRight }  alt="" src={ imgFeretBubble } />
                                </Box>

                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    Mon père avait coutume de me dire : « ne deviens pas agriculteur, c&apos;est un métier où on tremble du 1er janvier au 31 décembre ! » Je lui donne raison mais c&apos;est un stress motivant d&apos;autant plus que c&apos;est un métier passion et qui procure aussi de grandes joies. Et puis j&apos;ajoute qu&apos;en dépit de cet adage, mon père et son père avant lui sont restés viticulteurs toute leur vie (<i>Rires</i>). 
                                </Typography>
                            </Box>
                        </Box>

                    </Box>

                </Box>

                <hr ref={ ref => hashRefs.current["actualites"] = ref } className={ styles.separator } />

                {/* L&apos;actualité */}
                <Box  className={ styles.feretLetterBody } style={{ marginBottom: "40px" }}>

                    <Box className={ styles.feretLetterSubHeader } >
                        <Box className={ styles.feretLetterSubHeaderContent } style={{ width: "100%" }}>
                            <Typography paragraph variant="h2" className={ styles.feretLetterSubHeaderContentTitle } style={{ display: "inline-block", flexDirection: "row", alignContent: "center", gap: "0.5em" }}>L&apos;actualité des abonnés <img width="125" src={imgLogo} alt="" style={{ display: "inline-block" }} /></Typography>
                            <Typography variant="body1" className={ styles.feretLetterTextContent }>
                                Pour cette nouvelle édition de la Lettre Féret, nous avons sélectionné quelques nouveautés œnotouristiques ainsi que des brèves du vignoble bordelais. Découvrez-les :
                            </Typography>

                        </Box>
                    </Box>

                    <Box>
                        <Box className={styles.subscriberNewsHeader}>
                            <Typography variant="h3" className={styles.subscriberNewsTitle}><Link to={ history.addLocale("/VKBN1U-chateau-smith-haut-lafitte") } style={{ color: theme.palette.primary.main }}>Château Smith Haut Lafitte</Link></Typography>
                            <Typography variant="body1" className={styles.subscriberNewsSubTitle}>Visite « Terre de vins et d&apos;art »</Typography>
                        </Box>
                    
                        <Box className={styles.subscriberNewsBody}>

                            <Box className={styles.subscriberNewsBodyContent}>
                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    Parce que dans « artisan » il y a « art » et que dans « agriculture » il y a « culture », le Château Smith Haut Lafitte est un carrefour où artisans et artistes se rencontrent. Venez découvrir la passion du métier de vigneron lors d&apos;une visite « Terre de vins et d&apos;art ».
                                </Typography>

                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    Vous aurez la chance d&apos;y découvrir tout le savoir-faire artisanal de ce domaine tel que la confection des barriques et vous découvrirez également une collection d&apos;art établie au rythme des millésimes. Véritable musée à ciel ouvert, ce château vous ouvre ses bras et vous donne également l&apos;occasion de découvrir sa forêt des sens, un parcours pédestre, composé d&apos;installations poétiques et de sculptures parfois un peu provocantes, mêlant une réelle harmonie entre bois, ruisseaux et œuvres d&apos;art.
                                </Typography>
            
                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>Pour plus d&apos;informations : <a href="https://www.smith-haut-lafitte.com/les-visites-et-ateliers/">Château Smith Haut Lafitte</a></Typography>
                            </Box>

                            <Box className={styles.subscriberNewsBodyImgWrapper}>
                                <img src={ imgNewsLafitte } className={styles.subscriberNewsBodyImg} />
                            </Box>

                        </Box>

                    </Box>

                    <hr className={styles.subscriberSeparator} />

                    <Box>
                        <Box className={styles.subscriberNewsHeader}>
                            <Typography variant="h3" className={styles.subscriberNewsTitle}><Link to={ history.addLocale("/DQAB87-chateau-desmirail") } style={{ color: theme.palette.primary.main }}>Château Desmirail</Link></Typography>
                            <Typography variant="body1" className={styles.subscriberNewsSubTitle}>La Bulle Verte « À Desmirail, dans les pas de Denis Lurton »</Typography>
                        </Box>
                    
                        <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                            Reconnectez-vous à la nature en vous promenant en toute autonomie à travers leurs vignes. Grâce à un QR code à scanner vous permettant de télécharger une carte en ligne sur votre smartphone, vous découvrirez les secrets de leur terroir, les cépages qui y prospèrent et l&apos;importance du respect de la biodiversité. Cette balade apaisante vous permettra de vous immerger pleinement dans l&apos;atmosphère de Desmirail.
                        </Typography>

                        <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                            Après cette balade revigorante, poursuivez, si vous le souhaitez, cette expérience unique en réservant une visite et dégustation de votre choix au sein du château : classique, olfactive ou vins, fromages et charcuterie.
                        </Typography>
    
                        <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>Pour plus d&apos;informations : <a href="https://www.desmirail.com/oenotourisme/">Château Desmirail</a></Typography>
                    </Box>

                    <hr className={styles.subscriberSeparator} />

                    <Box>
                    
                        <Box className={styles.subscriberNewsBody}>

                            <Box className={styles.subscriberNewsBodyImgWrapper}>
                                <img src={ imgNewsMehari } className={styles.subscriberNewsBodyImg} />
                            </Box>

                            <Box className={styles.subscriberNewsBodyContent}>
                                <Box className={styles.subscriberNewsHeader}>
                                    <Typography variant="h3" className={styles.subscriberNewsTitle}>Château Lagrange</Typography>
                                    <Typography variant="body1" className={styles.subscriberNewsSubTitle}>Le vignoble en Méhari (nouvelle offre œnotouristique)</Typography>
                                </Box>
                                
                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    Partez en immersion à la découverte du vignoble de ce 3e Grand Cru Classé à bord d&apos;une Méhari, voiture iconique réinterprétée en modèle 100 % électrique. Parcourez le domaine afin d&apos;en apprendre davantage sur la richesse de leur terroir, ainsi que sur les diverses actions menées pour favoriser la biodiversité au sein de la propriété. En partant des plus vieilles vignes, jusqu&apos;au point culminant de l&apos;appellation, en passant par les parcelles enherbées et garennes éco-pâturées : le vignoble du Château Lagrange n&apos;aura plus de secrets pour vous. Après cette visite immersive, vous découvrirez également les chais et l&apos;histoire du domaine, et vous terminerez par une dégustation de trois vins de la propriété.
                                </Typography>
    
                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>Pour plus d&apos;informations : <a href="https://chateau-lagrange.com/visites-et-degustations/">Château Lagrange</a></Typography>
                            </Box>

                        </Box>
                    
                    </Box>

                    <hr className={styles.subscriberSeparator} />

                    <Box>
                        <Box className={styles.subscriberNewsHeader}>
                            <Typography variant="h3" className={styles.subscriberNewsTitle}><Link to={ history.addLocale("/2UYR8K-chateau-olivier") } style={{ color: theme.palette.primary.main }}>Château Olivier</Link></Typography>
                            <Typography variant="body1" className={styles.subscriberNewsSubTitle}>Nouvel itinéraire œnotouristique avec la Bulle verte</Typography>
                        </Box>
                    
                        <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                            L’équipe du Château Olivier a finalisé la conception d’un itinéraire de découverte à pied de la propriété à travers le concept de « Bulle Verte », pour découvrir l’histoire, l’architecture, la production viticole et la remarquable biodiversité patrimoine du château. Grâce à un QR Code, les visiteurs auront accès à un itinéraire géolocalisé, rythmé d&apos;interviews avec les propriétaires et des salariés du château, avec des descriptions audios et photos.
                        </Typography>
    
                        <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                            Pour plus d&apos;informations : <a href="https://www.chateau-olivier.com/visites-et-degustation/">Château Olivier</a>
                            <br/>
                            Pour plus d&apos;informations : <a href="https://www.la-bulle-verte.com/">La bulle verte</a>
                        </Typography>
                    </Box>

                    <hr className={styles.subscriberSeparator} />

                    <Box>
                        <Box className={styles.subscriberNewsHeader}>
                            <Typography variant="h3" className={styles.subscriberNewsTitle}>Château Lascombes</Typography>
                            <Typography variant="body1" className={styles.subscriberNewsSubTitle}>Arrivée du nouveau directeur et œnologue</Typography>
                        </Box>

                        <Box className={styles.subscriberNewsBody} style={{ height: "fit-content"}}>

                            <Box className={styles.subscriberNewsBodyContent}>
                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    M. Axel Heinz vient de signer avec le 2023 son tout premier millésime à Lascombes. 
                                    <br/>
                                    <br/>Né à Munich d’une mère originaire de Bordeaux, Axel Heinz a développé sa passion pour le vin dans cette région viticole qu’il adore. 
                                    <br/>Ayant étudié l’agronomie avec une spécialisation en viticulture et œnologie à Bordeaux, il fait ses premières expériences en vinification dans des domaines du Médoc et à Saint-Emilion. En 2005, il est œnologue, puis directeur d’Ornellaia et de Masseto en Toscane. Les familles Lawrence et Carlton McCoy Jr, nouveaux propriétaires du Château lui font totalement confiance dans les missions qui viennent de lui être confiées. 
                                </Typography>
                            </Box>

                            <Box className={styles.subscriberNewsBodyImgWrapper}>
                                <img src={ imgNewsHeinz } className={styles.subscriberNewsBodyImg} style={{ objectPosition: "0% 25%", height: "400px" }} />
                            </Box>

                        </Box>

                        <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                            Ils sont heureux d’avoir à la tête de Lascombes un grand spécialiste qui a propulsé Ornellaia et Masseto vers des sommets. Axel Heinz se réjouit d’avoir pour mission d’amener Château Lascombes au niveau des plus grands crus.
                            Après 18 ans passés en Toscane, il est heureux de rentrer à la maison et indique « Que tout sera mis en œuvre : viticulture et vinifications précises et respectueuses, recentrage sur le cœur du terroir de Lascombes, sélection draconienne et réduction du volume de grand vin, ainsi qu’un nouvel habillage reflétant le prestige du cru... »
                            <br/>Pour Axel Heinz, l’idée majeure désormais, « est d’exprimer le grand classicisme médocain tout en respectant l’identité du cru ».
                        </Typography>
    
                        <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                            Pour plus d&apos;informations : <a href="https://www.chateau-lascombes.com/">Château Lascombes</a>
                        </Typography>

                    </Box>

                    <hr className={styles.subscriberSeparator} />

                    <Box>
                        <Box className={styles.subscriberNewsHeader}>
                            <Typography variant="h3" className={styles.subscriberNewsTitle}><Link to={ history.addLocale("/3CHFKB-chateau-malartic-lagraviere") } style={{ color: theme.palette.primary.main }}>Château Malartic Lagravière</Link></Typography>
                            <Typography variant="body1" className={styles.subscriberNewsSubTitle}>Malartic à l’heure des JO !</Typography>
                        </Box>
                    
                        <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                            À l’occasion des JO de cet été, Malartic Lagravière vous propose une nouvelle formule de leur « Break Gourmand », avec un menu international « Les 5 Continents » , pour déguster des spécialités du monde entier, et 3 vins des Vignobles Malartic, rouge, blanc et un argentin de DiamAndes. Une pause épicurienne au cœur de leur vignoble, à tester dès maintenant et pendant tout l’été !
                        </Typography>
    
                        <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                            Pour plus d&apos;informations : <a href="https://www.malartic-lagraviere.com/visites-degustations/break-gourmand-a-malartic/">Château Malartic Lagravière</a>
                        </Typography>
                    </Box>

                    <hr className={styles.subscriberSeparator} />

                    <Box>
                        <Box className={styles.subscriberNewsHeader}>
                            <Typography variant="h3" className={styles.subscriberNewsTitle}><Link to={ history.addLocale("/ROQP5Z-chateau-la-rose-figeac") } style={{ color: theme.palette.primary.main }}>Château La Rose Figeac</Link></Typography>
                            <Typography variant="body1" className={styles.subscriberNewsSubTitle}>La petite « nouveauté »</Typography>
                        </Box>
                    
                        <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                            Pourquoi l&apos;arrivée des amphores à La Rose Figeac ?
                        </Typography>
                        
                        <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                            « Non pas pour la déco, bien que très jolies dans mon chai, j’avoue, et encore moins pour la mode… N&apos;oublions pas que ce contenant existe depuis 6 000 ans, on n&apos;a rien inventé ! La raison de ce choix est simple. Il y a 5 ans, pour une cause particulière, j&apos;ai produit « Le Bouquet de La Rose Figeac » pour laquelle je voulais encore plus de fruit que je n’en avais jusque-là. L&apos;amphore me paraissait l&apos;objet adapté, et je le confirme. Le résultat répond tout à fait à mes attentes si bien que j&apos;ai déjà hâte de vous raconter la suite, tellement j’en suis ravie ! » (Nathalie Despagne)
                        </Typography>
    
                        <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                            Pour plus d&apos;informations : <a href="https://www.larosefigeac.com/">Château La Rose Figeac</a>
                        </Typography>

                        <img src={ imgNewsFigeac } className={styles.subscriberNewsBodyImgFigeac} />
                    </Box>

                    <img src={ fioTwo } style={{ position: "absolute", left: "-300px", transform: "scale(-1.5, 1.5)", top: "10%", zIndex: "-1", width: "400px", opacity: "0.5" }}/>
                    <img src={ fioTwo } style={{ position: "absolute", right: "-300px", transform: "scale(1.5)", top: "30%", zIndex: "-1", width: "400px", opacity: "0.5" }}/>
                    <img src={ fioTwo } style={{ position: "absolute", left: "-300px", transform: "scale(-1.5, 1.5)", top: "50%", zIndex: "-1", width: "400px", opacity: "0.5" }}/>
                    <img src={ fioTwo } style={{ position: "absolute", right: "-300px", transform: "scale(1.5)", top: "70%", zIndex: "-1", width: "400px", opacity: "0.5" }}/>
                </Box>

                <hr ref={ ref => hashRefs.current["innovation"] = ref } className={ styles.subscriberSeparator } />

                {/* L&apos;innovation */}
                <Box  className={ styles.feretLetterBody } style={{ margin: "30px auto 0 auto" }}>

                    <Box className={ styles.feretLetterSubHeader } style={{ margin: "0 0 40px 0" }} >
                        <Box className={ styles.feretLetterSubHeaderContent }>
                            <Typography variant="h2" className={ styles.feretLetterSubHeaderContentTitle } style={{ margin: "0 0 1em 0", display: "inline-block", flexDirection: "row", alignContent: "center", gap: "0.5em" }}>L&apos;innovation du mois avec <img width="100" src={imgLogoInnovin} alt="" style={{ display: "inline-block", verticalAlign:"center", margin: "0 0 0 0.1em", height: "100%" }}/> </Typography>
                            <Typography variant="body1" className={ styles.feretLetterTextContent }>
                                En partenariat avec le cluster néo-aquitain Inno&apos;vin, nous vous proposons de nous pencher sur une innovation ou initiative d&apos;un acteur de l&apos;écosystème vitivinicole régional.
                            </Typography>
                        </Box>
                        <Box>
                            <img src={ imgInnovin } className={ styles.feretLetterSubHeaderImg } style={{ width: "100%" }} onDragStart={ (e) => { e.preventDefault(); } } />
                        </Box>

                    </Box>

                    <Typography variant="h3" style={{ color: theme.palette.primary.main }}>Une start-up bordelaise pour regénérer les sols</Typography>

                    <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                        Les micro-organismes du sol sont essentiels pour les services écosystémiques du sol. Dans les vignobles, les écosystèmes fragiles sont affectés par le manque de rotation des cultures, le labour du sol et l&apos;utilisation de produits chimiques. <a href="https://innovin.fr/adherents/starfish-bioscience/">StarFish BioScience</a>, une jeune start-up de Bordeaux, vise à restaurer les écosystèmes microbiens des sols viticoles en développant des produits pour rétablir les bactéries clés du sol. La technologie brevetée de l&apos;entreprise repose sur l&apos;analyse de l&apos;ADN des bactéries du sol pour modéliser le fonctionnement de l&apos;écosystème en fonction des caractéristiques du terrain viticole. Actuellement, l&apos;entreprise se concentre sur la cartographie des bactéries des vignobles, fournissant des données utiles aux viticulteurs pour évaluer l&apos;impact de leurs pratiques sur la santé microbiologique des sols. À terme, l&apos;entreprise prévoit de développer des produits à base de bactéries clés du sol pour améliorer le fonctionnement des écosystèmes bactériens et renforcer la résilience des cultures face aux défis climatiques et environnementaux.
                    </Typography>

                    <Box className={ styles.boxComment }>
                        <Typography variant="h3" style={{  color: "#632D3E", margin: "0 0 1em 0" }}>Qui est StarFish BioScience ?</Typography>

                        <Typography paragraph style={{  color: "#632D3E", fontSize: "18px" }}>
                            StarFish BioScience est une jeune société bordelaise créée fin 2023 et dirigée par sa fondatrice, Sandrine Claus, experte des microbiotes. La société a pour ambition de développer des produits innovants dérivés de bactéries clés de voûte des sols afin de réparer les écosystèmes microbiens endommagés par les pratiques agricoles afin de renforcer le fonctionnement naturel de ces écosystèmes. Ainsi, l&apos;objectif de la société est d’agir pour optimiser le fonctionnement de l’écosystème microbien du sol pour maximiser sa performance.
                        </Typography>

                        <Typography paragraph style={{  color: "#632D3E", fontSize: "18px" }}>
                            StarFish BioScience est soutenue par l&apos;incubateur régional néo-aquitain UNITEC et par l&apos;incubateur Start-Up Win spécialisé WineTech du groupe Bernard Magrez. Pour financer ses opérations, la jeune société a levé à ce jour <span style={{ whiteSpace: "nowrap" }}>900 000 euros</span> auprès de Seventure Partners, un fonds de capital-investissement spécialisé dans les start-ups des sciences de la vie. L’équipe est structurée autour de l’expertise de l’analyse des microbiotes avec un docteur en bioinformatique et un ingénieur agronome qui vient de rejoindre l’équipe en tant que directeur du développement.
                        </Typography>

                    </Box>

                    <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                        Pour retrouver l’article complet, c’est par ici : <a href="https://innovin.fr/linnovation-au-service-du-microbiote-starfish/">https://innovin.fr/linnovation-au-service-du-microbiote-starfish/</a>
                    </Typography>

                    <img src={ fioTwo } style={{ position: "absolute", left: "-300px", transform: "scale(-1.5, 1.5)", top: "10%", zIndex: "-1", width: "400px", opacity: "0.5" }}/>
                    <img src={ fioTwo } style={{ position: "absolute", right: "-300px", transform: "scale(1.5)", top: "30%", zIndex: "-1", width: "400px", opacity: "0.5" }}/>
                    <img src={ fioTwo } style={{ position: "absolute", left: "-300px", transform: "scale(-1.5, 1.5)", top: "50%", zIndex: "-1", width: "400px", opacity: "0.5" }}/>
                    <img src={ fioTwo } style={{ position: "absolute", right: "-300px", transform: "scale(1.5)", top: "70%", zIndex: "-1", width: "400px", opacity: "0.5" }}/>
                </Box>

                <Box style={{ padding: "20px 0 60px 0", display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "center", gap: "1.5em"}}>
                    <Button onClick={ () => { download(); } } color="primary" variant="contained" size="large" style={{ fontFamily: "Playfair Display" }}>Télécharger</Button>
                    <Button onClick={ () => { redirection(); } }  color="secondary" variant="contained" size="large" style={{ fontFamily: "Playfair Display" }}>S&apos;abonner</Button>
                </Box>

            </Box>

        </>
    );
};

export default FeretLetterPage;