import { string } from "prop-types";

import Facebook from "../../assets/facebook";
import Instagram from "../../assets/instagram";
import Linkedin from "../../assets/linkedin";
import Youtube from "../../assets/youtube";
import Twitter from "../../assets/twitter";
import Web from "../../assets/web";
import Pinterest from "../../assets/pinterest";

export const PlatformIcons = ({ platform }) => {
    const socialMediaIcons = {
        facebook: <Facebook />,
        instagram: <Instagram />,
        linkedin: <Linkedin />,
        youtube: <Youtube />,
        twitter: <Twitter />,
        siteweb: <Web />,
        pinterest: <Pinterest />,
    };

    return socialMediaIcons?.[platform] ?? null;
};

PlatformIcons.propTypes = {
    platform: string.isRequired,
};
