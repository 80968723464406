import { t, Trans } from "@lingui/macro";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import basic from "assets/appearsInBESV/basic.svg";
import essential from "assets/appearsInBESV/essential.svg";
import initial from "assets/appearsInBESV/initial.svg";
import premium from "assets/appearsInBESV/premium.svg";
import SubscriptionCard from "Components/FrontBESV/SubscriptionCard";
import Helmet from "Components/Helmet/Helmet";
import { useRef, useState } from "react";
import { allPrices } from "SubscriptionTunnel/constants";
import SwiperCore, { Pagination } from "swiper";

import arrow from "../assets/appearsInBESV/arrow.svg";
import bg from "../assets/appearsInBESV/besv_background.svg";
import bottomRightFio from "../assets/appearsInBESV/bottom_right_fio.svg";
import fioritureLeft from "../assets/appearsInBESV/fioriture_left.svg";
import illustration from "../assets/appearsInBESV/illustration.png";
import leftFio from "../assets/appearsInBESV/left_fio.svg";
import topRightFio from "../assets/appearsInBESV/top_right_fio.svg";
import wavyBkg from "../assets/appearsInBESV/wavy_background.svg";
import wavyBkgResponsive from "../assets/appearsInBESV/wavy_background_responsive.svg";
import Arrow from "../Components/Arrow/Arrow";
import DetailsSubscription from "../Components/FrontBESV/DetailsSubscription";
import SubscriptionsType from "../Components/FrontBESV/SubscriptionsType";
import ControlledSwitch from "../Components/Switch/ControlledSwitch";
import FrontContentTop from "../Front/FrontContentTop";
import FrontMainContainer from "../Front/FrontMainContainer";
import FrontTitle from "../FrontRanking/FrontTitle";
import theme from "../globalTheme";
import { useWindowDimensions } from "../helpers/helpers";

import SmallDivider from "Components/Divider/SmallDivider";
import KeyFeatures from "Components/FrontBESV/KeyFeatures";
import SubscriptionOptions from "Components/FrontBESV/SubscriptionOptions";
import PlusChip from "Components/FrontBESV/PlusChip";
import { STARTUP_HT } from "Dashboard/Pages/ServiceDashboard/constants";
import FreeTrialBanner from "Dashboard/Components/RestrictionSubscription/freeTrial/FreeTrialBanner";
import { Link } from "react-router-dom";
import { useCustomHistory } from "hooks";
SwiperCore.use([Pagination]);

const useStyle = makeStyles(() => ({
    fioriture_left: {
        display: "none",
        [theme.breakpoints.up("xl")]: {
            display: "initial",
            position: "absolute",
            left: 0,
        },
    },
    fioriture_top_right: {
        display: "none",
        [theme.breakpoints.up("xl")]: {
            display: "initial",
            position: "absolute",
            right: 0,
            top: 0,
        },
    },
    fioriture_bottom_right: {
        display: "none",
        [theme.breakpoints.up("xl")]: {
            display: "initial",
            position: "absolute",
            right: 0,
            bottom: 0,
        },
    },
    front_subtitle: {
        textTransform: "uppercase",
        fontFamily: "Lato",
        [theme.breakpoints.up("lg")]: {
            marginBottom: "4rem",
        },
        [theme.breakpoints.up("xl")]: {
            marginBottom: 0,
        },
    },
    illustration_container: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.up("xs")]: {
            marginTop: "-65px",
        },
        [theme.breakpoints.up("sm")]: {
            marginTop: "-100px",
        },
        [theme.breakpoints.up("md")]: {
            marginTop: "-100px",
        },
        [theme.breakpoints.up("lg")]: {
            marginTop: "-140px",
        },
        [theme.breakpoints.up("xl")]: {
            marginTop: "-136px",
        },
    },
    illustration: {
        [theme.breakpoints.up("xs")]: {
            width: "211px",
        },
        [theme.breakpoints.up("sm")]: {
            width: "236px",
        },
        [theme.breakpoints.up("md")]: {
            width: "338px",
        },
        [theme.breakpoints.up("lg")]: {
            width: "394px",
        },
        [theme.breakpoints.up("xl")]: {
            width: "466px",
        },
    },
    container_card: {
        gridTemplateColumns: "repeat(12, 1fr)",
        display: "grid",
        gap: "1rem",
        position: "relative",
    },
    title: {
        gridColumn: "1 /span 12",
        textAlign: "center",
        color: theme.palette.primary.dark,
    },
    subtitle: {
        gridColumn: "1 /span 12",
        textAlign: "center",
        color: theme.palette.primary.dark,
    },
    divider: {
        marginTop: "1rem",
        marginBottom: "1rem",
    },
    wave_content: {
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        borderRadius: 20,
        color: theme.palette.primary.dark,
        [theme.breakpoints.up("xs")]: {
            margin: "-14px",
            padding: "14px",
            backgroundImage: `url(${wavyBkgResponsive})`,
        },
        [theme.breakpoints.up("sm")]: {
            margin: "-40px -14px",
            padding: "40px 14px 14px",
            backgroundImage: `url(${wavyBkg})`,
        },
        [theme.breakpoints.up("md")]: {
            margin: "-40px -34px",
            padding: "40px 34px",
        },
        [theme.breakpoints.up("lg")]: {
            margin: "-34px -40px",
            padding: "34px 40px",
        },
        [theme.breakpoints.up("xl")]: {
            margin: "-1rem -90px -80px -90px",
            padding: "80px 90px 16px",
        },
    },
    key_4: {
        fontWeight: 400,
        color: theme.palette.wine.main,
        [theme.breakpoints.up("xs")]: {
            fontSize: "73px",
            opacity: "0.3",
        },
        [theme.breakpoints.up("sm")]: {
            fontSize: "80px",
            opacity: "1",
            position: "inherit",
            top: "unset",
            left: "unset",
        },
        [theme.breakpoints.up("md")]: {
            fontSize: "61",
        },
        [theme.breakpoints.up("lg")]: {
            fontSize: "92px",
        },
        [theme.breakpoints.up("xl")]: {
            fontSize: "92px",
        },
    },
    key_4_sentence: {
        fontWeight: 700,
        textAlign: "center",
        [theme.breakpoints.down("xs")]: {
            marginTop: "2rem",
            marginBottom: "0.5rem",
        },
    },
    keys_step: {
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
        justifyContent: "center",

        [theme.breakpoints.up("sm")]: {
            gap: "1rem",
        },
    },
    show_more_container: {
        marginTop: "1rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: theme.palette.primary.dark,
        [theme.breakpoints.up("xs")]: {
            flexDirection: "row",
            gap: "1rem",
            textAlign: "center",
        },
        [theme.breakpoints.up("sm")]: {},
        [theme.breakpoints.up("md")]: {
            gap: "0.5rem",
            flexDirection: "column",
        },
        [theme.breakpoints.up("lg")]: {
            gap: "0.3rem",
        },
        [theme.breakpoints.up("xl")]: {
            gap: "1rem",
        },
    },
    content_arrow: {
        gridColumn: "1 /span 12",
        display: "flex",
        justifyContent: "center",
    },
    background_fioriture: {
        display: "none",
        [theme.breakpoints.up("xl")]: {
            display: "inherit",
            position: "absolute",
            top: 0,
        },
    },
    second_container: {
        justifyContent: "center",
        paddingLeft: "16px",
        paddingRight: "16px",

        [theme.breakpoints.up("xl")]: {
            width: "1400px",
            margin: "auto",
        },
    },
    second_content: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        [theme.breakpoints.down("md")]: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
    },
    subscription_title: {
        color: theme.palette.primary.dark,
        [theme.breakpoints.up("xs")]: {
            gridColumn: " 1 / span 12",
            textAlign: "center",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "3 / span 8",
            textAlign: "left",
        },
        [theme.breakpoints.up("lg")]: {
            margin: "2rem 0",
            gridColumn: "2 / span 8",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "1 / span 11",
        },
    },
    switch_content: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: " 4 / span 7",
        },
        [theme.breakpoints.up("md")]: {
            marginTop: "1rem",
            gridColumn: "5 / span 5",
        },
        [theme.breakpoints.up("lg")]: {
            marginTop: "0",
            gridColumn: "11",
            display: "flex",
            alignItems: "center",
        },
        [theme.breakpoints.up("xl")]: {
            marginTop: "2rem",
            gridColumn: "12",
        },
    },
    carousel: {
        gridColumn: "1 / span 12",
    },
    carouselDotGroup: (props) => ({
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        "& > button": {
            display: props.images?.length < 2 ? "none" : "flex",
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "50px",
            width: "9px",
            height: "9px",
            padding: 0,
            border: "none",
            marginRight: theme.spacing(1),
        },
        "& > button:disabled": {
            backgroundColor: theme.palette.primary.light,
            borderRadius: "50px",
            width: "9px",
            height: "9px",
            padding: 0,
        },
    }),
    container_swiper: {
        [theme.breakpoints.up("md")]: {
            "& .swiper-container": {
                overflow: "visible",
            },
        },
    },
    cell: {
        ...theme.typography.h5,
        [theme.breakpoints.up("sm")]: {
            width: "250px",
        },
        [theme.breakpoints.up("md")]: {
            width: "236px",
        },
        [theme.breakpoints.up("xl")]: {
            width: "350px",
        },
        color: theme.palette.primary.dark,
        textAlign: "center ",
        padding: "0.5rem 0",
        borderBottom: `1px solid ${theme.palette.secondary.dark}`,
    },
    content_switch_mobile: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    quote_saving: {
        fontFamily: "Mansalva",
        color: theme.palette.wine.main,
    },
}));

const AppearsInBESV = () => {
    const styles = useStyle();
    const history = useCustomHistory();
    const [switchCheck, setSwitchCheck] = useState(true);
    const { width } = useWindowDimensions();
    const showMoreRef = useRef(null);

    //MOBILE
    const subscription = [
        {
            type: "basic",
            monthly: { ht: t`Gratuit`, annualHt: "", discount: null },
            annual: { ht: t`Gratuit`, discount: null },
            description: (
                <Trans>
                    Offrez-vous gratuitement une présence simple sur <i>Bordeaux et ses Vins</i> en ligne.
                </Trans>
            ),
            included: [
                t`Présentation limitée de la propriété`,
                t`Accès à la certification : le Badge Féret`,
                <Trans key={""}>
                    Parution dans <i>Bordeaux et ses Vins</i> papier ligne seulement
                </Trans>,
            ],
            options: [t`Reportage photo (650 €)`, t`Packshot bouteille (30 €/btl)`],
            excluded: [
                t`Vins et millésimes`,
                t`Accès à l’historique Féret de la propriété`,
                t`QR Code déclaration nutritionnelle`,
                t`Activités œnotouristiques`,
                t`Équipe`,
                t`Intégration des médailles et des notes`,
                t`Création, gestion diffusion des fiches techniques`,
                t`Traduction`,
                t`Accompagnement rédactionnel`,
                t`Dégustation à la demande`,
                t`Démarrage clé en main`,
            ],
        },
        {
            type: "initial",
            monthly: {
                ht: allPrices.initial.htMonthly,
                tva: 20,
                annualHt: allPrices.initial.htTotalMonthly,
                discount: null,
            },
            annual: {
                ht: allPrices.initial.htNumber,
                tva: 20,
                discount: null,
            },
            description: <Trans>L’indispensable pour profiter de la notoriété bicentenaire de Féret.</Trans>,
            included: [
                t`Présentation de la propriété`,
                <>
                    <Trans>Présentation 2 personnes</Trans>
                    <PlusChip style={{ marginLeft: "4px" }} />
                </>,
                <>
                    <Trans>Présentation de 4 vins</Trans>
                    <PlusChip style={{ marginLeft: "4px" }} />
                </>,
                <>
                    <Trans>Présentation de 12 millésimes</Trans>
                    <PlusChip style={{ marginLeft: "4px" }} />
                </>,
                <>
                    <Trans>Présentation de 3 activités œnotouristiques</Trans>
                    <PlusChip style={{ marginLeft: "4px" }} />
                </>,

                t`Mise à disposition de fichiers normés avec vos informations pour envoyer à vos distributeurs`,
                t`Accès à la certification : le Badge Féret`,
                t`Accès à l’historique Féret de la propriété`,
                t`QR Code déclaration nutritionnelle`,
                t`Traduction en 8 langues`,
            ],
            options: [
                t`Traduction supplémentaire`,
                <Box key="">
                    <Trans>
                        Parution dans <i>Bordeaux et ses Vins</i> papier{" "}
                        <span style={{ fontSize: "10px", fontWeight: 900 }}>- 20 %</span>
                    </Trans>
                </Box>,
                t`Reportage photo (450 €)`,
                t`Packshot bouteille (25 €/btl)`,
                t`Démarrage clé en main (${STARTUP_HT} €)`,
                t`Accompagnement rédactionnel (500 €)`,
                t`Accès à la page propriété via une url personnalisée (ex: chateau-quinconces.feret.com) (40 €/mois)`,
            ],
            excluded: [
                <>
                    <Trans>Déclaration de notes</Trans>
                    <PlusChip style={{ marginLeft: "4px" }} />
                </>,
                <>
                    <Trans>Dégustation à la demande</Trans>
                    <PlusChip style={{ marginLeft: "4px" }} />
                </>,
                <>
                    <Trans>Intégration automatique des médailles</Trans>
                    <PlusChip style={{ marginLeft: "4px" }} />
                </>,
                t`Création, gestion diffusion des fiches techniques`,
                t`Intégration automatique des notes`,
            ],
        },
        {
            type: "essential",
            monthly: {
                ht: allPrices.essential.htMonthly,
                tva: 20,
                annualHt: allPrices.essential.htTotalMonthly,
                discount: null,
            },
            annual: {
                ht: allPrices.essential.htNumber,
                tva: 20,
                discount: null,
            },
            description: t`La version allégée pour présenter votre propriété, gérer et diffuser vos fiches techniques efficacement.`,
            included: [
                t`Présentation de la propriété`,
                <>
                    <Trans>Présentation 2 personnes</Trans>
                    <PlusChip style={{ marginLeft: "4px" }} />
                </>,
                <>
                    <Trans>Présentation de 4 vins </Trans>
                    <PlusChip style={{ marginLeft: "4px" }} />
                </>,
                <>
                    <Trans>Présentation de 12 millésimes</Trans>
                    <PlusChip style={{ marginLeft: "4px" }} />
                </>,
                <>
                    <Trans>Présentation de 3 activités œnotouristiques</Trans>
                    <PlusChip style={{ marginLeft: "4px" }} />
                </>,

                t`Intégration automatique des médailles`,
                t`Accès à la certification : le Badge Féret`,
                t`Accès à l’historique Féret de la propriété`,
                t`Déclaration de notes`,
                t`QR Code déclaration nutritionnelle`,
                t`Trois modèles de fiches techniques`,
                t`Éditeur de fiches techniques limité`,
                <>
                    <Trans key={""}>
                        Envoi des fiches techniques via <i>Bordeaux et ses Vins</i> : 250 contacts max.
                    </Trans>
                    <PlusChip style={{ marginLeft: "4px" }} />
                </>,
                t`Téléchargement des fiches techniques PDF`,
                // `Partage des fiches techniques sur les réseaux sociaux`,
                t`Alimentation automatique des partenaires avec les fiches techniques`,
                t`Traduction en 8 langues`,
            ],
            options: [
                t`Traduction supplémentaire`,
                t`Dégustation à la demande (160€/dégus. )`,
                <Box key="">
                    <Trans>
                        Parution dans <i>Bordeaux et ses Vins</i> papier{" "}
                        <span style={{ fontSize: "10px", fontWeight: 900 }}>- 20 %</span>
                    </Trans>
                </Box>,
                t`Reportage photo (450 €)`,
                t`Packshot bouteille (25 €/btl)`,
                t`Démarrage clé en main (${STARTUP_HT} €)`,
                t`Accompagnement rédactionnel (500 €)`,
                t`Service studio fiche technique (250 €/modèle)`,
                t`Accès à la page propriété via une url personnalisée (ex: chateau-quinconces.feret.com) (40 €/mois)`,
                t`Diffusion des fiches techniques via des flux FTP (20 €/mois)`,
            ],
            excluded: [t`Intégration des notes`],
        },
        // {
        //     type: "premium",
        //     monthly: {
        //         ht: allPrices.premium.htMonthly,
        //         tva: 20,
        //         annualHt: allPrices.premium.htTotalMonthly,
        //         discount: null,
        //     },
        //     annual: {
        //         ht: allPrices.premium.htNumber,
        //         tva: 20,
        //         // discount: 1000,
        //         discount: null,
        //         discountText: t`OFFRE DE LANCEMENT - 50 %`,
        //     },
        //     description: t`La solution complète pour enrichir, certifier, diffuser et suivre vos informations dans le monde entier.`,
        //     included: [
        //         t`Présentation de la propriété`,
        //         t`Présentation équipe illimitée`,
        //         t`Présentation des vins illimitée`,
        //         t`Présentation des millésimes illimitée`,
        //         t`Présentation des activités œnotouristiques illimitée`,
        //         t`Intégration automatique des médailles`,
        //         t`Intégration automatique des notes*`,
        //         t`Accès à la certification : le Badge Féret`,
        //         t`Accès à l’historique Féret de la propriété`,
        //         t`Déclaration de notes`,
        //         t`QR Code déclaration nutritionnelle`,
        //         t`Tous les modèles de fiches techniques`,
        //         t`Éditeur de fiches techniques complet`,
        //         <Trans key={""}>
        //             Envoi des fiches techniques via <i>Bordeaux et ses Vins</i> : contacts illimités.
        //         </Trans>,
        //         t`Diffusion des fiches techniques via des flux FTP`,
        //         t`Téléchargement des fiches techniques PDF`,
        //         // `Partage des fiches techniques sur les réseaux sociaux`,
        //         t`Alimentation automatique des partenaires avec les fiches techniques`,
        //         t`Traduction en 8 langues`,
        //     ],
        //     options: [
        //         <Box key="">
        //             <Trans>
        //                 Traduction supplémentaire <span style={{ fontSize: "10px", fontWeight: 900 }}>- 20 %</span>
        //             </Trans>
        //         </Box>,
        //         t`Dégustation à la demande (160€/dégus. )`,
        //         <Box key="">
        //             <Trans>
        //                 Parution dans <i>Bordeaux et ses Vins</i> papier{" "}
        //                 <span style={{ fontSize: "10px", fontWeight: 900 }}>- 50 %</span>
        //             </Trans>
        //         </Box>,
        //         t`Reportage photo (450 €)`,
        //         t`Packshot bouteille (25 €/btl)`,
        //         t`Démarrage clé en main (${STARTUP_HT} €)`,
        //         t`Accompagnement rédactionnel (500 €)`,
        //         t`Service studio fiche technique (250 €/modèle)`,
        //         t`Accès à la page propriété via une url personnalisée (ex: chateau-quinconces.feret.com) (40 €/mois)`,
        //     ],
        // },
    ];

    const data = [
        {
            title: "",
            tooltip: "",
            cells: [
                {
                    title: (
                        <SubscriptionCard
                            icon={basic}
                            backgroundColor={theme.palette.background.card}
                            borderColor={theme.palette.primary.dark}
                            switch={switchCheck}
                            subscription={subscription?.[0]}
                        />
                    ),
                },
                {
                    title: (
                        <SubscriptionCard
                            icon={initial}
                            backgroundColor={theme.palette.success.light}
                            switch={switchCheck}
                            subscription={subscription[1]}
                        />
                    ),
                },
                {
                    title: (
                        <SubscriptionCard
                            icon={essential}
                            backgroundColor={theme.palette.primary.light}
                            switch={switchCheck}
                            subscription={subscription[2]}
                        />
                    ),
                },
                // {
                //     title: (
                //         <SubscriptionCard
                //             icon={premium}
                //             backgroundColor={theme.palette.primary.dark}
                //             switch={switchCheck}
                //             subscription={subscription[3]}
                //         />
                //     ),
                // },
            ],
        },
        {
            title: (
                <Trans>
                    Parution dans <i>Bordeaux et ses Vins</i> XX<sup>e</sup> édition (papier)
                </Trans>
            ),
            tooltip: "",
            cells: [
                {
                    title: (
                        <span style={{ fontWeight: "900" }} key=" ">
                            <Trans>Ligne seulement</Trans>
                        </span>
                    ),
                },
                {
                    title: (
                        <Typography variant="body2" style={{ fontWeight: "900" }}>
                            - 20 %
                        </Typography>
                    ),
                },
                {
                    title: (
                        <Typography variant="body2" style={{ fontWeight: "900" }}>
                            - 20 %
                        </Typography>
                    ),
                },
                {
                    title: (
                        <Typography variant="body2" style={{ fontWeight: "900" }}>
                            - 50 %
                        </Typography>
                    ),
                },
            ],
        },
        {
            title: t`Présentation de la propriété`,
            tooltip: "",
            cells: [
                {
                    title: (
                        <span style={{ fontWeight: "900" }}>
                            <Trans>Limitée</Trans>
                        </span>
                    ),
                },
                {
                    title: (
                        <span style={{ fontWeight: "900" }}>
                            <Trans>Complète</Trans>
                        </span>
                    ),
                },
                {
                    title: (
                        <span style={{ fontWeight: "900" }}>
                            <Trans>Complète</Trans>
                        </span>
                    ),
                },
                {
                    title: (
                        <span style={{ fontWeight: "900" }}>
                            <Trans>Complète</Trans>
                        </span>
                    ),
                },
            ],
        },
        {
            title: t`Présentation de l’équipe`,
            tooltip: "",
            cells: [
                {
                    title: (
                        <span style={{ fontWeight: "900" }}>
                            <Trans>propriétaire et exploitant</Trans>
                        </span>
                    ),
                },
                {
                    title: (
                        <span style={{ fontWeight: "900" }}>
                            <Trans>2 personnes</Trans>
                            <PlusChip style={{ marginLeft: "4px" }} />
                        </span>
                    ),
                },
                {
                    title: (
                        <span style={{ fontWeight: "900" }}>
                            <Trans>2 personnes</Trans>
                            <PlusChip style={{ marginLeft: "4px" }} />
                        </span>
                    ),
                },
                {
                    title: (
                        <span style={{ fontWeight: "900" }}>
                            <Trans>Illimitée</Trans>
                        </span>
                    ),
                },
            ],
        },
        {
            title: t`Présentation des vins`,
            tooltip: "",
            cells: [
                {
                    title: "false",
                },
                {
                    title: (
                        <span style={{ fontWeight: "900" }}>
                            <Trans>4 vins</Trans>
                            <PlusChip style={{ marginLeft: "4px" }} />
                        </span>
                    ),
                },
                {
                    title: (
                        <span style={{ fontWeight: "900" }}>
                            <Trans>4 vins</Trans>
                            <PlusChip style={{ marginLeft: "4px" }} />
                        </span>
                    ),
                },
                {
                    title: (
                        <span style={{ fontWeight: "900" }}>
                            <Trans>Illimitée</Trans>
                        </span>
                    ),
                },
            ],
        },
        {
            title: t`Présentation des millésimes`,
            tooltip: "",
            cells: [
                {
                    title: "false",
                },
                {
                    title: (
                        <span style={{ fontWeight: "900" }}>
                            <Trans>12 millésimes</Trans>
                            <PlusChip style={{ marginLeft: "4px" }} />
                        </span>
                    ),
                },
                {
                    title: (
                        <span style={{ fontWeight: "900" }}>
                            <Trans>12 millésimes</Trans>
                            <PlusChip style={{ marginLeft: "4px" }} />
                        </span>
                    ),
                },
                {
                    title: (
                        <span style={{ fontWeight: "900" }}>
                            <Trans>Illimitée</Trans>
                        </span>
                    ),
                },
            ],
        },
        {
            title: t`Présentation des activités œnotouristiques`,
            tooltip: "",
            cells: [
                { title: "false" },
                {
                    title: (
                        <span style={{ fontWeight: "900" }}>
                            <Trans>3 activités</Trans>
                            <PlusChip style={{ marginLeft: "4px" }} />
                        </span>
                    ),
                },
                {
                    title: (
                        <span style={{ fontWeight: "900" }}>
                            <Trans>3 activités</Trans>
                            <PlusChip style={{ marginLeft: "4px" }} />
                        </span>
                    ),
                },
                {
                    title: (
                        <span style={{ fontWeight: "900" }}>
                            <Trans>Illimitée</Trans>
                        </span>
                    ),
                },
            ],
        },
        {
            title: t`Intégration automatique des médailles`,
            tooltip: "",
            cells: [
                { title: "false" },
                {
                    title: (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            style={{ width: "fit-content", position: "relative", margin: "auto" }}
                        >
                            <CancelRoundedIcon style={{ color: theme.palette.secondary.main }} />
                            <PlusChip style={{ marginLeft: "4px", right: "-37px", position: "absolute" }} />
                        </Box>
                    ),
                },
                { title: "true" },
                { title: "true" },
            ],
        },
        {
            title: t`Intégration automatique des notes par Féret*`,
            tooltip: "",
            cells: [{ title: "false" }, { title: "false" }, { title: "false" }, { title: "true" }],
        },
        {
            title: t`Déclaration de notes`,
            tooltip: "",
            cells: [
                { title: "false" },
                {
                    title: (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            style={{ width: "fit-content", position: "relative", margin: "auto" }}
                        >
                            <CancelRoundedIcon style={{ color: theme.palette.secondary.main }} />
                            <PlusChip style={{ marginLeft: "4px", right: "-37px", position: "absolute" }} />
                        </Box>
                    ),
                },
                { title: "true" },
                { title: "true" },
            ],
        },
        {
            title: t`QR Code déclaration nutritionnelle`,
            tooltip: "",
            cells: [{ title: "false" }, { title: "true" }, { title: "true" }, { title: "true" }],
        },
        {
            title: t`Accès à l’historique Féret de la propriété`,
            tooltip: "",
            cells: [{ title: "false" }, { title: "true" }, { title: "true" }, { title: "true" }],
        },
        {
            title: t`Modèles disponibles pour la création des fiches techniques`,
            tooltip: "",
            cells: [
                { title: "false" },
                { title: "false" },
                {
                    title: (
                        <span style={{ fontWeight: "900" }}>
                            <Trans>3 modèles</Trans>
                        </span>
                    ),
                },
                {
                    title: (
                        <span style={{ fontWeight: "900" }}>
                            <Trans>Tous modèles</Trans>
                        </span>
                    ),
                },
            ],
        },
        {
            title: t`Accès à l’éditeur de fiches techniques Féret`,
            tooltip: "",
            cells: [
                { title: "false" },
                { title: "false" },
                {
                    title: (
                        <span style={{ fontWeight: "900" }}>
                            <Trans>Limité</Trans>
                        </span>
                    ),
                },
                {
                    title: (
                        <span style={{ fontWeight: "900" }}>
                            <Trans>Complet</Trans>
                        </span>
                    ),
                },
            ],
        },
        {
            title: (
                <Trans>
                    Envoi des fiches techniques via <span style={{ fontStyle: "italic" }}>Bordeaux et ses Vins</span>
                </Trans>
            ),
            tooltip: "",
            cells: [
                { title: "false" },
                { title: "false" },
                {
                    title: (
                        <span style={{ fontWeight: "900" }}>
                            <Trans>250 contacts max.</Trans>
                            <PlusChip style={{ marginLeft: "4px" }} />
                        </span>
                    ),
                },
                {
                    title: (
                        <span style={{ fontWeight: "900" }}>
                            <Trans>Illimité</Trans>
                        </span>
                    ),
                },
            ],
        },
        {
            title: t`Diffusion des fiches techniques via des flux FTP`,
            tooltip: "",
            cells: [
                { title: "false" },
                { title: "false" },
                {
                    title: (
                        <Trans>
                            <span style={{ fontWeight: "900" }}>Option</span> (20 €/mois)
                        </Trans>
                    ),
                },
                { title: "true" },
            ],
        },
        {
            title: t`Téléchargement des fiches techniques en PDF`,
            tooltip: "",
            cells: [{ title: "false" }, { title: "false" }, { title: "true" }, { title: "true" }],
        },
        // {
        //     title: "Partage des fiches techniques sur les réseaux sociaux",
        //     tooltip: "",
        //     cells: [{ title: "false" }, { title: "true" }, { title: "true" }],
        // },
        {
            title: (
                <Typography variant="h5">
                    <Trans>
                        Alimentation automatique des partenaires{" "}
                        <span style={{ fontSize: theme.typography.h6.fontSize, color: theme.palette.secondary.dark }}>
                            (sites e-commerces, négociants etc...)
                        </span>
                    </Trans>
                </Typography>
            ),
            tooltip: "",
            cells: [
                { title: "false" },
                {
                    title: (
                        <span style={{ fontWeight: "700" }}>
                            <Trans>Manuel</Trans>
                        </span>
                    ),
                },
                {
                    title: (
                        <span style={{ fontWeight: "900" }}>
                            <Trans>Automatique</Trans>
                        </span>
                    ),
                },
                {
                    title: (
                        <span style={{ fontWeight: "900" }}>
                            <Trans>Automatique</Trans>
                        </span>
                    ),
                },
            ],
        },
        {
            title: t`Accès à la certification : le Badge Féret`,
            tooltip: "",
            cells: [{ title: "true" }, { title: "true" }, { title: "true" }, { title: "true" }],
        },
        {
            title: t`Traduction en 8 langues`,
            tooltip: "",
            cells: [{ title: "false" }, { title: "true" }, { title: "true" }, { title: "true" }],
        },
        {
            title: t`Traduction supplémentaire`,
            tooltip: "",
            cells: [
                { title: "false" },
                {
                    title: (
                        <Trans>
                            <span style={{ fontWeight: "900" }}>Option (dès 0,16 €/mot)</span>
                        </Trans>
                    ),
                },
                {
                    title: (
                        <Trans>
                            <span style={{ fontWeight: "900" }}>Option (dès 0,16 €/mot)</span>
                        </Trans>
                    ),
                },
                {
                    title: (
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            height="6rem"
                        >
                            <span style={{ fontSize: "16px", fontWeight: 900 }}>- 20%</span>
                            <Trans>
                                <span style={{ fontWeight: "900", marginBottom: "24px" }}>Option (dès 0,13 €/mot)</span>
                            </Trans>
                        </Box>
                    ),
                },
            ],
        },
        {
            title: t`Dégustation à la demande`,
            tooltip: "",
            cells: [
                { title: "false" },
                {
                    title: (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            style={{ width: "fit-content", position: "relative", margin: "auto" }}
                        >
                            <CancelRoundedIcon style={{ color: theme.palette.secondary.main }} />
                            <PlusChip style={{ marginLeft: "4px", right: "-37px", position: "absolute" }} />
                        </Box>
                    ),
                },
                {
                    title: (
                        <Trans>
                            <span style={{ fontWeight: "900" }}>Option</span> (160€/dégus. )
                        </Trans>
                    ),
                },
                {
                    title: (
                        <Trans>
                            <span style={{ fontWeight: "900" }}>Option</span> (160€/dégus. )
                        </Trans>
                    ),
                },
            ],
        },
        {
            title: (
                <Typography variant="h5">
                    <Trans>
                        Accès à la page propriété via url personnalisée{" "}
                        <span style={{ fontSize: theme.typography.h6.fontSize, color: theme.palette.secondary.dark }}>
                            (ex : chateau-quinconces.feret.com)
                        </span>
                    </Trans>
                </Typography>
            ),
            tooltip: "",
            cells: [
                { title: "false" },
                {
                    title: (
                        <Trans>
                            <span style={{ fontWeight: "900" }}>Option</span> (40 €/mois)
                        </Trans>
                    ),
                },
                {
                    title: (
                        <Trans>
                            <span style={{ fontWeight: "900" }}>Option</span> (40 €/mois)
                        </Trans>
                    ),
                },
                {
                    title: (
                        <Trans>
                            <span style={{ fontWeight: "900" }}>Option</span> (40 €/mois)
                        </Trans>
                    ),
                },
            ],
        },
        {
            title: t`Accompagnement rédactionnel`,
            tooltip: "",
            cells: [
                {
                    title: "false",
                },
                {
                    title: (
                        <Trans>
                            <span style={{ fontWeight: "900" }}>Option</span> (dès 500 €)
                        </Trans>
                    ),
                },
                {
                    title: (
                        <Trans>
                            <span style={{ fontWeight: "900" }}>Option</span> (dès 500 €)
                        </Trans>
                    ),
                },
                {
                    title: (
                        <Trans>
                            <span style={{ fontWeight: "900" }}>Option</span> (dès 500 €)
                        </Trans>
                    ),
                },
            ],
        },
        {
            title: t`Reportage photo`,
            tooltip: "",
            cells: [
                {
                    title: (
                        <Trans>
                            <span style={{ fontWeight: "900" }}>Option</span> (650 €)
                        </Trans>
                    ),
                },
                {
                    title: (
                        <Trans>
                            <span style={{ fontWeight: "900" }}>Option</span> (450 €)
                        </Trans>
                    ),
                },
                {
                    title: (
                        <Trans>
                            <span style={{ fontWeight: "900" }}>Option</span> (450 €)
                        </Trans>
                    ),
                },
                {
                    title: (
                        <Trans>
                            <span style={{ fontWeight: "900" }}>Option</span> (450 €)
                        </Trans>
                    ),
                },
            ],
        },
        {
            title: t`Packshot bouteille`,
            tooltip: "",
            cells: [
                {
                    title: (
                        <Trans>
                            <span style={{ fontWeight: "900" }}>Option</span> (30 €/btl)
                        </Trans>
                    ),
                },
                {
                    title: (
                        <Trans>
                            <span style={{ fontWeight: "900" }}>Option</span> (25 €/btl)
                        </Trans>
                    ),
                },
                {
                    title: (
                        <Trans>
                            <span style={{ fontWeight: "900" }}>Option</span> (25 €/btl)
                        </Trans>
                    ),
                },
                {
                    title: (
                        <Trans>
                            <span style={{ fontWeight: "900" }}>Option</span> (25 €/btl)
                        </Trans>
                    ),
                },
            ],
        },
        {
            title: t`Démarrage clé en main`,
            tooltip: "",
            cells: [
                {
                    title: "false",
                },
                {
                    title: (
                        <Trans>
                            <span style={{ fontWeight: "900" }}>Option</span> ({STARTUP_HT} €)
                        </Trans>
                    ),
                },
                {
                    title: (
                        <Trans>
                            <span style={{ fontWeight: "900" }}>Option</span> ({STARTUP_HT} €)
                        </Trans>
                    ),
                },
                {
                    title: (
                        <Trans>
                            <span style={{ fontWeight: "900" }}>Option</span> ({STARTUP_HT} €)
                        </Trans>
                    ),
                },
            ],
        },
        {
            title: t`Service studio fiche technique`,
            tooltip: "",
            cells: [
                { title: "false" },
                { title: "false" },
                {
                    title: (
                        <Trans>
                            <span style={{ fontWeight: "900" }}>Option</span> (250 €/modèle)
                        </Trans>
                    ),
                },
                {
                    title: (
                        <Trans>
                            <span style={{ fontWeight: "900" }}>Option</span> (250 €/modèle)
                        </Trans>
                    ),
                },
            ],
        },
    ];

    return (
        <>
            <Helmet
                title={t`Figurer dans Bordeaux et ses Vins`}
                content="Découvrez comment figurer dans l'édition de référence Bordeaux et ses Vins par Féret, qui recense les producteurs, négociants et courtiers du vignoble bordelais. Informations pratiques, tarifs, témoignages... trouvez tout ce qu'il faut savoir pour faire partie de l'aventure sur notre site."
            />

            <FrontContentTop backgroundImage={bg}>
                <img src={leftFio} className={styles.fioriture_left}></img>
                <img src={topRightFio} className={styles.fioriture_top_right}></img>
                <img src={bottomRightFio} className={styles.fioriture_bottom_right}></img>

                <FrontTitle
                    title={
                        <>
                            <Typography variant="h1">
                                <Trans>
                                    Faites partie de l&apos;histoire Féret !
                                </Trans>
                            </Typography>
                            <Typography paragraph variant="body1" className={styles.front_subtitle}>
                                <br/>
                                <Trans>
                                    Figurer dans Bordeaux et ses vins
                                </Trans>
                            </Typography>
                        </>
                    }
                />
                <FrontMainContainer
                    boxStyle={{ marginTop: "3rem" }}
                    fioritureTopImg
                    fioritureStyle={{ backgroundImage: `url(${leftFio}),url(${topRightFio}), url(${bottomRightFio})` }}
                    styleCardContent
                    style={{ backgroundImage: "none" }}
                >
                    <Box className={styles.illustration_container}>
                        <img src={illustration} className={styles.illustration} />
                    </Box>
                    <Box className={styles.container_card}>
                        <Typography variant="h3" className={styles.title}>
                            <Trans>
                                Donnez un nouvel élan à votre activité grâce à <i>Bordeaux et ses Vins</i>
                            </Trans>
                        </Typography>
                        <Typography variant="h5" className={styles.subtitle}>
                            <Trans>
                                Profitez d’une <b>histoire bicentenaire</b> et <b>investissez</b> dans une{" "}
                                <b>
                                    présence en ligne solide, vous augmenterez votre visibilité, améliorerez votre
                                    notoriété, attirerez
                                </b>{" "}
                                de <b>nouveaux amateurs de vin curieux</b> et <b>fidéliserez vos clients existants.</b>
                            </Trans>
                        </Typography>
                    </Box>
                    <SmallDivider color={theme.palette.primary.light} className={styles.divider} />
                    <KeyFeatures showMoreRef={showMoreRef} />
                    <Box className={styles.wave_content}>
                        <Box className={styles.keys_step}>
                            <Trans>
                                <Typography variant="h4" className={styles.key_4}>
                                    3
                                </Typography>

                                <Typography variant="h5" className={styles.key_4_sentence}>
                                    abonnements pour profiter de <i>Bordeaux et ses Vins</i>
                                </Typography>
                            </Trans>
                        </Box>
                        <Typography variant="h5" style={{ textAlign: "center" }}>
                            <Trans>
                                Comment choisir la formule <b>la plus adaptée à votre besoin</b> ?
                            </Trans>
                        </Typography>
                        <Typography
                            variant="h5"
                            style={{ textAlign: "center", fontWeigt: 700, fontFamily: "Mansalva" }}
                        >
                            <Trans>
                                S&apos;abonner à la version digitale est indispensable pour figurer dans le guide Féret
                                papier.
                            </Trans>
                        </Typography>
                        <SubscriptionOptions/>
                        {/* <Box className={styles.show_more_container}>
                            <Typography
                                variant="body2"
                                style={{
                                    fontFamily: "Mansalva",
                                }}
                            >
                                {width < 960 ? (
                                    <Trans>
                                        Le choix n&apos;est pas encore évident ? <br />
                                        Retrouvez le détail des abonnements ci-dessous
                                    </Trans>
                                ) : (
                                    <Trans>
                                        Le choix n&apos;est pas encore évident ? Retrouvez le détail des abonnements
                                        ci-dessous
                                    </Trans>
                                )}
                            </Typography>
                            <img
                                src={arrow}
                                style={{ height: width < 960 && "52px", display: width < 600 && "none" }}
                            />
                        </Box> */}
                        <Box style={{  margin: "2.5em 0 1em 0", display: "flex", flexDirection: "row", alignContent: "space-around", alignItems: "center", justifyContent: "center", gap: "1em", width: "100%" }}>
                            <Link to={ history.addLocale('/bienvenue/inscription')}>
                                <Button variant="contained" color="primary" size="large"><Trans>S&apos;inscrire maintenant</Trans></Button>
                            </Link>
                            
                            <Link to={ history.addLocale('/contact') }>
                                <Button variant="contained" color="secondary" size="large"><Trans>Contactez-nous</Trans></Button>
                            </Link>
                        </Box>
                    </Box>
                </FrontMainContainer>
            </FrontContentTop>
            {/*  FIXME: Remove false to show pricing details, etc.. */}
            { false &&
                <Box style={{ position: "relative" }}>
                    <img className={styles.background_fioriture} src={fioritureLeft} />
                    <Box className={styles.second_container} ref={showMoreRef}>
                        {width < 1280 && <FreeTrialBanner />}

                        <Box className={styles.second_content}>
                            <Typography className={styles.subscription_title} variant="h2">
                                <Trans>
                                    Les abonnements <i>Bordeaux et ses Vins</i>
                                </Trans>
                            </Typography>
                            {width >= 600 && (
                                <Box className={styles.switch_content}>
                                    <ControlledSwitch
                                        switchCheck={switchCheck}
                                        handleSwitchChange={() => setSwitchCheck(!switchCheck)}
                                        leftLabel={
                                            <>
                                                <Typography variant="h5">
                                                    <Trans>Facturation annuelle</Trans>
                                                </Typography>

                                                <Arrow
                                                    rotation="105"
                                                    arrowPosition="start"
                                                    icon="magenta"
                                                    className={styles.content_arrow}
                                                    containerBox={{ opacity: switchCheck && "50%" }}
                                                >
                                                    <Typography variant="h6" className={styles.quote_saving}>
                                                        <Trans>Économisez jusqu&apos;à 14 % !</Trans>
                                                    </Typography>
                                                </Arrow>
                                            </>
                                        }
                                        rightLabel={
                                            <Typography variant="h5">
                                                <Trans>Facturation mensuelle</Trans>
                                            </Typography>
                                        }
                                        boxStyle={{ alignItems: "flex-start", marginTop: width >= 1280 ? 24 : 0 }}
                                    />
                                </Box>
                            )}
                        </Box>
                        {width >= 600 ? (
                            <SubscriptionsType
                                data={data}
                                cellClassName={styles.cell}
                                arrowScrollWidth={1400}
                                showFreeTrialBanner
                            />
                        ) : (
                            <>
                                <Box className={styles.switch_content}>
                                    <Typography
                                        variant="h6"
                                        style={{
                                            fontWeight: 700,
                                            textAlign: "center",
                                            color: theme.palette.primary.dark,
                                            marginTop: "1rem",
                                        }}
                                    >
                                        <Trans>Facturation</Trans>
                                    </Typography>
                                    <Box className={styles.content_switch_mobile}>
                                        <ControlledSwitch
                                            variant="h5"
                                            boxStyle={{ justifyContent: "center" }}
                                            switchCheck={switchCheck}
                                            handleSwitchChange={() => setSwitchCheck(!switchCheck)}
                                            leftLabel={
                                                <Typography variant="h5">
                                                    <Trans>annuelle</Trans>
                                                </Typography>
                                            }
                                            rightLabel={
                                                <Typography variant="h5">
                                                    <Trans>mensuelle</Trans>
                                                </Typography>
                                            }
                                        />
                                        <Typography variant="body2" className={styles.quote_saving}>
                                            <Trans>en facturation annuelle économisez jusqu’à 14 % !</Trans>
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box
                                    style={{
                                        color: theme.palette.primary.dark,
                                        display: "flex",
                                        justifyContent: "space-between",
                                        border: `1px solid ${theme.palette.secondary.dark}`,
                                        borderRadius: "4px",
                                        margin: "1rem",
                                        padding: "1px 12px",
                                    }}
                                >
                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                        <CheckCircleRoundedIcon
                                            fontSize="small"
                                            style={{ color: theme.palette.success.dark }}
                                        />
                                        <Typography variant="subtitle1">
                                            <Trans>INCLUS</Trans>
                                        </Typography>
                                    </Box>
                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                        <AddCircleOutlineIcon
                                            fontSize="small"
                                            style={{ color: theme.palette.warning.dark }}
                                        />
                                        <Typography variant="subtitle1">OPTIONS</Typography>
                                    </Box>
                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                        <CancelRoundedIcon
                                            fontSize="small"
                                            style={{ color: theme.palette.secondary.main }}
                                        />
                                        <Typography variant="subtitle1">
                                            <Trans>NON INCLUS</Trans>
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box className={styles.container_swiper}>
                                    {/* MOBILE */}
                                    <DetailsSubscription datas={subscription} switch={switchCheck} />
                                </Box>
                            </>
                        )}
                        {/* {props.currentLanguage === "fr" ? width >= 600 && <AskDemo /> : <Box style={{ height: "2rem" }} />} */}
                    </Box>
                </Box>
            }

        </>
    );
};

export default AppearsInBESV;
