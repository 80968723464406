/* eslint-disable no-unused-vars */
import React from "react";

import { makeStyles, Typography, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import theme from "../../../globalTheme";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        flexDirection: "column",
        // justifyContent: "space-between",
        // paddingRight: "10%",
        rowGap: "16px",
    },
    itemContainer: {
        display: "flex",
        flexDirection: "column",
        // justifyContent: "space-between",
        wordWrap: "break-all",
    },
    boxSiret: {
        gridColumn: "1 / span 5",

        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 5",
        },
    },
    boxEnterprise: {
        gridColumn: "auto / span 7",

        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 4",
        },
    },
    boxContact: {
        gridColumn: "1 / span 5",

        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 3",
        },
    },
    boxMail: {
        gridColumn: "auto / span 7",

        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 5",
        },
    },
    boxPhone: {
        gridColumn: "1 / span 5",

        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 4",
        },
    },
    boxAddress: {
        gridColumn: "auto / span 7",

        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 3",
        },
    },
    boxTVA: {
        gridColumn: "1 / span 5",

        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 4",
        },
    },
    secondContainer: {
        gridColumn: "auto / span 4",
        display: "flex",
        flexDirection: "column",
        marginBottom: theme.spacing(2),
        width: "170px",
        wordBreak: "break-all",
    },
    thirdContainer: {
        gridColumn: "auto / span 4",
        display: "flex",
        flexDirection: "column",
        marginBottom: theme.spacing(2),
        width: "220px",
        wordBreak: "break-all",

        // [theme.breakpoints.up("md")]: {
        //     width: "170px",
        // },
    },
    firstTypo: { color: theme.palette.secondary.contrastText },
    secondTypo: {
        fontSize: theme.typography.h6.fontSize,
        color: theme.palette.secondary.contrastText,
        fontWeight: "700",
    },
    borderCard: {
        border: `1px solid ${theme.palette.secondary.dark}`,
        borderRadius: "10px",
        padding: "20px 20px",
        paddingBottom: "20px",
        display: "flex",
        flexDirection: "column",
        marginTop: "1rem",
        // [theme.breakpoints.up("sm")]: {
        //     marginTop: "16px",
        // },
        // [theme.breakpoints.up("md")]: {
        //     marginTop: "32px",
        // },
    },
}));

const BillingInformationsBlock = (props) => {
    const styles = useStyles(props);
    if (!props.subscriptionState) {
        return null;
    }

    return (
        <Box className={styles.borderCard}>
            <Typography
                variant="h6"
                style={{
                    fontSize: "18px",
                    color: theme.palette.primary.dark,
                    fontWeight: "bold",
                    marginBottom: "20px",
                }}
            >
                Coordonnées de facturation
            </Typography>

            <Box className={styles.mainContainer}>
                {/* SIRET */}
                <Box className={[styles.boxSiret, styles.itemContainer].join(" ")}>
                    <Box>
                        <Typography variant="subtitle1" className={styles.firstTypo}>
                            Numéro de SIRET
                        </Typography>
                        <Typography variant="h6" className={styles.secondTypo}>
                            {props.subscriptionState?.subscriptionBillingSiret || null}
                        </Typography>
                    </Box>
                </Box>

                {/* COMPANY */}
                <Box className={[styles.boxEnterprise, styles.itemContainer].join(" ")}>
                    <Typography variant="subtitle1" className={styles.firstTypo}>
                        Entreprise facturée
                    </Typography>
                    <Typography variant="h6" className={styles.secondTypo}>
                        {props.subscriptionState?.subscriptionBillingStatut &&
                            `${props.subscriptionState?.subscriptionBillingStatut} `}
                        {props.subscriptionState?.subscriptionBillingCompanyName &&
                            props.subscriptionState?.subscriptionBillingCompanyName}
                    </Typography>
                </Box>

                {/* CONTACT */}
                <Box className={[styles.boxContact, styles.itemContainer].join(" ")}>
                    <Typography variant="subtitle1" className={styles.firstTypo}>
                        Contact
                    </Typography>
                    <Typography variant="h6" className={styles.secondTypo}>
                        {/* Title */}
                        {props.subscriptionState?.subscriptionContactTitle &&
                        props.subscriptionState?.subscriptionContactTitle !== ""
                            ? props.subscriptionState?.subscriptionContactTitle
                            : null}

                        {/* First name */}
                        {props.subscriptionState?.subscriptionContactFirstName &&
                        props.subscriptionState?.subscriptionFirstName !== ""
                            ? ` ${props.subscriptionState?.subscriptionContactFirstName}`
                            : null}

                        {/* Last name */}
                        {props.subscriptionState?.subscriptionContactLastName &&
                        props.subscriptionState?.subscriptionLastName !== ""
                            ? ` ${props.subscriptionState?.subscriptionContactLastName}`
                            : null}
                    </Typography>
                </Box>

                {/* MAIL ADDRESS */}
                <Box className={[styles.boxMail, styles.itemContainer].join(" ")}>
                    <Typography variant="subtitle1" className={styles.firstTypo}>
                        Adresse e-mail
                    </Typography>
                    <Typography variant="h6" className={styles.secondTypo}>
                        {props.subscriptionState?.subscriptionContactEmailAddress !== "" &&
                        props.subscriptionState?.subscriptionContactEmailAddress
                            ? props.subscriptionState?.subscriptionContactEmailAddress
                            : null}
                    </Typography>
                </Box>

                {/* PHONE NUMBER */}
                <Box className={[styles.boxPhone, styles.itemContainer].join(" ")}>
                    <Typography variant="subtitle1" className={styles.firstTypo}>
                        Téléphone
                    </Typography>
                    <Typography variant="h6" className={styles.secondTypo}>
                        {props.subscriptionState?.subscriptionContactPhoneNumber !== "" &&
                        props.subscriptionState?.subscriptionContactPhoneNumber
                            ? `${props.subscriptionState?.subscriptionContactPhoneNumber}`
                            : null}
                    </Typography>
                </Box>

                {/* ADDRESS */}
                <Box className={[styles.boxAddress, styles.itemContainer].join(" ")}>
                    <Typography variant="subtitle1" className={styles.firstTypo}>
                        Adresse
                    </Typography>
                    <Typography variant="h6" className={styles.secondTypo}>
                        {props.subscriptionState?.subscriptionBillingAddress || null}
                        {(props.subscriptionState?.subscriptionBillingExtraAddress &&
                            `, ${props.subscriptionState?.subscriptionBillingExtraAddress}`) ||
                            null}
                    </Typography>
                    <Typography variant="h6" className={styles.secondTypo}>
                        {props.subscriptionState?.subscriptionBillingZipCode || null}{" "}
                        {props.commune || props.subscriptionState?.subscriptionBillingCommune || null}
                    </Typography>
                </Box>

                <Box className={[styles.boxTVA, styles.itemContainer].join(" ")}>
                    {props.subscriptionState?.subscriptionBillingTVA && (
                        <>
                            <Typography variant="subtitle1" className={styles.firstTypo}>
                                Numéro de TVA intracommunautaire
                            </Typography>
                            <Typography variant="h6" className={styles.secondTypo}>
                                {props.subscriptionState?.subscriptionBillingTVA}
                            </Typography>
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

BillingInformationsBlock.propTypes = {
    subscriptionState: PropTypes.object,
    commune: PropTypes.string,
};

export default BillingInformationsBlock;
