import { makeStyles, Box, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { CarouselProvider, Slider, Slide, DotGroup } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { useWindowDimensions } from "../helpers/helpers";
import AddressBlock from "./AddressBlock";
import theme from "../globalTheme";
import Horaire from "../FrontOdg/Horaire";

const useStyle = makeStyles((theme) => ({
    carouselDotGroup: (props) => ({
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        "& > button": {
            display: props.images?.length < 2 ? "none" : "flex",
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "50px",
            width: "9px",
            height: "9px",
            padding: 0,
            border: "none",
            marginRight: theme.spacing(1),
        },
        "& > button:disabled": {
            backgroundColor: theme.palette.primary.light,
            borderRadius: "50px",
            width: "9px",
            height: "9px",
            padding: 0,
        },
    }),
    carousel: {
        display: "flex",
        flexDirection: "column",
        overflowY: "hidden",
        position: "relative",
    },
    carouselSlider: (props) => ({
        position: "block",
        [theme.breakpoints.up("xs")]: {
            height: props.height,
            "& .slideInner___2mfX9": {
                height: props.height,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "transparent",
                borderRadius: 10,
            },
            "& .horizontalSlider___281Ls": {
                height: props.height,
            },
        },
        "& .sliderTray___-vHFQ": {
            display: "flex",
            gap: "1rem",
        },
        [theme.breakpoints.up("sm")]: {
            height: props.height,
            "& .slideInner___2mfX9": {
                height: props.height,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "transparent",
                borderRadius: 10,
            },
            "& .horizontalSlider___281Ls": {
                height: props.height,
            },
        },
        [theme.breakpoints.only("md")]: {
            height: props.height,
            "& .slideInner___2mfX9": {
                height: props.height,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "transparent",
                borderRadius: 10,
            },
            "& .horizontalSlider___281Ls": {
                height: props.height,
            },
        },
        [theme.breakpoints.only("lg")]: {
            height: props.height,
            "& .slideInner___2mfX9": {
                height: props.height,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "transparent",
                borderRadius: 10,
            },
            "& .horizontalSlider___281Ls": {
                height: props.height,
            },
        },
        [theme.breakpoints.only("xl")]: {
            height: props.height,
            "& .slideInner___2mfX9": {
                height: props.height,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "transparent",
                borderRadius: 10,
            },
            "& .horizontalSlider___281Ls": {
                height: props.height,
            },
        },
    }),
    content_first_container: (props) => ({
        width: props.width,
        height: props.height - 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "100%",
        backgroundColor: "#D5D5D5",
    }),
    content_second_container: {
        display: "flex",
        width: "100%",
        height: "100%",
    },
    carousel_item_number: {
        fontFamily: "Playfair Display, Helvetica, Arial, sans-serif",
    },
}));

const ChallengeCarousel = (props) => {
    const styles = useStyle(props);
    const { width } = useWindowDimensions();
    const slides = props.medals ? [...props.medals] : [];

    if (props.medalTotal && props.medalMaxYear) {
        slides.unshift({
            bgColor: theme.palette.primary.light,
            fontColor: theme.palette.common.white,
            number: props.medalTotal,
            border: `1px solid ${theme.palette.primary.light}`,
        });
    }

    if (props.medalPercentage) {
        slides.unshift({
            bgColor: theme.palette.common.white,
            fontColor: theme.palette.primary.dark,
            number: props.medalPercentage + "% ",
            border: `1px solid ${theme.palette.primary.light}`,
        });
    }

    const setTotalSlides = (props) => {
        if (props.type === "keyFigures") {
            return props.medals?.length + (props.medalPercentage ? 1 : 0) + (props.medalTotal ? 1 : 0);
        } else if (props.type === "contacts") {
            return props.datas?.length;
        } else {
            return 2;
        }
    };

    return (
        <CarouselProvider
            totalSlides={setTotalSlides(props)}
            naturalSlideWidth={100}
            naturalSlideHeight={props.height}
            className={styles.carousel}
            infinite={false}
            dragEnabled={false}
            visibleSlides={props.visibleSlides}
        >
            <Slider className={styles.carouselSlider}>
                {props.type === "keyFigures" &&
                    slides?.map((el, i) => {
                        return (
                            <Slide key={i} index={i}>
                                <Box
                                    className={styles.content_first_container}
                                    style={{
                                        boxSizing: "borderBox",
                                        backgroundColor: el.bgColor ?? "transparent",
                                        boxShadow: !el.bgColor
                                            ? `inset 0 0 0 3px ${theme.palette.primary.light}`
                                            : "none",
                                        border: el.border ? el.border : "none",
                                    }}
                                >
                                    <Typography
                                        variant="h3"
                                        className={styles.carousel_item_number}
                                        style={{
                                            color: el.fontColor ?? theme.palette.primary.dark,
                                        }}
                                    >
                                        {el.number}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        style={{
                                            textAlign: "center",
                                            maxWidth: "85%",
                                            color: el.fontColor ?? theme.palette.primary.dark,
                                        }}
                                    >
                                        <span style={{ fontWeight: "bold", textTransform: "uppercase" }}>
                                            {el.name}{" "}
                                        </span>
                                        {el.year ? `en ${el.year}` : "de médaillés"}
                                    </Typography>
                                </Box>
                            </Slide>
                        );
                    })}

                {props.type === "contacts"
                    ? props.datas?.map((el, i) => {
                          return (
                              <Slide key={i} index={i}>
                                  <Box className={styles.content_second_container}>
                                      <AddressBlock data={el} />
                                  </Box>
                              </Slide>
                          );
                      })
                    : ""}

                {props.type === "horaires" && (
                    <>
                        <Slide index={0}>
                            <Box className={styles.content_second_container}>
                                <Box style={{ marginRight: "24px", width: "100%" }}>
                                    <Horaire
                                        horaires={props.datas.standard}
                                        title="De janvier à mai et d’octobre à janvier"
                                    />
                                </Box>
                            </Box>
                        </Slide>

                        <Slide index={0}>
                            <Box className={styles.content_second_container}>
                                <Box style={{ marginRight: "24px", width: "100%" }}>
                                    <Horaire horaires={props.datas.summer} title="De juin à fin septembre" />
                                </Box>
                            </Box>
                        </Slide>
                    </>
                )}
            </Slider>
            {width < 960 && props.type === "contacts" && setTotalSlides(props) > 1 && (
                <DotGroup className={styles.carouselDotGroup} />
            )}
            {props.type === "keyFigures" && <DotGroup className={styles.carouselDotGroup} />}
            {width <= 1165 && props.type === "horaires" && <DotGroup className={styles.carouselDotGroup} />}
        </CarouselProvider>
    );
};

ChallengeCarousel.propTypes = {
    medals: PropTypes.array,
    datas: PropTypes.array,
    responsiveHeight: PropTypes.array,
    visibleSlides: PropTypes.number,
    type: PropTypes.string,
    height: PropTypes.any,
    width: PropTypes.any,
    minDotVisible: PropTypes.number,
    medalPercentage: PropTypes.number,
    medalTotal: PropTypes.number,
    medalMaxYear: PropTypes.number,
};

export default ChallengeCarousel;
