import { useParams } from "react-router-dom";

import { useGetOdgSampleQuery } from "../generated/graphql";
import Loading from "../Components/Loader/Loading";
import { SwiperWrapper } from "../Front/SwiperCards";
import { t } from "@lingui/macro";
import OdgWineCard from "FrontOdg/OdgWineCard";
import globalTheme from "globalTheme";

export const OdgList = () => {
    const { name } = useParams();
    const { data: { GetOdgSample: odgList = [] } = {}, loading } = useGetOdgSampleQuery({
        variables: { name },
    });

    if (loading) {
        return <Loading />;
    }

    return (
        <div>
            <SwiperWrapper
                spaceBetween={0}
                title={t`Découvrez d’autres organismes de gestion`}
                noMargin
            >
                {odgList.map((e, i) => (
                    <OdgWineCard key={i} name={e.name} id={e._id} />
                ))}
            </SwiperWrapper>
        </div>
    );
};
