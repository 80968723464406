import PropTypes, { string } from "prop-types";
import { Box, Typography, makeStyles } from "@material-ui/core";
import theme from "../../globalTheme";
import { Trans } from "@lingui/macro";
import { useCustomHistory } from "hooks";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundRepeat: "no-repeat",
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.08)",
    },
    typo: {
        color: theme.palette.common.white,
        maxWidth: 212,
        textAlign: "center",
    },
    typo2: {
        color: theme.palette.common.white,
        maxWidth: 212,
        textAlign: "center",
        fontWeight: "bold",
    },
    link: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
        width: "100%",
        height: "100%",
        textAlign: "center",
    },
}));

const ButtonViewAllResults = (props) => {
    const styles = useStyles(props);
    const history = useCustomHistory();

    const test = () => {
        if (!props.noResult) {
            return props.seeAllResults;
        } else {
            if (props.label === "propriétés") {
                return (
                    <Trans>
                        <Typography variant="h3" className={styles.typo}>
                            Chercher <br />
                            <b>une propriété</b>
                        </Typography>
                    </Trans>
                );
            } else if (props.label === "vins") {
                return (
                    <Trans>
                        <Typography variant="h3" className={styles.typo}>
                            Chercher <br />
                            <b>un vin</b>
                        </Typography>
                    </Trans>
                );
            } else if (props.label === "activités") {
                return (
                    <Trans>
                        <Typography variant="h3" className={styles.typo}>
                            Chercher <br />
                            <b>une activité</b>
                        </Typography>
                    </Trans>
                );
            }
        }
    };

    return (
        <Box className={styles.root} style={props?.style}>
            <button
                className={styles.link}
                onClick={() =>
                    history.push({ pathname: props.url, search: JSON.stringify({ searchText: props?.searchText }) })
                }
            >
                {test()}
            </button>
        </Box>
    );
};

ButtonViewAllResults.propTypes = {
    url: PropTypes.string,
    searchText: PropTypes.string,
    label: PropTypes.string,
    style: PropTypes.string,
    noResult: PropTypes.bool,
    seeAllResults: string,
};

export default ButtonViewAllResults;
