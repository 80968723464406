import { Suspense, lazy, useState } from "react";
import { any, bool, func } from "prop-types";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import FrontNavbar from "Front/FrontNavbar";
import Invitation from "Invitation/Invitation";
import LandingPage from "Pages/LandingPage";
import SearchEngineHome from "Pages/SearchEngine/SearchEngineHome";
import SearchRouter from "Pages/SearchEngine/SearchRouter";
import FrontRoutes, { SEOLinks } from "Routes/FrontRoutes";

import { NavbarContext } from "./MainRouter";
import { PrivateRoute } from "./PrivateRoute";
import FrontFooter from "Front/footer/FrontFooter";
import { ALL_LANGUAGES } from "config/constants";
import Loading from "Components/Loader/Loading";
import BrowsingPreferenceModal from "Front/browsingPreference/BrowsingPreferenceModal";
import { useCustomHistory } from "hooks";
import { lazyRetry } from "Routes/lazyRetry";
import { RestrictedLanguageRoute } from "Routes/RestrictedLanguageRoute";

// Routes based
const ConnectedSubscriptionRouter = lazy(() =>
    lazyRetry(() => import("Routes/SubscriptionRouter/ConnectedSubscriptionRouter"), "ConnectedSubscriptionRouter")
);
const AwaitPaymentConfirmation = lazy(() =>
    lazyRetry(() => import("Pages/AwaitPaymentConfirmation"), "AwaitPaymentConfirmation")
);
const SubscriptionWaitingFree = lazy(() =>
    lazyRetry(() => import("SubscriptionTunnel/StatusAfterPaiement/SubscriptionWaitingFree"), "SubscriptionWaitingFree")
);
const SubscriptionWaitingPayment = lazy(() =>
    lazyRetry(
        () => import("SubscriptionTunnel/StatusAfterPaiement/SubscriptionWaitingPayment"),
        "SubscriptionWaitingPayment"
    )
);
const SubscriptionSuspended = lazy(() =>
    lazyRetry(() => import("SubscriptionTunnel/StatusAfterPaiement/SubscriptionSuspended"), "SubscriptionSuspended")
);

export const MainRoutes = ({
    welcomePopin,
    setWelcomePopin,
    openConnectionMenu,
    setOpenConnectionMenu,
    ftId,
    setFtId,
}) => {
    const { url } = useRouteMatch();
    const [warningBrowserLanguage, setWarningBrowserLanguage] = useState(false);
    const history = useCustomHistory();

    return (
        <Suspense fallback={<Loading />}>
            <BrowsingPreferenceModal
                registrationMode
                noEnlish
                open={warningBrowserLanguage}
                onClickBack={() => history.goBack()}
                onClose={() => setWarningBrowserLanguage(false)}
            />
            <Switch>
                {/* Home */}
                <RestrictedLanguageRoute
                    languages={ALL_LANGUAGES}
                    path={url}
                    exact
                >
                    <SEOLinks languages={ALL_LANGUAGES} />
                    <FrontNavbar />
                    <SearchEngineHome />
                    <FrontFooter />
                </RestrictedLanguageRoute>

                <Route path={`${url}/recherche`}>
                    <SearchRouter />
                </Route>

                <Route path={`${url}/bienvenue`}>
                    <LandingPage />
                </Route>

                {/* Landing pages */}
                <RestrictedLanguageRoute path={`${url}/invitation`} languages={["fr"]}>
                    <Invitation />
                </RestrictedLanguageRoute>

                {/* Subscription */}
                <Route path={`${url}/subscription`}>
                    <PrivateRoute>
                        <ConnectedSubscriptionRouter welcomePopin={welcomePopin} openWelcomePopin={setWelcomePopin} />
                    </PrivateRoute>
                </Route>

                <Route path={`${url}/paiement-en-cours`}>
                    <AwaitPaymentConfirmation />
                </Route>
                <Route path={`${url}/souscription-gratuite-en-attente`}>
                    <SubscriptionWaitingFree />
                </Route>
                <Route path={`${url}/souscription-en-attente`}>
                    <SubscriptionWaitingPayment />
                </Route>
                <Route path={`${url}/souscription-suspendu`}>
                    <SubscriptionSuspended />
                </Route>

                {/* Front */}
                <NavbarContext.Provider value={{ openConnectionMenu, setOpenConnectionMenu, ftId, setFtId }}>
                    <FrontRoutes />
                </NavbarContext.Provider>
            </Switch>
        </Suspense>
    );
};

MainRoutes.propTypes = {
    welcomePopin: bool,
    setWelcomePopin: func,
    openConnectionMenu: bool,
    setOpenConnectionMenu: func,
    ftId: any,
    setFtId: func,
};
