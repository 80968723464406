import { Trans } from "@lingui/macro";
import { Box, Link, makeStyles } from "@material-ui/core";
import { useCustomHistory } from "hooks";
import { string } from "prop-types";

const useStyles = makeStyles(() => ({
    button_see_more: {
        width: "281px",
        display: "flex",
        height: "158px",
        justifyContent: "center",
        alignItems: "center",
    },
    link: {
        cursor: "pointer",
        "&:hover": {
            fontWeight: "700",
        },
    },
}));
const MoreCommonalityButton = ({ pathname, filter, value }) => {
    const styles = useStyles();
    const history = useCustomHistory();

    return (
        <Box className={styles.button_see_more}>
            <Link
                className={styles.link}
                variant="body2"
                onClick={() => {
                    history.push({
                        pathname: pathname,
                        state: {
                            [filter]: value,
                        },
                    });
                }}
            >
                &#62; <Trans>Tout voir</Trans>
            </Link>
        </Box>
    );
};

MoreCommonalityButton.propTypes = {
    pathname: string,
    filter: string,
    value: string,
};

export default MoreCommonalityButton;
