import React, { useState } from "react";

import {
    makeStyles,
    TextField,
    InputAdornment,
    Typography,
    Divider,
    Box,
    Grid,
    MenuItem,
} from "@material-ui/core";
import PropTypes from "prop-types";
import CheckIcon from "@material-ui/icons/Check";
import Tag from "../Chips/Tag";
import { Controller } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
    customMenuPopover: {
        marginTop: "8px",
        "& .MuiMenu-paper": {
            maxHeight: "200px",
        },
        "& ::-webkit-scrollbar": {
            width: "8px",
        },
        "& ::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            paddingRight: "8px",
        },
        "& ::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "20px",
        },
    },
    menuItems: {
        fontSize: "14px",
        color: theme.palette.primary.dark,
        "&:hover": {
            backgroundColor: theme.palette.dashboard.background,
        },
        "&.MuiListItem-root.Mui-selected": {
            backgroundColor: theme.palette.dashboard.background,
        },
    },
    listSubheader: {
        fontSize: "12px",
        color: theme.palette.primary.light,
    },
    root: (props) => ({
        backgroundColor: "white",
        marginBottom: 0,
        "& .MuiFormHelperText-root.Mui-error": {
            margin: 0,
        },
        "& .MuiInput-underline:before": {
            borderBottom: "0px",
        },
        "& .MuiSelect-select.MuiSelect-select": {
            paddingRight: props.telephone && "18px",
            paddingTop: props.telephone && "10px",
        },
        "& .MuiSelect-select.MuiSelect-select:focus": {
            borderBottom: "0px",
        },
        "& .MuiInputBase-input": {
            color: theme.palette.primary.dark,
            backgroundColor: props.disabled
                ? theme.palette.dashboard.background
                : theme.palette.common.white,
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            border: props.telephone && "none",
            borderColor: props.telephone && "white",
        },
        "& .MuiMenu-paper": {
            maxHeight: "200px",
        },
        "& .MuiFormLabel-root": {
            color: props.error
                ? theme.palette.error.main
                : theme.palette.secondary.dark,
            fontSize: "14px",
            top: "-18px",
            paddingTop: theme.spacing(1),
            "&.Mui-hover": {
                borderWidth: "0.2px",
            },
        },
        "&.MuiFormControl-root": {
            width: "100%",
        },
        "& .MuiOutlinedInput-root": {
            paddingLeft: "10px",
            fontSize: "14px",
            minHeight: "34px",
            borderWidth: "0.2px",
            borderColor: theme.palette.secondary.dark,
            borderRadius: props.borderRadius ? props.borderRadius : "4px",

            "&:hover fieldset": {
                borderColor: props.error
                    ? theme.palette.error.main
                    : theme.palette.secondary.main,
            },
            "&.Mui-focused fieldset": {
                borderColor: props.error
                    ? theme.palette.error.main
                    : theme.palette.secondary.main,
            },

            "&.Mui-disabled": {
                color: theme.palette.secondary.dark,
                backgroundColor: theme.palette.action.disabledBackground,
                borderColor: theme.palette.secondary.dark,
                borderWidth: "0.2px",
            },
        },
        "& .MuiSelect-icon": {
            top: "calc(50% - 8px)",
            fontSize: "16px",
        },

        // TABLES
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            // borderRightColor: props.noBorderRight && "#11ffee00",
            borderRight: props.noBorderRight && "0",
            borderBottom: props.noBorderBottom && "0",
        },
        "& .MuiOutlinedInput-root.Mui-focused ": {
            borderRight: props.noBorderRight && "2px solid",
            borderRightColor:
                props.noBorderRight && theme.palette.secondary.dark,
            borderBottom: props.noBorderBottom && "2px solid",
            borderBottomColor:
                props.noBorderBottom && theme.palette.secondary.dark,
        },
        "& .MuiOutlinedInput-root.Mui-focused:hover ": {
            borderRight: props.noBorderRight && "2px solid",
            borderRightColor:
                props.noBorderRight && theme.palette.secondary.dark,
            borderBottom: props.noBorderBottom && "2px solid",
            borderBottomColor:
                props.noBorderBottom && theme.palette.secondary.dark,
        },
        "& .MuiOutlinedInput-root:hover": {
            borderRight: props.noBorderRight && "1px solid",
            borderRightColor:
                props.noBorderRight && theme.palette.secondary.main,
            borderBottom: props.noBorderBottom && "1px solid",
            borderBottomColor:
                props.noBorderBottom && theme.palette.secondary.main,
        },

        "& .MuiOutlinedInput-input": {
            paddingBottom: "0px",
            paddingTop: "0px",
            paddingLeft: "0px",
        },

        " & .MuiSelect-select": {
            "&:focus": {
                backgroundColor: theme.palette.common.white,
            },
        },
        "& .MuiOutlinedInput-adornedStart": {
            padding: "0px",
        },
        "& .MuiInputAdornment-positionEnd": {
            marginLeft: 0,
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: "translate(14px, 6px) scale(0.75)",
        },
        "& .MuiInputBase-input.Mui-disabled": {
            opacity: !props.telephone && "0.5",
        },
        boxContainer: { position: "relative", minWidth: "100%" },
    }),
    checkIcon: (props) => ({
        width: "20px",
        fill: "#9BBA97",
        marginRight: theme.spacing(2),
    }),
    mesure: (props) => ({
        color: theme.palette.secondary.dark,
        marginRight: props.select ? theme.spacing(2) : "0px",
    }),
    divider: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
}));

const getTags = (props) => {
    return (
        <Grid container spacing={1}>
            <Grid item>
                {props.notPublished && (
                    <Tag
                        color="secondary"
                        disabled={false}
                        label="Non publié"
                    />
                )}
            </Grid>
        </Grid>
    );
};

const OldSelectInput = (props) => {
    const styles = useStyles(props);
    const [focused, setFocused] = useState(false);

    const getEnumsShrink = () => {
        if (props.table) {
            if (focused) {
                return false;
            }
            if (props.value) {
                return false;
            }
        }
    };

    const getEnumsLabelDisplay = () => {
        if (props.table) {
            if (focused) {
                return "none";
            }
            if (props.value) {
                return "none";
            }
        }
    };
    return (
        <Box className={styles.boxContainer} style={{ ...props.boxStyle }}>
            <Box
                style={{ position: "absolute", right: 10, top: -10, zIndex: 2 }}
            >
                {" "}
                {getTags(props)}{" "}
            </Box>
            {props.control ? (
                <Controller
                    name={props.name}
                    control={props.control}
                    as={
                        <TextField
                            id={props.id}
                            label={props.disabledShrink ? "" : props.label}
                            placeholder={props.placeholder}
                            type="select"
                            select={true}
                            variant={props.variant}
                            size={props.size}
                            disabled={props.disabled}
                            required={props.required}
                            error={props.error}
                            helperText={props.helperText}
                            onBlur={(e) => setFocused(false)}
                            onFocus={() => {
                                if (!focused) {
                                    setFocused(true);
                                }
                            }}
                            value={props.value}
                            onChange={(e1, e2, e3) => {
                                props.onChange(e1, e2, e3);
                            }}
                            SelectProps={{
                                MenuProps: {
                                    PopoverClasses: {
                                        root: styles.customMenuPopover,
                                    },
                                },
                            }}
                            style={{ ...props.style }}
                            className={styles.root}
                            InputLabelProps={{
                                shrink: getEnumsShrink(),
                                style: { display: getEnumsLabelDisplay() },
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {props.correctValue && (
                                            <CheckIcon
                                                className={styles.checkIcon}
                                            />
                                        )}
                                        {props.mesure && (
                                            <Box style={{ display: "flex" }}>
                                                <Divider
                                                    className={styles.divider}
                                                    orientation="vertical"
                                                    flexItem
                                                />
                                                <Typography
                                                    variant="h6"
                                                    className={styles.mesure}
                                                >
                                                    {props.mesure}
                                                </Typography>
                                            </Box>
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        >
                            {props.children &&
                                props.children?.map((menuItem, i) => { 
                                    return (
                                        <MenuItem
                                            key={i}
                                            className={styles.menuItems}
                                            value={ menuItem.value || menuItem.title || menuItem }
                                        >
                                            {menuItem.title ? menuItem.title : menuItem}
                                        </MenuItem>
                                    );
                                })}
                        </TextField>
                    }
                />
            ) : (
                <TextField
                    id={props.id}
                    name={props.name}
                    label={props.label}
                    placeholder={props.placeholder}
                    type="select"
                    select={true}
                    variant={props.variant}
                    size={props.size}
                    disabled={props.disabled}
                    required={props.required}
                    error={props.error}
                    helperText={props.helperText || props.error}
                    onBlur={(e) => setFocused(false)}
                    onFocus={() => {
                        if (!focused) {
                            setFocused(true);
                        }
                    }}
                    value={props.value}
                    onChange={(e1, e2) => {
                        const raw = props.children?.find(
                            (e) => e?.value == e1?.target?.value
                        );

                        props.onChange(e1, e2, raw);
                    }}
                    style={{ ...props.style }}
                    className={styles.root}
                    InputLabelProps={{
                        shrink: getEnumsShrink(),
                        style: { display: getEnumsLabelDisplay() },
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {props.correctValue && (
                                    <CheckIcon className={styles.checkIcon} />
                                )}
                                {props.mesure && (
                                    <Box style={{ display: "flex" }}>
                                        <Divider
                                            className={styles.divider}
                                            orientation="vertical"
                                            flexItem
                                        />
                                        <Typography
                                            variant="h6"
                                            className={styles.mesure}
                                        >
                                            {props.mesure}
                                        </Typography>
                                    </Box>
                                )}
                            </InputAdornment>
                        ),
                    }}
                    SelectProps={{
                        MenuProps: {
                            PopoverClasses: {
                                root: styles.customMenuPopover,
                            },
                        },
                    }}
                >
                    {props.children &&
                        props.children.map((menuItem, i) => {
                            return (
                                <MenuItem
                                    key={i}
                                    className={styles.menuItems}
                                    value={menuItem.value || menuItem.title || menuItem}
                                >
                                    {menuItem.title || menuItem}
                                </MenuItem>
                            );
                        })}
                </TextField>
            )}
        </Box>
    );
};

OldSelectInput.propTypes = {
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    undertext: PropTypes.string,
    type: PropTypes.string,
    variant: PropTypes.oneOf(["text", "contained", "outlined"]),
    select: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    multiline: PropTypes.bool,
    rows: PropTypes.number,

    style: PropTypes.object,
    boxStyle: PropTypes.object,
    disabledShrink: PropTypes.bool,
    value: PropTypes.string,
    inputRef: PropTypes.any,
    control: PropTypes.any,

    children: PropTypes.any,
    helperText: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.oneOf(["small", "medium"]),

    correctValue: PropTypes.bool,
    mesure: PropTypes.oneOf(["An(s)", "ha", "Pied/Ha", "%", "m²"]),
    notPublished: PropTypes.bool,
    defaultSelected: PropTypes.bool,

    table: PropTypes.bool, // Label shrink false when it's in table
};

OldSelectInput.defaultProps = {
    onChange: () => {},
    onBlur: () => {},
};

export default OldSelectInput;
