import React, { useState } from "react";
import { Box, IconButton, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import theme from "../../../globalTheme";

const useStyles = makeStyles(() => ({
    root: (props) => ({
        boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.08)",
        transition: "transform .4s ease",
        color: theme.palette.common.white,
        fontSize: 15,
        width: "52px",
        height: "52px",
        transform: props.activeElement ? "scale(1.2)" : "scale(1)",
        backgroundColor: props.activeElement ? theme.palette.primary.dark : theme.palette.primary.light,
        "&.MuiIconButton-root:hover": {
            backgroundColor: theme.palette.primary.dark,
            transform: "scale(1.3)",
        },
        "&.MuiIconButton-root:disabled": {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.secondary.main,
        },
    }),
}));

const PublicationTimelineElement = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const styles = useStyles(props, anchorEl);
    return (
        <Box>
            <IconButton
                onClick={(event) => {
                    props.onClick;
                    setAnchorEl(event.currentTarget);
                }}
                className={clsx(styles.root)}
                disabled={props.disabled}
            >
                <div>{props.label}</div>
            </IconButton>
            {/* {props.activeElement && <ClickAwayListener onClickAway={props.onClose}>
                <Popper
                disableScrollLock
                style={{ marginTop: "1rem", zIndex: 100}}
                anchorEl={anchorEl}
                elevation={1}
                open={props.activeElement}
                onClose={props.onClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }} */}
            {/* > */}
            {/* <Card>
                        <CardContent style={{padding: "5px"}}>
                            <Box style={{display: "flex", alignItems: "flex-start"}}>
                                <img src={besv}/>
                                <Typography variant="body2" style={{color: theme.palette.primary.dark, fontFamily: "Mansalva"}}>Présent p. {props.pageNumber}</Typography>
                            </Box>
                            
                            {props.linkToBuy ? (
                                <Link href={props.linkToBuy} target="_blank" rel="noopener" variant="body2" style={{display: "flex", alignItems: "center", color : theme.palette.primary.light}}>Acheter l&apos;édition
                                    <img src={arrow} style={{marginLeft: "0.5rem"}}/>
                                </Link>
                            ) : (
                                <Typography variant="body2" style={{color : theme.palette.text.disabled}}>Rupture de stock</Typography>
                            )}
                        </CardContent>
                    </Card> */}
            {/* </Popper>
            </ClickAwayListener>} */}
        </Box>
    );
};

PublicationTimelineElement.propTypes = {
    label: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    onClose: PropTypes.func,
    activeElement: PropTypes.any,
    pageNumber: PropTypes.number,
    linkToBuy: PropTypes.string,
};

export default PublicationTimelineElement;
