import React from "react";

import { makeStyles, Typography, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import guillemetDroit from "../../assets/propriétéPage/guillemet_droit.webp";
import guillemetGauche from "../../assets/propriétéPage/guillemet_gauche.webp";

import getConnectors from "../../helpers/helpers";

const useStyle = makeStyles((theme) => ({
    quote: {},
    quote__boxTypo: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
    },
    typo: () => ({
        ...theme.front.typography.h3.dark_i,
        overflowWrap: "break-word",
        textAlign: "center",
    }),
    authorTypo: {
        ...theme.front.typography.h5.dark,
        textAlign: "center",
        color: "#915868",
    },
    functionTypo: {
        ...theme.front.typography.labeur.dark_b,
        textAlign: "center",
        color: "#360318",
    },
    guillemetGauche: () => ({
        position: "absolute",
        [theme.breakpoints.only("xs")]: {
            left: 0,
            top: "10px",
        },
        [theme.breakpoints.up("sm")]: {
            left: 0,
            top: 0,
        },
        [theme.breakpoints.up("lg")]: {
            left: -40,
            top: -35,
        },
    }),
    guillemetGauche__img: {
        [theme.breakpoints.only("xs")]: {
            width: "40px",
        },
    },
    guillemetDroit: () => ({
        position: "absolute",
        [theme.breakpoints.only("xs")]: {
            right: 0,
            bottom: "10px",
        },
        [theme.breakpoints.up("sm")]: {
            right: 0,
            bottom: 0,
        },
        [theme.breakpoints.up("lg")]: {
            right: -40,
            bottom: -20,
        },
    }),
    guillemetDroit__img: {
        [theme.breakpoints.only("xs")]: {
            width: "40px",
        },
    },
    boxQuoteAndCitation: {
        // padding: theme.spacing(2),
        overflowWrap: "break-word",
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.up("lg")]: {
            marginTop: "3rem",
        },
    },
    boxQuoteAndCitation__box: (props) => ({
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        position: "relative",
        width: props.small ? "90%" : "auto",
        minHeight: "120px",
    }),
}));

const Quote = (props) => {
    const styles = useStyle(props);
    return (
        <>
            <Box className={styles.boxQuoteAndCitation}>
                <Box className={styles.boxQuoteAndCitation__box}>
                    <Box className={styles.guillemetGauche}>
                        <img
                            className={styles.guillemetGauche__img}
                            src={guillemetGauche}
                            alt="guillemet_gauche_image"
                        />
                    </Box>
                    <Box className={styles.guillemetDroit}>
                        <img className={styles.guillemetDroit__img} src={guillemetDroit} alt="guillemet_droit_image" />
                    </Box>
                    <Box className={styles.quote__boxTypo}>
                        <Typography className={styles.typo}>{props.children}</Typography>
                        <Typography className={styles.authorTypo}>{props.quoteAuthor}</Typography>
                        <Typography className={styles.functionTypo}>
                            {getConnectors(props.quoteAuthorFunctions)}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

Quote.propTypes = {
    children: PropTypes.string,
    quoteAuthor: PropTypes.string,
    quoteAuthorFunctions: PropTypes.string,
    small: PropTypes.bool,
    oneSideOf: PropTypes.bool,
};

export default Quote;
