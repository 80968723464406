import { Plural, t, Trans } from "@lingui/macro";
import { Badge, Box, makeStyles, Typography } from "@material-ui/core";
import { useState } from "react";

import bg from "../../assets/searchEngine/activity/bg-activity-step-1.jpg";
import SimpleInput from "../../Components/Inputs/SimpleInput";
import BigSwitch from "../../Components/Switch/BigSwitch";
import { useSearchActivitiesQuery } from "../../generated/graphql";
import theme from "../../globalTheme";
import { useCustomHistory, useSearch } from "../../hooks";
import SearchActivityModal from "../../Search/modal/SearchActivityModal";
import SearchTabs from "../../Search/SearchTabs";
import SelectButtonOrCheckbox from "../../Search/SelectButtonOrCheckbox";
import SelectWithCount from "../../Search/SelectWithCount";
import ButtonSearch from "../../Search/ui/ButtonSearch";
import Helmet from "Components/Helmet/Helmet";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&::after": {
            // eslint-disable-next-line quotes
            content: '""',
            position: "absolute",
            zIndex: -1,
            top: "-5rem",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundImage: `url(${bg})`,
            backgroundRepeat: "no-repeat",
            backgroundPositionY: "top",
            backgroundPositionX: "center",
            backgroundSize: "cover",
        },

        minHeight: "calc(100vh - 5rem)",
    },
    main_container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "22px 1rem 2rem",
        paddingTop: 0,
        overflow: "hidden",

        [theme.breakpoints.up("md")]: {
            width: "calc(950px)",
            margin: "auto",
        },

        [theme.breakpoints.up("lg")]: {
            width: "calc(1354px)",
            margin: "auto",
        },

        [theme.breakpoints.up("xl")]: {
            width: "calc(1384px)",
            margin: "auto",
        },
    },
    title: {
        color: theme.palette.common.white,
        fontWeight: 400,
        textAlign: "center",
        marginBottom: "1rem",
    },
    nav: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        gap: 6,
        alignItems: "center",
        background: "transparent",
        width: "100%",
        maxWidth: 1100,
        borderRadius: "53px",
        marginTop: 25,
        // marginBottom: 20,

        [theme.breakpoints.up("sm")]: {
            marginTop: 33,
        },

        [theme.breakpoints.up("md")]: {
            marginTop: 23,
            // marginBottom: 65,
            background: theme.palette.common.white,
            height: 34,
            padding: "7px 9px",
        },

        [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "repeat(15, 1fr)",
            gap: 7,
            marginTop: 48,
            // marginBottom: 26,
        },
    },
    search: {
        gridColumn: "1 / span 12",

        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 4",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 4",
        },
    },
    category: {
        position: "relative",
        gridColumn: "1 / span 12",

        [theme.breakpoints.up("sm")]: {
            gridColumn: "1 / span 3",
        },

        [theme.breakpoints.up("md")]: {
            gridColumn: "5 / span 2",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "5 / span 2",
        },
    },
    lang: {
        position: "relative",
        gridColumn: "1 / span 12",

        [theme.breakpoints.up("sm")]: {
            gridColumn: "4 / span 3",
        },

        [theme.breakpoints.up("md")]: {
            gridColumn: "7 / span 2",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "7 / span 2",
        },
    },
    nbPerson: {
        position: "relative",
        gridColumn: "1 / span 12",

        [theme.breakpoints.up("sm")]: {
            gridColumn: "7 / span 3",
        },

        [theme.breakpoints.up("md")]: {
            gridColumn: "9 / span 2",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "9 / span 2",
        },
    },
    private: {
        position: "relative",
        gridColumn: "1 / span 12",

        [theme.breakpoints.up("sm")]: {
            gridColumn: "10 / span 3",
        },

        [theme.breakpoints.up("md")]: {
            gridColumn: "11 / span 2",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "11 / span 2",
        },
    },
    privateBox: {
        display: "flex",
        flexDirection: "row-reverse",
        alignItems: "center",
        justifyContent: "space-between",
        border: `0.4px solid ${theme.palette.secondary.main}`,
        borderRadius: "53px",
        height: "32px",
        paddingLeft: 6,
        paddingRight: 10,
        cursor: "pointer",
        "-webkit-tap-highlight-color": "transparent",

        [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
            justifyContent: "flex-start",
        },

        [theme.breakpoints.up("lg")]: {
            paddingRight: 5,
        },
    },
    badgeNotif: {
        position: "absolute",
        right: 10,
        top: 4,
        border: "none",
        padding: "0 4px",
        background: theme.palette.primary.dark,

        "& .MuiBadge-badge": {
            fontWeight: 700,
        },
    },
    result: {
        gridColumn: "1 / span 12",
        display: "flex",
        justifyContent: "center",
        paddingTop: 6,

        [theme.breakpoints.up("sm")]: {
            paddingTop: 13,
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "13 / span 3",
            paddingTop: 0,
        },
    },
    resultBtn: {
        [theme.breakpoints.up("sm")]: {
            maxWidth: 236,
        },

        [theme.breakpoints.up("lg")]: {
            maxWidth: "none",
        },
    },
    searchAdvancedBox: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        gap: 12,
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        maxWidth: 1100,
    },
    searchAdvancedBtn: {
        background: "transparent",
        border: "none",
        color: theme.palette.common.white,
        textDecoration: "underline",
        cursor: "pointer",
        padding: 0,

        gridColumn: "1 / span 12",
        display: "flex",
        justifyContent: "center",

        [theme.breakpoints.up("sm")]: {
            marginTop: 0,
        },

        [theme.breakpoints.up("md")]: {
            gridColumn: "5 / span 4",
            marginTop: 48,
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "11 / span 3",
            marginTop: 0,
            marginRight: 40,
        },
    },
}));

const FirstStepActivity = () => {
    const styles = useStyles();
    const history = useCustomHistory();
    const [modalIsShown, setModalIsShown] = useState();
    const [searchText, setSearchText] = useState("");
    const [prevFilters, setPrevFilters] = useState({});

    const { filters, setFilter, resetFilters, totalCount, getUrlParams, filtersCount, setAllFilters } = useSearch(
        {
            searchText: null,
            communes: [],
            categories: [],
            languages: [],
            accessibleTo: [],
            payments: [],
            tags: [],
            nbPersons: 0,
            priceRange: [1, 201],
            privateActivity: false,
            parkingBus: false,
            durations: [],
        },
        useSearchActivitiesQuery,
        "SearchActivities"
    );

    const handleNextStep = () =>
        history.push({
            pathname: "/recherche/activites/resultats",
            search: JSON.stringify(getUrlParams(searchText)),
        });

    return (
        <>
            <Helmet
                title={t`Rechercher des activités`}
                content="Découvrez les plus belles propriétés viticoles de Bordeaux grâce à notre moteur de recherche en ligne. Explorez leurs histoires, leurs terroirs et leurs vins sur Bordeaux et ses Vins."
            />

            <Box className={styles.root}>
                <Box className={styles.main_container}>
                    <Typography variant="h2" className={styles.title}>
                        <Trans>Parcourez le vignoble bordelais</Trans>
                    </Typography>
                    <SearchTabs active={"activity"} />
                    <Box className={styles.nav}>
                        <Box className={styles.search}>
                            <SimpleInput
                                onEnterPressed={() => handleNextStep()}
                                search
                                borderWidth={0.4}
                                size="small"
                                name="search"
                                placeholder={t`Chercher une activité, une ville...`}
                                variant="outlined"
                                value={searchText}
                                onBlur={(e) => setFilter("searchText", e?.target?.value)}
                                onChange={(e) => setSearchText(e?.target?.value)}
                                boxStyle={{
                                    backgroundColor: theme.palette.common.white,
                                    borderRadius: "53px",
                                    border: "none",
                                }}
                                bgTransparent
                                borderRadius={53}
                                style={{
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        border: "5px solid red !important",
                                    },
                                }}
                                iconPosition="start"
                            />
                        </Box>

                        <Box className={styles.category}>
                            <Badge
                                badgeContent={filters?.categories?.length}
                                color="primary"
                                className={styles.badgeNotif}
                            />
                            <SelectButtonOrCheckbox
                                enumField="ACTIVITY_TYPE"
                                setFilters={(categories) => setFilter("categories", categories)}
                                label={t`Catégorie`}
                                filter={filters?.categories}
                                filterName="categories"
                                hideNbInSelect
                            />
                        </Box>

                        <Box className={styles.lang}>
                            <Badge
                                badgeContent={filters?.languages?.length}
                                color="primary"
                                className={styles.badgeNotif}
                            />
                            <SelectButtonOrCheckbox
                                enumField="LANGUAGES"
                                setFilters={(languages) => setFilter("languages", languages)}
                                hideNbInSelect
                                label={t`Langue`}
                                filter={filters?.languages}
                                filterName="languages"
                            />
                        </Box>

                        <Box className={styles.nbPerson}>
                            <SelectWithCount
                                label={
                                    filters?.nbPersons > 0
                                        ? `${[
                                              filters?.nbPersons >= 200 && "+ ",
                                              filters?.nbPersons,
                                              filters?.nbPersons > 1 ? t`personnes` : t`personne`,
                                          ]
                                              .filter(Boolean)
                                              .join(" ")}`
                                        : t`Nb de personnes`
                                }
                                filter={filters?.nbPersons}
                                filterName="nbPersons"
                                valueSlide={filters?.nbPersons}
                                setValueSlide={(v) => setFilter("nbPersons", v)}
                            />
                            {/* <SelectInputSlide
                            label={
                                filters?.nbPersons > 0
                                    ? `${filters?.nbPersons === 200 ? "+ " : ""} ${filters?.nbPersons} ${
                                          filters?.nbPersons > 1 ? "personnes" : "personne"
                                      }`
                                    : "Nb de personnes"
                            }
                            filters={filters}
                            filter={filters?.nbPersons}
                            filterName="nbPersons"
                            setFilters={setFilters}
                            fakeResultAftersearch={fakeResultAftersearch}
                            winesResults={searchResult}
                            setSearchResult={setSearchResult}
                            valueSlide={valueSlide}
                            setValueSlide={setValueSlide}
                        /> */}
                        </Box>

                        <Box className={styles.private}>
                            <Badge
                                badgeContent={filters?.isPrivate ? 1 : 0}
                                color="primary"
                                className={styles.badgeNotif}
                            />
                            <Box
                                className={styles.privateBox}
                                onClick={() => setFilter("privateActivity", !filters?.privateActivity)}
                                style={{
                                    borderColor: filters?.privateActivity
                                        ? theme.palette.primary.dark
                                        : theme.palette.secondary.main,
                                    backgroundColor: filters?.privateActivity
                                        ? theme.palette.background.card
                                        : theme.palette.common.white,
                                }}
                            >
                                <BigSwitch
                                    checked={filters?.privateActivity}
                                    onChange={() => setFilter("privateActivity", !filters?.privateActivity)}
                                />
                                <Typography variant="h6" style={{ marginLeft: 7, color: theme.palette.primary.dark }}>
                                    <Trans>Privatisée</Trans>
                                </Typography>
                            </Box>
                        </Box>

                        <Box className={styles.result}>
                            <ButtonSearch onClick={() => handleNextStep()} className={styles.resultBtn}>
                                <Plural
                                    value={totalCount ?? 0}
                                    one={"Rechercher (# résultat)"}
                                    other={"Rechercher (# résultats)"}
                                    _0={"Rechercher (# résultat)"}
                                />
                            </ButtonSearch>
                        </Box>
                    </Box>

                    <Box className={styles.searchAdvancedBox}>
                        <button
                            className={styles.searchAdvancedBtn}
                            onClick={() => {
                                setPrevFilters(filters);
                                setModalIsShown(true);
                            }}
                        >
                            <Typography variant="subtitle1">
                                <Trans>Recherche avancée</Trans>
                            </Typography>
                        </button>
                    </Box>
                </Box>
                <SearchActivityModal
                    filtersCount={filtersCount}
                    isOpen={modalIsShown}
                    onClose={() => setModalIsShown(false)}
                    onCloseCross={() => {
                        setAllFilters(prevFilters);
                        setModalIsShown(false);
                    }}
                    titlePrimary={t`Tous les filtres`}
                    titleSecondary={t`Activités`}
                    filters={filters}
                    setFilter={(fieldname, v) => setFilter(fieldname, v)}
                    resetFilters={() => resetFilters()}
                    totalCount={totalCount}
                    searchText={searchText}
                    nextStep={() => handleNextStep()}
                />
            </Box>
        </>
    );
};

export default FirstStepActivity;
