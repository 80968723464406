/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import React, { useState } from "react";
import { Typography, makeStyles, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import theme from "../../globalTheme";
import Popin from "../../Containers/Popin";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import green from "../../assets/Wine/green.svg";
import { firstLetterUppercase, useWindowDimensions } from "../../helpers/helpers";
import { getSentence } from "./vintageHelpers";
import { Trans } from "@lingui/macro";

const useStyle = makeStyles((theme) => ({
    container: {
        width: "40rem",
        // maxWidth: "700px",
        overflowY: "auto",
        position: "relative",
        padding: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
        [theme.breakpoints.down("xs")]: {
            // width: "300px",
        },
        "& .MuiStepConnector-lineVertical": {
            borderLeftColor: "#9BBA97",
            position: "absolute",
            minHeight: "62px",
            top: -31,
        },
        "& .MuiStepLabel-labelContainer": {
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(1),
        },
        "& .MuiStepConnector-vertical": {
            position: "relative",
            marginLeft: "9px",
            padding: 0,
        },
        "& .MuiStepper-root": {
            padding: 0,
        },
        "& .MuiTimeline-root": {
            marginBottom: 0,
            marginTop: 0,
        },
        [theme.breakpoints.between("xs", "sm")]: {
            padding: 0,
        },
    },
    typoContainer: {
        display: "flex",
        justifyContent: "flex-start",
    },
    title: {
        color: theme.palette.common.black,
        zIndex: 2,
        backgroundColor: theme.palette.common.white,
        padding: "0px 2px",
        [theme.breakpoints.only("xs")]: {
            fontSize: "14px",
        },
    },
    stepLabelText: {
        border: "1px solid",
        borderColor: theme.palette.secondary.main,
        borderRadius: "4px",
        padding: "8px 16px",
    },
    stepLabel: {
        "& .MuiSvgIcon-root": {
            color: "white",
            width: "19px",
            height: "19px",
        },
    },
    typoTitleOperation: {
        color: "#915868",
        fontSize: "14px",
    },
    typoDescrOperation: {
        fontSize: "16px",
        color: "#360318",
        fontFamily: "Lato",
    },
    containerCustom: {
        width: "1100px",
        maxWidth: "95%",
        maxHeight: "98%",
        "&::-webkit-scrollbar": {
            width: "8px",
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            paddingRight: "8px",
            // -webkit-box-shadow: "inset 0 0 6px rgba(0,0,0,0.00)";
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#D0BDC3",
            borderRadius: "20px",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            width: "100%",
            overflowY: "scroll",
            maxHeight: "auto",
            height: "calc(100% - 16px)",
            maxWidth: "auto",
        },
    },
    container_popin : {
        maxWidth: "inherit",
        maxHeight: "inherit"
    },
    timeline : {
        [theme.breakpoints.down("sm")]: {
          padding:0
        },
    }
}));

const WineGreenOperationsPopin = (props) => {
    const styles = useStyle(props);
    const { width, height } = useWindowDimensions();

    return (
        <Popin
        containerWidth={width < 920 && "100%"}
        classStyleContent={styles.container_popin}
        
            title={
                <Box className={styles.typoContainer}>
                    <img
                        src={green}
                        alt="title_icon"
                        style={{
                            backgroundColor: "white",
                            paddingRight: "4px",
                        }}
                    />
                    <Typography variant="h3" className={styles.title}>
                        <Trans>Opérations en vert</Trans>
                    </Typography>
                </Box>
            }
            open={props.open}
            onClose={props.onClose}
            cross
            background={3}
        >
            <Box className={styles.container}>
                <Timeline
                    align="alternate"
                    className={styles.timeline}
                    style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
                >
                    {props?.greenOperations?.map((el, i) => {
                        return (
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot
                                        variant="outlined"
                                        style={{
                                            borderColor: "#9BBA97",
                                            borderWidth: "4px",
                                        }}
                                    />
                                    <TimelineConnector
                                        style={{
                                            backgroundColor: "#9BBA97",
                                            width: "3px",
                                        }}
                                    />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Box className={styles.stepLabelText}>
                                        {el?.operation && (
                                            <Typography
                                                className={
                                                    styles.typoTitleOperation
                                                }
                                            >
                                                {firstLetterUppercase(
                                                    el?.operation
                                                )}
                                            </Typography>
                                        )}
                                        <Typography
                                            className={
                                                styles.typoDescrOperation
                                            }
                                        >
                                            {" "}
                                            {getSentence(el)}{" "}
                                        </Typography>
                                    </Box>
                                </TimelineContent>
                            </TimelineItem>
                        );
                    })}
                </Timeline>
            </Box>
        </Popin>
    );
};

WineGreenOperationsPopin.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    greenOperations: PropTypes.array,
};

export default WineGreenOperationsPopin;
