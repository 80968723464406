// Settings:
import { Trans } from "@lingui/macro";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Logo500 from "assets/ErrorPage/500.svg";
import bigFioTopLeft from "assets/ErrorPage/big_top_left.svg";
import fioBottomLeft from "assets/ErrorPage/bottom_left.svg";
import fioBottomRight from "assets/ErrorPage/bottom_right.svg";
import mobileFio from "assets/ErrorPage/mobile_fio.svg";
import fioTopLeft from "assets/ErrorPage/top_left.svg";
import theme from "globalTheme";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
    containerError__wrapper: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",

        height: "100vh",
        overflow: "hidden",
        position: "relative",
        width: "100%",
        backgroundRepeat: "no-repeat",
        top: "0",
        bottom: "0",
        [theme.breakpoints.up("xs")]: {
            backgroundImage: `url(${mobileFio})`,
            backgroundSize: "100%",
            backgroundPosition: "left top",
        },
        [theme.breakpoints.up("sm")]: {
            backgroundImage: `url(${bigFioTopLeft})`,
            backgroundSize: "auto",

            backgroundPosition: "top -70px left -365px",
        },
        [theme.breakpoints.up("md")]: {
            backgroundPosition: "right -300px top -70px",
        },
        [theme.breakpoints.up("lg")]: {
            backgroundImage: `url(${fioTopLeft}), url(${bigFioTopLeft}), url(${fioBottomLeft}), url(${fioBottomRight})`,
            backgroundPosition:
                "top -65px left -55px, top -50px right 33px, bottom -45px left -70px, bottom -40px right -15px ",
        },
        [theme.breakpoints.up("xl")]: {
            backgroundPosition: "-65px -50px, -165px -40px, -80px bottom, bottom right",
        },
        "& .MuiTypography-h2": {
            fontFamily: "Playfair Display, Helvetica, Arial, sans-serif",
            transition: "fontSize .6s",
            [theme.breakpoints.up("xs")]: {
                fontSize: "1.875rem",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "2.188rem",
            },
            [theme.breakpoints.up("xl")]: {
                fontSize: "3.125rem",
            },
        },
        "& .MuiTypography-h5": {
            fontFamily: "Lato, Helvetica, Arial, sans-serif",
            transition: "fontSize .6s",
            fontSize: "1.125rem",
            [theme.breakpoints.up("xs")]: {
                fontSize: "1rem",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: "1rem",
            },
            [theme.breakpoints.up("xl")]: {
                fontSize: "1.125rem",
            },
        },
    },
    main_content: {
        display: "flex",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1,
        [theme.breakpoints.up("xs")]: {
            flexDirection: "column",
            alignItems: "start",
            gridColumn: "2 / span 10",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "2 / span 10",
        },
        [theme.breakpoints.up("lg")]: {
            alignItems: "center",
            flexDirection: "row",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "2 / span 10",
        },
    },
    container_texts: {
        [theme.breakpoints.up("xs")]: {
            width: "auto",
            margin: "0 0.5rem",
            textAlign: "center",
        },
        [theme.breakpoints.up("sm")]: {
            textAlign: "left",
            width: "100%",
            margin: 0,
        },
        [theme.breakpoints.up("md")]: {
            width: "auto",
        },
    },
    main_title: {
        color: theme.palette.primary.dark,
        [theme.breakpoints.up("xs")]: {
            textAlign: "center",
        },
        [theme.breakpoints.up("sm")]: {
            textAlign: "left",
        },
    },
    logo_500: {
        [theme.breakpoints.up("xs")]: {
            display: "none",
        },
        [theme.breakpoints.up("sm")]: {
            display: "inherit",
            width: "300px",
            margin: "1rem 0",
        },
        [theme.breakpoints.up("md")]: {
            margin: "2rem 0",
            width: "300px",
        },
        [theme.breakpoints.up("lg")]: {
            margin: "0 2rem 0 0",
            marginRight: "2rem",
            width: "400px",
        },
        [theme.breakpoints.up("xl")]: {
            width: "550px",
            margin: "0 1rem 0 0",
        },
    },
    contact_us: {
        [theme.breakpoints.up("xs")]: {
            fontFamily: "Mansalva",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            marginTop: "1rem",
        },
        [theme.breakpoints.up("sm")]: {
            fontFamily: "Mansalva",
            display: "inherit",
            marginTop: "0",
        },
    },
}));

const ErrorPage = (props) => {
    const styles = useStyles();

    return (
        <Box className={styles.containerError__wrapper}>
            <Box className={styles.main_content}>
                <img src={Logo500} className={styles.logo_500} />
                <Box className={styles.container_texts}>
                    <Typography variant="h2" className={styles.main_title}>
                        <Trans>
                            Cette page est temporairement{" "}
                            <span style={{ color: theme.palette.wine.main }}>indisponible</span>
                        </Trans>
                    </Typography>

                    <Typography variant="h5">
                        <Trans>Nous sommes désolés, cette page n’est pas accessible actuellement.</Trans>
                    </Typography>
                    <br />
                    <Typography variant="h5">
                        <Trans>Pas de panique, nous sommes déjà sur le coup pour résoudre ce problème !</Trans> <br />
                        <Trans>Nous vous invitons à revenir d’ici un petit moment.</Trans>
                    </Typography>

                    <Typography className={styles.contact_us} variant="h5">
                        <Trans>
                            Pour toute urgence, contactez-nous{" "}
                            <a
                                href={`mailto:support@feret.com?subject=Erreur 500 : ${location?.pathname}`}
                                style={{ cursor: "pointer", color: theme.palette.wine.main }}
                            >
                                support@feret.com
                            </a>
                        </Trans>
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

ErrorPage.propTypes = {
    numberError: PropTypes.number,
};

export default ErrorPage;
