import { Box, makeStyles, Typography } from "@material-ui/core";
import BaseButton from "Components/Buttons/BaseButton";
import FrontContentTop from "Front/FrontContentTop";
import { useWindowDimensions } from "helpers/helpers";
import Logo500 from "../../assets/ErrorPage/500.svg";
import bigFioTopLeft from "../../assets/ErrorPage/big_top_left.svg";
import fioBottomLeft from "../../assets/ErrorPage/bottom_left.svg";
import fioBottomRight from "../../assets/ErrorPage/bottom_right.svg";
import fioTopLeft from "../../assets/ErrorPage/top_left.svg";
import theme from "../../globalTheme";
import mobileFio from "../../assets/ErrorPage/mobile_fio.svg";
import { useCustomHistory } from "hooks";

const useStyles = makeStyles(() => ({
    front_content: (props) => {
        return {
            position: "relative",
            display: "grid",
            gap: "24px",
            gridTemplateColumns: "repeat(12, 1fr)",
            [theme.breakpoints.up("xs")]: {
                margin: "0 auto",
                padding: "44px 14px",
            },
            [theme.breakpoints.up("sm")]: {
                margin: "0 auto",
                padding: "50px",
            },
            [theme.breakpoints.up("md")]: {
                margin: "0 auto",
                padding: "0 16px",
            },
            "&::after": {
                // eslint-disable-next-line quotes
                content: '""',
                position: "absolute",
                zIndex: -1,
                top: "-5rem",
                bottom: 0,
                left: 0,
                right: 0,
                backgroundRepeat: "no-repeat",
                [theme.breakpoints.up("xs")]: {
                    backgroundImage: `url(${mobileFio})`,
                    backgroundSize: "100%",
                    backgroundPosition: "left 30px",
                },
                [theme.breakpoints.up("sm")]: {
                    backgroundImage: `url(${bigFioTopLeft})`,
                    backgroundSize: "auto",
                },
                [theme.breakpoints.up("lg")]: {
                    backgroundImage: `url(${fioTopLeft}), url(${bigFioTopLeft}), url(${fioBottomLeft}), url(${fioBottomRight})`,
                    backgroundPosition: "top left, top left, bottom -30% left -7% , bottom right",
                },
                [theme.breakpoints.up("xl")]: {
                    backgroundPosition: "top left, top left, bottom left , bottom right",
                },
            },
        };
    },
    content: {
        width: "auto",
        [theme.breakpoints.up("xs")]: {
            marginBottom: "1rem",
            minHeight: "20rem",
        },
        [theme.breakpoints.up("sm")]: {
            height: "auto",
            minHeight: "20rem",
            gridColumn: "1 / span 12",
        },
        [theme.breakpoints.up("md")]: {
            height: "calc(100vh - 14rem -  1rem - 75px)",
            gridColumn: "1 / span 12",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "2 / span 10",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "3 / span 9",
        },
    },
    main_content: {
        display: "flex",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.up("xs")]: {
            flexDirection: "column",
            alignItems: "start",
        },
        [theme.breakpoints.up("md")]: {
            flexDirection: "row",
            alignItems: "center",
        },
    },
    container_texts: {
        [theme.breakpoints.up("xs")]: {
            width: "auto",
            margin: "0 0.5rem",
            textAlign: "center",
        },
        [theme.breakpoints.up("sm")]: {
            textAlign: "left",
            width: "100%",
            margin: 0,
        },
        [theme.breakpoints.up("md")]: {
            width: "auto",
        },
    },
    main_title: {
        color: theme.palette.primary.dark,
        [theme.breakpoints.up("xs")]: {
            textAlign: "center",
        },
        [theme.breakpoints.up("sm")]: {
            textAlign: "left",
        },
    },
    logo_500: {
        [theme.breakpoints.up("xs")]: {
            display: "none",
        },
        [theme.breakpoints.up("sm")]: {
            display: "inherit",
            width: "300px",
            margin: "1rem 0",
        },
        [theme.breakpoints.up("md")]: {
            margin: "0 2rem",
            width: "300px",
        },
        [theme.breakpoints.up("lg")]: {
            margin: "0 2rem 0 0",
            marginRight: "2rem",
            width: "400px",
        },
        [theme.breakpoints.up("xl")]: {
            width: "550px",
            margin: "0 1rem 0 0",
        },
    },
    container_button: {
        display: "flex",
        gap: "1rem",
        marginTop: "0.5rem",
        [theme.breakpoints.up("xs")]: {
            flexDirection: "column-reverse",
            alignItems: "center",
        },
        [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
            margin: "0.5rem 0",
        },
    },

    arrow: {
        display: "none",
        [theme.breakpoints.up("xl")]: {
            display: "inherit",
            width: "15px",
            transform: "rotate(115deg)",
            marginLeft: "0.5rem",
        },
    },
    contact_us: {
        [theme.breakpoints.up("xs")]: {
            fontFamily: "Mansalva",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            marginTop: "1rem",
        },
        [theme.breakpoints.up("sm")]: {
            fontFamily: "Mansalva",
            display: "inherit",
            marginTop: "0",
        },
    },
}));

const Page500Front = (props) => {
    const styles = useStyles(props);
    const { width } = useWindowDimensions();
    const history = useCustomHistory();

    return (
        <FrontContentTop
            backgroundColor={theme.palette.dashboard.background}
            overridesClassName={styles.front_content}
            firstPart={styles.content}
        >
            <Box className={styles.main_content}>
                <img src={Logo500} className={styles.logo_500} />
                <Box className={styles.container_texts}>
                    <Typography variant="h2" className={styles.main_title}>
                        Cette page est temporairement{" "}
                        <span style={{ color: theme.palette.wine.main }}>indisponible</span>
                    </Typography>

                    <Typography variant="h5">
                        Nous sommes désolés, cette page n’est pas accessible actuellement.
                    </Typography>
                    <br />
                    <Typography variant="h5">
                        Pas de panique, nous sommes déjà sur le coup pour résoudre ce problème ! <br />
                        Nous vous invitons à revenir d’ici un petit moment.
                    </Typography>
                    <Box className={styles.container_button}>
                        <BaseButton
                            size="small"
                            color="primary"
                            variant="outlined"
                            style={{ width: width < 600 && "100%" }}
                            boxStyle={{ flex: width < 600 && 1 }}
                            onClick={() => history.push("/recherche")}
                        >
                            Moteur de recherche
                        </BaseButton>
                        <BaseButton
                            size="small"
                            color="primary"
                            variant="contained"
                            onClick={() => history.push("/figurer-dans-bordeaux-et-ses-vins")}
                        >
                            <Typography variant="h6">
                                Figurer dans <i>Bordeaux et ses Vins</i>
                            </Typography>
                        </BaseButton>
                    </Box>
                    <Typography className={styles.contact_us} variant="h5">
                        Pour toute urgence,{" "}
                        <u
                            onClick={() => history.push("/contact")}
                            style={{ cursor: "pointer", color: theme.palette.wine.main }}
                        >
                            contactez-nous !
                        </u>
                    </Typography>
                </Box>
            </Box>
        </FrontContentTop>
    );
};

export default Page500Front;
