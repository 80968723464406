/* eslint-disable no-unused-vars */
/*eslint-disable no-irregular-whitespace */

import React, { useState } from "react";
import { Box, Typography, makeStyles, Link } from "@material-ui/core";
import BaseButton from "../../../Components/Buttons/BaseButton";
import Popin from "../../../Containers/Popin";
import PropTypes from "prop-types";
import theme  from "../../../globalTheme";
import infoImg from "../../../assets/Dashboard/translationAssets/Info.png";
import Arrow from "../../../Components/Arrow/Arrow";

const useStyle = makeStyles(() => ({

}));


const AfterPaymentInformationPopin = (props) => {
    const styles = useStyle(props);
    return (
        <Popin
            cross
            background={2}
            open={props.modalOpen}
            onClose={props.handleClose}
        >
            <Box style={{ width: 740, marginRight: 56, marginLeft: 56, marginBottom: theme.spacing(2) }}>
                <Box style={{ display: "flex", alignItems: "center", marginBottom: theme.spacing(2) }}>
                    <img src={infoImg} alt="img" style={{ marginRight: "16px" }} />
                    <Typography style={{ textAlign: "left", color: theme.palette.primary.dark, fontSize: "18px", fontWeight: "bold", fontFamily: "Playfair display", }} variant="h3">
                        Vous avez fait une demande pour une traduction manuelle de ce champ.
                    </Typography>
                </Box>
                <Typography style={{ fontSize: "18px" }}>
                    L’aperçu de la traduction manuelle n’est pas disponible jusqu’à sa réalisation.             </Typography><br />
                <Typography style={{ fontSize: "18px" }}>Dès validation de votre règlement, il faut compter un délai de 3 jours ouvrés pour la retrouver dans votre tableau de bord.
                </Typography><br />
                <Typography style={{ fontSize: "18px" }}>Pour toutes questions concernant la traduction, merci de nous contacter à
                </Typography>
                <Link style={{ fontSize: "18px", }} underline="always">traduction@feret.com</Link>
            </Box>

            {/* 
            DEUXIEME VERSION DE LA POPIN
            <Box style={{ marginRight: 56, marginLeft: 56, marginBottom: theme.spacing(2) }}>
                <Box style={{ display: "flex", alignItems: "center", marginBottom: theme.spacing(2) }}>
                    <img src={infoImg} alt="img" style={{ marginRight: "16px" }} />
                    <Typography style={{ textAlign: "left", color: theme.palette.primary.dark, fontSize: "18px", fontWeight: "bold", fontFamily: "Playfair display", }} variant="h3">
                        Vous avez fait une demande pour une traduction manuelle de ce champ.
                </Typography>
                </Box>
                <Typography style={{ fontSize: "18px" }}>
                    Vous avez choisi de supprimer la traduction automatique de votre contenu. L’aperçu de la traduction n’est donc pas disponible jusqu’à sa réalisation.
                </Typography><br />
                <Typography style={{ fontSize: "18px" }}>Si vous avez déjà réglé la traduction, il faut compter un délai de 5 jours ouvrés dès validation de votre règlement pour la retrouver dans votre tableau de bord.
                                   </Typography><br />
                <Typography style={{ fontSize: "18px" }}>Si vous souhaitez annuler votre demande, merci de nous contacter à
                </Typography>
                <Link style={{ fontSize: "18px", }} underline="always">traduction@feret.com</Link>
            </Box> */}

        </Popin>
    );
};

export default AfterPaymentInformationPopin;

AfterPaymentInformationPopin.propTypes = {
    modalOpen: PropTypes.bool,
    handleClose: PropTypes.func,

};