import { t } from "@lingui/macro";
import { makeStyles } from "@material-ui/core";
import fioriture from "assets/frontPage/wine_fioriture.svg";
import LoadingWithImage from "Components/Loader/LoadingWithImage";
import theme from "globalTheme";
import { formatConcat, urlize } from "helpers/helpers";
import { any, string } from "prop-types";
import { useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";

import { useFrontVintageQuery, useFrontWinePresentationQuery } from "../../generated/graphql";
import { GenericWinePage } from "./GenericWinePage";

const useStyles = makeStyles(() => ({
    //FRONT
    backgroundBottleContainer: (args) => ({
        position: "absolute",
        backgroundColor: args?.backgroundBottleColor ? args?.backgroundBottleColor : "#915868",
        zIndex: 2,
        borderRadius: "0 10px 10px 0",
        [theme.breakpoints.only("xs")]: {
            width: "calc(100% + 28px)",
            height: "124px",
            borderRadius: "20px 20px 0 0",
            display: "flex",
            left: -14,
            top: -14,
            justifyContent: "center",
        },
        [theme.breakpoints.only("sm")]: {
            width: "calc(100% + 28px)",
            height: "200px",
            left: "-14px",
            right: "-28px",
            borderRadius: "20px 20px 0 0",
            top: "-40px",
            display: "flex",
            justifyContent: "center",
        },
        [theme.breakpoints.only("md")]: {
            borderRadius: "0 10px 10px 0",
            width: "15rem",
            height: "300px",
            left: "-34px",
            top: 0,
        },
        [theme.breakpoints.only("lg")]: {
            width: "14rem",
            height: "300px",
            left: "-40px",
        },
        [theme.breakpoints.only("xl")]: {
            width: "16rem",
            height: "300px",
            left: "-90px",
        },
    }),
    vintagesScrollContainer: {
        position: "relative",
        zIndex: 2,
        backgroundColor: theme.palette.common.white,
        [theme.breakpoints.only("xs")]: {
            marginTop: "132px",
            marginLeft: "8px",
            marginRight: "8px",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 12",
            marginTop: "223px",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "4 / span 9",
            marginTop: 0,
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "3 / span 10",
            gridRow: "1 / span 1",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "3 / span 6",
        },
    },
    bottle_img: {
        position: "absolute",
        zIndex: 10,
        [theme.breakpoints.only("xs")]: {
            right: "42%",
            height: "150px",
            top: "-16px",
        },
        [theme.breakpoints.only("sm")]: {
            right: "42%",
            height: "306px",
            top: "-45px",
        },
        [theme.breakpoints.only("md")]: {
            top: -120,
            height: "480px",
            right: "25%",
        },
        [theme.breakpoints.only("lg")]: {
            right: "25%",
            height: "452px",
            top: -90,
        },
        [theme.breakpoints.only("xl")]: {
            right: "25%",
            height: "482px",
            top: -120,
        },
    },
    background_bottle: {
        [theme.breakpoints.only("xs")]: {
            height: "174px",
            position: "relative",
            transform: "rotate(90deg)",
            top: -38,
            left: 0,
        },
        [theme.breakpoints.only("sm")]: {
            height: "280px",
            position: "relative",
            transform: "rotate(90deg)",
            top: -62,
            left: 80,
        },
        [theme.breakpoints.only("md")]: {
            height: "280px",
            position: "relative",
            bottom: -30,
            left: -18,
        },
        [theme.breakpoints.only("lg")]: {
            height: "280px",
            position: "relative",
            bottom: -30,
            left: -18,
        },
        [theme.breakpoints.only("xl")]: {
            height: "280px",
            position: "relative",
            bottom: -30,
            left: -18,
        },
    },
    tabsContainer: (args) => ({
        [theme.breakpoints.up("lg")]: {
            marginTop: !args?.data?.FrontVintage?.bottleLabel ? 0 : 24,
        },
    }),

    //STANDALONE
    backgroundBottleContainerStandalone: (args) => ({
        backgroundColor: args?.data?.FrontVintage?.backgroundBottleColor
            ? args?.data?.FrontVintage?.backgroundBottleColor
            : "#915868",
        zIndex: 2,
        borderRadius: "0 10px 10px 0",
        [theme.breakpoints.only("xs")]: {
            position: "relative",
            width: "calc(100% + 20px)",
            height: "120px",
            borderRadius: "0 20px 20px 0",
            display: "flex",
            top: -14,
            left: "-14px",
            justifyContent: "center",
            gridColumn: "1 / span 12",
        },
        [theme.breakpoints.only("sm")]: {
            position: "relative",
            gridColumn: "1 / span 12",
            width: "calc(100% + 5px)",
            height: "230px",
            left: "-14px",
            right: "-28px",
            top: "25px",
            borderRadius: "0 20px 20px 0",
            display: "flex",
            justifyContent: "center",
        },
        [theme.breakpoints.only("md")]: {
            position: "absolute",

            borderRadius: "0 10px 10px 0",
            width: "15rem",
            height: "395px",
            left: "-34px",
            top: 0,
        },
        [theme.breakpoints.only("lg")]: {
            position: "absolute",
            top: 180,
            width: "14rem",
            height: "565px",
            left: "-40px",
        },
        [theme.breakpoints.only("xl")]: {
            position: "absolute",

            width: "16rem",
            height: "565px",
            left: "-90px",
        },
    }),
    partOneContainer: {
        [theme.breakpoints.only("xs")]: {
            minHeight: "120px",
        },
        [theme.breakpoints.only("sm")]: {
            minHeight: "230px",
        },
        [theme.breakpoints.only("md")]: {
            minHeight: "395px",
        },
        [theme.breakpoints.only("lg")]: {
            minHeight: "565px",
        },
        [theme.breakpoints.only("xl")]: {
            minHeight: "565px",
        },
    },
    partOneContainerWithBottleLabel: {
        [theme.breakpoints.only("md")]: {
            minHeight: "calc(550px)",
        },
        [theme.breakpoints.only("lg")]: {
            minHeight: "calc(565px + 350px)",
        },
        [theme.breakpoints.only("xl")]: {
            minHeight: "calc(565px + 180px)",
        },
    },
    bottleLabel: {
        [theme.breakpoints.up("xs")]: {
            marginTop: "8rem",
        },
        [theme.breakpoints.up("lg")]: {
            marginTop: "20rem",
        },
    },
    tabsContainerStandalone: {
        marginTop: "1rem",
    },
    vintagesScrollContainerStandalone: {
        position: "relative",
        zIndex: 2,
        [theme.breakpoints.only("xs")]: {
            marginLeft: "8px",
            marginRight: "8px",
            backgroundColor: theme.palette.common.white,
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 12",
            marginTop: "4rem",
            backgroundColor: theme.palette.common.white,
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "4 / span 9",
            marginTop: "0",
            backgroundColor: theme.palette.common.white,
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "3 / span 10",
            gridRow: "1 / span 1",
            backgroundColor: "transparent",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "3 / span 6",
            backgroundColor: theme.palette.common.white,
        },
    },
    bottle_img_standalone: {
        position: "absolute",
        zIndex: 10,
        [theme.breakpoints.only("xs")]: {
            right: "42%",
            height: "150px",
            top: "-16px",
        },
        [theme.breakpoints.only("sm")]: {
            right: "42%",
            height: "278px",
            top: "-30px",
        },
        [theme.breakpoints.only("md")]: {
            top: -25,
            height: "420px",
            right: "25%",
        },
        [theme.breakpoints.only("lg")]: {
            right: "25%",
            height: "482px",
            top: 40,
        },
        [theme.breakpoints.only("xl")]: {
            right: "25%",
            height: "482px",
            top: 40,
        },
    },
    background_bottle_standalone: {
        [theme.breakpoints.only("xs")]: {
            top: "9px",
            left: "-7px",
            height: "100px",
            position: "absolute",
        },
        [theme.breakpoints.only("sm")]: {
            top: "25px",
            left: "-14px",
            height: "180px",
            position: "absolute",
        },
        [theme.breakpoints.only("md")]: {
            height: "280px",
            position: "relative",
            bottom: -30,
            left: -18,
        },
        [theme.breakpoints.only("lg")]: {
            height: "280px",
            position: "relative",
            bottom: -30,
            left: -18,
        },
        [theme.breakpoints.only("xl")]: {
            height: "280px",
            position: "relative",
            bottom: -30,
            left: -18,
        },
    },
    card: {
        [theme.breakpoints.up("lg")]: {
            backgroundImage: `url(${fioriture}) !important`,
            backgroundPosition: "top left !important",
        },
    },
}));

export const FrontConnectedWinePage = (props) => {
    const { wineEntityShortId, propertyName, wineShortId, year, cuvee, locale: pageLocale } = useParams();
    const settingsLocale = useSelector((state) => state?.user?.locale);
    const locale = pageLocale || settingsLocale;
    const slugVintage = urlize(formatConcat([year, cuvee], "-"));

    const { loading, data, error } = useFrontVintageQuery({
        variables: { wineShortId, slug: slugVintage, locale },
    });

    const { data: { FrontWinePresentation: wineData } = {}, loading: loadingWineData } = useFrontWinePresentationQuery({
        variables: {
            wineShortId,
            locale,
        },
    });

    const getData = data?.FrontVintage ?? wineData;
    const wineId = getData?.wineId;
    const backgroundBottleColor = data?.FrontVintage?.backgroundBottleColor || wineData?.backgroundBottleColor;
    const styles = useStyles({ data, backgroundBottleColor });
    const breadcrumbs = [
        {
            name: getData?.wineEntityName,
            path: `/${wineEntityShortId}-${propertyName}`,
        },
        {
            name: t`Vins`,
            path: `/${wineEntityShortId}-${propertyName}#vins`,
        },
        {
            name: data?.FrontVintage ? `${data?.FrontVintage?.name} ${data?.FrontVintage?.year}` : wineData?.name,
        },
    ];
    const previousPage = {
        name: t`Tous les vins de ${getData?.wineEntityName}`,
        path: `/${wineEntityShortId}-${propertyName}#vins`,
    };

    if (loading || loadingWineData) {
        return <LoadingWithImage />;
    }

    if (!wineData) {
        return <Redirect to="/" />;
    }

    return (
        <GenericWinePage
            isVintage={Boolean(year)}
            vintageId={data?.FrontVintage?.id}
            error={error}
            breadcrumbs={breadcrumbs}
            previousPage={previousPage}
            vintage={data?.FrontVintage}
            data={getData}
            wineId={wineId}
            locale={locale}
            wineEntityId={getData?.wineEntityId}
            wineEntityName={getData?.wineEntityName}
            frontTitle
            wineSectionTitle={t`Les autres vins de la propriété`}
            showMoreWines={t`Découvrir tous les vins`}
            winesListRef={props.winesListRef}
            bottleContainerClassName={styles.backgroundBottleContainer}
            vintagesScrollContainerClassName={styles.vintagesScrollContainer}
            bottleImgClassName={styles.bottle_img}
            backgroundBottleClassName={styles.background_bottle}
            tabsContainerClassName={styles.tabsContainer}
        />
    );
};

FrontConnectedWinePage.propTypes = {
    winesListRef: any,
};

export const StandaloneConnectedWinePage = (props) => {
    let { vintageId, locale: pageLocale } = useParams();
    const settingsLocale = useSelector((state) => state?.user?.locale);
    const locale = pageLocale || settingsLocale;
    const { loading, data, error } = useFrontVintageQuery({
        variables: { vintageId, locale },
    });

    const styles = useStyles({ data, props });
    const breadcrumbs = [
        {
            name: data?.FrontVintage?.wineEntityName,
            path: "/",
        },
        {
            name: t`Vins`,
            path: "/#vins",
        },
        {
            name: `${data?.FrontVintage?.name} ${data?.FrontVintage?.year}`,
        },
    ];

    const previousPage = {
        name: t`Tous nos vins`,
        path: "/#vins",
    };

    if (loading) {
        return <LoadingWithImage />;
    }

    return (
        <GenericWinePage
            standalone
            error={error}
            breadcrumbs={breadcrumbs}
            previousPage={previousPage}
            vintage={data?.FrontVintage}
            vintageId={vintageId}
            locale={locale}
            wineId={data?.FrontVintage?.wineId}
            wineEntityId={props.wineEntityId}
            wineEntityName={props.wineEntityName}
            hideFloritureTopImg
            titleStandalone
            logo={props.logo}
            boxStyles={{ paddingTop: "5rem" }}
            wineSectionTitle={t`Nos autres vins`}
            showMoreWines={t`Découvrir tous nos vins`}
            activitiesSectionTitle={t`Nos activités`}
            contactSectionTitle={t`Contactez-nous !`}
            winesListRef={props.winesListRef}
            bottleContainerClassName={styles.backgroundBottleContainerStandalone}
            tabsContainerClassName={styles.tabsContainerStandalone}
            vintagesScrollContainerClassName={styles.vintagesScrollContainerStandalone}
            bottleImgClassName={styles.bottle_img_standalone}
            backgroundBottleClassName={styles.background_bottle_standalone}
            classNameCard={styles.card}
            partOneContainerClassName={
                data?.FrontVintage?.bottleLabel?.data ? styles.partOneContainerWithBottleLabel : styles.partOneContainer
            }
            bottleLabelClassName={data?.FrontVintage?.bottleLabel?.data && styles.bottleLabel}
        />
    );
};

StandaloneConnectedWinePage.propTypes = {
    wineEntityName: string,
    wineEntityId: string,
    wineId: string,
    winesListRef: any,
    logo: string,
};
