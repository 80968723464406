import { t, Trans } from "@lingui/macro";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";

import bg from "../assets/bg-single-post.jpg";
import hourglass from "../assets/post/hourglass.svg";
import Loading from "../Components/Loader/Loading";
import CmsAccordion from "../Front/cms/CmsAccordion";
import FrontContentTop from "../Front/FrontContentTop";
import FrontMainContainer from "../Front/FrontMainContainer";
import { SwiperWrapper } from "../Front/SwiperCards";
import FeretVisitEncyclopedia from "../FrontPost/FeretVisitEncyclopedia";
import FrontTitle from "../FrontRanking/FrontTitle";
import {
    useGetArticleContentLazyQuery,
    useGetArticleFrontQuery,
    useGetArticlesByAuthorIdLazyQuery,
} from "../generated/graphql";
import theme from "../globalTheme";
import { parseHtml, urlize } from "../helpers/helpers";
import { useCustomHistory } from "hooks";
import FeretPublishCard from "FrontAuthor/FeretPublishCard";
import Page404 from "Components/ErrorPage/Page404";
import Page500Front from "Components/ErrorPage/Page500Front";
import Helmet from "Components/Helmet/Helmet";
import LoadingWithImage from "Components/Loader/LoadingWithImage";

const useStyles = makeStyles((e) => ({
    title_subtitle: {
        fontFamily: "Lato",
        fontWeight: "normal",
        color: theme.palette.common.white,
        textAlign: "center",
        margin: "0 auto 5px",
        cursor: "pointer",
        textDecoration: "none",
        display: "block",
        width: "fit-content",
        [theme.breakpoints.up("xl")]: {
            marginBottom: "8px",
        },
    },
    container_slider_1: {
        justifyContent: "center",
        paddingLeft: "16px",
        paddingRight: "16px",

        [theme.breakpoints.up("xl")]: {
            width: "calc(1314px)",
            margin: "auto",
        },
    },
    container_content_primary: {
        gridColumn: "1 / span 12",
    },
    content_scollable: {
        overflowY: "hidden",
        overflowX: "hidden",
        minHeight: "300px",
        display: "flex",
        [theme.breakpoints.up("lg")]: {
            minHeight: "700px",
        },
        width: "100%",
    },
    accordion: {
        marginTop: "27px",
        marginNottom: "25px",
    },
    frontTitle: {
        maxWidth: "none",

        [theme.breakpoints.down("xl")]: {
            paddingBottom: "0 !important",
        },
    },
    container_time: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "4px",
        marginBottom: 5,

        [theme.breakpoints.up("md")]: {
            marginBottom: 10,
        },

        [theme.breakpoints.up("xl")]: {
            marginBottom: 27,
        },
    },
    typo_time: {
        color: "#E9EBB7",
    },
    pagination_container: {
        display: "flex",
        justifyContent: "flex-end",
    },
}));

const PostPage = () => {
    const styles = useStyles();
    const { title } = useParams();
    const locale = useSelector((state) => state?.user?.locale || "fr");
    const beginRef = useRef();
    const history = useCustomHistory();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const pageNumber = searchParams.get("page");
    const page = parseInt(pageNumber) - 1;

    const { data: { GetArticleFront: article } = {}, loading: articleLloading, error } = useGetArticleFrontQuery({
        variables: { name: urlize(title), locale, page },
    });
    const handlePageClick = (p) => {
        searchParams.set("page", p);
        history.replace({ search: searchParams.toString() });
    };
    const [
        getContent,
        { data: { GetArticleContent: articleContent } = {}, loading: contentLoading, error: errorArticleContent },
    ] = useGetArticleContentLazyQuery();

    const [
        getAuthorArticle,
        { data: { GetArticlesByAuthorId: authorArticles } = [], error: errorAuthorArticles },
    ] = useGetArticlesByAuthorIdLazyQuery();

    useEffect(() => {
        if (article) {
            getAuthorArticle({ variables: { authorId: article.authorId, locale } });
        }
    }, [article]);

    useEffect(() => {
        if (article) {
            getContent({
                variables: { articleId: article._id, locale, page },
            });
        }
    }, [article, page]);

    useEffect(() => {
        if (!contentLoading && articleContent && beginRef.current) {
            const element = beginRef.current.getBoundingClientRect();
            const dest = window.scrollY + element?.top - 75;
            window.scrollTo({ behavior: "smooth", top: dest });
        }
    }, [articleContent, contentLoading]);

    useEffect(() => {
        if (!page) {
            handlePageClick(1);
        }
    }, [page]);

    const breadcrumbs = [
        {
            name: t`L'encyclopédie Féret`,
            path: "/encyclopedie-feret",
        },
        {
            name: article?.name,
        },
    ];

    if (articleLloading) {
        return <LoadingWithImage />;
    }

    if (!article) {
        return <Page404 />;
    }

    if (error || errorArticleContent || errorAuthorArticles) {
        return <Page500Front />;
    }

    return (
        <>
            <Helmet
                title={article?.name}
                content={`Découvrez notre article : ${article?.name}, abordant des domaines d'expertise précis liés au monde viticole. Enrichissez vos connaissances et devenez un expert en la matière avec Féret.`}
            />
            <FrontContentTop
                backgroundImage={bg}
                links={breadcrumbs}
                previousPage={{
                    name: t`L'encyclopédie Féret`,
                    path: "/encyclopedie-feret",
                }}
            >
                {article?.name && <FrontTitle className={styles.frontTitle} title={article?.name} />}
                <Typography
                    variant="h3"
                    className={styles.title_subtitle}
                    component={Link}
                    to={"/encyclopedie-feret/auteur/" + article?.authorSlug}
                >
                    {article?.author && <span style={{ fontWeight: "bold" }}>{article?.author}</span>}
                    {article?.author && article?.authorInfo && ", "}
                    {article?.authorInfo && article?.authorInfo}
                </Typography>

                {article?.readTime && (
                    <Box className={styles.container_time}>
                        <img src={hourglass} alt="icone temps de lecture" />
                        <Typography variant="h5" className={styles.typo_time}>
                            <Trans>temps de lecture estimé :</Trans> {article?.readTime}
                        </Typography>
                    </Box>
                )}

                <FrontMainContainer
                    styleCardContent
                    className={styles.main_container}
                    style={{ backgroundImage: "none" }}
                    ref={beginRef}
                >
                    <Box className={styles.container_content_primary}>
                        <Box className={styles.content_scollable}>
                            {contentLoading ? (
                                <Loading boxStyle={{ margin: "auto" }} />
                            ) : (
                                <Typography style={{ width: "inherit" }} variant="body2">
                                    {parseHtml(articleContent?.content, "inheritWidth")}
                                </Typography>
                            )}
                        </Box>
                        {article?.pageCount > 1 && (
                            <Box className={styles.pagination_container}>
                                <Pagination
                                    className={styles.pagination}
                                    count={article.pageCount}
                                    shape="rounded"
                                    page={page + 1}
                                    onChange={(event, pageNumber) => handlePageClick(pageNumber)}
                                />
                            </Box>
                        )}
                        {article?.references?.length > 0 && (
                            <Box className={styles.accordion}>
                                <Box style={{ marginTop: "16px" }}>
                                    <CmsAccordion
                                        type="postReferences"
                                        datas={[
                                            {
                                                content: article.references.map(
                                                    ({ place, content }) => `${place}. ${content}`
                                                ),
                                            },
                                        ]}
                                        iconStyle={{ width: 14, height: 21 }}
                                        title={t`Références`}
                                        panelNotActive
                                        itemMarginBottom={0}
                                        itemMarginTop={0}
                                        itemPadding={0}
                                    />
                                </Box>
                            </Box>
                        )}
                    </Box>
                </FrontMainContainer>

                {authorArticles?.length > 1 && (
                    <Box className={styles.container_slider_1}>
                        <SwiperWrapper title="Ses autres publications sur bordeaux.feret.com" spaceBetween={0} noMargin>
                            {authorArticles.map((article, i) => (
                                <FeretPublishCard key={i} title={article.name} slug={article.slugFr} italic />
                            ))}
                        </SwiperWrapper>
                    </Box>
                )}
            </FrontContentTop>
            <FeretVisitEncyclopedia />
        </>
    );
};

export default PostPage;
