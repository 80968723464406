import { shape, string } from "prop-types";
import { Box, Card, CardContent, Typography, makeStyles } from "@material-ui/core";

import theme from "../globalTheme";
import floriture1 from "../assets/author/floriture3.svg";
import floriture2 from "../assets/author/floriture4.svg";
import BaseButton from "../Components/Buttons/BaseButton";
import { formatConcat, formatPerson, urlize } from "../helpers/helpers";
import { Link } from "react-router-dom";
import { useCustomHistory } from "hooks";

const useStyles = makeStyles((e) => ({
    container_card: {
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
        },
    },
    image_container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "transparent",
        borderRadius: "100%",
        marginTop: 19,
        marginBottom: 24,
    },
    card_name: {
        color: theme.palette.primary.dark,
        fontWeight: "bold",
        marginBottom: 0,
        textAlign: "center",
    },
    button: {
        border: "none",
        padding: 0,
    },
}));

const FeretAuthorCard = ({ personIdentity, photo }) => {
    const styles = useStyles();
    const urlParam = formatConcat([personIdentity.firstName, personIdentity.lastName], " ");
    const { addLocale } = useCustomHistory();
    const url = addLocale(`/encyclopedie-feret/auteur/${urlize(urlParam)}`);

    return (
        <BaseButton classes={styles.button} component={Link} to={url}>
            <Card
                style={{
                    borderRadius: "10px",
                    width: 244,
                    height: 200,
                    padding: "16px 16px 20px",
                    backgroundColor: theme.palette.common.white,
                    backgroundImage: `url(${floriture1}), url(${floriture2})`,
                    backgroundPositionX: "left, right",
                    backgroundPositionY: "bottom, top",
                    backgroundRepeat: "no-repeat",
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.08)",
                }}
            >
                <CardContent style={{ padding: "0px", display: "flex", flexDirection: "column" }}>
                    <Box className={styles.image_container}>
                        {photo && (
                            <img
                                src={photo}
                                style={{ margin: "2px auto", width: 126, height: 126 }}
                                alt="photo challenge"
                            />
                        )}
                    </Box>

                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            height: 80,
                        }}
                    >
                        <Typography variant="h4" className={styles.card_name}>
                            {formatPerson(personIdentity)}
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </BaseButton>
    );
};

FeretAuthorCard.propTypes = {
    personIdentity: shape({
        title: string,
        firstName: string,
        lastName: string,
    }),
    photo: string,
};

export default FeretAuthorCard;
