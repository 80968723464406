import { t, Trans } from "@lingui/macro";
import { Box, Icon, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Helmet from "Components/Helmet/Helmet";

import facebook from "../assets/contact/facebook.svg";
import fioritureBottomLeft from "../assets/contact/fioriture_bottom.svg";
import fioritureTopRight from "../assets/contact/fioriture_right.svg";
import instagram from "../assets/contact/instagram.svg";
import linkedin from "../assets/contact/linkedin.svg";
import pattern from "../assets/contact/pattern_contact.svg";
import place from "../assets/contact/place.svg";
import Arrow from "../Components/Arrow/Arrow";
import ContactForm from "../Components/Forms/ContactForm";
import FrontContentTop from "../Front/FrontContentTop";
import FrontMainContainer from "../Front/FrontMainContainer";
import FrontTitle from "../Front/FrontTitle";
import theme from "../globalTheme";
import { useWindowDimensions } from "../helpers/helpers";
import { feretAddress, feretPostalCode } from "config/constants";

const useStyles = makeStyles(() => ({
    card: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        [theme.breakpoints.up("xs")]: {
            backgroundImage: `url(${fioritureBottomLeft})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom left",
        },
        [theme.breakpoints.up("md")]: {
            backgroundImage: `url(${fioritureBottomLeft}), url(${fioritureTopRight})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom left, top right",
        },
    },
    card_content: {
        gridTemplateColumns: "repeat(12, 1fr)",
        display: "grid",
        columnGap: "1.5rem",
    },
    text_container: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "1 / span 12",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "1 / span 12",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 5",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 6",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "1 / span 6",
        },
    },
    contact_form: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "1 / span 12",
            marginTop: "1rem",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "1 / span 12",
            marginTop: "1rem",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "6 / span 7",
            marginTop: "0rem",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "7 / span 6",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "7 / span 6",
        },
    },
    place_container: {
        [theme.breakpoints.up("xs")]: {
            display: "none",
        },
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
        [theme.breakpoints.up("md")]: {
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            marginTop: "4rem",
            gridColumn: "1 / span 5",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 6",
        },
        [theme.breakpoints.up("xl")]: {
            gridColumn: "1 / span 6",
        },
    },
    social_media: {
        [theme.breakpoints.up("xs")]: {
            display: "none",
        },

        [theme.breakpoints.up("md")]: {
            display: "flex",
            gap: "2rem",
        },
    },
    icon: {
        width: "fit-content",
        height: "fit-content",
    },
}));

const ContactPage = (props) => {
    const styles = useStyles();
    const { width } = useWindowDimensions();

    return (
        <>
            <Helmet
                title={t`Contact`}
                content="Vous avez des questions ou des remarques sur nos publications, nos offres, ou nos services ? Contactez-nous via notre formulaire en ligne et notre équipe se fera un plaisir de vous répondre dans les plus brefs délais. Nous sommes à votre écoute !"
            />
            <FrontContentTop
                backgroundColor={theme.palette.secondary.main}
                boxStyles={{
                    backgroundImage: `url(${pattern})`,
                    backgroundPositionY: "center",
                }}
            >
                <FrontTitle style={{ color: theme.palette.primary.dark }} title={t`Restons en contact`} />
                <FrontMainContainer
                    hideFloritureTopImg
                    styleCardContent
                    className={styles.card}
                    classNameCardContent={styles.card_content}
                    style={{}}
                >
                    <Box className={styles.text_container}>
                        <Box>
                            <Typography variant="h3" style={{ fontWeight: 700, marginBottom: "1rem" }}>
                                <Trans>Contactez-nous !</Trans>
                            </Typography>
                            <Typography variant="h5">
                                <Trans>
                                    Vous souhaitez nous poser une question, nous faire une remarque ou bien rejoindre
                                    notre équipe ?
                                </Trans>
                            </Typography>
                            <Arrow
                                textBoxStyle={{
                                    marginLeft: width < 960 ? "0px" : "8px",
                                    marginRight: "8px",
                                    marginTop: "2px",
                                }}
                                rotation="0"
                                arrowPosition="start"
                                icon="white"
                                arrowStyle={{ display: width < 960 && "none" }}
                            >
                                <Typography variant="h5" style={{ fontFamily: "Mansalva" }}>
                                    <Trans>
                                        Remplissez le questionnaire, nous reviendrons vers vous le plus rapidement
                                        possible.
                                    </Trans>
                                </Typography>
                            </Arrow>
                            <Box className={styles.place_container}>
                                <Icon className={styles.icon}>
                                    <img src={place} />
                                </Icon>
                                <Typography variant="h5">
                                    {feretAddress}, {feretPostalCode}
                                </Typography>
                            </Box>
                        </Box>
                        <Box className={styles.social_media}>
                            <Icon
                                className={styles.icon}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    window.open("https://www.facebook.com/Feret1812/", "_blank");
                                }}
                            >
                                <img src={facebook} />
                            </Icon>
                            <Icon
                                className={styles.icon}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    window.open("https://www.instagram.com/editions_feret/", "_blank");
                                }}
                            >
                                <img src={instagram} />
                            </Icon>
                            <Icon
                                className={styles.icon}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    window.open("https://www.linkedin.com/company/feret", "_blank");
                                }}
                            >
                                <img src={linkedin} />
                            </Icon>
                        </Box>
                    </Box>
                    <ContactForm className={styles.contact_form} />
                </FrontMainContainer>
            </FrontContentTop>
        </>
    );
};

ContactPage.propTypes = {};

export default ContactPage;
