import { bool, number, string } from "prop-types";
import { Box, Card, CardContent, Typography, makeStyles } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";

import theme from "globalTheme";
import floriture1 from "assets/author/floriture5.svg";
import floriture2 from "assets/author/floriture6.svg";
import floriture3 from "assets/organization/card-floriture-bottom.svg";
import { urlize } from "helpers/helpers";
import Tooltip from "Components/Tooltips/Tooltip";
import { useCustomHistory } from "hooks";

const useStyles = makeStyles(() => ({
    card: (props) => ({
        width: 292,
        height: props.height ?? 132,
        padding: "0px",
        backgroundColor: props.bgColor ?? theme.palette.primary.main,
        backgroundImage: `url(${floriture1}), url(${props.floritureBottomGray ? floriture3 : floriture2})`,
        backgroundPositionX: "left, right",
        backgroundPositionY: "top, bottom",
        backgroundRepeat: "no-repeat",
        boxShadow: "none",
        borderRadius: "10px",
        cursor: "pointer",
        textDecoration: "none",
    }),
    card_content_container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        marginTop: "0",
        padding: "16px 16px 22px",
        height: "90px",
    },
    item_title: (props) => ({
        textAlign: "center",
        width: "90%",
        marginLeft: "auto",
        marginRight: "auto",
        color: props.bgColor ? theme.palette.primary.main : theme.palette.common.white,
        fontStyle: props.italic ? "italic" : "normal",
        fontWeight: props.bold ? 700 : 500,
    }),
    link: {
        display: "flex",
        cursor: "pointer",
        textDecoration: "none",

        "&:hover": {
            textDecoration: "underline",
            color: theme.palette.common.white,
        },
    },
}));

const FeretPublishCard = (props) => {
    const styles = useStyles(props);
    const history = useCustomHistory();

    const path = props.path ?? "/encyclopedie-feret/article/";
    const slug = props.slug ?? urlize(props?.title);
    const url = history.addLocale(`${path}${slug}`);

    const limitTitle = 56;

    const getCardRender = () => {
        return (
            <Card className={styles.card} component={Link} to={url}>
                <CardContent className={styles.card_content_container}>
                    <Box
                        style={{
                            marginBottom: "16px",
                            minHeight: props?.titleHeight,
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        {props.title && (
                            <Typography variant="h4" className={styles.item_title}>
                                {props.title.length < limitTitle
                                    ? props.title
                                    : `${props.title.slice(0, limitTitle)}...`}
                            </Typography>
                        )}
                    </Box>

                    <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Link to={url} className={styles.link}>
                            <Typography
                                variant="body2"
                                style={{
                                    color: props.bgColor ? theme.palette.primary.main : theme.palette.common.white,
                                    marginRight: "16px",
                                }}
                            >
                                <Trans>Accéder</Trans>
                            </Typography>
                            <ArrowForwardIcon
                                style={{
                                    color: props.bgColor ? theme.palette.primary.main : theme.palette.common.white,
                                }}
                            />
                        </Link>
                    </Box>
                </CardContent>
            </Card>
        );
    };

    if (props?.title?.length > limitTitle) {
        return (
            <Tooltip color="primary" title={props?.title} arrow>
                {getCardRender()}
            </Tooltip>
        );
    }

    return getCardRender();
};

FeretPublishCard.propTypes = {
    title: string.isRequired,
    bgColor: string,
    italic: bool,
    bold: bool,
    height: number,
    titleHeight: number,
    floritureBottomGray: bool,
    path: string,
};

export default FeretPublishCard;
