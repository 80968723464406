import React, { useState } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import PropTypes, { any, bool, object, string } from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import bottle from "../../assets/propriétéPage/cellarPopin/bottle.svg";
import conditionnement from "../../assets/propriétéPage/cellarPopin/conditionnement.svg";
import contenant from "../../assets/propriétéPage/cellarPopin/tank.svg";
import elevage from "../../assets/propriétéPage/cellarPopin/elevage.svg";
import CellarDetailsPopin from "../../FrontEstate/Popins/CellarDetailsPopin";
import { t } from "@lingui/macro";
import theme from "../../globalTheme";

const useStyles = makeStyles((theme) => ({
    mediaContainer: {
        borderRadius: "5px",
        [theme.breakpoints.up("xs")]: {},
        [theme.breakpoints.up("sm")]: {},
        [theme.breakpoints.up("md")]: {
            width: "245px",
            height: "148px",
        },
        [theme.breakpoints.up("lg")]: {
            width: "208px",
            height: "120px",
        },
    },
    media: {
        width: "100%",
        height: "100%",
        borderRadius: "5px",
    },
    title: {
        ...theme.front.typography.h6.dark_b,
        textAlign: "initial",
    },
    subtitle: {
        color: theme.palette.secondary.dark,
        fontSize: "14px",
    },
    mainBox: {
        cursor: "pointer",
        [theme.breakpoints.only("xs")]: {
            marginBottom: "42px",
        },
        [theme.breakpoints.only("sm")]: {
            marginBottom: "42px",
        },
    },
    arrow: (props) => ({
        color: props.arrowColor ? props.arrowColor : theme.palette.primary.dark,
    }),
    popper: (props) => ({
        fontSize: "12px",
        backgroundColor: props.bgColor ? props.bgColor : theme.palette.primary.dark,
        borderRadius: props.borderRadius ? props.borderRadius : "12px",
        boxShadow: props.boxShadow ? props.boxShadow : "0px 3px 76px #00000014",
        padding: "8px",
    }),
}));

const FrontCellarCard = (props) => {
    const styles = useStyles();
    const [open, setOpen] = useState(false);

    return (
        <>
            {open ? (
                <CellarDetailsPopin
                    alt={`${props.cellarName} - ${props.wineEntityName}`}
                    open={open}
                    onClose={() => setOpen(false)}
                    cellarId={props.cellarId}
                    wineEntityId={props.wineEntityId}
                    locale={props.locale}
                />
            ) : (
                ""
            )}
            <Box className={styles.mainBox} onClick={() => setOpen(true)}>
                <Box className={styles.mediaContainer}>
                    <img
                        alt={`${props.cellarName} - ${props.wineEntityName}`}
                        src={props.picture}
                        className={styles.media}
                    />
                </Box>
                <Box
                    style={{
                        display: "flex",
                        alignItems: "baseline",
                        marginTop: "5px",
                        marginBottom: theme.spacing(2),
                    }}
                >
                    <Typography className={styles.title} variant="h5">
                        {props.cellarName}
                    </Typography>
                    {props.surfaceAreaValue && (
                        <Box style={{ display: "flex", alignItems: "center", marginLeft: "1rem" }}>
                            <Typography className={styles.subtitle} variant="h5">
                                {" "}
                                {props.surfaceAreaValue} {t`m²`}{" "}
                            </Typography>
                        </Box>
                    )}
                </Box>
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <Box style={{ display: "flex" }}>
                        {props.isVathouse && (
                            <Tooltip
                                color="primary"
                                title={t`Vinification`}
                                arrow
                                classes={{
                                    arrow: styles.arrow,
                                    tooltip: styles.popper,
                                }}
                            >
                                <img src={contenant} alt="cellar_type_img" style={{ marginRight: "12px" }} />
                            </Tooltip>
                        )}
                        {props.isAgeing && (
                            <Tooltip
                                color="primary"
                                title={t`Élevage`}
                                arrow
                                classes={{
                                    arrow: styles.arrow,
                                    tooltip: styles.popper,
                                }}
                            >
                                <img src={elevage} alt="cellar_type_img" style={{ marginRight: "12px" }} />
                            </Tooltip>
                        )}
                        {props.isConditioning && (
                            <Tooltip
                                color="primary"
                                title={t`Embouteillage et conditionnement`}
                                arrow
                                classes={{
                                    arrow: styles.arrow,
                                    tooltip: styles.popper,
                                }}
                            >
                                <img src={conditionnement} alt="cellar_type_img" style={{ marginRight: "12px" }} />
                            </Tooltip>
                        )}
                        {props.isStorage && (
                            <Tooltip
                                color="primary"
                                title={t`Stockage`}
                                arrow
                                classes={{
                                    arrow: styles.arrow,
                                    tooltip: styles.popper,
                                }}
                            >
                                <img src={bottle} alt="cellar_type_img" style={{ marginRight: "12px" }} />
                            </Tooltip>
                        )}
                    </Box>
                </Box>
            </Box>
        </>
    );
};

FrontCellarCard.propTypes = {
    locale: string,
    picture: string,
    cellarName: string,
    surfaceAreaValue: string,
    surfaceAreaUnit: string,
    imageAlt: string,
    isStorage: bool,
    isVathouse: bool,
    isConditioning: bool,
    isAgeing: bool,
    style: object,
    cellarId: any,
    wineEntityId: any,
    wineEntityName: string,
};

export default FrontCellarCard;
