import { Box, Typography, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import theme from "../../globalTheme";
import { useWindowDimensions } from "../../helpers/helpers";

const useStyles = makeStyles(() => ({
    container_title: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "max-content",
    },
    title: {
        color: theme.palette.primary.light,
        fontWeight: "bold",
    },
    content: {
        color: theme.palette.primary.dark,
        marginTop: "13px",
    },
    item: {
        width: "fit-content",
        padding: "1.5rem",
        background: theme.action.disabledBackground,
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
    },
}));

const ServiceItem = (props) => {
    const styles = useStyles();
    const { width } = useWindowDimensions();

    return (
        <Box className={styles.item}>
            <Box className={styles.container_title}>
                <img src={props.icon} alt="title_icon" style={{ marginRight: "7px" }} />
                <Typography variant="h6" className={styles.title}>
                    {props?.title}
                </Typography>
            </Box>
            <Typography variant="body2" className={styles.content}>
                {props?.content}
            </Typography>
        </Box>
    );
};

ServiceItem.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    icon: PropTypes.string,
};

export default ServiceItem;
