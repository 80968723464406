import { any, bool, string } from "prop-types";
import { Box, Typography } from "@material-ui/core";
import theme from "../../../globalTheme";
import { PAYMENT_METHODS } from "../../../config/constants";

const ThanksPurchase = (props) => {
    if (props.paymentMethod === PAYMENT_METHODS.bankTransfer) {
        return (
            <Box style={{ width: "max-content", margin: "auto", color: theme.palette.primary.dark }}>
                <Typography variant="h6" style={{ textAlign: "cen ter" }}>
                    {props.subscriptionFlow
                        ? "Pensez à indiquer la référence de votre inscription lors de votre virement"
                        : "Pensez à indiquer la référence de votre commande lors de votre virement"}{" "}
                    :
                </Typography>
                <Box
                    display={"flex"}
                    justifyContent="space-between"
                    style={{ backgroundColor: theme.palette.background.card, padding: "0 2rem" }}
                >
                    <Typography variant="h6" style={{ fontWeight: 700 }}>
                        Ref virement :
                    </Typography>
                    <Typography variant="h6">PTYESS22</Typography>
                </Box>
            </Box>
        );
    } else if (props.paymentMethod === PAYMENT_METHODS.bankCheck) {
        return (
            <Box style={{ width: "max-content", margin: "auto", color: theme.palette.primary.dark }}>
                <Typography variant="h6" style={{ textAlign: "cen ter" }}>
                    {props.subscriptionFlow
                        ? "Pensez à indiquer la référence de votre inscription au dos de votre chèque"
                        : "Pensez à indiquer la référence de votre commande au dos de votre chèque"}{" "}
                    :
                </Typography>
                <Box
                    display={"flex"}
                    justifyContent="space-between"
                    style={{ backgroundColor: theme.palette.background.card, padding: "0 2rem" }}
                >
                    <Typography variant="h6" style={{ fontWeight: 700 }}>
                        Ref. à indiquer :
                    </Typography>
                    <Typography variant="h6">PTYESS22</Typography>
                </Box>
            </Box>
        );
    } else {
        return (
            props.customText ?? (
                <Typography variant="h6" style={{ textAlign: "center" }}>
                    Surveillez vos e-mails, nous reviendrons très rapidement vers vous.
                </Typography>
            )
        );
    }
};

ThanksPurchase.propTypes = {
    paymentMethod: string,
    customText: any,
    subscriptionFlow: bool,
};

export default ThanksPurchase;
