import PropTypes, { bool, string } from "prop-types";
import { Box, IconButton, makeStyles } from "@material-ui/core";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";

import "swiper/swiper-bundle.min.css";
import "../FrontEstate/Components/Cards/cellarSwipe.css";
import theme from "globalTheme";
import EstateTitleSemiUnderlined from "../FrontEstate/Components/EstateTitledSemiUnderlined";
import { useRef, useState } from "react";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
SwiperCore.use([Navigation]);

const useStyles = makeStyles(() => ({
    buttons_container: (props) => ({
        display: "flex",
        gap: "2rem",
        alignItems: "center",
        marginBottom: "1rem",
        justifyContent: props.centerButtons ? "center" : "start",
        [theme.breakpoints.down("xs")]: {
            justifyContent: "space-evenly",
            gap: "0",
        },
    }),
    button: {
        height: "40px",
        width: "40px",
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
        "&:disabled": {
            backgroundColor: theme.palette.secondary.main,
        },
    },
    button_disabled: {
        backgroundColor: theme.palette.secondary.main,
        "&:hover": {
            backgroundColor: theme.palette.secondary.main,
        },
    },
    containerActivityCards: (props) => ({
        "& .swiper-container": {
            display: "flex",
            flexDirection: "column",
        },
        "& .swiper-slide": {
            width: "fit-content",
            backgroundColor: "transparent",
            textAlign: "left",
        },

        "& .swiper-wrapper": {
            paddingBottom: props?.wrapperPaddingBottom ?? "32px",
            display: "flex",
            justifyContent: props.itemsAlign ?? "space-between",
        },
        "& .swiper-slide img": {
            height: "auto",
            width: "auto",
        },
    }),
}));

const FrontSwiperContainer = (props) => {
    const styles = useStyles(props);
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const [_, setInit] = useState();

    return (
        <Box className={styles.containerActivityCards}>
            {props.title && (
                <EstateTitleSemiUnderlined
                    style={{ ...props.titleStyle, textAlign: props.titleCenter ? "center" : "left" }}
                    title={props.title}
                    heightAfter={32}
                    lowBar={props?.lowBar ?? { width: 50 }}
                    borderColor={props?.lowBar?.borderColor ?? theme.palette.primary.light}
                />
            )}
            <Swiper
                slidesPerView={props.slidesPerView ?? "auto"}
                spaceBetween={props.spaceBetween ?? 32}
                navigation={{
                    prevEl: prevRef.current,
                    nextEl: nextRef.current,
                }}
                autoHeight={props.autoHeight}
                onInit={() => setInit(true)}
            >
                {props.children?.length > 0 &&
                    props.children.map((e, i) => (
                        <Box key={i} style={{ height: "100%" }}>
                            <SwiperSlide style={{ padding: props.padding ?? "0px", width: props.width && props.width }}>
                                {e}
                            </SwiperSlide>
                        </Box>
                    ))}

                {props?.lastSlide && (
                    <Box style={{ height: "100%" }}>
                        <SwiperSlide style={{ padding: props.padding ?? "0px" }}>{props?.lastSlide}</SwiperSlide>
                    </Box>
                )}
            </Swiper>
            <Box className={styles.buttons_container}>
                <IconButton
                    classes={{
                        root: styles.button,
                        disabled: styles.button_disabled,
                    }}
                    className={styles.button}
                    ref={prevRef}
                >
                    <ArrowBackIcon style={{ color: theme.palette.common.white }} />
                </IconButton>
                <IconButton
                    classes={{
                        root: styles.button,
                        disabled: styles.button_disabled,
                    }}
                    ref={nextRef}
                >
                    <ArrowForwardIcon style={{ color: theme.palette.common.white }} />
                </IconButton>
            </Box>
        </Box>
    );
};

FrontSwiperContainer.propTypes = {
    title: PropTypes.string,
    children: PropTypes.any,
    padding: PropTypes.string,
    width: PropTypes.number,
    lowBar: PropTypes.object,
    noMargin: PropTypes.bool,
    itemsAlign: PropTypes.string,
    spaceBetween: PropTypes.number,
    lastSlide: PropTypes.any,
    wrapperPaddingBottom: PropTypes.number,
    titleCenter: PropTypes.bool,
    titleStyle: PropTypes.object,
    slidesPerView: string,
    autoHeight: bool,
    centerButtons: bool,
};

export default FrontSwiperContainer;
