import Slider from "@material-ui/core/Slider";
import { makeStyles, Box, Typography, Button } from "@material-ui/core";
import theme from "../globalTheme";
import { t } from "@lingui/macro";
import { any, array, bool, func, number, oneOf, string } from "prop-types";

const useStyles = makeStyles(() => ({
    mainBox: {
        display: "flex",
        flexDirection: "column",
        "& .MuiSlider-rail": (props) => ({
            color: props?.railColor ?? theme.palette.secondary.dark,
        }),
        "& .MuiSlider-track": (props) => ({
            color: props?.trackColor ?? theme.palette.primary.light,
            opacity: 1,
        }),
        "& .MuiSlider-valueLabel": {
            fontSize: "12px",
        },
    },
    wordingText: {
        display: "flex",
        alignItems: "center",
    },
    slider: (props) => ({
        width: props?.width ?? "100%",
        alignSelf: props?.alignSelf ?? "auto",
    }),
    secondLabelTypo: (props) => ({
        margin: "0px 4px",
        color: props.labelStyle?.color || theme.palette.primary.light,
        fontSize: props.labelStyle?.fontSize || "10px",
    }),
    labelTypo: (props) => ({
        color: props.labelStyle?.color || theme.palette.secondary.dark,
        fontSize: props.labelStyle?.fontSize || "10px",
    }),
    valueTypo3: (props) => ({
        margin: "0px 4px",
        color: props.labelStyle?.color || theme.palette.primary.light,
        fontSize: props.labelStyle?.fontSize || "10px",
    }),
    valueTypo4: (props) => ({
        color: theme.palette.secondary.dark,
        fontSize: props.labelStyle?.fontSize || "10px",
    }),
    error: (props) => ({
        fontSize: "12px",
        color: props.error ? theme.palette.error.main : "black",
        marginBottom: theme.spacing(1),
    }),
}));

const RangeSliderCustomSearch = (props) => {
    const styles = useStyles(props);

    const getSecondValue = (value) => {
        if (value) {
            if (value[1] === props.max) {
                return t`+ de ${props.max}`;
            } else {
                return value[1];
            }
        }
    };

    const getPluralOrSingularLabelEnd = (val) => {
        if (props.labelEndType && val <= 1) {
            return props.labelEndTypeSingular;
        }
        if (props.labelEndType && val > 1) {
            return props.labelEndType;
        }
        return "";
    };

    const getWording = (value) => {
        if (value && value?.length !== 0) {
            if (value[0] === value[1]) {
                return (
                    <>
                        <Typography
                            variant="h6"
                            className={styles.labelTypo}
                            style={{ marginLeft: props?.typoMarginLeft ?? 0 }}
                        >
                            {props.labelType ?? ""}{" "}
                        </Typography>
                        <Typography className={styles.secondLabelTypo}> {getSecondValue(value)} </Typography>
                        <Typography variant="h6" className={styles.labelTypo}>
                            {getPluralOrSingularLabelEnd(getSecondValue(value))}
                        </Typography>
                        {props?.resetValues && (
                            <Button
                                onClick={() => {
                                    props.onChange(null, [props.min, props.max]);
                                }}
                                style={{
                                    marginLeft: 5,
                                    padding: 0,
                                    fontSize: "0.75rem",
                                    minWidth: "auto",
                                    alignSelf: "flex-start",
                                    marginTop: "-5px",
                                }}
                            >
                                x
                            </Button>
                        )}
                    </>
                );
            } else if (value[0] === props?.min && value[1] === props?.max && props?.labelAll) {
                return (
                    <>
                        <Typography
                            variant="h6"
                            className={styles.labelTypo}
                            style={{ marginLeft: props?.typoMarginLeft ?? 0 }}
                        >
                            {" "}
                            {props.labelType}
                            {" : "}
                        </Typography>
                        <Typography
                            variant="h6"
                            className={styles.labelTypo}
                            style={{ fontWeight: props?.typoValueBold ? 700 : 400 }}
                        >
                            &nbsp;
                            {props?.labelAll}
                        </Typography>
                    </>
                );
            } else {
                return (
                    <>
                        <Typography
                            variant="h6"
                            className={styles.labelTypo}
                            style={{ marginLeft: props?.typoMarginLeft ?? 0 }}
                        >
                            {" "}
                            {props.labelType + " : "}{" "}
                        </Typography>
                        <Typography
                            className={styles.secondLabelTypo}
                            style={{ fontWeight: props?.typoValueBold ? 700 : 400 }}
                        >
                            {" "}
                            {value[0]} {props?.isPrice ? "€" : null}
                        </Typography>
                        <Typography
                            variant="h6"
                            className={styles.labelTypo}
                            style={{ fontWeight: props?.typoValueBold ? 700 : 400 }}
                        >
                            {" "}
                            {props.labelMiddleType || t`à`}{" "}
                        </Typography>
                        <Typography
                            className={styles.secondLabelTypo}
                            style={{ fontWeight: props?.typoValueBold ? 700 : 400 }}
                        >
                            {" "}
                            {getSecondValue(value)} {props?.isPrice ? "€" : null}{" "}
                        </Typography>
                        <Typography
                            variant="h6"
                            className={styles.labelTypo}
                            style={{ fontWeight: props?.typoValueBold ? 700 : 400 }}
                        >
                            {" "}
                            {getPluralOrSingularLabelEnd(getSecondValue(value))}
                        </Typography>
                        {props?.resetValues && (
                            <Button
                                onClick={() => props.onChange(null, [props.min, props.max])}
                                style={{
                                    marginLeft: 5,
                                    padding: 0,
                                    fontSize: "0.75rem",
                                    minWidth: "auto",
                                    alignSelf: "flex-start",
                                    marginTop: "-5px",
                                }}
                            >
                                x
                            </Button>
                        )}
                    </>
                );
            }
        } else {
            return (
                <>
                    <Typography
                        variant="h6"
                        className={styles.valueTypo4}
                        style={{ marginLeft: props?.typoMarginLeft ?? 0 }}
                    >
                        {" "}
                        {props.labelType + " : "}{" "}
                    </Typography>
                    <Typography className={styles.valueTypo3} style={{ fontWeight: props?.typoValueBold ? 700 : 400 }}>
                        {" "}
                        {value && value[0]} {props?.isPrice ? "€" : null}{" "}
                    </Typography>
                    <Typography variant="h6" className={styles.valueTypo4}>
                        {" "}
                        {props.labelMiddleType || t`à`}{" "}
                    </Typography>
                    <Typography className={styles.valueTypo3} style={{ fontWeight: props?.typoValueBold ? 700 : 400 }}>
                        {" "}
                        {getSecondValue()} {props?.isPrice ? "€" : null}{" "}
                    </Typography>
                    <Typography
                        variant="h6"
                        className={styles.valueTypo4}
                        style={{ fontWeight: props?.typoValueBold ? 700 : 400 }}
                    >
                        {" "}
                        {getPluralOrSingularLabelEnd(getSecondValue())}
                    </Typography>
                    {props?.resetValues ? (
                        <Button
                            onClick={() => {
                                props.onChange(null, [props.min, props.max]);
                            }}
                            style={{
                                // position: "absolute",
                                // top: -5,
                                marginLeft: 5,
                                padding: 0,
                                fontSize: "0.75rem",
                                minWidth: "auto",
                                alignSelf: "flex-start",
                                marginTop: "-5px",
                            }}
                        >
                            x
                        </Button>
                    ) : null}
                </>
            );
        }
    };

    function valueLabelFormat(value) {
        if (props.value && props.value[1] === value && value === props.max) {
            return `${props.initialMinimumValue + (props.max - 1)} ${!props.noShowMore ? "+" : ""}`;
        } else {
            return props.initialMinimumValue + (value - 1);
        }
    }
    const getWordingDependingOnValues = () => {
        if (props.value && props.value?.length > 0) {
            return getWording(props.value);
        } else {
            const val1 = props.initialMinimumValue + (props.value[0] - 1);
            const val2 = props.initialMinimumValue + (props.value[1] - 1);
            return getWording([val1, val2]);
        }
    };

    return (
        <Box className={styles.mainBox} style={props?.styleContainer}>
            <Box className={styles.wordingText}>{getWordingDependingOnValues()}</Box>

            <Slider
                value={props.value}
                defaultValue={props.defaultValue}
                onChange={(event, newValue) => {
                    return props.onChange(event, newValue);
                }}
                onChangeCommitted={(e, v) => {
                    const val1 = props.initialMinimumValue + v[0] - 1;
                    const val2 = props.initialMinimumValue + v[1] - 1;

                    let realValues = [val1, val2];
                    return props.onChange(e, realValues);
                }}
                max={props.max}
                min={props.min}
                valueLabelFormat={valueLabelFormat}
                marks={props.marks}
                scale={props.scale}
                step={props.step}
                track={props.track}
                className={styles.slider}
                style={{ color: props?.colorThumb ?? theme.palette.primary.light }}
                disabled={props.disabled}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                color={props.color}
                name={props.name}
                orientation={props.orientation}
            />
            {props.error && (
                <Typography variant="h6" className={styles.errors}>
                    {props.clue}
                </Typography>
            )}
        </Box>
    );
};

RangeSliderCustomSearch.propTypes = {
    labelType: string,
    labelMiddleType: string,
    labelEndType: string,
    labelEndTypeSingular: string,
    labelAll: string,

    value: array,
    onChange: func,
    defaultValue: number,
    disabled: bool,
    marks: bool,
    max: number,
    min: number,
    name: string,
    orientation: oneOf(["horizontal", "vertical"]),
    scale: func,
    step: number,
    track: oneOf(["normal", "false", "inverted"]),
    color: oneOf(["primary", "secondary"]),

    error: any,
    clue: any,

    initialMinimumValue: any,

    isPrice: bool,
    styleContainer: any,
    resetValues: bool,
    typoMarginLeft: string,
    typoValueBold: bool,
    width: string,
    alignSelf: string,

    colorThumb: string,
    trackColor: string,
    railColor: string,
    noShowMore: bool,
};

export default RangeSliderCustomSearch;
