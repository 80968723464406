import { Box, Card, Icon, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import SwiperCards, { SwiperWrapper } from "../Front/SwiperCards";
import ChallengeEntityCard from "../FrontChallenge/ChallengeEntityCard";
import theme from "../globalTheme";
import { useWindowDimensions } from "../helpers/helpers";
import SimpleInput from "../Components/Inputs/SimpleInput";
import inputSearchIcon from "../assets/navbarfront/inputLoupe.svg";
import { useGetCompetitionListQuery } from "../generated/graphql";
import { t, Trans } from "@lingui/macro";
import Loading from "Components/Loader/Loading";

const useStyle = makeStyles(() => ({
    card: {
        boxShadow: "unset",
        backgroundColor: theme.palette.background.card,
    },
    content_card_odc: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "start",
        [theme.breakpoints.down("lg")]: {
            gap: "2rem",
        },
        [theme.breakpoints.up("lg")]: {
            gap: "2rem",
            padding: "0 3rem 2rem 3rem",
        },
    },
    search: {
        display: "flex",
        justifyContent: "center",
        marginBottom: "2rem",
    },
}));

const OdcTab = () => {
    const styles = useStyle();
    const { width } = useWindowDimensions();
    const [search, setSearch] = useState("");
    const [data, setData] = useState("");
    const { data: otherData, loading } = useGetCompetitionListQuery();
    const otherChallenges = otherData?.GetCompetitionList;

    useEffect(() => {
        if (otherChallenges?.length) {
            setData(otherChallenges);
        }
    }, [otherChallenges]);

    const filteredData = () =>
        data?.length > 0
            ? data.filter((item) => {
                  if (!search) {
                      return true;
                  }
                  return item?.name
                      ?.toUpperCase()
                      .replace("-", " ")
                      .normalize("NFD")
                      .replace(/\p{Diacritic}/gu, "")
                      .includes(search?.toString().toUpperCase());
              })
            : [];

    return (
        <>
            <Typography
                variant="body2"
                style={{ backgroundColor: theme.palette.common.white, padding: "8px", margin: "1rem 0" }}
            >
                <Trans>
                    Régionaux, nationaux ou internationaux, ils sont aussi nombreux que divers et s’adressent à tous
                    types de vins et appellations.
                </Trans>
                <br /> <br />
                <Trans>
                    Les jurys qui sont amenés à les déguster sont composés d’amateurs éclairés, de professionnels du vin
                    ou des deux. Le vin primé est récompensé d’un trophée, d’une médaille en or, argent ou bronze ou
                    d’une reconnaissance sur sa bouteille. <br /> <br />
                    <Trans>
                        Ces concours aident le consommateur à choisir son vin parmi une multitude de références.
                    </Trans>
                </Trans>
            </Typography>
            <Card className={styles.card}>
                <Typography
                    variant="h3"
                    style={{
                        textAlign: "center",
                        margin: "2rem 0",
                        fontWeight: 700,
                        color: theme.palette.primary.dark,
                    }}
                >
                    <Trans>Découvrir les organismes de concours</Trans>
                </Typography>
                <Box className={styles.search}>
                    <SimpleInput
                        boxStyle={{ width: "191px" }}
                        customIcon={
                            <Icon style={{ display: "flex", alignItems: "center" }}>
                                <img src={inputSearchIcon} />
                            </Icon>
                        }
                        borderRadius="50px"
                        startAdornment
                        size="small"
                        name="search"
                        placeholder={t`Rechercher...`}
                        variant="outlined"
                        value={search}
                        onChange={(e) => setSearch(e?.target?.value)}
                    />
                </Box>

                {loading ? (
                    <Loading />
                ) : width >= 1280 ? (
                    <Box className={styles.content_card_odc}>
                        {filteredData().map(({ rewards, name, logo, totalMedals, maxYear }, i) => (
                            <ChallengeEntityCard
                                key={i}
                                rewards={rewards}
                                name={name}
                                logo={logo}
                                totalMedals={totalMedals}
                                maxYear={maxYear}
                            />
                        ))}
                    </Box>
                ) : (
                    <SwiperWrapper>
                        {filteredData()?.map((e, i) => {
                            const key = `challenge_others-${i}`;
                            return (
                                <ChallengeEntityCard
                                    key={key}
                                    name={e.name}
                                    logo={e.logo}
                                    totalMedals={e.totalMedals}
                                    maxYear={e.maxYear}
                                />
                            );
                        })}
                    </SwiperWrapper>
                )}
            </Card>
        </>
    );
};

OdcTab.propTypes = {};

export default OdcTab;
