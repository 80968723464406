import { makeStyles, Radio } from "@material-ui/core";
import { any, bool, func, object, oneOf, string } from "prop-types";
import React from "react";
import theme from "../../globalTheme";

const useStyles = makeStyles(() => ({
    radio: (props) => ({
        padding: 0,
        "& .MuiSvgIcon-root": {
            fill: theme.palette.secondary.main,
        },
        "&::before": {
            content: '""',
            background: props.disabled ? theme.palette.background.card : theme.palette.common.white,
            width: props.size === "small" ? "14px" : "1rem",
            height: props.size === "small" ? "14px" : "1rem",
            top: "4px",
            borderRadius: "50%",
            position: "absolute",
            left: props.size === "small" ? "3px" : "4px",
        },
    }),
    radioChecked: (props) => ({
        "& .MuiSvgIcon-root": {
            "&:nth-child(2)": {
                fill: props.disabled ? theme.palette.secondary.dark : theme.palette.primary.light,
            },
        },
    }),
}));
const BaseRadio = (props) => {
    const styles = useStyles(props);

    return (
        <Radio
            disabled={props.disabled}
            size={props.size}
            checked={props.checked}
            onChange={(e) => props.onChange(e)}
            value={props.value}
            name={props.name}
            inputProps={{ "aria-label": props.value }}
            classes={{
                root: [styles.radio, props?.classNameRadio].join(" "),
                checked: styles.radioChecked,
            }}
        />
    );
};

BaseRadio.propTypes = {
    checked: bool,
    onChange: func,
    value: any,
    name: string,
    size: oneOf(["medium", "small"]),
    disabled: bool,
    classNameRadio: object,
};

export default BaseRadio;
