import { bool, func, string } from "prop-types";
import { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { InputAdornment, makeStyles, TextField, Typography } from "@material-ui/core";
import { useGetCommunePostalCodeQuery } from "generated/graphql";
import globalTheme from "globalTheme";
import { formatConcat } from "helpers/helpers";
import { useSearchDebounce } from "hooks/useDebounce";
import ErrorIcon from "assets/Error.svg";

const useStyles = makeStyles(() => ({
    width: "100%",
    autocomplete: (props) => ({
        "& .MuiSvgIcon-root": {
            fontSize: "20px",
            color: globalTheme.palette.secondary.main,
        },
        "& .MuiAutocomplete-endAdornment": {
            position: "initial",
            marginRight: "4px",
        },
        "& .MuiAutocomplete-clearIndicator": {
            // width: "15px",
            display: props.unit && "none",
            marginRight: props.correctValue || props.error ? "25px" : "0px",
            color: globalTheme.palette.secondary.dark,
        },
        backgroundColor: "white",
        "& .MuiFormLabel-root": {
            color: globalTheme.palette.secondary.dark,
            fontSize: "14px",
            top: "-8px",
            "&.Mui-focused": {
                color: globalTheme.palette.primary.dark,
            },
            "&.Mui-error": {
                color: globalTheme.palette.error.main,
            },
        },
        "& .MuiOutlinedInput-root": {
            paddingLeft: "10px",
            fontSize: "14px",
            height: "34px",
            color: globalTheme.palette.primary.dark,
            borderWidth: "0.2px",
            borderColor: props.error ? globalTheme.palette.error.main : globalTheme.palette.secondary.dark,

            "&.Mui-error": {
                color: globalTheme.palette.error.main,
            },
            "&.Mui-focused": {
                color: globalTheme.palette.primary.dark,
                borderColor: props.error ? globalTheme.palette.error.main : globalTheme.palette.secondary.main,
                borderWidth: "0.2px",
            },
            "&.Mui-disabled": {
                color: globalTheme.palette.secondary.dark,
                backgroundColor: globalTheme.palette.action.disabledBackground,
                borderColor: globalTheme.palette.secondary.dark,
                borderWidth: "0.2px",
            },
            "&:hover fieldset": {
                borderColor: props.error ? globalTheme.palette.error.main : globalTheme.palette.secondary.main,
            },
            "&.Mui-focused fieldset": {
                borderColor: props.error ? globalTheme.palette.error.main : globalTheme.palette.secondary.main,
            },
        },
        "& .MuiOutlinedInput-input": {
            padding: "0px",
        },
        "& .MuiSelect-select": {
            "&:focus": {
                backgroundColor: globalTheme.palette.common.white,
            },
        },
        "& .MuiOutlinedInput-adornedStart": {
            padding: "0px",
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: "translate(16px, 4px) scale(0.75)",
        },
        "& .MuiFormControl-marginNormal": {
            margin: 0,
        },
        "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input": {
            padding: 0,
        },
        "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
            paddingRight: "5px",
        },
        "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
            paddingRight: "5px",
        },
    }),
    option: {
        fontSize: "14px",
        color: globalTheme.palette.primary.dark,
        "&:hover": {
            backgroundColor: globalTheme.palette.dashboard.background,
        },
        "&.MuiListItem-root.Mui-selected": {
            backgroundColor: globalTheme.palette.dashboard.background,
        },
    },
    optionText: {
        fontStyle: "italic",
        fontSize: "12px",
        color: globalTheme.palette.secondary.dark,
    },
}));

export const AutocompleteCommune = ({
    commune = null,
    postalCode = null,
    onChange,
    disabled = false,
    required = false,
    error = false,
    region,
}) => {
    const styles = useStyles();
    const [communeInput, setCommuneInput] = useState("");

    const [searchInput, setSearchInput] = useSearchDebounce(setCommuneInput);

    const { data: { GetCommunePostalCode: communes = [] } = {}, loading } = useGetCommunePostalCodeQuery({
        variables: { communePostalCode: communeInput, region },
    });

    const getCommuneFormat = (com, pc) => {
        const formatPc = pc ? `(${pc})` : null;
        return formatConcat([com, formatPc]);
    };

    const formatOnChangeParameter = (item) => {
        const { name, postalCode, INSEEcode } = item ?? {};
        return { commune: name, postalCode, communeINSEEcode: INSEEcode };
    };

    useEffect(() => {
        setSearchInput(getCommuneFormat(commune, postalCode));
    }, [commune, postalCode]);

    useEffect(() => {
        if (communes.length === 1) {
            const currentCommune = communes[0];
            const formatProps = getCommuneFormat(commune, postalCode);
            const formatCurrent = getCommuneFormat(currentCommune?.name, currentCommune?.postalCode);
            if (formatProps !== formatCurrent) {
                onChange(formatOnChangeParameter(communes[0]));
            }
        }
    }, [communes]);

    const handleChange = (value) => {
        if (isNaN(value?.index) || value?.index < 0) return onChange({});
        const item = communes[value.index];
        onChange(formatOnChangeParameter(item));
    };

    const loadingText = <Typography className={styles.optionText}>Recherche en cours...</Typography>;
    const noOptionsText = <Typography className={styles.optionText}>Aucun résultat</Typography>;

    return (
        <Autocomplete
            className={styles.autocomplete}
            classes={{ option: styles.option }}
            options={communes.map((item, index) => ({ label: item.displayName, index }))}
            filterOptions={(options) => options}
            inputValue={searchInput}
            onInputChange={(event, value) => setSearchInput(value)}
            onChange={(event, value) => handleChange(value)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    label={"Commune ou code postal" + (required ? " *" : "")}
                    error={error}
                    autocomplete="postalcode-and-commune"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <InputAdornment position="end">
                                {params.InputProps.endAdornment}
                                {error && <img src={ErrorIcon} className={styles.falseIcon} />}
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            loadingText={loadingText}
            getOptionLabel={(option) => option.label}
            noOptionsText={noOptionsText}
            clearOnBlur={false}
            disabled={disabled}
            loading={loading}
        />
    );
};

AutocompleteCommune.propTypes = {
    region: string,
    commune: string,
    postalCode: string,
    onChange: func,
    disabled: bool,
    required: bool,
    error: bool,
};
