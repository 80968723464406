import { makeStyles, Box, Typography, Divider } from "@material-ui/core";
import PropTypes from "prop-types";
import { useFrontVintageWineMakingQuery } from "../../generated/graphql";
import theme from "../../globalTheme";
import getConnectors, { getChips, parseHtml } from "../../helpers/helpers";

import sorting from "../../assets/Wine/vinification/sorting.png";
import destemming from "../../assets/Wine/vinification/destemming.png";
import manual from "../../assets/Wine/vinification/foulagemanual.svg";
import mecanical from "../../assets/Wine/vinification/foulagemecanical.svg";
import none from "../../assets/Wine/vinification/foulagenone.svg";
import vatting from "../../assets/Wine/vinification/vatting.png";
import bacteria from "../../assets/Wine/vinification/bacterie.svg";
import yeast from "../../assets/Wine/vinification/yeast.svg";
import remontage from "../../assets/Wine/vinification/remontage.svg";
import thermo from "../../assets/Wine/vinification/thermo.svg";
import verticalpress from "../../assets/Wine/vinification/verticalpress.svg";
import calendar from "../../assets/Wine/vinification/calendar.svg";
import parcellar from "../../assets/Wine/vinification/parcellar.svg";
import place from "../../assets/Wine/place.svg";
import Loading from "../../Components/Loader/Loading";
import { t, Trans } from "@lingui/macro";
import { capitalize } from "lodash";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: "flex",
        flexWrap: "wrap",
        gap: "32px",
        [theme.breakpoints.only("xs")]: {
            paddingBottom: "8px",
        },
        [theme.breakpoints.up("sm")]: {
            paddingBottom: "16px",
        },
    },
    defaultContainer: {
        backgroundColor: theme.palette.common.white,
        border: "0.4px solid",
        borderRadius: "5px",
        borderColor: theme.palette.secondary.dark,
        position: "relative",
        padding: theme.spacing(2),
        paddingTop: 0,
        [theme.breakpoints.only("xs")]: {
            width: "100%",
        },
    },
    typoContainer: {
        position: "relative",
        top: "-12px",
        left: "0px",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.only("xs")]: {
            top: -10,
        },
    },
    title: {
        ...theme.front.typography.h6.dark_b,
        zIndex: 2,
        backgroundColor: theme.palette.common.white,
        padding: "0px 2px",
    },
    flexContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        [theme.breakpoints.only("xs")]: {
            gap: "16px",
        },
    },
    typo: {
        fontSize: theme.front.typography.labeur.dark.fontSize,
        ...theme.front.typography.labeur.dark,
    },
    flexRowWrapContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        [theme.breakpoints.only("xs")]: {
            flexWrap: "nowrap",
            gap: "32px",
        },
    },
    macerationFirstBox: {
        minWidth: "100px",
        width: "auto",
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        [theme.breakpoints.only("xs")]: {
            width: "auto",
            margin: 0,
        },
    },
    flexStart: {
        display: "flex",
        alignItems: "center",
    },
    flexCenter: {
        display: "flex",
        alignItems: "center",
    },
    img1: {
        width: "18px",
        marginRight: "4px",
    },
    img2: {
        width: "12px",
        marginRight: "4px",
    },
    img3: {
        marginRight: "4px",
        height: "50px",
        [theme.breakpoints.only("xs")]: {
            height: "42px",
        },
    },
    fermentationContainer: {
        width: "130px",
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
        [theme.breakpoints.only("xs")]: {
            margin: 0,
            width: "45%",
        },
    },
    margins: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    secondContainer: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    flex: {
        display: "flex",
    },
    column: {
        display: "flex",
        flexDirection: "column",
    },
    vinificationPlace__boxImgTypo__boxTypo__typoPrim: {
        color: theme.palette.primary.dark,
        fontWeight: "500",
    },
    wineMarker__container__boxImgTypo__typoFirst: {
        ...theme.front.typography.labeur.dark,
    },
    maceration__typoTitleElement: {
        [theme.breakpoints.only("xs")]: {
            fontSize: "12px",
            fontFamily: "Lato",
            color: theme.palette.secondary.dark,
        },
    },
    macerationBox__calendarInfo: {
        display: "flex",
        alignItems: "flex-start",
        [theme.breakpoints.only("xs")]: {
            alignItems: "center",
            gap: "4px",
        },
    },
    macerationBox__temperatureInfo: {
        display: "flex",
        alignItems: "flex-start",
        [theme.breakpoints.only("xs")]: {
            alignItems: "center",
            gap: "4px",
        },
    },
    vinificationDetails__typoDescriptionComment: {
        ...theme.front.typography.labeur.dark,
    },
    typoParcellarContent: {
        ...theme.typography.body2,
        color: theme.palette.primary.dark,
    },
}));

const WineVinification = (props) => {
    const styles = useStyles(props);
    const { data, loading } = useFrontVintageWineMakingQuery({
        variables: { vintageId: props.vintageId, wineId: props.wineId, locale: props.locale },
    });
    const winemaking = data?.FrontVintageWineMaking;

    if (loading) {
        return (
            <Box display={"flex"} justifyContent={"center"} alignItems={"flex-end"}>
                <Loading loading={loading} />
            </Box>
        );
    }

    const getFoulageImage = () => {
        if (winemaking?.treadingFr === "manuel") {
            return manual;
        } else if (winemaking?.treadingFr === "aucun") {
            return none;
        } else if (winemaking?.treadingFr === "mécanique") {
            return mecanical;
        } else {
            return "";
        }
    };

    const getEraflageImage = () => {
        if (winemaking?.destemmingFr === "mécanique") {
            return destemming;
        } else if (winemaking?.destemmingFr === "aucun") {
            return none;
        } else {
            return;
        }
    };

    if (
        winemaking?.parcelBasedVinification ||
        winemaking?.vinificationPlace ||
        winemaking?.vinificationCoopName ||
        winemaking?.consultingEnologist?.title ||
        (winemaking?.sortingMethod && winemaking?.sortingMethod?.length !== 0) ||
        winemaking?.destemming ||
        winemaking?.treading ||
        (winemaking?.vatting && winemaking?.vatting?.length !== 0) ||
        (winemaking?.macerations && winemaking?.macerations?.length !== 0) ||
        (winemaking?.extractionMode && winemaking?.extractionMode?.length !== 0) ||
        winemaking?.yeasting ||
        winemaking?.alcoholicFermentationTemperature ||
        winemaking?.alcoholicFermentationDuration ||
        winemaking?.malolacticFermentationDuration ||
        winemaking?.malolacticFermentationTemperature ||
        winemaking?.malolacticBacteria ||
        (winemaking?.pressType && winemaking?.pressType !== 0) ||
        winemaking?.vinificationDetails
    ) {
        return (
            <Box className={styles.mainContainer}>
                {/* PARCELLAR */}
                {winemaking?.parcelBasedVinification && (
                    <Box className={styles.defaultContainer}>
                        <Box className={styles.typoContainer}>
                            <Typography variant="h3" className={styles.title}>
                                <Trans> Méthode </Trans>
                            </Typography>
                        </Box>
                        <Box className={styles.flexCenter} style={{ alignItems: "center" }}>
                            <img src={parcellar} alt="icon" className={styles.img3} />
                            <Typography className={styles.typoParcellarContent}>
                                <Trans> Parcellaire </Trans>
                            </Typography>
                        </Box>
                    </Box>
                )}

                {/* PLACE */}
                {(winemaking?.vinificationPlace || winemaking?.vinificationCoopName) && (
                    <Box className={styles.defaultContainer}>
                        <Box className={styles.typoContainer}>
                            <Typography variant="h3" className={styles.title}>
                                <Trans> Lieu de vinification </Trans>
                            </Typography>
                        </Box>
                        <Box className={styles.flexCenter} style={{ alignItems: "center" }}>
                            <img src={place} alt="icon" style={{ marginRight: "8px" }} className={styles.img3} />
                            <Box>
                                {winemaking?.vinificationPlace === t`En cave coopérative` &&
                                winemaking?.vinificationCoopName ? (
                                    <Typography
                                        variant="body2"
                                        className={styles.vinificationPlace__boxImgTypo__boxTypo__typoPrim}
                                    >
                                        {winemaking?.vinificationPlace}
                                    </Typography>
                                ) : (
                                    <Typography
                                        variant="h4"
                                        className={styles.typo}
                                        style={{
                                            fontFamily: "Lato",
                                            fontWeight: "500",
                                            color: theme.palette.primary.dark,
                                        }}
                                    >
                                        {winemaking?.vinificationPlace}
                                    </Typography>
                                )}
                                {winemaking?.vinificationPlace === t`En cave coopérative` && (
                                    <Typography
                                        className={styles.typo}
                                        style={{
                                            fontFamily: "Lato",
                                            fontWeight: "500",
                                            color: theme.palette.primary.dark,
                                        }}
                                    >
                                        {winemaking?.vinificationCoopName}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    </Box>
                )}

                {/* WINEMAKER */}
                {winemaking?.consultingEnologist?.title && (
                    <Box className={styles.defaultContainer}>
                        <Box className={styles.typoContainer}>
                            <Typography variant="h3" className={styles.title}>
                                <Trans> Œnologue </Trans>
                            </Typography>
                        </Box>
                        <Box className={styles.flexContainer} style={{ alignItems: "center" }}>
                            <Box className={styles.secondContainer}>
                                <Box className={styles.flex} style={{ alignItems: "center", gap: 8 }}>
                                    {winemaking?.consultingEnologist?.image?.data && (
                                        <Box
                                            style={{
                                                width: "56px",
                                                height: "56px",
                                            }}
                                        >
                                            <img
                                                src={winemaking?.consultingEnologist?.image?.data}
                                                style={{
                                                    marginRight: "8px",
                                                    borderRadius: "50%",
                                                    width: "100%",
                                                    height: "100%",
                                                }}
                                                alt="icon"
                                            />
                                        </Box>
                                    )}
                                    <Box className={styles.column}>
                                        <Typography
                                            variant="body2"
                                            className={styles.wineMarker__container__boxImgTypo__typoFirst}
                                            style={{
                                                color: theme.palette.primary.dark,
                                                fontWeight: "500",
                                            }}
                                        >
                                            {winemaking?.consultingEnologist?.title}
                                        </Typography>
                                        {winemaking?.consultingEnologist?.functions?.length !== 0 && (
                                            <Typography
                                                variant="body2"
                                                className={styles.typo}
                                                style={{
                                                    color: theme.palette.primary.dark,
                                                    fontWeight: "500",
                                                }}
                                            >
                                                {winemaking?.consultingEnologist?.functions}
                                            </Typography>
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                )}

                {/* SORTING*/}
                {winemaking?.sortingMethod && winemaking?.sortingMethod?.length !== 0 && (
                    <Box className={styles.defaultContainer}>
                        <Box className={styles.typoContainer}>
                            <Typography variant="h3" className={styles.title}>
                                <Trans> Tri </Trans>
                            </Typography>
                        </Box>
                        <Box className={styles.flexCenter} style={{ alignItems: "center" }}>
                            <img src={sorting} alt="icon" className={styles.img3} />
                            <Typography className={styles.typo} style={{ marginBottom: "1px" }}>
                                {getChips(winemaking?.sortingMethod, true)}
                            </Typography>
                        </Box>
                    </Box>
                )}

                {/* DESTEMMING */}
                {winemaking?.destemming && (
                    <Box className={styles.defaultContainer}>
                        <Box className={styles.typoContainer}>
                            <Typography variant="h3" className={styles.title}>
                                <Trans> Éraflage </Trans>
                            </Typography>
                        </Box>
                        <Box className={styles.flexCenter} style={{ alignItems: "center" }}>
                            <img
                                src={getEraflageImage()}
                                alt="icon"
                                className={styles.img3}
                                style={{
                                    ...theme.typography.body2,
                                    color: theme.palette.primary.dark,
                                }}
                            />
                            <Typography className={styles.typo} style={{ color: theme.palette.primary.dark }}>
                                {capitalize(winemaking?.destemming)}
                            </Typography>
                        </Box>
                    </Box>
                )}

                {/* TREADING */}
                {winemaking?.treading && (
                    <Box className={styles.defaultContainer}>
                        <Box className={styles.typoContainer}>
                            <Typography variant="h3" className={styles.title}>
                                <Trans> Foulage </Trans>
                            </Typography>
                        </Box>
                        <Box className={styles.flexCenter} style={{ alignItems: "center" }}>
                            <img src={getFoulageImage()} alt="icon" className={styles.img3} />
                            <Typography
                                variant="body2"
                                className={styles.typo}
                                style={{ color: theme.palette.primary.dark }}
                            >
                                {capitalize(winemaking?.treading)}
                            </Typography>
                        </Box>
                    </Box>
                )}

                {/* VATTING */}
                {winemaking?.vatting && winemaking?.vatting?.length !== 0 && (
                    <Box className={styles.defaultContainer}>
                        <Box className={styles.typoContainer}>
                            <Typography variant="h3" className={styles.title}>
                                <Trans> Encuvage </Trans>
                            </Typography>
                        </Box>
                        <Box className={styles.flexCenter} style={{ alignItems: "center" }}>
                            <img src={vatting} alt="icon" className={styles.img3} />
                            <Typography
                                variant="body2"
                                className={styles.typo}
                                style={{ color: theme.palette.primary.dark }}
                            >
                                {capitalize(getConnectors(winemaking?.vatting))}
                            </Typography>
                        </Box>
                    </Box>
                )}

                {/* MACERATIONS */}
                {winemaking?.macerations && winemaking?.macerations?.length !== 0 && (
                    <Box className={styles.defaultContainer}>
                        <Box className={styles.typoContainer}>
                            <Typography variant="h3" className={styles.title}>
                                <Trans>Macérations</Trans>
                            </Typography>
                        </Box>
                        <Box className={styles.flexContainer}>
                            {winemaking?.macerations
                                ?.filter((item) => Boolean(item?.type))
                                ?.map((el, i) => {
                                    return (
                                        <Box
                                            key={i}
                                            style={{
                                                display: "flex",
                                                marginRight: "32px",
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <Typography
                                                    variant="h6"
                                                    className={styles.maceration__typoTitleElement}
                                                    style={{
                                                        fontWeight: "500",
                                                        marginBottom: theme.spacing(1),
                                                        color: theme.palette.primary.light,
                                                    }}
                                                >
                                                    {capitalize(el?.type)}
                                                </Typography>
                                                <Box className={styles.flexRowWrapContainer}>
                                                    {el?.duration && (
                                                        <Box className={styles.macerationFirstBox}>
                                                            <Box className={styles.macerationBox__calendarInfo}>
                                                                <img
                                                                    src={calendar}
                                                                    className={styles.img1}
                                                                    alt="icon"
                                                                />
                                                                <Typography
                                                                    variant="body2"
                                                                    className={styles.typo}
                                                                    style={{ color: theme.palette.primary.dark }}
                                                                >
                                                                    {el?.duration} {el?.durationUnit}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    )}
                                                    {el?.temperature && (
                                                        <Box className={styles.macerationFirstBox}>
                                                            <Box className={styles.macerationBox__temperatureInfo}>
                                                                <img src={thermo} className={styles.img2} alt="icon" />
                                                                <Typography
                                                                    className={styles.typo}
                                                                    style={{
                                                                        color: theme.palette.primary.dark,
                                                                    }}
                                                                >
                                                                    {el?.temperature} {t`°C`}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    )}
                                                </Box>
                                            </Box>
                                            {i !== winemaking?.macerations.length - 1 && (
                                                <Divider orientation="vertical" />
                                            )}
                                        </Box>
                                    );
                                })}
                        </Box>
                    </Box>
                )}

                {/* EXTRACTION */}
                {winemaking?.extractionMode && winemaking?.extractionMode?.length !== 0 && (
                    <Box className={styles.defaultContainer}>
                        <Box className={styles.typoContainer}>
                            <Typography variant="h3" className={styles.title}>
                                <Trans> Extraction </Trans>
                            </Typography>
                        </Box>
                        <Box className={styles.flexCenter} style={{ alignItems: "center" }}>
                            <img src={remontage} alt="icon" className={styles.img3} />
                            <Typography
                                variant="body2"
                                className={styles.typo}
                                style={{ color: theme.palette.primary.dark }}
                            >
                                {capitalize(getConnectors(winemaking?.extractionMode))}
                            </Typography>
                        </Box>
                    </Box>
                )}

                {/* ALCOHOLIC FERMENTATION */}
                {(winemaking?.yeasting ||
                    winemaking?.alcoholicFermentationTemperature ||
                    winemaking?.alcoholicFermentationDuration) && (
                    <Box className={styles.defaultContainer}>
                        <Box className={styles.typoContainer}>
                            <Typography variant="h3" className={styles.title}>
                                <Trans> Fermentation alcoolique </Trans>
                            </Typography>
                        </Box>

                        <Box className={styles.flexContainer} style={{ alignItems: "center" }}>
                            {winemaking?.yeasting && (
                                <Box className={styles.fermentationContainer}>
                                    <Box className={styles.flexStart}>
                                        <img
                                            src={yeast}
                                            style={{
                                                width: "25px",
                                                marginRight: "4px",
                                            }}
                                            alt="icon"
                                        />
                                        <Typography
                                            variant="body2"
                                            className={styles.typo}
                                            style={{ color: theme.palette.primary.dark }}
                                        >
                                            <Trans>Levures {winemaking?.yeasting}</Trans>
                                        </Typography>
                                    </Box>
                                </Box>
                            )}

                            {winemaking?.alcoholicFermentationDuration &&
                                winemaking?.alcoholicFermentationDurationUnit && (
                                    <Box className={styles.fermentationContainer}>
                                        <Box className={styles.flex}>
                                            <img src={calendar} className={styles.img1} alt="icon" />
                                            <Typography
                                                variant="body2"
                                                className={styles.typo}
                                                style={{ color: theme.palette.primary.dark }}
                                            >
                                                {winemaking?.alcoholicFermentationDuration}{" "}
                                                {winemaking?.alcoholicFermentationDurationUnit}
                                            </Typography>
                                        </Box>
                                    </Box>
                                )}

                            {winemaking?.alcoholicFermentationTemperature && (
                                <Box className={styles.margins}>
                                    <Box className={styles.flex}>
                                        <img src={thermo} className={styles.typo2} alt="icon" />
                                        <Typography
                                            variant="body2"
                                            className={styles.typo}
                                            style={{ color: theme.palette.primary.dark }}
                                        >
                                            {winemaking?.alcoholicFermentationTemperature} {t`°C`}
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Box>
                )}

                {/* MALOLACTIC FERMENTATION */}
                {(winemaking?.malolacticFermentationDuration ||
                    winemaking?.malolacticFermentationTemperature ||
                    winemaking?.malolacticBacteria) && (
                    <Box className={styles.defaultContainer}>
                        <Box className={styles.typoContainer}>
                            <Typography variant="h3" className={styles.title}>
                                <Trans> Fermentation malolactique </Trans>
                            </Typography>
                        </Box>

                        <Box className={styles.flexContainer} style={{ alignItems: "center" }}>
                            {winemaking?.malolacticBacteria && (
                                <Box className={styles.fermentationContainer}>
                                    <Box className={styles.flexStart}>
                                        <img
                                            src={bacteria}
                                            style={{
                                                width: "25px",
                                                marginRight: "4px",
                                            }}
                                            alt="icon"
                                        />
                                        <Typography
                                            variant="body2"
                                            className={styles.typo}
                                            style={{ color: theme.palette.primary.dark }}
                                        >
                                            <Trans> Bactéries {winemaking?.malolacticBacteria} </Trans>
                                        </Typography>
                                    </Box>
                                </Box>
                            )}

                            {winemaking?.malolacticFermentationDuration &&
                                winemaking?.malolacticFermentationDurationUnit && (
                                    <Box className={styles.fermentationContainer}>
                                        <Box className={styles.flex}>
                                            <img src={calendar} className={styles.img1} alt="icon" />
                                            <Typography
                                                variant="body2"
                                                className={styles.typo}
                                                style={{ color: theme.palette.primary.dark }}
                                            >
                                                {winemaking?.malolacticFermentationDuration}{" "}
                                                {winemaking?.malolacticFermentationDurationUnit}
                                            </Typography>
                                        </Box>
                                    </Box>
                                )}

                            {winemaking?.malolacticFermentationTemperature && (
                                <Box className={styles.margins}>
                                    <Box className={styles.flex}>
                                        <img src={thermo} className={styles.typo2} alt="icon" />
                                        <Typography
                                            variant="body2"
                                            className={styles.typo}
                                            style={{ color: theme.palette.primary.dark }}
                                        >
                                            {winemaking?.malolacticFermentationTemperature} {t`°C`}
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Box>
                )}

                {/* PRESSTYPE */}
                {winemaking?.pressType && winemaking?.pressType !== 0 && (
                    <Box className={styles.defaultContainer} style={{ display: "flex", flexDirection: "column" }}>
                        <Box className={styles.typoContainer}>
                            <Typography variant="h3" className={styles.title}>
                                <Trans> Type de presse </Trans>
                            </Typography>
                        </Box>
                        <Box className={styles.flexCenter} style={{ alignItems: "center", height: "100%" }}>
                            <img
                                src={verticalpress}
                                style={{
                                    width: "25px",
                                    marginRight: "4px",
                                }}
                                alt="icon"
                            />
                            <Typography className={styles.typo} style={{ marginBottom: "1px" }}>
                                {getChips(winemaking?.pressType, true)}
                            </Typography>
                        </Box>
                    </Box>
                )}

                {/* COMMENT */}
                {winemaking?.vinificationDetails && winemaking?.vinificationDetails !== "<div/>" && (
                    <Box className={styles.defaultContainer}>
                        <Box className={styles.typoContainer}>
                            <Typography variant="h3" className={styles.title}>
                                <Trans>À propos de la vinification</Trans>
                            </Typography>
                        </Box>
                        <Box className={styles.margins}>
                            <Typography
                                className={styles.vinificationDetails__typoDescriptionComment}
                                style={{ color: theme.palette.primary.dark }}
                            >
                                {parseHtml(winemaking?.vinificationDetails)}
                            </Typography>
                        </Box>
                    </Box>
                )}
            </Box>
        );
    } else {
        return (
            <Typography
                style={{ fontSize: "16px", padding: "16px" }}
                className={styles.vinificationDetails__typoDescriptionComment}
            >
                <Trans>Information non renseignée</Trans>
            </Typography>
        );
    }
};

WineVinification.propTypes = {
    vintageId: PropTypes.string,
    wineName: PropTypes.string,
    year: PropTypes.number,
    aoc: PropTypes.string,
    cuvee: PropTypes.string,
};

export default WineVinification;
