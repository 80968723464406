import { t, Trans } from "@lingui/macro";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import { useEnumValues } from "hooks/useEnumValues";
import { bool, func, number, object, string } from "prop-types";
import { useSelector } from "react-redux";
import { SearchModal } from "Search/modal/SearchModal";

import AutocompleteChipsInput from "../../Components/Inputs/AutocompleteChipsInput";
import SimpleInputUncontrolled from "../../Components/Inputs/SimpleInputUncontrolled";
import BigSwitch from "../../Components/Switch/BigSwitch";
import theme from "../../globalTheme";
import { getUserLocale, useWindowDimensions } from "../../helpers/helpers";
import AutocompleteCommune from "../AutocompleteCommune";
import RangeSliderCustomSearch from "../RangeSliderCustomSearch";
import ModalSectionTitle from "../ui/ModalSectionTitle";
import SearchCheckbox from "./SearchCheckbox";

const useStyles = makeStyles(() => ({
    containerBoxContent: {
        paddingLeft: "10px",
        maxHeight: "calc(90vh - 150px - 95px)",

        [theme.breakpoints.up("sm")]: {
            maxHeight: "calc(90vh - 118px - 95px)",
        },

        [theme.breakpoints.up("md")]: {
            maxHeight: "calc(90vh - 88px - 95px)",
        },
    },
    filterSearch: {
        gridColumn: "1 / span 12",
    },
    filterSelect: {
        gridColumn: "1 / span 12",
        marginTop: 16,

        "& .MuiOutlinedInput-root": {
            borderRadius: 53,
        },
    },
    keywordsBox: {
        gridColumn: "1 / span 12",
        marginTop: 15,
    },
    color: {
        display: "flex",
        flexDirection: "column",
        gridColumn: "1 /span 12",

        [theme.breakpoints.up("sm")]: {
            gridColumn: "1 / span 6",
        },
    },
    checkboxBox: {
        display: "flex",
        flexDirection: "column",
        gridColumn: "1 /span 12",
        marginTop: 15,
    },
    checkboxesGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        gridColumn: "1 / span 12",
        width: "auto",

        [theme.breakpoints.up("sm")]: {
            width: "100%",
            display: "grid",
            justifyContent: "space-between",
        },
    },
    checkboxThreeCol: (props) => ({
        alignItems: "center",

        "&:nth-child(1)": {
            gridColumn: props?.width > 300 ? "1 / span 4" : "1 / span 12",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "1 / span 4",
            },

            [theme.breakpoints.up("md")]: {
                gridColumn: "1 / span 3",
            },
        },

        "&:nth-child(2)": {
            gridColumn: props?.width > 300 ? "auto / span 4" : "1 / span 12",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "auto / span 4",
            },

            [theme.breakpoints.up("md")]: {
                gridColumn: "auto / span 3",
            },
        },

        "&:nth-child(3)": {
            gridColumn: props?.width > 300 ? "auto / span 4" : "1 / span 12",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "auto / span 4",
            },

            [theme.breakpoints.up("md")]: {
                gridColumn: "auto / span 3",
            },
        },
        "&:nth-child(4)": {
            gridColumn: props?.width > 300 ? "auto / span 3" : "1 / span 12",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "auto / span 3",
            },
        },
    }),
    checkboxFourCol: () => ({
        alignItems: "center",

        "&:nth-child(1)": {
            gridColumn: "1 / span 12",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "1 / span 4",
            },

            [theme.breakpoints.up("md")]: {
                gridColumn: "1 / span 3",
            },
        },

        "&:nth-child(2)": {
            gridColumn: "1 / span 12",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "auto / span 4",
            },

            [theme.breakpoints.up("md")]: {
                gridColumn: "auto / span 3",
            },
        },

        "&:nth-child(3)": {
            gridColumn: "1 / span 12",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "auto / span 4",
            },

            [theme.breakpoints.up("md")]: {
                gridColumn: "auto / span 3",
            },
        },
        "&:nth-child(4)": {
            gridColumn: "1 / span 12",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "1 / span 4",
            },

            [theme.breakpoints.up("md")]: {
                gridColumn: "auto / span 3",
            },
        },
    }),
    keywordButton: {
        padding: "1rem",
        border: `0.5px solid ${theme.palette.primary.light}`,
        borderRadius: "16px",
        height: 27,
        backgroundColor: theme.palette.primary.light,
        boxShadow: "none",
        marginBottom: 7,
        marginRight: 7,
        cursor: "pointer",

        "&.Mui-disabled": {
            borderColor: theme.palette.primary.dark,
            backgroundColor: theme.palette.disabled.main,
            color: theme.palette.primary.dark,
            boxShadow: "none",
        },

        "&:hover": {
            boxShadow: "none",
        },
    },
    btnPerson: {
        position: "relative",
        borderRadius: "100%",
        width: 20,
        height: 20,
        padding: 0,
        minWidth: "auto",
        border: `0.5px solid ${theme.palette.wine.main}`,
        color: "#90174A",

        "&.Mui-disabled": {
            borderColor: theme.palette.secondary.dark,
        },
    },
    switches: (props) => ({
        gridColumn: "1 / span 12",
        display: props?.width > 300 ? "grid" : "flex",
        flexWrap: "wrap",
        gridTemplateColumns: "repeat(12, 1fr)",
        marginTop: 10,
        marginLeft: 20,
    }),
    switch: (props) => ({
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",

        "&:nth-child(1)": {
            gridColumn: "1 / span 5",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "1 / span 3",
            },
        },

        "&:nth-child(2)": {
            gridColumn: "6 / span 6",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "auto / span 3",
            },
        },

        "&:nth-child(3)": {
            gridColumn: props?.width > 300 ? "10 / span 4" : "1 / span 12",

            [theme.breakpoints.up("sm")]: {
                gridColumn: "auto / span 3",
            },
        },
    }),
    switchTypo: {
        marginLeft: 7,
        color: theme.palette.primary.dark,
        cursor: "pointer",
        fontWeight: 400,
    },
}));

const SearchActivityModal = (props) => {
    const { width } = useWindowDimensions();
    const styles = useStyles({ ...props, width });
    const locale = useSelector(getUserLocale);

    const activityTags = useEnumValues("ACTIVITY_TAGS", { locale });

    const handleClickKeyword = (el) => {
        const newV = [...(props?.filters?.tags ?? [])];

        if (newV.includes(el)) {
            const indexEl = newV.indexOf(el);

            if (indexEl !== -1) {
                newV.splice(indexEl, 1);
            }
        } else {
            newV.push(el);
        }

        props?.setFilter("tags", newV);
    };

    return (
        <SearchModal
            totalCount={props.similar ? 0 : props.totalCount}
            width={width > 600 ? "750px" : "100%"}
            open={props?.isOpen}
            onClose={() => {
                props.nextStep && props.nextStep();
                props.onClose();
            }}
            onCloseCross={() => props.onCloseCross()}
            titlePrimary={props?.titlePrimary}
            titleSecondary={`/ ${props?.titleSecondary}`}
            CTAPrimaryClick={() => props.resetFilters()}
            CTAPrimaryDisabled={props.filtersCount <= 0 && props.searchText}
            boxContentClassname={styles.containerBoxContent}
            contentBoxPaddingLeft="20px"
        >
            <Box className={styles.filterSearch}>
                <SimpleInputUncontrolled
                    search
                    size="small"
                    name={"search"}
                    placeholder={t`Chercher une activité...`}
                    variant="outlined"
                    boxStyle={{
                        backgroundColor: theme.palette.common.white,
                        borderRadius: "53px",
                        border: "none",
                    }}
                    bgTransparent
                    borderRadius={53}
                    onBlur={(e) => {
                        if (e?.target?.value !== undefined) {
                            return props.setFilter("searchText", e?.target?.value);
                        }
                    }}
                    iconPosition="start"
                    inModal
                    defaultValue={props.filters?.searchText}
                />
            </Box>

            <AutocompleteCommune
                value={props?.filters?.communes}
                setFilter={(e) => props?.setFilter("communes", e)}
                label={t`Commune`}
                placeholder={t`Choisir...`}
            />

            {/* ACTIVITY TYPES */}
            <ModalSectionTitle
                title={t`Type d'activité`}
                styles={{
                    marginTop: "27px",
                }}
            />

            <AutocompleteChipsInput
                placeholder={t`Choisir...`}
                class={styles.filterSelect}
                label={t`Catégorie`}
                enumField="ACTIVITY_TYPE"
                uppercase
                value={props?.filters?.categories}
                onChange={(e1, e2) => props?.setFilter("categories", e2)}
                labelColor={theme.palette.primary.dark}
                labelPaddingLeft="16px"
                labelPaddingTop="1px"
                placeholderPaddingLeft="20px"
                inputPaddingLeft="20px"
                locale={locale}
                popupIcon
            />

            <Box className={styles.keywordsBox}>
                <Typography variant="h6" style={{ color: theme.palette.primary.dark, fontWeight: 700 }}>
                    <Trans>Mots-clés</Trans>
                </Typography>
                <Box style={{ display: "flex", flexWrap: "wrap", marginTop: 10 }}>
                    {activityTags?.map((keyword, i) => {
                        return (
                            <Button
                                key={i}
                                variant="contained"
                                color="primary"
                                className={styles.keywordButton}
                                style={{
                                    backgroundColor: props?.filters?.tags?.includes(keyword)
                                        ? theme.palette.primary.light
                                        : "#FCF6F6",
                                    color: props?.filters?.tags?.includes(keyword)
                                        ? theme.palette.common.white
                                        : theme.palette.primary.dark,
                                }}
                                disableRipple
                                onClick={() => handleClickKeyword(keyword)}
                            >
                                <Typography variant="body2">{keyword}</Typography>
                            </Button>
                        );
                    })}
                </Box>
            </Box>

            {/* INFOS */}
            <ModalSectionTitle
                title={t`Informations pratiques`}
                styles={{
                    marginTop: "27px",
                }}
            />

            <AutocompleteChipsInput
                placeholder={t`Choisir...`}
                class={styles.filterSelect}
                label={t`Langue`}
                enumField="LANGUAGES"
                uppercase
                value={props?.filters?.languages}
                onChange={(e1, e2) => props?.setFilter("languages", e2)}
                labelColor={theme.palette.primary.dark}
                labelPaddingLeft="16px"
                labelPaddingTop="1px"
                placeholderPaddingLeft="20px"
                inputPaddingLeft="20px"
                locale={locale}
                popupIcon
            />

            <Box
                className={styles.filterSelect}
                style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
            >
                <Typography variant="h6" style={{ marginLeft: 20, marginRight: 20, color: theme.palette.primary.dark }}>
                    <Trans>Nombre de personnes</Trans>
                </Typography>

                <Box
                    style={{
                        gridColumn: "auto / span 6",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                    }}
                >
                    <Button
                        variant="outlined"
                        className={styles.btnPerson}
                        onClick={() => props?.setFilter("nbPersons", props?.filters?.nbPersons - 1)}
                        disabled={props?.filters?.nbPersons <= 0}
                    >
                        <Remove style={{ marginLeft: "0px", fill: theme.palette.wine.main, fontSize: 9 }} />
                        {/* <Box style={{ width: 5, height: "1.25px", backgroundColor: theme.palette.wine.main }} /> */}
                    </Button>

                    <Typography variant="h6" style={{ margin: "0 11px", color: theme.palette.primary.dark }}>
                        {props?.filters?.nbPersons ?? 0}
                    </Typography>

                    <Button
                        variant="outlined"
                        className={styles.btnPerson}
                        onClick={() => props?.setFilter("nbPersons", props?.filters?.nbPersons + 1)}
                        disabled={props?.filters?.nbPersons >= 200}
                    >
                        <Add style={{ marginLeft: "0px", fill: theme.palette.wine.main, fontSize: 9 }} />
                    </Button>
                </Box>
            </Box>

            <Box className={styles.switches}>
                <Box className={styles.switch} style={{ marginRight: 8 }}>
                    <BigSwitch
                        checked={props?.filters?.privateActivity}
                        onChange={() => props?.setFilter("privateActivity", !props?.filters?.privateActivity)}
                    />
                    <Typography
                        variant="h6"
                        className={styles.switchTypo}
                        onClick={() => props?.setFilter("privateActivity", !props?.filters?.privateActivity)}
                    >
                        <Trans>Privatisée</Trans>
                    </Typography>
                </Box>

                <Box className={styles.switch}>
                    <BigSwitch
                        checked={props?.filters?.parkingBus}
                        onChange={() => props?.setFilter("parkingBus", !props?.filters?.parkingBus)}
                    />
                    <Typography
                        variant="h6"
                        className={styles.switchTypo}
                        onClick={() => props?.setFilter("parkingBus", !props?.filters?.parkingBus)}
                    >
                        <Trans>Parking bus</Trans>
                    </Typography>
                </Box>
            </Box>

            <AutocompleteChipsInput
                placeholder={t`Choisir...`}
                class={styles.filterSelect}
                label={t`Adapté pour...`}
                enumField="ACTIVITY_ACCESSIBILITY"
                value={props?.filters?.accessibleTo}
                onChange={(e1, e2) => props?.setFilter("accessibleTo", e2)}
                labelColor={theme.palette.primary.dark}
                labelPaddingLeft="16px"
                labelPaddingTop="1px"
                placeholderPaddingLeft="20px"
                inputPaddingLeft="20px"
                locale={locale}
                uppercase
                popupIcon
            />

            <Box className={styles.checkboxBox}>
                <Typography variant="h6" style={{ color: theme.palette.primary.dark, fontWeight: 700 }}>
                    <Trans>Durée de l&apos;activité</Trans>
                </Typography>
                <Box style={{ display: "flex", justifyContent: "space-between", marginLeft: 20 }}>
                    <SearchCheckbox
                        setFilter={props.setFilter}
                        options={[t`Moins d'une heure`, t`Quelques heures`, t`La journée`, t`Plusieurs jours`]}
                        styles={styles}
                        filterName={"durations"}
                        boxClassName={styles.checkboxFourCol}
                        filters={props.filters}
                        containerClassName={styles.checkboxesGrid}
                    />
                </Box>
            </Box>

            <Box className={styles.checkboxBox}>
                <Typography variant="h6" style={{ color: theme.palette.primary.dark, fontWeight: 700 }}>
                    <Trans>Nombre de vins dégustés</Trans>
                </Typography>
                <Box style={{ display: "flex", justifyContent: "space-between", marginLeft: 20 }}>
                    <SearchCheckbox
                        setFilter={props.setFilter}
                        options={[t`1 à 2`, t`3 à 5`, t`plus de 5`]}
                        styles={styles}
                        filterName={"durations"} // filtre duration pour test integration, en attente du filtre du back
                        boxClassName={styles.checkboxThreeCol}
                        filters={props.filters}
                        containerClassName={styles.checkboxesGrid}
                    />
                </Box>
            </Box>

            {/* TARIFS */}
            <ModalSectionTitle
                title={t`Tarif`}
                styles={{
                    marginTop: "27px",
                }}
            />

            <Box className={styles.filterSelect} style={{ justifyContent: "center", alignItems: "center" }}>
                {/* <RangeSlider
                    value={props?.filters?.priceRange}
                    onChange={(e, v) => {
                        props?.setFilter("priceRange", v);
                    }}
                    min={0}
                    max={201}
                    labelType="Prix de l’activité : "
                    labelMiddleType="à"
                    labelAll={t`tous les prix`}
                    width="96%"
                    alignSelf="center"
                    defaultValue={[0, 201]}
                    colorThumb={theme.palette.primary.dark}
                    trackColor={theme.palette.primary.main}
                    railColor={theme.palette.secondary.main}
                    typoMarginLeft="24px"
                    typoValueBold
                    labelStyle={{ color: theme.palette.primary.dark, fontSize: "14px" }}
                    resetValues
                    // resetValuesClick={() => {
                    //     props?.setFilter("priceRange", [0, 201]);
                    // }}
                    isPrice
                /> */}

                <RangeSliderCustomSearch
                    value={props?.filters?.priceRange}
                    initialMinimumValue={1}
                    onChange={(e, v) => props?.setFilter("priceRange", v)}
                    labelEndTypeSingular="an"
                    labelMiddleType={t`à`}
                    labelEndType=""
                    labelAll={t`tous les prix`}
                    labelType={t`Prix de l'activité`}
                    max={201}
                    min={1}
                    defaultValue={[1, 201]}
                    colorThumb={theme.palette.primary.dark}
                    trackColor={theme.palette.primary.main}
                    railColor={theme.palette.secondary.main}
                    labelStyle={{
                        fontSize: "14px",
                        color: theme.palette.primary.dark,
                    }}
                    isPrice
                    resetValues
                    typoMarginLeft="24px"
                    width="96%"
                    alignSelf="center"
                />
            </Box>
            <AutocompleteChipsInput
                placeholder={t`Choisir...`}
                class={styles.filterSelect}
                style={{ marginBottom: 16 }}
                label={t`Moyens de paiement acceptés`}
                enumField="PAYMENT_METHOD"
                value={props?.filters?.payments}
                onChange={(e1, e2) => props?.setFilter("payments", e2)}
                labelColor={theme.palette.primary.dark}
                labelPaddingLeft="16px"
                labelPaddingTop="1px"
                placeholderPaddingLeft="20px"
                inputPaddingLeft="20px"
                locale={locale}
                uppercase
                popupIcon
            />
        </SearchModal>
    );
};

SearchActivityModal.propTypes = {
    similar: bool,
    isOpen: bool,
    onClose: func,
    titlePrimary: string,
    titleSecondary: string,
    filters: object,
    setFilter: func,
    searchText: string,
    resetFilters: func,
    filtersCount: number,
    totalCount: number,
    nextStep: func,
    onCloseCross: func,
};

export default SearchActivityModal;
