import { Box, Typography, makeStyles } from "@material-ui/core";
import BigSwitch from "Components/Switch/BigSwitch";
import theme from "globalTheme";
import { useWindowDimensions } from "helpers/helpers";
import { bool, func } from "prop-types";

const useStyles = makeStyles(() => ({
    free_trial_container: {
        borderRadius: 10,
        border: `1px solid ${theme.palette.secondary.main}`,
        background: theme.palette.background.card,
        padding: "1rem",
        position: "relative",
        [theme.breakpoints.down("sm")]: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        },
        [theme.breakpoints.up("xs")]: {
            textAlign: "center",
            gridColumn: "2 /span 10",
            marginTop: "-2rem",
        },
        [theme.breakpoints.up("sm")]: {
            textAlign: "left",
            marginTop: "0rem",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 12",
        },
    },
    switch_container: (props) => {
        return {
            display: "inline-flex",
            padding: "2px 5px",
            justifyContent: "center",
            alignItems: "center",
            gap: "5px",
            borderRadius: 4,
            background: theme.palette.primary.light,
            cursor: !props.hideToggle && "pointer",
        };
    },
    chip: {
        borderRadius: "4px !important",
        backgroundColor: `${theme.palette.wine.main} !important`,
        position: "absolute",
        top: "-12px",
        right: "15px",
        width: "fit-content",
        "& .MuiChip-label": {
            padding: "2px 8px",
            fontSize: "10px",
        },
        [theme.breakpoints.down("sm")]: {
            left: "50%",
            transform: "translate(-50%, 0)",
        },
    },
    description: {
        fontFamily: "Mansalva",
        color: theme.palette.primary.main,
    },
}));

const FreeTrial = (props) => {
    const styles = useStyles(props);
    const { width } = useWindowDimensions();

    return (
        <Box className={styles.free_trial_container}>
            <Box
                className={styles.switch_container}
                onClick={() => {
                    if (!props.hideToggle) {
                        props.onChange();
                    }
                }}
            >
                {!props.hideToggle && <BigSwitch onChange={() => props.onChange()} checked={props.checked} />}
                <Typography variant="body2" style={{ color: theme.palette.common.white }}>
                    {width >= 600 && "Profitez de"} <b>30 jours d&apos;essai gratuit</b>
                </Typography>
            </Box>
            <Typography variant="h5" className={styles.description}>
                Créez votre compte aujourd&apos;hui, aucun engagement, aucune obligation, pas de carte bleue requise.
                Utilisez la solution gratuitement pendant 30 jours avant de faire votre choix.
            </Typography>
        </Box>
    );
};

FreeTrial.propTypes = {
    onChange: func,
    checked: bool,
    hideToggle: bool,
};

export default FreeTrial;
