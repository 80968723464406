import { useState } from "react";

import { makeStyles, Grid, Typography, Divider, Link, Box, TextField } from "@material-ui/core";
import theme from "../../../globalTheme";
import PropTypes, { string } from "prop-types";
import estateIcon from "../../../assets/Dashboard/icon_chateau.svg";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { signIn, signOut } from "../../../features/user/userSlice";
import { getEntityId } from "../../../helpers/helpers";
import deconnectionIcon from "../../../assets/Indicator/deconnexion.svg";
import myaccountIcon from "../../../assets/myaccount.svg";
import AccordionSplit from "./../../../Components/Accordions/AccordionSplit";
import account__circle from "../../../assets/icons/account__circle/account_circle_black_24dp.svg";
import { useDispatch, useSelector } from "react-redux";
import { setNextRoute } from "../../../features/settings/settingsSlice";
import { useWindowDimensions } from "../../../helpers/helpers";
import { useCustomHistory } from "hooks";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    estate: {
        lineHeight: 1.1,
        padding: "0 16px",
    },
    userName: {
        lineHeight: 1.1,
        padding: "0 16px",
    },
    expandIcon: {
        color: theme.palette.secondary.dark,
        fontSize: "30px",
        padding: 0,
    },
    icon: {
        marginRight: theme.spacing(1),
        height: "20px",
        color: theme.palette.secondary.dark,
    },
    link: {
        display: "flex",
        cursor: "pointer",
    },
    decoIcon: {
        fontSize: "20px",
        color: theme.palette.primary.main,
    },
}));

const SwitchEstateSelect = (props) => {
    const styles = useStyles(props);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();
    const { width } = useWindowDimensions();
    const { t } = useTranslation();

    const getMyAccount = () => (
        <Grid item>
            <Link
                underline="none"
                onClick={() => dispatch(setNextRoute("/dashboard/account/general"))}
                className={styles.link}
            >
                <img
                    src={myaccountIcon}
                    alt="account_icon"
                    className={styles.decoIcon}
                    style={{ marginRight: theme.spacing(1) }}
                />

                <Typography variant="h6" style={{ color: theme.palette.primary.dark }}>
                    {t("common.myAccount")}
                </Typography>
            </Link>
            <Divider orientation="horizontal" style={{ height: "0.2px", margin: "8px 0px" }} />
        </Grid>
    );

    const getDisconnect = () => {
        return (
            <Grid item>
                <Link underline="none" onClick={() => props.onClickDisconnect()} className={styles.link}>
                    <img
                        src={deconnectionIcon}
                        alt="deconnection_icon"
                        className={styles.decoIcon}
                        style={{ marginRight: theme.spacing(1) }}
                    />

                    <Typography
                        variant="h6"
                        style={{
                            color: theme.palette.primary.dark,
                        }}
                    >
                        {t("common.logout")}
                    </Typography>
                </Link>
            </Grid>
        );
    };

    const getConnect = () => {
        return (
            <>
                <Grid item>
                    <TextField
                        onChange={(e) => {
                            setUsername(e.target.value);
                        }}
                        value={username}
                    />
                    <TextField
                        type={"password"}
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                        value={password}
                    />
                </Grid>
                <Grid item>
                    <Link
                        underline="none"
                        onClick={() => props.onClickConnect(username, password)}
                        className={styles.link}
                    >
                        <PowerSettingsNewIcon
                            style={{
                                color: theme.palette.primary.dark,
                            }}
                            className={styles.icon}
                        />
                        <Typography
                            variant="h6"
                            style={{
                                color: theme.palette.primary.dark,
                            }}
                        >
                            Connexion
                        </Typography>
                    </Link>
                </Grid>
            </>
        );
    };
    return (
        <Box className={styles.relativeContainer} style={props.style}>
            <AccordionSplit
                elevationPrimaryCard="0px 3px 6px #00000029"
                primaryIcon={width <= 1280 ? props.avatarImage || account__circle : null}
                secondaryElement={
                    <Box>
                        <Typography variant="h4" className={styles.userName}>
                            {props.userName}
                        </Typography>
                        <Typography variant="h6" className={styles.estate}>
                            {props.currentEstate}
                        </Typography>
                        <Typography variant="h6" className={styles.estate}>
                            {props.companyName}
                        </Typography>
                    </Box>
                }
                expandIcon
                openCardContent={
                    <Box>
                        {props.estates?.map((estate) => {
                            return (
                                <>
                                    <Box>
                                        <Link underline="none" onclick={() => {}}>
                                            <Box
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <img src={estateIcon} className={styles.icon} />
                                            </Box>
                                            <Box>
                                                <Typography variant="h6">{estate.label}</Typography>
                                                <Typography variant="h6" style={{ fontSize: "10px" }}>
                                                    Accéder à mon tableau de bord
                                                </Typography>
                                            </Box>
                                        </Link>
                                    </Box>
                                    <Divider orientation="horizontal" style={{ height: "0.2px" }} />
                                </>
                            );
                        })}
                        {props.inBoc && getMyAccount()}
                        {props.userName ? getDisconnect() : getConnect()}
                    </Box>
                }
                openCardJustify="right"
                backgroundColor={props.backgroundColor}
            />
        </Box>
    );
};

SwitchEstateSelect.propTypes = {
    showAddSubscription: PropTypes.bool,
    onAddSubscriptionClick: PropTypes.func,
    avatarImage: PropTypes.string,
    userName: PropTypes.string,
    currentEstate: PropTypes.string,
    estates: PropTypes.array,
    onClickDisconnect: PropTypes.func,
    onClickConnect: PropTypes.func,
    expanded: PropTypes.bool,
    handleChangeExpanded: PropTypes.func,
    style: PropTypes.object,
    shadow: PropTypes.bool,
    inBoc: PropTypes.bool,
    backgroundColor: PropTypes.string,
    companyName: string,
};

export default SwitchEstateSelect;

export const ConnectedSwitchEstateSelect = (props) => {
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);
    const entity = useSelector((state) => getEntityId(state));
    const [expanded, setExpanded] = useState(false);
    const history = useCustomHistory();

    return (
        <SwitchEstateSelect
            onAddSubscriptionClick={() => history.push("/subscription/")}
            showAddSubscription={!entity}
            style={props.style}
            expanded={expanded}
            inBoc={props.inBoc}
            backgroundColor={props.backgroundColor}
            handleChangeExpanded={() => {
                setExpanded(!expanded);
            }}
            onClickDisconnect={async () => {
                await dispatch(signOut());
                setExpanded(false);
                history.replace("/");
            }}
            onClickConnect={(username, password) => {
                dispatch(signIn({ username, password }));
                setExpanded(false);
            }}
            shadow={props.shadow}
            avatarImage={props.avatarImage}
            userName={`${currentUser?.firstName} ${currentUser?.lastName}`} // A connecter à l'username connecté
            currentEstate={currentUser?.wineEntityName} // A connecter au Château actuellement sur le dashboard
            companyName={currentUser?.isProfessional ? currentUser?.company : null}
        />
    );
};

ConnectedSwitchEstateSelect.propTypes = {
    style: PropTypes.object,
    shadow: PropTypes.bool,
    inBoc: PropTypes.bool,
    avatarImage: PropTypes.string,
    backgroundColor: PropTypes.string,
};
