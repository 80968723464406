/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import volumeImg from "../../assets/Wine/bottle2.svg";
import place from "../../assets/Wine/place.svg";
import bottler from "../../assets/Wine/bottler.svg";
import cork from "../../assets/Wine/cork.svg";
import date from "../../assets/Wine/date.svg";

import ReactApexCharts from "react-apexcharts";
import PropTypes from "prop-types";
import theme from "../../globalTheme";
import { useFrontVintageMarketingQuery, useFrontVintageProductionQuery } from "../../generated/graphql";
import getConnectors, { getChips } from "../../helpers/helpers";
import Loading from "../../Components/Loader/Loading";
import { useWindowDimensions } from "./../../helpers/helpers";
import { firstLetterUppercase } from "../../helpers/helpers";
import { useGetCommunesOptionsQuery } from "../../generated/graphql";
import { Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
        gap: "32px",
        [theme.breakpoints.only("xs")]: {
            paddingBottom: "8px",
        },
        [theme.breakpoints.up("sm")]: {
            paddingBottom: "16px",
        },
    },
    blockContainer: {
        border: "0.4px solid",
        backgroundColor: "white",

        borderRadius: "5px",
        borderColor: theme.palette.secondary.dark,
        position: "relative",
        padding: theme.spacing(2),
        paddingTop: 0,
        [theme.breakpoints.only("xs")]: {
            width: "100%",
        },
    },
    typoContainer: {
        position: "relative",
        top: "-12px",
        left: "0px",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.only("xs")]: {
            top: -10,
        },
    },
    title: {
        ...theme.front.typography.h6.dark_b,
        zIndex: 2,
        backgroundColor: theme.palette.common.white,
        padding: "0px 2px",
    },
    typo: {
        fontFamily: "Lato",
        fontSize: "16px",
        color: "#360318",
        [theme.breakpoints.only("xs")]: {
            fontSize: "12px",
        },
    },
    typo2: {
        textAlign: "center",
        color: theme.palette.primary.light,
        fontSize: "18px",
    },
    blocContainer__clarif: {
        display: "flex",
        alignItems: "flex-start",
        [theme.breakpoints.only("xs")]: {
            flexDirection: "column",
            gap: "16px",
        },
    },
    typoTitleElement: {
        ...theme.front.typography.label.darkLight,
    },
    productionRendement__typoPrim: {
        textAlign: "center",
        [theme.breakpoints.only("xs")]: {
            textAlign: "left",
        },
    },
    productionRendement__typoSecond: {
        textAlign: "center",
        color: theme.palette.primary.light,
        fontSize: "18px",
        [theme.breakpoints.only("xs")]: {
            textAlign: "left",
        },
    },
    bottling__boxElements__wrapper: {
        display: "flex",
        [theme.breakpoints.only("xs")]: {
            flexDirection: "column",
            gap: "16px",
        },
        [theme.breakpoints.up("xs")]: {
            alignItems: "left",
        },
        [theme.breakpoints.up("sm")]: {
            alignItems: "center",
        },
    },
    bottling__boxElements__img: {
        marginRight: theme.spacing(3),
        [theme.breakpoints.only("xs")]: {
            width: "32px",
        },
    },
    bottling__typoPrim: {
        fontWeight: "400",
        ...theme.front.typography.label.darkLight,
    },
    bottling__typoSecond: {
        color: theme.palette.primary.dark,
        ...theme.front.typography.h6,
    },
    productionBlockContainer: {
        [theme.breakpoints.only("xs")]: {
            paddingLeft: 0,
        },
    },
    blockColumn: {
        display: "flex",
        height: "calc(100% - 12px)",
        flexDirection: "column",
        justifyContent: "center",
        [theme.breakpoints.up("xs")]: {
            alignItems: "left",
        },
        [theme.breakpoints.up("sm")]: {
            alignItems: "center",
        },
    },
    blockRow: {
        display: "flex",
        height: "calc(100% - 12px)",
        flexDirection: "row",
        alignItems: "center",
        [theme.breakpoints.up("xs")]: {
            justifyContent: "left",
        },
        [theme.breakpoints.up("sm")]: {
            justifyContent: "center",
        },
    },
}));

const WineFrontProduction = (props) => {
    const styles = useStyles(props);
    const { width } = useWindowDimensions();
    const { data, loading, error } = useFrontVintageProductionQuery({
        variables: {
            vintageId: props.vintageId,
            locale: props.locale,
            wineId: props.wineId,
        },
    });
    const { data: distributionData } = useFrontVintageMarketingQuery({
        variables: {
            vintageId: props.vintageId,
            wineId: props.wineId,
            locale: props.locale,
        },
    });
    const distribution = distributionData?.FrontVintageMarketing;
    const { data: communeData } = useGetCommunesOptionsQuery();

    const getCommune = (code) => {
        for (let i = 0; i < communeData?.GetCommunesOptions?.length; i++) {
            const comm = communeData?.GetCommunesOptions?.[i];
            if (comm?.communeINSEEcode === code) {
                return comm?.simpleCommuneName;
            }
        }
    };

    if (loading) {
        return (
            <Box display={"flex"} justifyContent={"center"} alignItems={"flex-end"} width="100%">
                <Loading loading={loading} />
            </Box>
        );
    }

    const production = data?.FrontVintageProduction;
    const firstHalfPieChartPercentage = parseInt(production?.production);
    const secondHalfPieChartPercentage = 100 - production?.production;
    const chartOptions = {
        series: [firstHalfPieChartPercentage, secondHalfPieChartPercentage],
        options: {
            tooltip: {
                enabled: false,
            },
            colors: [theme.palette.primary.light, theme.palette.secondary.main],
            legend: {
                show: false,
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: false,
            },
            plotOptions: {
                pie: {
                    expandOnClick: false,
                },
            },
        },
    };

    const bottlingDate = production?.bottlingDate ? new Date(production?.bottlingDate) : null;
    const options = { year: "numeric", month: "long", day: "numeric" };
    const UTCBottlingDate = bottlingDate ? bottlingDate.toLocaleDateString(props.locale, options) : null;

    if (
        ![
            production?.finingTypes?.length !== 0,
            production?.filtrationTypes?.length !== 0,
            production?.yield,
            production?.production,
            production?.bottlesVolume,
            production?.estateBottling,
            production?.address?.address,
            production?.address?.complementaryAddress,
            production?.address?.postalCode,
            production?.address?.commune,
            production?.corkingMode,
            production?.waxedBottle,
            production?.bottler?.title,
            production?.bottlerCommuneINSEEcode,
            production?.bottlerPostalCode,
            UTCBottlingDate,
        ].some(Boolean)
    ) {
        if (distribution && !props.vintageId) {
            return null;
        }
        return (
            <Typography style={{ fontSize: "16px", padding: "16px" }}>
                <Trans>Information non renseignée</Trans>
            </Typography>
        );
    }

    return (
        <>
            {/* CLARIFICATION */}
            {(production?.finingTypes ||
                (production?.filtrationTypes && production?.filtrationTypes?.length !== 0)) && (
                <Box className={styles.blockContainer}>
                    <Box className={styles.typoContainer}>
                        <Typography variant="h3" className={styles.title}>
                            <Trans> Clarification </Trans>
                        </Typography>
                    </Box>
                    <Box className={styles.blocContainer__clarif}>
                        {production?.finingTypes && production?.finingTypes?.length !== 0 && (
                            <Box
                                style={{
                                    marginRight: theme.spacing(2),
                                    maxWidth: "350px",
                                }}
                            >
                                <Typography
                                    variant="h4"
                                    className={styles.typoTitleElement}
                                    style={{
                                        fontFamily: "Lato",
                                        fontSize: "14px",
                                        color: "#915868",
                                        marginBottom: "8px",
                                        fontWeight: "400",
                                    }}
                                >
                                    <Trans> Type de collage </Trans>
                                </Typography>
                                <Typography className={styles.typo}>
                                    {" "}
                                    {getConnectors(production?.finingTypes, true)}
                                </Typography>
                            </Box>
                        )}
                        {production?.filtrationTypes && production?.filtrationTypes?.length !== 0 && (
                            <Box>
                                <Typography
                                    variant="h4"
                                    className={styles.typoTitleElement}
                                    style={{
                                        fontFamily: "Lato",
                                        fontSize: "14px",
                                        color: "#915868",
                                        marginBottom: "8px",
                                        fontWeight: "400",
                                    }}
                                >
                                    <Trans> Filtration </Trans>
                                </Typography>
                                <Typography className={styles.typo} style={{ marginBottom: "1px" }}>
                                    {" "}
                                    {getChips(production?.filtrationTypes, true)}{" "}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
            )}

            {/* RENDEMENT */}
            {production?.yield && (
                <Box className={styles.blockContainer}>
                    <Box className={styles.typoContainer}>
                        <Typography variant="h3" className={styles.title}>
                            <Trans> Rendement </Trans>
                        </Typography>
                    </Box>
                    <Box className={styles.blockColumn}>
                        <Typography
                            variant="h2"
                            className={styles.productionRendement__typoPrim}
                            style={{
                                fontFamily: "Playfair Display",
                                fontSize: "50px",
                                color: "#000000",
                            }}
                        >
                            {production?.yield}
                        </Typography>
                        <Typography
                            className={styles.productionRendement__typoSecond}
                            style={{
                                fontFamily: "Lato",
                                fontSize: "16px",
                                color: "#360318",
                            }}
                        >
                            <Trans>hl/ha</Trans>
                        </Typography>
                    </Box>
                </Box>
            )}

            {/* PRODUCTION */}
            {production?.production && (
                <Box className={[styles.blockContainer, styles.productionBlockContainer].join(" ")}>
                    <Box className={styles.typoContainer}>
                        <Typography variant="h3" className={styles.title}>
                            <Trans> Production </Trans>
                        </Typography>
                    </Box>
                    <Box className={styles.blockRow}>
                        {chartOptions.series[0] !== 100 && (
                            <ReactApexCharts
                                options={chartOptions.options}
                                series={chartOptions.series}
                                type="pie"
                                width={width >= 600 ? 180 : 100}
                            />
                        )}

                        <Box>
                            <Typography
                                variant="h2"
                                style={{
                                    fontFamily: "Playfair Display",
                                    fontSize: "50px",
                                    color: "#000000",
                                }}
                            >{`${production?.production} %`}</Typography>
                            <Typography className={styles.typo}>
                                <Trans> de la récolte </Trans>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            )}

            {/* VOLUME */}
            {production?.bottlesVolume && (
                <Box className={styles.blockContainer}>
                    <Box className={styles.typoContainer}>
                        <Typography variant="h3" className={styles.title}>
                            <Trans> Volume produit </Trans>
                        </Typography>
                    </Box>
                    <Box className={styles.blockRow}>
                        <img
                            src={volumeImg}
                            alt="production_img"
                            style={{
                                marginRight: theme.spacing(3),
                                height: "80px",
                            }}
                        />
                        <Box style={{ textAlign: "center" }}>
                            <Typography
                                variant="h2"
                                style={{
                                    fontFamily: "Playfair Display",
                                    fontSize: "50px",
                                    color: "#00000",
                                }}
                            >
                                {" "}
                                {production?.bottlesVolume.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
                            </Typography>
                            <Typography className={styles.typo}>
                                <Trans> bouteilles </Trans>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            )}

            {/* BOTTLING */}
            {(production?.estateBottling ||
                production?.address?.address ||
                production?.address?.complementaryAddress ||
                production?.address?.postalCode ||
                production?.address?.commune ||
                UTCBottlingDate ||
                production?.bottler?.title ||
                production?.bottlerCommuneINSEEcode ||
                production?.bottlerPostalCode) && (
                <Box className={styles.blockContainer}>
                    <Box className={styles.typoContainer}>
                        <Typography variant="h3" className={styles.title}>
                            <Trans> Mise en bouteille </Trans>
                        </Typography>
                    </Box>
                    <Box className={styles.bottling__boxElements__wrapper}>
                        {production?.estateBottling && (
                            <Box
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginRight: theme.spacing(2),
                                }}
                            >
                                <img src={place} alt="production_img" className={styles.bottling__boxElements__img} />
                                <Box>
                                    <Typography
                                        variant="h4"
                                        className={styles.bottling__typoPrim}
                                        style={{
                                            fontfamily: "Lato",
                                            fontSize: "14px",
                                            color: "#915868",
                                        }}
                                    >
                                        <Trans> lieu </Trans>
                                    </Typography>
                                    <Typography className={styles.typo}>
                                        <Trans>À la propriété</Trans>
                                    </Typography>

                                    {/* {(production?.address?.address ||
                                            production?.address
                                                ?.complementaryAddress) && (
                                                <Typography
                                                className={styles.typo}>
                                                    {`${
                                                    production?.address?.address
                                                        ? production?.address
                                                              ?.address
                                                        : ""
                                                } ${
                                                    production?.address
                                                        ?.address &&
                                                    production?.address
                                                        ?.complementaryAddress
                                                        ? ","
                                                        : ""
                                                } ${
                                                    production?.address
                                                        ?.complementaryAddress
                                                        ? production?.address
                                                              ?.complementaryAddress
                                                        : ""
                                                }`}
                                                </Typography>
                                        )}
                                        {(production?.address?.postalCode ||
                                            production?.address?.commune) && (
                                            <Typography
                                            className={styles.typo}>
                                                {`${
                                                    production?.address
                                                        ?.postalCode
                                                        ? production?.address
                                                              ?.postalCode
                                                        : ""
                                                } ${
                                                    production?.address?.commune
                                                        ? production?.address
                                                              ?.commune
                                                        : ""
                                                }`}
                                            </Typography>
                                        )} */}
                                </Box>
                            </Box>
                        )}
                        {UTCBottlingDate && (
                            <Box
                                style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    marginRight: theme.spacing(2),
                                }}
                            >
                                <img src={date} alt="production_img" className={styles.bottling__boxElements__img} />
                                <Box>
                                    <Typography
                                        variant="h4"
                                        className={styles.bottling__typoPrim}
                                        style={{
                                            fontfamily: "Lato",
                                            fontSize: "14px",
                                            color: "#915868",
                                        }}
                                    >
                                        <Trans> le </Trans>
                                    </Typography>
                                    <Typography className={styles.typo}> {UTCBottlingDate} </Typography>
                                </Box>
                            </Box>
                        )}
                        {(production?.bottler?.title ||
                            production?.bottlerCommuneINSEEcode ||
                            production?.bottlerPostalCode) && (
                            <Box
                                style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                }}
                            >
                                <img src={bottler} alt="production_img" className={styles.bottling__boxElements__img} />
                                <Box>
                                    <Typography variant="h4" className={styles.bottling__typoPrim}>
                                        <Trans> par </Trans>
                                    </Typography>
                                    {production?.bottler?.title ? (
                                        <Typography className={styles.typo}>
                                            {`${production?.bottler?.title ? production?.bottler?.title : ""}`}
                                        </Typography>
                                    ) : (
                                        ""
                                    )}
                                    {production?.bottlerCommuneINSEEcode || production?.bottlerPostalCode ? (
                                        <Typography className={styles.typo}>
                                            {`${production?.bottlerPostalCode ? production?.bottlerPostalCode : ""} ${
                                                production?.bottlerCommuneINSEEcode
                                                    ? getCommune(production?.bottlerCommuneINSEEcode)
                                                    : ""
                                            }`}
                                        </Typography>
                                    ) : (
                                        ""
                                    )}
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
            )}

            {/* BOUCHAGE */}
            {(production?.corkingMode || production?.waxedBottle) && (
                <Box className={styles.blockContainer}>
                    <Box className={styles.typoContainer}>
                        <Typography variant="h3" className={styles.title}>
                            <Trans> Bouchage </Trans>
                        </Typography>
                    </Box>
                    <Box style={{ display: "flex", alignItems: "center" }}>
                        <img
                            src={cork}
                            alt="production_img"
                            style={{
                                marginRight: theme.spacing(3),
                                width: "50px",
                            }}
                        />
                        <Box>
                            {production?.corkingMode && (
                                <Typography
                                    variant="h4"
                                    style={{
                                        fontSize: "16px",
                                        color: "#360318",
                                        fontFamily: "lato",
                                        fontWeight: "500",
                                    }}
                                >
                                    {" "}
                                    {firstLetterUppercase(production?.corkingMode)}{" "}
                                </Typography>
                            )}
                            {production?.waxedBottle && (
                                <Typography
                                    variant="h4"
                                    style={{
                                        fontSize: "16px",
                                        color: "#360318",
                                        fontFamily: "lato",
                                        fontWeight: "500",
                                    }}
                                >
                                    <Trans> Bouteille cirée </Trans>
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
};

WineFrontProduction.propTypes = {
    vintageId: PropTypes.string,
    wineId: PropTypes.string,
    locale: PropTypes.string,
    wineName: PropTypes.string,
    year: PropTypes.number,
    aoc: PropTypes.string,
};

export default WineFrontProduction;
