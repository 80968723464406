import { SwiperWrapper } from "../Front/SwiperCards";
import theme from "../globalTheme";
import Loading from "../Components/Loader/Loading";
import { useGetAuthorFrontListQuery } from "../generated/graphql";
import { useParams } from "react-router-dom";
import FeretAuthorCard from "FrontAuthor/FeretAuthorCard";

const FeretAuthors = () => {
    const { name } = useParams();
    const { data: { GetAuthorFrontList: feretAuthors } = {}, loading } = useGetAuthorFrontListQuery({
        variables: { name },
    });

    if (loading) {
        return <Loading />;
    }

    return (
        <SwiperWrapper boxPaddingTop={4} spaceBetween={0} noMargin dark>
            {feretAuthors?.map((e, i) => {
                const key = `feret_author-${i}`;
                return <FeretAuthorCard key={key} personIdentity={e.personIdentity} photo={e?.photo?.twicpicURL} />;
            })}
        </SwiperWrapper>
    );
};

export default FeretAuthors;
