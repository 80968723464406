import { string } from "prop-types";
import { Box, Typography, makeStyles } from "@material-ui/core";
import theme from "globalTheme";
import fioriture from "assets/fioriture.svg";

const useStyles = makeStyles(() => ({
    logo_container: {
        width: "156px",
        height: "30px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.secondary.dark,
        backgroundImage: `url(${fioriture})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    },
    name: {
        color: theme.palette.primary.dark,
        textTransform: "uppercase",
    },
}));

const DefaultLogo = (props) => {
    const styles = useStyles();
    return (
        <Box className={styles.logo_container}>
            <Typography className={styles.name} variant="subtitle1">
                {props.criticName}
            </Typography>
        </Box>
    );
};

DefaultLogo.propTypes = {
    criticName: string,
};

export default DefaultLogo;
