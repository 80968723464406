/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import { makeStyles, Accordion, AccordionSummary, AccordionDetails, Link, Typography, Box, Hidden } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import theme  from "../../../globalTheme";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PropTypes from "prop-types";
import frenchflag from "../../../assets/Wine/ageing/france.svg";
import englishflag from "../../../assets/Dashboard/translationAssets/eng.svg";
import CheckIcon from "@material-ui/icons/Check";
import Arrow from "../../../Components/Arrow/Arrow";
import AskForTranslationPopin from "./AskForTranslationPopin";
import BetaTranslationPopin from "./BetaTranslationPopin";
import AboutTranslationPopin from "./AboutTranslationPopin";
import AfterPaymentInformationPopin from "./AfterPaymentInformationPopin";
import Tooltip from "../../../Components/Tooltips/Tooltip";

const useStyles = makeStyles((theme) => ({
    relativeContainer: {
        position: "relative",
        borderRadius: 10,
        zIndex: 50,
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
    },
    accordion: {
        backgroundColor: "transparent",
        position: "absolute",
        zIndex: 50,
        height: "20px",
        "& .MuiAccordionSummary-content": {
            flexGrow: 0,
        },
        "& .MuiButtonBase-root": {
            minHeight: 45
        },
        "& .MuiAccordionSummary-root.Mui-expanded": {
            minHeight: 45,
            marginTop: "-10px"
        },
        "& .MuiAccordionSummary-root": {
            padding: "0px 10px"
        },
        "& .MuiIconButton-root": {
            padding: 4
        },

    },
    expandIcon: {
        color: theme.palette.secondary.dark,
        fontSize: "18px",
    },
    avatar: (props) => ({
        backgroundColor: !props.avatarImage && theme.palette.primary.dark,
        width: 30,
        height: 30,
    }),
    container: {
        padding: 0,
        display: "flex",
        alignItems: "center",
        marginRight: theme.spacing(1),
    },
    icon: {
        marginRight: theme.spacing(1),
        height: "20px",
        color: theme.palette.secondary.dark,
    },
    link: {
        display: "flex",
        cursor: "pointer",
    },
    checkIcon: props => ({
        fill: theme.palette.success.main,
        width: "20px",
    }),
}));

const LanguagesBloc = (props) => {
    const styles = useStyles(props);

    const [accordionOpen, setAccordionOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState("FR");
    const [displayedLanguage, setDisplayedLanguage] = useState(<Box style={{ display: "flex", paddingLeft: "8px" }}>
        <img src={frenchflag} alt="flag" style={{ marginRight: "6px", width: "16px" }} />
        <Typography style={{ color: theme.palette.primary.dark, fontSize: "14px" }}>{"FR"}</Typography>
    </Box>);

    const [manualPopinOpen, setManualPopinOpen] = useState(false);
    const [aboutTranslationOpen, setAboutTranslationOpen] = useState(false);
    const [afterPaymentOpen, setAfterPaymentOpen] = useState(false);
    const [betaPopinOpen, setBetaPopinOpen] = useState(false);

    const getPaddingRight = () => {
        if (props.translationState === 3) {
            return 75;
        } else if (props.translationState === 4) {
            return 85;
        } else {
            return 90;
        }
    };

    const countries = [
        {
            country: "FR",
            flag: frenchflag,
            fullname: "Français",
            // translateStage: "OK", 
            // manualTranslateStage: "OK",
        },
        {
            country: "EN",
            flag: englishflag,
            fullName: "Anglais",
            // translateStage: "NO",
            // manualTranslateStage: "NO",
        }];

    const accordionOnChange = () => {
        //BETA
        return setBetaPopinOpen(true);
        // return setAccordionOpen(!accordionOpen)
    };

    const handleLanguagesOnClick = (el) => {
        setAccordionOpen(false);
        return setSelectedLanguage(el.country);
    };

    useEffect(() => {
        countries.map((el, i) => {
            if (el.country === selectedLanguage) {
                // IF STEP 2 (WAITING FOR MECA TRAD) & SELECTED LANGUAGE) : ADD TOOLTIP
                if (props.translationState === 2) {
                    setDisplayedLanguage(
                        <Box style={{ display: "flex", padding: 8, paddingRight: 0 }}>
                            <Tooltip
                                width={200}
                                color="primary"
                                arrow
                                title={<Typography style={{ color: "white", fontSize: "14px" }}> La traduction sera mise à jour au prochain enregistrement </Typography>}
                            >
                                <img src={el.flag} alt="flag" style={{ opacity: 0.5, marginRight: "6px", width: "16px" }} />
                            </Tooltip>
                            <Typography style={{ color: theme.palette.secondary.dark, fontSize: "14px" }}>
                                {el.country}
                            </Typography>
                        </Box>
                    );
                } else {
                    // ELSE : NO TOOLTIP
                    setDisplayedLanguage(
                        <Box style={{ display: "flex", paddingLeft: "8px" }}>
                            <img src={el.flag} alt="flag" style={{ opacity: (props.translationState === 4) && 0.5, marginRight: "6px", width: "16px" }} />
                            <Typography style={{ opacity: (props.translationState === 4) && 0.5, color: theme.palette.primary.dark, fontSize: "14px" }}>
                                {el.country}
                            </Typography>
                        </Box>
                    );
                }
            }
        });
    }, [selectedLanguage]);

    return (
        <Box className={styles.relativeContainer} style={props.style}>
            {/* 3 DIFFERENT POPINS */}
            {manualPopinOpen &&
                <AskForTranslationPopin
                    modalOpen={manualPopinOpen}
                    handleClose={() => setManualPopinOpen(false)} />
            }
            {aboutTranslationOpen &&
                <AboutTranslationPopin
                    modalOpen={aboutTranslationOpen}
                    handleClose={() => setAboutTranslationOpen(false)}
                    setManualPopinOpen={setManualPopinOpen}
                />
            }
            {afterPaymentOpen &&
                <AfterPaymentInformationPopin
                    modalOpen={afterPaymentOpen}
                    handleClose={() => setAfterPaymentOpen(false)} />
            }
            {/* BETA POPIN */}
            {betaPopinOpen &&
                <BetaTranslationPopin
                    modalOpen={betaPopinOpen}
                    handleClose={() => setBetaPopinOpen(false)} />
            }

            <Box style={{ width: "100%", height: 0, display: "flex", justifyContent: "flex-end", paddingRight: getPaddingRight(), position: "absolute", top: "-32px" }}>
                {/* IF STEP 4 (WAITING FOR MANUAL TRAD) : ADD "i" AND WAITING FOR MANUAL TEXT */}
                {props.translationState === 4 &&
                    <Box style={{ display: "flex", marginRight: "12px" }}>
                        <Box onClick={() => setAfterPaymentOpen(true)} style={{ marginTop: "14px", cursor: "pointer", borderRadius: "50px", backgroundColor: theme.palette.warning.main, width: "15px", height: "15px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Typography style={{ fontSize: "12px", color: "white", fontWeight: "bold", fontFamily: "Mansalva", }}>
                                i
                            </Typography>
                        </Box>
                        <Typography style={{ marginLeft: "4px", marginTop: "14px", fontSize: "12px", color: theme.palette.warning.main, fontWeight: "bold" }}>
                            Traduction manuelle en cours de traitement
                        </Typography>
                    </Box>}

                {/* IF STEP 3 (TRANSLATE WITH MACHINE) : ASK FOR TRAD ARROW */}
                {props.translationState === 3 &&
                    <Arrow rotation="240" arrowPosition="end" style={{ paddingTop: "6px" }}  >
                        <Link underline="always"
                            variant="h6"
                            style={{ cursor: "pointer", marginRight: "2px" }}
                            onClick={() => setManualPopinOpen(true)}
                        >
                            <Hidden mdDown>
                                Demander une traduction manuelle
                            </Hidden>
                            <Hidden lgUp>
                                Demande de traduction
                            </Hidden>
                        </Link>
                    </Arrow>}
                {props.translationState === 3 &&
                    <Tooltip
                        width={200}
                        color="primary"
                        arrow
                        title={
                            <Typography style={{ color: "white", fontSize: "14px" }}> À propos de cette traduction </Typography>
                        }
                    >
                        <Box
                            onClick={() => setAboutTranslationOpen(true)}
                            style={{ position: "relative", cursor: "pointer", borderRadius: "50px", backgroundColor: theme.palette.primary.dark, width: "15px", height: "15px", display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "8px", marginTop: "14px" }}
                        >
                            <Typography style={{ fontSize: "12px", color: "white", fontWeight: "bold" }}>?</Typography>
                        </Box>
                    </Tooltip>}


                {/* ACCORDION */}
                <Box>
                    <Accordion
                        square
                        elevation={0}
                        expanded={accordionOpen}
                        onChange={() => accordionOnChange()}
                        className={styles.accordion}
                        TransitionProps={{ unmountOnExit: true }}
                    >
                        <AccordionSummary
                            style={{ backgroundColor: "transparent", height: "20px", margin: 0, display: "flex", justifyContent: "flex-start" }}
                            expandIcon={<ExpandMoreIcon className={styles.expandIcon} />}
                        >
                            <Box style={{ display: "flex", flexDirection: "column" }}>
                                {displayedLanguage}
                            </Box>
                        </AccordionSummary>

                        <AccordionDetails style={{ marginTop: "-5px", paddingBottom: "6px", backgroundColor: theme.palette.dashboard.background, borderRadius: "20px", boxShadow: "0px 3px 6px #00000029", }}>
                            <ClickAwayListener onClickAway={() => setAccordionOpen(false)}>
                                <Box style={{ display: "flex", flexDirection: "column" }}>
                                    {countries.map((el, i) => {
                                        return <Box key={i} style={{ display: "flex", marginBottom: "4px", cursor: "pointer" }} onClick={() => handleLanguagesOnClick(el)}>
                                            <img src={el.flag} alt="flag" style={{ marginRight: "6px", width: "16px" }} />
                                            <Typography style={{ marginRight: "6px", fontSize: "14px" }}>{el.country}</Typography>
                                            {selectedLanguage === el.country && <CheckIcon className={styles.checkIcon} />}
                                        </Box>;
                                    })}
                                </Box>
                            </ClickAwayListener>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Box>
        </Box>
    );
};

LanguagesBloc.propTypes = {
    translationState: PropTypes.number,
    // 1: rien // BETA
    // 2: en att trad     
    // 3: traduit machine  
    // 4: en att traduit manuel 
    // 5: traduit manuel 
    showAddSubscription: PropTypes.bool,
    onAddSubscriptionClick: PropTypes.func,
    avatarImage: PropTypes.string,
    userName: PropTypes.string,
    currentEstate: PropTypes.string,
    estates: PropTypes.array,
    onClickDisconnect: PropTypes.func,
    expanded: PropTypes.bool,
    handleChangeExpanded: PropTypes.func,
    style: PropTypes.object,
};

export default LanguagesBloc;