import { any, bool, func } from "prop-types";
import { makeStyles, Box, Typography } from "@material-ui/core";

import Popin from "../../Containers/Popin";
import reward from "../../assets/reward.svg";
import { makeHttp } from "helpers/helpers";

const useStyles = makeStyles((theme) => ({
    container: {
        overflowY: "scroll",
        paddingRight: "5px",
        maxHeight: "350px",

        [theme.breakpoints.up("sm")]: {
            maxHeight: "370px",
        },

        [theme.breakpoints.up("md")]: {
            maxHeight: "600px",
        },

        [theme.breakpoints.up("lg")]: {
            maxHeight: "600px",
        },

        "&::-webkit-scrollbar": { width: "8px", height: "8px" },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(99,45,62,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(99,45,62,0.00)",
            paddingRight: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.primary.main,
            borderRadius: "20px",
        },
    },
    reward_container: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginBottom: "20px",
        marginLeft: 0,
        width: "100%",

        "&:last-child": {
            marginBottom: "0px",
        },
    },
    box_content: {
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap",
        marginLeft: "12px",

        [theme.breakpoints.up("sm")]: {
            flexWrap: "no-wrap",
            width: "100%",
        },
    },
    list: {
        display: "flex",
        padding: 0,
        margin: 0,

        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    list_item: {
        marginLeft: "25px",
    },
    list_item_content: {
        // ...theme.front.typography.labeur,
        fontWeight: "bold",
        color: theme.palette.primary.dark,
    },
    reward_image: {
        width: "51px",
        height: "51px",
    },
    content: {
        // ...theme.front.typography.labeur,
        fontWeight: "bold",
        color: theme.palette.primary.dark,
    },
}));

const RewardsPopin = (props) => {
    const styles = useStyles();

    return (
        <Popin
            background={6}
            open={props.open}
            cross
            onClose={props.onClose}
            title={`Médailles obtenues : ${props?.rewards.length}`}
            imgTitle={reward}
            styleImgTitle={{ marginRight: "13px" }}
            titleStyle={{ marginBottom: "0px", alignSelf: "auto" }}
            styleHeader={{ marginBottom: "41px" }}
            containerWidth="555px"
            typographyVariant="h3"
        >
            <Box className={styles.container}>
                {props?.rewards.map((el, i) => (
                    <Box key={i} className={styles.reward_container}>
                        <a href={makeHttp(el?.url)} target="_blank" rel="noreferrer">
                            <img src={el?.image?.data} className={styles.reward_image} />
                        </a>

                        <Box className={styles.box_content}>
                            <Typography variant="body2" className={styles.content}>
                                {el?.competitionName}
                            </Typography>

                            <ul className={styles.list}>
                                <li className={styles.list_item}>
                                    <Typography variant="body2" className={styles.list_item_content}>
                                        {el?.year}
                                    </Typography>
                                </li>
                                <li className={styles.list_item}>
                                    <Typography variant="body2" className={styles.list_item_content}>
                                        {el?.rewardName}
                                    </Typography>
                                </li>
                            </ul>
                        </Box>
                    </Box>
                ))}
            </Box>
        </Popin>
    );
};

RewardsPopin.propTypes = {
    open: bool,
    onClose: func,
    rewards: any,
};

export default RewardsPopin;
