import { React, useState } from "react";
import { makeStyles, TextField, IconButton, InputAdornment, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import CheckIcon from "@material-ui/icons/Check";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import ErrorIcon from "../../assets/Error.svg";

const useStyles = makeStyles((theme) => ({
    root: (props) => ({
        width: "100%",
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
        },
        "& .MuiOutlinedInput-root": {
            fontSize: "14px",
            color: theme.palette.primary.dark,
            borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
            backgroundColor: "white",
            height: "34px",
            "&.Mui-disabled": {
                color: theme.palette.secondary.dark,
                backgroundColor: theme.palette.disabled.main,
            },
            "&:hover fieldset": {
                borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
            },
            "&.Mui-focused fieldset": {
                border: "1px solid",
                borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
            },
        },
        "& .MuiFormLabel-root": {
            fontSize: "14px",
            color: props.error ? theme.palette.error.main : theme.palette.secondary.dark,
        },
    }),
    boxContainer: {
        position: "relative",
        width: "100%",
    },
    checkIcon: {
        width: "20px",
        fill: theme.palette.success.main,
    },
    falseIcon: {
        width: "20px",
        fill: theme.palette.error.main,
    },
    errorText: {
        fontSize: "12px",
        color: theme.palette.error.main,
        marginBottom: theme.spacing(1),
        marginTop: "0px",
        marginLeft: "8px",
    },
}));

const PasswordInput = (props) => {
    const styles = useStyles(props);
    const [passwordVisibility, setPasswordVisibility] = useState(false);

    const handleClickShowPassword = () => {
        setPasswordVisibility(!passwordVisibility);
    };

    return (
        <>
            <TextField
                autoComplete="no-autcomplete"
                inputRef={props.inputRef}
                id={props.id}
                name={props.name}
                style={props.style}
                onBlur={props.onBlur}
                onChange={props.onChange}
                onFocus={() => {
                    if (props.onFocus) {
                        props.onFocus();
                    }
                }}
                value={props.value}
                type={passwordVisibility ? "text" : "password"}
                variant="outlined"
                disabled={props.disabled}
                error={props.error}
                label={props.label}
                placeholder={props.placeholder}
                required={props.required}
                className={styles.root}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                    autoComplete: "disabled", // disable autocomplete and autofill
                }}
                InputProps={{
                    endAdornment: props.noEndAdornment ? null : (
                        <InputAdornment position="end">
                            <IconButton
                                disabled={props.disabled}
                                onClick={handleClickShowPassword}
                                style={{ padding: "8px" }}
                            >
                                {passwordVisibility ? (
                                    <VisibilityOffIcon color="secondary" style={{ width: "20px" }} />
                                ) : (
                                    <VisibilityIcon color="secondary" style={{ width: "20px" }} />
                                )}
                            </IconButton>
                            {props.correctValue && <CheckIcon className={styles.checkIcon} />}
                            {props.error && <img src={ErrorIcon} className={styles.falseIcon} />}
                        </InputAdornment>
                    ),
                }}
            />
            {props.error && (
                <Typography style={props.errorStyle} variant="h6" className={styles.errorText}>
                    {props.error}
                </Typography>
            )}
        </>
    );
};

PasswordInput.propTypes = {
    inputRef: PropTypes.any,
    onBlur: PropTypes.func,
    variant: PropTypes.oneOf(["text", "contained", "outlined"]),
    disabled: PropTypes.bool,
    error: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    style: PropTypes.object,
    correctValue: PropTypes.bool,
    helperText: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    getValues: PropTypes.func,
    onChange: PropTypes.func,
    falseValue: PropTypes.bool,
    noEndAdornment: PropTypes.bool,
    onFocus: PropTypes.func,
    errorStyle: PropTypes.any,
};
PasswordInput.defaultProps = {
    onChange: () => {},
    onBlur: () => {},
};

export default PasswordInput;
