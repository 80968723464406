import React from "react";
import { Typography, makeStyles, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import Popin from "../../Containers/Popin";
import theme from "../../globalTheme";
import  {  firstLetterUppercase } from "../../helpers/helpers";
import accord from "../../assets/Wine/advices/accord.svg";
import { t } from "@lingui/macro";
import BaseChip from "../../Components/Chips/BaseChip";

const useStyle = makeStyles((theme) => ({
    container_aromas: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        width: "100%",
        gap: "1rem",
    },
    titleBox: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(1),
    },
    blocksTitle: {
        display: "flex",
        alignItems: "center",
        marginRight: "10px",
    },
    flexWrap: {
        display: "flex",
        flexWrap: "wrap",
        gap: "24px",
    },
    image1: {
        width: "20px",
        marginRight: "4px",
    },
    blocksContainer: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "auto / span 12",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "auto / span 6",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 4",
        },
    },
}));

const FrontFoodPairingPopin = (props) => {
    const styles = useStyle(props);

    return (
        <Popin
            containerWidth="70rem"
            open={props.open}
            onClose={props.onClose}
            cross
            background={3}
            title={
                <Box className={styles.titleBox}>
                    <img src={accord} alt="climat_image" style={{ marginRight: "4px" }} />
                    <Typography variant="h3">{t`Accords mets et vins`}</Typography>
                </Box>
            }
        >
            <Box style={{ borderRadius: "10px", height: "40vh" }}>
                <Box className={styles.container_aromas}>
                    {props?.foodPairings.map((el, i) => {
                        return (
                            <Box key={i} className={styles.blocksContainer}>
                                <Box className={styles.blocksTitle} style={{ marginBottom: "8px" }}>
                                    <img src={el?.icon?.data} className={styles.image1} alt="icon" />
                                    <BaseChip title={firstLetterUppercase(el?.category)} />
                                </Box>
                                <Typography
                                    className={styles.typoAccord}
                                    style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        color: theme.palette.primary.light,
                                    }}
                                >
                                    {el?.foods?.map((food, i) => {
                                        return (
                                            <Typography variant="h6" key="">
                                                {firstLetterUppercase(food)}
                                                {el?.foods?.length > 1 && el?.foods?.length !== i + 1 && (
                                                    <span>,&nbsp;</span>
                                                )}
                                            </Typography>
                                        );
                                    })}
                                </Typography>
                            </Box>
                        );
                    })}
                </Box>
            </Box>
        </Popin>
    );
};

FrontFoodPairingPopin.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    foodPairings: PropTypes.any,
};

export default FrontFoodPairingPopin;
