import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./i18n-next";
import * as serviceWorker from "./serviceWorker";

// TwicPics Components importation
import { installTwicPics } from "@twicpics/components/react";

// TwicPics Components configuration (see Setup Options)
installTwicPics({
    // domain is mandatory
    domain: "https://feret.twic.pics",
});
ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
