import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import theme from "../globalTheme";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
    ul: {
        color: theme.palette.primary.dark,
        paddingLeft: 16,
        marginTop: 0,
        marginBottom: 0,
    },
}));

const SkillsDetails = (props) => {
    const styles = useStyles(props);

    return (
        <Box
            style={{
                color: theme.palette.common.black,
                padding: "7px 4px",
                height: props.height ?? "auto",
                overflow: "hidden",
            }}
        >
            <Typography variant="body2" style={{ color: theme.palette.primary.dark }}>
                {props?.data}
            </Typography>
        </Box>
    );
};

SkillsDetails.propTypes = {
    data: PropTypes.array,
    height: PropTypes.number,
};
export default SkillsDetails;
