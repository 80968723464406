import download2 from "../assets/summary/download.svg";
import FeretSummaryContainer from "../Front/FeretSummary/FeretSummaryContainer";
import FeretSummaryElement from "../Front/FeretSummary/FeretSummaryElement";
import { Box, makeStyles } from "@material-ui/core";
import zoom from "../assets/zoom.svg";
import CDC from "../assets/appellation/summary/CDC_icon.svg";
import appellation from "../assets/appellation/summary/wine_product_icon.svg";
import { urlize } from "../helpers/helpers";
import { t, Trans } from "@lingui/macro";
import { Link } from "react-router-dom";
import { useCustomHistory } from "hooks";

const useStyles = makeStyles((theme) => ({
    subtitle_primary: {
        color: theme.palette.primary.light,
        lineHeight: "16px",
    },
    subtitle_secondary: {
        color: theme.palette.primary.dark,
    },
    container_item: {
        display: "flex",
        flexDirection: "column",
        marginTop: "0.25rem",
    },
    subtitle_item: {
        display: "flex",
        alignItems: "flex-start",
        lineHeight: 1.2,
    },
}));

type AppellationType = {
    label: string;
    link: string;
};

interface AppellationSummaryProps {
    appellations: AppellationType[];
    maxWidth?: string;
}

const OdgSummary = (props: AppellationSummaryProps) => {
    const styles = useStyles();
    const { addLocale } = useCustomHistory();

    const getLinkLenght = () =>
        props?.appellations.length > 0 ? props?.appellations.map(({ link }) => link).filter(Boolean).length : 0;

    return (
        <FeretSummaryContainer maxWidth={props.maxWidth ?? "none"}>
            <FeretSummaryElement
                icon={appellation}
                title={props?.appellations?.length > 1 ? "Appellations" : "Appellation"}
                subtitle={
                    <Box className={styles.container_item}>
                        {props?.appellations?.map((appellation: AppellationType, i: number) => (
                            <Box key={i} className={styles.subtitle_item}>
                                {appellation.label}
                                <Box style={{ marginLeft: "0.25rem" }}>
                                    <Link to={addLocale(`/appellations/blaye-et-bourg/${urlize(appellation.label)}`)}>
                                        <img style={{ width: "18px", height: "18px" }} src={zoom} />
                                    </Link>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                }
            />
            {getLinkLenght() > 0 && (
                <FeretSummaryElement
                    icon={CDC}
                    title={
                        props?.appellations?.filter((e) => e.link)?.length > 1
                            ? t`Cahiers des charges`
                            : t`Cahier des charges`
                    }
                    subtitle={
                        <Box className={styles.container_item}>
                            {props?.appellations?.map((appellation: AppellationType, i: number) => {
                                if (!appellation.link) return null;
                                return (
                                    <Box key={i} className={styles.subtitle_item}>
                                        <Box>
                                            <Trans>Accéder au CDC de {appellation.label}</Trans>
                                        </Box>
                                        <Box style={{ marginLeft: "0.25rem" }}>
                                            <a href={appellation.link} target="_blank" rel="noreferrer">
                                                <img style={{ width: "18px", height: "18px" }} src={download2} />
                                            </a>
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                    }
                />
            )}
        </FeretSummaryContainer>
    );
};

export default OdgSummary;
