import React, { useState } from "react";
import PropTypes from "prop-types";
import { Typography, makeStyles } from "@material-ui/core";
import theme from "../../globalTheme";
import { parseHtml } from "../../helpers/helpers";
import { Trans } from "@lingui/macro";

const useStyles = makeStyles(() => ({
    voirPlus: {
        cursor: "pointer",
        color: "#9F8D93",
        textDecoration: "underline",
        fontSize: "16px",
    },
    typographyScroll: (props) => ({
        overflowWrap: "break-word",
        fontFamily: "Lato",
        textAlign: "left",
        color: theme.palette.primary.dark,
        maxHeight: props.maxHeight ?? "auto",
        overflowY: props.scroll ? "scroll" : "inherit",
        paddingRight: "5px",
        "&::-webkit-scrollbar": { width: "8px", height: "8px" },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(99,45,62,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(99,45,62,0.00)",
            paddingRight: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.primary.main,
            borderRadius: "20px",
        },
    }),
}));

const ExpandableFormattedTextBox = (props) => {
    const [expanded, setExpanded] = useState(false);
    const styles = useStyles(props);

    if (!expanded) {
        return (
            <>
                <Typography variant="body2" className={styles.typographyScroll} style={props.styleTypo}>
                    {parseHtml(props.truncatedValue, "body2")}
                </Typography>
                {props.truncatedValue !== props.value && (
                    <Typography className={styles.voirPlus} onClick={() => setExpanded(!expanded)}>
                        <Trans>Voir plus</Trans>
                    </Typography>
                )}
            </>
        );
    }

    return (
        <>
            <Typography variant="body2" className={styles.typographyScroll} style={props.styleTypo}>
                {parseHtml(props.value)}
            </Typography>
            {props.truncatedValue !== props.value && (
                <Typography
                    className={styles.voirPlus}
                    onClick={() => {
                        setExpanded(!expanded);
                    }}
                >
                    <Trans>Voir moins</Trans>
                </Typography>
            )}
        </>
    );
};

ExpandableFormattedTextBox.propTypes = {
    value: PropTypes.string,
    truncatedValue: PropTypes.string,
    maxHeight: PropTypes.string,
    scroll: PropTypes.bool,
    list: PropTypes.bool,
    styleTypo: PropTypes.object,
};

export default ExpandableFormattedTextBox;
