import { t, Trans } from "@lingui/macro";
import { Box, makeStyles, Typography } from "@material-ui/core";
import Helmet from "Components/Helmet/Helmet";

import floriture1 from "../assets/encyclopedia/floriture-1.svg";
import floriture2 from "../assets/encyclopedia/floriture-2.svg";
import floriture3 from "../assets/encyclopedia/floriture-3.svg";
import floriture4 from "../assets/encyclopedia/floriture-4.svg";
import floriture5 from "../assets/encyclopedia/floriture-5.svg";
import floriture6 from "../assets/encyclopedia/floriture-6.svg";
import FrontContentTop from "../Front/FrontContentTop";
import FrontMainContainer from "../Front/FrontMainContainer";
import FeretAuthors from "../FrontAuthor/FeretAuthors";
import FeretVisitEncyclopedia from "../FrontPost/FeretVisitEncyclopedia";
import FrontTitle from "../FrontRanking/FrontTitle";
import theme from "../globalTheme";
import { useWindowDimensions } from "../helpers/helpers";

const useStyles = makeStyles((e) => ({
    typoAbout: {
        color: theme.palette.text.primary,
        textAlign: "justify",
    },
    boxSlider: {
        background: theme.palette.background.card,
        marginTop: 16,
        padding: 8,
    },
    slider: { paddingLeft: 0, paddingRight: 0, paddingBottom: 0 },
}));

const EncyclopediaPage = () => {
    const styles = useStyles();
    const { width } = useWindowDimensions();

    const getBackgroundImage = () => {
        if (width >= 1920) {
            return {
                backgroundImage: `url(${floriture3}), url(${floriture4}), url(${floriture5}), url(${floriture6})`,
                backgroundPositionY: "71px, top, bottom, bottom",
                backgroundPositionX: "left, right, left, right",
            };
        } else if (width < 1920 && width >= 960) {
            return {
                backgroundImage: `url(${floriture4})`,
                backgroundPositionY: "top",
                backgroundPositionX: "right",
            };
        }
        return {
            backgroundImage: "none",
        };
    };

    return (
        <>
            <Helmet
                title={t`Encyclopédie Féret`}
                content="Découvrez l'Encyclopédie Féret, la référence incontournable pour tout savoir sur le vin de Bordeaux. Histoire, terroirs, cépages, appellations... plongez dans l'univers fascinant de l'un des plus grands vignobles du monde."
            />
            <FrontContentTop
                backgroundColor={theme.palette.secondary.dark}
                boxStyles={{
                    ...getBackgroundImage(),
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "auto",
                    minHeight: "calc(100vh - 75px)",
                    alignItems: "flex-start",
                    paddingBottom: 20,
                }}
            >
                <FrontTitle className={styles.frontTitle} title={t`L'encyclopédie Féret`} variantTypo="h1" />

                <FrontMainContainer
                    styleCardContent
                    className={styles.main_container}
                    style={{
                        backgroundImage: width >= 960 ? `url(${floriture1}), url(${floriture2})` : `url(${floriture2})`,
                        backgroundPositionY: width >= 960 ? "top, bottom" : "bottom",
                        backgroundPositionX: width >= 960 ? "left, right" : "right",
                        backgroundRepeat: "no-repeat",
                    }}
                    hideFloritureTopImg
                >
                    <Box style={{ backgroundColor: theme.palette.common.white, padding: 2 }}>
                        <Typography variant="body2" className={styles.typoAbout}>
                            <Trans>
                                La rencontre des Éditions Féret et du vin a lieu en 1850 et presque deux siècles après,
                                leur lien est toujours aussi fort.
                            </Trans>
                        </Typography>
                        <br />
                        <Typography variant="body2" className={styles.typoAbout}>
                            <Trans>
                                Féret c’est non seulement 19 éditions de <em>Bordeaux et ses Vins</em> entre 1850 et
                                2014 mais également des livres techniques traitant de la vigne et du vin. Au XIXᵉ
                                siècle, la maison publie des grands noms comme Guyot, Millardet ou encore Gayon.
                            </Trans>
                        </Typography>
                        <br />
                        <Typography variant="body2" className={styles.typoAbout}>
                            <Trans>
                                En 1998, les Éditions décident d’étoffer leur catalogue de titres et de renouer avec
                                cette tradition initiée par Édouard Féret. C’est ainsi qu’est lancée la collection des
                                Usuels de la vigne et du vin, dont chaque titre est rédigé par des experts et des
                                intervenants au niveau mondial.
                            </Trans>
                        </Typography>
                        <br />
                        <Typography variant="body2" className={styles.typoAbout}>
                            <Trans>
                                Les compétences de ces auteurs Féret sont également utilisées pour la première fois en
                                2001 pour la XVIᵉ édition de <i>Bordeaux et ses Vins</i>. Entièrement refondu, augmenté
                                et modernisé, ce nouveau Féret contient une première partie encyclopédique qui aborde de
                                façon synthétique tous les thèmes autour de la vigne et du vin en Gironde : l’histoire,
                                le terroir, les cépages, les maladies et les virus, la vinification, l’élevage, le
                                climat, la réglementation des étiquettes, le droit en matière de marques, la santé,
                                l’art de déguster, etc.
                            </Trans>
                        </Typography>
                        <br />
                        <Typography variant="body2" className={styles.typoAbout}>
                            <Trans>
                                C’est toujours dans cette continuité que Féret vous propose cette encyclopédie Féret en
                                ligne. Certains de nos experts nous ont fait l’honneur de nous suivre dans cette
                                aventure numérique. Ils ont, chacun dans leur domaine de compétence, mis à jour et/ou
                                rédigé les chapitres ci-dessous.
                            </Trans>
                        </Typography>
                    </Box>
                    <Box className={styles.boxSlider}>
                        <FeretVisitEncyclopedia
                            titleCenter
                            hideLowBar
                            titleStyle={{ color: theme.palette.primary.dark, fontWeight: 700, marginTop: 0 }}
                            boxClassName={styles.slider}
                            noPadding
                        />
                    </Box>
                    <Typography
                        variant="h3"
                        style={{
                            textAlign: "center",
                            margin: "2rem 0 1rem 0",
                            fontWeight: "700",
                            color: theme.palette.primary.dark,
                        }}
                    >
                        <Trans>Les auteurs</Trans>
                    </Typography>
                    <FeretAuthors />
                </FrontMainContainer>
            </FrontContentTop>
        </>
    );
};

export default EncyclopediaPage;
