// PHOTO
export const PHOTO_REPORT_HT = 450;
export const PACKSHOT_UNIT_HT = 25;

export const PHOTO_REPORT_HT_BASIC = 650;
export const PACKSHOT_UNIT_HT_BASIC = 30;

// STANDALONE
export const STANDALONE_HT = 40;

// STARTUP
export const STARTUP_HT = 500;

//TASTING REQUEST
export const TASTING_REQUEST_HT = 160;
export const TASTING_REQUEST_VIDEO_HT = 480;
