import { Box, Link, makeStyles, Typography } from "@material-ui/core";
import { bool, func, shape, string } from "prop-types";
import { useState } from "react";

import { AutocompleteCommune } from "Components/Inputs/AutocompleteCommune";
import CGUPopin from "Dashboard/Popins/CGUPopin";
import BaseButton from "Components/Buttons/BaseButton";
import AddressAutocompleteInput from "Components/Inputs/AddressAutocompleteInput";
import BaseCheckbox from "Components/Inputs/BaseCheckbox";
import ConstraintsPasswordInput from "Components/Inputs/ConstraintsPasswordInput";
import PasswordInput from "Components/Inputs/PasswordInput";
import SelectInput from "Components/Inputs/SelectInput";
import SimpleInput from "Components/Inputs/SimpleInput";
import TelephoneInput from "Components/Inputs/TelephoneInput";
import ControlledSwitchLabelInside from "Components/Switch/ControlledSwitchLabelInside";
import theme from "globalTheme";

const useStyles = makeStyles(() => ({
    container_grid: {
        gridTemplateColumns: "repeat(12, 1fr)",
        display: "grid",
        gap: "16px",
    },
    title_container: {
        gridColumn: "1 / 3",
        [theme.breakpoints.down("xs")]: {
            gridColumn: "1 / span 12",
        },
    },
    firstName_container: {
        gridColumn: "3 / 8",
        [theme.breakpoints.down("xs")]: {
            gridColumn: "1 / span 12",
        },
    },
    lastName_container: {
        gridColumn: "8 / 13",
        [theme.breakpoints.down("xs")]: {
            gridColumn: "1 / span 12",
        },
    },
    email_container: {
        gridColumn: "1 / 7",
        [theme.breakpoints.down("xs")]: {
            gridColumn: "1 / span 12",
        },
    },
    phone_container: {
        gridColumn: "7 / 13",
        [theme.breakpoints.down("xs")]: {
            gridColumn: "1 / span 12",
        },
    },
    password_container: {
        gridColumn: "1 / 7",
        [theme.breakpoints.down("xs")]: {
            gridColumn: "1 / span 12",
        },
    },
    confirmPaswword_container: {
        gridColumn: "7 / 13",
        [theme.breakpoints.down("xs")]: {
            gridColumn: "1 / span 12",
        },
    },
    professional_container: {
        gridColumn: "1 / 13",
        display: "flex",
        [theme.breakpoints.down("xs")]: {
            gridColumn: "1 / span 12",
        },
    },
    category_container: {
        gridColumn: "1 / 5",
        [theme.breakpoints.down("xs")]: {
            gridColumn: "1 / span 12",
        },
    },
    company_container: {
        gridColumn: "1 / 7",
        [theme.breakpoints.down("xs")]: {
            gridColumn: "1 / span 12",
        },
    },
    address_container: {
        gridColumn: "7 / 13",
        [theme.breakpoints.down("xs")]: {
            gridColumn: "1 / span 12",
        },
    },
    addressComplement_container: {
        gridColumn: "1 / 7",
        [theme.breakpoints.down("xs")]: {
            gridColumn: "1 / span 12",
        },
    },
}));

const SignUp = (props) => {
    const styles = useStyles();

    const [isChecked, setIsChecked] = useState(false);
    const [CGUPopinOpen, setCGUPopinOpen] = useState(false);

    return (
        <Box className={styles.container_grid}>
            {CGUPopinOpen && <CGUPopin modalOpen={CGUPopinOpen} handleClose={() => setCGUPopinOpen(false)} />}

            {/* TITLE */}
            <Box className={styles.title_container}>
                <SelectInput
                    enumField={"SIMPLE_PERSON_SALUTATION"}
                    onChange={(e) => {
                        props.title.onChange(e);
                    }}
                    value={props.title.value}
                    label="Titre"
                    variant="outlined"
                />
            </Box>
            {/* FIRSTNAME */}
            <Box className={styles.firstName_container}>
                <SimpleInput
                    value={props.firstName?.value}
                    onChange={props.firstName?.onChange}
                    correctValue={props.firstName?.correctValue}
                    label="Prénom"
                    required
                    error={props.firstName?.error ? "Le champ doit faire entre 1 et 30 caractères" : false}
                />
            </Box>
            {/* LASTNAME */}
            <Box className={styles.lastName_container}>
                <SimpleInput
                    label="Nom"
                    value={props.lastName?.value}
                    onChange={props.lastName?.onChange}
                    required
                    correctValue={props.lastName?.correctValue}
                    error={props.lastName?.error ? "Le champ doit faire entre 2 et 30 caractères" : false}
                />
            </Box>
            {/* EMAIL */}
            <Box className={styles.email_container}>
                <SimpleInput
                    id="username"
                    name="username"
                    type="email"
                    autoComplete={"username"}
                    value={props.emailAddress?.value}
                    onChange={props.emailAddress?.onChange}
                    error={props.emailAddress?.error ? props.emailAddress?.clue : false}
                    correctValue={props.emailAddress?.correctValue}
                    label="Adresse e-mail"
                    required
                    onFocus={() => props.emailAddress?.onFocus()}
                />
            </Box>
            {/* PHONE */}
            <Box className={styles.phone_container}>
                <TelephoneInput
                    type="tel"
                    label="Téléphone"
                    required
                    flagValue={props.phoneNumberFlag?.value}
                    flagOnChange={(e) => props.phoneNumberFlag?.onChange(e)}
                    phoneValue={props.phoneNumber.value}
                    phoneOnChange={(e) => props.phoneNumber.onChange(e.target.value)}
                    error={props.phoneNumber?.error ? "Le numéro doit contenir 10 chiffres et commencer par 0" : false}
                    correctValue={props.phoneNumber?.correctValue}
                    maxLength={14}
                />
            </Box>
            {/* PASSWORD */}
            <Box className={styles.password_container}>
                <ConstraintsPasswordInput
                    id="password"
                    name="password"
                    value={props.password?.value}
                    onChange={props.password?.onChange}
                    error={props.password?.error}
                    focus={props.password?.focus}
                    setFocus={props.password?.setFocus}
                    constraints={props.password?.constraints}
                    style={{ width: "100%" }}
                    label="Mot de passe"
                    required
                />
            </Box>
            {/* PASSWORD CONFIRMATION */}
            <Box className={styles.confirmPaswword_container}>
                <PasswordInput
                    value={props.confirmationPassword?.value}
                    onChange={props.confirmationPassword?.onChange}
                    error={props.confirmationPassword?.error ? props.confirmationPassword?.helperText : false}
                    correctValue={props.confirmationPassword?.correctValue}
                    style={{ width: "100%" }}
                    label="Confirmation du mot de passe"
                    required
                />
            </Box>
            {/* PROFESSIONAL OR NOT */}
            <Box className={styles.professional_container}>
                <ControlledSwitchLabelInside
                    switchCheck={props.isProfessional}
                    handleSwitchChange={() => props.onChangeIsProfessional()}
                    margin="0 1rem 0 0"
                />
                <Typography variant="h6">Je suis un professionnel</Typography>
            </Box>

            {props.isProfessional && (
                <>
                    {/* CATEGORY */}
                    <Box className={styles.category_container}>
                        <SelectInput
                            enumField="CONTACT_CATEGORY"
                            onChange={(e) => props.onChangeCategory(e)}
                            value={props.category}
                            label="Catégorie"
                            variant="outlined"
                            noClear
                            required
                        />
                    </Box>
                    {/* COMPANY */}
                    <Box className={styles.company_container}>
                        <SimpleInput
                            value={props.company?.value}
                            onChange={props.company?.onChange}
                            correctValue={props.company?.correctValue}
                            label="Nom de l'entreprise"
                            required
                            error={props.company?.error ? "Ce champs et obligatoire" : false}
                        />
                    </Box>

                    {/* ADRESSE */}
                    <Box className={styles.address_container}>
                        <AddressAutocompleteInput
                            width="100%"
                            address={props.address?.value}
                            onChange={(e, geoApiData) => {
                                if (geoApiData) {
                                    props.address.onChange(e?.simple);
                                    props.onChangePostalCode(geoApiData?.postcode);
                                    props.onChangeCommune(geoApiData?.city);
                                } else {
                                    props.address?.onChange(e);
                                }
                            }}
                            error={props.addressErrors}
                            correctValue={props.address?.value}
                        />
                    </Box>

                    {/* ADRESSE COMPLEMENT */}
                    <Box className={styles.addressComplement_container}>
                        <SimpleInput
                            value={props.extraAddress}
                            onChange={(e) => props.onChangeExtraAddress(e?.target?.value)}
                            label="Complément d'adresse"
                        />
                    </Box>
                    <Box className={styles.address_container}>
                        <AutocompleteCommune
                            label="Commune"
                            postalCode={props.postalCode}
                            commune={props.commune}
                            onChange={({ commune, postalCode }) => {
                                props.onChangeCommune(commune);
                                props.onChangePostalCode(postalCode);
                            }}
                        />
                    </Box>
                </>
            )}
            <BaseCheckbox
                boxStyle={{ gridColumn: "1 / 13" }}
                variant="outlined"
                labelStyle={{
                    fontFamily: "Lato",
                    fontSize: "14px",
                    color: "#915868",
                }}
                style={{ alignItems: "start" }}
                checked={isChecked}
                onChange={(e) => setIsChecked(!isChecked)}
                label={
                    <Typography variant="h6" style={{ color: theme.palette.primary.dark, textAlign: "left" }}>
                        J’ai lu et j’accepte la{" "}
                        <Link
                            underline="always"
                            style={{
                                cursor: "pointer",
                                fontSize: "14px",
                                color: theme.palette.primary.dark,
                            }}
                            onClick={() => setCGUPopinOpen(true)}
                        >
                            politique de protection des données personnelles.
                        </Link>
                    </Typography>
                }
            />

            {props.matchCheckbox && props.matchCheckbox?.value && props.matchCheckbox?.display ? (
                <Box style={{ gridColumn: "1 / 13" }}>
                    <BaseCheckbox
                        style={{ marginLeft: 0, alignItems: "start", textAlign: "left" }}
                        styleCheckbox={{ marginTop: "2px" }}
                        checked={props.matchCheckbox?.checked}
                        value={props.matchCheckbox?.value}
                        onChange={props.matchCheckbox?.onChange}
                        label={props.matchCheckbox?.label}
                    />
                </Box>
            ) : null}

            <BaseButton
                variant="contained"
                color="primary"
                fullWidth
                boxStyle={{ gridColumn: "1 / 13" }}
                disabled={
                    props.disabled ||
                    (props.isProfessional &&
                        (!props.category || !props.company || !props.address || !props.postalCode || !props.commune)) ||
                    !isChecked
                }
                onClick={props?.onSubmit}
            >
                S&apos;inscrire
            </BaseButton>
        </Box>
    );
};

SignUp.propTypes = {
    disabled: bool,
    title: shape({
        value: string,
        id: string,
        error: string,
    }),
    firstName: shape({
        id: string,
        error: string,
    }),
    lastName: shape({
        id: string,
        error: string,
    }),
    emailAddress: shape({ id: string, error: string }),
    subscriptionNewsletter: shape({
        id: string,
        error: string,
    }),

    phoneNumber: shape({
        id: string,
        error: string,
    }),
    password: shape({
        id: string,
        error: string,
    }),
    confirmationPassword: shape({
        id: string,
        error: string,
    }),

    isProfessional: bool,
    onChangeIsProfessional: func,

    category: string,
    onChangeCategory: func,

    company: shape({
        value: string,
        id: string,
        error: string,
    }),

    address: shape({
        value: string,
        id: string,
        error: string,
    }),

    extraAddress: string,
    onChangeExtraAddress: func,

    postalCode: string,
    onChangePostalCode: func,
    postalCodeErrors: string,

    commune: string,
    onChangeCommune: func,

    matchCheckbox: shape({
        checked: bool,
        value: string,
        display: bool,
        onChange: func,
        label: string,
    }),

    addressErrors: string,

    phoneNumberFlag: shape({
        value: string,
        onChange: func,
    }),

    onSubmit: func,
};

export default SignUp;
