import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/user/userSlice";
import storage from "redux-persist/lib/storage";
import settingsReducer from "./features/settings/settingsSlice";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import thunk from "redux-thunk";

const userPersistConfig = {
    key: "user",
    storage,
    blacklist: ["currentRequestId", "loading", "error", "refetchDone"],
};

const settingsPersistConfig = {
    key: "settings",
    storage,
    whitelist: ["adoptWineEntity"],
};

const reducers = combineReducers({
    user: persistReducer(userPersistConfig, userReducer),
    settings: persistReducer(settingsPersistConfig, settingsReducer),
});

const persistConfig = {
    key: "root",
    storage,
    blacklist: ["user"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
});

export default store;
