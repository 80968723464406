import { Box } from "@material-ui/core";
import { SUBSCRIPTION_TYPES } from "config/constants";
import theme from "globalTheme";
import { array, arrayOf, bool, func, number, object, string } from "prop-types";
import SimpleStepper from "../../Components/Stepper/SimpleStepper";
import activeIcon from "../../assets/icons/stepper/active.svg";
import disabledIcon from "../../assets/icons/stepper/coming.svg";
import completedIcon from "../../assets/icons/stepper/done.svg";

const SubscriptionStepperContainer = (props) => {
    const stepsLabel = () => {
        if (props.selectedPack === SUBSCRIPTION_TYPES.basic && props.selectedPaper === "line") {
            return [
                "Vous souscrivez pour",
                "Abonnement",
                // "Souscription papier",
                `${props.estateName || "Votre propriété"}`,
            ];
        } else {
            return [
                "Vous souscrivez pour",
                "Abonnement",
                // "Souscription papier",
                `${props.estateName || "Votre propriété"}`,
                "Facturation",
                "Paiement",
            ];
        }
    };
    if (props.disableStepper) {
        return <div style={{ height: "64px", backgroundColor: theme.palette.primary.main }} />;
    }
    return (
        <SimpleStepper
            stepLabels={props.stepsLabel || stepsLabel()}
            activeStep={props.activeStep}
            completedSteps={props.completedSteps}
            disabledSteps={props.disabledSteps}
            onClick={props.onClick}
            activeIcon={
                <Box>
                    <img width={19} height={19} src={activeIcon} />
                </Box>
            }
            completedIcon={
                <Box>
                    <img width={19} height={19} src={completedIcon} />
                </Box>
            }
            disabledIcon={
                <Box style={{ marginTop: "2px" }}>
                    <img width={10} height={10} src={disabledIcon} />
                </Box>
            }
            style={{
                paddingRight: "10%",
                paddingLeft: "10%",
                boxShadow: "0px 3px 6px #00000029",
            }}
        />
    );
};

SubscriptionStepperContainer.propTypes = {
    activeStep: number,
    completedSteps: arrayOf(bool),
    disabledSteps: arrayOf(bool),
    onClick: func,
    subscriptionState: object,
    selectedPack: string,
    selectedPaper: string,
    estateName: string,
    stepsLabel: array,
    disableStepper: bool,
};

export default SubscriptionStepperContainer;
