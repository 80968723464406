import React from "react";
import PropTypes from "prop-types";
import TunnelScrollingPopin from "../../Containers/TunnelScrollingPopin";

import papierZoom from "../../assets/SubscriptionPage/papierZoom.png";

const PaperZoomPopin = (props) => (
    <TunnelScrollingPopin maxWidth="95%" maxHeight="90%" clickableElement={props.clickableElement} backButton>
        <img src={papierZoom} alt="papier_example" style={{ width: "100%" }} />
    </TunnelScrollingPopin>
);

PaperZoomPopin.propTypes = {
    clickableElement: PropTypes.any,
    selected: PropTypes.number,
};

export default PaperZoomPopin;
