import { t, Trans } from "@lingui/macro";
import { Box, Divider, Hidden, Tab, Tabs, Typography } from "@material-ui/core";
import { any, array, bool, object, string } from "prop-types";
import { useContext, useEffect, useMemo, useState } from "react";
import Lightbox from "react-image-lightbox";
import { useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";

import soilLogo from "assets/sol.svg";
import subsoilLogo from "assets/sous-sol.svg";
import Page404 from "Components/ErrorPage/Page404";
import Page500Front from "Components/ErrorPage/Page500Front";
import Helmet from "Components/Helmet/Helmet";
import { TabPanel } from "Components/TabPanel";
import { FRONT_VINTAGE_TAB, SUBSCRIPTION_TYPES } from "config/constants";
import { useCustomHistory } from "hooks";
import { NavbarContext } from "Routes/MainRouter";
import substitution from "../../assets/Wine/substitution.png";
import Arrow from "../../Components/Arrow/Arrow";
import VintageCarousel from "../../Components/Carousel/VintageCarousel";
import ExpandableFormattedTextBox from "../../Components/Texts/ExpandableFormattedTextBox";
import Tooltip from "../../Components/Tooltips/Tooltip";
import FrontContentBottom from "../../Front/FrontContentBottom";
import FrontContentTop from "../../Front/FrontContentTop";
import FrontMainContainer from "../../Front/FrontMainContainer";
import FrontTitle from "../../Front/FrontTitle";
import ActivityCardsContainer from "../../FrontEstate/Components/Cards/ActivityCardsContainer";
import ContactPropertyFormCard from "../../FrontEstate/Components/Cards/ContactPropertyFormCard/ContactPropertyFormCard";
import WineCardsContainer from "../../FrontEstate/Components/Cards/WineCardsContainer";
import EstateTitledSemiUnderlined from "../../FrontEstate/Components/EstateTitledSemiUnderlined";
import DownloadFT from "../../FrontVintage/Components/DownloadFT";
import NewFtAvailable from "../../FrontVintage/Components/NewFtAvailable";
import WineAdvices from "../../FrontVintage/Components/WineAdvices";
import WineAgeing from "../../FrontVintage/Components/WineAgeing";
import WineCalendar from "../../FrontVintage/Components/WineCalendar";
import WineFrontDistribution from "../../FrontVintage/Components/WineFrontDistribution";
import WineFrontProduction from "../../FrontVintage/Components/WineFrontProduction";
import WineSummary from "../../FrontVintage/Components/WineSummary";
import WineVinification from "../../FrontVintage/Components/WineVinification";
import WineViticultureContainer from "../../FrontVintage/Container/WineViticultureContainer";
import LabelAndCertifPopin from "../../FrontVintage/Popins/LabelAndCertifPopin";
import RewardsPopin from "../../FrontVintage/Popins/RewardsPopin";
import { useContactCardQuery, useCreateWebFtDiffusionMutation } from "../../generated/graphql";
import theme from "../../globalTheme";
import getConnectors, {
    firstLetterUppercase,
    formatConcat,
    makeHttp,
    useWindowDimensions,
} from "../../helpers/helpers";
import { useStyles } from "./styles";
import WineCommunalities from "./WineCommonalities";
import { ScoreCardContainer } from "FrontVintage/Components/ScoreCardContainer";
import { groupBy } from "lodash";

const a11yProps = (index) => ({
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
});

export const GenericWinePage = (props) => {
    const styles = useStyles(props);
    const [activeTab, setActiveTab] = useState(FRONT_VINTAGE_TAB.advices);
    const [medalPopinOpen, setMedalPopinOpen] = useState(false);
    const [labelAndCertifPopinOpen, setLabelAndCertifPopinOpen] = useState(false);
    const currentUser = useSelector((state) => state.user.currentUser);
    const [isOpen, setIsOpen] = useState(null);
    const { width } = useWindowDimensions();
    const { data } = useContactCardQuery({ variables: { wineEntityId: props.wineEntityId } });
    const { wineEntityShortId, propertyName } = useParams();

    const location = useLocation();
    const history = useCustomHistory();
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);

    const { setFtId, setOpenConnectionMenu, openConnectionMenu } = useContext(NavbarContext);

    const [createDiffusion] = useCreateWebFtDiffusionMutation();

    const {
        // type,
        name,
        year,
        rank,
        color,
        label,
        cuvee,
        rankFr,
        wineId,
        scores,
        nonOGM,
        rewards,
        sweetness,
        allergens,
        climatology,
        bottleImage,
        noSulphites,
        bottleLabel,
        vintageList,
        wineAssembly,
        classification,
        productPageUrl,
        backgroundImage,
        certificationList,
        commercialisation,
        alcoholicStrength,
        vintageDescription,
        bySeasonClimatology,
        backgroundBottleImage,
        truncatedVintageDescription,
        description,
        truncateDescription,
        harvestMethod,
        soil,
        subsoil,
        averageAge,
        totalSurfaceArea,
        averageDensity,
        tabs: availableTabs,
    } = props.data;

    const restriction = props.data?.subscriptionName == SUBSCRIPTION_TYPES.basic;

    useEffect(() => {
        if (openConnectionMenu && currentUser && props.vintage) {
            setIsOpen(props.data?.ftId);
            createDiffusion({ variables: { ftId: props.data?.ftId, userId: currentUser.userId } });
            setOpenConnectionMenu(false);
        }
    }, [openConnectionMenu, currentUser, props.vintage]);

    useEffect(() => {
        if (location && availableTabs?.length > 0) {
            setActiveTab(availableTabs[0]);
        }
    }, [location, availableTabs]);

    const tabs = useMemo(() => {
        const tmp = props.vintage
            ? [
                  {
                      label: t`Dégustation`,
                      value: FRONT_VINTAGE_TAB.advices,
                      content: (
                          <WineAdvices
                              vintageId={props.vintageId}
                              wineName={name}
                              year={props.data?.year}
                              aoc={label}
                          />
                      ),
                  },
                  {
                      label: t`Viticulture`,
                      value: FRONT_VINTAGE_TAB.viticulture,
                      content: (
                          <WineViticultureContainer
                              locale={props.locale}
                              vintageId={props.vintageId}
                              aoc={label}
                              wineName={name}
                              year={year}
                              cuvee={cuvee}
                          />
                      ),
                  },
                  {
                      label: t`Vendanges`,
                      value: FRONT_VINTAGE_TAB.harvest,
                      content: (
                          <>
                              <WineCalendar vintageId={props.vintageId} locale={props.locale} />
                          </>
                      ),
                  },
                  {
                      label: t`Vinification`,
                      value: FRONT_VINTAGE_TAB.winemaking,
                      content: (
                          <WineVinification
                              locale={props.locale}
                              vintageId={props.vintageId}
                              wineName={name}
                              year={year}
                              aoc={label}
                              cuvee={cuvee}
                          />
                      ),
                  },
                  {
                      label: t`Élevage`,
                      value: FRONT_VINTAGE_TAB.ageing,
                      content: (
                          <WineAgeing
                              locale={props.locale}
                              vintageId={props.vintageId}
                              wineName={name}
                              year={year}
                              aoc={label}
                              cuvee={cuvee}
                          />
                      ),
                  },
                  {
                      label: t`Production`,
                      value: FRONT_VINTAGE_TAB.production,
                      content: (
                          <Box style={{ display: "flex", flexWrap: "wrap", gap: "32px" }}>
                              <WineFrontProduction
                                  locale={props.locale}
                                  vintageId={props.vintageId}
                                  wineName={name}
                                  year={year}
                                  aoc={label}
                              />
                          </Box>
                      ),
                  },
                  {
                      label: t`Distribution`,
                      value: FRONT_VINTAGE_TAB.marketing,
                      content: (
                          <Box style={{ display: "flex", flexWrap: "wrap", gap: "32px" }}>
                              <WineFrontDistribution
                                  locale={props.locale}
                                  vintageId={props.vintageId}
                                  wineName={name}
                                  year={year}
                                  aoc={label}
                              />
                          </Box>
                      ),
                  },
              ]
            : [
                  {
                      label: t`Dégustation`,
                      value: FRONT_VINTAGE_TAB.advices,
                      content: <WineAdvices wineId={wineId} wineName={name} year={year} aoc={label} score />,
                  },
                  {
                      label: t`Vinification`,
                      value: FRONT_VINTAGE_TAB.winemaking,
                      content: <WineVinification locale={props.locale} wineId={wineId} wineName={name} />,
                  },
                  {
                      label: t`Élevage`,
                      value: FRONT_VINTAGE_TAB.ageing,
                      content: <WineAgeing locale={props.locale} wineId={wineId} wineName={name} />,
                  },
                  {
                      label: t`Production et distribution`,
                      value: FRONT_VINTAGE_TAB.production,
                      content: (
                          <Box style={{ display: "flex", flexWrap: "wrap", gap: "32px" }}>
                              <WineFrontProduction locale={props.locale} wineId={wineId} wineName={name} />
                              <WineFrontDistribution locale={props.locale} wineId={wineId} wineName={name} />
                          </Box>
                      ),
                  },
              ];
        return tmp.filter((item) => availableTabs?.includes(item.value));
    }, [props.vintage, props.data]);

    const wineNameFormated = useMemo(() => formatConcat([name, year, cuvee]), [name, year, cuvee]);

    const handleTabChange = (newValue) => setActiveTab(newValue);

    const getVintageSelector = () => {
        if (vintageList?.length > 0) {
            return <VintageCarousel vintages={vintageList} wineId={props.wineId} />;
        }
        return (
            <Box className={styles.vintageSelectorOnly__box}>
                <EstateTitledSemiUnderlined
                    title={
                        <Typography variant="h3" style={{ fontFamily: "Lato" }}>
                            {label.toUpperCase()} {rank && rankFr !== "autre" ? ` · ${firstLetterUppercase(rank)}` : ""}
                        </Typography>
                    }
                />
            </Box>
        );
    };

    const handleDownload = () => {
        setFtId(props.data?.ftId);
        setOpenConnectionMenu(true);
    };

    if (!props.data) {
        return <Page404 />;
    }

    if (props.error) {
        return <Page500Front />;
    }

    const currentTab = tabs.find((item) => item.value == activeTab);

    const scoreByYear = groupBy(scores, "vintageYear");

    return (
        <Box style={{ width: "100%", height: "100%", margin: "auto" }}>
            {isOpen && <NewFtAvailable onClose={() => setIsOpen(null)} open={isOpen} />}

            {lightboxIsOpen && (
                <Lightbox
                    imagePadding={100}
                    mainSrc={bottleLabel?.data}
                    onCloseRequest={() => setLightboxIsOpen(false)}
                />
            )}

            {medalPopinOpen && (
                <RewardsPopin open={medalPopinOpen} onClose={() => setMedalPopinOpen(false)} rewards={rewards} />
            )}

            {labelAndCertifPopinOpen && (
                <LabelAndCertifPopin
                    open={labelAndCertifPopinOpen}
                    onClose={() => setLabelAndCertifPopinOpen(false)}
                    certificationList={certificationList}
                />
            )}

            <Helmet
                title={wineNameFormated}
                content={
                    year
                        ? `Découvrez le vin ${wineNameFormated} par ${props.wineEntityName}. Accédez aux informations sur le terroir, les cépages et les techniques de vinification utilisées pour ce vin et appréciez sa complexité grâce à sa fiche technique détaillée sur Bordeaux et ses Vins.`
                        : `Découvrez le vin ${name} par ${props.wineEntityName} accédez à ses caractéristiques uniques grâce à ses fiches techniques détaillées. Son terroir, son assemblage, ses accords mets et vins... Et ses millésimes.`
                }
            />
            <FrontContentTop
                backgroundImage={backgroundImage?.twicpicURL}
                links={props.breadcrumbs}
                previousPage={props.previousPage}
            >
                {/* MAIN INFOS BLOCK */}
                {props.frontTitle && (
                    <FrontTitle
                        title={
                            <Box className={styles?.mainInfosBlock}>
                                <Typography variant="h1">{formatConcat([name, year, cuvee], " • ")}</Typography>
                                {classification && (
                                    <Box
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            width: "100%",
                                            marginTop: "1rem",
                                        }}
                                    >
                                        <Divider orientation="horizontal" className={styles.classifications_divider} />
                                        <Typography
                                            variant="h3"
                                            style={{
                                                color: theme.palette.common.white,
                                                marginRight: theme.spacing(1),
                                                marginLeft: theme.spacing(1),
                                                fontFamily: "Lato",
                                            }}
                                        >
                                            {classification}
                                        </Typography>
                                        <Divider orientation="horizontal" className={styles.classifications_divider} />
                                    </Box>
                                )}
                                {props.wineEntityName && (
                                    <Typography
                                        variant="h4"
                                        style={{ marginTop: "1rem" }}
                                        className={styles.mainInfosBlockSubtitle}
                                    >
                                        <Link
                                            to={history.addLocale(`/${wineEntityShortId}-${propertyName}`)}
                                            underline="always"
                                            className={styles.mainInfosBlockSubtitle}
                                        >
                                            {props.wineEntityName}
                                        </Link>
                                    </Typography>
                                )}
                            </Box>
                        }
                    />
                )}

                <FrontMainContainer
                    styleCardContent
                    className={props.classNameCard}
                    hideFloritureTopImg={props.hideFloritureTopImg}
                >
                    {/* PART 1 GLOBAL INFOS CONTAINER */}
                    {props.titleStandalone && (
                        <Box
                            className={styles?.mainInfosBlock}
                            style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                        >
                            {(width < 1280 || width >= 1920) && (
                                <>
                                    <Box style={{ width: "10rem" }}>
                                        <img src={props.logo} style={{ width: "100%" }} />
                                    </Box>
                                    <Typography variant="h1" style={{ color: theme.palette.primary.dark }}>
                                        {formatConcat([name, year, cuvee], " • ")}
                                    </Typography>
                                    {classification && (
                                        <Box
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                width: "100%",
                                                marginTop: "1rem",
                                            }}
                                        >
                                            <Divider
                                                orientation="horizontal"
                                                style={{ backgroundColor: theme.palette.primary.dark }}
                                                className={styles.classifications_divider}
                                            />
                                            <Typography
                                                variant="h3"
                                                style={{
                                                    color: theme.palette.primary.dark,
                                                    marginRight: theme.spacing(1),
                                                    marginLeft: theme.spacing(1),
                                                    fontFamily: "Lato",
                                                }}
                                            >
                                                {classification}
                                            </Typography>
                                            <Divider
                                                orientation="horizontal"
                                                style={{ backgroundColor: theme.palette.primary.dark }}
                                                className={styles.classifications_divider}
                                            />
                                        </Box>
                                    )}
                                    {props.wineEntityName && (
                                        <Typography
                                            variant="h4"
                                            style={{ marginTop: "1rem", color: theme.palette.primary.dark }}
                                            className={styles.mainInfosBlockSubtitle}
                                        >
                                            <Link
                                                to={`/${wineEntityShortId}-${propertyName}`}
                                                underline="always"
                                                style={{ color: theme.palette.primary.dark }}
                                                className={styles.mainInfosBlockSubtitle}
                                            >
                                                {props.wineEntityName}
                                            </Link>
                                        </Typography>
                                    )}
                                </>
                            )}
                        </Box>
                    )}
                    <Box className={`${styles.partOneContainer} ${props.partOneContainerClassName}`}>
                        {/* 1 - BOTTLE IMG */}
                        <Box className={props.bottleContainerClassName}>
                            {bottleImage?.twicpicURL && (
                                <img
                                    src={bottleImage?.twicpicURL}
                                    alt={`${formatConcat([name, year, cuvee])} - ${props.wineEntityName}`}
                                    className={props.bottleImgClassName}
                                />
                            )}

                            <Box className={styles.backgroundBottleContainer}>
                                <img
                                    src={backgroundBottleImage?.twicpicURL}
                                    className={props.backgroundBottleClassName}
                                    alt=""
                                />
                            </Box>

                            {props.vintageId && (
                                <Hidden smDown>
                                    {bottleLabel && (
                                        <Box className={`${styles.bottleLabel} ${props.bottleLabelClassName}`}>
                                            <img
                                                onClick={() => setLightboxIsOpen(true)}
                                                src={bottleLabel?.twicpicURL}
                                                alt={`${formatConcat([name, year, cuvee])} - ${props.wineEntityName}`}
                                                style={{
                                                    maxHeight: "100%",
                                                    maxWidth: "100%",
                                                    margin: "auto",
                                                    cursor: "pointer",
                                                }}
                                            />
                                        </Box>
                                    )}
                                </Hidden>
                            )}
                        </Box>
                        {/* 2 - ESTATE INFORMATIONS BLOCK */}
                        <Box className={props.vintagesScrollContainerClassName}>
                            {props.standalone && width >= 1280 && width < 1920 && (
                                <Box
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        marginBottom: "2rem",
                                        position: "relative",
                                        marginTop: "180px",
                                        background: "transparent",
                                    }}
                                >
                                    <Box style={{ width: "10rem", position: "absolute", top: "-180px" }}>
                                        <img src={props.logo} style={{ width: "100%" }} />
                                    </Box>
                                    <Typography variant="h1" style={{ color: theme.palette.primary.dark }}>
                                        {props.vintageId ? formatConcat([name, year, cuvee], " • ") : name}
                                    </Typography>
                                    {classification && (
                                        <Box
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                width: "100%",
                                                marginTop: "1rem",
                                            }}
                                        >
                                            <Divider
                                                orientation="horizontal"
                                                style={{ backgroundColor: theme.palette.primary.dark }}
                                                className={styles.classifications_divider}
                                            />
                                            <Typography
                                                variant="h3"
                                                style={{
                                                    color: theme.palette.primary.dark,
                                                    marginRight: theme.spacing(1),
                                                    marginLeft: theme.spacing(1),
                                                    fontFamily: "Lato",
                                                }}
                                            >
                                                {classification}
                                            </Typography>
                                            <Divider
                                                orientation="horizontal"
                                                style={{ backgroundColor: theme.palette.primary.dark }}
                                                className={styles.classifications_divider}
                                            />
                                        </Box>
                                    )}
                                    {props.wineEntityName && (
                                        <Typography
                                            variant="h4"
                                            style={{ marginTop: "1rem", color: theme.palette.primary.dark }}
                                            className={styles.mainInfosBlockSubtitle}
                                        >
                                            <Link
                                                to={`/${wineEntityShortId}-${propertyName}`}
                                                underline="always"
                                                style={{ color: theme.palette.primary.dark }}
                                                className={styles.mainInfosBlockSubtitle}
                                            >
                                                {props.wineEntityName}
                                            </Link>
                                        </Typography>
                                    )}
                                </Box>
                            )}
                            {/* Vintages choice component */}
                            {getVintageSelector()}
                        </Box>
                        <Box className={styles.description__wrapper}>
                            {bottleLabel && (
                                <Hidden mdUp>
                                    <Box className={styles.bottleLabelSmall}>
                                        <img
                                            src={bottleLabel?.twicpicURL}
                                            alt={`${formatConcat([name, year, cuvee])} - ${props.wineEntityName}`}
                                            style={{
                                                maxHeight: "100%",
                                                maxWidth: "100%",
                                                margin: "auto",
                                            }}
                                        />
                                    </Box>
                                </Hidden>
                            )}
                            {props.data?.ftId && <DownloadFT onClick={() => handleDownload()} />}
                            {/* <SocialShare /> */}
                            {vintageList && vintageList.length > 0 && (
                                <Box
                                    style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        marginBottom: theme.spacing(3),
                                    }}
                                >
                                    <EstateTitledSemiUnderlined
                                        title={
                                            <Typography variant="h3" style={{ fontFamily: "Lato" }}>
                                                {label.toUpperCase()}{" "}
                                                {rank && rankFr !== "autre" ? ` · ${firstLetterUppercase(rank)}` : ""}
                                            </Typography>
                                        }
                                    />
                                </Box>
                            )}

                            {/* Description*/}
                            {vintageDescription && (
                                <Box>
                                    <Typography className={styles.descriptionTypoTitle}>
                                        <Trans>Description du vin</Trans>
                                    </Typography>

                                    <ExpandableFormattedTextBox
                                        value={vintageDescription}
                                        truncatedValue={truncatedVintageDescription}
                                    />
                                </Box>
                            )}
                            {description && !props.vintageId && (
                                <Box>
                                    <Typography className={styles.descriptionTypoTitle}>
                                        <Trans>Description du vin</Trans>
                                    </Typography>

                                    <ExpandableFormattedTextBox
                                        value={description}
                                        truncatedValue={truncateDescription}
                                    />
                                </Box>
                            )}

                            {(soil || subsoil) && !props.vintageId && (
                                <Box marginTop="1rem">
                                    <Typography className={styles.descriptionTypoTitle}>
                                        <Trans>Sols et sous-sols</Trans>
                                    </Typography>

                                    {soil && (
                                        <Box display="flex" alignItems="center">
                                            <img src={soilLogo} width="45px" />
                                            <Typography
                                                variant="body2"
                                                style={{ color: theme.palette.primary.dark, marginLeft: "0.5rem" }}
                                            >
                                                {getConnectors(soil, true)}
                                            </Typography>
                                        </Box>
                                    )}
                                    {subsoil && (
                                        <Box display="flex" alignItems="center">
                                            <img src={subsoilLogo} width="45px" />
                                            <Typography
                                                variant="body2"
                                                style={{ color: theme.palette.primary.dark, marginLeft: "0.5rem" }}
                                            >
                                                {getConnectors(subsoil, true)}
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                            )}
                        </Box>
                        {/* Label and certif */}
                        {certificationList?.length > 0 && (
                            <Box className={styles.label__wrapper}>
                                <Box
                                    style={{
                                        position: "relative",
                                        zIndex: 2,
                                        marginBottom: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h4"
                                        style={{
                                            marginBottom: theme.spacing(2),
                                            fontFamily: "Playfair Display",
                                            fontSize: "21px",
                                            fontWeight: "bold",
                                            color: "#000000",
                                        }}
                                    >
                                        <Trans>Labels et certifications</Trans>
                                    </Typography>
                                    <Box
                                        style={{
                                            marginTop: "4px",
                                        }}
                                    >
                                        {certificationList?.map((el, i) => {
                                            return (
                                                i <= 2 && (
                                                    <Box
                                                        style={{
                                                            display: "flex",
                                                            marginBottom: "12px",
                                                            marginRight: "8px",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        {el?.logo?.twicpicURL && (
                                                            <img
                                                                src={el?.logo?.twicpicURL}
                                                                alt="label_img"
                                                                style={{
                                                                    marginRight: "8px",
                                                                    height: "40px",
                                                                }}
                                                            />
                                                        )}
                                                        {el?.object && (
                                                            <Typography
                                                                variant="h6"
                                                                style={{
                                                                    fontFamily: "Lato",
                                                                    fontSize: "16px",
                                                                    color: "#360318",
                                                                }}
                                                                className={styles.typoDescritpion__CertifOrLabel}
                                                            >
                                                                {el.object}
                                                            </Typography>
                                                        )}
                                                    </Box>
                                                )
                                            );
                                        })}
                                    </Box>

                                    <Box style={{ marginTop: "5px" }}>
                                        <Arrow
                                            icon="complex"
                                            rotation="0"
                                            arrowPosition="start"
                                            style={{ height: "100%" }}
                                            arrowStyle={{ width: "52px", height: "22px" }}
                                        >
                                            <Typography
                                                variant="h6"
                                                style={{
                                                    fontFamily: theme.typography.fontFamily,
                                                    fontSize: "14px",
                                                    // maxWidth: "auto",
                                                    // paddingBottom: theme.spacing(2),
                                                    color: theme.palette.primary.dark,
                                                    textDecoration: "underline",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => setLabelAndCertifPopinOpen(true)}
                                            >
                                                {certificationList?.length <= 3
                                                    ? t`Voir plus de détails`
                                                    : t`Voir tous les labels et certifications`}
                                            </Typography>
                                        </Arrow>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                        {rewards?.length > 0 && (
                            <Box className={styles.label__wrapper}>
                                <Box
                                    style={{
                                        position: "relative",
                                        zIndex: 20,
                                    }}
                                >
                                    <Typography
                                        variant="h4"
                                        className={styles.typographyTitle__labelCertif}
                                        style={{
                                            marginBottom: theme.spacing(2),
                                            fontSize: "21px",
                                        }}
                                    >
                                        <Trans>Médailles</Trans>
                                    </Typography>
                                    <Box
                                        style={{
                                            display: "flex",
                                            marginTop: "4px",
                                            flexWrap: "wrap",
                                        }}
                                    >
                                        {rewards?.map(
                                            (el, i) =>
                                                i <= 3 && (
                                                    <Box
                                                        style={{
                                                            position: "relative",
                                                            display: "flex",
                                                            marginBottom: "12px",
                                                        }}
                                                    >
                                                        {el?.image?.data && (
                                                            <>
                                                                {i === 3 && rewards?.length > 4 && (
                                                                    <Box
                                                                        className={styles.medal_mask}
                                                                        onClick={() => setMedalPopinOpen(true)}
                                                                    >
                                                                        <Typography className={styles.link_open_modal}>
                                                                            +{rewards?.length - 4}
                                                                        </Typography>
                                                                    </Box>
                                                                )}

                                                                {i <= 3 && (
                                                                    <Tooltip
                                                                        color="primary"
                                                                        title={
                                                                            <Box style={{ maxWidth: "260px" }}>
                                                                                <Typography
                                                                                    style={{
                                                                                        color:
                                                                                            theme.palette.common.white,
                                                                                        fontSize:
                                                                                            theme.typography.body2
                                                                                                .fontSize,
                                                                                    }}
                                                                                >
                                                                                    {el?.competitionName ?? ""}
                                                                                </Typography>
                                                                                <Typography
                                                                                    style={{
                                                                                        color:
                                                                                            theme.palette.common.white,
                                                                                        fontSize:
                                                                                            theme.typography.body2
                                                                                                .fontSize,
                                                                                    }}
                                                                                >
                                                                                    {el?.year ? el?.year : ""}
                                                                                </Typography>
                                                                                <Typography
                                                                                    style={{
                                                                                        color:
                                                                                            theme.palette.common.white,
                                                                                        fontSize:
                                                                                            theme.typography.body2
                                                                                                .fontSize,
                                                                                    }}
                                                                                >
                                                                                    {el?.rewardName ?? ""}
                                                                                </Typography>
                                                                            </Box>
                                                                        }
                                                                        arrow
                                                                        classes={{
                                                                            arrow: styles.arrow,
                                                                            tooltip: styles.popper,
                                                                        }}
                                                                    >
                                                                        {el?.url ? (
                                                                            <Link
                                                                                to={{ pathname: makeHttp(el?.url) }}
                                                                                target="_blank"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={el?.image?.twicpicURL}
                                                                                    alt="label_img"
                                                                                    style={{
                                                                                        marginRight: "8px",
                                                                                        height: "103px",
                                                                                        cursor: "pointer",
                                                                                    }}
                                                                                />
                                                                            </Link>
                                                                        ) : (
                                                                            <img
                                                                                src={el?.image?.twicpicURL}
                                                                                alt="label_img"
                                                                                style={{
                                                                                    marginRight: "8px",
                                                                                    height: "103px",
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Tooltip>
                                                                )}
                                                            </>
                                                        )}
                                                    </Box>
                                                )
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        )}
                        {scores?.length > 0 &&
                            (props.isVintage ? (
                                <ScoreCardContainer
                                    className={{
                                        container: styles.label__wrapper,
                                        title: styles.typographyTitle__labelCertif,
                                    }}
                                    scores={scores}
                                    title={year}
                                />
                            ) : (
                                Object.entries(scoreByYear).map(([vintageYear, vintageScores]) => (
                                    <ScoreCardContainer
                                        key={vintageYear}
                                        className={{
                                            container: styles.label__wrapper,
                                            title: styles.typographyTitle__labelCertif,
                                        }}
                                        scores={vintageScores}
                                        title={vintageYear}
                                    />
                                ))
                            ))}
                        {vintageList?.length > 0 &&
                            vintageList.map(
                                ({ vintageId, scores, year, cuvee }) =>
                                    scores?.length > 0 && (
                                        <ScoreCardContainer
                                            className={{
                                                container: styles.label__wrapper,
                                                title: styles.typographyTitle__labelCertif_2,
                                            }}
                                            key={vintageId}
                                            scores={scores}
                                            title={formatConcat([year, cuvee], " - ")}
                                        />
                                    )
                            )}
                        {/* 3 - SUMMARY ELEMENTS */}
                        <Box className={styles.summaryContainer}>
                            <WineSummary
                                vintageId={props.vintageId}
                                color={color}
                                sugar={sweetness}
                                bubbles={props.data?.type}
                                grapes={wineAssembly}
                                weather={bySeasonClimatology ? null : climatology?.map((e) => e.text)}
                                detailedWeather={bySeasonClimatology ? climatology : null}
                                marketing={commercialisation}
                                alcoholicStrength={alcoholicStrength}
                                allergens={allergens}
                                nonOGM={nonOGM}
                                productPageUrl={productPageUrl}
                                noSulphites={noSulphites}
                                harvestMethod={harvestMethod}
                                averageAge={averageAge}
                                totalSurfaceArea={totalSurfaceArea}
                                averageDensity={averageDensity}
                            />
                        </Box>
                        {/* BG IMAGE */}
                        <Hidden smDown>
                            <img src={substitution} alt="illu_vine" className={styles.fioriture} />
                        </Hidden>
                    </Box>
                    {/* PART 2 TABS */}

                    {tabs.length > 0 && (
                        <Box className={props.tabsContainerClassName}>
                            <Box className={styles.tabsContainer}>
                                <Tabs
                                    className={styles.tabs}
                                    value={activeTab}
                                    indicatorColor="primary"
                                    scrollButtons="on"
                                    variant="scrollable"
                                    onChange={(_, v) => handleTabChange(v)}
                                >
                                    {tabs.map((item, i) => (
                                        <Tab
                                            key={i}
                                            className={styles.tab}
                                            value={item.value}
                                            label={item.label}
                                            wrapped
                                            {...a11yProps(item.value)}
                                        />
                                    ))}
                                </Tabs>
                            </Box>
                            {currentTab && (
                                <TabPanel
                                    value={activeTab}
                                    className={
                                        currentTab.value === FRONT_VINTAGE_TAB.advices && styles.tabPanelContainer
                                    }
                                    index={currentTab.value}
                                >
                                    <Box className={styles.mainTabsContainer}>{currentTab.content}</Box>
                                </TabPanel>
                            )}
                        </Box>
                    )}
                </FrontMainContainer>
                <Box className={styles.winePage_GridColumn_Layout} style={{ marginTop: "3rem" }}>
                    <WineCardsContainer
                        styleBox={{ gridColumn: "2 / span 10" }}
                        omitWineId={props.wineId}
                        title={props.wineSectionTitle}
                        wineEntityId={props.wineEntityId}
                        whiteVersion
                        limited
                        wineEntityName={props.wineEntityName}
                        locale={props.locale}
                        showMoreWines={props.showMoreWines}
                    />
                </Box>
            </FrontContentTop>

            <FrontContentBottom
                activityBackground={!restriction}
                contactBackground
                boxStyle={{ backgroundSize: restriction && "cover" }}
            >
                <ActivityCardsContainer
                    wineEntityName={props.wineEntityName}
                    wineEntityId={props.wineEntityId}
                    locale={props.locale}
                    styleBox={{ margin: width < 600 && "0 14px" }}
                    title={
                        props.activitiesSectionTitle || props?.wineEntityName
                            ? t`Les activités proposées par ${props?.wineEntityName}`
                            : t`Les activités proposées par cette propriété`
                    }
                />
                {/* Contact Paper */}
                <ContactPropertyFormCard
                    restriction={restriction}
                    standalone={props.standalone}
                    contactSectionTitle={props.contactSectionTitle}
                    size={"large"}
                    estateName={props.wineEntityName}
                    address={data?.address}
                    phoneNumber={data?.telephoneNumber}
                    fax={data?.fax}
                    logo={data?.logos?.[0]?.data || null}
                    identityLinked={true}
                    coordinates={data?.coordinates}
                    weeklyHours={
                        data?.displayOpeningHours
                            ? data?.openingHours && data?.openingHours?.length
                                ? data?.openingHours
                                : null
                            : null
                    }
                    wineEntityId={props.wineEntityId}
                />
            </FrontContentBottom>
            {!props.standalone && <WineCommunalities wineId={props.wineId} />}
        </Box>
    );
};

GenericWinePage.propTypes = {
    vintage: object,
    vintageId: string,
    locale: string,
    wineEntityId: string,
    wineEntityName: string,
    error: any,
    hideFloritureTopImg: bool,
    frontTitle: bool,
    titleStandalone: bool,
    showMoreWines: string,
    wineSectionTitle: string,
    wineId: string,
    contactSectionTitle: string,
    breadcrumbs: array,
    previousPage: array,
    bottleContainerClassName: string,
    tabsContainerClassName: string,
    vintagesScrollContainerClassName: string,
    bottleImgClassName: string,
    backgroundBottleClassName: string,
    activitiesSectionTitle: string,
    standalone: bool,
    logo: string,
    classNameCard: string,
    partOneContainerClassName: string,
    bottleLabelClassName: string,
    data: object,
    isVintage: bool,
};
