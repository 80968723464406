// SETUP
import React from "react";
import PropTypes from "prop-types";
import theme  from "./../../globalTheme";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination} from "swiper";
import { firstLetterUppercase } from "../../helpers/helpers";
import { getSentence } from "./vintageHelpers";

// COMPONENTS
import { Box, makeStyles, Typography } from "@material-ui/core";

// CONTENTS
import pheno from "../../assets/Wine/pheno.svg";
import { Trans } from "@lingui/macro";

// USES
SwiperCore.use([Pagination]);

// STYLES
const useStyles = makeStyles(() => ({
    winePhenoStagesM__wrapper: {
        border: "0.4px solid",
        borderRadius: "5px",
        borderColor: theme.palette.secondary.dark,
        position: "relative",
        padding: "16px",
    },
    winePhenoStageM__title: {
        alignItems: "center",
        backgroundColor: "white",
        display: "flex",
        padding: "0 4px",
        position: "absolute",
        top: -11,
        gap: "8px"
    },
    winePhenoStageM__title__img: {
        width: "24px"
    },
    winePhenoStageM__title__typo: {
        fontSize: "14px",
        fontWeight: "700"
    },
    winePhenoStageM__contents: {
        "& .swiper-container": {
            paddingBottom: "24px",
            paddingTop: "8px",
        },
        "& .swiper-container-horizontal > .swiper-pagination-bullets": {
            bottom: 0,
        },
    },
    stageElement__wrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "140px"
    },
    stageElement__boxImg: {
        width: "95px"
    },
    stageElement__boxImg__img: {

    },
    stageElement__boxText: {
        
    },
    stageElement__boxText__firstTypo: {
        fontSize: "12px",
    },
    stageElement__boxText__secondTypo: {
        fontSize: "12px",
    },
}));

const WinePhenologicalStagesMobile = (props) => {
    const styles = useStyles();

    return(
        <Box className={styles.winePhenoStagesM__wrapper}>
            <Box className={styles.winePhenoStageM__title}>
                <img src={pheno} alt="title_icon" className={styles.winePhenoStageM__title__img}/>
                <Typography variant="h3" className={styles.winePhenoStageM__title__typo}><Trans>Stades phénologiques</Trans></Typography>
            </Box>
            <Box className={styles.winePhenoStageM__contents}>
                <Swiper
                    pagination={{
                        "dynamicBullets": true
                      }}
                    // slidesPerView={"auto"}
                    spaceBetween={30}
                >
                    {props.phenologicalStages?.map((el, i) => {
                        if (i < 6) {
                            return (
                                <SwiperSlide key={i}>
                                    <Box className={styles.stageElement__wrapper}>
                                        <Box className={styles.stageElement__boxImg}>
                                            <img src={el?.image?.data} alt="stages_img" className={styles.stageElement__boxImg__img}/>
                                        </Box>
                                        <Box className={styles.stageElement__boxText}>
                                            {el?.stage &&
                                                <Typography className={styles.stageElement__boxText__firstTypo}>
                                                    {firstLetterUppercase(el?.stage)}
                                                </Typography>
                                            }
                                            <Typography className={styles.stageElement__boxText__secondTypo}>
                                                {getSentence(el)}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </SwiperSlide>
                            );
                        }
                    })}
                </Swiper>
            </Box>
        </Box>
    );
};

WinePhenologicalStagesMobile.propTypes = {
    phenologicalStages: PropTypes.array,
};

export default WinePhenologicalStagesMobile;