import { Box, makeStyles, Typography } from "@material-ui/core";

import FeretSummaryContainer from "../Front/FeretSummary/FeretSummaryContainer";
import FeretSummaryElement from "../Front/FeretSummary/FeretSummaryElement";
import president from "../assets/organization/summary/president.svg";
import director from "../assets/organization/summary/director.svg";
import contact from "../assets/organization/summary/contact.svg";
import { formatConcat } from "../helpers/helpers";
import { t } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
    subtitle_primary: {
        color: theme.palette.primary.light,
        lineHeight: "16px",
    },
    subtitle_secondary: {
        color: theme.palette.primary.dark,
    },
}));

type memberType = {
    firstName: string;
    lastName: string;
    title: string;
    role: string;
    icon: string;
};

interface AppellationSummaryProps {
    maxWidth?: string;
    members: memberType[];
    address: { address: string; postalCode: string; commune: string; country: string };
    phone: string;
    email: string;
}

const AppellationSummary = (props: AppellationSummaryProps) => {
    const styles = useStyles();

    const getMembers = () =>
        props.members
            .map((member, i) =>
                [member.role, member.firstName].filter(Boolean).length > 0 ? (
                    <FeretSummaryElement
                        key={i}
                        icon={member.icon === "primary" ? president : director}
                        title={member.role}
                        subtitle={formatConcat([member.title, member.firstName, member.lastName])}
                    />
                ) : null
            )
            .filter(Boolean);

    return (
        <FeretSummaryContainer maxWidth={props.maxWidth ?? "none"}>
            {getMembers()}
            {(props.address?.address || props.address?.postalCode || props.phone || props.email) && (
                <FeretSummaryElement
                    icon={contact}
                    title={t`Contact`}
                    subtitle={
                        <Box>
                            <Typography variant="h6" className={styles.subtitle_secondary}>
                                {props.address?.address}
                            </Typography>
                            <Typography variant="h6" className={styles.subtitle_secondary}>
                                {props.address?.postalCode} {props.address?.commune}
                            </Typography>
                            <Typography variant="h6" className={styles.subtitle_secondary}>
                                {props.address?.country}
                            </Typography>
                            <Typography variant="h6" className={styles.subtitle_secondary}>
                                {props.phone}
                            </Typography>
                            <Typography variant="h6" className={styles.subtitle_secondary}>
                                {props.email}
                            </Typography>
                        </Box>
                    }
                />
            )}
        </FeretSummaryContainer>
    );
};

export default AppellationSummary;
