/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import React from "react";
import { makeStyles, Box, Typography, Tooltip } from "@material-ui/core";
import PropTypes from "prop-types";
import franceImg from "../../assets/Wine/ageing/france.svg";
import vatImg from "../../assets/Wine/ageing/vat.svg";
import lees from "../../assets/Wine/ageing/lees.svg";
import batonnage from "../../assets/Wine/ageing/batonnage.svg";
import barrelImg from "../../assets/Wine/ageing/barrel.svg";
import tonnelierImg from "../../assets/Wine/ageing/tonnelier.svg";

import theme from "../../globalTheme";
import { useFrontVintageAgeingQuery } from "../../generated/graphql";
import Loading from "../../Components/Loader/Loading";
import { parseHtml } from "../../helpers/helpers";
import { Plural, t, Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: "flex",
        flexWrap: "wrap",
        gap: "32px",
        [theme.breakpoints.only("xs")]: {
            paddingBottom: "8px",
        },
        [theme.breakpoints.up("sm")]: {
            paddingBottom: "16px",
        },
    },
    otherAgeingContainer: {
        border: "0.4px solid",
        backgroundColor: "white",

        borderRadius: "5px",
        borderColor: theme.palette.secondary.dark,
        position: "relative",
        // marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        paddingTop: 0,
        [theme.breakpoints.only("xs")]: {
            width: "100%",
        },
    },
    ageingContainer: {
        [theme.breakpoints.up("lg")]: {
            width: "25%",
        },
        [theme.breakpoints.up("md")]: {
            width: "35%",
        },
        backgroundColor: "white",
        border: "0.4px solid",
        borderRadius: "5px",
        borderColor: theme.palette.secondary.dark,
        position: "relative",
        // marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        paddingTop: 0,
        [theme.breakpoints.only("xs")]: {
            width: "100%",
        },
    },
    ageingTypoContainer: {
        display: "flex",
        height: "100%",
        flexDirection: "column",
        justifyContent: "center",
        width: "200px",
        [theme.breakpoints.only("xs")]: {
            width: "auto",
        },
    },
    otherAgeingTypoContainer: {
        display: "flex",
        width: "100%",
        // gap: "16px",
        flexWrap: "wrap",
        [theme.breakpoints.only("xs")]: {
            gap: "8px",
            width: "100%",
            flexWrap: "nowrap",
        },
    },
    barrelAgeingSecondContainer: {
        backgroundColor: "white",

        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: "8px",
    },
    barrelAgeing: {
        width: "100%",
    },
    commentContainer: {
        backgroundColor: "white",

        border: "0.4px solid",
        borderRadius: "5px",
        borderColor: theme.palette.secondary.dark,
        position: "relative",
        // marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        paddingTop: 0,
    },
    typo: {
        ...theme.front.typography.labeur.dark,
    },
    typoContainer: {
        position: "relative",
        top: "-12px",
        left: "0px",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.only("xs")]: {
            top: -10,
        },
    },
    title: {
        ...theme.front.typography.h6.dark_b,
        zIndex: 2,
        backgroundColor: theme.palette.common.white,
        padding: "0px 2px",
    },
    chip: {
        height: "25px",
        backgroundColor: theme.palette.dashboard.background,
        borderColor: theme.palette.primary.light,
        "& .MuiChip-label": {
            fontSize: "14px",
            padding: theme.spacing(1),
        },
    },
    popper: {
        fontSize: "14px",
        color: "#FFFFFF",
        backgroundColor: theme.palette.primary.dark,
        borderRadius: "12px",
        boxShadow: "0px 3px 19px #0000006C",
        padding: "8px",
    },
    arrow: {
        color: theme.palette.primary.dark,
    },
    contentText__tonnelier: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
    },
    typoOthersMaturations: {
        ...theme.front.typography.labeur.dark,
        minWidth: "200px",
        width: "60%",
        marginRight: "16px",
        [theme.breakpoints.only("xs")]: {
            width: "auto",
            minWidth: "auto",
            margin: 0,
        },
    },
    otherMAturations__img: {
        width: "50px",
        [theme.breakpoints.only("xs")]: {
            width: "42px",
        },
    },
    otherAgeingTypoContainer__boxTypo: {
        display: "flex",
        alignItems: "flex-start",
        [theme.breakpoints.only("xs")]: {
            width: "100%",
            justifyContent: "space-between",
        },
    },
    otherAgeingTypo__img: {
        width: "51px",
        alignSelf: "flex-start",
        marginRight: "8px",
        [theme.breakpoints.only("xs")]: {
            width: "34px",
            marginRight: 0,
        },
    },
    typoBarrelMakers__typoPrim: {
        ...theme.front.typography.labeur.dark,
        minWidth: "150px",
        width: "80%",
        marginRight: "16px",
        [theme.breakpoints.only("xs")]: {
            margin: 0,
            minWidth: "auto",
            width: "auto",
        },
    },
    typoBarrelMakers__typoSecond: {
        ...theme.front.typography.labeur.dark,
        [theme.breakpoints.only("xs")]: {
            whiteSpace: "nowrap",
        },
    },
    ElevageEnBarrique__img: {
        [theme.breakpoints.only("xs")]: {
            width: "42px",
        },
    },
    commentContainer__boxTypoDescription__Typo: {
        fontSize: "16px",
        [theme.breakpoints.only("xs")]: {
            fontSize: "12px",
        },
    },
    ageing__boxContainerTypo__otherBox__TypoPrim: {
        ...theme.front.typography.labeur.dark,
    },
    ageing__boxContainerTypo__otherBox__TypoSecond: {
        marginTop: "8px",
        ...theme.front.typography.labeur.dark,
    },
    textAndPercentageTonnelier: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
    },
}));

const WineAgeing = (props) => {
    const getToasting = (v) => {
        if (v === 0) {
            return t`Non précisé`;
        }
        if (v === 1) {
            return t`très légère`;
        }
        if (v === 2) {
            return t`légère`;
        }
        if (v === 3) {
            return t`moyenne`;
        }
        if (v === 4) {
            return t`forte`;
        }
        if (v === 5) {
            return t`très forte`;
        }
    };

    const styles = useStyles(props);
    const { data, loading, error } = useFrontVintageAgeingQuery({
        variables: {
            vintageId: props.vintageId,
            wineId: props.wineId,
            locale: props.locale,
        },
    });

    const ageing = data?.FrontVintageAgeing;

    const getLeesImage = () => {
        if (ageing?.batonnage) {
            return batonnage;
        }
        if (ageing?.lees) {
            return lees;
        } else {
            return "";
        }
    };

    if (loading) {
        return (
            <Box display={"flex"} justifyContent={"center"} alignItems={"flex-end"}>
                <Loading loading={loading} />
            </Box>
        );
    }

    const getOtherAgeingPercentage = (el) => {
        if (el?.durationValue && !el?.durationUnit) {
            return (
                <Typography
                    className={styles.typo}
                    style={{
                        fontFamily: "Lato",
                        fontSize: "16px",
                        color: theme.palette.primary.dark,
                    }}
                >
                    <Plural value={el?.durationValue} one="# mois" other="# mois" />
                </Typography>
            );
        } else if (el?.durationValue && el?.durationUnit) {
            return (
                <Typography
                    className={styles.typo}
                    style={{
                        fontFamily: "Lato",
                        fontSize: "16px",
                        color: theme.palette.primary.dark,
                    }}
                >
                    {el?.durationValue} {el?.durationUnit}
                </Typography>
            );
        } else {
            return "";
        }
    };

    if (
        ageing?.description ||
        ageing?.lees ||
        ageing?.batonnage ||
        (ageing?.otherMaturations && ageing?.otherMaturations?.length !== 0) ||
        ageing?.frenchOak ||
        ageing?.newBarrelPercentage ||
        ageing?.barrelMaturationDurationValue ||
        ageing?.toasting ||
        (ageing?.barrelMakers && ageing?.barrelMakers?.length !== 0)
    ) {
        return (
            <Box className={styles.mainContainer}>
                {/* Elevage en barrique */}

                {(ageing?.frenchOak ||
                    ageing?.newBarrelPercentage ||
                    ageing?.barrelMaturationDurationValue ||
                    ageing?.toasting) && (
                    <Box className={styles.ageingContainer}>
                        <Box className={styles.typoContainer}>
                            <Typography variant="h3" className={styles.title}>
                                <Trans>Élevage en barriques</Trans>
                            </Typography>
                        </Box>
                        <Box className={styles.barrelAgeingSecondContainer} style={{ height: "80%" }}>
                            {/* Barrels */}
                            <Box
                                style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                }}
                            >
                                <img src={barrelImg} alt="ageing_img" className={styles.ElevageEnBarrique__img} />
                                {(ageing?.frenchOak ||
                                    ageing?.newBarrelPercentage ||
                                    ageing?.barrelMaturationDurationValue ||
                                    ageing?.toasting) && (
                                    <Box className={styles.ageingTypoContainer}>
                                        <Box
                                            style={{
                                                display: "flex",
                                                alignitems: "center",
                                            }}
                                        >
                                            {ageing?.frenchOak && (
                                                <Typography
                                                    className={styles.typo}
                                                    style={{
                                                        marginRight: "8px",
                                                        fontFamily: "Lato",
                                                        fontSize: "16px",
                                                        color: theme.palette.primary.dark,
                                                    }}
                                                >
                                                    <Trans> Chêne français</Trans>
                                                </Typography>
                                            )}
                                            {ageing?.frenchOak && (
                                                <Tooltip
                                                    color="primary"
                                                    title={t`Chêne français`}
                                                    arrow
                                                    classes={{
                                                        arrow: styles.arrow,
                                                        tooltip: styles.popper,
                                                    }}
                                                >
                                                    <Box
                                                        style={{
                                                            borderRadius: "50%",
                                                            width: "24px",
                                                            height: "24px",
                                                            overflow: "hidden",
                                                        }}
                                                    >
                                                        <img
                                                            src={franceImg}
                                                            alt="ageing_img"
                                                            style={{
                                                                height: "24px",
                                                                transform: "translateX(-10%)",
                                                            }}
                                                        />
                                                    </Box>
                                                </Tooltip>
                                            )}
                                        </Box>
                                        {ageing?.newBarrelPercentage ? (
                                            <Typography
                                                className={styles.typo}
                                                style={{
                                                    fontFamily: "Lato",
                                                    fontSize: "16px",
                                                    color: theme.palette.primary.dark,
                                                }}
                                            >
                                                <Trans>Neuves : {ageing?.newBarrelPercentage} %</Trans>
                                            </Typography>
                                        ) : null}
                                        {ageing?.barrelMaturationDurationValue ? (
                                            <Typography
                                                className={styles.typo}
                                                style={{
                                                    fontFamily: "Lato",
                                                    fontSize: "16px",
                                                    color: theme.palette.primary.dark,
                                                }}
                                            >
                                                <Plural
                                                    value={ageing?.barrelMaturationDurationValue}
                                                    one="# mois"
                                                    other="# mois"
                                                />
                                            </Typography>
                                        ) : null}
                                        {ageing?.toasting ? (
                                            <Typography
                                                className={styles.typo}
                                                style={{
                                                    fontFamily: "Lato",
                                                    fontSize: "16px",
                                                    color: theme.palette.primary.dark,
                                                }}
                                            >
                                                <Trans>Chauffe :</Trans> {getToasting(ageing?.toasting)}
                                            </Typography>
                                        ) : null}
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Box>
                )}
                {ageing?.barrelMakers && ageing?.barrelMakers?.length !== 0 && (
                    <Box className={styles.ageingContainer}>
                        <Box className={styles.typoContainer}>
                            <Typography variant="h3" className={styles.title}>
                                <Trans>Tonneliers</Trans>
                            </Typography>
                        </Box>
                        <Box className={styles.barrelAgeingSecondContainer}>
                            {/* Tonnelier */}
                            {ageing?.barrelMakers && ageing?.barrelMakers?.length !== 0 && (
                                <Box
                                    className={styles.barrelAgeing}
                                    style={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                    }}
                                >
                                    <Box className={styles.otherAgeingTypoContainer}>
                                        <img
                                            src={tonnelierImg}
                                            alt="ageing_img"
                                            className={styles.otherAgeingTypo__img}
                                        />
                                        <Box className={styles.contentText__tonnelier}>
                                            {ageing?.barrelMakers?.map((el, i) => {
                                                if (el?.barrelMaker) {
                                                    return (
                                                        <Box className={styles.textAndPercentageTonnelier}>
                                                            <Typography
                                                                className={styles.typoBarrelMakers__typoPrim}
                                                                style={{
                                                                    marginRight: 0,
                                                                    fontFamily: "Lato",
                                                                    fontSize: "16px",
                                                                    color: theme.palette.primary.dark,
                                                                }}
                                                            >
                                                                {el?.barrelMaker}
                                                            </Typography>
                                                            {el?.percentage && (
                                                                <Typography
                                                                    className={styles.typoBarrelMakers__typoSecond}
                                                                    style={{
                                                                        fontFamily: "Lato",
                                                                        fontSize: "16px",
                                                                        color: theme.palette.primary.dark,
                                                                    }}
                                                                >
                                                                    {el?.percentage} %
                                                                </Typography>
                                                            )}
                                                        </Box>
                                                    );
                                                }
                                            })}
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Box>
                )}

                {/* Autres élevages */}
                {ageing?.otherMaturations && ageing?.otherMaturations?.length !== 0 && (
                    <Box className={styles.otherAgeingContainer}>
                        <Box className={styles.typoContainer}>
                            <Typography variant="h3" className={styles.title}>
                                <Trans> Autres élevages </Trans>
                            </Typography>
                        </Box>
                        <Box className={styles.barrelAgeingSecondContainer}>
                            {/* Autres élevages */}
                            <Box
                                style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    width: "100%",
                                    gap: "8px",
                                }}
                            >
                                <img src={vatImg} alt="ageing_img" className={styles.otherMAturations__img} />
                                <Box className={styles.otherAgeingTypoContainer} style={{ flexDirection: "column" }}>
                                    {ageing?.otherMaturations?.map((el, i) => {
                                        if (el?.containerType) {
                                            return (
                                                <Box className={styles.otherAgeingTypoContainer__boxTypo}>
                                                    <Typography
                                                        className={styles.typoOthersMaturations}
                                                        style={{
                                                            fontFamily: "Lato",
                                                            fontSize: "16px",
                                                            color: theme.palette.primary.dark,
                                                        }}
                                                    >
                                                        {el?.containerType}
                                                        &nbsp;{"•"}
                                                        &nbsp;
                                                        {el?.material}
                                                    </Typography>
                                                    {getOtherAgeingPercentage(el)}
                                                </Box>
                                            );
                                        }
                                    })}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                )}

                {/* Lees & batonnage */}
                {(ageing?.lees || ageing?.batonnage) && (
                    <Box className={styles.ageingContainer}>
                        <Box className={styles.typoContainer}>
                            <Typography variant="h3" className={styles.title}>
                                <Trans> Technique </Trans>
                            </Typography>
                        </Box>
                        <Box className={styles.barrelAgeingSecondContainer} style={{ alignItems: "center" }}>
                            <Box>
                                <img src={getLeesImage()} alt="ageing_img" className={styles.otherMAturations__img} />
                            </Box>
                            {/* Autres élevages */}
                            <Box
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                }}
                            >
                                {ageing?.lees && (
                                    <Typography
                                        variant="h4"
                                        className={styles.ageing__boxContainerTypo__otherBox__TypoPrim}
                                        style={{
                                            fontFamily: "Lato",
                                            fontSize: "16px",
                                            color: theme.palette.primary.dark,
                                            fontWeight: "500",
                                        }}
                                    >
                                        <Trans>Élevage sur lies</Trans>
                                    </Typography>
                                )}
                                {ageing?.batonnage && (
                                    <Typography
                                        variant="h4"
                                        className={styles.ageing__boxContainerTypo__otherBox__TypoSecond}
                                        style={{
                                            fontFamily: "Lato",
                                            fontSize: "16px",
                                            color: theme.palette.primary.dark,
                                            fontWeight: "500",
                                        }}
                                    >
                                        <Trans>Avec bâtonnage</Trans>
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    </Box>
                )}

                {/* Complementary informations */}
                {ageing?.description && (
                    <Box className={styles.commentContainer}>
                        <Box className={styles.typoContainer}>
                            <Typography variant="h3" className={styles.title}>
                                <Trans>À propos de l&apos;élevage</Trans>
                            </Typography>
                        </Box>
                        <Box classname={styles.margins}>
                            <Typography
                                className={styles.commentContainer__boxTypoDescription__Typo}
                                style={{
                                    fontFamily: "Lato",
                                    fontSize: "16px",
                                    color: theme.palette.primary.dark,
                                }}
                            >
                                {parseHtml(ageing?.description)}
                            </Typography>
                        </Box>
                    </Box>
                )}
            </Box>
        );
    } else {
        return (
            <Typography style={{ fontSize: "16px", padding: "16px" }}>
                <Trans>Information non renseignée</Trans>
            </Typography>
        );
    }
};

WineAgeing.propTypes = {
    vintageId: PropTypes.string,
    wineName: PropTypes.string,
    year: PropTypes.number,
    aoc: PropTypes.string,
    cuvee: PropTypes.string,
    locale: PropTypes.string,
};

export default WineAgeing;
