import { t, Trans } from "@lingui/macro";
import { Box, makeStyles, Typography } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { useGetClassificationFrontQuery } from "generated/graphql";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import alliance from "../assets/rankingSummary/alliance.svg";
import contacts from "../assets/rankingSummary/contacts.svg";
import fioriture2 from "../assets/rankingSummary/fioriture2.svg";
import AddressBlock from "../FrontChallenge/AddressBlock";
import ChallengeCarousel from "../FrontChallenge/ChallengeCarousel";
import theme from "../globalTheme";
import { isNotEmpty, parseHtml, useWindowDimensions } from "../helpers/helpers";
import RankingCmsAccordion from "Front/cms/RankingAccrdion";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} {...other}>
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.any,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`,
    };
}

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        width: "100%",

        [theme.breakpoints.up("sm")]: {
            backgroundPositionY: "49px",
            backgroundPositionX: "left",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${fioriture2})`,
            marginLeft: "-32px",
            paddingLeft: "32px",
        },

        [theme.breakpoints.up("xl")]: {
            marginLeft: "-86px",
            paddingLeft: "86px",
        },
    },
    tabs: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        paddingBottom: "0",
        fontFamily: "Playfair Display, Helvetica, Arial, sans-serif",
        borderBottom: `1px solid ${theme.palette.secondary.main}`,

        "& .MuiTabs-indicator": {
            height: "4px",
            marginRight: "auto",
            borderRadius: "5px 5px 0px 0px",
            backgroundColor: theme.palette.primary.light,
        },
        "& .MuiTabs-scrollable": {
            borderBottom: "1px solid",
            borderBottomColor: theme.palette.secondary.main,
        },
        "& .MuiTabScrollButton-root.Mui-disabled": {
            transitionProperty: "width, opacity",
            transitionDuration: ".8s",
            animationTimingFunction: "cubic-bezier(.59,.01,.39,1)",
            width: "0px",
        },
        "& .MuiTabScrollButton-root": {
            transitionProperty: "width, opacity",
            transitionDuration: ".8s",
            animationTimingFunction: "cubic-bezier(.59,.01,.39,1)",
        },
        "& .MuiTab-textColorInherit": {
            fontFamily: "Playfair Display, Helvetica, Arial, sans-serif",
            fontWeight: "bold",
            maxWidth: "none",
            color: theme.palette.secondary.main,
        },
        "& .Mui-selected": {
            fontFamily: "Playfair Display, Helvetica, Arial, sans-serif",
            fontWeight: "bold",
            color: theme.palette.text.primary,
        },
        "& .MuiTab-wrapper": {
            fontSize: "18px",
        },
        "& .MuiTabs-flexContainer": {
            justifyContent: "space-between",
        },
    },
    tab: {
        position: "relative",
        textTransform: "none",
        fontSize: "21px",
        fontFamily: "Playfair Display",
        padding: "5px 0px",
        fontWeight: "bold",
        color: theme.palette.secondary.main,
        marginTop: "21px",

        [theme.breakpoints.down("md")]: {
            paddingBottom: "26px",
        },
    },
    tabPanelTitleBox: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(1),
    },
    tabPanelTitle: {
        fontFamily: "Playfair Display, Helvetica, Arial, sans-serif",
        fontWeight: "bold",
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
}));

const RankingsTabs = ({ organism, active, setActive }) => {
    const styles = useStyles();
    const { width } = useWindowDimensions();
    const { name } = useParams();
    const location = useLocation();

    const { data } = useGetClassificationFrontQuery({
        variables: { classificationOrganismSlug: name },
    });

    const handleChange = (event, newValue) => {
        setActive(newValue);
    };

    const displayAbout = () => {
        if (isNotEmpty(organism?.about)) {
            return (
                <Typography
                    variant="body2"
                    style={{
                        color: theme.palette.primary.dark,
                        fontWeight: "400",
                        fontFamily: theme.typography.fontFamily,
                    }}
                    className={styles.tabPanelContent}
                >
                    {parseHtml(organism.about)}
                </Typography>
            );
        }
        return null;
    };

    useEffect(() => {
        setActive(0);
    }, [location]);

    if (!organism) return null;

    return (
        <Box className={styles.root}>
            <AppBar position="static" color="transparent" style={{ boxShadow: "none" }}>
                <Tabs
                    className={styles.tabs}
                    value={active}
                    onChange={handleChange}
                    variant={width <= 800 ? "scrollable" : "fullWidth"}
                    scrollButtons="auto"
                    indicatorColor="primary"
                >
                    {data?.GetClassificationFront?.length > 0 && <Tab label={t`Liste des classés`} {...a11yProps(0)} />}
                    <Tab label={organism.organisation} {...a11yProps(1)} />
                </Tabs>
            </AppBar>

            {/* tabs 1 */}
            {data?.GetClassificationFront?.length > 0 && (
                <TabPanel value={active} index={0} className={styles.tab}>
                    <RankingCmsAccordion datas={data?.GetClassificationFront} />
                </TabPanel>
            )}

            {/* tabs 2 */}
            <TabPanel value={active} index={data?.GetClassificationFront.length ? 1 : 0} className={styles.tab}>
                <Box style={{ position: "relative", zIndex: 1 }}>
                    <Box className={styles.tabPanelTitleBox}>
                        <img src={alliance} alt="alliance_image" />
                        <Typography
                            variant="h4"
                            style={{ color: theme.palette.text.primary }}
                            className={styles.tabPanelTitle}
                        >
                            <Trans>À propos</Trans>
                        </Typography>
                    </Box>
                    {displayAbout()}
                </Box>
                {organism?.contacts?.length > 0 && (
                    <Box style={{ position: "relative", zIndex: 1 }}>
                        <Box className={styles.tabPanelTitleBox} style={{ marginTop: "30px" }}>
                            <img src={contacts} alt="alliance_image" />
                            <Typography
                                variant="h4"
                                style={{ color: theme.palette.text.primary }}
                                className={styles.tabPanelTitle}
                            >
                                <Trans>Contacts</Trans>
                            </Typography>
                        </Box>

                        {width < 960 ? (
                            <ChallengeCarousel
                                datas={organism.contacts}
                                visibleSlides={width < 850 ? 1 : 2}
                                type="contacts"
                                height={109}
                                width={109}
                            />
                        ) : (
                            <Box style={{ display: "flex", flexWrap: "wrap", gap: 24 }}>
                                {organism.contacts.map((contact, i) => (
                                    <AddressBlock
                                        key={i}
                                        data={contact}
                                        width="calc(100% / 3 - (23px + 20px + (24px)))"
                                        boxStyle={{ maxWidth: 272 }}
                                    />
                                ))}
                            </Box>
                        )}
                    </Box>
                )}
            </TabPanel>
        </Box>
    );
};

RankingsTabs.propTypes = {
    organism: PropTypes.object,
    active: PropTypes.number,
    setActive: PropTypes.func,
};

export default RankingsTabs;
