import { t } from "@lingui/macro";
import { Box, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

import redGrape from "../../assets/Dashboard/redGrape.svg";
import whiteGrape from "../../assets/Dashboard/whiteGrape.svg";
import theme from "../../globalTheme";
import getConnectors, {
    applySeparator,
    getHectares,
    getRound,
    lowerCaseAndFirstLetterUppercase,
} from "../../helpers/helpers";
import CommunesTables from "./CommunesTables";
import GrapesTables from "./GrapesTables";

const SurfaceBlock = (props) => {
    const getTypographyBox = (o) => {
        const { title, value } = o;

        if (value) {
            return (
                <Box
                    style={{
                        width: title !== "Porte-greffes" && "15%",
                        minWidth: title === "Porte-greffes" && "20%",
                        maxWidth: title === "Porte-greffes" && "40%",
                    }}
                >
                    {title ? (
                        <Typography
                            variant="h6"
                            style={{
                                fontSize: "10px",
                                color: "#432E35",
                                padding: "0 5%",
                            }}
                        >
                            {title}
                        </Typography>
                    ) : (
                        ""
                    )}
                    {value ? (
                        <Typography
                            variant="h6"
                            style={{
                                color: theme.palette.primary.main,
                                fontWeight: "bold",
                                padding: "0 5%",
                            }}
                        >
                            {value}
                        </Typography>
                    ) : (
                        ""
                    )}
                </Box>
            );
        } else {
            return "";
        }
    };

    const getGeneralInfo = () => {
        return (
            <Box
                style={{ paddingLeft: theme.spacing(1), flexWrap: "wrap" }}
                display={"flex"}
                justifyContent={"flex-start"}
                paddingBottom={1}
            >
                {[
                    {
                        title: t`Âge moyen`,
                        value: !props.hidden?.ageMoyenDesVignes
                            ? Math.round(props.vineyard?.ageMoyenDesVignes)
                                ? Math.round(props.vineyard?.ageMoyenDesVignes) + " " + t`ans`
                                : ""
                            : "",
                    },
                    {
                        title: t`Densité moyenne`,
                        value: !props.hidden?.densiteDePlantation
                            ? applySeparator(getRound(props.vineyard?.densiteDePlantation, 100)) + " " + t`pieds/ha` &&
                              props.surfaceImportCVI
                                ? t`${applySeparator(getRound(props.vineyard?.densiteDePlantation, 100))} pieds/ha`
                                : ""
                            : "",
                    },
                    {
                        title: t`Nombre de pieds théorique`,
                        value: !props.hidden?.vinesCount
                            ? applySeparator(getRound(props.vineyard?.vinesCount, 100))
                                ? applySeparator(getRound(props.vineyard?.vinesCount, 100))
                                : ""
                            : "",
                    },
                    {
                        title: t`Porte-greffes`,
                        value: !props.hidden?.rootstocks ? getConnectors(props.vineyard?.rootstocks) : "",
                    },
                ].map((e) => {
                    if (e) {
                        return getTypographyBox(e);
                    }
                })}
            </Box>
        );
    };

    return (
        [
            props.vineyard?.couleur,
            lowerCaseAndFirstLetterUppercase(props.vineyard?.vineyard?.appellation),
            getHectares(props.vineyard?.superficie),
            props.vineyard?.communes,
            props.vineyard?.encepagements && props.vineyard?.encepagements?.length !== 0,
            props.vineyard?.rootstocks && props.vineyard?.rootstocks?.length !== 0,
            props.vineyard?.ageMoyenDesVignes,
            props.vineyard?.vinesCount,
            props.vineyard?.densiteDePlantation,
        ].some(Boolean) && (
            <Box
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    border: "0.4px solid",
                    borderRadius: "5px",
                    borderColor: theme.palette.secondary.main,
                    marginBottom: "10px",
                    position: "relative",
                    backgroundColor: "white",
                }}
            >
                <img
                    style={{ position: "absolute", top: 0, right: 0 }}
                    src={props.vineyard?.couleur === "rouge" ? redGrape : whiteGrape}
                    alt="grape_picture"
                />

                <Typography
                    variant="h4"
                    style={{
                        fontSize: "18px",
                        padding: "10px",
                        paddingBottom: "12px",
                        paddingLeft: theme.spacing(2),
                    }}
                >
                    {lowerCaseAndFirstLetterUppercase(props.vineyard?.couleur)}{" "}
                    {props.vineyard?.couleur && lowerCaseAndFirstLetterUppercase(props.vineyard?.appellation)
                        ? "• "
                        : ""}
                    {lowerCaseAndFirstLetterUppercase(props.vineyard?.appellation)}{" "}
                    {getHectares(props.vineyard?.superficie, props.locale) &&
                    lowerCaseAndFirstLetterUppercase(props.vineyard?.appellation)
                        ? "• "
                        : ""}
                    {props.surfaceImportCVI
                        ? getHectares(props.vineyard?.superficie, props.locale)
                        : props.vineyard?.superficie
                        ? props.vineyard?.superficie + t` ha`
                        : ""}
                </Typography>

                {props.surfaceImportCVI && getGeneralInfo()}

                <Box style={{ display: "flex" }}>
                    {props.vineyard?.communes && props.vineyard?.communes?.length !== 0 && !props.hidden?.communes && (
                        <Box style={{ width: "50%" }}>
                            <Typography
                                variant="h6"
                                style={{
                                    fontSize: "10px",
                                    color: "#432E35",
                                    padding: "0 5%",
                                }}
                            >
                                {t`Communes`}
                            </Typography>
                            <CommunesTables locale={props.locale} communes={props.vineyard?.communes} width={"90%"} />
                        </Box>
                    )}
                    {props.vineyard?.encepagements &&
                        props.vineyard?.encepagements?.length !== 0 &&
                        !props.hidden?.encepagements && (
                            <Box style={{ width: "50%" }}>
                                <Typography
                                    variant="h6"
                                    style={{
                                        fontSize: "10px",
                                        color: "#432E35",
                                        padding: "0 5%",
                                    }}
                                >
                                    {t`Encépagement`}
                                </Typography>
                                <GrapesTables
                                    locale={props.locale}
                                    grapes={props.vineyard?.encepagements}
                                    width={"90%"}
                                />
                            </Box>
                        )}
                </Box>
            </Box>
        )
    );
};

SurfaceBlock.propTypes = {
    surfaceArea: PropTypes.number,
    color: PropTypes.string,
    hectares: PropTypes.string,
    appellation: PropTypes.string,
    communes: PropTypes.object,
    grapes: PropTypes.object,
    lieuDits: PropTypes.object,
    averageAge: PropTypes.number,
    averageDensity: PropTypes.number,
    vinesCount: PropTypes.number,

    surfaceImportCVI: PropTypes.bool,
    vineyard: PropTypes.any,
    porteGreffes: PropTypes.array,
    hidden: PropTypes.array,
    locale: PropTypes.string,
};

export default SurfaceBlock;
