import { Grid, makeStyles, Typography, TypographyTypeMap } from "@material-ui/core";
import React from "react";

import { Maybe } from "../generated/graphql";

interface FrontTitleProps {
    title?: Maybe<string>;
    style?: any;
    typoVariant?: TypographyTypeMap["props"]["variant"];
}

const useStyles = makeStyles((theme) => {
    return {
        title_container: {
            margin: "0",
            textAlign: "center",
            color: theme.palette.common.white,
            paddingTop: "30px",
            paddingBottom: "20px",
            // maxWidth: "none",

            [theme.breakpoints.up("md")]: {
                paddingTop: "38px",
                paddingBottom: "23px",
            },

            [theme.breakpoints.up("xl")]: {
                paddingTop: "38px",
                paddingBottom: "23px",
            },
        },
    };
});

const FrontTitle = (props: FrontTitleProps) => {
    const styles = useStyles();
    return (
        <Grid item>
            <Typography variant={props?.typoVariant ?? "h1"} className={styles.title_container} style={props.style}>
                {props.title}
            </Typography>
        </Grid>
    );
};

export default FrontTitle;
