import React from "react";
import PropTypes, { bool, func } from "prop-types";
import Modal from "../../../Containers/Modal";
import papierZoom from "../../../assets/SubscriptionPage/papierZoom.png";
import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    container: {
        // height: "80vh",

        [theme.breakpoints.up("xs")]: {
            overflowY: "scroll",
            overflowX: "scroll",
        },
        [theme.breakpoints.up("md")]: {
            overflowX: "inherit",
        },
    },
    img: {
        [theme.breakpoints.up("xs")]: {
            width: "55rem",
        },
        [theme.breakpoints.up("md")]: {
            width: "100%",
        },
    },
}));
const PreviewPaperSubscriptionModal = (props) => {
    const styles = useStyles();
    return (
        <Modal
            open={props.open}
            onClose={() => {
                props.onClose();
            }}
            actionNeeded={false}
        >
            <Box className={styles.container}>
                <img src={papierZoom} className={styles.img} />
            </Box>
        </Modal>
    );
};

PreviewPaperSubscriptionModal.propTypes = {
    open: bool,
    onClose: func,
};

export default PreviewPaperSubscriptionModal;
