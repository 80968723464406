import { Box, makeStyles } from "@material-ui/core";
import Loading from "Components/Loader/Loading";
import { array, bool, func, object, string } from "prop-types";
import { useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";

import { SwiperWrapper } from "Front/SwiperCards";
import { urlFormatted, useWindowDimensions } from "helpers/helpers";
import { useCustomHistory } from "hooks";
import EstateTitledSemiUnderlined from "../../../FrontEstate/Components/EstateTitledSemiUnderlined";
import tourismBg from "../../../assets/landingPage/tourismbg.svg";
import { useListActivitiesQuery } from "../../../generated/graphql";
import theme from "../../../globalTheme";
import ActivityCard from "./ActivityCard";

const useStyles = makeStyles(() => ({
    containerActivityCards: (props) => ({
        marginBottom: "3rem",
        width: "100%",
        position: "relative",
        overflow: "visible",
        "&::before": {
            display: props.backgroundActivity ? "inherit" : "none",
            width: "107vw",
            content: '""',
            position: "absolute",
            zIndex: -1,
            top: 0,
            bottom: 0,
            right: 0,

            backgroundImage: `url(${tourismBg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            [theme.breakpoints.up("xl")]: {
                left: "-19%",
            },
        },
    }),
}));

const ActivityCardsContainer = (props) => {
    const styles = useStyles(props);
    const location = useLocation();
    const activitiesListRef = useRef(null);
    const activitiesList = activitiesListRef?.current;
    const { wineEntityShortId, propertyName } = useParams();
    const history = useCustomHistory();
    const { width } = useWindowDimensions();
    const { data: { ListActivities: activities } = {}, loading } = useListActivitiesQuery({
        variables: {
            wineEntityId: props.wineEntityId,
            omit: props.omit,
            locale: props.locale,
        },
    });

    useEffect(() => {
        if (props.setActivitiesAreLoading) {
            props.setActivitiesAreLoading(loading);
        }
    }, [loading]);

    useEffect(() => {
        if (
            location.hash === "#activites" &&
            !loading &&
            !props.loadingWine &&
            !props.loadingBesv &&
            !props.loadingEstate &&
            !props.loadingContact
        ) {
            if (activitiesList) {
                const rect = activitiesList.getBoundingClientRect();
                const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

                window.scroll({
                    top: rect.top + rect.height / 2 - viewHeight / 2,
                    behavior: "smooth",
                });
            }
        }
    }, [
        loading,
        props.loadingWine,
        props.loadingBesv,
        props.loadingEstate,
        props.loadingContact,
        location.hash,
        activitiesList,
    ]);

    if (loading) {
        return <Loading loading={loading} />;
    }

    if (!activities?.length) return null;
    return (
        <Box
            className={`${styles.containerActivityCards} ${props.boxClassName}`}
            style={props.boxStyle}
            ref={activitiesListRef}
            id="activites"
        >
            {props.title && (
                <EstateTitledSemiUnderlined title={props.title} styleBox={props.styleBox} heightAfter={32} />
            )}
            <SwiperWrapper
                spaceBetween={0}
                slidesPerView={width < 600 ? 1 : "auto"}
                loading={loading}
                wrapperPaddingBottom="0"
                noPadding
            >
                {activities?.map((activity, i) => {
                    let key = `activity-${activity?.name}-${i}`;
                    return (
                        <Box key={key} style={{ height: "100%", margin: "0 8px" }}>
                            <ActivityCard
                                wineEntityName={props.wineEntityName}
                                activityImage={activity?.image?.[0]?.twicpicURL}
                                name={activity?.name}
                                activityDuration={
                                    activity?.duration && activity?.durationUnit
                                        ? `${activity?.duration} ${activity?.durationUnit}`
                                        : ""
                                }
                                activityMinDuration={
                                    activity?.minRentTime && activity?.minRentTimeUnit
                                        ? `${activity?.minRentTime} ${activity?.minRentTimeUnit} minimum`
                                        : ""
                                }
                                activityDescription={activity?.description}
                                activityHandicap={activity?.accessibleTo}
                                activityChildren={activity?.startingAge}
                                activityReservation={activity?.reservationNeeded}
                                activityReservationTime={activity?.activePeriods}
                                activityTags={activity?.tags}
                                activityLanguages={activity?.languages}
                                activityMaxPersons={activity?.groupSize}
                                activityPrice={activity?.priceDetails}
                                activityPriceFree={activity?.free}
                                activityTelephoneNumber={activity?.contactPhoneNumber}
                                activityType={activity?.types?.[0]}
                                activityId={activity?.activityId}
                                to={history.addLocale(
                                    `/${urlFormatted(wineEntityShortId, propertyName)}/activites/${urlFormatted(
                                        activity.activityShortId,
                                        activity.name
                                    )}`
                                )}
                            />
                        </Box>
                    );
                })}
            </SwiperWrapper>
        </Box>
    );
};

ActivityCardsContainer.propTypes = {
    wineEntityId: string,
    omit: array,
    title: string,
    locale: string,
    backgroundActivity: bool,
    boxClassName: object,
    boxStyle: object,
    wineEntityName: string,
    setActivitiesAreLoading: func,
    loadingWine: bool,
    loadingBesv: bool,
    loadingContact: bool,
    loadingEstate: bool,
    styleBox: object,
};

export default ActivityCardsContainer;
