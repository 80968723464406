import { useCallback, useState } from "react";
import { Box, Card, Icon, makeStyles, Typography } from "@material-ui/core";
import OdgWineCard from "../FrontOdg/OdgWineCard";
import theme from "../globalTheme";
import SwiperCards, { SwiperWrapper } from "../Front/SwiperCards";
import { useWindowDimensions } from "../helpers/helpers";
import SimpleInput from "../Components/Inputs/SimpleInput";
import inputSearchIcon from "../assets/navbarfront/inputLoupe.svg";
import { useGetOdgShortQuery } from "../generated/graphql";
import Loading from "../Components/Loader/Loading";
import { t, Trans } from "@lingui/macro";

const useStyle = makeStyles(() => ({
    card: {
        boxShadow: "unset",
        backgroundColor: theme.palette.background.card,
    },
    content_card_odg: {
        display: "flex",
        flexWrap: "wrap",
        [theme.breakpoints.down("lg")]: {
            gap: "2rem",
            justifyContent: "center",
        },
        [theme.breakpoints.up("lg")]: {
            gap: "2rem",
            justifyContent: "center",
            paddingBottom: "32px",
        },
        [theme.breakpoints.up("xl")]: {
            gap: "10px 3px",
            padding: "2px",
            paddingBottom: "32px",
            justifyContent: "left",
        },
    },
    search: {
        display: "flex",
        justifyContent: "center",
        marginBottom: "2rem",
    },
}));

const OdgTab = () => {
    const styles = useStyle();
    const { width } = useWindowDimensions();
    const [search, setSearch] = useState("");

    const { data: { GetOdg: odgList = [] } = {}, loading } = useGetOdgShortQuery();

    const filteredData = useCallback(() => {
        let d = [...odgList];
        return d?.filter((item) => {
            if (!search) {
                return true;
            }
            return item?.name
                ?.toUpperCase()
                .replace("-", " ")
                .normalize("NFD")
                .replace(/\p{Diacritic}/gu, "")
                .includes(search?.toString().toUpperCase());
        });
    }, [odgList, search]);

    return (
        <>
            <Typography
                variant="body2"
                style={{ backgroundColor: theme.palette.common.white, padding: "8px", margin: "1rem 0" }}
            >
                <Trans>
                    Anciennement appelées syndicats viticoles, ces associations font le lien entre l’INAO et les
                    producteurs de vin. Chacune d’entre elles gère une ou plusieurs appellations.
                </Trans>
                <br />
                <br />
                <Trans>
                    Les organismes de défense et de gestion (ODG) sont chargés de rédiger le cahier des charges des vins
                    de leur appellation, de veiller à son application, de tenir à jour la liste des producteurs qu’ils
                    transmettent régulièrement à l’INAO, de promouvoir, valoriser, protéger et défendre leur(s)
                    appellation(s), leurs vins et leur terroir.
                </Trans>
            </Typography>
            <Card className={styles.card}>
                <Typography
                    variant="h3"
                    style={{
                        textAlign: "center",
                        margin: "2rem 0",
                        fontWeight: 700,
                        color: theme.palette.primary.dark,
                    }}
                >
                    <Trans>Découvrir les organismes de défense et de gestion</Trans>
                </Typography>
                <Box className={styles.search}>
                    <SimpleInput
                        boxStyle={{ width: "191px" }}
                        customIcon={
                            <Icon style={{ display: "flex", alignItems: "center" }}>
                                <img src={inputSearchIcon} />
                            </Icon>
                        }
                        borderRadius="50px"
                        startAdornment
                        size="small"
                        name={"search"}
                        placeholder={t`Rechercher...`}
                        variant="outlined"
                        value={search}
                        onChange={(e) => setSearch(e?.target?.value)}
                    />
                </Box>
                {loading ? (
                    <Loading />
                ) : (
                    <>
                        {width >= 1280 ? (
                            <Box className={styles.content_card_odg}>
                                {filteredData().map((odg, i) => (
                                    <OdgWineCard key={i} name={odg.name} />
                                ))}
                            </Box>
                        ) : (
                            <SwiperWrapper>
                                {filteredData().map((e, i) => (
                                    <OdgWineCard key={i} name={e.name} id={e._id} />
                                ))}
                            </SwiperWrapper>
                        )}
                    </>
                )}
            </Card>
        </>
    );
};

OdgTab.propTypes = {};

export default OdgTab;
