import React, { useMemo } from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { arrayOf, number, shape, string } from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import theme from "../../globalTheme";
import calendar from "../../assets/appellation/calendar.svg";
import attackIcon from "../../assets/appellation/attack.svg";
import acidityIcon from "../../assets/appellation/acidity.svg";
import temperatureIcon from "../../assets/appellation/temperature.svg";
import chronometerIcon from "../../assets/appellation/chronometer.svg";
import FrontSlider from "./FrontSlider";
import { plural, t, Trans } from "@lingui/macro";
import fioriture2 from "../../assets/rankingSummary/fioriture2.svg";
import { parseHtml, useWindowDimensions } from "../../helpers/helpers";
import ServiceItem from "./ServiceItem";
import service from "../../assets/appellation/service.svg";
import { WineImages } from "../WineImages";
import { TabPanel } from "Components/TabPanel";
import { useSelector } from "react-redux";

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        width: "100%",

        [theme.breakpoints.up("lg")]: {
            backgroundPositionY: "49px",
            backgroundPositionX: "left",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${fioriture2})`,
        },
    },
    tabs: (props) => ({
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        paddingBottom: "0",
        fontFamily: "Playfair Display, Helvetica, Arial, sans-serif",
        borderBottom: `1px solid ${theme.palette.secondary.main}`,

        "& .MuiTabs-indicator": {
            height: "4px",
            marginRight: "auto",
            borderRadius: "5px 5px 0px 0px",
            backgroundColor: theme.palette.primary.light,

            // [theme.breakpoints.up("md")]: {
            //     // width: "50% !important",
            //     width: setSizeTabIndicator(props?.titles?.length),
            // },
        },
        "& .MuiTabs-scrollable": {
            borderBottom: "1px solid",
            borderBottomColor: theme.palette.secondary.main,
        },
        "& .MuiTabScrollButton-root.Mui-disabled": {
            transitionProperty: "width, opacity",
            transitionDuration: ".8s",
            animationTimingFunction: "cubic-bezier(.59,.01,.39,1)",
            width: "0px",
        },
        "& .MuiTabScrollButton-root": {
            transitionProperty: "width, opacity",
            transitionDuration: ".8s",
            animationTimingFunction: "cubic-bezier(.59,.01,.39,1)",
        },
        "& .MuiTab-textColorInherit": {
            ...theme.front.typography.h6.light_b,
            maxWidth: "none",
            color: theme.palette.secondary.main,
        },
        "& .Mui-selected": {
            ...theme.front.typography.h6.light_b,
            color: theme.palette.text.primary,
        },
        "& .MuiTab-wrapper": {
            ...theme.typography.h4,
        },
        "& .MuiTabs-flexContainer": {
            justifyContent: "space-between",
        },
        "& .MuiTab-root": {
            flex: 1,
        },
    }),
    tab: {
        position: "relative",
        textTransform: "none",
        fontFamily: "Playfair Display",
        padding: "5px 0px",
        // minWidth: "fit-content",
        fontWeight: "bold",
        color: theme.palette.secondary.main,
        marginTop: "21px",
        // overflowY: "scroll",
        // height: "300px",

        [theme.breakpoints.down("md")]: {
            // minHeight: "490px",
            paddingBottom: "26px",
        },

        [theme.breakpoints.down("sm")]: {
            // minHeight: "530px",
        },
    },
    tabPanelTitleBox: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(1),
    },
    tabPanelTitle: {
        // ...theme.front.typography.h6.dark_b,
        fontFamily: "Playfair Display, Helvetica, Arial, sans-serif",
        fontWeight: "bold",
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    arome_container_items: {
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap",
    },
    container_items_slider_1: {
        width: "100%",
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
        [theme.breakpoints.up("lg")]: {
            width: "49%",
        },
    },
    container_items_slider_2: {
        [theme.breakpoints.up("xs")]: {
            width: "100%",
        },
        [theme.breakpoints.up("md")]: {
            width: "70%",
        },
        [theme.breakpoints.up("lg")]: {
            width: "49%",
        },
    },
    arome_container_item_single: {
        marginBottom: "6px",
        marginRight: "9px",
        borderRadius: "4px",
        padding: 2,
        background: theme.palette.primary.light,
    },
    arome_content: {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.common.white,
    },
    service_slider_container: {
        background: "#FCF6F6",
        // marginBottom: "16px",
        padding: "1.5rem",
    },
    service_title: {
        color: theme.palette.primary.light,
        fontWeight: "bold",
    },
    container_sliders: {
        display: "flex",
        flexWrap: "wrap",
        columnGap: "1rem",
        rowGap: "1rem",
        [theme.breakpoints.up("lg")]: {
            columnGap: "2%",
        },
    },
}));

const AppellationTabs = ({ wines, wineInformations }) => {
    const styles = useStyles();
    const [tabValue, setTabValue] = React.useState(0);
    const { width } = useWindowDimensions();
    const locale = useSelector((state) => state?.user?.locale || "fr");

    const olfactifSliderMarks = [
        {
            value: 0,
            label: t`Faible`,
        },
        {
            value: 20,
            label: t`Discrète`,
        },
        {
            value: 40,
            label: t`Aromatique`,
        },
        {
            value: 60,
            label: t`Développée`,
        },
        {
            value: 80,
            label: t`Expressive`,
        },
        {
            value: 100,
            label: t`Puissante`,
        },
    ];

    const palateSliderMarks = [
        {
            value: 0,
            label: t`Très faible`,
        },
        {
            value: 33,
            label: t`Faible`,
        },
        {
            value: 66,
            label: t`Moyenne`,
        },
        {
            value: 100,
            label: t`Forte`,
        },
    ];

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const getSliderValue = (baseArr, values) => {
        if (!values) {
            return null;
        }
        let finalVal = null;
        baseArr?.map((el, i) => {
            if (values === i + 1) {
                finalVal = el.value;
            }
        });
        return finalVal;
    };

    const titleAbout = ({ color, sweetness }) => {
        if (color === "Blanc") {
            if (sweetness === "Sec") {
                return t`À propos des crus blancs secs`;
            } else if (sweetness === "Demi-sec") {
                return t`À propos des crus blancs demi-secs`;
            } else if (sweetness === "Moelleux") {
                return t`À propos des crus blancs moelleux`;
            } else if (sweetness === "Liquoreux") {
                return t`À propos des crus blancs liquoreux`;
            }
        } else if (color === "Rouge") {
            return t`À propos des crus rouges`;
        } else {
            return t`À propos des crus rosés`;
        }
    };

    const tabsTitles = useMemo(() => {
        if (!wines?.length) return null;
        const titles = new Set();
        const titleByColor = {
            Rouge: "Les rouges",
            Rosé: "Les rosés",
            Blanc: "Les blancs",
        };

        wines.map(({ color, sweetness }) => {
            let title = [];
            if (color === "Blanc") {
                if (sweetness === "Sec") {
                    title += t`Les blancs secs`;
                } else if (sweetness === "Demi-sec") {
                    title += t`Les blancs demi-secs`;
                } else if (sweetness === "Moelleux") {
                    title += t`Les blancs moelleux`;
                } else if (sweetness === "Liquoreux") {
                    title += t`Les blancs liquoreux`;
                }
            } else if (color === "Rouge") {
                title += t`Les rouges`;
            } else {
                title += t`Les rosés`;
            }
            titles.add(title);
        });

        return [...titles];
    }, [wines]);

    return (
        <Box className={styles.root}>
            <AppBar position="static" color="transparent" style={{ boxShadow: "none" }}>
                <Tabs
                    className={styles.tabs}
                    value={tabValue}
                    onChange={handleChange}
                    variant={width <= 800 && tabsTitles.length > 1 ? "scrollable" : "fullWidth"}
                    // variant={"scrollable"}
                    scrollButtons="auto"
                    indicatorColor="primary"
                    aria-label="scrollable auto tabs example"
                >
                    {tabsTitles?.map((e, i) => (
                        <Tab key={i} label={e} {...a11yProps(i)} />
                    ))}
                </Tabs>
            </AppBar>

            {wineInformations?.map(
                (
                    { aromas, noseIntensity, tasteIntensity, temperature, attack, length, storageYear, acidity, about },
                    i
                ) => (
                    <TabPanel key={i} value={tabValue} index={i} className={styles.tab}>
                        <Box style={{ position: "relative", zIndex: 1 }}>
                            <Box className={styles.tabPanelTitleBox}>
                                <Box style={{ width: 27, height: 27, display: "flex", justifyContent: "center" }}>
                                    <img src={service} alt="title_icon" />
                                </Box>

                                <Typography
                                    variant="h4"
                                    style={{ color: theme.palette.text.primary }}
                                    className={styles.tabPanelTitle}
                                >
                                    <Trans>Dégustation &amp; service</Trans>
                                </Typography>
                            </Box>

                            {aromas?.length > 0 && (
                                <Box className={styles.arome_container_items} style={{ marginBottom: "16px" }}>
                                    {aromas?.map((aroma, i) => (
                                        <Box key={i} className={styles.arome_container_item_single}>
                                            <Typography
                                                variant="body2"
                                                className={styles.arome_content}
                                                style={{ padding: "4px" }}
                                            >
                                                {locale === "fr" ? t`Arômes` + " " + aroma : aroma + " " + t`Arômes`}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                            )}

                            <Box className={styles.container_sliders}>
                                {noseIntensity && (
                                    <Box className={styles.container_items_slider_1}>
                                        <Box className={styles.service_slider_container}>
                                            <Typography variant="body2" className={styles.service_title}>
                                                <Trans>Intensité olfactive</Trans>
                                            </Typography>
                                            <FrontSlider
                                                value={getSliderValue(olfactifSliderMarks, noseIntensity)}
                                                boxStyle={{ paddingLeft: 0, paddingRight: 0 }}
                                                marks={olfactifSliderMarks}
                                                titleMarginBottom="1.5rem"
                                                variantLabelJauge="h6"
                                                colorEnum
                                                disabled
                                            />
                                        </Box>
                                    </Box>
                                )}

                                {tasteIntensity && (
                                    <Box className={styles.container_items_slider_2}>
                                        <Box className={styles.service_slider_container}>
                                            <Typography variant="body2" className={styles.service_title}>
                                                <Trans>Intensité gustative</Trans>
                                            </Typography>
                                            <FrontSlider
                                                colorEnum
                                                disabled
                                                marks={palateSliderMarks}
                                                value={getSliderValue(palateSliderMarks, tasteIntensity)}
                                                boxStyle={{ paddingLeft: 0, paddingRight: 0 }}
                                                titleMarginBottom="1.5rem"
                                            />
                                        </Box>
                                    </Box>
                                )}

                                {temperature && (
                                    <ServiceItem
                                        title={t`Température de service`}
                                        icon={temperatureIcon}
                                        content={`${temperature} °C`}
                                    />
                                )}
                                {attack && <ServiceItem title={t`Attaque`} icon={attackIcon} content={attack} />}
                                {acidity && <ServiceItem title={t`Acidité`} icon={acidityIcon} content={acidity} />}
                                {length && <ServiceItem title={t`Longueur`} icon={chronometerIcon} content={length} />}
                                {storageYear && (
                                    <ServiceItem
                                        title={t`Garde moyenne`}
                                        icon={calendar}
                                        content={plural(storageYear, {
                                            one: "# an",
                                            other: "# ans",
                                        })}
                                    />
                                )}
                            </Box>
                        </Box>

                        <Box style={{ position: "relative", zIndex: 1 }}>
                            <Box className={styles.tabPanelTitleBox} style={{ marginTop: "30px" }}>
                                <Box
                                    style={{
                                        width: 7,
                                        height: 30,
                                        marginLeft: 20,
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <WineImages wines={[wines[i]]} limit={1} isBottle />
                                </Box>

                                <Typography
                                    variant="h4"
                                    style={{ color: theme.palette.text.primary }}
                                    className={styles.tabPanelTitle}
                                >
                                    {titleAbout(wines[i])}
                                </Typography>
                            </Box>

                            <Typography
                                variant="body2"
                                style={{ color: theme.palette.primary.dark, marginLeft: "16px" }}
                                className={styles.tabPanelContent}
                            >
                                {parseHtml(about)}
                            </Typography>
                        </Box>
                    </TabPanel>
                )
            )}
        </Box>
    );
};

AppellationTabs.propTypes = {
    wines: arrayOf(
        shape({
            color: string,
            sweetness: string,
            type: string,
        })
    ),
    wineInformations: arrayOf(
        shape({
            wineName: string,
            aromas: string,
            noseIntensity: number,
            tasteIntensity: number,
            temperature: number,
            attack: string,
            length: string,
            acidity: string,
            storageYear: number,
            about: string,
        })
    ),
};

export default AppellationTabs;
