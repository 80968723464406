import PropTypes from "prop-types";
import { Box, makeStyles } from "@material-ui/core";

import { useGetClassificationOrganismListQuery } from "../generated/graphql";
import Loading from "../Components/Loader/Loading";
import SwiperCards, { SwiperWrapper } from "../Front/SwiperCards";
import theme from "../globalTheme";

import { useParams } from "react-router-dom";
import { t } from "@lingui/macro";
import RankingEntityCard from "./RankingEntityCard";

const useStyles = makeStyles(() => ({
    container_other_rankings: (props) => ({
        justifyContent: "center",
        paddingLeft: "16px",
        paddingRight: "16px",

        [theme.breakpoints.up("xl")]: {
            width: props.titleCenter ? "100%" : "calc(1314px)",
            margin: "auto",
        },
    }),
}));

export const RankingList = (props) => {
    const styles = useStyles(props);
    const { name } = useParams();

    const {
        data: { GetClassificationOrganismList: organismList = [] } = {},
        loading,
    } = useGetClassificationOrganismListQuery({ variables: { name } });

    if (loading) {
        return <Loading />;
    }

    return (
        <Box className={[styles.container_other_rankings, props.boxClassName].join(" ")}>
            <SwiperWrapper
                title={props.title ?? t`Les autres classements bordelais`}
                spaceBetween={15}
                titleCenter={props.titleCenter}
                hideLowBar={props.hideLowBar}
                titleStyle={props.titleStyle}
                noPadding={props.noPadding}
                noMargin={props.noMargin}
                dark
            >
                {organismList?.map((organism, i) => {
                    let key = `ranking-${i}`;
                    return <RankingEntityCard key={key} organism={organism} />;
                })}
            </SwiperWrapper>
        </Box>
    );
};

RankingList.propTypes = {
    title: PropTypes.string,
    titleCenter: PropTypes.bool,
    hideLowBar: PropTypes.bool,
    titleStyle: PropTypes.object,
    noPadding: PropTypes.bool,
    noMargin: PropTypes.bool,
    boxClassName: PropTypes.object,
};
