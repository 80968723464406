import { t } from "@lingui/macro";
import { Box, makeStyles, Typography } from "@material-ui/core";
import Page404 from "Components/ErrorPage/Page404";
import Page500Front from "Components/ErrorPage/Page500Front";
import Helmet from "Components/Helmet/Helmet";
import LoadingWithImage from "Components/Loader/LoadingWithImage";
import AppellationRegionCard from "FrontAppellation/AppellationRegionCard";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import floriture1 from "../assets/appellation/floriture-1.svg";
import floriture2 from "../assets/appellation/floriture-2.svg";
import bg from "../assets/bg-odg.jpg";
import floriture3 from "../assets/odg/floriture3.svg";
import fioriture1 from "../assets/rankingSummary/fioriture1.svg";
import contacts from "../assets/summary/contacts.svg";
import SocialMediaIcons from "../Components/Icons/SocialMediaIcons";
import Loading from "../Components/Loader/Loading";
import ExpandableFormattedTextBox from "../Components/Texts/ExpandableFormattedTextBox";
import FrontContentTop from "../Front/FrontContentTop";
import FrontMainContainer from "../Front/FrontMainContainer";
import { SwiperWrapper } from "../Front/SwiperCards";
import AddressBlock from "../FrontChallenge/AddressBlock";
import ChallengeCarousel from "../FrontChallenge/ChallengeCarousel";
import EstateTitleSemiUnderlined from "../FrontEstate/Components/EstateTitledSemiUnderlined";
import { OdgList } from "../FrontOdg/OdgList";
import OdgSummary from "../FrontOdg/OdgSummary";
import FrontTitle from "../FrontRanking/FrontTitle";
import { useGetAppellationDescriptionsSampleQuery, useGetOdgFrontQuery } from "../generated/graphql";
import theme from "../globalTheme";
import {
    formatAddress,
    formatConcat,
    getTextLength,
    isNotEmpty,
    parseHtml,
    useWindowDimensions,
} from "../helpers/helpers";

const useStyles = makeStyles(() => ({
    container_card: {
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
        },
    },
    container_content_top: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        gap: "26px",

        [theme.breakpoints.up("md")]: {
            marginTop: "-45px",
        },
    },
    container_image_primary_logo: {
        position: "absolute",
        top: "45%",
        [theme.breakpoints.up("lg")]: {
            top: "25%",
        },
        [theme.breakpoints.up("xl")]: {
            top: "45%",
        },
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        zIndex: -1,
    },
    image_primary_logo: {
        width: "100%",
        height: "100%",
    },
    socialMedia: {
        order: 1,
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: "15px",

        [theme.breakpoints.up("lg")]: {
            order: 2,
        },

        [theme.breakpoints.up("xl")]: {
            right: 64,
            top: 42,
        },
    },
    container_others_organization: {
        padding: "0 16px",
        justifyContent: "center",

        [theme.breakpoints.up("xl")]: {
            width: "calc(1314px)",
            margin: "auto",
        },
    },
    container_appellation_region: {
        [theme.breakpoints.up("xl")]: {
            backgroundColor: theme.palette.common.white,
            backgroundImage: `url(${floriture1}), url(${floriture2})`,
            backgroundPositionY: "48px, top",
            backgroundPositionX: "left, right",
            backgroundRepeat: "no-repeat",
        },
    },
    container_appelation_content: {
        justifyContent: "center",
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingBottom: 38,
        background: theme.palette.common.white,

        [theme.breakpoints.up("xl")]: {
            width: "calc(1314px)",
            margin: "auto",
        },
    },
    container_content_secondary: {
        gridColumn: "1 / span 12",

        [theme.breakpoints.up("md")]: {
            gridColumn: "9 / span 4",
            marginTop: "45px",
        },

        [theme.breakpoints.down("sm")]: {
            backgroundImage: `url(${floriture3})`,
            backgroundPositionY: "70%",
            backgroundPositionX: "75%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
        },
    },
    container_content_primary: {
        gridColumn: "1 / span 12",

        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 8",
        },
    },
    container_infos_right: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",

        [theme.breakpoints.up("md")]: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
        },
    },
    center_responsive: {
        gridColumn: "1 / span 6",
        width: "100%",

        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 12",
        },
    },
    contact_image_container: {
        imageContainer: {
            width: "48px",
            height: "48px",
        },
    },
    contact_media: {
        media: {
            width: "100%",
            [theme.breakpoints.only("xs")]: {
                width: "42px",
            },
        },
    },
    content_text: {
        color: theme.palette.primary.dark,

        "& *": {
            textAlign: "justify",
        },

        "& p p": {
            marginBottom: 0,
            marginTop: 0,
        },
    },
    contact_title: {
        width: "100%",
        display: "flex",
        gap: "8px",
        "&:not(:last-child)": {
            marginBottom: "28px",
        },
    },
}));

const OdgPage = () => {
    const styles = useStyles();
    const { width } = useWindowDimensions();
    const { name } = useParams();
    const [currentOdg, setCurrentOdg] = useState(null);
    const locale = useSelector((state) => state?.user?.locale || "fr");

    const { data: { GetOdgFront: odgs = [] } = {}, loading: odgLoading, error: errorOdg } = useGetOdgFrontQuery({
        variables: { name, locale },
    });

    const {
        data: { GetAppellationDescriptionsSample: appellationsSample } = {},
        loading: appellationsLoading,
        error: errorAppellationDescription,
    } = useGetAppellationDescriptionsSampleQuery({
        variables: { locale },
    });

    useEffect(() => {
        if (odgs.length > 0) {
            const currentOdgTmp = odgs[0];
            const { members, appellations } = currentOdgTmp;

            const contacts =
                members?.length > 0
                    ? members.map((member) => {
                          const { role, title, firstName, lastName, address, displayName, ...rest } = member;
                          return {
                              ...rest,
                              title: role,
                              name: displayName ?? formatConcat([title, firstName, lastName]),
                              address: formatAddress(address),
                          };
                      })
                    : [];

            setCurrentOdg({
                ...currentOdgTmp,
                contacts,
                appellations,
            });
        }
    }, [odgs]);

    const breadcrumbs = [
        {
            name: t`Organismes du vignoble bordelais`,
            path: "/organismes-vignoble-bordelais",
        },
        {
            name: t`ODG`,
            path: "/organismes-vignoble-bordelais/ODG",
        },
        {
            name: currentOdg?.name,
        },
    ];

    const getHeight = () => {
        if (width < 360) return 180;
        if (width >= 360 && width < 510) return 150;
        if (width >= 700 && width < 850) return 135;

        return 150;
    };

    const checkIfContactDatasNotEmpty = useMemo(() => {
        const result = currentOdg?.contacts?.filter((e) => e.address || e.email || e.name || e.phone);
        return result ?? [];
    }, [currentOdg]);

    if (odgLoading) {
        return <LoadingWithImage />;
    }

    if (!currentOdg && !odgLoading) {
        return <Page404 />;
    }

    if (errorOdg || errorAppellationDescription) {
        return <Page500Front />;
    }
    return (
        <>
            <Helmet
                title={currentOdg?.name}
                content={`${currentOdg?.name} : découvrez tout sur cet organisme de défense et de gestion sur Bordeaux et ses Vins. Retrouvez les principales informations de contact, les appellations couvertes et leurs typicités...`}
            />
            <FrontContentTop
                backgroundImage={bg}
                links={breadcrumbs}
                previousPage={{
                    name: t`Tous les ODG`,
                    path: "/organismes-vignoble-bordelais/ODG",
                }}
            >
                <FrontTitle className={styles.frontTitle} title={currentOdg?.name} />
                <FrontMainContainer
                    styleCardContent
                    className={styles.main_container}
                    style={{
                        backgroundImage: `url(${fioriture1})`,
                        backgroundPositionY: "bottom",
                        backgroundPositionX: "right",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    <Box className={styles.container_card}>
                        <Box className={styles.socialMedia}>
                            <SocialMediaIcons socialMedias={currentOdg.socialMedias} />
                        </Box>
                        <Box className={styles.container_content_top}>
                            <Box className={styles.container_content_primary}>
                                {isNotEmpty(currentOdg?.description) && (
                                    <EstateTitleSemiUnderlined
                                        title={t`À propos`}
                                        heightAfter={32}
                                        style={{ marginTop: "0px" }}
                                        lowBar={{ width: 75 }}
                                        borderColor={theme.palette.primary.main}
                                    />
                                )}

                                <Box style={{ marginTop: "24px" }}>
                                    {isNotEmpty(currentOdg?.description) && (
                                        <Typography variant="body2" className={styles.content_text}>
                                            {getTextLength(currentOdg?.description) > 1690 ? (
                                                <ExpandableFormattedTextBox
                                                    value={currentOdg?.description}
                                                    truncatedValue={`${currentOdg?.description.slice(0, 1690)}...`}
                                                />
                                            ) : (
                                                parseHtml(currentOdg?.description, "body2")
                                            )}
                                        </Typography>
                                    )}
                                    {checkIfContactDatasNotEmpty.length > 0 && (
                                        <Box>
                                            <Box className={styles.contact_title} style={{ marginTop: "24px" }}>
                                                <Box className={styles.contact_image_container}>
                                                    <img className={styles.contact_media} src={contacts} />
                                                </Box>
                                                <Box>
                                                    <Typography variant="h4">Contacts</Typography>
                                                </Box>
                                            </Box>

                                            {width < 960 ? (
                                                <ChallengeCarousel
                                                    datas={currentOdg.contacts}
                                                    visibleSlides={width < 850 ? 1 : 2}
                                                    type="contacts"
                                                    height={getHeight()}
                                                    width={109}
                                                />
                                            ) : (
                                                <Box style={{ display: "flex", gap: "1rem" }}>
                                                    {currentOdg.contacts?.map((e, i) => (
                                                        <AddressBlock key={i} data={e} width="50%" />
                                                    ))}
                                                </Box>
                                            )}
                                        </Box>
                                    )}
                                </Box>
                            </Box>

                            {currentOdg?.appellations?.length > 0 && (
                                <Box className={styles.container_content_secondary}>
                                    <Box className={styles.container_infos_right}>
                                        <Box
                                            className={styles.center_responsive}
                                            style={{
                                                gridColumn: width <= 700 ? "1 / span 12" : "1 / span 6",
                                            }}
                                        >
                                            <OdgSummary appellations={currentOdg.appellations} />
                                        </Box>

                                        {width >= 960 && (
                                            <Box
                                                className={styles.container_image_primary_logo}
                                                style={{ height: "55%" }}
                                            >
                                                <img
                                                    src={floriture3}
                                                    className={styles.image_primary_logo}
                                                    alt="floriture raisin"
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </FrontMainContainer>

                <Box className={styles.container_others_organization}>
                    <OdgList />
                </Box>
            </FrontContentTop>

            <Box className={styles.container_appellation_region}>
                <Box className={styles.container_appelation_content}>
                    {appellationsLoading ? (
                        <Loading />
                    ) : (
                        <SwiperWrapper
                            spaceBetween={0}
                            boxPaddingTop={4}
                            title={t`Des appellations de la région bordelaise`}
                            dark
                            style="#FFF"
                            noMargin
                        >
                            {appellationsSample?.map((appellation, i) => {
                                let key = `appellation_region-${i}`;
                                return (
                                    <AppellationRegionCard
                                        key={key}
                                        wines={appellation?.wines}
                                        name={appellation?.name}
                                        logo={appellation?.logo}
                                        subregion={appellation?.subregion}
                                    />
                                );
                            })}
                        </SwiperWrapper>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default OdgPage;
