/* eslint-disable no-unused-vars */
import { Box, Divider, Grid, InputAdornment, makeStyles, TextField, Typography } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { AVAILABLE_COUNTRIES } from "config/constants";
import { useWindowDimensions } from "helpers/helpers";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";

import ErrorIcon from "../../assets/Error.svg";
import AutocompleteSelectInput from "../../Components/Inputs/AutocompleteSelectInput";
import SimpleInput from "../../Components/Inputs/SimpleInput";
import theme from "../../globalTheme";
import { getDetailsFromSiret } from "../../helpers/network";
import Tag from "../Chips/Tag";
import Tooltip from "../Tooltips/Tooltip";
import AddressAutocomplete from "./AddressAutocomplete";
import AddressAutocompleteInput from "./AddressAutocompleteInput";
import { AutocompleteCommune } from "./AutocompleteCommune";

const getOnlyNumber = (val) => {
    const onlyNumberCheck = /^\d+$/.test(val);
    return !onlyNumberCheck;
};

const useStyles = makeStyles((theme) => ({
    root: (props) => ({
        backgroundColor: "white",
        "& .MuiInputBase-root": {
            color: theme.palette.primary.dark,
            "&:hover": {
                borderColor: theme.palette.secondary.dark,
            },
        },
        infoText: {
            backgroundColor: "#632D3E",
            color: "white",
            padding: "0.1em 0.7em",
            borderRadius: "5em",
            fontSize: "0.6em",
            fontFamily: "Playfair Display",
            fontStyle: "italic",
            fontWeight: "600",
        },
        "& .MuiFormLabel-root": {
            color: theme.palette.secondary.dark,
            fontSize: "12px",
            top: "-15px",
            paddingTop: (props) => {
                if (props.type === "textarea") {
                    return "16px";
                }
                return "6px";
            },
            "&.Mui-focused": {
                color: theme.palette.primary.dark,
            },
            "&.Mui-error": {
                color: theme.palette.error.main,
            },
        },
        "&.MuiFormControl-root": {
            width: "100%",
        },
        "& .MuiOutlinedInput-root": {
            borderColor: theme.palette.secondary.main,
            paddingLeft: "10px",
            fontSize: "14px",
            borderWidth: "0.2px",
            borderRadius: props.borderRadius ? props.borderRadius : "4px",
            minHeight: props.textareaMinHeight ? props.textareaMinHeight : "34px",
            display: props.textareaMinHeight && "flex",
            alignItems: props.textareaMinHeight && "flex-start",
            "&.Mui-disabled": {
                color: props.table ? theme.palette.primary.dark : theme.palette.secondary.dark,
                backgroundColor: theme.palette.action.disabledBackground,
                borderColor: theme.palette.secondary.dark,
                borderWidth: "0.2px",
            },
            "&:hover fieldset": {
                borderColor:
                    props.error || (props.value && getOnlyNumber(props.value))
                        ? theme.palette.error.main
                        : theme.palette.secondary.main,
            },
            "&.Mui-focused fieldset": {
                borderColor:
                    props.error || (props.value && getOnlyNumber(props.value))
                        ? theme.palette.error.main
                        : theme.palette.secondary.main,
            },
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline legend": {
            fontSize: "0.64em",
        },
        // TABLES
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderRight: props.noBorderRight && "0",
            borderBottom: props.noBorderBottom && "0",
        },
        "& .MuiOutlinedInput-root.Mui-focused ": {
            borderRight: props.noBorderRight && "2px solid",
            borderRightColor: props.noBorderRight && theme.palette.secondary.dark,
            borderBottom: props.noBorderBottom && "2px solid",
            borderBottomColor: props.noBorderBottom && theme.palette.secondary.dark,
        },
        "& .MuiOutlinedInput-root.Mui-focused:hover ": {
            borderRight: props.noBorderRight && "2px solid",
            borderRightColor: props.noBorderRight && theme.palette.secondary.dark,
            borderBottom: props.noBorderBottom && "2px solid",
            borderBottomColor: props.noBorderBottom && theme.palette.secondary.dark,
        },
        "& .MuiOutlinedInput-root:hover": {
            borderRight: props.noBorderRight && "1px solid",
            borderRightColor: props.noBorderRight && theme.palette.secondary.main,
            borderBottom: props.noBorderBottom && "1px solid",
            borderBottomColor: props.noBorderBottom && theme.palette.secondary.main,
        },

        "& .MuiOutlinedInput-input": {
            padding: "0px",
        },
        " & .MuiSelect-select": {
            "&:focus": {
                backgroundColor: theme.palette.common.white,
            },
        },
        "& .MuiOutlinedInput-adornedStart": {
            padding: "0px",
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: (props) => {
                if (props.type === "textarea") {
                    return "translate(14px, -2px) scale(0.75)";
                }
                return "translate(14px, 6px) scale(0.75)";
            },
        },
    }),
    checkIcon: (props) => ({
        width: "20px",
        fill: "#9BBA97",
        marginRight: props.select ? theme.spacing(2) : "0px",
    }),
    falseIcon: (props) => ({
        width: "20px",
        fill: theme.palette.error.main,
        marginRight: props.select ? theme.spacing(2) : "0px",
    }),
    mesure: (props) => ({
        color: theme.palette.secondary.dark,
        marginRight: props.select ? theme.spacing(2) : "0px",
    }),
    divider: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    boxContainer: {
        position: "relative",
    },
    tagsContainer: {
        position: "absolute",
        right: 3,
        top: -10,
        zIndex: 2,
    },
    underText: {
        fontSize: "10px",
        color: theme.palette.secondary.main,
        marginBottom: theme.spacing(1),
    },
    clue: (props) => ({
        fontSize: "12px",
        color: props.error ? theme.palette.error.main : "black",
        marginBottom: theme.spacing(1),
    }),
    addressInputItem: {
        gridColumn: "auto / span 4",
    },
}));

const getTags = (props, styles) => {
    return (
        <Grid container spacing={1}>
            <Grid item>{props.notPublished && <Tag color="secondary" disabled={false} label="Non publié" />}</Grid>
            <Grid item style={{ padding: "6px 4px 2px 4px" }}>
                {props.tooltip && (
                    <Tooltip color="primary" title={props.tooltipTitle} arrow>
                        <Typography
                            style={{
                                backgroundColor: "#632D3E",
                                color: "white",
                                padding: "0.1em 0.7em",
                                borderRadius: "5em",
                                fontSize: "0.6em",
                                fontFamily: "Playfair Display",
                                fontStyle: "italic",
                                fontWeight: "600",
                            }}
                        >
                            {" "}
                            i{" "}
                        </Typography>
                    </Tooltip>
                )}
            </Grid>
        </Grid>
    );
};

const getStatusFromInseeCode = (inseeCode) => {
    const shortCode = inseeCode.slice(0, 2);
    switch (shortCode) {
        case "10":
            return "EI";
        case "22":
            return "SCF";
        case "23":
            return "SEP";
        case "52":
            return "SNC";
        case "53": {
            if (["5306", "5307"].includes(inseeCode)) return "SCS";
            if (["5308", "5309", "5370", "5385"].includes(inseeCode)) return "SCA";
            break;
        }
        case "54": {
            if (inseeCode === "5498") return "EURL";
            return "SARL";
        }
        case "55":
        case "56":
            return "SA";
        case "57": {
            if (inseeCode === "5720") return "SASU";
            return "SAS";
        }
        case "58":
            return "SE";
        case "65": {
            if (inseeCode === "6533") return "GAEC";
            if (inseeCode === "6538") return "GFR";
            if (inseeCode === "6560") return "SC";
            if (inseeCode === "6597") return "SCEA";
            if (inseeCode === "6598") return "EARL";
            if (["6534", "6535"].includes(inseeCode)) return "GFA";
            if (["6540", "6541", "6544"].includes(inseeCode)) return "SCI";
            break;
        }
    }
    return null;
};

const SiretInput = (props) => {
    const styles = useStyles(props);
    const { width } = useWindowDimensions();

    const inputProps = { maxLength: props.maxLength, ...props.inputProps };
    const [focused, setFocused] = useState({
        siretFocusedAtFirst: false,
        zipCodeFocused: false,
        siretFocused: false,
    });

    const getDataFromSiret = async (siret) => {
        if (siret.length !== 14) {
            return false;
        }

        let somme = 0;
        for (let i = 13; i >= 0; i -= 1) {
            const currentValue = siret[13 - i];
            const currentResult = currentValue * (i % 2 === 0 ? 1 : 2);
            if (currentResult >= 10) {
                somme += 1 + currentResult - 10;
            } else {
                somme += currentResult;
            }
        }

        if (somme % 10 === 0) {
            props.setLoading(true);
            const details = await getDetailsFromSiret(siret);
            let companyName = "";
            let tva = "";

            let address = "";
            let complementaryAddress = "";
            let postalCode = "";
            let city = "";
            let country = "";

            if (details) {
                companyName = details.nom_entreprise;
                const statut = getStatusFromInseeCode(details.categorie_juridique);
                tva = details.numero_tva_intracommunautaire;

                if (details.etablissements && details.etablissements.length) {
                    address = details.etablissements[details.etablissements.length - 1].adresse_ligne_1;
                    complementaryAddress = details.etablissements[details.etablissements.length - 1].complement_adresse;
                    postalCode = details.etablissements[details.etablissements.length - 1].code_postal;
                    city = details.etablissements[details.etablissements.length - 1].ville;
                    country = details.etablissements[details.etablissements.length - 1].pays;
                    // Province
                }
                props.companyName?.onChange(companyName);
                props.statut?.onChange(statut);
                props.tva?.onChange(tva);
                props.address?.onChange(address);
                props.extraAddress?.onChange(complementaryAddress);
                props.zipCode?.onChange(postalCode);
                props.onChangeCommune(city);
                props.country?.onChange(country);

                props?.setLoading(false);
            }
            props?.setLoading(false);
            return true;
        }
        return false;
    };

    const handleChange = (e) => {
        props.onChange(e?.target?.value);
        getDataFromSiret(e.target?.value);
    };

    const displayErrorImage = useMemo(
        () => props.falseValue || (props.value && getOnlyNumber(props.value) && focused?.siretFocusedAtFirst),
        [props.falseValue, props.value, focused]
    );

    const onChangeAutocompleteCommune = (e, d) => {
        if (d) {
            props.address.onChange(e?.simple);
            props.onChangeCommune(d?.city);
            props.onChangeCommuneINSEECode(d?.citycode);
            props.zipCode.onChange(d?.postcode);
        } else {
            props.address.onChange(e);
        }
    };

    return (
        <>
            <Grid item sm={4} md={3} lg={3} xl={3}>
                <Box className={styles.boxContainer} style={{ ...props.boxStyle }}>
                    <Box style={{ position: "relative" }}>
                        <Box className={styles.tagsContainer}>{getTags(props, styles)}</Box>
                        <TextField
                            onFocus={(e) => {
                                const newState = {
                                    ...focused,
                                    siretFocused: true,
                                    siretFocusedAtFirst: true,
                                };
                                setFocused(newState);
                            }}
                            onBlur={() => setFocused({ ...focused, siretFocused: false })}
                            label={props.label}
                            placeholder={props.placeholder}
                            type={props.type}
                            select={props.select}
                            variant={props.variant}
                            size={props.size}
                            name={props.name}
                            disabled={props.disabled}
                            required
                            defaultValue={props.defaultValue}
                            onChange={(e) => handleChange(e)}
                            value={props.value || ""}
                            style={{ ...props.style }}
                            className={styles.root}
                            error={
                                focused?.siretFocusedAtFirst &&
                                (props.error || (props.value && getOnlyNumber(props.value)))
                            }
                            inputRef={props.inputRef}
                            InputLabelProps={{ shrink: props.value }}
                            inputProps={inputProps}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {props.endAdornment && props.endAdornment}

                                        {props.correctValue && !getOnlyNumber(props.value) && (
                                            <CheckIcon className={styles.checkIcon} />
                                        )}
                                        {displayErrorImage && <img src={ErrorIcon} className={styles.falseIcon} />}

                                        {props.mesure && (
                                            <Box style={{ display: "flex" }}>
                                                <Divider className={styles.divider} orientation="vertical" flexItem />
                                                <Typography variant="h6" className={styles.mesure}>
                                                    {props.mesure}
                                                </Typography>
                                            </Box>
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        >
                            {props.children}
                        </TextField>
                    </Box>
                    {/* UNDERTEXT */}
                    {props.undertext && (
                        <Typography variant="h6" className={styles.undertext}>
                            {" "}
                            {props.undertext}{" "}
                        </Typography>
                    )}
                    {props.clue &&
                        (focused?.siretFocused || props.error || (props.value && getOnlyNumber(props.value))) && (
                            <Typography
                                variant="h6"
                                className={styles.clue}
                                style={{
                                    color: props.value && getOnlyNumber(props.value) && theme.palette.error.main,
                                }}
                            >
                                {" "}
                                {props.clue}{" "}
                            </Typography>
                        )}
                </Box>
            </Grid>

            <Grid container item sm={6} md={4} lg={4} xl={5}>
                {/* STATUT ET RAISON SOCIALE */}
                <Grid item xs={12} sm={4}>
                    <AutocompleteSelectInput
                        borderRadius="4px 0px 0px 4px"
                        label="Statut"
                        value={props.statut?.value}
                        onChange={(e) => props.statut?.onChange(e)}
                        enumField="LEGAL_STATUS"
                        sorted
                    />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <SimpleInput
                        borderRadius="0px 4px 4px 0px"
                        label="Raison sociale"
                        value={props.companyName?.value}
                        onChange={(e) => props.companyName?.onChange(e?.target?.value)}
                        correctValue={props.companyName?.correctValue}
                        required
                    />
                </Grid>
            </Grid>
            <Grid item sm={4} md={3} lg={3} xl={3}>
                {/* TVA */}
                <SimpleInput
                    label="N° de TVA"
                    value={props.tva?.value}
                    onChange={(e) => props.tva?.onChange(e?.target?.value)}
                    correctValue={props.tva?.correctValue}
                />
            </Grid>

            {width >= 960 ? (
                <AddressAutocomplete
                    style={{ paddingLeft: theme.spacing(2), marginBottom: theme.spacing(2), width: "100%" }}
                    label="Adresse"
                    address={props.address?.value}
                    onChangeAddress={(e, d) => onChangeAutocompleteCommune(e, d)}
                    checkIcon
                    correctValue={props.address}
                    endAdornmentTopValue="14px"
                    extraAddress={props.extraAddress?.value}
                    onChangeExtraAddress={(e) => props.extraAddress?.onChange(e)}
                    postalCode={props.zipCode?.value}
                    onChangePostalCode={(e) => props.zipCode.onChange(e)}
                    commune={props.commune}
                    onChangeCommune={(v) => props.onChangeCommune(v || null)}
                    onChangeCommuneINSEECode={props.onChangeCommuneINSEECode}
                />
            ) : (
                <>
                    <Grid item sm={4} md={3} lg={3} xl={3}>
                        <AddressAutocompleteInput
                            address={props.address?.value}
                            onChange={(e, d) => onChangeAutocompleteCommune(e, d)}
                            label="Adresse"
                            onBlur={(e, d) => onChangeAutocompleteCommune(e, d)}
                            endAdornmentTopVal="14px"
                            correctValue={props.address.value}
                        />
                    </Grid>

                    <Grid item sm={4} md={3} lg={3} xl={3}>
                        <SimpleInput
                            value={props.extraAddress?.value}
                            onChange={(e) => props.extraAddress?.onChange(e?.target?.value)}
                            label="Complément d'adresse"
                            correctValue={props.extraAddress?.value}
                        />
                    </Grid>

                    <Grid item sm={width > 650 ? 4 : 5} md={3} lg={3} xl={3}>
                        <AutocompleteCommune
                            postalCode={props.zipCode?.value}
                            label={"Commune"}
                            commune={props.commune}
                            onChange={({ commune, postalCode, communeINSEEcode }) => {
                                props.onChangeCommune(commune);
                                props.zipCode.onChange(postalCode);
                                props.onChangeCommuneINSEECode(communeINSEEcode);
                                //    props.onChange({ commune, postalCode, communeINSEEcode });
                            }}
                            required
                        />
                    </Grid>
                </>
            )}
            <Grid
                item
                md={3}
                sm={4}
                lg={3}
                xl={3}
                style={{ padding: width < 960 ? 16 : "0px 16px", paddingLeft: theme.spacing(2) }}
            >
                {/* PAYS */}
                <AutocompleteSelectInput
                    label="Pays"
                    placeholder="Choisir..."
                    options={AVAILABLE_COUNTRIES}
                    value={props.country?.value}
                    correctValue={props.country?.value}
                    onChange={(e) => props.country?.onChange(e)}
                    required
                />
            </Grid>
        </>
    );
};

SiretInput.propTypes = {
    translationState: PropTypes.number,

    name: PropTypes.string,
    id: PropTypes.string,
    children: PropTypes.any,
    type: PropTypes.string,
    select: PropTypes.bool,

    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.string,
    onFocus: PropTypes.func,

    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    required: PropTypes.bool,
    helperText: PropTypes.string,

    endAdornment: PropTypes.any,
    label: PropTypes.string,
    placeholder: PropTypes.string,

    style: PropTypes.object,
    boxStyle: PropTypes.object,
    borderRadius: PropTypes.string,
    variant: PropTypes.oneOf(["text", "contained", "outlined"]),
    size: PropTypes.oneOf(["small", "medium"]),

    inputRef: PropTypes.any,

    inputProps: PropTypes.object,
    multiline: PropTypes.bool,
    rows: PropTypes.number,
    maxLength: PropTypes.number,
    minLength: PropTypes.number,
    rowsMax: PropTypes.number,

    undertext: PropTypes.string,
    correctValue: PropTypes.bool,
    mesure: PropTypes.oneOf(["An(s)", "ha", "Pied/ha", "%", "m²", "°C", "hl"]),
    notPublished: PropTypes.bool,
    tooltip: PropTypes.bool,
    tooltipTitle: PropTypes.string,
    // ref: PropTypes.any,

    numberMode: PropTypes.bool,
    falseValue: PropTypes.bool,
    clue: PropTypes.string,

    statut: PropTypes.object,
    companyName: PropTypes.object,
    tva: PropTypes.object,
    country: PropTypes.object,
    province: PropTypes.object,
    address: PropTypes.object,
    extraAddress: PropTypes.object,
    zipCode: PropTypes.object,
    commune: PropTypes.string,
    onChangeCommune: PropTypes.func,
    onChangeCommuneINSEECode: PropTypes.func,
    setLoading: PropTypes.func,
    propertyOwner: PropTypes.bool,
    propertyOperator: PropTypes.bool,
};

SiretInput.defaultProps = {
    onChange: () => {},
    onBlur: () => {},
};

export default SiretInput;
