/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import PropTypes from "prop-types";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { mod, parseHtml } from "../../helpers/helpers";

import subPropImg1 from "../../assets/propriétéPage/propriété_01-féret.png";
import subPropImg2 from "../../assets/propriétéPage/propriété_02_féret.png";
import { makeStyles, Grid, Box, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        overflow: "hidden",
        backgroundColor: "rgba(255, 255, 255, 0)",
    },
    gradientBox: {
        position: "absolute",
        width: "calc(100% - 8px)",
        height: "calc(100% - 8px)",
        zIndex: 10,
        backgroundColor: "rgba(0,0,0,0.5)",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "2px",
    },
    mobilePreviewGradientBox: {
        position: "absolute",
        width: "calc(100% - 1px)",
        height: "calc(100% - 1px)",
        zIndex: 10,
        backgroundColor: "rgba(0,0,0,0.5)",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "2px",
    },
    gradientBoxTypo: {
        fontSize: "38px",
        color: "white",
        textAlign: "center",
        fontWieght: "bold",
    },
    mainImg: {
        objectFit: "cover",
        width: "100%",
        height: "100%",
        "&:hover": {
            cursor: "pointer",
        },
        borderRadius: "2px",
    },
    fourthImg: {
        width: "100%",
        height: "100%",
        "&:hover": {
            cursor: "pointer",
        },
        borderRadius: "2px",
    },
}));

const Gallery = (props) => {
    const styles = useStyles();
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [allImagesIndex, setAllImagesIndex] = useState(0);

    let filteredImagesUrls = [];
    let captions =
        props.imageUrls && props.imageUrls?.length !== 0
            ? [...props.imageUrls]?.map((el, i) => {
                if (el?.caption) {
                    return el?.caption;
                } else {
                    return "";
                }
            })
            : "";

    if (props.front) {
        props.imageUrls?.map((el, i) => {
            filteredImagesUrls?.push(el?.twicpicURL);
        });
    } else {
        filteredImagesUrls = [...props.imageUrls];
    }

    const allImages = [...(filteredImagesUrls || [])];

    if (props.mainImageUrl) {
        allImages.unshift(props.mainImageUrl);
    }

    const getMainImageXsValue = () => {
        if (props.imageUrls?.length === 3 && !props.mobilePreview) {
            return 4;
        } else if (props.mobilePreview) {
            return 12;
        } else {
            return 6;
        }
    };
    const mainImageXsValue = getMainImageXsValue();

    return (
        <Grid container direction={"row"} spacing={1} style={{ width: !props.mobilePreview && "calc(100% + 16px)" }}>
            {lightboxIsOpen && (
                <Lightbox
                    imagePadding={100}
                    mainSrc={allImages.length && allImages[allImagesIndex]}
                    onCloseRequest={() => {
                        setLightboxIsOpen(false);
                    }}
                    nextSrc={allImages[mod(allImagesIndex + 1, allImages?.length)]}
                    prevSrc={allImages[mod(allImagesIndex - 1, allImages?.length)]}
                    onMoveNextRequest={() => setAllImagesIndex(mod(allImagesIndex + 1, allImages?.length))}
                    onMovePrevRequest={() => setAllImagesIndex(mod(allImagesIndex - 1, allImages?.length))}
                    onImageLoadError={(imageSrc, srcType, errorEvent) => { }}
                    imageCaption={parseHtml(captions[allImagesIndex])}
                />
            )}
            {/* Main image */}
            {!!allImages.length && (
                <Grid item xs={mainImageXsValue} style={{ paddingBottom: props.mobilePreview ? 0 : 4 }}>
                    <img className={styles.mainImg} src={allImages[0]} onClick={() => {
                        setLightboxIsOpen(true);
                        setAllImagesIndex(0);
                    }} />
                </Grid>
            )}
            {allImages?.length === 0 ? (
                <Grid item xs={mainImageXsValue} style={{ paddingBottom: props.mobilePreview ? 0 : 4 }}>
                    <img className={styles.mainImg} src={subPropImg1} />
                </Grid>
            ) : null}
            {allImages?.length <= 1 ? (
                <Grid item xs={6}>
                    <img className={styles.mainImg} src={subPropImg2} />
                </Grid>
            ) : null}

            {/* When 3 images: different structure (not for mobile preview) */}
            {!props.mobilePreview && props.imageUrls?.length === 3 ? (
                <>
                    <Grid item xs={props.mobilePreview ? 6 : 4} spacing={0}>
                        <img className={styles.mainImg} src={allImages[1]} onClick={() => {
                            setLightboxIsOpen(true);
                            setAllImagesIndex(1);
                        }} />
                    </Grid>
                    <Grid item xs={props.mobilePreview ? 6 : 4} spacing={0}>
                        <img className={styles.mainImg} src={allImages[2]} onClick={() => {
                            setLightboxIsOpen(true);
                            setAllImagesIndex(2);
                        }} />
                    </Grid>
                </>
            ) : null}

            {/* 4 other images Mobile OU Desktop*/}
            {props.mobilePreview ? (
                <Grid direction="row" item container xs={12} style={{ paddingTop: props.mobilePreview ? 2 : 4 }}>
                    {props.imageUrls?.map((image, i) => {
                        if (i === 0) {
                            return null;
                        }
                        if (i < 5) {
                            if (i === 1 || i === 3) {
                                return (
                                    <Grid
                                        item
                                        key={i}
                                        xs={6}
                                        style={{
                                            paddingRight: "1px",
                                            marginBottom: "2px",
                                        }}
                                    >
                                        <img className={styles.mainImg} src={image} onClick={() => {
                                            setLightboxIsOpen(true);
                                            setAllImagesIndex(i);
                                        }} />
                                    </Grid>
                                );
                            } else if (i === 2) {
                                return (
                                    <Grid
                                        item
                                        key={i}
                                        xs={6}
                                        style={{
                                            paddingLeft: "1px",
                                            marginBottom: "2px",
                                        }}
                                    >
                                        <img className={styles.mainImg} src={image} onClick={() => {
                                            setLightboxIsOpen(true);
                                            setAllImagesIndex(i);
                                        }} />
                                    </Grid>
                                );
                            } else if (i === 4) {
                                return (
                                    <Grid
                                        item
                                        key={i}
                                        xs={6}
                                        style={{
                                            position: "relative",
                                            paddingLeft: "1px",
                                            marginBottom: "2px",
                                        }}
                                    >
                                        {/* Mobile preview gradient box */}
                                        {props.imageUrls?.length > 5 && (
                                            <Box
                                                onClick={() => {
                                                    setLightboxIsOpen(true);
                                                    setAllImagesIndex(i);
                                                }}
                                                className={styles.mobilePreviewGradientBox}
                                            >
                                                <Typography
                                                    variant="h6"
                                                    className={styles.gradientBoxTypo}
                                                    style={props.plusStyle}
                                                >
                                                    +{props.imageUrls?.length - 5}
                                                </Typography>
                                            </Box>
                                        )}
                                        <img className={styles.mainImg} src={image} onClick={() => {
                                            setLightboxIsOpen(true);
                                            setAllImagesIndex(i);
                                        }} />
                                    </Grid>
                                );
                            }
                        } else if (i > 4) {
                            return null;
                        }
                    })}
                </Grid>
            ) : (
                <Grid direction="row" item container xs={6} spacing={1}>
                    {filteredImagesUrls?.map((image, i) => {
                        if (i === 0) {
                            return null;
                        }
                        if (i < 5 && filteredImagesUrls?.length !== 3) {
                            return (
                                <Grid
                                    item
                                    key={i}
                                    xs={filteredImagesUrls?.length === 2 ? 12 : 6}
                                    style={{
                                        position: i === 4 && "relative",
                                        height: filteredImagesUrls?.length > 4 && "50%",
                                    }}
                                >
                                    {/* Gradient box when fourth image */}
                                    {i === 4 && filteredImagesUrls?.length > 5 && (
                                        <Box
                                            onClick={() => {
                                                setLightboxIsOpen(true);
                                                setAllImagesIndex(i);
                                            }}
                                            className={styles.gradientBox}
                                        >
                                            <Typography
                                                variant="h6"
                                                className={styles.gradientBoxTypo}
                                                style={props.plusStyle}
                                            >
                                                +{filteredImagesUrls?.length - 5}
                                            </Typography>
                                        </Box>
                                    )}
                                    <img className={styles.mainImg} src={image} onClick={() => {
                                        setLightboxIsOpen(true);
                                        setAllImagesIndex(i);
                                    }} />
                                </Grid>
                            );
                        } else if (i > 4) {
                            return null;
                        }
                    })}
                </Grid>
            )}
        </Grid>
    );
};

Gallery.propTypes = {
    cols: PropTypes.number,
    imageUrls: PropTypes.arrayOf(PropTypes.string),
    mainImageUrl: PropTypes.string,
    mobilePreview: PropTypes.bool,
    plusStyle: PropTypes.object,
    front: PropTypes.bool,
};

export default Gallery;

// filteredImagesUrls?.length === 3 ? 4 : 6
