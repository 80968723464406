import "pure-react-carousel/dist/react-carousel.es.css";

import { Trans } from "@lingui/macro";
import { Box, makeStyles, Tab, Tabs, Typography } from "@material-ui/core";
import { merge } from "lodash";
import PropTypes, { string } from "prop-types";
import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import theme from "globalTheme";
import { clearStringAccent, formatConcat, urlFormatted, urlize } from "helpers/helpers";
import { useCustomHistory } from "hooks";

const useStyle = makeStyles(() => ({
    mainContainer: (props) => ({
        position: "relative",
        maxWidth: "-webkit-fill-available",
        padding: "20px 8px 8px 8px",
        height: "104px",
        border: "0.5px solid #9F8D93",
        borderRadius: "5px",
        display: "flex",
        alignItems: "center",
        "& .slideInner___2mfX9": {
            display: "flex",
            alignItems: "center",
            width: "140px",
            maxWidth: "140px",
            height: "100px",
            justifyContent: "center",
        },
        "& .horizontalSlider___281Ls": {
            height: "100px",
            border: "0.5px solid",
            borderColor: theme.palette.secondary.main,
            borderRadius: "5px",
            marginRight: props.vintages?.length < 6 ? "0px" : "28px",
            marginLeft: props.vintages?.length < 6 ? "0px" : "28px",
        },
        "& .slideHorizontal___1NzNV": {
            maxWidth: "150px",
        },
        [theme.breakpoints.only("xs")]: {
            height: "80px",
            padding: "16px 0",
        },
        [theme.breakpoints.up("sm")]: {
            height: "   ",
        },
    }),
    carouselSlider: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        "& .MuiTabs-indicator": {
            height: "100%",
            width: "100%",
            opacity: 0.2,
            borderRadius: "5px",
            backgroundColor: theme.palette.primary.dark,
        },
        "& .MuiTabScrollButton-root.Mui-disabled": {
            transitionProperty: "width, opacity",
            transitionDuration: ".8s",
            animationTimingFunction: "cubic-bezier(.59,.01,.39,1)",
            width: "0px",
        },
        "& .MuiTabScrollButton-root": {
            transitionProperty: "width, opacity",
            transitionDuration: ".8s",
            animationTimingFunction: "cubic-bezier(.59,.01,.39,1)",
        },
        "& .MuiTab-wrapper": {
            fontSize: "42px",
            fontFamily: "Playfair Display",
            [theme.breakpoints.only("xs")]: {
                fontSize: "20px",
            },
        },
        "& .MuiTab-textColorInherit.Mui-selected .MuiTab-wrapper": {
            fontSize: "40px",
            [theme.breakpoints.only("xs")]: {
                fontSize: "22px",
            },
        },
        "& .MuiTabs-flexContainer": {
            gap: "8px",
            // justifyContent: "space-evenly",
        },

        "& .MuiTab-root": {
            minWidth: "120px",
        },
        "& .MuiTab-textColorInherit.Mui-selected": {
            color: "#000000",
        },
    },
    title: () => {
        const extra = merge(theme.front.typography.h3.dark_b, {
            [theme.breakpoints.only("xs")]: {
                fontSize: "18px",
                top: "-14px",
            },
        });
        return {
            position: "absolute",
            top: "-20px",
            left: "16px",
            display: "flex",
            alignItems: "center",
            backgroundColor: "white",
            zIndex: 10,
            overflow: "visible",
            ...extra,
        };
    },
    slide: {
        color: "#D0BDC3",
    },
    tabCuveeVersion__title: {
        fontFamily: "Playfair Display",
        fontSize: "40px",
        [theme.breakpoints.only("xs")]: {
            fontSize: "20px",
        },
    },
    tabCuveeVersion__cuvee: {
        fontSize: "14px",
        fontFamily: "Lato",
        textTransform: "lowercase",
        [theme.breakpoints.only("xs")]: {
            fontSize: "12px",
        },
    },
}));

const VintageCarousel = (props) => {
    const styles = useStyle(props);
    const { year, cuvee } = useParams();
    const { wineEntityShortId, propertyName, wineShortId, wineName } = useParams();
    const history = useCustomHistory();

    const urlizeVintage = (year, cuvee) => {
        const formattedCuvvee = cuvee ? urlize(cuvee) : "";
        return clearStringAccent(formatConcat([year, formattedCuvvee], "-"));
    };

    const currentTab = year ? urlizeVintage(year, cuvee) : props.wineId;

    const getShortCuvee = (cuvee) => (cuvee?.length > 14 ? cuvee?.substr(0, 14) + "..." : cuvee);

    const wineUrl = useMemo(() => {
        const wineEntityPart = urlFormatted(wineEntityShortId, propertyName);
        const winePart = urlFormatted(wineShortId, wineName);
        return history.addLocale(`/${wineEntityPart}/vins/${winePart}`);
    });

    return (
        <Box className={styles.mainContainer}>
            <Typography variant="h4" className={styles.title}>
                <Trans>En savoir plus sur...</Trans>
            </Typography>
            <Tabs className={styles.carouselSlider} scrollButtons="on" variant="scrollable" value={currentTab}>
                <Tab
                    component={Link}
                    to={wineUrl}
                    style={{ display: "flex", alignItems: "flex-start" }}
                    className={styles.slide}
                    value={props.wineId}
                    label={
                        <Box>
                            <Typography className={styles.tabCuveeVersion__title}>
                                <Trans>Le vin</Trans>
                            </Typography>
                        </Box>
                    }
                />
                {props.vintages?.map(({ year: vYear, cuvee: vCuvee }, i) => (
                    <Tab
                        style={{ flex: 1 }}
                        key={i}
                        index={i}
                        value={urlizeVintage(vYear, vCuvee)}
                        label={
                            <Box>
                                <Typography className={styles.tabCuveeVersion__title}>{vYear}</Typography>
                                <Typography className={styles.tabCuveeVersion__cuvee}>
                                    {vCuvee ? getShortCuvee(vCuvee) : <br />}
                                </Typography>
                            </Box>
                        }
                        component={Link}
                        to={`${wineUrl}/${urlizeVintage(vYear, vCuvee)}`}
                        className={styles.slide}
                    />
                ))}
            </Tabs>
        </Box>
    );
};

VintageCarousel.propTypes = {
    vintages: PropTypes.array,
    wineId: string,
};

export default VintageCarousel;
