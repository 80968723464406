import cn from "clsx";
import { Fragment, useState } from "react";
import { array, number, string } from "prop-types";
import { Box, makeStyles, Typography } from "@material-ui/core";

import barcodeImage from "../../assets/barcode.svg";
import Tooltip from "../../Components/Tooltips/Tooltip";
import { t, Trans } from "@lingui/macro";
import addToCard from "../../assets/Wine/product_url.svg";
import { firstLetterUppercase } from "helpers/helpers";
import GTINPopin from "FrontVintage/Components/GTINPopin";
import globalTheme from "globalTheme";

const defaultColor = "#360318";

const COL_NUMBER = 5;

const gridBreakpoint = "sm";

const gridItems = {};
for (let i = 0; i < COL_NUMBER; i++) {
    const order = i + 1;
    gridItems[`order_${order}`] = {
        gridColumnStart: order,
        [globalTheme.breakpoints.down(gridBreakpoint)]: { gridColumnStart: (i % 3) + 1 },
    };
}

const useStyles = makeStyles((theme) => ({
    price__box__typoSecond: {
        display: "flex",
        alignItems: "center",
        whiteSpace: "nowrap",
        fontSize: "16px",
        color: "#360318",
        fontWeight: "bold",
        [theme.breakpoints.only("xs")]: {
            fontSize: "12px",
        },
    },
    packagingContainer: {
        width: "100%",
        display: "grid",
        marginBottom: "4px",
        color: theme.palette.primary.light,
        gap: "8px",
        "&>*": {
            margin: "auto 0",
        },
    },
    conditionning: ({ color = defaultColor }) => ({
        display: "flex",
        flexWrap: "nowrap",
        gap: "4px",
        "&>*": {
            whiteSpace: "nowrap",
            color,
            fontSize: "16px",
            [globalTheme.breakpoints.only("xs")]: {
                fontSize: "12px",
            },
        },
    }),
    ...gridItems,
}));

export const DisplayPackaging = ({ packagings, wine, cuvee, year, color, limit }) => {
    const styles = useStyles({ color });
    const [GTINPopinOpen, setGTINPopinOpen] = useState(false);

    return (
        <>
            {GTINPopinOpen && (
                <GTINPopin
                    modalOpen={GTINPopinOpen}
                    handleClose={() => setGTINPopinOpen(false)}
                    values={GTINPopinOpen}
                    wine={wine}
                    cuvee={cuvee}
                    year={year}
                />
            )}
            <Box className={styles.packagingContainer}>
                {packagings?.length > 0 &&
                    packagings.map(
                        (packaging, i) =>
                            packaging?.centilisation &&
                            (!limit || i < limit) && (
                                <Fragment key={i}>
                                    <img
                                        src={packaging?.icon?.data}
                                        alt="img"
                                        className={styles.order_1}
                                        style={{ height: "40px" }}
                                    />

                                    <Box className={cn(styles.conditionning, styles.order_2)}>
                                        {packaging?.conditionningMode && (
                                            <Typography>
                                                {firstLetterUppercase(packaging?.conditionningMode)} •
                                            </Typography>
                                        )}
                                        <Typography>
                                            {packaging?.centilisation &&
                                                `${firstLetterUppercase(packaging?.centilisation)}`}
                                        </Typography>
                                        <Typography>{packaging?.units && `x ${packaging?.units}`}</Typography>
                                    </Box>
                                    <Typography className={cn(styles.price__box__typoSecond, styles.order_3)}>
                                        {packaging?.price && `${packaging?.price} €`}
                                    </Typography>

                                    {packaging?.gtin && (
                                        <Box className={styles.order_4} style={{ display: "flex" }}>
                                            <Tooltip color="primary" title={t`Voir le GTIN`} fontSize="14px" arrow>
                                                <img
                                                    src={barcodeImage}
                                                    alt="img"
                                                    onClick={() => setGTINPopinOpen(packaging)}
                                                    style={{
                                                        height: "22px",
                                                        width: "35px",
                                                        cursor: "pointer",
                                                    }}
                                                />
                                            </Tooltip>
                                        </Box>
                                    )}
                                    {packaging?.url && (
                                        <Box className={styles.order_5} style={{ display: "flex" }}>
                                            <Tooltip
                                                color="primary"
                                                title={
                                                    <Typography variant="h6">
                                                        <Trans>Acheter ce produit</Trans>
                                                    </Typography>
                                                }
                                                arrow
                                            >
                                                <img
                                                    src={addToCard}
                                                    alt="img"
                                                    onClick={() =>
                                                        window.open(packaging.url, "_blank", "noopener,noreferrer")
                                                    }
                                                    style={{
                                                        height: "22px",
                                                        width: "35px",
                                                        cursor: "pointer",
                                                    }}
                                                />
                                            </Tooltip>
                                        </Box>
                                    )}
                                </Fragment>
                            )
                    )}
            </Box>
        </>
    );
};

DisplayPackaging.propTypes = {
    packagings: array,
    limit: number,
    wine: string,
    cuvee: string,
    year: string,
    color: string,
};
