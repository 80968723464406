function SvgPinterest() {
  return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
          <path
            d="M12 0C5.37281 0 0 5.37256 0 12C0 18.6274 5.37281 24 12 24C18.6272 24 24 18.6274 24 12C24 5.37256 18.6272 0 12 0ZM13.3808 15.9858C12.2708 15.9858 11.2268 15.3858 10.8694 14.7045C10.8694 14.7045 10.2722 17.0725 10.1468 17.5295C9.70165 19.145 8.39227 20.761 8.29037 20.8934C8.21942 20.9855 8.06253 20.9567 8.04632 20.8345C8.01858 20.6287 7.68417 18.5906 8.0775 16.9284C8.27417 16.0936 9.39916 11.327 9.39916 11.327C9.39916 11.327 9.07163 10.6707 9.07163 9.70042C9.07163 8.17694 9.95454 7.03967 11.054 7.03967C11.9887 7.03967 12.44 7.74163 12.44 8.58304C12.44 9.52291 11.8419 10.9283 11.5333 12.2305C11.2752 13.3209 12.0795 14.2105 13.1549 14.2105C15.1027 14.2105 16.4135 11.7091 16.4135 8.74484C16.4135 6.4924 14.8972 4.80565 12.137 4.80565C9.01958 4.80565 7.07552 7.13101 7.07552 9.7289C7.07552 10.6241 7.34019 11.2563 7.75415 11.7447C7.94394 11.9693 7.97095 12.0597 7.90195 12.3175C7.85211 12.5068 7.73991 12.962 7.69228 13.1419C7.62378 13.4027 7.41312 13.4955 7.17717 13.3995C5.74183 12.8134 5.07253 11.2406 5.07253 9.47257C5.07253 6.55255 7.5349 3.05113 12.4191 3.05113C16.3438 3.05113 18.927 5.89086 18.927 8.93979C18.9272 12.9733 16.6848 15.9858 13.3808 15.9858Z"
            fill="#632D3E"
          />
      </svg>
  );
}

export default SvgPinterest;
