import { useSelector } from "react-redux";

import { calculateTTC, calculateTVA, getUserLocale, round } from "helpers/helpers";

export const usePrice = ({ ht, currency = "EUR" }) => {
    const locale = useSelector(getUserLocale);
    const intl = new Intl.NumberFormat(locale, { style: "currency", currency, minimumFractionDigits: 0 });
    const htRounded = round(ht);
    const ttc = calculateTTC(htRounded);
    const htPrice = intl.format(htRounded);
    const ttcPrice = intl.format(ttc);

    const htPriceSuffixed = htPrice + " HT";
    const ttcPriceSuffixed = ttcPrice + " TTC";
    const tva = calculateTVA(htRounded);
    const tvaPrice = intl.format(tva);

    return {
        ht: htRounded,
        ttc,
        tva,
        htPrice,
        ttcPrice,
        tvaPrice,
        htPriceSuffixed,
        ttcPriceSuffixed,
    };
};
