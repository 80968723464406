// Setup:
import React from "react";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "./cellarSwipe.css";
import { Box } from "@material-ui/core";

// Styles:
import useStyles from "./SwipeMembers.style";

SwiperCore.use([Pagination]);

const SwipeMembers = (props) => {
    const styles = useStyles();

    return (
        <Box className={styles.swiper__wrapper}>
            <Swiper pagination slidesPerView={props.slidesPerView ?? "auto"} spaceBetween={30}>
                {props.cellarsData.map((el, key) => {
                    return <SwiperSlide key={key}>{el}</SwiperSlide>;
                })}
            </Swiper>
        </Box>
    );
};

SwipeMembers.propTypes = {
    cellarsData: PropTypes.object,
    slidesPerView: PropTypes.any
};

export default SwipeMembers;
