import { bool, node, number } from "prop-types";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { Plural, Trans } from "@lingui/macro";

import noResultImg from "assets/searchEngine/no-result.svg";
import Arrow from "Components/Arrow/Arrow";
import { useWindowDimensions } from "helpers/helpers";
import { LoadWrapper } from "Dashboard/Components/LoadWrapper/LoadWrapper";
import theme from "globalTheme";

const useStyles = makeStyles(() => ({
    noResultImageBox: {
        gridColumn: "1 / span 18",
        position: "relative",
        display: "flex",
        alignItems: "flex-start",

        [theme.breakpoints.up("sm")]: {
            minHeight: 130,
            alignItems: "flex-end",
        },

        [theme.breakpoints.up("md")]: {
            minHeight: 170,
            alignItems: "flex-end",
            marginBottom: 24,
        },
    },
    noResultImg: {
        display: "none",

        [theme.breakpoints.up("sm")]: {
            display: "block",
            position: "absolute",
            width: 96,
            top: 0,
        },

        [theme.breakpoints.up("md")]: {
            width: 130,
            left: 77,
        },

        [theme.breakpoints.up("xl")]: {
            width: 145,
            left: 77,
        },
    },
    noResultTypoBox: {
        background: "#FCF6F6",
        padding: "13px 16px",
        width: "100%",

        [theme.breakpoints.up("sm")]: {
            padding: "17px 16px 26px 96px",
        },

        [theme.breakpoints.up("md")]: {
            padding: "22px 16px 22px",
            paddingLeft: "calc(96px + 130px)",
            marginBottom: 10,
        },

        [theme.breakpoints.up("xl")]: {
            padding: "22px 16px 22px",
            paddingLeft: "calc(96px + 145px)",
            marginBottom: 0,
        },
    },
    noResultTitle: {
        color: theme.palette.secondary.dark,
        width: "100%",
        textAlign: "left",

        [theme.breakpoints.up("sm")]: {
            maxWidth: 536,
        },

        [theme.breakpoints.up("md")]: {
            maxWidth: "90%",
        },

        [theme.breakpoints.up("lg")]: {
            maxWidth: "70%",
        },

        [theme.breakpoints.up("xl")]: {
            maxWidth: "70%",
        },
    },
    noResultContentBox: {
        gridColumn: "1 / span 18",
        display: "flex",
        flexDirection: "column",
        marginTop: 19,

        [theme.breakpoints.up("sm")]: {
            gridColumn: "1 / span 18",
            gridRow: "5 / span 1",
        },

        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 13",
            gridRow: "5 / span 1",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 10",
            gridRow: "5 / span 1",
        },
    },
    noResultContent: {
        color: theme.palette.common.black,
        fontWeight: 700,
    },
}));

export const NoMatchingResult = ({ display, displaySimilar, loading, resultCount, similarLabel }) => {
    const styles = useStyles();
    const { width } = useWindowDimensions();

    if (!display) return null;

    const similarLabelComponent = () => (
        <Typography
            variant="h6"
            style={{
                fontFamily: "Mansalva",
                color: theme.palette.primary.dark,
            }}
        >
            {similarLabel}
        </Typography>
    );

    return (
        <LoadWrapper loading={loading} style={{ gridColumn: "1 / span 18" }}>
            <Box className={styles.noResultImageBox}>
                <img src={noResultImg} className={styles.noResultImg} alt="image aucun résultat" />
                <Box className={styles.noResultTypoBox}>
                    <Typography variant="h3" className={styles.noResultTitle}>
                        <Trans>Aucun résultat respectant tous vos critères de recherche n’a été trouvé.</Trans>
                    </Typography>
                </Box>
            </Box>

            {displaySimilar && (
                <Box className={styles.noResultContentBox}>
                    <Typography variant="h4" className={styles.noResultContent}>
                        <Plural
                            value={resultCount}
                            one="Découvrez le résultat qui correspond au plus près à votre recherche"
                            other="Découvrez les résultats qui correspondent au plus près à votre recherche"
                        />
                    </Typography>
                    {resultCount > 1 &&
                        (width > 600 ? (
                            <Arrow
                                rotation="105"
                                arrowPosition="start"
                                style={{ height: "100%", marginLeft: "0" }}
                                icon="primary"
                                textBoxStyle={{
                                    marginLeft: 0,
                                    marginRight: "8px",
                                    marginTop: "2px",
                                }}
                            >
                                {similarLabelComponent()}
                            </Arrow>
                        ) : (
                            similarLabelComponent()
                        ))}
                </Box>
            )}
        </LoadWrapper>
    );
};

NoMatchingResult.propTypes = {
    display: bool,
    displaySimilar: bool,
    loading: bool,
    resultCount: number,
    similarLabel: node,
};
