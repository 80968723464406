import React from "react";

import { makeStyles, Grid, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    baseCard: (props) => ({
        border: props.disabledCard ? "1px dashed #535353" : "none",
        // margin: "auto",
        borderRadius: "20px",
        padding: theme.spacing(3),
        [theme.breakpoints.up("sm")]: {
            width: "100%",
        },
        [theme.breakpoints.up("md")]: {},
        [theme.breakpoints.up("lg")]: {
            maxWidth: "272px",
        },
        [theme.breakpoints.up("xl")]: {},
    }),
    mediaContainer: {
        display: "flex",
        margin: "auto",
    },
    media: {
        maxWidth: "100%",
        maxHeight: "100%",
        margin: "auto",
    },
    card_content: {
        [theme.breakpoints.up("xs")]: {
            flexDirection: "column",
        },
        [theme.breakpoints.up("lg")]: {
            flexDirection: "row",
        },
    },
}));

const SubscriptionCards = (props) => {
    const styles = useStyles(props);
    return (
        <Card className={styles.baseCard}>
            <Box className={styles.cardActionArea}>
                <Grid container spacing={2} className={styles.card_content}>
                    <Grid item>
                        <Typography style={{ textAlign: "left" }} variant="h4" align="center">
                            {props.cardTitle}
                        </Typography>
                        {props.extraContent ?? null}
                    </Grid>
                    {props.children}
                </Grid>
            </Box>
        </Card>
    );
};

SubscriptionCards.propTypes = {
    cardTitle: PropTypes.string,
    disabledCard: PropTypes.bool,
    children: PropTypes.any,
    extraContent: PropTypes.any,
};

export default SubscriptionCards;
