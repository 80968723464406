import { Accordion, AccordionDetails, AccordionSummary, Box, Link, Typography, makeStyles } from "@material-ui/core/";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { urlFormatted } from "helpers/helpers";
import { useCustomHistory } from "hooks";
import PropTypes from "prop-types";
import { useState } from "react";
import star from "../../assets/rankingSummary/stars.svg";
import theme from "../../globalTheme";

const useStyles = makeStyles(() => ({
    root: {
        width: "100%",
        padding: "0px",
    },
    accordion: {
        padding: "0px",
        background: "transparent",
        border: "none",
        boxShadow: "none",
        "& .Mui-expanded": {
            marginTop: "0px",
        },
        "&:before": {
            height: 0,
        },
    },
    accordion_content_container: {
        background: theme.action.disabledBackground,
        borderRadius: "4px",
        padding: "15px 8px 12px",
        width: "100%",
    },
    accordion_title: {
        color: theme.palette.common.black,
        marginRight: theme.spacing(1),
    },
    accordion_content_title: {
        fontFamily: theme.typography.fontFamily,
    },
    accordion_summary: {
        justifyContent: "flex-start",
        padding: "0px",

        "&.Mui-expanded": {
            minHeight: 48,
        },

        "& .MuiAccordionSummary-content": {
            flexGrow: 0,
            marginTop: 0,
            marginBottom: 0,
        },

        "& .MuiIconButton-root": {
            padding: 0,
            marginTop: 3,
            color: theme.palette.primary.dark,
        },
    },
    accordion_details: {
        flexWrap: "wrap",
        padding: "0px",
    },
    icon: {
        width: "20px",
        height: "20px",
    },
}));

const RankingCmsAccordion = (props) => {
    const styles = useStyles(props);
    const history = useCustomHistory();
    const [expanded, setExpanded] = useState("panel1");

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <Box className={styles.root}>
            {props?.datas?.map((data, i) => (
                <Accordion
                    key={i}
                    className={styles.accordion}
                    expanded={expanded === `panel${i + 1}`}
                    onChange={handleChange(`panel${i + 1}`)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel-ranking-${i}`}
                        id={`panel-ranking-header-${i}`}
                        className={styles.accordion_summary}
                    >
                        <Box style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                            <img src={star} className={styles.icon} style={{ opacity: 1 }} />
                            <Typography variant="h4" className={styles.accordion_title}>
                                <span>{data.classificationGrade} :</span> {data.number}
                            </Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails className={styles.accordion_details}>
                        <Box className={styles.accordion_content_container}>
                            {data?.classified?.map((content) => (
                                <Box key={content?.id} style={{ marginBottom: "10px" }}>
                                    <Typography
                                        variant="h4"
                                        className={styles.accordion_content_title}
                                        style={{
                                            color: theme.palette.primary.light,
                                            marginBottom: 10,
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        {content.appellationLabel}
                                    </Typography>
                                    <Box style={{ display: "flex", flexWrap: "wrap", alignItems: "baseline" }}>
                                        {content?.wineEntities.map((wineEntity, i) => {
                                            return (
                                                <Box
                                                    key={wineEntity.wineEntityId}
                                                    style={{ display: "flex", alignItems: "baseline" }}
                                                >
                                                    <Link
                                                        href={
                                                            wineEntity.shortId &&
                                                            `/${urlFormatted(wineEntity.shortId, wineEntity.name)}`
                                                        }
                                                        variant="body2"
                                                        style={{
                                                            textTransform: "uppercase",
                                                            cursor: wineEntity.shortId && "pointer",
                                                            textDecoration: "none",
                                                        }}
                                                    >
                                                        {wineEntity.name}
                                                    </Link>
                                                    {i < content?.wineEntities?.length - 1 && (
                                                        <span style={{ margin: "0 0.3rem" }}>-</span>
                                                    )}
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
};

RankingCmsAccordion.propTypes = {
    datas: PropTypes.object,
};

export default RankingCmsAccordion;
