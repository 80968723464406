import React from "react";
import { makeStyles, Accordion, AccordionSummary, AccordionDetails, Typography, Box } from "@material-ui/core/";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PropTypes from "prop-types";
import theme from "../../globalTheme";
import star from "../../assets/rankingSummary/stars.svg";
import location from "../../assets/appellation/location.svg";
import grape from "../../assets/appellation/grape.svg";
import place from "../../assets/appellation/bright_place.svg";
import reference from "../../assets/post/ref.svg";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        padding: "0px",
    },
    accordion: {
        padding: "0px",
        background: "transparent",
        border: "none",
        boxShadow: "none",

        "& .Mui-expanded": {
            marginTop: "0px",
        },
        "&:before": {
            height: 0,
        },
    },
    accordion_content_container: {
        background: theme.action.disabledBackground,
        borderRadius: "4px",
        padding: "15px 8px 12px",
        width: "100%",
    },
    accordion_container_items: {
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap",
    },
    accordion_container_item_single: (props) => ({
        marginBottom: props?.itemMarginBottom ?? "6px",
        marginRight: props?.itemMarginRight ?? "5px",
        borderRadius: "4px",
        padding: props?.itemPadding ?? "4px",
    }),
    accordion_title: {
        // ...theme.front.typography.h6.dark_b,
        color: theme.palette.common.black,
        marginRight: theme.spacing(1),
    },
    accordion_content_title: {
        fontFamily: theme.typography.fontFamily,
        // ...theme.front.typography.label.darkLight,
    },
    accordion_summary: {
        justifyContent: "flex-start",
        padding: "0px",

        "&.Mui-expanded": {
            minHeight: 48,
        },

        "& .MuiAccordionSummary-content": {
            flexGrow: 0,
            marginTop: 0,
            marginBottom: 0,
        },

        "& .MuiIconButton-root": {
            padding: 0,
            marginTop: 3,
            color: theme.palette.primary.dark,
        },
    },
    accordion_details: {
        flexWrap: "wrap",
        padding: "0px",
    },
    icon: (props) => ({
        marginRight: "5px",
        marginTop: "3px",
        width: props?.iconStyle?.width ?? "20px",
        height: props?.iconStyle?.height ?? "20px",
    }),
    accordion_description: {
        color: theme.palette.primary.dark,
    },
}));

const CmsAccordion = (props) => {
    const styles = useStyles(props);

    const [expanded, setExpanded] = React.useState(props?.panelNotActive ? null : "panel1");

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const getItemBackground = (type) => {
        if (type === "rouge") {
            return theme.palette.primary.light;
        } else if (type === "blanc") {
            return "#E9EBB7";
        } else {
            return theme.palette.common.white;
        }
    };

    const getItemColor = (type) => {
        if (type === "rouge") {
            return theme.palette.common.white;
        } else if (type === "blanc") {
            return theme.palette.primary.dark;
        } else {
            return theme.palette.primary.light;
        }
    };

    const getIconAccordion = () => {
        switch (props.type) {
            case "cepage":
                return grape;
            case "geography":
                return location;
            case "proximityArea":
                return place;
            case "postReferences":
                return reference;
            default:
                return star;
        }
    };

    return (
        <Box className={styles.root}>
            {props.type === "geography" ||
                props.type === "ranking" ||
                props.type === "proximityArea" ||
                (props.type === "postReferences" &&
                    props?.datas?.map((e, i) => (
                        <Accordion
                            key={i}
                            className={styles.accordion}
                            expanded={expanded === `panel${i + 1}`}
                            onChange={handleChange(`panel${i + 1}`)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className={styles.accordion_summary}
                            >
                                <Box style={{ display: "flex", alignItems: "center" }}>
                                    <img
                                        src={getIconAccordion()}
                                        className={styles.icon}
                                        style={{ opacity: e.title === "Les cépages accessoires" ? 0.5 : 1 }}
                                    />
                                    <Typography variant="h4" className={styles.accordion_title}>
                                        {e.title ?? props.title} {e.number ? `: ${e.number}` : ""}{" "}
                                        {props.subtitle ?? ""}
                                    </Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails className={styles.accordion_details}>
                                {(props.type === "geography" ||
                                    props.type === "proximityArea" ||
                                    props.type === "postReferences") && (
                                        <>
                                            {e?.description && (
                                                <Box style={{ marginBottom: "8px" }}>
                                                    <Typography variant="body2" className={styles.accordion_description}>
                                                        {e.description}
                                                    </Typography>
                                                </Box>
                                            )}

                                            <Box className={styles.accordion_container_items}>
                                                {e?.content?.map((e, i) => (
                                                    <Box
                                                        key={i}
                                                        className={styles.accordion_container_item_single}
                                                        style={{
                                                            background: getItemBackground(e.type),
                                                            border: props.borderBox ?? "none",
                                                        }}
                                                    >
                                                        <Typography
                                                            variant={props.type === "postReferences" ? "h6" : "body2"}
                                                            className={styles.accordion_content_title}
                                                            style={{
                                                                color: e.type
                                                                    ? getItemColor(e.type)
                                                                    : theme.palette.primary.main,
                                                            }}
                                                        >
                                                            {e.name ?? e}
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                        </>
                                    )}


                            </AccordionDetails>
                        </Accordion>
                    )))}

            {props.type === "cepage" &&
                props?.datas?.map((e, i) => (
                    <Box key={i} style={{ marginBottom: "23px" }}>
                        <Box style={{ display: "flex", alignItems: "center", marginBottom: "12px" }}>
                            <img
                                src={getIconAccordion()}
                                className={styles.icon}
                                style={{ opacity: e.title === "Les cépages accessoires" ? 0.5 : 1 }}
                            />
                            <Typography variant="h4" className={styles.accordion_title}>
                                {e.title} : {e.number} {props.subtitle ?? ""}
                            </Typography>
                        </Box>

                        <Box className={styles.accordion_container_items}>
                            {e?.content?.map((e, i) => (
                                <Box
                                    key={i}
                                    className={styles.accordion_container_item_single}
                                    style={{
                                        background: getItemBackground(e.type),
                                        border: props.borderBox ?? "none",
                                    }}
                                >
                                    <Typography
                                        variant="body2"
                                        className={styles.accordion_content_title}
                                        style={{ color: getItemColor(e.type) }}
                                    >
                                        {e.name ?? e}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                ))}

            {props.type === "ranking" && props?.datas?.map((e, i) => (
                <Accordion
                    key={i}
                    className={styles.accordion}
                    expanded={expanded === `panel${i + 1}`}
                    onChange={handleChange(`panel${i + 1}`)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={styles.accordion_summary}
                    >
                        <Box style={{ display: "flex", alignItems: "center" }}>
                            <img
                                src={getIconAccordion()}
                                className={styles.icon}
                                style={{ opacity: e.title === "Les cépages accessoires" ? 0.5 : 1 }}
                            />
                            <Typography variant="h4" className={styles.accordion_title}>
                                {e.classificationGrade ?? props.title} {e.number ? `: ${e.number}` : ""}{" "}
                                {props.subtitle ?? ""}
                            </Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails className={styles.accordion_details}>
                        {(props.type === "geography" ||
                            props.type === "proximityArea" ||
                            props.type === "postReferences") && (
                                <>
                                    {e?.description && (
                                        <Box style={{ marginBottom: "8px" }}>
                                            <Typography variant="body2" className={styles.accordion_description}>
                                                {e.description}
                                            </Typography>
                                        </Box>
                                    )}

                                    <Box className={styles.accordion_container_items}>
                                        {e?.content?.map((e, i) => (
                                            <Box
                                                key={i}
                                                className={styles.accordion_container_item_single}
                                                style={{
                                                    background: getItemBackground(e.type),
                                                    border: props.borderBox ?? "none",
                                                }}
                                            >
                                                <Typography
                                                    variant={props.type === "postReferences" ? "h6" : "body2"}
                                                    className={styles.accordion_content_title}
                                                    style={{
                                                        color: e.type
                                                            ? getItemColor(e.type)
                                                            : theme.palette.primary.main,
                                                    }}
                                                >
                                                    {e.name ?? e}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                </>
                            )}

                        {props.type === "ranking" && (
                            <Box className={styles.accordion_content_container}>
                                {e?.classified?.map((content) => (
                                    <Box key={content?.id} style={{ marginBottom: "10px" }}>
                                        <Typography
                                            variant="h4"
                                            className={styles.accordion_content_title}
                                            style={{ color: theme.palette.primary.light, marginBottom: 10 }}
                                        >
                                            {content.appellationLabel}
                                        </Typography>

                                        <Typography variant="body2" style={{ textTransform: "uppercase" }}>
                                            {content?.appellationLabel}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        )}
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
};

CmsAccordion.propTypes = {
    datas: PropTypes.object,
    icon: PropTypes.string,
    iconStyle: PropTypes.object,
    type: PropTypes.string,
    subtitle: PropTypes.string,
    borderBox: PropTypes.string,
    title: PropTypes.string,
    panelNotActive: PropTypes.bool,
    itemMarginBottom: PropTypes.number,
    itemMarginTop: PropTypes.number,
    itemPadding: PropTypes.number,
};

export default CmsAccordion;
