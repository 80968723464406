import { FormControl, MenuItem, Select, makeStyles, Typography } from "@material-ui/core";
import { array, func, number, object, string } from "prop-types";
import { useState } from "react";
import { Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
    select: {
        fontSize: theme.typography.h6.fontSize,
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.08)",
        backgroundColor: theme.palette.dashboard.background,
        width: "100%",
        height: "34px",
        color: theme.palette.primary.dark,
        borderRadius: 4,
        padding: "0px",
        border: "none",

        "& .MuiSelect-iconOutlined": {
            fill: theme.palette.primary.dark,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: 0,
        },
        "&.Mui-focused fieldset": {
            borderColor: `${theme.palette.dashboard.background} !important`,
            borderWidth: "0px !important",
        },

        "&:focus": {
            border: "none",
            backgroundColor: "transparent",
        },

        "&:before": {
            borderBottom: "none",
        },

        "&:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
        },

        "& .MuiTypography-root": {
            paddingLeft: 10,
        },
    },
    outlinedSelect: {
        display: "flex",
        "&:focus": {
            background: "none",
        },
    },
    paper: {
        color: theme.palette.primary.dark,
        "& .Mui-selected": {
            backgroundColor: theme.palette.dashboard.background,
        },
        "& .Mui-selected:hover": {
            backgroundColor: theme.palette.dashboard.background,
        },
    },
}));

const SelectInputFilter = (props) => {
    const styles = useStyles(props);

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <FormControl className={props.className}>
            <Select
                variant="outlined"
                className={styles.select}
                classes={{
                    root: styles.selectRoot,
                    outlined: styles.outlinedSelect,
                }}
                value={props?.value}
                onChange={props?.handleChange}
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                MenuProps={{
                    classes: {
                        paper: styles.paper,
                    },
                }}
            >
                {props?.options?.map((el) => (
                    <MenuItem key={el} value={el}>
                        <Typography variant="body2">
                            {props.searchOptions ? (
                                <strong>
                                    <Trans>{el} résultats par page</Trans>
                                </strong>
                            ) : (
                                <>
                                    <strong>{el}</strong> {props.label}
                                </>
                            )}
                        </Typography>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

SelectInputFilter.propTypes = {
    options: array.isRequired,
    value: number,
    handleChange: func,
    className: object,
    label: string,
    searchOptions: string,
};

export default SelectInputFilter;
