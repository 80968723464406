import { Plural, t, Trans } from "@lingui/macro";
import { Badge, Box, makeStyles, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import Page500Front from "Components/ErrorPage/Page500Front";
import { useEnumValuesState } from "hooks/useEnumValues";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import bg from "assets/searchEngine/estate/bg-estate-step-1.jpg";
import BreadCrumbs from "Components/Breadcrumbs/BreadCrumbs";
import SelectFilterPerPage from "Components/Inputs/SelectInputFilter";
import SimpleInput from "Components/Inputs/SimpleInput";
import { useSearchWineEntitiesQuery } from "generated/graphql";
import theme from "globalTheme";
import { getUserLocale, useWindowDimensions } from "helpers/helpers";
import { useSearch } from "hooks";
import AutocompleteCheckboxSearch from "Search/AutocompleteCheckboxSearch";
import SearchResultEstateCard from "Search/card/SearchResultEstateCard";
import SearchEstateModal from "Search/modal/SearchEstateModal";
import SearchMainContainer from "Search/SearchMainContainer";
import SearchTabs from "Search/SearchTabs";
import ButtonMoreFilters from "Search/ui/ButtonMoreFilters";
import ButtonResetFilters from "Search/ui/ButtonResetFilters";
import { NoMatchingResult } from "Search/ui/NoMatchingResult";
import Helmet from "Components/Helmet/Helmet";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(() => ({
    root: {
        "&::after": {
            // eslint-disable-next-line quotes
            content: '""',
            position: "absolute",
            zIndex: -1,
            top: "-5rem",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundImage: `url(${bg})`,
            backgroundRepeat: "no-repeat",
            backgroundPositionY: "top",
            backgroundPositionX: "center",
            backgroundSize: "cover",
        },
        gridTemplateColumns: "repeat(12, 1fr)",
        display: "grid",
        minHeight: "calc(100vh - 5rem)",
    },
    main_container: {
        gridColumn: "1 / span 12",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "22px 0 2rem",

        [theme.breakpoints.up("sm")]: {
            padding: "22px 1rem 2rem",
            paddingTop: 80,
        },

        [theme.breakpoints.up("md")]: {
            paddingTop: 80,
        },

        [theme.breakpoints.up("xl")]: {
            width: "calc(1314px)",
            margin: "auto",
        },
    },

    title: {
        color: theme.palette.common.white,
        fontWeight: 400,
        textAlign: "center",
        marginBottom: "1rem",
    },
    headerContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        gap: "7px 6px",
        marginBottom: 13,

        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 4",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 3",
        },

        [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "repeat(18, 1fr)",
        },
    },
    search: {
        display: "none",

        [theme.breakpoints.up("sm")]: {
            display: "block",
            gridColumn: "1 / span 8",
            gridRow: "1 / span 1",
        },

        [theme.breakpoints.up("md")]: {
            gridColumn: "1 / span 4",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 5",
        },

        [theme.breakpoints.up("xl")]: {
            gridColumn: "1 / span 5",
        },
    },
    label: {
        display: "none",
        position: "relative",

        [theme.breakpoints.up("md")]: {
            display: "block",
            gridColumn: "auto / span 2",
            gridRow: "1 / span 1",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 2",
            gridRow: "1 / span 1",
        },

        [theme.breakpoints.up("xl")]: {
            gridColumn: "auto / span 2",
        },
    },
    appellation: {
        display: "none",
        position: "relative",

        [theme.breakpoints.up("md")]: {
            display: "block",
            gridColumn: "auto / span 2",
            gridRow: "1 / span 1",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 3",
            gridRow: "1 / span 1",
        },

        [theme.breakpoints.up("xl")]: {
            gridColumn: "auto / span 2",
        },
    },
    classification: {
        display: "none",
        position: "relative",

        [theme.breakpoints.up("md")]: {
            display: "block",
            gridColumn: "auto / span 2",
            gridRow: "1 / span 1",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 2",
            gridRow: "1 / span 1",
        },

        [theme.breakpoints.up("xl")]: {
            gridColumn: "auto / span 2",
        },
    },
    badge: {
        display: "none",
        position: "relative",

        [theme.breakpoints.up("md")]: {
            display: "block",
            gridColumn: "auto / span 4",
            gridRow: "1 / span 1",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 2",
            gridRow: "1 / span 1",
        },

        [theme.breakpoints.up("xl")]: {
            gridColumn: "auto / span 2",
        },
    },
    badgeBox: {
        display: "flex",
        width: "100%",
        flexDirection: "row-reverse",
        alignItems: "center",
        justifyContent: "space-between",
        border: `0.4px solid ${theme.palette.secondary.main}`,
        borderRadius: "53px",
        height: "32px",
        paddingLeft: 6,
        paddingRight: 10,
        cursor: "pointer",
        "-webkit-tap-highlight-color": "transparent",

        [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
            justifyContent: "flex-start",
        },

        [theme.breakpoints.up("lg")]: {
            paddingRight: 0,
        },
    },
    btnFilters: {
        position: "relative",
        gridColumn: "1 / span 6",
        gridRow: "1 / span 1",
        width: "100%",

        [theme.breakpoints.up("sm")]: {
            gridColumn: "9 / span 3",
        },

        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 2",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "13 / span 2",
            paddingLeft: 5,
        },
    },
    badgeNotif: {
        position: "absolute",
        right: 10,
        top: 4,
        border: "none",
        padding: "0 4px",

        "& .MuiBadge-badge": {
            fontWeight: 700,
            background: theme.palette.primary.dark,
            color: theme.palette.common.white,
        },
    },
    badgeSearchAdvanced: {
        top: 6,

        [theme.breakpoints.down("md")]: {
            right: -7,
        },
    },
    filterResultBox: {
        gridColumn: "1 / span 12",
        gridRow: "3 / span 1",
        display: "flex",
        alignItems: "flex-start",
    },
    deleteFiltersBox: {
        display: "flex",
        justifyContent: "flex-end",
    },
    separator: {
        gridColumn: "1 / span 18",
        gridRow: "2 / span 1",
        width: "100%",
        height: 0.4,
        backgroundColor: theme.palette.secondary.main,
    },
    nbResultTypo: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        marginTop: 0,
    },
    resultType: {
        color: theme.palette.secondary.dark,
        fontWeight: 400,
    },
    perPageFilterResultSimilar: {
        gridColumn: "auto / span 12",
        gridRow: "1 / span 1",
        marginTop: 0,

        [theme.breakpoints.up("sm")]: {
            gridColumn: "15 / span 4",
            gridRow: "3 / span 1",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "15 / span 4",
            gridRow: "3 / span 1",
        },
    },
    containerCards: {
        display: "grid",
        gridTemplateColumns: "repeat(1, 1fr)",
        gap: 20,

        [theme.breakpoints.up("sm")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
        },

        [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "repeat(3, 1fr)",
        },

        [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "repeat(4, 1fr)",
        },
    },
    card: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
    },
    pagination: {
        gridColumn: "1 / span 12",
        display: "flex",
        justifyContent: "flex-end",

        "& .MuiPaginationItem-page.Mui-selected": {
            color: theme.palette.primary.light,
            border: `1px solid ${theme.palette.primary.light}`,
            height: "fit-content",
            backgroundColor: "inherit",
            fontWeight: 700,
        },
    },
    searchAdvancedBox: {
        marginRight: 40,
        position: "relative",
        gridColumn: "16 / span 3",
        gridRow: "1 / span 1",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 3,

        [theme.breakpoints.up("md")]: {
            marginRight: 0,
            gridColumn: "17 / span 2",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "17 / span 2",
        },

        [theme.breakpoints.up("xl")]: {
            gridColumn: "17 / span 2",
        },
    },
    searchAdvancedBtn: {
        background: "transparent",
        border: "none",
        color: theme.palette.primary.dark,
        textDecoration: "underline",
        cursor: "pointer",
        padding: 0,
        display: "flex",
        justifyContent: "center",
    },
    breadcrumbs: {
        paddingLeft: "1rem",
    },
}));

const SearchResultEstate = () => {
    const styles = useStyles();
    const { width } = useWindowDimensions();
    const locale = useSelector(getUserLocale);
    const location = useLocation();
    const { data: appellationLabels, loading: loadingAppellation } = useEnumValuesState("APPELLATION_LABEL");
    const { data: classificationOrganisms, loading: loadingClassification } = useEnumValuesState(
        "CLASSIFICATION_ORGANISM"
    );
    const { data: labelCertifEstate, loading: loadingCertifEstate } = useEnumValuesState("LABEL_CERTIFICATION_ESTATE", {
        locale,
    });
    const [prevFilters, setPrevFilters] = useState({});

    // modal
    const [modalIsShown, setModalIsShown] = useState(false);
    const [searchTextDisplay, setSearchTextDisplay] = useState("");
    const [textFocused, setTextFocused] = useState(false);
    const [localisationChecked, setLocalisationChecked] = useState(false);

    const [pageSize, setPageSize] = useState(12);
    const {
        loading,
        results,
        filtersCount,
        filters,
        setFilter,
        totalCount,
        pageNumber,
        setPageNumber,
        similar,
        resetFilters,
        errors,
        setAllFilters,
    } = useSearch(
        {
            searchText: "",
            labels: [],
            appellations: [],
            appellationsWineProducts: [],
            classifications: [],
            badge: false,
            tourism: [],
            sweetness: [],
            colors: [],
            subRegions: [],
            communes: [],
            surface: [0, 100],
        },
        useSearchWineEntitiesQuery,
        "SearchWineEntities",

        pageSize
    );
    useEffect(() => {
        setSearchTextDisplay(filters?.searchText);
    }, [filters?.searchText]);

    useEffect(() => {
        if (location?.state?.communes) {
            setLocalisationChecked(true);
            setFilter("communes", [location?.state?.communes]);
        }
        if (location?.state?.appellation) {
            setFilter("appellations", [location?.state?.appellation]);
        }
        if (location?.state?.subregion) {
            setLocalisationChecked(false);
            setFilter("subRegions", [location?.state?.subregion]);
        }
    }, [location]);

    const [displayedSimilar, setDisplayedSimilar] = useState(similar);
    const [displayedResults, setDisplayedResults] = useState(results);
    const [displayedTotalCount, setDisplayedTotalCount] = useState(totalCount);

    const trueResults = modalIsShown ? displayedResults : results;
    const trueSimilar = modalIsShown ? displayedSimilar : similar;
    const trueTotalCount = modalIsShown ? displayedTotalCount : totalCount;

    const getStringNumResults = () => {
        const c = trueTotalCount;
        if (trueTotalCount > 0) {
            return <Plural value={c} one="# résultat trouvé" other="# résultats trouvés" />;
        } else {
            return t`Aucun résultat trouvé`;
        }
    };

    const breadcrumbs = [
        {
            name: t`Recherche`,
            path: "/recherche",
        },
        {
            name: t`Propriétés`,
            path: "/recherche/proprietes",
        },
        {
            name: t`Résultats`,
        },
    ];

    if (errors) {
        return <Page500Front />;
    }

    return (
        <>
            <Helmet title={t`Résultats propriétés`} />
            <Box className={styles.root}>
                <BreadCrumbs links={breadcrumbs} className={styles.breadcrumbs} />
                <Box className={styles.main_container}>
                    <Typography variant="h2" className={styles.title}>
                        <Trans>Parcourez le vignoble bordelais</Trans>
                    </Typography>
                    <SearchTabs active="estate" redirectToResult />
                    <SearchMainContainer>
                        <Box className={styles.headerContainer}>
                            <Box className={styles.search}>
                                <SimpleInput
                                    onFocus={() => setTextFocused(true)}
                                    onChange={(e) => setSearchTextDisplay(e?.target?.value)}
                                    value={searchTextDisplay}
                                    search
                                    borderWidth={0}
                                    size="small"
                                    name={"search"}
                                    placeholder={
                                        width < 960
                                            ? t`Chercher une propriété...`
                                            : t`Chercher une propriété, un vin...`
                                    }
                                    variant="outlined"
                                    onBlur={(e) => {
                                        if (textFocused) {
                                            setFilter("searchText", e?.target?.value);
                                        }
                                    }}
                                    boxStyle={{
                                        backgroundColor: theme.palette.common.white,
                                        borderRadius: "53px",
                                        border: "none",
                                    }}
                                    bgTransparent
                                    borderRadius={53}
                                    iconPosition="start"
                                />
                            </Box>

                            <Box className={styles.label}>
                                <Badge badgeContent={filters?.labels?.length} className={styles.badgeNotif} />

                                <AutocompleteCheckboxSearch
                                    class={styles.category_filter}
                                    label={t`Label`}
                                    options={labelCertifEstate}
                                    value={filters?.labels}
                                    onChange={(e1, e2) => setFilter("labels", e2)}
                                    loading={loadingCertifEstate}
                                    uppercase
                                    popupIcon
                                />
                            </Box>

                            <Box className={styles.appellation}>
                                <Badge badgeContent={filters?.appellations?.length} className={styles.badgeNotif} />

                                <AutocompleteCheckboxSearch
                                    class={styles.category_filter}
                                    label={t`Appellation`}
                                    options={appellationLabels}
                                    loading={loadingAppellation}
                                    value={filters?.appellations}
                                    onChange={(e1, e2) => setFilter("appellations", e2)}
                                    sortAlphabetical
                                    uppercase
                                    popupIcon
                                />
                            </Box>

                            <Box className={styles.classification}>
                                <Badge badgeContent={filters?.classifications?.length} className={styles.badgeNotif} />
                                <AutocompleteCheckboxSearch
                                    class={styles.category_filter}
                                    options={classificationOrganisms}
                                    label={t`Classement`}
                                    value={filters?.classifications}
                                    filterName="classifications"
                                    onChange={(e1, e2) => setFilter("classifications", e2)}
                                    loading={loadingClassification}
                                    sortAlphabetical
                                    popupIcon
                                />
                            </Box>

                            {/* <Box className={styles.badge}>
                                <Badge
                                    badgeContent={filters?.badge ? 1 : 0}
                                    className={styles.badgeNotif}
                                    style={{ top: 4, right: width >= 1280 ? 0 : -8 }}
                                />

                                <Box
                                    className={styles.badgeBox}
                                    onClick={() => setFilter("badge", !filters.badge)}
                                    style={{
                                        borderColor: filters?.badge
                                            ? theme.palette.primary.dark
                                            : theme.palette.secondary.main,
                                        backgroundColor: filters?.badge
                                            ? theme.palette.background.card
                                            : theme.palette.common.white,
                                    }}
                                >
                                    <BigSwitch
                                        checked={filters?.badge}
                                        onChange={() => setFilter("badge", !filters.badge)}
                                    />
                                    <Typography
                                        variant="h6"
                                        style={{ marginLeft: 7, color: theme.palette.primary.dark }}
                                    >
                                        <Trans>Badge Féret</Trans>
                                    </Typography>
                                </Box>
                            </Box> */}

                            {width < 600 ? (
                                <Box className={styles.btnFilters}>
                                    <Badge badgeContent={filtersCount} className={styles.badgeNotif} />
                                    <ButtonMoreFilters
                                        onClick={() => {
                                            setPrevFilters(filters);
                                            setDisplayedResults(results);
                                            setDisplayedTotalCount(totalCount);
                                            setDisplayedSimilar(similar);
                                            setModalIsShown(true);
                                        }}
                                    />
                                </Box>
                            ) : (
                                <Box className={styles.searchAdvancedBox}>
                                    <Badge
                                        badgeContent={filtersCount}
                                        className={[styles.badgeNotif, styles.badgeSearchAdvanced].join(" ")}
                                    />
                                    <button
                                        className={styles.searchAdvancedBtn}
                                        onClick={() => {
                                            setPrevFilters(filters);
                                            setDisplayedResults(results);
                                            setDisplayedTotalCount(totalCount);
                                            setDisplayedSimilar(similar);
                                            setModalIsShown(true);
                                        }}
                                    >
                                        <Typography variant="subtitle1">
                                            <Trans>Recherche avancée</Trans>
                                        </Typography>
                                    </button>
                                </Box>
                            )}

                            <Box className={styles.separator} />

                            <Box className={styles.filterResultBox}>
                                <Box className={styles.deleteFiltersBox}>
                                    <ButtonResetFilters
                                        onClick={() => {
                                            setSearchTextDisplay("");
                                            resetFilters();
                                        }}
                                        disabled={filtersCount === 0 && !searchTextDisplay}
                                    />
                                </Box>

                                <Box className={styles.nbResultTypo}>
                                    <Typography
                                        variant="subtitle1"
                                        className={styles.resultType}
                                        style={{ marginLeft: "5px" }}
                                    >
                                        {getStringNumResults()}
                                    </Typography>
                                </Box>
                            </Box>

                            <NoMatchingResult
                                display={!trueResults?.length || trueSimilar}
                                displaySimilar={trueResults?.length > 0 && trueSimilar}
                                resultCount={trueTotalCount}
                                similarLabel={
                                    <Trans>
                                        Chacune des propriétés ci-dessous présente un seul critère non respecté ou non
                                        précisé par le producteur.
                                    </Trans>
                                }
                                loading={loading}
                            />

                            {trueResults?.length > 0 && (
                                <SelectFilterPerPage
                                    searchOptions
                                    label="résultats"
                                    options={[12, 24, 48, 96]}
                                    value={pageSize}
                                    handleChange={(e) => {
                                        setPageSize(Number(e.target.value));
                                        setPageNumber(1);
                                    }}
                                    className={styles.perPageFilterResultSimilar}
                                />
                            )}
                        </Box>
                        <Box className={styles.containerCards}>
                            {trueResults?.map((estate, i) => (
                                <SearchResultEstateCard
                                    estate={estate}
                                    key={i}
                                    appellation={estate?.appellation}
                                    badge={estate?.badge}
                                    image={estate?.image}
                                    label={estate?.label}
                                    onlineSales={estate?.onlineSales}
                                    isOpen={estate?.isOpen}
                                    wines={estate?.wines}
                                    name={estate?.wineEntityName}
                                    classification={estate?.classification}
                                    ha={estate?.ha}
                                    wineEntityId={estate?.wineEntityId}
                                    missing={estate.missingField}
                                />
                            ))}
                        </Box>

                        {trueResults?.length > 0 && (
                            <Box className={styles.pagination}>
                                <Pagination
                                    onChange={(_, n) => {
                                        setPageNumber(n);
                                        window.scrollTo({ top: 0 });
                                    }}
                                    count={trueTotalCount ? Math.floor(trueTotalCount / pageSize) + 1 : null}
                                    page={pageNumber}
                                    showFirstButton
                                    showLastButton
                                    className={styles.pagination}
                                    shape="rounded"
                                    size="small"
                                />
                            </Box>
                        )}
                    </SearchMainContainer>
                </Box>
            </Box>

            <SearchEstateModal
                localisationChecked={localisationChecked}
                setLocalisationChecked={setLocalisationChecked}
                filtersCount={filtersCount}
                similar={similar}
                searchTextDisplay={searchTextDisplay}
                setSearchTextDisplay={(v) => setSearchTextDisplay(v)}
                clearFilters={() => {
                    setSearchTextDisplay("");
                    resetFilters();
                }}
                isOpen={modalIsShown}
                onClose={() => setModalIsShown(false)}
                onCloseCross={() => {
                    setAllFilters(prevFilters);
                    setModalIsShown(false);
                }}
                titlePrimary={t`Tous les filtres`}
                titleSecondary={t`Propriété`}
                searchResult={results}
                filters={filters}
                setFilter={(fieldname, v) => setFilter(fieldname, v)}
                setNbFilters={() => {}}
                totalCount={totalCount}
            />
        </>
    );
};

export default SearchResultEstate;
