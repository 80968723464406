import { Trans, t } from "@lingui/macro";
import { Typography, makeStyles } from "@material-ui/core";
import Modal from "Containers/Modal";
import warningIcon from "assets/popin/icones/warning.svg";
import { signOut } from "features/user/userSlice";
import globalTheme from "globalTheme";
import { useCustomHistory } from "hooks";
import { useDispatch } from "react-redux";

const useStyle = makeStyles({
    strongMessage: {
        margin: "30px auto",
        fontWeight: 700,
        width: "50%",
        color: globalTheme.palette.primary.dark,
    },
    typo: {
        color: globalTheme.palette.primary.dark,
    },
});

export const InactiveAccountModal = () => {
    const style = useStyle();
    const dispatch = useDispatch();
    const history = useCustomHistory();

    const handleClose = () => {
        dispatch(signOut());
        history.replace("/");
    };

    return (
        <Modal
            styles={{ textAlign: "center" }}
            icon={warningIcon}
            title={<span className={style.typo}>{t`Votre compte a été suspendu`}</span>}
            actionNeeded={false}
            onClose={handleClose}
            width={625}
            open
        >
            <Typography variant="h6" className={style.typo}>
                <Trans>
                    L’accès à votre compte a été restreint, votre page propriété n’est plus visible sur Bordeaux et ses
                    Vins et vos fiches techniques sont désormais cachées.
                </Trans>
            </Typography>
            <Typography className={style.strongMessage} variant="h6">
                <Trans>Si vous pensez qu’il s’agit d’une erreur ou pour en savoir plus contactez-nous :</Trans>
                <br />
                <br />
                <a className={style.typo} href="mailto:relationclient@feret.com">
                    relationclient@feret.com
                </a>
                <br />
                +33 5 56 13 79 95
            </Typography>
        </Modal>
    );
};
