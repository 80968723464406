import { t } from "@lingui/macro";
import { makeStyles } from "@material-ui/core";
import { SUBREGIONS } from "config/constants";
import { useGetAppellationDescriptionsFrontQuery, useGetClassificationOrganismListQuery } from "generated/graphql";
import { darkColorSvg, urlize, useWindowDimensions } from "helpers/helpers";
import { useCustomHistory } from "hooks";
import { string } from "prop-types";
import { useSelector } from "react-redux";

import fioriture from "../assets/navbarfront/fioriture_menu.svg";
import logoFrt from "../assets/navbarfront/logoFrt.svg";
import theme from "../globalTheme";
import Navbar from "./navbar/Navbar";

const useStyles = makeStyles(() => ({
    containerTitle: {
        borderBottomLeftRadius: "20px",
        width: "300px",
        [theme.breakpoints.up("md")]: {
            backgroundSize: "265px",
        },
        [theme.breakpoints.up("lg")]: {
            backgroundSize: "265px",
            padding: "4rem 0 2rem 2rem",
        },
        [theme.breakpoints.up("xl")]: {
            padding: "4rem 0 2rem 1rem",
        },

        gridColumn: "1 / 6",
        backgroundImage: `url(${fioriture})`,
        backgroundRepeat: "no-repeat",
        color: theme.palette.common.white,
    },
    containerLinks: {
        padding: "4rem 0rem 2rem 1rem",
        [theme.breakpoints.up("md")]: {
            padding: "2rem 0rem 2rem 1rem",
        },
        [theme.breakpoints.up("lg")]: {
            padding: "2rem 0rem",
        },
        gridColumn: "6 /  13",
        display: "flex",
        flexDirection: "column",
        color: theme.palette.primary.dark,
        // width: "max-content",
        width: "auto",
        minWidth: "315px",
    },
    containerSubMenus: {
        width: "max-content",
        padding: "2rem 2rem 2rem 1rem",
        justifyContent: "center",
        borderBottomRightRadius: "20px",
        gridColumn: "auto / span 3",
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        textTransform: "uppercase",
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
    },
    logo: {
        [theme.breakpoints.up("xs")]: {
            width: "5rem",
            height: "100%",
        },
        [theme.breakpoints.up("sm")]: {
            width: "7rem",
            height: "100%",
        },
        [theme.breakpoints.up("md")]: {
            width: "106px",
            height: "auto",
            filter: "unset",
        },
        [theme.breakpoints.up("lg")]: {
            width: "110px",
        },
        [theme.breakpoints.up("xl")]: {
            width: "110px",
        },
    },
    classNameLogo: {
        [theme.breakpoints.up("xs")]: {
            fill: theme.palette.common.white,
            filter: "unset",
        },
        display: "inherit",
        marginLeft: "0.5rem",
    },
    classNameLogoScroll: {
        display: "inherit",
        filter: darkColorSvg,
        marginLeft: "0.5rem",
    },
    slideContents: {
        overflow: "hidden",
        [theme.breakpoints.up("xs")]: {
            width: "100%",
        },
        [theme.breakpoints.up("md")]: {
            overflow: "inherit",
            width: "100%",
        },
        [theme.breakpoints.up("lg")]: {
            width: "60%",
        },
        [theme.breakpoints.up("xl")]: {
            width: "55%",
        },
        height: "100%",
    },
}));

const FrontNavbar = (props) => {
    const locale = useSelector((state) => state?.user?.locale || "fr");
    const styles = useStyles();
    const { width } = useWindowDimensions();
    const history = useCustomHistory();
    const {
        data: { GetAppellationDescriptionsFront: appellations, loading: loadingAppellations } = {},
        loading,
    } = useGetAppellationDescriptionsFrontQuery({
        variables: { locale },
    });

    const {
        data: { GetClassificationOrganismList: classifications, loading: loadingClassifications } = {},
    } = useGetClassificationOrganismListQuery({
        variables: { locale },
    });

    const appellationMenuItems = SUBREGIONS.map((subregion) => {
        const subregionFormatted = subregion === "Entre-deux-Mers" ? "Entre-deux-mers" : subregion;

        return {
            title: subregion,
            to: history.addLocale(`/appellations/${urlize(subregionFormatted)}`),
            subMenus:
                appellations?.length > 0 &&
                appellations
                    .filter((appellation) => appellation.subregion === subregionFormatted)
                    .map((appellation) => ({
                        title: appellation.name,
                        to: history.addLocale(
                            `/appellations/${urlize(subregionFormatted)}/${urlize(appellation.name)}`
                        ),
                    })),
        };
    });

    const competitionMenuItems =
        classifications?.length > 0 &&
        classifications.map((competition) => ({
            title: competition.name,
            to: history.addLocale(`/les-classements-de-bordeaux/${urlize(competition.name)}`),
        }));

    const placement = () => {
        if (width >= 960 && width < 1280) {
            return "bottom-end";
        } else {
            return "bottom-start";
        }
    };

    const datas = [
        {
            sectionTitle: t`La Lettre Féret`,
            mainTitle: t`La Lettre Féret`,
            to: "/external/lettre-feret"
        },
        {
            sectionTitle: t`Les Domaines`,
            mainTitle: t`Les Domaines`,
            to: history.addLocale("/recherche/proprietes/resultats")
        },
        {
            sectionTitle: t`Œnotourisme`,
            mainTitle: t`Œnotourisme`,
            to: history.addLocale("/recherche/activites/resultats")
        },
        {
            sectionTitle: t`Maison d'édition`,
            mainTitle: t`Maison d'édition`,
            menuItems: [
                {
                    title: t`Expertise Féret`,
                    subMenus: [
                        {
                            title: t`Classements`,
                            to: history.addLocale("/les-classements-de-bordeaux"),
                        },
                        {
                            title: t`Appellations`,
                            to: history.addLocale("/appellations"),
                        },
                        {
                            title: t`Organismes`,
                            to: history.addLocale("/organismes-vignoble-bordelais/"),
                        },
                    ]
                },
                {
                    title: t`Librairie Féret`,
                    subMenus: [
                        {
                            title: t`Encyclopédie Féret`,
                            to: history.addLocale("/encyclopedie-feret"),
                        },
                        {
                            title: t`Bordeaux et ses vins`,
                            to: "/external/feret-bookshop" ,
                        }
                    ]
                },
                {
                    title: t`Recherches historiques`,
                    to: "/fr/external/historical-research",
                }
            ]
        },
        {
            sectionTitle: t`Note du millésime`,
            mainTitle: t`Note du millésime`,
            to: history.addLocale("/note-du-millesime")
        },
    ];

    // --- OLD MENU ----
    // const datas = [
    //     {
    //         sectionTitle: t`Expertise Féret`,
    //         mainTitle: t`Expertise Féret`,
    //         width: "160px",
    //         height: "300px",
    //         menuItems: [
    //             {
    //                 title: t`Encyclopédie Féret`,
    //                 to: history.addLocale("/encyclopedie-feret"),
    //             },
    //             {
    //                 title: t`La salle de dégustation Féret`,
    //                 to: history.addLocale("/salle-de-degustation"),
    //             },
    //         ],
    //     },
    //     {
    //         sectionTitle: t`Les appellations`,
    //         mainTitle: t`Appellations`,
    //         viewMore: history.addLocale("/appellations"),
    //         menuItems: appellationMenuItems,
    //         loading,
    //         width: "105px",
    //     },
    //     {
    //         sectionTitle: t`Les classements`,
    //         mainTitle: t`Classements`,
    //         viewMore: history.addLocale("/les-classements-de-bordeaux"),
    //         menuItems: competitionMenuItems,
    //         width: "105px",
    //     },
    //     {
    //         sectionTitle: t`Les organismes`,
    //         mainTitle: t`Organismes`,
    //         menuItems: [
    //             {
    //                 title: t`Défense et gestion`,
    //                 to: history.addLocale("/organismes-vignoble-bordelais/ODG"),
    //             },
    //             {
    //                 title: t`Formation`,
    //                 to: history.addLocale("/organismes-vignoble-bordelais/formation"),
    //             },
    //             {
    //                 title: t`Représentation`,
    //                 to: history.addLocale("/organismes-vignoble-bordelais/representation"),
    //             },
    //             {
    //                 title: t`Concours`,
    //                 to: history.addLocale("/organismes-vignoble-bordelais/concours"),
    //             },
    //         ],

    //         viewMore: history.addLocale("/organismes-vignoble-bordelais/ODG"),
    //         width: "105px",
    //     },
    // ];

    return (
        <Navbar
            loadingMenuItems={loadingAppellations || loadingClassifications}
            datas={datas}
            buttonBESV={false}
            search
            fioriture
            logo={<img className={styles.logo} src={logoFrt} />}
            classNameContainerTitle={styles.containerTitle}
            classNameLogoFront={styles.classNameLogo}
            classNameLogoFrontScroll={styles.classNameLogoScroll}
            classNameContainerLinks={styles.containerLinks}
            classNameContainerSubMenus={styles.containerSubMenus}
            classNameSlideContent={styles.slideContents}
            placement={placement()}
            hrefLogo={history.addLocale("/recherche")}
        />
    );
};

FrontNavbar.propTypes = {
    wineEntityName: string,
};
export default FrontNavbar;
