import { t, Trans } from "@lingui/macro";
import { useActivityFrontQuery } from "generated/graphql";
import { urlFormatted } from "helpers/helpers";
import {  string } from "prop-types";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import GenericActivityPage from "./GenericActivityPage";

const FrontActivityPage = () => {
    const {  activityShortId } = useParams();
    const settingsLocale = useSelector((state) => state?.user?.locale);
    const locale = settingsLocale || "fr";

    const { data, loading } = useActivityFrontQuery({
        variables: {
            activityShortId,
            locale,
        },
    });
    const activity = data?.ActivityFront;
    const wineEntityId = data?.ActivityFront?.wineEntityId;
    const wineEntityName = data?.ActivityFront?.estateName;
    const breadcrumbs = [
        {
            name: activity?.estateName,
            path: `/${urlFormatted(activity?.wineEntityShortId, wineEntityName)}`,
        },
        {
            name: t`Activités`,
            path: `/${urlFormatted(activity?.wineEntityShortId, wineEntityName)}#activites`,
        },
        {
            name: activity?.name,
        },
    ];

    const previousPage = {
        name: <Trans>Toutes les activités de {wineEntityName}</Trans>,
        path: `/${urlFormatted(activity?.wineEntityShortId, wineEntityName)}#activites`,
    };

    return (
        <GenericActivityPage
            activityId={activity?.id}
            socialMedias
            frontTitle
            wineSectionTitle={t`Les vins de la propriété`}
            showMoreWines={t`Découvrir tous les vins`}
            breadcrumbs={breadcrumbs}
            previousPage={previousPage}
            activity={activity}
            wineEntityId={wineEntityId}
            wineEntityName={wineEntityName}
            locale={locale}
            loading={loading}
        />
    );
};

export default FrontActivityPage;

export const StandaloneActivityPage = (props) => {
    const { activityId } = useParams();
    const settingsLocale = useSelector((state) => state?.user?.locale);
    const locale = settingsLocale || "fr";

    const { data, loading } = useActivityFrontQuery({
        variables: {
            activityId,
            locale,
        },
    });
    const activity = data?.ActivityFront;
    const wineEntityId = data?.ActivityFront?.wineEntityId;
    const wineEntityName = data?.ActivityFront?.estateName;
    const breadcrumbs = [
        {
            name: activity?.estateName,
            path: "/",
        },
        {
            name: t`Activités`,
            path: "/#activites",
        },
        {
            name: activity?.name,
        },
    ];

    const previousPage = {
        name: t`Toutes nos activités`,
        path: "/#activites",
    };
    return (
        <GenericActivityPage
            standalone
            loading={loading}
            logo={props.logo}
            hideFloritureTopImg
            titleStandalone
            activitiesSectionTitle={t`Nos autres activités`}
            contactSectionTitle={t`Contactez-nous !`}
            wineSectionTitle={t`Nos vins`}
            showMoreWines={t`Découvrir tous nos vins`}
            activity={activity}
            wineEntityId={wineEntityId}
            wineEntityName={wineEntityName}
            breadcrumbs={breadcrumbs}
            previousPage={previousPage}
        />
    );
};

StandaloneActivityPage.propTypes = {
    logo: string,
};
