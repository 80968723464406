import { Box, Typography } from "@material-ui/core";
import { array, object, shape, string } from "prop-types";

import ScoreCard from "FrontVintage/Components/ScoreCard";

export const ScoreCardContainer = ({ className, scores, title }) => {
    return (
        <Box className={className?.container}>
            <Box
                style={{
                    position: "relative",
                    zIndex: 20,
                }}
            >
                {title && (
                    <Typography variant="h4" className={className?.title}>
                        {title}
                    </Typography>
                )}
                <Box
                    style={{
                        display: "flex",
                        marginTop: "4px",
                        flexWrap: "wrap",
                        gap: "1rem",
                    }}
                >
                    {scores?.map((score) => (
                        <ScoreCard score={score} key={score._id} vintage={title} />
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

ScoreCardContainer.propTypes = {
    className: shape({ constiner: object, title: object }),
    scores: array,
    title: string,
};
