import { object, string } from "prop-types";
import { Box, Card, CardContent, Typography, makeStyles } from "@material-ui/core";
import learnMore from "../assets/CommunePage/learn_more.svg";
import theme from "../globalTheme";
import decoTop from "../assets/rankingSummary/fioriture4.svg";
import decoBottom from "../assets/rankingSummary/fioriture5.svg";
import star from "../assets/rankingSummary/stars.svg";
import { Link } from "react-router-dom";
import { urlize } from "../helpers/helpers";
import { Trans } from "@lingui/macro";
import { useCustomHistory } from "hooks";

const useStyles = makeStyles(() => ({
    card: {
        borderRadius: "10px",
        width: "18.25rem",
        height: "14.45rem",
        background: `url(${decoTop}) -5px -13px no-repeat, url(${decoBottom}) bottom right no-repeat`,
        backgroundColor: theme.palette.common.white,
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.08)",
        cursor: "pointer",
    },
    content: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
    },
    container_card: {
        height: "100%",
        justifyContent: "space-between",
        display: "flex",
        flexDirection: "column",
    },
    item_title: {
        color: theme.palette.primary.main,
        fontWeight: "bold",
        textAlign: "center",
        // height: 40,
        // marginBottom: 9,
    },
    link: {
        display: "flex",
        justifyContent: "end",
        // marginTop: "1rem",
        alignSelf: "end",
        textDecoration: "none",

        "&:hover": {
            textDecoration: "underline",
            textDecorationColor: theme.palette.secondary.dark,
        },
    },
}));

const imageStyle = {
    height: "80%",
    margin: "auto 0",
};

const RankingEntityCard = ({ organism }) => {
    const styles = useStyles();
    const history = useCustomHistory();

    return (
        <Card
            className={styles.card}
            component={Link}
            to={history.addLocale(`/les-classements-de-bordeaux/${urlize(organism?.name)}`)}
            style={{ textDecoration: "none" }}
        >
            <CardContent style={{ padding: "0.5rem 1rem 1rem 1rem", height: "-webkit-fill-available" }}>
                <Box className={styles.container_card}>
                    <Box className={styles.content}>
                        {organism?.logo?.data ? (
                            <Box
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "6.5rem",
                                    margin: "0 auto",
                                    marginBottom: 5,
                                }}
                            >
                                <img
                                    src={organism?.logo?.twicpicURL}
                                    style={{
                                        width: "auto",
                                        height: "4.6rem",
                                    }}
                                />
                            </Box>
                        ) : null}
                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                            }}
                        >
                            <Box
                                style={{
                                    display: "flex",
                                    marginTop: "0.5rem",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <Typography variant="h4" className={styles.item_title}>
                                    {organism?.name}
                                </Typography>

                                {organism?.classifiedWinesCount > 0 && (
                                    <Typography
                                        variant="body2"
                                        style={{
                                            textAlign: "center",
                                            color: theme.palette.primary.light,
                                            fontWeight: "bold",
                                            display: "flex",
                                        }}
                                    >
                                        <img
                                            src={star}
                                            style={{
                                                width: "20px",
                                                height: "20px",
                                                marginRight: "5px",
                                            }}
                                        />
                                        {organism?.classifiedWinesCount} crus classés
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    </Box>
                    <Box style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                padding: "0px",
                            }}
                        >
                            <Link
                                to={history.addLocale(`/les-classements-de-bordeaux/${urlize(organism?.name)}`)}
                                className={styles.link}
                            >
                                <Typography
                                    variant="body2"
                                    style={{
                                        color: theme.palette.secondary.dark,
                                        marginRight: "0.5rem",
                                        padding: "0px",
                                        marginLeft: "-2px",
                                    }}
                                >
                                    <Trans>Accéder</Trans>
                                </Typography>
                                <img src={learnMore} style={imageStyle} />
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

RankingEntityCard.propTypes = {
    organism: object,
};

export default RankingEntityCard;
