import { useState } from "react";
import { makeStyles, Box, Typography, Link } from "@material-ui/core";
import PropTypes, { bool } from "prop-types";
import { useSelector } from "react-redux";
import ReactPlayer from "react-player";

import FrontSlider from "Components/Slider/FrontSlider";
import RadarChart from "Components/Radar/RadarChart";
import { firstLetterUppercase, getChips, isObjectDeepEmpty, parseHtml } from "helpers/helpers";
import FrontAromasPopin from "./FrontAromasPopin";
import FrontFoodPairingPopin from "./FrontFoodPairingPopin";

import service from "assets/Wine/advices/service.svg";
import accord from "assets/Wine/advices/accord.svg";
import roserobe from "assets/Wine/advices/roserobe.svg";
import clairetrobe from "assets/Wine/advices/clairetrobe.svg";
import redrobe from "assets/Wine/advices/redrobe.svg";
import apparence from "assets/Wine/advices/apparence.svg";
import whiterobe from "assets/Wine/advices/whiterobe.svg";
import moerobe from "assets/Wine/advices/moerobe.svg";
import liqrobe from "assets/Wine/advices/liqrobe.svg";

import nez from "assets/Wine/advices/nez.svg";
import palais from "assets/Wine/advices/palais.svg";
import comment from "assets/Wine/advices/comment.svg";
import video from "assets/Wine/advices/video.svg";

import agrume from "assets/Wine/advices/agrume.svg";
import baies from "assets/Wine/advices/baies.svg";
import chronometer from "assets/Wine/advices/chronometer.svg";
import clean from "assets/Wine/advices/clean.svg";
import drapeau from "assets/Wine/advices/drapeau.svg";
import avantService from "assets/Wine/advices/avant_service.svg";
import raisin from "assets/Wine/advices/raisin.svg";
import verre from "assets/Wine/advices/verre.svg";
import verretourne from "assets/Wine/advices/verretourne.svg";
import apogee from "assets/Wine/advices/apogee.svg";
import gustatif from "assets/Wine/advices/gustatif.svg";
import arrow from "assets/Wine/arrow.png";
import Loading from "Components/Loader/Loading";
import thermo from "assets/Wine/advices/temperature.svg";
import { useWindowDimensions } from "helpers/helpers";

import { useFrontVintageAdviceQuery } from "generated/graphql";

import theme from "globalTheme";
import { t, Trans } from "@lingui/macro";
import BaseChip from "Components/Chips/BaseChip";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: "flex",
        flexWrap: "wrap",
        gap: "32px",
    },
    videoContainer: {
        border: "0.4px solid",
        borderRadius: "5px",
        borderColor: theme.palette.secondary.dark,
        position: "relative",
        padding: theme.spacing(2),
        paddingTop: 0,
        [theme.breakpoints.up("xs")]: {
            width: "fill-available",
        },
        [theme.breakpoints.up("sm")]: {
            width: "fill-available",
        },
        [theme.breakpoints.up("md")]: {
            width: "100%",
        },
        [theme.breakpoints.up("lg")]: {
            width: "44%",
        },
        [theme.breakpoints.up("xl")]: {
            width: "44%",
        },
    },
    commentContainer: {
        border: "0.4px solid",
        borderRadius: "5px",
        borderColor: theme.palette.secondary.dark,
        position: "relative",
        padding: theme.spacing(2),
        paddingTop: 0,
        backgroundColor: theme.palette.common.white,
        [theme.breakpoints.only("xs")]: {
            width: "100%",
        },
        [theme.breakpoints.up("sm")]: {
            width: "100%",
        },
        [theme.breakpoints.up("md")]: {
            width: "100%",
        },
        [theme.breakpoints.up("lg")]: {
            width: "44%",
        },
        [theme.breakpoints.up("xl")]: {
            width: "44%",
        },
    },
    typoContainer: {
        background: theme.palette.common.white,
        position: "absolute",
        top: "-28px",
        left: "0px",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.only("xs")]: {
            top: -27,
        },
    },
    title: {
        ...theme.front.typography.h6.dark_b,
        zIndex: 2,
        backgroundColor: theme.palette.common.white,
        padding: "0px 2px",
    },
    blocksContainerSlider: {
        width: "100%",
        minHeight: "50px",
        position: "relative",
        paddingRight: "30px",
        paddingLeft: "20px",
    },
    blocksContainerSliderNose: {
        paddingRight: "20px",
        paddingLeft: "0px",
    },
    blocksTitle: {
        display: "flex",
        alignItems: "center",
    },
    blocksTitleTypo: {
        ...theme.front.typography.label.darkLight,
    },
    flexWrap: {
        display: "flex",
        flexWrap: "wrap",
        gap: "24px",
        paddingTop: "16px",
    },
    containerService: {
        display: "flex",
        flexWrap: "wrap",
        gap: "24px",
        paddingTop: "16px",
        [theme.breakpoints.down("md")]: {
            justifyContent: "space-between",
        },
    },
    containerApparence: {
        justifyContent: "flex-start",
        [theme.breakpoints.down("md")]: {
            justifyContent: "space-between",
        },
    },
    image1: {
        width: "20px",
        marginRight: "4px",
    },
    bigImage: {
        backgroundColor: theme.palette.common.white,
        width: "50px",
    },
    secondPart: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        width: "100%",
        [theme.breakpoints.up("xs")]: {
            gap: "32px",
        },
        [theme.breakpoints.up("sm")]: {
            gap: "32px",
        },
        [theme.breakpoints.up("md")]: {},
        [theme.breakpoints.up("lg")]: {
            gap: "32px",
        },
        [theme.breakpoints.up("xl")]: {},
    },
    palateRadar: {
        width: "100%",
        paddingTop: "20px",
        position: "relative",
        left: "-5px",
    },
    blcContainer__accords: {
        width: "100%",
        backgroundColor: theme.palette.common.white,
        border: "0.4px solid",
        borderRadius: "5px",
        borderColor: theme.palette.secondary.dark,
        position: "relative",
        padding: theme.spacing(2),
        paddingTop: 0,
        [theme.breakpoints.up("xs")]: {},
        [theme.breakpoints.only("sm")]: {
            width: "fill-available",
        },
    },
    blcContainer__services: {
        maxHeight: "164px",
        backgroundColor: theme.palette.common.white,
        border: "0.4px solid",
        borderRadius: "5px",
        borderColor: theme.palette.secondary.dark,
        position: "relative",
        padding: theme.spacing(2),
        paddingTop: 0,
        [theme.breakpoints.up("xs")]: {
            width: "100%",
        },
        [theme.breakpoints.up("sm")]: {
            width: "fill-available",
        },
        [theme.breakpoints.up("md")]: {},
        [theme.breakpoints.up("lg")]: {
            width: "44%",
        },
        [theme.breakpoints.up("xl")]: {
            width: "44%",
        },
    },
    blcContainer__apparence: {
        backgroundColor: theme.palette.common.white,
        border: "0.4px solid",
        borderRadius: "5px",
        borderColor: theme.palette.secondary.dark,
        position: "relative",
        padding: theme.spacing(2),
        paddingTop: 0,
        [theme.breakpoints.up("xs")]: {
            width: "100%",
        },
        [theme.breakpoints.up("sm")]: {
            width: "fill-available",
        },
        [theme.breakpoints.up("md")]: {},
        [theme.breakpoints.up("lg")]: {
            width: "44%",
        },
        [theme.breakpoints.up("xl")]: {
            width: "44%",
        },
    },
    blcContainer__nez: {
        backgroundColor: theme.palette.common.white,
        border: "0.4px solid",
        borderRadius: "5px",
        borderColor: theme.palette.secondary.dark,
        position: "relative",
        padding: theme.spacing(2),
        paddingTop: 0,
        [theme.breakpoints.only("xs")]: {
            width: "100%",
        },
        [theme.breakpoints.up("sm")]: {
            width: "fill-available",
        },
        [theme.breakpoints.up("md")]: {},
        [theme.breakpoints.up("lg")]: {
            width: "44%",
            height: "fit-content",
        },
        [theme.breakpoints.up("xl")]: {
            width: "44%",
        },
    },
    blcContainer__palais: {
        backgroundColor: theme.palette.common.white,
        border: "0.4px solid",
        borderRadius: "5px",
        borderColor: theme.palette.secondary.dark,
        position: "relative",
        padding: theme.spacing(2),
        paddingTop: 0,
        [theme.breakpoints.only("xs")]: {
            width: "100%",
        },
        [theme.breakpoints.up("sm")]: {
            width: "fill-available",
        },
        [theme.breakpoints.up("md")]: {},
        [theme.breakpoints.up("lg")]: {
            maxWidth: "44%",
            width: "100%",
        },
        [theme.breakpoints.up("xl")]: {
            maxWidth: "44%",
            width: "100%",
        },
    },
    advicesVideo__wrapper: {
        aspectRatio: "16/9",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    boxVideoAndDescription__wrapper: {
        display: "flex",
        alignItems: "flex-start",
        width: "100%",
        gap: "16px",
        paddingTop: "16px",
        [theme.breakpoints.down("sm")]: {
            flexWrap: "wrap",
        },
    },
    comment__Typo: {
        ...theme.front.typography.labeur.dark,
    },
    typoAccord: {
        ...theme.front.typography.labeur.dark,
    },
    blocksContainer__accords: {
        width: "255px",
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(1),
        [theme.breakpoints.down("sm")]: {
            width: "auto",
        },
    },
    typoAccordLink: {
        ...theme.front.typography.labeur.clear,
        cursor: "pointer",
    },
    boxRouedesAromes: {
        position: "relative",
        [theme.breakpoints.only("xs")]: {
            height: "auto",
            width: "auto",
        },
    },
    typoApparence: {
        ...theme.front.typography.labeur.dark,
    },
    typoNoiseAromas: {
        ...theme.front.typography.label.darkLight,
    },
    typoPalais: {
        ...theme.front.typography.labeur.dark,
    },
    typoLinkPalais: {
        fontFamily: "Lato",
        fontSize: "14px",
        color: "#915868",
        cursor: "pointer",
    },
    typoApogee: {
        ...theme.front.typography.labeur.dark,
        color: theme.palette.primary.dark,
    },
    typoApogeeAvantService: {
        ...theme.front.typography.labeur.dark,
        color: theme.palette.primary.dark,
    },
    radarChart: {
        display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "inherit",
        },
    },
    sliderNose: (args) => {
        return {
            paddingRight: "1.5px",
            paddingLeft: "1.5px",

            '& .MuiSlider-markLabel[data-index="0"]': {
                left: args.locale === "de" && "3% !important",
            },
            '& .MuiSlider-markLabel[data-index="1"]': {
                left: args.locale === "de" && "21.7% !important",
            },
            '& .MuiSlider-markLabel[data-index="2"]': {
                left: args.locale === "de" && "43% !important",
            },
            '& .MuiSlider-markLabel[data-index="3"]': {
                left: args.locale === "de" && "62% !important",
            },
            '& .MuiSlider-markLabel[data-index="4"]': {
                left: args.locale === "de" && "84.3% !important",
            },
            '& .MuiSlider-markLabel[data-index="5"]': {
                left: args.locale === "de" && "103.3% !important",
            },
        };
    },
}));

const WineAdvices = (props) => {
    const locale = useSelector((state) => state?.user?.locale || "fr");

    const { width } = useWindowDimensions();
    const [frontAromasPopinOpen, setFrontAromasPopinOpen] = useState(false);
    const [frontFoodPairingPopinOpen, setFrontFoodPairingPopinOpen] = useState(false);
    const { data, loading } = useFrontVintageAdviceQuery({
        variables: { vintageId: props.vintageId, wineId: props.wineId, locale },
    });
    const advices = data?.FrontVintageAdvice;
    const styles = useStyles({ ...props, advices, locale });

    const isAromaValuesTruthy = (aromaValues) => {
        if (!aromaValues) {
            return false;
        }
        const keys = Object.keys(aromaValues);
        for (let i = 0; i < keys.length; i++) {
            const k = keys[i];
            if (aromaValues[k]) {
                return true;
            }
        }
        return false;
    };

    if (loading) {
        return (
            <Box display={"flex"} justifyContent={"center"} alignItems={"flex-end"}>
                <Loading loading={loading} />
            </Box>
        );
    }

    let limitCrossed = false;

    const appearanceSliderMarks = [
        {
            value: 0,
            label: t`Pâle`,
        },
        {
            value: 33,
            label: t`Claire`,
        },
        {
            value: 66,
            label: t`Soutenue`,
        },
        {
            value: 100,
            label: t`Intense`,
        },
    ];

    const getSliderValue = (baseArr, values) => {
        if (!values) {
            return null;
        }
        let finalVal = null;
        baseArr?.map((el, i) => {
            if (values === i + 1) {
                finalVal = el.value;
            }
        });
        return finalVal;
    };

    const noseSliderMarks = [
        {
            value: 0,
            label: t`Faible`,
        },
        {
            value: 20,
            label: t`Discrète`,
        },
        {
            value: 40,
            label: t`Aromatique`,
        },
        {
            value: 60,
            label: t`Développée`,
        },
        {
            value: 80,
            label: t`Expressive`,
        },
        {
            value: 100,
            label: t`Puissante`,
        },
    ];

    const palateSliderMarks = [
        {
            value: 0,
            label: t`Très faible`,
        },
        {
            value: 33,
            label: t`Faible`,
        },
        {
            value: 66,
            label: t`Moyenne`,
        },
        {
            value: 100,
            label: t`Forte`,
        },
    ];

    const getCategories = (aromas) => {
        if (!aromas) {
            return [];
        }
        const allKeys = Object.entries(aromas);
        let arr = [];
        allKeys.map((e) => {
            if (e[1] !== null) {
                return arr.push(e[0]);
            }
        });

        return arr;
    };

    const chartSeries = [
        {
            name: "Intensité /100",
            data: advices?.aromaValues ? Object.values(advices?.aromaValues).filter((e) => e !== null) : {},
        },
    ];
    const chartOptions = {
        chart: {
            type: "radar",
        },
        plotOptions: {
            radar: {
                polygons: {
                    fill: {
                        colors: [theme.palette.dashboard.background],
                    },
                    connectorColors: theme.palette.secondary.main,
                    strokeColors: theme.palette.common.white,
                },
            },
        },
        fill: {},
        colors: ["#FFEFF682"],

        stroke: {
            show: true,
            width: 1,
            colors: [theme.palette.primary.main],
            dashArray: 0,
        },
        markers: {
            strokeColors: theme.palette.primary.main,
            strokeWidth: 1,
            shape: "circle",
            colors: [theme.palette.primary.main],
        },
        yaxis: {
            max: 100,
        },
        xaxis: {
            categories: getCategories(advices?.aromaValues),
            labels: {
                style: {
                    colors: "#9F8D93",
                    fontSize: frontAromasPopinOpen ? "14px" : "16px",
                },
            },
        },
        tooltip: {
            enabled: false,
        },
    };

    const getRobeColor = () => {
        if (advices?.wineColor === "Blanc") {
            if (advices?.wineSweetness === "Moelleux") {
                return moerobe;
            } else if (advices?.wineSweetness === "Liquoreux") {
                return liqrobe;
            } else {
                return whiterobe;
            }
        } else if (advices?.wineColor === "Rouge") {
            return redrobe;
        } else if (advices?.wineColor === "Rosé") {
            if (advices?.wineLabel === "Bordeaux Clairet") {
                return clairetrobe;
            } else {
                return roserobe;
            }
        } else {
            return "";
        }
    };

    const getHeight = () => {
        if (width <= 700) {
            return 350;
        } else if (width < 960) {
            return 480;
        } else if (width >= 960) {
            return 290;
        } else if (width >= 1280) {
            return 300;
        } else {
            return 300;
        }
    };

    const setRadarChartHeight = () => {
        if (width < 960) return 200;
        else if (width >= 960 && width < 1920) return 280;
        return 215;
    };

    if (!isObjectDeepEmpty(advices)) {
        return (
            <Box className={styles.mainContainer} style={{ justifyContent: "space-between" }}>
                {frontAromasPopinOpen && (
                    <FrontAromasPopin
                        modalOpen={frontAromasPopinOpen}
                        handleClose={() => setFrontAromasPopinOpen(false)}
                        advices={advices}
                        chartOptions={chartOptions}
                        chartSeries={chartSeries}
                    />
                )}
                {frontFoodPairingPopinOpen && (
                    <FrontFoodPairingPopin
                        open={frontFoodPairingPopinOpen}
                        onClose={() => setFrontFoodPairingPopinOpen(false)}
                        foodPairings={advices?.foodPairingGroups}
                    />
                )}
                {/* VIDEO */}
                {advices?.videoUrl && (
                    <Box className={styles.videoContainer} style={{ backgroundColor: "white" }}>
                        <Box className={styles.typoContainer} style={{ top: "-22px" }}>
                            <img
                                src={video}
                                alt="title_icon"
                                style={{
                                    backgroundColor: "white",
                                    width: "35px",
                                    marginRight: "6px",
                                    marginLeft: "6px",
                                }}
                            />
                            <Typography variant="h3" className={styles.title}>
                                <Trans>Vidéo de dégustation</Trans>
                            </Typography>
                        </Box>
                        <Box className={styles.boxVideoAndDescription__wrapper}>
                            {advices?.videoUrl ? (
                                <ReactPlayer
                                    url={advices?.videoUrl}
                                    width={"100%"}
                                    height={getHeight()}
                                    className={styles.advicesVideo__wrapper}
                                />
                            ) : null}
                        </Box>
                    </Box>
                )}
                {/* COMMENT */}
                {advices?.description && (
                    <Box style={{ width: !advices?.videoUrl && "100%" }} className={styles.commentContainer}>
                        <Box className={styles.typoContainer}>
                            <img
                                src={comment}
                                alt="title_icon"
                                style={{
                                    backgroundColor: "white",
                                    width: "45px",
                                }}
                            />
                            <Typography variant="h3" className={styles.title}>
                                <Trans>Commentaire de dégustation</Trans>
                            </Typography>
                        </Box>
                        <Box className={styles.boxVideoAndDescription__wrapper}>
                            <Typography className={styles.comment__Typo} style={{ color: theme.palette.primary.dark }}>
                                {parseHtml(advices?.description)}
                            </Typography>
                        </Box>
                    </Box>
                )}

                {/* ACCORDS */}
                {advices?.foodPairingGroups && advices?.foodPairingGroups?.length !== 0 && (
                    <Box className={styles.blcContainer__accords}>
                        <Box className={styles.typoContainer}>
                            <img src={accord} alt="title_icon" className={styles.bigImage} />
                            <Typography variant="h3" className={styles.title}>
                                {" "}
                                <Trans> Accords mets et vins </Trans>
                            </Typography>
                        </Box>
                        <Box className={styles.flexWrap}>
                            {advices?.foodPairingGroups.map((el, i) => {
                                let foods = [];
                                if (el?.foods?.length > 5) {
                                    limitCrossed = true;
                                    el?.foods?.map((f, j) => {
                                        if (j < 5) {
                                            foods?.push(f);
                                        }
                                    });
                                }

                                if (i < 4) {
                                    return (
                                        <Box className={styles.blocksContainer__accords}>
                                            <Box className={styles.blocksTitle}>
                                                <img
                                                    src={el?.icon?.data}
                                                    className={styles.image1}
                                                    alt="icon"
                                                    style={{
                                                        height: "35px",
                                                    }}
                                                />
                                                <BaseChip title={firstLetterUppercase(el?.category)} />
                                            </Box>
                                            <Typography
                                                className={styles.typoAccord}
                                                style={{
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    color: theme.palette.primary.light,
                                                }}
                                            >
                                                {el?.foods?.length <= 5
                                                    ? el?.foods?.map((food, i) => {
                                                          return (
                                                              <Typography variant="h6" key={i}>
                                                                  {firstLetterUppercase(food)}
                                                                  {el?.foods?.length > 1 &&
                                                                      el?.foods?.length !== i + 1 && (
                                                                          <span>,&nbsp;</span>
                                                                      )}
                                                              </Typography>
                                                          );
                                                      })
                                                    : el?.foods?.slice(0, 5).map((food, i) => {
                                                          return (
                                                              <Typography variant="h6" key={i}>
                                                                  {firstLetterUppercase(food)}
                                                                  {el?.foods?.length > 1 && i !== 4 && (
                                                                      <span>,&nbsp;</span>
                                                                  )}
                                                                  {i === 4 && "..."}
                                                              </Typography>
                                                          );
                                                      })}
                                            </Typography>
                                        </Box>
                                    );
                                }
                            })}
                        </Box>
                        {(advices?.foodPairingGroups?.length > 5 || limitCrossed) && (
                            <Box
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginLeft: "10px",
                                    marginTop: theme.spacing(2),
                                }}
                            >
                                <img
                                    src={arrow}
                                    alt="img"
                                    style={{
                                        marginRight: theme.spacing(2),
                                    }}
                                />
                                <Link
                                    underline="always"
                                    onClick={() => setFrontFoodPairingPopinOpen(true)}
                                    className={styles.typoAccordLink}
                                    style={{
                                        fontFamily: "Lato",
                                        fontSize: "14px",
                                        color: "#915868",
                                    }}
                                >
                                    <Trans>Voir tous les accords mets et vins</Trans>
                                </Link>
                            </Box>
                        )}
                    </Box>
                )}

                <Box className={styles.secondPart}>
                    {/* SERVICES */}
                    {(advices?.apogee?.length > 0 ||
                        advices?.ageing?.length > 0 ||
                        advices?.treatmentBeforeService ||
                        advices?.decantingDuration ||
                        advices?.serviceTemperature) && (
                        <Box className={styles.blcContainer__services}>
                            <Box className={styles.typoContainer}>
                                <img src={service} alt="title_icon" className={styles.bigImage} />
                                <Typography variant="h3" className={styles.title}>
                                    <Trans> Service </Trans>
                                </Typography>
                            </Box>
                            <Box className={styles.containerService}>
                                {/* Apogee */}
                                {(advices?.apogee?.length > 0 || advices?.ageing?.length > 0) && (
                                    <Box className={styles.flexWrap}>
                                        <Box className={styles.blocksContainer}>
                                            <Box className={styles.blocksTitle}>
                                                <img
                                                    src={apogee}
                                                    className={styles.image1}
                                                    style={{
                                                        width: "16px",
                                                        marginRight: "4px",
                                                    }}
                                                    alt="icon"
                                                />
                                                <Typography className={styles.blocksTitleTypo}>
                                                    {props.vintageId ? (
                                                        <Trans>Apogée</Trans>
                                                    ) : (
                                                        <Trans>Garde moyenne</Trans>
                                                    )}
                                                </Typography>
                                            </Box>
                                            {props.vintageId ? (
                                                <Typography className={styles.typoApogee}>
                                                    {advices?.apogee?.[0] === advices?.apogee?.[1]
                                                        ? advices?.apogee?.[1]
                                                        : `${advices?.apogee?.[0]} -${" "}
                                                    ${advices?.apogee?.[1]}`}{" "}
                                                </Typography>
                                            ) : (
                                                <Typography className={styles.typoApogee}>
                                                    {advices?.ageing?.[0] === advices?.ageing?.[1]
                                                        ? advices?.ageing?.[1]
                                                        : `${advices?.ageing?.[0]} -${" "}
                                                    ${advices?.ageing?.[1]}`}{" "}
                                                    <Trans>ans</Trans>
                                                </Typography>
                                            )}
                                        </Box>
                                    </Box>
                                )}
                                {/* Avant service */}
                                {(advices?.treatmentBeforeService || advices?.decantingDuration) && (
                                    <Box className={styles.flexWrap}>
                                        <Box className={styles.blocksContainer}>
                                            <Box className={styles.blocksTitle}>
                                                <img
                                                    src={avantService}
                                                    className={styles.image1}
                                                    style={{ width: "16px" }}
                                                    alt="icon"
                                                />
                                                <Typography className={styles.blocksTitleTypo}>
                                                    <Trans>Avant service</Trans>
                                                </Typography>
                                            </Box>
                                            <Typography className={styles.typoApogeeAvantService}>
                                                {advices?.treatmentBeforeService
                                                    ? firstLetterUppercase(advices?.treatmentBeforeService)
                                                    : ""}{" "}
                                                {advices?.decantingDuration
                                                    ? `: ${advices?.decantingDuration} ${t`h`}`
                                                    : ""}
                                            </Typography>
                                        </Box>
                                    </Box>
                                )}
                                {/* Service temperature */}
                                {advices?.serviceTemperature && (
                                    <Box className={styles.flexWrap}>
                                        <Box className={styles.blocksContainer}>
                                            <Box className={styles.blocksTitle}>
                                                <img
                                                    src={thermo}
                                                    className={styles.image1}
                                                    style={{ width: "14px" }}
                                                    alt="icon"
                                                />
                                                <Typography className={styles.blocksTitleTypo}>
                                                    <Trans>Température de service</Trans>
                                                </Typography>
                                            </Box>
                                            <Typography
                                                className={styles.blocksTitleTypo}
                                                style={{
                                                    color: theme.palette.primary.dark,
                                                    fontSize: "16px",
                                                }}
                                            >
                                                {advices?.serviceTemperature} {t`°C`}
                                            </Typography>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    )}

                    {/* APPARENCE */}
                    {(advices?.appearanceIntensity ||
                        advices?.appearanceTears ||
                        advices?.appearanceColor ||
                        advices?.appearanceBrilliance ||
                        advices?.appearanceLimpidity) && (
                        <Box className={styles.blcContainer__apparence}>
                            <Box className={styles.typoContainer}>
                                <img src={apparence} alt="title_icon" className={styles.bigImage} />
                                <Typography variant="h3" className={styles.title}>
                                    <Trans> Apparence </Trans>
                                </Typography>
                            </Box>
                            <Box className={styles.flexWrap}>
                                {/* Apparence slider */}
                                {advices?.appearanceIntensity ? (
                                    <Box className={styles.blocksContainerSlider}>
                                        <Box className={styles.blocksTitle}>
                                            <Typography className={styles.blocksTitleTypo}>
                                                <Trans>Intensité de la robe</Trans>
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                marginTop: width < 600 && "10%",
                                            }}
                                        >
                                            <FrontSlider
                                                colorEnum
                                                marks={appearanceSliderMarks}
                                                value={getSliderValue(
                                                    appearanceSliderMarks,
                                                    advices?.appearanceIntensity
                                                )}
                                            />
                                        </Box>
                                    </Box>
                                ) : (
                                    ""
                                )}
                                {/* Apparence tears */}
                                <Box
                                    className={styles.containerApparence}
                                    style={{
                                        width: "100%",
                                        display: "flex",

                                        gap: "32px",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    {advices?.appearanceTears ? (
                                        <Box className={styles.flexWrap}>
                                            <Box className={styles.blocksContainer2}>
                                                <Box className={styles.blocksTitle}>
                                                    <img src={verretourne} className={styles.image1} alt="icon" />
                                                    <Typography className={styles.blocksTitleTypo}>
                                                        <Trans>Larmes</Trans>
                                                    </Typography>
                                                </Box>
                                                <Typography
                                                    style={{
                                                        color: theme.palette.primary.dark,
                                                    }}
                                                    className={styles.typoApparence}
                                                >
                                                    {advices?.appearanceTears}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    ) : (
                                        ""
                                    )}
                                    {/* Apparence robe */}
                                    {advices?.appearanceColor ? (
                                        <Box className={styles.flexWrap}>
                                            <Box className={styles.blocksContainer2}>
                                                <Box className={styles.blocksTitle}>
                                                    <img src={getRobeColor()} className={styles.image1} alt="icon" />
                                                    <Typography className={styles.blocksTitleTypo}>
                                                        <Trans>Robe</Trans>
                                                    </Typography>
                                                </Box>
                                                <Typography
                                                    style={{
                                                        color: theme.palette.primary.dark,
                                                    }}
                                                    className={styles.typoApparence}
                                                >
                                                    {advices?.appearanceColor}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    ) : (
                                        ""
                                    )}
                                    {/* Apparence robe */}
                                    {advices?.appearanceBrilliance ? (
                                        <Box className={styles.flexWrap}>
                                            <Box className={styles.blocksContainer2}>
                                                <Box className={styles.blocksTitle}>
                                                    <img src={clean} className={styles.image1} alt="icon" />
                                                    <Typography className={styles.blocksTitleTypo}>
                                                        <Trans>Brillance</Trans>
                                                    </Typography>
                                                </Box>
                                                <Typography
                                                    style={{
                                                        color: theme.palette.primary.dark,
                                                    }}
                                                    className={styles.typoApparence}
                                                >
                                                    {advices?.appearanceBrilliance}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    ) : (
                                        ""
                                    )}
                                    {advices?.appearanceLimpidity ? (
                                        <Box className={styles.flexWrap}>
                                            <Box className={styles.blocksContainer2}>
                                                <Box className={styles.blocksTitle}>
                                                    <img src={verre} className={styles.image1} alt="icon" />
                                                    <Typography className={styles.blocksTitleTypo}>
                                                        <Trans>Limpidité</Trans>
                                                    </Typography>
                                                </Box>
                                                <Typography
                                                    style={{
                                                        color: theme.palette.primary.dark,
                                                    }}
                                                    className={styles.typoApparence}
                                                >
                                                    {advices?.appearanceLimpidity}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    ) : (
                                        ""
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    )}

                    {/* NOSE */}
                    {(advices?.noseIntensity || advices?.noseAromas?.length) && (
                        <Box className={styles.blcContainer__nez}>
                            <Box className={styles.typoContainer}>
                                <img src={nez} alt="title_icon" className={styles.bigImage} />
                                <Typography variant="h3" className={styles.title}>
                                    <Trans> Nez </Trans>
                                </Typography>
                            </Box>
                            <Box className={styles.flexWrap}>
                                {/* Nose slider */}
                                {advices?.noseIntensity ? (
                                    <Box
                                        className={[
                                            styles.blocksContainerSlider,
                                            styles.blocksContainerSliderNose,
                                        ].join(" ")}
                                    >
                                        <Box className={styles.blocksTitle}>
                                            <Typography className={styles.blocksTitleTypo}>
                                                <Trans>Intensité olfactive</Trans>
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                marginTop: width < 600 && "10%",
                                            }}
                                        >
                                            <FrontSlider
                                                colorEnum
                                                disabled
                                                marks={noseSliderMarks}
                                                value={getSliderValue(noseSliderMarks, advices?.noseIntensity)}
                                                sliderClassname={styles.sliderNose}
                                            />
                                        </Box>
                                    </Box>
                                ) : null}
                                {advices?.noseAromas && advices?.noseAromas?.length !== 0 && (
                                    <Box className={styles.flexWrap}>
                                        <Box className={styles.blocksContainer}>
                                            <Box className={styles.blocksTitle}>
                                                <img
                                                    src={baies}
                                                    className={styles.image1}
                                                    style={{
                                                        width: "25px",
                                                    }}
                                                    alt="icon"
                                                />
                                                <Typography className={styles.blocksTitleTypo}>
                                                    <Trans>Arômes olfactifs</Trans>
                                                </Typography>
                                            </Box>
                                            <Typography
                                                className={styles.typoNoiseAromas}
                                                style={{
                                                    marginTop: "8px",
                                                }}
                                            >
                                                {getChips(advices?.noseAromas, true)}
                                            </Typography>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    )}

                    {/* PALAIS */}
                    {(advices?.palateIntensity ||
                        advices?.palateAttack ||
                        advices?.palateAcidity ||
                        advices?.palateLength ||
                        advices?.palateTannins ||
                        isAromaValuesTruthy(advices?.aromaValues) ||
                        advices?.aromaAnimal ||
                        advices?.aromaVegetal ||
                        advices?.aromaBalsamic ||
                        advices?.aromaConfectionery ||
                        advices?.aromaEmpyreumatic ||
                        advices?.aromaFermented ||
                        advices?.aromaFloral ||
                        advices?.aromaFruity ||
                        advices?.aromaMineral ||
                        advices?.aromaOaky ||
                        advices?.aromaSpicy) && (
                        <Box className={styles.blcContainer__palais}>
                            <Box className={styles.typoContainer}>
                                <img src={palais} alt="title_icon" className={styles.bigImage} />
                                <Typography variant="h3" className={styles.title}>
                                    <Trans> Palais </Trans>
                                </Typography>
                            </Box>
                            <Box className={styles.flexWrap} style={{ marginBottom: theme.spacing(2) }}>
                                {/* palate slider */}
                                {advices?.palateIntensity && (
                                    <Box className={styles.blocksContainerSlider}>
                                        <Box className={styles.blocksTitle}>
                                            <Typography className={styles.blocksTitleTypo}>
                                                <Trans>Intensité gustative</Trans>
                                            </Typography>
                                        </Box>
                                        <Box
                                            style={{
                                                marginTop: width < 600 && "10%",
                                            }}
                                        >
                                            <FrontSlider
                                                colorEnum
                                                marks={palateSliderMarks}
                                                value={getSliderValue(palateSliderMarks, advices?.palateIntensity)}
                                            />
                                        </Box>
                                    </Box>
                                )}
                                <Box
                                    className={styles.containerApparence}
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        gap: "32px",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    {advices?.palateAttack && (
                                        <Box className={styles.flexWrap} style={{ marginBottom: "16px" }}>
                                            <Box className={styles.blocksContainer2}>
                                                <Box className={styles.blocksTitle}>
                                                    <img
                                                        src={drapeau}
                                                        className={styles.image1}
                                                        style={{
                                                            width: "14px",
                                                        }}
                                                        alt="icon"
                                                    />
                                                    <Typography className={styles.blocksTitleTypo}>
                                                        <Trans>Attaque</Trans>
                                                    </Typography>
                                                </Box>
                                                <Typography
                                                    style={{
                                                        color: theme.palette.primary.dark,
                                                    }}
                                                    className={styles.typoPalais}
                                                >
                                                    {advices?.palateAttack}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )}
                                    {advices?.palateAcidity && (
                                        <Box className={styles.flexWrap} style={{ marginBottom: "16px" }}>
                                            <Box className={styles.blocksContainer2}>
                                                <Box className={styles.blocksTitle}>
                                                    <img src={agrume} className={styles.image1} alt="icon" />
                                                    <Typography className={styles.blocksTitleTypo}>
                                                        <Trans>Acidité</Trans>
                                                    </Typography>
                                                </Box>
                                                <Typography
                                                    style={{
                                                        color: theme.palette.primary.dark,
                                                    }}
                                                    className={styles.typoPalais}
                                                >
                                                    {advices?.palateAcidity}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )}
                                    {advices?.palateLength && (
                                        <Box className={styles.flexWrap} style={{ marginBottom: "16px" }}>
                                            <Box className={styles.blocksContainer2}>
                                                <Box className={styles.blocksTitle}>
                                                    <img src={chronometer} className={styles.image1} alt="icon" />
                                                    <Typography className={styles.blocksTitleTypo}>
                                                        <Trans>Longueur</Trans>
                                                    </Typography>
                                                </Box>
                                                <Typography
                                                    style={{
                                                        color: theme.palette.primary.dark,
                                                    }}
                                                    className={styles.typoPalais}
                                                >
                                                    {advices?.palateLength}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )}
                                    {advices?.palateTannins && (
                                        <Box className={styles.flexWrap} style={{ marginBottom: "16px" }}>
                                            <Box className={styles.blocksContainer2}>
                                                <Box className={styles.blocksTitle}>
                                                    <img src={raisin} className={styles.image1} alt="icon" />
                                                    <Typography className={styles.blocksTitleTypo}>
                                                        <Trans>Tanins</Trans>
                                                    </Typography>
                                                </Box>
                                                <Typography
                                                    style={{
                                                        color: theme.palette.primary.dark,
                                                    }}
                                                    className={styles.typoPalais}
                                                >
                                                    {advices?.palateTannins}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                            {(isAromaValuesTruthy(advices?.aromaValues) ||
                                advices?.aromaAnimal ||
                                advices?.aromaVegetal ||
                                advices?.aromaBalsamic ||
                                advices?.aromaConfectionery ||
                                advices?.aromaEmpyreumatic ||
                                advices?.aromaFermented ||
                                advices?.aromaFloral ||
                                advices?.aromaFruity ||
                                advices?.aromaMineral ||
                                advices?.aromaOaky ||
                                advices?.aromaSpicy) && (
                                <Box>
                                    <Box className={styles.boxRouedesAromes}>
                                        <Box className={styles.blocksTitle}>
                                            <img src={gustatif} className={styles.image1} alt="icon" />
                                            <Typography className={styles.blocksTitleTypo}>
                                                <Trans>Arômes gustatifs</Trans>
                                            </Typography>
                                        </Box>
                                        <Box className={styles.palateRadar}>
                                            {isAromaValuesTruthy(advices?.aromaValues) && (
                                                <div className={styles.radarChart}>
                                                    <RadarChart
                                                        options={chartOptions}
                                                        series={chartSeries}
                                                        height={setRadarChartHeight()}
                                                    />
                                                </div>
                                            )}
                                            {width < 600 && (
                                                <Box
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        marginLeft: "10px",
                                                    }}
                                                >
                                                    <img
                                                        src={arrow}
                                                        alt="img"
                                                        style={{
                                                            marginRight: theme.spacing(2),
                                                        }}
                                                    />
                                                    <Link
                                                        underline="always"
                                                        onClick={() => setFrontAromasPopinOpen(true)}
                                                        className={styles.typoLinkPalais}
                                                    >
                                                        <Trans>Voir tous les arômes gustatifs</Trans>
                                                    </Link>
                                                </Box>
                                            )}
                                            {width > 600 &&
                                                (advices?.aromaAnimal ||
                                                    advices?.aromaVegetal ||
                                                    advices?.aromaBalsamic ||
                                                    advices?.aromaConfectionery ||
                                                    advices?.aromaEmpyreumatic ||
                                                    advices?.aromaFermented ||
                                                    advices?.aromaFloral ||
                                                    advices?.aromaFruity ||
                                                    advices?.aromaMineral ||
                                                    advices?.aromaOaky ||
                                                    advices?.aromaSpicy) && (
                                                    <Box
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            marginLeft: "10px",
                                                        }}
                                                    >
                                                        <img
                                                            src={arrow}
                                                            alt="img"
                                                            style={{
                                                                marginRight: theme.spacing(2),
                                                            }}
                                                        />
                                                        <Link
                                                            underline="always"
                                                            onClick={() => setFrontAromasPopinOpen(true)}
                                                            className={styles.typoLinkPalais}
                                                        >
                                                            <Trans>Voir tous les arômes gustatifs</Trans>
                                                        </Link>
                                                    </Box>
                                                )}
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
        );
    } else {
        return (
            <Typography style={{ fontSize: "16px", padding: "16px" }}>
                <Trans>Information non renseignée</Trans>
            </Typography>
        );
    }
};

WineAdvices.propTypes = {
    vintageId: PropTypes.string,
    wineName: PropTypes.string,
    year: PropTypes.number,
    aoc: PropTypes.string,
    score: bool,
};

export default WineAdvices;
