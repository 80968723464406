import { Box, Button, Typography, makeStyles } from "@material-ui/core";
import theme from "../globalTheme";
import imgLetterFeretBg from "../assets/feretLetter/letter_ferret_fioritures.png";
import imgLetterFeretTxt from "../assets/feretLetter/letter_feret_logo.png";
import imgLetterFeretCardOne from "../assets/feretLetter/feret_banner_wine_fields.png";
import imgFeretArrow from "../assets/feretLetter/ferret_arrow.png";
import imgBenedicte from "../assets/feretLetter/feret_benedicte_cover.webp";
import imgDegustation from "../assets/feretLetter/feret_tasters.jpg";
import imgFioritureBL from "../assets/feretLetter/bg_letter_feret.svg";
import imgFioritureBC from "../assets/feretLetter/fioHeader.svg";

import imgLetterFeretTwo from "../assets/feretLetter/editions/two/hero_index_letter_feret.webp";
import imgLetterFeretTwoSpotlight from "../assets/feretLetter/editions/two/hero_index_spotlight.webp";
import imgLetterFeretTwoNews from "../assets/feretLetter/editions/two/hero_index_news.webp";
import imgLetterFeretTwoInnovation from "../assets/feretLetter/editions/two/hero_index_innovation.webp";

import imgLogo from "../assets/navbarfront/logoFrt.svg";
import imgLogoInnovin from "../assets/feretLetter/editions/two/innovin.png";
import { useCustomHistory } from "hooks";
import { Link } from "react-router-dom";
import { t, Trans } from "@lingui/macro";

const useStyles = makeStyles((e) => ({
    feretLetterHeader: {
        fontFamily: "Playfair Display",
        backgroundImage: `url(${imgFioritureBC})`,
        backgroundSize: "auto 100%",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "10px",
        [theme.breakpoints.up("md")]: {
            backgroundPositionX: "75px",
        },
        minHeight: "250px",
        paddingBottom: "100px",
        width: "100%"
    },
    feretLetterBody: {
        padding: "0 10px",
        maxWidth: "1100px",
        margin: "0 auto",
    },
    indexContainer: {
        backgroundImage: `url(${imgFioritureBL})`,
        backgroundSize: "auto 100%",
        padding: "40px 20px",
        [theme.breakpoints.up("md")]: {
            padding: "40px",
        }
    },
    indexCardLink: {
        color: "white",
        textDecoration: "none"
    },
    indexCard: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0", 
        textDecoration: "none",

    },
    indexCardImageWrapper: {
        height: "250px"
    },
    indexCardImage: {
        minHeight: "250px",
        maxHeight: "250px",
        objectFit: "cover",
        objectPosition: "center",
        minWidth: "100%",
        maxWidth: "100%"
    },
    indexCardTextWrapper: {
        padding: "20px",
        color: "#fff",
        backgroundColor: "#915868"
    },
    indexCardText: {
        textAlign: "justify",
        [theme.breakpoints.up("md")]: {
            textAlign: "left"
        }
    },
    indexCardArrowWrapper: {
        width: "100%", 
        display: "flex",
        flexDirection: "row-reverse"
    }, 
    indexCardArrow: {
        width: "100px"
    },
    gridCards: {
        position: "relative",
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "repeat(2, 1fr)",
        gap: "25px",
        [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
            gridTemplateRows: "1fr",
        }

    },
    feretLetterSubHeader: {
        color: theme.palette.primary.main,
        margin: "0 0 1em 0"
    }

}));

const FeretLetterIndexPage = (props) => {
    const styles = useStyles(props, theme); 
    const history = useCustomHistory();

    const redirection = () => {
        window.open("https://501d5642.sibforms.com/serve/MUIFAHE1rH_H4jYB-3b9JSYq6XUdpjLdOpsafgJIfbiyeucjB7bHgXvaoix9BVPjCY_nIvNEQmSiBQRAcP5-dPCEJ2dpIVgNQUPrfHt6r2R6guuKE3d-pWUNZI-1OjoprqbmQNypZe9k1umeYJEqRZ_qBh6SeUCgBgQIEHNTa6P8jjcc_deJ5anuEUL4KrRZncDzURop2Ox36EUg", "_blank");
    };

    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                gap={4}
                className={ styles.feretLetterHeader }
            >  
                <img src={ imgLetterFeretTxt } alt="" style={{ width: "300px", margin: "0 auto" }} />
            </Box>

            <Box className={ styles.indexContainer } style={{ position: "relative", maxWidth:"1100px", margin: "-100px auto 3em auto", borderRadius: "1em"}}>

                <Box>
                    <Typography className={ styles.feretLetterSubHeader } variant="h2">La Lettre Féret n°2 - juillet 2024</Typography>
                </Box>

                <Link to={ history.addLocale("/la-lettre-feret-ed-2#editorial") } className={styles.indexCardLink}>
                    <Box className={ styles.indexCard } style={{ marginBottom: "25px" }}>
                        <Box className={styles.indexCardImageWrapper}>
                            <img className={ styles.indexCardImage} alt="" src={ imgLetterFeretTwo } style={{ objectPosition: "bottom" }} onDragStart={ (e) => { e.preventDefault(); } } />
                        </Box>

                        <Box className={ styles.indexCardTextWrapper }>
                            <Typography variant="h3">Éditorial : &quot;La Lettre Féret n°2 : Une caisse de résonance&quot;</Typography>
                            <br/>
                            <Typography className={ styles.indexCardText } paragraph>
                                Vous l&apos;attendiez avec impatience, voici notre deuxième Lettre !
                                <br/>
                                Plus que jamais, nous avons choisi d&apos;être votre porte-voix afin de mettre en lumière vos actualités. Au programme : deux nouvelles rubriques que vous retrouverez tous les trimestres et un coup de projecteur tout en douceur.
                            </Typography>
                            <Box className={ styles.indexCardArrowWrapper}>
                                    <img className={ styles.indexCardArrow } alt="" src={ imgFeretArrow } onDragStart={ (e) => { e.preventDefault(); } } />
                            </Box>
                        </Box>
                    </Box>
                </Link>

                <Link to={ history.addLocale("/la-lettre-feret-ed-2#coup-de-projecteur") } className={styles.indexCardLink}>
                    <Box className={ styles.indexCard } style={{ marginBottom: "25px" }}>
                        <Box className={styles.indexCardImageWrapper}>
                            <img className={ styles.indexCardImage} alt="" src={ imgLetterFeretTwoSpotlight } onDragStart={ (e) => { e.preventDefault(); } } />
                        </Box>

                        <Box className={ styles.indexCardTextWrapper } style={{ backgroundColor: "#C58E9E", height: "100%" }}>
                            <Typography variant="h3" style={{ lineHeight: "1.5em" }}>Coup de Projecteur sur Marie Lefévère : <br/><i>« Il faut remettre le mot convivialité au cœur de Bordeaux.»</i></Typography>
                            <br/>
                            <Typography className={ styles.indexCardText } paragraph>
                                Féret part à la rencontre de Marie Lefévère, propriétaire des châteaux Sansonnet, Villemaurine, Soutard-Cadet et Moulin du Cadet.
                            </Typography>
                            <Box className={ styles.indexCardArrowWrapper}>
                                    <img className={ styles.indexCardArrow } alt="" src={ imgFeretArrow } onDragStart={ (e) => { e.preventDefault(); } } />
                            </Box>
                        </Box>
                    </Box>
                </Link>

                <Box className={ styles.gridCards }>
                    <Box>
                        <Link to={ history.addLocale("/la-lettre-feret-ed-2#actualites") } className={styles.indexCardLink}>
                            <Box className={ styles.indexCard } style={{ height: "100%" }}>
                                <Box className={styles.indexCardImageWrapper}>
                                    <img className={ styles.indexCardImage} alt="" src={ imgLetterFeretTwoNews } style={{ objectPosition: "top", height: "200px"}} onDragStart={ (e) => { e.preventDefault(); } } />
                                </Box>

                                <Box className={ styles.indexCardTextWrapper } style={{ backgroundColor: theme.palette.primary.main }}>
                                    <Typography variant="h3">L&apos;actualité des abonnés <img width="100" src={imgLogo} alt="" style={{ display: "inline-block", margin: "0 0 0 0.1em" }} /></Typography>
                                    <br/>
                                    <Typography className={ styles.indexCardText } paragraph>
                                        À travers cette nouvelle rubrique, Féret se fait l&apos;écho de vos actualités et des nouveautés du secteur.
                                    </Typography>
                                    <Box className={ styles.indexCardArrowWrapper} style={{ flexGrow: "1"}}>
                                            <img className={ styles.indexCardArrow } alt="" src={ imgFeretArrow } onDragStart={ (e) => { e.preventDefault(); } } />
                                    </Box>
                                </Box>
                            </Box>
                        </Link>
                    </Box>

                    <Box>
                        <Link to={ history.addLocale("/la-lettre-feret-ed-2#innovation") } className={styles.indexCardLink}>
                            <Box className={ styles.indexCard }>
                                <Box className={styles.indexCardImageWrapper}>
                                    <img className={ styles.indexCardImage} alt="" src={ imgLetterFeretTwoInnovation } onDragStart={ (e) => { e.preventDefault(); } } />
                                </Box>

                                <Box className={ styles.indexCardTextWrapper } style={{ backgroundColor: "#b1c4af", height: "100%" }}>
                                    <Typography paragraph variant="h3" style={{ display: "inline-block", alignContent: "center", verticalAlign: "center" }}>Les innovations du mois avec <img width="100" src={imgLogoInnovin} alt="" style={{ display: "inline-block", verticalAlign:"center", margin: "0 0 0 0.1em", height: "100%" }}/> </Typography>
                                    <br/>
                                    <Typography className={ styles.indexCardText } paragraph>
                                        Avec notre partenaire INNO’VIN nous évoquons ici les innovations technologiques et autres initiatives au service de la filière.
                                    </Typography>
                                    <Box className={ styles.indexCardArrowWrapper}>
                                            <img className={ styles.indexCardArrow } alt="" src={ imgFeretArrow } onDragStart={ (e) => { e.preventDefault(); } } />
                                    </Box>
                                </Box>
                            </Box>
                        </Link>
                    </Box>
                </Box>
            </Box>

            <Box className={ styles.indexContainer } style={{ position: "relative", maxWidth:"1100px", margin: "0 auto 3em auto", borderRadius: "1em"}}>

                <Box>
                    <Typography className={ styles.feretLetterSubHeader } variant="h2">La Lettre Féret n°1 - mai 2024</Typography>
                </Box>

                <Link to={ history.addLocale("/la-lettre-feret#editorial") } className={styles.indexCardLink}>
                    <Box className={ styles.indexCard } style={{ marginBottom: "25px" }}>
                        <Box className={styles.indexCardImageWrapper}>
                            <img className={ styles.indexCardImage} alt="" src={ imgLetterFeretCardOne } onDragStart={ (e) => { e.preventDefault(); } } />
                        </Box>

                        <Box className={ styles.indexCardTextWrapper }>
                            <Typography variant="h3">Éditorial : &quot;La Lettre Féret n°1 : À marquer d&apos;une pierre blanche&quot;</Typography>
                            <br/>
                            <Typography className={ styles.indexCardText } paragraph>
                                Un millésime tout en (belles) surprises comme le sera, nous l&apos;imaginons, l&apos;arrivée sur vos écrans de la « Lettre Féret ».
                                <br/>
                                Tous les trimestres, cette « Lettre Féret » offrira une mine d&apos;informations précieuses pour les amateurs et les professionnels du vin, en fournissant des mises à jour sur les châteaux, les nouveaux millésimes ainsi que des analyses et des avis d&apos;experts. 
                            </Typography>
                            <Box className={ styles.indexCardArrowWrapper}>
                                    <img className={ styles.indexCardArrow } alt="" src={ imgFeretArrow } onDragStart={ (e) => { e.preventDefault(); } } />
                            </Box>
                        </Box>
                    </Box>
                </Link>

                <Box className={ styles.gridCards }>
                    <Box>
                        <Link to={ history.addLocale("/la-lettre-feret#benedicte-martre") } className={styles.indexCardLink}>
                            <Box className={ styles.indexCard } style={{ height: "100%" }}>
                                <Box className={styles.indexCardImageWrapper}>
                                    <img className={ styles.indexCardImage} alt="" src={ imgBenedicte } style={{ objectPosition: "top", height: "200px"}} onDragStart={ (e) => { e.preventDefault(); } } />
                                </Box>

                                <Box className={ styles.indexCardTextWrapper } style={{ backgroundColor: "#b1c4af", height: "100%" }}>
                                    <Typography variant="h3">Coup de Projecteur sur Bénédicte Martre</Typography>
                                    <br/>
                                    <Typography className={ styles.indexCardText } paragraph>
                                        Féret part à la rencontre de Bénédicte Martre, directrice RSE-QHSE & Communication des Vignobles de Larose. 
                                    </Typography>
                                    <Box className={ styles.indexCardArrowWrapper} style={{ flexGrow: "1"}}>
                                            <img className={ styles.indexCardArrow } alt="" src={ imgFeretArrow } onDragStart={ (e) => { e.preventDefault(); } } />
                                    </Box>
                                </Box>
                            </Box>
                        </Link>
                    </Box>

                    <Box>
                        <Link to={ history.addLocale("/la-lettre-feret#notation-millesime") } className={styles.indexCardLink}>
                            <Box className={ styles.indexCard }>
                                <Box className={styles.indexCardImageWrapper}>
                                    <img className={ styles.indexCardImage} alt="" src={ imgDegustation } onDragStart={ (e) => { e.preventDefault(); } } />
                                </Box>

                                <Box className={ styles.indexCardTextWrapper } style={{ backgroundColor: theme.palette.primary.main }}>
                                    <Typography paragraph variant="h3" style={{ display: "inline-block", alignContent: "center" }}>La notation du millésime par <img width="100" src={imgLogo} alt="" style={{ display: "inline-block", margin: "0 0 0 0.1em" }} /></Typography>
                                    <br/>
                                    <Typography className={ styles.indexCardText } paragraph>
                                        Féret, c&apos;est une première, revêt l&apos;habit d&apos;un prescripteur passionné et légitime de plus de 200 ans d&apos;expertise avec la présentation de notes de dégustations primeurs.
                                    </Typography>
                                    <Box className={ styles.indexCardArrowWrapper}>
                                            <img className={ styles.indexCardArrow } alt="" src={ imgFeretArrow } onDragStart={ (e) => { e.preventDefault(); } } />
                                    </Box>
                                </Box>
                            </Box>
                        </Link>
                    </Box>

                </Box>
            </Box>

            <Box style={{ fontFamily: "Playfair Display", margin: "80px 0 0 0", position: "sticky", bottom:"0", padding: "20px 0", borderTop: "#EFEFEF solid 1px", display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "center", gap: "1.5em", backgroundImage: `url(${imgLetterFeretBg})`, backgroundSize: "cover"  }}>
                <Button onClick={ () => { redirection(); } } color="primary" variant="contained" size="large" style={{ fontFamily: "Playfair Display", fontSize:"22px"}}><Trans>S&apos;abonner à La Lettre Féret</Trans></Button>
            </Box>
        </>
    );
};

export default FeretLetterIndexPage;
