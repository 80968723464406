import PropTypes from "prop-types";
import { useWindowDimensions } from "../../helpers/helpers";
import theme from "../../globalTheme";
import { Typography } from "@material-ui/core";

const ButtonMoreFilters = (props) => {
    const { width } = useWindowDimensions();

    return (
        <button
            style={{
                backgroundColor: theme.palette.common.white,
                border: `0.4px solid ${theme.palette.secondary.main}`,
                borderRadius: 53,
                height: 34,
                width: "100%",
                cursor: "pointer",
            }}
            onClick={props?.onClick}
        >
            <Typography variant="h6">{width > 600 ? "+ de filtres" : "Filtrer"}</Typography>
        </button>
    );
};

ButtonMoreFilters.propTypes = {
    onClick: PropTypes.func,
};

export default ButtonMoreFilters;
