import { makeStyles, Typography, Box } from "@material-ui/core";
import PropTypes, { string } from "prop-types";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { firstLetterUppercase } from "../../../helpers/helpers";
import redGrape from "../../../assets/Dashboard/redGrape.svg";
import whiteGrape from "../../../assets/Dashboard/whiteGrape.svg";
import theme from "../../../globalTheme";
import rewardImage from "../../../assets/Dashboard/reward.svg";
import scoreImage from "../../../assets/vin_score.svg";
import { t, Trans } from "@lingui/macro";
import Tooltip from "../../../Components/Tooltips/Tooltip";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    mediumWineCard: (props) => ({
        alignItems: "space-between",
        cursor: "pointer",
        backgroundColor: theme.palette.common.white,
        border: "0.4px solid",
        borderColor: theme.palette.secondary.main,
        borderRadius: "10px",
        display: "flex",
        position: "relative",
        textDecoration: "none",
        [theme.breakpoints.up("xs")]: {
            gap: "8px",
            padding: "8px",
        },
        [theme.breakpoints.up("sm")]: {
            gap: "16px",
            padding: "16px",
        },
    }),
    mediumWineCard__img01: (props) => ({
        height: "40px",
        position: "absolute",
        right: 0,
        top: 0,
        width: props.wineColorFr === "Blanc" ? "40px" : "50px",
    }),
    mediumWineCard__boxImgBottlWine: {
        [theme.breakpoints.up("xs")]: {
            width: "32px",
        },
        [theme.breakpoints.up("sm")]: {
            width: "42px",
            height: "104px",
        },
    },
    mediumWineCard__img02: {
        [theme.breakpoints.up("xs")]: {
            position: "relative",
            width: "40px",
            // width: "inherit",

            height: "148px",
        },
        [theme.breakpoints.up("sm")]: {
            position: "absolute",
            bottom: "2px",
            // width: "inherit",
        },
        [theme.breakpoints.up("md")]: {
            bottom: "15px",
        },
    },
    mediumWineCard__content: {
        width: "100%",
        zIndex: "1",
        display: "flex",
        flexDirection: "column",
    },

    mediumWineCard__rank: {
        ...theme.front.typography.label.darkLight,
    },
    mediumWineCard__body: {
        flex: "1",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },

    mediumWineCard__details01: {
        alignItems: "center",
        display: "flex",
        gap: "4px",
    },
    mediumWineCard__wineColor: {
        color: "#360318",
        fontSize: "16px",
    },
    mediumWineCard__bulletPoint: {
        ...theme.front.typography.labeur.dark,
        fontSize: "14px",
    },
    mediumWineCard__aoc: {
        fontSize: "16px",
        color: "#360318",
    },
    mediumWineCard__details02: (props) => ({
        alignItems: "center",
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
    }),
    mediumWineCard__boxReward: {
        display: "flex",
        alignItems: "center",
    },
    mediumWineCard__reward: {
        width: "30px",
        height: "40px",
        marginRight: theme.spacing(1),
    },
    mediumWineCard__textReward: {
        color: theme.palette.primary.light,
        fontSize: "16px",
    },
    mediumWineCard__link: {
        alignItems: "center",
        color: theme.palette.primary.light,
        display: "flex",
        gap: "4px",
    },
    mediumWineCard__arrowIcon: {
        color: "#9F8D93",
    },
}));

const MediumWineCard = (props) => {
    const styles = useStyles(props);

    const getImage = () => {
        if (props.wineColorFr === "Rouge" || props.wineColorFr === "Rosé") {
            return <img src={redGrape} alt="grape" className={styles.mediumWineCard__img01} />;
        } else if (props.wineColorFr === "Blanc") {
            return <img src={whiteGrape} alt="grape" className={styles.mediumWineCard__img01} />;
        } else {
            return;
        }
    };
    return (
        <Box className={styles.mediumWineCard} component={Link} to={props.to}>
            {props.wineColor && getImage()}
            {props.image && (
                <Box className={styles.mediumWineCard__boxImgBottlWine}>
                    <img
                        className={styles.mediumWineCard__img02}
                        src={props.image || ""}
                        alt={`${props.name} - ${props.wineEntityName}`}
                    />
                </Box>
            )}
            <Box className={styles.mediumWineCard__content}>
                {(props.name || props.rank) && (
                    <Box className={styles.mediumWineCard__header}>
                        {props.name && (
                            <Typography
                                variant="h4"
                                className={styles.mediumWineCard__title}
                                style={{
                                    fontWeight: "bold",
                                    color: theme.palette.common.black,
                                }}
                            >
                                {props.name}
                            </Typography>
                        )}
                        {props.wineRanking && (
                            <Typography className={styles.mediumWineCard__wineRank} style={{ color: "#360318" }}>
                                {props.wineRanking}
                            </Typography>
                        )}
                        {props.rank && props.rankFr !== "autre" && (
                            <Typography className={styles.mediumWineCard__rank}>
                                {firstLetterUppercase(props.rank)}
                            </Typography>
                        )}
                    </Box>
                )}
                {(props.aoc || props.wineColor || props.hasRewards) && (
                    <Box className={styles.mediumWineCard__body}>
                        {(props.aoc || props.wineColor) && (
                            <Box className={styles.mediumWineCard__details01}>
                                {props.wineColor && props.shouldMentionColor && (
                                    <Typography className={styles.mediumWineCard__wineColor}>
                                        {" "}
                                        {props.wineColor}{" "}
                                    </Typography>
                                )}
                                {props.aoc && props.shouldMentionColor && props.wineColor && (
                                    <Box className={styles.mediumWineCard__bulletPoint}>{" · "}</Box>
                                )}
                                {props.sweetness && props.shouldMentionSweetness && (
                                    <Typography className={styles.mediumWineCard__wineColor}>
                                        {" "}
                                        {props.sweetness}{" "}
                                    </Typography>
                                )}
                                {props.aoc && props.sweetness && props.shouldMentionSweetness && (
                                    <Box className={styles.mediumWineCard__bulletPoint}>{" · "}</Box>
                                )}
                                {props.aoc && (
                                    <Typography variant="h6" className={styles.mediumWineCard__aoc}>
                                        {" "}
                                        {props.aoc}{" "}
                                    </Typography>
                                )}
                            </Box>
                        )}
                        <Box className={styles.mediumWineCard__details02}>
                            <Box style={{ display: "flex" }}>
                                <Box className={styles.mediumWineCard__boxReward}>
                                    {props.hasRewards && (
                                        <Box className={styles.mediumWineCard__boxReward}>
                                            <Tooltip title={t`Vin récompensé`} color="primary" arrow>
                                                <img
                                                    src={rewardImage}
                                                    alt="reward_image"
                                                    className={styles.mediumWineCard__reward}
                                                />
                                            </Tooltip>
                                            {props.rankFr === "premier vin" && (
                                                <Typography className={styles.mediumWineCard__textReward}>
                                                    {t`Vin récompensé`}
                                                </Typography>
                                            )}
                                        </Box>
                                    )}
                                </Box>
                                <Box className={styles.mediumWineCard__boxReward}>
                                    {props.hasScores && (
                                        <Box className={styles.mediumWineCard__boxReward}>
                                            <Tooltip title={t`Vin noté`} color="primary" arrow>
                                                <img
                                                    src={scoreImage}
                                                    alt="score_image"
                                                    className={styles.mediumWineCard__reward}
                                                />
                                            </Tooltip>
                                            {props.rankFr === "premier vin" && (
                                                <Typography className={styles.mediumWineCard__textReward}>
                                                    {t`Vin noté`}
                                                </Typography>
                                            )}
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                            <Box className={styles.mediumWineCard__boxLink}>
                                <Link to={props.to} className={styles.mediumWineCard__link}>
                                    <Typography
                                        className={styles.mediumWineCard__typoLink}
                                        style={{
                                            fontSize: "14px",
                                            color: "#9F8D93",
                                        }}
                                    >
                                        <Trans>Découvrir</Trans>
                                    </Typography>
                                    <ArrowForwardIcon className={styles.mediumWineCard__arrowIcon} />
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

MediumWineCard.propTypes = {
    wineColor: PropTypes.string,
    wineColorFr: PropTypes.string,
    rank: PropTypes.string,
    rankFr: PropTypes.string,
    name: PropTypes.string,
    aoc: PropTypes.string,
    image: PropTypes.string,
    to: string,
    hasVintages: PropTypes.bool,
    hasRewards: PropTypes.bool,
    hasScores: PropTypes.bool,
    sweetness: PropTypes.string,
    wineRanking: PropTypes.string,
    shouldMentionSweetness: PropTypes.bool,
    shouldMentionColor: PropTypes.bool,
    wineEntityName: string,
};

export default MediumWineCard;
