import { makeStyles, Box } from "@material-ui/core";
import React from "react";
import theme from "../globalTheme";
import BreadCrumbs from "../Components/Breadcrumbs/BreadCrumbs";
interface FrontContentTopProps {
    estate?: any;
    children: React.ReactNode;
    boxStyles?: any;
    firstPart?: object;
    backgroundImage?: string;
    backgroundColor?: string;
    links?: { name: string; path?: string }[];
    cropBackground?: boolean;
    previousPage?: { name: string; path?: string };
    overridesClassName?: any;
    styleOverridesClassName?: any;
}

const useStyles = makeStyles(() => {
    return {
        content: (props: FrontContentTopProps) => {
            return {
                position: "relative",
                display: "grid",
                gap: "24px",
                gridTemplateColumns: "repeat(12, 1fr)",
                "&::after": {
                    // eslint-disable-next-line quotes
                    content: '""',
                    position: "absolute",
                    zIndex: -1,
                    top: "-5rem",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundRepeat: "no-repeat",
                    backgroundImage: props.backgroundColor
                        ? ""
                        : `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("${props.backgroundImage}")`,
                    backgroundSize: "cover",
                    backgroundColor: props.backgroundColor ?? "none",
                    height: props.cropBackground ? "101vh" : "inherit",
                    ...props.styleOverridesClassName,
                },

                [theme.breakpoints.up("sm")]: {
                    margin: "0 auto",
                    padding: "0 16px",
                },
            };
        },
        first_part: () => {
            return {
                width: "100%",
                marginBottom: "3rem",
                [theme.breakpoints.up("xs")]: {
                    gridColumn: "1 / span 12",
                },
                [theme.breakpoints.up("xl")]: {
                    width: "calc(1314px)",
                    margin: "0 auto 3rem auto",
                },
            };
        },
    };
});

const FrontContentTop = (props: FrontContentTopProps) => {
    const styles = useStyles(props);
    return (
        <Box className={props.overridesClassName || styles.content} style={props.boxStyles}>
            {props.links ? <BreadCrumbs links={props.links} previousPage={props.previousPage} /> : null}
            <Box className={[styles.first_part, props?.firstPart ?? {}].join(" ")}>{props.children}</Box>
        </Box>
    );
};

export default FrontContentTop;
