import { Card, CardContent, makeStyles } from "@material-ui/core";
import React, { forwardRef, LegacyRef } from "react";

import fioriture1 from "../assets/CommunePage/fioriture1.svg";
import fioriture2 from "../assets/CommunePage/fioriture2.svg";
import theme from "../globalTheme";
import fioriture3 from "../assets/CommunePage/fioriture3.svg";

const useStyles = makeStyles(() => {
    return {
        front_main_container: {
            position: "relative",
            borderRadius: "20px",
            boxShadow: "rgb(0 0 0 / 8%) 0px 4px 76px 4px",
            [theme.breakpoints.down("sm")]: {
                padding: "1rem",
            },
            padding: "2rem",
            overflow: "initial",
            ...theme.front.padding.estateCard,
        },
        arrowNavigation: {
            position: "absolute",
            width: "100%",
            zIndex: 2,
            display: "flex",
            justifyContent: "space-between",
            [theme.breakpoints.up("xs")]: {
                top: "15vh",
                left: "0",
            },
            [theme.breakpoints.up("md")]: {
                top: "90vh",
                left: "0",
            },
            [theme.breakpoints.up("xl")]: {
                top: "60vh",
                left: "0",
            },
        },
        fioriture: {
            position: "absolute",
            top: "-165px",
            left: "-270px",
            zIndex: 0,
            [theme.breakpoints.down("lg")]: {
                display: "none",
            },
        },
        styleCardContent: {
            position: "relative",
            zIndex: 2,
            padding: 0,
            paddingBottom: "0px !important",
        },
    };
});

interface FrontMainContainerProps {
    children: React.ReactNode;
    style?: object;
    styleCardContent?: boolean;
    fioritureTopImg?: string;
    hideFloritureTopImg?: boolean;
    fioritureStyle?: object;
    paddingMain?: boolean;
    className?: object;
    classNameCardContent?: object;
    ref?: any;
    boxStyle?: object;
}

const FrontMainContainer = forwardRef((props: FrontMainContainerProps, ref: LegacyRef<HTMLDivElement>) => {
    const styles = useStyles(props);
    return (
        <div ref={ref} style={props.boxStyle}>
            <Card
                id="propriete"
                className={[styles.front_main_container, props.className].join(" ")}
                style={
                    props.style ?? {
                        backgroundImage: `url(${fioriture1}), url(${fioriture2})`,
                        backgroundPositionY: "bottom",
                        backgroundPositionX: "right, left",
                        backgroundRepeat: "no-repeat",
                    }
                }
            >
                {!props.hideFloritureTopImg && (
                    <div className={styles.fioriture}>
                        <img src={props.fioritureTopImg ?? fioriture3} style={props.fioritureStyle ?? {}} />
                    </div>
                )}

                <CardContent
                    className={`${props.styleCardContent ? styles.styleCardContent : ""} ${props.classNameCardContent}`}
                >
                    {props.children}
                </CardContent>
            </Card>
        </div>
    );
});

FrontMainContainer.displayName = "FrontMainContainer";

FrontMainContainer.propTypes = {};

export default FrontMainContainer;
