import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Typography } from "@material-ui/core";
import ShowMoreText from "react-show-more-text";
import { t, Trans } from "@lingui/macro";
/* eslint-disable no-unused-vars */

const useStyles = makeStyles((theme) => ({
    text: (props) => ({
        ...theme.front.typography.labeur.dark,
        lineHeight: "24px",
        color: props.colorFont ? props.colorFont : "",
        whiteSpace: "pre-line",
        "& a": {
            color: "#9F8D93",
            textDecorationColor: "#9F8D93",
        },
    }),
    moreTypo: {
        color: "#9F8D93",
        textDecorationColor: "#9F8D93",
        fontSize: "16px",
    },
    lessTypo: {
        color: "#9F8D93",
        textDecorationColor: "#9F8D93",
        fontSize: "16px",
    },
}));
const ExpandableTextBox = (props) => {
    const styles = useStyles(props);
    return (
        <ShowMoreText
            more={
                <Typography className={styles.moreTypo}>
                    <Trans>Voir plus</Trans>
                </Typography>
            }
            less={
                <Typography className={styles.lessTypo}>
                    <Trans>Voir moins</Trans>
                </Typography>
            }
            className={styles.text}
            expanded={props.expanded}
            lines={props.lines}
        >
            {props.text ? props.text : t`Aucune description à afficher`}
        </ShowMoreText>
    );
};

ExpandableTextBox.propTypes = {
    lines: PropTypes.number,
    text: PropTypes.string,
    expanded: PropTypes.bool,
    colorFont: PropTypes.string,
};

export default ExpandableTextBox;
