import { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Card, Icon, makeStyles, Typography } from "@material-ui/core";

import theme from "../globalTheme";
import { SwiperWrapper } from "../Front/SwiperCards";
import { useWindowDimensions } from "../helpers/helpers";
import OdrCard from "./OdrCard";
import SimpleInput from "../Components/Inputs/SimpleInput";
import inputSearchIcon from "../assets/navbarfront/inputLoupe.svg";
import { useGetRepresentationOrganismsFrontQuery } from "../generated/graphql";
import { t, Trans } from "@lingui/macro";
import Loading from "Components/Loader/Loading";

const useStyle = makeStyles(() => ({
    card: {
        boxShadow: "unset",
        backgroundColor: theme.palette.background.card,
    },
    content_card_odg: {
        display: "flex",
        flexWrap: "wrap",
        [theme.breakpoints.down("lg")]: {
            gap: "2rem",
            justifyContent: "center",
        },
        [theme.breakpoints.up("lg")]: {
            gap: "2rem",
            justifyContent: "center",
            paddingBottom: "32px",
        },
        [theme.breakpoints.up("xl")]: {
            gap: "10px 3px",
            padding: "2px",
            paddingBottom: "32px",
            justifyContent: "left",
        },
    },
    search: {
        display: "flex",
        justifyContent: "center",
        marginBottom: "2rem",
    },
}));

const OdrTab = () => {
    const styles = useStyle();
    const { width } = useWindowDimensions();
    const [search, setSearch] = useState("");
    const locale = useSelector((state) => state?.user?.locale || "fr");

    const {
        data: { GetRepresentationOrganismsFront: representationOrganism = [] } = {},
        loading,
    } = useGetRepresentationOrganismsFrontQuery({ variables: { locale } });

    const filteredData = () => {
        if (!representationOrganism) {
            return;
        }
        let d = [...representationOrganism];
        return d.filter((item) => {
            if (!search) {
                return true;
            }
            return item?.name
                ?.toUpperCase()
                .replace("-", " ")
                .normalize("NFC")
                .replace(/\p{Diacritic}/gu, "")
                .includes(search?.toString().toUpperCase());
        });
    };

    return (
        <>
            <Typography
                variant="body2"
                style={{ backgroundColor: theme.palette.common.white, padding: "8px", margin: "1rem 0" }}
            >
                <Trans>
                    Selon leurs chartes, les organismes de représentation remplissent plusieurs missions comme la
                    promotion et la défense des vins de leurs membres et de leurs filières, l’accompagnement de leurs
                    adhérents dans leurs divers projets ou leurs démarches, le rôle d’ambassadeurs du vin de Bordeaux ou
                    de leurs propres vins à travers le monde, la mise en avant des démarches environnementales
                    responsables ou encore l’organisation d’événements vinicoles…
                </Trans>
            </Typography>
            <Card className={styles.card}>
                <Typography
                    variant="h3"
                    style={{
                        textAlign: "center",
                        margin: "2rem 0",
                        fontWeight: 700,
                        color: theme.palette.primary.dark,
                    }}
                >
                    <Trans>Découvrir les organismes de représentation</Trans>
                </Typography>
                <Box className={styles.search}>
                    <SimpleInput
                        boxStyle={{ width: "191px" }}
                        customIcon={
                            <Icon style={{ display: "flex", alignItems: "center" }}>
                                <img src={inputSearchIcon} />
                            </Icon>
                        }
                        borderRadius="50px"
                        startAdornment
                        size="small"
                        name="search"
                        placeholder={t`Rechercher...`}
                        variant="outlined"
                        value={search}
                        onChange={(e) => setSearch(e?.target?.value)}
                    />
                </Box>

                {loading ? (
                    <Loading />
                ) : width >= 1280 ? (
                    <Box className={styles.content_card_odg}>
                        {filteredData()?.map((odr, i) => (
                            <OdrCard key={i} name={odr.name} logo={odr.logo?.twicpicURL} />
                        ))}
                    </Box>
                ) : (
                    <SwiperWrapper>
                        {filteredData()?.map((e, i) => (
                            <OdrCard key={i} name={e.name} logo={e.logo?.twicpicURL} id={e._id} />
                        ))}
                    </SwiperWrapper>
                )}
            </Card>
        </>
    );
};

OdrTab.propTypes = {};

export default OdrTab;
