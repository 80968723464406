import { Box, IconButton, InputAdornment, TextField, Typography, makeStyles } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import PropTypes from "prop-types";
import { useState } from "react";
import ErrorIcon from "../../assets/Error.svg";
import theme from "../../globalTheme";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    falseIcon: {
        width: "20px",
        fill: theme.palette.error.main,
        marginRight: "0px",
    },
    root: (props) => ({
        marginBottom: props.error || !props.value ? 0 : theme.spacing(3),
        "& .MuiFormLabel-root": {
            color: theme.palette.secondary.dark,
            fontSize: "14px",
            top: "-10px",

            "&.Mui-focused": {
                color: theme.palette.primary.dark,
            },
            "&.Mui-error": {
                color: theme.palette.error.main,
            },
        },
        "& .MuiOutlinedInput-root": {
            fontSize: "14px",
            height: "34px",
            borderWidth: "0.2px",
            borderColor: theme.palette.secondary.dark,
            "&.Mui-error": {
                color: theme.palette.error.main,
            },
            "&.Mui-focused": {
                color: theme.palette.primary.dark,
                borderColor: theme.palette.primary.dark,
                borderWidth: "0.2px",
            },
            "&.Mui-disabled": {
                color: theme.palette.secondary.dark,
                backgroundColor: theme.palette.action.disabledBackground,
                borderColor: theme.palette.secondary.dark,
                borderWidth: "0.2px",
            },
            "&.MuiSelect-select": {
                minWidth: "272px",
            },
            "&:hover fieldset": {
                borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
            },
            "&.Mui-focused fieldset": {
                borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
            },
        },
        "& .MuiOutlinedInput-adornedStart": {
            padding: "0px",
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: "translate(14px, 6px) scale(0.75)",
        },
    }),
}));

const ConstraintsPasswordInput = (props) => {
    const styles = useStyles(props);
    const { t } = useTranslation();
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const handleClickShowPassword = () => {
        setPasswordVisibility(!passwordVisibility);
    };

    return (
        <>
            <TextField
                value={props.value || ""}
                onChange={props.onChange}
                id={props.id}
                name={props.name}
                style={props.style}
                onBlur={() => props.setFocus(false)}
                type={passwordVisibility ? "text" : "password"}
                variant="outlined"
                disabled={props.disabled}
                error={props.error}
                label={props.label}
                placeholder={props.placeholder}
                required={props.required}
                className={styles.root}
                InputLabelProps={{ shrink: true }}
                // inputProps={{
                //     autoComplete: "disabled", // disable autocomplete and autofill
                // }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                disabled={props.disabled && "disabled"}
                                onClick={handleClickShowPassword}
                                style={{ padding: "8px" }}
                            >
                                {passwordVisibility ? (
                                    <VisibilityOffIcon color="secondary" style={{ width: "20px" }} />
                                ) : (
                                    <VisibilityIcon color="secondary" style={{ width: "20px" }} />
                                )}
                            </IconButton>
                            {props.value &&
                                props.constraints?.symbolConstraint &&
                                props.constraints?.lenConstraint &&
                                props.constraints?.lowerCaseConstraint &&
                                props.constraints?.upperCaseConstraint &&
                                !props.noCheckIcon && (
                                    <CheckIcon
                                        style={{
                                            width: "20px",
                                            fill: "#9BBA97",
                                        }}
                                    />
                                )}
                            {props.error && <img src={ErrorIcon} className={styles.falseIcon} />}
                        </InputAdornment>
                    ),
                }}
            />
            {props.error && (
                <Box style={props.constraintsBoxStyle}>
                    <Typography
                        variant="h6"
                        style={{
                            color: theme.palette.secondary.dark,
                            fontSize: "12px",
                        }}
                    >
                        {t("error.message.passwordMustContain")}
                    </Typography>
                    <Typography
                        variant="h6"
                        style={{
                            textDecoration: props.constraints?.upperCaseConstraint && "line-through",
                            color: props.constraints?.upperCaseConstraint
                                ? theme.palette.success.main
                                : theme.palette.secondary.dark,
                            fontSize: "12px",
                        }}
                    >
                        • {t("error.message.atLeast1Uppercase")}
                    </Typography>
                    <Typography
                        variant="h6"
                        style={{
                            textDecoration: props.constraints?.lenConstraint && "line-through",
                            color: props.constraints?.lenConstraint
                                ? theme.palette.success.main
                                : theme.palette.secondary.dark,
                            fontSize: "12px",
                        }}
                    >
                        • {t("error.message.min8Char")}
                    </Typography>
                    <Typography
                        variant="h6"
                        style={{
                            textDecoration: props.constraints?.lowerCaseConstraint && "line-through",
                            color: props.constraints?.lowerCaseConstraint
                                ? theme.palette.success.main
                                : theme.palette.secondary.dark,
                            fontSize: "12px",
                        }}
                    >
                        • {t("error.message.atLeast1Lowercase")}
                    </Typography>

                    <Typography
                        variant="h6"
                        style={{
                            textDecoration: props.constraints?.symbolConstraint && "line-through",
                            color: props.constraints?.symbolConstraint
                                ? theme.palette.success.main
                                : theme.palette.secondary.dark,
                            fontSize: "12px",
                        }}
                    >
                        • {t("error.message.atLeast1SpecialChar")}
                    </Typography>
                </Box>
            )}
        </>
    );
};

ConstraintsPasswordInput.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    variant: PropTypes.oneOf(["text", "contained", "outlined"]),
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    style: PropTypes.object,
    correctValue: PropTypes.bool,
    helperText: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    falseValue: PropTypes.bool,
    // CONTRAINTES
    setFocus: PropTypes.func,
    focus: PropTypes.bool,
    constraints: PropTypes.object,
    constraintsBoxStyle: PropTypes.object,
    noCheckIcon: PropTypes.bool,
};

ConstraintsPasswordInput.defaultProps = {
    onChange: () => {},
    onBlur: () => {},
};

export default ConstraintsPasswordInput;
