import React from "react";

import { makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";

const useStyle = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.primary.dark,
    },
    popper: {
        fontSize: "16px",
        backgroundColor: theme.palette.primary.dark,
        borderRadius: "12px",
        boxShadow: "0px 3px 76px #00000014",
        padding: "10px",
    },
    text: {
        backgroundColor: "#632D3E",
        color: "white",
        padding: "1px 6.72px",
        borderRadius: "48px",
        fontSize: "9.6px",
        fontFamily: "Playfair Display",
        fontStyle: "italic",
        fontWeight: "600",
    }
}));

const AdditionalInformationTooltip = (props) => {
    const styles = useStyle(props);
    return (
        <Tooltip
            disableHoverListener={props.disableHoverListener}
            interactive={props.interactive}
            color="primary"
            title={props.title}
            arrow
            placement={props.placement}
            open={props.open}
            classes={{ arrow: styles.arrow, tooltip: styles.popper }}
        >
            <Typography style={props.style} className={styles.text}>
                {" "}
                i{" "}
            </Typography>
        </Tooltip>
    );
};

AdditionalInformationTooltip.propTypes = {
    children: PropTypes.string,
    open: PropTypes.bool,
    title: PropTypes.string,
    placement: PropTypes.string,
    disableHoverListener: PropTypes.bool,
    interactive: PropTypes.bool,
    style: PropTypes.any,
};

export default AdditionalInformationTooltip;
