import React from "react";
import GoogleMapReact from "google-map-react";
import marker from "../../assets/marker.png";
import { makeStyles, Box, Typography } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyle = makeStyles((theme) => ({
    container: (props) => ({
        height: props.size === "large" ? "400px" : "100%",
        width: "100%",
        [theme.breakpoints.down("sm")]: {
            height: "400px",
            marginBottom: "1rem",
        },
    }),

    text: {
        backgroundColor: theme.palette.common.white,
        textAlign: "center",
        fontSize: "14px",
        borderRadius: "10px",
        marginTop: theme.spacing(1),
        color: theme.palette.primary.main,
        fontFamily: "Playfair Display",
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        fontWeight: "bold",
        position: "absolute",
        left: "-55px",
    },
}));

const LocationPin = () => {
    return (
        <img
            src={marker}
            style={{
                transform: "translateZ(0) translate(-50%, -50%)",
                backfaceVisibility: "hidden",
            }}
            className="pin-icon"
        />
    );
};

const Map = (props) => {
    const styles = useStyle(props);

    const API_KEY = "AIzaSyDoBguLT_7nbf8EfWKcpZro0Mr3hfj2ZLI";
    // process.env.REACT_APP_MAP_API_KEY

    const defaultValue = {
        center: {
            lat: props.coordinates?.latitude || 44.844669,
            lng: props.coordinates?.longitude || -0.57556,
        },
        zoom: 13,
    };
    return (
        <Box className={styles.container}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: API_KEY || "" }}
                defaultCenter={defaultValue.center}
                defaultZoom={defaultValue.zoom}
            ></GoogleMapReact>
        </Box>
    );
};

LocationPin.propTypes = {
    text: PropTypes.string,
    coordinates: PropTypes.object,
};

Map.propTypes = {
    text: PropTypes.string,
    coordinates: PropTypes.object,
};

export default Map;
