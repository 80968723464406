import { Card, makeStyles, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

import { useGetFormationOrganismsSampleQuery } from "../generated/graphql";
import { SwiperWrapper } from "../Front/SwiperCards";
import theme from "../globalTheme";
import { Trans } from "@lingui/macro";
import FormationCard from "FrontFormation/FormationCard";

const useStyle = makeStyles(() => ({
    card: {
        boxShadow: "unset",
        backgroundColor: theme.palette.background.card,
    },
}));

const OdfTab = () => {
    const styles = useStyle();
    const locale = useSelector((state) => state?.user?.locale || "fr");

    const {
        data: { GetFormationOrganismsSample: formationOrganisms } = {},
        loading,
    } = useGetFormationOrganismsSampleQuery({
        variables: {
            locale,
        },
    });

    return (
        <>
            <Typography
                variant="body2"
                style={{ backgroundColor: theme.palette.common.white, padding: "8px", margin: "1rem 0" }}
            >
                <Trans>
                    Bordeaux est une des capitales françaises du vin et en tant que telle, elle se devait d’avoir des
                    organismes de formation de premier plan qui couvrent tous les domaines vitivinicoles.{" "}
                </Trans>
                <br /> <br />{" "}
                <Trans>
                    Du BTS au Master, des diplômes universitaires plus spécifiques au DNO, de la formation initiale à
                    l’alternance en passant par des stages de courte durée, l’éventail des formations proposées est très
                    large et comble les besoins de la filière : viticulture, œnologie, vinification, marketing,
                    commercialisation, management, sommellerie, gestion, économie, droit des domaines viticoles,
                    œnotourisme…
                </Trans>
            </Typography>
            <Card className={styles.card}>
                <Typography
                    variant="h3"
                    style={{
                        textAlign: "center",
                        margin: "2rem 0",
                        fontWeight: 700,
                        color: theme.palette.primary.dark,
                    }}
                >
                    <Trans>Découvrir les organismes de formation</Trans>
                </Typography>

                <SwiperWrapper loading={loading}>
                    {formationOrganisms?.map((e, i) => (
                        <FormationCard key={i} title={e?.name} logo={e?.logo?.twicpicURL} />
                    ))}
                </SwiperWrapper>
            </Card>
        </>
    );
};

OdfTab.propTypes = {};

export default OdfTab;
