import { Box } from "@material-ui/core";
import objectPath from "object-path";
import omitDeep from "omit-deep-lodash";
import { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import ToastOptions from "../Components/Toasts/ToastOptions";
import { setDirty, setSaveForm } from "../features/settings/settingsSlice";
import getConnectors, { deepOmit, getEntityId, removeEmptyRows, sanitizeAddress } from "../helpers/helpers";
import { uploadAndCropImage, uploadAndClipAndCropImage } from "../helpers/network";
import { isError, onChangeConditions } from "../helpers/validators";
import { getLabelsErrors } from "../labels";

const errorsFormReducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case "update":
            return {
                ...state,
                [payload.name]: payload.value,
            };
        case "clear":
            return {
                ...state,
                [payload.name]: null,
            };
        case "set":
            return { ...payload };
    }
};

const reducerFormBuilder = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case "busy":
            return {
                ...state,
                busy: payload.value,
            };
        case "update":
            return {
                ...state,
                [payload.name]: payload.value,
            };
        case "set":
            return { ...payload };
        case "append":
            return {
                ...state,
                ...payload,
            };
    }
};

const getInitialErrors = (conditions) => {
    const source = Object.keys(conditions).map((key) => ({ [key]: false }));
    return Object.assign({}, ...source);
};

export const useNewFormBuilder = (
    queryPath,
    queryArgs,
    conditions,
    useQueryHook,
    useMutationHook,
    remoteFieldNames = {},
    queryDocument,
    refetchQueries = [],
    errorDeps
) => {
    const dispatchSettings = useDispatch();
    const isDirtyForm = useSelector((state) => state.settings.formDirty);
    const [scratch, dispatch] = useReducer(reducerFormBuilder, {});
    const [called, setCalled] = useState(false);

    const [errors, errorsDispatch] = useReducer(errorsFormReducer, {});
    const [saveDisabled, setSaveDisabled] = useState(true);
    const [loading, setLoading] = useState(true);
    const [clues, setClues] = useState();
    const wineEntityId = useSelector((state) => getEntityId(state));
    const forceSaveForm = useSelector((state) => state.settings.saveForm);

    const toastOptions = ToastOptions();

    const getClues = () => {
        const ret = {};
        const conKeys = Object.keys(conditions);

        for (let i = 0; i < conKeys.length; i++) {
            const conKey = conKeys[i];
            let message = [];
            const conTypes = Object.keys(conditions[conKey]);
            for (let j = 0; j < conTypes.length; j++) {
                const conType = conTypes[j];
                switch (conType) {
                    case "date":
                        message.push(<Box>Le format attendu est JJ/MM/AAAA </Box>);
                        break;
                    case "numberLimit":
                        message.push(
                            <Box>{`min. ${conditions[conKey][conType].min}, max. ${conditions[conKey][conType].max}`}</Box>
                        );
                        break;
                    case "elementsLimit":
                        message.push(<Box>{`${conditions[conKey][conType].limit} éléments max.`}</Box>);
                        break;
                    case "dependantPercentageLimit":
                        message.push(<Box>{`total maxi: ${conditions[conKey][conType].numberLimit}%`}</Box>);
                        break;
                    case "minRange":
                        message.push(<Box>{`minimum : ${conditions[conKey][conType].min}`}</Box>);
                        break;
                }
            }

            ret[conKey] = message;
        }
        return ret;
    };

    const clearError = (fieldname) => {
        errorsDispatch({
            type: "clear",
            payload: {
                name: fieldname,
            },
        });
    };

    const validate = (name, value, isOnChange) => {
        const cons = conditions[name];
        if (!cons) {
            return true;
        }
        const conKeys = Object.keys(cons);

        let conditionValue = false;
        let conKey = null;
        let condition = null;

        for (let i = 0; i < conKeys.length; i++) {
            const thisConKey = conKeys[i];
            const thisCondition = cons[thisConKey];

            const errorValue = isError(thisConKey, value, thisCondition, scratch, null, isOnChange);
            if (errorValue) {
                conditionValue = errorValue;
                conKey = thisConKey;
                condition = thisCondition;
            }
        }
        if (conKey === "dependant") {
            const { accessor } = condition;

            errorsDispatch({
                type: "update",
                payload: {
                    name: accessor,
                    value: conditionValue,
                },
            });
        }
        if (conKey === "compositeKeys" || conKey === "totalPercentage" || conKey === "matchingHours") {
            if (conditionValue) {
                conditionValue?.forEach((e) => {
                    errorsDispatch({
                        type: "update",
                        payload: {
                            name,
                            value: e,
                        },
                    });
                });
            }
        }
        if (conKey === "dependantPercentageLimit") {
            const { accessors } = condition;
            accessors.forEach(() => {
                errorsDispatch({
                    type: "update",
                    payload: {
                        name,
                        value: conditionValue,
                    },
                });
            });
        }
        if (conditionValue) {
            errorsDispatch({
                type: "update",
                payload: {
                    name,
                    value: conditionValue,
                },
            });
        } else {
            errorsDispatch({
                type: "update",
                payload: {
                    name,
                    value: false,
                },
            });
        }
    };

    const handleBlur = async (name, deps, value, skipValidation) => {
        if (value === undefined) {
            value = scratch[name];
        }
        if (!skipValidation) {
            validate(name, value);
        }
        if (deps) {
            deps?.map((e) => validate(e, scratch[e]));
        }
    };

    const shouldValidateOnChange = (name) => {
        if (!conditions[name]) {
            return false;
        } else {
            if (conditions[name].onChange) {
                return true;
            }
            const conditionKeys = Object.keys(conditions[name]);
            for (let i = 0; i < conditionKeys.length; i++) {
                const conditionKey = conditionKeys[i];
                if (onChangeConditions.includes(conditionKey)) {
                    return true;
                }
            }
            return false;
        }
    };

    const handleChange = (name, e, noDirty = false, depFunction = null, forceValidate) => {
        let value = e;
        if (e !== 0 && e?.target) {
            value = e?.target?.type === "checkbox" ? e?.target?.checked : e?.target?.value;
        }
        let shouldDirty = !noDirty || isDirtyForm;

        if ((shouldValidateOnChange(name) && shouldDirty) || forceValidate) {
            validate(name, value, true);
        }
        if (depFunction) {
            const res = depFunction();
            dispatch({
                type: "append",
                payload: {
                    ...res,
                },
            });
        }
        dispatch({
            type: "update",
            payload: {
                name,
                value,
            },
        });
        dispatchSettings(setDirty(shouldDirty));
    };

    const [updateItemByIdMutation, { loading: mutationLoading, error: mutationError }] = useMutationHook({
        refetchQueries: [...refetchQueries, { query: queryDocument, variables: queryArgs }],
        awaitRefetchQueries: true,
    });
    const { loading: loadingGetQuery, data, refetch, networkStatus, error: queryError } = useQueryHook(
        {
            variables: queryArgs,
            fetchPolicy: "network-only",
            notifyOnNetworkStatusChange: true,
        }
    );

    const initialize = (values) => {
        dispatch({
            type: "set",
            payload: values,
        });
        errorsDispatch({
            type: "set",
            payload: getInitialErrors(conditions),
        });
        setClues(getClues());
        setCalled(true);
        dispatchSettings(setDirty(false));
    };

    useEffect(() => {
        const values = objectPath.get(data, queryPath);

        if (networkStatus !== 4 && values && Object.keys(values).length !== 0) {
            initialize(values);
            setLoading(false);
        }
    }, [!!objectPath.get(data, queryPath), objectPath.get(data, queryPath), loadingGetQuery, networkStatus]);

    useEffect(() => {
        const saveIsDisabled = !isDirtyForm;

        if (saveIsDisabled !== saveDisabled) {
            setSaveDisabled(saveIsDisabled);
        }
    }, [isDirtyForm, loading]);

    const handleSave = async (inputScratch = null) => {
        let containsError = false;

        const errorKeys = Object.keys(errors);
        const errorKeysToFilter = [];
        if (errorDeps) {
            const errorDepsKeys = Object.keys(errorDeps);
            for (let i = 0; i < errorDepsKeys.length; i++) {
                const errorDepsKey = errorDepsKeys[i];
                const errorDepsValue = errorDeps[errorDepsKey];
                const v = !!scratch[errorDepsValue.fieldName];

                if (v !== errorDepsValue.shouldEqual) {
                    errorKeysToFilter.push(errorDepsKey);
                }
            }
        }

        const filteredErrorKeys = errorKeys.filter((e) => !errorKeysToFilter.includes(e));

        const errorsKeysNames = [];

        const labelsObject = getLabelsErrors(queryPath);
        for (let i = 0; i < filteredErrorKeys.length; i++) {
            const errorKey = filteredErrorKeys[i];
            if (errors?.[errorKey] && !Array.isArray(errors?.[errorKey])) {
                if (typeof errors?.[errorKey] === "object" && errors?.[errorKey] !== null) {
                    for (const key in errors?.[errorKey]) {
                        if (errors?.[errorKey][key]) {
                            const v = errors?.[errorKey][key];
                            if (v) {
                                if (labelsObject?.[key]?.["labelError"] || labelsObject?.[key]?.["label"]) {
                                    errorsKeysNames?.push(
                                        labelsObject?.[key]?.["labelError"] || labelsObject?.[key]?.["label"]
                                    );
                                }
                                containsError = true;
                            }
                        }
                    }
                } else {
                    errorsKeysNames.push(
                        labelsObject[filteredErrorKeys?.[i]]?.["labelError"] ||
                            labelsObject[filteredErrorKeys?.[i]]?.["label"]
                    );
                    containsError = true;
                }
            }

            if (Array.isArray(errors?.[errorKey])) {
                errors?.[errorKey]?.map((el) => {
                    errorsKeysNames?.push(
                        labelsObject[errorKey]?.[el?.fieldname]?.["labelError"] ||
                            labelsObject[errorKey]?.[el?.fieldname]?.["label"] ||
                            labelsObject[el?.fieldname]?.["label"] ||
                            errorKey
                    );
                });
                containsError = true;
            }
        }
        if (containsError) {
            // CUSTOM ERROR FOR OPEN HOURS TABLE
            const horaires = errorsKeysNames.find((element) => element === "Tableau horaires");
            const splicedErrorsKeysNames = errorsKeysNames?.filter((e) => e !== "Tableau horaires");

            if (horaires) {
                toast.error(
                    toastOptions?.toastErrorComponent(
                        "Pour enregistrer vérifier qu'il n'y ai pas d'erreur ou qu'aucune ligne du tableau des horaires ne soit vide."
                    ),
                    toastOptions?.saveErrorOptions
                );
            }
            // ALL TOAST ERRORS
            if (splicedErrorsKeysNames && splicedErrorsKeysNames?.length > 1) {
                toast.error(
                    toastOptions?.toastErrorComponent(
                        `Pour enregistrer, vérifiez les champs : ${getConnectors(splicedErrorsKeysNames || "")}`
                    ),
                    toastOptions?.saveErrorOptions
                );
            } else if (splicedErrorsKeysNames && splicedErrorsKeysNames?.length === 1) {
                toast.error(
                    toastOptions?.toastErrorComponent(
                        `Pour enregistrer, vérifiez le champ : ${splicedErrorsKeysNames || ""}`
                    ),
                    toastOptions?.saveErrorOptions
                );
            }
        } else {
            setLoading(true);
            let scratchToUse = inputScratch || scratch;
            scratchToUse = omitDeep(scratchToUse, "__typename");
            scratchToUse = removeEmptyRows(scratchToUse);

            let imagePromises;
            const scratchFieldNames = Object.keys(scratchToUse);
            for (const scratchFieldName of scratchFieldNames) {
                if (scratchFieldName in remoteFieldNames) {
                    if (remoteFieldNames[scratchFieldName] === "omit") {
                        delete scratchToUse[scratchFieldName];
                    } else if (remoteFieldNames[scratchFieldName]?.kind === "image") {
                        imagePromises = scratchToUse[scratchFieldName]?.map((imageData) => {
                            return uploadAndCropImage(
                                imageData,
                                wineEntityId,
                                remoteFieldNames[scratchFieldName]?.bucketName
                            );
                        });
                        if (imagePromises) {
                            const imagePromisesRes = await Promise.all(imagePromises);
                            scratchToUse[scratchFieldName] = imagePromisesRes;
                        }
                    } else if (remoteFieldNames[scratchFieldName]?.kind === "clipandcropimage") {
                        imagePromises = scratchToUse[scratchFieldName]?.map((imageData) => {
                            return uploadAndClipAndCropImage(
                                imageData,
                                wineEntityId,
                                remoteFieldNames[scratchFieldName]?.bucketName
                            );
                        });
                        if (imagePromises) {
                            const imagePromisesRes = await Promise.all(imagePromises);
                            scratchToUse[scratchFieldName] = imagePromisesRes;
                        }
                    } else if (remoteFieldNames[scratchFieldName] === "rich") {
                        scratchToUse[scratchFieldName] = scratchToUse[scratchFieldName]?.replaceAll(
                            /(<div><br><\/div>){1,}/g,
                            "<div/>"
                        );

                        if (scratchToUse[scratchFieldName] === "<div/>") {
                            scratchToUse[scratchFieldName] = null;
                        }
                        handleChange(scratchFieldName, scratchToUse[scratchFieldName]);
                    } else if (remoteFieldNames[scratchFieldName] === "transrich") {
                        if (scratchToUse[scratchFieldName]) {
                            scratchToUse[scratchFieldName] = {
                                ...scratchToUse[scratchFieldName],
                                fr: scratchToUse[scratchFieldName]?.fr?.replaceAll(/(<div><br><\/div>){1,}/g, "<div/>"),
                            };
                        }

                        if (scratchToUse[scratchFieldName] === "<div/>") {
                            scratchToUse[scratchFieldName] = null;
                        }
                        handleChange(scratchFieldName, scratchToUse[scratchFieldName]);
                    } else if (remoteFieldNames[scratchFieldName] === "address") {
                        scratchToUse[scratchFieldName] = sanitizeAddress(scratchToUse[scratchFieldName]);
                    } else {
                        scratchToUse[remoteFieldNames[scratchFieldName]] = scratchToUse[scratchFieldName];
                    }
                } else {
                    Object.entries(remoteFieldNames).forEach(([key, value]) => {
                        const parts = key.split(".");
                        const firstPath = parts[0];
                        if (firstPath === scratchFieldName) {
                            if (value === "omit") {
                                scratchToUse = deepOmit(scratchToUse, key);
                            }
                        }
                    });
                }
            }
            await updateItemByIdMutation({
                variables: {
                    input: scratchToUse,
                    ...queryArgs,
                },
                awaitRefetchQueries: true,
                refetchQueries: [...refetchQueries, { query: queryDocument, variables: queryArgs }],
            });
            dispatchSettings(setDirty(false));
            dispatchSettings(setSaveForm(false));
            setLoading(false);
            toast.success(toastOptions?.saveSuccessText, toastOptions?.saveSuccessOptions);
        }
    };

    useEffect(() => {
        if (forceSaveForm) {
            handleSave();
        }
    }, [forceSaveForm]);

    return {
        called,
        handleSave,
        handleChange,
        setLoading,
        updateItemByIdMutation,
        loadingGetQuery,
        mutationLoading,
        loading,
        refetch,
        saveDisabled,
        scratch,
        errors,
        mutationError,
        queryError,
        handleBlur,
        clues,
        clearError,
        validate,
    };
};
