import React, { useState } from "react";
import { array, bool, number } from "prop-types";
import { Box, Card, CardContent, Icon, IconButton, makeStyles, Typography } from "@material-ui/core";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import CancelRoundedIcon from "../../assets/appearsInBESV/CancelRoundedIcon.svg";
import CheckCircleRoundedIcon from "../../assets/appearsInBESV/CheckCircleRoundedIcon.svg";
import AddCircleOutlineIcon from "../../assets/appearsInBESV/AddCircleOutlineIcon.svg";
import theme from "../../globalTheme";
import BaseButton from "../Buttons/BaseButton";
import { firstLetterUppercase, getSubscriptionTranslation } from "../../helpers/helpers";
import Tag from "../Chips/Tag";
import star from "../../assets/appearsInBESV/star_price.svg";
import { Trans } from "@lingui/macro";
import { SUBSCRIPTION_TYPES } from "config/constants";
import { useCustomHistory } from "hooks";
import PlusChip from "./PlusChip";
import Arrow from "Components/Arrow/Arrow";

SwiperCore.use([Navigation]);

const useStyle = makeStyles(() => ({
    item: {
        display: "flex",
        alignItems: "flex-start",
    },
    button: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        width: "40px",
        height: "40px",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
        "&:disabled": {
            backgroundColor: theme.palette.secondary.main,
        },
    },
    button_disabled: {
        backgroundColor: theme.palette.secondary.main,
        "&:hover": {
            backgroundColor: theme.palette.secondary.main,
        },
    },
    swiper: {
        "& .swiper-container": {
            overflow: "visible !important",
        },
        "& .swiper-slide": {
            textAlign: "left",
            flexDirection: "column",
            backgroundColor: "transparent",
        },
    },
    monthly_text: {
        display: "flex",
        alignItems: "end",
        flexDirection: "column",
        justifyContent: "end",
    },
    card: {
        borderRadius: "10px",
        width: "100%",
    },
    typo_price: {
        fontFamily: "Lato",
        display: "flex",
        alignItems: "flex-end",
        fontWeight: "700",
        lineHeight: 1,
    },
}));

const DetailsSubscription = (props) => {
    const styles = useStyle();
    const swiperRef = React.useRef(null);
    const [index, setIndex] = useState(1);
    const history = useCustomHistory();
    const facturation = props.switch ? "monthly" : "annual";

    const price = (data) => {
        if (data.type !== SUBSCRIPTION_TYPES.basic) {
            if (props.switch) {
                return (
                    <>
                        <Box className={styles.monthly_text}>
                            <Typography variant="h3" className={styles.typo_price}>
                                {data.monthly.ht} €{" "}
                                <Trans>
                                    HT<Typography variant="h5">/mois</Typography>
                                </Trans>{" "}
                            </Typography>
                        </Box>
                    </>
                );
            } else {
                return (
                    <>
                        <Box className={styles.monthly_text}>
                            <Typography
                                variant="h3"
                                className={styles.typo_price}
                                style={{
                                    position: "relative",
                                }}
                            >
                                {data.annual.discount && (
                                    <img
                                        src={star}
                                        style={{
                                            position: "absolute",
                                            width: "auto",
                                            height: "auto",
                                            top: "-7px",
                                            left: "-8px",
                                        }}
                                    />
                                )}
                                {parseInt(data.annual.ht - data.annual.discount).toLocaleString("fr-FR")} €{" "}
                                <Trans>
                                    HT <Typography variant="h5">/an</Typography>
                                </Trans>{" "}
                            </Typography>

                            {data.annual.discount && (
                                <>
                                    <Typography
                                        variant="h6"
                                        style={{ textAlign: "end", width: "100%", textDecoration: "line-through" }}
                                    >
                                        {parseInt(data.annual.ht).toLocaleString("fr-FR")} € <Trans>HT/an</Trans>
                                    </Typography>
                                </>
                            )}
                        </Box>
                    </>
                );
            }
        } else
            return (
                <>
                    <Typography variant="h3" className={styles.typo_price}>
                        {data.monthly.ht}
                    </Typography>
                    <Box>
                        <Typography variant="h6">{data.monthly.annualHt}</Typography>
                    </Box>
                </>
            );
    };

    return (
        <>
            <Box
                style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", marginBottom: "1rem" }}
            >
                <IconButton
                    classes={{
                        root: styles.button,
                        disabled: styles.button_disabled,
                    }}
                    className={styles.button}
                    disabled={index === 1}
                    onClick={() => {
                        setIndex(index - 1);
                        swiperRef.current.swiper.slidePrev();
                    }}
                >
                    <ArrowBackIcon style={{ color: theme.palette.common.white }} />
                </IconButton>
                <IconButton
                    classes={{
                        root: styles.button,
                        disabled: styles.button_disabled,
                    }}
                    disabled={index === props.datas.length}
                    onClick={() => {
                        if (index !== props.datas.length) {
                            setIndex(index + 1);
                            swiperRef.current.swiper.slideNext();
                        }
                    }}
                >
                    <ArrowForwardIcon style={{ color: theme.palette.common.white }} />
                </IconButton>
            </Box>
            <Swiper
                autoHeight
                className={styles.swiper}
                ref={swiperRef}
                slidesPerView={"auto"}
                spaceBetween={32}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    600: {
                        slidesPerView: "auto",
                    },
                }}
            >
                {props.datas.map((data, i) => {
                    let key = `data-${i}`;
                    return (
                        <SwiperSlide key={key}>
                            <Card
                                className={styles.card}
                                style={{
                                    border:
                                        data[props.switch ? "monthly" : "annual"].discount &&
                                        `5px solid ${theme.palette.wine.main} `,
                                    position: "relative",
                                    overflow: "visible",
                                    color:
                                        ((data.type === SUBSCRIPTION_TYPES.basic ||
                                            data.type === SUBSCRIPTION_TYPES.initial) &&
                                            theme.palette.primary.dark) ||
                                        theme.palette.common.white,
                                    backgroundColor:
                                        (data.type === SUBSCRIPTION_TYPES.basic && theme.palette.common.white) ||
                                        (data.type === SUBSCRIPTION_TYPES.essential && theme.palette.primary.light) ||
                                        (data.type === SUBSCRIPTION_TYPES.initial && theme.palette.success.light) ||
                                        theme.palette.primary.dark,
                                }}
                            >
                                {data[props.switch ? "monthly" : "annual"].discount ? (
                                    <Box
                                        style={{
                                            position: "absolute",
                                            top: "-15px",
                                            right: "0px",
                                            left: "0px",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Tag
                                            label={data[props.switch ? "monthly" : "annual"].discountText}
                                            size={"large"}
                                            style={{
                                                ...theme.typography.body2,
                                                backgroundColor: theme.palette.wine.main,
                                                color: theme.palette.common.white,
                                                fontStyle: "uppercase",
                                                padding: "3px 0px",
                                                height: "auto",
                                                width: "fit-content",
                                            }}
                                        />
                                    </Box>
                                ) : null}
                                <Box style={{ padding: "1rem" }}>
                                    {data[facturation].discount ? (
                                        <Typography
                                            variant="subtitle1"
                                            style={{
                                                fontFamily: "Mansalva",
                                                textAlign: "end",
                                                color:
                                                    data.type === SUBSCRIPTION_TYPES.basic ||
                                                    data.type === SUBSCRIPTION_TYPES.initial
                                                        ? theme.palette.primary.dark
                                                        : theme.palette.common.white,
                                            }}
                                        >
                                            <Trans>offre valable la première année pour une facturation annuelle</Trans>
                                        </Typography>
                                    ) : null}
                                    <Typography
                                        variant="h3"
                                        style={{ fontFamily: "Lato", justifyContent: "end", display: "flex" }}
                                    >
                                        {price(data)}
                                    </Typography>
                                    <Typography variant="h3" style={{ fontFamily: "Lato", fontWeight: "300" }}>
                                        <Trans>ABONNEMENT</Trans>
                                    </Typography>
                                    <Typography variant="h3" style={{ fontWeight: "700" }}>
                                        « {firstLetterUppercase(getSubscriptionTranslation(data.type))} »
                                    </Typography>
                                    <Typography variant="h6" style={{ fontFamily: "Mansalva" }}>
                                        {data.description}
                                    </Typography>
                                    <BaseButton
                                        disabled={false}
                                        size="small"
                                        style={{
                                            margin: "1rem 0",
                                            width: "100%",
                                            border: `1px solid ${theme.palette.primary.dark}`,
                                        }}
                                        color="primary"
                                        variant="outlined"
                                        onClick={() => history.push("/bienvenue")}
                                    >
                                        <Trans>Choisir</Trans>
                                    </BaseButton>
                                </Box>
                                <CardContent style={{ borderTop: `0.4px solid ${theme.palette.secondary.dark}` }}>
                                    <Box>
                                        {data.included.map((include, i) => {
                                            let key = `include-${i}`;
                                            return (
                                                <Box key={key} className={styles.item}>
                                                    <Icon
                                                        style={{
                                                            width: "auto",
                                                            height: "auto",
                                                            marginTop: "2px",
                                                            marginRight: "5px",
                                                        }}
                                                    >
                                                        <img src={CheckCircleRoundedIcon} />
                                                    </Icon>
                                                    <Typography variant="h6">{include}</Typography>
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                    <Box>
                                        {data.options.map((option, i) => {
                                            let key = `option-${i}`;

                                            return (
                                                <Box key={key} className={styles.item}>
                                                    <Icon
                                                        style={{
                                                            width: "auto",
                                                            height: "auto",
                                                            marginTop: "2px",
                                                            marginRight: "5px",
                                                        }}
                                                    >
                                                        <img src={AddCircleOutlineIcon} />
                                                    </Icon>
                                                    <Typography variant="h6">{option}</Typography>
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                    <Box>
                                        {data.excluded?.map((exclud, i) => {
                                            let key = `${exclud}-${i}`;
                                            return (
                                                <Box key={key} className={styles.item}>
                                                    <Icon
                                                        style={{
                                                            width: "auto",
                                                            height: "auto",
                                                            marginTop: "2px",
                                                            marginRight: "5px",
                                                        }}
                                                    >
                                                        <img src={CancelRoundedIcon} />
                                                    </Icon>
                                                    <Typography variant="h6">{exclud}</Typography>
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                    {data.type === SUBSCRIPTION_TYPES.premium ? (
                                        <Typography variant="subtitle1">
                                            <Trans>*notes : sous réserve de disposer des droits d&apos;usage</Trans>
                                        </Typography>
                                    ) : null}
                                </CardContent>
                            </Card>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
            <Box
                display="flex"
                justifyContent="start"
                alignItems="start"
                style={{ gap: "4px", marginTop: "1rem", flexDirection: "column" }}
            >
                <PlusChip />
                <Arrow rotation="105" arrowPosition="start" style={{ marginLeft: "1rem" }}>
                    <Typography variant="h5" style={{ fontFamily: "Mansalva", color: theme.palette.primary.dark }}>
                        <Trans>
                            choisissez l’option PLUS lors de votre souscription et bénéficiez d’une version plus riche,
                            moins limitée !
                        </Trans>
                    </Typography>
                </Arrow>
            </Box>
            {/* {props.currentLanguage === "fr" ? <AskDemo /> : <Box style={{ height: "2rem" }} />} */}
        </>
    );
};

DetailsSubscription.propTypes = {
    step: number,
    datas: array,
    switch: bool,
};

export default DetailsSubscription;
