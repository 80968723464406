import { string, object } from "prop-types";
import { Box, Card, CardContent, Typography, makeStyles } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Link } from "react-router-dom";

import theme from "../globalTheme";

import AppellationImage from "../assets/appellation/appellation-image.svg";

import { WineImages } from "./WineImages";
import { urlize } from "../helpers/helpers";
import { useCustomHistory } from "hooks";

const useStyles = makeStyles(() => ({
    card: {
        borderRadius: "10px",
        width: "calc(278px - (2 * 16px))",
        padding: "16px 16px 20px",
        backgroundColor: theme.palette.background.card,
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.08)",
        cursor: "pointer",
        textDecoration: "none",
    },
    container_card: {
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
        },
    },
    image_container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.palette.common.white,
        width: "100%",
        height: "125px",
        borderRadius: 4,
    },
    card_title: {
        color: theme.palette.primary.main,
        fontWeight: "bold",
        marginBottom: 0,
        marginTop: "5px",
        textAlign: "center",
    },
    container_icons_wine: {
        display: "flex",
        justifyContent: "center",
    },
    logo: {
        maxWidth: "100%",
        maxHeight: "100%",
    },
}));

const AppellationRegionCard = (props) => {
    const styles = useStyles();
    const history = useCustomHistory();

    const name = urlize(props.name);
    const subregion = urlize(props.subregion);
    const url = history.addLocale(`/appellations/${subregion}/${name}`);

    return (
        <Card className={styles.card} component={Link} to={url}>
            <CardContent style={{ padding: "0px", display: "flex", flexDirection: "column" }}>
                <Box className={styles.image_container}>
                    <img
                        src={props?.logo?.twicpicURL ?? AppellationImage}
                        className={styles.logo}
                        alt="logo appellation"
                    />
                </Box>

                <Box
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: 80,
                    }}
                >
                    <Typography variant="h4" className={styles.card_title}>
                        {props.name}
                    </Typography>

                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                        <Box style={{ width: "16px" }} />
                        <Box className={styles.container_icons_wine} style={{ marginLeft: "16px" }}>
                            <WineImages wines={props.wines} gap={2} />
                        </Box>

                        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Link to={url} className={styles.link}>
                                <ArrowForwardIcon style={{ color: theme.palette.secondary.dark }} />
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

AppellationRegionCard.propTypes = {
    name: string,
    logo: object,
    wines: object,
    subregion: string,
};

export default AppellationRegionCard;
