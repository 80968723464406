import { useState } from "react";
import PropTypes, { bool, func, number } from "prop-types";
import { Box } from "@material-ui/core";

import SubscriptionContainer from "./SubscriptionContainer";
import theme from "../../globalTheme";
import PaymentWithReception from "../../Dashboard/Components/Payment/PaymentWithReception";
import CGAUPopin from "../../Dashboard/Popins/CGAUPopin";
import BillingInformationsBlock from "../Components/Summary/BillingInformationsBlock";
import ShortSummaryBlock from "../Components/Summary/ShortSummaryBlock";
import { getIdentityType, getPackType } from "../constants";
import { calculateTTC, numberWithSpaces } from "../../helpers/helpers";
import BaseButton from "../../Components/Buttons/BaseButton";
import ThanksPurchase from "../../Dashboard/Components/Payment/ThanksPurchase";
import Modal from "../../Containers/Modal";
import warning from "../../assets/propriétéPage/report_pb.svg";
import { PAYMENT_METHODS } from "../../config/constants";

const DeferredPayment = (props) => {
    const [CGAUPopinOpen, setCGAUPopinOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const role = getIdentityType(props.subscriptionState?.selectedRole);
    const webSub = getPackType(props.subscriptionState?.selectedPack);
    const isBankTransfert = props.selectPayment === PAYMENT_METHODS.bankTransfer;

    return (
        <>
            {" "}
            <SubscriptionContainer
                title={
                    isBankTransfert
                        ? "Vous avez choisi le paiement par virement"
                        : "Vous avez choisi le paiement par chèque"
                }
                titleVariant="h3"
                backLink
                onClickBack={props.onClickBack}
                style={{ margin: 0, marginTop: 0 }}
            >
                <Box>
                    {CGAUPopinOpen && (
                        <CGAUPopin modalOpen={CGAUPopinOpen} handleClose={() => setCGAUPopinOpen(false)} />
                    )}

                    <Modal
                        title="Attention !"
                        icon={warning}
                        actionNeeded={false}
                        open={open}
                        onClose={() => {
                            props.onClickNext();
                            setOpen(false);
                        }}
                    >
                        <ThanksPurchase subscriptionFlow={props.subscriptionFlow} paymentMethod={props.selectPayment} />
                    </Modal>

                    <PaymentWithReception
                        subscriptionFlow
                        paymentMethod={props.selectPayment}
                        totalPrice={`${numberWithSpaces(calculateTTC(props.amountToPay, 20))} €`}
                    />
                </Box>
                <BaseButton
                    onClick={() => setOpen(true)}
                    size="small"
                    color="primary"
                    variant="contained"
                    boxStyle={{ textAlign: "end" }}
                >
                    Valider le paiment par {isBankTransfert ? "virement" : "chèque"}
                </BaseButton>
            </SubscriptionContainer>
            <SubscriptionContainer
                title={"Récapitulatif de votre souscription"}
                titleVariant="h3"
                style={{ margin: 0, marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}
            >
                <Box style={{ display: "flex", width: "100%", justifyContent: "space-between", gap: "1rem" }}>
                    <ShortSummaryBlock
                        mode="full"
                        type="role"
                        value={role}
                        title="Vous souscrivez pour"
                        subscriptionState={props.subscriptionState}
                        marginLeft={"0"}
                    />
                    <ShortSummaryBlock
                        mode="full"
                        type="web"
                        value={webSub}
                        title="Abonnement"
                        switchCheck={props.switchCheck}
                        discountPrice={webSub?.discount}
                    />
                    {/* <ShortSummaryBlock
                        mode="full"
                        type="paper"
                        value={paperSub}
                        title={"Souscription papier"}
                        subscriptionState={props.subscriptionState}
                        marginRight={"0"}
                    /> */}
                </Box>
                <BillingInformationsBlock subscriptionState={props.subscriptionState} commune={props.commune} />
            </SubscriptionContainer>
        </>
    );
};

DeferredPayment.propTypes = {
    onClickBack: PropTypes.func,
    selectPayment: bool,
    subscriptionState: PropTypes.object,
    commune: PropTypes.string,
    switchCheck: bool,
    amountToPay: number,
    onClickNext: func,
    subscriptionFlow: bool,
};

export default DeferredPayment;
