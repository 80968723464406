import React from "react";
import PropTypes, { string } from "prop-types";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { firstLetterUppercase } from "helpers/helpers";
import PlusChip from "./PlusChip";
import theme from "globalTheme";

const useStyles = makeStyles(() => ({
    card: (props) => {
        return {
            backgroundColor: props.backgroundColor,
            borderRadius: 6,
            flex: "1",
            padding: 10,
        };
    },
    title_container: {
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        },
    },
}));

const PlusDetailsCard = (props) => {
    const styles = useStyles(props);
    return (
        <Box style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <Box className={styles.title_container}>
                <Box
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                        marginBottom: 4,
                        color: theme.palette.primary.dark,
                    }}
                >
                    <Typography variant="h4" style={{ fontWeight: 700 }}>
                        {firstLetterUppercase(props.subscriptionName)}
                    </Typography>{" "}
                    <Typography variant="h6">pack</Typography>
                    <PlusChip onlyChip />
                </Box>
                <Typography variant="body2" style={{ fontWeight: 700, color: theme.palette.primary.dark }}>
                    {props.price} € HT/an
                </Typography>
            </Box>
            <Box className={styles.card}>
                <Typography variant="h6" style={{ color: props.color }}>
                    {props.description}
                </Typography>
            </Box>
        </Box>
    );
};

PlusDetailsCard.propTypes = {
    subscriptionName: string,
    price: string,
    description: string,
    backgroundColor: string,
    color: string,
};

export default PlusDetailsCard;
