import { Box, makeStyles, Typography } from "@material-ui/core";
import { usePrice } from "hooks/usePrice";
import PropTypes, { bool, element, func, number, string } from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import cbImg from "../../../assets/SubscriptionPage/cb.svg";
import image1 from "../../../assets/SubscriptionPage/montre.svg";
import image2 from "../../../assets/SubscriptionPage/sablier.svg";
import BaseButton from "../../../Components/Buttons/BaseButton";
import { PAYMENT_METHODS, TVA } from "../../../config/constants";
import theme from "../../../globalTheme";
import PromoCode from "./PromoCode";

const useStyle = makeStyles(() => ({
    containerOutline: {
        border: `1px solid ${theme.palette.secondary.dark}`,
        padding: "1rem 1.875rem",
        borderRadius: "10px",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: "start",
    },
    price: {
        fontWeight: 700,
    },
    taxesContainer: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
    baseButtons: {
        width: "100%",
        // maxWidth: "15rem",
        height: "47px",
        border: "0.2px solid",
        borderColor: theme.palette.secondary.main,
        borderRadius: "4px",
        display: "flex",
    },
    subtitleBox: {
        display: "flex",
        marginBottom: "10px",
        alignItems: "center",
        width: "100%",
    },
    subtitleImg: {
        marginRight: "4px",
    },
    buttonsBox: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
    },
}));

const Payment = (props) => {
    const [promoCode, setPromoCode] = useState("");
    const [checkPromoCode, setCheckPromoCode] = useState(null);
    const styles = useStyle();
    const currentUser = useSelector((state) => state?.user?.currentUser);

    useEffect(() => {
        if (props.paymentMethod === PAYMENT_METHODS.offline && props.onClose) {
            props.onClose();
        }
    }, [props.paymentMethod]);

    const getButton = (
        buttonLabel,
        buttonImage,
        name,
        classname,
        styles,
        index,
        alignmentStyle = "flex-start",
        href
    ) => (
        <BaseButton
            href={href}
            disabled={props.disabled}
            key={index}
            classes={classname.baseButtons}
            variant="outlined"
            onClick={() => props.onClick(name)}
            style={{ ...styles, backgroundColor: props.paymentMethod === name ? theme.palette.primary.light : "" }}
            boxStyle={{ width: "100%", display: "flex", justifyContent: alignmentStyle }}
        >
            {buttonImage && (
                <img
                    style={{
                        marginRight: theme.spacing(1),
                        filter:
                            props.paymentMethod === name
                                ? "invert(100%) sepia(97%) saturate(13%) hue-rotate(237deg) brightness(104%) contrast(104%)"
                                : "",
                    }}
                    src={buttonImage}
                    alt="button_img"
                />
            )}
            <Typography
                variant="h6"
                style={{
                    color: props.paymentMethod === name ? theme.palette.common.white : theme.palette.primary.dark,
                    fontWeight: props.paymentMethod === name ? "700" : "normal",
                }}
            >
                {buttonLabel}
            </Typography>
        </BaseButton>
    );

    const { htPrice, ttcPrice, tvaPrice } = usePrice({ ht: props.totalHT });

    return (
        <>
            {props.customPart}
            {!props.disableTitle && (
                <Box style={{ display: "flex", gap: "1rem" }}>
                    <Box
                        className={styles.containerOutline}
                        style={{
                            width: !props.optionLegend && "100%",
                            flexDirection: !props.optionLegend && "row",
                            flex: 1,
                        }}
                    >
                        <Typography variant="h4" style={{ alignSelf: "start" }}>
                            {props.legend}
                        </Typography>
                        <Box style={{ textAlign: "end" }}>
                            <Typography variant="body1" className={styles.price}>
                                {props.priceLegend}
                            </Typography>
                            <Typography variant="h6">{props.legendDetail}</Typography>
                        </Box>
                    </Box>
                    {props.optionLegend && (
                        <Box className={styles.containerOutline}>
                            <Typography variant="h4" style={{ alignSelf: "start" }}>
                                {props.optionLegend}
                            </Typography>
                            <Box style={{ textAlign: "center" }}>
                                <Typography variant="body1" className={styles.price}>
                                    {props.priceOptionLegend} € HT
                                </Typography>
                            </Box>
                        </Box>
                    )}
                </Box>
            )}
            {!props.disabledRecapPrice && (
                <Box className={styles.containerOutline} style={{ flexDirection: "column", margin: "1rem 0" }}>
                    <Box className={styles.taxesContainer}>
                        <Typography variant="h4">Total hors taxes :</Typography>
                        <Typography variant="body1" className={styles.price}>
                            {htPrice}
                        </Typography>
                    </Box>
                    {checkPromoCode && (
                        <Box
                            className={styles.taxesContainer}
                            style={{ margin: "0.4rem 0", color: theme.palette.secondary.contrastText }}
                        >
                            <Typography variant="body2">Code promo {promoCode.toUpperCase()} :</Typography>
                            <Typography variant="body2">- 50 €</Typography>
                        </Box>
                    )}
                    <Box
                        className={styles.taxesContainer}
                        style={{ margin: "0.4rem 0", color: theme.palette.secondary.contrastText }}
                    >
                        <Typography variant="body2">TVA ({TVA} %) :</Typography>
                        <Typography variant="body2">{tvaPrice}</Typography>
                    </Box>
                    <Box className={styles.taxesContainer}>
                        <Typography variant="h4">Total à payer :</Typography>
                        <Typography variant="body1" className={styles.price}>
                            {ttcPrice}
                        </Typography>
                    </Box>
                </Box>
            )}
            {!props.noDiscount && (
                <PromoCode
                    checkPromoCode={checkPromoCode}
                    promoCode={promoCode}
                    onDeleteCode={() => {
                        setPromoCode("");
                        setCheckPromoCode(null);
                    }}
                    onChange={(e) => {
                        setCheckPromoCode(null);
                        setPromoCode(e.target.value);
                    }}
                    onAdd={() => {
                        if (promoCode === "MORTYBG") {
                            setCheckPromoCode(true);
                        } else {
                            setCheckPromoCode(false);
                        }
                    }}
                />
            )}

            <Box
                className={styles.containerOutline}
                style={{
                    marginTop: "1rem",
                    color: theme.palette.primary.dark,
                    flexDirection: "column",
                    backgroundColor: theme.palette.common.white,
                    padding: "1rem 2rem",
                }}
            >
                {!props.disabledTitle ? (
                    <Typography variant="h4" style={{ marginBottom: theme.spacing(2), width: "100%" }}>
                        Choisissez votre mode de paiement
                    </Typography>
                ) : null}
                <Box className={styles.subtitleBox}>
                    <img src={image1} alt="subtitle_img" className={styles.subtitleImg} />
                    <Typography variant="h5">Validation instantanée</Typography>
                </Box>
                <Box className={styles.buttonsBox} style={{ marginBottom: "20px", width: "100%" }}>
                    {getButton(
                        "Carte bancaire",
                        cbImg,
                        PAYMENT_METHODS.creditCard,
                        styles,
                        { maxWidth: props.hideSEPA ? "auto" : "15rem" },
                        0
                    )}
                    {!props?.hideSEPA &&
                        getButton("Prélèvement SEPA", null, PAYMENT_METHODS.sepaDirectDebit, styles, {}, 1, "flex-end")}
                </Box>
                <Box className={styles.subtitleBox}>
                    <img src={image2} alt="subtitle_img" className={styles.subtitleImg} />
                    <Typography variant="h5">Validation après réception du paiement</Typography>
                </Box>
                <Box className={styles.buttonsBox}>
                    {getButton(
                        <Typography variant="h6" style={{ textAlign: "center" }}>
                            Si vous souhaitez opter pour un paiement « hors-ligne » (chèque, virement, espèces), c’est
                            possible, {props.disableMailto ? "cliquez-ici" : <b>contactez-nous</b>} !
                        </Typography>,
                        null,
                        PAYMENT_METHODS.offline,
                        styles,
                        2,
                        "",
                        "",
                        !props.disableMailto &&
                            `mailto:support@feret.com?subject=Demande%20de%20paiement%20%22hors%20ligne%22%20-%20${currentUser?.zohoSubscriptionId}&body=Pr%C3%A9cisez%20dans%20cet%20e-mail%20le%20mode%20de%20paiement%20que%20vous%20souhaitez%20utiliser%20et%20%C3%A0%20quelle%20fin%20ainsi%20que%20toutes%20les%20informations%20que%20vous%20jugeriez%20importantes.%20%0ANous%20reviendrons%20rapidement%20vers%20vous.%20%0A%0AAttention%20%3A%20ne%20supprimez%20pas%20les%20informations%20ci-dessous%20%0A${currentUser?.wineEntityName}%20(${currentUser?.mainCommune}%2C%20${currentUser?.mainAppellation}%2C%20${currentUser?.zohoSubscriptionId})`
                    )}

                    {/* {getButton(
                        "Virement bancaire",
                        null,
                        PAYMENT_METHODS.bankTransfer,
                        styles,
                        { maxWidth: "15rem" },
                        2
                    )}
                    {getButton("Chèque", null, PAYMENT_METHODS.bankCheck, styles, { maxWidth: "15rem" }, 3, "flex-end")} */}
                </Box>
            </Box>
        </>
    );
};

Payment.propTypes = {
    legend: PropTypes.string,
    priceLegend: PropTypes.string,
    legendDetail: PropTypes.string,
    totalHT: PropTypes.string,
    total: PropTypes.string,
    onClick: PropTypes.func,
    paymentMethod: string,
    disabled: bool,
    disableTitle: bool,
    disabledRecapPrice: bool,
    disabledTitle: bool,
    noDiscount: bool,
    optionLegend: string,
    priceOptionLegend: number,
    hideSEPA: bool,
    onClose: func,
    disableMailto: bool,
    customPart: element,
};

export default Payment;
