import { bool, func } from "prop-types";
import Modal from "Containers/Modal";
import { Box, makeStyles, Typography } from "@material-ui/core";
import theme from "globalTheme";
import icon from "assets/popin/icones/translation.svg";
import BaseButton from "Components/Buttons/BaseButton";
import { useDispatch } from "react-redux";
import { setLocale } from "features/user/userSlice";
import { t, Trans } from "@lingui/macro";

const useStyles = makeStyles(() => ({
    content_card: {
        marginBottom: "0px !important",
    },
    comeBack_button: {
        zIndex: "100",
        cursor: "pointer",
        display: "inline",
        marginLeft: "2rem",
        position: "absolute",
        top: "18px",
        color: theme.palette.primary.dark,
        "&:hover": {
            textDecoration: "underline",
        },
    },
    description: {
        marginBottom: "0.5rem",
        textAlign: "center",
    },
    calls_to_actions_container: {
        backgroundColor: theme.palette.background.card,
        padding: "8px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    buttons_container: {
        display: "flex",
        gap: "0.5rem",
        marginTop: "0.5rem",
    },
}));

const BrowsingPreferenceModal = (props) => {
    const styles = useStyles();
    const dispatch = useDispatch();

    return (
        <Modal
            backgroundColor={`${theme.palette.primary.light} !important`}
            width="38rem"
            open={props.open}
            onClose={() => props.onClose()}
            title={
                props.registrationMode
                    ? t`L'inscription et la connexion ne sont pas encore disponibles dans votre langue`
                    : t`Vos préférences de navigation`
            }
            actionNeeded={true}
            icon={icon}
            comeBack
            onComeBack={() => props.onClickBack()}
            noFioritures
            contentCard={styles.content_card}
        >
            <Box>
                <Typography variant="h5" className={styles.description}>
                    {props.registrationMode
                        ? t`Nos équipes œuvrent à la configuration d'autres langues.`
                        : t`Cette page n'est pas disponible dans votre langue de navigation`}
                </Typography>
                <Box className={styles.calls_to_actions_container}>
                    <Typography variant="h5">
                        {props.registrationMode
                            ? t`Accéder à la connexion ou à l'inscription en`
                            : t`Vous pouvez accéder à son contenu en`}{" "}
                        :
                    </Typography>
                    <Box className={styles.buttons_container}>
                        <BaseButton
                            variant="outlined"
                            color="primary"
                            size="small"
                            onClick={() => dispatch(setLocale("fr"))}
                        >
                            <Trans>Français</Trans>
                        </BaseButton>
                        {props.noEnlish ? null : (
                            <BaseButton
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => dispatch(setLocale("en"))}
                            >
                                <Trans>Anglais</Trans>
                            </BaseButton>
                        )}
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

BrowsingPreferenceModal.propTypes = {
    open: bool,
    onClose: func,
    registrationMode: bool,
    onClickBack: func,
    noEnlish: bool,
};

export default BrowsingPreferenceModal;
