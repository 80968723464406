import { Box, Divider, InputAdornment, MenuItem, TextField, Typography, makeStyles } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { any, array, bool, func, object, oneOf, string } from "prop-types";
import { useEffect, useRef, useState } from "react";

import { useEnumValues } from "hooks/useEnumValues";
import { getSortedValues } from "../../helpers/helpers";
import AdditionalInformationTooltip from "../Tooltips/AdditionalInformationTooltip";

import ErrorIcon from "../../assets/Error.svg";

const useStyles = makeStyles((theme) => ({
    customMenuPopover: {
        "& .MuiMenu-paper": {
            maxHeight: "200px",
        },
        "& ::-webkit-scrollbar": {
            width: "8px",
        },
        "& ::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            paddingRight: "8px",
        },
        "& ::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "20px",
        },
    },
    close: (props) => ({
        position: "relative",
        right: props.correctValue || props.error ? "4px" : "20px",
        cursor: "pointer",
        fontSize: "15px",
        color: theme.palette.secondary.main,
        marginRight: props.error ? "8px" : "0px",
    }),
    menuItems: {
        fontSize: "14px",
        color: theme.palette.primary.dark,
        "&:hover": {
            backgroundColor: theme.palette.dashboard.background,
        },
        "&.MuiListItem-root.Mui-selected": {
            backgroundColor: theme.palette.dashboard.background,
        },
    },
    root: (props) => {
        const getBorderRadius = () => {
            if (props.table) {
                return "0px";
            }
            return props.borderRadius ?? "4px";
        };
        return {
            width: "100%",
            height: "100%",
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: props.noBorder
                    ? "white"
                    : props.error
                    ? theme.palette.error.main
                    : theme.palette.secondary.main,
                borderRadius: getBorderRadius(),
                height: "auto",
            },
            "& .MuiInput-underline:before": {
                content: "none",
                borderBottom: "0px",
            },
            " & .MuiSelect-select": {
                padding: props.noPadding && 0,
                paddingRight: !props.noPadding && "8px",
                "&:focus": {
                    backgroundColor: props.variant ? "transparent" : theme.palette.common.white,
                },
            },
            "& .MuiSvgIcon-root": {
                width: "15px",
            },
            "& .MuiSelect-iconOutlined": {
                color: props.arrowColor || theme.palette.secondary.main,
                marginRight: props.error ? "30px" : "0px",
            },
            "& .MuiAutocomplete-clearIndicator": {
                marginRight: (props.correctValue || props.error) && "0px",
                color: theme.palette.secondary.dark,
            },
            "& .MuiOutlinedInput-root": {
                paddingRight: 0,
                fontSize: "14px",
                color: theme.palette.primary.dark,
                borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
                backgroundColor: "white",
                height: props.fullHeight ? "100%" : "34px",
                "&.Mui-disabled": {
                    color: theme.palette.secondary.dark,
                    backgroundColor: theme.palette.disabled.main,
                },
                "&:hover fieldset": {
                    borderColor: props.noBorder
                        ? "white"
                        : props.error
                        ? theme.palette.error.main
                        : theme.palette.secondary.main,
                },
                "&.Mui-focused fieldset": {
                    border: "1px solid",
                    borderColor: props.noBorder
                        ? "white"
                        : props.error
                        ? theme.palette.error.main
                        : theme.palette.secondary.main,
                },
            },
            "& .MuiFormLabel-root": {
                display: props.table && "none",
                fontSize: "14px",
                color: props.error ? theme.palette.error.main : theme.palette.secondary.dark,
            },
        };
    },
    boxContainer: (props) => ({
        position: "relative",
        width: "100%",
        height: props.fullHeight ? "100%" : "auto",
    }),
    checkIcon: {
        width: "15px",
        fill: theme.palette.success.main,
    },
    falseIcon: {
        width: "20px",
        fill: theme.palette.error.main,
    },
    toolTipBox: {
        position: "absolute",
        right: "8px",
        top: "-8px",
        zIndex: 2,
        cursor: "default",
    },
    errorText: {
        fontSize: "12px",
        color: theme.palette.error.main,
        marginLeft: "8px",
    },
}));

const SelectInput = (props) => {
    const styles = useStyles(props);
    const [focused, setFocused] = useState(false);
    const enumValues = useEnumValues(props.enumField, { locale: props.locale });
    let filteredEnumsValues;

    const ref = useRef();
    useEffect(() => {
        if (props.autoFocus) {
            ref.current?.focus();
        }
    }, [props.autoFocus]);

    const getExcludedValues = (allEnums) => {
        if (allEnums) {
            let arr;

            if (props.excluded) {
                arr = allEnums?.filter((el) => props.value === el || !props.excluded?.includes(el));
            } else {
                arr = allEnums;
            }
            if (props.sorted) {
                arr = getSortedValues(arr, true);
            }

            filteredEnumsValues = arr;
        }
    };

    getExcludedValues(props.filteredEnums || enumValues);
    const getMenuItems = () => {
        if (props.options) {
            return props.options?.map((menuItem, i) => (
                <MenuItem key={i} className={styles.menuItems} value={menuItem?.value}>
                    {menuItem?.title}
                </MenuItem>
            ));
        } else if (props.children) {
            return props.children?.map((menuItem, i) => (
                <MenuItem key={i} className={styles.menuItems} value={menuItem?.value || menuItem?.title || menuItem}>
                    {menuItem?.title ? menuItem?.title : menuItem}
                </MenuItem>
            ));
        } else if (filteredEnumsValues) {
            return filteredEnumsValues?.map((menuItem, i) => (
                <MenuItem key={i} className={styles.menuItems} value={menuItem}>
                    {menuItem}
                </MenuItem>
            ));
        }
    };

    return (
        <Box className={[props.class, styles.boxContainer].join(" ")} style={{ ...props.boxStyle }}>
            {props.tooltipTitle && (
                <Box className={styles.toolTipBox}>
                    <AdditionalInformationTooltip color="primary" title={props.tooltipTitle} arrow />
                </Box>
            )}
            <TextField
                label={props.label}
                placeholder={props.placeholder}
                type="select"
                select={true}
                variant={props.variant ? props.variant : "outlined"}
                size="small"
                required={props.required}
                error={props.error}
                onBlur={(e) => {
                    setFocused(false);
                    props.onBlur(e);
                }}
                value={props.value || ""}
                onChange={(e1, e2, e3) => {
                    props.onChange(e1.target.value, e2, e3);
                }}
                onFocus={() => {
                    if (!focused) {
                        setFocused(true);
                    }
                    if (props.onFocus) {
                        props.onFocus();
                    }
                }}
                style={props.style}
                className={styles.root}
                disabled={props.disabled}
                SelectProps={{
                    MenuProps: {
                        PopoverClasses: { root: styles.customMenuPopover },
                        disableScrollLock: true,
                    },
                    inputRef: ref,
                }}
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            {/* {params.InputProps.endAdornment}  */}
                            {!props.disabled && props.value && !props.noClear && (
                                <CloseIcon
                                    className={styles.close}
                                    onClick={() => {
                                        if (props.onClose) {
                                            props.onClose();
                                        } else {
                                            props.onChange("");
                                        }
                                    }}
                                />
                            )}
                            {props.correctValue && (
                                <CheckIcon
                                    className={styles.checkIcon}
                                    style={{
                                        marginRight: "22px",
                                        width: "15px",
                                    }}
                                />
                            )}
                            {props.error && <img src={ErrorIcon} className={styles.falseIcon} />}
                            {props.mesure && (
                                <Box style={{ display: "flex" }}>
                                    <Divider className={styles.divider} orientation="vertical" flexItem />
                                    <Typography variant="h6" className={styles.mesure}>
                                        {props.mesure}
                                    </Typography>
                                </Box>
                            )}
                        </InputAdornment>
                    ),
                }}
            >
                {getMenuItems()}
            </TextField>
            {props.error && (
                <Typography variant="h6" className={styles.errorText}>
                    {" "}
                    {props.error}{" "}
                </Typography>
            )}
        </Box>
    );
};

SelectInput.propTypes = {
    autoFocus: bool,
    table: bool,
    onChange: func,
    onBlur: func,

    disabled: bool,
    error: string,
    label: string,
    placeholder: string,
    required: bool,
    variant: string,

    style: object,
    boxStyle: object,

    value: string,
    onFocus: func,

    correctValue: bool,
    mesure: oneOf(["An(s)", "ha", "Pied/Ha", "%", "m²"]),

    enumField: string,
    filteredEnums: any,
    excluded: any,

    sorted: any,
    noClear: bool,
    tooltipTitle: string,
    children: any,
    fullHeight: bool,
    class: object,
    options: array,
    arrowColor: string,
    locale: string,
    noPadding: bool,
    onClose: func,
};

SelectInput.defaultProps = {
    onChange: () => {},
    onBlur: () => {},
};

export default SelectInput;
