import { makeStyles } from "@material-ui/core";
import { communes, communesKeys } from "./../communes";

import PropTypes from "prop-types";
import AutocompleteChipsInput from "../Components/Inputs/AutocompleteChipsInput";
import theme from "../globalTheme";

const useStyles = makeStyles(() => ({
    filterSelect: {
        gridColumn: "1 / span 12",
        marginTop: 16,

        "& .MuiOutlinedInput-root": {
            borderRadius: 53,
        },
    },
}));

const AutocompleteCommune = (props) => {
    const styles = useStyles();
    return (
        <AutocompleteChipsInput
            class={styles.filterSelect}
            style={{ marginBottom: 27 }}
            label={props.label}
            options={communesKeys}
            uppercase
            getOptionSelected={(e1, e2) => {
                return e1.INSEEcode === e2;
            }}
            value={props.value}
            onChange={(e1, e2) => {
                props?.setFilter(e2);
            }}
            labelColor={theme.palette.primary.dark}
            labelPaddingLeft="16px"
            labelPaddingTop="1px"
            placeholderPaddingLeft="20px"
            inputPaddingLeft="20px"
            popupIcon
            getOptionLabel={(e1) => {
                return communes[e1].displayName + " (" + communes[e1].postalCode + ")";
            }}
            placeholder={props.placeholder}
        />
    );
};

AutocompleteCommune.propTypes = {
    value: PropTypes.array,
    setFilter: PropTypes.func,
    label: PropTypes.string,
    placeholder: PropTypes.string,
};

export default AutocompleteCommune;
