import React, { useState } from "react";
import RoleIdentificationContainer from "../Containers/RoleIdentificationContainer";
import PropTypes from "prop-types";
import TunnelSwitchPopin from "../../Containers/TunnelSwitchPopin";

const EstateRolePopinContainer = (props) => {
    const [previousSelection] = useState(props.subscriptionStateSelectedRole);

    const handleClose = () => {
        props.subscriptionDispatch({
            type: "setValue",
            payload: { fieldName: "selectedRole", fieldValue: previousSelection },
        });
        return props.handleClose();
    };

    return (
        <TunnelSwitchPopin
            modalOpen={props.modalOpen}
            handleClose={handleClose}
            handleOpen={props.handleOpen}
            switchCheck={props.switchCheck}
            backButton
            backButtonPosition
            style={{ height: "auto", padding: 0, backgroundColor: "#11ffee00", ...props.modalStyles }}
        >
            <RoleIdentificationContainer
                onClickNext={() => {
                    props.handleClose();
                    props.onClickNext();
                }}
                popin={true}
                subscriptionStateSelectedRole={props.subscriptionStateSelectedRole}
                subscriptionDispatch={props.subscriptionDispatch}
                style={{ width: "auto" }}
            />
            {/* BETA */}
            {/* <RoleIdentificationOtherRegionContainer
                style={{ width: "auto" }}
            /> */}
        </TunnelSwitchPopin>
    );
};

EstateRolePopinContainer.propTypes = {
  subscriptionStateSelectedRole: PropTypes.object,
  subscriptionDispatch: PropTypes.func,

  modalOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  handleOpen: PropTypes.func,

  switchCheck: PropTypes.bool,
  handleSwitchChange: PropTypes.func,
  onClickNext: PropTypes.func,
  modalStyles: PropTypes.object,
};

export default EstateRolePopinContainer;