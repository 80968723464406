/* eslint-disable no-unused-vars */
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import PropTypes, { bool, object, string } from "prop-types";
import React from "react";
import { getPricesWithOption } from "SubscriptionTunnel/Containers/WebSubscriptionContainer";
import Arrow from "../../../Components/Arrow/Arrow";

import Tag from "../../../Components/Chips/Tag";
import theme from "../../../globalTheme";
import ExtraStatusCard from "../ExtraStatusCard";
import { SUBSCRIPTION_TYPES } from "config/constants";
import PlusChip from "Components/FrontBESV/PlusChip";

const useStyles = makeStyles(() => ({
    defaultStyle: {
        width: "100%",
        padding: "20px 20px",

        // marginBottom: theme.spacing(2),
        // margin: theme.spacing(2),
        // marginTop: 0,
    },

    fullModeStyle: {
        position: "relative",

        border: `1px solid ${theme.palette.secondary.dark}`,
        borderRadius: "10px",
        [theme.breakpoints.up("sm")]: {
            "&:nth-child(1)": {
                // width: "100%",
                marginBottom: "16px",
            },
            "&:nth-child(2)": {
                // width: "100%",
            },
        },
        [theme.breakpoints.up("md")]: {
            "&:nth-child(1)": {
                // width: "50%",
                marginBottom: 0,
            },
            "&:nth-child(2)": {
                // width: "50%",
            },
        },
    },
}));

const ShortSummaryBlock = (props) => {
    const styles = useStyles(props);

    if (!props.type || !props.value) {
        return null;
    }
    if (!props.subscriptionState && props.type === "role") {
        return null;
    }

    // 2ND PAR OF CARD INFORMATIONS
    const getSecondPartCardsInformations = () => {
        if (props.type === "role") {
            return (
                <Typography
                    variant="h5"
                    style={{
                        color: theme.palette.primary.dark,
                        fontStyle: "italic",
                    }}
                >
                    {props.subscriptionState?.subscriptionCommune} (
                    {props.subscriptionState?.subscriptionMainAppellation})
                </Typography>
            );
        } else if (props.type === "web") {
            return (
                <>
                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            width: "max-content",
                            alignItems: "baseline",
                        }}
                    >
                        <Typography
                            variant="h5"
                            style={{
                                color: theme.palette.primary.dark,
                                fontWeight: "900",
                                textAlign: "left",
                                fontSize: "22px",
                            }}
                        >
                            {getPricesWithOption(props.value, props.switchCheck, props.withPlus, props.value?.id === SUBSCRIPTION_TYPES.initial ? 150 : 200)}
                        </Typography>
                        <Typography
                            variant="h6"
                            style={{
                                color: theme.palette.primary.dark,
                                display: "flex",
                                alignItems: "flex-end",
                                textAlign: "left",
                            }}
                        >
                            {(props.value?.id === SUBSCRIPTION_TYPES.basic
                                ? props.value?.period
                                : props.switchCheck
                                ? "/an"
                                : " /mois") || ""}
                        </Typography>
                        {props.value?.type === "Premium" && props.switchCheck && props.discountPrice && (
                            <Typography
                                variant="h6"
                                style={{
                                    textDecorationLine: "line-through",
                                    color: theme.palette.primary.dark,
                                    marginLeft: "8px",
                                }}
                            >
                                {props.value?.price} /an
                            </Typography>
                        )}
                    </Box>

                    {props.value?.type === "Premium" && props.switchCheck && props.discountPrice && (
                        <Box style={{ display: "flex" }}>
                            <Arrow textBoxStyle={{ margin: 0 }} arrowPosition="end" rotation="105" icon="primary" />

                            <Typography
                                variant="h6"
                                style={{ fontFamily: "Mansalva", color: theme.palette.primary.dark }}
                            >
                                valable la première année
                            </Typography>
                        </Box>
                    )}
                    {props.value?.id !== SUBSCRIPTION_TYPES.basic ? (
                        <Typography
                            variant="subtitle1"
                            style={{
                                color: theme.palette.common.black,
                                textAlign: "left",
                            }}
                        >
                            Engagement de 1 an
                        </Typography>
                    ) : null}
                </>
            );
        } else if (props.type === "paper") {
            return (
                <>
                    <Typography
                        variant="h5"
                        style={{
                            color: theme.palette.primary.dark,
                            fontWeight: "900",
                            textAlign: "left",
                            fontSize: "22px",
                        }}
                    >
                        {props.subscriptionState.selectedPack !== SUBSCRIPTION_TYPES.basic && props.value?.id !== "line"
                            ? props.value?.discountPrice
                            : props.value?.price}
                    </Typography>
                    {/* BETA */}
                    {/* <Arrow
                    rotation="125"
                    arrowPosition="start"
                    style={{ marginTop: theme.spacing(1), paddingBottom: 0 }}
                >
                    <Typography
                        variant="h6"
                        style={{ cursor: "pointer", color: "#915868", fontSize: "20px", fontWeight: "900" }}
                    >
                        Vous ne payez rien maintenant
                    </Typography>
                </Arrow> */}
                </>
            );
        } else {
            return;
        }
    };

    const defaultStyle = styles.defaultStyle;
    const manuelStyle = styles.manuelStyle;
    const fullModeStyle = props.mode === "full" ? styles.fullModeStyle : {};

    const getMarginTop = () => {
        if (props.mode === "full") {
            return "8px";
        } else if (props.type === "light") {
            return "0px";
        }
    };

    // COMPLETE CARD
    return (
        <Box className={`${defaultStyle} ${manuelStyle} ${fullModeStyle} ${props.boxStyle}`}>
            {/* {props.mode === "full" &&
                props.type !== "role" &&
                props.type === "web" &&
                props.switchCheck &&
                props.value.id === SUBSCRIPTION_TYPES.premium && (
                    <Tag
                        label={"OFFRE DE LANCEMENT - 50 %"}
                        size={"large"}
                        style={{
                            fontSize: theme.typography.subtitle1.fontSize,
                            backgroundColor: theme.palette.primary.light,
                            position: "absolute",
                            top: "-9px",
                            left: props.rightTag ? "unset" : "20%",
                            right: props.rightTag ?? "unset",
                            color: theme.palette.common.white,
                            fontWeight: 700,
                            fontStyle: "uppercase",
                            minHeight: "auto",
                            padding: "3px 0px",
                            height: "auto",
                        }}
                    />
                )} */}
            <Box style={{ display: "flex" }}>
                {props.mode === "full" && props.value?.picture && (
                    <img
                        style={props.iconStyle || { height: "20px", marginRight: "0.5rem" }}
                        src={props.value.picture}
                        alt="subscription_image"
                    />
                )}
                <Typography
                    variant={props.mode === "full" ? "h4" : "h6"}
                    style={{
                        textAlign: "left",
                        fontSize: props.mode === "light" && "10px",
                        fontWeight: props.mode === "full" && 600,
                    }}
                >
                    {props.title}
                </Typography>
            </Box>
            <Box
                style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "center",
                    marginTop: !props.isFree ? getMarginTop() : 0,
                }}
            >
                {props.mode === "full" && (
                    <Typography
                        variant="h5"
                        style={{
                            color: theme.palette.primary.dark,
                            textAlign: "left",
                            fontWeight: "bold",
                            marginLeft: props.type !== "role" && theme.spacing(1),
                        }}
                    >
                        <span>
                            {props.type === "role"
                                ? props.subscriptionState?.subscriptionEstateName
                                : `« ${props.value?.type} » `}
                        </span>
                        {props.withPlus && [SUBSCRIPTION_TYPES.initial, SUBSCRIPTION_TYPES.essential].includes(props.value.id) && (
                            <span style={{ ...theme.typography.h5, fontWeight: 400 }}>
                                {" "}
                                avec pack <PlusChip/>
                            </span>
                        )}
                    </Typography>
                )}
                {props.mode === "light" && (
                    <Grid container>
                        <Grid xs={12}>
                            <Typography
                                variant="h6"
                                style={{
                                    textAlign: "left",
                                    fontWeight: "bold",
                                    color: theme.palette.primary.dark,
                                }}
                            >
                                {props.type === "role"
                                    ? props.subscriptionState?.subscriptionEstateName ?? props.value?.title
                                    : props.value?.type}
                                {/* {props.switchCheck && props.value?.type === "Premium" && (
                                    <Tag
                                        style={{
                                            backgroundColor: theme.palette.primary.light,
                                            color: theme.palette.common.white,
                                            padding: "8px 3px",
                                            minHeight: "auto",
                                            height: "auto",
                                            marginLeft: "0.5rem",
                                            borderRadius: "2px",
                                        }}
                                        label={"- 50 %"}
                                    />
                                )} */}
                            </Typography>
                        </Grid>
                        <Grid xs={12}>
                            <Typography
                                variant="h6"
                                style={{
                                    fontSize: "18px",
                                    textAlign: "left",
                                    fontWeight: "bold",
                                    color: theme.palette.primary.dark,
                                }}
                            >
                                {props.withPlus && [SUBSCRIPTION_TYPES.initial, SUBSCRIPTION_TYPES.essential].includes(props.value.id) && (
                                    <ExtraStatusCard />
                                )}
                                {props.type !== "role" ? (
                                    props.type === "web" ? (
                                        // BETA
                                        <>
                                            <Box style={{ display: "flex", alignItems: "center" }}>
                                                <Typography
                                                    variant="h6"
                                                    style={{
                                                        marginRight: "4px",
                                                        textAlign: "left",
                                                        fontWeight: "900",
                                                    }}
                                                >
                                                    {getPricesWithOption(
                                                        props.value,
                                                        props.switchCheck,
                                                        props.withPlus, 
                                                        props.value?.id === SUBSCRIPTION_TYPES.initial ? 150 : 200
                                                    )}
                                                </Typography>
                                                <Typography variant="subtitle1" style={{ textAlign: "left" }}>
                                                    {(props.value?.id === SUBSCRIPTION_TYPES.basic
                                                        ? props.value?.period
                                                        : props.switchCheck
                                                        ? " /an"
                                                        : " /mois") || ""}
                                                </Typography>
                                            </Box>
                                            {props.value?.type === "Premium" &&
                                                props.switchCheck &&
                                                props.discountPrice && (
                                                    <Box style={{ display: "flex" }}>
                                                        <Arrow
                                                            textBoxStyle={{ margin: 0 }}
                                                            arrowPosition="end"
                                                            rotation="105"
                                                            icon="primary"
                                                        />

                                                        <Typography variant="h6" style={{ fontFamily: "Mansalva" }}>
                                                            valable la première année
                                                        </Typography>
                                                    </Box>
                                                )}
                                            {/* {props.value?.type === "Premium" && props.switchCheck && (
                                                <Typography
                                                    variant="subtitle1"
                                                    style={{ textDecorationLine: "line-through" }}
                                                >
                                                    {props.value.price} /an
                                                </Typography>
                                            )} */}
                                        </>
                                    ) : props.subscriptionState.selectedPack !== SUBSCRIPTION_TYPES.basic &&
                                      props.value?.type !== "line" ? (
                                        props.value?.discountPrice
                                    ) : (
                                        props.value?.price
                                    )
                                ) : (
                                    ""
                                )}
                            </Typography>
                            {props.type === "paper" && props.value?.type !== "line" && (
                                <Box>
                                    <Typography
                                        variant="h6"
                                        style={{
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            color: theme.palette.primary.dark,
                                        }}
                                    >
                                        Gratuit
                                    </Typography>
                                    {/* BETA */}
                                    {/* <Arrow
                                        rotation="125"
                                        arrowPosition="start"
                                        style={{ marginTop: theme.spacing(1), paddingBottom: 0 }}
                                    >
                                        <Typography
                                            variant="h6"
                                            style={{ cursor: "pointer", color: "#915868", fontSize: "14px", fontWeight: "900", maxWidth: "130px", marginTop: "4px" }}
                                        >
                                            Vous ne payez rien maintenant
                                        </Typography>
                                    </Arrow> */}
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                )}
            </Box>
            {props.mode === "full" && (
                <Box
                    style={{ marginLeft: props.type === "web" && theme.spacing(1) }}
                    className={props.classNameCardInfo}
                >
                    {getSecondPartCardsInformations()}
                </Box>
            )}
        </Box>
    );
};

ShortSummaryBlock.propTypes = {
    subscriptionState: PropTypes.object,
    mode: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.object,
    value: PropTypes.object,
    marginLeft: PropTypes.string,
    marginRight: PropTypes.string,
    switchCheck: PropTypes.bool,
    discountPrice: string,
    boxStyle: object,
    iconStyle: object,
    classNameCardInfo: string,
    rightTag: string,
    isFree: bool,
    withPlus: bool,
};

export default ShortSummaryBlock;
