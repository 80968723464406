/* eslint-disable no-unused-vars */

import React, { useState } from "react";
import Loading from "../Components/Loader/Loading";
import leftPopin from "../assets/SubscriptionPage/bg_popin.png";
import rightPopin from "../assets/SubscriptionPage/bg_popin_right.png";
import BaseButton from "../Components/Buttons/BaseButton";

import { makeStyles, Modal, Backdrop, Fade, IconButton, Box, Link } from "@material-ui/core";
import cross from "../assets/cross.svg";
import PropTypes from "prop-types";
import ControlledSwitch from "../Components/Switch/ControlledSwitch";

const useStyle = makeStyles((theme) => ({
    contentBox: (props) => ({
        // ...theme.paddings.default.popin,
        padding: props.contentBoxPadding ? props.contentBoxPadding : 20,
        marginTop: props.popinImage ? props.marginTop : "-40px",
        "&::-webkit-scrollbar": {
            width: "8px",
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            paddingRight: "8px",
        },
        "&::-webkit-scrollbar-thunb": {
            backgroundColor: "#D0BDC3",
            borderRadius: "20px",
        },
    }),
    buttonsContainer: (props) => {
        const getJustifyContent = () => {
            if (props.backLink && props.backButton) {
                return "space-between";
            } else if (props.backLink && !props.backButton) {
                return "flex-start";
            } else if (!props.backLink && props.backButton) {
                return "flex-end";
            }
        };

        return {
            display: "flex",
            justifyContent: getJustifyContent(),
            position: "relative",
            width: "100%",
            height: "auto",
        };
    },
    crossButton: {
        ...theme.margins.default.popin,
        padding: 0,
        cursor: "pointer",
        zIndex: 99,
    },
    backLink: {
        ...theme.margins.default.popin,
        cursor: "pointer",
        fontStyle: "italic",
    },
    popinMainContainer: {
        // position: "fixed",
        margin: "auto",
        left: "50%",
        right: "50%",
        display: "flex",
        justifyContent: "center",
    },
    popinContainer: (props) => ({
        position: "absolute",
        padding: props.padding ? 20 : 0,
        backgroundColor: "white",
        borderRadius: "50%",
        top: "-40px",
        zIndex: 1000,
    }),
    popin: (props) => ({
        height: props.imageSize || "120px",
        left: "16px",
        top: "8px",
        width: props.imageSize || "120px",
        zIndex: 1000,
        position: "static",
    }),
    mainContainer: (props) => {
        const getSide = () => {
            if (props.bgSide === "right") {
                return `url(${rightPopin})`;
            } else if (props.bgSide === "left") {
                return `url(${leftPopin})`;
            } else {
                return;
            }
        };
        return {
            backgroundColor: theme.palette.common.white,
            backgroundImage: getSide(),
            backgroundPosition: props.bgSide === "right" ? "bottom right" : "bottom left",
            backgroundRepeat: "no-repeat",
            backgroundSize: props.bgSize ? props.bgSize : "80%",
            position: "relative",
            outline: "none",
            marginTop: "min(40px)",
            borderRadius: "10px",
            width: "auto",
            "&::-webkit-scrollbar": { width: "8px" },
            "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                paddingRight: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.secondary.main,
                borderRadius: "20px",
            },
        };
    },
    modal: (props) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "scroll",
        "&::-webkit-scrollbar": {
            width: "8px",
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            paddingRight: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "20px",
        },
        zIndex: "1300 !important",
        "& .MuiBackdrop-root": {
            backgroundColor: props.modalBg || "rgba(0,0,0,0.5)",
        },
    }),
}));

const TunnelSwitchPopin = (props) => {
    const styles = useStyle(props);

    if (!props.modalOpen) {
        return null;
    }
    return (
        <Modal
            onBackdropClick={props.onBackdropClick}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={[styles.modal, props.customModal].join(" ")}
            open={props.modalOpen}
            onClose={props.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
        >
            <Fade in={open}>
                <Box className={[styles.mainContainer, props.className].join(" ")} style={props.style}>
                    <Box className={styles.buttonsContainer}>
                        {props.backLink && (
                            <Link className={styles.backLink} underline="always" onClick={props.backLinkOnClick}>
                                Retour
                            </Link>
                        )}
                        {props.backButton && (
                            <IconButton aria-label="close" className={styles.crossButton} onClick={props.handleClose}>
                                <img src={cross} alt="close_cross" />
                            </IconButton>
                        )}
                    </Box>

                    <Box className={styles.popinMainContainer}>
                        {props.popinImage ? (
                            <Box className={styles.popinContainer} style={props.popinContainerStyle}>
                                <img className={styles.popin} src={props.popinImage} alt="popin_image" />
                            </Box>
                        ) : null}
                    </Box>

                    <Box style={{ ...props.styleSwitch }}>
                        {props.priceSwitch && (
                            <ControlledSwitch
                                switchCheck={props.switchCheck}
                                handleSwitchChange={(event) => props.handleSwitchChange(event)}
                            />
                        )}
                    </Box>

                    <Box style={props.contentBox} className={[styles.contentBox, props.contentBoxClass].join(" ")}>
                        {props.loading ? <Loading loading={props.loading} /> : props.modalOpen ? props.children : null}
                    </Box>

                    {props.completedButton ? (
                        <Box>
                            <BaseButton>Terminé</BaseButton>
                        </Box>
                    ) : null}
                </Box>
            </Fade>
        </Modal>
    );
};

export default TunnelSwitchPopin;

TunnelSwitchPopin.propTypes = {
    clickableElement: PropTypes.any,
    children: PropTypes.any,
    style: PropTypes.object,
    className: PropTypes.object,
    customModal: PropTypes.object,
    bgSide: PropTypes.string,
    marginTop: PropTypes.number,
    popinContainerStyle: PropTypes.object,
    backButton: PropTypes.bool,
    completedButton: PropTypes.bool,
    popinImage: PropTypes.string,
    imageSize: PropTypes.number,
    bgSize: PropTypes.string, // ex: 60%

    modalOpen: PropTypes.bool,
    handleClose: PropTypes.func,
    handleOpen: PropTypes.func,
    onBackdropClick: PropTypes.func,

    loading: PropTypes.bool,

    priceSwitch: PropTypes.bool,
    styleSwitch: PropTypes.object,
    switchCheck: PropTypes.bool,
    handleSwitchChange: PropTypes.func,
    contentBox: PropTypes.object,
    contentBoxClass: PropTypes.object,
    modalBg: PropTypes.string,

    backLink: PropTypes.bool,
    backLinkOnClick: PropTypes.func,
};

TunnelSwitchPopin.defaultProps = {
    handleClose: () => {},
    handleOpen: () => {},
    onBackdropClick: () => {},
};
