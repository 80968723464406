import { useEffect, useRef, useState } from "react";

export const useDebounce = (callback, dependencies, delay = 500) => {
    const handleTimeout = useRef();

    useEffect(() => {
        if (handleTimeout.current) clearTimeout(handleTimeout.current);
        handleTimeout.current = setTimeout(() => callback(), delay);
        return () => clearTimeout(handleTimeout.current);
    }, dependencies);
};

export const useSearchDebounce = (callback, delay = 500) => {
    const [searchInput, setSearchInput] = useState("");

    useDebounce(() => callback(searchInput), [searchInput], delay);

    return [searchInput, setSearchInput];
};
