function SvgTwitter() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="21" viewBox="0 0 307 304" fill="none">
            <path d="M88.5 10H18.5L219.5 294H288L88.5 10Z" stroke="#632D3E" strokeWidth="19" />
            <path d="M29.5 299.5H5L119.5 170L131 184.5L29.5 299.5Z" fill="#632D3E" />
            <path d="M268 2L171.5 111.5L182 128L292 2H268Z" fill="#632D3E" />
            <path d="M29.5 299.5H5L119.5 170L131 184.5L29.5 299.5Z" stroke="#632D3E" strokeWidth="3" />
            <path d="M268 2L171.5 111.5L182 128L292 2H268Z" stroke="#632D3E" strokeWidth="3" />
        </svg>
    );
}

export default SvgTwitter;
