import { useState } from "react";
import { Typography, Box, makeStyles } from "@material-ui/core";
import PropTypes, { string } from "prop-types";
import ClickableElementPopin from "../../Containers/ClickableElementPopin";
import BaseButton from "../../Components/Buttons/BaseButton";
import theme from "../../globalTheme";
import reportPb from "../../assets/propriétéPage/report_pb.svg";
import Modal from "../../Containers/Modal";
import BaseRadio from "../../Components/Radio/BaseRadio";
import TextareaInput from "../../Components/Inputs/TextareaInput";
import { Trans } from "@lingui/macro";

const useStyles = makeStyles(() => ({
    arrowNavigation: {
        position: "absolute",
        width: "100%",
        zIndex: 2,
        display: "flex",
        justifyContent: "space-between",
        top: "50%",
        left: 0,
        "& .Mui-disabled": {
            filter:
                "brightness(0) saturate(100%) invert(100%) sepia(6%) saturate(6978%) hue-rotate(285deg) brightness(84%) contrast(90%)",
        },
    },
    radioContainer: {
        display: "flex",
        alignItems: "center",
        color: theme.palette.primary.dark,
    },
    message_container: {
        marginTop: "1rem",
    },
}));

const BookPopin = (props) => {
    const styles = useStyles();
    const activeIndex = props.scans?.length ? 0 : null;
    const [reportProblem, setReportProblem] = useState();
    const [message, setMessage] = useState(null);
    const [choice, setChoice] = useState("contentUnComplete");
    const [step, setStep] = useState(0);

    const content = (step) => {
        switch (step) {
            case 0:
                return (
                    <>
                        <Typography variant="h6" style={{ color: theme.palette.primary.dark }}>
                            Que souhaitez-vous signaler ?
                        </Typography>
                        <Box className={styles.radioContainer}>
                            <BaseRadio
                                size="small"
                                value="contentUnComplete"
                                checked={choice === "contentUnComplete"}
                                onChange={() => setChoice("contentUnComplete")}
                            />
                            <Typography
                                style={{ marginLeft: "0.5rem" }}
                                variant="h6"
                                onClick={() => setChoice("contentUnComplete")}
                            >
                                {props.estateName} n’apparaît pas entièrement sur cette page
                            </Typography>
                        </Box>
                        <Box className={styles.radioContainer}>
                            <BaseRadio
                                size="small"
                                value="contentMissing"
                                checked={choice === "contentMissing"}
                                onChange={() => setChoice("contentMissing")}
                            />
                            <Typography
                                style={{ marginLeft: "0.5rem" }}
                                variant="h6"
                                onClick={() => setChoice("contentMissing")}
                            >
                                {props.estateName} n’apparaît pas du tout sur cette page
                            </Typography>
                        </Box>
                        <Box className={styles.message_container}>
                            <TextareaInput
                                label="Commentaire"
                                value={message}
                                onChange={(e) => setMessage(e)}
                                textareaMinHeight={125}
                                minHeight={300}
                                disabledLimit
                                maxLength={120}
                                noTranslation
                            />
                        </Box>
                    </>
                );
            case 1:
                return (
                    <Typography variant="h6" style={{ color: theme.palette.primary.dark }}>
                        Merci pour votre signalement, nous le traiterons dans les meilleurs délais.
                    </Typography>
                );
        }
    };
    return (
        <>
            {reportProblem && (
                <Modal
                    icon={reportPb}
                    title="Signaler un problème"
                    open={reportProblem}
                    onClose={() => {
                        setReportProblem(false);
                        setStep(0);
                    }}
                    actionNeeded={step === 0}
                    primaryCTA={{
                        text: "Envoyer",
                        onClick: () => setStep(step + 1),
                    }}
                    secondaryCTA={{
                        text: "Annuler et quitter",
                        onClick: () => setReportProblem(false),
                    }}
                >
                    {content(step)}
                </Modal>
            )}
            <ClickableElementPopin
                backButton={true}
                bgSide="left"
                clickableElement={props.clickableElement}
                maxWidth="790px"
                loading={props.loading}
            >
                <Box>
                    <Box style={{ textAlign: "center" }}>
                        <img
                            style={{
                                maxHeight: "80vh",
                                width: "-webkit-fill-available",
                            }}
                            src={props.scans?.[activeIndex]}
                        />
                    </Box>
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                        }}
                    >
                        {!props.linkToBuy ? (
                            <Typography
                                variant="h6"
                                style={{
                                    marginRight: "1rem",
                                    color: theme.palette.secondary.dark,
                                }}
                            >
                                <Trans>Rupture de stock</Trans>
                            </Typography>
                        ) : null}
                        <BaseButton
                            href={props.linkToBuy}
                            target="_blank"
                            disabled={!props.linkToBuy}
                            variant="contained"
                            color="primary"
                            size="small"
                        >
                            <Trans>Acheter cette édition</Trans>
                        </BaseButton>
                    </Box>
                    {/* <Typography
                        variant="subtitle1"
                        style={{
                            color: theme.palette.primary.light,
                            textAlign: "end",
                        }}
                    >
                        Si vous rencontrez un problème{" "}
                        <span
                            onClick={() => setReportProblem(true)}
                            style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                            }}
                        >
                            cliquez ici
                        </span>
                    </Typography> */}
                </Box>
            </ClickableElementPopin>
        </>
    );
};

BookPopin.propTypes = {
    linkToBuy: PropTypes.string,
    clickableElement: PropTypes.any,
    scans: PropTypes.array,
    loading: PropTypes.any,
    estateName: string,
};

export default BookPopin;
