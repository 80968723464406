import { Box, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { CarouselProvider, DotGroup, Slide, Slider } from "pure-react-carousel";

import "pure-react-carousel/dist/react-carousel.es.css";
import LinkCard from "./LinkCard";
import HomeEstateCard from "./card/HomeEstateCard";
import HomeWineCard from "./card/HomeWineCard";

const useStyle = makeStyles((theme) => ({
    carouselDotGroup: (props) => ({
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        "& > button": {
            display: props.images?.length < 2 ? "none" : "flex",
            backgroundColor: theme.palette.secondary.main,
            borderRadius: "50px",
            width: "9px",
            height: "9px",
            padding: 0,
            border: "none",
            marginRight: theme.spacing(1),
        },
        "& > button:disabled": {
            backgroundColor: theme.palette.primary.light,
            borderRadius: "50px",
            width: "9px",
            height: "9px",
            padding: 0,
        },
    }),
    carousel: {
        display: "flex",
        flexDirection: "column",
        overflowY: "hidden",
        position: "relative",
        width: "100%",
    },
    carouselSlider: (props) => ({
        position: "block",
        [theme.breakpoints.up("xs")]: {
            height: props.height,
            "& .slideInner___2mfX9": {
                height: props.height,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "transparent",
                borderRadius: 10,
            },
            "& .horizontalSlider___281Ls": {
                height: props.height,
            },
        },
        [theme.breakpoints.up("sm")]: {
            height: props.height,
            "& .slideInner___2mfX9": {
                height: props.height,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "transparent",
                borderRadius: 10,
            },
            "& .horizontalSlider___281Ls": {
                height: props.height,
            },
        },
        [theme.breakpoints.only("md")]: {
            height: props.height,
            "& .slideInner___2mfX9": {
                height: props.height,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "transparent",
                borderRadius: 10,
            },
            "& .horizontalSlider___281Ls": {
                height: props.height,
            },
        },
        [theme.breakpoints.only("lg")]: {
            height: props.height,
            "& .slideInner___2mfX9": {
                height: props.height,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "transparent",
                borderRadius: 10,
            },
            "& .horizontalSlider___281Ls": {
                height: props.height,
            },
        },
        [theme.breakpoints.only("xl")]: {
            height: props.height,
            "& .slideInner___2mfX9": {
                height: props.height,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "transparent",
                borderRadius: 10,
            },
            "& .horizontalSlider___281Ls": {
                height: props.height,
            },
        },
    }),
}));

const DEFAULT_SLIDE_NUMBER = 3;

const SearchCarousel = (props) => {
    const styles = useStyle(props);

    return (
        <CarouselProvider
            totalSlides={
                ["keyFigures", "contacts", "wines_home", "supports_home", "estates_home"].includes(props.type)
                    ? props.datas?.length
                    : DEFAULT_SLIDE_NUMBER
            }
            naturalSlideWidth={100}
            naturalSlideHeight={props.height}
            className={styles.carousel}
            infinite={false}
            dragEnabled={false}
            visibleSlides={props.visibleSlides}
        >
            <Slider className={styles.carouselSlider}>
                {props.type === "search_home" &&
                    props.datas?.map((el, i) => (
                        <Slide key={i} index={i}>
                            <Box key={el?.id} className={styles.card}>
                                <LinkCard {...el} />
                            </Box>
                        </Slide>
                    ))}

                {props.type === "estates_home" &&
                    props.datas?.map((estate, i) => (
                        <Slide key={i} index={i}>
                            <HomeEstateCard
                                estate={estate}
                                key={i}
                                appellation={estate?.appellation}
                                badge={estate?.badge}
                                image={estate?.image}
                                label={estate?.label}
                                onlineSales={estate?.onlineSales}
                                isOpen={estate?.isOpen}
                                wines={estate?.wines}
                                name={estate?.wineEntityName}
                                classification={estate?.classification}
                                ha={estate?.ha}
                                wineEntityId={estate?.wineEntityId}
                                missing={estate.missingField}
                            />
                        </Slide>
                    ))}

                {props.type === "wines_home" &&
                    props.datas?.map((wine, i) => (
                        <Slide key={i} index={i}>
                            <HomeWineCard
                                wine={wine}
                                sweetness={wine?.sweetness}
                                wineId={wine?._id}
                                key={wine?._id}
                                name={wine?.brandName}
                                years={wine?.years}
                                color={wine?.color}
                                appellation={wine?.appellation}
                                wineEntityName={wine?.wineEntityName}
                                reward={wine?.reward}
                                score={wine?.score}
                                missing={wine?.missingField}
                                wineEntityId={wine?.wineEntityId}
                                vintageIds={wine?.vintageIds}
                            />
                        </Slide>
                    ))}

                {props.type === "supports_home" &&
                    props.datas?.map((support, i) => (
                        <Slide key={i} index={i}>
                            <img src={support.src} alt={support.alt} />
                        </Slide>
                    ))}
            </Slider>

            {<DotGroup className={styles.carouselDotGroup} />}
        </CarouselProvider>
    );
};

SearchCarousel.propTypes = {
    datas: PropTypes.array,
    responsiveHeight: PropTypes.array,
    visibleSlides: PropTypes.number,
    type: PropTypes.string,
    height: PropTypes.any,
    width: PropTypes.any,
};

export default SearchCarousel;
