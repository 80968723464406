import * as React from "react";
import PropTypes from "prop-types";

function SvgPropriete(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={49.025}
            height={36.12}
            {...props}
        >
            <g data-name="Groupe 3576">
                <g data-name="Groupe 1303">
                    <path
                        data-name="Trac\xE9 666"
                        d="M27.335 35.467h19.964a.763.763 0 00.763-.763V19.946a1.021 1.021 0 00-1.022-1.022h-13.4"
                        fill="none"
                        stroke={props.color}
                        strokeLinecap="round"
                        strokeWidth={1.3}
                    />
                    <path
                        data-name="Trac\xE9 667"
                        d="M28.975 35.467H1.638a.744.744 0 01-.744-.744V19.668a.744.744 0 01.744-.744H15.32"
                        fill="none"
                        stroke={props.color}
                        strokeLinecap="round"
                        strokeWidth={1.3}
                    />
                    <path
                        data-name="Ligne 132"
                        fill="none"
                        stroke={props.color}
                        strokeLinecap="round"
                        strokeWidth={1.3}
                        d="M15.537 25.919l-14.643-.04"
                    />
                    <path
                        data-name="Ligne 133"
                        fill="none"
                        stroke={props.color}
                        strokeLinecap="round"
                        strokeWidth={1.3}
                        d="M32.767 25.107H16.048"
                    />
                    <path
                        data-name="Ligne 134"
                        fill="none"
                        stroke={props.color}
                        strokeLinecap="round"
                        strokeWidth={1.3}
                        d="M48.062 25.879l-14.525.04"
                    />
                    <path
                        data-name="Trac\xE9 668"
                        d="M16.048 35.468V18.252a.743.743 0 01.374-.646l8.053-4.6"
                        fill="none"
                        stroke={props.color}
                        strokeLinecap="round"
                        strokeWidth={1.3}
                    />
                    <path
                    data-name="Trac\xE9 669"
                    d="M32.766 35.468V18.252a.742.742 0 00-.375-.646l-8.052-4.6"
                    fill="none"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeWidth={1.3}
                    />
                    <path
                    data-name="Trac\xE9 670"
                    d="M.894 18.925l2.9-4.687"
                    fill="none"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeWidth={1.3}
                    />
                    <path
                    data-name="Trac\xE9 671"
                    d="M48.131 18.925l-2.9-4.687"
                    fill="none"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeWidth={1.3}
                    />
                    <path
                    data-name="Ligne 135"
                    fill="none"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeWidth={1.3}
                    d="M22.323 14.238H3.794"
                    />
                    <path
                    data-name="Ligne 136"
                    fill="none"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeWidth={1.3}
                    d="M45.183 14.238H26.654"
                    />
                    <path
                    data-name="Ligne 137"
                    fill="none"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeWidth={1.3}
                    d="M44.638 11.431v2.807"
                    />
                    <path
                    data-name="Ligne 138"
                    fill="none"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeWidth={1.3}
                    d="M4.319 11.431v2.807"
                    />
                    <path
                    data-name="Ligne 139"
                    fill="none"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeWidth={1.3}
                    d="M10.002 12.303v1.935"
                    />
                    <path
                    data-name="Ligne 140"
                    fill="none"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeWidth={1.3}
                    d="M15.578 12.303v1.935"
                    />
                    <path
                    data-name="Ligne 141"
                    fill="none"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeWidth={1.3}
                    d="M33.364 12.303v1.935"
                    />
                    <path
                    data-name="Ligne 142"
                    fill="none"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeWidth={1.3}
                    d="M39.001 12.303v1.935"
                    />
                    <path
                    data-name="Trac\xE9 672"
                    d="M21.346 13.856V7.705a3 3 0 012.991-2.99h0a3 3 0 012.989 2.99v6.151"
                    fill="none"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeWidth={1.3}
                    />
                    <path
                    data-name="Ligne 143"
                    fill="none"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeWidth={1.3}
                    d="M24.34.649v4.063"
                    />
                    <path
                    data-name="Ligne 144"
                    fill="none"
                    stroke={props.color}
                    strokeLinecap="round"
                    strokeWidth={1.3}
                    d="M21.35 8.075h5.98"
                    />
                    <path
                    data-name="Trac\xE9 673"
                    d="M15.025 18.924h18.339l-8.89-6.621z"
                    fill={props.color}
                    />
                </g>
                <path
                data-name="Trac\xE9 712"
                d="M3.863 21.639v2.045"
                fill="none"
                stroke={props.color}
                strokeLinecap="round"
                />
                <path
                data-name="Trac\xE9 723"
                d="M3.863 30.639v1.322"
                fill="none"
                stroke={props.color}
                strokeLinecap="round"
                />
                <path
                data-name="Trac\xE9 726"
                d="M20.437 28.54v3.421"
                fill="none"
                stroke={props.color}
                strokeLinecap="round"
                />
                <path
                data-name="Trac\xE9 729"
                d="M20.437 21.971v.69"
                fill="none"
                stroke={props.color}
                strokeLinecap="round"
                />
                <path
                data-name="Trac\xE9 717"
                d="M36.863 21.639v2.045"
                fill="none"
                stroke={props.color}
                strokeLinecap="round"
                />
                <path
                data-name="Trac\xE9 720"
                d="M36.863 30.639v1.322"
                fill="none"
                stroke={props.color}
                strokeLinecap="round"
                />
                <path
                data-name="Trac\xE9 713"
                d="M7.863 21.639v2.045"
                fill="none"
                stroke={props.color}
                strokeLinecap="round"
                />
                <path
                data-name="Trac\xE9 722"
                d="M7.863 30.639v1.322"
                fill="none"
                stroke={props.color}
                strokeLinecap="round"
                />
                <path
                data-name="Trac\xE9 725"
                d="M24.437 28.54v3.421"
                fill="none"
                stroke={props.color}
                strokeLinecap="round"
                />
                <path
                data-name="Trac\xE9 727"
                d="M24.437 21.971v.69"
                fill="none"
                stroke={props.color}
                strokeLinecap="round"
                />
                <path
                data-name="Trac\xE9 716"
                d="M40.863 21.639v2.045"
                fill="none"
                stroke={props.color}
                strokeLinecap="round"
                />
                <path
                data-name="Trac\xE9 719"
                d="M40.863 30.639v1.322"
                fill="none"
                stroke={props.color}
                strokeLinecap="round"
                />
                <path
                data-name="Trac\xE9 714"
                d="M11.863 21.639v2.045"
                fill="none"
                stroke={props.color}
                strokeLinecap="round"
                />
                <path
                data-name="Trac\xE9 721"
                d="M11.863 30.639v1.322"
                fill="none"
                stroke={props.color}
                strokeLinecap="round"
                />
                <path
                data-name="Trac\xE9 724"
                d="M28.437 28.54v3.421"
                fill="none"
                stroke={props.color}
                strokeLinecap="round"
                />
                <path
                data-name="Trac\xE9 728"
                d="M28.437 21.971v.69"
                fill="none"
                stroke={props.color}
                strokeLinecap="round"
                />
                <path
                data-name="Trac\xE9 715"
                d="M44.863 21.639v2.045"
                fill="none"
                stroke={props.color}
                strokeLinecap="round"
                />
                <path
                data-name="Trac\xE9 718"
                d="M44.863 30.639v1.322"
                fill="none"
                stroke={props.color}
                strokeLinecap="round"
                />
            </g>
        </svg>
    );
}

SvgPropriete.propTypes = {
    color: PropTypes.string,
};

export default SvgPropriete;

