import React from "react";
import PropTypes, { bool, string } from "prop-types";
import { FormControlLabel, Switch, makeStyles, Box, Typography } from "@material-ui/core";
import theme from "../../globalTheme";
import { useWindowDimensions } from "../../helpers/helpers";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: "flex",
        alignItems: "flex-end",
    },
    root: (props) => ({
        "& .MuiSwitch-colorPrimary": {
            color: theme.palette.primary.main,
        },
        "& .MuiTypography-root": {
            color: theme.palette.secondary.dark,
            fontSize: "14px",
        },
        "&.MuiFormControlLabel-root": {
            margin: 0,
        },
        "& .MuiSwitch-track": {
            backgroundColor: "#915868",
            border: "1px solid",
            borderColor: "#915868",
            opacity: 1,
        },
        "& .MuiSwitch-switchBase": {
            top: props.switchPosition?.top ? props.switchPosition?.top : 0,
            left: props.switchPosition?.left ? props.switchPosition?.left : 0,
        },
        "& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#915868",
        },
        "& .MuiSwitch-colorPrimary.Mui-disabled + .MuiSwitch-track": {
            backgroundColor: theme.palette.secondary.main,
        },
        "& .MuiSwitch-colorPrimary.Mui-disabled": {
            color: theme.palette.secondary.main,
        },
        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            opacity: 1,
        },
    }),
}));

const ControlledSwitch = (props) => {
    const styles = useStyles(props);
    const { width, height } = useWindowDimensions();

    const getColor = (switchCheck, disabled) => {
        if (switchCheck && !disabled) {
            return theme.palette.secondary.dark;
        } else if (disabled) {
            return theme.palette.secondary.main;
        } else {
            return theme.palette.primary.dark;
        }
    };

    const getTTC = () => {
        if (width >= 960) {
            return "Prix TTC";
        } else {
            return "TTC";
        }
    };
    const getHT = () => {
        if (width >= 960) {
            return "Prix HT";
        } else {
            return "HT";
        }
    };

    return (
        <Box style={props.boxStyle} className={styles.mainContainer}>
            <Typography
                variant={props.variant || "h6"}
                style={{
                    fontWeight: !props.switchCheck && !props.disabled && 700,
                    color: getColor(props.switchCheck, props.disabled),
                    whiteSpace: "nowrap",
                    paddingBottom: "8px",
                    fontFamily: props?.fontTypo ?? "Lato, Helvetica, Arial, sans-serif",
                    cursor: "pointer",
                }}
                onClick={props?.switchCheck ? () => props?.handleSwitchChange(false) : () => {}}
            >
                {props.leftLabel || getTTC()}
            </Typography>
            <FormControlLabel
                style={{ ...props.style }}
                className={styles.root}
                control={
                    <Switch
                        color="primary"
                        name="checkedSwitch"
                        checked={props.switchCheck}
                        onChange={(event) => props.handleSwitchChange(event)}
                        disabled={props.disabled}
                    />
                }
            />
            <Box>
                {props.rightLabelExtra && (
                    <Box
                        style={{ display: "flex", cursor: "pointer", width: "fit-content" }}
                        onClick={!props?.switchCheck ? () => props?.handleSwitchChange(true) : () => {}}
                    >
                        {props.rightLabelExtra}
                    </Box>
                )}
                <Typography
                    variant={props.variant || "h6"}
                    style={{
                        fontWeight: props.switchCheck && !props.disabled && 700,
                        color: getColor(!props.switchCheck, props.disabled),
                        whiteSpace: "nowrap",
                        paddingBottom: "8px",
                        fontFamily: props?.fontTypo ?? "Lato, Helvetica, Arial, sans-serif",
                        cursor: "pointer",
                    }}
                    onClick={!props?.switchCheck ? () => props?.handleSwitchChange(true) : () => {}}
                >
                    {props.rightLabel || getHT()}
                </Typography>
            </Box>
        </Box>
    );
};

ControlledSwitch.propTypes = {
    switchCheck: PropTypes.bool,
    handleSwitchChange: PropTypes.func,
    placement: PropTypes.oneOf(["start", "end"]),
    label: PropTypes.string,
    style: PropTypes.object,
    leftLabel: PropTypes.string,
    rightLabel: PropTypes.string,
    disabled: PropTypes.bool,
    boxStyle: PropTypes.object,
    rightLabelExtra: PropTypes.any,
    fontTypo: PropTypes.string,
    clickedLabel: PropTypes.bool,
    bold: bool,
    variant: string,
};

export default ControlledSwitch;
