import React from "react";

import { makeStyles, Grid, Hidden } from "@material-ui/core";
import PropTypes, { object, string } from "prop-types";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import BaseSubscriptionStatusCards from "./BaseSubscriptionStatusCards";
import { getIdentityType } from "../../constants";
import EstateRolePopinContainer from "../../Popins/EstateRolePopinContainer";

const useStyles = makeStyles((theme) => ({
    primaryDarkText: {
        color: theme.palette.common.black,
        textAlign: "left",
    },
}));

const RoleIdentificationSubscriptionStatusCard = (props) => {
    const styles = useStyles();

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const identityType = getIdentityType(props.subscriptionStateSelectedRole);

    return (
        <>
            {open && (
                <EstateRolePopinContainer
                    onClickNext={props.onClickNext}
                    modalOpen={open}
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                    subscriptionStateSelectedRole={props.subscriptionStateSelectedRole}
                    subscriptionDispatch={props.subscriptionDispatch}
                    switchCheck={props.switchCheck}
                    handleSwitchChange={(event) => props.handleSwitchChange(event)}
                    modalStyles={{
                        gridColumn: "2/12",
                        height: "auto",
                    }}
                />
            )}
            <BaseSubscriptionStatusCards cardTitle="Vous souscrivez pour" disabledCard={props.disabledCard || false}>
                <Grid container item spacing={2} alignItems="center">
                    <Hidden only={["xs", "sm", "lg"]}>
                        <Grid item xs={3} md={2} lg={2} xl={3}>
                            <CardMedia image={identityType?.picture} component="img" />
                        </Grid>
                    </Hidden>
                    <Grid item xs={9}>
                        <Typography className={styles.primaryDarkText} align="center">
                            {props.estateName || props.subscriptionState?.subscriptionEstateName || identityType?.title}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            width: "100%",
                            paddingTop: 0,
                        }}
                    ></Grid>
                </Grid>
            </BaseSubscriptionStatusCards>
        </>
    );
};

RoleIdentificationSubscriptionStatusCard.propTypes = {
    disabledCard: PropTypes.bool,

    subscriptionStateSelectedRole: PropTypes.number,
    subscriptionDispatch: PropTypes.func,
    estateName: string,
    modalOpen: PropTypes.bool,
    handleClose: PropTypes.func,
    handleOpen: PropTypes.func,

    switchCheck: PropTypes.bool,
    handleSwitchChange: PropTypes.func,

    onClickNext: PropTypes.func,
    subscriptionState: object,
};

export default RoleIdentificationSubscriptionStatusCard;
