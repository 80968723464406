import { t, Trans } from "@lingui/macro";
import { Box, makeStyles, Typography } from "@material-ui/core";
import Helmet from "Components/Helmet/Helmet";

import bottom_left from "assets/encyclopedia/bottom_left.svg";
import bottom_right from "assets/encyclopedia/bottom_right.svg";
import top_left from "assets/encyclopedia/top_left.svg";
import top_right from "assets/encyclopedia/top_right.svg";
import { ALL_LANGUAGES } from "config/constants";
import FeretJournalists from "FrontTasting/FeretJournalists";
import { useCustomHistory } from "hooks";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import floriture1 from "../assets/encyclopedia/floriture-1.svg";
import headerSubscribe from "../assets/encyclopedia/header-subscribe.png";
import wineglass from "../assets/encyclopedia/wine-glass.svg";
import FrontContentTop from "../Front/FrontContentTop";
import FrontMainContainer from "../Front/FrontMainContainer";
import FrontTitle from "../FrontRanking/FrontTitle";
import theme from "../globalTheme";
import { useWindowDimensions } from "../helpers/helpers";

const useStyles = makeStyles((e) => ({
    frontTitle: {
        color: theme.palette.primary.dark,
    },
    typoAbout: {
        color: theme.palette.primary.dark,
        textAlign: "justify",
    },
    boxSlider: {
        marginTop: 16,
        padding: 8,
    },
    slider: { paddingLeft: 0, paddingRight: 0, paddingBottom: 0 },
    blocSubscribe: {
        background: `url(${headerSubscribe})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "left, right",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "2rem 0",
        borderRadius: 4,

        [theme.breakpoints.down("md")]: {
            padding: "1.5em 0",
        },
    },
    btnSubscribe: {
        border: `1px solid ${theme.palette.common.white}`,
        background: theme.palette.common.white,
        fontFamily: "Lato, Helvetica, Arial, sans-serif",
        padding: "0.5em 20px",
        marginTop: "1rem",
        marginBottom: "0.5em",
        textDecoration: "none",
        borderRadius: "4px",
        color: theme.palette.primary.dark,

        [theme.breakpoints.down("md")]: {
            marginTop: "1em",
        },

        "&:hover": {
            border: `1px solid ${theme.palette.common.white}`,
            background: "transparent",
            color: theme.palette.common.white,
        },
    },
    blocContact: {
        backgroundColor: theme.palette.common.white,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "end",
        padding: "4em 0",
        marginTop: "1em",
        backgroundPositionY: "top",
        backgroundPositionX: "left",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",

        [theme.breakpoints.down("md")]: {
            marginTop: "0em",
            padding: "1em 0",
            paddingBottom: "3em",
            backgroundPosition: "0% 100%",
        },
        [theme.breakpoints.down("sm")]: {
            backgroundPosition: "0% 100%",
            backgroundSize: 130,
        },
    },
    btnContact: {
        border: `1px solid ${theme.palette.primary.dark}`,
        fontFamily: "Lato, Helvetica, Arial, sans-serif",
        padding: "0.5em 20px",
        marginTop: "1em",
        textDecoration: "none",
        borderRadius: "4px",
        color: theme.palette.primary.dark,

        "&:hover": {
            border: `1px solid ${theme.palette.primary.dark}`,
            background: theme.palette.primary.dark,
            color: theme.palette.common.white,
        },
    },
    textSubscribe: {
        color: theme.palette.common.white,
        fontWeight: 700,
        textAlign: "center",
        [theme.breakpoints.down("md")]: {
            width: "70%",
        },
    },
    textContact: {
        width: "60%",
        textAlign: "right",
        color: theme.palette.primary.dark,
    },
}));

const RoomTastingPage = () => {
    const styles = useStyles();
    const history = useCustomHistory();
    const { width } = useWindowDimensions();
    const locale = useSelector((state) => state?.user?.locale || "fr");
    const currentUser = useSelector((state) => state.user.currentUser);

    const getBackgroundImage = () => {
        if (width >= 1920) {
            return {
                backgroundImage: `url(${top_left}), url(${top_right}), url(${bottom_left}), url(${bottom_right})`,
                backgroundPositionY: "71px, top, bottom, bottom",
                backgroundPositionX: "left, right, left, right",
            };
        } else if (width < 1920 && width >= 960) {
            return {
                backgroundImage: `url(${bottom_right})`,
                backgroundPositionY: "top",
                backgroundPositionX: "right",
            };
        }
        return {
            backgroundImage: "none",
        };
    };

    return (
        <>
            <Helmet
                title={t`Salle de dégustation Féret`}
                content={t`Découvrez la salle de dégustation Féret, un incontournable pour les amateurs de vins bordelais. Retrouvez ici les témoignages et l'expertise des dégustateurs partenaires de Bordeaux et ses Vins. Laissez-vous inspirer, éduquer et émerveiller par la richesse de cette région viticole d'exception grâce aux passionnantes contributions de nos partenaires dégustateurs.`}
            >
                {ALL_LANGUAGES.map((lang) => (
                    <link
                        key={lang}
                        rel={lang === "fr" ? "canonical" : "alternate"}
                        href={`https://bordeaux.feret.com/${lang}/salle-de-degustation`}
                        hrefLang={lang}
                    />
                ))}
            </Helmet>
            <FrontContentTop
                backgroundColor={theme.palette.background.card}
                boxStyles={{
                    ...getBackgroundImage(),
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "auto",
                    minHeight: "calc(100vh - 75px)",
                    alignItems: "flex-start",
                    paddingBottom: 0,
                }}
            >
                <FrontTitle className={styles.frontTitle} title={t`La salle de dégustation Féret`} variantTypo="h1" />

                <FrontMainContainer
                    styleCardContent
                    className={styles.main_container}
                    style={{
                        backgroundImage: `url(${floriture1})`,
                        backgroundPositionY: "top",
                        backgroundPositionX: "left",
                        backgroundRepeat: "no-repeat",
                        paddingBottom: 0,
                    }}
                    hideFloritureTopImg
                >
                    <Box style={{ backgroundColor: theme.palette.common.white, padding: 2 }}>
                        <Typography variant="body2" className={styles.typoAbout}>
                            <Trans>
                                Découvrez la <b>salle de dégustation Féret</b>, une ressource incontournable pour les
                                amateurs de vins bordelais. Cette page regroupe tous les journalistes partenaires de la
                                solution <i>Bordeaux et ses Vins</i> qui partagent leur expertise et leurs impressions
                                de dégustation. Laissez-vous inspirer, éduquer et émerveiller par la richesse et la
                                diversité de cette région viticole d&apos;exception, grâce aux contributions
                                passionnantes de nos journalistes partenaires.
                            </Trans>
                        </Typography>
                    </Box>
                    <Box className={styles.boxSlider}>
                        <FeretJournalists
                            titleCenter
                            hideLowBar
                            titleStyle={{ color: theme.palette.primary.dark, fontWeight: 700, marginTop: 0 }}
                            boxClassName={styles.slider}
                            noPadding
                        />
                    </Box>

                    {!currentUser && (
                        <Box className={styles.blocSubscribe}>
                            <Typography variant="h5" className={styles.textSubscribe}>
                                <Trans>Pas encore abonné ou hors région bordelaise ?</Trans>
                            </Typography>
                            <Typography variant="h3" style={{ color: theme.palette.common.white, textAlign: "center" }}>
                                <Trans>
                                    Vous souhaitez <b>faire déguster vos vins ?</b>
                                </Trans>
                            </Typography>
                            <a
                                className={styles.btnSubscribe}
                                target="_blank"
                                rel="noreferrer"
                                href={"https://pro.feret.com/salle-degustation-feret/#demande"}
                            >
                                <Trans>Contactez-nous maintenant !</Trans>
                            </a>
                        </Box>
                    )}

                    <Box
                        className={styles.blocContact}
                        style={{
                            backgroundImage: `url(${wineglass})`,
                        }}
                    >
                        <Typography variant="body2" className={styles.textContact} style={{ color: "#360318" }}>
                            <Trans>
                                <b>Vous êtes journaliste</b> et souhaitez <b>faire partie</b> de la{" "}
                                <b>salle de dégustation Féret</b> ?
                            </Trans>
                        </Typography>
                        <Link className={styles.btnContact} to={history.addLocale("/contact")}>
                            <Trans>Contactez-nous !</Trans>
                        </Link>
                    </Box>
                </FrontMainContainer>
            </FrontContentTop>
        </>
    );
};

export default RoomTastingPage;
