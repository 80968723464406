import { makeStyles } from "@material-ui/core";
import { formatConcat } from "../helpers/helpers";
import BaseTooltip from "../Components/Tooltips/Tooltip";
import { array, bool, number } from "prop-types";
import { useMemo } from "react";
import { capitalize } from "lodash";

// Glasses icons (border black)
import fallBack from "../assets/appellation/glasses/clairet.svg";

import BlancLiquoreux from "../assets/appellation/glasses/blanc_liquoreux.svg";
import Rose from "../assets/appellation/glasses/rose.svg";
import Rouge from "../assets/appellation/glasses/rouge.svg";
import BlancMoelleux from "../assets/appellation/glasses/blanc_moelleux.svg";
import BlancSec from "../assets/appellation/glasses/blanc_sec.svg";

import BlancEffervescent from "../assets/appellation/glasses/blanc_cremant.svg";
import RoseEffervescent from "../assets/appellation/glasses/rose_cremant.svg";

// Glasses icons (border white)
import fallBackWhite from "../assets/appellation/glasses/clairet_white.svg";

import BlancLiquoreuxWhite from "../assets/appellation/glasses/blanc_liquoreux_white.svg";
import RoseWhite from "../assets/appellation/glasses/rose_white.svg";
import RougeWhite from "../assets/appellation/glasses/rouge_white.svg";
import BlancMoelleuxWhite from "../assets/appellation/glasses/blanc_moelleux_white.svg";
import BlancSecWhite from "../assets/appellation/glasses/blanc_sec_white.svg";

import BlancEffervescentWhite from "../assets/appellation/glasses/blanc_cremant_white.svg";
import RoseEffervescentWhite from "../assets/appellation/glasses/rose_cremant_white.svg";

// Bottles icons
import fallBackBottle from "../assets/appellation/bottles/clairet.svg";

import RougeBottle from "../assets/appellation/bottles/rouge.svg";
import BlancLiquoreuxBottle from "../assets/appellation/bottles/blanc_liquoreux.svg";
import RoseBottle from "../assets/appellation/bottles/rose.svg";
import BlancSecBottle from "../assets/appellation/bottles/blanc_sec.svg";
import BlancMoelleuxBottle from "../assets/appellation/bottles/blanc_moelleux.svg";

import BlancEffervescentBottle from "../assets/appellation/bottles/crement_blanc.svg";
import RoseEffervescentBottle from "../assets/appellation/bottles/crement_rose.svg";

const useStyles = makeStyles(() => ({
    icon_wine: ({ height, gap }) => ({
        width: "auto !important",
        height: `${height}px !important`,
        marginRight: gap,
    }),
}));

export const WineImages = ({ wines, limit, isBottle = false, isWhite = false, height = 32, gap = 4 }) => {
    const styles = useStyles({ height, gap });

    const glassImagesByWine = {
        BlancLiquoreux,
        Rose,
        Rouge,
        BlancMoelleux,
        BlancSec,
        BlancEffervescent,
        RoseEffervescent,
    };

    const glassWhiteImagesByWine = {
        BlancLiquoreux: BlancLiquoreuxWhite,
        Rose: RoseWhite,
        Rouge: RougeWhite,
        BlancMoelleux: BlancMoelleuxWhite,
        BlancSec: BlancSecWhite,
        BlancEffervescent: BlancEffervescentWhite,
        RoseEffervescent: RoseEffervescentWhite,
    };

    const bottleImagesByWine = {
        BlancLiquoreux: BlancLiquoreuxBottle,
        Rose: RoseBottle,
        Rouge: RougeBottle,
        BlancMoelleux: BlancMoelleuxBottle,
        BlancSec: BlancSecBottle,
        BlancEffervescent: BlancEffervescentBottle,
        RoseEffervescent: RoseEffervescentBottle,
    };

    const defaultImage = useMemo(() => {
        let fallBackImage = fallBack;
        if (isBottle) fallBackImage = fallBackBottle;
        if (isWhite) fallBackImage = fallBackWhite;
        return <img src={fallBackImage} className={styles.icon_wine} alt="clairet icon" />;
    }, [isBottle, isWhite]);

    const getWineName = (wine, separator = "", withAccent = false) => {
        const { color, sweetness, type } = wine;
        const displayColor = color === "Rosé" && !withAccent ? "Rose" : color;
        const sweetCheck = (color === "Blanc" || !["Sec", "Demi-sec"].includes(sweetness)) && type !== "Effervescent";
        const displaySweetness = sweetCheck ? sweetness : null;
        const displayType = type === "Effervescent" ? type : null;
        return formatConcat([displayColor, displaySweetness, displayType], separator);
    };

    const wineImages = useMemo(() => {
        let imagesByWine = glassImagesByWine;
        if (isBottle) imagesByWine = bottleImagesByWine;
        if (isWhite) imagesByWine = glassWhiteImagesByWine;
        return (
            wines?.length > 0 &&
            wines
                .map((wine, i) => {
                    if (limit && i >= limit) return null;
                    const wineName = getWineName(wine);

                    if (!imagesByWine?.[wineName]) return null;

                    const label = capitalize(getWineName(wine, " ", true).toLowerCase());
                    return { src: imagesByWine[wineName], wineName, label };
                })
                .filter(Boolean)
        );
    }, [wines]);

    return wineImages?.length > 0
        ? wineImages.map(({ src, wineName, label }, i) => {
              return (
                  <BaseTooltip key={i} title={label}>
                      <img src={src} className={styles.icon_wine} alt={`${wineName} clairet icon`} />
                  </BaseTooltip>
              );
          })
        : defaultImage;
};

WineImages.defaultProps = {
    height: 32,
    gap: 4,
};

WineImages.propTypes = {
    wines: array,
    isBottle: bool,
    isWhite: bool,
    limit: number,
    height: number,
};
