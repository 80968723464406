import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, makeStyles, Typography } from "@material-ui/core";
import theme from "../globalTheme";
import FrontContentTop from "../Front/FrontContentTop";
import FrontTitle from "../FrontRanking/FrontTitle";
import FrontMainContainer from "../Front/FrontMainContainer";
import { urlize, useWindowDimensions } from "../helpers/helpers";
import floriture1 from "../assets/encyclopedia/floriture-1.svg";
import floriture2 from "../assets/encyclopedia/floriture-2.svg";
import floriture3 from "../assets/encyclopedia/floriture-3.svg";
import floriture4 from "../assets/encyclopedia/floriture-4.svg";
import floriture5 from "../assets/encyclopedia/floriture-5.svg";
import floriture6 from "../assets/encyclopedia/floriture-6.svg";
import subRegionCard from "../assets/appellation/sub-regions-card.svg";
import AppellationListWithTabs from "../FrontAppellation/AppellationListWithTabs";

import { useGetAppellationDescriptionsFrontQuery } from "../generated/graphql";
import Loading from "../Components/Loader/Loading";
import { SUBREGIONS, SUBREGIONSTITLE } from "../config/constants";
import Page404 from "Components/ErrorPage/Page404";
import Page500Front from "Components/ErrorPage/Page500Front";
import LoadingWithImage from "Components/Loader/LoadingWithImage";
import { t, Trans } from "@lingui/macro";
import Helmet from "Components/Helmet/Helmet";
import { useCustomHistory } from "hooks";

const useStyles = makeStyles((e) => ({
    typoDescription: {
        color: theme.palette.text.primary,
        marginBottom: 34,

        [theme.breakpoints.up("lg")]: {
            marginBottom: 40,
        },

        [theme.breakpoints.up("xl")]: {
            marginBottom: 24,
        },
    },
    boxSlider: {
        marginTop: 16,
        padding: "8px 0px",
    },

    vineyard: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        marginBottom: 6,
        gap: 16,
        [theme.breakpoints.up("md")]: {
            gap: 24,
            marginBottom: 9,
        },

        [theme.breakpoints.up("lg")]: {
            marginBottom: 20,
        },
    },
    cardBox: {
        gridColumn: "1 / span 12",
        // backgroundImage: `url(${subRegionCard})`,
        // backgroundPositionY: "top",
        // backgroundPositionX: "center",
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "143%",
        // height: 250,
        borderRadius: 10,
        overflow: "hidden",

        [theme.breakpoints.up("sm")]: {},

        [theme.breakpoints.up("md")]: {
            backgroundColor: "#eaeaea",
            gridColumn: "1 / span 8",
            alignSelf: "stretch",
            maxHeight: 315,
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 9",
            maxHeight: 390,
        },

        [theme.breakpoints.up("xl")]: {
            maxHeight: 412,
        },
    },
    imageTop: {
        width: "100%",

        [theme.breakpoints.up("md")]: {
            height: "100%",
            width: "89%",
            objectFit: "cover",
        },

        [theme.breakpoints.up("lg")]: {
            width: "auto",
        },

        [theme.breakpoints.up("xl")]: {
            width: "cover",
        },
    },
    subRegionsBox: {
        gridColumn: "1 / span 12",
        display: "flex",
        flexDirection: "column",
        gap: 8,

        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 4",
        },

        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 3",
            gap: 28,
        },
    },
    subRegionsTitle: {
        color: theme.palette.text.primary,

        [theme.breakpoints.up("md")]: {
            textAlign: "center",
        },
    },
    subRegionsBtnsBox: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexWrap: "wrap",
        gap: "8px 6px",

        [theme.breakpoints.up("md")]: {
            flexDirection: "column",
            alignItems: "center",
            gap: "10px 6px",
        },

        [theme.breakpoints.up("lg")]: {
            gap: "21px 6px",
        },
    },
    subRegionsBtn: {
        borderRadius: "4px",
        padding: 8,
        width: "auto",
        color: theme.palette.primary.dark,
        textDecoration: "none",
    },
    subRegionImg: {
        width: "100%",
        height: "auto",
    },
}));

const AllAppellationsPage = () => {
    const styles = useStyles();
    const { width } = useWindowDimensions();
    const locale = useSelector((state) => state?.user?.locale || "fr");
    const params = useParams();
    const { addLocale } = useCustomHistory();

    const {
        data: { GetAppellationDescriptionsFront: appellations } = {},
        loading,
        error,
    } = useGetAppellationDescriptionsFrontQuery({
        variables: { locale },
    });

    const getSubRegionBg = (subregion) => {
        switch (subregion) {
            case "Médoc":
                return {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.common.white,
                };
            case "Blaye et Bourg":
                return { backgroundColor: theme.palette.secondary.main };
            case "Libournais":
                return {
                    backgroundColor: theme.palette.secondary.dark,
                    color: theme.palette.common.white,
                };
            case "Graves et Sauternais":
                return { backgroundColor: theme.palette.wine.light };
            case "Bordeaux et Bordeaux supérieur":
                return {
                    backgroundColor: theme.palette.background.card,
                    color: theme.palette.primary.dark,
                    border: `1px solid ${theme.palette.primary.dark}`,
                };
            case "Entre-deux-Mers":
                return { backgroundColor: theme.palette.primary.light, color: theme.palette.common.white };
        }
    };

    const regions = () => {
        return SUBREGIONS.map((subregion) => {
            return urlize(subregion);
        });
    };
    const getBackgroundImage = () => {
        if (width >= 1920) {
            return {
                backgroundImage: `url(${floriture3}), url(${floriture4}), url(${floriture5}), url(${floriture6})`,
                backgroundPositionY: "71px, top, bottom, bottom",
                backgroundPositionX: "left, right, left, right",
            };
        } else if (width < 1920 && width >= 960) {
            return {
                backgroundImage: `url(${floriture4})`,
                backgroundPositionY: "top",
                backgroundPositionX: "right",
            };
        } else {
            return {
                backgroundImage: "none",
            };
        }
    };

    if (loading) {
        return <LoadingWithImage />;
    }
    if (params?.region && !regions()?.includes(params?.region)) {
        return <Page404 />;
    }
    if (error) {
        return <Page500Front />;
    }
    return (
        <>
            <Helmet
                title={params?.region ? SUBREGIONSTITLE[params.region] : t`Appellations`}
                content={
                    params?.region
                        ? `Découvrez l'histoire de la sous-région viticole ${
                              SUBREGIONSTITLE[params.region]
                          } et explorez toutes les appellations qui y sont produites. Plongez dans l'univers des vins du vignoble bordelais avec Bordeaux et ses Vins.`
                        : "Découvrez les appellations du vignoble bordelais, un terroir d'exception qui produit des vins de renommée mondiale. Histoire, caractéristiques, cépages... Trouvez toutes les informations sur les appellations bordelaises et explorez l'univers passionnant du vin sur Bordeaux et ses Vins."
                }
            />
            <FrontContentTop
                backgroundColor={theme.palette.primary.dark}
                boxStyles={{
                    backgroundColor: theme.palette.primary.dark,
                    ...getBackgroundImage(),
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "auto",
                    minHeight: "calc(100vh - 75px)",
                    alignItems: "flex-start",
                    paddingBottom: 20,
                }}
            >
                <FrontTitle className={styles.frontTitle} title={t`Les appellations`} />

                <FrontMainContainer
                    styleCardContent
                    className={styles.main_container}
                    style={{
                        backgroundImage: `url(${floriture1}), url(${floriture2})`,
                        backgroundPositionY: "top, bottom",
                        backgroundPositionX: "left, right",
                        backgroundRepeat: "no-repeat",
                    }}
                    hideFloritureTopImg
                >
                    <Box className={styles.vineyard}>
                        <Box className={styles.cardBox}>
                            <img className={styles.imageTop} src={subRegionCard} alt="le vignoble bordelais" />
                        </Box>
                        <Box className={styles.subRegionsBox}>
                            <Typography variant="h4" className={styles.subRegionsTitle}>
                                <Trans>Le vignoble bordelais découpé en six sous-régions</Trans>{" "}
                            </Typography>

                            <Box className={styles.subRegionsBtnsBox}>
                                {SUBREGIONS.map((region, index) => (
                                    <Link
                                        key={index}
                                        to={addLocale(`/appellations/${urlize(region)}`)}
                                        className={styles.subRegionsBtn}
                                        style={getSubRegionBg(region)}
                                    >
                                        <Typography variant="body2" style={{ fontWeight: "700" }}>
                                            {region}
                                        </Typography>
                                    </Link>
                                ))}
                            </Box>
                        </Box>
                    </Box>

                    <Typography variant="body2" className={styles.typoDescription}>
                        <Trans>
                            Le vignoble de Bordeaux compte pas moins de 48 appellations déclinées en plusieurs couleurs
                            et natures de vins. Il produit environ 80 % de vins rouges et 20 % de vins blancs auxquels
                            on peut ajouter les vins rosés et les crémants.
                        </Trans>
                        <br />
                        <Trans>
                            La présence de la Garonne et de la Dordogne permet le découpage du vignoble en 6
                            sous-régions principales : sur la rive gauche de la Garonne, le Médoc, les Graves et le
                            Sauternais ; sur la rive droite de la Dordogne, le Blayais et le Bourgeais, le Libournais et
                            l’Entre-deux-Mers entre les deux fleuves. Bordeaux et Bordeaux supérieur sont présentes sur
                            la totalité du territoire bordelais.
                        </Trans>
                    </Typography>

                    <Box className={styles.boxSlider}>
                        <AppellationListWithTabs
                            appellations={appellations}
                            titleCenter
                            hideLowBar
                            titleStyle={{
                                color: theme.palette.primary.dark,
                                fontWeight: 700,
                                marginTop: 0,
                                textAlign: "center",
                            }}
                            boxClassName={styles.slider}
                            noPadding
                            noMargin
                        />
                    </Box>
                </FrontMainContainer>
            </FrontContentTop>
        </>
    );
};

export default AllAppellationsPage;
