import { array, node, string } from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, useHistory, useLocation, useRouteMatch } from "react-router-dom";

import { setLanguagesAvailable } from "features/user/userSlice";
import BrowsingPreferenceModal from "Front/browsingPreference/BrowsingPreferenceModal";

export function RestrictedLanguageRoute({ children, languages, ...rest }) {
    const { params } = useRouteMatch();
    const { locale: pageLocale } = params;
    const settingsLocale = useSelector((state) => state?.user?.locale || "fr");
    const locale = pageLocale || settingsLocale;
    const contentTranslated = languages.includes(locale);
    const history = useHistory();

    const [warningBrowserLanguage, setWarningBrowserLanguage] = useState(false);

    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        dispatch(setLanguagesAvailable(languages));
    }, [location]);

    useEffect(() => {
        setWarningBrowserLanguage(!contentTranslated);
    }, [contentTranslated, location]);

    return (
        <>
            <BrowsingPreferenceModal
                open={warningBrowserLanguage}
                onClickBack={() => history.goBack()}
                onClose={() => setWarningBrowserLanguage(false)}
            />
            <Route {...rest} render={() => children} />
        </>
    );
}

RestrictedLanguageRoute.propTypes = {
    children: node,
    languages: array,
    path: string.isRequired,
};
