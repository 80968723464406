import download2 from "../assets/summary/download.svg";
import FeretSummaryContainer from "../Front/FeretSummary/FeretSummaryContainer";
import FeretSummaryElement from "../Front/FeretSummary/FeretSummaryElement";
import { Maybe } from "../generated/graphql";
import { Box, makeStyles } from "@material-ui/core";
import zoom from "../assets/zoom.svg";
import wineProduct from "../assets/appellation/summary/wine_product_icon.svg";
import surface from "../assets/appellation/summary/surface_icon.svg";
import ODG from "../assets/appellation/summary/ODG_icon.svg";
import CDC from "../assets/appellation/summary/CDC_icon.svg";
import { urlize } from "../helpers/helpers";
import { t, Trans } from "@lingui/macro";
import { Link } from "react-router-dom";
import { useCustomHistory } from "hooks";

const useStyles = makeStyles((theme) => ({
    subtitle_primary: {
        color: theme.palette.primary.light,
        lineHeight: "16px",
    },
    subtitle_secondary: {
        color: theme.palette.primary.dark,
    },
}));

interface AppellationSummaryProps {
    products: string;
    surface: { ha: number; percentage: number };
    yield: { vise: number; butoir: number };
    volume: number;
    density: number;
    gapBetweenRows: { min: number | string; max: number };
    missingThreshold: number;
    averageMaximumLoad: number;
    odg: string;
    specificationLink: string;
    name: Maybe<string>;
    address: { street: string; postalCode: number; city: string };
    maxWidth?: string;
}

const AppellationSummary = (props: AppellationSummaryProps) => {
    const styles = useStyles();
    const { addLocale } = useCustomHistory();

    return (
        <FeretSummaryContainer maxWidth={props.maxWidth ?? "none"}>
            <FeretSummaryElement icon={wineProduct} title={t`Vins produits`} subtitle={props.products} />
            {(props.surface.ha || props.surface.percentage) && (
                <FeretSummaryElement
                    icon={surface}
                    title={t`Superficie`}
                    subtitle={
                        <Box style={{ display: "flex", flexDirection: "column" }}>
                            {props.surface.ha && (
                                <Box className={styles.subtitle_primary}>
                                    <Trans>en ha :</Trans>{" "}
                                    <span className={styles.subtitle_secondary}>{props.surface.ha}</span>
                                </Box>
                            )}
                            {props.surface.percentage && (
                                <Box className={styles.subtitle_primary}>
                                    <Trans>en % du vignoble bordelais :</Trans>{" "}
                                    <span className={styles.subtitle_secondary}>{props.surface.percentage} %</span>
                                </Box>
                            )}
                        </Box>
                    }
                />
            )}
            {props.odg && (
                <FeretSummaryElement
                    icon={ODG}
                    title="ODG"
                    subtitle={
                        <>
                            {props.odg}
                            <Box style={{ marginLeft: "0.25rem" }}>
                                <Link to={addLocale(`/organismes-vignoble-bordelais/ODG/${urlize(props.odg)}`)}>
                                    <img style={{ width: "18px", height: "18px" }} src={zoom} />
                                </Link>
                            </Box>
                        </>
                    }
                />
            )}
            {props.specificationLink && (
                <FeretSummaryElement
                    icon={CDC}
                    title={t`Cahier des charges`}
                    subtitle={
                        <>
                            <Trans>Accéder au cahier des charges </Trans>
                            <Box style={{ marginLeft: "0.25rem" }}>
                                <a target="_blank" rel="noreferrer" href={props.specificationLink}>
                                    <img style={{ width: "18px", height: "18px" }} src={download2} />
                                </a>
                            </Box>
                        </>
                    }
                />
            )}
        </FeretSummaryContainer>
    );
};

export default AppellationSummary;
