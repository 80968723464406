/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import React from "react";
import {
    makeStyles,
    Box,
    Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import Chip from "@material-ui/core/Chip";
// import soils from "../../assets/Wine/soils.svg";
import theme  from "../../globalTheme";
import getConnectors, {getChips, firstLetterUppercase} from "../../helpers/helpers";
import soils from "../../assets/sol.svg";
import subsoils from "../../assets/sous-sol.svg";

const useStyles = makeStyles((theme) => ({
    container: {
        border: "0.4px solid",
        borderRadius: "5px",
        borderColor: theme.palette.secondary.dark,
        position: "relative",
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        paddingTop: 0,
        paddingBottom: "0px",
        [theme.breakpoints.only("xs")]: {
            margin: 0,
        }
    },
    typoContainer: {
        position: "relative",
        top: "-22px",
        left: "0px",
        display:"flex",
        alignItems: "center"
    },
    title: {
        color: theme.palette.common.black,
        zIndex: 2,
        backgroundColor: theme.palette.common.white,
        padding: "0px 2px",
        fontSize: "18px",
        fontWeight: "bold",
        [theme.breakpoints.only("xs")]: {
            fontSize: "14px",
            fontWeight: "700",
        },
    },
    typo: {
        color: theme.palette.primary.light,
        fontSize: "18px",
        top: "-18px",
        marginBottom: "1px",
        position: "relative",
        [theme.breakpoints.only("xs")]: {
            fontSize: "12px",
        },
    }
}));

const WineSoils = (props) => {
    const styles = useStyles(props);

    return (
        <Box className={styles.container}>
            <Box className={styles.typoContainer}> 
                <img src={props.subsoils ? subsoils : soils} alt="title_icon" style={{backgroundColor: "white", paddingRight: "4px", width: "50px"}}/>
                <Typography variant="h3" className={styles.title}>{props.title}</Typography>
            </Box>
            <Typography className={styles.typo}>{getChips(props.soilsType, true)}</Typography>
        </Box>
    );
};

WineSoils.propTypes = {
    soilsType: PropTypes.array,
    title: PropTypes.string,
    subsoils: PropTypes.bool,
};

export default WineSoils;