import { Box, makeStyles, Typography } from "@material-ui/core";
import Loading from "./Loading";
import MobileImg from "../../assets/loading/loading-mobile.svg";
import wine from "assets/loading/wine_background.svg";
import fioriture from "assets/loading/fioriture_loading.svg";
import { Trans } from "@lingui/macro";

const useStyles = makeStyles((theme) => ({
    background: {
        position: "absolute",
        width: "100%",
        minHeight: "calc(100vh)",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${MobileImg})`,
        backgroundSize: "contain",

        [theme.breakpoints.up("sm")]: {
            top: 0,
            minHeight: "calc(100vh - 31rem)",
            backgroundImage: `url(${fioriture})`,
            backgroundPosition: "right -15px",
            backgroundSize: "30%",
        },

        [theme.breakpoints.up("md")]: {
            top: 0,
            backgroundPosition: "left, right -15px",
            minHeight: "calc(100vh - 14rem)",
            backgroundSize: "contain, 30%",
            backgroundImage: `url(${wine}), url(${fioriture})`,
        },

        [theme.breakpoints.up("lg")]: {
            backgroundPosition: "left, right -80px",
            top: 0,
            minHeight: "calc(100vh - 14rem)",
            backgroundSize: "contain, 20%",
            backgroundImage: `url(${wine}), url(${fioriture})`,
        },

        [theme.breakpoints.up("xl")]: {
            minHeight: "calc(100vh - 14rem)",
            backgroundImage: `url(${wine}), url(${fioriture})`,
        },
    },
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        minHeight: "calc(100vh)",

        [theme.breakpoints.up("sm")]: {
            minHeight: "calc(100vh - 31rem - 75px)",
        },

        [theme.breakpoints.up("md")]: {
            minHeight: "calc(100vh - 14rem - 75px)",
        },
    },
    content: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },
    typo: {
        marginTop: "15px",
        color: theme.palette.primary.dark,
    },
}));

const LoadingWithImage = () => {
    const styles = useStyles();

    return (
        <>
            <Box className={styles.background} />

            <Box className={styles.root}>
                <Box className={styles.content}>
                    <Loading loading={true} boxStyle={{ margin: 0 }} />
                    <Typography variant="h4" className={styles.typo}>
                        <Trans>Chargement en cours...</Trans>
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

LoadingWithImage.propTypes = {};

export default LoadingWithImage;
