import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { SwiperWrapper } from "../Front/SwiperCards";
import { useParams } from "react-router-dom";
import { SUBREGIONS } from "../config/constants";
import { urlize } from "../helpers/helpers";
import { TabPanel } from "Components/TabPanel";
import { Trans } from "@lingui/macro";
import { useCustomHistory } from "hooks";
import AppellationRegionCard from "FrontAppellation/AppellationRegionCard";

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: "transparent",
    },
    container_appelation_content: (props) => ({
        justifyContent: "center",
        paddingLeft: "0px",
        paddingRight: "0px",

        [theme.breakpoints.up("xl")]: {
            width: "100%",
            margin: "auto",
        },
    }),
    appBar: {
        background: "transparent",
        border: "none",
        boxShadow: "none",
        marginTop: 26,
    },
    flexContainer: {
        display: "flex",
        justifyContent: "space-between",
    },
    subRegionButtonTypo: {
        color: theme.palette.primary.dark,
    },
    indicator: {
        backgroundColor: theme.palette.primary.dark,
        height: 4,
        borderRadius: 4,
    },
    tabBox: {
        paddingTop: 11,
    },
}));

const AppellationListWithTabs = (props) => {
    const history = useCustomHistory();
    const styles = useStyles();
    const { region } = useParams();
    const tabRef = useRef();

    useEffect(() => {
        if (region && tabRef?.current) {
            tabRef.current.scrollIntoView();
        }
    }, [region, tabRef]);

    const handleChange = (event, value) => {
        history.replace(`/appellations/${value}`);
    };

    const currentSubregion = urlize(region ?? "Médoc");

    return (
        <div className={styles.root}>
            <Typography variant="h3" style={props.titleStyle}>
                <Trans>Découvrez toutes les appellations par sous-région</Trans>
            </Typography>
            <AppBar position="static" color="default" className={styles.appBar} id="appelations-tab">
                <Tabs
                    value={currentSubregion}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    className={styles.tabs}
                    classes={{ flexContainer: styles.flexContainer, indicator: styles.indicator }}
                >
                    {SUBREGIONS.map((subregion, i) => {
                        const fontWeight = urlize(subregion) === currentSubregion ? "bold" : "normal";
                        return (
                            <Tab
                                value={urlize(subregion)}
                                key={urlize(subregion)}
                                label={
                                    <Typography
                                        variant="h5"
                                        className={styles.subRegionButtonTypo}
                                        style={{ fontWeight }}
                                    >
                                        {subregion}
                                    </Typography>
                                }
                                {...a11yProps(i)}
                                style={{ margin: "0px 16px", padding: 0, minWidth: "auto" }}
                                disableFocusRipple
                                disableRipple
                            />
                        );
                    })}
                </Tabs>
            </AppBar>

            <Box className={styles.tabsBox} ref={tabRef}>
                {SUBREGIONS.map((subregion, i) => (
                    <TabPanel key={i} value={currentSubregion} index={urlize(subregion)}>
                        <Box className={[styles.container_appelation_content, props.boxClassName].join(" ")}>
                            <SwiperWrapper
                                boxPaddingTop={4}
                                spaceBetween={20}
                                noPadding={props.noPadding}
                                noMargin={props.noMargin}
                                dark
                            >
                                {props.appellations?.length > 0 &&
                                    props.appellations
                                        .filter(Boolean)
                                        .filter(({ subregion }) => subregion && currentSubregion === urlize(subregion))
                                        .map((e, i) => {
                                            const key = `appellation_region-${i}`;
                                            return (
                                                <AppellationRegionCard
                                                    key={key}
                                                    wines={e.wines}
                                                    name={e.name}
                                                    logo={e.logo}
                                                    subregion={e.subregion}
                                                />
                                            );
                                        })}
                            </SwiperWrapper>
                        </Box>
                    </TabPanel>
                ))}
            </Box>
        </div>
    );
};

AppellationListWithTabs.propTypes = {
    appellations: PropTypes.array,
    titleCenter: PropTypes.bool,
    hideLowBar: PropTypes.bool,
    titleStyle: PropTypes.object,
    noPadding: PropTypes.bool,
    noMargin: PropTypes.bool,
    boxClassName: PropTypes.object,
};

export default AppellationListWithTabs;
