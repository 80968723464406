import { Box, Typography, makeStyles } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import { useTranslation } from "react-i18next";

const useStyle = makeStyles(() => ({
    icon: () => ({
        fontSize: "22px !important",
        color: "white",
        marginRight: "4px !important",
    }),
    toastContainer: {
        display: "flex !important",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    typo: {
        fontSize: "13px !important",
    },
    toast: {
        "&.Toastify__toast--error": {
            backgroundColor: "#B1314B !important",
        },
    },
}));

const ToastOptions = (props) => {
    const { t } = useTranslation();
    const styles = useStyle(props);

    const toastSuccessComponent = (text) => (
        <Box
            className={styles.toastContainer}
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "flex-start",
            }}
        >
            <CheckIcon className={styles.icon} style={{ fontSize: "22px", color: "white", marginRight: "4px" }} />
            <Typography style={{ fontSize: 13 }} className={styles.typo}>
                {text}
            </Typography>
        </Box>
    );

    const toastDeleteComponent = (text) => (
        <Box
            className={styles.toastContainer}
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "flex-start",
            }}
        >
            <DeleteIcon className={styles.icon} style={{ fontSize: "22px", color: "white", marginRight: "4px" }} />
            <Typography className={styles.typo}>{text}</Typography>
        </Box>
    );

    const toastErrorComponent = (text) => (
        <Box
            className={styles.toastContainer}
            style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "flex-start",
            }}
        >
            <CloseIcon className={styles.icon} style={{ fontSize: "22px", color: "white", marginRight: "4px" }} />
            <Typography className={styles.typo}>{text}</Typography>
        </Box>
    );

    // SAVE SUCCESS
    const saveSuccessText = toastSuccessComponent(t("toast.success"));
    const saveSuccessOptions = {
        autoClose: 2000,
        hideProgressBar: false,
        pauseOnHover: true,
        className: styles.toast,
    };

    // SAVE ERROR
    const saveErrorOptions = {
        autoClose: 5000,
        hideProgressBar: false,
        pauseOnHover: true,
        className: styles.toast,
    };

    // PASSWORD SUCCESS
    const passwordText = toastSuccessComponent("Le nouveau mot de passe a bien été pris en compte");
    const passwordOptions = {
        autoClose: 2700,
        hideProgressBar: false,
        pauseOnHover: true,
        className: styles.toast,
    };

    // DELETE SUCCESS
    const deleteElementOptions = {
        autoClose: 2700,
        hideProgressBar: false,
        pauseOnHover: true,
        className: styles.toast,
    };

    return {
        saveSuccessText,
        saveSuccessOptions,
        saveErrorOptions,

        passwordText,
        passwordOptions,

        deleteElementOptions,

        toastDeleteComponent,
        toastSuccessComponent,
        toastErrorComponent,
    };
};

ToastOptions.propTypes = {};

export default ToastOptions;
