import { t, Trans } from "@lingui/macro";
import { Box, makeStyles, Typography } from "@material-ui/core";
import harvestMethod from "assets/Wine/harvest_method.svg";
import truncate from "html-truncate";
import React, { useState } from "react";

import analyse from "assets/Wine/analyse.png";
import blending from "assets/Wine/assemblage.svg";
import climat from "assets/Wine/climatologie.svg";
import marketing from "assets/Wine/commercialisation.svg";
import averageAge from "assets/Wine/average_age.svg";
import totalSurfaceArea from "assets/Wine/total_surface_area.svg";
import averageDensity from "assets/Wine/average_density.svg";
import profil from "assets/Wine/profil.svg";
import zoom from "assets/zoom.svg";
import BaseButton from "Components/Buttons/BaseButton";
import Tooltip from "Components/Tooltips/Tooltip";
import SummaryElement from "FrontEstate/Components/PropertySummary/SummaryElement";
import theme from "globalTheme";
import getConnectors, { firstLetterUppercase, getHtmlLength, parseHtml } from "helpers/helpers";
import ClimatologyPopin from "./ClimatologyPopin";
import { string } from "prop-types";

const useStyles = makeStyles(() => ({
    typosContainer: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",

        [theme.breakpoints.only("xs")]: {
            flexWrap: "wrap",
        },
    },
    typoProfil: {
        ...theme.front.typography.labeur.dark,
        color: theme.palette.primary.dark,
    },
    typo__grapeVaretyName: {
        ...theme.front.typography.labeur.dark,
    },
    typo__percentage: {
        ...theme.front.typography.labeur.dark,
    },
    fontSizeXs: {
        ...theme.front.typography.labeur.dark,
    },
    title: {
        ...theme.front.typography.h6.dark_b,
    },
    analyzeTitle: {
        ...theme.front.typography.label.darkLight,
    },
    analyzeTitleContent: {
        ...theme.front.typography.labeur.dark,
    },
}));

const WineSummary = (props) => {
    const styles = useStyles(props);
    const [globalClimatologyOpen, setGlobalClimatologyOpen] = useState(false);
    const [detailedClimatologyOpen, setDetailedClimatologyOpen] = useState(false);

    // PROFIL
    const getSummaryElementProfile = () => {
        if (props.color || props.bubbles || props.sugar) {
            return (
                <Typography className={styles.typoProfil} style={{ color: theme.palette.primary.dark }}>
                    {props.color} {`· ${props.bubbles}`} {`· ${props.sugar}`}
                </Typography>
            );
        }
    };

    const getSummaryAverageAge = () => {
        if (props.averageAge) return <Typography variant="h6">{props.averageAge}</Typography>;
    };
    const getSummaryTotalSurfaceArea = () => {
        if (props.totalSurfaceArea) return <Typography variant="h6">{props.totalSurfaceArea}</Typography>;
    };
    const getSummaryAverageDensity = () => {
        if (props.averageDensity) return <Typography variant="h6">{props.averageDensity}</Typography>;
    };

    // BLENDING
    const getSummaryElementBlending = () => {
        if (props.grapes) {
            return props.grapes?.map((el, i) => {
                let key = `summary-element-blending-${i}`;
                return (
                    <Box
                        key={key}
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                        }}
                    >
                        {el?.grapeVarietyName && (
                            <Typography
                                variant="h6"
                                className={styles.typo__grapeVaretyName}
                                style={{
                                    color: theme.palette.primary.dark,
                                    marginRight: 15,
                                }}
                            >{`${el?.grapeVarietyName}`}</Typography>
                        )}
                        {el?.percentage && (
                            <Typography
                                variant="h6"
                                style={{
                                    fontWeight: "bold",
                                    color: theme.palette.primary.dark,
                                }}
                                className={styles.typo__percentage}
                            >{` ${el.percentage} %`}</Typography>
                        )}
                    </Box>
                );
            });
        } else {
            return (
                <Typography variant="h6" style={{ color: theme.palette.primary.dark }}>
                    <Trans>Information non renseignée</Trans>
                </Typography>
            );
        }
    };
    // CLIMATOLOGY
    const getTitleClimatology = () => {
        // GLOBAL
        if (props.weather) {
            return (
                <Box style={{ display: "flex", alignItems: "center" }}>
                    <Typography
                        variant="h6"
                        className={styles.title}
                        style={{ marginRight: "10px", color: theme.palette.common.black }}
                    >
                        <Trans>Climatologie</Trans>
                    </Typography>
                    {props?.weather?.length && getHtmlLength(props?.weather?.[0]) > 90 ? (
                        <Tooltip fontSize="11px" title={t`Voir plus de détails`} color="primary" arrow>
                            <img
                                src={zoom}
                                style={{ cursor: "pointer" }}
                                onClick={() => setGlobalClimatologyOpen(true)}
                            />
                        </Tooltip>
                    ) : (
                        ""
                    )}
                    {globalClimatologyOpen && (
                        <ClimatologyPopin
                            modalOpen={globalClimatologyOpen}
                            handleClose={() => setGlobalClimatologyOpen(false)}
                            globalClimatology={props?.weather?.[0]}
                        />
                    )}
                </Box>
            );
            // DETAILED CLIMATOLOGY
        } else if (props.detailedWeather && props.detailedWeather?.length !== 0) {
            return (
                <Box style={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="h6" className={styles.title} style={{ marginRight: "10px", color: "#000000" }}>
                        <Trans>Climatologie</Trans>
                    </Typography>

                    {(getHtmlLength(props.detailedWeather[0]?.text) > 90 || props.detailedWeather?.length > 1) && (
                        <Tooltip fontSize="11px" title={t`Voir plus de détails`} color="primary" arrow>
                            <img
                                src={zoom}
                                style={{ cursor: "pointer" }}
                                onClick={() => setDetailedClimatologyOpen(true)}
                            />
                        </Tooltip>
                    )}
                    {detailedClimatologyOpen && (
                        <ClimatologyPopin
                            modalOpen={detailedClimatologyOpen}
                            handleClose={() => setDetailedClimatologyOpen(false)}
                            detailedClimatology={props?.detailedWeather}
                        />
                    )}
                </Box>
            );
        }
    };

    const getSummaryElementWeather = () => {
        // GLOBAL
        if (props.weather) {
            return (
                <Box style={{ maxWidth: "256px", overflowWrap: "break-word" }}>
                    <Typography
                        variant="h6"
                        className={styles.fontSizeXs}
                        style={{ color: theme.palette.primary.dark }}
                    >
                        {parseHtml(truncate(props.weather?.[0], 90))}
                    </Typography>
                </Box>
            );
            // DETAILED CLIMATOLOGY
        } else if (props.detailedWeather && props.detailedWeather?.length !== 0) {
            return (
                <Box style={{ maxWidth: "256px", overflowWrap: "break-word" }}>
                    <Box style={{ display: "flex", alignItems: "center" }}>
                        <img
                            src={props.detailedWeather[0]?.logo?.data}
                            alt="climat_image"
                            style={{
                                marginRight: "8px",
                                width: "16px",
                                height: "18px",
                            }}
                        />
                        <Typography
                            style={{
                                color: "#915868",
                                fontFamily: "Lato",
                                fontSize: "14px",
                            }}
                        >
                            {props.detailedWeather[0]?.season}
                        </Typography>
                    </Box>
                    <Typography
                        variant="h6"
                        style={{ color: theme.palette.primary.dark }}
                        className={styles.fontSizeXs}
                    >
                        {parseHtml(truncate(props.detailedWeather[0]?.text, 90))}
                    </Typography>
                </Box>
            );
        }
    };
    // MARKETING
    const getSummaryElementMarketing = () => {
        const marketing = props.marketing;
        if (props.marketing || props.productPageUrl) {
            return (
                <>
                    {marketing ? firstLetterUppercase(getConnectors(props.marketing).toLowerCase()) : null}
                    {props.productPageUrl && (
                        <BaseButton
                            color="primary"
                            className={styles.fontSizeXs}
                            style={{
                                // height: "34px",
                                minHeight: "34px",
                                marginTop: "4px",
                                color: "#915868",
                            }}
                            onClick={() => {
                                if (
                                    props.productPageUrl?.indexOf("http://") == 0 ||
                                    props.productPageUrl?.indexOf("https://") == 0
                                ) {
                                    window.open(props.productPageUrl);
                                } else {
                                    window.open(`https://${props.productPageUrl}`);
                                }
                            }}
                        >
                            <Trans>Acheter ce vin à la propriété</Trans>
                        </BaseButton>
                    )}
                </>
            );
        }
    };

    // ANALYSES
    const getSummaryElementAnalyzes = () => {
        if (props.alcoholicStrength || props.allergens || props.nonOGM || props.noSulphites) {
            return (
                <>
                    {props?.alcoholicStrength && (
                        <Box className={styles.typosContainer}>
                            <Typography
                                variant="h6"
                                // className={styles.analyzeTitle}
                                style={{ color: "#915868", marginRight: 15 }}
                            >
                                <Trans>Titre alcoolique</Trans>
                            </Typography>
                            <Typography
                                variant="h6"
                                // className={styles.analyzeTitleContent}
                                style={{ color: theme.palette.primary.dark }}
                            >
                                {props.alcoholicStrength} %
                            </Typography>
                        </Box>
                    )}
                    {props.allergens && props.allergens?.length !== 0 && (
                        <Box className={styles.typosContainer}>
                            <Typography
                                variant="h6"
                                style={{ marginRight: "4px", color: "#915868" }}
                                // className={styles.analyzeTitle}
                            >
                                <Trans>Allergènes</Trans>
                            </Typography>
                            <Typography
                                variant={"h6"}
                                // className={styles.analyzeTitleContent}
                                style={{ color: theme.palette.primary.dark }}
                            >
                                {getConnectors(props.allergens)}
                            </Typography>
                        </Box>
                    )}
                    {props.nonOGM && (
                        <Box className={styles.typosContainer}>
                            <Typography
                                variant="h6"
                                style={{
                                    fontWeight: "bold",
                                    color: theme.palette.primary.dark,
                                }}
                                className={styles.fontSizeXs}
                            >
                                <Trans>Ne contient pas d&apos;OGM</Trans>
                            </Typography>
                        </Box>
                    )}
                    {props.noSulphites && (
                        <Box className={styles.typosContainer}>
                            <Typography
                                variant="h6"
                                style={{ fontWeight: "bold", color: theme.palette.primary.dark }}
                                className={styles.fontSizeXs}
                            >
                                {" "}
                                <Trans>Vin sans sulfites ajoutés</Trans>
                            </Typography>
                        </Box>
                    )}
                </>
            );
        } else {
            return (
                <Typography variant="h6" className={styles.fontSizeXs} style={{ color: theme.palette.primary.dark }}>
                    <Trans>Information non renseignée</Trans>
                </Typography>
            );
        }
    };

    const getSummaryElementHarvestMethod = () => {
        if (props.vintageId) {
            return null;
        } else if (props.harvestMethod && props.harvestMethod.length) {
            return (
                <Typography
                    variant="h6"
                    className={styles.typo__grapeVaretyName}
                    style={{
                        color: theme.palette.primary.dark,
                    }}
                >
                    {getConnectors(props.harvestMethod, true)}
                </Typography>
            );
        } else {
            return (
                <Typography variant="h6" className={styles.fontSizeXs} style={{ color: theme.palette.primary.dark }}>
                    <Trans>Information non renseignée</Trans>
                </Typography>
            );
        }
    };

    const summaryElements = [
        {
            icon: profil,
            title: t`Profil`,
            subtitle: getSummaryElementProfile(),
        },
        {
            icon: averageAge,
            title: t`Âge moyen`,
            subtitle: getSummaryAverageAge(),
        },
        {
            icon: totalSurfaceArea,
            title: t`Superficie`,
            subtitle: getSummaryTotalSurfaceArea(),
        },
        {
            icon: averageDensity,
            title: t`Densité moyenne`,
            subtitle: getSummaryAverageDensity(),
        },
        {
            icon: blending,
            title: t`Assemblage`,
            subtitle: getSummaryElementBlending(),
        },
        {
            icon: climat,
            title: getTitleClimatology(),
            subtitle: getSummaryElementWeather(),
        },
        {
            icon: marketing,
            title: t`Commercialisation`,
            subtitle: getSummaryElementMarketing(),
        },
        {
            icon: analyse,
            title: t`Données techniques`,
            subtitle: getSummaryElementAnalyzes(),
        },
        {
            icon: harvestMethod,
            title: t`Vendanges`,
            subtitle: getSummaryElementHarvestMethod(),
        },
    ];

    return summaryElements.map((element, i) => {
        let key = `summary-element-${i}`;
        return (
            <SummaryElement
                key={key}
                style={{ marginBottom: "25px" }}
                icon={element.icon}
                title={element.title}
                subtitle={element.subtitle}
            />
        );
    });
};

WineSummary.propTypes = {
    averageAge: string,
    totalSurfaceArea: string,
    averageDensity: string,
};

export default WineSummary;
