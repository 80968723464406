import { makeStyles, Box, Typography } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    mainBox: {
        width: "100%",
        display: "flex",
        gap: "8px",
    },
    typoContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
    },
    title: {
        ...theme.typography.h4,
        color: "#000000",
    },
    subtitle: () => ({
        ...theme.front.typography.labeur.dark,
        color: "#360318",
    }),
    imageContainer: {
        width: "48px",
        height: "48px",
    },
    media: {
        width: "100%",
        // height: "60px",
        [theme.breakpoints.only("xs")]: {
            width: "42px",
        },
    },
    zoom: {
        cursor: "pointer",
        marginLeft: "10px",
    },
}));

const SummaryElement = (props) => {
    const styles = useStyles(props);

    if (!props.subtitle || props.subtitle.length === 0) {
        return null;
    } else {
        return (
            <Box className={styles.mainBox} style={props.style}>
                <Box className={styles.imageContainer}>
                    <img className={styles.media} src={props.icon} />
                </Box>
                <Box className={styles.typoContainer} style={props.typoContainerStyle}>
                    <Typography variant="h4" className={styles.title}>
                        {props.title}
                    </Typography>
                    <Box style={{ display: "flex" }}>
                        <Typography variant="h6" className={styles.subtitle} style={props.subtitleStyle}>
                            {props.subtitle}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        );
    }
};

SummaryElement.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.array,
    style: PropTypes.object,
    linkTo: PropTypes.string,
    zoomStyle: PropTypes.object,
};

export default SummaryElement;
