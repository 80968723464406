import React from "react";

import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(() => ({
  button: {
    borderRadius: "40px",
    padding: "0",
    height: "67px",
    minWidth: "67px",
    fontSize: "40px",
    boxShadow: "0px 0px 56px #00000084",
    "&.MuiButton-sizeSmall": {
      fontSize: "30px",
      height: "46px",
      minWidth: "46px",
      boxShadow: "0px 0px 0px #00000084",
    },
    "& .MuiSvgIcon-root": {
      width: "40px",
      fontSize: "12px",
    }
  },
}));

const IconButton = (props) => {
  const styles = useStyles();
  return (
      <Button
        variant={props.variant}
        color={props.color}
        onClick={props.onClick}
        size={props.size}
        disabled={props.disabled}
        className={styles.button}
      >
          {props.children}
      </Button>
  );
};

IconButton.propTypes = {
  variant: PropTypes.oneOf(["text", "contained", "outlined"]),
  color: PropTypes.oneOf(["primary", "secondary", "inherit", "default"]),
  children: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(["small", "medium"]),
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
};

export default IconButton;
