/* eslint-disable no-useless-escape */
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import theme from "../globalTheme";
import SimpleInput from "../Components/Inputs/SimpleInput";
import BaseButton from "../Components/Buttons/BaseButton";
import { Link } from "react-router-dom";
import { resetPassword } from "../features/user/firebase";
import Popin from "../Containers/Popin";
import Loading from "../Components/Loader/Loading";
import { useCustomHistory } from "hooks";

const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

const useStyles = makeStyles(() => ({
    subscribe: {
        cursor: "pointer",
        marginLeft: "2px",
        fontSize: "14px",
        color: theme.palette.primary.dark,
        alignSelf: "center",
        fontFamily: theme.typography.h6.fontFamily,
    },
    email_container: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "2 / span 10",
        },
        [theme.breakpoints.up("sm")]: {
            gridColumn: "2 / span 5",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 6",
        },
    },
    submit_button: {
        gridColumn: "2 / span 10",
        textAlign: "center",
        [theme.breakpoints.up("lg")]: {
            gridColumn: "1 / span 12",
        },
    },
}));

const ForgotPassword = (props) => {
    const styles = useStyles();
    const history = useCustomHistory();
    const [focused, setFocused] = useState(false);
    const [email, setEmail] = useState("");
    const [formatError, setFormatError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [confirmationPopIn, setConfirmationPopIn] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!focused) {
            const newFormatError = email && email !== "" && !email.match(emailRegex);
            if (newFormatError !== formatError) {
                setFormatError(newFormatError);
            }
        }
        if (focused && emailError) {
            setEmailError(false);
        }
        if (focused && formatError) {
            setFormatError(false);
        }
    }, [focused]);

    const handleResetPassword = async () => {
        try {
            const res = await resetPassword(email);
            setLoading(false);
            setConfirmationPopIn(true);
        } catch (e) {
            setLoading(false);
            if (e.code === "auth/user-not-found") {
                setEmailError("Cette adresse n'est pas dans notre base de donnée");
            }
        }
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="left" alignItems="flex-end">
                <Loading loading={loading} />
            </Box>
        );
    }

    return (
        <>
            {confirmationPopIn ? (
                <Popin open={confirmationPopIn} minHeight="100px">
                    <Box
                        style={{
                            minWidth: "400px",
                            width: "500px",
                        }}
                    >
                        <Typography
                            variant="h4"
                            style={{
                                textAlign: "center",
                                marginBottom: theme.spacing(1),
                                zIndex: 5,
                                position: "relative",
                            }}
                        >
                            Votre demande a été prise en compte !
                        </Typography>
                        <Typography
                            variant="h6"
                            style={{
                                textAlign: "center",
                                margin: "12px 0px",
                            }}
                        >
                            Surveillez vos e-mails.
                        </Typography>

                        <BaseButton
                            boxStyle={{ display: "flex", justifyContent: "center" }}
                            disabled={false}
                            color="primary"
                            variant="contained"
                            size="small"
                            onClick={() => history.push("/bienvenue/connexion")}
                        >
                            Se connecter
                        </BaseButton>
                    </Box>
                </Popin>
            ) : null}
            <Box className={styles.email_container}>
                <SimpleInput
                    error={emailError || (formatError && "format de l'adresse invalide")}
                    label="Adresse e-mail"
                    placeholder="Entrez votre adresse e-mail"
                    required
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                />
            </Box>
            <Box className={styles.submit_button}>
                <BaseButton
                    disabled={emailError || formatError}
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={async () => {
                        setLoading(true);
                        await handleResetPassword();
                    }}
                >
                    Envoyer
                </BaseButton>
            </Box>
            <Box className={styles.submit_button}>
                <Typography
                    variant="h6"
                    style={{
                        color: theme.palette.primary.dark,
                    }}
                >
                    Vous n&apos;avez pas de compte ?&nbsp;
                    <Link
                        onClick={() => {
                            props.onClick();
                        }}
                        underline="always"
                        className={styles.subscribe}
                    >
                        Inscrivez-vous
                    </Link>
                </Typography>
            </Box>
        </>
    );
};

ForgotPassword.propTypes = {
    onClick: PropTypes.func,
};

export default ForgotPassword;
