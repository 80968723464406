import { Box, Typography, makeStyles } from "@material-ui/core";
import theme from "globalTheme";
import { urlFormatted } from "helpers/helpers";
import { useCustomHistory } from "hooks";
import { shape, string } from "prop-types";
const useStyles = makeStyles(() => ({
    card: (activity) => {
        return {
            width: "calc(281px - 2rem)",
            height: "calc(158px - 2rem)",
            borderRadius: 10,
            backgroundSize: "contain",
            backgroundImage: `linear-gradient(to bottom, rgba(54, 3, 24, 0), rgba(54, 3, 24, 0.9)), url(${activity.image[0].data})`,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "2rem",
            textAlign: "center",
            [theme.breakpoints.only("xs")]: {
                width: "100%",
                padding: "1rem",
            },
            "&:hover": {
                background: theme.palette.primary.dark,
                cursor: "pointer",
            },
        };
    },
    link: {
        display: "flex",
        color: theme.palette.common.white,
        textDecoration: "none",
        width: "fit-content",
        "&:hover": {
            textDecoration: "none",
        },
        [theme.breakpoints.only("xs")]: {
            width: "100%",
        },
    },
}));
const HomeActivityCard = ({ activity }) => {
    const styles = useStyles(activity);
    const history = useCustomHistory();
    return (
        <a
            className={styles.link}
            href={history.addLocale(
                `/${urlFormatted(activity.wineEntityShortId, activity.wineEntityName)}/activites/${urlFormatted(
                    activity.shortId,
                    activity.name
                )}`
            )}
        >
            <Box className={styles.card}>
                <Typography variat="body2">{activity.name}</Typography>
                <Typography variat="body2" style={{ fontWeight: 700 }}>
                    {activity?.wineEntityName}
                </Typography>
            </Box>
        </a>
    );
};

HomeActivityCard.propTypes = {
    activity: shape({
        wineEntityShortId: string,
        wineEntityName: string,
        shortId: string,
        name: string,
    }),
};

export default HomeActivityCard;
