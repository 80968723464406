import { t, Trans } from "@lingui/macro";
import { Box, makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useState } from "react";

import engrais from "../../assets/propriétéPage/engrais.svg";
import enherbement from "../../assets/propriétéPage/enherbement.svg";
import insecte from "../../assets/propriétéPage/insecte.svg";
import taille from "../../assets/propriétéPage/taille.svg";
import travail from "../../assets/propriétéPage/travail.svg";
import vert from "../../assets/propriétéPage/vert.svg";
import ExpandableTextBox from "../../Components/Texts/ExpandableTextBox";
import theme from "../../globalTheme";
import getConnectors, {
    firstLetterUppercase,
    getChips,
    parseHtml,
    useWindowDimensions,
} from "../../helpers/helpers";

const useStyles = makeStyles((theme) => ({
    vineyardDetailsMaintenance__typoTitle: {
        ...theme.front.typography.h6.dark_b,
    },
    vineyardDetailsMaintenace__typoText: {
        ...theme.front.typography.labeur.dark,
    },
}));

const VineyardDetailsMaintenance = (props) => {
    const styles = useStyles();
    const { width } = useWindowDimensions();

    const getGrassInformations = () => {
        let ret;
        if (props.vineyard?.grassCover && props.vineyard?.grassCover?.length) {
            ret = [...props.vineyard?.grassCover];
        }

        if (props.vineyard?.grassGrowingIsTemporary === true) {
            ret?.push(t`temporaire`);
        } else if (props.vineyard?.grassGrowingIsTemporary === false) {
            ret?.push(t`permanent`);
        }

        if (props.vineyard?.grassGrowingIsTotal === true) {
            ret?.push(t`total`);
        } else if (props.vineyard?.grassGrowingIsTotal === false) {
            ret?.push(t`séquentiel`);
        }

        if (ret && ret?.length) {
            return (
                <Typography
                    className={styles.vineyardDetailsMaintenace__typoText}
                    style={{ color: "#360318" }}
                >
                    {firstLetterUppercase(getConnectors(ret))}
                </Typography>
            );
        } else {
            return (
                <Typography
                    variant="h5"
                    style={{ color: theme.palette.secondary.dark }}
                    className={styles.vineyardDetailsMaintenace__typoText}
                >
                    <Trans>Non précisé</Trans>
                </Typography>
            );
        }
    };
    return (
        <>
            <Box
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: width >= 600 ? "row" : "column",
                    justifyContent:
                        width >= 600 ? "space-between" : "flex-start",
                    marginTop: "16px",
                    height: "auto",
                }}
            >
                <Box
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: width >= 600 ? "50%" : "100%",
                        marginRight: "32px",
                    }}
                >
                    {/* GRASS COVER*/}

                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: "row",
                            marginBottom: "20px",
                        }}
                    >
                        <img
                            src={enherbement}
                            style={{
                                marginRight: "26px",
                                width: "70px",
                                height: "80px",
                                opacity:
                                    props.vineyard?.grassCover &&
                                    props.vineyard?.grassCover !== 0
                                        ? 1
                                        : 0.5,
                            }}
                        />
                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                            }}
                        >
                            <Typography
                                className={
                                    styles.vineyardDetailsMaintenance__typoTitle
                                }
                                style={{
                                    color:
                                        props.vineyard?.grassCover &&
                                        props.vineyard?.grassCover !== 0
                                            ? "black"
                                            : theme.palette.secondary.main,
                                }}
                            >
                                <Trans>Enherbement</Trans>
                            </Typography>
                            <Box
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                {getGrassInformations()}
                            </Box>
                        </Box>
                    </Box>

                    {/* ENGRAIS */}
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: "row",
                            marginBottom: "4px",
                        }}
                    >
                        <img
                            src={engrais}
                            style={{
                                marginRight: "16px",
                                width: "80px",
                                height: "80px",
                                opacity:
                                    props.vineyard?.fertilizer &&
                                    props.vineyard?.fertilizer !== 0
                                        ? 1
                                        : 0.5,
                            }}
                        />
                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                            }}
                        >
                            <Typography
                                className={
                                    styles.vineyardDetailsMaintenance__typoTitle
                                }
                                style={{
                                    color:
                                        props.vineyard?.fertilizer &&
                                        props.vineyard?.fertilizer !== 0
                                            ? "black"
                                            : theme.palette.secondary.main,
                                }}
                            >
                                <Trans>Engrais</Trans>
                            </Typography>
                            {props.vineyard?.fertilizer &&
                            props.vineyard?.fertilizer?.length !== 0 ? (
                                <Typography
                                    className={
                                        styles.vineyardDetailsMaintenace__typoText
                                    }
                                    style={{ color: "#360318" }}
                                >
                                    {getChips(props.vineyard?.fertilizer, true)}
                                </Typography>
                            ) : (
                                <Typography
                                    variant="h5"
                                    className={
                                        styles.vineyardDetailsMaintenace__typoText
                                    }
                                    style={{
                                        color: theme.palette.secondary.dark,
                                    }}
                                >
                                    <Trans>Non précisé</Trans>
                                </Typography>
                            )}
                        </Box>
                    </Box>

                    {/* WORK*/}
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: "column",
                            marginBottom: "20px",
                        }}
                    >
                        <Box style={{ display: "flex" }}>
                            <img
                                src={travail}
                                style={{
                                    marginRight: "16px",
                                    width: "80px",
                                    height: "80px",
                                    opacity:
                                        props.vineyard?.underWorkType &&
                                        props.vineyard?.underWorkType !== 0
                                            ? 1
                                            : 0.5,
                                }}
                            />
                            <Box
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                }}
                            >
                                <Typography
                                    className={
                                        styles.vineyardDetailsMaintenance__typoTitle
                                    }
                                    style={{
                                        color:
                                            props.vineyard?.underWorkType &&
                                            props.vineyard?.underWorkType !== 0
                                                ? "black"
                                                : theme.palette.secondary.main,
                                    }}
                                >
                                    <Trans>Travail sous le rang</Trans>
                                </Typography>
                                {props.vineyard?.underWorkType &&
                                props.vineyard?.underWorkType?.length !== 0 ? (
                                    <Typography
                                        className={
                                            styles.vineyardDetailsMaintenace__typoText
                                        }
                                        style={{ color: "#360318" }}
                                    >
                                        {firstLetterUppercase(
                                            getConnectors(
                                                props.vineyard?.underWorkType
                                            )
                                        )}
                                    </Typography>
                                ) : (
                                    <Typography
                                        variant="h5"
                                        className={
                                            styles.vineyardDetailsMaintenace__typoText
                                        }
                                        style={{
                                            color: theme.palette.secondary.dark,
                                        }}
                                    >
                                        <Trans>Non précisé</Trans>
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        {props.vineyard?.underWorkDescription && (
                            <ExpandableTextBox
                                colorFont={"#360318"}
                                text={parseHtml(
                                    props.vineyard?.underWorkDescription
                                )}
                                lines={3}
                            />
                        )}
                    </Box>
                </Box>

                <Box
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: width >= 600 ? "50%" : "100%",
                    }}
                >
                    {/* TRIMMING */}
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: "row",
                            marginBottom: "20px",
                        }}
                    >
                        <img
                            src={taille}
                            style={{
                                marginRight: "16px",
                                width: "80px",
                                height: "80px",
                                opacity:
                                    props.vineyard?.trimming &&
                                    props.vineyard?.trimming !== 0
                                        ? 1
                                        : 0.5,
                            }}
                        />
                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                            }}
                        >
                            <Typography
                                className={
                                    styles.vineyardDetailsMaintenance__typoTitle
                                }
                                style={{
                                    color:
                                        props.vineyard?.trimming &&
                                        props.vineyard?.trimming !== 0
                                            ? "black"
                                            : theme.palette.secondary.main,
                                }}
                            >
                                <Trans>Mode de taille</Trans>
                            </Typography>
                            {props.vineyard?.trimming &&
                            props.vineyard?.trimming !== 0 ? (
                                <Typography variant="h6">
                                    {getChips(props.vineyard?.trimming, true)}
                                </Typography>
                            ) : (
                                <Typography
                                    variant="h5"
                                    style={{
                                        color: theme.palette.secondary.dark,
                                    }}
                                    className={
                                        styles.vineyardDetailsMaintenace__typoText
                                    }
                                >
                                    <Trans>Non précisé</Trans>
                                </Typography>
                            )}
                        </Box>
                    </Box>
                    {/* GREEN OPERATION */}

                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: "column",
                            marginBottom: "20px",
                        }}
                    >
                        <Box style={{ display: "flex" }}>
                            <img
                                src={vert}
                                style={{
                                    marginRight: "16px",
                                    width: "80px",
                                    height: "80px",
                                    opacity:
                                        props.vineyard?.greenProcedures &&
                                        props.vineyard?.greenProcedures !== 0
                                            ? 1
                                            : 0.5,
                                }}
                            />
                            <Box
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                }}
                            >
                                <Typography
                                    className={
                                        styles.vineyardDetailsMaintenance__typoTitle
                                    }
                                    style={{
                                        color:
                                            props.vineyard?.greenProcedures &&
                                            props.vineyard?.greenProcedures !==
                                                0
                                                ? "black"
                                                : theme.palette.secondary.main,
                                    }}
                                >
                                    <Trans>Opérations en vert</Trans>
                                </Typography>
                                {props.vineyard?.greenProcedures &&
                                props.vineyard?.greenProcedures !== 0 ? (
                                    <Typography
                                        className={
                                            styles.vineyardDetailsMaintenace__typoText
                                        }
                                    >
                                        {getChips(
                                            props.vineyard?.greenProcedures,
                                            true
                                        )}
                                    </Typography>
                                ) : (
                                    <Typography
                                        variant="h5"
                                        className={
                                            styles.vineyardDetailsMaintenace__typoText
                                        }
                                        style={{
                                            color: theme.palette.secondary.dark,
                                        }}
                                    >
                                        <Trans>Non précisé</Trans>
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        {props.vineyard?.vineWorkDescription && (
                            <ExpandableTextBox
                                colorFont={"#360318"}
                                text={parseHtml(
                                    props.vineyard?.vineWorkDescription
                                )}
                                lines={3}
                            />
                        )}
                    </Box>
                    {/* INSECTS */}
                    {props.vineyard?.sexualConfusion && (
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                                marginBottom: "20px",
                            }}
                        >
                            <Box style={{ display: "flex" }}>
                                <img
                                    src={insecte}
                                    style={{
                                        marginRight: "16px",
                                        width: "80px",
                                        height: "80px",
                                        opacity: props.vineyard?.sexualConfusion
                                            ? 1
                                            : 0.5,
                                    }}
                                />
                                <Box
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Typography
                                        className={
                                            styles.vineyardDetailsMaintenance__typoTitle
                                        }
                                        style={{
                                            color: props.vineyard
                                                ?.sexualConfusion
                                                ? "black"
                                                : theme.palette.secondary.main,
                                        }}
                                    >
                                        <Trans>
                                            Lutte contre les insectes ravageurs
                                        </Trans>
                                    </Typography>
                                    {props.vineyard?.sexualConfusion ? (
                                        <Typography
                                            className={
                                                styles.vineyardDetailsMaintenace__typoText
                                            }
                                        >
                                            <Trans>
                                                Pratique de confusion sexuelle
                                            </Trans>
                                        </Typography>
                                    ) : (
                                        <Typography
                                            variant="h5"
                                            className={
                                                styles.vineyardDetailsMaintenace__typoText
                                            }
                                            style={{
                                                color:
                                                    theme.palette.secondary
                                                        .dark,
                                            }}
                                        >
                                            <Trans>Non précisé</Trans>
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                            {props.vineyard?.insectsDescription && (
                                <ExpandableTextBox
                                    colorFont={"#360318"}
                                    text={parseHtml(
                                        props.vineyard?.insectsDescription
                                    )}
                                    lines={3}
                                />
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
            {/* COMPLEMENTARY INFORMATIONS */}
            {props.vineyard?.complementaryInformations !== null &&
                props.vineyard?.complementaryInformations !== "<div/>" && (
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: "column",
                            marginBottom: "32px",
                            width: "50%",
                        }}
                    >
                        <Typography
                            className={
                                styles.vineyardDetailsMaintenance__typoTitle
                            }
                        >
                            <Trans>Informations complémentaires</Trans>
                        </Typography>
                        <ExpandableTextBox
                            colorFont={"#360318"}
                            text={parseHtml(
                                props.vineyard?.complementaryInformations
                            )}
                            lines={5}
                        />
                    </Box>
                )}
        </>
    );
};

VineyardDetailsMaintenance.propTypes = {
    vineyard: PropTypes.object,
};

export default VineyardDetailsMaintenance;
