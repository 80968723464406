import { useGetEnumByFieldLazyQuery } from "generated/graphql";
import { useEffect } from "react";

export const useEnumValues = (enumField, options) => {
    const { locale = "fr" } = options ?? {};
    const [trigger, { data: { GetEnumByField: enums = [] } = {}, called }] = useGetEnumByFieldLazyQuery();

    useEffect(() => {
        if (typeof enumField === "string" && enumField !== "" && !called) {
            trigger({ variables: { field: enumField } });
        }
    }, [enumField]);

    return enums.length > 0 ? enums.map((e) => e?.valueLng?.[locale] ?? e?.valueLng?.fr).filter(Boolean) : [];
};

export const useEnumValuesState = (enumField, options) => {
    const { locale = "fr" } = options ?? {};
    const [trigger, { data: { GetEnumByField: enums = [] } = {}, called, loading }] = useGetEnumByFieldLazyQuery();

    useEffect(() => {
        if (typeof enumField === "string" && enumField !== "" && !called) {
            trigger({ variables: { field: enumField } });
        }
    }, [enumField]);

    const data = enums.length > 0 ? enums.map((e) => e?.valueLng?.[locale] ?? e?.valueLng?.fr).filter(Boolean) : [];
    return { data, loading };
};
