import axios from "axios";
import { useState } from "react";
import { useCreateUploadUrlMutation, useCreateDownloadUrlMutation } from "../generated/graphql";

export const useFileUpload = (bucketName, contentType = null) => {
    const [createUploadURL] = useCreateUploadUrlMutation();
    const [createDownloadURL] = useCreateDownloadUrlMutation();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    const uploadFile = async (file, path) => {
        const fileName = `${path}/${file.name}`;
        setLoading(true);
        const response = await createUploadURL({
            variables: {
                fileName,
                bucketName,
            },
        });
        const uploadUrl = response?.data?.CreateUploadURL.url;

        const getContentType = {
            csv: "text/csv",
            image: "image/jpeg, image/png",
            pdf: "application/pdf",
        };
        const contentTypeHeader = { "Content-Type": getContentType?.[contentType] };

        await axios.put(uploadUrl, file, { headers: contentTypeHeader });
        const download = await createDownloadURL({
            variables: {
                fileName,
                bucketName,
            },
        });
        const preview = download?.data?.CreateDownloadURL.url;
        const result = { preview, fileName, bucketName };
        setData(result);
        setLoading(false);
        return result;
    };

    return [uploadFile, { data, loading }];
};
