function SvgLinkedin() {
  return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
          <path
            d="M21.6 0H2.4C1.08 0 0 1.08 0 2.4V21.6C0 22.92 1.08 24 2.4 24H21.6C22.92 24 24 22.92 24 21.6V2.4C24 1.08 22.92 0 21.6 0ZM7.2 20.4H3.6V9.6H7.2V20.4ZM5.4 7.56C4.2 7.56 3.24 6.6 3.24 5.4C3.24 4.2 4.2 3.24 5.4 3.24C6.6 3.24 7.56 4.2 7.56 5.4C7.56 6.6 6.6 7.56 5.4 7.56ZM20.4 20.4H16.8V14.04C16.8 13.08 15.96 12.24 15 12.24C14.04 12.24 13.2 13.08 13.2 14.04V20.4H9.6V9.6H13.2V11.04C13.8 10.08 15.12 9.36 16.2 9.36C18.48 9.36 20.4 11.28 20.4 13.56V20.4Z"
            fill="#632D3E"
          />
      </svg>
  );
}

export default SvgLinkedin;
