/* eslint-disable no-unused-vars */
import React, { useState } from "react";

import { makeStyles, TextField, InputAdornment, Typography, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "../../assets/Error.svg";

const useStyles = makeStyles((theme) => ({
    root: (props) => ({
        width: "100%",
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
        },
        "& .MuiOutlinedInput-root": {
            fontSize: "14px",
            color: theme.palette.primary.dark,
            borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
            backgroundColor: "white",
            height: "34px",
            "&.Mui-disabled": {
                color: theme.palette.secondary.dark,
                backgroundColor: theme.palette.disabled.main,
            },
            "&:hover fieldset": {
                borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
            },
            "&.Mui-focused fieldset": {
                border: "1px solid",
                borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
            },
        },
        "& .MuiFormLabel-root": {
            fontSize: "14px",
            color: props.error ? theme.palette.error.main : theme.palette.secondary.dark,
        }
    }),
    boxContainer: {
        position: "relative",
        width: "100%"
    },
    checkIcon: {
        width: "20px",
        fill: theme.palette.success.main,
    },
    falseIcon: {
        width: "20px",
        fill: theme.palette.error.main,
    },
    errorText: {
        fontSize: "12px",
        color: theme.palette.error.main,
        marginLeft: "8px"
    }
}));

const UncontrolledSimpleInput = (props) => {
    const styles = useStyles({ ...props });

    return (
        <Box className={styles.boxContainer} style={{ ...props.boxStyle }}>
            <TextField
                value={props.value}
                type="text"
                variant="outlined"
                size="small"
                inputRef={props.inputRef}
                name={props.name}
                label={props.label}
                placeholder={props.placeholder}
                disabled={props.disabled}
                required={props.required}
                error={props.error}
                onBlur={props.onBlur}
                onFocus={props.onFocus}
                style={{ ...props.style }}
                className={styles.root}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    classes: { input: styles.root },
                    endAdornment: (
                        <InputAdornment position="end">
                            {props.correctValue && (<CheckIcon className={styles.checkIcon} />)}
                            {props.error ? <img src={ErrorIcon} className={styles.falseIcon} /> : <Box></Box>}
                        </InputAdornment>
                    ),
                }}
            />
            {props.error ? <Typography variant="h6" className={styles.errorText}> {props.error} </Typography> : ""}
        </Box>
    );
};

UncontrolledSimpleInput.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    inputRef: PropTypes.any,
    name: PropTypes.string,

    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,

    disabled: PropTypes.bool,
    error: PropTypes.string,
    required: PropTypes.bool,

    style: PropTypes.object,
    boxStyle: PropTypes.object,

    correctValue: PropTypes.bool,
    tooltipTitle: PropTypes.string,
};

export default React.memo(UncontrolledSimpleInput);