import { Box, makeStyles } from "@material-ui/core";
import PropTypes, { object, string } from "prop-types";

import theme from "../../globalTheme";
import RoleIdentificationSubscriptionStatusCard from "../Components/SubscriptionStatusCards/RoleIdentificationSubscriptionStatusCard";
import SubscriptionTypeSummary from "../Components/SubscriptionStatusCards/SubscriptionTypeSummary";

const useStyle = makeStyles(() => ({
    containerStatus: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "space-between",
        [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
        },
        [theme.breakpoints.up("md")]: {},
        [theme.breakpoints.up("lg")]: {
            flexDirection: "column",
        },
        [theme.breakpoints.up("xl")]: {},
    },
    statusItem: {
        display: "flex",
        justifyContent: "center",
        width: "100%",

        [theme.breakpoints.up("sm")]: {
            "&:nth-child(1)": {
                marginRight: "16px",
            },
            "&:nth-child(3)": {
                marginLeft: "16px",
            },
        },
        [theme.breakpoints.up("md")]: {},
        [theme.breakpoints.up("lg")]: {
            marginBottom: "16px",
            "&:nth-child(1)": {
                marginRight: 0,
            },
            "&:nth-child(3)": {
                marginLeft: 0,
            },
        },
        [theme.breakpoints.up("xl")]: {},
    },
}));

const SubscriptionStatusCardsContainer = (props) => {
    const styles = useStyle();
    return (
        <Box className={styles.containerStatus}>
            <Box className={styles.statusItem}>
                <RoleIdentificationSubscriptionStatusCard
                    estateName={props.estateName}
                    subscriptionState={props.subscriptionState}
                    subscriptionStateSelectedRole={props.subscriptionStateSelectedRole}
                    subscriptionDispatch={props.subscriptionDispatch}
                    handleSwitchChange={(event) => props.handleSwitchChange(event)}
                    switchCheck={props.switchCheck}
                    onClickNext={props.onClickNext}
                />
            </Box>

            <Box className={styles.statusItem}>
                <SubscriptionTypeSummary
                    subscriptionStateSelectedPack={props.subscriptionStateSelectedPack}
                    subscriptionDispatch={props.subscriptionDispatch}
                    handleSwitchChange={(event) => props.handleSwitchChange(event)}
                    switchCheck={props.switchCheck}
                    webSubDisplayModifyLink={props.webSubDisplayModifyLink}
                    disabledCard
                    withPlus={props.withPlus}
                />
            </Box>
        </Box>
    );
};

SubscriptionStatusCardsContainer.propTypes = {
    identityType: PropTypes.number,
    webSubType: PropTypes.number,
    webSubDisplayModifyLink: PropTypes.bool,
    paperSubType: PropTypes.number,
    paperSubDisplayModifyLink: PropTypes.bool,
    estateName: string,
    subscriptionStateSelectedPack: PropTypes.number,
    subscriptionStateSelectedPaper: PropTypes.number,
    subscriptionStateSelectedRole: PropTypes.number,
    subscriptionState: object,
    subscriptionDispatch: PropTypes.func,

    switchCheck: PropTypes.bool,
    allPrices: PropTypes.object,
    handleSwitchChange: PropTypes.func,

    onClickNext: PropTypes.func,

    withPlus: PropTypes.bool,
};

export default SubscriptionStatusCardsContainer;
