// import { t } from "@lingui/macro";
// import { Grid, makeStyles, Typography } from "@material-ui/core";
// import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

// import LoadingWithImage from "Components/Loader/LoadingWithImage";
// import fioriture1 from "../assets/CommunePage/fioriture1.svg";
// import fioriture2 from "../assets/CommunePage/fioriture2.svg";
// import grounds from "../assets/CommunePage/grounds.svg";
// import place from "../assets/CommunePage/place.svg";
// import bg from "../assets/frontPage/background.svg";
// import Map from "../Components/Map/Map";
// import AppellationsByColor from "../Front/AppellationsByColor";
// import FrontContentBottom from "../Front/FrontContentBottom";
// import FrontContentTop from "../Front/FrontContentTop";
// import FrontMainContainer from "../Front/FrontMainContainer";
// import FrontTitle from "../Front/FrontTitle";
// import SubtitleWithLogo from "../Front/SubtitleWithLogo";
// import ActivityCards from "../FrontCommune/ActivityCards";
// import CommuneSummary from "../FrontCommune/CommuneSummary";
// import EntityCards from "../FrontCommune/EntityCards";
// import EstateTitleSemiUnderlined from "../FrontEstate/Components/EstateTitledSemiUnderlined";
// import { useGetCommuneFrontQuery } from "../generated/graphql";
// import theme from "../globalTheme";
import Page404 from "Components/ErrorPage/Page404";

// const useStyles = makeStyles(() => {
//     return {
//         container_card: {
//             justifyContent: "space-between",
//             [theme.breakpoints.down("sm")]: {
//                 justifyContent: "center",
//             },
//         },
//     };
// });

const CommunePage = () => {
    // const { name } = useParams<{ name: string }>();

    // const { data, loading } = useGetCommuneFrontQuery({
    //     variables: {
    //         name,
    //     },
    // });

    // const styles = useStyles();
    // const commune = data?.GetCommuneFront;
    // const coordinates = commune && {
    //     latitude: commune?.latitude,
    //     longitude: commune?.longitude,
    // };

    return (
        <>
            <Helmet>
                <meta name="robots" content="none" />
            </Helmet>
            <Page404 />
        </>
    );

    // if (!commune || loading) {
    //     return <LoadingWithImage />;
    // }

    // return (
    //     <>
    // <Helmet title={commune?.fullName} />

    //         <FrontContentTop backgroundImage={bg}>
    //             <FrontTitle title={commune?.fullName} />
    //             <FrontMainContainer
    //                 style={{
    //                     ...theme.front.padding.estateCard,
    //                     backgroundImage: `url(${fioriture1}), url(${fioriture2})`,
    //                     backgroundPositionY: "bottom",
    //                     backgroundPositionX: "right, left",
    //                     backgroundRepeat: "no-repeat",
    //                 }}
    //             >
    //                 <Grid container className={styles.container_card}>
    //                     <Grid item xs={12} md={7}>
    //                         {coordinates ? <Map coordinates={coordinates} /> : null}
    //                     </Grid>
    //                     <Grid item xs={12} md={4}>
    //                         <CommuneSummary
    //                             postalCode={commune?.postalCode}
    //                             population={commune?.population}
    //                             gentileF={commune?.gentileF}
    //                             gentileM={commune?.gentileM}
    //                             surfaceArea={commune?.surfaceArea}
    //                             altitudeMax={commune?.altitudeMax}
    //                             altitudeMin={commune?.altitudeMin}
    //                             latitude={commune?.latitude}
    //                             longitude={commune?.longitude}
    //                             mairieAddress={commune?.mairieAddress}
    //                         />
    //                     </Grid>
    //                 </Grid>
    //                 <Grid container>
    //                     <Grid item>
    //                         <EstateTitleSemiUnderlined title={t`Sa géographie`} heightAfter={32} />
    //                         <SubtitleWithLogo title={t`Localisation`} logoUrl={place} />
    //                         <Typography>{commune?.geoSituation}</Typography>

    //                         <SubtitleWithLogo title={t`Les sols et les sous-sols`} logoUrl={grounds} />
    //                         <Typography>{commune?.soilDescription}</Typography>
    //                         <EstateTitleSemiUnderlined title={t`On y produit...`} />
    //                         <AppellationsByColor
    //                             redWines={commune?.redWines}
    //                             whiteWines={commune?.whiteWines}
    //                             roseWines={commune?.roseWines}
    //                         />
    //                     </Grid>
    //                 </Grid>
    //             </FrontMainContainer>
    //             <EntityCards communeINSEEcode={commune.INSEEcode} commune={data?.GetCommuneFront?.fullName} />
    //         </FrontContentTop>

    //         <FrontContentBottom activityBackground>
    //             <ActivityCards communeINSEEcode={commune.INSEEcode} />
    //         </FrontContentBottom>
    //     </>
    // );
};

export default CommunePage;
