import React from "react";

import { makeStyles, Avatar, Box, Typography, Grid, Button } from "@material-ui/core";
import PropTypes from "prop-types";
import { parseHtml, useWindowDimensions } from "../../../helpers/helpers";
import theme from "../../../globalTheme";

const useStyles = makeStyles((theme) => ({
    personDetails: (props) => ({
        height: "fit-content",
        width: "320px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        borderRadius: "5px",
        textTransform: "none",
        border: "0.4px solid",
        cursor: "default",
        borderColor: "rgba(255, 0, 0, 0)",
        backgroundColor: props.selected && theme.palette.dashboard.background,
        // "& .MuiButton-label": {
        //     alignItems: "center",
        // },
        // "&:hover": {
        //     backgroundColor: "#FCF6F6",
        //     borderRadius: "5px",
        //     background: 0.5,
        // },
        [theme.breakpoints.up("xs")]: {
            width: "100%",
        },
        [theme.breakpoints.up("md")]: {
            gridColumn: "auto / span 8",
            cursor: "pointer",
        },
        [theme.breakpoints.up("lg")]: {
            gridColumn: "auto / span 4",
            border: "none",
        },

        [theme.breakpoints.down("sm")]: {
            borderColor: "#9F8D93",
            padding: "1rem",
        },

        [theme.breakpoints.down("sm")]: {
            backgroundColor: props.selected && "transparent",

            "&:hover": {
                backgroundColor: "transparent",
            },
        },
    }),
    personDetails__media: (props) => ({
        border: "4px solid",
        borderColor: props.vip ? theme.palette.primary.light : theme.palette.common.white,
        marginRight: theme.spacing(1),
        [theme.breakpoints.only("xs")]: {
            width: "200px",
            height: "200px",
        },
        [theme.breakpoints.only("md")]: {
            width: "250px",
            height: "250px",
        },
        [theme.breakpoints.only("sm")]: {
            width: "250px",
            height: "250px",
        },
        [theme.breakpoints.down("sm")]: {
            width: "168px",
            height: "168px",
        },
        [theme.breakpoints.down("xs")]: {
            width: "77px",
            height: "77px",
        },
        [theme.breakpoints.up("md")]: {
            width: "49px",
            height: "49px",
        },
    }),
    personDetails__name: {
        ...theme.front.typography.h6.dark_b,
        textAlign: "left",
    },
    personDetails__work: {
        ...theme.front.typography.labeur.dark,
        color: theme.palette.text.secondary,
        textAlign: "left",
    },
    personDetails__description: {
        ...theme.front.typography.labeur.dark,
        color: theme.palette.text.secondary,
        textAlign: "left",
    },
}));

const getTypo = (name, works, description, styles) => {
    const { width } = useWindowDimensions();

    return (
        <>
            {name && (
                <Typography color="secondary" className={styles.personDetails__name}>
                    {name}
                </Typography>
            )}

            {works && (
                <Typography className={styles.personDetails__work} style={{ color: "#360318", fontWeight: "bold" }}>
                    <Grid container direction="column">
                        {works}
                    </Grid>
                </Typography>
            )}

            {width < 960 && description && (
                <Typography
                    className={styles.personDetails__description}
                    style={{
                        color: "#360318",
                        fontWeight: "normal",
                        marginTop: theme.spacing(2),
                        marginBottom: theme.spacing(2),
                    }}
                >
                    <Grid container direction="column">
                        {parseHtml(description)}
                    </Grid>
                </Typography>
            )}
        </>
    );
};

const PersonDetails = (props) => {
    const styles = useStyles(props);
    return (
        <Button className={styles.personDetails} onClick={props.onClick}>
            <Avatar src={props.picture} className={styles.personDetails__media} alt={props.name} />
            <Grid
                container
                justify="space-between"
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                {(props.name || props.works) && (
                    <Grid item> {getTypo(props.name, props.works, props.description, styles)} </Grid>
                )}
            </Grid>
        </Button>
    );
};

PersonDetails.propTypes = {
    name: PropTypes.string,
    works: PropTypes.string,
    picture: PropTypes.string,
    vip: PropTypes.bool,
    description: PropTypes.string,
    teamMemberClick: PropTypes.any,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
};

export default PersonDetails;
