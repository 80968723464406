import { Trans, t } from "@lingui/macro";
import { Box, makeStyles } from "@material-ui/core";
import Page500Front from "Components/ErrorPage/Page500Front";
import Loading from "Components/Loader/Loading";
import { SwiperWrapper } from "Front/SwiperCards";
import EstateTitleSemiUnderlined from "FrontEstate/Components/EstateTitledSemiUnderlined";
import HomeWineCard from "Search/card/HomeWineCard";
import wines from "assets/Wine/wines.svg";
import theme from "globalTheme";
import { string } from "prop-types";
import { useGetCommonalitiesWineQuery } from "generated/graphql";
import SubtitleCommonality from "Front/SubtitleCommonality";
import { useWindowDimensions } from "helpers/helpers";
import MoreCommonalityButton from "Front/MoreCommunalityButton";

const useStyles = makeStyles(() => ({
    container: {
        [theme.breakpoints.up("xs")]: {
            gridColumn: "1 / span 12",
        },
        [theme.breakpoints.up("sm")]: {
            padding: "0 16px",
        },
        [theme.breakpoints.up("xl")]: {
            width: "calc(1314px)",
            margin: "auto",
        },
        "& .swiper-slide": {
            [theme.breakpoints.only("xs")]: {
                width: "100%",
            },
        },
    },
    main_container: {
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        gap: "1rem",
        backgroundColor: theme.palette.background.card,
        [theme.breakpoints.only("xs")]: {
            padding: "14px",
        },
    },
}));

const WineCommunalities = (props) => {
    const styles = useStyles();
    const { width } = useWindowDimensions();

    const {
        data: { GetCommonalitiesWine: commonalitiesWine = [] } = {},
        loading: commonalitiesWineLoading,
        error: commonalitiesWineError,
    } = useGetCommonalitiesWineQuery({
        variables: {
            wineId: props.wineId,
        },
    });

    if (commonalitiesWineLoading) {
        return <Loading />;
    }

    if (commonalitiesWineError) {
        return <Page500Front />;
    }

    const byAppellation = commonalitiesWine?.byAppellation?.result;
    const byProfil = commonalitiesWine?.byProfil?.result;

    return (
        <Box className={styles.main_container}>
            <Box className={styles.container}>
                <EstateTitleSemiUnderlined title={t`(Re)découvrez des vins de la région`} />
                {byAppellation?.length > 0 && (
                    <>
                        <SubtitleCommonality
                            img={wines}
                            title={
                                <Trans>
                                    de la même {width >= 600 ? <br /> : ""} <b>appellation</b>
                                </Trans>
                            }
                        />
                        <SwiperWrapper
                            spaceBetween={0}
                            slidesPerView="auto"
                            loading={commonalitiesWineLoading}
                            wrapperPaddingBottom="0"
                        >
                            {byAppellation.map((wine, i) => (
                                <>
                                    <Box key={i} style={{ width: width >= 600 ? "230px" : "100%" }}>
                                        <HomeWineCard
                                            arrayLoading={commonalitiesWineLoading}
                                            wine={wine}
                                            sweetness={wine?.sweetness}
                                            wineId={wine?.wineId}
                                            name={wine?.brandName}
                                            color={wine?.color}
                                            appellation={wine?.appellation}
                                            wineEntityName={wine?.wineEntityName}
                                            wineEntityId={wine?.wineEntityId}
                                        />
                                    </Box>
                                    {i === byAppellation?.length - 1 &&
                                        commonalitiesWine?.byAppellation?.total > 10 && (
                                            <MoreCommonalityButton
                                                pathname="/recherche/vins/resultats"
                                                filter="appellation"
                                                value={wine.appellation}
                                            />
                                        )}
                                </>
                            ))}
                        </SwiperWrapper>
                    </>
                )}
                {byProfil?.length > 0 && (
                    <>
                        <SubtitleCommonality
                            img={wines}
                            title={
                                <Trans>
                                    du même {width >= 600 ? <br /> : ""} <b>profil</b>
                                </Trans>
                            }
                        />
                        <SwiperWrapper
                            spaceBetween={0}
                            slidesPerView="auto"
                            loading={commonalitiesWineLoading}
                            wrapperPaddingBottom="0"
                        >
                            {byProfil.map((wine, i) => (
                                <>
                                    <Box key={i} style={{ width: width >= 600 ? "230px" : "100vw" }}>
                                        <HomeWineCard
                                            arrayLoading={commonalitiesWineLoading}
                                            wine={wine}
                                            sweetness={wine?.sweetness}
                                            wineId={wine?.wineId}
                                            name={wine?.brandName}
                                            color={wine?.color}
                                            appellation={wine?.appellation}
                                            wineEntityName={wine?.wineEntityName}
                                            wineEntityId={wine?.wineEntityId}
                                            vintageIds={wine?.vintageIds}
                                        />
                                    </Box>
                                    {i === byProfil?.length - 1 &&
                                        commonalitiesWine?.byProfil?.total > 10 && (
                                            <MoreCommonalityButton
                                                pathname="/recherche/vins/resultats"
                                                filter="profil"
                                                value={{ color: wine.color, sweetness: wine.sweetness }}
                                            />
                                        )}
                                </>
                            ))}
                        </SwiperWrapper>
                    </>
                )}
            </Box>
        </Box>
    );
};

WineCommunalities.propTypes = {
    wineId: string,
};

export default WineCommunalities;
