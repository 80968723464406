import React from "react";

import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";

const useStyle = makeStyles((theme) => ({
    arrow: (props) => ({
        color: props.arrowColor ? props.arrowColor : theme.palette.primary.dark,
        top: "1px !important",
    }),
    popper: (props) => ({
        fontSize: props.fontSize ? props.fontSize : theme.typography.h6.fontSize,
        backgroundColor: props.bgColor ? props.bgColor : theme.palette.primary.dark,
        borderRadius: props.borderRadius ? props.borderRadius : "9px",
        boxShadow: "none",
        padding: "10px",
        width: props.width ?? "inherit",
        fontFamily: "Lato",
        maxWidth: "30rem",
    }),
}));
const BaseTooltip = (props) => {
    const styles = useStyle(props);

    return !props.title ? (
        props.children
    ) : (
        <Tooltip
            open={props.open}
            disableFocusListener
            disableHoverListener={props.disableHoverListener}
            interactive={props.interactive}
            color="primary"
            title={props.title}
            arrow
            placement={props.placement}
            // open={props.open}
            classes={{
                arrow: styles.arrow,
                tooltip: styles.popper,
            }}
        >
            {props.children}
        </Tooltip>
    );
};

BaseTooltip.propTypes = {
    children: PropTypes.any,
    open: PropTypes.bool,
    title: PropTypes.string,
    placement: PropTypes.string,
    disableHoverListener: PropTypes.bool,
    interactive: PropTypes.bool,
    width: PropTypes.number,
    bgColor: PropTypes.string,
    arrowColor: PropTypes.string,
    borderRadius: PropTypes.number,
    boxShadow: PropTypes.string,
    fontSize: PropTypes.string,
};

export default BaseTooltip;
