import PropTypes from "prop-types";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { getBlobFromImageElement, copyBlobToClipboard } from "copy-image-clipboard";
import React, { useState } from "react";
import { useBarcode } from "@createnextapp/react-barcode";
import BaseButton from "../Buttons/BaseButton";
import { Box, Typography } from "@material-ui/core";
import theme from "../../globalTheme";

function BaseBarCode(props) {
    const [copied, setCopied] = useState(false);

    const { inputRef } = useBarcode({
        value: props.value,
        options: {
            background: "white",
            width: 2,
            format: props.format,
        },
    });
    return (
        <>
            <img ref={inputRef} id="imageLinkToCopy" />
            {!props.noCopy ? (
                <Box>
                    <BaseButton
                        variant="outlined"
                        color="primary"
                        size="small"
                        width={18}
                        style={{ fontSize: 12, padding: "0px 4px" }}
                        onClick={() => {
                            const imageElement = document.getElementById("imageLinkToCopy");
                            getBlobFromImageElement(imageElement)
                                .then((blob) => {
                                    return copyBlobToClipboard(blob);
                                })
                                .then(() => {
                                    setCopied(true);
                                })
                                .catch((e) => {
                                    // eslint-disable-next-line no-console
                                    console.log("Error copying: ", e.message);
                                });
                        }}
                    >
                        <FileCopyIcon style={{ width: 15, marginRight: "4px" }} />
                        Copier
                    </BaseButton>
                    {copied && (
                        <Typography
                            variant="h6"
                            style={{
                                marginLeft: "6px",
                                fontSize: "12px",
                                color: theme.palette.primary.dark,
                                fontStyle: "italic",
                            }}
                        >
                            Copié.
                        </Typography>
                    )}
                </Box>
            ) : (
                ""
            )}
        </>
    );
}

export default BaseBarCode;

BaseBarCode.propTypes = {
    value: PropTypes.any,
    errors: PropTypes.any,
    format: PropTypes.string,
    noCopy: PropTypes.bool,
};
