import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import theme from "globalTheme";
import rewardImg from "../../assets/reward.svg";
import PlusChip from "Components/FrontBESV/PlusChip";

const useStyles = makeStyles((e) => ({
    root: {
        display: "flex",
        alignItems: "center",
        borderRadius: 4,
        padding: "1px 2px",
        margin: "2px 0px",
        width: "max-content",

        [theme.breakpoints.up("md")]: {
            width: "fit-content",
        },
    },
}));

const ExtraStatusCard = () => {
    const styles = useStyles();
    return (
        <Box className={styles.root}>
            <Typography variant="h6" style={{ color: theme.palette.primary.dark }}>
                avec pack <PlusChip/>
            </Typography>
        </Box>
    );
};

export default ExtraStatusCard;
