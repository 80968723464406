import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { login, logout, loginFacebook, loginGoogle } from "./firebase";
import { getApolloClient } from "../../App";

import { GetSimpleUserByIdDocument, SignUpDocument } from "../../generated/graphql";

export const fetchUser = createAsyncThunk("user/fetchUser", async (arg, { getState, requestId }) => {
    const { currentRequestId, loading } = getState().user;
    if (loading !== true || requestId !== currentRequestId) {
        return;
    }
    let currentUser;
    if (arg && arg.user) {
        currentUser = arg.user;
    } else {
        currentUser = getState().user.currentUser;
    }

    const apolloClient = getApolloClient();
    const query = await apolloClient.query({
        query: GetSimpleUserByIdDocument,
        variables: { userId: currentUser.userId },
        fetchPolicy: arg.fetchPolicy ? arg?.fetchPolicy : "cache-first",
    });
    return query.data.GetSimpleUserById;
});

export const refetchUser = createAsyncThunk("user/refetchUser", async (arg, { getState, requestId }) => {
    const { currentRequestId, loading } = getState().user;
    if (loading !== true || requestId !== currentRequestId) {
        return;
    }
    let currentUser;
    if (arg && arg.user) {
        currentUser = arg.user;
    } else {
        currentUser = getState().user.currentUser;
    }

    const apolloClient = getApolloClient();
    const query = await apolloClient.query({
        query: GetSimpleUserByIdDocument,
        variables: { userId: currentUser.userId },
        fetchPolicy: "network-only",
    });
    return query.data.GetSimpleUserById;
});

export const signIn = createAsyncThunk("user/signIn", async (arg, { getState, requestId }) => {
    const { username, password } = arg;
    const { currentRequestId, loading } = getState().user;
    if (loading !== true || requestId !== currentRequestId) {
        return;
    }
    const response = await login(username, password);
    const apolloClient = getApolloClient();
    const query = await apolloClient.query({
        query: GetSimpleUserByIdDocument,
        variables: { userId: response.user.uid },
        fetchPolicy: "network-only",
    });
    return query.data.GetSimpleUserById;
});

const checkRemoteLogin = async (response) => {
    if (response.code) {
        switch (response.code) {
            case "auth/account-exists-with-different-credential":
                return {
                    error: "Cette adresse mail est déjà liée à un compte Féret",
                    message: "Cette adresse mail est déjà liée à un compte Féret",
                    user: null,
                };
            default:
                return {
                    error: "Une erreur est survenue lors de la connexion",
                    message: "Une erreur est survenue lors de la connexion",
                    user: null,
                };
        }
    } else {
        const apolloClient = getApolloClient();

        if (response.additionalUserInfo.isNewUser) {
            // INFO: New user, create it
            const res = await apolloClient.mutate({
                mutation: SignUpDocument,
                variables: {
                    input: {
                        firstName: response.additionalUserInfo.profile?.given_name,
                        lastName: response.additionalUserInfo.profile?.family_name,
                        email: response.user?.email,
                        firebaseId: response.user?.uid,
                    },
                },
            });
            return {
                error: null,
                message: null,
                user: res.data.SignUp.user,
            };
            // const newUser = await apolloClient.mutate({
            //     mutation: CreateUserDocument,
            //     variables: {
            //         input: {
            //             firebaseId: response.user.uid,
            //             email: response.user.email,
            //             billingName: response.user.displayName,
            //         },
            //     },
            // });

            // return {
            //     error: null,
            //     message: null,
            //     user: {
            //         _id: newUser?.data?.User?.CreateOne?.recordId,
            //     },
            // };
        } else {
            let user = await apolloClient.query({
                query: GetSimpleUserByIdDocument,
                variables: { userId: response.user.uid },
            });

            return {
                error: null,
                message: null,
                user: user.data.GetSimpleUserById,
            };
        }
    }
};

export const signInWithFacebook = async () => {
    const response = await loginFacebook();
    return checkRemoteLogin(response);
};

export const signInWithGoogle = async () => {
    const response = await loginGoogle();
    return checkRemoteLogin(response);
};

export const signOut = createAsyncThunk("user/signOut", async (arg, { getState, requestId }) => {
    const { currentRequestId, loading } = getState().user;
    if (loading !== true || requestId !== currentRequestId) {
        return;
    }
    await logout();
    return null;
});

export const userSlice = createSlice({
    name: "user",
    initialState: {
        locale: window?.navigator?.language?.substring(0, 2) || "fr",
        loading: false,
        currentRequestId: undefined,
        error: null,
        currentUser: null,
        betaBanner: true,
        notificationOpened: false,
        languagesAvailable: ["fr", "en"],
        refetchDone: false,
    },
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setLocale(state, action) {
            state.locale = action.payload;
        },
        setBetaBanner(state, action) {
            state.betaBanner = action.payload;
        },
        setNotificationOpened(state, action) {
            state.notificationOpened = action.payload;
        },
        setLanguagesAvailable(state, action) {
            state.languagesAvailable = action.payload;
        },
        setCurrentUser(state, action) {
            state.loading = false;
            state.currentUser = action.payload;
        },
        resetRefetchDone(state) {
            state.refetchDone = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(signIn.pending, (state, action) => {
            if (!state.loading) {
                state.error = null;
                state.loading = true;
                state.currentRequestId = action.meta.requestId;
            }
        });
        builder.addCase(signIn.fulfilled, (state, action) => {
            const { requestId } = action.meta;

            if (state.loading && state.currentRequestId === requestId) {
                state.loading = false;
                state.currentUser = action.payload;
                state.currentRequestId = undefined;
            }
        });
        builder.addCase(signIn.rejected, (state, action) => {
            const { requestId } = action.meta;
            if (state.loading && state.currentRequestId === requestId) {
                state.loading = false;
                state.error = action.error;
                state.currentRequestId = undefined;
            }
        });
        builder.addCase(signOut.pending, (state, action) => {
            if (!state.loading) {
                state.loading = true;
                state.currentRequestId = action.meta.requestId;
            }
        });
        builder.addCase(signOut.fulfilled, (state, action) => {
            const { requestId } = action.meta;
            if (state.loading && state.currentRequestId === requestId) {
                state.loading = false;
                state.currentUser = action.payload;
                state.currentRequestId = undefined;
            }
        });
        builder.addCase(signOut.rejected, (state, action) => {
            const { requestId } = action.meta;
            if (state.loading && state.currentRequestId === requestId) {
                state.loading = false;
                state.error = action.error;
                state.currentRequestId = undefined;
            }
        });
        builder.addCase(fetchUser.fulfilled, (state, action) => {
            const { requestId } = action.meta;
            if (state.loading && state.currentRequestId === requestId) {
                state.loading = false;
                state.currentUser = action.payload;
                state.currentRequestId = undefined;
            }
        });
        builder.addCase(fetchUser.rejected, (state, action) => {
            const { requestId } = action.meta;
            if (state.loading && state.currentRequestId === requestId) {
                state.loading = false;
                state.error = action.error;
                state.currentRequestId = undefined;
            }
        });
        builder.addCase(fetchUser.pending, (state, action) => {
            if (!state.loading) {
                state.loading = true;
                state.currentRequestId = action.meta.requestId;
            }
        });
        builder.addCase(refetchUser.fulfilled, (state, action) => {
            const { requestId } = action.meta;
            if (state.loading && state.currentRequestId === requestId) {
                state.refetchDone = true;
                state.loading = false;
                state.currentUser = action.payload;
                state.currentRequestId = undefined;
            }
        });
        builder.addCase(refetchUser.rejected, (state, action) => {
            const { requestId } = action.meta;
            if (state.loading && state.currentRequestId === requestId) {
                state.loading = false;
                state.error = action.error;
                state.currentRequestId = undefined;
            }
        });
        builder.addCase(refetchUser.pending, (state, action) => {
            if (!state.loading) {
                state.loading = true;
                state.currentRequestId = action.meta.requestId;
            }
        });
    },
});
export const {
    setLocale,
    setLoading,
    setBetaBanner,
    setCurrentUser,
    setNotificationOpened,
    setLanguagesAvailable,
    resetRefetchDone,
} = userSlice.actions;
export default userSlice.reducer;
