import { useMemo, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import { Typography, Box, Checkbox } from "@material-ui/core";
import { ExpandMore, ArrowDropDown } from "@material-ui/icons";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import { firstLetterUppercase } from "../helpers/helpers";
import { useEnumValues } from "hooks/useEnumValues";
import { ContentLoader } from "Search/ui/ContentLoader";
import theme from "../globalTheme";

const useStyles = makeStyles((theme) => ({
    root: (props) => ({
        width: "100%",
        borderRadius: "53px",
        "& .MuiAutocomplete-endAdornment": {
            top: props.popupIcon ? "calc(50% - 8px)" : "calc(50% - 14px)",
        },
        "& .MuiSvgIcon-root": {
            fill: theme.palette.secondary.dark,
            width: "18px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            display: "none",
        },
        "& .MuiOutlinedInput-root": {
            fontSize: "14px",
            color: theme.palette.primary.dark,
            borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
            backgroundColor: "white",
            minHeight: "34px",
            "&.Mui-disabled": {
                color: theme.palette.secondary.dark,
                backgroundColor: theme.palette.disabled.main,
            },
            "&:hover fieldset": {
                borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
            },
            "&.Mui-focused fieldset": {
                border: "1px solid",
                borderColor: props.error ? theme.palette.error.main : theme.palette.secondary.main,
            },
        },
        "& .MuiFormLabel-root": {
            fontSize: "14px",
            color: props.error ? theme.palette.error.main : props?.labelColor ?? theme.palette.secondary.dark,
            paddingLeft: props?.labelPaddingLeft ?? 0,
            paddingTop: props?.labelPaddingTop ?? 0,
        },
        "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
            padding: 0,
            paddingTop: "4px",
            paddingBottom: "4px",
            paddingRight: 30,
            paddingLeft: "4px",
            position: "relative",
            background: theme.palette.common.white,
            margin: 0,
            width: "100%",
            border: `0.4px solid ${theme.palette.secondary.main}`,
            borderRadius: "53px",
            marginTop: "0px !important",
        },
        "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input": () => ({
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: props?.placeholderPaddingLeft ?? "9px",
        }),
        "& .MuiAutocomplete-tag": {
            display: "none",
            backgroundColor: theme.palette.primary.light,
            borderRadius: "4px",
            height: "22px",
        },
        "& .MuiChip-label": {
            color: theme.palette.common.white,
            fontSize: "12px",
        },
        "& .MuiChip-deleteIcon": {
            color: "white",
            width: "10px",
            height: "10px",
        },
        "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
            fontSize: "12px",
            color: theme.palette.primary.dark,
            fontStyle: "italic",
        },
        "& .MuiInputLabel-outlined": (props) => ({
            transform: props?.focused ? "translate(14px, -8px) scale(0.75)" : "translate(14px, 10px) scale(1)",
            backgroundColor: props?.focused ? theme.palette.primary.light : theme.palette.common.white,
            color: props?.focused ? theme.palette.common.white : theme.palette.primary.dark,
            padding: props?.focused ? "5px 7px" : "0 2px",
            borderRadius: props?.focused ? 5 : 0,

            [theme.breakpoints.up("md")]: {
                transform: props?.focused ? "translate(14px, -4px) scale(0.75)" : "translate(14px, 10px) scale(1)",
            },
        }),
    }),
    noOptionsText: {
        fontStyle: "italic",
        fontSize: "12px",
        color: theme.palette.secondary.dark,
    },
    errorText: {
        fontSize: "12px",
        color: theme.palette.error.main,
        marginBottom: theme.spacing(1),
        marginTop: "0px",
    },
    option: {
        fontSize: "14px",
        color: theme.palette.primary.dark,
        "&:hover": {
            backgroundColor: theme.palette.dashboard.background,
        },
        "&.MuiListItem-root.Mui-selected": {
            backgroundColor: theme.palette.dashboard.background,
        },
    },
    paper: {
        width: "280px",
        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.08)",

        "& ::-webkit-scrollbar": { width: "3px", height: "8px" },
        "& ::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(99,45,62,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(99,45,62,0.00)",
            paddingRight: "8px",
        },
        "& ::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.primary.light,
            borderRadius: "20px",
        },

        "& .MuiAutocomplete-option": {
            backgroundColor: theme.palette.common.white,
            paddingTop: 0,
            paddingBottom: 0,
            display: "flex",
            justifyContent: "flex-start",
            minHeight: "auto",
            marginBottom: "0.5rem",
            marginTop: "0.5rem",

            "& .MuiTypography-root": {
                marginLeft: 10,
            },

            "&:hover, &.MuiListItem-root.Mui-selected, &.MuiListItem-root.Mui-selected:hover": {
                backgroundColor: theme.palette.common.white,
            },

            "& .MuiIconButton-label": {
                width: "11px",
                height: "11px",
            },
        },
    },
    checkbox: {
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.common.white,
        paddingTop: 0,
        paddingBottom: 0,
        display: "flex",
        justifyContent: "space-between",
        minHeight: "auto",
        marginBottom: "0.5rem",
        marginTop: "0.5rem",
        padding: 4,
        width: 11,
        height: 11,

        "&.MuiCheckbox-colorSecondary.Mui-checked": {
            color: theme.palette.primary.light,
        },

        "& .MuiSvgIcon-fontSizeSmall": {
            fontSize: "1.4rem",
        },
    },
}));

const AutocompleteCheckboxSearch = (props) => {
    const [focused, setFocused] = useState(false);
    const classes = useStyles({ ...props, focused });
    const enumValues = useEnumValues(props.enumField);

    const options = useMemo(() => {
        let ret = props.enumField ? enumValues : props.options;
        if (props.sortAlphabetical) {
            ret = [...ret].sort();
        }
        return ret;
    });

    return (
        <Box className={[props.class, classes.root].join(" ")} style={props.style}>
            <Autocomplete
                popupIcon={
                    props.popupIcon ? (
                        <ExpandMore style={{ fill: theme.palette.primary.dark, fontSize: "1rem" }} />
                    ) : (
                        <ArrowDropDown />
                    )
                }
                renderOption={(option, { selected }) => (
                    <>
                        <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            className={classes.checkbox}
                            checked={selected}
                        />
                        <Typography
                            variant="h6"
                            style={{
                                whiteSpace: "break-spaces",
                                color: theme.palette.common.black,
                            }}
                        >
                            {option}
                        </Typography>
                    </>
                )}
                renderTags={props.renderTags}
                openText="Ouvrir"
                closeText="Fermer"
                id="tags-outlined"
                multiple
                disableListWrap
                disableClearable
                getOptionDisabled={(options) => (props.value?.length >= props?.tagsLimit ? true : false)}
                openOnFocus={false}
                ListboxProps={{ style: { maxHeight: "180px" } }}
                options={options || []}
                onChange={(event, newValue) => props.onChange(event, newValue)}
                getOptionLabel={(option) => {
                    if (props.getOptionLabel) {
                        return props.getOptionLabel(option);
                    }
                    if (props.uppercase) {
                        return firstLetterUppercase(option);
                    }
                    return option;
                }}
                value={props.value || []}
                classes={{ option: classes.option, paper: classes.paper }}
                noOptionsText={
                    <Typography className={classes.noOptionsText}>
                        Il n&apos;y a plus d&apos;options disponibles.
                    </Typography>
                }
                loading={props.loading}
                loadingText={<ContentLoader />}
                disabled={props.disabled}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label={props.label}
                        onFocus={() => setFocused(true)}
                        onBlur={(e) => setFocused(false)}
                        error={props.error}
                        disabled={props.disabled}
                    />
                )}
            />
            {props.error && (
                <Typography variant="h6" className={classes.errorText}>
                    {" "}
                    {props.error}{" "}
                </Typography>
            )}
        </Box>
    );
};

AutocompleteCheckboxSearch.propTypes = {
    label: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func,
    getOptionLabel: PropTypes.func,
    value: PropTypes.any,
    style: PropTypes.object,
    class: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    uppercase: PropTypes.bool,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    tagsLimit: PropTypes.any,
    renderTags: PropTypes.func,
    labelColor: PropTypes.string,
    placeholderPaddingLeft: PropTypes.string,
    labelPaddingLeft: PropTypes.string,
    labelPaddingTop: PropTypes.string,
    enumField: PropTypes.string,
    popupIcon: PropTypes.bool,
    sortAlphabetical: PropTypes.bool,
    loading: PropTypes.bool,
};

AutocompleteCheckboxSearch.defaultProps = {
    onChange: () => {},
};

export default AutocompleteCheckboxSearch;
